import { UserRefItemModel, UserRefListModel } from "./model";
import styled from "styled-components";
import { textualBlue /* grey_4, grey_6 */ } from "assets/strings/colors";

const UserRefItem = styled(UserRefItemModel)`
  #root & {
    position: relative;
    margin-top: 0.2rem;
    border-radius: 1rem;
    border: 1.5px solid #e6f5ff;
    background-color: #e4f5ff;
    width: 100%;
    > p {
      color: ${textualBlue};
      font-size: 1.5rem;
      text-align: center;
      margin: 0.5rem;
      overflow: hidden;
      white-space: nowrap;
    }
    .overflow-visible {
      white-space: initial;
    }
  }
`;
const UserRefList = styled(UserRefListModel)`
  #root & {
    position: relative;
    display: flex;
    justify-content: center;
    text-align: center;
  }
`;

export { UserRefList, UserRefItem };
