import React, { PureComponent } from "react";
import moment from "moment";
import "moment/locale/es-us";
import { connect } from "react-redux";
import { clearReduxStore } from "redux/actions/general";
import {
  ticketsAutocomplete,
  ticketsHistory,
  patchDiscardTicket,
  closeTicket,
  reverseTicket,
  getBuildings,
} from "services/api";
import MPosTicketsSearchView from "./components/tickets-search-view";
import { throwPopupMessage, closePopup } from "components/popup-message";
import MPosTicketsSearchPhoneView from "./components/tickets-search-phone-view";
import { pushArrayOptions } from "services/utils";
import {
  getSelectedCommerce as getSelectedCommerceFromRedux,
  getSelectedBuilding as getSelectedBuildingFromRedux,
} from "redux/selectors";

import {
  addSocketListener,
  disconnectSocket,
  historyMarkTicketAsPaid,
  initiateSocket,
  joinCommerceSocket,
  removeSocketListener,
} from "services/websockets";

moment.locale("es-us");

class MPosTicketsHistory extends PureComponent {
  constructor(props) {
    super(props);

    let commerce = this.props.getSelectedCommerce;
    let building = this.props.getSelectedBuilding;
    let category = commerce.category ? commerce.category : null;
    let buildingName =
      building && building.commercial_name ? building.commercial_name : null;
    this.state = {
      commerce,
      category,
      building,
      buildingName,
      token: this.props.token,
      buildingSearch: "this_building",
      creationRule: "all_tickets",
      newUsersRule: "all_users",
      viewState: "ticket_search",
      searchType: "dateRange", // enum ['ticket#','dateRange']
      searchParam: "",
      iva: 0,
      pd_fee: 0,
      fromDate: null,
      endDate: null,
      regular: false,
      gift: false,
      premium: false,
      monthly_premium: false,
      ready: false,
      paid: false,
      closed: false,
      closed_superadmin: false,
      closed_premium_no_transaction: false,
      incomplete: false,
      discarded: false,
      tickets: [],
      countTickets: 0,
      nextPageExist: false,
      currentPage: 1,
      ticketOptions: [],
      selectedTicket: null,
      showTicket: true,
      ticketOptionSelected: "",

      // viewType: 'phone' | is set when component mounts
      accesses: [],
      accessOptions: [],
      accessSelected: null,
      showConfirmButton: true,

      exitTicketDate: null,
      exitTicketHour: "",
      exitTicketMinute: "",

      paidTicketDate: null,
      paidTicketHour: "",
      paidTicketMinute: "",

      popupMessages: null,
      popupMessagesType: "",
      showPopup: false,
      optionActivated: false,
    };
    // ========== INSTANCE PROPERTIES ==========
    this.initialState = { ...this.state };
    this.ticketsPerPage = 20;
    this.formDataCache = {};
    // Bind PopupMessage functions
    this.throwPopupMessage = throwPopupMessage.bind(this);
    this.closePopup = closePopup.bind(this);
  }

  // =============== STATE DOCS ===============
  /*
--> ticket_search
  { ...this.initialState }
  Error messages will be shown here

--> ticket_search_loading
  { ...this.initialState }
  or
  { 
    ...this.this.initialState,
    ...this.formDataCache,
    currentPage: Number
  }

--> ticket_search_done
  {
    ...this.formDataCache,
    tickets: [{zero or more}],
    currentPage: Number,
    nextPageExist: boolean
  }

--> ticket_selection_done
  {
    ...this.formDataCache,
    tickets: [{one or more}],
    currentPage: Number,
    nextPageExist: boolean,
    selectedTicket: {object},
    showTicket: true
  }

*/
  // =============== END STATE DOCS ===============

  /*
   ** mpos-ticket-validate on Mount:
   ** --> set viewType
   ** --> set the 'resize' event listener
   */
  componentDidMount() {
    // Add event listener to keep track of the window size
    this.setViewType();
    initiateSocket();
    joinCommerceSocket(this.state.token, (socket_res) => {
      console.log(socket_res.message);
    });
    addSocketListener("mark as paid", (socket_res) => {
      if (socket_res.error || socket_res.name === "INSUFFICIENT_FUNDS") {
        this.setState({ ...this.initialState });
        this.throwPopupMessage(
          "error",
          socket_res.name === "INSUFFICIENT_FUNDS"
            ? "El usuario no tiene fondos suficientes"
            : "Ha ocurrido un error, verifique su conexión a internet"
        );
      }
      if (socket_res.message) {
        // Remove timeout
        setTimeout(() => {
          this.setState((prevState) => ({
            viewState: "option_done",
            popupMessages: `Ticket cobrado con éxito`,
            popupMessagesType: "success",
          }));
        }, 350);
      }
    });
    window.addEventListener("resize", this.setViewType);
    if (this.props.location.state && this.props.location.state.viewState) {
      let remoteState = this.props.location.state;
      if (remoteState.searchType === "dateRange") {
        if (!remoteState.fromDate || !remoteState.endDate) {
          this.setState((prevState) => ({
            ...this.initialState,
          }));
          this.throwPopupMessage(
            "error",
            "Debe seleccionar una fecha para continuar"
          );
        } else {
          this.setState((prevState) => ({
            ...remoteState,
            fromDate: moment(remoteState.fromDate, "DD / MM / YYYY"),
            endDate: moment(remoteState.endDate, "DD / MM / YYYY"),
          }));
        }
      } else {
        if (!remoteState.fromDate || !remoteState.endDate) {
          this.setState((prevState) => ({
            ...remoteState,
            fromDate: null,
            endDate: null,
          }));
        } else {
          this.setState((prevState) => ({
            ...remoteState,
            fromDate: remoteState.fromDate,
            endDate: remoteState.endDate,
          }));
        }
      }
      this.props.history.replace({
        pathname: this.props.location.pathname,
        state: {},
      });
    }
  } // Remote access control from user-admin

  // Remove event listeners
  componentWillUnmount() {
    window.removeEventListener("resize", this.setViewType);
    removeSocketListener();
    disconnectSocket();
  }

  componentDidUpdate() {
    let building = this.props.getSelectedBuilding;
    let buildingName =
      building && building.commercial_name ? building.commercial_name : null;
    this.setState({
      building,
      buildingName,
    });
  }
  // ========== METHODS ==========

  // 0)
  handleBuildingSearch = (event) => {
    event.preventDefault();
    let buildingSearch = event.currentTarget.id;
    this.setState({ buildingSearch });
  };

  // 1)
  handleSearchTypeButtons = (event) => {
    event.preventDefault();

    this.setState({
      fromDate: null,
      endDate: null,
      searchType: event.target.value,
    });
  }; //

  // 2)
  handleSearchInputChange = (event) => {
    let value = event.target.value;
    // The field is restrcted to accept only letters, numbers, @(first position) and _-.
    if (value.match(/^(?=@?.*)@?[\w.-]*$/)) {
      value = value.toLowerCase();
      this.setState({ searchParam: value });
    }
  };

  // 3)
  handleFromDate = (date) => {
    if (date) {
      this.setState({ fromDate: date });
    } else {
      this.setState({ fromDate: date });
    }
  };

  // 4)
  handleEndDate = (date) => {
    if (date) {
      // If the date exists add 23 hours
      this.setState({
        endDate: date.add(1, "days"),
      });
    } else {
      // If the date is null, save it like that
      this.setState({ endDate: date });
    }
  };

  // 5)
  handleTypesButton = (event) => {
    event.preventDefault();
    let type = event.target.name;
    this.setState((prevState) => ({
      [type]: !prevState[type],
    }));
  };

  //6)
  handleTicketCreation = (event) => {
    event.preventDefault();
    let creationRule = event.currentTarget.id;
    this.setState({ creationRule });
  };

  // 7)
  handleStatusButton = (event) => {
    event.preventDefault();
    let status = event.target.name;
    this.setState((prevstate) => ({
      [status]: !prevstate[status],
    }));
  };

  //8)
  handleNewUsers = (event) => {
    event.preventDefault();
    let newUsersRule = event.currentTarget.id;
    this.setState({ newUsersRule });
  };

  formatRequestData = (dataObject) => {
    let value,
      fromDate,
      endDate,
      status = [],
      types = [],
      creationRule,
      newUsersRule,
      limit;

    if (dataObject.ready) status.push("ready");
    if (dataObject.paid) {
      status.push("paid");
      status.push("incomplete");
    }
    if (dataObject.closed) {
      status.push("closed");
      if (this.state.category === "pagodirecto") {
        status.push("closed_superadmin");
        status.push("closed_promotional_no_transaction");
      }
    }
    if (dataObject.closed_premium_no_transaction) {
      if (
        this.state.category === "pagodirecto" ||
        this.state.category === "estacionamiento"
      ) {
        status.push("closed_premium_no_transaction");
      }
    }
    if (this.state.category === "pagodirecto") {
      if (dataObject.gift) {
        types.push("prized");
        types.push("recommended");
        types.push("promotioned");
      }
      if (dataObject.regular) {
        types.push("regular");
      }
      if (dataObject.premium) {
        types.push("premium");
      }
      if (dataObject.monthly_premium) {
        types.push("monthly_premium");
      }
      creationRule = dataObject.creationRule;
      newUsersRule = dataObject.newUsersRule;
    }
    if (dataObject.discarded) status.push("discarded");
    if (dataObject.closed_superadmin) status.push("closed_superadmin");
    if (dataObject.fromDate) fromDate = dataObject.fromDate.toISOString();
    if (dataObject.endDate) endDate = dataObject.endDate.toISOString();
    value = dataObject.searchParam;
    value = value[0] === "@" ? value.substr(1) : value;
    limit = this.ticketsPerPage + 1;

    if (this.state.searchType !== "dateRange") {
      fromDate = null;
      endDate = null;
    }
    return {
      value,
      fromDate,
      endDate,
      status,
      types,
      creationRule,
      newUsersRule,
      limit,
    };
  };

  handleSubmit = (event) => {
    event.preventDefault();
    // format request data
    let {
      value,
      fromDate,
      endDate,
      status,
      types,
      creationRule,
      newUsersRule,
      limit,
    } = this.formatRequestData(this.state);
    let skip = 0;
    // save a copy of the form state
    this.formDataCache = {
      searchType: this.state.searchType,
      searchParam: this.state.searchParam,
      fromDate: this.state.fromDate,
      endDate: this.state.endDate,
      ready: this.state.ready,
      paid: this.state.paid,
      closed: this.state.closed,
      closed_superadmin: this.state.closed_superadmin,
      incomplete: this.state.incomplete,
      discarded: this.state.discarded,
      closed_premium_no_transaction: this.state.closed_premium_no_transaction,
      gift: this.state.gift,
      premium: this.state.premium,
      monthly_premium: this.state.monthly_premium,
      regular: this.state.regular,
      creationRule: this.state.creationRule,
      newUsersRule: this.state.newUsersRule,
    };

    // Remove on screen ticket
    this.setState({ showTicket: false });
    // timeout next instructions so the ticket is removed from view
    setTimeout(() => {
      // Set loading state
      this.setState({
        ...this.initialState,
        ...this.formDataCache,
        buildingSearch: this.state.buildingSearch,
        viewState: "ticket_search_loading",
      });
      // make the api call
      let commerce = null;
      let building = null;
      let new_user = false;
      if (this.state.commerce && this.state.commerce.category) {
        let category = this.state.commerce.category;
        switch (category) {
          case "pagodirecto":
            building =
              this.state.buildingSearch === "this_building"
                ? this.state.building._id
                : null;
            new_user = newUsersRule === "new_users";
            break;
          case "estacionamiento":
            building = this.state.building._id;
            break;
          default:
            if (this.state.commerce.parking) {
              commerce = this.state.commerce._id;
            }
            break;
        }
      }
      let promise;
      if (this.state.searchType === "ticket#") {
        promise = ticketsAutocomplete({
          value,
          status,
          to_pay: false,
          skip,
          limit,
          commerce,
          building,
          types,
          creationRule,
        });
      }
      if (this.state.searchType === "dateRange") {
        promise = ticketsHistory(
          fromDate,
          endDate,
          status,
          types,
          creationRule,
          skip,
          limit,
          commerce,
          building,
          new_user
        );
      }
      this.handleTicketsSearchPromise(promise);
    }, 200);
  };

  handlePageDown = (event) => {
    event.preventDefault();
    // format request data
    let {
      value,
      fromDate,
      endDate,
      status,
      types,
      creationRule,
      newUsersRule,
      limit,
    } = this.formatRequestData(this.formDataCache);
    let skip = (this.state.currentPage - 2) * this.ticketsPerPage;
    // Set loading state
    this.setState((prevState) => ({
      ...this.initialState,
      ...this.formDataCache,
      viewState: "ticket_search_loading",
      buildingSearch: prevState.buildingSearch,
      currentPage: prevState.currentPage - 1,
      nextPageExist: true,
    }));
    // make the api call
    let commerce = null;
    let building = null;
    let new_user = false;
    if (this.state.commerce && this.state.commerce.category) {
      let category = this.state.commerce.category;
      switch (category) {
        case "pagodirecto":
          building =
            this.state.buildingSearch === "this_building"
              ? this.state.building._id
              : null;
          new_user = newUsersRule === "new_users";
          break;
        case "estacionamiento":
          building = this.state.building._id;
          break;
        default:
          if (this.state.commerce.parking) {
            commerce = this.state.commerce._id;
          }
          break;
      }
    }
    let promise;
    if (this.state.searchType === "ticket#") {
      promise = ticketsAutocomplete({
        value,
        status,
        types,
        creationRule,
        to_pay: false,
        skip,
        limit,
        commerce,
        building,
      });
    }
    if (this.state.searchType === "dateRange") {
      promise = ticketsHistory(
        fromDate,
        endDate,
        status,
        types,
        creationRule,
        skip,
        limit,
        commerce,
        building,
        new_user
      );
    }
    this.handleTicketsSearchPromise(promise);
  };

  // 9)
  handlePageUp = (event) => {
    event.preventDefault();
    // format request data
    let {
      value,
      fromDate,
      endDate,
      status,
      types,
      creationRule,
      newUsersRule,
      limit,
    } = this.formatRequestData(this.formDataCache);
    let skip = this.state.currentPage * this.ticketsPerPage;
    // Set loading state
    this.setState((prevState) => ({
      ...this.initialState,
      ...this.formDataCache,
      viewState: "ticket_search_loading",
      currentPage: prevState.currentPage + 1,
      buildingSearch: prevState.buildingSearch,
      nextPageExist: true,
    }));
    // make the api call
    let commerce = null;
    let building = null;
    let new_user = false;
    if (this.state.commerce && this.state.commerce.category) {
      let category = this.state.commerce.category;
      switch (category) {
        case "pagodirecto":
          building =
            this.state.buildingSearch === "this_building"
              ? this.state.building._id
              : null;
          new_user = newUsersRule === "new_users";
          break;
        case "estacionamiento":
          building = this.state.building._id;
          break;
        default:
          if (this.state.commerce.parking) {
            commerce = this.state.commerce._id;
          }
          break;
      }
    }
    let promise;
    if (this.state.searchType === "ticket#") {
      promise = ticketsAutocomplete({
        value,
        status,
        types,
        creationRule,
        to_pay: false,
        skip,
        limit,
        commerce,
        building,
      });
    }
    if (this.state.searchType === "dateRange") {
      promise = ticketsHistory(
        fromDate,
        endDate,
        status,
        types,
        creationRule,
        skip,
        limit,
        commerce,
        building,
        new_user
      );
    }
    this.handleTicketsSearchPromise(promise);
  };

  // 10)
  handleTicketsSearchPromise = (promise) => {
    promise
      .then((res) => res.json())
      .then((res) => {
        let { iva, pd_fee } = this.state;
        if (res.error) {
          // If it is an authentication error, clear redux-store to close the session
          if (res.error.code === "#1008" || res.error.code === "#1009") {
            //this.props.clearReduxStore();
            this.setState({ authError: true });
            return; // prevent setState exeution on unmounted component
          }
          this.setState({ ...this.initialState });
          this.throwPopupMessage("error", res.error.description);
        } else {
          if (res.iva) {
            iva = res.iva.amount / 100 + 1;
          }
          if (res.pd_fee) {
            pd_fee = res.pd_fee.amount;
          }
          if (res.tickets) {
            if (!res.tickets.length) {
              // if the response has no tickets reset the view with the formData
              this.setState({
                ...this.initialState,
                ...this.formDataCache,
                buildingSearch: this.state.buildingSearch,
                countTickets: res.tickets.length,
                viewState: "ticket_search",
              });
            } else {
              let nextPageExist;
              let totalTickets = res.tickets;
              let tickets = [];
              if (this.state.category === "pagodirecto") {
                tickets = [...totalTickets];
              } else if (this.state.category === "estacionamiento") {
                totalTickets.forEach((ticket) => {
                  if (ticket.status !== "closed_promotional_no_transaction") {
                    tickets.push(ticket);
                  }
                });
              } else {
                totalTickets.forEach((ticket) => {
                  if (
                    ticket.status !== "closed_premium_no_transaction" &&
                    ticket.status !== "closed_superadmin" &&
                    ticket.status !== "closed_promotional_no_transaction"
                  ) {
                    tickets.push(ticket);
                  }
                });
              }
              if (tickets.length > this.ticketsPerPage) {
                nextPageExist = true;
                tickets.pop();
              } else {
                nextPageExist = false;
              }
              this.setState({
                viewState: "ticket_search_done",
                tickets: tickets,
                countTickets: tickets.length,
                buildingSearch: this.state.buildingSearch,
                nextPageExist,
                iva,
                pd_fee,
              });
            }
          }
        }
      })
      .catch((err) => {
        this.setState({ ...this.initialState });
        this.throwPopupMessage(
          "error",
          "Ha ocurrido un error, verifique su conexión a internet"
        );
      });
  };

  // 11)
  handleTicketSelection = (event) => {
    let index = event.currentTarget.id;
    let status = this.state.tickets[index].status;
    let ticket = this.state.tickets[index];
    let ticketOptions = [];
    this.setState((prevState) => ({
      showTicket: false,
    }));

    setTimeout(
      function () {
        this.setState((prevState) => ({
          viewState: "ticket_selection_done",
          selectedTicket: prevState.tickets[index],
          ticketOptions,
          showTicket: true,
        }));
      }.bind(this),
      350
    );
    if (status === "closed_superadmin") status = "closed";
    switch (status) {
      case "ready":
        ticketOptions = pushArrayOptions(["Descartar", "Cobrar"]);
        break;
      case "paid":
        ticketOptions =
          this.state.category === "pagodirecto"
            ? pushArrayOptions(["Descartar", "Cerrar", "Reversar"])
            : pushArrayOptions(["Descartar"]);
        break;
      case "incomplete":
        ticketOptions =
          this.state.category === "pagodirecto"
            ? pushArrayOptions(["Cerrar", "Reversar"])
            : pushArrayOptions([]);
        break;
      case "closed":
        ticketOptions =
          this.state.category === "pagodirecto"
            ? pushArrayOptions(["Reversar"])
            : pushArrayOptions([]);
        break;
      case "closed_premium_no_transaction":
        ticketOptions =
          this.state.category === "pagodirecto"
            ? pushArrayOptions(["Reversar"])
            : pushArrayOptions([]);
        break;
      case "closed_promotional_no_transaction":
        ticketOptions =
          this.state.category === "pagodirecto"
            ? pushArrayOptions(["Reversar"])
            : pushArrayOptions([]);
        break;
      case "discarded":
        ticketOptions =
          ticket && ticket.paid_at && this.state.category === "pagodirecto"
            ? pushArrayOptions(["Reversar"])
            : pushArrayOptions([]);
        break;
      default:
        break;
    }
  };

  // 12)
  removeSelectedTicket = () => {
    // Remove ticket and set transition states
    if (this.state.showTicket) {
      this.setState((prevState) => ({
        showTicket: false,
        buildingSearch: prevState.buildingSearch,
      }));
    }
    if (this.state.viewState === "option_done") {
      setTimeout(() => {
        this.setState((prevState) => ({
          ...this.initialState,
          buildingSearch: prevState.buildingSearch,
        }));
      }, 350);
    } else {
      setTimeout(() => {
        this.setState((prevState) => ({
          viewState: "ticket_search_done",
          selectedTicket: null,
          buildingSearch: prevState.buildingSearch,
        }));
      }, 350);
    }
  };

  // ticket selected options...

  handleOptionsSelection = (event, data) => {
    event.preventDefault();
    let ticketOptionSelected = data.value;
    if (ticketOptionSelected !== "Cerrar") {
      this.setState({ ticketOptionSelected, viewState: "option_confirmation" });
      setTimeout(() => {
        this.throwPopupMessage(
          "alert",
          `¿Deseas ${ticketOptionSelected.toLowerCase()} este ticket?`
        );
      }, 350);
    } else {
      let buildingTicket = this.state.selectedTicket.building._id;
      this.setState({ viewState: "option_loading" });
      const apiCall = async () => {
        try {
          const response = await getBuildings(buildingTicket);
          const res = await response.json();
          if (res.error) throw res.error;

          let accesses = [];
          let accessOptions = [];
          let building = null;
          if (res.building && res.building.accesses) {
            building = res.building;
            accesses = res.building.accesses.filter(
              (access) => access.type === "exit"
            );
            let options = accesses.map((access) => {
              return access.name;
            });
            accessOptions = pushArrayOptions(options);
          }
          this.setState({
            building,
            accesses,
            accessOptions,
            ticketOptionSelected,
            showConfirmButton: false,
            viewState: "option_confirmation",
          });
          setTimeout(() => {
            this.throwPopupMessage(
              "alert",
              `Para ${ticketOptionSelected} este ticket seleccione un acceso de salida`
            );
          }, 350);
        } catch (error) {
          this.setState({ ...this.initialState });
          this.throwPopupMessage(
            "error",
            "Ha ocurrido un error, verifique su conexión a internet"
          );
        }
      };
      apiCall();
    }
  };

  toggleOptionConfirmation = (event) => {
    event.preventDefault();
    if (this.state.viewState === "option_done") {
      this.closePopup();
      let { value, fromDate, endDate, status, types, creationRule, limit } =
        this.formatRequestData(this.state);
      let skip = 0;
      // save a copy of the form state
      this.formDataCache = {
        searchType: this.state.searchType,
        searchParam: this.state.searchParam,
        fromDate: this.state.fromDate,
        endDate: this.state.endDate,
        gift: this.state.gift,
        regular: this.state.regular,
        premium: this.state.premium,
        monthly_premium: this.state.monthly_premium,
        ready: this.state.ready,
        paid: this.state.paid,
        closed: this.state.closed,
        incomplete: this.state.incomplete,
        discarded: this.state.discarded,
        creationRule: this.state.creationRule,
      };
      // Remove on screen ticket
      this.setState({ showTicket: false });
      // timeout next instructions so the ticket is removed from view
      setTimeout(() => {
        // Set loading state
        this.setState({
          ...this.initialState,
          ...this.formDataCache,
          buildingSearch: this.state.buildingSearch,
          viewState: "ticket_search_loading",
        });
        // make the api call
        let commerce = null;
        let building = null;
        if (this.state.commerce && this.state.commerce.category) {
          let category = this.state.commerce.category;
          switch (category) {
            case "pagodirecto":
              building =
                this.state.buildingSearch === "this_building"
                  ? this.state.building._id
                  : null;
              break;
            case "estacionamientos":
              building = this.state.building._id;
              break;
            default:
              if (this.state.commerce.parking) {
                commerce = this.state.commerce._id;
              }
              break;
          }
        }
        let promise;
        if (this.state.searchType === "ticket#") {
          promise = ticketsAutocomplete({
            value,
            status,
            types,
            creationRule,
            to_pay: false,
            skip,
            limit,
            commerce,
            building,
          });
        }
        if (this.state.searchType === "dateRange") {
          promise = ticketsHistory(
            fromDate,
            endDate,
            status,
            types,
            creationRule,
            skip,
            limit,
            commerce,
            building
          );
        }
        this.handleTicketsSearchPromise(promise);
      }, 200);
    }
    if (this.state.viewState === "option_confirmation") {
      this.closePopup();
      // timeout the update for the animation
      setTimeout(() => {
        this.setState({
          viewState: "ticket_selection_done",
          showConfirmButton: true,
          buildingSearch: this.state.buildingSearch,
        });
      }, 350);
    }
  };

  confirmOption = async () => {
    //before discardTicket
    let option = this.state.ticketOptionSelected;
    let passOption = "";
    this.setState({ viewState: "option_loading", popupMessages: "" });
    switch (option) {
      case "Descartar":
        passOption = "descartado";
        this.discardTicket(passOption);
        break;
      case "Cerrar":
        passOption = "cerrado";
        this.closeTicketFromHistory(passOption);
        break;
      case "Reversar":
        passOption = "reversado";
        this.reverseTicketFromHistory(passOption);
        break;
      case "Cobrar":
        passOption = "debitado";
        this.validateTicketFromHistory();
        break;
      default:
        break;
    }
  };
  validateTicketFromHistory = async () => {
    this.setState({ viewState: "option_loading", popupMessages: "" });

    let paid_at = moment(this.state.paidTicketDate);
    let paidTicketHour = parseInt(this.state.paidTicketHour, 10);
    let paidTicketMinute = parseInt(this.state.paidTicketMinute, 10);
    paid_at.add(paidTicketHour, "hours");
    paid_at.add(paidTicketMinute, "minutes");
    paid_at = paid_at.toISOString();

    if (!moment(paid_at).isAfter(moment(this.state.selectedTicket.enter_at))) {
      this.setState({ viewState: "option_confirmation" });
      this.throwPopupMessage(
        "error",
        "La fecha y hora de pago no pueden ser inferior a la hora de entrada"
      );

      return;
    }

    if (
      moment(paid_at).isBefore(moment()) ||
      moment(paid_at).isBefore(moment(this.state.selectedTicket.exit_at))
    ) {
      historyMarkTicketAsPaid(this.props.token, {
        ...this.state.selectedTicket,
        paid_at,
        enable_ticket_type: "user",
      });

      return;
    }

    this.setState({ viewState: "option_confirmation" });
    this.throwPopupMessage(
      "error",
      "La fecha y hora de pago no pueden ser superiores a la hora de salida o a la hora actual"
    );
  };

  discardTicket = async (option) => {
    try {
      const { _id, username } = this.state.selectedTicket;
      this.setState({ viewState: "option_loading", popupMessages: "" });
      const response = await patchDiscardTicket(_id, username);
      const res = await response.json();

      if (res.error) throw res.error;
      setTimeout(() => {
        this.setState((prevState) => ({
          viewState: "option_done",
          popupMessages: `Ticket ${option} con éxito`,
          popupMessagesType: "success",
        }));
      }, 350);
    } catch (error) {}
  };
  closeTicketFromHistory = async (option) => {
    this.setState({ viewState: "option_loading" });
    try {
      let building_code = this.state.selectedTicket.building_code;
      let username = this.state.selectedTicket.username;
      let ticket_id = this.state.selectedTicket._id;
      let building_exit = this.state.accessSelected.code;

      let exit_at = moment(this.state.exitTicketDate);
      let exitTicketHour = parseInt(this.state.exitTicketHour, 10);
      let exitTicketMinute = parseInt(this.state.exitTicketMinute, 10);
      exit_at.add(exitTicketHour, "hours");
      exit_at.add(exitTicketMinute, "minutes");
      //DOMMatrixReadOnly = exit_at.toISOString();

      // todo
      const isPremium =
        this.state.selectedTicket.type === "regular" &&
        this.state.selectedTicket.ticket_type === "premium";
      if (
        !moment(exit_at).isSameOrAfter(
          moment(this.state.selectedTicket.paid_at)
        ) &&
        !isPremium
      ) {
        this.setState({ viewState: "option_confirmation" });
        this.throwPopupMessage(
          "error",
          "La fecha y hora de salida no pueden ser inferior a la hora de pago"
        );

        return;
      }

      if (!moment(exit_at).isBefore(moment()) && !isPremium) {
        this.setState({ viewState: "option_confirmation" });
        this.throwPopupMessage(
          "error",
          "La fecha y hora de pago no pueden ser superiores a la hora actual"
        );

        return;
      }

      const response = await closeTicket({
        username,
        ticket_id,
        building_exit,
        building_code,
        exit_at,
      });

      const res = await response.json();
      if (res.error) throw res.error;
      setTimeout(() => {
        this.setState((prevState) => ({
          viewState: "option_done",
          popupMessages: `Ticket ${option} con éxito`,
          popupMessagesType: "success",
        }));
      }, 350);
    } catch (err) {
      this.setState({ ...this.initialState });
      this.throwPopupMessage(
        "error",
        "Ha ocurrido un error, verifique su conexión a internet"
      );
    }
  };

  handleAccessOptions = (event, data) => {
    event.preventDefault();
    let accessSelectedArray = this.state.accesses.filter(
      (access) => access.name === data.value
    );
    let accessSelected = accessSelectedArray[0];

    const enableBtn =
      !!this.state.exitTicketDate &&
      !!this.state.exitTicketHour &&
      !!this.state.exitTicketMinute &&
      !!accessSelected;

    this.setState({
      accessSelected,
      showConfirmButton: enableBtn,
    });
  };

  // todo
  handleTicketExitDate = (date) => {
    const enableBtn =
      !!date &&
      !!this.state.exitTicketHour &&
      !!this.state.exitTicketMinute &&
      !!this.state.accessSelected;

    if (date) {
      this.setState({
        exitTicketDate: date.startOf("date"),
        showConfirmButton: enableBtn,
      });
    } else {
      this.setState({ exitTicketDate: date, showConfirmButton: enableBtn });
    }
  };

  handleTicketExitHour = (event) => {
    let value = event.target.value;

    if (value.length === 3) {
      value = value.substring(1);
    }

    if (value.match(/^(0?[0-9]|0?1[0-9]|0?2[0-3])?$/)) {
      if (value.match(/^[1-9]$/)) {
        value = "0" + value;
      }

      const enableBtn =
        !!this.state.exitTicketDate &&
        !!value &&
        !!this.state.exitTicketMinute &&
        !!this.state.accessSelected;

      this.setState({ exitTicketHour: value, showConfirmButton: enableBtn });
    }
  };

  handleTicketExitMinute = (event) => {
    let value = event.target.value;

    if (value.length === 3) {
      value = value.substring(1);
    }
    if (value.match(/^(0?[0-9]|0?[1-5][0-9])?$/)) {
      if (value.match(/^[1-9]$/)) {
        value = "0" + value;
      }

      const enableBtn =
        !!this.state.exitTicketDate &&
        !!this.state.exitTicketHour &&
        !!value &&
        !!this.state.accessSelected;

      this.setState({ exitTicketMinute: value, showConfirmButton: enableBtn });
    }
  };

  //todo

  handlePaidTicketDate = (date) => {
    const enableBtn =
      !!date && !!this.state.paidTicketHour && !!this.state.paidTicketMinute;

    if (date) {
      this.setState({
        paidTicketDate: date.startOf("date"),
        showConfirmButton: enableBtn,
      });
    } else {
      this.setState({ paidTicketDate: date, showConfirmButton: enableBtn });
    }
  };

  handlePaidTicketHour = (event) => {
    let value = event.target.value;

    if (value.length === 3) {
      value = value.substring(1);
    }

    if (value.match(/^(0?[0-9]|0?1[0-9]|0?2[0-3])?$/)) {
      if (value.match(/^[1-9]$/)) {
        value = "0" + value;
      }

      const enableBtn =
        !!this.state.paidTicketDate && !!value && !!this.state.paidTicketMinute;

      this.setState({ paidTicketHour: value, showConfirmButton: enableBtn });
    }
  };

  handlePaidTicketMinute = (event) => {
    let value = event.target.value;

    if (value.length === 3) {
      value = value.substring(1);
    }
    if (value.match(/^(0?[0-9]|0?[1-5][0-9])?$/)) {
      if (value.match(/^[1-9]$/)) {
        value = "0" + value;
      }

      const enableBtn =
        !!this.state.paidTicketDate && !!this.state.paidTicketHour && !!value;

      this.setState({ paidTicketMinute: value, showConfirmButton: enableBtn });
    }
  };

  reverseTicketFromHistory = async (option) => {
    this.setState({ viewState: "option_loading" });
    try {
      let ticket = this.state.selectedTicket;
      let ticket_id = ticket._id;
      let params = { ticket_id, has_premium: false };

      let errors = {
        error_reversed: "Este ticket ya fue reversado",
        error_type: "No se puede reversar este ticket",
      };

      if (ticket && ticket.reversed) throw errors.error_reversed;
      if (ticket && ticket.status === "closed_premium_no_transactions")
        throw errors.error_type;

      if (ticket.paid_by) params.paid_by = ticket.paid_by;
      if (
        ticket.premium_type ||
        ticket.ticket_type === "prized" ||
        ticket.ticket_type === "recommended" ||
        ticket.ticket_type === "promotioned"
      )
        params.has_premium = true;

      const response = await reverseTicket(params);
      const res = await response.json();
      if (res.error) throw res.error;
      setTimeout(() => {
        this.setState((prevState) => ({
          viewState: "option_done",
          popupMessages: `Ticket ${option} con éxito`,
          popupMessagesType: "success",
        }));
      }, 350);
    } catch (err) {
      if (typeof err === "object") {
        setTimeout(() => {
          this.setState((prevState) => ({
            viewState: "option_done",
            popupMessages: err.description,
            popupMessagesType: "error",
          }));
        }, 350);
      } else {
        setTimeout(() => {
          this.setState((prevState) => ({
            viewState: "option_done",
            popupMessages: err,
            popupMessagesType: "error",
          }));
        }, 350);
      }
    }
  };
  // 13)

  setViewType = () => {
    let documentElement = document.documentElement,
      width = window.innerWidth || documentElement.clientWidth;
    if (width < 878) {
      this.setState({ viewType: "phone" });
    } else {
      this.setState({ viewType: "desktop" });
    }
  };

  render() {
    if (this.state.viewType === "phone") {
      return (
        <MPosTicketsSearchPhoneView
          // properties
          viewState={this.state.viewState}
          state={this.state}
          category={this.state.category}
          buildingName={this.state.buildingName}
          buildingSearch={this.state.buildingSearch}
          searchType={this.state.searchType}
          searchParam={this.state.searchParam}
          fromDate={this.state.fromDate}
          endDate={this.state.endDate}
          gift={this.state.gift}
          regular={this.state.regular}
          premium={this.state.premium}
          monthlyPremium={this.state.monthly_premium}
          ready={this.state.ready}
          paid={this.state.paid}
          closed={this.state.closed}
          closedPremiumNoTransaction={this.state.closed_premium_no_transaction}
          discarded={this.state.discarded}
          countTickets={this.state.countTickets}
          tickets={this.state.tickets}
          ticketOptions={this.state.ticketOptions}
          pd_fee={this.state.pd_fee}
          iva={this.state.iva}
          nextPageExist={this.state.nextPageExist}
          currentPage={this.state.currentPage}
          selectedTicket={this.state.selectedTicket}
          showTicket={this.state.showTicket}
          popupMessages={this.state.popupMessages}
          popupMessagesType={this.state.popupMessagesType}
          showPopup={this.state.showPopup}
          permissions={this.props.permissions}
          location={this.props.location}
          accessOptions={this.state.accessOptions}
          showConfirmButton={this.state.showConfirmButton}
          ticketOptionSelected={this.state.ticketOptionSelected}
          creationRule={this.state.creationRule}
          exitTicketDate={this.state.exitTicketDate}
          exitTicketHour={this.state.exitTicketHour}
          exitTicketMinute={this.state.exitTicketMinute}
          paidTicketDate={this.state.paidTicketDate}
          paidTicketHour={this.state.paidTicketHour}
          paidTicketMinute={this.state.paidTicketMinute}
          // methods
          handleTicketExitDate={this.handleTicketExitDate}
          handleTicketExitHour={this.handleTicketExitHour}
          handleTicketExitMinute={this.handleTicketExitMinute}
          handlePaidTicketDate={this.handlePaidTicketDate}
          handlePaidTicketHour={this.handlePaidTicketHour}
          handlePaidTicketMinute={this.handlePaidTicketMinute}
          handleTicketCreation={this.handleTicketCreation}
          handleTypesButton={this.handleTypesButton}
          handleBuildingSearch={this.handleBuildingSearch}
          handleSearchTypeButtons={this.handleSearchTypeButtons}
          handleOptionsSelection={this.handleOptionsSelection}
          handleAccessOptions={this.handleAccessOptions}
          handleSearchInputChange={this.handleSearchInputChange}
          handleFromDate={this.handleFromDate}
          handleEndDate={this.handleEndDate}
          handleStatusButton={this.handleStatusButton}
          handleSubmit={this.handleSubmit}
          handlePageUp={this.handlePageUp}
          handlePageDown={this.handlePageDown}
          handleTicketSelection={this.handleTicketSelection}
          removeSelectedTicket={this.removeSelectedTicket}
          closePopup={this.closePopup}
          toggleOptionConfirmation={this.toggleOptionConfirmation}
          confirm={this.confirmOption}
          newUsersRule={this.state.newUsersRule}
          handleNewUsers={this.handleNewUsers}
        />
      );
    }

    return (
      <MPosTicketsSearchView
        // properties
        viewState={this.state.viewState}
        state={this.state}
        category={this.state.category}
        buildingName={this.state.buildingName}
        buildingSearch={this.state.buildingSearch}
        searchType={this.state.searchType}
        searchParam={this.state.searchParam}
        gift={this.state.gift}
        regular={this.state.regular}
        premium={this.state.premium}
        monthlyPremium={this.state.monthly_premium}
        fromDate={this.state.fromDate}
        endDate={this.state.endDate}
        ready={this.state.ready}
        countTickets={this.state.countTickets}
        paid={this.state.paid}
        closed={this.state.closed}
        closedSuperadmin={this.state.closed_superadmin}
        closedPremiumNoTransaction={this.state.closed_premium_no_transaction}
        discarded={this.state.discarded}
        tickets={this.state.tickets}
        pd_fee={this.state.pd_fee}
        iva={this.state.iva}
        nextPageExist={this.state.nextPageExist}
        currentPage={this.state.currentPage}
        selectedTicket={this.state.selectedTicket}
        showTicket={this.state.showTicket}
        ticketOptions={this.state.ticketOptions}
        ticketOptionSelected={this.state.ticketOptionSelected}
        optionActivated={this.state.optionActivated}
        accessOptions={this.state.accessOptions}
        showConfirmButton={this.state.showConfirmButton}
        popupMessages={this.state.popupMessages}
        popupMessagesType={this.state.popupMessagesType}
        showPopup={this.state.showPopup}
        permissions={this.props.permissions}
        location={this.props.location}
        creationRule={this.state.creationRule}
        exitTicketDate={this.state.exitTicketDate}
        exitTicketHour={this.state.exitTicketHour}
        exitTicketMinute={this.state.exitTicketMinute}
        paidTicketDate={this.state.paidTicketDate}
        paidTicketHour={this.state.paidTicketHour}
        paidTicketMinute={this.state.paidTicketMinute}
        // methods
        handleTicketExitDate={this.handleTicketExitDate}
        handleTicketExitHour={this.handleTicketExitHour}
        handleTicketExitMinute={this.handleTicketExitMinute}
        handlePaidTicketDate={this.handlePaidTicketDate}
        handlePaidTicketHour={this.handlePaidTicketHour}
        handlePaidTicketMinute={this.handlePaidTicketMinute}
        handleTicketCreation={this.handleTicketCreation}
        handleTypesButton={this.handleTypesButton}
        handleBuildingSearch={this.handleBuildingSearch}
        handleOptionsSelection={this.handleOptionsSelection}
        handleAccessOptions={this.handleAccessOptions}
        handleSearchTypeButtons={this.handleSearchTypeButtons}
        handleSearchInputChange={this.handleSearchInputChange}
        handleFromDate={this.handleFromDate}
        handleEndDate={this.handleEndDate}
        handleStatusButton={this.handleStatusButton}
        handleSubmit={this.handleSubmit}
        handlePageUp={this.handlePageUp}
        handlePageDown={this.handlePageDown}
        handleTicketSelection={this.handleTicketSelection}
        closePopup={this.closePopup}
        toggleOptionConfirmation={this.toggleOptionConfirmation}
        confirm={this.confirmOption}
        newUsersRule={this.state.newUsersRule}
        handleNewUsers={this.handleNewUsers}
      />
    );
  }
}

const mapStateToProps = (state) => {
  let getSelectedBuilding = getSelectedBuildingFromRedux(state);
  let getSelectedCommerce = getSelectedCommerceFromRedux(state);
  return { getSelectedBuilding, getSelectedCommerce };
};

const mapDispatchToProps = {
  clearReduxStore,
};

export default connect(mapStateToProps, mapDispatchToProps)(MPosTicketsHistory);
