import React from "react";

const TransfersListHeaderModel = ({ transfers, viewType, className }) => {
  if (transfers.length < 1 || viewType === "phone") return null;
  return (
    <dd className={className}>
      <p>Referencia</p>
      <p>Fecha</p>
      <p>Banco</p>
      <p>Estado</p>
      <p>Monto</p>
    </dd>
  );
};

export default TransfersListHeaderModel;
