import React, { useContext } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Button } from "components/buttons";
import { general as texts } from "assets/strings/texts";
import { WalletTransferHistoryContext } from "../../context";
import { useDates } from "hooks/use-inputs";
import { Dropdown } from "components/dropdown";
import { useDropdown } from "hooks/use-dropdown";
import { useNoSpecialCharactersTextInput } from "hooks/use-inputs";

export function HistoryFormModel({ phoneView, className }) {
  const accountsOptions = [
    {
      value: "wallet",
      text: "Wallet",
      key: "wallet",
    },
    {
      value: "service",
      text: "Servicio",
      key: "service",
    },
    {
      value: "third-bancamiga",
      text: "Terceros Bancamiga",
      key: "third-bancamiga",
    },
    {
      value: "third-other",
      text: "Terceros otros bancos",
      key: "third-other",
    },
  ];
  const {
    bind: { onChange: onChangeAccount, value: accountSelected },
  } = useDropdown("");

  const {
    state: { loading },
    changeState,
  } = useContext(WalletTransferHistoryContext);

  const onSubmit = (event) => {
    event.preventDefault();
    changeState({
      date_from: fromDate ? fromDate.toISOString() : fromDate,
      date_to: endDate ? endDate.toISOString() : endDate,
      transaction_type: accountSelected,
      submit: true,
    });
  };

  const { fromDate, endDate, handleFromDate, handleEndDate } = useDates(
    null,
    null
  );

  const {
    bind: { onChange: onChangeRef, value: ref },
  } = useNoSpecialCharactersTextInput("");
  const submitDisabled = !accountSelected || loading;

  return (
    <form className={className} onSubmit={onSubmit}>
      <p className="title"> Rango de fechas:</p>
      <div className={`collapsed false`}>
        <div className="date-pickers">
          <DatePicker
            className="date-picker"
            placeholderText={texts.from}
            dateFormat={texts.shortDateFormat}
            showMonthDropdown
            showYearDropdown
            todayButton={texts.today}
            isClearable
            withPortal={phoneView}
            selected={fromDate}
            maxDate={endDate ? moment(endDate).subtract(23, "hours") : moment()}
            onChange={handleFromDate}
          />
          <p>/</p>
          <DatePicker
            className="date-picker"
            placeholderText={texts.to}
            dateFormat={texts.shortDateFormat}
            popperPlacement="bottom-end"
            showMonthDropdown
            showYearDropdown
            todayButton={texts.today}
            isClearable
            withPortal={phoneView}
            selected={endDate ? moment(endDate).subtract(23, "hours") : null}
            minDate={fromDate ? moment(fromDate).add(23, "hours") : null}
            maxDate={moment()}
            onChange={handleEndDate}
          />
        </div>
      </div>
      <p className="title"> Seleccione tipo de transferencia:</p>
      <Dropdown
        fluid
        floating
        selection
        id="account"
        value={accountSelected}
        onChange={onChangeAccount}
        options={accountsOptions}
      />
      <div className="reference-input">
        <label htmlFor="ref">Referencia:</label>
        <input
          id="ref"
          type="text"
          onChange={onChangeRef}
          value={ref}
          placeholder="49861993"
        />
      </div>
      <Button color="grey" disabled={submitDisabled}>
        Buscar
      </Button>
    </form>
  );
}
