import { ConfirmationModel } from "./model";
import styled from "styled-components";

const Confirmation = styled(ConfirmationModel)`
  #root & {
    margin: 0;
    margin-top: 1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export { Confirmation };
