import { superadminCommerceTransactions } from "services/api";
import { errors } from "assets/strings/texts";

const formatRequestData = (state) => {
  const {
    from_date,
    end_date,
    type,
    currency,
    transaction_tag,
    counterpart_id,
    commerce_counterpart_id,
  } = state.searchParams;

  const customer_id = state.selectedCommerce._id;
  const { limit, skip } = state.pagination;
  return {
    from_date,
    end_date,
    limit,
    skip,
    type,
    currency,
    transaction_tag,
    customer_id,
    counterpart_id,
    commerce_counterpart_id,
  };
};

export const getMovementsRequest = async (
  state,
  changeState,
  throwPopupMessage
) => {
  try {
    changeState({ loading: true });
    const payload = formatRequestData(state);
    const response = await superadminCommerceTransactions(payload);
    const res = await response.json();
    if (res.error) throw res.error;
    const nextPageExist = res.transactions.length > state.movementsPerPage;

    changeState({
      pagination: {
        ...state.pagination,
        nextPageExist,
      },
      movements: nextPageExist
        ? [...res.transactions.slice(0, state.movementsPerPage)]
        : [...res.transactions],
      loading: false,
      getMovements: false,
    });
  } catch (error) {
    console.log({ error });
    changeState({
      loading: false,
      getMovements: false,
    });
    if (
      error.id === "NO_TOKEN_PROVIDED" ||
      error.id === "NO_TOKEN_PROVIDED" ||
      error.id === "DUPLICATE_SESSION"
    ) {
      throwPopupMessage("error", errors.AUTHENTICATION);
    } else {
      throwPopupMessage(
        "error",
        error.message ||
          error.descrption ||
          "Ha ocurrido un error intente más tarde"
      );
    }
  }
};
