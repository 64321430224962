import React, { useEffect } from "react";
import { connect } from "react-redux";
import { SupportView } from "./components/mpos-support-view";
import { useStateManager } from "hooks/use-state-manager";
import { usePhoneInput, useTextInput } from "hooks/use-inputs";
import { usePopupMessages } from "hooks/use-popup-messages";
import { getConstants, updateSupportInfo } from "services/api";
import { addOfficeNumbers } from "redux/actions/session";
import { clearReduxStore } from "redux/actions/general";

/* 
    {
    "support_info": {
        "email": "contacto.pagodirecto@gmail.com",
        "office_numbers": ["04123667246", "04241325221"],
        "attention_hours": {
            "days": "",
            "hours": ""
        },
        "whatsapp_number": "04123667246"
    }
}
  */
//04241325220
const MPosSupport = ({ addOfficeNumbers }) => {
  const initialState = {
    viewType: "",
    submit: false,
    officeNumbers: [],
    officeNumberSelected: null,
    officeNumberSelectedIndex: null,
    attentionHours: {
      days: "",
      hours: "",
    },
    addNewOfficeNumber: false,
    loading: false,
    editNumber: false,
  };

  const [state, changeState] = useStateManager(initialState);
  const {
    setValue: setWhatsappNumber,
    bind: { value: whatsappNumber, onChange: handleChangeWhatsappNumber },
  } = usePhoneInput("");

  const {
    setValue: setEmail,
    bind: { value: email, onChange: handleChangeEmail },
  } = useTextInput("");

  const {
    popupMessages,
    popupMessagesType,
    showPopup,
    throwPopupMessage,
    closePopup,
  } = usePopupMessages(null, "", false);

  const handleChangeOfficeNumberInput = (event) => {
    let phone = event.target.value.replace(/[^0-9\s]/g, "");
    phone = phone.replace(" ", "");
    changeState({
      officeNumberSelected: phone,
    });
  };

  const handleOfficeNumberSelection = (event) => {
    let officeNumberSelectedIndex = event.currentTarget.id;
    let officeNumberSelected = state.officeNumbers[officeNumberSelectedIndex];
    changeState({
      officeNumberSelected,
      officeNumberSelectedIndex,
      editNumber: true,
    });
    throwPopupMessage("", "");
  };

  const toggleEditNumber = (event) => {
    event.preventDefault();
    let officeNumbers = [...state.officeNumbers];
    officeNumbers[state.officeNumberSelectedIndex] = state.officeNumberSelected;
    changeState({ officeNumbers });
    closePopup();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    changeState({ submit: true });
  };

  useEffect(() => {
    const constants = async () => {
      try {
        changeState({ loading: true });
        const response = await getConstants();
        const res = await response.json();
        changeState({ loading: false });
        if (res.error) throw res.error;

        if (res.support_info) {
          let {
            email: constantEmail,
            office_numbers: officeNumbers,
            whatsapp_number: constantWhatsappNumber,
            attention_hours: attentionHours,
          } = res.support_info;
          setWhatsappNumber(constantWhatsappNumber);
          setEmail(constantEmail);
          changeState({
            officeNumbers,
            attentionHours: attentionHours
              ? attentionHours
              : { days: "", hours: "" },
          });
          addOfficeNumbers(officeNumbers);
        }
      } catch (error) {
        changeState({ ...initialState });
        throwPopupMessage(
          "error",
          "Lo sentimos, ha ocurrido un error. Intente más tarde"
        );
        console.log(error);
      }
    };
    constants();
  }, []);

  useEffect(() => {
    const changeSupport = async () => {
      const support_info = {
        email,
        office_numbers: state.officeNumbers,
        whatsapp_number: whatsappNumber,
        attention_hours: state.attentionHours,
      };
      try {
        changeState({ loading: true });
        const response = await updateSupportInfo({ support_info });
        const res = await response.json();
        if (res.error) throw res.error;
        changeState({ loading: false });
        if (res.support_info) {
          let {
            email: constantEmail,
            office_numbers: officeNumbers,
            whatsapp_number: constantWhatsappNumber,
          } = res.support_info;
          setWhatsappNumber(constantWhatsappNumber);
          setEmail(constantEmail);
          changeState({ officeNumbers, editNumber: false });
          throwPopupMessage("success", "Cambios guardados con éxito");
        }
      } catch (error) {
        changeState({ ...initialState });
        throwPopupMessage(
          "error",
          "Lo sentimos, ha ocurrido un error. Intente más tarde"
        );
        console.log(error);
      }
    };

    if (state.submit) {
      changeState({ submit: false });
      changeSupport();
    }
  }, [state.submit]);

  const customClosePopup = () => {
    if (state.editNumber) changeState({ editNumber: false });
    closePopup();
  };
  return (
    <SupportView
      editNumber={state.editNumber}
      loading={state.loading}
      officeNumbers={state.officeNumbers}
      whatsappNumber={whatsappNumber}
      email={email}
      handleChangeWhatsappNumber={handleChangeWhatsappNumber}
      handleChangeEmail={handleChangeEmail}
      handleOfficeNumberSelection={handleOfficeNumberSelection}
      officeNumberSelected={state.officeNumberSelected}
      handleChangeOfficeNumberInput={handleChangeOfficeNumberInput}
      toggleEditNumber={toggleEditNumber}
      handleSubmit={handleSubmit}
      popupMessages={popupMessages}
      popupMessagesType={popupMessagesType}
      showPopup={showPopup}
      closePopup={customClosePopup}
    />
  );
};

const mapStateToProps = null;

const mapDispatchToProps = {
  addOfficeNumbers,
  clearReduxStore,
};

export default connect(mapStateToProps, mapDispatchToProps)(MPosSupport);
