import styled from 'styled-components'
import { RechargeFormModel } from './model'
import { grey_6 } from 'assets/strings/colors'

export const RechargeForm = styled(RechargeFormModel)`

  #root &.form-appear {
    max-height: 0;
  }
  #root &.form-appear-active {
    max-height: 2000px;
    transition: max-height 700ms linear;
  }
  #root &.form-enter {
    max-height: 0;
  }
  #root &.form-enter-done {
    max-height: 2000px;
    transition: max-height 700ms linear;
  }

  #root &{
    >.title{
      font-size: .85rem;
      border-bottom: 1px solid #5B5B5B;
      display: inline-block;
      text-transform: uppercase;
      font-weight: bold;
      color: #5B5B5B;
      margin-bottom: 0;
    }
    >.username{
      color: #63c0e8;
      font-size: 1.5rem;
      margin: .1rem;
      text-align: center;
    }
    .text{
      color: ${grey_6};
      font-size: 1.1rem;
      margin: .1rem;
      text-align: center;
    }
    .input-section{
      margin: 1.5rem 0 .5rem 0;
      >label{
        font-size: .85rem;
        border-bottom: 1px solid #5B5B5B;
        display: inline-block;
        text-transform: uppercase;
        font-weight: bold;
        color: #5B5B5B;
        margin-bottom: .5rem;
      }

      >input {
        border: none;
        width: 100%;
        border-radius: 1rem;
        color: ${grey_6};
        font-size: 1.5rem;
        margin: 1rem 0 .2rem 0;
        text-align: center;
        outline: none;
        box-shadow: none;
        -webkit-transition: box-shadow 250ms linear;
        transition: box-shadow 250ms linear;
        :focus{
          box-shadow: 0 1px darkgrey;
        }
        ::-webkit-input-placeholder{
          color: lightgray;
        }
        :-ms-input-placeholder{
          color: lightgray;
        }
        ::-ms-input-placeholder{
          color: lightgray;
        }
        ::placeholder{
          color: lightgray;
        }
      }
    }
  }
`