import { RowModel } from "./model";
import styled from "styled-components";
import { grey_6 } from "assets/strings/colors";

const Row = styled(RowModel)`
  #root && {
    margin-top: 1.2rem;
    > .row-parking {
      display: flex;
      ${(props) => {
        return props.phoneView ? "flex-direction: column;" : "";
      }};
      ${(props) => {
        return props.phoneView ? "width: 100%; align-items: center;" : "";
      }};
      position: relative;
      justify-content: flex-end;
      z-index: 1;
      > .center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: ${(props) => {
          return props.phoneView ? "250px" : "220px";
        }};
        margin-left: ${(props) => {
          return props.phoneView ? "0" : "1rem";
        }};
        ${(props) => {
          return props.phoneView ? "margin-top: 1rem;" : "";
        }};
        background: white;
        border-radius: 0.4rem;
        box-shadow: 1px 1px 4px 0 rgba(34, 36, 38, 0.4);
        width: 97%;

        > .selectors {
          margin: 0;
          padding-top: 0.5rem;
          width: 95%;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          > .periods {
            width: 50%;
            display: flex;
            > .box {
              display: flex;
              justify-content: center;
              width: 50%;
              :hover {
                cursor: pointer;
              }
            }
            > .box-selected {
              width: 50%;
              display: flex;
              justify-content: center;
              border-radius: 0.3rem;
              background: ${grey_6};
              color: white;
              font-weight: bold;
            }
          }

          > .title {
            font-size: 1.1rem;
            color: #5b5b5b;
            text-transform: uppercase;
            font-weight: bold;
            width: 50%;
          }
        }
        > .title {
          padding-top: 0.2rem;
          font-size: 1.1rem;
          color: #5b5b5b;
          text-transform: uppercase;
          font-weight: bold;
        }
      }
    }
    > .row-users {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      z-index: 1;
      width: 100%;
      height: calc(72.9vh-4rem);
      margin-left: 0.5rem;

      > .pie {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        background: white;
        height: ${(props) => {
          return props.phoneView ? "40vh" : "30vh";
        }};
        border-radius: 0.4rem;
        box-shadow: 1px 1px 4px 0 rgba(34, 36, 38, 0.4);
        width: 100%;
        margin-bottom: 0.5rem;
      }
      > .bar {
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        border-radius: 0.4rem;
        height: ${(props) => {
          return props.phoneView ? "40vh" : "30vh";
        }};
        box-shadow: 1px 1px 4px 0 rgba(34, 36, 38, 0.4);
        width: 100%;
      }
    }
    .row.row-appear {
      opacity: 0;
      transform: translateY(30px);
    }
    .row.row-appear-active {
      opacity: 0.8;
      transform: translateY(-2px);
      transition: all 350ms ease-out;
    }
    .row.row-enter {
      opacity: 0;
      transform: translateY(30px);
    }
    .row.row-enter-active {
      opacity: 0.8;
      transform: translateY(-2px);
      transition: all 300ms ease-out;
    }
    .row.row-enter-done {
      opacity: 1;
      transform: translateY(0);
      transition: all 50ms ease-out;
    }
    .row.row-exit {
      opacity: 0.8;
      transform: translateY(2px);
    }
    .row.row-exit-active {
      opacity: 0;
      transform: translateY(-30px);
      transition: all 350ms ease-out;
    }
  }
`;

export { Row };
