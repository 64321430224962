import React from "react";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";
import { Panel } from "components/panel";

const TransitionPanelModel = ({ show, children, className }) => {
  if (!show) return null;
  return (
    <div className={className}>
      <CSSTransition
        in={show && true}
        appear={show && true}
        mountOnEnter
        unmountOnExit
        timeout={350}
        classNames="panel"
      >
        <Panel>{children}</Panel>
      </CSSTransition>
    </div>
  );
};

const TransitionPanel = styled(TransitionPanelModel)`
  #root & {
    margin: 0;
    width: calc(100% - 1rem);
  }
`;

export default TransitionPanel;
