import React from "react";

export const BlueInputModel = ({
  label,
  placeholder,
  name,
  value,
  onChange,
  disabled,
  className,
}) => {
  return (
    <section className={className}>
      <label htmlFor={name}>{label}</label>
      <input
        type="text"
        name={name}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
      />
    </section>
  );
};
