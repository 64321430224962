import React from "react";
import { IconBack } from "./styles";
import { Icon } from "semantic-ui-react";

export const IconBackModel = ({ handleBack, show = true, className }) => {
  if (!show) return null;
  return <Icon name="arrow left" onClick={handleBack} className={className} />;
};

export const IconBackContentModel = ({
  handleBack,
  show = true,
  className,
}) => (
  <div className={className}>
    <IconBack handleBack={handleBack} show={show} />
  </div>
);
