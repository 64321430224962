import styled from "styled-components";
import {
  MovementListItemModel,
  MovementPhoneListItemModel,
  MovementListHeaderModel,
  NoMovementFoundModel,
} from "./model";
import { grey_1, grey_4 } from "assets/strings/colors";

export const MovementListItem = styled(MovementListItemModel)`
  #root & {
    margin: 0;
    padding: 0.7rem;
    cursor: pointer;
    border-radius: 1rem;
    border-bottom: 1px solid ${grey_4};
    font-size: 0.9rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    :hover {
      background: ${grey_1};
    }

    > .period-section {
      padding: 0 0.5rem 0 0.5rem;
      width: 30%;

      > p {
        > span {
          text-transform: uppercase;
          font-weight: bold;
        }

        line-height: 2rem;
        margin: 0.1rem 0 0.1rem 0;
      }
    }

    > .amount {
      padding: 0 0.5rem 0 0.5rem;
      width: 30%;
      margin: 0;
      font-size: 1.2rem;
    }

    > .type {
      padding: 0 0.5rem 0 0.5rem;
      width: 20%;
      margin: 0;
      text-align: center;

      > img {
        height: 2.5rem;
      }

      > .credit {
        text-transform: uppercase;
        font-weight: bold;
        color: #a2c400;
      }

      > .debit {
        text-transform: uppercase;
        font-weight: bold;
        color: #ff0000;
      }
    }

    > .ref {
      padding: 0 0.5rem 0 0.5rem;
      width: 20%;
      margin: 0;
      text-align: center;
    }
  }
`;

export const MovementPhoneListItem = styled(MovementPhoneListItemModel)`
  #root & {
    margin: 0;
    padding: 0.7rem;
    font-size: 0.85rem;
    cursor: pointer;
    border-radius: 1rem;
    border-bottom: 1px solid ${grey_4};

    :hover {
      background: ${grey_1};
    }

    > .title {
      display: inline-block;
      margin-bottom: 0.3rem;
      text-transform: uppercase;
      font-size: 1.1rem;
      border-bottom: 1px solid ${grey_4};
    }

    .type {
      float: right;
      height: 2rem;
    }

    > p {
      margin: 0 0 0.6rem 0;
    }
  }
`;

export const MovementListHeader = styled(MovementListHeaderModel)`
  #root & {
    margin: 0;

    > .col {
      margin: 1.2rem 0.5rem 0 0.5rem;
      padding-bottom: 0.3rem;
      display: inline-block;
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
      border-bottom: 1px solid ${grey_4};
    }

    > .forty {
      width: calc(40% - 1rem);
    }

    > .thirty {
      width: calc(30% - 1rem);
    }

    > .twenty-five {
      width: calc(25% - 1rem);
    }

    > .twenty {
      width: calc(20% - 1rem);
    }
  }
`;

export const NoMovementFound = styled(NoMovementFoundModel)`
  #root & {
    text-align: center;
    font-size: 1.2rem;
    line-height: 62px;
  }
`;
