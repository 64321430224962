import React from "react";
import MPosUsersExtensionsView from "./components/mpos-users-extensions-view";
import { UsersExtensionsState } from "./context";

const MPosUsersExtensions = () => {
  return (
    <UsersExtensionsState>
      <MPosUsersExtensionsView />
    </UsersExtensionsState>
  );
};

export default MPosUsersExtensions;
