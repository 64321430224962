import React, { useContext } from "react";
import { WalletAccountsContext } from "../../context";
import Options from "components/options";
import { Dropdown } from "components/dropdown";
import { getOptions } from "../../services";
import { Loader } from "components/loader";

export const AccountsInfoModel = ({ className }) => {
  const { walletAccounts, state, changeState, loadingAccounts } = useContext(
    WalletAccountsContext
  );
  const onChangeAccountOption = (event, data) => {
    const account = walletAccounts.find(
      (wAccount) => wAccount._id === data.value
    );
    changeState({
      accountSelected: data.value,
      accountOptions: getOptions(account),
      getMovements: true,
      viewState: "default",
      detailToShow: null,
    });
  };
  const onChangeOption = (event, data) => {
    setTimeout(() => {
      changeState({
        viewState: "detail",
        detailToShow: data,
      });
    }, 300);
  };
  if (loadingAccounts) return <Loader />;
  return (
    <div className={className}>
      <div className="header">
        <p>Cuentas Pagodirecto</p>
      </div>
      <div className="selection-options">
        <Dropdown
          options={state.accountsMappedInOptions}
          onChange={onChangeAccountOption}
          value={state.accountSelected}
          selection
          fluid
          placeholder="Seleccione una cuenta"
        />
        <div className="w-20">
          <Options
            options={state.accountOptions}
            onChange={onChangeOption}
            vertical
          />
        </div>
      </div>
    </div>
  );
};
