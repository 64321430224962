import { TransferDetailModel } from "./model";
import styled from "styled-components";

export const TransferDetail = styled(TransferDetailModel)`
  #root & {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;