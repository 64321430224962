import { useState, useEffect } from "react";
import { priceToRequest } from "services/utils";
import { postTransfer } from "../services";

const getBodyFromState = (state) => {
  const { issuer_id, transfer_user_id, amount, concept, receiver_commerce } =
    state;
  return {
    issuer_id,
    transfer_user_id,
    amount: priceToRequest(amount),
    concept,
    receiver_commerce,
  };
};

const useTransfer = ({
  initialState,
  state,
  changeState,
  throwPopupMessage,
  setFetchAccounts,
  changeGlobalState,
}) => {
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    if (fetching) {
      const body = getBodyFromState(state);
      postTransfer(
        body,
        initialState,
        changeState,
        throwPopupMessage,
        setFetchAccounts,
        changeGlobalState
      );
      setFetching(false);
    }
  }, [fetching]);

  return setFetching;
};

export default useTransfer;
