import React, { useContext } from "react";
import moment from "moment";
import { DateForm } from "components/date-form";
import { MPremiumHistoryContext } from "../../context";
import { Button } from "components/buttons";
import { Dropdown } from "components/dropdown";

export const HistoryFormModel = ({ className }) => {
  const { viewType, state, changeState, searchParam, handleSearchInputChange } =
    useContext(MPremiumHistoryContext);

  const handleGetHistory = (event) => {
    event.preventDefault();
    changeState({ getActiveBundles: true });
  };

  const handleFromDate = (date) => {
    changeState({ from_date: date });
  };

  const handleEndDate = (date) => {
    changeState({
      end_date: date ? moment(date).add(23, "hours") : date,
    });
  };

  const handleSearchTypeButtons = (event) => {
    event.preventDefault();
    changeState({ searchType: event.target.value });
  };
  const handleSelectionDrop = (event, data) => {
    changeState({ bundle_title: data.value });
  };
  if (state.formLoading) {
    return (
      <div style={{ height: "25vh" }}>
        <div
          className="ui active loader"
          style={{
            marginTop: "3rem",
            position: "relative",
          }}
        />
      </div>
    );
  }

  return (
    <div className={className}>
      <div className="selectors">
        <p>
          Filtrar
          <br /> busqueda por:
        </p>
        <Button
          color={state.searchType === "dateRange" ? "blue" : "grey_1"}
          pressed={state.searchType === "dateRange" ? true : false}
          value="dateRange"
          onClick={handleSearchTypeButtons}
        >
          fecha
        </Button>
        <Button
          color={state.searchType === "user" ? "blue" : "grey_1"}
          pressed={state.searchType === "user" ? true : false}
          value="user"
          onClick={handleSearchTypeButtons}
        >
          usuario
        </Button>
      </div>
      {state.searchType === "dateRange" && (
        <DateForm
          phoneView={viewType === "phone"}
          loading={state.bundlesLoading}
          handleFromDate={handleFromDate}
          fromDate={state.from_date}
          endDate={state.end_date}
          handleEndDate={handleEndDate}
        />
      )}
      {state.searchType === "user" && (
        <div
          className={`ui transparent labeled input ${
            state.searchType === "dateRange" ? "hidden" : ""
          }`}
        >
          <i aria-hidden="true" className="search icon label"></i>
          <input
            placeholder="Nombre de usuario"
            type="text"
            value={searchParam}
            onChange={handleSearchInputChange}
          />
        </div>
      )}
      <div className="selection">
        <p className="label">Título</p>
        <Dropdown
          placeholder="Paquete premium"
          fluid
          multiple
          search
          selection
          id="bundlePacket"
          onChange={handleSelectionDrop}
          options={state.premiumBundlesOptions}
        />
      </div>
      <div className="search">
        <button onClick={handleGetHistory}>Buscar</button>
      </div>
    </div>
  );
};
