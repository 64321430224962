import styled from "styled-components";
import { HistoryModel } from "./model";

export const History = styled(HistoryModel)`
  #root & {
    width: 100%;
    padding: 0rem;
    margin: 0;
    height: calc(60vh - 2rem);
    overflow-x: hidden;
  }
`;
