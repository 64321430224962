import React, { PureComponent } from "react";
// import moment from 'moment'
import { connect } from "react-redux";
import {
  debounce,
  inputPrice,
  priceToRequest,
  formatPrice,
} from "services/utils";
import { errors } from "assets/strings/texts";
import { clearReduxStore } from "redux/actions/general";
import { recharges_recharge as viewTexts } from "assets/strings/texts";
import { throwPopupMessage, closePopup } from "components/popup-message";
import MposRechargesRechargeView from "./components/recharges-recharge-view";
import { usersAutocomplete, getConstants, postRecharge } from "services/api";
import {
  element_transition_time,
  popup_transition_time,
} from "assets/strings/constants";
import MposRechargesRechargePhoneView from "./components/recharges-recharge-phone-view";

class MPosRechargesRecharge extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      viewState: "user_search",
      searchParam: "",
      users: [],
      selectedUser: null,
      showRechargeForm: false,

      // New testing things, for other amount to recharge users funds
      showOtherAmountField: false,
      dropdownValueToShow: "",

      desiredRechargeAmount: "",
      rechargeRef: "",
      bankNameSelected: "",
      showGeneratedRecharge: false,
      // viewType: 'phone' | is set when component mounts
      popupMessages: null,
      popupMessagesType: "",
      showPopup: false,
    };
    // ========== INSTANCE PROPERTIES ==========
    this.initialState = { ...this.state };
    this.rechargeAmounts = [];
    this.bankList = [];
    // ========== BIND POPUP FUNCTIONS ==========
    this.throwPopupMessage = throwPopupMessage.bind(this);
    this.closePopup = closePopup.bind(this);
  }

  // =============== STATE DOCS ===============
  /*

--> user_search
  { ...this.initialState }
  Error messages will be shown here

--> user_search_loading
  { ...this.initialState }
  or
  {
    ...this.this.initialState,
    searchParam: String
  }

--> user_search_done
  {
    searchParam: String,
    users: [{one or more}]
  }

--> user_selection_done
  {
    searchParam: String,
    users: [{one or more}],
    selectedUser: {object},
    showRechargeForm: true,
    desiredRechargeAmount: String,
  }

--> recharge_confirmation
  {
    searchParam: String,
    tickets: [{one or more}],
    selectedUser: {object},
    showRechargeForm: true,
    desiredRechargeAmount: String,
    popupMessages: [<RechargeConfirmation/>],
    popupMessagesType: 'alert',
    showPopup: true
  }

--> recharge_loading
  {
    searchParam: String,
    tickets: [{one or more}],
    selectedUser: {object},
    showRechargeForm: true,
    desiredRechargeAmount: String,
    popupMessages: [<RechargeConfirmation/>],
    popupMessagesType: 'alert',
    showPopup: true
  }

--> recharge_done
  {
    searchParam: String,
    tickets: [{one or more}],
    selectedUser: {object},
    showRechargeForm: true,
    desiredRechargeAmount: String,
    popupMessages: null,
    popupMessagesType: '',
    showPopup: false,
    showGeneratedRecharge: true
  }
*/
  // =============== END STATE DOCS ===============

  /*
   ** mpos-recharges-recharge on Mount:
   ** --> get recharge amounts
   ** --> set viewType
   ** --> Set 'resize' event listener
   */
  componentDidMount() {
    // --> get recharge amounts
    getConstants()
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((res) => {
        if (res.RECHARGE_AMOUNTS_FRONT) {
          this.rechargeAmounts = res.RECHARGE_AMOUNTS_FRONT.map((amount) => ({
            text: "" + formatPrice(amount),
            value: formatPrice(amount),
            key: formatPrice(amount),
          }));
        }
        if (res.bank_receiving_accounts) {
          this.bankList = res.bank_receiving_accounts.map((bankName) => ({
            text: bankName,
            value: bankName,
            key: bankName,
          }));
        }
      })
      .catch((err) => {
        // If error has a body, check the response
        if (typeof err.json === "function") {
          err.json().then((err_body) => {
            // If it is an authentication error, clear the redux-store to close the session
            if (
              err_body.error.id === "NO_TOKEN_PROVIDED" ||
              err_body.error.id === "AUTHENTICATE_FAILED" ||
              err_body.error.id === "DUPLICATE_SESSION"
            ) {
              //this.props.clearReduxStore();
              this.throwPopupMessage("alert", errors.AUTHENTICATION);
              return; // prevent setState exeution on unmounted component
            }
            this.setState({ ...this.initialState });
            this.throwPopupMessage("error", err_body.error.description);
          });
        } else {
          this.setState({ ...this.initialState });
          this.throwPopupMessage("error", errors.GENERAL_ERR);
        }
      });
    // --> Set viewType
    this.setViewType();
    // --> Set 'resize' event listener
    window.addEventListener("resize", this.setViewType);
    document.addEventListener("keydown", this.onEnter);
  }

  // Remove event listeners
  componentWillUnmount() {
    window.removeEventListener("resize", this.setViewType);
    document.removeEventListener("keydown", this.onEnter);
  }

  // ========== METHODS ==========
  // 1)
  handleSearchInputChange = (event) => {
    let value = event.target.value;
    // The field is restrcted to accept only letters, numbers, @(first position) and _-.
    if (value.match(/^(?=@?.*)@?[\w.-]*$/)) {
      value = value.toLowerCase();

      this.setState({
        ...this.initialState,
        //viewState: "user_search_loading",
        searchParam: value,
      });
      // call the API in a debounced way
      //this.debouncedApiCall(value);
    }
  };

  // 2) function disabled for now
  debouncedApiCall = debounce((value) => {
    // remove the @ from the first position before using it in the request
    value = value[0] === "@" ? value.substr(1) : value;
    // call the API
    let usersPromise = usersAutocomplete(value);
    // Manage the response
    this.handleUsersSearchPromise(usersPromise);
    // Timer
  }, 600);

  // 3)
  handleUsersSearchPromise = (promise) => {
    promise
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((res) => {
        if (res.users) {
          // else, print the tickets
          this.setState((prevState) => ({
            ...this.initialState,
            searchParam: prevState.searchParam,
            viewState: "user_search_done",
            users: res.users,
          }));
        }
      })
      .catch((err) => {
        // If error has a body, check the response
        if (typeof err.json === "function") {
          err.json().then((err_body) => {
            // If it is an authentication error, clear the redux-store to close the session
            if (
              err_body.error.id === "NO_TOKEN_PROVIDED" ||
              err_body.error.id === "AUTHENTICATE_FAILED" ||
              err_body.error.id === "DUPLICATE_SESSION"
            ) {
              this.throwPopupMessage("alert", errors.AUTHENTICATION);
              //this.props.clearReduxStore();
              return; // prevent setState exeution on unmounted component
            }
            this.setState({ ...this.initialState });
            this.throwPopupMessage("error", err_body.error.description);
          });
        } else {
          this.setState({ ...this.initialState });
          this.throwPopupMessage("error", errors.GENERAL_ERR);
        }
      });
  };

  // 4)
  handleUserSelection = (event) => {
    if (!this.rechargeAmounts.length) {
      this.throwPopupMessage("error", errors.MISSING_REQUIRED_DATA);
    } else {
      let index = event.currentTarget.id;
      let selectedUser = this.state.users[index];
      this.setState((prevState) => ({
        ...this.initialState,
        viewState: "user_selection_done",
        searchParam: prevState.searchParam,
        users: prevState.users,
        showRechargeForm: true,
        selectedUser,
      }));
    }
  };

  // 5)
  removeSelectedUser = (event) => {
    event.preventDefault();
    this.setState({
      showRechargeForm: false,
    });
    setTimeout(() => {
      this.setState({
        viewState: "user_search_done",
        selectedUser: null,
      });
    }, element_transition_time);
  };

  // 6)
  handleAmountSelect = (event, data) => {
    data.value === "Otro monto"
      ? this.setState({
          showOtherAmountField: true,
          dropdownValueToShow: data.value,
        })
      : this.setState({
          showOtherAmountField: false,
          dropdownValueToShow: data.value,
          desiredRechargeAmount: data.value,
        });
  };

  // 7)
  handleBankSelect = (event, data) => {
    this.setState({
      bankNameSelected: data.value,
      //desiredRechargeAmount: data.value,
    });
  };

  // Manage input of other amount field selected...
  handleOtherAmount = (e) => {
    let amount = e.target.value.replace(/[^0-9\s]/g, "");
    let desiredRechargeAmount = inputPrice(amount.replace(" ", ""));
    this.setState({ desiredRechargeAmount });
  };

  handleReference = (e) => {
    let rechargeRef = e.target.value;
    this.setState({ rechargeRef });
  };

  // 7)
  toggleRechargeConfirmation = (event) => {
    event.preventDefault();
    if (
      this.state.viewState === "recharge_confirmation" ||
      this.state.viewState === "recharge_loading"
    ) {
      this.closePopup();
      // timeout the update for the animation
      setTimeout(() => {
        this.setState({ viewState: "user_selection_done" });
      }, popup_transition_time);
    }
    if (this.state.viewState === "user_selection_done") {
      if (!this.state.desiredRechargeAmount) {
        this.throwPopupMessage("alert", viewTexts.noAmountSelected);
      } else {
        this.setState({ viewState: "recharge_confirmation" });
        // timeout the update for the animation
        setTimeout(() => {
          this.throwPopupMessage("alert", viewTexts.rechargeConfirmation);
        }, popup_transition_time);
      }
    }
  };

  // 11)
  recharge = () => {
    this.setState({ viewState: "recharge_loading" });
    const {
      selectedUser,
      desiredRechargeAmount,
      rechargeRef,
      bankNameSelected,
    } = this.state;
    postRecharge(
      selectedUser.username,
      selectedUser._id,
      rechargeRef,
      bankNameSelected,
      priceToRequest(desiredRechargeAmount)
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((res) => {
        if (this.state.viewType === "phone") {
          this.setState({ showRechargeForm: false });
        }
        this.closePopup();
        setTimeout(() => {
          this.setState({
            viewState: "recharge_done",
            showGeneratedRecharge: true,
          });
        }, popup_transition_time);
      })
      .catch((err) => {
        // If error has a body, check the response
        if (typeof err.json === "function") {
          err.json().then((err_body) => {
            // If it is an authentication error, clear the redux-store to close the session
            if (
              err_body.error.id === "NO_TOKEN_PROVIDED" ||
              err_body.error.id === "AUTHENTICATE_FAILED" ||
              err_body.error.id === "DUPLICATE_SESSION"
            ) {
              //this.props.clearReduxStore();
              this.throwPopupMessage("alert", errors.AUTHENTICATION);
              return; // prevent setState exeution on unmounted component
            }
            this.setState({ ...this.initialState });
            this.throwPopupMessage("error", err_body.error.description);
          });
        } else {
          this.setState({ ...this.initialState });
          this.throwPopupMessage("error", errors.GENERAL_ERR);
        }
      });
  };

  // 12)
  resetView = () => {
    this.setState({
      showGeneratedRecharge: false,
      showRechargeForm: false,
    });
    setTimeout(() => {
      this.setState({ ...this.initialState });
    }, element_transition_time);
  };

  // 13)
  setViewType = () => {
    // TODO: Re-implement
    let documentElement = document.documentElement,
      width = window.innerWidth || documentElement.clientWidth;
    if (width < 768) {
      if (this.state.viewState === "recharge_done") {
        this.setState({
          viewType: "phone",
          showRechargeForm: false,
        });
      } else {
        this.setState({ viewType: "phone" });
      }
    } else {
      if (this.state.viewState === "recharge_done") {
        this.setState({
          viewType: "desktop",
          showRechargeForm: true,
        });
      } else {
        this.setState({ viewType: "desktop" });
      }
    }
  };

  //new handler
  handleSearchUser = (event) => {
    event.preventDefault();
    // search input
    let usersPromise = usersAutocomplete(this.state.searchParam);
    // Manage the response
    this.setState({
      viewState: "user_search_loading",
    });
    this.handleUsersSearchPromise(usersPromise);
  };

  onEnter = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      this.handleSearchUser(event);
    }
  };

  render() {
    if (this.state.viewType === "phone") {
      return (
        <MposRechargesRechargePhoneView
          // Props
          viewType={this.state.viewType}
          viewState={this.state.viewState}
          searchParam={this.state.searchParam}
          users={this.state.users}
          showRechargeForm={this.state.showRechargeForm}
          showOtherAmountField={this.state.showOtherAmountField}
          dropdownValueToShow={this.state.dropdownValueToShow}
          selectedUser={this.state.selectedUser}
          rechargeAmounts={this.rechargeAmounts}
          bankList={this.bankList}
          rechargeRef={this.state.rechargeRef}
          bankNameSelected={this.state.bankNameSelected}
          desiredRechargeAmount={this.state.desiredRechargeAmount}
          showGeneratedRecharge={this.state.showGeneratedRecharge}
          popupMessages={this.state.popupMessages}
          popupMessagesType={this.state.popupMessagesType}
          showPopup={this.state.showPopup}
          closePopup={this.closePopup}
          // Methods
          handleSearchInputChange={this.handleSearchInputChange}
          handleUserSelection={this.handleUserSelection}
          handleOtherAmount={this.handleOtherAmount}
          handleBankSelect={this.handleBankSelect}
          removeSelectedUser={this.removeSelectedUser}
          handleAmountSelect={this.handleAmountSelect}
          handleReference={this.handleReference}
          toggleRechargeConfirmation={this.toggleRechargeConfirmation}
          recharge={this.recharge}
          resetView={this.resetView}
          handleSearchUser={this.handleSearchUser}
        />
      );
    }

    return (
      <MposRechargesRechargeView
        // Props
        viewState={this.state.viewState}
        searchParam={this.state.searchParam}
        users={this.state.users}
        bankList={this.bankList}
        showRechargeForm={this.state.showRechargeForm}
        showOtherAmountField={this.state.showOtherAmountField}
        dropdownValueToShow={this.state.dropdownValueToShow}
        bankNameSelected={this.state.bankNameSelected}
        selectedUser={this.state.selectedUser}
        rechargeAmounts={this.rechargeAmounts}
        rechargeRef={this.state.rechargeRef}
        desiredRechargeAmount={this.state.desiredRechargeAmount}
        showGeneratedRecharge={this.state.showGeneratedRecharge}
        popupMessages={this.state.popupMessages}
        popupMessagesType={this.state.popupMessagesType}
        showPopup={this.state.showPopup}
        closePopup={this.closePopup}
        // Methods
        handleSearchInputChange={this.handleSearchInputChange}
        handleUserSelection={this.handleUserSelection}
        handleBankSelect={this.handleBankSelect}
        handleAmountSelect={this.handleAmountSelect}
        handleOtherAmount={this.handleOtherAmount}
        handleReference={this.handleReference}
        toggleRechargeConfirmation={this.toggleRechargeConfirmation}
        recharge={this.recharge}
        resetView={this.resetView}
        handleSearchUser={this.handleSearchUser}
      />
    );
  }
}

const mapStateToProps = null;

const mapDispatchToProps = {
  clearReduxStore,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MPosRechargesRecharge);
