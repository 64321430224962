import React, { useContext } from "react";
import { WalletTransferHistoryContext } from "../../context";
import { useGetWeekendDate } from "../../hooks/useGetWeekendDate";
import { useSetWeekendDate } from "../../hooks/useSetWeekendDate";
import DatePicker from "react-datepicker";
import { general as texts } from "assets/strings/texts";
import { Loader } from "components/loader";
import moment from "moment";
import { Button } from "components/buttons";
import ConfirmChanges from "../confirm-changes";

const WeekendDateModel = ({ className, phoneView }) => {
  const { state, changeState, throwPopupMessage, closePopup } = useContext(
    WalletTransferHistoryContext
  );
  const { loading: getLoading } = useGetWeekendDate();
  const { loading: setLoading } = useSetWeekendDate();
  const onChangeDate = (date) => {
    changeState({
      weekendDate: date,
    });
  };
  const onConfirm = (event) => {
    event.preventDefault();
    closePopup();
    setTimeout(() => {
      changeState({ setWeekendDate: true });
    }, 100);
  };
  const onSubmit = (event) => {
    event.preventDefault();
    throwPopupMessage(
      "alert",
      <ConfirmChanges onCancel={closePopup} onAccept={onConfirm} />
    );
  };

  if (getLoading || setLoading) return <Loader />;
  return (
    <form className={className} onSubmit={onSubmit}>
      <label>Fecha de fin de semana:</label>
      <DatePicker
        className="date-picker"
        placeholderText={texts.from}
        dateFormat={texts.shortDateFormat}
        showMonthDropdown
        showYearDropdown
        todayButton={texts.today}
        isClearable
        withPortal={phoneView}
        selected={state.weekendDate}
        minDate={moment().startOf("day")}
        maxDate={moment().endOf("day").add(1, "week")}
        onChange={onChangeDate}
      />
      <Button
        color="blue"
        width={phoneView ? "30%" : "20%"}
        disabled={!state.weekendDate}
        margin={phoneView ? "1rem 0 0 0" : "0"}
      >
        Cambiar fecha
      </Button>
    </form>
  );
};

export default WeekendDateModel;
