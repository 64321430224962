import React from "react";
import moment from "moment";
import { Button } from "components/buttons";
import { PanelTitle } from "components/panel";
import { general as texts } from "assets/strings/texts";
import { recharge_validate as viewTexts } from "assets/strings/texts";
import { BankDetails } from "../bank-details";
import { TDCDetails } from "../tdc-details";

const RechargeValidationModel = ({
  viewType,
  category,
  recharge,
  rechargesType,
  username,
  handleUsernameChangeRecharge,
  rechargeAmount,
  rechargeReasonsOptions,
  toggleConfirmation,
  handleRechargeAmount,
  removeSelectedRecharge,
  className,
  viewState,
  searchParam,
  users,
  handleSearchInputChange,
  handleUserSelection,
}) => {
  const rechargeApiMapped = {
    old: "API Anterior",
    new: "API Actual",
  };
  const defaultRechargeAPI = "--";
  const rechargeAPI =
    rechargeApiMapped[recharge.api_mobile_payment] || defaultRechargeAPI;

  let paymentDate = moment(recharge.updated_at);
  let rechargeReason = "";
  if (recharge.reason && rechargeReasonsOptions instanceof Array) {
    rechargeReasonsOptions.forEach((reason) => {
      if (reason.value === recharge.reason) {
        rechargeReason = reason.text;
      }
    });
  }
  return (
    <div className={className}>
      <PanelTitle>{viewTexts.rechargeValidationTitle}</PanelTitle>
      <h5 className="title"> {texts.username} </h5>
      {recharge.status === "pending" && recharge.type === "mobile_payment" ? (
        <>
          <input
            type="text"
            placeholder="user"
            className="amount"
            required={true}
            value={username}
            onChange={handleUsernameChangeRecharge}
          />
        </>
      ) : (
        <p className="content">
          {(recharge && recharge.customer && recharge.customer.username) ||
            "--"}{" "}
        </p>
      )}
      {recharge.payment_issuing_phone && (
        <h5 className="title"> {texts.phoneNumber} </h5>
      )}
      {recharge.payment_issuing_phone && (
        <p className="content"> {recharge.payment_issuing_phone} </p>
      )}
      <h5 className="title"> {viewTexts.paymentDateTitle} </h5>
      <p className="content sm">
        {paymentDate.format(texts.shortDateFormat)} <br />
        {paymentDate.format(texts.timeFormat)}
      </p>

      <h5 className="title"> {viewTexts.rechargeStatus} </h5>
      <p className="content sm">
        {texts[recharge.status]}
        {recharge.reason && <br />}
        {recharge.reason && rechargeReason}
      </p>

      <h5 className="title">
        {" "}
        {viewTexts.rechargeAmountTitle} ({recharge.currency}){" "}
      </h5>
      <p className="content sm">{rechargeAmount}</p>

      <h5 className="title">{viewTexts.rechargeAPI}</h5>
      <p className="content sm">{rechargeAPI}</p>

      {recharge.payment_ref && (
        <h5 className="title"> {viewTexts.paymentReferenceTitle} </h5>
      )}
      {recharge.payment_ref && (
        <p className="content sm"> {recharge.payment_ref} </p>
      )}
      {recharge.payment_receiving_bank && (
        <h5 className="title"> {viewTexts.paymentBankTitle} </h5>
      )}
      {recharge.payment_receiving_bank && (
        <p className="content sm"> {recharge.payment_receiving_bank} </p>
      )}

      {recharge.bank_details && (
        <BankDetails
          bankDetails={recharge.bank_details}
          causeRejection={
            recharge.hasOwnProperty("cause_rejection")
              ? recharge.cause_rejection
              : null
          }
        />
      )}
      <TDCDetails tdcDetails={recharge} />

      {!(recharge.status === "approved" || recharge.status === "rejected") &&
        rechargesType !== "TDC" && (
          <Button color="blue" onClick={() => toggleConfirmation("approved")}>
            {viewTexts.validateRecharge}
          </Button>
        )}

      {!(recharge.status === "approved" || recharge.status === "rejected") &&
        category === "pagodirecto" &&
        recharge.type === "bank_transfer" && (
          <Button color="red" onClick={() => toggleConfirmation("rejected")}>
            {viewTexts.rejectRecharge}
          </Button>
        )}

      {viewType === "phone" && (
        <Button color="grey" onClick={removeSelectedRecharge}>
          {texts.back}
        </Button>
      )}
    </div>
  );
};

export { RechargeValidationModel };
