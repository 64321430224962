import React, { useContext } from "react";
import { WalletAccountsContext } from "../../context";
import { MovementsFormNew } from "components/movements-form-new";
import { Link } from "react-router-dom";
import { Button } from "components/buttons";
import {
  formatMovementsList,
  formatMovementsPhoneList,
} from "components/movements-list";
import { EntitiesList, Pagination } from "components/entities-list";
import {
  Panel,
  PanelTitle,
  PanelSectionHeader,
  PanelSectionContent,
} from "components/panel";
import { CSSTransition } from "react-transition-group";
import moment from "moment";
import "moment/locale/es-us";
import { formatPrice } from "services/utils";
import {
  general as text,
  feeTags,
  servicesTags,
  transferTags,
} from "assets/strings/texts";
moment.locale("es-us");

export const MovementsModel = ({ phoneView, className }) => {
  const { state, changeState, transactionTags } = useContext(
    WalletAccountsContext
  );

  const onSubmit = (data) => {
    changeState({ searchParams: { ...data }, getMovements: true });
  };
  const handlePageDown = (event) => {
    event.preventDefault();
    const { pagination } = state;
    const newPagination = {
      ...pagination,
      skip: pagination.skip - 1,
      currentPage: pagination.currentPage - 1,
    };
    changeState({ pagination: newPagination, getMovements: true });
  };

  const handlePageUp = (event) => {
    event.preventDefault();
    const { pagination } = state;
    const newPagination = {
      ...pagination,
      skip: pagination.skip + 1,
      currentPage: pagination.currentPage + 1,
    };
    changeState({ pagination: newPagination, getMovements: true });
  };

  const onMovementSelection = (event) => {
    const movement = state.movements.find(
      (mov) => mov._id === event.currentTarget.id
    );
    changeState({ selectedMovement: null });
    setTimeout(() => {
      changeState({
        selectedMovement: movement,
        viewState: "detail",
        detailToShow: "movement",
      });
    }, 300);
  };
  return (
    <div className={className}>
      <div className="movements-form">
        <MovementsFormNew onSubmit={onSubmit} phoneView={phoneView} />
      </div>
      <EntitiesList
        height="calc(80vh - 232px)"
        maxHeight="60vh"
        viewState={state.loadingMovements}
        entities={state.movements}
        formattingFunction={
          phoneView ? formatMovementsPhoneList : formatMovementsList
        }
        additionalArgs={[transactionTags]}
        selectionHandler={onMovementSelection}
      >
        <Pagination
          padding="0.8rem 0 0.8rem 0"
          nextPageExist={state.pagination.nextPageExist}
          currentPage={state.pagination.currentPage}
          handlePageUp={handlePageUp}
          handlePageDown={handlePageDown}
        />
      </EntitiesList>
    </div>
  );
};

export const MovementDetailModel = ({
  data,
  show,
  transactionTags,
  className,
}) => {
  const currencyMapped = {
    USD: "$ ",
    BS: "VES ",
  };
  if (!data || !show) return null;
  const isPending = data.status === "pending";
  const isWithdraw = data.transaction_tag === "withdraw";
  const transactionTagLabel = transactionTags.find(
    (tag) => tag.value === data.transaction_tag
  );
  return (
    <CSSTransition
      in={data && true}
      timeout={350}
      appear={data && true}
      classNames="panel"
    >
      <Panel>
        <PanelTitle>Detalles de la transacción</PanelTitle>
        <PanelSectionHeader>Descripción:</PanelSectionHeader>
        <PanelSectionContent>{data.description_tag}</PanelSectionContent>
        <PanelSectionContent>{data.description}</PanelSectionContent>

        <PanelSectionHeader>Referencia:</PanelSectionHeader>
        <PanelSectionContent>{data.reference}</PanelSectionContent>
        <PanelSectionHeader>Etiqueta:</PanelSectionHeader>
        <PanelSectionContent>
          {transactionTagLabel
            ? transactionTagLabel.text
            : data.transaction_tag}
        </PanelSectionContent>
        {data.fee_tag && <PanelSectionHeader>Etiqueta FEE:</PanelSectionHeader>}
        {data.fee_tag && (
          <PanelSectionContent>
            {feeTags[data.fee_tag] || "N/A"}
          </PanelSectionContent>
        )}
        {data.service_tag && (
          <PanelSectionHeader>Etiqueta de Servicios:</PanelSectionHeader>
        )}
        {data.service_tag && (
          <PanelSectionContent>
            {servicesTags[data.service_tag] || "N/A"}
          </PanelSectionContent>
        )}
        {data.transfer_tag && (
          <PanelSectionHeader>Etiqueta de tranferencias:</PanelSectionHeader>
        )}
        {data.transfer_tag && (
          <PanelSectionContent>
            {transferTags[data.transfer_tag] || "N/A"}
          </PanelSectionContent>
        )}

        <PanelSectionHeader>Fondos previos:</PanelSectionHeader>
        <PanelSectionContent>
          {currencyMapped[data.currency] || ""}
          {formatPrice(data.previous_funds)}
        </PanelSectionContent>

        <PanelSectionHeader>Monto:</PanelSectionHeader>
        <PanelSectionContent>
          {currencyMapped[data.currency] || ""}
          {formatPrice(data.pd_amount)}
        </PanelSectionContent>

        <PanelSectionHeader>Fondos posteriores:</PanelSectionHeader>
        <PanelSectionContent>
          {currencyMapped[data.currency] || ""}
          {formatPrice(data.funds)}
        </PanelSectionContent>

        <PanelSectionHeader>Contraparte:</PanelSectionHeader>
        <PanelSectionContent>
          {(data.counterpart && data.counterpart.username) ||
            (data.commerce_counterpart && data.commerce_counterpart.name) ||
            "N/A"}
        </PanelSectionContent>

        <PanelSectionHeader>Fecha:</PanelSectionHeader>
        <PanelSectionContent>
          {moment(data.created_at).format(text.shortDateHourFormat)}
        </PanelSectionContent>
        {isPending && isWithdraw && (
          <Link
            to={{
              pathname: "/mpos/wallet/transfer",
              state: {
                withdraw: { ...data, intern: true },
              },
            }}
            className="link"
          >
            <Button color="blue" width="100%">
              Transferir y aprobar
            </Button>
          </Link>
        )}
      </Panel>
    </CSSTransition>
  );
};
