import React from "react";
import { Grid } from "semantic-ui-react";
import PopupMessage from "components/popup-message";
import { RightBorderGreyColumn } from "components/styled-grid-column";
import { CommerceTickets } from "../components/commerce-tickets";
import { SearchCommerces } from "../components/search-commerces";
import { Ticket } from "../components/ticket";

const BuildingCommercesView = ({
  searchBy,
  handleSelectors,
  handleSearch,
  fromDate,
  endDate,
  loading,
  loadingTickets,
  selectedCommerce,
  selectedTicket,
  tickets,
  handleTicketSelection,
  searchParam,
  handleEndDate,
  handleFromDate,
  handleSearchInputChange,
  viewType,
  commerceIsSelected,
  ticketIsSelected,
  viewState,
  commerces,
  handleCommerceSelection,
  //pagination
  handleCommercePageUp,
  handleCommercePageDown,
  currentCommercesPage,
  nextCommercesPageExist,

  handleTicketsPageUp,
  handleTicketsPageDown,
  currentTicketsPage,
  nextTicketsPageExist,
  //popupMessage
  popupMessages,
  popupMessagesType,
  showPopup,
  closePopup,
}) => {
  return (
    <Grid padded>
      <PopupMessage
        messageType={popupMessagesType}
        showPopup={showPopup}
        messages={popupMessages}
        closePopup={closePopup}
      />
      <RightBorderGreyColumn width={5}>
        <SearchCommerces
          viewType={viewType}
          loading={loading}
          searchBy={searchBy}
          fromDate={fromDate}
          endDate={endDate}
          searchParam={searchParam}
          commerces={commerces}
          handleSelectors={handleSelectors}
          handleFromDate={handleFromDate}
          handleEndDate={handleEndDate}
          handleSearchInputChange={handleSearchInputChange}
          handleSearch={handleSearch}
          handlePageUp={handleCommercePageUp}
          handlePageDown={handleCommercePageDown}
          currentPage={currentCommercesPage}
          nextPageExist={nextCommercesPageExist}
          handleSelection={handleCommerceSelection}
        />
      </RightBorderGreyColumn>
      <RightBorderGreyColumn width={6}>
        <CommerceTickets
          loading={loadingTickets}
          isSelected={commerceIsSelected}
          selectedCommerce={selectedCommerce}
          tickets={tickets}
          handleSelection={handleTicketSelection}
          handlePageUp={handleTicketsPageUp}
          handlePageDown={handleTicketsPageDown}
          currentPage={currentTicketsPage}
          nextPageExist={nextTicketsPageExist}
        />
      </RightBorderGreyColumn>
      <Grid.Column width={5}>
        <Ticket isSelected={ticketIsSelected} ticket={selectedTicket} />
      </Grid.Column>
    </Grid>
  );
};

export { BuildingCommercesView };
