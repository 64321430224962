import { CopyToClipboardModel } from "./model";
import styled from "styled-components";
import { grey_6, grey_4 } from "assets/strings/colors";

export const CopyToClipboard = styled(CopyToClipboardModel)`
  #root & {
    padding: 1rem 0 2rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: calc(100% - 1rem);
    color: ${grey_6};
    > p {
      padding: 1rem 0 2rem 0;
      margin: 0;
      font-size: 1rem;
      color: ${grey_6};
      display: flex;
      align-items: center;
      width: 30%;
      > span {
        > .copy-icon {
          display: flex;
          align-items: left;
          float: left;
          color: ${grey_4};
          cursor: pointer;
          z-index: 1;
        }
      }
    }
  }
`;
