import styled from 'styled-components'
import { RechargesFormModel } from './model'
import { grey_4 } from 'assets/strings/colors';

const RechargesForm = styled(RechargesFormModel)`
  #root &{
    padding: 1rem;
    border-bottom: 1px solid ${grey_4};
    >.username-input {
      padding: .5px;
      >div {
        height: 3rem;
        margin-bottom: 1rem;
        border-radius: 1rem;
      }
    }
    >button{
      margin: 0;
    }
    >.label{
      font-size: .9rem;
      margin: 0 0 .6rem 0;
      font-weight: bold;
      text-transform: uppercase;
    }
    >.container{
      min-height: 4rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;
      >button{
        margin: 0 .5rem .6rem .5rem;
      }
    }
    @media (max-width: 768px) {
      >.container{
        flex-wrap: wrap;
        >button{
          width: calc( 50% - 1rem );
        }
      }
    }
  }
`
export { RechargesForm }