import React, { useContext, useEffect } from "react";
import { UsersReportContext } from "../../context";
import { Dropdown } from "components/dropdown";
import { PanelSectionHeader } from "components/panel";
import { services } from "../../services";

const SchoolOptions = ({ className }) => {
  const { state, changeState } = useContext(UsersReportContext);

  useEffect(() => {
    const getSchools = async () => {
      try {
        const { res, ok } = await services.commerces();
        if (!ok) throw res;
        const schoolOptions = res.commerces.map((school) => ({
          value: school._id,
          key: school._id,
          text: school.school_name,
        }));
        changeState({ schoolOptions });
      } catch (error) {
        console.log({ error });
      }
    };
    if (!state.schoolOptions.length) {
      getSchools();
    }
  }, []);

  const onChangeSchool = (event, data) => {
    const schoolSelected = data.value;
    changeState({ schoolSelected });
  };
  return (
    <div className={className}>
      <PanelSectionHeader className={"title"}>
        Seleccione la escuela
      </PanelSectionHeader>
      <Dropdown
        fluid
        selection
        onChange={onChangeSchool}
        options={state.schoolOptions}
        value={state.schoolSelected}
        className={"selection"}
      />
    </div>
  );
};

export default SchoolOptions;
