import Ticket from './model'
import styled from 'styled-components'
import giftImg from 'assets/img/gift-ticket-icon.png'
import { grey_4, gold_1, gold_2 } from 'assets/strings/colors'

const StyledTicket = styled(Ticket)`
  #root &{
    display: flex;
    flex-direction: column;
    align-items: center;
    >p{
      margin: 1rem;
    }
    >button:first-of-type{
      margin-top: 6.5vh;
    }
    >button{
      margin: 0 0 3vh 0;
      width: 60%;
      max-width: 200px;
    }
    >.ticket::before{
      height: 10px;
      content: '';
      width: 100%;
      border-radius: .3rem .3rem 0 0;
      position: absolute;
      top: -10px;
    }
    >.ticket.gift::before{
      background: ${gold_2}; /* Old browsers */
      background: -moz-linear-gradient(left, ${gold_2} 0%, ${gold_1} 50%, ${gold_2} 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, ${gold_2} 0%, ${gold_1} 50%, ${gold_2} 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, ${gold_2} 0%, ${gold_1} 50%, ${gold_2} 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=${gold_2}, endColorstr=${gold_2},GradientType=1 ); /* IE6-9 */
    }
    >.ticket.gift{
      background-image: url(${giftImg});
      background-size: 6vw;
      background-position: 98% 2%;
      background-origin: content-box;
      background-repeat: no-repeat;
    }
    >.ticket{
      display: flex;
      background-origin: content-box;
      flex-direction: column;
      align-items: center;
      width: 60%;
      margin-top: 5vh;
      background: white;
      border-radius: .3rem;
      box-shadow: 0 2px 7px 1px ${grey_4};
      >.iconoTicket{
        margin-top: 3.3vh;
        width: 33%;
      }
      >h4{
        margin: 1.5vh 0 0 0;
        font-size: 2rem;
        max-width: 80%;
        text-align: center;
      }
      >h5{
        margin: 0;
        font-size: 1.5rem;
        max-width: 80%;
        text-align: center;
      }
      >.address{
        margin: 1.5vh 0 0 0;        
        font-size: .9rem;
        max-width: 80%;
        text-align: center;
      }
      >.amount{
        margin: 2vh 0 0 0;
        font-size: 1.4rem;
      }
      >.ticketId{
        margin: 1vh 0 0 0;
        bottom: 23vw;
        font-size: 2rem;
        max-width: 80%;
        text-align: center;
      }
      >.ticketUsername{
        margin: 1vh 0 0 0;
        bottom: 23vw;
        font-size: 1rem;
        max-width: 80%;
        text-align: center;
      }
      >.ticketTime{
        margin: 2vh 0 0 0;
        bottom: 23vw;
        font-size: .9rem;
        max-width: 80%;
        text-align: center;
      }
      >.soporteTicket{
        position: relative;
        margin: 0;
        left: -.2px;
        bottom: -1vw;
        width: 101%;
      }
    }

    .ticket.ticket-appear {
      opacity: 0;
      transform: translateX(-30px);
    }
    .ticket.ticket-appear-active {
      opacity: .8;
      transform: translateX(2px);
      transition: all 350ms ease-out;
    }
    .ticket.ticket-enter {
      opacity: 0;
      transform: translateX(-30px);
    }
    .ticket.ticket-enter-active {
      opacity: .8;
      transform: translateX(2px);
      transition: all 300ms ease-out;
    }
    .ticket.ticket-enter-done {
      opacity: 1;
      transform: translateX(0);
      transition: all 50ms ease-out;
    }
    .ticket.ticket-exit {
      opacity: .8;
      transform: translateX(-2px);
    }
    .ticket.ticket-exit-active {
      opacity: 0;
      transform: translateX(30px);
      transition: all 350ms ease-out;
    }

    @media (max-width: 768px) {
      &.noTicket{
        height: 78.75vh
      }
      >.ticket.gift{
        background-size: 10vw;
      }
      >.ticket{
        >h4{
          font-size: 1.5rem;
        }
        >h5{
          font-size: 1.2rem;        
        }
        >.address{
          font-size: .9rem; 
        }
        >.amount{
          font-size: 1.2rem;
        }
        >.ticketId{
          font-size: 1.5rem; 
        }
        >.ticketTime{
          font-size: .8rem; 
        }
        >.soporteTicket{
          position: relative;
          bottom: -3vw;
          left: -.2px;
          width: 101%;
        }
      }
    }

    @media (max-width: 576px) {
      >.ticket.gift{
        background-size: 15vw;
      }
      >.ticket{
        width: 85%;
      }
      >button{
        max-width: none;
      }
    }

  }
`;

export default StyledTicket