import React, { useState } from "react";
import UsersNotificationsContext from "./context";
import { useStateManager } from "hooks/use-state-manager";
import { connect } from "react-redux";
import { clearReduxStore } from "redux/actions/general";
import { pushNotificationAPI } from "api";
import { usePopupMessages } from "hooks/use-popup-messages/hook";

const UsersNotificationsState = ({ children }) => {
  const initialState = {
    _title: "",
    _content: "",
    _file: "",
    _screen: "",
    _file_format: "",
    _days_to_expire: "",
    _link: "",
    screenOptions: [
      {
        key: "all",
        text: "",
        value: "",
      },
    ],
    fileFormatOptions: [
      {
        key: "all",
        text: "",
        value: "",
      },
    ],
    sendPush: false,
    loading: false,
  };
  const [state, changeState] = useStateManager(initialState);
  const [file, setFile] = useState(null);
  const {
    popupMessages,
    popupMessagesType,
    showPopup,
    throwPopupMessage,
    closePopup,
  } = usePopupMessages(null, "", false);

  const getConstants = async () => {
    try {
      changeState({ loading: true });
      const response = await pushNotificationAPI.pushInfo();
      const res = await response.json();
      if (res.error) throw res.error;

      const screenOptions = res.screens.map((screen) => {
        return {
          key: screen.value,
          value: screen.value,
          text: screen.name,
        };
      });

      const fileFormatOptions = res.file_formats.map((format) => {
        return {
          key: format.value,
          value: format.value,
          text: format.name,
        };
      });
      changeState({ screenOptions, fileFormatOptions, loading: false });
      setTimeout(() => {
        changeState({ panelTransition: true });
      }, 350);
    } catch (error) {
      console.log({ error });
      changeState({ loading: false });
      throwPopupMessage(
        "error",
        "error buscando valores predeterminados, intente refrescar la página"
      );
    }
  };

  const sendPushNotification = async () => {
    try {
      const {
        _title: title,
        _content: body,
        _screen,
        _file_format: file_format,
        _days_to_expire: days_to_expire,
        _link,
      } = state;
      const link = _link.includes("https://") ? _link : `https://${_link}`;
      const screen = _screen === "link:" ? `${_screen}${link}` : _screen;
      const data = {
        title,
        body,
        screen,
        file_format,
        days_to_expire,
      };
      changeState({ loading: true });
      let formData = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append("excel", file);

      const response = await pushNotificationAPI.postPush(formData);
      const res = await response.json();
      if (res.error) throw res.error;
      changeState({ loading: false });
      throwPopupMessage("success", res.message);
    } catch (error) {
      const general = "Ha ocurrido un error, intente más tarde";
      const errorMessage = error.description ? error.description : general;
      console.log({ errorMessage });
      throwPopupMessage("error", errorMessage);
    }
  };
  return (
    <UsersNotificationsContext.Provider
      value={{
        state,
        file,
        setFile,
        changeState,
        getConstants,
        sendPushNotification,
        popupMessages,
        popupMessagesType,
        showPopup,
        closePopup,
      }}
    >
      {children}
    </UsersNotificationsContext.Provider>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = {
  clearReduxStore,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersNotificationsState);
