import React, { useContext } from "react";
import { Button } from "components/buttons";
import { general as texts } from "assets/strings/texts";
import { recharge_validate as viewTexts } from "assets/strings/texts";
import { SearchInputRounded } from "components/search-input";
import { TransfersContext } from "../../context";
import { DateForm } from "components/date-form";
import { useEnter } from "hooks/use-enter";
import { useFetchTransfers } from "../../services";

const TransfersFormModel = ({ className }) => {
  const { state, changeState, viewType } = useContext(TransfersContext);
  const phone = viewType === "phone";

  const onReferenceChange = (event) => {
    const reference = event.currentTarget.value;
    changeState({ formData: { ...state.formData, reference } });
  };

  const onButtonsSelections = (event) => {
    event.preventDefault();
    const status = event.target.id;
    let newStatus = [];

    if (!state.formData.status.find((s) => s === status))
      newStatus = [...state.formData.status, status];
    else newStatus = [...state.formData.status.filter((s) => s !== status)];
    changeState({
      formData: {
        ...state.formData,
        status: [...newStatus],
      },
    });
  };

  const statusPressed = (value) =>
    state.formData.status.find((s) => s === value);

  const statusColor = (value) => (statusPressed(value) ? "blue" : "grey_1");

  const handleFromDate = (date) => {
    changeState({
      formData: { ...state.formData, date_from: date && date.startOf("day") },
    });
  };
  const handleEndDate = (date) => {
    changeState({
      formData: { ...state.formData, date_to: date && date.endOf("day") },
    });
  };
  const onSubmit = (event) => {
    event.preventDefault();
    changeState({ submitForm: true, formData: { ...state.formData, page: 1 } });
  };

  useEnter(onSubmit, state);
  useFetchTransfers();

  return (
    <form className={className} onSubmit={onSubmit}>
      <label className="label">Rango de fechas:</label>
      <DateForm
        fromDate={state.formData.date_from}
        endDate={state.formData.date_to}
        handleFromDate={handleFromDate}
        handleEndDate={handleEndDate}
        phoneView={phone}
      />

      <div className="username-input">
        <SearchInputRounded
          handleChange={onReferenceChange}
          searchParam={state.formData.reference}
          placeholder="Referencia"
        />
      </div>

      <label className="label" key="status-selector-label">
        {viewTexts.rechargeStatus}
      </label>
      <div className="status container" key="status-selector-buttons">
        <Button
          color={statusColor("approved")}
          pressed={statusPressed("approved")}
          id="approved"
          onClick={onButtonsSelections}
        >
          {texts.approved}
        </Button>
        <Button
          color={statusColor("pending")}
          pressed={statusPressed("pending")}
          id="pending"
          onClick={onButtonsSelections}
        >
          {texts.pending}
        </Button>
        <Button
          color={statusColor("rejected")}
          pressed={statusPressed("rejected")}
          id="rejected"
          onClick={onButtonsSelections}
        >
          {texts.rejected}
        </Button>
      </div>

      <Button color="grey">Buscar</Button>
    </form>
  );
};

export default TransfersFormModel;
