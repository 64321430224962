import React from "react";
import { Grid } from "semantic-ui-react";
import { GeneralData } from "../../components/general-data";
import { Filters } from "../../components/filters";
import { PanelsBox } from "../../components/panels-box";
import { FirstRow } from "../../components/first-row";
import { SecondRow } from "../../components/second-row";
import { Row } from "../../components/row";
import PopupMessage from "components/popup-message";
import { Loader } from "components/loader";

const ParkingPhoneView = ({
  transition,
  item,
  fromDate,
  endDate,
  barStats,
  customStats,
  tableStats,
  ticketsDistribution,
  totalAmounts,
  parkingData,
  buildingName,
  relevance,
  filterTime,
  typeStatusList,
  buildingsOptions,
  barHandlePeriod,
  listTypesStatusHandlePeriod,
  listDataHandle,
  amountsHandlePeriod,
  handleBuildingChange,
  handlePeriodChange,
  handleFromDate,
  handleEndDate,
  handleSubmit,
  popupMessagesType,
  showPopup,
  popupMessages,
  closePopup,
  loading,
}) => {
  let entry_content, exit_content;

  entry_content =
    tableStats &&
    tableStats.entry_relevance &&
    tableStats.entry_relevance.length > 0
      ? tableStats.entry_relevance
      : [];
  exit_content =
    tableStats &&
    tableStats.exit_relevance &&
    tableStats.exit_relevance.length > 0
      ? tableStats.exit_relevance
      : [];

  return (
    <Grid padded>
      <PopupMessage
        messageType={popupMessagesType}
        showPopup={showPopup}
        messages={popupMessages}
        closePopup={closePopup}
      />
      {loading && <Loader />}
      {!loading && (
        <Grid.Column width={16}>
          <Filters
            item={item}
            transition={transition}
            fromDate={fromDate}
            endDate={endDate}
            buildingName={buildingName}
            filterTime={filterTime}
            buildingsOptions={buildingsOptions}
            handleBuildingChange={handleBuildingChange}
            handlePeriodChange={handlePeriodChange}
            handleFromDate={handleFromDate}
            handleEndDate={handleEndDate}
            handleSubmit={handleSubmit}
            phoneView
          />
          <FirstRow
            phoneView
            transition={transition}
            item={item}
            customStats={customStats}
            listTypesStatusHandlePeriod={listTypesStatusHandlePeriod}
            listDataHandle={listDataHandle}
            typeStatusList={typeStatusList}
          />
          <SecondRow
            phoneView
            transition={transition}
            item={item}
            barStats={barStats}
            ticketsDistribution={ticketsDistribution}
            barHandlePeriod={barHandlePeriod}
            totalAmounts={totalAmounts}
            amountsHandlePeriod={amountsHandlePeriod}
          />

          <Row
            phoneView
            transition={transition}
            item={item}
            barStats={barStats}
            barHandlePeriod={barHandlePeriod}
          />
          <GeneralData
            phoneView
            item={item}
            transition={transition}
            parkingData={parkingData}
            negativeVariation={relevance.variation < 0}
            relevance={relevance}
          />
          {!loading &&
          entry_content &&
          entry_content.length > 0 &&
          exit_content &&
          exit_content.length > 0 ? (
            <PanelsBox
              transition={transition}
              phoneView
              tableStats={tableStats}
            />
          ) : (
            <div style={{ textAlign: "center" }}>
              No existe información suficiente
            </div>
          )}
        </Grid.Column>
      )}
    </Grid>
  );
};

export { ParkingPhoneView };
