import { FiltersModel } from "./model";
import styled from "styled-components";
import {
  grey_6 /*  grey_4,  grey_1, */,
  bluePD,
  blue_3,
} from "assets/strings/colors";

const Filters = styled(FiltersModel)`
  #root & {
    > .filters {
      min-height: 50px;

      position: relative;
      z-index: 2;
      display: flex;
      ${(props) => {
        return props.phoneView ? "flex-wrap: wrap; " : "";
      }}
      ${(props) => {
        return props.phoneView ? "" : "height: 10vh;";
      }}
      ${(props) => {
        return props.phoneView ? "margin-bottom: 1rem" : "";
      }}
      ${(props) => {
        return props.phoneView ? "" : "justify-content: space-between;";
      }}
      ${(props) => {
        return props.phoneView ? "" : "align-items: center;";
      }}
      margin-left: ${(props) => {
        return props.phoneView ? "5%" : "2rem";
      }};
      ${(props) => {
        return props.phoneView ? "width: 90%; " : "";
      }}
      font-size: clamp(0.7rem, 1vw, 1rem);
      > .single {
        display: flex;
        ${(props) => {
          return props.phoneView ? "flex: 50%;" : "";
        }}
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        > p {
          margin: 0.5rem 0;
          padding: 0 0.5rem;
          text-transform: uppercase;
          font-size: clamp(0.6rem, 0.9vw, 9rem);
          background: #c0c0cc;
          font-weight: bold;
          color: white;
          border-radius: 1rem;
          text-align: center;
        }
      }
      > button {
        ${(props) => {
          return props.phoneView ? "flex: 50%;" : "";
        }}
        cursor: pointer;
        background: ${bluePD};
        color: white;
        font-weight: bold;
        border: none;
        width: 20%;
        height: 20px;
        border-radius: 0.2rem;
        :hover {
          background: ${blue_3};
        }
      }
      > .date-pickers {
        ${(props) => {
          return props.phoneView ? "flex: 50%;" : "";
        }}
        margin-left: 0;
        > p {
          font-weight: bold;
        }
        > div {
          width: 46%;
          > .react-datepicker__input-container {
            width: 100%;
            > input {
              background: none;
              width: calc(100% - 16px);
              border: none;
              color: ${grey_6};
              font-size: clamp(0.7rem, 1vw, 1rem);
              margin: 0.5rem 5px 0.5rem 5px;
              text-align: center;
              outline: none;
              box-shadow: none;
              transition: box-shadow 250ms linear;
              :focus {
                box-shadow: 0 1px darkgrey;
              }
            }
            > .react-datepicker__close-icon::after {
              right: 0;
            }
          }
          > .react-datepicker-wrapper {
            width: 100%;
            > .react-datepicker__input-container {
              width: 100%;
              > input {
                width: calc(100% - 16px);
                border: none;
                border-radius: 1rem;
                color: ${grey_6};
                font-size: clamp(0.7rem, 1vw, 1.2rem);
                margin: 0.5rem 5px 0.5rem 5px;
                text-align: center;
                outline: none;
                box-shadow: none;
                transition: box-shadow 250ms linear;
                :focus {
                  box-shadow: 0 1px darkgrey;
                }
              }
              > .react-datepicker__close-icon::after {
                right: 15px;
              }
            }
          }
        }
      }
    }
    .filters.filters-appear {
      opacity: 0;
      transform: translateY(30px);
    }
    .filters.filters-appear-active {
      opacity: 0.8;
      transform: translateY(-2px);
      transition: all 350ms ease-out;
    }
    .filters.filters-enter {
      opacity: 0;
      transform: translateY(30px);
    }
    .filters.filters-enter-active {
      opacity: 0.8;
      transform: translateY(-2px);
      transition: all 300ms ease-out;
    }
    .filters.filters-enter-done {
      opacity: 1;
      transform: translateY(0);
      transition: all 50ms ease-out;
    }
    .filters.filters-exit {
      opacity: 0.8;
      transform: translateY(2px);
    }
    .filters.filters-exit-active {
      opacity: 0;
      transform: translateY(-30px);
      transition: all 350ms ease-out;
    }
  }
`;

export { Filters };
