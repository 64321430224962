import React from "react";
import { Button } from "components/buttons";
import { Grid } from "semantic-ui-react";
import { Loader } from "components/loader";
import { BankAccountList } from "./bank-account-list";
import { BankAccountDetail } from "./bank-account-detail";
import { BankAccountForm } from "./bank-account-form";

const MposWalletPhoneView = ({
  viewType,
  isSelected,
  showForm,
  edit,
  genLoading,
  bankList,
  indexSelected,
  accountSelected,
  bankAccounts,
  handleAccountSelection,
  handleShowForm,
  handleOptions,
  handleBack,
  update,
}) => {
  return (
    <Grid padded>
      <Grid.Column width={16}>
        {genLoading && <Loader margin="4rem 0 0 0" />}
        {!genLoading &&
          !isSelected &&
          !showForm && [
            <BankAccountList
              viewType={viewType}
              bankAccounts={bankAccounts}
              handleAccountSelection={handleAccountSelection}
              handleOptions={handleOptions}
              indexSelected={indexSelected}
              key={"list"}
            />,
            <div
              key="newAccount"
              style={{
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "1rem",
                paddingLeft: "15%",
              }}
            >
              <Button color="blue" width={"80%"} onClick={handleShowForm}>
                Nueva Cuenta
              </Button>
            </div>,
          ]}
        {!genLoading && isSelected && !showForm && (
          <BankAccountDetail
            viewType={viewType}
            accountSelected={accountSelected}
            handleBack={handleBack}
            update={update}
            bankList={bankList}
            edit={edit}
            indexSelected={indexSelected}
          />
        )}
        {!genLoading && !isSelected && showForm && (
          <BankAccountForm
            viewType={viewType}
            handleBack={handleBack}
            update={update}
            bankList={bankList}
          />
        )}
      </Grid.Column>
    </Grid>
  );
};

export { MposWalletPhoneView };
