import { useState } from "react";

const useStatusButtons = (statusObject) => {
  const [status, setStatus] = useState(statusObject);
  return {
    ...status,
    handleStatusButtons: (event) => {
      event.preventDefault();
      let value = event.target.value;
      setStatus({ ...status, [value]: !status[value] });
    },
    reset: setStatus(statusObject),
  };
};

const useFilterButtons = (filterArray) => {
  //receive a json array
  const [filter, setFilter] = useState(filterArray);
  let obj = {};
  filter.map((elem) => {
    // prop is each json in array
    let keys = Object.keys(elem);
    const [newProp, setNewProp] = useState(elem[keys[0]]); //elemValue
    obj = {
      ...obj,
      [keys[0]]: newProp,
      [`handle${keys[0].charAt(0).toUpperCase() + keys[0].slice(1)}`]: (
        // camelCase to json key
        event
      ) => {
        event.preventDefault();
        setNewProp(event.target.value);
      },
    };
    return null;
  });
  return {
    ...obj,
    reset: () => {
      setFilter(filterArray);
    },
  };
};

export { useStatusButtons, useFilterButtons };
