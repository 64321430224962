import { DigitizeFooterModel } from "./model";
import styled from "styled-components";
import { grey_5 } from "assets/strings/colors";

const DigitizeFooter = styled(DigitizeFooterModel)`
  #root && {
    height: 10vh;
    min-height: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > .info {
      width: ${(props) => {
        return props.viewType === "phone" ? "100%" : "30%";
      }};
      color: ${grey_5};
      > .contact {
        font-weight: bold;
        color: black;
      }
    }
  }
`;

export { DigitizeFooter };
