import React from "react";
import { Home } from "../home-view";
import { UsersList, formatUserList } from "../users-list";
import { DigitizeForm } from "../digitize-form";
import { ValidateTicket } from "../ticket-validate";
import { AlertMessage } from "../alert-message";
import { Loader } from "components/loader";

const DigitizeBodyModel = ({
  message,
  ticketHour,
  ticketMinute,
  handleTicketHour,
  handleTicketMinute,
  ticketDate,
  autoPaid,
  handleTicketDate,
  ticketNumber,
  handleTicketNumber,
  toShow,
  users,
  generatedTicket,
  selectedUser,
  handleUserSelection,
  handleContinue,
  handleSubmit,
  handleValidate,
  handleAutoPaid,
  errorType,
  handleBack,
  viewType,
  className,
}) => {
  const component = {
    home: <Home viewType={viewType} />,
    loader: <Loader />,
    users: (
      <UsersList
        toShow={toShow}
        viewType={viewType}
        users={users}
        handleUserSelection={handleUserSelection}
        formatFunctionUserList={formatUserList}
      />
    ),
    digitize: (
      <DigitizeForm
        ticketHour={ticketHour}
        ticketMinute={ticketMinute}
        handleTicketHour={handleTicketHour}
        handleTicketMinute={handleTicketMinute}
        autoPaid={autoPaid}
        handleAutoPaid={handleAutoPaid}
        ticketDate={ticketDate}
        handleTicketDate={handleTicketDate}
        ticketNumber={ticketNumber}
        handleTicketNumber={handleTicketNumber}
        selectedUser={selectedUser}
        handleSubmit={handleSubmit}
        viewType={viewType}
      />
    ),
    validate: (
      <ValidateTicket
        handleValidate={handleValidate}
        generatedTicket={generatedTicket}
        viewType={viewType}
      />
    ),
    success: (
      <AlertMessage
        type="success"
        message={message}
        handleContinue={handleContinue}
        viewType={viewType}
      />
    ),
    error: (
      <AlertMessage
        type="error"
        errorType={errorType}
        message={message}
        handleContinue={handleContinue}
        viewType={viewType}
        handleBack={handleBack}
      />
    ),
  };
  return (
    <div className={className}>
      {component[toShow] || <Home viewType={viewType} />}
    </div>
  );
};

export { DigitizeBodyModel };
