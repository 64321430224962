import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { clearReduxStore } from 'redux/actions/general'

class SidebarItemModel extends PureComponent {
  state = {
    collapsed: true
  }

  handleClick = () => {
    if(this.props.selectedCommerce && this.props.selectedCommerce.parking && this.props.selectedCommerce.parking.billing_type === 'prepaid' && this.props.selectedCommerce.parking.prepaid_tickets === 0) {
      this.props.throwPopupMessage('error', 'No tiene tickets prepagados');
    }
    if(this.props.collapsible){
      this.setState({ collapsed: !this.state.collapsed })
    }
    if(this.props.sidebarHandler) {
      this.props.sidebarHandler(false)
    }
    if(this.props.logout){
      this.props.clearReduxStore()
    }
  }

  render(){

    let children = this.props.children
    if(this.props.collapsible){
      children = (
        <div className={`collapsed ${this.state.collapsed}`}>
          {this.props.children}
        </div>
      )
    }

    let sidebarItem = (
      <div
        className={this.props.className + ' item'}
        onClick={this.handleClick}>
          <p>
            {this.props.text}
            {this.props.collapsible && 
            <i className={'caret down icon ' + (this.state.collapsed ? 'collapsed' : '')}>
            </i>}
          </p>
          {children}
      </div>
    )

    if(this.props.linkTo){
      sidebarItem = (
        <Link to={this.props.linkTo} >
          {sidebarItem}
        </Link>
      )
    }

    return sidebarItem
  }
}

const mapStateToProps = null

const mapDispatchToProps = {
  clearReduxStore
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SidebarItemModel)