import Bill from './model'
import styled from 'styled-components'
import { grey_4 } from 'assets/strings/colors'

const StyledBill = styled(Bill)`
  #root &{
    position: relative;
    min-height: calc(100vh - 9rem);
    height: inherit;
    display: flex;
    border-left: 1px solid ${grey_4};
    flex-direction: column;
    align-items: center;
    >h4 {
      text-align: center;
      font-size: 1.125rem;
      margin-top: 4.44vh;
      margin-bottom: 4.44vh;
    }
    >img{
      height: 15vh;
    }
    >hr{
      width: 80%;
      border-bottom: 1px solid ${grey_4};
      margin: 0 0 4.44vh 0;
    }
    >p{
      position: absolute;
      bottom: 1rem;
    }
    >button{
      width: 160px;
      margin-top: 5vh;
      margin-bottom: .5vh;
    }
  @media (max-width: 767px) {
    border-top: 1px solid ${grey_4};
    border-left: none;
    min-height: inherit;
    >p{
      margin-top: 1rem;
      margin-bottom: 1rem;
      position: inherit;
    }
  }
}

`;

export default StyledBill
