import { useEffect, useContext } from "react";
import { DiscountCodesContext } from "../context";

const useHistoryList = () => {
  const { asyncHistory, state, changeState } = useContext(DiscountCodesContext);
  useEffect(() => {
    if (state.history.submit) {
      changeState({ history: { ...state.history, submit: false } });
      asyncHistory();
    }
  }, [state.history.submit]);
};

export default useHistoryList;
