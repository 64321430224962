import { TRANSACTIONS_TAGS_ADD_MANY } from "redux/actions/constants";

const initialState = {
  transactionsTags: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case TRANSACTIONS_TAGS_ADD_MANY:
      let { transactionsTags } = action.payload;
      return {
        ...state,
        transactionsTags: [...transactionsTags],
      };

    default:
      return state;
  }
}
