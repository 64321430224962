import React from "react";
import { OfficeNumberListItem } from "../office-numbers-list";

const OfficeNumberListItemModel = ({
  officeNumber,
  handleSelection,
  index,
  className,
}) => {
  return (
    <div id={index} onClick={handleSelection} className={className}>
      <p>{officeNumber}</p>
    </div>
  );
};

function formatOfficeNumbersList(officesNumbers, viewState, handleSelection) {
  let formatOfficeNumbersList = [];
  if (officesNumbers.length > 0) {
    officesNumbers.forEach((officeNumber, index) => {
      formatOfficeNumbersList.push(
        <OfficeNumberListItem
          index={index}
          officeNumber={officeNumber}
          handleSelection={handleSelection}
          key={`${officeNumber}.${index}}`}
        />
      );
    });
  } else {
    return <div>No existen números guardados</div>;
  }
  return formatOfficeNumbersList;
}

export { OfficeNumberListItemModel, formatOfficeNumbersList };
