import React, { useContext } from "react";
import { TransfersContext } from "../context";
import { Panel, PanelPlaceholder } from "components/panel";
import { CSSTransition } from "react-transition-group";
import TransferDetail from "../components/transfer-detail";
import { Loader } from "components/loader";

const TransferDetailContainer = () => {
  const { state } = useContext(TransfersContext);
  if (state.changeStatusLoading) return <Loader />;
  if (!state.selectedTransfer)
    return (
      <PanelPlaceholder>
        Seleccióne una transacción para habilitar la recarga
      </PanelPlaceholder>
    );

  return (
    <CSSTransition
      in={state.showDetail}
      mountOnEnter
      unmountOnExit
      timeout={350}
      appear={true}
      classNames="panel"
    >
      <Panel>
        <TransferDetail />
      </Panel>
    </CSSTransition>
  );
};

export default TransferDetailContainer;
