import React from "react";

export const ActivateFormModel = ({
  type,
  handleActivate,
  onChangeDaysQty,
  daysQty,
  className,
}) => {
  return (
    <form onSubmit={handleActivate} className={className}>
      {type !== "Regular" && (
        <label htmlFor="days-qty" className="form-label">
          <span className="form-label__text">Días del servicio</span>
          {/*<p>{daysQty}</p>*/}
          <input
            id="days-qty"
            type="number"
            onChange={onChangeDaysQty}
            placeholder="Ejemplo: 30"
            value={daysQty}
            required
            className="form-label__input"
          />
        </label>
      )}
      <button className="form-submit">Activar</button>
    </form>
  );
};
