import React from "react";

const GreenButtonModel = ({ text, isSelected, handle, className }) => {
  return (
    <button className={className} disabled={isSelected} onClick={handle}>
      {text}
    </button>
  );
};

export { GreenButtonModel };
