import { CommercePanelModel } from "./model";
import styled from "styled-components";
import { grey_3, grey_5 } from "assets/strings/colors";

const CommercePanel = styled(CommercePanelModel)`
  #root & {
    > hr {
      width: 100%;
      margin: 0 auto 0 auto;
      border: none;
      border-bottom: 1px solid ${grey_5};
    }
    > .commerce-img-container {
      height: 22vh;
      margin: -1.3rem -1.3rem 0 -1.3rem;
      display: flex;
      border-radius: 0.2rem 0.2rem 0 0;
      justify-content: center;
      align-items: center;
      background: ${grey_3};
      > .commerce-img {
        height: 70%;
      }
    }
    > .commerce-name {
      text-transform: uppercase;
      font-size: 1.2rem;
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
      color: #2c304d;
      margin: 1.2rem 1rem 1.2rem 1rem;
    }
    > .commerce-data {
      > div {
        margin: 0.6rem 0 0.6rem 0;
        > dt {
          font-weight: bold;
          text-transform: uppercase;
          color: #5b5b5b;
        }
        > dd {
          text-align: justify;
        }
      }
      .fixed-buttons {
        margin: 0;
        margin-top: 1rem;
        display: flex;
        justify-content: space-around;
      }
    }
    > .buttons {
      display: flex;
    }
  }
`;
export { CommercePanel };
