import React from "react";
import { connect } from "react-redux";
import { clearReduxStore } from "redux/actions/general";
import {
  throwPopupMessage as throwPopupMessageRedux,
  closePopup as closePopupRedux,
} from "redux/actions/popup-messages";
import {
  getSelectedCommerce as getSelectedCommerceFromRedux,
  getBuildings as getBuildingsFromRedux,
} from "redux/selectors";
import { DiscountCodesState } from "./context";
import { DiscountCodesView } from "./components/discountCodesView";
import useOnMount from "./hooks/useOnMount";

const DiscountCodes = ({ throwPopupMessage, closePopup }) => {
  const [viewType] = useOnMount();
  return (
    <DiscountCodesState
      throwPopupMessage={throwPopupMessage}
      closePopup={closePopup}
      viewType={viewType}
    >
      <DiscountCodesView />
    </DiscountCodesState>
  );
};

const mapStateToProps = (state) => ({
  selectedCommerce: getSelectedCommerceFromRedux(state),
  buildings: getBuildingsFromRedux(state),
});
const mapDispatchToProps = {
  clearReduxStore,
  throwPopupMessage: throwPopupMessageRedux,
  closePopup: closePopupRedux,
};
export default connect(mapStateToProps, mapDispatchToProps)(DiscountCodes);
