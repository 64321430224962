import React from "react";
import { ListDetail } from "./index";
import moment from "moment-timezone";
moment.locale();

const ListDetailModel = ({
  ticketNumber,
  username,
  index,
  building,
  date,
  viewState,
  className,
}) => {
  let detailComponent = {
    parking: (
      <div className={className}>
        <div className="data">
          <p className="ticket-number">#{ticketNumber}</p>
          <p className="username">@{username}</p>
        </div>
        <div className="moment-site">
          <p className="site">{building}</p>
          <p className="moment">{moment(date).format("LLL")}</p>
        </div>
      </div>
    ),
    users: (
      <div className={className}>
        <div className="data">
          <p className="username">@{username}</p>
        </div>
        <div className="moment-site">
          <p className="moment">{moment(date).format("LLL")}</p>
        </div>
      </div>
    ),
  };
  return detailComponent[viewState] || null;
};

const formatListDetail = (list, viewState, handleItemSelection) => {
  if (list.length <= 0)
    return (
      <div style={{ textAlign: "center" }}>No hay resultado para mostrar</div>
    );

  const states = {
    parking: list.map((ticket, index) => {
      const hasBuilding = ticket && ticket.building;
      const buildingName =
        hasBuilding && ticket.building.name
          ? ticket.building.name
          : hasBuilding && ticket.building.commercial_name
          ? ticket.building.commercial_name
          : hasBuilding && ticket.building.parking_name
          ? ticket.building.parking_name
          : hasBuilding &&
            ticket.building.code &&
            ticket.building.code === "0004"
          ? "C.C. San Ignacio"
          : "";
      return (
        <ListDetail
          ticketNumber={ticket.ticket_number}
          username={ticket.username}
          index={index}
          building={buildingName}
          date={ticket.enter_at}
          handleItemSelection={handleItemSelection}
          key={ticket._id}
          viewState={viewState}
        />
      );
    }),
    users: list.map((user, index) => {
      return (
        <ListDetail
          username={user.username}
          index={index}
          date={user.created_at}
          handleItemSelection={handleItemSelection}
          key={user._id}
          viewState={viewState}
        />
      );
    }),
  };
  return states[viewState] || null;
};

export { formatListDetail, ListDetailModel };
