import React, { useContext } from "react";
import { EntitiesList, Pagination } from "components/entities-list";
import { Grid } from "semantic-ui-react";
import PopupMessage from "components/popup-message";
import { Loader } from "components/loader";
import { RightBorderGreyColumn } from "components/styled-grid-column";
import { MPremiumHistoryContext } from "../context";
import { HistoryForm } from "./history-form";
import { formatBundlesList } from "./bundle-list-item";
import { UserBundleDetail } from "./user-bundle-detail";
import { Panel } from "components/panel";
import { CSSTransition } from "react-transition-group";

export const MPremiumHistoryView = ({ props }) => {
  const {
    popupMessagesType,
    showPopup,
    popupMessages,
    closePopup,
    handlePageDown,
    handlePageUp,
    handleBundleSelection,
    state
  } = useContext(MPremiumHistoryContext);

  return (
    <Grid padded>
      <PopupMessage
        messageType={popupMessagesType}
        showPopup={showPopup}
        messages={popupMessages}
        closePopup={closePopup}
      ></PopupMessage>
      <RightBorderGreyColumn phone={8} tablet={8} computer={8}>
        <HistoryForm />
        <EntitiesList
          height="calc(67vh - 2.5rem)"
          viewState={null}
          entities={state.bundles}
          formattingFunction={formatBundlesList}
          selectionHandler={handleBundleSelection}
          additionalArgs={[state.bundlesLoading]}
        >
          <Pagination
            padding="0.8rem 0 0.8rem 0"
            nextPageExist={state.nextPageExist}
            currentPage={state.currentPage}
            handlePageUp={handlePageUp}
            handlePageDown={handlePageDown}
          />
        </EntitiesList>
      </RightBorderGreyColumn>
      <Grid.Column phone={8} tablet={8} computer={8}>
        {state.statisticsLoading && <Loader />}
        {!state.statisticsLoading && (
          <CSSTransition
            in={state.selectionDone}
            mountOnEnter
            unmountOnExit
            timeout={350}
            appear={true}
            classNames="panel"
          >
            <Panel>
              <UserBundleDetail />
            </Panel>
          </CSSTransition>
        )}
      </Grid.Column>
    </Grid>
  );
};
