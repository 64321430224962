import React, { useContext } from "react";
import { MobilePaidsContext } from "../context";
import { Grid } from "semantic-ui-react";
import MobilePaidDetailContainer from "./mobile-paid-detail";
import MobilePaidsFormAndListContainer from "./mobile-paids-form-and-list";

const toRender = (selectedMobilePaid) => {
  if (!selectedMobilePaid) {
    return <MobilePaidsFormAndListContainer />;
  }
  return <MobilePaidDetailContainer />;
};

const MobilePaidPhoneView = () => {
  const { state } = useContext(MobilePaidsContext);
  return (
    <Grid padded>
      <Grid.Column width={16}>{toRender(state.selectedMobilePaid)}</Grid.Column>
    </Grid>
  );
};

export default MobilePaidPhoneView;
