import React from "react";
import moment from "moment";
import "moment/locale/es-us";
import { formatPrice } from "services/utils";
import {
  BundleListItem,
  BundlePhoneListItem,
  NoBundleFound,
  BundleListHeader,
} from "../bundle-list-item";

moment.locale("es-us");

const BundleListItemModel = ({
  handleBundleSelection,
  expireDate,
  startDate,
  totalAmount,
  currency,
  username,
  title,
  index,
  className,
}) => {
  return (
    <dd id={index} className={className} onClick={handleBundleSelection}>
      <div className="username">
        <p>@{username}</p>
      </div>
      <div className="building">
        <p>{title}</p>
      </div>

      <p className="amount">{formatPrice(totalAmount) + " " + currency}</p>
      <div className="period-section">
        <p className="date">
          <span>desde: </span>
          {startDate}
        </p>
        <p className="date">
          <span>hasta: </span>
          {expireDate}
        </p>
      </div>
    </dd>
  );
};

const BundlePhoneListItemModel = ({
  handleBundleSelection,
  expireDate,
  startDate,
  totalAmount,
  currency,
  username,
  title,
  index,
  className,
}) => {
  return (
    <dd id={index} className={className} onClick={handleBundleSelection}>
      <p className="title">usuario</p>
      <p>@{username}</p>
      <p className="title">Título</p>
      <p>{title}</p>
      <p className="title">total</p>
      <p>{formatPrice(totalAmount) + " " + currency}</p>
      <p className="title">vigencia</p>
      <p>
        {startDate} - {expireDate}
      </p>
    </dd>
  );
};

const BundleListHeaderModel = ({ className }) => {
  return (
    <dd className={className}>
      <p className="col twenty">Usuario</p>
      <p className="col twenty-five">Título</p>
      <p className="col twenty">Total</p>
      <p className="col thirty-five">Vigencia</p>
    </dd>
  );
};

const NoBundleFoundModel = ({ className }) => {
  return <p className={className}>No se han encontrado paquetes activos</p>;
};

function formatBundlesList(bundles, viewState, handleBundleSelection, loading) {
  // Declaramos el array que devolverá la función

  let bundlesList = [];
  let formatString = "DD [de] MMMM YYYY";
  if (loading) {
    return (
      <div
        className="ui active loader"
        style={{
          marginTop: "3rem",
          position: "relative",
        }}
      />
    );
  }
  if (!bundles.length) {
    return <NoBundleFound />;
  } else {
    bundlesList.push(<BundleListHeader key="header" />);
    bundles.forEach((bundle, index) => {
      let expireDate = moment(bundle.expire_date).format(formatString);
      let startDate = moment(bundle.start_date).format(formatString);
      let bundleTitle = bundle.bundle_title || "N/A";

      bundlesList.push(
        <BundleListItem
          handleBundleSelection={handleBundleSelection}
          expireDate={expireDate}
          startDate={startDate}
          totalAmount={bundle.commerce_amount}
          currency={"Bs"}
          status={bundle.status}
          username={bundle.username}
          title={bundleTitle}
          index={index}
          key={bundle._id}
        />
      );
    });
    return bundlesList;
  }
}

function formatBundlesPhoneList(
  bundles,
  viewState,
  handleBundleSelection,
  loading
) {
  // Declaramos el array que devolverá la función
  let bundlesList = [];
  let formatString = "DD / MM / YYYY";
  if (loading) {
    return (
      <div
        className="ui active loader"
        style={{
          marginTop: "3rem",
          position: "relative",
        }}
      ></div>
    );
  }

  if (!bundles.length) {
    return <NoBundleFound />;
  } else {
    bundles.forEach((bundle, index) => {
      let expireDate = moment(bundle.expire_date).format(formatString);
      let startDate = moment(bundle.start_date).format(formatString);
      let bundleTitle = bundle.bundle_title || "N/A";

      bundlesList.push(
        <BundlePhoneListItem
          handleBundleSelection={handleBundleSelection}
          expireDate={expireDate}
          startDate={startDate}
          totalAmount={bundle.commerce_amount}
          currency={"Bs"}
          status={bundle.status}
          username={bundle.username}
          title={bundleTitle}
          index={index}
          key={bundle._id}
        />
      );
    });
    return bundlesList;
  }
}

export {
  BundleListItemModel,
  BundlePhoneListItemModel,
  BundleListHeaderModel,
  NoBundleFoundModel,
  formatBundlesList,
  formatBundlesPhoneList,
};
