import styled from "styled-components";
import { RechargeValidationModel } from "./model";
import { grey_6, grey_5 } from "assets/strings/colors";

const RechargeValidation = styled(RechargeValidationModel)`
  #root & {
    > .amount {
      border: none;
      border-radius: 1rem;
      color: ${grey_6};
      font-size: 1.5rem;
      margin: 0.5rem auto 0.5rem auto;
      text-align: center;
      width: 100%;
      outline: none;
      box-shadow: none;
      -webkit-transition: box-shadow 250ms linear;
      transition: box-shadow 250ms linear;
      :focus {
        box-shadow: 0 1px ${grey_5};
      }
    }
    > .title {
      font-size: 0.85rem;
      border-bottom: 1px solid ${grey_6};
      display: inline-block;
      text-transform: uppercase;
      font-weight: bold;
      color: ${grey_6};
      margin-bottom: 0;
      margin-top: 0.5rem;
    }
    > .content {
      text-align: center;
      margin: 0.5rem 0;
      color: ${grey_6};
      font-size: 1.5rem;
    }
    > .sm {
      font-size: 1rem;
    }
    > button {
      margin: 0.7rem 0 0 0;
    }
  }
`;

export { RechargeValidation };
