import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { WithdrawHistoryContext } from "../../context";
import {
  PanelTitle,
  PanelSectionHeader,
  PanelSectionContent,
} from "components/panel";
import { WithdrawValidatorForm } from "../withdraw-validation-form";
import { Loader } from "components/loader";
import { Button } from "components/buttons";
import { BillRefContent } from "./styles";
import { formatPrice } from "services/utils";
import { general as text, currencyMapped } from "assets/strings/texts";
import moment from "moment";
import "moment/locale/es-us";
moment.locale("es-us");

export const BillRefContentModel = ({ bill, currency, className }) => {
  return (
    <div className={className}>
      <p>Detalles del recibo:</p>
      <dt>Aprobado por:</dt>
      <dd>{bill.pay_by_username}</dd>
      <dt>Fecha de aprobación:</dt>
      <dd>{moment(bill.pay_date).format(text.shortDateHourFormat)}</dd>
      <dt>Referencia de pago:</dt>
      <dd>{bill.pay_ref}</dd>
      <dt>Código:</dt>
      <dd>{bill.code}</dd>
      <dt>Subtotal:</dt>
      <dd>
        {currency}
        {formatPrice(bill.sub_total_amount)}
      </dd>
      <dt>Porcentaje de retención ISLR:</dt>
      <dd>{formatPrice(bill.islr_withholding_percentage)}%</dd>
      <dt>Monto de retención ISLR:</dt>
      <dd>
        {currency}
        {formatPrice(bill.islr_withholding_amount)}
      </dd>
      <dt>Porcentaje de retención IVA:</dt>
      <dd>{formatPrice(bill.iva_withholding_percentage)}%</dd>
      <dt>Retención IVA:</dt>
      <dd>
        {currency}
        {formatPrice(bill.iva_withholding_amount)}
      </dd>

      <dt>Monto de comisión:</dt>
      <dd>
        {currency}
        {formatPrice(bill.pd_fee)}
      </dd>

      <dt>Total:</dt>
      <dd>
        {currency}
        {formatPrice(bill.total_amount)}
      </dd>
    </div>
  );
};

const ShowMoreButton = ({ showBillRef, onClick }) => {
  if (!showBillRef) return null;
  return (
    <Button onClick={onClick} color="grey">
      Mostrar más
    </Button>
  );
};

const ApproveButtons = ({
  isPending,
  onApprove,
  withdrawSelected,
  className,
}) => {
  if (!isPending) return null;
  const intern = withdrawSelected.transaction_owner.split("-")[0] === "pd";
  return (
    <div className={className}>
      <Button color="green" width="40%" onClick={onApprove}>
        Aprobar
      </Button>
      <Link
        to={{
          pathname: "/mpos/wallet/transfer",
          state: {
            withdraw: { ...withdrawSelected, intern: intern },
          },
        }}
        className="link"
      >
        <Button color="blue" width="100%">
          Transferir y aprobar
        </Button>
      </Link>
    </div>
  );
};
const WithdrawDetailModel = ({ className }) => {
  const { state, throwPopupMessage, submitApprove } = useContext(
    WithdrawHistoryContext
  );
  const data = state.withdrawSelected;
  const showBillRef = data.bill_ref && data.status === "approved";
  const bill = data.bill_ref;
  if (!data) return null;

  const showMore = () => {
    throwPopupMessage(
      "info",
      <BillRefContent currency={currencyMapped[data.currency]} bill={bill} />
    );
  };

  const onApproveSelected = () => {
    throwPopupMessage(
      "",
      <WithdrawValidatorForm
        withdrawSelected={state.withdrawSelected}
        onSubmit={submitApprove}
      />
    );
  };

  const isPending = data.status === "pending";

  if (state.approveForm.loading) return <Loader />;
  return (
    <div className={className}>
      <PanelTitle>Detalles de la transacción</PanelTitle>
      <PanelSectionHeader>Descripción:</PanelSectionHeader>
      <PanelSectionContent>{data.description_tag}</PanelSectionContent>
      <PanelSectionContent>{data.description}</PanelSectionContent>

      <PanelSectionHeader>Referencia (PagoDirecto):</PanelSectionHeader>
      <PanelSectionContent>{data.reference}</PanelSectionContent>

      {/*       <PanelSectionHeader>Fondos previos:</PanelSectionHeader>
      <PanelSectionContent>
        {currencyMapped[data.currency] || ""}
        {formatPrice(data.previous_funds)}
      </PanelSectionContent> */}

      <PanelSectionHeader>Monto:</PanelSectionHeader>
      <PanelSectionContent>
        {currencyMapped[data.currency] || ""}
        {formatPrice(data.pd_amount)}
      </PanelSectionContent>
      {/* 
      <PanelSectionHeader>Fondos posteriores:</PanelSectionHeader>
      <PanelSectionContent>
        {currencyMapped[data.currency] || ""}
        {formatPrice(data.funds)}
      </PanelSectionContent> */}

      <PanelSectionHeader>Contraparte:</PanelSectionHeader>
      <PanelSectionContent>
        {(data.counterpart && data.counterpart.username) ||
          (data.commerce_counterpart && data.commerce_counterpart.name) ||
          "N/A"}
      </PanelSectionContent>

      <PanelSectionHeader>Fecha:</PanelSectionHeader>
      <PanelSectionContent>
        {moment(data.created_at).format(text.shortDateHourFormat)}
      </PanelSectionContent>
      <ShowMoreButton showBillRef={showBillRef} onClick={showMore} />
      <ApproveButtons
        isPending={isPending}
        onApprove={onApproveSelected}
        withdrawSelected={state.withdrawSelected}
        className="approve-buttons"
      />
    </div>
  );
};

export default WithdrawDetailModel;
