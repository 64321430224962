import React, { useEffect } from "react";
import { connect } from "react-redux";
import { clearReduxStore } from "redux/actions/general";
import {
  getSelectedCommerce,
  getBuildings as getBuildingsRedux,
} from "redux/selectors";
import { useTextInput } from "hooks/use-inputs";
import { useDropdown } from "hooks/use-dropdown";
import { useStateManager } from "hooks/use-state-manager";
import { usePopupMessages } from "hooks/use-popup-messages";
import { createBoxOffice } from "services/api";
import SignupBoxofficeView from "./components/signup-boxoffice";

const MPosSignupBoxoffice = ({ buildingsSaved }) => {
  let buildingOptions = [];
  if (buildingsSaved && buildingsSaved.length) {
    buildingOptions = buildingsSaved.map((buildingItem, index) => {
      return {
        key: buildingItem._id,
        text: buildingItem.commercial_name,
        value: buildingItem._id,
      };
    });
  }
  const initialState = {
    loading: false,
    buildingOptions,
    genPromoTicket: true,
    submit: false,
  };
  const [state, changeState] = useStateManager(initialState);
  const {
    reset: resetUsername,
    bind: { value: username, onChange: handleUsername },
  } = useTextInput("");
  const {
    bind: { value: building, onChange: handleBuilding },
  } = useDropdown(state.buildingOptions[0].value);

  const {
    popupMessages,
    popupMessagesType,
    showPopup,
    throwPopupMessage,
    closePopup,
  } = usePopupMessages(null, "", false);

  useEffect(() => {
    const resetInputs = () => {
      resetUsername();
    };
    const registerNewBoxoffice = async () => {
      try {
        changeState({ loading: true });
        const form = {
          username,
          building_id: building,
          generate_promotional_ticket: state.genPromoTicket,
        };
        const response = await createBoxOffice(form);
        const res = await response.json();
        if (res.error) throw res.error;
        resetInputs();
        changeState({ loading: false });
        throwPopupMessage("success", "usuario registrado exitosamente");
      } catch (error) {
        changeState({ loading: false });
        throwPopupMessage("error", error.description);
      }
    };
    if (state.submit) {
      changeState({ submit: false });
      registerNewBoxoffice();
    }
  }, [state.submit]);

  const handleGenPromoTicket = (event) => {
    changeState({ genPromoTicket: event.target.checked });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    changeState({ submit: true });
  };
  return (
    <SignupBoxofficeView
      loading={state.loading}
      buildingOptions={state.buildingOptions}
      building={building}
      username={username}
      genPromoTicket={state.genPromoTicket}
      handleGenPromoTicket={handleGenPromoTicket}
      handleBuilding={handleBuilding}
      handleUsername={handleUsername}
      handleSubmit={handleSubmit}
      popupMessages={popupMessages}
      popupMessagesType={popupMessagesType}
      showPopup={showPopup}
      closePopup={closePopup}
    />
  );
};

const mapStateToProps = (state) => {
  let selectedCommerce = getSelectedCommerce(state);
  let buildingsSaved = getBuildingsRedux(state);
  return { selectedCommerce, buildingsSaved };
};

const mapDispatchToProps = {
  clearReduxStore,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MPosSignupBoxoffice);
