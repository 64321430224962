import styled from "styled-components";
import {
  WithdrawListItemModel,
  WithdrawPhoneListItemModel,
  WithdrawListHeaderModel,
  NoWithdrawFoundModel,
} from "./model";
import { grey_1, grey_4, bluePD } from "assets/strings/colors";

const WithdrawListItem = styled(WithdrawListItemModel)`
  #root & {
    margin: 0;
    cursor: pointer;
    border-radius: 1rem;
    border-bottom: 1px solid ${grey_4};
    font-size: 0.9rem;
    width: 100%;
    display: flex;
    align-items: center;
    :hover {
      background: ${grey_1};
    }
    > .col {
      margin: 0 0.5rem;
      display: inline-block;
      padding: 0.5rem 0;
      text-align: center;
      width: calc(20% - 1rem);
    }
    > .reference-section {
      > .reference {
        color: ${bluePD};
      }
    }
    > .period-section {
      > p {
        > span {
          text-transform: uppercase;
          font-weight: bold;
        }
        line-height: 2rem;
      }
    }
    > .amount {
      font-size: 1rem;
    }
    > .status {
      text-align: center;
      > img {
        height: 2.5rem;
      }
      > .paid {
        text-transform: uppercase;
        font-weight: bold;
        color: #a2c400;
      }
      > .pending {
        text-transform: uppercase;
        font-weight: bold;
        color: #ff8000;
      }
      > .overdue {
        text-transform: uppercase;
        font-weight: bold;
        color: #ff0000;
      }
    }
    > .commerce {
      width: calc(20% - 1rem);
      > img {
        height: 2.5rem;
      }
    }
  }
`;

const WithdrawPhoneListItem = styled(WithdrawPhoneListItemModel)`
  #root & {
    margin: 0;
    padding: 0.7rem;
    font-size: 0.85rem;
    cursor: pointer;
    border-radius: 1rem;
    border-bottom: 1px solid ${grey_4};
    :hover {
      background: ${grey_1};
    }
    > .title {
      display: inline-block;
      margin-bottom: 0.3rem;
      text-transform: uppercase;
      font-size: 1.1rem;
      border-bottom: 1px solid ${grey_4};
    }
    .status {
      float: right;
      height: 2rem;
    }
    > p {
      margin: 0 0 0.6rem 0;
    }
  }
`;

const WithdrawListHeader = styled(WithdrawListHeaderModel)`
  #root & {
    margin: 0;
    > .col {
      margin: 0 0.5rem;
      width: 100%;
      padding: 0.5rem 0
      display: inline-block;
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
      border-bottom: 1px solid ${grey_4};
    }
    > .forty {
      width: calc(40% - 1rem);
    }
    > .thirty {
      width: calc(30% - 1rem);
    }
    > .twenty-five {
      width: calc(25% - 1rem);
    }
    > .twenty {
      width: calc(20% - 1rem);
    }
  }
`;

const NoWithdrawFound = styled(NoWithdrawFoundModel)`
  #root & {
    text-align: center;
    font-size: 1.2rem;
    line-height: 62px;
  }
`;

export {
  WithdrawListItem,
  WithdrawListHeader,
  NoWithdrawFound,
  WithdrawPhoneListItem,
};
