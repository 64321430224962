import React, { useContext } from "react";
import { Dropdown } from "components/dropdown";
import { PanelSectionHeader } from "components/panel";
import { UsersReportContext } from "../../context";
import { ReportForm } from "../report-form";
import { Loader } from "components/loader";

const onlyParkingReportOptions = [
  {
    value: "ticketsBuilding",
    key: "ticketsBuilding",
    text: "Tickets generados",
  },
];
const sharedReportOptions = [
  {
    value: "parkingLogs",
    key: "parkingLogs",
    text: "Logs de accesos por estacionamientos",
  },
];

const parkingReportOptions = [
  ...onlyParkingReportOptions,
  ...sharedReportOptions,
];
const reportOptions = [
  {
    value: "verifiedRegisteredUsers",
    key: "verifiedRegisteredUsers",
    text: "Registro de usuarios verificados",
  },
  {
    value: "unverifiedRegisteredUsers",
    key: "unverifiedRegisteredUsers",
    text: "Registro de usuarios no verificados",
  },
  {
    value: "unverifiedRegisteredUsersByOffice",
    key: "unverifiedRegisteredUsersByOffice",
    text: "Registro por taquilla de usuarios no verificados ",
  },
  {
    value: "userActivities",
    key: "userActivities",
    text: "Actividad de Usuarios",
  },
  {
    value: "transactionCustomer",
    key: "transactionCustomer",
    text: "Transacciones de usuarios",
  },
  {
    value: "transactionsSchools",
    key: "transactionsSchools",
    text: "Transacciones QR de escuelas asociadas",
  },
  {
    value: "servicePayment",
    key: "servicePayment",
    text: "Pago de servicios",
  },
  {
    value: "walletRecharges",
    key: "walletRecharges",
    text: "Recargas al wallet",
  },
  {
    value: "transactionsCreditPremium",
    key: "transactionsCreditPremium",
    text: "Transacciones de crédito Premium",
  },
  {
    value: "premiumTicketsQtyByUser",
    key: "premiumTicketsQtyByUser",
    text: "Tickets premium por usuario",
  },
  {
    value: "approvedRechargesSuperadmin",
    key: "approvedRechargesSuperadmin",
    text: "Recargas aprobadas por superadmin",
  },
  {
    value: "tickets",
    key: "tickets",
    text: "Tickets generados en todos los estacionamientos",
  },
  {
    value: "transactionsCommercesTransfers",
    key: "transactionsCommercesTransfers",
    text: "Transferencias de comercios",
  },
  {
    value: "parkingMeterTickets",
    key: "parkingMeterTickets",
    text: "Tickets de parquímetros",
  },
  {
    value: "tollTickets",
    key: "tollTickets",
    text: "Tickets de peajes",
  },
  ...sharedReportOptions,
];

export const ReportsGeneratorModel = ({ className }) => {
  const { state, changeState } = useContext(UsersReportContext);
  const onChangeReport = (event, data) => {
    const reportValue = data.value;
    changeState({ reportValue });
  };
  if (state.loading) return <Loader />;
  return (
    <div className={className}>
      <PanelSectionHeader className={"title"}>
        Seleccione el tipo de reporte
      </PanelSectionHeader>
      <div className="report-selector">
        <Dropdown
          selection
          onChange={onChangeReport}
          options={state.isSuperadmin ? reportOptions : parkingReportOptions}
          value={state.reportValue}
          className={"selection"}
        />
      </div>
      <ReportForm />
    </div>
  );
};
