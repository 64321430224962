import React, { useEffect } from "react";
import { useViewType } from "hooks/use-view-type";
import { UsersNotificationsState } from "./context";
import MPosUsersNotificationsMassiveView from "./components/mpos-users-notifications-massive-view";
import MPosUsersNotificationsMassivePhoneView from "./components/mpos-users-notifications-massive-phone-view";

const MPosUsersNotificationsMassive = (props) => {
  const [viewType, setViewType] = useViewType(null);
  useEffect(() => {
    setViewType();
    window.addEventListener("resize", setViewType);
    return () => {
      window.removeEventListener("resize", setViewType);
    };
  }, []);

  const uiComponent = {
    phone: <MPosUsersNotificationsMassivePhoneView />,
    desktop: <MPosUsersNotificationsMassiveView />,
  };
  return (
    <UsersNotificationsState>{uiComponent[viewType]}</UsersNotificationsState>
  );
};

export default MPosUsersNotificationsMassive;
