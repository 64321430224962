import React, {PureComponent, isValidElement, useContext} from "react";
import {connect} from "react-redux";
import {CSSTransition} from "react-transition-group";
import {closePopup as closePopupRedux} from "redux/actions/popup-messages";
import {getPopupMessages, getMessagesType, isPopupVisible} from "redux/selectors/popup-message";

class PopupMessageModel extends PureComponent {
    render() {
        let {messageType, messages, closePopup, showPopup, children, className} = this.props;
        // Set the header depending on the type
        let header;
        switch (messageType) {
            case "success":
                header = <p className="header">¡Genial!</p>;
                break;
            case "alert":
                header = <p className="header">Atención</p>;
                break;
            case "error":
                header = <p className="header">¡Ups!</p>;
                break;
            case "info":
                header = <p className="header"></p>;
                break;
            default:
                // if there is no matching type, display no header
                header = null;
                break;
        }

        // format the messageText depending on wheather is a single
        // message or multiple ones
        let messageText = null;
        if (typeof messages === "string") {
            messageText = <div className="message">{messages}</div>;
        } else if (messages instanceof Array) {
            messageText = messages.map((message, index) => {
                return (
                    <div className="message" key={"popup" + index}>
                        {message}
                    </div>
                );
            });
        } else if (isValidElement(messages)) {
            messageText = messages;
        }

        // if there are messages print them, else don't
        if (messageText) {
            return (
                <CSSTransition in={showPopup} appear timeout={250} classNames={className}>
                    <div
                        className={"overlay " + className}
                        onClick={(e) => (e.target.classList.contains("overlay") ? closePopup(e) : false)}
                    >
                        <div className={"popup " + messageType}>
                            <i className="x icon" onClick={closePopup}/>
                            {header}
                            {messageText}
                            {children}
                        </div>
                    </div>
                </CSSTransition>
            );
        }
        return null;
    }
}

export default PopupMessageModel;

const mapStateToProps = (state) => ({
    messages: getPopupMessages(state),
    messageType: getMessagesType(state),
    showPopup: isPopupVisible(state),
});

const mapDispatchToProps = {
    closePopup: closePopupRedux,
};
const PopupMessageReduxModel = connect(mapStateToProps, mapDispatchToProps)(PopupMessageModel);

export {PopupMessageReduxModel};

export function throwPopupMessage(messageType, ...messages) {
    this.setState((prevState) => {
        return {
            ...prevState,
            showPopup: true,
            popupMessagesType: messageType,
            popupMessages: [...messages],
        };
    });
}

export function closePopup() {
    this.setState((prevState) => {
        return {
            ...prevState,
            showPopup: false,
        };
    });

    setTimeout(() => {
        this.setState((prevState) => {
            return {
                ...prevState,
                popupMessagesType: "",
                popupMessages: null,
            };
        });
    }, 250);
}


export const PopupMessageWithContextModel = ({context, children, className}) => {
    const {popupMessagesType: messageType, popupMessages: messages, closePopup, showPopup} = useContext(context);
    // Set the header depending on the type

    const defaultHeader = " ";
    const headerStrings = {
        success: "¡Genial!",
        alert: "Atención",
        error: "¡Ups!",
        info: "",
    }
    const headerContent = headerStrings[messageType] || defaultHeader;
    const headerComponent = <p className="header">{headerContent}</p>;

    const typeofMessage = (messages) => {
        const isStringMessage = typeof messages === "string" ? "string" : undefined;
        const isArrayMessage = messages instanceof Array ? "array" : undefined;
        const isComponentMessage = isValidElement(messages) ? "component" : undefined;
        return isStringMessage || isArrayMessage || isComponentMessage || null;
    }

    const messageTextComponents = {
        "string": <div className="message">{messages}</div>,
        "component": messages,
        "array": messages instanceof Array && messages.map((message, index) => {
            return (
                <div className="message" key={"popup" + index}>
                    {message}
                </div>
            );
        }),
    }
    const messageTextComponent = messageTextComponents[typeofMessage(messages)] || null;

    // if there are messages print them, else don't
    if (messageTextComponent) {
        return (
            <CSSTransition in={showPopup} appear timeout={250} classNames={className}>
                <div
                    className={"overlay " + className}
                    onClick={(e) => (e.target.classList.contains("overlay") ? closePopup(e) : false)}
                >
                    <div className={"popup " + messageType}>
                        <i className="x icon" onClick={closePopup}/>
                        {headerComponent}
                        {messageTextComponent}
                        {children}
                    </div>
                </div>
            </CSSTransition>
        );
    }
    return null;
}

