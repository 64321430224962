export const COMMERCES_ADD_MANY = 'COMMERCES_ADD_MANY'
export const COMMERCES_SELECT = 'COMMERCES_SELECT'

export const addCommerces = (commerces) => ({
  type: COMMERCES_ADD_MANY,
  payload: {
    commerces
  } 
})

export const selectCommerce = (commerceId) => ({
  type: COMMERCES_SELECT,
  payload: {
    commerceId
  } 
})