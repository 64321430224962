import { RegisterFormModel } from "./model";
import styled from "styled-components";
import { magenta, bluePD, grey_3, grey_5 } from "assets/strings/colors";
const RegisterForm = styled(RegisterFormModel)`
  #root && {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > .info {
      width: ${(props) => {
        return props.viewType === "phone" ? "100%" : "30%";
      }};
      color: ${grey_5};
      text-align: center;
      padding-bottom: 1rem;
      > .contact {
        font-weight: bold;
        color: black;
      }
    }
    > .loader {
      margin: 0;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    > .register {
      margin: 0;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      > p {
        margin: 0;
        margin-bottom: 1rem;
        width: 50%;
        text-align: center;
        font-size: 1.5rem;
        font-weight: bold;
      }
      > input {
        margin: 0.5rem;
        height: 8%;
        width: 40%;
        border: 1px solid ${grey_3};
        border-radius: 2rem;
        text-align: center;
        font-size: 1rem;
        :focus {
          outline: none;
          border: 1px solid ${magenta};
        }
      }
      > .doc-class {
        height: 8%;
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: inline-block;
        .label {
          background: white;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 20%;
          color: #6586cf;
          padding: 0;
          border: 1px solid ${grey_3};
          border-radius: 2rem;
          text-align: center;
          font-size: 1rem;
          .menu {
            border: 1px solid ${magenta};
            border-radius: 1rem;
          }
          :focus {
            outline: none;
            border: 1px solid ${magenta};
          }
        }
        > input {
          background: white;
          height: 100%;
          width: 80%;
          border: 1px solid ${grey_3};
          border-radius: 2rem;
          text-align: center;
          font-size: 1rem;
          :focus {
            outline: none;
            border: 1px solid ${magenta};
          }
        }
      }
      > .wrong-password {
        margin: 0;
        margin-bottom: 0.2rem;
        color: red;
        font-size: 0.8rem;
      }
      > button {
        margin-top: 3rem;
        width: 40%;
        height: 8%;
        //background: linear-gradient(to right, ${magenta}, ${bluePD});
        background: ${bluePD};
        border: none;
        border-radius: 2rem;
        color: white;
        font-size: 1rem;
        font-weight: bold;
        :hover {
          outline: none;
          opacity: 0.7;
          cursor: pointer;
        }
        :disabled {
          outline: none;
          opacity: 0.7;
          cursor: not-allowed;
        }
      }
    }
    .register.register-appear {
      opacity: 0;
      transform: translateX(-30px);
    }
    .register.register-appear-active {
      opacity: 0.8;
      transform: translateX(2px);
      transition: all 350ms ease-out;
    }
    .register.register-enter {
      opacity: 0;
      transform: translateX(-30px);
    }
    .register.register-enter-active {
      opacity: 0.8;
      transform: translateX(2px);
      transition: all 300ms ease-out;
    }
    .register.register-enter-done {
      opacity: 1;
      transform: translateX(0);
      transition: all 50ms ease-out;
    }
    .register.register-exit {
      opacity: 0.8;
      transform: translateX(-2px);
    }
    .register.register-exit-active {
      opacity: 0;
      transform: translateX(30px);
      transition: all 350ms ease-out;
    }
    @media (max-width: 900px) {
      > .register {
        > p {
          width: 80%;
          font-size: 1.5rem;
        }
        > input {
          width: 80%;
          font-size: 1rem;
        }
        > .doc-class {
          width: 80%;
          font-size: 1rem;
        }
      }
    }
    @media (max-width: 750px) {
      > .register {
        > p {
          width: 90%;
          font-size: 1.5rem;
        }
        > input {
          width: 90%;
          font-size: 1rem;
        }
        > .doc-class {
          width: 90%;
          font-size: 1rem;
        }
      }
    }
    @media (max-width: 500px) {
      > .register {
        > p {
          width: 90%;
          font-size: 1.2rem;
        }
        > input {
          width: 90%;
          font-size: 1rem;
        }
        > .doc-class {
          width: 90%;
          font-size: 1rem;
        }
      }
    }
  }
`;

export { RegisterForm };
