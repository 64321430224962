import styled from 'styled-components'
import { DigitizeTicketFormModel } from './model'
import { grey_6 } from 'assets/strings/colors'

export const DigitizeTicketForm = styled(DigitizeTicketFormModel)`

  #root &.form-appear {
    max-height: 0;
  }
  #root &.form-appear-active {
    max-height: 2000px;
    transition: max-height 700ms linear;
  }
  #root &.form-enter {
    max-height: 0;
  }
  #root &.form-enter-done {
    max-height: 2000px;
    transition: max-height 700ms linear;
  }

  #root &{
    overflow: hidden;
    >.title{
      font-size: .85rem;
      border-bottom: 1px solid #5B5B5B;
      display: inline-block;
      text-transform: uppercase;
      font-weight: bold;
      color: #5B5B5B;
      margin-bottom: 0;
    }
    >.username{
      color: #63c0e8;
      font-size: 1.5rem;
      margin: .1rem;
      text-align: center;
    }
    .text{
      color: ${grey_6};
      font-size: 1.1rem;
      margin: .1rem;
      text-align: center;
    }
    .input-section{
      margin: .5rem 0 .5rem 0;
      #ticket-id{
        width: calc(100% - 10px);
      }
      #ticket-date-picker{
        width: calc(100% - 10px);
      }
      input{
        border: none;
        border-radius: 1rem;
        color: ${grey_6};
        font-size: 1.5rem;
        margin: .5rem 5px .5rem 5px;
        text-align: center;
        outline: none;
        box-shadow: none;
        transition: box-shadow 250ms linear;
        :focus{
          box-shadow: 0 1px darkgrey;
        }
        ::-webkit-input-placeholder{
          color: lightgray;
        }
        :-ms-input-placeholder{
          color: lightgray;
        }
        ::-ms-input-placeholder{
          color: lightgray;
        }
        ::placeholder{
          color: lightgray;
        }
      }
      >label{
        font-size: .85rem;
        border-bottom: 1px solid #5B5B5B;
        display: inline-block;
        text-transform: uppercase;
        font-weight: bold;
        color: #5B5B5B;
        margin-bottom: .5rem;
      }
      >div{
        width: 100%;
        >.react-datepicker__input-container{
          width: 100%;
        }
        >.react-datepicker-wrapper{
          width: 100%;
          >.react-datepicker__input-container{
            width: 100%;
            >.react-datepicker__close-icon::after{
              right: 15px;
            }
          }
        }
      }
      >.time-picker{
        width: 65%;
        margin: 0 auto 2rem auto;
        >#ticket-hour,
        >#ticket-minutes{
          font-size: 1.5rem;
          width: calc(45% - 10px);
          vertical-align: middle;
          text-align: center;
        }
        >#ticket-ampm{
          font-size: 1.5rem;
          width: calc(20% - 10px);
          vertical-align: middle;
          text-align: center;
        }
      }
    }
  }
`