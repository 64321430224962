import React from "react";
import { Button } from "components/buttons";
import { general as texts } from "assets/strings/texts";

const ConfirmationModel = ({
  loading,
  textInfo,
  handleConfirm,
  handleCancel,
  className,
}) => {
  return (
    <div className={className}>
      {loading && (
        <div className="ui active loader" style={{ marginTop: "2rem" }}></div>
      )}
      <p>{textInfo}</p>
      <Button color="green" disabled={loading} onClick={handleConfirm}>
        {!loading && texts.accept}
        {loading && `${texts.wait}...`}
      </Button>
      <Button color="grey" onClick={handleCancel} disabled={loading}>
        Cancelar
      </Button>
    </div>
  );
};

export { ConfirmationModel };
