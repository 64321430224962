import React from "react";
import { Card } from "./card";
import { box_office as texts } from "assets/strings/texts";
const OptionsView = ({
  optionSelected,
  handleNewUser,
  handleRecharge,
  handleNewTicket,
  handleValidateEvent,
}) => {
  return (
    <div>
      <Card
        iconName="add user"
        handle={handleNewUser}
        description={texts.newUserDescription}
        isSelected={optionSelected === "new-user"}
        buttonText="Nuevo usuario"
        withIcon
      />
      {/*      <Card
        iconName="money bill alternate outline"
        handle={handleRecharge}
        description={texts.newRechargeDescription}
        isSelected={optionSelected === "new-recharge"}
        buttonText="Nueva recarga"
        withIcon
      />*/}
      <Card
        iconName="file alternate outline"
        handle={handleNewTicket}
        description={texts.newTicketDescription}
        isSelected={optionSelected === "new-ticket"}
        buttonText="Nuevo ticket"
        withIcon
      />
      <Card
        iconName="file alternate outline"
        handle={handleValidateEvent}
        description={texts.validateEventsDescription}
        isSelected={optionSelected === "validate-event"}
        buttonText="Eventos"
        withIcon
      />
    </div>
  );
};

export { OptionsView };
