import React from "react";
import moment from "moment";
import "moment/locale/es-us";
import { Grid } from "semantic-ui-react";
import PopupMessage from "components/popup-message";
import { Loader } from "components/loader";
import { EarningsSummary, DashboardSummary } from "components/summary-tabs";
import DashboardSection from "components/dashboard-section";
import BarGraph from "components/bar-graph";
import { bluePD, posDirectoBlue } from "assets/strings/colors";
moment.locale("es-us");

const MposGeneralDashboardView = ({
  wallet,
  loading,
  category,
  stats,
  dataGraph,
  periodToShow,
  timePeriods,
  handleTimePeriodSelect,
  popupMessagesType,
  showPopup,
  popupMessages,
  closePopup,
}) => {
  const parking = category && category === "estacionamiento";
  return loading ? (
    <Loader />
  ) : (
    <Grid padded>
      <PopupMessage
        messageType={popupMessagesType}
        showPopup={showPopup}
        messages={popupMessages}
        closePopup={closePopup}
      />
      <Grid.Column width={16}>
        <DashboardSection
          elemPerRow={1}
          elemPerPhoneRow={1}
          sectionTitle="Wallet"
        >
          <EarningsSummary unit={" Bs.S."} value={wallet} text="" />
        </DashboardSection>
        <DashboardSection
          elemPerRow={0}
          elemPerPhoneRow={0}
          sectionTitle="Estadísticas"
          timePeriods={timePeriods}
          periodToShow={periodToShow}
          handleTimePeriodsSelect={handleTimePeriodSelect}
        ></DashboardSection>

        <DashboardSection elemPerRow={3} elemPerPhoneRow={1}>
          <DashboardSummary
            color={parking ? posDirectoBlue : bluePD}
            value={stats.qty ? stats.qty : 0}
            text="Transacciones Promedio"
          />
          <DashboardSummary
            color={parking ? posDirectoBlue : bluePD}
            unit={"Bs.S."}
            value={stats.amount ? stats.amount : 0}
            text="Ingreso Promedio"
          />
          <DashboardSummary
            color={parking ? posDirectoBlue : bluePD}
            value={stats.amountByQty ? stats.amountByQty : 0}
            unit={"Bs.S"}
            text="Monto por Transacción"
          />
        </DashboardSection>
        <DashboardSection elemPerRow={1} elemPerPhoneRow={1}>
          <BarGraph
            title={dataGraph.titleGraph}
            data={dataGraph.data}
            keyX={dataGraph.keyX}
            keyY={dataGraph.keyY}
            lineName={dataGraph.lineName}
            fill={parking ? posDirectoBlue : posDirectoBlue}
            custom
          />
        </DashboardSection>
      </Grid.Column>
    </Grid>
  );
};

export default MposGeneralDashboardView;
