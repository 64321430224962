import React from "react";
import { Grid, Segment } from "semantic-ui-react";
import Navbar from "components/navbar";
//import PopupMessage from 'components/popup-message'
import { BlueRoundButton } from "components/round-button";
import GreatingsText from "components/greatings-text";

const ChangePasswordView = ({
  viewState,
  redirect,
  password,
  confirm,
  // methods
  handleConfirmChange,
  handlePasswordChange,
  handleSubmit,
}) => {
  return (
    <div>
      {/*  <PopupMessage 
        messageType={popupMessagesType}
        messages={popupMessages}
        showPopup={showPopup}
        closePopup={closePopup} /> */}

      <Navbar title={"Cambio de Contraseña"} />
      <Grid centered padded>
        <Grid.Column mobile={15} tablet={8} computer={6}>
          <GreatingsText key="reset-pass-email-text">
            <h4>Ingrese su nueva contraseña</h4>
          </GreatingsText>

          <p style={{ color: "#6586cf", textAlign: "center" }}>
            Recuerde que su contraseña debe tener una longitud de al menos 6
            caracteres.
          </p>

          <form
            onSubmit={handleSubmit}
            className={viewState === "wait_response" ? "ui loading form" : ""}
          >
            <Segment.Group>
              <div
                className="ui transparent left labeled input segment"
                style={{ display: "flex" }}
              >
                <input
                  type="password"
                  required
                  minLength={6}
                  onChange={handlePasswordChange}
                  placeholder="Ingrese su nueva contraseña"
                  autoComplete="off"
                  value={password}
                />
              </div>
              <div
                className="ui transparent left labeled input segment"
                style={{ display: "flex" }}
              >
                <input
                  type="password"
                  required
                  onChange={handleConfirmChange}
                  placeholder="Confirme su nueva contraseña"
                  value={confirm}
                  autoComplete="off"
                />
              </div>
            </Segment.Group>
            {viewState === "confirm_not_equal" && (
              <p style={{ color: "#ff0000", textAlign: "center" }}>
                Las contraseñas no coinciden
              </p>
            )}

            <BlueRoundButton
              fluid
              disabled={viewState !== "confirm_equal" ? true : false}
            >
              {" "}
              Guardar Contraseña{" "}
            </BlueRoundButton>
          </form>
        </Grid.Column>
      </Grid>
      {redirect}
    </div>
  );
};
export default ChangePasswordView;
