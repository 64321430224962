import React from "react";
import styled from "styled-components";
import Digitize from "./digitize";
//import Recharge from "./recharge";
import Register from "./register";
import ValidateEvent from "./validate-event";

const FeaturesView = ({ viewType, optionSelected, token, className }) => {
  return (
    <div className={className}>
      {optionSelected === "new-user" && <Register />}
      {optionSelected === "new-ticket" && <Digitize token={token} />}
      {optionSelected === "validate-event" && <ValidateEvent token={token} />}
      {/*{optionSelected === "new-recharge" && <Recharge />}*/}
    </div>
  );
};

const Features = styled(FeaturesView)`
  #root && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export default Features;
