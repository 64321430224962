import styled from "styled-components";
import {
  BundleListItemModel,
  BundlePhoneListItemModel,
  BundleListHeaderModel,
  NoBundleFoundModel
} from "./model";
import { grey_1, grey_4 } from "assets/strings/colors";

const BundleListItem = styled(BundleListItemModel)`
  #root & {
    margin: 0;
    padding: 2.5rem 0;
    cursor: pointer;
    border-bottom: 1px solid ${grey_4};
    font-size: 0.9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 8vh;

    :hover {
      background: ${grey_1};
    }

    > .username {
      margin: 0;
      width: 20%;
      padding: 0 0.5rem;
      color: #63c0e8;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    > .building {
      margin: 0;
      width: 25%;
      padding: 0 0.5rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    > .amount {
      margin: 0;
      width: 20%;
      padding: 0 1rem;
      font-size: 1rem;
    }

    > .period-section {
      margin: 0;
      width: 35%;
      padding: 0 1rem;
      overflow: hidden;

      > p {
        > span {
          text-transform: uppercase;
          font-weight: bold;
        }

        line-height: 2rem;
        margin: 0.1rem 0 0.1rem 0;
      }
    }
  }
`;

const BundlePhoneListItem = styled(BundlePhoneListItemModel)`
  #root & {
    margin: 0;
    padding: 0.7rem;
    font-size: 0.85rem;
    cursor: pointer;
    border-radius: 1rem;
    border-bottom: 1px solid ${grey_4};

    :hover {
      background: ${grey_1};
    }

    > .title {
      display: inline-block;
      margin-bottom: 0.3rem;
      text-transform: uppercase;
      font-size: 1.1rem;
      border-bottom: 1px solid ${grey_4};
    }

    .status {
      float: right;
      height: 2rem;
    }

    > p {
      margin: 0 0 0.6rem 0;
    }
  }
`;

const BundleListHeader = styled(BundleListHeaderModel)`
  #root & {
    margin: 0;
    margin-bottom: 1rem;
    height: 6vh;
    display: flex;
    align-items: flex-end;

    > .col {
      margin: 1.2rem 0.5rem 0 0.5rem;
      padding-bottom: 0.3rem;
      display: inline-block;
      text-transform: uppercase;
      font-weight: bold;
      border-bottom: 1px solid ${grey_4};
    }

    > .forty {
      width: calc(40% - 1rem);
    }

    > .thirty-five {
      width: calc(35% - 1rem);
    }

    > .thirty {
      width: calc(30% - 1rem);
    }

    > .twenty-five {
      width: calc(25% - 1rem);
    }

    > .twenty {
      width: calc(20% - 1rem);
    }
  }
`;

const NoBundleFound = styled(NoBundleFoundModel)`
  #root & {
    text-align: center;
    font-size: 1rem;
    line-height: 62px;
  }
`;

export { BundleListItem, BundleListHeader, NoBundleFound, BundlePhoneListItem };
