import { SearchByUserModel } from "./model";
import styled from "styled-components";
import { grey_4 } from "assets/strings/colors";

const SearchByUser = styled(SearchByUserModel)`
  #root && {
    height: 100vh-4em;
    > .buttons {
      border-top: 1px solid ${grey_4};
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }
  }
`;
export { SearchByUser };
