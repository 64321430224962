import {
  //baseURL,
  baseURL_V2,
} from "services/config";

import { get } from "services/http-methods";

const endpoints = {
  autocomplete: `${baseURL_V2}/users/autocomplete`,
};

export function usersAutocomplete(searchParam) {
  return get(endpoints.autocomplete, { input: searchParam });
}
