import {
  MPOS_COMMERCES_SET_VIEW_STATE,
  MPOS_COMMERCES_UPDATE_COMMERCES,
  MPOS_COMMERCES_SELECT_COMMERCE,
  MPOS_COMMERCES_REMOVE_COMMERCE,
  MPOS_COMMERCES_RESET_STATE,
  MPOS_COMMERCES_UPDATE_INVOICES,
  MPOS_COMMERCES_SELECT_INVOICE,
  MPOS_COMMERCES_UPDATE_WITHDRAWALS,
  MPOS_COMMERCES_SELECT_WITHDRAW,
  MPOS_COMMERCES_UPDATE_USERS,
  MPOS_COMMERCES_SELECT_USER,
} from "redux/actions/view-mpos-commerces";

const initialState = {
  viewState: "commerce_search",
  commerces: [],
  selectedCommerce: null,
  invoices: [],
  selectedInvoice: null,
  withdraw: [],
  users: [],
  selectedWithdraw: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case MPOS_COMMERCES_SET_VIEW_STATE: {
      let { viewState } = action.payload;
      return {
        ...state,
        viewState,
      };
    }

    case MPOS_COMMERCES_UPDATE_COMMERCES: {
      let { commerces } = action.payload;
      return {
        ...state,
        commerces,
      };
    }

    case MPOS_COMMERCES_SELECT_COMMERCE: {
      let { selectedCommerce } = action.payload;
      return {
        ...state,
        selectedCommerce,
      };
    }

    case MPOS_COMMERCES_REMOVE_COMMERCE: {
      return {
        ...state,
        selectedCommerce: initialState.selectedCommerce,
      };
    }

    case MPOS_COMMERCES_RESET_STATE: {
      return {
        ...initialState,
      };
    }

    case MPOS_COMMERCES_UPDATE_INVOICES: {
      let { invoices } = action.payload;
      return {
        ...state,
        invoices,
      };
    }

    case MPOS_COMMERCES_SELECT_INVOICE: {
      let { selectedInvoice } = action.payload;
      return {
        ...state,
        selectedInvoice,
      };
    }
    case MPOS_COMMERCES_UPDATE_WITHDRAWALS: {
      let { withdraw } = action.payload;
      return {
        ...state,
        withdraw,
      };
    }

    case MPOS_COMMERCES_SELECT_WITHDRAW: {
      let { selectedWithdraw } = action.payload;
      return {
        ...state,
        selectedWithdraw,
      };
    }

    case MPOS_COMMERCES_UPDATE_USERS: {
      let { users } = action.payload;
      return {
        ...state,
        users,
      };
    }

    case MPOS_COMMERCES_SELECT_USER: {
      let { selectedUser } = action.payload;
      return {
        ...state,
        selectedUser,
      };
    }

    default:
      return state;
  }
}
