import {
  //baseURL,
  baseURL_V2,
} from "services/config";

import { get, post } from "services/http-methods";

const endpoints = {
  activate: `${baseURL_V2}/premium/activate`,
  disable: `${baseURL_V2}/premium/disable`,
  bundles: `${baseURL_V2}/premium/bundles`,
  history: `${baseURL_V2}/premium/activated`,
  statistics: `${baseURL_V2}/premium/statistics/user`,
  bills: `${baseURL_V2}/premium/bills`,
  markBillAsPaid: `${baseURL_V2}/premium/bills/update`,
  availableBundles: `${baseURL_V2}/premium/bundles/available`,
};

export function premiumActivate(body) {
  /**
   * @brief  to activate/extend monthly premium
   * @attributes
   *  body = {
   *    user_id,
   *    buildings,
   *    qty_days,
   *    premium_type,
   *    conditions,
   *    bundle_title
   * }
   */
  return post(endpoints.activate, body);
}

export function premiumDisable(body) {
  /**
   * @brief  to activate/extend monthly premium
   * @attributes
   *  body = {
   *    user_id,
   *    building_code,
   *    type
   * }
   */
  return post(endpoints.disable, body);
}

export function premiumBundle(user_id) {
  /**
   * @brief  bundles premium by user
   * @attributes
   *    user_id,
   */
  return post(endpoints.bundles, { user_id });
}

export function premiumHistory(body) {
  /**
   * @brief  to get premium history
   * @attributes
   *    from_date: "2022-03-30T04:00:00.000+00:00",
   *    end_date: "2022-05-01T04:00:00.000+00:00",
   *    limit: 25,
   *    skip: 0,
   *    status: ["approved"],
   *    commerce_id: "5ebd7da27bf00228b0ad4327"
   */
  return post(endpoints.history, body);
}

export function premiumStatisticUser(body) {
  /**
   * @brief  to get premium history
   * @attributes
   *    date_from: "2022-03-30T04:00:00.000+00:00",
   *    date_to: "2022-05-01T04:00:00.000+00:00",
   *    premium_type: "monthly_premium"
   *    user_id: ["approved"],
   *    building_code: "0001"
   */
  return post(endpoints.statistics, body);
}

export function premiumBills(body) {
  /**
   * @brief  to get premium bills
   * @attributes
   * from_date,
   * end_date,
   * limit:25,
   * skip:0,
   * status: ["pending", "paid"],
   * commerce_id,
   */
  return post(endpoints.bills, body);
}

export function premiumMarkBillAsPaid(body) {
  /**
   * @brief  to mark as paid a monthly premium bill
   * @attributes
   * bill_id,
   * physical_bill_ref,
   * pay_ref,
   * pay_type,
   */
  return post(endpoints.markBillAsPaid, body);
}

export function premiumAvailableBundles(body) {
  /**
   * @brief  to mark as paid a monthly premium bill
   * @attributes
   * bill_id,
   * physical_bill_ref,
   * pay_ref,
   * pay_type,
   */
  return get(endpoints.availableBundles);
}
