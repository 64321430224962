import { useEffect, useState } from "react";
import { useViewType } from "hooks/use-view-type";
import { getAccounts } from "../services";

const useOnmountView = () => {
  const [viewType, setViewType] = useViewType("desktop", 992);
  const [fetch, setFetch] = useState(true);
  const [loading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState([]);
  useEffect(() => {
    if (fetch) {
      setLoading(true);
      getAccounts(setAccounts);
      setFetch(false);
      setLoading(false);
    }
    setViewType();
    window.addEventListener("resize", setViewType);
    return () => {
      window.removeEventListener("resize", setViewType);
    };
  }, [fetch, setAccounts, accounts]);

  return [accounts, viewType, loading, setFetch];
};

export default useOnmountView;
