import styled from "styled-components";
import { grey_4 } from "assets/strings/colors";
import { Dropdown as SemanticDropdown } from "semantic-ui-react";

export const Dropdown = styled(SemanticDropdown)`
  #root &.ui.dropdown {
    border: none;
    border-radius: 1rem !important;
    border-bottom: 1px solid ${grey_4};
    font-size: ${(props) => {
      let fontsize = props.fontSize ? props.fontSize : null;
      return `calc(${fontsize})`;
    }};
    text-align: ${(props) => {
      let textalign = props.textalign ? props.textalign : "left";
      return `${textalign}`;
    }};
    > .menu {
      top: 110%;
      border-radius: 1rem !important;
      border: none;
      box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.45) !important;
    }
  }
`;
export const DropdownSimple = styled(SemanticDropdown)`
  #root &.ui.dropdown {
    font-size: ${(props) => {
      let fontSize = props.fontSize ? props.fontSize : null;
      return `calc(${fontSize})`;
    }};
    color: ${grey_4};
    background: none;
    margin-bottom: 7rem;
  }
`;
