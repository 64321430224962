import { TransactionDetailModel } from "./model";
import styled from "styled-components";
import imagenTicket from "assets/img/ticket_asset_new.png";
import iconHours from "assets/img/per_hour_icon.png";
import { green } from "assets/strings/colors";

const TransactionDetail = styled(TransactionDetailModel)`
  #root & {
    background: #f0f0ff;
    height: calc(100vh - 4rem);
    position: relative;
    display: flex;
    justify-content: center;
    > .no-transaction {
      display: flex;
      align-items: center;
      justify-content: center;
      height: calc(80vh - 4rem);
    }
    > .transaction.detail {
      display: flex;
      align-self: center;
      flex-direction: column;
      align-items: center;
      height: calc(80vh - 4rem);
      width: 50%;
      background-image: url(${imagenTicket});
      background-size: auto 100%;
      background-repeat: no-repeat;
      background-position: center;
      > .icon {
        margin: 1rem;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        border: 4px solid #fcfcff;
        background: #ecdc00;
        background-image: url(${iconHours});
        background-size: auto 70%;
        background-repeat: no-repeat;
        background-position: center;
      }
      > .back-icon {
        align-self: flex-start;
        padding: 1rem;
        margin: 0;
      }
      > .title {
        margin-top: 0;
        text-transform: uppercase;
        font-size: clamp(0.8rem, 1vw, 1.5rem);
        color: #808080;
      }
      > .date {
        font-size: clamp(0.7rem, 0.9vw, 1rem);
        margin-bottom: 5rem;
      }
      > .content-box {
        display: flex;
        justify-content: space-between;
        width: 90%;
        margin-bottom: 2rem;
      }
      > .footer-title {
        margin-top: 5rem;
        font-size: clamp(1rem, 1vw, 1.5rem);
        text-transform: uppercase;
        font-weight: bold;
      }
      > .footer {
        margin-top: -0.8rem;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
        height: 10vh;
        background: #f0f0ff;
        border-radius: 5%;
        > .ticket-number {
          font-size: clamp(1rem, 2.5vw, 2rem);
          text-transform: uppercase;
          font-weight: bold;
          color: #808080;
        }
      }
      > button {
        margin-top: 1rem;
        border: none;
        background: ${green};
        width: 30%;
        height: 5vh;
        color: white;
        font-weight: bold;
        border-radius: 0.25rem;
        :hover {
          cursor: pointer;
          opacity: 0.6;
        }
      }
    }
    .transaction.transaction-appear {
      opacity: 0;
      transform: translateX(-30px);
    }
    .transaction.transaction-appear-active {
      opacity: 0.8;
      transform: translateX(2px);
      transition: all 350ms ease-out;
    }
    .transaction.transaction-enter {
      opacity: 0;
      transform: translateX(-30px);
    }
    .transaction.transaction-enter-active {
      opacity: 0.8;
      transform: translateX(2px);
      transition: all 300ms ease-out;
    }
    .transaction.transaction-enter-done {
      opacity: 1;
      transform: translateX(0);
      transition: all 50ms ease-out;
    }
    .transaction.transaction-exit {
      opacity: 0.8;
      transform: translateX(-2px);
    }
    .transaction.transaction-exit-active {
      opacity: 0;
      transform: translateX(30px);
      transition: all 350ms ease-out;
    }

    @media (max-width: 767px) {
      > .transaction.detail {
        width: 75%;
        > .icon {
          margin: 0;
        }
      }
    }
  }
`;

export { TransactionDetail };
