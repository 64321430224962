import styled from 'styled-components'
import { AccessesFormModel } from './model'
import { grey_6 } from 'assets/strings/colors'

const AccessesForm = styled(AccessesFormModel)`
  #root &{
    .input-section{
      margin: .5rem 0 .5rem 0;
      text-align: left;
      input{
        border: none;
        width: 100%;
        border-radius: 1rem;
        color: ${grey_6};
        font-size: 1.5rem;
        margin: .2rem 0 .2rem 0;
        text-align: center;
        outline: none;
        box-shadow: none;
        -webkit-transition: box-shadow 250ms linear;
        transition: box-shadow 250ms linear;
        :focus{
          box-shadow: 0 1px darkgrey;
        }
        ::-webkit-input-placeholder{
          color: lightgray;
        }
        :-ms-input-placeholder{
          color: lightgray;
        }
        ::-ms-input-placeholder{
          color: lightgray;
        }
        ::placeholder{
          color: lightgray;
        }
      }
      >label, >.label{
        font-size: .85rem;
        border-bottom: 1px solid ${grey_6};
        display: inline-block;
        text-transform: uppercase;
        font-weight: bold;
        color: ${grey_6};
        margin-bottom: .5rem;
      }
      >.longlat{
        >.label{
          color: ${grey_6};
          display: inline-block;
          font-size: .9rem;
          width: 20%;
        }
        >input{
          display: inline-block;
          width: 80%;
        }
      }
    }
  }
`
export { AccessesForm }