import React, { Component } from "react";
import { connect } from "react-redux";
import { Panel } from "components/panel";
import { getRole } from "redux/selectors/session";
import { Button } from "components/buttons";
import { errors } from "assets/strings/texts";
import { getInvoices } from "services/api";
import { general as texts } from "assets/strings/texts";
import { InvoicesForm } from "components/invoices-form";
import { clearReduxStore } from "redux/actions/general";
import { InvoicePaymentForm } from "../invoice-payment-form";
import { throwPopupMessage } from "redux/actions/popup-messages";
import { EntitiesList, Pagination } from "components/entities-list";
import { commerces_admin as viewTexts } from "assets/strings/texts";
import { formatInvoicesPhoneList } from "../invoice-payment-list-item";
import { getViewMposCommerces } from "redux/selectors/view-mpos-commerces";

import {
  handleSearchTypeButtons,
  handleFromDate,
  handleEndDate,
  handleStatusButtons,
} from "components/invoices-form";

import {
  setViewState,
  selectInvoice,
  updateInvoicesList,
} from "redux/actions/view-mpos-commerces";

class InvoicesPanel_unconnected extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchType: "latests", // enum ['latests','dateRange']
      fromDate: null,
      endDate: null,
      paid: false,
      pending: false,
      overdue: false,
      isFormCollapsed: true,
      currentPage: 1,
      nextPageExist: false,
    };

    // ========== INSTANCE PROPERTIES ==========
    this.initialState = { ...this.state };
    this.invoicesPerPage = 10;
    this.invoicesFormDataCache = {};
    // Bind invoices form handlers
    this.handleSearchTypeButtons = handleSearchTypeButtons.bind(this);
    this.handleFromDate = handleFromDate.bind(this);
    this.handleEndDate = handleEndDate.bind(this);
    this.handleStatusButtons = handleStatusButtons.bind(this);
  }

  componentDidMount() {
    this.invoicesFormDataCache = {
      // with the selected commerce
      selectedCommerce: this.props.selectedCommerce,
      // and the initial state
      searchType: this.initialState.searchType,
      fromDate: this.initialState.fromDate,
      endDate: this.initialState.endDate,
      paid: this.initialState.paid,
      pending: this.initialState.pending,
      overdue: this.initialState.overdue,
    };

    let {
      commerce_id,
      fromDate,
      endDate,
      limit,
      building,
    } = this.formatInvoicesRequestData(this.invoicesFormDataCache);

    let skip = 0;
    let promise = getInvoices(
      fromDate,
      endDate,
      skip,
      limit,
      building,
      commerce_id
    );
    this.props.setViewState("commerce_invoice_loading");
    this.handleInvoicesSearchPromise(promise);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedCommerce._id !== this.props.selectedCommerce._id) {
      this.setState({ ...this.initialState });

      this.invoicesFormDataCache = {
        // with the selected commerce
        selectedCommerce: this.props.selectedCommerce,
        // and the initial state
        searchType: this.initialState.searchType,
        fromDate: this.initialState.fromDate,
        endDate: this.initialState.endDate,
        paid: this.initialState.paid,
        pending: this.initialState.pending,
        overdue: this.initialState.overdue,
      };

      let {
        commerce_id,
        fromDate,
        endDate,
        building,
        limit,
      } = this.formatInvoicesRequestData(this.invoicesFormDataCache);

      let skip = 0;

      let promise = getInvoices(
        fromDate,
        endDate,
        skip,
        limit,
        building,
        commerce_id
      );
      this.props.setViewState("commerce_invoice_loading");
      this.handleInvoicesSearchPromise(promise);
    }
  }

  toggleInvoicesForm = () => {
    this.setState({
      isFormCollapsed: !this.state.isFormCollapsed,
    });
  };

  removeInvoicesPanel = () => {
    this.props.setViewState("commerce_info");
  };

  throwInvoicePaymentPopup = () => {
    this.props.setViewState("commerce_invoice_payment");
    this.props.throwPopupMessage("", <InvoicePaymentForm />);
    // ==
    // TODO: THIS will change when invoice payment form is updated
    // ==
    // setTimeout(() => {
    //   this.throwPopupMessage('', '')
    // }, popup_transition_time);
  };

  formatInvoicesRequestData = (formState) => {
    let commerce_id,
      fromDate,
      endDate,
      status = [],
      building,
      limit;
    if (formState.selectedCommerce) {
      commerce_id = formState.selectedCommerce._id;
      building = formState.selectedCommerce.parking.hasOwnProperty("building")
        ? formState.selectedCommerce.parking.building
        : null;
    }
    if (formState.searchType === "dateRange" && formState.fromDate)
      fromDate = formState.fromDate.toISOString();
    if (formState.searchType === "dateRange" && formState.endDate)
      endDate = formState.endDate.toISOString();
    if (formState.paid) status.push("paid");
    if (formState.pending) status.push("pending");
    if (formState.overdue) status.push("overdue");
    limit = this.invoicesPerPage + 1;
    return { commerce_id, fromDate, endDate, status, limit, building };
  };

  handleSubmit = (event) => {
    event.preventDefault();
    // format request data
    let {
      commerce_id,
      fromDate,
      endDate,
      limit,
      building,
    } = this.formatInvoicesRequestData({
      ...this.state,
      selectedCommerce: this.props.selectedCommerce,
    });
    let skip = 0;
    // save a copy of the form state
    this.invoicesFormDataCache = {
      selectedCommerce: this.props.selectedCommerce,
      searchType: this.state.searchType,
      fromDate: this.state.fromDate,
      endDate: this.state.endDate,
      paid: this.state.paid,
      pending: this.state.pending,
      overdue: this.state.overdue,
    };

    // If there are missing fields return to the prevState
    if (this.state.searchType === "dateRange" && (!fromDate || !endDate)) {
      this.setState({
        currentPage: this.initialState.currentPage,
        nextPageExist: this.initialState.nextPageExist,
      });
      this.props.updateInvoicesList([]); // empty list
      this.props.setViewState("commerce_invoice");
      this.props.throwPopupMessage("error", errors.MISSING_FIELDS);
    } else {
      // Set loading state and cached form data
      this.setState((prevState) => ({
        ...prevState,
        currentPage: this.initialState.currentPage,
        nextPageExist: this.initialState.nextPageExist,
      }));
      this.props.updateInvoicesList([]); // empty list
      this.props.setViewState("commerce_invoice_loading");
      // make the api call
      let promise = getInvoices(
        fromDate,
        endDate,
        skip,
        limit,
        building,
        commerce_id
      );
      this.handleInvoicesSearchPromise(promise);
    }
  };

  // 15)
  handlePageDown = (event) => {
    event.preventDefault();
    // format request data
    let {
      commerce_id,
      fromDate,
      endDate,
      limit,
      building,
    } = this.formatInvoicesRequestData(this.invoicesFormDataCache);
    let skip = (this.state.currentPage - 2) * this.invoicesPerPage;
    // Set loading state
    const { selectedCommerce, ...formCache } = this.invoicesFormDataCache;
    this.setState((prevState) => ({
      ...formCache,
      currentPage: prevState.currentPage - 1,
      nextPageExist: true,
    }));
    this.props.setViewState("commerce_invoice_loading");
    // make the api call
    let promise = getInvoices(
      fromDate,
      endDate,
      skip,
      limit,
      building,
      commerce_id
    );
    this.handleInvoicesSearchPromise(promise);
  };

  // 16)
  handlePageUp = (event) => {
    event.preventDefault();
    // format request data
    let {
      fromDate,
      endDate,
      limit,
      building,
      commerce_id,
    } = this.formatInvoicesRequestData(this.invoicesFormDataCache);
    let skip = this.state.currentPage * this.invoicesPerPage;
    // Set loading state
    const { selectedCommerce, ...formCache } = this.invoicesFormDataCache;
    this.setState((prevState) => ({
      ...formCache,
      currentPage: prevState.currentPage + 1,
      nextPageExist: true,
    }));
    this.props.setViewState("commerce_invoice_loading");
    // make the api call
    let promise = getInvoices(
      fromDate,
      endDate,
      skip,
      limit,
      building,
      commerce_id
    );
    this.handleInvoicesSearchPromise(promise);
  };

  // 17)
  handleInvoicesSearchPromise = (promise) => {
    promise
      .then((res) => {
        if (!res.ok) {
          throw res;
        } // If not OK throw error
        return res.json(); // If OK return body
      })
      .then((res) => {
        // Manage success response
        if (res.bills) {
          let nextPageExist;
          if (res.bills.length > this.invoicesPerPage) {
            nextPageExist = true;
            res.bills.pop();
          } else {
            nextPageExist = false;
          }
          this.setState({ nextPageExist });
          this.props.updateInvoicesList(res.bills);
          this.props.setViewState("commerce_invoice_done");
          this.props.selectInvoice(null);
        }
      })
      .catch((err) => {
        // Manage error response
        // set apropiate states
        const { selectedCommerce, ...formCache } = this.invoicesFormDataCache;
        this.setState({
          ...formCache,
          currentPage: this.initialState.currentPage,
          nextPageExist: this.initialState.nextPageExist,
        });
        this.props.updateInvoicesList([]); // empty list
        this.props.setViewState("commerce_invoice");
        this.props.selectInvoice(null);
        // If error has a body, check the response
        if (typeof err.json === "function") {
          err.json().then((err_body) => {
            // If it is an authentication error, clear the redux-store to close the session
            if (
              err_body.error.id === "NO_TOKEN_PROVIDED" ||
              err_body.error.id === "AUTHENTICATE_FAILED" ||
              err_body.error.id === "DUPLICATED_SESSION"
            ) {
              this.props.throwPopupMessage("alert", errors.AUTHENTICATION);
              //this.props.clearReduxStore();
              return; // prevent setState exeution on unmounted component
            }
            this.props.throwPopupMessage("error", err_body.error.description);
          });
        } else {
          this.props.throwPopupMessage("error", errors.GENERAL_ERR);
        }
      });
  };

  // 18)
  handleInvoiceSelection = (event) => {
    this.props.selectInvoice(event.currentTarget.id);
  };

  render() {
    const { role, viewType, viewState, selectedInvoice, invoices } = this.props;

    const {
      searchType,
      fromDate,
      endDate,
      paid,
      pending,
      overdue,
      isFormCollapsed,
      currentPage,
      nextPageExist,
    } = this.state;

    return (
      <Panel>
        <div style={{ display: "flex" }}>
          {viewType === "phone" && (
            <Button
              color="grey"
              margin="0 .2rem 0 .2rem"
              onClick={this.removeInvoicesPanel}
            >
              {texts.back}
            </Button>
          )}
          <Button
            color="green"
            margin="0 .2rem 0 .2rem"
            disabled={!selectedInvoice}
            onClick={this.throwInvoicePaymentPopup}
          >
            {viewTexts.markAsPaid}
          </Button>
        </div>
        <InvoicesForm
          role={role}
          viewState={viewState}
          searchType={searchType}
          fromDate={fromDate}
          endDate={endDate}
          paid={paid}
          pending={pending}
          overdue={overdue}
          collapsed={isFormCollapsed}
          toggleCollapse={this.toggleInvoicesForm}
          handleSearchTypeButtons={this.handleSearchTypeButtons}
          handleFromDate={this.handleFromDate}
          handleEndDate={this.handleEndDate}
          handleStatusButtons={this.handleStatusButtons}
          handleSubmit={this.handleSubmit}
        />

        <EntitiesList
          margin="1rem 0 0 0"
          height={`calc(100vh - 
                ${isFormCollapsed ? "17.4rem" : "32rem"})`}
          viewState={viewState}
          entities={invoices}
          formattingFunction={formatInvoicesPhoneList}
          selectionHandler={this.handleInvoiceSelection}
          additionalArgs={[selectedInvoice]}
        >
          <Pagination
            padding="0 0 0.8rem 0"
            currentPage={currentPage}
            nextPageExist={nextPageExist}
            handlePageDown={this.handlePageDown}
            handlePageUp={this.handlePageUp}
          />
        </EntitiesList>
      </Panel>
    );
  }
}

const mapStateToProps = (state) => ({
  role: getRole(state),
  viewType: state.responsiveData.viewType,
  invoices: getViewMposCommerces(state).invoices,
  viewState: getViewMposCommerces(state).viewState,
  selectedInvoice: getViewMposCommerces(state).selectedInvoice,
  selectedCommerce: getViewMposCommerces(state).selectedCommerce,
});

const mapDispatchToProps = {
  setViewState,
  selectInvoice,
  clearReduxStore,
  throwPopupMessage,
  updateInvoicesList,
};

const InvoicesPanel = connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoicesPanel_unconnected);

export { InvoicesPanel };
