import { DateRangeModel } from "./model";
import styled from "styled-components";
import { grey_6 } from "assets/strings/colors";

export const DateRange = styled(DateRangeModel)`
  #root & {
    margin: 0.7rem 0 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    > p {
      margin: 0;
      font-weight: bold;
    }

    > div {
      width: 46%;

      > .react-datepicker__input-container {
        width: 100%;

        > input {
          width: calc(100% - 16px);
          border: none;
          border-radius: 1rem;
          color: ${grey_6};
          font-size: 1.2rem;
          margin: 0.5rem 5px 0.5rem 5px;
          text-align: center;
          outline: none;
          box-shadow: none;
          transition: box-shadow 250ms linear;

          :focus {
            box-shadow: 0 1px darkgrey;
          }
        }

        > .react-datepicker__close-icon::after {
          right: 0;
        }
      }

      > .react-datepicker-wrapper {
        width: 100%;

        > .react-datepicker__input-container {
          width: 100%;

          > input {
            width: calc(100% - 16px);
            border: none;
            border-radius: 1rem;
            color: ${grey_6};
            font-size: 1.2rem;
            margin: 0.5rem 5px 0.5rem 5px;
            text-align: center;
            outline: none;
            box-shadow: none;
            transition: box-shadow 250ms linear;

            :focus {
              box-shadow: 0 1px darkgrey;
            }
          }

          > .react-datepicker__close-icon::after {
            right: 15px;
          }
        }
      }
    }

    > div:last-child {
      > .react-datepicker-popper {
        > .react-datepicker {
          > .react-datepicker__triangle {
            right: 50px;
            left: auto;
          }
        }
      }
    }
  }
`;
