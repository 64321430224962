import styled from "styled-components";

import {
  grey_1,
  grey_2,
  grey_4,
  grey_5,
  grey_6,
  //blue,
  //blue_2,
  green,
  green_2,
  green_6,
  green_5,
  red,
  red_2,
  yellow,
  yellow_2,
  purplePD,
  purplePD_2,
  posDirectoBlue,
  posDirectoBlueHover,
} from "assets/strings/colors";

const AnchorTagButton = styled.button`
  #root & {
    padding: 0;
    margin: 0;
    background: transparent;
    border: none;
    outline: none;
    color: #4183c4;
    cursor: pointer;
  }
`;

const Button = styled.button`
  #root & {
    display: block;
    height: 3.4rem;
    margin: ${(props) => (props.margin ? props.margin : "2rem 0 0 0")};
    width: ${(props) => (props.width ? props.width : "100%")};
    border: none;
    border-bottom: ${(props) =>
      props.pressed || props.icon ? "none" : `2px solid ${grey_4}`};
    outline: 0;
    font-weight: bold;
    cursor: pointer;
    outline: none;
    border-radius: 0.25rem;

    ${(props) => (props.color === "grey_1" ? `background: ${grey_1}` : "")};
    ${(props) => (props.color === "grey_1" ? "color: black" : "")};
    ${(props) =>
      props.color === "transparent" ? "background: transparent" : ""};
    ${(props) => (props.color === "transparent" ? `color: ${grey_5}` : "")};

    ${(props) =>
      props.color === "blue" ? `background: ${posDirectoBlue}` : ""};
    ${(props) => (props.color === "blue" ? "color: white" : "")};

    ${(props) => (props.color === "red" ? `background: ${red}` : "")};
    ${(props) => (props.color === "red" ? "color: white" : "")};

    ${(props) => (props.color === "grey" ? `background: ${grey_5}` : "")};
    ${(props) => (props.color === "grey" ? "color: white" : "")};

    ${(props) => (props.color === "green" ? `background: ${green}` : "")};
    ${(props) => (props.color === "green" ? "color: white" : "")};

    ${(props) => (props.color === "green_6" ? `background: ${green_6}` : "")};
    ${(props) => (props.color === "green_6" ? "color: white" : "")};

    ${(props) => (props.color === "yellow" ? `background: ${yellow}` : "")};
    ${(props) => (props.color === "yellow" ? "color: white" : "")};

    ${(props) => (props.color === "purple" ? `background: ${purplePD}` : "")};
    ${(props) => (props.color === "purple" ? "color: white" : "")};

    :hover {
      ${(props) => (props.color === "grey_1" ? `background: ${grey_2}` : "")};
      ${(props) =>
        props.color === "blue" ? `background: ${posDirectoBlueHover}` : ""};
      ${(props) => (props.color === "red" ? `background: ${red_2}` : "")};
      ${(props) => (props.color === "grey" ? `background: ${grey_6}` : "")};
      ${(props) => (props.color === "green" ? `background: ${green_2}` : "")};
      ${(props) => (props.color === "green_6" ? `background: ${green_5}` : "")};
      ${(props) => (props.color === "yellow" ? `background: ${yellow_2}` : "")};
      ${(props) =>
        props.color === "purple" ? `background: ${purplePD_2}` : ""};
    }

    > a {
      color: inherit;
    }
  }
  #root &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export { AnchorTagButton, Button };
