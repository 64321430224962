import React, { Component } from 'react'
import moment from 'moment'
import { Button } from 'components/buttons'

class DigitizeConfirmationModel extends Component {
  constructor(props) {
    super(props)
    const newTicketDate = moment(this.props.newTicketDate)
    const twoDaysAgo = moment().subtract(2, 'days')

    this.state = {
      shouldConfirmOldTicket: newTicketDate.isBefore(twoDaysAgo)
    }
  }

  acceptOldTicket = () => {
    this.setState({ shouldConfirmOldTicket: false })
  }

  render() {
    const {
      viewState,
      selectedUser,
      newTicketReference,
      newTicketDate,
      newTicketHour,
      newTicketMinute,
      digitizeTicket,
      cancel,
      className
    } = this.props;

    const {
      shouldConfirmOldTicket
    } = this.state;


    if (shouldConfirmOldTicket) {
      return (
        <React.Fragment>
          Este ticket tiene una antiguedad mayor a 2 días, ¿Está seguro que desea digitalizarlo?
          <Button
            color='blue'
            disabled={(viewState === 'ticket_digitize_loading')}
            onClick={this.acceptOldTicket}
          >
            Si
          </Button>
          <Button
            color='grey'
            onClick={cancel}
          >
            Cancelar
          </Button>
        </React.Fragment>
      )
    }

    let ticketRef, date, hour, minutes
    newTicketReference ? ticketRef = newTicketReference : ticketRef = '--'
    newTicketDate ? date = moment(newTicketDate).format('DD / MM / YYYY') : date = '--' 
    newTicketHour ? hour = newTicketHour : hour = '--'
    newTicketMinute ? minutes = newTicketMinute : minutes = '--'

    return (
      <div className={className}>
        <p>Porfavor, confirme los datos de la digitalización</p>
        <p className='username'>@{selectedUser.username}</p>
        <p><b>Ticket físico #:</b> {ticketRef}</p>
        <p><b>Fecha de entrada:</b> {date}</p>
        <p><b>Hora de entrada:</b> {hour}:{minutes}</p>
        <Button
          color='blue'
          disabled={(viewState === 'ticket_digitize_loading')}
          onClick={digitizeTicket}
        >
          {viewState === 'ticket_digitize_confirmation' && 'Aceptar'}
          {viewState === 'ticket_digitize_loading' && 'Digitalizando...'}
        </Button>
        <Button
          color='grey'
          onClick={cancel}
        >
          Cancelar
        </Button>
      </div>
    )
  }
}

export { DigitizeConfirmationModel }