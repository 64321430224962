import React, { useContext } from "react";
import { TransfersContext } from "../../context";
import {
  currencyAmount,
  mapStatus,
  checkField,
  checkDate,
} from "services/utils";
import { Button } from "components/buttons";
import { PanelTitle } from "components/panel";
import { IconBack } from "components/icon-back";
import AssignTo from "../assign-to";
import { useChangeStatus } from "../../services/change-status";
import moment from "moment";

const StatusButtons = ({
  status,
  onApprove,
  onAssign,
  onReject,
  className,
}) => {
  if (status !== "pending") return null;
  return (
    <div className={className}>
      <Button color="blue" onClick={onApprove} width="30%">
        Aprobar
      </Button>
      <Button color="green" onClick={onAssign} width="30%">
        Asignar
      </Button>
      <Button color="red" onClick={onReject} width="30%">
        Rechazar
      </Button>
    </div>
  );
};

const TransferDetailModel = ({ className }) => {
  useChangeStatus();
  const { state, changeState, throwPopupMessage, viewType } =
    useContext(TransfersContext);

  const transfer = state.selectedTransfer;
  const status = transfer && transfer.status ? transfer.status : "";
  const onApprove = (event, sendUsername = false) => {
    event.preventDefault();
    changeState({ changeStatus: true, sendUsername, newStatus: "approved" });
  };
  const onReject = (event) => {
    event.preventDefault();
    changeState({
      changeStatus: true,
      newStatus: "rejected",
      sendUsername: false,
    });
  };

  const onUsernameChange = (event) => {
    const username = event.target.value;
    changeState({ username });
  };

  const onAssign = (event) => {
    event.preventDefault();
    throwPopupMessage(
      "",
      <AssignTo
        selectedTransfer={state.selectedTransfer}
        onApprove={(event) => {
          onApprove(event, true);
        }}
        onUsernameChange={onUsernameChange}
      />
    );
  };

  const handleBack = (event) => {
    event.preventDefault();
    changeState({ selectedTransfer: null, showDetail: false });
  };

  const phone = viewType === "phone";
  const hasHour = checkField(transfer, "movement_hour") !== "N/A";
  return (
    <div className={className}>
      {phone && <IconBack handleBack={handleBack} />}
      <PanelTitle>Transferencia</PanelTitle>
      <h5 className="title">Descripción:</h5>
      <p className="content">{checkField(transfer, "description")}</p>
      <h5 className="title">Estado:</h5>
      <p className="content">{mapStatus(checkField(transfer, "status"))}</p>
      <h5 className="title">Monto:</h5>
      <p className="content">
        {currencyAmount(checkField(transfer, "amount"), "Bs.")}
      </p>
      <h5 className="title">N° de Referencia:</h5>
      <p className="content">{checkField(transfer, "reference")}</p>
      <h5 className="title">Fecha:</h5>
      <p className="content">
        {checkDate(checkField(transfer, "movement_date"))}{" "}
        {hasHour
          ? moment(transfer.movement_hour, "h:mm:ss").format("h:mm a")
          : ""}
      </p>
      <h5 className="title">Usuario posible:</h5>
      <p className="content">{checkField(transfer, "possible_username")}</p>
      <StatusButtons
        status={status}
        onAssign={onAssign}
        onApprove={onApprove}
        onReject={onReject}
        className="buttons"
      />
    </div>
  );
};

export default TransferDetailModel;
