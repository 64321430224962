import React from "react";
import UserListItem from "../user-list-item";
import defaultUserGrey from "assets/img/default-user-grey.png";
import { users_admin as viewTexts } from "assets/strings/texts";

const UserListItemModel = ({
  username,
  email,
  mobilePhone,
  idNumber,
  profileImg,
  index,
  handleUserSelection,
  className,
}) => {
  profileImg = profileImg ? profileImg : defaultUserGrey;
  return (
    <dd id={index} onClick={handleUserSelection} className={className}>
      <img className="img" src={profileImg} alt="user avatar" />
      <div className="data">
        <p className="username">@{username}</p>
        {/* <p className="email">{email}</p> */}
        <p className="phone">{mobilePhone}</p>
        <p className="idNumber">{idNumber}</p>
      </div>
    </dd>
  );
};

function formatUserList(users, viewState, handleUserSelection, loading) {
  let formatUserList = [];
  if (loading) {
    return <div className="ui active loader"></div>;
  }
  if (!users.length) {
    return <div className="not-found">{viewTexts.notFound}</div>;
  }
  users.forEach((user, index) => {
    let idNumber = "";
    if (
      user.profile &&
      user.profile.identification &&
      user.profile.identification.doc_type &&
      user.profile.identification.doc_id
    ) {
      idNumber =
        user.profile.identification.doc_type +
        "-" +
        user.profile.identification.doc_id;
    }
    formatUserList.push(
      <UserListItem
        index={index}
        username={user.username}
        email={user.email}
        mobilePhone={
          user && user.profile && user.profile.mobile_phone
            ? user.profile.mobile_phone
            : "--"
        }
        idNumber={idNumber}
        handleUserSelection={handleUserSelection}
        key={user._id}
      />
    );
  });

  return formatUserList;
}

export default UserListItemModel;
export { formatUserList };
