import React from "react";
//import Ticket from "./ticket";
import Ticket from "components/ticket";
import { Grid } from "semantic-ui-react";
import PopupMessage from "components/popup-message";
import SearchTicketsForm from "./search-tickets-form";
import { OptionConfirmation } from "./option-confirmation";
import { EntitiesList, Pagination } from "components/entities-list";
import {
  formatTicketsList,
  formatTicketsListLessInfo,
} from "components/ticket-list-item";
import { RightBorderGreyColumn } from "components/styled-grid-column";
import { posDirectoBlue } from "../../../assets/strings/colors";

const MposTicketsSearchView = ({
  viewState,
  state,
  searchType,
  countTickets,
  searchParam,
  fromDate,
  endDate,
  pd_fee,
  iva,
  gift,
  regular,
  premium,
  monthlyPremium,
  ready,
  paid,
  closed,
  closedPremiumNoTransaction,
  closedSuperadmin,
  discarded,
  tickets,
  nextPageExist,
  currentPage,
  selectedTicket,
  showTicket,
  ticketOptions,
  confirm,
  popupMessages,
  popupMessagesType,
  showPopup,
  toggleOptionConfirmation,
  permissions,
  ticketOptionSelected,
  accessOptions,
  showConfirmButton,

  exitTicketDate,
  handleTicketExitDate,
  exitTicketHour,
  handleTicketExitHour,
  exitTicketMinute,
  handleTicketExitMinute,

  paidTicketDate,
  handlePaidTicketDate,
  paidTicketHour,
  handlePaidTicketHour,
  paidTicketMinute,
  handlePaidTicketMinute,

  creationRule,
  handleTicketCreation,

  handleBuildingSearch,
  buildingName,
  buildingSearch,
  category,

  handleTypesButton,
  handleAccessOptions,
  handleOptionsSelection,
  handleSearchTypeButtons,
  handleSearchInputChange,
  handleFromDate,
  handleEndDate,
  handleStatusButton,
  handleSubmit,
  handlePageUp,
  handlePageDown,
  handleTicketSelection,
  closePopup,
  newUsersRule,
  handleNewUsers,
}) => {
  return (
    <Grid padded>
      <PopupMessage
        messageType={popupMessagesType}
        showPopup={showPopup}
        messages={popupMessages}
        closePopup={closePopup}
      />
      {(viewState === "option_confirmation" ||
        viewState === "option_done" ||
        viewState === "option_loading") && (
        // If view IS on confirmation process, show confirmation popup
        <PopupMessage
          messageType={popupMessagesType}
          showPopup={showPopup}
          messages={popupMessages}
          closePopup={toggleOptionConfirmation}
        >
          {(viewState === "option_confirmation" ||
            viewState === "option_loading") && (
            <OptionConfirmation
              viewState={viewState}
              ticket={selectedTicket}
              confirm={confirm}
              handleAccessOptions={handleAccessOptions}
              accessOptions={accessOptions}
              showConfirmButton={showConfirmButton}
              ticketOptionSelected={ticketOptionSelected}
              cancel={toggleOptionConfirmation}

              ticketData={selectedTicket}

              exitTicketDate={exitTicketDate}
              handleTicketExitDate={handleTicketExitDate}
              exitTicketHour={exitTicketHour}
              handleTicketExitHour={handleTicketExitHour}
              exitTicketMinute={exitTicketMinute}
              handleTicketExitMinute={handleTicketExitMinute}

              paidTicketDate={paidTicketDate}
              handlePaidTicketDate={handlePaidTicketDate}
              paidTicketHour={paidTicketHour}
              handlePaidTicketHour={handlePaidTicketHour}
              paidTicketMinute={paidTicketMinute}
              handlePaidTicketMinute={handlePaidTicketMinute}
            />
          )}
        </PopupMessage>
      )}
      <RightBorderGreyColumn width={8}>
        <SearchTicketsForm
          // props
          searchType={searchType}
          searchParam={searchParam}
          countTickets={countTickets}
          fromDate={fromDate}
          endDate={endDate}
          gift={gift}
          regular={regular}
          premium={premium}
          monthlyPremium={monthlyPremium}
          ready={ready}
          paid={paid}
          closed={closed}
          closedPremiumNoTransaction={closedPremiumNoTransaction}
          closedSuperadmin={closedSuperadmin}
          discarded={discarded}
          permissions={permissions}
          phoneView={false}
          handleBuildingSearch={handleBuildingSearch}
          buildingName={buildingName}
          buildingSearch={buildingSearch}
          category={category}
          creationRule={creationRule}
          handleTicketCreation={handleTicketCreation}
          //methods
          handleSearchTypeButtons={handleSearchTypeButtons}
          handleSearchInputChange={handleSearchInputChange}
          handleFromDate={handleFromDate}
          handleEndDate={handleEndDate}
          handleTypesButton={handleTypesButton}
          handleStatusButton={handleStatusButton}
          handleSubmit={handleSubmit}
          newUsersRule={newUsersRule}
          handleNewUsers={handleNewUsers}
        />
        <EntitiesList
          height={
            category !== "pagodirecto"
              ? //</RightBorderGreyColumn>? "calc(80vh - 170px)"
                "calc(80vh - 170px)"
              : "calc(80vh - 170px - 6rem)"
          }
          viewState={viewState}
          entities={tickets}
          additionalArgs={!pd_fee && !iva ? [] : [{ pd_fee, iva, category }]}
          formattingFunction={
            category === "pagodirecto"
              ? formatTicketsList
              : formatTicketsListLessInfo
          }
          selectionHandler={handleTicketSelection}
        >
          <Pagination
            padding="0.65rem 0 0.65rem 0"
            nextPageExist={nextPageExist}
            currentPage={currentPage}
            handlePageUp={handlePageUp}
            handlePageDown={handlePageDown}
          >
            <div
              style={{
                background: posDirectoBlue,
                color: "#FFFFFF",
                padding: "0.5rem 0rem 0.5rem 0rem",
                textAlign: "center",
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
              Cantidad de Tickets:
              {" " + countTickets}
            </div>
          </Pagination>
        </EntitiesList>
      </RightBorderGreyColumn>
      <Grid.Column width={8}>
        <Ticket
          viewState={viewState}
          state={state}
          category={category}
          ticketData={selectedTicket}
          showTicket={showTicket}
          ticketOptions={ticketOptions}
          toggleOptionConfirmation={toggleOptionConfirmation}
          ticketOptionSelected={ticketOptionSelected}
          handleOptionsSelection={handleOptionsSelection}
          popupMessages={popupMessages}
          popupMessagesType={popupMessagesType}
          closePopup={closePopup}
        />
      </Grid.Column>
    </Grid>
  );
};

export default MposTicketsSearchView;
