import React from "react";

const TDCDetailsModel = ({ tdcDetails }) => {
  if (!tdcDetails) return null;
  return (
    <>
      {tdcDetails.description && <p className="title">{"Descripción"}</p>}
      {tdcDetails.description && (
        <p className="content sm">{tdcDetails.description}</p>
      )}
      {tdcDetails.dni && <p className="title">{"Identificación"}</p>}
      {tdcDetails.dni && <p className="content sm">{tdcDetails.dni}</p>}
      {tdcDetails.name && <p className="title">{"Nombre"}</p>}
      {tdcDetails.name && <p className="content sm">{tdcDetails.name}</p>}
      {tdcDetails.usd_amount && <p className="title">{"Monto en USD"}</p>}
      {tdcDetails.usd_amount && (
        <p className="content sm">{tdcDetails.usd_amount}</p>
      )}
      {tdcDetails.usd_exchange_amount && (
        <p className="title">{"Tasa de cambio"}</p>
      )}
      {tdcDetails.usd_exchange_amount && (
        <p className="content sm">{`${tdcDetails.usd_exchange_amount} VES`}</p>
      )}
    </>
  );
};

export { TDCDetailsModel };
