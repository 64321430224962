import DirectoryModel from "./model";
import styled from "styled-components";
import { grey_6 } from "assets/strings/colors";

const Directory = styled(DirectoryModel)`
  #root & {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    > .title {
      margin: 0;
      padding: 1rem 0 0 0;
      align-self: center;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 1.2rem;
      color: ${grey_6};
    }
    > .input-container {
      margin-bottom: 1rem;
    }
  }
`;

export default Directory;
