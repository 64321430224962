import React, { useContext } from "react";
import { WalletAccountsContext } from "../../context";
import styled from "styled-components";
import { Panel } from "components/panel";
import { CSSTransition } from "react-transition-group";
import { CounterPartSelect } from "components/counterpart-select";
import { useStateManager } from "hooks/use-state-manager";
import { Button } from "components/buttons";
import AmountInput from "components/amount-input";
import { grey_6 } from "assets/strings/colors";
import useTransfer from "../../hooks/useTransfer";
import { Loader } from "components/loader";

const TransferFormModel = ({ data, show, className }) => {
  if (!data || !show) return null;
  const {
    throwPopupMessage,
    setFetchAccounts,
    changeState: changeGlobalState,
  } = useContext(WalletAccountsContext);

  const initialState = {
    loading: false,
    issuer_id: data._id,
    transfer_user_id: "",
    amount: "",
    concept: "",
    receiver_commerce: false,
  };

  const [state, changeState] = useStateManager(initialState);
  const submit = useTransfer({
    initialState,
    state,
    changeState,
    throwPopupMessage,
    setFetchAccounts,
    changeGlobalState,
  });

  const onChangeCounterpart = (data) => {
    if (data.commerce_counterpart_id) {
      changeState({
        receiver_commerce: true,
        transfer_user_id: data.commerce_counterpart_id,
      });
      return;
    }

    changeState({ transfer_user_id: data.counterpart_id });
  };

  const onChangeInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    changeState({ [name]: value });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    submit(true);
  };

  if (state.loading) return <Loader margin="4rem 0 0 0" />;
  return (
    <CSSTransition
      in={data && true}
      timeout={350}
      appear={data && true}
      classNames="panel"
    >
      <Panel>
        <form onSubmit={onSubmit} className={className}>
          <CounterPartSelect onChangeCounterpart={onChangeCounterpart} />

          <AmountInput
            placeholder="Monto"
            type="text"
            name="amount"
            id="amount"
            value={state.amount}
            onChangeInput={onChangeInput}
          />

          <input
            placeholder="Concepto"
            type="text"
            name="concept"
            id="concept"
            onChange={onChangeInput}
          />

          <Button
            color="blue"
            disabled={
              !state.amount || !state.concept || !state.transfer_user_id
            }
          >
            Enviar fondos
          </Button>
        </form>
      </Panel>
    </CSSTransition>
  );
};

const TransferForm = styled(TransferFormModel)`
  #root & {
    width: 100%;
    height: 35vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > input {
      border: none;
      width: 100%;
      height: 4rem;
      border-radius: 1rem;
      color: ${grey_6};
      font-size: 1.5rem;
      margin: 1rem 0 0.2rem 0;
      text-align: center;
      outline: none;
      box-shadow: none;
      -webkit-transition: box-shadow 250ms linear;
      transition: box-shadow 250ms linear;
      :focus {
        box-shadow: 0 1px darkgrey;
      }
      ::-webkit-input-placeholder {
        color: lightgray;
      }
      :-ms-input-placeholder {
        color: lightgray;
      }
      ::-ms-input-placeholder {
        color: lightgray;
      }
      ::placeholder {
        color: lightgray;
      }
    }
  }
`;

export default TransferForm;
