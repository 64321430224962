import React, { useContext } from "react";
import { Button } from "components/buttons";
import { general as texts } from "assets/strings/texts";
import { recharge_validate as viewTexts } from "assets/strings/texts";
import { SearchInputRounded } from "components/search-input";
import { MobilePaidsContext } from "../../context";
import { useEnter } from "hooks/use-enter";
import { useFetchMobilePaids } from "../../services";
import { useGetConstants } from "../../services/constants";

const MobilePaidsFormModel = ({ className }) => {
  const { state, changeState } = useContext(MobilePaidsContext);

  const onInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (value.match(/^[0-9]*\.?[0-9]*$/)) {
      changeState({
        formData: { ...state.formData, [name]: value },
      });
    }
  };

  const onButtonsSelections = (event) => {
    event.preventDefault();
    const status = event.target.id;
    let newStatus = [];
    if (!state.formData.status.find((s) => s === status))
      newStatus = [...state.formData.status, status];
    else newStatus = [...state.formData.status.filter((s) => s !== status)];
    changeState({
      formData: {
        ...state.formData,
        status: [...newStatus],
      },
    });
  };

  const statusPressed = (value) =>
    state.formData.status.find((s) => s === value);

  const statusColor = (value) => (statusPressed(value) ? "blue" : "grey_1");

  const onSubmit = (event) => {
    event.preventDefault();
    changeState({ submitForm: true, formData: { ...state.formData, page: 1 } });
  };

  useGetConstants();
  useEnter(onSubmit, state);
  useFetchMobilePaids();

  return (
    <form className={className} onSubmit={onSubmit}>
      <div className="values-inputs">
        <label>Buscar por:</label>
        <SearchInputRounded
          handleChange={onInputChange}
          searchParam={state.formData.phone}
          placeholder="Teléfono"
          maxLength={11}
          name="phone"
          width="32%"
        />
        <SearchInputRounded
          handleChange={onInputChange}
          searchParam={state.formData.amount}
          placeholder={"Monto"}
          name="amount"
          width="32%"
        />
        <SearchInputRounded
          handleChange={onInputChange}
          searchParam={state.formData.reference}
          placeholder={"Referencia"}
          name="reference"
          width="32%"
        />
      </div>

      <label className="label" key="status-selector-label">
        {viewTexts.rechargeStatus}
      </label>
      <div className="status container" key="status-selector-buttons">
        <Button
          color={statusColor("approved")}
          pressed={statusPressed("approved")}
          id="approved"
          onClick={onButtonsSelections}
        >
          {texts.approved}
        </Button>
        <Button
          color={statusColor("pending")}
          pressed={statusPressed("pending")}
          id="pending"
          onClick={onButtonsSelections}
        >
          {texts.pending}
        </Button>

        <Button
          color={statusColor("deferred")}
          pressed={statusPressed("deferred")}
          id="deferred"
          onClick={onButtonsSelections}
        >
          {texts.deferred}
        </Button>
      </div>

      <Button color="grey">Buscar</Button>
    </form>
  );
};

export default MobilePaidsFormModel;
