import { SearchModel } from "./model";
import styled from "styled-components";
import { grey_5, bluePD } from "assets/strings/colors";

const Search = styled(SearchModel)`
  #root && {
    height: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > .title {
      ${(props) =>
        props.viewType !== "phone" ? "" : "margin: 0.5rem 0 0.5rem 0;"};
      font-size: 1.1rem;
      font-weight: bold;
    }

    > .search {
      display: flex;
      align-items: center;
      justify-content: center;
      width: ${(props) => (props.viewType !== "phone" ? "30%" : "60%")};
      height: 4vh;
      min-height: 28px;
      border: 1px solid ${grey_5};
      border-radius: 3rem;
      > input {
        background: transparent;
        text-align: center;
        font-size: 0.8rem;
        border: none;
        width: 90%;
        :focus {
          outline: none;
        }
      }
      > i {
        margin: 0;
        padding-right: 2rem;
        width: 10%;
        color: ${bluePD};
        cursor: pointer;
      }
      :focus {
        outline: none;
        border: 1.5px solid ${bluePD};
      }
    }
  }
`;

export { Search };
