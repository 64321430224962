import styled from "styled-components";
import { grey_6 } from "assets/strings/colors";

const Panel = styled.div`
  #root & {
    position: relative; /*Needs to be positionated for loader*/
    background: white;
    min-height: 100px;
    padding: 1.3rem;
    margin: 1.3rem;
    border-radius: 0.2rem;
    box-shadow: 1px 2px 8px 0 rgba(34, 36, 38, 0.4);
    max-height: ${(props) => (props.maxHeight ? props.maxHeight : "auto")};
    height: ${(props) => (props.height ? props.height : "auto")};
  }
  #root &.inactive {
    opacity: 0.2;
  }

  #root &.panel-appear {
    opacity: 0;
    transform: translateX(30px);
  }
  #root &.panel-appear-active {
    opacity: 0.8;
    transform: translateX(0px);
    transition: all 350ms ease-out;
  }
  #root &.panel-enter {
    opacity: 0;
    transform: translateX(30px);
  }
  #root &.panel-enter-active {
    opacity: 1;
    transform: translateX(0px);
    transition: all 300ms ease-out;
  }
  #root &.panel-enter-done {
    transform: none;
    transition: all 50ms ease-out;
  }
  #root &.panel-exit {
    opacity: 0.8;
    transform: translateX(-2px);
  }
  #root &.panel-exit-active {
    opacity: 0;
    transform: translateX(30px);
    transition: all 350ms ease-out;
  }
`;

const PanelTitle = styled.h3`
  #root & {
    text-transform: uppercase;
    font-size: 1.2rem;
    text-align: center;
    color: #2c304d;
    padding: 1rem 0 1rem 0;
    margin: 0 1.5rem 0 1.5rem;
  }
`;

const PanelPlaceholder = styled.p`
  #root & {
    text-align: center;
    margin: 2rem;
  }
`;

const PanelSectionHeader = styled.p`
  #root & {
    font-size: 0.85rem;
    border-bottom: 1px solid ${grey_6};
    display: inline-block;
    text-transform: uppercase;
    font-weight: bold;
    color: ${grey_6};
    margin: 0.5rem 0 0 0;
  }
`;

const PanelSectionContent = styled.p`
  #root & {
    color: ${grey_6};
    margin: 0;
    font-size: 1.1rem;
    text-align: center;
  }
`;

export {
  Panel,
  PanelTitle,
  PanelPlaceholder,
  PanelSectionHeader,
  PanelSectionContent,
};
