import styled from 'styled-components'
import { RechargeConfirmationModel } from './model'

const RechargeConfirmation = styled(RechargeConfirmationModel)`
  #root &{
    >p{
      margin: 0;
    }
    >.username{
      color: #63c0e8;
      font-size: 1.5rem;
      text-align: center;
    }
  }
`

export { RechargeConfirmation }