import React from "react";
import ExtensionsListItem from "../extensions-list-item";
import { users_admin as viewTexts } from "assets/strings/texts";

const carnetStatusMapped = {
  "in-progress": "en proceso",
  printed: "impreso",
  delivered: "entregado",
};

const ExtensionsListItemModel = ({
  carnet,
  name,
  index,
  handleChildEdit,
  className,
}) => {
  return (
    <dd id={index} onClick={handleChildEdit} className={className}>
      <div className="data">
        <div className="status" />
        <p className="name">{name}</p>
        <p className="carnet">Carnet {carnetStatusMapped[carnet]}</p>
      </div>
    </dd>
  );
};

function formatExtensionsList(children, viewState, handleChildEdit, loading) {
  let formatList = [];
  if (loading) {
    return <div className="ui active loader"></div>;
  }
  if (!children.length) {
    return <div className="not-found">{viewTexts.childrenNotFound}</div>;
  }
  children.forEach((child, index) => {
    formatList.push(
      <ExtensionsListItem
        index={index}
        status={child.status}
        name={child.name}
        carnet={child.is_printed}
        handleChildEdit={handleChildEdit}
        key={child._id}
      />
    );
  });

  return formatList;
}

export default ExtensionsListItemModel;
export { formatExtensionsList };
