import React from "react";
import { Grid } from "semantic-ui-react";
import PopupMessage from "components/popup-message";
//intern components
//import { Form } from "./form";
import { Filters } from "./filters";
import { TransactionsList } from "./transactions-list";
import { TransactionDetail } from "./transaction-detail";

const OvertimeTicketsTransactionsPhoneView = ({
  viewType,
  transactionSelected,
  transactionList,
  handleTransactionSelection,
  showDetail,
  loading,
  handleRefresh,
  handleApprove,
  handleBack,
  fromDate,
  endDate,
  handleFromDate,
  handleEndDate,
  popupMessages,
  popupMessagesType,
  showPopup,
  closePopup,
  showList,
  nextPageExists,
  page,
  handlePageUp,
  handlePageDown,
}) => {
  return (
    <Grid padded>
      <PopupMessage
        messageType={popupMessagesType}
        showPopup={showPopup}
        messages={popupMessages}
        closePopup={closePopup}
      />
      <Grid.Column width={16}>
        {showList && (
          <Filters
            fromDate={fromDate}
            endDate={endDate}
            handleFromDate={handleFromDate}
            handleEndDate={handleEndDate}
            handleSubmit={handleRefresh}
            phoneView
          />
        )}
        {showList && (
          <TransactionsList
            transactionList={transactionList}
            handleTransactionSelection={handleTransactionSelection}
            loading={loading}
            nextPageExists={nextPageExists}
            page={page}
            handlePageUp={handlePageUp}
            handlePageDown={handlePageDown}
          />
        )}

        {!showList && (
          <TransactionDetail
            viewType={viewType}
            transactionSelected={transactionSelected}
            showDetail={showDetail}
            handleApprove={handleApprove}
            handleBack={handleBack}
          />
        )}
      </Grid.Column>
    </Grid>
  );
};

export { OvertimeTicketsTransactionsPhoneView };
