import template from "lodash/template";

export const errors = {
  AUTHENTICATION:
    "Ha ocurrido un error de autenticación, inicie sesión de nuevo.",
  MISSING_FIELDS: "Faltan campos obligatorios.",
  MISSING_REQUIRED_DATA:
    "Ha ocurrido un error al cargar está página, por favor intente refrescarla.",
  GENERAL_ERR: "Ha ocurrido un error, intente más tarde.",
};

export const commerceCategories = [
  "alimentacion",
  "alojamiento",
  "transporte",
  "publicidad",
  "educacion",
  "banca",
  "hosteleria",
  "hogar",
  "mascotas",
  "agricultura",
  "salud",
  "belleza",
  "moda",
  "tecnologia",
  "departamento",
  "entretenimiento",
  "none",
];

export const billingTypes = {
  prepaid: "Pre-pago",
  postpaid: "Post-pago",
};

export const general = {
  not: "No",
  accept: "Aceptar",
  loading: "Cargando",
  continue: "Continuar",
  back: "Volver",
  delete: "Eliminar",
  block: "Bloquear",
  unblock: "Desbloquear",
  add: "Agregar",
  edit: "Editar",
  hide: "Ocultar",
  show: "Mostrar",
  longDateFormat: "dddd, D [de] MMMM YYYY",
  shortDateHourFormat: "DD /MM /YYYY HH:mm",
  shortDateFormat: "DD /MM /YYYY",
  timeFormat: "HH:mm",
  today: "Hoy",
  to: "Hasta",
  from: "Desde",
  latests: "Útimos",
  latest: "Útimos",
  dateRange: "Rango de fechas",
  paid: "Pagado",
  pending: "Pendiente",
  overdue: "Vencido",
  approved: "Aprobado",
  reported: "Reportado",
  deferred: "Diferido",
  rejected: "Rechazado",
  reversed: "Reversado",
  processing: "En proceso",
  username: "nombre de usuario",
  phoneNumber: "número de teléfono",
  entriesOf: "Entradas del ",
  ticketsCuantity: "Cantidad de tickets: ",
  notFound: "No se han encontrado ",
  user: "Usuario",
  name: "Nombre",
  type: "Tipo",
  email: "Email",
  phone: "Teléfono pago móvil",
  contactPhone: "Teléfono de contacto",
  id: "Identificación",
  role: "Rol",
  status: "Estado",
  verifyed: "Verificado",
  userSearchPlaceholder: "Buscar usuario",
  wait: "Espere",
  tickets: "Tickets",
  rate: "Tarifa",
  available: "Disponible",
  prepaid: "Prepago",
  postpaid: "Postpago",
  income: "Ingresos",
  expenditures: "Egresos",
  withdrawals: "Retiros",
  withdrawValidate: "Validar Retiro",
  currency: { bs: "VES", usd: "USD" },
  registerDate: "Fecha de registro",
};

export const mpos = {
  ticketsValidateTitle: "Habilitar Ticket",
  ticketsHistoryTitle: "Historial de Tickets",
  ticketsDigitizeTitle: "Digitalizar Ticket",
  ticketsOvertimedTitle: "Aprobar Transacciones",
  dashboardTitle: "Resumen",
  commercesInvoicesTitle: "Administrador de Comercios",
  invoicesHistoryTitle: "Historial de Recibos",
  wallet: "Bancamiga Wallet",
  commerceMovements: "Historial de Movimientos",
  commerceUsers: "Equipo de trabajo",
  commerceSettings: "Configuración",
  withdrawHistoryTitle: "Historial de Retiros",
  configTitle: "Configuración",
  rechargesValidateTitle: "Habilitar Recargas",
  rechargesRechargeTitle: "Recargar",
  buildingSettingsTitle: "Ajustes",
  buildingPdfeeTitle: "Fees",
  buildingCommercesTitle: "Comercios Inscritos",
  usersAdminTitle: "Administrador de Usuarios",
  usersReportsTitle: "Reportes",
  usersExtensionsTitle: "Extensiones",
  usersMovementsMobilePaidTitle: "Pagos Móviles",
  usersMovementsTransfersTitle: "Transferencias",
  usersLogsTitle: "Reportes de Conexión BLE",
  usersNotificationsTitle: "Centro de Notificaciones",
  updateReady: "Existe una versión más reciente de esta aplicación.",
  updateButton: "Haga click aquí para actualizar.",
  closeButton: "Haga click aquí para cerrar.",
  blockedNotification:
    "Su usuario se encuentra bloqueado, comuníquese con nosotros para solventarlo.",
  boxOffice: "Taquilla de Estacionamiento",
  registerBoxoffice: "Registro  de Taquilla",
  support: "Información de Soporte",
  amountHistory: "Posición consolidada",
  transfer: "Transferencias",
  transferHistory: "Histórico de Transferencias",
  todayMovements: "Movimientos de hoy",
  monthlyPremiumActivate: "Activar premium",
  monthlyPremiumInvoices: "Recibos de premiums activados",
  monthlyPremiumHistory: "Historial de premiums",
  superadminBills: "Facturas",
  superadminBillsHistory: "Historial de Facturas",
  superadminAccounts: "Cuentas de wallet PagoDirecto",
  usersNotificationsDiscountCodesTitle: "Códigos de descuento",
};

export const tickets_validate = {
  searchPlaceholder: "Buscar Ticket",
  sumaryBar: "TICKET ",
  noSelection: "No ha seleccionado ningún ticket",
  calculating: "Calculando...",
  retry: "Reintentar",
  billHeader: "COMPROBANTE",
  validate: "Habiltar Ticket",
  validateConfirmation: "¿Desea habilitar el ticket seleccionado?",
  validateLoading: "Habilitando...",
  ticket: "Ticket",
  pdFee: "Servicio Directo",
  commerceFee: "Comisión Comercio",
  subTotal: "Subtotal",
  taxName: "IVA",
  total: "Total",
  successValidate: "TICKET HABILITADO",
};

export const commerces_admin = {
  searchPlaceholder: "Buscar Comercios",
  preSearch: "Ingrese el nombre de un comercio en la barra de busqueda.",
  commercesNotFound: "No se han encontrado comercios.",
  commerceAddressHeader: "dirección",
  commerceIDHeader: "RIF",
  commerceContactHeader: "persona de contacto",
  commercePhoneHeader: "teléfono",
  commerceWallet: "Wallet",
  commerceBillingType: "tipo de facturación",
  blockCommerce: "Bloquear comercio",
  changeBillingType: "Cambiar facturación",
  viewInvoices: "Ver recibos",
  markAsPaid: "Registrar pago",
  invoiceTotalHeader: "total",
  withdrawTotalHeader: "total",
  invoicePeriodHeader: "período",
  paymentFormHeader: "A continuación, ingrese los datos del pago",
  paymentFormBillRef: "# de Factura física",
  paymentFormPayRef: "# Transferencía / Cheque",
  paymentFormPayType: "Tipo de pago",
  paymentFormPayTypePaycheck: "Cheque",
  paymentFormPayTypeWireTransfer: "Transferencía",
  paymentFormPayTypePlaceholder: " -- ",
  prepaidTickets: "Tickets prepagados",
  addTickets: "Agregar tickets",
  showWithdrawals: "Mostrar Retiros",
  date: "Fecha",
  // eslint-disable-next-line
  prepaidTicketsConfirmationMessage: template(
    "¿Está seguro que desea agregar <%= numOfTickets %> tickets prepagados a <%= commerce %>?"
  ),
};

export const recharge_validate = {
  rechargeStatus: "Estado de recarga",
  mobilePaidStatus: "Estado de Pago Móvil",
  pagoMovil: "PagoMovil",
  wireTransfer: "Transferencia",
  pagodirecto: "PagoDirecto",
  credicard: "Credicard",
  tdc: "TDC",
  ref: "Ref.:",
  rechargesOf: "Recargas del ",
  rechargeValidationTitle: "recarga",
  paymentDateTitle: "fecha de solicitud",
  mobilePaidDateTitle: "fecha de pago",
  paymentIssuesPhone: "fecha de solicitud",
  rechargeAmountTitle: "monto de recarga",
  mobilePaidAmountTitle: "monto pagado",

  paymentReferenceTitle: "referencía del pago",
  paymentBankTitle: "Banco receptor de pagodirecto",
  validateRecharge: "Aprobar recarga",
  reportRecharge: "Recarga invalida",
  rejectRecharge: "Rechazar recarga",
  rechargeSearch:
    "Ingrese el número de referencia de la recarga que desea habilitar",
  rechargesNotFound: "No se han encontrado recargas",
  rechargeValidateConfirmation: "¿Desea aprobar la recarga seleccionada?",
  rechargeReportConfirmation:
    "¿Desea reportar la recarga seleccionada como invalida?",
  rechargeRejectConfirmation: "¿Desea rechazar la recarga seleccionada?",
  rechargeResultApproved: "Recarga Aprobada.",
  rechargeResultReported: "Recarga Reportada.",
  rechargeResultRejected: "Recarga Rechazada.",
  rechargeAPI: "API consultada",
  bankDetails: "Detalles Bancarios",
  bankName: "Banco:",
  accountName: "A Nombre de:",
  cardNumber: "Número de Tarjeta:",
  cardType: "Tipo de Tarjeta:",
  currency: "Moneda:",
  docId: "Documento:",
  causeRejection: "Motivo:",
};

export const recharges_recharge = {
  rechargeFormPlaceholder:
    "Seleccione un usuario para recargar su saldo PagoDirecto",
  rechargeFormTitle: "Seleccione el monto a recargar",
  noAmountSelected: "Recuerde selecionar el monto a recargar",
  rechargeConfirmation: "¿Desea realizar la siguiente recarga?",
  desiredAmount: "Seleccione el monto a recargar",
  recharge: "Recargar",
  recharging: "Recargando",
  successRecharge: "¡Recarga exitosa!",
  amount: "Monto",
  bankList: "Bancos Receptores de PagoDirecto",
};
export const withdraw = {
  withdrawFormTitle: "Introduzca el monto a retirar",
  noAccountSelected: "Recuerde selecionar una cuenta bancaria",
  withdrawConfirmation: "¿Desea realizar el siguiente retiro?",
  desiredAccount: "Seleccione una cuenta bancaria",
  desiredCurrency: "Seleccione el tipo de moneda",
  withdraw: "Retirar",
  withdrawing: "Retirando",
  successWithdraw: "¡Retiro exitoso!",
  amount: "Monto",
};
export const config = {
  changePinButton: "Cambiar PIN PD",
  changeRateButton: "Cambiar Tarifa",
  editAccount: "Editar cuenta",
  addAccount: "Añadir cuenta",
  configConfirmation: "Confirmar Cambios",
  showAccounts: "Mostrar cuentas bancarias",
  successConfig: "¡Cambios Realizados con Éxito!",
};

export const building_settings = {
  noWorkspaceSelected: "No se ha seleccionado un espacio de trabajo",
  header: "Espacio de trabajo seleccionado",
  rate: "Tarifa de estacionamiento",
  mtdSettingsTitle: "Modulos de Estacionamiento (MTD)",
  mac: "MAC",
  codeAndName: "Código y nombre",
  mtd: "MTD",
  macType: "Tipo de MAC",
  coordinates: "Coordenadas",
  lat: "Lat.:",
  long: "Long.:",
  rssi: "RSSI",
  rssi_android: "Android:",
  rssi_ios: "IOS:",
  track_index: "track",
  accessNamePlaceholder: "Entrada S1",
  accessStatusPlaceholder: "inactive",
  accessMacTypePlaceholder: "arduin",
  accessMacPlaceholder: "B1:BA",
  accessTypePlaceholder: "entry",
  accessLongPlaceholder: "32.157869",
  accessLatPlaceholder: "53.974910",
  accessAndroidRssiPlaceholder: "-90",
  accessIosRssiPlaceholder: "-70",
  trakIndexPlaceholder: "A-1",
};
export const users_admin = {
  userPlaceholder: "Seleccione un usuario para ver su información",
  notFound: "No se han encontrado usuarios",
  childrenNotFound: "No se han encontrado personas representadas",
  funds: "Fondos",
  BSfunds: "Fondos BS",
  BSblockedFunds: "Fondos BS bloqueados",
  USDblockedFunds: "Fondos USD bloqueados",
  USDfunds: "Fondos USD",
  appVersion: "Version de la APP",
};

export const building_admin = {
  notFound: "No se han encontrado edificios",
};

export const owners_dashboard = {
  ticketsDigitized: "Tickets digitalizados",
  ticketsPaid: "Tickets pagados",
  averageParkingTime: "Estadía media",
  availableTickets: "Tickets disponibles",
  ticketsValidated: "Tickets habilitados",
  dailyTransactions: "Transacciones del día",
  weeklyTransactions: "Transacciones de la semana",
  dailyFuel: "Suministro del día",
  weeklyFuel: "Suministro de la semana",
  averageIncome: "Ingreso promedio",
  averageFuel: "Suministro promedio",
  averageExpensePerPerson: "Valor promedio por persona",
  usdCurrency: " USD",
  BSCurrency: " BS",
  blockedBalance: "Saldo Bloqueado",
  availableBalance: "Saldo Disponible",
};
export const superadmin_dashboard = {
  today: "Hoy",
  yesterday: "Ayer",
  dailyPercent: "Variación diaria",
  week: "Esta semana",
  last_week: "Semana pasada",
  weeklyPercent: "Variación semanal",
  month: "Este mes",
  last_month: "Último mes",
  monthlyPercent: "Variación mensual",
  ticketsDigitized: "Tickets digitalizados",
  ticketsPaid: "Tickets pagados",
  averageParkingTime: "Estadía media",
  availableTickets: "Tickets disponibles",
  ticketsValidated: "Tickets habilitados",
  dailyTransactions: "Transacciones del día",
  weeklyTransactions: "Transacciones de la semana",
  dailyFuel: "Suministro del día",
  weeklyFuel: "Suministro de la semana",
  averageIncome: "Ingreso promedio",
  averageFuel: "Suministro promedio",
  averageExpensePerPerson: "Valor promedio por persona",
  usdCurrency: " USD",
  BSCurrency: " BS",
  blockedBalance: "Saldo Bloqueado",
  availableBalance: "Saldo Disponible",
};

export const box_office = {
  newUserDescription: "Opción que permite registrar un nuevo usuario",
  newRechargeDescription:
    "Opción que permite recargar el wallet de un usuario ya registrado",
  newTicketDescription: "Opción que permite digitalizar y habilitar tickets",
  validateEventsDescription:
    "Opción que permite validar entradas o inscripciones a eventos",
};

export const time_assets = [
  "00:00 hrs",
  "01:00 hrs",
  "02:00 hrs",
  "03:00 hrs",
  "04:00 hrs",
  "05:00 hrs",
  "06:00 hrs",
  "07:00 hrs",
  "08:00 hrs",
  "09:00 hrs",
  "10:00 hrs",
  "11:00 hrs",
  "12:00 hrs",
  "13:00 hrs",
  "14:00 hrs",
  "15:00 hrs",
  "16:00 hrs",
  "17:00 hrs",
  "18:00 hrs",
  "19:00 hrs",
  "20:00 hrs",
  "21:00 hrs",
  "22:00 hrs",
  "23:00 hrs",
];

export const days_of_weeks_assets = [
  "Lun",
  "Mar",
  "Mie",
  "Jue",
  "Vie",
  "Sáb",
  "Dom",
];

export const currencyMapped = {
  USD: "$ ",
  BS: "VES ",
};

export const feeTags = {
  p2p: "P2P",
  p2c: "P2C",
  movistar: "Movistar",
  movilnet: "Movilnet",
  digitel: "Digitel",
  simpletv: "SimpleTV",
  galanet: "Galanet",
  fuel: "Gasolina",
  withdraw: "Retiro",
  recharge: "Recarga",
  bancamiga: "Bancamiga",
  parking: "Estacionamiento",
};
export const servicesTags = {
  movistar: "Movistar",
  movilnet: "Movilnet",
  digitel: "Digitel",
  simpletv: "SimpleTV",
  galanet: "Galanet",
  inter: "Inter",
  cantv: "CANTV",
};
export const transferTags = {
  p2p: "P2P",
  p2c: "P2C",
  c2p: "C2P",
  c2c: "C2C",
};
