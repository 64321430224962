import React from "react";
import "moment/locale/es-us";
import { connect } from "react-redux";
import { clearReduxStore } from "redux/actions/general";
import MposGeneralDashboard from "./general";
import PDDashboard from "./pagodirecto";
import MposParkingDashboard from "./parking";

const MposDashboard = (props) => {
  const category = props.category;
  const defaultDashboard = <MposGeneralDashboard category={category} />;
  const dashboardsList = {
    pagodirecto: <PDDashboard />,
    estacionamiento: [
      <MposGeneralDashboard category={category} key="general" />,
      <MposParkingDashboard key="parking" />,
    ],
  };
  return dashboardsList[category] || defaultDashboard;
};

const mapStateToProps = null;
const mapDispatchToProps = {
  clearReduxStore,
};

export default connect(mapStateToProps, mapDispatchToProps)(MposDashboard);
