import React, { useEffect } from "react";
import { useStateManager } from "hooks/use-state-manager/hook";
import { connect } from "react-redux";
import { clearReduxStore } from "redux/actions/general";
import styled from "styled-components";
import { grey_bg, grey_3 } from "assets/strings/colors";
import Options from "./options";
import Features from "./features";

const MPosBoxOfficeView = ({ token, className }) => {
  const initialState = {
    viewType: "desktop",
    optionSelected: "validate-event",
  };

  const [state, changeState] = useStateManager(initialState);
  useEffect(() => {
    setViewType();
    window.addEventListener("resize", setViewType);
    return () => {
      window.removeEventListener("resize", setViewType);
    };
  }, []);

  const setViewType = () => {
    let documentElement = document.documentElement,
      width = window.innerWidth || documentElement.clientWidth;
    if (width < 768) {
      changeState({ viewType: "phone" });
    } else {
      changeState({ viewType: "desktop" });
    }
  };

  const handleNewTicket = (event) => {
    event.preventDefault();
    changeState({ optionSelected: "new-ticket" });
  };
  const handleNewUser = (event) => {
    event.preventDefault();
    changeState({ optionSelected: "new-user" });
  };
  const handleRecharge = (event) => {
    event.preventDefault();
    changeState({ optionSelected: "new-recharge" });
  };

  const handleValidateEvent = (event) => {
    event.preventDefault();
    changeState({ optionSelected: "validate-event" });
  };

  return (
    <div className={className}>
      <div className="options">
        <Options
          viewType={state.viewType}
          optionSelected={state.optionSelected}
          handleNewTicket={handleNewTicket}
          handleNewUser={handleNewUser}
          handleRecharge={handleRecharge}
          handleValidateEvent={handleValidateEvent}
        />
      </div>
      <div className="features">
        <Features
          viewType={state.viewType}
          optionSelected={state.optionSelected}
          token={token}
        />
      </div>
    </div>
  );
};

const MPosBoxOffice = styled(MPosBoxOfficeView)`
  #root && {
    height: calc(100vh - 4rem);
    min-height: 550px;
    width: 100%;
    background: ${grey_bg};
    display: flex;
    align-items: center;
    justify-content: space-between;
    > .features {
      height: calc(100vh - 4rem);
      min-height: 550px
      width: 65%;
     
    }
    > .options {
      height: calc(100vh - 4rem);
      min-height: 550px
      width: 35%;
      border-right: 1px solid ${grey_3};
    }
    @media(max-width: 768px){
      flex-direction: column-reverse;
      > .features {
        height: calc(90vh - 4rem);
        min-height: 450px;
        width: 100%;
        border-right: none;
        border-bottom: 1px solid ${grey_3};
      }
      > .options {
        height: calc(10vh - 4rem);
        width: 100%;
        border-right: none;
        min-height: 50px;
      }
    }
  }
`;

const mapStateToProps = null;

const mapDispatchToProps = {
  clearReduxStore,
};

export default connect(mapStateToProps, mapDispatchToProps)(MPosBoxOffice);
