import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Panel } from "components/panel";
import { Button } from "components/buttons";
import { getSession } from "redux/selectors";
import { errors } from "assets/strings/texts";
import { clearReduxStore } from "redux/actions/general";
import { getBuildings, updateBuildingRate } from "services/api";
import PopupMessage from "components/popup-message";
import { general as texts } from "assets/strings/texts";
import { building_settings as viewTexts } from "assets/strings/texts";
import { throwPopupMessage, closePopup } from "components/popup-message";
import { formatPrice, priceToRequest, inputPrice } from "services/utils";

import { addBuildings, selectBuilding } from "redux/actions/buildings";

class RateFormModel extends PureComponent {
  constructor(props) {
    super(props);
    let prevProps = props;
    let building = props.building;
    let activeMode =
      building && building.billing && building.billing.active_mode
        ? building.billing.active_mode
        : "FLAT";
    let billingArray =
      building && building.billing && building.billing.billing_mode
        ? building.billing.billing_mode
        : [];

    let billingObj = {};
    if (billingArray.length >= 0) {
      billingArray.map((billing_type) => {
        if (billing_type.tag === activeMode) {
          billingObj = billing_type;
        }
        return null;
      });
    }

    this.state = {
      prevProps,
      activeMode,
      billingObj,
      flatAmount: formatPrice(
        billingObj.flat_amount ? billingObj.flat_amount : 0
      ),
      flatAmountNumber: billingObj.flat_amount ? billingObj.flat_amount : 0,
      hourAmount: formatPrice(
        billingObj.hour_amount ? billingObj.hour_amount : 0
      ),
      hourAmountNumber: billingObj.hour_amount ? billingObj.hour_amount : 0,
      secondHourAmount: formatPrice(
        billingObj.second_hour_amount ? billingObj.second_hour_amount : 0
      ),
      secondHourAmountNumber: billingObj.second_hour_amount
        ? billingObj.second_hour_amount
        : 0,
      thirdHourAmount: formatPrice(
        billingObj.third_hour_amount ? billingObj.third_hour_amount : 0
      ),
      thirdHourAmountNumber: billingObj.third_hour_amount
        ? billingObj.third_hour_amount
        : 0,
      extraAmount: formatPrice(
        billingObj.extra_amount ? billingObj.extra_amount : 0
      ),
      extraAmountNumber: billingObj.extra_amount ? billingObj.extra_amount : 0,
      overnightAmount: formatPrice(
        billingObj.overnight_amount ? billingObj.overnight_amount : 0
      ),
      overnightAmountNumber: billingObj.overnight_amount
        ? billingObj.overnight_amount
        : 0,
      edit: false,
      loading: false,
      building,
      popupMessages: null,
      popupMessagesType: "",
      showPopup: false,
      authError: false
    };
    this.initialState = { ...this.state };
    // Bind PopupMessage functions
    this.throwPopupMessage = throwPopupMessage.bind(this);
    this.closePopup = closePopup.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.building !== state.prevProps.building) {
      let building = props.building;
      let activeMode =
        building && building.billing && building.billing.active_mode
          ? building.billing.active_mode
          : "FLAT";
      let billingArray =
        building && building.billing && building.billing.billing_mode
          ? building.billing.billing_mode
          : [];

      let billingObj = {};
      if (billingArray.length >= 0) {
        billingArray.map((billing_type) => {
          if (billing_type.tag === activeMode) {
            billingObj = billing_type;
          }
          return null;
        });
      }
      return {
        prevProps: props,
        billingObj,
        activeMode,
        flatAmount: formatPrice(
          billingObj.flat_amount ? billingObj.flat_amount : 0
        ),
        flatAmountNumber: billingObj.flat_amount,
        hourAmount: formatPrice(
          billingObj.hour_amount ? billingObj.hour_amount : 0
        ),
        hourAmountNumber: billingObj.hour_amount,
        secondHourAmount: formatPrice(
          billingObj.second_hour_amount ? billingObj.second_hour_amount : 0
        ),
        secondHourAmountNumber: billingObj.second_hour_amount,
        thirdHourAmount: formatPrice(
          billingObj.third_hour_amount ? billingObj.third_hour_amount : 0
        ),
        thirdHourAmountNumber: billingObj.third_hour_amount,
        extraAmount: formatPrice(
          billingObj.extra_amount ? billingObj.extra_amount : 0
        ),
        extraAmountNumber: billingObj.extra_amount,
        overnightAmount: formatPrice(
          billingObj.overnight_amount ? billingObj.overnight_amount : 0
        ),
        overnightAmountNumber: billingObj.overnight_amount,
        building
      };
    }
    return null;
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      if (this.props.building !== prevProps.building) {
        let building = this.props.building;
        let activeMode =
          building && building.billing && building.billing.active_mode
            ? building.billing.active_mode
            : "FLAT";
        let billingArray =
          building && building.billing && building.billing.billing_mode
            ? building.billing.billing_mode
            : [];

        let billingObj = {};
        if (billingArray.length >= 0) {
          billingArray.map((billing_type) => {
            if (billing_type.tag === activeMode) {
              billingObj = billing_type;
            }
            return null;
          });
        }

        this.setState({
          billingObj,
          activeMode,
          flatAmount: formatPrice(
            billingObj.flat_amount ? billingObj.flat_amount : 0
          ),
          flatAmountNumber: billingObj.flat_amount,
          hourAmount: formatPrice(
            billingObj.hour_amount ? billingObj.hour_amount : 0
          ),
          hourAmountNumber: billingObj.hour_amount,
          secondHourAmount: formatPrice(
            billingObj.second_hour_amount ? billingObj.second_hour_amount : 0
          ),
          secondHourAmountNumber: billingObj.second_hour_amount,
          thirdHourAmount: formatPrice(
            billingObj.third_hour_amount ? billingObj.third_hour_amount : 0
          ),
          thirdHourAmountNumber: billingObj.third_hour_amount,
          extraAmount: formatPrice(
            billingObj.extra_amount ? billingObj.extra_amount : 0
          ),
          extraAmountNumber: billingObj.extra_amount,
          overnightAmount: formatPrice(
            billingObj.overnight_amount ? billingObj.overnight_amount : 0
          ),
          overnightAmountNumber: billingObj.overnight_amount,
          building
        });
      }
    }
  }

  updateBuildings = async () => {
    try {
      const response = await getBuildings();
      if (!response.ok) throw response;

      const res = await response.json();
      if (res.buildings) {
        this.props.addBuildings(res.buildings);
        this.props.selectBuilding(this.props.building._id);
      }
    } catch (err) {
      this.setState({ ...this.initialState });
      this.throwPopupMessage("error", errors.GENERAL_ERR);
    }
  };
  handleFlatAmount = (event) => {
    let inputAmount = event.target.value.replace(/[^0-9\s]/g, "");
    let formattingAmount = inputPrice(inputAmount.replace(" ", ""));
    this.setState({
      flatAmount: formattingAmount,
      flatAmountNumber: priceToRequest(formattingAmount)
    });
  };
  handleHourAmount = (event) => {
    let inputAmount = event.target.value.replace(/[^0-9\s]/g, "");
    let formattingAmount = inputPrice(inputAmount.replace(" ", ""));
    this.setState({
      hourAmount: formattingAmount,
      hourAmountNumber: priceToRequest(formattingAmount)
    });
  };
  handleSecondHourAmount = (event) => {
    let inputAmount = event.target.value.replace(/[^0-9\s]/g, "");
    let formattingAmount = inputPrice(inputAmount.replace(" ", ""));
    this.setState({
      secondHourAmount: formattingAmount,
      secondHourAmountNumber: priceToRequest(formattingAmount)
    });
  };
  handleThirdHourAmount = (event) => {
    let inputAmount = event.target.value.replace(/[^0-9\s]/g, "");
    let formattingAmount = inputPrice(inputAmount.replace(" ", ""));
    this.setState({
      thirdHourAmount: formattingAmount,
      thirdHourAmountNumber: priceToRequest(formattingAmount)
    });
  };
  handleExtraAmount = (event) => {
    let inputAmount = event.target.value.replace(/[^0-9\s]/g, "");
    let formattingAmount = inputPrice(inputAmount.replace(" ", ""));
    this.setState({
      extraAmount: formattingAmount,
      extraAmountNumber: priceToRequest(formattingAmount)
    });
  };
  handleOvernightAmount = (event) => {
    let inputAmount = event.target.value.replace(/[^0-9\s]/g, "");
    let formattingAmount = inputPrice(inputAmount.replace(" ", ""));
    this.setState({
      overnightAmount: formattingAmount,
      overnightAmountNumber: priceToRequest(formattingAmount)
    });
  };

  handleEdit = (event) => {
    event.preventDefault();
    this.setState({ edit: true });
    this.throwPopupMessage("alert", "");
  };
  closeEdit = (event) => {
    event.preventDefault();
    this.closePopup();
    this.setState({ edit: false, newRate: "" });
  };

  formattingData = () => {
    const validBillings = {
      FLAT: {
        flat_amount: this.state.flatAmountNumber,
        overnight_amount: this.state.overnightAmountNumber
      },
      PER_HOUR: {
        hour_amount: this.state.hourAmountNumber,
        overnight_amount: this.state.overnightAmountNumber
      },
      PER_HOUR_CUSTOM: {
        flat_amount: this.state.flatAmountNumber,
        hour_amount: this.state.hourAmountNumber,
        second_hour_amount: this.state.secondHourAmountNumber,
        third_hour_amount: this.state.thirdHourAmountNumber,
        extra_amount: this.state.extraAmountNumber,
        overnight_amount: this.state.overnightAmountNumber
      }
    };
    let dataFormatted = {
      building_id: this.state.building._id,
      ...validBillings[this.state.activeMode]
    };
    return dataFormatted;
  };
  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ loading: true, popupMessagesType: "", popupMessages: "" });
    try {
      const response = await updateBuildingRate(this.formattingData());
      if (!response.ok) throw response;
      this.setState({
        loading: false,
        edit: false,
        popupMessagesType: "success",
        popupMessages: "Tarifa actualizada con éxito"
      });
      //this.throwPopupMessage("success", res.message);
      this.updateBuildings();
    } catch (err) {
      if (typeof err.json === "function") {
        const err_body = await err.json();
        // If it is an authentication error, clear the redux-store to close the session
        if (
          err_body.error.id === "NO_TOKEN_PROVIDED" ||
          err_body.error.id === "AUTHENTICATE_FAILED" ||
          err_body.error.id === "DUPLICATE_SESSION"
        ) {
          return; // prevent setState exeution on unmounted component
        }
        this.setState({ ...this.initialState });
        this.throwPopupMessage("alert", errors.AUTHENTICATION);
      } else {
        this.setState({ ...this.initialState });
        this.throwPopupMessage("error", errors.GENERAL_ERR);
      }
    }
  };

  // actualizar la lista de buildings y reseleccionar el building con redux

  render() {
    let activeAmounts = this.state.billingObj.active_amounts
      ? this.state.billingObj.active_amounts
      : [];
    let hourIsActive = activeAmounts.find((amount) => amount === "hour_amount"),
      secondHourIsActive = activeAmounts.find(
        (amount) => amount === "second_hour_amount"
      ),
      thirdHourIsActive = activeAmounts.find(
        (amount) => amount === "third_hour_amount"
      ),
      flatIsActive = activeAmounts.find((amount) => amount === "flat_amount"),
      extraIsActive = activeAmounts.find((amount) => amount === "extra_amount");

    return (
      <Panel>
        <PopupMessage
          messageType={this.state.popupMessagesType}
          showPopup={this.state.showPopup}
          messages={this.state.popupMessages}
          closePopup={this.state.edit ? this.closeEdit : this.closePopup}
        >
          {this.state.edit && !this.state.loading && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              {this.state.activeMode === "FLAT" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <p>Indique la nueva tarifa plana</p>
                  <input
                    id="flat"
                    type="text"
                    required={true}
                    placeholder="4.300,564 Bs.S."
                    autoComplete="off"
                    value={this.state.flatAmount}
                    onChange={this.handleFlatAmount}
                    style={{
                      border: "none",
                      borderRadius: "1rem",
                      //color: `${grey_4}`,
                      fontSize: "1.3rem",
                      margin: "1rem auto",
                      textAlign: "center",
                      outline: "none",
                      boxShadow: "none"
                    }}
                  />
                  {this.state.billingObj.overnight && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      <p>Pernocta</p>
                      <input
                        id="overnight"
                        type="text"
                        required={true}
                        placeholder="4.300,564 Bs.S."
                        autoComplete="off"
                        value={`${this.state.overnightAmount}`}
                        onChange={this.handleOvernightAmount}
                        style={{
                          border: "none",
                          borderRadius: "1rem",
                          //color: `${grey_4}`,
                          fontSize: "1.3rem",
                          margin: "1rem auto",
                          textAlign: "center",
                          outline: "none",
                          boxShadow: "none"
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
              {this.state.activeMode === "PER_HOUR" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <p>Indique la nueva tarifa por hora</p>
                  <input
                    id="per_hour"
                    type="text"
                    required={true}
                    placeholder="6.8200,564 Bs.S."
                    autoComplete="off"
                    value={`${this.state.hourAmount} Bs.S.`}
                    onChange={this.handleHourAmount}
                    style={{
                      border: "none",
                      borderRadius: "1rem",
                      //color: `${grey_4}`,
                      fontSize: "1.3rem",
                      margin: "1rem auto",
                      textAlign: "center",
                      outline: "none",
                      boxShadow: "none"
                    }}
                  />
                </div>
              )}
              {this.state.activeMode === "PER_HOUR_CUSTOM" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  {flatIsActive && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      <p>Indique la nueva tarifa plana</p>
                      <input
                        id="flat_custom"
                        type="text"
                        required={true}
                        placeholder="4.300,564 Bs.S."
                        autoComplete="off"
                        value={this.state.flatAmount}
                        onChange={this.handleFlatAmount}
                        style={{
                          border: "none",
                          borderRadius: "1rem",
                          //color: `${grey_4}`,
                          fontSize: "1.3rem",
                          margin: "1rem 0",
                          textAlign: "center",
                          outline: "none",
                          boxShadow: "none"
                        }}
                      />
                    </div>
                  )}
                  {hourIsActive && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      <p>Indique la nueva tarifa por una hora</p>
                      <input
                        id="per_hour_one"
                        type="text"
                        required={true}
                        placeholder="4.300,564 Bs.S."
                        autoComplete="off"
                        value={`${this.state.hourAmount}`}
                        onChange={this.handleHourAmount}
                        style={{
                          display: "block",
                          border: "none",
                          borderRadius: "1rem",
                          //color: `${grey_4}`,
                          fontSize: "1.3rem",
                          margin: "1rem auto",
                          textAlign: "center",
                          outline: "none",
                          boxShadow: "none"
                        }}
                      />
                    </div>
                  )}
                  {secondHourIsActive && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      <p>Indique la nueva tarifa por dos hora</p>
                      <input
                        id="per_hour_two"
                        type="text"
                        required={true}
                        placeholder="4.300,564 Bs.S."
                        autoComplete="off"
                        value={`${this.state.secondHourAmount}`}
                        onChange={this.handleSecondHourAmount}
                        style={{
                          border: "none",
                          borderRadius: "1rem",
                          //color: `${grey_4}`,
                          fontSize: "1.3rem",
                          margin: "1rem auto",
                          textAlign: "center",
                          outline: "none",
                          boxShadow: "none"
                        }}
                      />
                    </div>
                  )}
                  {thirdHourIsActive && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      <p>Indique la nueva tarifa por tres horas</p>
                      <input
                        id="per_hour_three"
                        type="text"
                        required={true}
                        placeholder="4.300,564 Bs.S."
                        autoComplete="off"
                        value={`${this.state.thirdHourAmount}`}
                        onChange={this.handleThirdHourAmount}
                        style={{
                          display: "block",
                          border: "none",
                          borderRadius: "1rem",
                          //color: `${grey_4}`,
                          fontSize: "1.3rem",
                          margin: "1rem auto",
                          textAlign: "center",
                          outline: "none",
                          boxShadow: "none"
                        }}
                      />
                    </div>
                  )}
                  {extraIsActive && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      <p>Indique la nueva tarifa extra por mas de un día</p>
                      <input
                        id="extra"
                        type="text"
                        required={true}
                        placeholder="4.300,564 Bs.S."
                        autoComplete="off"
                        value={`${this.state.extraAmount}`}
                        onChange={this.handleExtraAmount}
                        style={{
                          border: "none",
                          borderRadius: "1rem",
                          //color: `${grey_4}`,
                          fontSize: "1.3rem",
                          margin: "1rem auto",
                          textAlign: "center",
                          outline: "none",
                          boxShadow: "none"
                        }}
                      />
                    </div>
                  )}
                  {this.state.billingObj.overnight && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      <p>Pernocta</p>
                      <input
                        id="overnight"
                        type="text"
                        required={true}
                        placeholder="4.300,564 Bs.S."
                        autoComplete="off"
                        value={`${this.state.overnightAmount}`}
                        onChange={this.handleOvernightAmount}
                        style={{
                          border: "none",
                          borderRadius: "1rem",
                          //color: `${grey_4}`,
                          fontSize: "1.3rem",
                          margin: "1rem auto",
                          textAlign: "center",
                          outline: "none",
                          boxShadow: "none"
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
              <Button
                color="green"
                disabled={this.state.loading || (!this.state.flatAmountNumber && (this.state.activeMode === "FLAT" || (this.state.activeMode === "PER_HOUR_CUSTOM" && flatIsActive))) || (!this.state.hourAmountNumber && (this.state.activeMode === "PER_HOUR" || (this.state.activeMode === "PER_HOUR_CUSTOM" && hourIsActive))) || (!this.state.secondHourAmountNumber && this.state.activeMode === "PER_HOUR_CUSTOM" && secondHourIsActive) || (!this.state.thirdHourAmountNumber && this.state.activeMode === "PER_HOUR_CUSTOM" && thirdHourIsActive) || (!this.state.extraAmountNumber && this.state.activeMode === "PER_HOUR_CUSTOM" && extraIsActive) || (!this.state.overnightAmountNumber && this.state.billingObj.overnight)}
                onClick={this.handleSubmit}
              >
                {this.state.loading ? texts.loading : texts.accept}
              </Button>
              <Button
                color="grey"
                disabled={this.state.loading}
                onClick={this.closeEdit}
              >
                {this.state.loading ? texts.loading : texts.back}
              </Button>
            </div>
          )}
          {this.state.loading && <div className="ui active loader"></div>}
        </PopupMessage>

        <div className={this.props.className}>
          <div className="header">
            <label htmlFor="rate">{viewTexts.rate}</label>
          </div>
          <div className="buttons">
            <Button
              color="blue"
              type="submit"
              disabled={this.state.loading}
              onClick={this.handleEdit}
            >
              {this.state.loading ? texts.loading : texts.edit}
            </Button>
          </div>
        </div>
      </Panel>
    );
  }
}

const mapStateToProps = (state) => ({
  workspace: getSession(state).workspace
});

const mapDispatchToProps = {
  clearReduxStore,
  addBuildings,
  selectBuilding
};

RateFormModel = connect(mapStateToProps, mapDispatchToProps)(RateFormModel);

export { RateFormModel };
