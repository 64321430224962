import React from "react";
import { CSSTransition } from "react-transition-group";
import BarGraph from "components/bar-graph";
import { TotalAmounts } from "../total-amounts";
import { purplePD_2 } from "assets/strings/colors";

const bar = {
  //lineName: "Tickets",
  height: 150,
  fill: purplePD_2,
  keyX: "name",
  keyY: "tickets",
  margin: {
    bottom: 0,
    left: -28,
    top: 0,
    right: 0,
  },
};

const SecondRowModel = ({
  transition,
  ticketsDistribution,
  totalAmounts,
  amountsHandlePeriod,
  item,
  className,
}) => {
  //let period = barStats.periodSelected;
  let periodAmounts = totalAmounts.periodSelected;

  return (
    <div className={className}>
      <CSSTransition
        in={transition}
        timeout={350}
        appear={true}
        classNames="second-row"
      >
        <div className="second-row-parking">
          <div className="left">
            <div className="selectors">
              <p className="title"> {ticketsDistribution.title}</p>
            </div>
            <BarGraph
              data={ticketsDistribution.data}
              lineName={ticketsDistribution.lineName}
              keyX={bar.keyX}
              keyY={
                ticketsDistribution.keyY ? ticketsDistribution.keyY : bar.keyY
              }
              height={bar.height}
              margin={bar.margin}
              fill={bar.fill}
            />
          </div>
          <div className="right">
            <div className="selectors">
              <div className="periods">
                <p
                  className={periodAmounts === "last" ? "box-selected" : "box"}
                  onClick={amountsHandlePeriod}
                  id="last"
                >
                  Periodo Anterior
                </p>
                <p
                  id="current"
                  className={
                    periodAmounts === "current" ? "box-selected" : "box"
                  }
                  style={{ height: "20px" }}
                  onClick={amountsHandlePeriod}
                >
                  Periodo Actual
                </p>
              </div>
            </div>

            <TotalAmounts
              firstTitle={totalAmounts[periodAmounts].firstTitle}
              firstUnit={totalAmounts[periodAmounts].firstUnit}
              firstAmount={totalAmounts[periodAmounts].firstAmount}
              secondTitle={totalAmounts[periodAmounts].secondTitle}
              secondUnit={totalAmounts[periodAmounts].secondUnit}
              secondAmount={totalAmounts[periodAmounts].secondAmount}
            />
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export { SecondRowModel };
