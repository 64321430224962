import React from "react";
import PropTypes from "prop-types";
import { CommerceListItem } from "./index";
import defaultUserGrey from "assets/img/default-user-grey.png";
import defaultUserWhite from "assets/img/default-user-white.png";
import { commerces_admin as viewTexts } from "assets/strings/texts";

const CommerceListItemModel = ({
  img,
  index,
  name,
  idNumber,
  idNumberType,
  isAdmin,
  blocked,
  onSelect,
  className,
}) => {
  let defaultImg = isAdmin ? defaultUserWhite : defaultUserGrey;
  let adminClass = isAdmin ? "admin" : "";
  let commerceImg = img ? img : defaultImg;
  let commerceName = name ? name : "";
  let commerceidNumber = idNumber ? idNumber : "";
  let commerceidNumberType = idNumberType ? idNumberType : "";
  let blockedIcon = blocked ? <i className="ban icon"></i> : null;
  let blockedClass = blocked ? "blocked" : "";
  return (
    <dd
      className={`${className} ${adminClass}  ${blockedClass}`}
      onClick={onSelect}
      id={index}
    >
      <img className="img" src={commerceImg} alt="commerce" />
      <div className="data">
        <p className="header">{commerceName}</p>
        <p className="description">
          {commerceidNumberType + "-" + commerceidNumber}
        </p>
      </div>
      <div className="status">{blockedIcon}</div>
    </dd>
  );
};

const CommercesHeaderModel = ({ className }) => {
  return <dd className={className}>Comercios</dd>;
};

function formatCommercesList(commerces, viewState, handleCommerceSelection) {
  // Declaramos el array que devolverá la función
  let commercesList = [];
  if (viewState === "commerce_search") {
    return <div className="not-found">{viewTexts.preSearch}</div>;
  }
  if (viewState === "commerce_search_loading") {
    return <div className="ui active loader"></div>;
  }
  if (
    viewState === "commerce_search_done" ||
    viewState === "commerce_info" ||
    viewState === "commerce_info_loading" ||
    viewState === "commerce_invoice" ||
    viewState === "commerce_invoice_loading" ||
    viewState === "commerce_invoice_done" ||
    viewState === "commerce_invoice_payment" ||
    viewState === "commerce_invoice_payment_loading" ||
    viewState === "commerce_invoice_payment_done" ||
    viewState === "commerce_users" ||
    viewState === "commerce_getusers_loading" ||
    viewState === "commerce_getusers_done" ||
    viewState === "commerce_user_reset" ||
    viewState === "commerce_user_reset_loading" ||
    viewState === "commerce_withdraw" ||
    viewState === "commerce_withdraw_loading" ||
    viewState === "commerce_withdraw_done" ||
    viewState === "commerce_withdraw_payment" ||
    viewState === "commerce_withdraw_payment_loading" ||
    viewState === "commerce_show_movements"
  ) {
    if (commerces.length === 0) {
      return <div className="not-found">{viewTexts.commercesNotFound}</div>;
    } else {
      commerces.forEach((commerce, index) => {
        commercesList.push(
          <CommerceListItem
            img={commerce.img}
            index={index}
            name={commerce.name}
            idNumber={commerce.identification.doc_id}
            idNumberType={commerce.identification.doc_type}
            onSelect={handleCommerceSelection}
            blocked={commerce.status === "blocked" ? true : false}
            key={commerce._id}
          />
        );
      });
      return commercesList;
    }
  }
}

CommerceListItemModel.propTypes = {
  onSelect: PropTypes.func,
  img: PropTypes.string,
  header: PropTypes.string,
  description: PropTypes.string,
  isAdmin: PropTypes.bool,
  blocked: PropTypes.bool,
  className: PropTypes.string,
};

export { CommerceListItemModel, CommercesHeaderModel, formatCommercesList };
