import { RechargeListItemModel } from './model'
import styled from 'styled-components'
import { blue, textualBlue, grey_4 } from 'assets/strings/colors'

const RechargeListItem = styled(RechargeListItemModel)`
  #root &{
    cursor: pointer;
    padding: 1.1vh 1rem 1.1vh 1rem;
    border-bottom: 1px solid ${grey_4};
    margin: 0;
    color: ${textualBlue}
    >.time{
      display: inline-block;
      margin: .5rem 1rem .5rem auto; 
      float: right;
    }
    >.data{
      max-width: 60%;
      text-overflow: ellipsis;
      white-space:nowrap;
      overflow:hidden;
      display: inline-block;
      >p{
        margin: 0;
        padding-left: .7rem;
        font-size: .9rem;
      }
      >.ref{
        padding: 0;
        white-space: pre;
        >.ref-number{
          display: inline-block;
          padding-left: .3rem;
          font-weight: bold;
          font-size: 1.2rem;
          color: ${blue}
        }
        >.status{
          font-weight: bold;
        }
      }   
    }        
  }
`
export { RechargeListItem }