import React from "react";
import { SuperadminBillsHistoryState } from "./context";
import SuperadminBillsHistoryView from "./components/superadmin-bills-history";

const MPosSuperadminBillsHistory = () => {
  return (
    <SuperadminBillsHistoryState>
      <SuperadminBillsHistoryView />
    </SuperadminBillsHistoryState>
  );
};

export default MPosSuperadminBillsHistory;
