import styled from 'styled-components'
import { CommerceListItemModel, CommercesHeaderModel } from './model'
import { grey_3, grey_4 } from 'assets/strings/colors'

const CommerceListItem = styled(CommerceListItemModel)`
  #root &{
    border-bottom: solid 1px ${grey_4};
    cursor: pointer;
    padding: .5rem 1rem;
    margin: 0;
    position: relative;
    >.img{
      display: inline-block;
      vertical-align: middle;
      margin-right: .5rem;
      height: 40px;
      border-radius: 200px;
    }
    >.data{
      display: inline-block;
      vertical-align: middle;
      width: calc(85% - 40px - 1rem);
      margin-right: .5rem;
      font-size: .9rem;
      >.header{
        text-transform: uppercase;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: bold;
        margin: 0;
      }
      >.description{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0;
      }
    }
    >.status{
      width: 15%;
      color: #B00000;
      font-size: 1.5rem;
      vertical-align: middle;
      text-align: right;
      display: inline-block;
    }
  }
  #root &.admin{
    background: ${grey_3};
  }
  #root &.blocked{
    background: 
      repeating-linear-gradient(
        135deg, 
        transparent,
        transparent 30px ,
        rgba(200,0,0,0.2) 30px,
        rgba(200,0,0,0.2) 40px
      )
  }
`

const CommerceHeader = styled(CommercesHeaderModel)`
  #root &{
    margin: 0;
    padding: .2rem 0 .2rem 1rem;
    border-bottom: solid 1px ${grey_4};
  }
`

export { CommerceListItem, CommerceHeader }