import {
  CommerceTicketsModel,
  TicketsListItemModel,
  TicketsListHeaderModel,
} from "./model";
import styled from "styled-components";
import { grey_3, grey_4, grey_5, grey_6, bluePD } from "assets/strings/colors";

const CommerceTickets = styled(CommerceTicketsModel)`
  #root && {
    height: calc(100vh - 4rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    > .commerce-info {
      height: 30vh;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid ${grey_4};
      > .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        > img {
          width: 8vw;
          border: 4px solid ${grey_3};
          border-radius: 1.5rem;
        }
        > .name {
          font-size: 1.2rem;
          font-weight: bold;
          color: ${grey_6};
        }
      }
      > .info {
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        > p {
          margin: 0;
        }
        > .doc {
          font-size: 0.9rem;
          color: ${grey_5};
        }
      }
    }
    > .list {
      width: 100%;
    }
    @media (max-width: 800px) {
      height: 100vh;
      > .commerce-info {
        > .header {
          > img {
            width: 20vw;
          }
        }
      }
      > button {
        height: 10vh;
        align-self: center;
        margin-bottom: 1rem;
      }
    }
  }
`;

const TicketsListItem = styled(TicketsListItemModel)`
  #root & {
    cursor: pointer;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 1.1vh 1rem 1.1vh 1rem;
    border-bottom: 1px solid ${grey_4};
    margin: 0;
    > .ticket-icon {
      display: block;
      width: 16px;
      height: 16px;
      margin: 0.9vh 0.8rem 0.2vh 0;
    }
    > .data {
      width: 22%;
      padding: 0;
      margin: 0;
      text-align: left;
      > .ticket-number {
        margin: 0;
        margin-right: 0.4rem;
        display: block;
        color: #63c0e8;
      }
      > .ticket-username {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    > .time {
      width: 25%;
      padding: 0;
      margin: 0;
      padding-left: 3rem;
      text-align: right;
      display: block;
      margin: 1.1vh 1rem 1.1vh auto;
      float: right;
    }
    > .ref {
      width: 25%;
      padding: 0;
      margin: 0;
      padding-left: 3rem;
      text-align: center;
      display: block;
      margin: 1.1vh 1rem 1.1vh auto;
    }
  }
`;

const TicketsListHeader = styled(TicketsListHeaderModel)`
  #root & {
    margin: 0;
    border-bottom: 2px solid ${bluePD};
    > .col {
      color: ${grey_4};
      margin: 1.2rem 0.5rem 0 0.5rem;
      padding-bottom: 0.3rem;
      display: inline-block;
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
    }
    > .forty {
      width: calc(40% - 1rem);
    }
    > .twenty {
      width: calc(20% - 1rem);
    }
    > .twenty-five {
      width: calc(25% - 1rem);
    }
  }
`;

export { CommerceTickets, TicketsListItem, TicketsListHeader };
