import { BillsHistoryFormModel } from "./model";
import styled from "styled-components";

export const BillsHistoryForm = styled(BillsHistoryFormModel)`
  #root & {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
`;
