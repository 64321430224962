import React from "react";
import { useResponsive } from "hooks/use-responsive/hook";
import { MobilePaidsState } from "./context";
import MobilePaidsPhoneView from "./containers/mobile-paid-phone-view";
import MobilePaidsView from "./containers/mobile-paids-view";

const MPosUsersMovementsMobilePaid = () => {
  const viewType = useResponsive();
  const view = {
    phone: <MobilePaidsPhoneView />,
    desktop: <MobilePaidsView />,
  };
  return (
    <MobilePaidsState viewType={viewType}>{view[viewType]}</MobilePaidsState>
  );
};

export default MPosUsersMovementsMobilePaid;
