import { store } from "redux/store";
import runtimeEnv from "@mars/heroku-js-runtime-env";

const env = runtimeEnv();

export const baseURL = env.REACT_APP_API_URL;
export const baseURL_V2 = env.REACT_APP_API_URL_V2;
export const micropd_01 = env.REACT_APP_API_URL_MICROPD1_V2;
export const micropd_02 = env.REACT_APP_API_URL_MICROPD2;
export const tollURL = env.REACT_APP_API_URL_TOLL;
export const reports_server = env.REACT_APP_API_URL_REPORTS;
export const withdrawURL = env.REACT_APP_API_URL_WITHDRAW;

export function getToken() {
  return store.getState().session.token;
}
