import React from "react";
import { Grid } from "semantic-ui-react";
import { Loader } from "components/loader";
import PopupMessage from "components/popup-message";
import { WalletAmount } from "./wallet-amounts";
import { PDAmount } from "./pd-amounts";
import { BancamigaAmount } from "./bancamiga-amounts";

export const AmountHistoryView = ({
  loading,
  info,
  loadingAmounts,
  bankAmounts,
  popupMessages,
  popupMessagesType,
  showPopup,
  closePopup,
}) => {
  return (
    <Grid padded>
      <PopupMessage
        messageType={popupMessagesType}
        showPopup={showPopup}
        messages={popupMessages}
        closePopup={closePopup}
      ></PopupMessage>
      {loading && <Loader />}
      {!loading && (
        <WalletAmount
          users={info.user_amounts}
          commerces={info.commerce_amounts}
        />
      )}
      {!loading && (
        <BancamigaAmount
          loading={loadingAmounts}
          banks={bankAmounts}
          dateFrom={info.date_from}
          dateTo={info.date_to}
        />
      )}
      {!loading && (
        <PDAmount
          dateFrom={info.date_from}
          dateTo={info.date_to}
          monthlyPremium={info.monthly_premium_amounts}
          parking={info.parking_amounts}
          services={info.service_amounts}
        />
      )}
    </Grid>
  );
};
