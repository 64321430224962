import NewCodeModel from "./model";
import styled from "styled-components";
import { bluePD, grey_3, grey_6 } from "assets/strings/colors";

const NewCode = styled(NewCodeModel)`
  #root & {
    > .rounded-div {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      > input {
        width: 50%;
        margin: 0.5rem;
        padding: 0.5rem;
        text-align: center;
        border-radius: 0.5rem;
        border: none;
        border-bottom: 1px solid ${grey_3};
        :focus {
          outline: none;
          border-bottom: 1px solid ${bluePD};
        }
        :disabled {
          border: none;
          background: transparent;
          color: ${grey_6};
        }
      }
      > .selection {
        width: 50%;
      }
      > .date-pickers {
        margin: 0;
        padding: 0;
        margin-top: 0.7rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 50%;
        cursor: pointer;

        > div {
          width: 100%;
          > .react-datepicker__input-container {
            width: 100%;
            > input {
              width: calc(100% - 16px);
              border: none;
              border-radius: 1rem;
              color: ${grey_6};
              font-size: 1.2rem;
              margin: 0.5rem 5px 0.5rem 5px;
              text-align: center;
              outline: none;
              box-shadow: none;
              transition: box-shadow 250ms linear;
              :focus {
                box-shadow: 0 1px darkgrey;
              }
            }
            > .react-datepicker__close-icon::after {
              right: 0;
            }
          }
        }
      }
    }
    > .users {
      margin-top: 1rem;
      width: 100%;
    }
  }
`;

export default NewCode;
