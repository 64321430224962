import styled from "styled-components";
import FileInputModel from "./model";
import { grey_3 } from "assets/strings/colors";

const FileInput = styled(FileInputModel)`
  #root & {
    > .file-button {
      background-color: transparent;
      border: 1px dashed ${grey_3};
      width: 100%;
      padding: 40px 20px;
      border-radius: 5px;
      color: ${grey_3};
      cursor: pointer;
      :hover {
        opacity: 0.7;
      }
    }
    > .selected {
      color: black;
    }
  }
`;
export default FileInput;
