import { useEffect, useState, useContext } from "react";
import { customerExtensionsAPI } from "api";
import { UsersExtensionsContext } from "../context";
import { errors } from "assets/strings/texts";

export const useEditExtension = () => {
  const [loading, setLoading] = useState(false);
  const [activate, setActivate] = useState(false);
  const { state, changeState, throwPopupMessage } = useContext(
    UsersExtensionsContext
  );

  useEffect(() => {
    const editExtension = async () => {
      setLoading(true);
      try {
        const first_name = state.inputs.firstName;
        const second_name = state.inputs.secondName;
        const first_last_name = state.inputs.firstLastname;
        const second_last_name = state.inputs.secondLastname;
        const user_id = state.selectedUser._id;
        const child_id = state.selectedExtension._id;
        const status = state.dropdownValues.status;
        const design = state.dropdownValues.carnet;
        const commerce = state.dropdownValues.school;
        const is_printed = state.dropdownValues.is_printed;

        const response = await customerExtensionsAPI.updateExtension({
          user_id,
          child_id,
          first_name,
          second_name,
          first_last_name,
          second_last_name,
          commerce,
          design,
          status,
          is_printed,
        });
        const res = await response.json();
        if (res.error) throw res.error;
        changeState({
          viewState: "user_selected",
          users: state.users,
          selectedUser: state.selectedUser,
          searchExtensions: true,
        });
        throwPopupMessage("success", "Cambios guardados con éxito");
      } catch (error) {
        if (
          error.id === "NO_TOKEN_PROVIDED" ||
          error.id === "AUTHENTICATE_FAILED" ||
          error.id === "DUPLICATE_SESSION"
        ) {
          changeState({
            viewState: "user_selected",
            users: state.users,
            selectedUser: state.selectedUser,
            searchExtensions: true,
          });
          throwPopupMessage("alert", errors.AUTHENTICATION);
        } else {
          changeState({
            viewState: "user_selected",
            users: state.users,
            selectedUser: state.selectedUser,
            searchExtensions: true,
          });
          throwPopupMessage("error", error.description);
        }
      }
      setLoading(false);
    };
    if (activate) {
      editExtension();
      setActivate(false);
    }
  }, [activate]);

  return { loading, setActivate };
};
