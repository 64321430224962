import {
    SOCKET
} from "redux/actions/general";

const initialState = {
    socket: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SOCKET:
            let { socket } = action.payload;
            return {
                ...state,
                socket,
            };
        default:
            return state;
    }
}
