import React from "react";
import moment from "moment";
import "moment/locale/es-us";
import { Grid } from "semantic-ui-react";
import PopupMessage from "components/popup-message";
//import DashboardSection from "components/dashboard-section";
//import HistoryTicketsGraph from "components/history-tickets-graph";
//import { DashboardSummary } from "components/summary-tabs";
//import { FilterForm } from "./filter-form";
//import { purplePD } from "assets/strings/colors";

moment.locale("es-us");

const MposParkingDashboardView = ({
  viewState,
  phoneView,
  graphText,
  searchType,
  searchFilter,
  fromDate,
  endDate,
  ticketsPerDay,
  totalTransactions,
  popupMessages,
  popupMessagesType,
  handleSearchTypeButtons,
  handleSearchFilterButtons,
  handleFromDate,
  handleEndDate,
  showPopup,
  closePopup,
  className,
}) => {
  return (
    <Grid>
      <PopupMessage
        messageType={popupMessagesType}
        showPopup={showPopup}
        messages={popupMessages}
        closePopup={closePopup}
      />
      {viewState === "waiting_dashboard" ? (
        <></>
      ) : (
        <Grid.Column width={16}>
          {/*  <DashboardSection elemPerRow={2} elemPerPhoneRow={1}>
            {viewState === "data_received" && (
              <HistoryTicketsGraph
                title={graphText}
                ticketsPerDay={ticketsPerDay}
                stroke={purplePD}
              />
            )}
                {viewState === "data_received" && (
              <div style={{ height: "315px" }}>
                <DashboardSummary
                  color={purplePD}
                  value={totalTransactions}
                  text="Total de Tickets"
                />

                <FilterForm
                  viewState={viewState}
                  phoneView={phoneView}
                  searchType={searchType}
                  searchFilter={searchFilter}
                  fromDate={fromDate}
                  endDate={endDate}
                  handleSearchTypeButtons={handleSearchTypeButtons}
                  handleSearchFilterButtons={handleSearchFilterButtons}
                  handleFromDate={handleFromDate}
                  handleEndDate={handleEndDate}
                  className={className}
                />
              </div>
            )} 

            {viewState === "waiting_data" && (
              <div className="ui active loader"></div>
            )}
          </DashboardSection> */}
        </Grid.Column>
      )}
    </Grid>
  );
};

export default MposParkingDashboardView;
