import React from 'react'
import { Button } from 'components/buttons'

const ResultScreenModel = ({
  imagePath,
  text,
  buttonText,
  handleFinishButton,
  className
}) =>{
  return(
    <div className={className}>
      <img src={imagePath} alt={''}/>
      <p>{text}</p>
      <Button 
        color='blue'
        onClick={handleFinishButton}>
          {buttonText}
      </Button>
    </div>
  )
}

export { ResultScreenModel }