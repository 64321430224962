import React, { useContext } from "react";
import { MobilePaidsContext } from "../context";
import { Panel, PanelPlaceholder } from "components/panel";
import { CSSTransition } from "react-transition-group";
import MobilePaidDetail from "../components/mobile-paid-detail";
import { Loader } from "components/loader";

const MobilePaidDetailContainer = () => {
  const { state } = useContext(MobilePaidsContext);
  if (state.changeStatusLoading) return <Loader />;
  if (!state.selectedMobilePaid)
    return (
      <PanelPlaceholder>
        Seleccióne una transacción para habilitar la recarga
      </PanelPlaceholder>
    );

  return (
    <CSSTransition
      in={state.showDetail}
      mountOnEnter
      unmountOnExit
      timeout={350}
      appear={true}
      classNames="panel"
    >
      <Panel>
        <MobilePaidDetail />
      </Panel>
    </CSSTransition>
  );
};

export default MobilePaidDetailContainer;
