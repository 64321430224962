import React, { useContext } from "react";
import { UsersExtensionsContext } from "../../context";
import { Panel } from "components/panel";
import { Dropdown } from "components/dropdown";
import { Button } from "components/buttons";
import { IconBack } from "components/icon-back";
import { BlueInput } from "components/blue-input";

export const NewExtensionModel = ({ className }) => {
  const {
    state,
    changeState,
    handleBack,
    viewport,
    onChangeInput,
    onChangeDropdown,
    throwPopupMessage,
  } = useContext(UsersExtensionsContext);
  const phoneView = viewport === "phone";

  const handleSubmit = (event) => {
    event.preventDefault();
    changeState({
      viewState: "submit",
      optionName: "newExtension",
    });
    throwPopupMessage("alert", "Por favor, acepte los cambios");
  };
  return (
    <div className={className}>
      <Panel className="extension">
        <div className="header">
          {phoneView && <IconBack handleBack={handleBack} />}
          <p className="edit">Agregar Hijo</p>
        </div>
        <BlueInput
          label="Primer Nombre:"
          name="firstName"
          placeholder="Escriba el primer nombre"
          onChange={onChangeInput}
          value={state.inputs.firstName}
        />
        <BlueInput
          label="Segundo Nombre:"
          name="secondName"
          placeholder="Escriba el segundo nombre"
          onChange={onChangeInput}
          value={state.inputs.secondName}
        />
        <BlueInput
          label="Primer apellido:"
          name="firstLastname"
          placeholder="Escriba el primer apellido"
          onChange={onChangeInput}
          value={state.inputs.firstLastname}
        />
        <BlueInput
          label="Segundo Apellido:"
          name="secondLastname"
          placeholder="Escriba el segundo apellido"
          onChange={onChangeInput}
          value={state.inputs.secondLastname}
        />

        <label htmlFor="school" className="title">
          Colegio:
        </label>
        <Dropdown
          name="school"
          className="drop"
          selection
          options={state.schoolsOptions}
          value={state.dropdownValues.school}
          onChange={onChangeDropdown}
        />
        <label htmlFor="carnet" className="title">
          Carnet:
        </label>
        <Dropdown
          name="carnet"
          className="drop"
          selection
          options={state.carnetOptions}
          value={state.dropdownValues.carnet}
          onChange={onChangeDropdown}
        />
        <Button
          color="green_6"
          width="40%"
          margin="0"
          disabled={
            !state.inputs.firstName || state.inputs.firstName.length < 3
          }
          onClick={handleSubmit}
        >
          Guardar Cambios
        </Button>
      </Panel>
    </div>
  );
};
