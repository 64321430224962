import React, { Component } from "react";
import { connect } from "react-redux";
import { getSelectedCommerce } from "redux/selectors";
import { Panel } from "components/panel";
import { Button } from "components/buttons";
import { errors } from "assets/strings/texts";
import { getSuperAdminTransactions } from "services/api";
import { general as texts } from "assets/strings/texts";
import { WithdrawHistoryForm } from "components/withdraw-history-form";
import { clearReduxStore } from "redux/actions/general";
import { WithdrawPaymentForm } from "../withdraw-payment-form";
import { throwPopupMessage } from "redux/actions/popup-messages";
import { EntitiesList, Pagination } from "components/entities-list";
import { commerces_admin as viewTexts } from "assets/strings/texts";
import { formatWithdrawPhoneList } from "../withdraw-payment-list-item";
import { getViewMposCommerces } from "redux/selectors/view-mpos-commerces";

import {
  handleSearchTypeButtons,
  handleFromDate,
  handleEndDate,
  handleStatusButtons,
} from "components/withdraw-history-form";

import {
  setViewState,
  selectWithdraw,
  updateWithdrawList,
} from "redux/actions/view-mpos-commerces";

class WithdrawPanel_unconnected extends Component {
  constructor(props) {
    super(props);
    let commerceSessionId = this.props.session._id;
    let category = this.props.session.category;

    this.state = {
      commerceSessionId,
      category,
      searchType: "latests", // enum ['latests','dateRange']
      fromDate: null,
      endDate: null,
      approved: false,
      pending: true,
      overdue: false,
      isFormCollapsed: true,
      currentPage: 1,
      nextPageExist: false,
      authError: false,
    };

    // ========== INSTANCE PROPERTIES ==========
    this.initialState = { ...this.state };
    this.whitdrawPerPage = 10;
    this.withdrawFormDataCache = {};
    // Bind invoices form handlers
    this.handleSearchTypeButtons = handleSearchTypeButtons.bind(this);
    this.handleFromDate = handleFromDate.bind(this);
    this.handleEndDate = handleEndDate.bind(this);
    this.handleStatusButtons = handleStatusButtons.bind(this);
  }

  componentDidMount() {
    this.withdrawFormDataCache = {
      // with the selected commerce
      commerceSessionId: this.initialState.commerceSessionId,
      selectedCommerce: this.props.selectedCommerce,
      // and the initial state
      searchType: this.initialState.searchType,
      fromDate: this.initialState.fromDate,
      endDate: this.initialState.endDate,
      paid: this.initialState.paid,
      pending: this.initialState.pending,
      overdue: this.initialState.overdue,
    };

    let reqData = this.formatWithdrawRequestData(this.withdrawFormDataCache);

    /* getSuperAdminTransactions(
      fromDate,
      endDate,
      skip,
      limit,
      user_id,
      type,
      currency,
      transaction_tag
    ); */
    let promise = getSuperAdminTransactions(reqData);
    this.props.setViewState("commerce_withdraw_loading");
    this.handleWithdrawSearchPromise(promise);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedCommerce._id !== this.props.selectedCommerce._id) {
      this.setState({ ...this.initialState });

      this.withdrawFormDataCache = {
        // with the selected commerce
        selectedCommerce: this.props.selectedCommerce,
        // and the initial state
        searchType: this.initialState.searchType,
        fromDate: this.initialState.fromDate,
        endDate: this.initialState.endDate,
        paid: this.initialState.paid,
        pending: this.initialState.pending,
        overdue: this.initialState.overdue,
      };

      let reqData = this.formatWithdrawRequestData(this.withdrawFormDataCache);

      let promise = getSuperAdminTransactions(reqData);
      this.props.setViewState("commerce_withdraw_loading");
      this.handleWithdrawSearchPromise(promise);
    }
  }

  toggleWithdrawForm = () => {
    this.setState({
      isFormCollapsed: !this.state.isFormCollapsed,
    });
  };

  removeWithdrawPanel = () => {
    this.props.setViewState("commerce_info");
  };

  throwWithdrawPaymentPopup = () => {
    this.props.setViewState("commerce_withdraw_payment");
    this.props.throwPopupMessage(
      "",
      <WithdrawPaymentForm withdrawals={this.props.withdraw} />
    );
    // ==
    // TODO: THIS will change when invoice payment form is updated
    // ==
    // setTimeout(() => {
    //   this.throwPopupMessage('', '')
    // }, popup_transition_time);
  };

  formatWithdrawRequestData = (formState) => {
    let user_id,
      commerce_id,
      fromDate,
      endDate,
      limit,
      skip,
      type = "debit",
      currency = "BS",
      transaction_tag = "withdraw";

    if (formState.commerceSessionId) commerce_id = formState.commerceSessionId;
    if (formState.selectedCommerce) user_id = formState.selectedCommerce._id;
    if (formState.searchType === "dateRange" && formState.fromDate)
      fromDate = formState.fromDate.toISOString();
    if (formState.searchType === "dateRange" && formState.endDate)
      endDate = formState.endDate.toISOString();
    limit = this.withdrawPerPage + 1;
    skip = 0;
    return {
      fromDate,
      endDate,
      skip,
      limit,
      user_id,
      type,
      currency,
      transaction_tag,
      commerce_id,
    };
  };

  handleSubmit = (event) => {
    event.preventDefault();
    // format request data
    let reqData = this.formatWithdrawRequestData({
      ...this.state,
      selectedCommerce: this.props.selectedCommerce,
    });
    // save a copy of the form state
    this.withdrawFormDataCache = {
      commerceSessionId: this.state.commerceSessionId,
      selectedCommerce: this.props.selectedCommerce,
      searchType: this.state.searchType,
      fromDate: this.state.fromDate,
      endDate: this.state.endDate,
      paid: this.state.paid,
      pending: this.state.pending,
      overdue: this.state.overdue,
    };

    // If there are missing fields return to the prevState
    if (
      this.state.searchType === "dateRange" &&
      (!reqData.fromDate || !reqData.endDate)
    ) {
      this.setState({
        currentPage: this.initialState.currentPage,
        nextPageExist: this.initialState.nextPageExist,
      });
      this.props.updateWithdrawList([]); // empty list
      this.props.setViewState("commerce_withdraw");
      this.props.throwPopupMessage("error", errors.MISSING_FIELDS);
    } else {
      // Set loading state and cached form data
      this.setState((prevState) => ({
        ...prevState,
        currentPage: this.initialState.currentPage,
        nextPageExist: this.initialState.nextPageExist,
      }));
      this.props.updateWithdrawList([]); // empty list
      this.props.setViewState("commerce_withdraw_loading");
      // make the api call
      let promise = getSuperAdminTransactions(reqData);
      this.handleWithdrawSearchPromise(promise);
    }
  };

  // 15)
  handlePageDown = (event) => {
    event.preventDefault();
    // format request data
    let reqData = this.formatWithdrawRequestData(this.withdrawFormDataCache);
    let skip = (this.state.currentPage - 2) * this.withdrawPerPage;
    // Set loading state
    const { selectedCommerce, ...formCache } = this.withdrawFormDataCache;
    this.setState((prevState) => ({
      ...formCache,
      currentPage: prevState.currentPage - 1,
      nextPageExist: true,
    }));
    this.props.setViewState("commerce_withdraw_loading");
    // make the api call
    let promise = getSuperAdminTransactions({ ...reqData, skip });
    this.handleWithdrawSearchPromise(promise);
  };

  // 16)
  handlePageUp = (event) => {
    event.preventDefault();
    // format request data
    let reqData = this.formatWithdrawRequestData(this.withdrawFormDataCache);
    let skip = this.state.currentPage * this.withdrawPerPage;
    // Set loading state
    const { selectedCommerce, ...formCache } = this.withdrawFormDataCache;
    this.setState((prevState) => ({
      ...formCache,
      currentPage: prevState.currentPage + 1,
      nextPageExist: true,
    }));
    this.props.setViewState("commerce_withdraw_loading");
    // make the api call
    let promise = getSuperAdminTransactions({ ...reqData, skip });
    this.handleWithdrawSearchPromise(promise);
  };

  // 17)
  handleWithdrawSearchPromise = (promise) => {
    promise
      .then((res) => {
        if (!res.ok) {
          throw res;
        } // If not OK throw error
        return res.json(); // If OK return body
      })
      .then((res) => {
        // Manage success response
        if (res.transactions) {
          let nextPageExist;
          if (res.transactions.length > this.withdrawPerPage) {
            nextPageExist = true;
            res.transactions.pop();
          } else {
            nextPageExist = false;
          }
          this.setState({ nextPageExist });
          this.props.updateWithdrawList(res.transactions);
          this.props.setViewState("commerce_withdraw_done");
          this.props.selectWithdraw(null);
        }
      })
      .catch((err) => {
        // Manage error response
        // set apropiate states
        const { selectedCommerce, ...formCache } = this.withdrawFormDataCache;
        this.setState({
          ...formCache,
          currentPage: this.initialState.currentPage,
          nextPageExist: this.initialState.nextPageExist,
        });
        this.props.updateWithdrawList([]); // empty list
        this.props.setViewState("commerce_withdraw");
        this.props.selectWithdraw(null);
        // If error has a body, check the response
        if (typeof err.json === "function") {
          err.json().then((err_body) => {
            // If it is an authentication error, clear the redux-store to close the session
            if (
              err_body.error.id === "NO_TOKEN_PROVIDED" ||
              err_body.error.id === "AUTHENTICATE_FAILED" ||
              err_body.error.id === "DUPLICATE_SESSION"
            ) {
              this.props.throwPopupMessage("alert", errors.AUTHENTICATION);
              //this.props.clearReduxStore();
              return; // prevent setState exeution on unmounted component
            }
            this.props.throwPopupMessage("error", err_body.error.description);
          });
        } else {
          this.props.throwPopupMessage("error", errors.GENERAL_ERR);
        }
      });
  };

  // 18)
  handleWithdrawSelection = (event) => {
    this.props.selectWithdraw(event.currentTarget.id);
  };

  render() {
    const {
      //viewType,
      viewState,
      selectedWithdraw,
      withdraw,
    } = this.props;

    const {
      searchType,
      fromDate,
      endDate,
      approved,
      pending,
      overdue,
      isFormCollapsed,
      currentPage,
      nextPageExist,
    } = this.state;
    return (
      <Panel>
        <p
          style={{
            textAlign: "center",
            fontSize: "1.1rem",
          }}
        >
          Retiros de fondos pendientes por pagar
        </p>
        <div style={{ display: "flex" }}>
          <Button
            color="grey"
            margin="0 .2rem 0 .2rem"
            onClick={this.removeWithdrawPanel}
          >
            {texts.back}
          </Button>
          <Button
            color="green"
            margin="0 .2rem 0 .2rem"
            disabled={!selectedWithdraw}
            onClick={this.throwWithdrawPaymentPopup}
          >
            {viewTexts.markAsPaid}
          </Button>
        </div>
        <WithdrawHistoryForm
          category={this.state.category}
          viewState={viewState}
          searchType={searchType}
          fromDate={fromDate}
          endDate={endDate}
          approved={approved}
          pending={pending}
          overdue={overdue}
          collapsed={isFormCollapsed}
          toggleCollapse={this.toggleWithdrawForm}
          handleSearchTypeButtons={this.handleSearchTypeButtons}
          handleFromDate={this.handleFromDate}
          handleEndDate={this.handleEndDate}
          handleStatusButtons={this.handleStatusButtons}
          handleSubmit={this.handleSubmit}
        />

        <EntitiesList
          margin="1rem 0 0 0"
          height={`calc(100vh - 
                ${isFormCollapsed ? "17.4rem" : "32rem"})`}
          viewState={viewState}
          entities={withdraw}
          formattingFunction={formatWithdrawPhoneList}
          selectionHandler={this.handleWithdrawSelection}
          additionalArgs={[
            selectedWithdraw,
            this.state.approved,
            this.state.pending,
          ]}
        >
          <Pagination
            padding="0 0 0.8rem 0"
            currentPage={currentPage}
            nextPageExist={nextPageExist}
            handlePageDown={this.handlePageDown}
            handlePageUp={this.handlePageUp}
          />
        </EntitiesList>
      </Panel>
    );
  }
}

const mapStateToProps = (state) => ({
  session: getSelectedCommerce(state),
  viewType: state.responsiveData.viewType,
  withdraw: getViewMposCommerces(state).withdraw,
  viewState: getViewMposCommerces(state).viewState,
  selectedWithdraw: getViewMposCommerces(state).selectedWithdraw,
  selectedCommerce: getViewMposCommerces(state).selectedCommerce,
});

const mapDispatchToProps = {
  setViewState,
  selectWithdraw,
  clearReduxStore,
  throwPopupMessage,
  updateWithdrawList,
};

const WithdrawPanel = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithdrawPanel_unconnected);

export { WithdrawPanel };
