import {
  BillsListEmptyModel,
  BillsListHeaderModel,
  BillsListHeaderPhoneModel,
  BillsListItemModel,
  BillsListItemPhoneModel,
} from "./model";

import styled from "styled-components";
import { grey_4, bluePD } from "assets/strings/colors";

export const BillsListHeader = styled(BillsListHeaderModel)`
  #root & {
    margin: 0;
    padding: 2rem 1rem 1rem 1rem;
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    text-transform: uppercase;
    font-weight: bold;
    color: ${grey_4};
    border-bottom: 2px solid ${bluePD};
    > li {
      width: 20%;
      text-align: center;
    }
  }
`;
export const BillsListHeaderPhone = styled(BillsListHeaderPhoneModel)``;

export const BillsListItem = styled(BillsListItemModel)`
  #root & {
    margin: 0;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style-type: none;
    width: calc(100% - 1px);
    border-bottom: 1px solid ${grey_4};
    height: 7vh;

    > li {
      width: 20%;
      white-space: nowrap;
      overflow: hidden;
      text-align: center;
      text-overflow: ellipsis;
    }
    > .number {
      color: ${bluePD};
    }
    > .link {
      :hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
`;

export const BillsListItemPhone = styled(BillsListItemPhoneModel)``;

export const BillsListEmpty = styled(BillsListEmptyModel)`
  #root & {
    width: 100%;
    text-align: center;
    padding: 2rem;
  }
`;
