import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { errors } from 'assets/strings/texts'
import { getSession } from 'redux/selectors/session'
import { setUserEmail } from 'redux/actions/session'
import { popup_transition_time } from 'assets/strings/constants'
import { throwPopupMessage, closePopup } from 'components/popup-message'
import EmailVerificationView from './components/email-verification-view'
import { sendEmailVerification, verifyEmail, updateEmail } from 'services/api'
import { throwPopupMessage as throwPopupMessageRedux } from 'redux/actions/popup-messages'

class EmailValidation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      formData: {
        email: '',
        code: ''
      },
      popupMessages: null,
      popupMessagesType: '',
      showPopup: false,
      redirect: null
    }
    // ========== INSTANCE PROPERTIES ==========
    
    // set initial state
    this.initialState = { ...this.state }
    // Bind PopupMessage functions
    this.throwPopupMessage = throwPopupMessage.bind(this)
    this.closePopup = closePopup.bind(this)
  }

  // ========== METHODS ==========
  // 1)
  handleFormChange = (event) => {
    this.setState({
      formData: {
        ...this.state.formData,
        [event.target.name]: event.target.value
      }
    })
  }

  openChangeEmailForm = () => {
    this.throwPopupMessage('', '')
  } 

  // 3)
  handleEmailReSend = (event) => {
    event.preventDefault()
    this.setState({ loading: true })
    sendEmailVerification()
      .then(res => {
        if(!res.ok){ throw res }
        return res.json()
      })
      .then(res => {
        if(res.message){
          this.setState({ ...this.initialState })
          this.props.throwPopupMessage('success', res.message)
        }
      })
      .catch(err => {
        // If error has a body, check the response 
        if(typeof err.json === 'function'){
          err.json().then(err_body => {
            this.setState({ ...this.initialState })
            this.props.throwPopupMessage('error', err_body.error.description)

          })
        } else {
          this.setState({ ...this.initialState })
          this.props.throwPopupMessage('error', errors.GENERAL_ERR)
        }
      });
  }

  // 4)
  handleCodeSubmit = (event) => {
    event.preventDefault()
    this.setState({ loading: true })
    verifyEmail(this.state.formData.code)
      .then(res => {
        if(!res.ok){ throw res }
        return res.json()
      })
      .then(res => {
        if (res.message) {
          this.props.throwPopupMessage('success', res.message)
          this.setState({
            loading: false,
            redirect: <Redirect to='/owner-info'/> 
          })
        }
      })
      .catch(err => {
        // If error has a body, check the response 
        if(typeof err.json === 'function'){
          err.json().then(err_body => {
            this.setState({ ...this.initialState })
            this.props.throwPopupMessage('error', err_body.error.description)

          })
        } else {
          this.setState({ ...this.initialState })
          this.props.throwPopupMessage('error', errors.GENERAL_ERR)
        }
      });
  }

  // 5)
  handleEmailUpdate = (event) => {
    event.preventDefault()
    this.setState({loading: true})
    updateEmail({ email: this.state.formData.email })
      .then(res => {
        if(!res.ok){ throw res }
        return res.json()
      })
      .then(res => {
        if (res.message){
          this.props.setUserEmail(this.state.formData.email)
          return sendEmailVerification()
        }
      })
      .then(res => {
        if(!res.ok){ throw res }
        return res.json()
      })
      .then(res => {
        if(res.message){
          // remove email update form
          this.closePopup()
          setTimeout(() => { // after transition present result
            this.setState({ ...this.initialState })
            this.props.throwPopupMessage(
              'success',
              'El código verificación se ha enviado a su nueva dirección de correo electrónico'
            )
          }, popup_transition_time);
        }
      })
      .catch(err => {
        // If error has a body, check the response 
        if(typeof err.json === 'function'){
          err.json().then(err_body => {
            this.setState({ ...this.initialState })
            this.props.throwPopupMessage('error', err_body.error.description)

          })
        } else {
          this.setState({ ...this.initialState })
          this.props.throwPopupMessage('error', errors.GENERAL_ERR)
        }
      });
  }
  
  render() {
    return (
      <EmailVerificationView
        // props
        loading={this.state.loading}
        formData={this.state.formData}
        email={this.props.userEmail}
        redirect={this.state.redirect}
        popupMessages={this.state.popupMessages}
        popupMessagesType={this.state.popupMessagesType}
        showPopup={this.state.showPopup}
        // methods
        handleFormChange={this.handleFormChange}
        handleEmailReSend={this.handleEmailReSend}
        handleCodeSubmit={this.handleCodeSubmit}
        openChangeEmailForm={this.openChangeEmailForm}
        handleEmailUpdate={this.handleEmailUpdate}
        closePopup={this.closePopup}
      />
    )
  }
}

const mapStateToProps = state => ({
  userEmail: getSession(state).email
})
const mapDispatchToProps = {
  throwPopupMessage: throwPopupMessageRedux,
  setUserEmail
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailValidation)