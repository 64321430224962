import React, { PureComponent } from "react";
import moment from "moment";
import "moment/locale/es-us";
import { connect } from "react-redux";
import { errors } from "assets/strings/texts";
import { clearReduxStore } from "redux/actions/general";
import { parkingAssociatedDashboard } from "services/api";
import { general as texts } from "assets/strings/texts";
import { bluePD } from "assets/strings/colors";
import { getSelectedCommerce } from "redux/selectors/commerces";
import MposParkingAssociatedView from "./components/mpos-parking-associated-view";
import { owners_dashboard as viewTexts } from "assets/strings/texts";
import { throwPopupMessage, closePopup } from "components/popup-message";

moment.locale("es-us");

class MposParkingAssociated extends PureComponent {
  constructor(props) {
    super(props);
    let ticketsPerDay = [];
    let dayOfPastMonth = moment({ hour: "0" }).subtract(31, "days");
    for (let i = 0; i < 32; i++) {
      ticketsPerDay.push({
        day: dayOfPastMonth.format("MMM D"),
        usedTickets: 0,
      });
      dayOfPastMonth.add(1, "days");
    }

    let availableOrValidatedTicketsText = "";
    if (props.selectedCommerce.parking.billing_type === "prepaid") {
      availableOrValidatedTicketsText = viewTexts.availableTickets;
    } else if (props.selectedCommerce.parking.billing_type === "postpaid") {
      availableOrValidatedTicketsText = viewTexts.ticketsValidated;
    }
    let summary = [
      // tickets digitized during this week
      {
        color: bluePD,
        text: viewTexts.ticketsPaid,
        value: 0,
      },
      // tickets available / validated
      {
        color: bluePD,
        text: availableOrValidatedTicketsText,
        value: 0,
      },
      // rate
      {
        color: bluePD,
        decimals: 2,
        text: texts.rate,
        value: 0,
        unit: "",
      },
      // rate
      {
        color: bluePD,
        decimals: 1,
        text: viewTexts.averageParkingTime,
        value: 0,
        unit: "min",
      },
    ];

    this.state = {
      ticketsPerDay,
      summary,
      popupMessages: null,
      popupMessagesType: "",
      showPopup: false,
      loading: false,
    };
    this.initialState = { ...this.state };
    // Bind PopupMessage functions
    this.throwPopupMessage = throwPopupMessage.bind(this);
    this.closePopup = closePopup.bind(this);
  }

  componentDidMount() {
    let todayStartIso = moment({ hour: "0" }).toISOString(true);
    this.setState({ loading: true });
    parkingAssociatedDashboard(todayStartIso)
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((res) => {
        // format tickets_by_day
        this.setState({ loading: false });
        let resTicketsPerDay = [];
        let [...ticketsPerDay] = this.state.ticketsPerDay;
        res.tickets_per_day.forEach((ticketsOfDay) => {
          resTicketsPerDay.push({
            day: moment({
              y: ticketsOfDay.date.year,
              M: ticketsOfDay.date.month - 1,
              d: ticketsOfDay.date.day,
            }).format("MMM D"),
            usedTickets: ticketsOfDay.count,
          });
        });
        ticketsPerDay.forEach((ticketsOfOneDay, index) => {
          resTicketsPerDay.forEach((resTicketsOfOneDay) => {
            if (ticketsOfOneDay.day === resTicketsOfOneDay.day) {
              ticketsPerDay[index] = {
                ...ticketsOfOneDay,
                usedTickets: resTicketsOfOneDay.usedTickets,
              };
            }
          });
        });

        //console.log(res);

        let summary = [];
        // num of tickets digitized during the week
        summary.push({ ...this.state.summary[0] });
        summary[0].value = res.tickets_of_the_week
          ? res.tickets_of_the_week
          : 0;
        // Available tickets (prepaid) / Tickets validated (postpaid)
        summary.push({ ...this.state.summary[1] });
        if (this.props.selectedCommerce.parking.billing_type === "prepaid") {
          summary[1].value = res.available_tickets ? res.available_tickets : 0;
        } else if (
          this.props.selectedCommerce.parking.billing_type === "postpaid"
        ) {
          summary[1].value = res.tickets_validated ? res.tickets_validated : 0;
        }
        // rate
        summary.push({ ...this.state.summary[2] });
        let ticketAmount = res.rate && res.rate.amount ? res.rate.amount : "";
        let currency = res.rate && res.rate.currency ? res.rate.currency : "";
        summary[2].value = ticketAmount;
        summary[2].unit = currency;
        // average parking time
        summary.push({ ...this.state.summary[3] });
        try {
          summary[3].value = Number.parseFloat(res.avg_duration);
          summary[3].unit = ` ${res.avg_duration.split(" ")[1]}`;
        } catch (error) {
          summary[3].value = 0;
          summary[3].unit = "min";
        }
        // Apply dashboard data
        this.setState({
          summary,
          ticketsPerDay,
        });
      })
      // if an error occurs, reset the view and print the error
      .catch((err) => {
        // If error has a body, check the response
        if (typeof err.json === "function") {
          err.json().then((err_body) => {
            // If it is an authentication error, clear the redux store to close session
            if (
              err_body.error.id === "NO_TOKEN_PROVIDED" ||
              err_body.error.id === "AUTHENTICATE_FAILED" ||
              err_body.error.id === "DUPLICATE_SESSION"
            ) {
              this.throwPopupMessage("alert", errors.AUTHENTICATION);
              //this.props.clearReduxStore();
              return; // prevent setState exeution on unmounted component
            } else {
              this.setState({ ...this.initialState });
              this.throwPopupMessage("error", err_body.error.description);
            }
          });
        } else {
          this.setState({ ...this.initialState });
          this.throwPopupMessage("error", errors.GENERAL_ERR);
        }
      });
  }
  render() {
    return (
      <MposParkingAssociatedView
        summary={this.state.summary}
        ticketsPerDay={this.state.ticketsPerDay}
        popupMessages={this.state.popupMessages}
        popupMessagesType={this.state.popupMessagesType}
        showPopup={this.state.showPopup}
        closePopup={this.closePopup}
        loading={this.state.loading}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  selectedCommerce: getSelectedCommerce(state),
});

const mapDispatchToProps = {
  clearReduxStore,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MposParkingAssociated);
