import React from "react";

const formatCurrency = (event) => {
  let value = event.target.value;
  value = value.replace(/\D/g, "");
  value = value.replace(/(\d)(\d{2})$/, "$1,$2");
  value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
  event.target.value = value;
  return event;
};

const handleClick = (event) => {
  event.target.focus();
  const inputValue = event.target.value;
  event.target.setSelectionRange(inputValue.length, inputValue.length);
};

const AmountInput = ({ onChangeInput, ...props }) => {
  return (
    <input
      {...props}
      type="text"
      onChange={(event) => onChangeInput(formatCurrency(event))}
      onClick={handleClick}
    />
  );
};

export default AmountInput;
