import AssignToModel from "./model";
import styled from "styled-components";
import { bluePD, grey_6 } from "assets/strings/colors";

const AssignTo = styled(AssignToModel)`
  #root && {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > .title {
      font-size: 1.2rem;
    }
    > label {
      align-self: flex-start;
      border-bottom: 1px solid ${grey_6};
      color: ${grey_6};
      font-weight: bold;
    }
    > input {
      margin: 1rem;
      border: 1px solid ${bluePD};
      border-radius: 2rem;
      text-align: center;
      font-size: 1rem;
      width: 90%;
      height: 30px;
      :focus {
        outline: none;
        border: 1px solid ${bluePD};
      }
    }
  }
`;

export default AssignTo;
