import React, { useContext } from "react";
import { UsersExtensionsContext } from "../context";
//import { PermissionsValidator } from "components/permissions-validator";
import { RightBorderGreyColumn } from "components/styled-grid-column";
import PopupMessage from "components/popup-message";
import { Grid } from "semantic-ui-react";
import { UsersSearch } from "./user-search";
import { UserInfo } from "./user-info";
import { ExtensionForm } from "./extension-form";
import { MovementDetails } from "./movement-details";
import { OptionConfirmation } from "./option-confirmation";

const MPosUsersExtensionsPhoneView = () => {
  const { state, popupMessages, popupMessagesType, showPopup, closePopup } =
    useContext(UsersExtensionsContext);
  return (
    <Grid padded>
      <PopupMessage
        messageType={popupMessagesType}
        showPopup={showPopup}
        messages={popupMessages}
        closePopup={closePopup}
      >
        <OptionConfirmation />
      </PopupMessage>

      <RightBorderGreyColumn>
        {state.viewState === "user_search" && <UsersSearch />}

        {state.viewState === "user_selected" && <UserInfo />}
        {(state.viewState === "extension_selected" ||
          state.viewState === "new_extension") && <ExtensionForm />}
        {state.viewState === "movement_selected" && <MovementDetails />}
      </RightBorderGreyColumn>
    </Grid>
  );
};

export default MPosUsersExtensionsPhoneView;
