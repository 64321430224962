import SearchInputModelNew from "./model";
import styled from "styled-components";
import { grey_4, bluePD } from "assets/strings/colors";

const SearchInputNew = styled(SearchInputModelNew)`
  #root & {
    display: flex;
    align-items: center;
    width: 100%;
    height: 3rem;
    border-bottom: solid 1px ${grey_4};
    font-size: 1rem;
    .input-tag {
      margin-left: 0.5rem;
      height: 2rem;
      max-width: calc(100% - 60px);
      ::placeholder {
        overflow-wrap: normal;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .search {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      width: ${(props) => (props.width ? props.width : "15%")};
      height: 100%;
      align-self: flex-end;
      > i {
        color: ${bluePD};
        height: 100%;
        width: 100%;
        :hover {
          cursor: pointer;
          width: calc(100% - 1px);
          border-left: solid 2px white;
          padding-right: 2px;
        }
      }
    }
    @media (max-width: 400px) {
      .search {
        width: 20%;
      }
    }
  }
  *,
  :before {
    box-sizing: initial;
  }
`;

export default SearchInputNew;
