import React, { PureComponent } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { debounce } from "services/utils";
import { errors } from "assets/strings/texts";
import { clearReduxStore } from "redux/actions/general";
import { ticketsAutocomplete } from "services/api";
import { tickets_validate as viewTexts } from "assets/strings/texts";
import { throwPopupMessage, closePopup } from "components/popup-message";
import MposTicketsValidateView from "./components/tickets-validate-view";
import MposTicketsValidatePhoneView from "./components/tickets-validate-phone-view";

import { addCommerces, selectCommerce } from "redux/actions/commerces";
import {
  getSelectedCommerce as getSelectedCommerceFromRedux,
  getSelectedBuilding as getSelectedBuildingFromRedux,
} from "redux/selectors";
import {
  socket_timeout,
  debouncer_timeout,
  popup_transition_time,
  element_transition_time,
} from "assets/strings/constants";

import {
  addSocketListener,
  /*  joinSocket, */
  markTicketAsPaid,
  calculateTicketAmount,
  removeSocketListener,
  initiateSocket,
  joinCommerceSocket,
  disconnectSocket,
} from "services/websockets";

class MPosTicketsValidate extends PureComponent {
  constructor(props) {
    super(props);
    let commerce = this.props.getSelectedCommerce;
    let building = this.props.getSelectedBuilding;
    this.state = {
      commerce,
      building,
      token: this.props.token,
      viewState: "ticket_search",
      searchParam: "",
      nextPageExist: false,
      currentPage: 1,
      tickets: [],
      selectedTicket: null,
      showTicket: true,
      amount: null,
      pdFee: null,
      commerceFee: null,
      subTotal: null,
      tax: null,
      taxPercent: null,
      totalAmount: null,
      // viewType: 'phone' | is set when component mounts
      popupMessages: null,
      popupMessagesType: "",
      showPopup: false,
    };
    // ========== INSTANCE PROPERTIES ==========
    this.initialState = { ...this.state };
    this.digitizedTicket = null;
    this.calculateAmountTimeout = null;
    this.markAsPaidTimeout = null;
    this.ticketsPerPage = 20;
    // Bind popup message functions
    this.throwPopupMessage = throwPopupMessage.bind(this);
    this.closePopup = closePopup.bind(this);
  }

  // =============== STATE DOCS ===============
  /*
  --> ticket_search
    { ...this.initialState }
    Error messages will be shown here
  
  --> ticket_search_loading
    { ...this.initialState }
    or
    {
      ...this.this.initialState,
      searchParam: String,
      currentPage: Number
    }
  
  --> ticket_search_done
    {
      searchParam: String,
      tickets: [{zero or more}],
      currentPage: Number,
      nextPageExist: boolean
    }
  
  --> ticket_selection
    {
      searchParam: String,
      tickets: [{zero or more}],
      currentPage: Number,
      nextPageExist: boolean,
      selectedTicket: {object},
      showTicket: true
    }
  
  --> ticket_selection_loading
    {
      searchParam: String,
      tickets: [{zero or more}],
      currentPage: Number,
      nextPageExist: boolean,
      selectedTicket: {object},
      showTicket: true
    }
  
  --> ticket_selection_done
    {
      searchParam: String,
      tickets: [{zero or more}],
      currentPage: Number,
      nextPageExist: boolean,
      selectedTicket: {object},
      showTicket: true,
      amount: Number,
      pdFee: Number,
      commerceFee: Number,
      subTotal: Number,
      tax: Number,
      taxPercent: Number,
      totalAmount: Number
    }
  
  --> ticket_validation
    {
      searchParam: String,
      tickets: [{zero or more}],
      currentPage: Number,
      nextPageExist: boolean,
      selectedTicket: {object},
      showTicket: true
      amount: Number,
      pdFee: Number,
      commerceFee: Number,
      subTotal: Number,
      tax: Number,
      taxPercent: Number,
      totalAmount: Number,
      showPopup: true,
      popupMessagesType: 'alert',
      popupMessages: ['¿Desea habilitar el ticket seleccionado?']
    }
  
  --> ticket_validation_loading
    {
      searchParam: String,
      tickets: [{zero or more}],
      currentPage: Number,
      nextPageExist: boolean,
      selectedTicket: {object},
      showTicket: true
      amount: Number,
      pdFee: Number,
      commerceFee: Number,
      subTotal: Number,
      tax: Number,
      taxPercent: Number,
      totalAmount: Number,
      showPopup: true,
      popupMessagesType: 'alert',
      popupMessages: ['¿Desea habilitar el ticket seleccionado?']
    }
  
  --> ticket_validation_done
    {
      searchParam: String,
      tickets: [{zero or more}],
      currentPage: Number,
      nextPageExist: boolean,
      selectedTicket: {object},
      showTicket: true
      amount: Number,
      pdFee: Number,
      commerceFee: Number,
      subTotal: Number,
      tax: Number,
      taxPercent: Number,
      totalAmount: Number,
      showPopup: false,
      popupMessagesType: '',
      popupMessages: null
    }
  
  */
  // =============== END STATE DOCS ===============

  /*
   ** mpos-ticket-validate on Mount:
   ** --> join the socket
   ** --> set the 'mark as paid' event listener
   ** --> set the 'calculate amount' event listener
   ** --> set viewType
   ** --> set the 'resize' event listener
   ** --> set states to validate digitized ticket if exist
   */
  componentDidMount() {
    // --> Join Socket
    /*   initiateSocket();
    joinCommerceSocket(this.props.token, (socket_res) => {
      console.log(socket_res.message);
    });*/
    /*   joinSocket(this.props.token, (socket_res) => {
      console.log(socket_res.message);
    }); */
    // --> Set 'mark as paid' event listener
    initiateSocket();
    joinCommerceSocket(this.state.token, (socket_res) => {
      console.log(socket_res.message);
    });
    addSocketListener("mark as paid", (socket_res) => {
      if (socket_res.error) {
        // Remove timeout
        clearTimeout(this.markAsPaidTimeout);
        // remove confirmation popup and then show error popup
        this.closePopup();

        setTimeout(
          function () {
            this.setState({ ...this.initialState });
            this.throwPopupMessage("error", socket_res.error.description);
          }.bind(this),
          popup_transition_time
        );
      }
      if (socket_res.message) {
        // Remove timeout
        clearTimeout(this.markAsPaidTimeout);

        if (socket_res.commerce) {
          // When a ticket is validated by a commerce
          this.props.addCommerces([socket_res.commerce]); // Add commerce updated to redux store
          this.props.selectCommerce(socket_res.commerce._id); // Set the commerce as selectedCommerce in redux store
        }
        // If it is on the correct state, procede
        if (this.state.viewState === "ticket_validation_loading") {
          console.log(socket_res.message);
          this.setState({ viewState: "ticket_validation_done" });
          this.closePopup();
        }
      }
    });
    // --> Set 'calculate amount' event listener
    addSocketListener("calculate amount", (socket_res) => {
      if (this.state.viewState === "ticket_selection_loading") {
        if (socket_res.ticket === this.state.selectedTicket._id) {
          clearTimeout(this.calculateAmountTimeout);
          this.setState({
            viewState: "ticket_selection_done",
            amount: socket_res.amount,
            pdFee: socket_res.pd_fee,
            commerceFee: socket_res.commerce_fee,
            subTotal: socket_res.sub_total,
            tax: socket_res.tax,
            taxPercent: socket_res.tax_percent,
            totalAmount: socket_res.total_amount,
          });
        }
      }
    });
    // --> Set viewType
    this.setViewType();
    // --> Set 'resize' event listener
    window.addEventListener("resize", this.setViewType);
    document.addEventListener("keydown", this.onEnter);
    // --> set states to validate digitized ticket if exist
    if (
      this.props.location.state &&
      this.props.location.state.digitizedTicket
    ) {
      let start_of_date = moment(
        this.props.location.state.digitizedTicket.enter_at
      )
        .startOf("day")
        .toISOString();
      this.digitizedTicket = {
        ...this.props.location.state.digitizedTicket,
        start_of_date,
      };
      this.props.history.replace({
        pathname: this.props.location.pathname,
        state: {},
      });
      this.setState({
        viewState: "ticket_selection",
        searchParam: this.digitizedTicket.ticket_number,
        tickets: [this.digitizedTicket],
        selectedTicket: this.digitizedTicket,
        showTicket: true,
      });
      this.getTicketAmount(this.digitizedTicket);
    }
    if (this.props.location.state && this.props.location.state.username) {
      let username = this.props.location.state.username;
      this.props.history.replace({
        pathname: this.props.location.pathname,
        state: {},
      });
      this.debouncedApiCall(username, 0, 0);
    }
  }

  // Remove event listeners
  componentWillUnmount() {
    window.removeEventListener("resize", this.setViewType);
    document.removeEventListener("keydown", this.onEnter);
    removeSocketListener();
    disconnectSocket();
  }

  componentDidUpdate() {
    let building = this.props.getSelectedBuilding;
    this.setState({
      building,
    });
  }

  // ========== METHODS ==========
  // 1)
  handleSearchInputChange = (event) => {
    let value = event.target.value;
    // The field is restrcted to accept only letters, numbers, @(first position) and _-.
    if (value.match(/^(?=@?.*)@?[\w.-]*$/)) {
      value = value.toLowerCase();
      this.setState({
        ...this.initialState,
        //viewState: "ticket_search_loading",
        searchParam: value,
      });
      //let skip = 0;
      //let limit = this.ticketsPerPage + 1;
      // call the API in a debounced way
      //this.debouncedApiCall(value, skip, limit);
    }
  };

  // 2)
  handlePageDown = () => {
    // Get data required for the api call
    let skip = (this.state.currentPage - 2) * this.ticketsPerPage;
    let limit = this.ticketsPerPage + 1;
    let value = this.state.searchParam;
    value = value[0] === "@" ? value.substr(1) : value;
    //  set state and make api call
    this.setState((prevState) => ({
      ...this.initialState,
      viewState: "ticket_search_loading",
      searchParam: prevState.searchParam,
      currentPage: prevState.currentPage - 1,
      nextPageExist: true,
    }));

    let commerce = null;
    let building = null;
    if (this.state.commerce && this.state.commerce.category) {
      let category = this.state.commerce.category;
      switch (category) {
        case "pagodirecto":
          building = null;
          break;
        case "estacionamientos":
          building = this.state.building._id;
          break;
        default:
          if (this.state.commerce.parking) {
            commerce = this.state.commerce._id;
          }
          break;
      }
    }

    let promise = ticketsAutocomplete({
      value,
      status: ["ready"],
      to_pay: true,
      skip,
      limit,
      commerce,
      building,
    });
    this.handleTicketsSearchPromise(promise);
  };

  // 3)
  handlePageUp = () => {
    // Get data required for the api call
    let skip = this.state.currentPage * this.ticketsPerPage;
    let limit = this.ticketsPerPage + 1;
    let value = this.state.searchParam;
    value = value[0] === "@" ? value.substr(1) : value;
    // set state and make api call
    this.setState((prevState) => ({
      ...this.initialState,
      viewState: "ticket_search_loading",
      searchParam: prevState.searchParam,
      currentPage: prevState.currentPage + 1,
      nextPageExist: true,
    }));
    let commerce = null;
    let building = null;
    if (this.state.commerce && this.state.commerce.category) {
      let category = this.state.commerce.category;
      switch (category) {
        case "pagodirecto":
          building = null;
          break;
        case "estacionamientos":
          building = this.state.building._id;
          break;
        default:
          if (this.state.commerce.parking) {
            commerce = this.state.commerce._id;
          }
          break;
      }
    }
    let promise = ticketsAutocomplete({
      value,
      status: ["ready"],
      to_pay: true,
      skip,
      limit,
      commerce,
      building,
    });
    this.handleTicketsSearchPromise(promise);
  };

  // 4)
  debouncedApiCall = debounce((value, skip, limit) => {
    // remove the @ from the first position before using it in the request
    value = value[0] === "@" ? value.substr(1) : value;
    // call the API
    let commerce = null;
    let building = null;
    if (this.state.commerce && this.state.commerce.category) {
      let category = this.state.commerce.category;
      switch (category) {
        case "pagodirecto":
          building = null;
          break;
        case "estacionamientos":
          building = this.state.building._id;
          break;
        default:
          if (this.state.commerce.parking) {
            commerce = this.state.commerce._id;
            building = this.state.commerce.parking.building._id;
          }
          break;
      }
    }

    let ticketsPromise = ticketsAutocomplete({
      value,
      status: ["ready"],
      to_pay: true,
      skip,
      limit,
      commerce,
      building,
    });
    // Manage the response
    this.handleTicketsSearchPromise(ticketsPromise);
    // Timer
  }, debouncer_timeout);

  // 5)
  handleTicketsSearchPromise = (promise) => {
    promise
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((res) => {
        if (res.tickets) {
          if (!res.tickets.length) {
            // if the response has no tickets, remove selected ticket from screen
            this.setState((prevState) => ({
              showTicket: false,
            }));
            // and reset view
            setTimeout(() => {
              this.setState((prevState) => ({
                ...this.initialState,
                searchParam: prevState.searchParam,
                viewState: "ticket_search",
              }));
            }, element_transition_time);
          } else {
            let nextPageExist;
            if (res.tickets.length > this.ticketsPerPage) {
              nextPageExist = true;
              res.tickets.pop();
            } else {
              nextPageExist = false;
            }
            // else, print the tickets
            this.setState({
              viewState: "ticket_search_done",
              tickets: res.tickets,
              nextPageExist,
            });
          }
        }
      })
      // if an error occurs, reset the view and print the error
      .catch((err) => {
        // If error has a body, check the response
        if (typeof err.json === "function") {
          err.json().then((err_body) => {
            // If it is an authentication error, clear the redux store to close session
            if (
              err_body.error.id === "NO_TOKEN_PROVIDED" ||
              err_body.error.id === "AUTHENTICATE_FAILED" ||
              err_body.error.id === "DUPLICATE_SESSION"
            ) {
              this.throwPopupMessage("alert", errors.AUTHENTICATION);
              //this.props.clearReduxStore();
              return; // prevent setState exeution on unmounted component
            }
            this.setState({ ...this.initialState });
            this.throwPopupMessage("error", err_body.error.description);
          });
        } else {
          this.setState({ ...this.initialState });
          this.throwPopupMessage("error", errors.GENERAL_ERR);
        }
      });
  };

  // 6)
  handleTicketSelection = (event) => {
    let index = event.currentTarget.id;
    this.setState((prevState) => ({
      showTicket: false,
      amount: null,
      pdFee: null,
      commerceFee: null,
      subTotal: null,
      tax: null,
      taxPercent: null,
      totalAmount: null,
    }));

    setTimeout(
      function () {
        this.setState((prevState) => ({
          viewState: "ticket_selection",
          selectedTicket: prevState.tickets[index],
          showTicket: true,
        }));
        this.getTicketAmount(this.state.tickets[index]);
      }.bind(this),
      element_transition_time
    );
  };

  // 7)
  getTicketAmount = (ticket) => {
    // set 'calculate amount' event timeout
    this.calculateAmountTimeout = setTimeout(() => {
      if (this.state.viewState === "ticket_selection_loading") {
        this.setState({
          viewState: "ticket_selection",
          amount: null,
          pdFee: null,
          commerceFee: null,
          subTotal: null,
          tax: null,
          taxPercent: null,
          totalAmount: null,
        });
      }
    }, socket_timeout);
    // Emit 'calculate amount' event
    this.setState({
      viewState: "ticket_selection_loading",
      amount: null,
      pdFee: null,
      commerceFee: null,
      subTotal: null,
      tax: null,
      taxPercent: null,
      totalAmount: null,
    });
    const hasBuildingId = ticket && ticket.building && ticket.building._id;
    calculateTicketAmount(
      hasBuildingId ? ticket.building._id : ticket.this.props.workspace,
      ticket,
      this.props.token
    );
  };

  // 8)
  removeSelectedTicket = () => {
    // Remove ticket and set transition states
    this.setState((prevState) => ({
      showTicket: false,
    }));
    setTimeout(() => {
      this.setState((prevState) => ({
        viewState: "ticket_search_done",
        selectedTicket: null,
        amount: null,
        pdFee: null,
        commerceFee: null,
        subTotal: null,
        tax: null,
        taxPercent: null,
        totalAmount: null,
      }));
    }, element_transition_time);
  };

  // 9)
  toggleValidatedTicket = () => {
    if (
      this.state.viewState === "ticket_validation" ||
      this.state.viewState === "ticket_validation_loading"
    ) {
      this.closePopup();
      // timeout the update for the animation
      setTimeout(() => {
        this.setState({ viewState: "ticket_selection_done" });
      }, popup_transition_time);
    }
    if (this.state.viewState === "ticket_selection_done") {
      this.setState({ viewState: "ticket_validation" });
      // timeout the update for the animation
      setTimeout(() => {
        this.throwPopupMessage("alert", viewTexts.validateConfirmation);
      }, popup_transition_time);
    }
  };

  // 10)
  validateTicket = () => {
    // set 'mark as paid' event timeout
    this.markAsPaidTimeout = setTimeout(() => {
      if (this.state.viewState === "ticket_validation_loading") {
        this.setState({
          viewState: "ticket_validation",
        });
      }
    }, socket_timeout);
    // Emit 'mark as paid' event
    this.setState({ viewState: "ticket_validation_loading" });
    markTicketAsPaid(this.props.token, this.state.selectedTicket);
  };

  // 11)
  resetView = () => {
    this.setState({ ...this.initialState });
  };

  // 12)
  setViewType = () => {
    let documentElement = document.documentElement,
      width = window.innerWidth || documentElement.clientWidth;
    if (width < 768) {
      this.setState({ viewType: "phone" });
    } else {
      this.setState({ viewType: "desktop" });
    }
  };

  onEnter = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      this.handleSearchTicket(event);
    }
  };

  // new handlers
  handleSearchTicket = (event) => {
    event.preventDefault();
    // search input
    //let skip = (this.state.currentPage - 2) * this.ticketsPerPage;
    //let limit = this.ticketsPerPage + 1;
    let value = this.state.searchParam;
    value = value[0] === "@" ? value.substr(1) : value;
    //  set state and make api call
    this.setState((prevState) => ({
      ...this.initialState,
      viewState: "ticket_search_loading",
      searchParam: value,
    }));

    let commerce = null;
    let building = null;
    if (this.state.commerce && this.state.commerce.category) {
      let category = this.state.commerce.category;
      switch (category) {
        case "pagodirecto":
          building = null;
          break;
        case "estacionamientos":
          building = this.state.building._id;
          break;
        default:
          if (this.state.commerce.parking) {
            commerce = this.state.commerce._id;
          }
          break;
      }
    }

    let promise = ticketsAutocomplete({
      value,
      status: ["ready"],
      to_pay: true,
      skip: 0,
      limit: 0,
      commerce,
      building,
    });
    this.handleTicketsSearchPromise(promise);
  };

  render() {
    let billContent, currency;
    if (
      this.state.selectedTicket &&
      (this.state.amount || this.state.amount === 0) &&
      (this.state.pdFee || this.state.pdFee === 0) &&
      (this.state.commerceFee || this.state.commerceFee === 0) &&
      (this.state.subTotal || this.state.subTotal === 0) &&
      (this.state.tax || this.state.tax === 0) &&
      (this.state.taxPercent || this.state.taxPercent === 0) &&
      (this.state.totalAmount || this.state.totalAmount === 0)
    ) {
      billContent = {
        amount: this.state.amount,
        pdFee: this.state.pdFee,
        commerceFee: this.state.commerceFee,
        subTotal: this.state.subTotal,
        tax: this.state.tax,
        taxPercent: this.state.taxPercent,
        totalAmount: this.state.totalAmount,
      };
      currency = this.state.selectedTicket.building.rate.currency;
    } else {
      billContent = null;
      currency = null;
    }

    if (this.state.viewType === "phone") {
      return (
        <MposTicketsValidatePhoneView
          // properties
          viewState={this.state.viewState}
          searchParam={this.state.searchParam}
          commerce={this.state.commerce}
          tickets={this.state.tickets}
          currentPage={this.state.currentPage}
          nextPageExist={this.state.nextPageExist}
          showTicket={this.state.showTicket}
          selectedTicket={this.state.selectedTicket}
          totalAmount={this.state.totalAmount}
          billContent={billContent}
          currency={currency}
          popupMessages={this.state.popupMessages}
          popupMessagesType={this.state.popupMessagesType}
          showPopup={this.state.showPopup}
          location={this.props.location}
          // methods
          closePopup={this.closePopup}
          handleSearchInputChange={this.handleSearchInputChange}
          handlePageDown={this.handlePageDown}
          handlePageUp={this.handlePageUp}
          handleTicketSelection={this.handleTicketSelection}
          getTicketAmount={this.getTicketAmount}
          removeSelectedTicket={this.removeSelectedTicket}
          toggleValidatedTicket={this.toggleValidatedTicket}
          validateTicket={this.validateTicket}
          resetView={this.resetView}
          handleSearchTicket={this.handleSearchTicket}
        />
      );
    }

    return (
      <MposTicketsValidateView
        // properties
        viewState={this.state.viewState}
        searchParam={this.state.searchParam}
        tickets={this.state.tickets}
        commerce={this.state.commerce}
        currentPage={this.state.currentPage}
        nextPageExist={this.state.nextPageExist}
        showTicket={this.state.showTicket}
        selectedTicket={this.state.selectedTicket}
        billContent={billContent}
        currency={currency}
        popupMessages={this.state.popupMessages}
        popupMessagesType={this.state.popupMessagesType}
        showPopup={this.state.showPopup}
        location={this.props.location}
        // methods
        closePopup={this.closePopup}
        handleSearchInputChange={this.handleSearchInputChange}
        handlePageDown={this.handlePageDown}
        totalAmount={this.state.totalAmount}
        handlePageUp={this.handlePageUp}
        handleTicketSelection={this.handleTicketSelection}
        getTicketAmount={this.getTicketAmount}
        toggleValidatedTicket={this.toggleValidatedTicket}
        validateTicket={this.validateTicket}
        handleSearchTicket={this.handleSearchTicket}
        resetView={this.resetView}
      />
    );
  }
}

const mapStateToProps = (state) => {
  let getSelectedBuilding = getSelectedBuildingFromRedux(state);
  let getSelectedCommerce = getSelectedCommerceFromRedux(state);
  return { getSelectedBuilding, getSelectedCommerce };
};

const mapDispatchToProps = {
  clearReduxStore,
  addCommerces,
  selectCommerce,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MPosTicketsValidate);
