import React, { useContext } from "react";
import Preview from "./Preview";
import Result from "./Result";
import { useStateManager } from "hooks/use-state-manager";
import { WalletAccountsContext } from "views/mpos-superadmin-accounts/context";
import useOnMountWithdraw from "views/mpos-superadmin-accounts/hooks/useOnMountWithdraw";
import styled from "styled-components";
import moment from "moment";
import "moment/locale/es";
moment.locale("es");

const WithdrawModel = ({ data, show }) => {
  if (!data || !show) return null;

  const { throwPopupMessage } = useContext(WalletAccountsContext);
  const initialState = {
    preview: null,
    loadingPreview: true,
    showPreview: true,
    commerce_id: data._id,
    showResult: false,
    result: null,
    loadingResult: true,
  };
  const [state, changeState] = useStateManager(initialState);
  const [setWithdraw, setPreview] = useOnMountWithdraw(
    state,
    changeState,
    throwPopupMessage
  );

  const onWithdraw = (event) => {
    event.preventDefault();
    setWithdraw(true);
    changeState({ showPreview: false, showResult: true });
  };
  const onPreview = (event) => {
    event.preventDefault();
    setPreview(true);
    changeState({ showPreview: false, showResult: true });
  };
  return (
    <>
      <Preview
        data={state.preview}
        loading={false}
        show={state.showPreview}
        onWithdraw={onWithdraw}
        onPreview={onPreview}
      />
      <Result
        data={{ ...state.result }}
        loading={state.loadingResult}
        show={state.showResult}
      />
    </>
  );
};

const Withdraw = styled(WithdrawModel)`
  #root & {
  }
`;

export default Withdraw;
