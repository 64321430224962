import styled from 'styled-components'
import { NewTermsModel } from './model'

const NewTerms = styled(NewTermsModel)`
  #root &{
    >button{
      width: 60%;
    }
  }
`
export { NewTerms }