import BillItem from './model'
import styled from 'styled-components'

const StyledBillItem = styled(BillItem)`
  #root &{
    font-size: 1rem;
    width: 80%;
    margin-bottom: 4.44vh;
    span{
      display: inline-block;
    }
    span:first-child{
      max-width: 50%;
    }
    span:last-child{
      float: right;
    }
  }
`;

export default StyledBillItem