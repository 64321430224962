import {
  TicketListItemModel,
  TicketListLessInfoItemModel,
  TicketListHeaderModel,
  TicketListLessInfoHeaderModel,
} from "./model";
import styled from "styled-components";
import { grey_4, bluePD, magenta } from "assets/strings/colors";

const TicketListItem = styled(TicketListItemModel)`
  #root & {
    cursor: pointer;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 1.1vh 1rem 1.1vh 1rem;
    border-bottom: 1px solid ${grey_4};
    margin: 0;
    > .ticket-icon {
      display: block;
      width: 16px;
      height: 16px;
      margin: 0.9vh 0.4rem 0.2vh -0.5rem;
    }
    > .location-icon {
      display: block;
      width: 15px;
      height: 20px;
      margin: 0.9vh 0.4rem 0.2vh -0.5rem;
    }
    > .data {
      width: 25%;
      padding: 0;
      margin: 0;
      text-align: left;
      > .ticket-number {
        margin: 0;
        text-align: left;
        display: block;
        color: #63c0e8;
      }
      > .ticket-username {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        > .new-user {
          margin: 0;
          margin-right: 0.2rem;
          font-size: 0.3rem;
          padding: 0 0.15rem;
          border: 1px solid ${magenta};
          border-radius: 100%;
          background: ${magenta};
        }
      }
    }

    > .status {
      width: 25%;
      padding: 0;
      margin: 0;
      padding-right: 0.5rem;
      text-align: center;
      > .funds {
        margin-top: -1rem;
        color: #ff0000;
        font-size: 1rem;
        font-weight: bold;
      }
    }
    > .building {
      width: 25%;
      padding: 0;
      margin: 0;
      padding-left: 2rem;
      text-align: center;
    }
    > .time {
      width: 25%;
      padding: 0;
      margin: 0;
      padding-left: 3rem;
      text-align: center;
      display: block;
      margin: 1.1vh 1rem 1.1vh auto;
      float: right;
    }
    > .time-less-info {
      width: 25%;
      display: block;
      margin: 1.1vh 1rem 1.1vh auto;
      float: right;
    }

    @media (max-width: 900px) {
      font-size: 0.8rem;
      > .data {
        padding: 0;
      }
      > .status {
        > .funds {
          font-size: 0.8rem;
        }
      }
    }
    @media (max-width: 767px) {
      font-size: 0.7rem;
      > .data {
        padding: 0;
      }
      > .status {
        > .funds {
          font-size: 0.7rem;
        }
      }
    }
    @media (max-width: 380px) {
      font-size: 0.6rem;
      > .data {
        padding: 0;
      }
      > .status {
        > .funds {
          font-size: 0.6rem;
        }
      }
    }
  }
`;
const TicketListLessInfoItem = styled(TicketListLessInfoItemModel)`
  #root & {
    cursor: pointer;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 1.1vh 1rem 1.1vh 1rem;
    border-bottom: 1px solid ${grey_4};
    margin: 0;
    > .ticket-icon {
      display: block;
      width: 16px;
      height: 16px;
      margin: 0.9vh 0.8rem 0.2vh 0;
    }
    > .data {
      width: 22%;
      padding: 0;
      margin: 0;
      text-align: left;
      > .ticket-number {
        margin: 0;
        margin-right: 0.4rem;
        display: block;
        color: #63c0e8;
      }
      > .ticket-username {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    > .status {
      width: calc(25% - 1rem);
      padding: 0;
      margin: 0;
      padding-right: 0.5rem;
      text-align: center;
      > .funds {
        margin-top: -1rem;
        color: #ff0000;
        font-size: 1rem;
        font-weight: bold;
      }
    }
    > .time {
      width: 25%;
      padding: 0;
      margin: 0;
      padding-left: 3rem;
      text-align: right;
      display: block;
      margin: 1.1vh 1rem 1.1vh auto;
      float: right;
    }
    @media (max-width: 900px) {
      font-size: 0.8rem;
      > .data {
        padding: 0;
      }
      > .status {
        > .funds {
          font-size: 0.8rem;
        }
      }
    }
    @media (max-width: 767px) {
      font-size: 0.7rem;
      > .data {
        padding: 0;
      }
      > .status {
        > .funds {
          font-size: 0.7rem;
        }
      }
    }
    @media (max-width: 380px) {
      font-size: 0.6rem;
      > .data {
        padding: 0;
      }
      > .status {
        > .funds {
          font-size: 0.6rem;
        }
      }
    }
  }
`;

const TicketListHeader = styled(TicketListHeaderModel)`
  #root & {
    margin: 0;
    border-bottom: 2px solid ${bluePD};
    > .col {
      color: ${grey_4};
      margin: 1.2rem 0.5rem 0 0.5rem;
      padding-bottom: 0.3rem;
      display: inline-block;
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
    }
    > .forty {
      width: calc(40% - 1rem);
    }
    > .twenty {
      width: calc(20% - 1rem);
    }
    > .twenty-five {
      width: calc(25% - 1rem);
    }
  }
`;
const TicketListLessInfoHeader = styled(TicketListLessInfoHeaderModel)`
  #root & {
    margin: 0;
    border-bottom: 2px solid ${bluePD};

    > .col {
      color: ${grey_4};
      margin: 1.2rem 0.5rem 0 0.5rem;
      padding-bottom: 0.3rem;
      display: inline-block;
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
    }
    > .twenty-five {
      width: calc(25% - 1rem);
    }
    > .fifty {
      width: calc(50% - 1rem);
      padding-right: 2rem;
      text-align: right;
    }
  }
`;
export {
  TicketListItem,
  TicketListHeader,
  TicketListLessInfoItem,
  TicketListLessInfoHeader,
};
