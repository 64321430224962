import { SummaryTabsModel } from "./model";
import styled from "styled-components";

const EarningsSummary = styled(SummaryTabsModel)`
  .value {
    font-size: 2rem;
    text-align: left;
    font-weight: bold;
    margin: 1.2rem 1.2rem 1rem 1.2rem;
  }
  .value::after {
    font-size: 1rem;
    content: ${(props) => props.unit && "'" + props.unit + "'"};
  }
  .text {
    font-size: 1.2rem;
    margin: 0 1rem 1rem 1rem;
    color: #5b5b5b;
    font-weight: bold;
    text-transform: uppercase;
    text-align: right;
  }
`;
const DashboardSummary = styled(SummaryTabsModel)`
  .value {
    font-size: 1.6rem;
    text-align: center;
    font-weight: bold;
    margin: 1.2rem;
  }
  .value::after {
    font-size: 1rem;
    content: ${({ unit }) => (unit ? `' ${unit}'` : `''`)};
  }
  .text {
    border-top: 3px solid ${(props) => props.color};
    font-size: 1.2rem;
    height: 2.7rem;
    border-radius: 0.2rem 0 0 0;
    margin-bottom: 1rem;
    padding: 0 1rem 0 1rem;
    color: #5b5b5b;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
  }
  @media screen and (max-width: 576px) {
    .value {
      font-size: 1.2rem;
    }
    .value::after {
      font-size: 0.9rem;
    }
    .text {
      font-size: 0.9rem;
      height: 2.2rem;
    }
  }
`;

export { EarningsSummary, DashboardSummary };
