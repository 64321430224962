import React from "react";

const ItemContentModel = ({ title, content, className }) => {
  return (
    <div className={className}>
      <p className="title">{title}</p>
      <p className="content">{content}</p>
    </div>
  );
};
export { ItemContentModel };
