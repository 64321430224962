import SidebarItemModel from './model'
import styled from 'styled-components'
import { blue, grey_5 } from 'assets/strings/colors'

const SidebarItem = styled(SidebarItemModel)`
  #root &{
    cursor: ${props => props.clickable ? 'pointer' : 'auto'};
    color: white;
    line-height: 1;
    border-top: 1px solid ${grey_5};
    text-align: left;
    position: relative;
    :hover{
      background: rgba(107, 107, 107, 0.2)
    }
    ${props => props.newFeature ?
     `&::before{
      content: '';
      display: inline-block;
      background: ${blue};
      height: 10px;
      width: 10px;
      border-radius: 100%;
      position: absolute;
      top: 17px;
      right: ${props.collapsible ? '45px': '20px'};
    }` : '' }
    >p{
      margin: 0;
      padding: 1rem 1.15rem;
      >.caret.down.icon{
        float: right;
        transform: rotateZ(0deg);
        transition: transform 150ms linear;
      }
      >.caret.down.icon.collapsed{
        transform: rotateZ(-90deg)
      }
    }
    >.collapsed{
      margin-left: 2rem;
      max-height: 0;
      overflow: hidden;
      transition: max-height 450ms linear;
    }
    >.collapsed.false{
      max-height: 1000px;
    }
`;

export default SidebarItem