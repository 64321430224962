import React, { useContext } from "react";
import { NotificationForm } from "./notification-form";
import { Grid } from "semantic-ui-react";
import PopupMessage from "components/popup-message";
import { UsersNotificationsContext } from "../context";
import { Panel, PanelTitle } from "components/panel";

const MPosUsersNotificationsMassivePhoneView = (props) => {
  const { popupMessages, popupMessagesType, showPopup, closePopup } =
    useContext(UsersNotificationsContext);
  return (
    <Grid padded centered>
      <PopupMessage
        messageType={popupMessagesType}
        showPopup={showPopup}
        messages={popupMessages}
        closePopup={closePopup}
      />
      <Grid.Column>
        <Panel>
          <PanelTitle>Notificación push masiva</PanelTitle>
          <NotificationForm />
        </Panel>
      </Grid.Column>
    </Grid>
  );
};

export default MPosUsersNotificationsMassivePhoneView;
