import MtdListItemModel from "./model";
import styled from "styled-components";
import { grey_4 } from "assets/strings/colors";

const MtdListItem = styled(MtdListItemModel)`
   {
    border-bottom: solid 1px ${grey_4};
    cursor: pointer;
    padding: 0.5rem 1rem;
    margin: 0;
    position: relative;
    > .img {
      display: inline-block;
      vertical-align: middle;
      margin-right: 1rem;
      height: 30px;
      border-radius: 200px;
    }
    > .data {
      display: inline-block;
      vertical-align: middle;
      width: calc(85% - 30px - 1rem);
      > .name {
        color: #63c0e8;
        font-size: 1.2rem;
        font-weight: bold;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: wrap;
      }
      > .mac {
        color: #454545;
        font-size: 0.9rem;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        > span {
          font-weight: bold;
        }
      }
      > .type {
        color: #454545;
        font-size: 0.9rem;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
`;
export default MtdListItem;
