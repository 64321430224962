import React from "react";
import moment from "moment";
import "moment/locale/es-us";
import okCircleIcon from "assets/img/ok-circle-icon-64px.png";
import isotipoPd from "assets/img/logotipo-pd-app.png";
import defaultUser from "assets/img/default-user-grey.png";
import excamCircleIcon from "assets/img/exclam-circle-icon-64px.png";
import closeCircleIcon from "assets/img/close-circle-icon-64px.png";
import bellCircleIcon from "assets/img/bell-circle-icon-64px.png";
import { formatPrice } from "services/utils";
import {
  MovementListItem,
  MovementPhoneListItem,
  MovementListHeader,
  NoMovementFound,
} from "../movements-list";

moment.locale("es-us");

const MovementListItemModel = ({
  handleMovementSelection,
  movementDate,
  counterpart,
  totalAmount,
  currency,
  type,
  transactionTag,
  transactionLabel,
  status,
  id,
  className,
  style,
}) => {
  if (!currency) currency = "BS";
  return (
    <dd
      id={id}
      className={className}
      onClick={handleMovementSelection}
      style={style}
    >
      <div className="period-section">
        <p className="date">{movementDate}</p>
      </div>

      {type === "debit" && (
        <p className="amount-debit">
          {"-" + formatPrice(totalAmount) + " " + currency}
        </p>
      )}
      {type === "credit" && (
        <p className="amount-credit">
          {formatPrice(totalAmount) + " " + currency}
        </p>
      )}

      <div className="status">
        {status === "approved" && type === "credit" && (
          <img src={okCircleIcon} alt="approved" />
        )}
        {status === "reversed" && type === "credit" && (
          <img src={okCircleIcon} alt="approved" />
        )}
        {status === "approved" && type === "debit" && (
          <img src={excamCircleIcon} alt="approved" />
        )}
        {status === "pending" && <img src={bellCircleIcon} alt="pending" />}
        {status === "rejected" && <img src={closeCircleIcon} alt="rejected" />}
        {type === "credit" && status === "approved" && (
          <p className="approved">Crédito</p>
        )}
        {type === "credit" && status === "reversed" && (
          <p className="approved">Reverso</p>
        )}
        {type === "credit" && status === "pending" && (
          <p className="pending">Crédito-pendiente</p>
        )}
        {type === "credit" && status === "rejected" && (
          <p className="rejected">Crédito-rechazado</p>
        )}
        {type === "debit" &&
          (transactionTag === "withdraw" ? (
            <p className="debit">Retiro</p>
          ) : (
            <p className="debit">Débito</p>
          ))}
      </div>
      <div className="user">
        {counterpart === "pagodirecto" && (
          <img src={isotipoPd} alt="paid" className="user" />
        )}
        {counterpart === "pagodirecto" && (
          <p className="user">
            <strong>Pago</strong>Directo
          </p>
        )}

        {counterpart !== "pagodirecto" && (
          <img src={defaultUser} alt="paid" className="user" />
        )}
        {counterpart !== "pagodirecto" && (
          <p className="user">{"@" + counterpart}</p>
        )}
        {transactionLabel && <p className="label">{transactionLabel}</p>}
      </div>
    </dd>
  );
};

const MovementPhoneListItemModel = ({
  handleMovementSelection,
  viewState,
  movementDate,
  counterpart,
  totalAmount,
  currency,
  type,
  transactionTag,
  transactionLabel,
  status,
  id,
  style,
  className,
}) => {
  if (!currency) currency = "BS";
  return (
    <dd
      id={id}
      className={className}
      onClick={handleMovementSelection}
      style={style}
    >
      {status === "approved" && type === "credit" && (
        <img src={okCircleIcon} alt="approved" className="status" />
      )}
      {status === "reversed" && type === "credit" && (
        <img src={okCircleIcon} alt="approved" className="status" />
      )}
      {status === "approved" && type === "debit" && (
        <img src={excamCircleIcon} alt="approved" className="status" />
      )}
      {status === "pending" && (
        <img src={bellCircleIcon} alt="pending" className="status" />
      )}
      {status === "rejected" && (
        <img src={closeCircleIcon} alt="rejected" className="status" />
      )}

      <p className="title">Fecha</p>
      <p>{movementDate}</p>
      <p className="title">Monto</p>

      {type === "debit" && (
        <p>{"-" + formatPrice(totalAmount) + " " + currency}</p>
      )}
      {type === "credit" && (
        <p className="amount-credit">
          {formatPrice(totalAmount) + " " + currency}
        </p>
      )}
      {counterpart === "pagodirecto" && (
        <img src={isotipoPd} alt="paid" className="status" />
      )}
      {counterpart !== "pagodirecto" && (
        <img src={defaultUser} alt="paid" className="status" />
      )}
      <p className="title"> Usuario</p>
      {counterpart === "pagodirecto" && <p>{"pagoDirecto"}</p>}
      {counterpart !== "pagodirecto" && <p>{"@" + counterpart}</p>}
    </dd>
  );
};

const MovementListHeaderModel = ({ className }) => {
  return (
    <dd className={className}>
      <p className="col twenty-five">Fecha</p>
      <p className="col twenty-five">Monto</p>
      <p className="col twenty-five">Tipo</p>
      <p className="col twenty-five">Usuario</p>
    </dd>
  );
};

const NoMovementFoundModel = ({ className }) => {
  return <p className={className}>No se han encontrado transacciones</p>;
};

function formatMovementsList(
  movements,
  loading,
  handleMovementSelection,
  transactionTags
) {
  let formatString = "DD [de] MMMM YYYY";
  if (loading) {
    return (
      <div
        className="ui active loader"
        style={{
          marginTop: "3rem",
          position: "relative",
        }}
      ></div>
    );
  }

  if (!Array.isArray(movements) || !movements.length) {
    return <NoMovementFound />;
  }
  return [
    <MovementListHeader key="header" />,
    ...movements.map((movement) => {
      const transactionTagObject =
        transactionTags &&
        transactionTags.length &&
        transactionTags.find((tag) => tag.value === movement.transaction_tag)
          ? transactionTags.find(
              (tag) => tag.value === movement.transaction_tag
            )
          : { text: "" };
      const transactionLabel =
        transactionTagObject && transactionTagObject.text;

      return (
        <MovementListItem
          handleMovementSelection={handleMovementSelection}
          movementDate={moment(movement.created_at).format(formatString)}
          counterpart={
            (movement &&
              movement.counterpart &&
              movement.counterpart.username) ||
            (movement &&
              movement.commerce_counterpart &&
              movement.commerce_counterpart.name) ||
            "N/A"
          }
          totalAmount={movement.pd_amount}
          currency={movement.currency}
          type={movement.type}
          transactionTag={movement.transaction_tag}
          transactionLabel={transactionLabel}
          status={movement.status}
          id={movement._id}
          key={movement._id}
        />
      );
    }),
  ];
}

function formatMovementsPhoneList(movements, loading, handleMovementSelection) {
  let formatString = "DD [de] MMMM YYYY";
  if (loading) {
    return (
      <div
        className="ui active loader"
        style={{
          marginTop: "3rem",
          position: "relative",
        }}
      ></div>
    );
  }

  if (!Array.isArray(movements) || !movements.length)
    return <NoMovementFound />;

  return [
    ...movements.map((movement) => (
      <MovementPhoneListItem
        handleMovementSelection={handleMovementSelection}
        movementDate={moment(movement.created_at).format(formatString)}
        counterpart={
          (movement && movement.counterpart && movement.counterpart.username) ||
          (movement &&
            movement.commerce_counterpart &&
            movement.commerce_counterpart.name) ||
          "N/A"
        }
        totalAmount={movement.pd_amount}
        currency={movement.currency}
        type={movement.type}
        transactionTag={movement.transaction_tag}
        status={movement.status}
        id={movement._id}
        key={movement._id}
      />
    )),
  ];
}

export {
  MovementListItemModel,
  MovementPhoneListItemModel,
  MovementListHeaderModel,
  NoMovementFoundModel,
  formatMovementsList,
  formatMovementsPhoneList,
};
