import {
  SignupFormInputsModel,
  CommercesSignupFirstFormInputsModel,
  CommercesSignupSecondFormInputsModel,
  SigninFormInputsModel,
  OwnerInfoFormInputsModel,
  CommerceInfoFormInputsPart1Model,
  CommerceInfoFormInputsPart2Model,
  CommerceInfoFormInputsPart3Model,
  EmailInputModel,
  CodeInputModel,
  ResetPasswordInputsModel,
} from "./model";
import styled from "styled-components";

export const SignupFormInputs = styled(SignupFormInputsModel)`
  #root & {
    border-radius: 1rem;
    margin: ${(props) => props.margins};

    & > .segment {
      display: flex;
      align-items: center;
      width: 100%;
      height: 8.15vh;
      min-height: 48px;
      font-size: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
    & .label {
      background: transparent;
      color: #6586cf;
      padding: 0;
      margin-right: 1em;
      .menu {
        border-radius: 1rem;
      }
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none !important;
      margin: 0 !important;
      -moz-appearance: textfield !important;
    }

    & :first-child {
      border-radius: 1rem 1rem 0 0;
    }
    & :last-child {
      border-radius: 0 0 1rem 1rem;
    }
    & > :last-child.weak {
      border-bottom: 5px solid #ff2f1a;
    }
    & > :last-child.medium {
      border-bottom: 5px solid #ffb413;
    }
    & > :last-child.secure {
      border-bottom: 5px solid #79fa00;
    }
    /* For password confirmation on forgot-password */
    & > :last-child.confirmed::after {
      content: "\\2713";
      font-size: 1.3rem;
      color: green;
      font-weight: bold;
    }
  }
`;
export const CommercesSignupFirstFormInputs = styled(CommercesSignupFirstFormInputsModel)`
  #root & {
    border-radius: 1rem;
    margin: ${(props) => props.margins};

    & > .segment {
      display: flex;
      align-items: center;
      width: 100%;
      height: 8.15vh;
      min-height: 48px;
      font-size: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
    & .label {
      background: transparent;
      color: #6586cf;
      padding: 0;
      margin-right: 1em;
      .menu {
        border-radius: 1rem;
      }
    }
    & .separator {
      font-size: 2rem;
      color: #6586cf;
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none !important;
      margin: 0 !important;
      -moz-appearance: textfield !important;
    }

    & :first-child {
      border-radius: 1rem 1rem 0 0;
    }
    & :last-child {
      border-radius: 0 0 1rem 1rem;
    }
    & > :last-child.weak {
      border-bottom: 5px solid #ff2f1a;
    }
    & > :last-child.medium {
      border-bottom: 5px solid #ffb413;
    }
    & > :last-child.secure {
      border-bottom: 5px solid #79fa00;
    }
    /* For password confirmation on forgot-password */
    & > :last-child.confirmed::after {
      content: "\\2713";
      font-size: 1.3rem;
      color: green;
      font-weight: bold;
    }
  }
`;
export const CommercesSignupSecondFormInputs = styled(CommercesSignupSecondFormInputsModel)`
  #root & {
    border-radius: 1rem;
    margin: ${(props) => props.margins};

    & > .segment {
      display: flex;
      align-items: center;
      width: 100%;
      height: 8.15vh;
      min-height: 48px;
      font-size: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
    & .label {
      background: transparent;
      color: #6586cf;
      padding: 0;
      margin-right: 1em;
      .menu {
        border-radius: 1rem;
      }
    }
    & .new-label {
      align-items: center;
      width: 100% - 1rem;
      background: transparent;
      color: #6586cf;
      padding: 0;
      margin-right: 1em;
      .menu {
        width: 100%;
        border-radius: 1rem;
      }
    }
    & .single-dropdown {
      i {
        color: #6586cf;
      }
      .menu {
        width: 100%;
        border-radius: 1rem;
      }
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none !important;
      margin: 0 !important;
      -moz-appearance: textfield !important;
    }

    & :first-child {
      border-radius: 1rem 1rem 0 0;
    }
    & :last-child {
      border-radius: 0 0 1rem 1rem;
    }
    & > :last-child.weak {
      border-bottom: 5px solid #ff2f1a;
    }
    & > :last-child.medium {
      border-bottom: 5px solid #ffb413;
    }
    & > :last-child.secure {
      border-bottom: 5px solid #79fa00;
    }
    /* For password confirmation on forgot-password */
    & > :last-child.confirmed::after {
      content: "\\2713";
      font-size: 1.3rem;
      color: green;
      font-weight: bold;
    }
  }
`;

export const SigninFormInputs = styled(SigninFormInputsModel)`
  #root & {
    border-radius: 1rem;
    margin: ${(props) => props.margins};

    & > .segment {
      display: flex;
      align-items: center;
      width: 100%;
      height: 8.15vh;
      min-height: 48px;
      font-size: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }

    & :first-child {
      border-radius: 1rem 1rem 0 0;
    }
    & :last-child {
      border-radius: 0 0 1rem 1rem;
    }
  }
`;

export const OwnerInfoFormInputs = styled(OwnerInfoFormInputsModel)`
  #root & {
    border-radius: 1rem;
    margin: ${(props) => props.margins};

    & > .segment {
      display: flex;
      align-items: center;
      width: 100%;
      height: 8.15vh;
      min-height: 48px;
      font-size: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .date {
      width: 15%;
      text-align: center;
    }
    & .label {
      color: #6586cf;
      padding: 0;
      margin-right: 1em;
      width: 40%;
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none !important;
      margin: 0 !important;
      -moz-appearance: textfield !important;
    }

    & :first-child {
      border-radius: 1rem 1rem 0 0;
    }
    & :last-child {
      border-radius: 0 0 1rem 1rem;
    }
  }
`;

export const CommerceInfoFormInputsPart1 = styled(CommerceInfoFormInputsPart1Model)`
  #root & {
    border-radius: 1rem;
    margin: ${(props) => props.margins};

    & > .segment {
      display: flex;
      align-items: center;
      width: 100%;
      height: 8.15vh;
      min-height: 48px;
      font-size: 16px;
      padding-left: 1rem;
      padding-right: 1rem;
      > textarea {
        width: 100%;
        border: transparent;
        ::-webkit-input-placeholder {
          color: #ccc;
        }
        ::-moz-placeholder {
          color: #ccc;
        }
        :-ms-input-placeholder {
          color: #ccc;
        }
      }
    }
    & > .colorpicker.segment {
      display: block;
      padding-left: 0;
      padding-right: 0;
      height: auto;
      > .label {
        padding-left: 0.5rem;
        border-left: 8px solid ${(props) => props.colors && props.colors[0]};
        border-right: 8px solid ${(props) => props.colors && props.colors[0]};
        margin: 0 1rem 1rem 1rem;
        color: rgba(169, 169, 169, 0.87);
        border-radius: 0;
      }
      > p:nth-child(3).label {
        margin: 1rem;
        border-left: 8px solid ${(props) => props.colors && props.colors[1]};
        border-right: 8px solid ${(props) => props.colors && props.colors[1]};
      }
      > div:nth-child(2) {
        border-bottom: 1px solid rgba(34, 36, 38, 0.15);
        padding-bottom: 0.8rem;
      }
      > .color[style] {
        box-sizing: border-box !important;
        padding-left: 1rem;
        padding-right: 1rem;
        border-radius: 0.6rem !important;
        box-shadow: none !important;
        border-radius: 1rem 1rem 0 0 !important;
        width: 100% !important;
        > div:first-child[style] {
          border-radius: 0.6rem !important;
        }
        .hue-horizontal {
          border-radius: 1rem !important;
        }
      }
    }
    & .label {
      background: transparent;
      color: #6586cf;
      padding: 0;
      margin-right: 1em;
      .menu {
        border-radius: 1rem;
      }
    }
    & > .segment.textareaInput {
      height: auto;
    }

    & .single-dropdown {
      i {
        color: #6586cf;
      }
      .menu {
        width: 100%;
        border-radius: 1rem;
      }
    }

    & .city {
      margin-left: 2rem;
      i {
        color: #6586cf;
      }
      .menu {
        width: 100%;
        border-radius: 1rem;
      }
    }

    & .state {
      i {
        color: #6586cf;
      }
      .menu {
        width: 100%;
        border-radius: 1rem;
      }
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none !important;
      margin: 0 !important;
      -moz-appearance: textfield !important;
    }

    & > div:first-child {
      border-radius: 1rem 1rem 0 0;
    }
    & > div:last-child {
      border-radius: 0 0 1rem 1rem;
    }
  }
`;
export const CommerceInfoFormInputsPart2 = CommerceInfoFormInputsPart1.withComponent(
  CommerceInfoFormInputsPart2Model
);

export const CommerceInfoFormInputsPart3 = CommerceInfoFormInputsPart1.withComponent(
  CommerceInfoFormInputsPart3Model
);

export const EmailInput = styled(EmailInputModel)`
  #root & {
    border-radius: 1rem;

    & > .segment {
      display: flex;
      align-items: center;
      width: 100%;
      height: 8.15vh;
      min-height: 48px;
      font-size: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }

    & > div {
      border-radius: 1rem;
    }
  }
`;

export const CodeInput = EmailInput.withComponent(CodeInputModel);

export const ResetPasswordInputs = SignupFormInputs.withComponent(ResetPasswordInputsModel);
