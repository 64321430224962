import React from 'react'
import styled from 'styled-components'
import logotipoPD from 'assets/img/logotipo-pd-blanco.png'
import isotipoPD from 'assets/img/isotipo-pd-blanco.png'

const NotFoundComponent = ({className}) => {
  return(
    <div className = { className }>
      <div className = 'banner'>
        <h1> Pago Directo
          <img className = 'isotipo' src = { isotipoPD } alt = 'PagoDirecto' />
          <img className='logotipo' src={logotipoPD} alt='PagoDirecto'/>
        </h1>
      </div>
      <h4>Error 404</h4>
      <p>La página a la que intenta acceder no existe o ha sido movida a otra ubicación</p>
      
    </div>
  )
}

const NotFound = styled(NotFoundComponent)`
  #root &{

    .banner{
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#c61999+0,06a3e5+100 */
      background: #c61999; /* Old browsers */
      background: -moz-linear-gradient(20deg, #c61999 0%, #06a3e5 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(20deg, #c61999 0%,#06a3e5 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(20deg, #c61999 0%,#06a3e5 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c61999', endColorstr='#06a3e5',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

      h1{
        display: inline-block;
        margin-top: 20vh;
        font-size: 0;
      }
      .logotipo{
        height: 10vw;
        margin-bottom: 5vh;
      }
      .isotipo{
        height: 20vw;
        margin-bottom: 3vh;
      }
    }
    h4{
      font-size: 3rem;
      margin-left: 3rem;
      margin-top: 3rem;
    }
    p{
      font-size: 1.25rem;
      margin-left: 3rem;
      margin-top: 1rem;
    }
  }
`;

export default NotFound