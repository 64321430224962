import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { Button } from "components/buttons";
import { formatPrice } from "services/utils";
import { CSSTransition } from "react-transition-group";
import { general as texts } from "assets/strings/texts";
import imagenTicket from "assets/img/imagen-ticket.png";
import soporteTicket from "assets/img/soporte-ticket.png";
import { tickets_validate as viewTexts } from "assets/strings/texts";

const Ticket = ({
  viewState,
  showTicket,
  ticketData,
  canValidate,
  totalAmount,
  getTicketAmount,
  validate,
  removeTicket,
  phoneView,
  className,
}) => {
  let FormatedTotalAmount = totalAmount ? formatPrice(totalAmount) : "Esperando monto...";
  if (
    viewState === "ticket_search" ||
    viewState === "ticket_search_loading" ||
    viewState === "ticket_search_done"
  ) {
    return (
      <div className={className + " noTicket"}>
        <p>{viewTexts.noSelection}</p>
      </div>
    );
  }
  if (ticketData) {
    return (
      <div className={className}>
        <CSSTransition
          in={showTicket}
          timeout={350}
          appear={true}
          classNames="ticket"
        >
          <div className={"ticket " + ticketData.type}>
            <img
              className="iconoTicket"
              src={imagenTicket}
              alt="icono-ticket"
            />
            <h4>Ticket Digital</h4>
            <h5>{ticketData.building.parking_name}</h5>
            <p className="address">{ticketData.building.address}</p>
            {phoneView && (
              <p className="amount">
                {FormatedTotalAmount} {ticketData.building.rate.currency}
              </p>
            )}
            <p className="ticketId">{ticketData.ticket_number}</p>
            <p className="ticketUsername">@{ticketData.username}</p>
            <p className="ticketTime">
              {moment(ticketData.enter_at).format(texts.shortDateHourFormat)}
            </p>
            <img
              className="soporteTicket"
              src={soporteTicket}
              alt="soporte-ticket"
            />
          </div>
        </CSSTransition>

        {(viewState === "ticket_selection" ||
          viewState === "ticket_selection_loading") && (
          <Button
            color="blue"
            disabled={viewState === "ticket_selection_loading"}
            onClick={() => getTicketAmount(ticketData)}
          >
            {viewState === "ticket_selection" && viewTexts.retry}
            {viewState === "ticket_selection_loading" && viewTexts.calculating}
          </Button>
        )}
        {(viewState === "ticket_selection_done" ||
          viewState === "ticket_validation" ||
          viewState === "ticket_validation_loading") && (
          <Button color="blue" onClick={validate} disabled={!canValidate}>
            {viewTexts.validate}
          </Button>
        )}
        {phoneView && (
          <Button color="grey" onClick={removeTicket}>
            {texts.back}
          </Button>
        )}
      </div>
    );
  }
};

Ticket.propTypes = {
  ticketData: PropTypes.shape({
    ticket_number: PropTypes.string,
    enter_at: PropTypes.string,
    building: PropTypes.shape({
      name: PropTypes.string,
      address: PropTypes.string,
    }),
  }),
};

export default Ticket;
