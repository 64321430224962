import React from "react";
import { connect } from "react-redux";
import { clearReduxStore } from "redux/actions/general";
import { getSelectedCommerce as getSelectedCommerceFromRedux } from "redux/selectors";
import { useStateManager } from "hooks/use-state-manager";
import { usePopupMessages } from "hooks/use-popup-messages";
import { useViewType } from "hooks/use-view-type";
import { useNumberInput, useLowercaseInput } from "hooks/use-inputs";
import { ActivateConfirmation } from "../components/activation-confirmation";
import { Confirmation } from "../components/confirmation";
import ActivateMPContext from "./context";
import {
  usersAutocomplete,
  premiumBundle,
  premiumActivate,
  premiumDisable,
} from "api";

const ActivateMPState = ({ selectedCommerce, children }) => {
  const initialState = {
    viewState: "users_result",
    loadingUsers: false,
    submitUserSearch: false,
    getPremiumBundles: false,
    loading: false,
    resetInputs: false,
    selectedUser: null,
    users: [],
    premiumBundles: [],
    selectedBundle: null,
    bundleToDisable: null,
    activateEvent: false,
    activateLoading: false,
    disableEvent: false,
    disableLoading: false,
    generate_transaction: true,
    why_no_transaction: null,
  };
  const [state, changeState] = useStateManager(initialState);
  const [viewType, setViewType] = useViewType(null);
  const {
    popupMessages,
    popupMessagesType,
    showPopup,
    throwPopupMessage,
    closePopup,
  } = usePopupMessages(null, "", false);

  const {
    //reset: resetUser,
    bind: { value: searchParam, onChange: handleSearchInputChange },
  } = useLowercaseInput("");
  const {
    reset: resetDaysQty,
    bind: { value: daysQty, onChange: onChangeDaysQty },
  } = useNumberInput("30");

  const searchUsersCallback = async () => {
    try {
      changeState({ loadingUsers: true });
      const response = await usersAutocomplete(searchParam);
      const res = await response.json();
      if (res.error) throw res.error;
      changeState({ users: res.users, loadingUsers: false });
    } catch (error) {
      console.log(error);
      changeState(initialState);
      throwPopupMessage(
        "error",
        `${
          error && error.description
            ? error.description
            : "Ups, ha ocurrido un error, intente más tarde"
        }`
      );
    }
  };

  const premiumBundlesCallback = async () => {
    try {
      changeState({ loading: true });
      const response = await premiumBundle(state.selectedUser._id);
      const res = await response.json();
      if (res.error) throw res.error;
      changeState({ premiumBundles: res.premium_bundles, loading: false });
    } catch (error) {
      changeState(initialState);
      throwPopupMessage(
        "error",
        `${
          error && error.description
            ? error.description
            : "Ups, ha ocurrido un error, intente más tarde"
        }`
      );
    }
  };

  const premiumBundlesActivationCallback = async () => {
    try {
      changeState({ activateLoading: true });
      const extraData =
        selectedCommerce.category === "pagodirecto" &&
        state.selectedBundle.premium_type === "regular"
          ? {
              generate_transaction: state.generate_transaction,
              why_no_transaction: state.why_no_transaction,
            }
          : {};
      const response = await premiumActivate({
        user_id: state.selectedUser._id,
        buildings: state.selectedBundle.buildings,
        qty_days:
          state.selectedBundle.premium_type !== "regular"
            ? parseInt(daysQty)
            : null,
        premium_type: state.selectedBundle.premium_type,
        conditions: state.selectedBundle.conditions,
        bundle_title: state.selectedBundle.title,
        ...extraData,
      });

      const res = await response.json();
      if (res.error) throw res.error;
      const { users, selectedUser } = state;
      changeState({
        ...initialState,
        users,
        selectedUser,
        getPremiumBundles: true,
      });
      resetDaysQty();
      throwPopupMessage("success", res.message);
    } catch (error) {
      changeState(initialState);
      throwPopupMessage(
        "error",
        `${
          error && error.description
            ? error.description
            : "Ups, ha ocurrido un error, intente más tarde"
        }`
      );
    }
  };
  const premiumBundlesDisableCallback = async () => {
    try {
      changeState({ disableLoading: true });
      const response = await premiumDisable({
        user_id: state.selectedUser._id,
        type: state.bundleToDisable.premium_type,
        building_code: state.bundleToDisable.buildings[0].code,
      });
      const res = await response.json();
      if (res.error) throw res.error;
      const { users, selectedUser } = state;
      changeState({
        ...initialState,
        users,
        selectedUser,
        getPremiumBundles: true,
      });
      resetDaysQty();
      throwPopupMessage("success", res.message);
    } catch (error) {
      throwPopupMessage(
        "error",
        `${
          error && error.description
            ? error.description
            : "Ups, ha ocurrido un error, intente más tarde"
        }`
      );
    }
  };

  const handleSearchUser = (event) => {
    event.preventDefault();
    changeState({ submitUserSearch: true });
  };

  const handleUserSelection = (event) => {
    let index = event.currentTarget.id;
    let selectedUser = state.users[index];
    changeState({
      viewState: "bundles_results",
      selectedUser,
      premiumBundles: [],
      selectedBundle: null,
      getPremiumBundles: true,
    });
  };

  const handleBundleSelection = (event) => {
    let index = event.currentTarget.id;
    let selectedBundle = state.premiumBundles[index];
    changeState({
      viewState: "bundle_selected",
      selectedBundle,
    });
  };

  const handleActivateFromSuperadmin = (event) => {
    event.preventDefault();
    closePopup();
    changeState({ activateEvent: true });
  };
  const handleActivate = (event) => {
    event.preventDefault();
    const type = state.selectedBundle.premium_type;
    if (
      (selectedCommerce && selectedCommerce.category !== "pagodirecto") ||
      type !== "regular"
    ) {
      changeState({ activateEvent: true });
    } else {
      throwPopupMessage("alert", <ActivateConfirmation />);
    }
  };

  const handleDisableBundle = (event) => {
    event.preventDefault();
    let index = event.currentTarget.id;
    let bundleToDisable = state.premiumBundles[index];
    changeState({
      viewState: "bundle_selected",
      bundleToDisable,
    });
    throwPopupMessage(
      "alert",
      <Confirmation>¿Estás seguro de que deseas continuar?</Confirmation>
    );
  };

  const handleDisable = (event) => {
    event.preventDefault();
    changeState({
      disableEvent: true,
    });
  };

  const handleBack = (event) => {
    event.preventDefault();
    const states = ["users_result", "bundles_results", "bundle_selected"];
    states.forEach((stateName, index) => {
      if (state.viewState === stateName) {
        changeState({ viewState: states[index - 1] });
      }
    });
  };

  return (
    <ActivateMPContext.Provider
      value={{
        state,
        changeState,
        viewType,
        setViewType,
        searchParam,
        daysQty,
        onChangeDaysQty,
        searchUsersCallback,
        premiumBundlesCallback,
        premiumBundlesActivationCallback,
        premiumBundlesDisableCallback,
        handleSearchInputChange,
        handleSearchUser,
        handleActivate,
        handleDisableBundle,
        handleDisable,
        handleUserSelection,
        handleBundleSelection,
        handleActivateFromSuperadmin,
        popupMessages,
        popupMessagesType,
        showPopup,
        throwPopupMessage,
        closePopup,
        handleBack,
        selectedCommerce,
      }}
    >
      {children}
    </ActivateMPContext.Provider>
  );
};

const mapStateToProps = (state) => {
  let selectedCommerce = getSelectedCommerceFromRedux(state);
  return { selectedCommerce };
};

const mapDispatchToProps = { clearReduxStore };

export default connect(mapStateToProps, mapDispatchToProps)(ActivateMPState);
