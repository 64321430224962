import { DownloadExcelModel } from "./model";
import styled from "styled-components";
import { grey_4, bluePD } from "assets/strings/colors";

export const DownloadExcel = styled(DownloadExcelModel)`
  #root & {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.6rem;
    width: 100%;
    border-bottom: 1px solid ${grey_4};
    > .link {
      width: 30%;
      > p {
        padding: 0;
        marigin: 0;
        text-decoration: underline;
        text-transform: capitalize;
        color: ${bluePD};
        cursor: pointer;
      }
    }
  }
`;
