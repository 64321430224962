import { baseURL_V2, micropd_02 } from "services/config";
import { post } from "services/http-methods";

const endpoints = {
  getAccounts: `${baseURL_V2}/superadmin/commerces/bank-account`,
  bankAmounts: `${micropd_02}/superadmin/bank-transaction/account-status`,
};

export function getAccounts(commerce_id) {
  return post(endpoints.getAccounts, { commerce_id });
}

export const getBankAmounts = (body) => {
  return post(endpoints.bankAmounts, body);
};
