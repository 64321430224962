import BankAccountDetailModel from "./model";
import styled from "styled-components";
import { textualBlue, bluePD } from "assets/strings/colors";

const BankAccountDetail = styled(BankAccountDetailModel)`
  #root && {
    justify-content: center;
    text-align: justify;
    align-items: center;
    position: relative;
    > .register {
      > .title {
        color: ${textualBlue};
        margin: 0;
        margin-top: 2rem;
        margin-bottom: 4.5rem;
        font-size: 1.5rem;
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
      }
      > .options {
        padding-left: 60%;
        top: 15%;
        right: 13%;
        border: none;
        background: none;
        box-shadow: none;
        position: absolute;
        z-index: 1;
      }
      > .register-form {
        border-radius: 1rem;
        left: 11%;
        width: 80%;
        align-items: center;
        & > .segment {
          display: flex;
          width: 100%;
          height: 7.15vh;
          min-height: 60px;
          font-size: 1.2rem;
        }
        & .label {
          background: transparent;
          color: ${bluePD};
          padding: 0;
          padding-top: 0.5rem;
          border: none;
          font-size: 1.2rem;
          min-height: 48px;
          margin-right: 1em;
          .menu {
            border-radius: 1rem;
          }
        }
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none !important;
          margin: 0 !important;
          -moz-appearance: textfield !important;
        }

        & :first-child {
          border-radius: 1rem 1rem 0 0;
        }
        & :last-child {
          border-radius: 0 0 1rem 1rem;
        }
        & > :last-child.weak {
          border-bottom: 5px solid #ff2f1a;
        }
        & > :last-child.medium {
          border-bottom: 5px solid #ffb413;
        }
        & > :last-child.secure {
          border-bottom: 5px solid #79fa00;
        }
        /* For password confirmation on forgot-password */
        & > :last-child.confirmed::after {
          content: "\\2713";
          font-size: 1.3rem;
          color: green;
          font-weight: bold;
        }
      }
      > .selectors {
        position: absolute;
        width: 50%;
        left: 25%;
        margin-bottom: 2rem;
        > .wallet {
          margin-top: 1rem;
          text-align: center;
          font-size: 1.2rem;
          > .card {
            width: 100%;
            background: #1455a2;
            border-radius: 1rem;
            filter: drop-shadow(3px 4px 5px #1455a2);
          }
          > .logo {
            position: absolute;
            width: 50%;
            left: 5%;
            top: 11%;
          }
          > .currency {
            position: absolute;
            bottom: 38%;
            left: 7%;
            color: white;
            font-size: 1.2rem;
          }
          > .legend {
            position: absolute;
            bottom: 31%;
            left: 7%;
            color: white;
            font-size: 1.2rem;
          }
          > .amount {
            position: absolute;
            bottom: 28%;
            left: 7%;
            color: white;
            font-size: 1.6rem;
            font-weight: bold;
          }
        }
      }
    }

    .register.register-appear {
      opacity: 0;
      transform: translateX(-30px);
    }
    .register.register-appear-active {
      opacity: 0.8;
      transform: translateX(2px);
      transition: all 350ms ease-out;
    }
    .register.register-enter {
      opacity: 0;
      transform: translateX(-30px);
    }
    .register.register-enter-active {
      opacity: 0.8;
      transform: translateX(2px);
      transition: all 300ms ease-out;
    }
    .register.register-enter-done {
      opacity: 1;
      transform: translateX(0);
      transition: all 50ms ease-out;
    }
    .register.register-exit {
      opacity: 0.8;
      transform: translateX(-2px);
    }
    .register.register-exit-active {
      opacity: 0;
      transform: translateX(30px);
      transition: all 350ms ease-out;
    }
    @media (max-width: 950px) {
      > .register {
        > .selectors {
          > .wallet {
            > .currency {
              bottom: 44%;
              font-size: 1rem;
            }
            > .legend {
              bottom: 38%;
              font-size: 1rem;
            }
            > .amount {
              bottom: 35%;
              font-size: 1.2rem;
            }
          }
        }
      }
    }
    @media (max-width: 767px) {
      > .register {
        > .selectors {
          > .wallet {
            width: 150%;
            > .card {
              width: 100%;
              margin-left: -30%;
              background: #1455a2;
              border-radius: 1rem;
              filter: drop-shadow(3px 4px 5px #1455a2);
            }
            > .logo {
              position: absolute;
              width: 60%;
              left: -10%;
              top: 9%;
            }
            > .currency {
              left: -10%;
              font-size: 1.2rem;
              top: 40%;
            }
            > .legend {
              left: -10%;
              font-size: 1.2rem;
              top: 47.5%;
            }
            > .amount {
              left: -10%;
              font-size: 1.4rem;
              bottom: 40%;
            }
          }
        }
      }
    }
    @media (max-width: 500px) {
      > .register {
        > .selectors {
          > .wallet {
            width: 150%;
            > .card {
              width: 100%;
              margin-left: -30%;
              background: #1455a2;
              border-radius: 1rem;
              filter: drop-shadow(3px 4px 5px #1455a2);
            }
            > .logo {
              position: absolute;
              width: 60%;
              left: -10%;
              top: 9%;
            }
            > .currency {
              left: -10%;
              font-size: 1.2rem;
              top: 35%;
            }
            > .legend {
              left: -10%;
              font-size: 1.2rem;
              top: 39.5%;
            }
            > .amount {
              left: -10%;
              font-size: 1.4rem;
              bottom: 48%;
            }
          }
        }
      }
    }
    @media (max-width: 350px) {
      > .register {
        > .selectors {
          > .wallet {
            width: 150%;
            > .card {
              width: 100%;
              margin-left: -30%;
              background: #1455a2;
              border-radius: 1rem;
              filter: drop-shadow(3px 4px 5px #1455a2);
            }
            > .logo {
              position: absolute;
              width: 60%;
              left: -10%;
              top: 9%;
            }
            > .currency {
              left: -10%;
              font-size: 0.8rem;
              top: 30%;
            }
            > .legend {
              left: -10%;
              font-size: 0.8rem;
              top: 35.5%;
            }
            > .amount {
              left: -10%;
              font-size: 1rem;
              top: 40%;
            }
          }
        }
      }
    }
  }
`;

export { BankAccountDetail };
