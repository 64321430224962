import { useEffect, useState, useContext } from "react";
import { customerExtensionsAPI } from "api";
import { UsersExtensionsContext } from "../context";
import { errors } from "assets/strings/texts";

export const useListExtensionService = () => {
  const [loading, setLoading] = useState(false);
  const { changeState, throwPopupMessage, state } = useContext(
    UsersExtensionsContext
  );
  useEffect(() => {
    const searchExtensions = async () => {
      setLoading(true);
      try {
        changeState({ waitExtensions: true });
        const user_id = state.selectedUser._id;
        const extensionsResponse = await customerExtensionsAPI.listExtensions({
          user_id,
        });
        const extensionsRes = await extensionsResponse.json();
        if (extensionsRes.error) throw extensionsRes.error;
        const extensions = [...extensionsRes.children];
        const movementsResponse = await customerExtensionsAPI.usersTransactions(
          {
            commerce_id: state.commerce_id,
            from_date: null,
            end_date: null,
            skip: 0,
            limit: 20,
            user_id,
            user_extension: true,
          }
        );
        const movementsRes = await movementsResponse.json();
        if (movementsRes.error) throw movementsRes.error;
        const movements = [...movementsRes.transactions];
        changeState({ extensions, movements });
      } catch (error) {
        if (
          error.id === "NO_TOKEN_PROVIDED" ||
          error.id === "AUTHENTICATE_FAILED" ||
          error.id === "DUPLICATE_SESSION"
        ) {
          throwPopupMessage("alert", errors.AUTHENTICATION);
        } else {
          throwPopupMessage("error", error.description);
        }
      }
      setLoading(false);
    };
    if (state.searchExtensions || state.selectedUser) {
      changeState({ searchExtensions: false });
      searchExtensions();
    }
  }, [state.searchExtensions, state.selectedUser]);

  return { loading };
};
