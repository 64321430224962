import React, { PureComponent } from "react";
import moment from "moment";
import "moment/locale/es-us";
import { Link } from "react-router-dom";
import { formatPrice } from "services/utils";
import { general as texts } from "assets/strings/texts";
import okCircleIcon from "assets/img/ok-circle-icon-64px.png";
import bellCircleIcon from "assets/img/bell-circle-icon-64px.png";
import excamCircleIcon from "assets/img/exclam-circle-icon-64px.png";
import { commerces_admin as viewTexts } from "assets/strings/texts";
import { InvoicePhoneListItem, NoInvoiceFound } from "./index";

moment.locale("es-us");

class InvoicePaymentListItemModel extends PureComponent {
  render() {
    let {
      invoiceId,
      handleInvoiceSelection,
      billingPeriodStart,
      billingPeriodEnd,
      totalAmount,
      currency,
      status,
      checked,
      commerce,
      className,
    } = this.props;

    let checkedClass = "";
    if (checked === true) {
      checkedClass = "checked";
    }
    if (status === "paid") {
      handleInvoiceSelection = null;
      checkedClass = "inactive";
    }

    return (
      <dd className={className}>
        <div
          id={invoiceId}
          className="checkbox-container"
          onClick={handleInvoiceSelection}
        >
          <div className={"checkbox " + checkedClass}></div>
        </div>
        <Link
          target="_blank"
          to={`/mpos/invoices/history?invoice=${invoiceId}&commerce=${commerce}`}
        >
          <div className="content">
            {status === "paid" && (
              <img src={okCircleIcon} alt="paid" className="status" />
            )}
            {status === "pending" && (
              <img src={bellCircleIcon} alt="pending" className="status" />
            )}
            {status === "overdue" && (
              <img src={excamCircleIcon} alt="overdue" className="status" />
            )}

            <p className="title">{viewTexts.invoiceTotalHeader}</p>
            <p>{formatPrice(totalAmount) + " " + currency}</p>
            <p className="title">{viewTexts.invoicePeriodHeader}</p>
            <p>
              {billingPeriodStart} - {billingPeriodEnd}
            </p>
          </div>
        </Link>
      </dd>
    );
  }
}

const NoInvoiceFoundModel = ({ className }) => {
  return <p className={className}>No se han encontrado recibos</p>;
};

function formatInvoicesPhoneList(
  invoices,
  viewState,
  handleInvoiceSelection,
  selectedInvoice
) {
  // Declaramos el array que devolverá la función
  let invoicesList = [];
  if (viewState === "commerce_invoice_loading") {
    return (
      <div
        className="ui active loader"
        style={{
          position: "relative",
        }}
      ></div>
    );
  } else if (!invoices.length) {
    return <NoInvoiceFound />;
  } else {
    invoices.forEach((invoice, index) => {
      let billingPeriodStart = moment(invoice.billing_period.start).format(
        texts.shortDateFormat
      );
      let billingPeriodEnd = moment(invoice.billing_period.end).format(
        texts.shortDateFormat
      );

      invoicesList.push(
        <InvoicePhoneListItem
          handleInvoiceSelection={handleInvoiceSelection}
          billingPeriodStart={billingPeriodStart}
          billingPeriodEnd={billingPeriodEnd}
          totalAmount={invoice.total_amount}
          currency={invoice.building.rate.currency}
          status={invoice.status}
          checked={selectedInvoice === invoice._id}
          invoiceId={invoice._id}
          commerce={invoice.commerce._id}
          key={invoice._id}
        />
      );
    });
    return invoicesList;
  }
}

export {
  InvoicePaymentListItemModel,
  formatInvoicesPhoneList,
  NoInvoiceFoundModel,
};
