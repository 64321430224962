import React from "react";
import { Link } from "react-router-dom";
import { PanelTitle } from "components/panel";
import okCircleIcon from "assets/img/ok-circle-icon.png";
import { Button } from "components/buttons";

const SuccessDigitizeModel = ({
  role,
  viewState,
  username,
  category,
  viewType,
  commerce,
  ticketPaid,
  premiumObj,
  generatedTicket,
  digitizedBy,
  resetView,
  className,
}) => {
  let showPremiumData = false;
  if (
    premiumObj !== null &&
    premiumObj.active &&
    premiumObj.remainingPremiumDays >= 0 &&
    premiumObj.remainingTicketsPerDay >= 0 &&
    ticketPaid
  ) {
    showPremiumData = true;
  }
  let isPrepaidCommerce =
    commerce &&
    commerce.parking &&
    commerce.parking.billing_type &&
    commerce.parking.billing_type === "prepaid";
  let canValidate = isPrepaidCommerce
    ? commerce.parking.prepaid_tickets > 0
      ? true
      : false
    : true;
  return (
    <div className={className}>
      <PanelTitle>Ticket Digitalizado Exitosamente</PanelTitle>
      <div className="ok-circle-icon">
        <img src={okCircleIcon} alt="" />
      </div>
      {showPremiumData && <PanelTitle>Habilitado Automáticamente</PanelTitle>}
      <h5 className="sub-title">
        {!ticketPaid ? "# Ticket digital" : "# Ticket premium digital"}
      </h5>
      <p className="text">
        {generatedTicket && generatedTicket.ticket_number
          ? generatedTicket.ticket_number
          : "No disponible"}
      </p>
      {showPremiumData && (
        <h5 className="sub-title">Días restantes del servicio</h5>
      )}
      {showPremiumData && (
        <p className="text">{premiumObj.remainingPremiumDays}</p>
      )}
      {showPremiumData && (
        <h5 className="sub-title">Tickes premium restantes</h5>
      )}
      {showPremiumData && (
        <p className="text">{premiumObj.remainingTicketsPerDay}</p>
      )}
      {viewState !== "user_selection" && (
        <h5 className="sub-title">Digitalizado por</h5>
      )}

      {viewState !== "user_selection" && <p className="text">{digitizedBy}</p>}

      {category !== "estacionamiento" && !ticketPaid && (
        <Link
          to={{
            pathname: "/mpos/tickets/validate",
            state: {
              digitizedTicket:
                viewState !== "user_selection" ? generatedTicket : null,
              username: viewState !== "user_selection" ? null : username,
            },
          }}
        >
          <Button
            color="blue"
            disabled={
              category === "estacionamiento" || ticketPaid || !canValidate
            }
          >
            Habilitar este ticket
          </Button>
        </Link>
      )}
      {viewType === "phone" && (
        <Button color="grey" margin="1rem 0 0 0 " onClick={resetView}>
          Digitalizar otro ticket
        </Button>
      )}
    </div>
  );
};

export default SuccessDigitizeModel;
