import { markWithdrawAsPaid } from "services/api";
import { errors } from "assets/strings/texts";

const approveWithdraw = async (context) => {
  const {
    state,
    setApproveForm,
    changeState,
    throwPopupMessage,
    initialState,
  } = context;
  setApproveForm({ loading: true });
  try {
    const body = {
      bill_id: state.approveForm.billId,
      withdraw_id: state.approveForm.withdrawId,
      pay_ref: state.approveForm.reference,
      pay_date: state.approveForm.payDate,
    };

    const response = await markWithdrawAsPaid(body);
    const res = await response.json();
    if (res.error) throw res.error;
    throwPopupMessage("success", "Retiro Aprobado");
    changeState(initialState);
  } catch (error) {
    if (
      error.id === "NO_TOKEN_PROVIDED" ||
      error.id === "AUTHENTICATE_FAILED" ||
      error.id === "DUPLICATE_SESSION"
    ) {
      throwPopupMessage("alert", errors.AUTHENTICATION);
    }
    setApproveForm({ loading: false });

    setTimeout(
      () => throwPopupMessage("error", error.description || errors.GENERAL_ERR),
      100
    );
  }
};

export default approveWithdraw;
