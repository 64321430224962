import { OptionConfirmationModel } from "./model";
import styled from "styled-components";

const OptionConfirmation = styled(OptionConfirmationModel)`
  #root && {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export { OptionConfirmation };
