import React, { useContext } from "react";
import MobilePaidsForm from "../components/mobile-paids-form";
import MobilePaidsList from "../components/mobile-paids-list";
import { MobilePaidsContext } from "../context";

const MobilePaidFormAndListContainer = () => {
  const { viewType } = useContext(MobilePaidsContext);

  const phone = viewType === "phone";
  return (
    <>
      <MobilePaidsForm phone={phone} />
      <MobilePaidsList />
    </>
  );
};

export default MobilePaidFormAndListContainer;
