import styled from "styled-components";
import { OptionConfirmationModel } from "./model";
import { grey_6 } from 'assets/strings/colors'

const OptionConfirmation = styled(OptionConfirmationModel)`
  #root & {
    margin-top: 1rem;
    text-align: center;
    font-size: 1rem;
    justify-content: center;
    > .info {
      > .ticket-number {
        > span {
          color: #63c0e8;
          font-size: 1rem;
          text-align: center;
        }
      }
      > .username {
        > span {
          font-size: 1rem;
          text-align: center;
        }
      }
    }
    > .dropdown {
      floating: left;
      z-index: 400;
      margin-top: 1rem !important;

    }
    .input-section{
      margin: .5rem 0 .5rem 0;
      #ticket-id{
        width: calc(100% - 10px);
      }
      #ticket-date-picker{
        width: calc(100% - 10px);
      }
      input{
        border: none;
        border-radius: 1rem;
        color: ${grey_6};
        font-size: 1.5rem;
        margin: .5rem 5px .5rem 5px;
        text-align: center;
        outline: none;
        box-shadow: none;
        transition: box-shadow 250ms linear;
        :focus{
          box-shadow: 0 1px darkgrey;
        }
        ::-webkit-input-placeholder{
          color: lightgray;
        }
        :-ms-input-placeholder{
          color: lightgray;
        }
        ::-ms-input-placeholder{
          color: lightgray;
        }
        ::placeholder{
          color: lightgray;
        }
      }
      >label{
        font-size: .85rem;
        display: inline-block;
        text-transform: uppercase;
        font-weight: bold;
        color: #5B5B5B;
        margin-bottom: .5rem;
      }
      >div{
        width: 100%;
        >.react-datepicker__input-container{
          width: 100%;
        }
        >.react-datepicker-wrapper{
          width: 100%;
          >.react-datepicker__input-container{
            width: 100%;
            >.react-datepicker__close-icon::after{
              right: 15px;
            }
          }
        }
      }
      >.time-picker{
        width: 80%;
        margin: 0 auto 0 auto;
        >#ticket-hour,
        >#ticket-minutes{
          font-size: 1.5rem;
          width: calc(45% - 10px);
          vertical-align: middle;
          text-align: center;
        }
        >#ticket-ampm{
          font-size: 1.5rem;
          width: calc(20% - 10px);
          vertical-align: middle;
          text-align: center;
        }
      }
    }
  }
`;

export { OptionConfirmation };
