export const getToken = (state) => {
  return state.session.token;
};

export const getRole = (state) => {
  return state.session.role;
};

export const getPermissions = (state) => {
  return state.session.permissions;
};

export const getCategory = (state) => {
  return state.session.category;
};

export const getSession = (state) => {
  return state.session;
};

export const getOfficeNumbers = (state) => {
  return state.session.officeNumbers;
};

export const getRoutesSaved = (state) => {
  return state.session.routes;
};
