import { useContext, useEffect } from "react";
import { SuperadminBillsHistoryContext } from "../context";
import { listBilling } from "api/superadmin-bills";
import { useFetch } from "hooks/use-fetch";

export const useFetchBills = () => {
  const { changeState, throwPopupMessage } = useContext(
    SuperadminBillsHistoryContext
  );
  const { loading, error, data, setActivateFetch, setArgs } =
    useFetch(listBilling);

  useEffect(() => {
    if (data) {
      const nextPageExist = data.bills.length >= 10;
      changeState({ bills: data.bills, nextPageExist });
    }
    if (error) {
      throwPopupMessage("error", error.description);
    }
  }, [data, error]);

  return { setArgs, setActivateFetch, loading };
};
