import React from "react";
import { Button } from "components/buttons";
import { PanelTitle } from "components/panel";
import { general as texts } from "assets/strings/texts";
import okCircleIcon from "assets/img/ok-circle-icon.png";
import { recharges_recharge as viewTexts } from "assets/strings/texts";

const SuccessRechargeModel = ({
  desiredRechargeAmount,
  selectedUser,
  resetView,
  className,
}) => {
  return (
    <div className={className}>
      <PanelTitle>{viewTexts.successRecharge}</PanelTitle>
      <div className="ok-circle-icon">
        <img src={okCircleIcon} alt="" />
      </div>
      <h5 className="sub-title">{viewTexts.amount}</h5>
      <p className="text">{desiredRechargeAmount} Bs.S.</p>
      <h5 className="sub-title">{texts.user}</h5>
      <p className="text">@{selectedUser.username}</p>
      <Button color="blue" margin="1rem 0 0 0 " onClick={resetView}>
        {texts.accept}
      </Button>
    </div>
  );
};

export default SuccessRechargeModel;
