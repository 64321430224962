import React, { useEffect, useContext } from "react";
import { ActivateMPContext, ActivateMPState } from "./context";
import { DefaultRender } from "components/default-render";
import MposMonthlyPremiumActivateView from "./components/mpos-monthly-premium-activate-view";
import MposMonthlyPremiumActivatePhoneView from "./components/mpos-monthly-premium-activate-phone-view";

const MonthlyPremiumActivate = () => {
  const {
    state,
    changeState,
    viewType,
    setViewType,
    searchUsersCallback,
    premiumBundlesCallback,
    premiumBundlesActivationCallback,
    premiumBundlesDisableCallback,
    handleSearchUser,
  } = useContext(ActivateMPContext);

  useEffect(() => {
    setViewType();
    window.addEventListener("resize", setViewType);
    document.addEventListener("keydown", onEnter);
    return () => {
      window.removeEventListener("resize", setViewType);
      document.removeEventListener("keydown", onEnter);
    };
  }, []);

  //componentDidUdpate
  const newApiRequest =
    state.submitUserSearch ||
    state.getPremiumBundles ||
    state.activateEvent ||
    state.disableEvent;
  useEffect(() => {
    if (state.submitUserSearch) {
      changeState({ submitUserSearch: false });
      searchUsersCallback();
    }

    if (state.getPremiumBundles) {
      changeState({ getPremiumBundles: false });
      premiumBundlesCallback();
    }

    if (state.activateEvent) {
      changeState({ activateEvent: false });
      premiumBundlesActivationCallback();
    }

    if (state.disableEvent) {
      changeState({ disableEvent: false });
      premiumBundlesDisableCallback();
    }
  }, [newApiRequest]);

  const onEnter = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      handleSearchUser(event);
    }
  };

  const toRender = {
    phone: <MposMonthlyPremiumActivatePhoneView />,
    desktop: <MposMonthlyPremiumActivateView />,
  };
  return toRender[viewType] || <DefaultRender />;
};

const MposMonthlyPremiumActivate = () => {
  return (
    <ActivateMPState>
      <MonthlyPremiumActivate />
    </ActivateMPState>
  );
};

export default MposMonthlyPremiumActivate;
