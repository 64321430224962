import React from "react";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";

const BarGraphModel = ({
  data,
  title,
  lineName,
  keyX,
  keyY,
  height,
  fill,
  margin,
  className,
}) => {
  return (
    <div className={className}>
      {title && <p className="title">{title}</p>}
        <ResponsiveContainer height={height ? height : 255} width="90%">
          <BarChart
            width={300}
            height={300}
            data={data}
            margin={{
              top: margin && margin.hasOwnProperty("top") ? margin.top : 5,
              right:
                margin && margin.hasOwnProperty("right") ? margin.right : 30,
              left: margin && margin.hasOwnProperty("left") ? margin.left : 20,
              bottom:
                margin && margin.hasOwnProperty("bottom") ? margin.bottom : 5,
            }}
          >
            <XAxis dataKey={keyX ? keyX : "day"} />
            <YAxis />
            <Tooltip />
            {/*    <Legend /> */}
            <Bar
              dataKey={keyY}
              name={lineName ? lineName : ""}
              fill={fill ? fill : "#8884d8"}
            />
          </BarChart>
        </ResponsiveContainer>
    </div>
  );
};

export default BarGraphModel;
