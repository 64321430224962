import React from "react";
import { CopyToClipboard as ReactCopyToClipboard } from "react-copy-to-clipboard";
import { Icon } from "semantic-ui-react";

export const CopyToClipboardModel = ({ textToCopy, textToShow, className }) => {
  return (
    <ReactCopyToClipboard className={className} text={textToCopy}>
      <p>
        <span>
          <Icon name="copy" className="copy-icon" />
        </span>
        {textToShow}
      </p>
    </ReactCopyToClipboard>
  );
};
