import { useState } from "react";

const usePopupMessages = (initialMessage, initialMessageType, initialShow) => {
  const [popup, setPopup] = useState({
    popupMessages: initialMessage,
    popupMessagesType: initialMessageType,
    showPopup: initialShow,
  });
  return {
    popupMessages: popup.popupMessages,
    popupMessagesType: popup.popupMessagesType,
    showPopup: popup.showPopup,
    setPopupValue: (prop, value) => {
      setPopup({
        ...popup,
        [prop]: value,
      });
    },
    throwPopupMessage: (messageType, ...messages) => {
      setPopup({
        popupMessagesType: messageType,
        popupMessages: [...messages],
        showPopup: true,
      });
    },
    closePopup: () => {
      setPopup({ popup, showPopup: false });
      setTimeout(() => {
        setPopup({
          ...popup,
          popupMessagesType: "",
          popupMessages: null,
        });
      }, 250);
    },
  };
};

export { usePopupMessages };
