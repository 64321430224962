import React from "react";
import { Button } from "components/buttons";
import { Dropdown } from "components/dropdown";
import { general as texts } from "assets/strings/texts";
import moment from "moment";
import DatePicker from "react-datepicker";

const OptionConfirmationModel = ({
  viewState,
  ticket,
  ticketOptionSelected,
  handleAccessOptions,
  accessOptions,
  showConfirmButton,
  confirm,
  cancel,
  className,

  ticketData,

  exitTicketDate,
  handleTicketExitDate,
  exitTicketHour,
  handleTicketExitHour,
  exitTicketMinute,
  handleTicketExitMinute,

  paidTicketDate,
  handlePaidTicketDate,
  paidTicketHour,
  handlePaidTicketHour,
  paidTicketMinute,
  handlePaidTicketMinute,
}) => {
  const isPremium =
    ticketData.type === "regurar" && ticketData.ticket_type === "premium";
  return (
    <div className={className}>
      {viewState === "option_loading" && (
        <div className="ui active loader" style={{ marginTop: "2rem" }}></div>
      )}
      {viewState === "option_confirmation" && (
        <div className="info">
          <p className="ticket-number" key="number_ticket">
            Número: <span>{ticket.ticket_number}</span>
          </p>
          <p className="username" key="username">
            Usuario: <span>@{ticket.username}</span>
          </p>
        </div>
      )}

      {/* cerrar */}
      {ticketOptionSelected === "Cobrar" && (
        <>
          <div className="input-section" style={{ marginTop: "3rem" }}>
            <label htmlFor="ticket-date-picker">Fecha de pago</label>
            <DatePicker
              id="ticket-date-picker"
              className="date-picker"
              autoComplete="off"
              placeholderText="2018-12-31"
              dateFormat="DD / MM / YYYY"
              todayButton={"Hoy"}
              isClearable
              // withPortal={viewType === "phone" ? true : false}
              minDate={moment(ticketData.enter_at)}
              maxDate={moment()}
              selected={paidTicketDate}
              onChange={handlePaidTicketDate}
              // disabled={
              //   viewState === "user_selection_loading" ||
              //   viewState === "ticket_digitize_confirmation" ||
              //   viewState === "ticket_digitize_loading" ||
              //   viewState === "ticket_digitize_done"
              // }
            />
          </div>

          <div className="input-section">
            <label htmlFor="ticket-hour">Hora de pago</label>
            <div className="time-picker">
              <input
                id="ticket-hour"
                type="tel"
                required={true}
                maxLength="3"
                pattern="^(0?[0-9]|1[0-9]|2[0-3])$"
                autoComplete="off"
                placeholder="14"
                value={paidTicketHour}
                onChange={handlePaidTicketHour}
                // disabled={
                //   viewState === "user_selection_loading" ||
                //   viewState === "ticket_digitize_confirmation" ||
                //   viewState === "ticket_digitize_loading" ||
                //   viewState === "ticket_digitize_done"
                // }
              />
              :
              <input
                id="ticket-minutes"
                type="tel"
                required={true}
                maxLength="3"
                pattern="^(0?[0-9]|[1-5][0-9])$"
                autoComplete="off"
                placeholder="40"
                value={paidTicketMinute}
                onChange={handlePaidTicketMinute}
                // disabled={
                //   viewState === "user_selection_loading" ||
                //   viewState === "ticket_digitize_confirmation" ||
                //   viewState === "ticket_digitize_loading" ||
                //   viewState === "ticket_digitize_done"
                // }
              />
            </div>
          </div>
        </>
      )}

      {ticketOptionSelected === "Cerrar" && (
        <>
          <div className="input-section" style={{ marginTop: "3rem" }}>
            <label htmlFor="ticket-date-picker">Fecha de salida</label>
            <DatePicker
              id="ticket-date-picker"
              className="date-picker"
              autoComplete="off"
              placeholderText="2018-12-31"
              dateFormat="DD / MM / YYYY"
              todayButton={"Hoy"}
              isClearable
              //withPortal={viewType === "phone"}
              minDate={
                moment(ticketData.enter_at).isBefore(ticket.paid_at)
                  ? moment(ticket.paid_at)
                  : moment(ticketData.enter_at)
              }
              maxDate={isPremium ? null : moment()}
              selected={exitTicketDate}
              onChange={handleTicketExitDate}
              disabled={
                viewState === "user_selection_loading" ||
                viewState === "ticket_digitize_confirmation" ||
                viewState === "ticket_digitize_loading" ||
                viewState === "ticket_digitize_done"
              }
            />
          </div>

          <div className="input-section">
            <label htmlFor="ticket-hour">Hora de salida</label>
            <div className="time-picker">
              <input
                id="ticket-hour"
                type="tel"
                required={true}
                maxLength="3"
                pattern="^(0?[0-9]|1[0-9]|2[0-3])$"
                autoComplete="off"
                placeholder="14"
                value={exitTicketHour}
                onChange={handleTicketExitHour}
                // disabled={
                //   viewState === "user_selection_loading" ||
                //   viewState === "ticket_digitize_confirmation" ||
                //   viewState === "ticket_digitize_loading" ||
                //   viewState === "ticket_digitize_done"
                // }
              />
              :
              <input
                id="ticket-minutes"
                type="tel"
                required={true}
                maxLength="3"
                pattern="^(0?[0-9]|[1-5][0-9])$"
                autoComplete="off"
                placeholder="40"
                value={exitTicketMinute}
                onChange={handleTicketExitMinute}
                // disabled={
                //   viewState === "user_selection_loading" ||
                //   viewState === "ticket_digitize_confirmation" ||
                //   viewState === "ticket_digitize_loading" ||
                //   viewState === "ticket_digitize_done"
                // }
              />
            </div>

            <label htmlFor="ticket-hour">Carril de salida</label>

            <Dropdown
              placeholder="Seleccione una salida"
              fluid
              selection
              wrapSelection={true}
              onChange={handleAccessOptions}
              options={accessOptions}
            />
          </div>
        </>
      )}
      <Button
        color="blue"
        disabled={viewState === "option_loading" || !showConfirmButton}
        onClick={confirm}
      >
        {viewState === "option_confirmation" && texts.accept}
        {viewState === "option_loading" && `${texts.wait}...`}
      </Button>
      <Button
        color="grey"
        onClick={cancel}
        disabled={viewState === "option_loading"}
      >
        Cancelar
      </Button>
    </div>
  );
};

export { OptionConfirmationModel };
