import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "components/buttons";
import { errors } from "assets/strings/texts";
import { addPrepaidTickets } from "services/api";
import { general as texts } from "assets/strings/texts";
import { popup_transition_time } from "assets/strings/constants";
import { clearReduxStore } from "redux/actions/general";
import { throwPopupMessage, closePopup } from "redux/actions/popup-messages";
import { commerces_admin as viewTexts } from "assets/strings/texts";
import { getViewMposCommerces } from "redux/selectors/view-mpos-commerces";

import {
  selectCommerce,
  updateCommercesList,
} from "redux/actions/view-mpos-commerces";

class PrepaidTicketsConfirmation_unconnected extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  addPrepaidTickets = () => {
    this.setState({ loading: true });
    const { numOfTickets } = this.props;
    const commerceId = this.props.selectedCommerce._id;

    addPrepaidTickets(commerceId, numOfTickets)
      .then((res) => {
        this.setState({ loading: false });
        if (!res.ok) {
          throw res;
        } // If not OK throw error
        return res.json(); // If OK return body
      })
      .then((res) => {
        // Manage success response
        if (res.commerce) {
          const updatedCommerces = [...this.props.commerces];

          this.props.commerces.forEach((commerce, i) => {
            if (commerce._id === res.commerce._id) {
              updatedCommerces[i] = res.commerce;
            }
          });

          this.props.updateCommercesList(updatedCommerces);
          this.props.selectCommerce(res.commerce);
          this.props.closePopup();
          setTimeout(() => {
            this.props.throwPopupMessage("success", res.message);
          }, popup_transition_time);
        }
      })
      .catch((err) => {
        // Manage error response
        // set apropiate states
        this.props.closePopup();

        if (typeof err.json === "function") {
          err.json().then((err_body) => {
            // If it is an authentication error, clear the redux-store to close the session
            if (
              err_body.error.id === "NO_TOKEN_PROVIDED" ||
              err_body.error.id === "AUTHENTICATE_FAILED" ||
              err_body.error.id === "DUPLICATE_SESSION"
            ) {
              this.setState({ authError: true });
              //this.props.clearReduxStore()
              this.props.throwPopupMessage("alert", errors.AUTHENTICATION);
              return; // prevent setState exeution on unmounted component
            }
            setTimeout(() => {
              this.props.throwPopupMessage("error", err_body.error.description);
            }, popup_transition_time);
          });
        } else {
          setTimeout(() => {
            this.props.throwPopupMessage("error", errors.GENERAL_ERR);
          }, popup_transition_time);
        }
      });
  };

  render() {
    const { numOfTickets, selectedCommerce } = this.props;

    const commerce = selectedCommerce.name;

    const { loading } = this.state;

    return (
      <React.Fragment>
        {viewTexts.prepaidTicketsConfirmationMessage({
          numOfTickets,
          commerce,
        })}
        <Button
          color="blue"
          onClick={this.addPrepaidTickets}
          disabled={loading}
        >
          {loading ? <div className="ui active loader inline" /> : texts.accept}
        </Button>
        <Button
          color="grey"
          margin="1rem 0 0 0"
          onClick={this.props.closePopup}
        >
          {texts.back}
        </Button>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  commerces: getViewMposCommerces(state).commerces,
  selectedCommerce: getViewMposCommerces(state).selectedCommerce,
});

const mapDispatchToProps = {
  closePopup,
  selectCommerce,
  clearReduxStore,
  throwPopupMessage,
  updateCommercesList,
};

const PrepaidTicketsConfirmation = connect(
  mapStateToProps,
  mapDispatchToProps
)(PrepaidTicketsConfirmation_unconnected);

export { PrepaidTicketsConfirmation };
