import React, { PureComponent } from "react";
import { errors } from "assets/strings/texts";
import moment from "moment";
import { connect } from "react-redux";
import { store } from "redux/store";
import { debounce } from "services/utils";
import { clearReduxStore } from "redux/actions/general";
import { throwPopupMessage, closePopup } from "components/popup-message";
import MposTicketsDigitizeView from "./components/tickets-digitize-view";
import { usersAutocomplete, usersTicketsAvailability } from "services/api";
import MposTicketsDigitizePhoneView from "./components/tickets-digitize-phone-view";
import {
  getSelectedCommerce as getSelectedCommerceFromRedux,
  getSelectedBuilding as getSelectedBuildingFromRedux,
} from "redux/selectors";
import {
  /* joinSocket, */
  addSocketListener,
  digitizeTicket,
  //disconnectSocket,
  //initiateSocket,
  //joinCommerceSocket,
  removeSocketListener,
  /*  disconnectSocket,
  initiateSocket,
  joinCommerceSocket*/
} from "services/websockets";

export function getRole() {
  return store.getState().session.role;
}
export function getWorkspace() {
  return store.getState().session.workspace;
}
class MPosTicketsDigitize extends PureComponent {
  constructor(props) {
    super(props);
    let role = getRole();
    let commerce = this.props.getSelectedCommerce;
    let building = this.props.getSelectedBuilding
      ? this.props.getSelectedBuilding
      : commerce.parking.building;
    let buildingName =
      building && building.commercial_name ? building.commercial_name : null;
    this.state = {
      commerce,
      building,
      token: this.props.token,
      viewState: "user_search",
      searchParam: "",
      users: [],
      role,
      ticketPaid: false,
      premiumObj: null,
      showResponsePanel: false,
      selectedUser: null,
      newTicketReference: "",
      newTicketDate: null,
      newTicketHour: "",
      newTicketMinute: "",
      generatedTicket: null,
      showGeneratedTicket: false,
      buildingName,
      // viewType: 'phone' | is set when component mounts
      popupMessages: null,
      popupMessagesType: "",
      showPopup: false,
    };
    // ========== INSTANCE PROPERTIES ==========
    this.initialState = { ...this.state };
    this.digitizeTicketTimeout = null;
    // Bind popup message functions
    this.throwPopupMessage = throwPopupMessage.bind(this);
    this.closePopup = closePopup.bind(this);
  }

  // =============== STATE DOCS ===============
  /*

--> user_search
  { ...this.initialState }
  Error messages will be shown here

--> user_search_loading
  { ...this.initialState }
  or
  {
    ...this.this.initialState,
    searchParam: String
  }

--> user_search_done
  {
    searchParam: String,
    users: [{one or more}]
  }

--> user_selection
  {
    searchParam: String,
    users: [{one or more}],
    selectedUser: {object},
    showResponsePanel: true
  }

  --> user_selection_loading
  {
    searchParam: String,
    users: [{one or more}],
    selectedUser: {object},
    showResponsePanel: true
  }

--> user_selection_done
  {
    searchParam: String,
    users: [{one or more}],
    selectedUser: {object},
    showResponsePanel: true,
    newTicketReference: String,
    newTicketDate: moment,
    newTicketHour: String,
    newTicketMinute: String
  }

--> ticket_digitize_confirmation
  {
    searchParam: String,
    tickets: [{one or more}],
    selectedUser: {object},
    showResponsePanel: true,
    newTicketReference: String,
    newTicketDate: moment,
    newTicketHour: String,
    newTicketMinute: String,
    popupMessages: [<DigitizeConfirmation/>],
    popupMessagesType: 'alert',
    showPopup: true
  }

--> ticket_digitize_loading
  {
    searchParam: String,
    tickets: [{one or more}],
    selectedUser: {object},
    showResponsePanel: true,
    newTicketReference: String,
    newTicketDate: moment,
    newTicketHour: String,
    newTicketMinute: String,
    popupMessages: [<DigitizeValidation/>],
    popupMessagesType: 'alert',
    showPopup: true
  }

--> ticket_digitize_done
  {
    searchParam: String,
    tickets: [{one or more}],
    selectedUser: {object},
    showResponsePanel: true,
    newTicketReference: String,
    newTicketDate: moment,
    newTicketHour: String,
    newTicketMinute: String,
    popupMessages: null,
    popupMessagesType: '',
    showPopup: false,
    generatedTicket: {object},
    showGeneratedTicket: true
  }
*/
  // =============== END STATE DOCS ===============

  /*
   ** mpos-ticket-digitize on Mount:
   ** --> set viewType
   ** --> set the 'resize' event listener
   */
  componentDidMount() {
    // --> Join Socket
    /*  initiateSocket();
    joinCommerceSocket(this.props.token, (socket_res) => {
      console.log(socket_res.message);
    });*/
    /*   joinSocket(this.props.token, (socket_res) => {
      console.log(socket_res.message);
    }); */
    // --> Set 'mark as paid' event listener
    //initiateSocket();
    /*     joinCommerceSocket(this.state.token, (socket_res) => {
      console.log(socket_res.message);
      console.log("test");
    }); */
    addSocketListener("digitized ticket", (socket_res) => {
      if (socket_res.error) {
        console.log({ socket_res, error: "error" });
        clearTimeout(this.digitizeTicketTimeout);
        // show error popup
        this.setState({ viewState: "user_selection_done" });
        this.closePopup();

        setTimeout(() => {
          this.throwPopupMessage("error", socket_res.error.description);
        }, 250);
      }
      if (socket_res.ticket) {
        let premiumObj = null;
        clearTimeout(this.digitizeTicketTimeout);
        this.closePopup();
        let ticketPaid = false;
        if (socket_res.ticket.status && socket_res.ticket.status === "paid") {
          ticketPaid = true;
        }
        if (
          socket_res.premium_is_active &&
          socket_res.remaining_premium_days >= 0 &&
          socket_res.remaining_tickets_per_day >= 0
        ) {
          premiumObj = {
            active: socket_res.premium_is_active,
            remainingPremiumDays: socket_res.remaining_premium_days,
            remainingTicketsPerDay: socket_res.remaining_tickets_per_day,
          };
        }
        setTimeout(() => {
          // If it is on the correct state, proceed
          if (this.state.viewState === "ticket_digitize_loading") {
            if (this.state.viewType === "phone") {
              this.setState({
                showResponsePanel: false,
              });
              setTimeout(() => {
                this.setState({
                  viewState: "ticket_digitize_done",
                  showGeneratedTicket: true,
                  generatedTicket: socket_res.ticket,
                  ticketPaid,
                  premiumObj,
                });
              }, 350);
            } else {
              this.setState({
                viewState: "ticket_digitize_done",
                showGeneratedTicket: true,
                generatedTicket: socket_res.ticket,
                ticketPaid,
                premiumObj,
              });
            }
          }
        }, 250);
      }
    });
    // --> Set viewType
    this.setViewType();
    // --> Set 'resize' event listener
    window.addEventListener("resize", this.setViewType);
    document.addEventListener("keydown", this.onEnter);
  }

  // Remove event listeners
  componentWillUnmount() {
    window.removeEventListener("resize", this.setViewType);
    document.removeEventListener("keydown", this.onEnter);
    removeSocketListener();
    //disconnectSocket();
  }

  componentDidUpdate() {
    let building = this.props.getSelectedBuilding
      ? this.props.getSelectedBuilding
      : this.props.getSelectedCommerce.parking.building;
    let buildingName =
      building && building.commercial_name ? building.commercial_name : null;
    this.setState({
      building,
      buildingName,
    });
  }
  // ========== METHODS ==========
  // 1)
  handleSearchInputChange = (event) => {
    let value = event.target.value;
    // The field is restrcted to accept only letters, numbers, @(first position) and _-.
    if (value.match(/^(?=@?.*)@?[\w.-]*$/)) {
      value = value.toLowerCase();

      this.setState({
        ...this.initialState,
        buildingName: this.state.buildingName,
        //viewState: "user_search_loading",
        searchParam: value,
      });
      // call the API in a debounced way
      // this.debouncedApiCall(value);
    }
  };

  // 2) disabled for now
  debouncedApiCall = debounce((value) => {
    // remove the @ from the first position before using it in the request
    value = value[0] === "@" ? value.substr(1) : value;
    // call the API
    let usersPromise = usersAutocomplete(value);
    // Manage the response
    this.handleUsersSearchPromise(usersPromise);
    // Timer
  }, 600);

  // 3)
  handleUsersSearchPromise = (promise) => {
    promise
      .then((res) => res.json())
      .then((res) => {
        // if error, reset view and print error
        if (res.error) {
          // If it is an authentication error, clear the redux-store to close the session
          if (
            res.error.id === "NO_TOKEN_PROVIDED" ||
            res.error.id === "AUTHENTICATE_FAILED" ||
            res.error.id === "DUPLICATE_SESSION"
          ) {
            //this.props.clearReduxStore();
            this.throwPopupMessage("alert", errors.AUTHENTICATION);
            return; // prevent setState exeution on unmounted component
          }
          this.setState({ ...this.initialState });
          this.throwPopupMessage("error", res.error.description);
        }
        if (res.users) {
          if (!res.users.length) {
            // if the response has no users, reset view
            this.setState((prevState) => ({
              ...this.initialState,
              buildingName: prevState.buildingName,
              searchParam: prevState.searchParam,
              viewState: "user_search",
            }));
          } else {
            // else, print the tickets
            this.setState((prevState) => ({
              ...this.initialState,
              buildingName: prevState.buildingName,
              searchParam: prevState.searchParam,
              viewState: "user_search_done",
              users: res.users,
            }));
          }
        }
      })
      // if an error occurs, reset the view and print the error
      .catch((err) => {
        this.setState({ ...this.initialState });
        this.throwPopupMessage(
          "error",
          "Ha ocurrido un error, verifique su conexión a internet"
        );
      });
  };

  // 4)
  handleUserSelection = (event) => {
    let index = event.currentTarget.id;
    let selectedUser = this.state.users[index];
    this.setState((prevState) => ({
      ...this.initialState,
      buildingName: prevState.buildingName,
      viewState: "user_selection_loading",
      searchParam: prevState.searchParam,
      showResponsePanel: true,
      users: prevState.users,
      selectedUser,
    }));
    usersTicketsAvailability(selectedUser.username)
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          if (res.error.id === "ANOTHER_TICKET_IS_READY") {
            this.setState({
              viewState: "user_selection",
            });
          } else {
            // If it is an authentication error, clear the redux-store to close the session
            if (
              res.error.id === "NO_TOKEN_PROVIDED" ||
              res.error.id === "AUTHENTICATE_FAILED" ||
              res.error.id === "DUPLICATE_SESSION"
            ) {
              //this.props.clearReduxStore();
              this.throwPopupMessage("alert", errors.AUTHENTICATION);
              return; // prevent setState exeution on unmounted component
            } else {
              this.setState({ ...this.initialState });
              this.throwPopupMessage("error", res.error.description);
            }
          }
        }
        if (res.available) {
          this.setState({
            viewState: "user_selection_done",
          });
        }
      })
      // if an error occurs, reset the view and print the error
      .catch((err) => {
        this.setState({ ...this.initialState });
        this.throwPopupMessage(
          "error",
          "Ha ocurrido un error, verifique su conexión a internet"
        );
      });
  };

  // 5)
  removeSelectedUser = (event) => {
    event.preventDefault();
    this.setState({
      showResponsePanel: false,
    });
    setTimeout(() => {
      this.setState({
        viewState: "user_search_done",
        selectedUser: null,
      });
    }, 350);
  };

  // 6)
  handleTicketReference = (event) => {
    let value = event.target.value;
    // The field is restrcted to accept only letters, numbers and _-.
    if (value.match(/^[\w.-]*$/)) {
      this.setState({ newTicketReference: value });
    }
  };

  // 7)
  handleTicketDate = (date) => {
    if (date) {
      this.setState({ newTicketDate: date.startOf("date") });
    } else {
      this.setState({ newTicketDate: date });
    }
  };

  // 8)
  handleTicketHour = (event) => {
    let value = event.target.value;

    if (value.length === 3) {
      value = value.substring(1);
    }
    if (value.match(/^(0?[0-9]|0?1[0-9]|0?2[0-3])?$/)) {
      if (value.match(/^[1-9]$/)) {
        value = "0" + value;
      }
      this.setState({ newTicketHour: value });
    }
  };

  // 9)
  handleTicketMinute = (event) => {
    let value = event.target.value;

    if (value.length === 3) {
      value = value.substring(1);
    }
    if (value.match(/^(0?[0-9]|0?[1-5][0-9])?$/)) {
      if (value.match(/^[1-9]$/)) {
        value = "0" + value;
      }

      this.setState({ newTicketMinute: value });
    }
  };

  toggleDigitizeConfirmation = (event) => {
    event.preventDefault();
    if (
      this.state.viewState === "ticket_digitize_confirmation" ||
      this.state.viewState === "ticket_digitize_loading"
    ) {
      this.closePopup();
      // timeout the update for the animation
      setTimeout(() => {
        this.setState({ viewState: "user_selection_done" });
      }, 250);
    }
    if (this.state.viewState === "user_selection_done") {
      // validate enter_at is before moment()
      let enter_at = moment(this.state.newTicketDate);
      const newTicketHour = parseInt(this.state.newTicketHour, 10);
      const newTicketMinute = parseInt(this.state.newTicketMinute, 10);
      enter_at.add(newTicketHour, "hours");
      enter_at.add(newTicketMinute, "minutes");
      if (enter_at.isAfter(moment())) {
        this.throwPopupMessage(
          "error",
          "La fecha y hora de entrada no puede ser mayor a la actual"
        );
      } else {
        this.setState({ viewState: "ticket_digitize_confirmation" });
        // timeout the update for the animation
        setTimeout(() => {
          this.throwPopupMessage("alert", "");
        }, 250);
      }
    }
  };

  // 11)
  digitizeTicket = (event) => {
    this.setState({ viewState: "ticket_digitize_loading" });
    // format enter_at
    let enter_at = moment(this.state.newTicketDate);
    let newTicketHour = parseInt(this.state.newTicketHour, 10);
    let newTicketMinute = parseInt(this.state.newTicketMinute, 10);
    enter_at.add(newTicketHour, "hours");
    enter_at.add(newTicketMinute, "minutes");
    enter_at = enter_at.toISOString();
    // format request body
    let building = this.state.building._id;
    let newTicketData = {
      ticket_reference: this.state.newTicketReference,
      customer: this.state.selectedUser._id,
      building,
      enter_at,
    };

    // set 'digitized ticket' event timeout
    this.digitizeTicketTimeout = setTimeout(() => {
      if (this.state.viewState === "ticket_digitize_loading") {
        this.setState({
          viewState: "ticket_digitize_confirmation",
        });
      }
    }, 20000);
    // Emit 'mark as paid' event
    digitizeTicket(this.props.token, newTicketData);
  };

  // 12)
  resetView = () => {
    this.setState({ showGeneratedTicket: false });
    setTimeout(() => {
      this.setState({ ...this.initialState });
    }, 350);
  };

  // 13)
  setViewType = () => {
    let documentElement = document.documentElement,
      width = window.innerWidth || documentElement.clientWidth;
    if (width < 768) {
      if (this.state.viewState === "ticket_digitize_done") {
        this.setState({
          viewType: "phone",
          showResponsePanel: false,
        });
      } else {
        this.setState({ viewType: "phone" });
      }
    } else {
      if (this.state.viewState === "ticket_digitize_done") {
        this.setState({
          viewType: "desktop",
          showResponsePanel: true,
        });
      } else {
        this.setState({ viewType: "desktop" });
      }
    }
  };

  // new handlers
  handleSearchUser = (event) => {
    event.preventDefault();
    // search input
    let usersPromise = usersAutocomplete(this.state.searchParam);
    // Manage the response
    this.setState({
      viewState: "user_search_loading",
    });
    this.handleUsersSearchPromise(usersPromise);
  };

  onEnter = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      this.handleSearchUser(event);
    }
  };

  render() {
    if (this.state.viewType === "phone") {
      return (
        <MposTicketsDigitizePhoneView
          // Props
          role={this.state.role}
          viewType={this.state.viewType}
          viewState={this.state.viewState}
          searchParam={this.state.searchParam}
          ticketPaid={this.state.ticketPaid}
          premiumObj={this.state.premiumObj}
          commerce={this.state.commerce}
          users={this.state.users}
          buildingName={this.state.buildingName}
          showResponsePanel={this.state.showResponsePanel}
          selectedUser={this.state.selectedUser}
          newTicketReference={this.state.newTicketReference}
          newTicketDate={this.state.newTicketDate}
          newTicketHour={this.state.newTicketHour}
          newTicketMinute={this.state.newTicketMinute}
          generatedTicket={this.state.generatedTicket}
          showGeneratedTicket={this.state.showGeneratedTicket}
          digitizedBy={this.props.companyName}
          popupMessages={this.state.popupMessages}
          popupMessagesType={this.state.popupMessagesType}
          showPopup={this.state.showPopup}
          location={this.props.location}
          // Methods
          handleSearchInputChange={this.handleSearchInputChange}
          handleUserSelection={this.handleUserSelection}
          removeSelectedUser={this.removeSelectedUser}
          handleTicketReference={this.handleTicketReference}
          handleTicketDate={this.handleTicketDate}
          handleTicketHour={this.handleTicketHour}
          handleTicketMinute={this.handleTicketMinute}
          toggleDigitizeConfirmation={this.toggleDigitizeConfirmation}
          digitizeTicket={this.digitizeTicket}
          resetView={this.resetView}
          closePopup={this.closePopup}
          handleSearchUser={this.handleSearchUser}
        />
      );
    }

    return (
      <MposTicketsDigitizeView
        // Props
        role={this.state.role}
        viewState={this.state.viewState}
        searchParam={this.state.searchParam}
        commerce={this.state.commerce}
        users={this.state.users}
        category={this.state.commerce.category}
        ticketPaid={this.state.ticketPaid}
        premiumObj={this.state.premiumObj}
        showResponsePanel={this.state.showResponsePanel}
        selectedUser={this.state.selectedUser}
        newTicketReference={this.state.newTicketReference}
        newTicketDate={this.state.newTicketDate}
        newTicketHour={this.state.newTicketHour}
        newTicketMinute={this.state.newTicketMinute}
        generatedTicket={this.state.generatedTicket}
        buildingName={this.state.buildingName}
        showGeneratedTicket={this.state.showGeneratedTicket}
        digitizedBy={this.props.sessionOf}
        popupMessages={this.state.popupMessages}
        popupMessagesType={this.state.popupMessagesType}
        showPopup={this.state.showPopup}
        location={this.props.location}
        // Methods
        handleSearchInputChange={this.handleSearchInputChange}
        handleUserSelection={this.handleUserSelection}
        handleTicketReference={this.handleTicketReference}
        handleTicketDate={this.handleTicketDate}
        handleTicketHour={this.handleTicketHour}
        handleTicketMinute={this.handleTicketMinute}
        handleSearchUser={this.handleSearchUser}
        toggleDigitizeConfirmation={this.toggleDigitizeConfirmation}
        digitizeTicket={this.digitizeTicket}
        closePopup={this.closePopup}
      />
    );
  }
}

const mapStateToProps = (state) => {
  let getSelectedBuilding = getSelectedBuildingFromRedux(state);
  let getSelectedCommerce = getSelectedCommerceFromRedux(state);
  return { getSelectedBuilding, getSelectedCommerce };
};

const mapDispatchToProps = {
  clearReduxStore,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MPosTicketsDigitize);
