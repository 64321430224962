import moment from "moment";
// Format a Number using "." as thousandns separator and ","
// as decimal separator
// --> receive aeither a string or a number
// --> returns a string
export const inputHour = (x) => {
  x = x.replace(/[,.]/g, ""); // string of only numbers
  if (x.length <= 4) x = x + "00";
  x = x.substring(1);
  let parts = x.split(":");
  // todo When integer part exists
  parts[0] = x.slice(0, 2);
  parts[1] = x.slice(2, 4);

  return parts.join(":"); //Join integer and float part to show
};

export const formatPrice = (x) => {
  if (typeof x === "number") {
    try {
      x = x.toFixed(2);
    } catch (e) {
      return console.error(e);
    }
  }
  if (typeof x === "string") {
    let parts = x.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return parts.join(",");
  }
};

export const inputPrice = (x) => {
  x = x.replace(/[,.]/g, ""); // string of only numbers
  let parts = [];
  if (x.length < 3) {
    //read the decimal part
    parts[0] = 0;
    parts[1] = x;
  } else {
    // todo When integer part exists
    parts[0] = x.slice(0, x.length - 2);
    if (parts[0].charAt(0) === "0") {
      // When the integer part have '0' in the first element, should be delete it
      parts[0] = parts[0].substring(1);
    }
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "."); // Each three numbers are separate with a point
    parts[1] = x.slice(x.length - 2, x.length);
  }
  return parts.join(","); //Join integer and float part to show
};

export const inputPriceMoreZeros = (x) => {
  x = x.replace(/[,.]/g, ""); // string of only numbers
  let parts = [];
  if (x.length < 3) {
    //read the decimal part
    parts[0] = 0;
    parts[1] = x;
  } else {
    // todo When integer part exists
    parts[0] = x.slice(0, x.length - 3);
    if (parts[0].charAt(0) === "0") {
      // When the integer part have '0' in the first element, should be delete it
      parts[0] = parts[0].substring(1);
    }
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "."); // Each three numbers are separate with a point
    parts[1] = x.slice(x.length - 3, x.length);
  }
  return parts.join(","); //Join integer and float part to show
};

export const priceToRequest = (x) => {
  x = x.replace(/\./g, "");
  let parts = x.split(",");
  let priceNumber = parts.join(".");
  return parseFloat(priceNumber);
};
// Decode the a token and returns its payload
export const decode_jwt = (token) => {
  let base64Url = token.split(".")[1];
  let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  return JSON.parse(window.atob(base64));
};

// Return the debounced versión of a function
export const debounce = (fn, time) => {
  let timeout;

  return function () {
    const functionCall = () => fn.apply(this, arguments);

    clearTimeout(timeout);
    timeout = setTimeout(functionCall, time);
  };
};

export const getRgbFromHex = (hex) => {
  try {
    if (hex instanceof String && hex[0] === "#") hex = hex.substr(1);

    let rgbValue = parseInt(hex, 16); // convert rrggbb to decimal
    let r = (rgbValue >> 16) & 0xff; // extract red
    let g = (rgbValue >> 8) & 0xff; // extract green
    let b = (rgbValue >> 0) & 0xff; // extract blue
    let rgb = { r, g, b };
    return rgb;
  } catch (error) {
    console.log(error);
  }
};

export const getAverageColor = (arrOfRgb) => {
  try {
    let red = 0,
      green = 0,
      blue = 0;
    arrOfRgb.forEach((color) => {
      red = red + color.r;
      blue = blue + color.b;
      green = green + color.g;
    });
    let average = {
      r: red / arrOfRgb.length,
      g: green / arrOfRgb.length,
      b: blue / arrOfRgb.length,
    };
    return average;
  } catch (error) {
    console.log(error);
  }
};

export const calculateLuma = (rgbColor) => {
  return 0.2126 * rgbColor.r + 0.7152 * rgbColor.g + 0.0722 * rgbColor.b;
};

export const getQueryParams = (queryString) => {
  queryString = queryString.substr(1);
  queryString = queryString.split("&");
  let queryParams = {};
  queryString.forEach((param) => {
    let pair = param.split("=");
    if (pair[1]) {
      queryParams = { ...queryParams, [pair[0]]: decodeURIComponent(pair[1]) };
    }
  });
  return queryParams;
};

export const loadStateFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem("store");
    if (serializedState === null) {
      return undefined;
    } else {
      return JSON.parse(serializedState);
    }
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

export const saveStateToLocalStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("store", serializedState);
  } catch (err) {
    console.error(err);
  }
};

export const pushOption = (option, optionArray, show = "show") => {
  let newOption = {
    text: show === "show" ? option : "",
    value: option,
    key: optionArray.length,
  };
  optionArray.push(newOption);
  return optionArray;
};

export const pushArrayOptions = (options, show = "show") => {
  let newOptions = [];
  options.forEach((option) => {
    pushOption(option, newOptions, show);
  });

  return newOptions;
};

export const arrayToObject = (array, key) => {
  let obj = {};
  array.map((elem) => {
    obj[elem[key]] = elem;
    return null;
  });
  return obj;
};

export const filterBuildingByCode = ({ buildings, code }) => {
  let result = buildings.filter((building) => {
    if (building.code === code) return building;
    else return null;
  });
  return result;
};

export const listInParts = (list, limit) => {
  let idx = 0,
    result = [];
  while (idx < list.length) {
    if (idx % limit === 0) result.push([]);
    result[result.length - 1].push(list[idx++]);
  }
  return result;
};

export const buildingsToOptions = (buildings) => {
  const buildingsOptions = buildings.map((building, index) => {
    return { key: index, text: building.name, value: building._id };
  });
  buildingsOptions.unshift({ key: "all", text: "Todos", value: null });
  return buildingsOptions;
};

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const randomString = () => {
  let s4 = () => {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
};

export const changeToValidDate = (dateBadFormatted) => {
  let dateArray = dateBadFormatted.split("/");
  return dateArray.join("-");
};

export const formatPhoneNumber = (number) => {
  let dot = ".";
  let numberFormatted =
    number.slice(0, 4) +
    dot +
    number.slice(4, 7) +
    dot +
    number.slice(7, 9) +
    dot +
    number.slice(9, number.length);
  return numberFormatted;
};

export const amountsToObject = (amounts) => {
  const obj = {};
  amounts.forEach((amount) => {
    obj[amount.currency] = amount;
  });
  return obj;
};

export const formatInt = (x) => {
  if (typeof x === "number") {
    try {
      x = x.toString();
    } catch (e) {
      return console.error(e);
    }
  }
  if (typeof x === "string") {
    x = x.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return x;
  }
};
export const randomListKey = () => {
  return Math.random();
};

export const jsonToGetQuery = (queryParams) => {
  const queryParamsKeys = Object.keys(queryParams);
  const queryParamsEmpty = queryParamsKeys.length === 0;
  let query = "";
  if (!queryParamsEmpty) {
    query = "?";
    queryParamsKeys.map((key, index) => {
      let isFirstKey = index < 1;
      query = `${query}${isFirstKey ? "" : "&"}${key}=${queryParams[key]}`;
      return null;
    });
  }
  return query;
};

export const getAccessData = (accesses, code) => {
  let name = "--";
  let track = "--";
  accesses.forEach((access) => {
    if (access.code === code) {
      name = access.name;
      track = access.track_index ? access.track_index : track;
    }
  });
  return { name, track };
};

export const formatString = "DD [de] MMMM YYYY";

export const inputPriceNew = (x) => {
  x = x.replace(/[,.]/g, "");
  let parts = [];

  if (x.length < 3) {
    //read the decimal part
    parts[0] = 0;
    parts[1] = x;
  } else {
    // todo When integer part exists
    parts[0] = x.slice(0, x.length - 2);
    if (parts[0].charAt(0) === "0") {
      // When the integer part have '0' in the first element, should be delete it
      parts[0] = parts[0].substring(1);
    }
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "."); // Each three numbers are separate with a point
    parts[1] = x.slice(x.length - 2, x.length);
  }
  return parts.join(","); //Join integer and float part to show
};

export const currencyAmount = (amount, currency) => {
  if (!amount || amount === "N/A") return "N/A";
  return `${amount} ${currency || ""}`;
};

export const mapStatus = (status) => {
  const allStatus = {
    pending: "Pendiente",
    rejected: "Rechazado",
    approved: "Aprobado",
    deferred: "Diferido",
  };
  return allStatus[status] || status;
};

export const shortFormatDateValidator = (date) => {
  const regex = /^\d{2}\/\d{2}\/\d{4}$/; // "dd/mm/yyyy"
  return regex.test(date);
};
export const checkField = (obj, field, auxField) =>
  obj[field] || obj[auxField] || "N/A";

export const checkDate = (date) => {
  const format = shortFormatDateValidator(date) ? "DD/MM/YYYY" : null;
  const firstFormat = moment(date, format).format("LL");
  const secondFormat = moment(date).format("h:mm a");

  if (!format) {
    return firstFormat !== "Invalid date" && secondFormat !== "Invalid date"
      ? `${firstFormat} ${secondFormat}`
      : date;
  }

  const dateFormatted =
    (firstFormat !== "Invalid date" && firstFormat) ||
    (secondFormat !== "Invalid date" && secondFormat) ||
    date;

  return dateFormatted;
};

export const transactionTagMapper = (tag) => {
  const tagMapper = {
    reverse: "Reverso",
    withdraw: "Retiro",
    parking: "Estacionamiento",
    parking_overtime: "Sobretiempo de estacionamiento",
    fee: "Fee pagodirecto",
    transfer: "Transferencia",
    recharge: "Recarga",
    dollar_payment: "Pago en dólares",
    ubii: "Ubii pago",
    service: "Pago de servicios",
  };
  return tagMapper[tag] || tag;
};
