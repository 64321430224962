import { getDate, setDate } from "./api";
import moment from "moment";

export const getWeekendDate = async (
  changeState,
  setLoading,
  throwPopupMessage
) => {
  setLoading(true);
  try {
    const response = await getDate();
    const res = await response.json();
    if (res.error) throw res.error;
    changeState({
      weekendDate: moment(res.data.date),
    });
  } catch (error) {
    throwPopupMessage(
      "error",
      error.description || "Error al intentar obtener la fecha de fin de semana"
    );
  }
  setLoading(false);
  changeState({
    waitWeekendDate: false,
  });
};

export const setWeekendDate = async (
  state,
  changeState,
  setLoading,
  throwPopupMessage
) => {
  setLoading(true);
  try {
    const response = await setDate({
      date: state.weekendDate,
    });
    const res = await response.json();
    if (res.error) throw res.error;
    changeState({
      weekendDate: moment(res.data.date),
    });
    throwPopupMessage(
      "success",
      "Fecha de fin de semana actualizada correctamente"
    );
  } catch (error) {
    throwPopupMessage(
      "error",
      error.description || "Error al intentar obtener la fecha de fin de semana"
    );
  }
  setLoading(false);
  changeState({
    setWeekendDate: false,
  });
};
