import React from "react";
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const HistoryTicketsGraphModel = ({
  ticketsPerDay,
  title,
  lineName,
  keyX,
  keyY,
  height,
  stroke,
  className,
}) => {
  return (
    <div className={className}>
      <p className="title">{title}</p>
      <ResponsiveContainer height={height ? height : 255}>
        <LineChart
          layout="horizontal"
          data={ticketsPerDay}
          margin={{ top: 15, right: 10, left: -25, bottom: 5 }}
        >
          <CartesianGrid vertical={false} />
          <YAxis axisLine={false} tickLine={false} type="number" />
          <XAxis
            axisLine={false}
            padding={{ left: 20, right: 20 }}
            tickLine={false}
            dataKey={keyX ? keyX : "day"}
            type="category"
          />
          <Tooltip />
          <Legend />
          <Line
            type="monotoneX"
            animationDuration={1000}
            dot={false}
            activeDot={{ r: 7 }}
            animationEasing="linear"
            name={lineName ? lineName : "Tickets Pagados"}
            dataKey={keyY ? keyY : "usedTickets"}
            stroke={stroke ? stroke : "#156D93"}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default HistoryTicketsGraphModel;
