import React from "react";
import { AccessListItem } from "./index";
import { Button } from "components/buttons";
import { general as texts } from "assets/strings/texts";
import { building_settings as viewTexts } from "assets/strings/texts";

const AccessListItemModel = ({
  mac,
  type,
  name,
  code,
  index,
  handleAccessSelection,
  handleAccessEdit,
  handleAccessDelete,
  className,
}) => {
  return (
    <dd index={index} className={className} onClick={handleAccessSelection}>
      <div className="data">
        <p>
          <span className="label">{viewTexts.mac}:</span>
          <span className="mac-address">{mac}</span>
        </p>
        <p>
          <span className="label">{texts.type}:</span>
          {type}
        </p>
        <p>
          <span className="label">{viewTexts.codeAndName}:</span>
          {code} - {name}
        </p>
      </div>
      <div className="buttons">
        <Button
          color="grey_1"
          onClick={(e) => {
            handleAccessEdit(e, index);
          }}
        >
          {texts.edit}
        </Button>
        <Button
          color="red"
          onClick={(e) => {
            handleAccessDelete(e, index);
          }}
        >
          {texts.delete}
        </Button>
      </div>
    </dd>
  );
};

function formatAccessesList(
  accesses,
  viewState,
  handleAccessSelection,
  handleAccessEdit,
  handleAccessDelete
) {
  let accessesList = [];
  accesses.forEach((access, index) => {
    accessesList.push(
      <AccessListItem
        status={access.status}
        mac={access.mac}
        type={access.type}
        code={access.code}
        name={access.name}
        index={index}
        handleAccessSelection={handleAccessSelection}
        handleAccessEdit={handleAccessEdit}
        handleAccessDelete={handleAccessDelete}
        key={access._id}
      />
    );
  });
  return accessesList;

  // if (viewState === 'access_search_loading') {
  //   return <div className='ui active loader'></div>
  // }
  // if (viewState === 'access_search') {
  //   return <div className='not-found'>{texts.notFound} {viewTexts.mtd}</div>
  // }
  // if (viewState === 'access_search_done') {

  //   if(accesses.length === 0){
  //     return <div className='not-found'>{texts.notFound} {viewTexts.mtd}</div>

  //   } else {
  //     // Declaramos el array que devolverá la función
  //     let accessesList = [];
  //     accesses.forEach((access, index) => {
  //       accessesList.push(
  //         <AccessListItem
  //           mac={access.mac}
  //           type={access.type}
  //           code={access.code}
  //           name={access.name}
  //           index={index}
  //           handleAccessSelection={handleAccessSelection}
  //           handleAccessEdit={handleAccessSelection}
  //           key={access._id} />
  //       )
  //     })
  //     return accessesList

  //   }
  // }
}

export { AccessListItemModel, formatAccessesList };
