import { useState, useEffect } from "react";

const useResponsive = () => {
  const [view, setView] = useState("desktop");

  useEffect(() => {
    window.addEventListener("resize", setViewType);
    setViewType();
    return () => {
      window.removeEventListener("resize", setViewType);
    };
  }, []);

  const setViewType = () => {
    const documentElement = document.documentElement;
    const width = window.innerWidth || documentElement.clientWidth;
    const currentView = width < 800 ? "phone" : "desktop";
    setView(currentView);
  };

  return view;
};

export { useResponsive };
