import React from "react";
import { Grid } from "semantic-ui-react";
import { Panel } from "components/panel";
import { RechargeForm } from "./recharge-form";
import SuccessRecharge from "./success-recharge";
import SearchInputNew from "components/search-input-new";
import PopupMessage from "components/popup-message";
import { CSSTransition } from "react-transition-group";
import { EntitiesList } from "components/entities-list";
import { formatUserList } from "./user-list-item";
import { RechargeConfirmation } from "./recharge-confirmation";
import { element_transition_time } from "assets/strings/constants";

const MposTicketsDigitizeView = ({
  viewType,
  viewState,
  searchParam,
  users,
  showRechargeForm,
  bankList,
  bankNameSelected,
  rechargeRef,
  showOtherAmountField,
  dropdownValueToShow,

  selectedUser,
  rechargeAmounts,
  desiredRechargeAmount,
  showGeneratedRecharge,
  popupMessages,
  popupMessagesType,
  showPopup,
  closePopup,
  handleSearchInputChange,
  handleUserSelection,
  handleSearchUser,
  removeSelectedUser,
  handleAmountSelect,
  handleBankSelect,
  handleReference,
  handleOtherAmount,

  toggleRechargeConfirmation,
  recharge,
  resetView,
}) => {
  return (
    <Grid padded>
      {!(
        viewState === "recharge_confirmation" ||
        viewState === "recharge_loading"
      ) && (
        // If not on confirmation process, show popup
        <PopupMessage
          messageType={popupMessagesType}
          showPopup={showPopup}
          messages={popupMessages}
          closePopup={closePopup}
        />
      )}

      {(viewState === "recharge_confirmation" ||
        viewState === "recharge_loading") && (
        // If view IS on confirmation process, show confirmation popup
        <PopupMessage
          messageType={popupMessagesType}
          showPopup={showPopup}
          messages={popupMessages}
          closePopup={toggleRechargeConfirmation}
        >
          <RechargeConfirmation
            viewType={viewType}
            viewState={viewState}
            selectedUser={selectedUser}
            amount={desiredRechargeAmount}
            recharge={recharge}
            cancel={toggleRechargeConfirmation}
          />
        </PopupMessage>
      )}

      <Grid.Column width={16}>
        {(viewState === "user_search" ||
          viewState === "user_search_loading" ||
          viewState === "user_search_done") && [
          <SearchInputNew
            key="search-input"
            placeholder="Buscar Usuario"
            searchParam={searchParam}
            handleChange={handleSearchInputChange}
            handleSearch={handleSearchUser}
          />,
          <EntitiesList
            key="users-list"
            height="calc(100vh - 9rem)"
            viewState={viewState}
            formattingFunction={formatUserList}
            entities={users}
            selectionHandler={handleUserSelection}
          />,
        ]}

        <CSSTransition
          in={showRechargeForm}
          mountOnEnter
          unmountOnExit
          timeout={element_transition_time}
          appear={true}
          classNames="panel"
        >
          <Panel
            className={
              (viewState === "recharge_confirmation" ||
                viewState === "recharge_loading" ||
                viewState === "recharge_done") &&
              "inactive"
            }
          >
            {viewState === "recharge_loading" && (
              <div className="ui loader active"></div>
            )}

            {(viewState === "user_selection_done" ||
              viewState === "recharge_confirmation" ||
              viewState === "recharge_loading" ||
              viewState === "recharge_done") && (
              <RechargeForm
                viewType={viewType}
                rechargeRef={rechargeRef}
                viewState={viewState}
                selectedUser={selectedUser}
                rechargeAmounts={rechargeAmounts}
                showOtherAmountField={showOtherAmountField}
                dropdownValueToShow={dropdownValueToShow}
                desiredRechargeAmount={desiredRechargeAmount}
                bankNameSelected={bankNameSelected}
                bankList={bankList}
                // handlers
                handleReference={handleReference}
                handleBankSelect={handleBankSelect}
                handleAmountSelect={handleAmountSelect}
                handleOtherAmount={handleOtherAmount}
                toggleRechargeConfirmation={toggleRechargeConfirmation}
                removeSelectedUser={removeSelectedUser}
              />
            )}
          </Panel>
        </CSSTransition>

        <CSSTransition
          in={showGeneratedRecharge}
          mountOnEnter
          unmountOnExit
          timeout={element_transition_time}
          appear={true}
          classNames="panel"
        >
          <Panel>
            {viewState === "recharge_done" && (
              <SuccessRecharge
                desiredRechargeAmount={desiredRechargeAmount}
                selectedUser={selectedUser}
                resetView={resetView}
              />
            )}
          </Panel>
        </CSSTransition>
      </Grid.Column>
    </Grid>
  );
};

export default MposTicketsDigitizeView;
