import React from "react";
import styled from "styled-components";
import HistoryForm from "./form";
import HistoryList from "./list";

const HistoryModel = ({ className }) => {
  return (
    <div className={className}>
      <HistoryForm />
      <HistoryList />
    </div>
  );
};

const History = styled(HistoryModel)`
  #root & {
    height: calc(100vh - 4rem);
  }
`;
export default History;
