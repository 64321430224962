import React from "react";
import styled from "styled-components";
import { grey_4 } from "assets/strings/colors";

const HorizontalLine = styled(({ className }) => <div className={className} />)`
  #root & {
    margin: 0;
    padding: 0;
    width: 100%;
    border-bottom: 1px solid ${grey_4};
  }
`;

export default HorizontalLine;
