import React from 'react'

const PrivacyPolicies = (props) => {
  return(
    <div>
      <h3 id="1elcompromisodepagodirectoconsuprivacidad"><strong>1. EL COMPROMISO DE PAGODIRECTO CON SU PRIVACIDAD.</strong></h3>

      <p><strong>PAGODIRECTO</strong>  se compromete a proteger la privacidad de sus usuarios. La Política de Privacidad de <strong>PAGODIRECTO</strong> (la " <strong>Política de Privacidad</strong>") es aplicable a toda la información personal recolectada por PagoDirecto, o remitida a PagoDirecto, ya sea con conexión o sin conexión, incluyendo información personal recolectada o remitida a través de nuestras paginas (web/app) web y/o app (nuestras "<strong>Paginas (web/app) Web y/o app</strong>") y cualquier sitio móvil, aplicación, widget y cualquier otra modalidad interactiva móvil (conjuntamente, nuestras " <strong>Aplicaciones</strong>"), a través de las páginas de redes sociales oficiales que controlamos (nuestras " <strong>Redes Sociales</strong>") así como a través de mensajes de correo electrónico con formato HTML que enviemos (conjuntamente, incluyendo las Redes Sociales, Aplicaciones y Paginas (web/app) Web y/o app, las "<strong>Paginas (web/app)</strong>"). Si usted no está de acuerdo con alguno de los términos de esta Política de Privacidad, por favor no utilice la página o app ni proporcione ningún tipo de información personal.</p>

      <h3 id="2informacinpersonal"><strong>2. INFORMACIÓN PERSONAL</strong></h3>

      <h4 id="qutipodeinformacinpersonalrecolectamos"><strong>¿Qué tipo de Información Personal recolectamos?</strong></h4>

      <p>" <strong>Información Personal</strong>" es toda aquella información que lo identifica como individuo o la información que una vez combinada pueda crear un perfil de usted como tal, ya sea completo o parcial, tal como:</p>

      <ul>
        <li>Nombre</li>

        <li>Nombre de usuario</li>

        <li>Domicilio (incluyendo direcciones de facturación y de envío)</li>

        <li>Número telefónico (incluyendo teléfono celular y fijo)</li>

        <li>Correo electrónico</li>

        <li>Número de tarjeta de crédito y/o débito</li>

        <li>Foto de perfil (si le es requerido)</li>

        <li>Identificación de Usuario de Redes Sociales</li>

        <li>País de Residencia</li>

        <li>Nombre de empresa y domicilio</li>

        <li>Preferencias</li>

        <li>Información de su navegador y dispositivo</li>

        <li>Información del archivo de registro del servidor</li>

        <li>Información recolectada a través de cookies, etiquetas de píxeles y otras tecnologías</li>

        <li>Datos para el uso de las aplicaciones</li>

        <li>Información demográfica y otra información proporcionada por usted</li>

        <li>Información de localización</li>

        <li>Información agregada</li>
      </ul>

      <h4 id="cmorecolectamossuinformacinpersonal"><strong>¿Cómo recolectamos su información personal?</strong></h4>

      <p>PagoDirecto y nuestros proveedores de servicios podemos recopilar su información personal de muchas maneras, incluyendo:</p>

      <ul>
        <li><strong>A través de Páginas (web/app):</strong> Podremos recopilar Información Personal a través de los Páginas (web/app), por ejemplo, cuando realiza una suscripción o al realizar una compra.</li>

        <li><strong>Sin Conexión:</strong>  Podremos recopilar Información Personal sin conexión, tal como cuando contacte a nuestro servicio de atención a usuarios.</li>

        <li><strong>De otras fuentes:</strong>  Podremos recibir su Información Personal de otras fuentes, tales como bases de datos públicas, socios comerciales; plataformas de redes sociales, de personas con las que usted sea amigo o se encuentre conectado de alguna otra forma en plataformas de redes sociales, así como de cualquier tercero. Por ejemplo, si usted elige relacionar su cuenta de Redes Sociales a su cuenta del Sitio Web y/o app, cierta información Personal de su cuenta de Redes Sociales se compartirán con PagoDirecto. Dicha información puede incluir Información Personal que forme parte de su perfil o los perfiles de sus amigos.</li>

        <li><strong>A través de su navegador o dispositivo:</strong>  Cierta información es recolectada por la mayoría de los navegadores o automáticamente a través de su dispositivo. Utilizamos esta información para fines estadísticos, así como para garantizar el correcto funcionamiento de los Paginas (web/app).</li>

        <li><strong>A través de archivos de registro del servidor:</strong>  Su "Dirección IP" es un número que se asigna automáticamente a la computadora o el dispositivo que usted está usando por su Proveedor de Servicios Internet (PSI). Una Dirección IP es identificada y registrada automáticamente en nuestros archivos de registro del servidor cuando el usuario visita las Páginas (web/app), así como la hora de la visita y la(s) página(s) que fueron visitadas. Por favor, tenga en cuenta que consideramos a las Direcciones IP, archivos de registro de servidor y la información relacionada como Otra Información, con excepción de aquellos casos en los que la ley aplicable disponga de otra manera.</li>

        <li><strong>Uso de cookies:</strong>  Las cookies permiten que un servidor web y/o app pueda transferir datos a una computadora o dispositivo con fines de registro y otros propósitos. Si no desea que su información sea recolectada a través del uso de cookies, hay un procedimiento simple en la mayoría de los navegadores que le permite deshabilitar el uso de cookies. Para conocer más acerca del uso de cookies, por favor visite http:www.allaboutcookies.org. Para conocer más acerca de nuestro uso de cookies, por favor consulte nuestra política de cookies.</li>

        <li><strong>A través del uso de la Aplicación.</strong>  Al descargar y utilizar la Aplicación, PagoDirecto y nuestros proveedores de servicios podemos rastrear y recopilar datos de uso de la Aplicación, tales como la fecha y hora en que la Aplicación instalada en su dispositivo acceda a nuestros servidores y qué información y archivos han sido descargados a la Aplicación en su número de dispositivo.</li>

        <li><strong>De usted:</strong>  Podemos recopilar información como la fecha de nacimiento, sexo y código postal, así como sus medios de comunicación preferentes, siempre y cuando usted voluntariamente proporcione esta información.</li>

        <li><strong>Localización física:</strong>  Podremos recopilar la localización física de su dispositivo.</li>
      </ul>

      <p>En algunos casos, podremos usar un servicio de pago a través de terceros. En estos casos, su Información Personal podrá ser recolectada por un tercero y no por PagoDirecto, y estará sujeta a la política de privacidad de terceros y no a la presente Política de Privacidad. No tendremos control y no seremos responsables por el uso o divulgación de su Información Personal que realice este tercero.</p>

      <h4 id="cmousamossuinformacinpersonal"><strong>¿Cómo usamos su Información Personal?</strong></h4>

      <p>Podremos usar su Información Personal:</p>

      <ul>
        <li>Para responder a sus consultas y satisfacer sus solicitudes, tales como el envío de información, contenido o para responder a sus preguntas y comentarios.</li>

        <li>Para enviarle información de su interés, por ejemplo, información sobre los paginas (web/app) y los cambios en nuestros términos, condiciones y políticas. Ya que esta información puede ser importante para el uso de los paginas (web/app), usted no podrá optar por no recibir estas comunicaciones.</li>

        <li>Para completar y cumplir su compra, por ejemplo, para sus pagos, comunicarnos con usted en relación a su compra y proporcionarle información sobre servicio al cliente.</li>

        <li>Para proporcionarle actualizaciones y novedades relativas a nuestros productos, promociones y programas, así como para enviarle invitaciones para participar en programas especiales.</li>

        <li>Para permitirle contactar y ser contactado por otros usuarios a través de los paginas (web/app), en la medida que el Sitio aplicable lo permita.</li>

        <li>Para permitir que participe en foros, chats, páginas de perfiles y blogs y otros servicios en los que será capaz de publicar información y materiales (incluyendo nuestras Redes Sociales).</li>

        <li>Para nuestros propósitos, tales como análisis y gestión de nuestros negocios, investigación de mercados, auditorías, desarrollo de nuevos productos, mejorar nuestras Páginas (web/app), mejorar nuestros servicios y productos, identificar tendencias de uso, determinar la eficacia de nuestras campañas de publicidad, adaptar la experiencia a través de los Paginas (web/app) y su contenido en base a sus actividades en los Paginas (web/app) y medir la satisfacción del cliente, así como proveer servicio al cliente (incluida la solución de problemas en relación con servicios al cliente).</li>

        <li>Utilizamos información recopilada a través de su navegador o dispositivo para fines estadísticos así como para asegurar el buen funcionamiento de nuestras Páginas (web/app).</li>

        <li>Para información detallada de las finalidades para las cuales utilizamos las cookies, por favor revise nuestra política de cookies.</li>

        <li>Podemos utilizar la localización física de su dispositivo para proveerle servicios de localización y contenido personalizados. También podremos compartir la localización física de su dispositivo, combinada con información de la publicidad vista por usted y otra información que recolectamos, con nuestros socios de mercadotecnia, y con ninguna otro tercero, para permitirles proveerlo con contenido personalizado y estudiar la efectividad de las campanas publicitarias.</li>

        <li>Ya que creemos que es necesario o apropiado: (a) bajo la ley aplicable, incluyendo leyes fuera de su país de residencia, considerando que dicha jurisdicción tenga provisiones para garantizar la protección de su información personal tal como la regulación venezolana en materia de información personal; (b) cumplir con procesos legales; (c) para responder a las solicitudes de las autoridades públicas y de gobierno incluyendo las autoridades públicas y de gobierno fuera de su país de residencia, considerando que dicha jurisdicción tenga provisiones para garantizar la protección de su información personal tal como la regulación venezolana en materia de información personal ; (d) para hacer cumplir nuestros términos y condiciones; (e) para proteger nuestras operaciones o las de cualquiera de nuestras afiliadas; (f) para proteger nuestros derechos, privacidad, seguridad o propiedad, y/o de nuestras afiliadas, de usted o de otras personas; y (g) para permitirnos buscar soluciones disponibles o limitar los daños que PagoDirecto podemos contener.</li>

        <li>Sus datos personales se tratarán por PAGODIRECTO dentro y fuera de Venezuela, considerando que dicha jurisdicción tenga provisiones para garantizar la protección de su información personal tal como la regulación venezolana en materia de información personal. Los socios comerciales con los que compartimos información son sólo aquellos que necesitan tener acceso a Información Personal para procesar la orden que usted ha realizado en nuestras Paginas (web/app), tal como, pagos, asistencia en transportación, estancia, escuelas, quienes sólo reciben la información necesaria para dichos propósitos. PAGODIRECTO procesa sus datos personales en cumplimiento de la legislación de protección de datos y privacidad aplicable. Si tiene preguntas sobre el tratamiento de sus datos personales realizado por PAGODIRECTO, o le gustaría obtener una copia de la información que tiene PAGODIRECTO, o quiere que sean rectificados, o bien, sus datos personales son inexactos o desea sean eliminados, por favor contáctenos.</li>
      </ul>

      <h3 id="3seguridad"><strong>3. SEGURIDAD</strong></h3>

      <p>Utilizamos medidas organizacionales, técnicas y administrativas razonables para proteger la Información Personal que se encuentra bajo nuestro control. Desafortunadamente, ninguna transmisión de datos a través de internet o a través del sistema de almacenamiento de datos puede ser considerada como 100% segura.</p>

      <p>Si existe alguna razón por la que crea que su relación y/o comunicación con PagoDirecto ya no es segura (por ejemplo, si considera que la seguridad de cualquier cuenta que tenga con PagoDirecto se encuentra comprometida), por favor notifíquenos el problema inmediatamente, comunicándose con PagoDirecto según se indica en la sección de contacto.</p>

      <h3 id="4accesomodificacinyopciones"><strong>4. ACCESO, MODIFICACIÓN Y OPCIONES</strong></h3>

      <p>Le ofrecemos diversas opciones relacionadas con el uso y revelación de si Información Personal para fines de publicidad.</p>

      <ul>
        <li>Si en cualquier momento usted desea dejar de recibir comunicaciones con fines de publicidad, la manera más sencilla es utilizando la opción para cancelar la subscripción a la comunicación recibida. De igual forma, podrá hacérnoslo saber mediante correo electrónico, llamada o escribiéndonos usando la información contenida en la sección de contacto. En dicha solicitud, por favor indique su interés en dejar de recibir comunicaciones con fines de publicidad.</li>

        <li>Adicionalmente, PagoDirecto no revelaremos su Información Personal a terceras personas, incluyendo nuestras subsidiarias. Solamente compartiremos su Información Personal si hemos recibido previamente su autorización para hacerlo. Adicionalmente, aún cuando contemos con su previa autorización, dejaremos de compartir su Información Personal si recibimos y procesamos una solicitud de su parte, en la cual nos solicite detener la transmisión de su Información Personal. Si usted quisiera realizar dicha solicitud, por favor hágalo mediante el procedimiento establecido en la sección de contacto. Por favor indique claramente que solicita que se detenga la transmisión de su Información Personal a nuestras subsidiarias y/o cualquier tercero con propósitos de publicidad.</li>

        <li>Por favor, tome en cuenta que los cambios pueden no hacerse de manera inmediata. Nos esforzaremos por cumplir con su(s) solicitud(es) tan pronto como nos sea posible y en ningún caso después de 30 días a partir de que recibamos su petición. Tenga también en cuenta que si usted opta por dejar de recibir mensajes relacionados con publicidad, todavía podremos enviarle mensajes importantes de carácter administrativo. Usted no podrá optar por dejar de recibir mensajes administrativos.</li>

        <li>Para revisar, corregir, actualizar, suprimir, eliminar o limitar de cualquier manera el uso de la Información Personal que nos haya proporcionado anteriormente, por favor utilice el formulario de la sección de contacto y establezca claramente su solicitud. En su petición, por favor aclare qué información le gustaría que sea modificada, si desea que su Información Personal sea eliminada de nuestra base de datos o qué limitaciones le gustaría establecer para el uso de su Información Personal.</li>

        <li>Intentaremos cumplir con su petición tan pronto como sea razonablemente posible. Tome en cuenta que a pesar de nuestros esfuerzos, puede haber información residual que permanecerá en nuestras bases de datos y otros registros, los cuales no serán eliminados o modificados. Además, tenga en cuenta que puede resultar necesario mantener cierta información para conservar registros y/o completar cualquier transacción que haya iniciado antes de solicitar la modificación o cancelación (e. g. al realizar una compra o entrar en una promoción, no podrá cambiar o borrar los datos personales facilitados hasta después de la terminación de dicha compra o promoción).</li>
      </ul>

      <h3 id="5paginaswebappdeterceros"><strong>5. PAGINAS (WEB/APP) DE TERCEROS</strong></h3>

      <p>La presente Política de Privacidad no está dirigida a la privacidad, información u otras prácticas de terceros, por las cuales no seremos responsables, incluyendo cualquier tercero que opere en cualquier Sitio que se encuentre enlazado con los paginas (web/app) de PAGODIRECTO. La inclusión de un enlace en páginas (web/app) de PAGODIRECTO no implica que el sitio enlazado cuente con verificación o rectificación nuestra o de nuestras afiliadas.</p>

      <p>Además, podemos darle acceso a funciones de terceros que le permitan enviar contenido a sus cuentas de redes sociales. Por favor, tenga en cuenta que cualquier información que usted proporcione a través del uso de estas funciones se rige por la política de privacidad de los terceros y no por la presente Política de Privacidad. PagoDirecto no tenemos ningún control ni nos haremos responsables por el uso de la información que usted proporcione a cualquier tercero a través del uso de estas funciones.</p>

      <p>A su vez, no somos responsables de la recolección, uso y divulgación políticas y prácticas (incluyendo las prácticas de seguridad de datos) de otras organizaciones, tales como Facebook, Apple, Google, Microsoft o cualquier desarrollador de aplicaciones, proveedor de aplicación, proveedor de plataformas de redes sociales, proveedor del sistema operativo, proveedor de servicios inalámbricos o fabricante del dispositivo, incluyendo cualquier información Personal que usted Divulgue a otras organizaciones a través de o en relación con las aplicaciones o nuestras Páginas de Redes Sociales.</p>

      <h3 id="6periododeconservacin"><strong>6. PERIODO DE CONSERVACIÓN</strong></h3>

      <p>Conservaremos su Información Personal por el tiempo que sea necesario para cumplir los propósitos establecidos en la presente Política de Privacidad.</p>

      <h3 id="7informacinsensible"><strong>7. INFORMACIÓN SENSIBLE</strong></h3>

      <p>Generalmente solicitamos que no nos envíen y/o nos revele cualquier Información Personal sensible (e. g. información relacionada con el origen racial o étnico, opiniones políticas, religión u otras creencias, salud o dolencia, antecedentes penales o afiliación partidista o sindical), ya sea a través de las Paginas (web/app) o directamente a PagoDirecto. En casos específicos, tales como solicitudes de seguros, le haremos saber cómo proporcionarnos dicha información de manera segura.</p>

      <h3 id="8modificacinaestapolticadeprivacidad"><strong>8. MODIFICACIÓN A ESTA POLÍTICA DE PRIVACIDAD</strong></h3>

      <p>Podremos realizar modificaciones a esta Política de Privacidad. Por favor revise leyenda "Última actualización" en la parte superior de esta página para verificar cuándo fue emitida la última versión de esta Política de Privacidad. Cualquier modificación a esta Política de Privacidad entrará en vigor en el momento en que publiquemos la nueva versión en cualquiera de nuestras Paginas (web/app). El uso que usted haga de los Paginas (web/app) a partir de la publicación de estas modificaciones, significa que usted acepta la nueva Política de Privacidad.</p>

      <h3 id="9contacto"><strong>9. CONTACTO</strong></h3>

      <p>Si tiene cualquier pregunta a cerca de la presente Política de Privacidad, por favor contáctenos a nuestro correo: <a href="mailto:contacto@PagoDirecto.com">contacto@PagoDirecto.com</a></p>
    </div>
  )
}

export default PrivacyPolicies