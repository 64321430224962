import React, { useContext } from "react";
import { AdminTransfersContext } from "../../context";
import { Button } from "components/buttons";
import {
  useNumberInput,
  useNoSpecialCharactersTextInput,
} from "hooks/use-inputs";
import { Loader } from "components/loader";
export const CodeFormModel = ({ className }) => {
  const { changeState, closePopup, state } = useContext(AdminTransfersContext);

  const {
    bind: { value: user, onChange: onChangeUser },
    //reset: resetCode,
  } = useNoSpecialCharactersTextInput("");

  const {
    bind: { value: code, onChange: onChangeCode },
    //reset: resetCode,
  } = useNumberInput("");

  const data = {
    userLabel: "Usuario",
    codeLabel: "Código 2FA",
    buttonLabel: "Confirmar",
    buttonCancelLabel: "Cancelar",
  };

  const onClose = (event) => {
    event.preventDefault();
    changeState({ getCode: false });
    closePopup();
  };

  const onSubmit = (event) => {
    event.preventDefault();
    changeState({ submit: true, code, user });
  };

  return (
    <form className={className} onSubmit={onSubmit}>
      <div className="input-section">
        <label htmlFor="code-id">{data.userLabel}</label>
        <input
          id="user"
          type="text"
          name="code"
          autoComplete="off"
          placeholder="Usuario"
          value={user}
          onChange={onChangeUser}
          required
        />
        <label htmlFor="code-id">{data.codeLabel}</label>
        <input
          id="code"
          type="text"
          name="code"
          autoComplete="off"
          placeholder="Código"
          maxLength={6}
          minLength={6}
          value={code}
          onChange={onChangeCode}
          required
        />
      </div>
      {!state.transferLoading && (
        <Button
          color="blue"
          type="submit"
          width="40%"
          disabled={state.transferLoading}
        >
          {data.buttonLabel}
        </Button>
      )}
      {!state.transferLoading && (
        <Button
          color="grey"
          type="submit"
          width="40%"
          onClick={onClose}
          disabled={state.transferLoading}
        >
          {data.buttonCancelLabel}
        </Button>
      )}
      {state.transferLoading && <Loader margin="10rem 0 0 0" />}
    </form>
  );
};
