import React from "react";
import { useExcelGeneratorService } from "../../services/excel-generator";
import { Button } from "components/buttons";

export const DownloadExcelModel = ({ className }) => {
  const { setActivate, url } = useExcelGeneratorService();

  const downloadExcel = (event) => {
    event.preventDefault();
    setActivate(true);
  };
  const urlClick = () => {
    window.open(url);
  };

  const LinkComponent = () => {
    if (!url) return <></>;
    return <p onClick={urlClick}>click aquí</p>;
  };
  return (
    <div className={className}>
      <Button margin="0" onClick={downloadExcel} color="blue" width="30%">
        Lista general de extensiones
      </Button>
      <div className="link">
        <LinkComponent />
      </div>
    </div>
  );
};
