import React, { useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { addCommerces, selectCommerce } from "redux/actions/commerces";
import { selectBuilding } from "redux/actions/buildings";
import {
  getSelectedBuilding as getSelectedBuildingFromRedux,
  getSelectedCommerce as getSelectedCommerceFromRedux,
} from "redux/selectors";

import { useViewType } from "hooks/use-view-type";
import { useStateManager } from "hooks/use-state-manager";
//import { useDebounce } from "hooks/use-debounce";
import { useNumberInput } from "hooks/use-inputs";
import { useTimeInput } from "hooks/use-inputs";
import { useSingleDate } from "hooks/use-inputs";

import {
  initiateSocket,
  joinCommerceSocket,
  disconnectSocket,
  addSocketListener,
  commerceDigitizeTicket,
  markTicketAsPaid,
  calculateTicketAmount,
  removeSocketListener,
} from "services/websockets";

import {
  usersAutocomplete,
  commerceTicketsAvailability,
  editCommerce,
} from "services/api";

//import { getTicketAmount } from "services/ticket-service";

//import { debouncer_timeout } from "assets/strings/constants";
import { MposCommerceDigitizeView } from "./components/commerce-digitize-view";

const MposCommerceDigitize = (props) => {
  let commerce = props.selectedCommerce;

  let building = props.selectedBuilding
    ? props.selectedBuilding
    : commerce.building;

  let buildingName =
    building && building.commercial_name ? building.commercial_name : null;
  let commerceIsPrepaid =
    commerce && commerce.parking && commerce.parking.billing_type === "prepaid";
  const canDigitize = commerce.can_digitize && building.can_digitize;
  let prepaidTickets =
    commerce &&
    commerce.parking &&
    commerce.parking.hasOwnProperty("prepaid_tickets")
      ? commerce.parking.prepaid_tickets
      : 0;
  const initialState = {
    commerce,
    building,
    token: props.token,
    buildingName,
    prepaidTickets,
    commerceIsPrepaid,
    searchParam: "",
    users: [],
    selectedUser: null,
    generatedTicket: null,
    premiumObj: null,
    toShow: "home",
    autoPaid: true,
    checkAvailability: false,
    amountReceived: false,
    amount: null,
    pdFee: null,
    commerceFee: null,
    subTotal: null,
    tax: null,
    taxPercent: null,
    totalAmount: null,
    message: "",
    errorType: "current",
    updateCommerce: false,
    searchUser: false,
    canDigitize,
    //
  };

  const [state, changeState] = useStateManager(initialState);
  const {
    setValue: setTicketNumber,
    bind: { onChange: handleTicketNumber, value: ticketNumber },
  } = useNumberInput("");
  const {
    date: ticketDate,
    handleDate: handleTicketDate,
    setDate: setTicketDate,
  } = useSingleDate(null);

  const {
    hour: ticketHour,
    setHour: setTicketHour,
    handleHour: handleTicketHour,
    minute: ticketMinute,
    setMinute: setTicketMinute,
    handleMinute: handleTicketMinute,
  } = useTimeInput({ initialHour: "", initialMinute: "" });

  let digitizeTicketTimeout = null;
  let markAsPaidTimeout = null;
  let calculateAmountTimeout = null;

  const [viewType, setViewType] = useViewType("desktop");
  /*   const debouncedSearchParam = useDebounce(
    //no used for now
    state.searchParam,
    debouncer_timeout - 600
  );
 */
  //0) listener for all sockets
  useEffect(() => {
    initiateSocket();
    joinCommerceSocket(state.token, (socket_res) => {
      console.log(socket_res.message);
    });
    addSocketListener("digitized ticket", (socket_res) => {
      if (socket_res.error) {
        console.log({ socket_res, error: "error" });
        clearTimeout(digitizeTicketTimeout);
        // show error popup
        changeState({
          toShow: "error",
          message:
            socket_res.description || "Problemas para conectar con el servidor",
        });
      }
      if (socket_res.ticket) {
        console.log({ socket_res });
        let ticket = socket_res.ticket;
        let premiumObj = null;
        let toShow = ticket.status === "ready" ? "validate" : "success";
        let message =
          ticket.status === "ready"
            ? ""
            : "ticket digitalizado y habilitado exitosamente";
        clearTimeout(digitizeTicketTimeout);
        if (
          socket_res.premium_is_active &&
          socket_res.remaining_premium_days >= 0 &&
          socket_res.remaining_tickets_per_day >= 0
        ) {
          toShow = "success";
          message =
            "Este usuario es premium y ya su ticket fue habilitado automaticamente";
          premiumObj = {
            active: socket_res.premium_is_active,
            remainingPremiumDays: socket_res.remaining_premium_days,
            remainingTicketsPerDay: socket_res.remaining_tickets_per_day,
          };
        }
        // If it is on the correct state, proceed
        changeState({
          toShow,
          message,
          generatedTicket: socket_res.ticket,
          premiumObj,
          updateCommerce: true,
        });
      }
    });
    // --> Set 'mark as paid' event listener
    addSocketListener("mark as paid", (socket_res) => {
      if (socket_res.error) {
        // Remove timeout
        clearTimeout(markAsPaidTimeout);
        // remove confirmation popup and then show error popup
      }
      if (socket_res.message) {
        // Remove timeout
        clearTimeout(markAsPaidTimeout);

        if (!socket_res.commerce) {
          // When a ticket is validated by a commerce
          changeState({ updateCommerce: true });
          // Set the commerce as selectedCommerce in redux store
        }
        changeState({
          ...initialState,
          toShow: "success",
          message: "Ticket Habilitado exitosamente",
        });
      }
    });
    // --> Set 'calculate amount' event listener
    addSocketListener("calculate amount", (socket_res) => {
      clearTimeout(calculateAmountTimeout);
      changeState({
        amountReceived: true,
        amount: socket_res.amount,
        pdFee: socket_res.pd_fee,
        commerceFee: socket_res.commerce_fee,
        subTotal: socket_res.sub_total,
        tax: socket_res.tax,
        taxPercent: socket_res.tax_percent,
        totalAmount: socket_res.total_amount,
      });
    });

    setViewType();
    window.addEventListener("resize", setViewType);
    document.addEventListener("keydown", onEnter);
    return () => {
      window.removeEventListener("resize", setViewType);
      document.removeEventListener("keydown", onEnter);
      removeSocketListener();
      disconnectSocket();
    };
  }, []);

  // 1) Search inpurt
  useEffect(() => {
    const searchUsers = async () => {
      try {
        let users = [];
        changeState({ toShow: "loader" });
        const response = await usersAutocomplete(state.searchParam);
        const res = await response.json();

        if (res.error) throw res.error;
        if (res.users) users = res.users;
        changeState({
          toShow: "users",
          users,
        });
      } catch (error) {
        changeState({
          toShow: "error",
          message: "Ha ocurrido un error, intente nuevamente",
        });
        if (
          error.id === "NO_TOKEN_PROVIDED" ||
          error.id === "AUTHENTICATE_FAILED" ||
          error.id === "DUPLICATE_SESSION"
        ) {
          changeState({
            ...initialState,
            toShow: "error",
            errorType: "current",
            message: "Error de autenticación, inicie sesión nuevamente",
          });
        } else {
          changeState({
            ...initialState,
            toShow: "error",
            errorType: "current",
            message: error.description,
          });
        }
      }
    };
    if (state.searchUser) {
      searchUsers();
      changeState({ searchUser: false });
    }
    return () => {};
  }, [state.searchUser]);

  //2) Check if there is a ticket
  useEffect(() => {
    const checkAvailabilityFuncion = async () => {
      try {
        const response = await commerceTicketsAvailability(
          state.selectedUser._id
        );
        const res = await response.json();
        if (res.error) {
          if (res.error.id === "NOT_TICKET_ACTIVE" && state.canDigitize) {
            changeState({ toShow: "digitize" });
          } else if (
            res.error.id === "NOT_TICKET_ACTIVE" &&
            !state.canDigitize
          ) {
            changeState({
              ...initialState,
              toShow: "error",
              errorType: "current",
              message: "Comercio no autorizado para digitalizar",
            });
          } else {
            if (
              res.error.id === "NO_TOKEN_PROVIDED" ||
              res.error.id === "AUTHENTICATE_FAILED" ||
              res.error.id === "DUPLICATE_SESSION"
            ) {
              changeState({
                ...initialState,
                toShow: "error",
                errorType: "current",
                message:
                  "Ha ocurrido un error de autenticación, inicie sesión nuevamente",
              });
              return () => {}; // prevent setState exeution on unmounted component
            } else {
              changeState({
                ...initialState,
                toShow: "error",
                errorType: "current",
                message: res.error.description,
              });
            }
          }
        } else {
          if (res.ticket && res.ticket.status === "ready") {
            changeState({ toShow: "validate", generatedTicket: res.ticket });
          } else {
            let error = {
              description: "Este usuario ya posee un ticket listo para salir",
            };
            throw error;
          }
        }
      } catch (e) {
        changeState({
          ...initialState,
          toShow: "error",
          message: e.description
            ? e.description
            : "Ha ocurrido un error, verifique su conexión a internet",
        });
      }
    };
    if (state.checkAvailability) {
      checkAvailabilityFuncion();
      changeState({ checkAvailability: false });
    }
    return () => {};
  }, [state.checkAvailability]);

  //3) Event after obtain ticket amount
  useEffect(() => {
    if (state.amountReceived) {
      changeState({ amountReceived: false });
      markTicketAsPaid(props.token, state.generatedTicket);
    }
  }, [state.amountReceived]);

  //4) Update commerce
  useEffect(() => {
    const commerceInfo = async () => {
      try {
        let name = state.commerce.name;
        let phone = state.commerce.phone;
        let address = state.commerce.address;
        let doc_id = state.commerce.identification.doc_id;
        let doc_type = state.commerce.identification.doc_type;
        let photo_url = state.commerce.photo_url;
        const response = await editCommerce({
          name,
          phone,
          address,
          doc_id,
          doc_type,
          photo_url,
        });
        const res = await response.json();
        if (res.error) throw res.error;
        props.addCommerces([res.commerce]); // Add commerce updated to redux store
        props.selectCommerce(res.commerce._id);
      } catch (error) {
        console.log(error);
      }
    };
    if (state.updateCommerce) {
      changeState({ updateCommerce: false });
      commerceInfo();
    }
  }, [state.updateCommerce]);

  //   methods

  const handleSearchInputChange = (event) => {
    let value = event.target.value;
    // The field is restrcted to accept only letters, numbers, @(first position) and _-.
    if (value.match(/^(?=@?.*)@?[\w.-]*$/)) {
      value = value.toLowerCase();
      // call the API in a debounced way
      changeState({
        ...initialState,
        searchParam: value,
      });
    }
  };

  const handleUserSelection = (event) => {
    let index = event.currentTarget.id;
    let selectedUser = state.users[index];
    changeState({
      toShow: "loader",
      selectedUser,
      checkAvailability: true,
    });
    setTicketNumber("");
    setTicketDate(null);
    setTicketHour("");
    setTicketMinute("");
  };

  const handleAutoPaid = (event) => {
    event.preventDefault();
    changeState({ autoPaid: !state.autoPaid });
  };

  const handleUserSearch = (event) => {
    event.preventDefault();
    changeState({ searchUser: true });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let building = state.building._id ? state.building._id : state.building;
    let date = moment(
      `${moment(ticketDate).format("YYYY/MM/DD")} ${ticketHour}:${ticketMinute}`
    );
    if (date.isAfter(moment())) {
      changeState({
        toShow: "error",
        errorType: "bad-input",
        message: "La hora ingresada es superior a la hora actual",
      });
    } else {
      let newTicketData = {
        ticket_reference: ticketNumber,
        customer: state.selectedUser._id,
        building,
        enter_at: moment(
          `${moment(ticketDate).format(
            "YYYY/MM/DD"
          )} ${ticketHour}:${ticketMinute}`
        ).format(),
      };

      changeState({ toShow: "loader" });
      if (
        state.commerceIsPrepaid &&
        state.prepaidTickets <= 0 &&
        state.autoPaid
      ) {
        changeState({
          toShow: "error",
          errorType: "no-tickets",
          message: "No dispone de más tickets para brindarle a sus clientes",
        });
      } else {
        commerceDigitizeTicket(props.token, newTicketData, state.autoPaid);
      }
    }
  };

  const handleContinue = (event) => {
    event.preventDefault();
    changeState(initialState);
  };

  const handleBack = (event) => {
    event.preventDefault();
    changeState({ toShow: "digitize" });
  };

  const handleValidate = (event) => {
    event.preventDefault();
    changeState({ toShow: "loader" });
    if (
      (state.commerceIsPrepaid &&
        state.prepaidTickets <= 0 &&
        state.autoPaid) ||
      (state.commerceIsPrepaid && state.prepaidTickets <= 0)
    ) {
      changeState({
        toShow: "error",
        errorType: "no-tickets",
        message: "No dispone de más tickets para brindarle a sus clientes",
      });
    } else {
      calculateTicketAmount(
        state.generatedTicket
          ? state.generatedTicket.building._id
          : state.building._id,
        state.generatedTicket,
        props.token
      );
    }
  };

  const onEnter = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      handleUserSearch(event);
    }
  };

  return (
    <MposCommerceDigitizeView
      viewType={viewType}
      toShow={state.toShow}
      canDigitize={state.canDigitize}
      commerceIsPrepaid={state.commerceIsPrepaid}
      selectedUser={state.selectedUser}
      searchParam={state.searchParam}
      users={state.users}
      generatedTicket={state.generatedTicket}
      autoPaid={state.autoPaid}
      ticketHour={ticketHour}
      ticketMinute={ticketMinute}
      ticketDate={ticketDate}
      handleAutoPaid={handleAutoPaid}
      handleContinue={handleContinue}
      handleTicketDate={handleTicketDate}
      handleTicketHour={handleTicketHour}
      handleTicketMinute={handleTicketMinute}
      handleBack={handleBack}
      handleSearchInputChange={handleSearchInputChange}
      ticketNumber={ticketNumber}
      handleTicketNumber={handleTicketNumber}
      handleUserSelection={handleUserSelection}
      handleUserSearch={handleUserSearch}
      handleSubmit={handleSubmit}
      handleValidate={handleValidate}
      message={state.message}
      errorType={state.errorType}
    />
  );
};

const mapStateToProps = (state) => {
  let selectedBuilding = getSelectedBuildingFromRedux(state);
  let selectedCommerce = getSelectedCommerceFromRedux(state);
  return { selectedBuilding, selectedCommerce };
};

const mapDispatchToProps = {
  addCommerces,
  selectCommerce,
  selectBuilding,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MposCommerceDigitize);
