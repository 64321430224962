import React from 'react'
import PropTypes from 'prop-types'
import UserListItem from '../user-list-item'
import defaultUserGrey from 'assets/img/default-user-grey.png'

const UserListItemModel = ({ 
  username,
  email,
  idNumber,
  profileImg,
  index,
  handleUserSelection,
  className
}) => {
  profileImg = profileImg ? profileImg : defaultUserGrey
  return (
    <dd 
      id={index}
      onClick={handleUserSelection}
      className={className}>
        <img className='img' src={profileImg} alt='user avatar' />
        <div className='data'>
          <p className='username'>@{username}</p>
          <p className='email'>{email}</p>
          <p className='idNumber'>{idNumber}</p>
        </div>
    </dd>
  )
}

function formatUserList(users, viewState, handleUserSelection) {
  let formatUserList = [];
  if (viewState === 'user_search_loading') {
    return <div className='ui active loader'></div>
  }
  if (viewState === 'user_search_done' && !users.length) {
    return <div className='not-found'>No se han encontrado usuarios</div>
  }
  if (viewState === 'user_search_done'
    || viewState === 'user_selection'
    || viewState === 'user_selection_done'
    || viewState === 'recharge_confirmation'
    || viewState === 'recharge_loading'
    || viewState === 'recharge_done') {

    users.forEach((user, index) => {
      let idNumber = ''
      if (user.profile 
      && user.profile.identification
      && user.profile.identification.doc_type
      && user.profile.identification.doc_id) {
        idNumber = user.profile.identification.doc_type + '-' + user.profile.identification.doc_id
      }
      formatUserList.push(
        <UserListItem
          index={index}
          username={user.username}
          email={user.email}
          idNumber={idNumber}
          handleUserSelection={handleUserSelection}
          key={user._id} />
      )
    })

    return formatUserList
  }
}

UserListItemModel.propTypes = {
  username: PropTypes.string,
  email: PropTypes.string,
  idNumber: PropTypes.string,
  profileImg: PropTypes.string,
  index: PropTypes.number
}

export default UserListItemModel
export { formatUserList }