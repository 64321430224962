import React, { useContext } from "react";
import { UsersExtensionsContext } from "../../context";
import { Panel } from "components/panel";
import { formatPrice } from "services/utils";
import { Icon } from "semantic-ui-react";
import { grey_4 } from "assets/strings/colors";

const MovementDetailsModel = ({ className }) => {
  const { state, handleBack } = useContext(UsersExtensionsContext);
  const movement = state.selectedMovement;
  const phoneView = state.viewPort === "phone";

  let name = "Usuario";
  if (state.extensions.length && movement) {
    state.extensions.forEach((extension) => {
      if (extension._id === movement.child) name = extension.name;
    });
  }
  const commerceCounterpart =
    movement &&
    movement.commerce_counterpart &&
    movement.commerce_counterpart.short_name
      ? movement.commerce_counterpart.short_name
      : "--";
  const counterpart =
    movement && movement.counterpart && movement.counterpart.username
      ? movement.counterpart.username
      : "--";
  const description =
    movement && movement.description ? movement.description : "--";
  const funds = movement && movement.funds ? formatPrice(movement.funds) : "--";
  const prevFunds =
    movement && movement.previous_funds
      ? formatPrice(movement.previous_funds)
      : "--";
  const method = movement && movement.method ? movement.method : "--";
  const reference = movement && movement.reference ? movement.reference : "--";

  if (state.viewState !== "movement_selected") return null;
  return (
    <div className={className}>
      <Panel className="info">
        <div className="header">
          {phoneView && (
            <Icon
              name="arrow left"
              onClick={handleBack}
              style={{
                display: "flex",
                alignItem: "left",
                float: "left",
                color: grey_4,
                cursor: "pointer",
                zIndex: 1,
              }}
            />
          )}
          <p> Detalle del Movimiento </p>
        </div>
        <p className="title">Responsable:</p>
        <p className="content">{name}</p>
        <p className="title">Contraparte Comercio:</p>
        <p className="content">{commerceCounterpart}</p>
        <p className="title">Contraparte Natural:</p>
        <p className="content">{counterpart}</p>
        <p className="title">Descripción:</p>
        <p className="content">{description}</p>
        <p className="title">Fondos Actuales:</p>
        <p className="content">{funds}</p>
        <p className="title">Fondos Previos:</p>
        <p className="content">{prevFunds}</p>
        <p className="title">Método:</p>
        <p className="content">{method}</p>
        <p className="title">Referencia:</p>
        <p className="content">{reference}</p>
      </Panel>
    </div>
  );
};

export { MovementDetailsModel };
