import React from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Button } from "components/buttons";
import { SwitchSlider } from "components/checkboxes";

const SearchTicketsFormModel = ({
  searchType,
  searchParam,
  fromDate,
  endDate,
  gift,
  regular,
  premium,
  monthlyPremium,
  ready,
  paid,
  closed,
  discarded,
  closedPremiumNoTransaction,
  closedSuperadmin,
  creationRule,
  handleTicketCreation,
  handleSearchTypeButtons,
  handleSearchInputChange,
  handleTypesButton,
  handleStatusButton,
  handleSubmit,
  handleFromDate,
  handleEndDate,

  handleBuildingSearch,
  buildingName,
  buildingSearch,
  category,
  newUsersRule,
  handleNewUsers,
  phoneView,
  className,
}) => {
  const showFilter =
    category === "pagodirecto" || category === "estacionamiento";
  return (
    <div className={className}>
      {category === "pagodirecto" && (
        <SwitchSlider
          margin="0 0 .5rem 0"
          selected={buildingSearch}
          options={[
            { data: "all_buildings", displayData: "Todos los edificios" },
            { data: "this_building", displayData: buildingName },
          ]}
          handleSelection={handleBuildingSearch}
        />
      )}
      <div className="selectors">
        <p>
          Filtrar
          <br /> busqueda por:
        </p>
        <Button
          color={searchType === "dateRange" ? "blue" : "grey_1"}
          pressed={searchType === "dateRange" ? true : false}
          value="dateRange"
          onClick={handleSearchTypeButtons}
        >
          fecha
        </Button>
        <Button
          color={searchType === "ticket#" ? "blue" : "grey_1"}
          pressed={searchType === "ticket#" ? true : false}
          value="ticket#"
          onClick={handleSearchTypeButtons}
        >
          #ticket / usuario
        </Button>
      </div>
      <form onSubmit={handleSubmit}>
        <div
          className={`ui transparent labeled input ${
            searchType === "dateRange" ? "hidden" : ""
          }`}
        >
          <i aria-hidden="true" className="search icon label"></i>
          <input
            placeholder="Buscar Ticket"
            type="text"
            value={searchParam}
            onChange={handleSearchInputChange}
          />
        </div>
        <div
          className={`date-pickers ${searchType === "ticket#" ? "hidden" : ""}`}
        >
          <DatePicker
            className="date-picker"
            placeholderText="Desde"
            dateFormat="DD / MM / YYYY"
            showMonthDropdown
            showYearDropdown
            todayButton={"Hoy"}
            isClearable
            withPortal={phoneView}
            selected={fromDate}
            maxDate={endDate ? moment(endDate).subtract(23, "hours") : moment()}
            onChange={handleFromDate}
          />
          /
          <DatePicker
            className="date-picker"
            placeholderText="Hasta"
            dateFormat="DD / MM / YYYY"
            showMonthDropdown
            showYearDropdown
            todayButton={"Hoy"}
            isClearable
            withPortal={phoneView}
            selected={endDate}
            minDate={fromDate ? moment(fromDate).add(23, "hours") : null}
            maxDate={moment()}
            onChange={handleEndDate}
          />
        </div>

        {showFilter && (
          <div className="selectors">
            <Button
              color={regular ? "blue" : "grey_1"}
              pressed={regular ? true : false}
              name="regular"
              onClick={handleTypesButton}
            >
              regulares
            </Button>
            <Button
              color={gift ? "blue" : "grey_1"}
              pressed={gift ? true : false}
              name="gift"
              onClick={handleTypesButton}
            >
              especiales
            </Button>
            <Button
              color={premium ? "blue" : "grey_1"}
              pressed={premium ? true : false}
              name="premium"
              onClick={handleTypesButton}
            >
              premium
            </Button>
            <Button
              color={monthlyPremium ? "blue" : "grey_1"}
              pressed={monthlyPremium ? true : false}
              name="monthly_premium"
              onClick={handleTypesButton}
            >
              fijos
            </Button>
          </div>
        )}
        {showFilter && (
          <SwitchSlider
            margin="0 0 .5rem 0"
            selected={creationRule}
            options={[
              { data: "all_tickets", displayData: "Todos" },
              { data: "digitized_tickets", displayData: "Digitalizados" },
              { data: "normal_tickets", displayData: "Creados" },
            ]}
            handleSelection={handleTicketCreation}
            zIndex={0}
          />
        )}
        {category === "pagodirecto" && searchType === "dateRange" && (
          <SwitchSlider
            margin="0 0 .5rem 0"
            selected={newUsersRule}
            options={[
              { data: "all_users", displayData: "Todos los usuarios" },
              { data: "new_users", displayData: "Nuevos usuarios" },
            ]}
            handleSelection={handleNewUsers}
            zIndex={0}
          />
        )}
        {showFilter && (
          <div className="selectors">
            <Button
              color={ready ? "blue" : "grey_1"}
              pressed={ready ? true : false}
              name="ready"
              onClick={handleStatusButton}
            >
              por pagar
            </Button>
            <Button
              color={paid ? "blue" : "grey_1"}
              pressed={paid ? true : false}
              name="paid"
              onClick={handleStatusButton}
            >
              pagados
            </Button>
            <Button
              color={closed ? "blue" : "grey_1"}
              pressed={closed ? true : false}
              name="closed"
              onClick={handleStatusButton}
            >
              cerrados
            </Button>

            {/*
      No used for now       
      <Button
              color={closedPremiumNoTransaction ? "blue" : "grey_1"}
              pressed={closedPremiumNoTransaction ? true : false}
              name="closed_premium_no_transaction"
              onClick={handleStatusButton}
            >
              utilizados (fijos)
            </Button> */}
            <Button
              color={closedSuperadmin ? "blue" : "grey_1"}
              pressed={closedSuperadmin ? true : false}
              name="closed_superadmin"
              onClick={handleStatusButton}
            >
              habilitados por ATC
            </Button>

            <Button
              color={discarded ? "blue" : "grey_1"}
              pressed={discarded ? true : false}
              name="discarded"
              onClick={handleStatusButton}
            >
              descartados
            </Button>
          </div>
        )}
        <Button color="grey" className="submit-button" type="submit">
          Buscar
        </Button>
      </form>
    </div>
  );
};

export default SearchTicketsFormModel;
