import styled from "styled-components";
import { ActivateConfirmationModel } from "./model";

const ActivateConfirmation = styled(ActivateConfirmationModel)`
  #root & {
    margin-top: 1rem;
    text-align: center;
    font-size: 1rem;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    > .info {
      > .ticket-number {
        > span {
          color: #63c0e8;
          font-size: 1rem;
          text-align: center;
        }
      }
      > .username {
        > span {
          font-size: 1rem;
          text-align: center;
        }
      }
    }
    > .dropdown {
      floating: left;
      margin-top: 2rem;
      z-index: 400;
    }
  }
`;

export { ActivateConfirmation };
