import styled from "styled-components";
import { CommerceMovementsFormModel } from "./model";
import { grey_3, grey_6, grey_4 } from "assets/strings/colors";

const CommerceMovementsForm = styled(CommerceMovementsFormModel)`
  #root & {
    > .collapsed.false {
      max-height: 600px;
    }
    > .collapsed {
      max-height: 0;
      transition: max-height 450ms linear;
      > .selectors {
        display: flex;
        margin: 0.7rem 0 0 0;
        align-items: center;
        justify-content: space-between;
        > button {
          font-size: 0.85rem;
          margin: 0 0.2rem 0 0.2rem;
        }
        > button:first-child {
          margin-left: 0;
        }
        > button:last-child {
          margin-right: 0;
        }
      }
      > .filters {
        display: flex;
        margin: 0;
        margin-top: 1rem;
        align-items: center;

        > .user {
          width: 50%;
          margin-left: 1rem;
          display: flex;
          height: 3rem;
          font-size: 1.2rem;
          border: 1px solid ${grey_4};
          border-radius: 1rem;
          .input-tag {
            margin-top: 0.25rem;
            margin-left: 0.5rem;
            height: 2rem;
            max-width: calc(100% - 60px);
            ::placeholder {
              overflow-wrap: normal;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .search.icon.label {
            color: ${grey_4};
            margin-left: 1rem;
          }
        }
      }
      > .date-pickers {
        margin: 0;

        margin-top: 0.7rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        > p {
          margin: 0;
          font-weight: bold;
        }
        > div {
          width: 46%;
          border-bottom: solid 1px ${grey_4};
          > .react-datepicker__input-container {
            width: 100%;
            > input {
              width: calc(100% - 16px);
              border: none;
              border-radius: 1rem;
              color: ${grey_6};
              font-size: 1.2rem;
              margin: 0.5rem 5px 0.5rem 5px;
              text-align: center;
              outline: none;
              box-shadow: none;
              transition: box-shadow 250ms linear;
              :focus {
                box-shadow: 0 1px darkgrey;
              }
            }
            > .react-datepicker__close-icon::after {
              right: 0;
            }
          }
          > .react-datepicker-wrapper {
            width: 100%;
            > .react-datepicker__input-container {
              width: 100%;
              > input {
                width: calc(100% - 16px);
                border: none;
                border-radius: 1rem;
                color: ${grey_6};
                font-size: 1.2rem;
                margin: 0.5rem 5px 0.5rem 5px;
                text-align: center;
                outline: none;
                box-shadow: none;
                transition: box-shadow 250ms linear;
                :focus {
                  box-shadow: 0 1px darkgrey;
                }
              }
              > .react-datepicker__close-icon::after {
                right: 15px;
              }
            }
          }
        }
        > div:last-child {
          > .react-datepicker-popper {
            > .react-datepicker {
              > .react-datepicker__triangle {
                right: 50px;
                left: auto;
              }
            }
          }
        }
      }
      > button {
        margin: 0.7rem 0 0 0;
      }
    }
    > .collapse-button {
      text-align: center;
      margin-top: 0.5rem;
      height: 2rem;
      line-height: 2rem;
      border-bottom: 1px solid ${grey_3};
    }
  }
`;

export { CommerceMovementsForm };
