import { NewUserFormModel } from "./model";
import styled from "styled-components";
import { textualBlue, bluePD } from "assets/strings/colors";

const NewUserForm = styled(NewUserFormModel)`
  #root && {
    justify-content: center;
    align-items: center;
    position: relative;

    > .register {
      > .title {
        color: ${textualBlue};
        margin: 0;
        margin-top: 2rem;
        margin-bottom: 4rem;
        font-size: 1.5rem;
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
      }
      > .register-form {
        border-radius: 1rem;
        left: 15%;
        width: 70%;
        align-items: center;
        & > .segment {
          display: flex;
          align-items: center;
          width: 100%;
          height: 9.15vh;
          min-height: 48px;
          font-size: 1rem;
          padding-left: 2rem;
        }
        & .label {
          background: transparent;
          color: ${bluePD};
          padding: 0;
          text-align: center;
          font-size: 1.5rem;
          margin-right: 1em;
          .menu {
            border-radius: 1rem;
          }
        }
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none !important;
          margin: 0 !important;
          -moz-appearance: textfield !important;
        }

        & :first-child {
          border-radius: 1rem 1rem 0 0;
        }
        & :last-child {
          border-radius: 0 0 1rem 1rem;
        }
        & > :last-child.weak {
          border-bottom: 5px solid #ff2f1a;
        }
        & > :last-child.medium {
          border-bottom: 5px solid #ffb413;
        }
        & > :last-child.secure {
          border-bottom: 5px solid #79fa00;
        }
        /* For password confirmation on forgot-password */
        & > :last-child.confirmed::after {
          content: "\\2713";
          font-size: 1.3rem;
          color: green;
          font-weight: bold;
        }
      }
      > .selectors {
        padding-top: 1rem;
        position: absolute;
        width: 55%;
        top: 100%;
        left: 22%;
      }
    }

    .register.register-appear {
      opacity: 0;
      transform: translateX(-30px);
    }
    .register.register-appear-active {
      opacity: 0.8;
      transform: translateX(2px);
      transition: all 350ms ease-out;
    }
    .register.register-enter {
      opacity: 0;
      transform: translateX(-30px);
    }
    .register.register-enter-active {
      opacity: 0.8;
      transform: translateX(2px);
      transition: all 300ms ease-out;
    }
    .register.register-enter-done {
      opacity: 1;
      transform: translateX(0);
      transition: all 50ms ease-out;
    }
    .register.register-exit {
      opacity: 0.8;
      transform: translateX(-2px);
    }
    .register.register-exit-active {
      opacity: 0;
      transform: translateX(30px);
      transition: all 350ms ease-out;
    }
  }
`;

export { NewUserForm };
