import React from "react";
import { NavLink } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import { NewBlueRoundButton, WhiteRoundButton } from "components/round-button";
import LogoBanner from "./components/banner";
//import ImgIsotipo from "./components/isotipo";
import ImgLogotipo from "./components/logotipo";
//import isotipo from "assets/img/isotipo-pd-blanco.png";
//import logotipo from "assets/img/logotipo-pd-blanco.png";
import logotipo from "assets/img/Logo_Blanco.png";
import "semantic-ui-css/semantic.min.css";
import { clearReduxStore } from "redux/actions/general";
import { connect } from "react-redux";

const Splash = (props) => {
  //props.clearReduxStore();

  return (
    <div>
      <LogoBanner>
        {/*<ImgIsotipo src={isotipo} alt="Logo PagoDirecto" />*/}
        <ImgLogotipo src={logotipo} alt="Logo PagoDirecto" />
      </LogoBanner>

      <Grid centered padded className="splash-buttons">
        <Grid.Column mobile={15} tablet={8} computer={6}>
          <NavLink to="/signup">
            <NewBlueRoundButton margins="4vh 0 4vh 0" fluid>
              Crear Cuenta
            </NewBlueRoundButton>
          </NavLink>
          <NavLink to="/signin">
            <WhiteRoundButton fluid>Iniciar Sesión</WhiteRoundButton>
          </NavLink>
        </Grid.Column>
      </Grid>
    </div>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = {
  clearReduxStore,
};

export default connect(mapStateToProps, mapDispatchToProps)(Splash);
