import React from "react";
import moment from "moment";
import "moment/locale/es-us";
import okCircleIcon from "assets/img/ok-circle-icon-64px.png";
import excamCircleIcon from "assets/img/exclam-circle-icon-64px.png";
import { formatPrice } from "services/utils";
import {
  MovementListItem,
  MovementPhoneListItem,
  MovementListHeader,
  NoMovementFound,
} from "../movements-list-item";

moment.locale("es-us");

export const MovementListItemModel = ({
  handleSelection,
  date,
  type,
  amount,
  //concept,
  reference,
  index,
  className,
}) => {
  const typeMapped = type === "Crédito" ? "credit" : "debit";
  const typeComponents = {
    credit: {
      icon: <img src={okCircleIcon} alt="credit" />,
      label: <p className="credit">Crédito</p>,
    },
    debit: {
      icon: <img src={excamCircleIcon} alt="debit" />,
      label: <p className="debit">Débito</p>,
    },
  };
  const typeComponentToShow =
    typeComponents[typeMapped] || typeComponents.credit;
  const stringAmount = `${formatPrice(amount)} Bs.S`;
  return (
    <dd id={index} className={className} onClick={handleSelection}>
      <div className="period-section">
        <p className="date">{date}</p>
      </div>
      <p className="amount">{stringAmount}</p>
      <div className="type">
        {typeComponentToShow.icon}
        {typeComponentToShow.label}
      </div>
      <div className="ref">
        <p>{reference}</p>
      </div>
    </dd>
  );
};

export const MovementPhoneListItemModel = ({
  handleSelection,
  date,
  type,
  amount,
  //concept,
  reference,
  index,
  className,
}) => {
  const typeMapped = type === "Crédito" ? "credit" : "debit";
  const typeComponents = {
    credit: <img src={okCircleIcon} alt="approved" className="type" />,
    debit: <img src={excamCircleIcon} alt="rejected" className="type" />,
  };
  const typeComponentToShow =
    typeComponents[typeMapped] || typeComponents.credit;
  const stringAmount = `${formatPrice(amount)} Bs.S`;

  return (
    <dd id={index} className={className} onClick={handleSelection}>
      {typeComponentToShow}
      <p className="title">Monto</p>
      <p>{stringAmount}</p>
      <p className="title">Fecha</p>
      <p>{date}</p>
      <p className="title">Referencia</p>
      <p>{reference}</p>
    </dd>
  );
};

export const MovementListHeaderModel = ({ className }) => {
  return (
    <dd className={className}>
      <p className="col twenty">Fecha</p>
      <p className="col twenty">Monto</p>
      <p className="col twenty">Tipo</p>
      <p className="col twenty">Referencia</p>
      <p className="col twenty">Concepto</p>
    </dd>
  );
};

export const NoMovementFoundModel = ({ className }) => {
  return <p className={className}>No se ha encontrado transacciones</p>;
};

export function formatMovementList(
  movements,
  loading,
  handleMovementSelection
) {
  // Declaramos el array que devolverá la función
  let allMovements = movements;
  //let approved = withdraw[1].approved;
  //let pending = withdraw[1].pending;

  let movementsList = [];
  let formatString = "DD [de] MMMM YYYY";
  if (loading) {
    return (
      <div
        className="ui active loader"
        style={{
          marginTop: "3rem",
          position: "relative",
        }}
      ></div>
    );
  }
  if (!(allMovements instanceof Array) || !allMovements.length) {
    return <NoMovementFound />;
  } else {
    movementsList.push(<MovementListHeader key="header" />);
    allMovements.forEach((movement, index) => {
      let date = moment(movement.date).format(formatString);
      movementsList.push(
        <MovementListItem
          handleSelection={handleMovementSelection}
          date={date}
          amount={movement.amount}
          reference={movement.reference}
          type={movement.type}
          index={index}
          key={index}
        />
      );
    });
    return movementsList;
  }
}

export function formatMovementPhoneList(
  movements,
  loading,
  handleMovementSelection
) {
  // Declaramos el array que devolverá la función
  let allMovements = movements;
  //let approved = withdraw[1].approved;
  //let pending = withdraw[1].pending;

  let movementsList = [];
  let formatString = "DD [de] MMMM YYYY";
  if (loading) {
    return (
      <div
        className="ui active loader"
        style={{
          marginTop: "3rem",
          position: "relative",
        }}
      ></div>
    );
  }
  if (!(allMovements instanceof Array) || !allMovements.length) {
    return <NoMovementFound />;
  } else {
    allMovements.forEach((movement, index) => {
      let date = moment(movement.date).format(formatString);
      movementsList.push(
        <MovementPhoneListItem
          handleSelection={handleMovementSelection}
          date={date}
          amount={movement.amount}
          reference={movement.reference}
          type={movement.type}
          index={index}
          key={index}
        />
      );
    });
    return movementsList;
  }
}
