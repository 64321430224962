import styled from "styled-components";
import { LogDetailModel } from "./model";

const LogDetail = styled(LogDetailModel)`
  margin-top: 2rem;
  height: 80vh;
  > .box {
    border-radius: 1rem;
    margin-top: 1rem;
    background-color: #e4f5ff;
    text-align: left;
    > .title {
      font-size: 1.1rem;
      text-transform: uppercase;
      font-weight: bold;
      text-align: center;
      color: #2196f3;
    }
    > div {
      > p {
        padding: 0 0.5rem 0.5rem 0.5rem;
      }
    }
  }
`;
export { LogDetail };
