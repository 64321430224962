import React, { useContext } from "react";
import { Icon } from "semantic-ui-react";
import { SuperadminBillsContext } from "../../context";
import { Button } from "components/buttons";
import { Panel } from "components/panel";
import { grey_4 } from "assets/strings/colors";
import { billingComponentServices } from "../../services";
import { Loader } from "components/loader";
import { formatPrice } from "services/utils";

const iconStyle = {
  paddingLeft: "2rem",
  paddingTop: "1rem",
  display: "flex",
  alignItems: "left",
  float: "left",
  color: grey_4,
  cursor: "pointer",
  zIndex: 1,
};

export const BillPreviewModel = ({ className }) => {
  const { state, changeState } = useContext(SuperadminBillsContext);
  const { loading, setArgs, setActivateFetch } =
    billingComponentServices.usePostBill();

  const currency = state.fiscalForm.currency;
  const onSubmit = (event) => {
    event.preventDefault();
    const isVES = state.fiscalForm.currency === "VES";
    const validLines = state.lines.filter((line) => line.isActive);
    const lines = validLines.map((line) => {
      const { description, qty, amount_bs, amount_usd, is_exempt, code } = line;
      return {
        description,
        qty: parseInt(qty),
        amount_bs: parseFloat(amount_bs.toString().replace(",", ".")),
        amount_usd: isVES
          ? 0
          : parseFloat(amount_usd.toString().replace(",", ".")),
        is_exempt,
        code,
      };
    });
    const fiscal = {
      ...state.fiscalForm,
      lines,
      exchange_usd: parseFloat(state.exchange_usd_to_bs),
    };
    setArgs(fiscal);
    setActivateFetch(true);
  };

  if (loading) return <Loader />;
  if (!state.billToPreview)
    return <div className={className}>No hay datos que mostrar</div>;

  const bill = { ...state.billToPreview };

  return (
    <Panel>
      <div className={className}>
        <Icon
          style={iconStyle}
          name="arrow left"
          onClick={(event) => {
            event.preventDefault();
            changeState({ showPreview: false });
          }}
        />
        <div className="container">
          <div className="header">
            <div className="content">
              <div className="data">
                <p>
                  Razón Social:
                  <span>{bill.Name}</span>
                </p>
              </div>
              <div className="data">
                <p>
                  Nro de Rif:
                  <span>{bill.FiscalRegistry}</span>
                </p>
              </div>
            </div>
            <div className="content">
              <div className="data">
                <p>
                  Domicilio Fiscal:
                  <span>{bill.Address}</span>
                </p>
              </div>
            </div>
            <div className="content">
              <div className="data">
                <p>
                  Teléfono:
                  <span>{bill.Phone}</span>
                </p>
              </div>
              <div className="data">
                <p>
                  Condiciones de Pago:
                  <span>Pago inmediato</span>
                </p>
              </div>
            </div>
            <div className="content">
              <p className="table header">Código</p>
              <p className="table header left">Concepto o Descripción</p>
              <p className="table header right">Cantidad</p>
              <p className="table header right">Precio Venta Unitario</p>
              <p className="table header right">Total</p>
            </div>
            {bill.Details.map((detail) => {
              return (
                <div className="content" key={detail.OperationCode || 1}>
                  <p className="table">{detail.OperationCode}</p>
                  <p className="table left">{detail.Description}</p>
                  <p className="table right">{detail.Quantity}</p>
                  <p className="table right">{formatPrice(detail.UnitPrice)}</p>
                  <p className="table right">{formatPrice(detail.Amount)}</p>
                </div>
              );
            })}
          </div>
          {currency === "USD" && (
            <div className="footer middle">
              <div className="titles usd">
                <p>BASE IMPONIBLE $</p>
                <p>BASE EXENTA $</p>
                <p>I.V.A. 16,00 %</p>
                <p>total a pagar $</p>
                <p>Tasa de Cambio</p>
              </div>
              <div className="values">
                <p>{formatPrice(bill.TaxBaseVES)}</p>
                <p>{formatPrice(bill.ExemptAmountVES)}</p>
                <p>{formatPrice(bill.TaxAmountVES)}</p>
                <p>{formatPrice(bill.TotalVES)}</p>
                <p>{formatPrice(bill.ExchangeRate)}</p>
              </div>
            </div>
          )}
          <div className="footer">
            <div className="titles">
              <p>MONTO TOTAL DE LA BASE IMPONIBLE DEL I.V.A.</p>
              <p>BASE EXENTA</p>
              <p>I.V.A. 16,00 % SOBRE</p>
              <p>I.V.A. % SOBRE</p>
              <p>total a pagar</p>
            </div>
            <div className="values">
              <p>{formatPrice(bill.TaxBase)}</p>
              <p>{formatPrice(bill.ExemptAmount)}</p>
              <p>{formatPrice(bill.TaxAmount)}</p>
              <p>{formatPrice(bill.TaxBase)}</p>
              <p>{formatPrice(bill.Total)}</p>
            </div>
          </div>
        </div>

        <Button onClick={onSubmit} color="blue">
          Generar Factura
        </Button>
      </div>
    </Panel>
  );
};
