import React, { useContext } from "react";
import { Grid } from "semantic-ui-react";
import { Loader } from "components/loader";
import { PopupMessageContext } from "components/popup-message";
import { AccountsInfo } from "./accounts-info";
import { Movements, MovementDetail } from "./movements";

import { ResponsiveColumn } from "components/responsive-column/styles";
import { WalletAccountsContext } from "../context";
import { AccountDetails, Withdraw, TransferForm } from "./options";
import { IconBack } from "components/icon-back";
export const SuperadminAccountsView = () => {
  const { state, viewType, walletAccounts, changeState, transactionTags } =
    useContext(WalletAccountsContext);

  if (state.loading) return <Loader />;

  const display = (viewState) =>
    (viewType === "phone" && state.viewState === viewState) ||
    viewType === "desktop";

  const getAccountDetails = () => {
    if (state.accountSelected)
      return walletAccounts.find(
        (account) => account._id === state.accountSelected
      );
    return null;
  };

  const handleBack = (event) => {
    event.preventDefault();
    changeState({ viewState: "default" });
  };
  return (
    <Grid padded centered>
      <PopupMessageContext context={WalletAccountsContext} />
      <ResponsiveColumn
        computer={8}
        mobile={16}
        show={display("default")}
        withBorder
      >
        <AccountsInfo />
        <Movements />
      </ResponsiveColumn>
      <ResponsiveColumn computer={8} mobile={16} show={display("detail")}>
        <div style={{ padding: "1rem", width: "100%" }}>
          <IconBack handleBack={handleBack} show={viewType === "phone"} />
        </div>
        <MovementDetail
          data={state.selectedMovement}
          transactionTags={transactionTags}
          show={state.detailToShow === "movement"}
        />
        <AccountDetails
          data={getAccountDetails()}
          show={state.detailToShow === "details"}
        />
        <Withdraw
          data={getAccountDetails()}
          show={state.detailToShow === "withdraw"}
        />
        <TransferForm
          data={getAccountDetails()}
          show={state.detailToShow === "transfer"}
        />
      </ResponsiveColumn>
    </Grid>
  );
};
