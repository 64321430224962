import React from 'react'
import { Redirect } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'
import Navbar from 'components/navbar'
import { getQueryParams } from 'services/utils'
import { TermsGeneralv1x4x0 } from './components/terms-and-conditions-general/v1-4-0'
import PrivacyPolicies from './components/privacy-policies'

const Policies = ( props ) => {
  if (props.location.search && props.location.search !== ''){
    let queryParams = getQueryParams(props.location.search)
    switch (queryParams.type) {
      case 'general':
        return (
          <div>
            <Navbar
              title={'Terminos y condiciones'}/>
            <Grid centered padded>
              <Grid.Column mobile={15} tablet={14} computer={13}>
                <TermsGeneralv1x4x0/>
              </Grid.Column>
            </Grid>
          </div>
        )
      case 'privacy-policies':
        return (
          <div>
            <Navbar
              title={'Políticas de privacidad'}/>
            <Grid centered padded>
              <Grid.Column mobile={15} tablet={14} computer={13}>
                <PrivacyPolicies />
              </Grid.Column>
            </Grid>
          </div>
        )
      default:
        return <Redirect to='not-found' />
    }
  } else {
    return <Redirect to='not-found'/>
  }
}
export default Policies