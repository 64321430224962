import { useEffect, useContext } from "react";
import { MobilePaidsContext } from "../context";
import { changeStatus } from "./api";

export const useChangeStatus = () => {
  const { state, changeState, throwPopupMessage } =
    useContext(MobilePaidsContext);

  const changeMobilePaidStatus = async () => {
    changeState({ changeStatus: false, changeStatusLoading: true });
    try {
      const response = await changeStatus({
        id: state.selectedMobilePaid._id,
        recharge_id: state.selectedMobilePaid._id,
        username: state.sendUsername ? state.username : null,
        payment_ref: state.selectedMobilePaid.reference_id,
      });
      const res = await response.json();

      if (res.error) throw res.error;
      throwPopupMessage("success", "Se cambió el estado con éxito");
      changeState({
        mobilePaids: [],
        selectedMobilePaid: null,
        submitForm: true,
      });
    } catch (error) {
      throwPopupMessage("error", error.description);
    }
    changeState({ changeStatusLoading: false });
  };

  useEffect(() => {
    if (state.changeStatus) {
      changeState({ changeStatusLoading: true }); //eliminar despues de quitar el mock
      setTimeout(() => {
        //eliminar despues de quitar el mock
        changeMobilePaidStatus();
      }, 500);
    }
  }, [state.changeStatus]);

  return null;
};
