import CounterPartSelectModel from "./model";
import styled from "styled-components";
import { bluePD } from "assets/strings/colors";

const CommerceAutocomplete = styled(CounterPartSelectModel)`
  #root & {
    ${(props) => (props.margin ? `margin: ${props.margin}` : "")};
    > p {
      margin: 0.5rem 0;
    }
    > .search-field {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      > .counterpart-select {
        width: 85%;
      }
      > .search-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        width: ${(props) => (props.width ? props.width : "10%")};
        height: 100%;
        background-color: ${bluePD};
        border: none;
        border-radius: 0.4rem;
        > .icon-search {
          margin: 0;
          padding: 0.5rem 0;
          width: 100%;
          height: 100%;
          color: white;
        }
        :hover {
          cursor: pointer;
          opacity: 0.8;
        }
      }
    }

    @media (max-width: 400px) {
      > .search-field {
        > .search {
          width: 20%;
        }
      }
    }
  }
`;

export default CommerceAutocomplete;
