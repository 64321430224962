import { useEffect, useContext, useState } from "react";
import { WalletTransferHistoryContext } from "../context";
import { getWeekendDate } from "../services";

export const useGetWeekendDate = () => {
  const { state, changeState, throwPopupMessage } = useContext(
    WalletTransferHistoryContext
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (state.waitWeekendDate) {
      getWeekendDate(changeState, setLoading, throwPopupMessage);
    }
  }, [state.waitWeekendDate]);

  return { loading };
};
