import React, { useContext } from "react";
import { Grid } from "semantic-ui-react";
import { Loader } from "components/loader";
import { PopupMessageContext } from "components/popup-message";
import { SimpleTransfer } from "./simple-transfer";
import { CompleteTransfer } from "./complete-transfer";
import { AccountsInfo } from "./accounts-info";
import { TypeofTransfer } from "./typeof-transfer";
import { Button } from "components/buttons";
import { AdminTransfersContext } from "../context";

export const AdminTransferPhoneView = () => {
  const { state, remoteLoading, handleAhead } = useContext(
    AdminTransfersContext
  );

  const transferComponents = {
    show_simple_transfer: <SimpleTransfer phoneView />,
    show_complete_transfer: <CompleteTransfer phoneView />,
  };

  if (state.loading || remoteLoading) return <Loader />;
  const transferForm = transferComponents[state.viewState];
  const rightView = state.transferLoading ? <Loader /> : transferForm;

  if (state.showRightSide)
    return (
      <Grid padded centered>
        <PopupMessageContext context={AdminTransfersContext} />
        <Grid.Column width={16}>{rightView}</Grid.Column>
      </Grid>
    );

  return (
    <Grid padded centered>
      <PopupMessageContext context={AdminTransfersContext} />
      <Grid.Column width={16}>
        <AccountsInfo />
        <TypeofTransfer phoneView />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button color="blue" onClick={handleAhead} width="40%">
            Siguiente
          </Button>
        </div>
      </Grid.Column>
    </Grid>
  );
};
