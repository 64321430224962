import React from "react";

import { Icon } from "semantic-ui-react";

const SearchModel = ({
  handleSearchInputChange,
  searchParam,
  handleSearch,
  className,
}) => {
  return (
    <div className={className}>
      <p className="title">Tickets de eventos</p>
      <div className="search">
        <input
          type="text"
          maxLength={7}
          placeholder="Ingrese la referencia del ticket"
          value={searchParam}
          onChange={handleSearchInputChange}
        />
        <Icon name="search" onClick={handleSearch} />
      </div>
    </div>
  );
};

export { SearchModel };
