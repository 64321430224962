import { reports_server, baseURL } from "services/config";
import { post, postFormData } from "services/http-methods";

/* function mockResponse() {
  const data = {
    ok: false,
    created: false,
    error: { message: "Este reporte no está disponible" },
  };
  const blob = new Blob([JSON.stringify(data)]);
  return new Response(blob);
} */
const endpoints = {
  verifiedRegisteredUsers: `${reports_server}/verified-registered-users`,
  unverifiedRegisteredUsers: `${reports_server}/unverified-registered-users`,
  userActivities: `${reports_server}/user-activities`,
  transactionsCustomer: `${reports_server}/transactions-customer`,
  servicePayment: `${reports_server}/services-payments`,
  walletRecharges: `${reports_server}/wallet-recharges`,
  transactionsCreditPremium: `${reports_server}/transactions-credit-premium`,
  premiumTicketsQtyByUser: `${reports_server}/ticketspremium-qty-amount-byuser`,
  approvedRechargesSuperadmin: `${reports_server}/approved-recharges-superadmin`,
  tickets: `${reports_server}/tickets`,
  unverifiedRegisterdUsersByOffice: `${reports_server}/verif-unverif-registered-taquilla`,
  transactionsSchools: `${reports_server}/transactions-schools`,
  parkingLogs: `${reports_server}/building-logs-entrance-exit`,
  ticketsBuilding: `${reports_server}/tickets-building-general`,
  commerces: `${baseURL}/child/info`,
  transactionsCommercesTransfers: `${reports_server}/transactions-commerces-transfers`,
  tollTickets: `${reports_server}/toll-tickets`,
  parkingMetersTickets: `${reports_server}/parkingmeters-tickets`,
};

export function verifiedRegisteredUsers(body) {
  /**
   * @brief
   * @attributes
   *  body = {
   * }
   */

  return post(endpoints.verifiedRegisteredUsers, body);
}

export function unverifiedRegisteredUsers(body) {
  /**
   * @brief
   * @attributes
   *  body = {
   * }
   */

  return post(endpoints.unverifiedRegisteredUsers, body);
}
export function userActivities(formData) {
  /**
   * @brief  to activate/extend monthly premium
   * @attributes
   *  body = {
   *         fecha_inicio:
   *         fecha_fin:
   *         archivotxt: file,
   * }
   */

  return postFormData(endpoints.userActivities, formData);
}

export function transactionsCustomer(body) {
  /**
   * @brief
   * @attributes
   *  body = {
   * }
   */

  return post(endpoints.transactionsCustomer, body);
}
export function servicePayment(body) {
  /**
   * @brief
   * @attributes
   *  body = {
   * }
   */

  return post(endpoints.servicePayment, body);
}

export function walletRecharges(body) {
  /**
   * @brief
   * @attributes
   *  body = {
   * }
   */

  return post(endpoints.walletRecharges, body);
}
export function transactionsCreditPremium(body) {
  /**
   * @brief
   * @attributes
   *  body = {
   * }
   */

  return post(endpoints.transactionsCreditPremium, body);
}

export function premiumTicketsQtyByUser(body) {
  /**
   * @brief
   * @attributes
   *  body = {
   * }
   */

  return post(endpoints.premiumTicketsQtyByUser, body);
}
export function approvedRechargesSuperadmin(body) {
  /**
   * @brief
   * @attributes
   *  body = {
   * }
   */

  return post(endpoints.approvedRechargesSuperadmin, body);
}

export function tickets(body) {
  /**
   * @brief
   * @attributes
   *  body = {
   * }
   */

  return post(endpoints.tickets, body);
}

export function unverifiedRegisteredUsersByOffice(body) {
  /**
   * @brief
   * @attributes
   *  body = {
   * }
   */

  return post(endpoints.unverifiedRegisterdUsersByOffice, body);
}

export function transactionsSchools(body) {
  /**
   * @brief
   * @attributes
   *  body = {
   * }
   */

  return post(endpoints.transactionsSchools, body);
}

export function parkingLogs(body) {
  /**
   * @brief
   * @attributes
   *  body = {
   * }
   */
  return post(endpoints.parkingLogs, body);
}

export function ticketsBuilding(body) {
  /**
   * @brief
   * @attributes
   *  body = {
   * }
   *
   */

  return post(endpoints.ticketsBuilding, body);
}

export function getCommerces(body) {
  /**
   * @brief
   * @attributes
   *  body = {
   * }
   *
   */

  return post(endpoints.commerces, body);
}

export const transactionsCommercesTransfers = (body) =>
  post(endpoints.transactionsCommercesTransfers, body);

export const tollTickets = (body) => post(endpoints.tollTickets, body);

export const parkingMetersTickets = (body) =>
  post(endpoints.parkingMetersTickets, body);
