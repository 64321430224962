import { BlueInputModel } from "./model";
import styled from "styled-components";
import { bluePD, grey_6 } from "assets/strings/colors";

export const BlueInput = styled(BlueInputModel)`
  #root & {
    margin: 0;
    padding: 0;
    width: 100%;
    > label {
      align-self: flex-start;
      border-bottom: 1px solid ${grey_6};
      color: ${grey_6};
      font-weight: bold;
    }
    > input {
      margin: 1rem;
      border: 1px solid ${bluePD};
      border-radius: 2rem;
      text-align: center;
      font-size: 1rem;
      width: 90%;
      height: 30px;
      :focus {
        outline: none;
        border: 1px solid ${bluePD};
      }
      :disabled {
        border: none;
        background: transparent;
        color: ${grey_6};
      }
    }
  }
`;
