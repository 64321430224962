import React, { PureComponent } from "react";
import { errors } from "assets/strings/texts";
import moment from "moment";
import "moment/locale/es-us";
import { connect } from "react-redux";
import { clearReduxStore } from "redux/actions/general";
import { getParkingDashboard, getParkingDashboardFilter } from "services/api";
import MposParkingDashboardView from "./components/mpos-parking-dashboard-view";
import { throwPopupMessage, closePopup } from "components/popup-message";
import { handleFromDate, handleEndDate } from "./components/filter-form";
moment.locale("es-us");

class MposParkingDashboard extends PureComponent {
  constructor(props) {
    super(props);
    let ticketsPerDay = [];
    let totalTransactions = 0;
    let dayOfPastMonth = moment({ hour: "0" }).subtract(31, "days");
    for (let i = 0; i < 31; i++) {
      ticketsPerDay.push({
        day: dayOfPastMonth.format("MMM D"),
        usedTickets: 0,
      });
      dayOfPastMonth.add(1, "days");
    }
    this.state = {
      viewState: "data_received",
      searchType: "last_month", // enum ['latests','dateRange'].
      searchFilter: "",
      fromDate: null,
      endDate: null,
      graphText: "Tickets pagados en el ultimo mes",
      rate: {
        amount: 0,
        currency: " ",
      },
      historyTickets: {
        ready: 0,
        paid: 0,
      },
      avgDuration: {
        value: 0,
        unit: " ",
      },
      usdStatus: {
        weekAmount: 0,
        todayAmount: 0,
      },
      bsStatus: {
        weekAmount: 0,
        todayAmount: 0,
      },
      ticketsPerDay,
      totalTransactions,
      popupMessages: null,
      popupMessagesType: "",
      showPopup: false,
      authError: false,
    };
    this.initialState = { ...this.state };
    // Bind PopupMessage functions
    this.handleFromDate = handleFromDate.bind(this);
    this.handleEndDate = handleEndDate.bind(this);
    // Bind PopupMessage functions
    this.throwPopupMessage = throwPopupMessage.bind(this);
    this.closePopup = closePopup.bind(this);
  }

  componentDidMount() {
    this.obtainDashboard();
  }

  obtainDashboard() {
    this.setState({ viewState: "waiting_dashboard" });
    let todayStartIso = moment({ hour: "0" }).toISOString(true);
    getParkingDashboard(todayStartIso)
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          // If it is an authentication error, clear the react-redux to close the session
          if (
            res.error.id === "NO_TOKEN_PROVIDED" ||
            res.error.id === "AUTHENTICATE_FAILED" ||
            res.error.id === "DUPLICATE_SESSION"
          ) {
            this.throwPopupMessage("alert", errors.AUTHENTICATION);
            //this.props.clearReduxStore();
            return; // prevent setState exeution on unmounted component
          }
          this.throwPopupMessage("error", res.error.description);
        } else {
          // format tax factor percent
          let taxFactor = res.tax_percent / 100 + 1;
          // format rate
          let rate = { ...res.rate };
          rate.amount = rate.amount * taxFactor;
          // format history_tickets
          let historyTickets = { ...res.history_tickets };
          // format avg_duration
          let avgDuration = { ...this.state.avgDuration };
          try {
            avgDuration = {
              value: Number.parseFloat(res.avg_duration),
              unit: res.avg_duration.split(" ")[1],
            };
          } catch (error) {
            avgDuration = {
              value: 0,
              unit: " ",
            };
          }
          // format tickets_per_day
          let resTicketsPerDay = [],
            [...ticketsPerDay] = this.state.ticketsPerDay;
          res.tickets_per_day.forEach((ticketsOfDay) => {
            resTicketsPerDay.push({
              day: moment({
                y: ticketsOfDay.date.year,
                M: ticketsOfDay.date.month - 1,
                d: ticketsOfDay.date.day,
              }).format("MMM D"),
              usedTickets: ticketsOfDay.count,
            });
          });
          ticketsPerDay.forEach((ticketsOfOneDay, index) => {
            resTicketsPerDay.forEach((resTicketsOfOneDay) => {
              if (ticketsOfOneDay.day === resTicketsOfOneDay.day) {
                ticketsPerDay[index] = {
                  ...ticketsOfOneDay,
                  usedTickets: resTicketsOfOneDay.usedTickets,
                };
              }
            });
          });
          let usdStatus = {
            todayAmount: res.usd_status.today_amount * taxFactor,
            weekAmount: res.usd_status.week_amount * taxFactor,
            funds: res.usd_status.funds,
            blocked_funds: res.usd_status.blocked_funds,
            currency: res.usd_status.currency,
          };

          let bsStatus = {
            todayAmount: res.bs_status.today_amount * taxFactor,
            weekAmount: res.bs_status.week_amount * taxFactor,
            funds: res.bs_status.funds,
            blocked_funds: res.bs_status.blocked_funds,
            currency: res.bs_status.currency,
          };
          let totalTransactions = 0;
          if (ticketsPerDay.length > 0) {
            ticketsPerDay.forEach((elem) => {
              totalTransactions += elem.usedTickets;
            });
          }

          // Apply dashboard data
          this.setState({
            viewState: "data_received",
            rate,
            historyTickets,
            avgDuration,
            ticketsPerDay,
            totalTransactions,
            usdStatus,
            bsStatus,
          });
        }
      })
      .catch((err) => {
        this.setState({ ...this.initialState });
        this.throwPopupMessage(
          "error",
          "Ha ocurrido un error, verifique su conexión a internet"
        );
      });
    // --> set viewType
    this.setViewType();

    // --> set the 'resize' event listener
    window.addEventListener("resize", this.setViewType);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setViewType);
    //removeSocketListener();
  }
  handleSearchTypeButtons = (event) => {
    if (this.state.searchFilter === "active")
      this.setState({ searchFilter: "inactive" });
    let value = event.target.value;
    if (value === "last_month") {
      this.setState({ viewState: "waiting_data" });
      let fromDate = moment({ hour: "0" })
        .subtract(31, "days")
        .toISOString(true);
      let endDate = moment({ hour: "0" }).toISOString(true);
      getParkingDashboardFilter(fromDate, endDate)
        .then((res) => res.json())
        .then((res) => {
          if (res.error) {
            // If it is an authentication error, clear the react-redux to close the session
            if (
              res.error.id === "NO_TOKEN_PROVIDED" ||
              res.error.id === "AUTHENTICATE_FAILED" ||
              res.error.id === "DUPLICATE_SESSION"
            ) {
              this.throwPopupMessage("alert", errors.AUTHENTICATION);
              //this.props.clearReduxStore();
              //return; // prevent setState exeution on unmounted component
            }
            this.throwPopupMessage("error", res.error.description);
          } else {
            let resTicketsPerDay = [],
              ticketsPerDay = [];
            let dayOfPastMonth = moment({ hour: "0" }).subtract(31, "days");
            for (let i = 0; i < 31; i++) {
              ticketsPerDay.push({
                day: dayOfPastMonth.format("MMM D"),
                usedTickets: 0,
              });
              dayOfPastMonth.add(1, "days");
            }
            let totalTransactions = 0;
            res.tickets_per_day.forEach((ticketsOfDay) => {
              resTicketsPerDay.push({
                day: moment({
                  y: ticketsOfDay.date.year,
                  M: ticketsOfDay.date.month - 1,
                  d: ticketsOfDay.date.day,
                }).format("MMM D"),
                usedTickets: ticketsOfDay.count,
              });
              totalTransactions += ticketsOfDay.count;
            });
            ticketsPerDay.forEach((ticketsOfOneDay, index) => {
              resTicketsPerDay.forEach((resTicketsOfOneDay) => {
                if (ticketsOfOneDay.day === resTicketsOfOneDay.day) {
                  ticketsPerDay[index] = {
                    ...ticketsOfOneDay,
                    usedTickets: resTicketsOfOneDay.usedTickets,
                  };
                }
              });
            });
            this.setState({
              viewState: "data_received",
              searchType: value,
              graphText: "Tickets pagados en el último mes",
              searchFilter: "inactive",
              ticketsPerDay,
              totalTransactions,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ ...this.initialState });
          this.throwPopupMessage(
            "error",
            "Ha ocurrido un error, verifique su conexión a internet"
          );
        });
    } else {
      this.setState({
        searchType: value,
        searchFilter: "inactive",
      });
    }
  };

  handleSearchFilterButtons = async (event) => {
    let value = event.target.value;
    let { fromDate, endDate } = this.state;
    if (fromDate && endDate) {
      this.setState({ viewState: "waiting_data" });
      getParkingDashboardFilter(fromDate, endDate)
        .then((res) => res.json())
        .then((res) => {
          if (res.error) {
            // If it is an authentication error, clear the react-redux to close the session
            if (
              res.error.id === "NO_TOKEN_PROVIDED" ||
              res.error.id === "DUPLICATE_SESSION" ||
              res.error.id === "AUTHENTICATE_FAILED"
            ) {
              this.throwPopupMessage("alert", errors.AUTHENTICATION);
              //this.props.clearReduxStore();
              return; // prevent setState exeution on unmounted component
            }
            this.throwPopupMessage("error", res.error.description);
          } else {
            let resTicketsPerDay = [],
              ticketsPerDay = [];
            let dayInit = moment(fromDate, "MMM D");

            for (let i = 0; i < res.days; i++) {
              ticketsPerDay.push({
                day: dayInit.format("MMM D"),
                usedTickets: 0,
              });
              dayInit.add(1, "days");
            }
            let totalTransactions = 0;
            res.tickets_per_day.forEach((ticketsOfDay) => {
              resTicketsPerDay.push({
                day: moment({
                  y: ticketsOfDay.date.year,
                  M: ticketsOfDay.date.month - 1,
                  d: ticketsOfDay.date.day,
                }).format("MMM D"),
                usedTickets: ticketsOfDay.count,
              });
              totalTransactions += ticketsOfDay.count;
            });
            ticketsPerDay.forEach((ticketsOfOneDay, index) => {
              resTicketsPerDay.forEach((resTicketsOfOneDay) => {
                if (ticketsOfOneDay.day === resTicketsOfOneDay.day) {
                  ticketsPerDay[index] = {
                    ...ticketsOfOneDay,
                    usedTickets: resTicketsOfOneDay.usedTickets,
                  };
                }
              });
            });
            this.setState({
              viewState: "data_received",
              searchFilter: value,
              graphText: "Tickets pagados en el rango de fechas seleccionado",
              ticketsPerDay,
              totalTransactions,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.setState({ ...this.initialState });
          this.throwPopupMessage(
            "error",
            "Ha ocurrido un error, verifique su conexión a internet"
          );
        });
    } else {
      this.throwPopupMessage(
        "alert",
        "El rango de fechas no debe estar vacío."
      );
      this.setState({
        searchFilter: "inactive",
      });
    }
  };

  setViewType = () => {
    let documentElement = document.documentElement,
      width = window.innerWidth || documentElement.clientWidth;
    if (width < 768) {
      this.setState({ phoneView: true });
    } else {
      this.setState({ phoneView: false });
    }
  };

  render() {
    let currency = "";
    if (this.state.rate && this.state.rate.currency)
      currency = this.state.rate.currency;

    return (
      <MposParkingDashboardView
        viewState={this.state.viewState}
        phoneView={this.state.phoneView}
        graphText={this.state.graphText}
        currency={currency}
        rate={this.state.rate}
        searchType={this.state.searchType}
        searchFilter={this.state.searchFilter}
        fromDate={this.state.fromDate}
        endDate={this.state.endDate}
        historyTickets={this.state.historyTickets}
        avgDuration={this.state.avgDuration}
        usdStatus={this.state.usdStatus}
        bsStatus={this.state.bsStatus}
        ticketsPerDay={this.state.ticketsPerDay}
        totalTransactions={this.state.totalTransactions}
        popupMessages={this.state.popupMessages}
        popupMessagesType={this.state.popupMessagesType}
        handleSearchTypeButtons={this.handleSearchTypeButtons}
        handleSearchFilterButtons={this.handleSearchFilterButtons}
        handleFromDate={this.handleFromDate}
        handleEndDate={this.handleEndDate}
        showPopup={this.state.showPopup}
        closePopup={this.closePopup}
      />
    );
  }
}

const mapStateToProps = null;

const mapDispatchToProps = {
  clearReduxStore,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MposParkingDashboard);
