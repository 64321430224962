import styled from 'styled-components'
import { InvoicePaymentListItemModel, NoInvoiceFoundModel } from './model'
import { grey_1, grey_3, grey_4, grey_5 } from 'assets/strings/colors'

const InvoicePhoneListItem = styled(InvoicePaymentListItemModel)`
  #root &{
    display: flex;
    align-items: center;
    margin: 0;
    font-size: .85rem;
    border-radius: 1rem;
    border-bottom: 1px solid ${grey_4};
    >.checkbox-container{
      cursor: pointer;
      height: 7rem;
      width: 20%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid ${grey_3};
      :hover{
        >.checkbox{
          background: rgba(8, 153, 203, .3);
        }
      }
      >.checkbox{
        height: 2rem;
        width: 2rem;
        border-radius: 100%;
        border: 1px solid ${grey_5};
        box-shadow: 0 0 0 4px inset white;
        &.checked{
          background: #0899CB;
          border: 1px solid #06749A;
        }
        &.inactive{
          background: ${grey_3};
          cursor: not-allowed;
        }
      }
    }
    >a{
      color: inherit;
      width: 100%;
      >.content{
        width: 100%;
        cursor: pointer;
        display: inline-block;
        padding: .5rem;
        border-radius: 0 1rem 1rem 0;
        :hover{
          background: ${grey_1};
        }
        >.title{
          display: inline-block;
          margin-bottom: .3rem;
          text-transform: uppercase;
          font-size: 1.1rem;
          border-bottom: 1px solid ${grey_4};
        }
        .status{
          float: right; 
          height: 3rem;
        }
        >p{
          margin: 0 0 .6rem 0;
        }
      }
    }
  }
`

const NoInvoiceFound = styled(NoInvoiceFoundModel)`
  #root &{
    text-align: center;
    font-size: 1.2rem;
    line-height: 62px;
  }
`

export { 
  NoInvoiceFound,
  InvoicePhoneListItem
}