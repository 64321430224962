import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { throwPopupMessage, closePopup } from 'components/popup-message'
import ResetPasswordView from './components/reset-password-view'
import { forgotPassword, verifyResetPasswordCode, resetPassword } from 'services/api'

class ResetPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      viewState: 'initial',
      loading: false,
      passwordStrength: '',
      formData: {
        email: '',
        code: '',
        password: '',
        confirm: '' 
      },
      popupMessages: null,
      popupMessagesType: '',
      showPopup: false,
      redirect: null
    }
    // set initial state
    this.initialState = { ...this.state }
    // Bind PopupMessage functions
    this.throwPopupMessage = throwPopupMessage.bind(this)
    this.closePopup = closePopup.bind(this)
  }

  resetView = () => {
    this.setState({ ...this.initialState })
  } 

  handleFormChange = (event) => {
    this.setState({
      formData:{
        ...this.state.formData, 
        [event.target.name]: event.target.value
      }
    })
  }

  handlePasswordChange = (event) => {
    let value = event.target.value
    let passwordStrength = 0
    if (value.match(/\d/g)) {
      passwordStrength++
    }
    if (value.match(/[A-Za-z]/g)) {
      passwordStrength++
    }
    if (value.length >= 8) {
      passwordStrength++
    }
    switch (passwordStrength) {
      case 0:
        passwordStrength = 'weak'
        break
      case 3:
        passwordStrength = 'secure'
        break
      default:
        passwordStrength = 'medium'
        break
    }

    this.setState({
      formData: {
        ...this.state.formData,
        [event.target.name]: event.target.value
      },
      passwordStrength
    })
  }

  handleEmailSubmit = (event) => {
    event.preventDefault()
    let response = forgotPassword({ email: this.state.formData.email })
    this.setState({ loading: true })
    //Assign the loading state to the form
    response.then(res => res.json())
      .then(res => {
        if(res.error){
          this.setState({ ...this.initialState }) 
          this.throwPopupMessage('error', res.error.description)
        }
        if (res.message){
          this.setState({ viewState: 'code-verification', loading: false })
          this.throwPopupMessage('success', 'El código de verificación se ha enviado exitosamente.')
        }
      })
      .catch(err => {
        this.setState({ ...this.initialState }) 
        this.throwPopupMessage('alert', 'Se ha producido un error, verifique su conexión a internet.')
      })
  }

  handleCodeSubmit = (event) => {
    event.preventDefault()
    let response = verifyResetPasswordCode(this.state.formData.email, this.state.formData.code)
    this.setState({ loading: true })
    //Assign the loading state to the form
    response.then(res => res.json())
      .then(res => {
        if (res.error) {
          this.setState({
            ...this.initialState,
            viewState: 'code-verification',
            formData: {
              ...this.state.formData,
              code: ''
            }
          })
          this.throwPopupMessage('error', res.error.description)
        }
        if (res.message) {
          this.setState({ viewState: 'password-reset', loading: false })
          this.throwPopupMessage('success', res.message)
        }
      })
      .catch(err => {
        this.setState({
          ...this.initialState,
          viewState: 'code-verification',
          formData: {
            ...this.state.formData,
            code: ''
          }
        })
        this.throwPopupMessage('alert', 'Se ha producido un error, verifique su conexión a internet.')
      })
  }

  handleResetPasswordSubmit = (event) => {
    event.preventDefault()
    let response = resetPassword(this.state.formData)
    this.setState({ loading: true })
    //Assign the loading state to the form
    response.then(res => res.json())
      .then(res => {
        if (res.error) {
          this.setState({
            ...this.initialState,
            viewState: 'password-reset',
            formData: {
              ...this.state.formData,
              password: '',
              confirm: ''
            }
          })
          this.throwPopupMessage('error', res.error.description)
        }
        if (res.message) {
          this.setState({
            redirect: < Redirect to={{
              pathname: '/signin',
              state: {
                formAlertMessageType: 'success',
                formAlertMessage: res.message
              }
            }} />,
            loading: false 
          })
        }
      })
      .catch(err => {
        this.setState({
          ...this.initialState,
          viewState: 'password-reset',
          formData: {
            ...this.state.formData,
            password: '',
            confirm: ''
          }
        })
        this.throwPopupMessage('alert', 'Se ha producido un error, verifique su conexión a internet.')
      })
  }

  render() {

    // Apply checkmark class
    let confirmationMatchClassName = ''
    if (this.state.formData.password !== '' &&
      this.state.formData.password === this.state.formData.confirm){
      confirmationMatchClassName = 'confirmed'
    }

    return ( 
      <ResetPasswordView
        // props
        viewState={this.state.viewState}
        loading={this.state.loading}
        redirect={this.state.redirect}
        match = {this.props.match}
        formData={this.state.formData}
        passwordStrength={this.state.passwordStrength}
        confirmationMatchClassName={confirmationMatchClassName}
        popupMessages={this.state.popupMessages}
        popupMessagesType={this.state.popupMessagesType}
        showPopup={this.state.showPopup}
        // methods
        closePopup = {this.closePopup}
        handleFormChange={this.handleFormChange}
        handleEmailSubmit={this.handleEmailSubmit}
        handleCodeSubmit={this.handleCodeSubmit}
        handlePasswordChange={this.handlePasswordChange}
        handleResetPasswordSubmit={this.handleResetPasswordSubmit}
        resetView={this.resetView}
        /> 
    )
  }
}

export default ResetPassword
