import React from "react";
import MtdListItem from "../mtd-list-item";
import { users_admin as viewTexts } from "assets/strings/texts";

const MtdListItemModel = ({
  name,
  mac,
  type,
  trackIndex,
  index,
  handleMtdSelection,
  className,
}) => {
  return (
    <dd id={index} onClick={handleMtdSelection} className={className}>
      <div className="data">
        <p className="name">{name}</p>
        <p className="mac">
          {mac} <span>{`${trackIndex ? "-- TRACK: " : ""} ${trackIndex}`}</span>
        </p>
        <p className="type">{type}</p>
      </div>
    </dd>
  );
};

function formatMtdList(mtds, viewState, handleMtdSelection, loading) {
  let formatMtdList = [];
  if (loading) {
    return <div className="ui active loader" />;
  }
  if (!mtds.length) {
    return <div className="not-found">{viewTexts.notFound}</div>;
  }
  mtds.forEach((mtd, index) => {
    formatMtdList.push(
      <MtdListItem
        index={index}
        name={mtd.name}
        mac={mtd.mac}
        type={mtd.type}
        trackIndex={mtd.track_index ? mtd.track_index : ""}
        handleMtdSelection={handleMtdSelection}
        key={mtd._id}
      />
    );
  });

  return formatMtdList;
}

export default MtdListItemModel;
export { formatMtdList };
