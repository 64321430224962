import React, { useContext } from "react";
import { UsersExtensionsContext } from "../../context";
import { Panel } from "components/panel";
import { Dropdown } from "components/dropdown";
import { Button } from "components/buttons";
import { CopyToClipboard } from "components/copy-to-clipboard";
import { IconBack } from "components/icon-back";
import { BlueInput } from "components/blue-input";

export const EditExtensionModel = ({ className }) => {
  const {
    state,
    changeState,
    handleBack,
    viewport,
    onChangeInput,
    onChangeDropdown,
    throwPopupMessage,
  } = useContext(UsersExtensionsContext);

  const phoneView = viewport === "phone";
  const selectedExtension = state.selectedExtension;
  const disabled =
    !selectedExtension.isModifiable ||
    selectedExtension.is_printed === "delivered" ||
    selectedExtension.is_printed === "printed";

  const handleSubmit = (event) => {
    event.preventDefault();
    changeState({
      viewState: "submit",
      optionName: "updateExtension",
    });
    throwPopupMessage("alert", "Por favor, acepte los cambios");
  };

  return (
    <div className={className}>
      <Panel className="extension">
        <div className="header">
          {phoneView && <IconBack handleBack={handleBack} />}
          <p className="edit">Editar Hijo</p>
        </div>
        <BlueInput
          label="Primer Nombre:"
          name="firstName"
          placeholder="Escriba el primer nombre"
          onChange={onChangeInput}
          value={state.inputs.firstName}
          disabled={disabled}
        />
        <BlueInput
          label="Segundo Nombre:"
          name="secondName"
          placeholder="Escriba el segundo nombre"
          onChange={onChangeInput}
          value={state.inputs.secondName}
          disabled={disabled}
        />
        <BlueInput
          label="Primer apellido:"
          name="firstLastname"
          placeholder="Escriba el primer apellido"
          onChange={onChangeInput}
          value={state.inputs.firstLastname}
          disabled={disabled}
        />
        <BlueInput
          label="Segundo Apellido:"
          name="secondLastname"
          placeholder="Escriba el segundo apellido"
          onChange={onChangeInput}
          value={state.inputs.secondLastname}
          disabled={disabled}
        />

        <label htmlFor="school" className="title">
          Colegio:
        </label>
        <Dropdown
          name="school"
          className="drop"
          selection
          options={state.schoolsOptions}
          value={state.dropdownValues.school}
          onChange={onChangeDropdown}
          disabled={disabled}
        />
        <label htmlFor="carnet" className="title">
          Carnet:
        </label>
        <Dropdown
          name="carnet"
          className="drop"
          selection
          options={state.carnetOptions}
          value={state.dropdownValues.carnet}
          onChange={onChangeDropdown}
          disabled={disabled}
        />
        <label htmlFor="carnet" className="title">
          Status del Carnet:
        </label>
        <Dropdown
          name="is_printed"
          className="drop"
          selection
          options={state.carnetStatusOptions}
          value={state.dropdownValues.is_printed}
          onChange={onChangeDropdown}
          //disabled={state.dropdownValues.is_printed === "delivered"}
        />
        <label htmlFor="status" className="title">
          Estado:
        </label>
        <Dropdown
          name="status"
          className="drop"
          selection
          options={state.statusOptions}
          value={state.dropdownValues.status}
          onChange={onChangeDropdown}
          disabled={disabled}
        />
        <CopyToClipboard
          className="idinfo"
          textToShow="Copiar id"
          textToCopy={
            selectedExtension && selectedExtension._id
              ? selectedExtension._id
              : ""
          }
        />

        <div className="selectors">
          <Button color="green_6" width="40%" margin="0" onClick={handleSubmit}>
            Guardar Cambios
          </Button>
          {/* <Button
        color="red"
        width="40%"
        margin="0"
        onClick={handleDeleteExtension}
      >
        Eliminar
      </Button> */}
        </div>
      </Panel>
    </div>
  );
};
