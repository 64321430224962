import { ListDetailModel } from "./model";
import styled from "styled-components";
import { grey_4, bluePD } from "assets/strings/colors";

const ListDetail = styled(ListDetailModel)`
   {
    #root && {
      width: 100%;
      border-bottom: 1px solid ${grey_4};
      height: 8vh;
      min-height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 1rem;
      > .data {
        padding: 0;
        margin: 0;
        text-align: left;
        > .ticket-number {
          color: ${grey_4};
        }
        > .username {
          margin-top: -1rem;
          color: ${bluePD};
        }
      }
      > .moment-site {
        padding: 0;
        margin: 0;
        text-align: right;
        > .moment {
          font-size: 0.8rem;
          color: ${grey_4};
        }
        > .site {
          font-size: 0.8rem;
        }
      }
    }
  }
`;

export { ListDetail };
