import styled from "styled-components";
import { SupportFormModel } from "./model";
import { bluePD, grey_4 } from "assets/strings/colors";

const SupportForm = styled(SupportFormModel)`
  #root && {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > * {
      width: 40%;
    }
    > input {
      height: 3rem;
      margin: 0.5rem;
      border: 1px solid ${grey_4};
      border-radius: 2rem;
      text-align: center;
      font-size: 1rem;
      :focus {
        outline: none;
        border: 1px solid ${bluePD};
      }
    }
    > .header {
      margin: 0;
      margin-top: 1.5rem;
      font-size: 1.3rem;
    }
    > button {
      width: 30%;
    }
    @media (max-width: 800px) {
      > * {
        width: 100%;
      }
      > button {
        width: 80%;
      }
    }
  }
`;

export { SupportForm };
