import React from "react";
import { CSSTransition } from "react-transition-group";
import moment from "moment";
import { Icon } from "semantic-ui-react";
import { ItemContent } from "../item-content";
import { formatPrice } from "services/utils";

const TransactionDetailModel = ({
  viewType,
  showDetail,
  transactionSelected,
  handleApprove,
  handleBack,
  className,
}) => {
  const ticket_ref =
    transactionSelected && transactionSelected.ticket_ref
      ? transactionSelected.ticket_ref
      : { username: "--", ticket_number: "--" };
  const commerce =
    transactionSelected &&
    transactionSelected.commerce_counterpart &&
    transactionSelected.commerce_counterpart.name
      ? transactionSelected.commerce_counterpart.name
      : "--";
  const amount =
    transactionSelected && transactionSelected.pd_amount
      ? formatPrice(transactionSelected.pd_amount)
      : "--";
  const currency =
    transactionSelected && transactionSelected.currency
      ? transactionSelected.currency
      : "--";
  const date =
    transactionSelected && transactionSelected.updated_at
      ? moment(transactionSelected.updated_at).format("LLL")
      : "--";
  const status =
    transactionSelected && transactionSelected.status
      ? transactionSelected.status
      : "--";

  const view = {
    noDetail: (
      <div className={className}>
        <p className="no-transaction">No ha seleccionado ninguna transacción</p>
      </div>
    ),
    detail: (
      <div className={className}>
        <CSSTransition
          in={showDetail}
          timeout={350}
          appear={true}
          classNames="transaction"
        >
          <div className="transaction detail">
            {" "}
            {viewType === "phone" && (
              <div className="back-icon">
                <Icon
                  name="arrow left"
                  onClick={handleBack}
                  style={{ cursor: "pointer" }}
                />
              </div>
            )}
            <div className="icon" />
            <div className="title">Transacción por demora</div>
            <div className="date">{date}</div>
            <div className="content-box">
              <ItemContent title="Edificio" content={commerce} align="left" />
              <ItemContent
                title="Usuario"
                content={ticket_ref.username}
                align="right"
              />
            </div>
            <div className="content-box">
              <ItemContent
                title="Monto"
                content={`${amount} ${currency}`}
                align="left"
              />
              <ItemContent
                title="Estado"
                content={status === "pending" ? "Pendiente" : "--"}
                align="right"
              />
            </div>
            <p className="footer-title">#Ticket</p>
            <div className="footer">
              <p className="ticket-number">{ticket_ref.ticket_number}</p>
            </div>
            <button onClick={handleApprove}> Aprobar </button>
          </div>
        </CSSTransition>
      </div>
    ),
  };

  return transactionSelected ? view.detail : view.noDetail;
};

export { TransactionDetailModel };
