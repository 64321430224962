import * as httpMethods from "services/http-methods";
import { micropd_01 } from "services/config";

const endpoints = {
  createBillingFiscal: `${micropd_01}/superadmin/unidigital/directory/add`,
  deleteDirectory: `${micropd_01}/superadmin/unidigital/directory/delete`,
  listBillingFiscalDirectory: `${micropd_01}/superadmin/unidigital/directory`,
  listBilling: `${micropd_01}/superadmin/unidigital/bill/list`,
  newBill: `${micropd_01}/superadmin/unidigital/bill`,
  billPreview: `${micropd_01}/superadmin/unidigital/bill/preview`,
  commerceBill: `${micropd_01}/superadmin/unidigital/commerce-bill`,
};

const fetching = async (endpoint, body) => {
  try {
    const response = await httpMethods.post(endpoint, body);
    const jsonRes = await response.json();
    if (jsonRes.error) throw jsonRes.error;
    return jsonRes;
  } catch (error) {
    return error;
  }
};

export const createBillingFiscal = (body) => {
  return fetching(endpoints.createBillingFiscal, body);
};
export const listBillingFiscalDirectory = (body) => {
  return fetching(endpoints.listBillingFiscalDirectory, body);
};
export const deleteBillingFiscalDirectory = (body) => {
  return fetching(endpoints.deleteDirectory, body);
};
export const listBilling = (body) => {
  return fetching(endpoints.listBilling, body);
};
export const newBill = (body) => {
  return fetching(endpoints.newBill, body);
};
export const billPreview = (body) => {
  return fetching(endpoints.billPreview, body);
};
export const commerceBill = (body) => {
  return fetching(endpoints.commerceBill, body);
};
