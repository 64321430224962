import styled from "styled-components";
import TransfersFormModel from "./model";
import { grey_4 } from "assets/strings/colors";

const TransfersForm = styled(TransfersFormModel)`
  #root & {
    padding: 1rem;
    border-bottom: 1px solid ${grey_4};

    > .username-input {
      padding: 0.5px;

      > .selection {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;

        > p {
          padding-top: 1rem;
          width: 20%;
          text-decoration: underline;
        }
      }
    }

    > button {
      margin: 0;
    }

    > .label {
      font-size: 0.9rem;
      margin: 0 0 0.6rem 0;
      font-weight: bold;
      text-transform: uppercase;
    }

    > .container {
      min-height: 4rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;

      > button {
        margin: 0 0.5rem 0.6rem 0.5rem;
      }
    }

    @media (max-width: 768px) {
      > .container {
        flex-wrap: wrap;

        > button {
          width: calc(50% - 1rem);
        }
      }
    }
  }
`;
export default TransfersForm;
