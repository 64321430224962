import React, { useContext } from "react";

import { SuperadminBillsHistoryContext } from "../context";
import SuperadminBillsHistoryDesktopView from "../components/desktop-view";
import SuperadminBillsHistoryPhoneView from "../components/phone-view";

const SuperadminBillsHistoryView = () => {
  const { viewport } = useContext(SuperadminBillsHistoryContext);

  const view = {
    phone: <SuperadminBillsHistoryPhoneView />,
    desktop: <SuperadminBillsHistoryDesktopView />,
  };
  return view[viewport];
};

export default SuperadminBillsHistoryView;
