import React, { useContext } from "react";
import defaultUserGrey from "assets/img/default-user-grey.png";
import { Icon } from "semantic-ui-react";
import { ActivateMPContext } from "../../context";
import { grey_4 } from "assets/strings/colors";
import { CSSTransition } from "react-transition-group";

export const UserInfoModel = ({ className }) => {
  const {
    state: { selectedUser: user },
    handleBack,
    viewType,
  } = useContext(ActivateMPContext);

  let userHasProfile = user && user.profile;
  let userHasId =
    userHasProfile &&
    user.profile.identification &&
    user.profile.identification.doc_type &&
    user.profile.identification.doc_id;

  let mobilePhone =
    userHasProfile && user.profile.mobile_phone
      ? `${user.profile.mobile_phone}`
      : "--";
  let username = user && user.username ? `@${user.username}` : "--";
  let name =
    userHasProfile && user.profile.full_name
      ? `${user.profile.full_name}`
      : "--";
  let idNumber = userHasId
    ? `${user.profile.identification.doc_type}-${user.profile.identification.doc_id}`
    : "--";

  return (
    <div className={className}>
      {viewType === "phone" && (
        <Icon
          style={{
            paddingTop: "1rem",
            paddingLeft: "2.5rem",
            paddingBottom: "1rem",
            display: "flex",
            alignItem: "left",
            float: "left",
            color: grey_4,
            cursor: "pointer",
            zIndex: 1,
          }}
          onClick={handleBack}
          name="arrow left"
        />
      )}
      <CSSTransition
        in={user ? true : false}
        mountOnEnter
        unmountOnExit
        timeout={350}
        appear={true}
        classNames="container"
      >
        <div className={`container${viewType === "phone" ? " space" : ""}`}>
          <img className="img" src={defaultUserGrey} alt="user avatar" />
          <div className="data">
            <p className="name">{name}</p>
            <p className="username">{username}</p>
            <p className="phone">{mobilePhone}</p>
            <p className="idNumber">{idNumber}</p>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};
