import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { clearReduxStore } from "redux/actions/general";
import { getSelectedCommerce } from "redux/selectors";
import { errors } from "assets/strings/texts";
import { getQueryParams } from "services/utils";
import {
  getInvoices,
  getInvoiceById,
  //downloadInvoiceExcel,
} from "services/api";
import { commerceBill } from "api/superadmin-bills";
import { reportsAPI } from "api";
import { throwPopupMessage, closePopup } from "components/popup-message";
import { MposInvoicesHistoryView } from "./components/mpos-invoices-history-view";
import { MposInvoicesHistoryPhoneView } from "./components/mpos-invoices-history-phone-view";

import {
  handleSearchTypeButtons,
  handleFromDate,
  handleEndDate,
} from "components/invoices-form";

class MposInvoicesHistory extends PureComponent {
  constructor(props) {
    super(props);
    let commerce_id = props.selectedCommerce._id;
    let building =
      props.selectedCommerce.parking &&
      props.selectedCommerce.parking.building &&
      props.selectedCommerce.parking.building._id
        ? props.selectedCommerce.parking.building._id
        : "";
    let haveParking =
      props.category !== "pagodirecto" &&
      props.category !== "estacionamiento" &&
      props.category !== "gasolina" &&
      props.selectedCommerce &&
      props.selectedCommerce.parking;
    this.state = {
      viewState: "invoices_search",
      haveParking,
      searchType: "latests", // enum ['latests','dateRange']
      fromDate: null,
      endDate: null,
      invoices: [],
      commerce_id,
      building,
      nextPageExist: false,
      currentPage: 1,
      selectedInvoice: null,
      showInvoicesSearch: true,
      loadingExcel: false,
      loadingBill: false,
      // viewType: 'phone' | is set when component mounts
      popupMessages: null,
      popupMessagesType: "",
      showPopup: false,
    };

    // ========== INSTANCE PROPERTIES ==========
    this.initialState = { ...this.state };
    this.invoicesPerPage = 10;
    this.formDataCache = {};
    // Bind invoices form handlers
    this.handleSearchTypeButtons = handleSearchTypeButtons.bind(this);
    this.handleFromDate = handleFromDate.bind(this);
    this.handleEndDate = handleEndDate.bind(this);
    // Bind PopupMessage functions
    this.throwPopupMessage = throwPopupMessage.bind(this);
    this.closePopup = closePopup.bind(this);
  }

  // =============== STATE DOCS ===============
  /*
--> invoices_search
  { ...this.initialState }
  Error messages will be shown here

--> invoices_search_loading
  { ...this.initialState }
  or
  {
    ...this.this.initialState,
    searchType: String,
    fromDate: moment,
    endDate: moment
  }

  --> invoices_pagination_loading
  {
    searchType: String,
    fromDate: moment,
    endDate: moment,
    invoices: [{zero or more}],
    currentPage: Number,
    nextPageExist: boolean
  }

--> invoices_search_done
  {
    searchType: String,
    fromDate: moment,
    endDate: moment,
    invoices: [{zero or more}],
    currentPage: Number,
    nextPageExist: boolean
  }

--> invoice_selection_done
  {
    searchType: String,
    fromDate: moment,
    endDate: moment,
    invoices: [{zero or more}],
    currentPage: Number,
    nextPageExist: boolean,
    selectedInvoice: {},
    showInvoicesSearch: false
  }
*/
  // =============== END STATE DOCS ===============

  /*
   ** mpos-invoices-history on Mount:
   ** --> make initial invoices request
   ** --> set viewType
   ** --> set the 'resize' event listener
   */
  componentDidMount() {
    // --> make initial invoices request
    this.setState({ viewState: "invoices_search_loading" });
    if (this.props.location.search) {
      let params = getQueryParams(this.props.location.search);
      getInvoiceById(params.invoice, params.commerce)
        .then((res) => res.json())
        .then((res) => {
          if (res.error) {
            // If it is an authentication error, clear the redux-store to close the session
            if (
              res.error.id === "NO_TOKEN_PROVIDED" ||
              res.error.id === "AUTHENTICATE_FAILED" ||
              res.error.id === "DUPLICATE_SESSION"
            ) {
              this.throwPopupMessage("alert", errors.AUTHENTICATION);
              //this.props.clearReduxStore();
              //return; // prevent setState exeution on unmounted component
            }

            // If it is a superadmin remove the search view and show error
            if (this.props.category === "pagodirecto") {
              this.setState({ showInvoicesSearch: false });
              setTimeout(() => {
                this.throwPopupMessage("error", res.error.description);
              }, 350);
            } else {
              // In any other case
              this.setState({ ...this.initialState });
              this.throwPopupMessage("error", res.error.description);
            }
          }
          if (res.bill) {
            // remove the search form

            this.setState({ showInvoicesSearch: false });
            // after transition set the appropiate states
            setTimeout(() => {
              this.setState({
                ...this.initialState,
                viewState: "invoice_selection_done",
                searchType: "",
                invoices: [res.bill],
                selectedInvoice: { ...res.bill },
                showInvoicesSearch: false,
              });
            }, 350);
          }
        })
        .catch((err) => {
          // If it is a superadmin remove the search view and show error
          if (this.props.category === "pagodirecto") {
            this.setState({ showInvoicesSearch: false });
            setTimeout(() => {
              this.throwPopupMessage("error", errors.GENERAL_ERR);
            }, 350);
          } else {
            this.setState({ ...this.initialState });
            this.throwPopupMessage("error", errors.GENERAL_ERR);
          }
        });
    } else {
      let fromDate = null,
        endDate = null;
      let skip = 0,
        limit = this.invoicesPerPage + 1;
      let building = this.state.building;
      let commerce_id = this.state.commerce_id;
      let promise = getInvoices(
        fromDate,
        endDate,
        skip,
        limit,
        building,
        commerce_id
      );
      this.handleInvoicesSearchPromise(promise);
    }
    // --> set viewType
    this.setViewType();
    // --> set the 'resize' event listener
    window.addEventListener("resize", this.setViewType);
  }

  // Remove event listeners
  componentWillUnmount() {
    window.removeEventListener("resize", this.setViewType);
  }

  // 4)
  formatRequestData = (dataObject) => {
    let fromDate, endDate, limit;
    if (this.state.searchType === "dateRange" && dataObject.fromDate)
      fromDate = dataObject.fromDate.toISOString();
    if (this.state.searchType === "dateRange" && dataObject.endDate)
      endDate = dataObject.endDate.toISOString();
    limit = this.invoicesPerPage + 1;
    return { fromDate, endDate, limit };
  };

  // 5)
  handleSubmit = (event) => {
    event.preventDefault();
    // format request data
    let { fromDate, endDate, limit } = this.formatRequestData(this.state);
    let skip = 0;
    // save a copy of the form state
    this.formDataCache = {
      searchType: this.state.searchType,
      fromDate: this.state.fromDate,
      endDate: this.state.endDate,
    };
    // If there are missing fields
    if (
      (this.state.searchType === "dateRange" && (!fromDate || !endDate)) ||
      this.state.searchType === ""
    ) {
      this.setState({
        ...this.initialState,
        ...this.formDataCache,
      });
      this.throwPopupMessage("error", "Faltan campos obligatorios.");
    } else {
      // Set loading state and starting paination state
      this.setState({
        ...this.initialState,
        ...this.formDataCache,
        viewState: "invoices_search_loading",
      });
      // make the api call
      let building = this.state.building;
      let commerce_id = this.state.commerce_id;
      let promise = getInvoices(
        fromDate,
        endDate,
        skip,
        limit,
        building,
        commerce_id
      );
      this.handleInvoicesSearchPromise(promise);
    }
  };

  // 6)
  handlePageDown = (event) => {
    event.preventDefault();
    // format request data
    let { fromDate, endDate, limit } = this.formatRequestData(
      this.formDataCache
    );
    let skip = (this.state.currentPage - 2) * this.invoicesPerPage;
    // Set loading state
    this.setState((prevState) => ({
      ...this.initialState,
      ...this.formDataCache,
      viewState: "invoices_pagination_loading",
      currentPage: prevState.currentPage - 1,
      nextPageExist: true,
    }));
    // make the api call
    let building = this.state.building;
    let commerce_id = this.state.commerce_id;
    let promise = getInvoices(
      fromDate,
      endDate,
      skip,
      limit,
      building,
      commerce_id
    );
    this.handleInvoicesSearchPromise(promise);
  };

  // 7)
  handlePageUp = (event) => {
    event.preventDefault();
    // format request data
    let { fromDate, endDate, limit } = this.formatRequestData(
      this.formDataCache
    );
    let skip = this.state.currentPage * this.invoicesPerPage;
    // Set loading state
    this.setState((prevState) => ({
      ...this.initialState,
      ...this.formDataCache,
      viewState: "invoices_pagination_loading",
      currentPage: prevState.currentPage + 1,
      nextPageExist: true,
    }));
    // make the api call
    let building = this.state.building;
    let commerce_id = this.state.commerce_id;
    let promise = getInvoices(
      fromDate,
      endDate,
      skip,
      limit,
      building,
      commerce_id
    );
    this.handleInvoicesSearchPromise(promise);
  };

  // 8)
  handleInvoicesSearchPromise = (promise) => {
    promise
      .then((res) => res.json())
      .then((res) => {
        if (res.error) {
          // If it is an authentication error, clear the redux-store to close the session
          if (
            res.error.id === "NO_TOKEN_PROVIDED" ||
            res.error.id === "AUTHENTICATE_FAILED" ||
            res.error.id === "DUPLICATE_SESSION"
          ) {
            //this.props.clearReduxStore();
            //return; // prevent setState exeution on unmounted component
            this.throwPopupMessage("alert", errors.AUTHENTICATION);
            return;
          }
          this.setState({ ...this.initialState });
          this.throwPopupMessage("error", res.error.description);
        }
        if (res.bills) {
          let nextPageExist;
          if (res.bills.length > this.invoicesPerPage) {
            nextPageExist = true;
            res.bills.pop();
          } else {
            nextPageExist = false;
          }
          this.setState({
            viewState: "invoices_search_done",
            invoices: res.bills,
            nextPageExist,
          });
        }
      })
      .catch((err) => {
        this.setState({ ...this.initialState });
        this.throwPopupMessage(
          "error",
          "Ha ocurrido un error, verifique su conexión a internet"
        );
      });
  };

  // 9)
  handleInvoiceSelection = (event) => {
    let index = event.currentTarget.id;
    this.setState({
      showInvoicesSearch: false,
    });
    setTimeout(() => {
      this.setState({
        viewState: "invoice_selection_done",
        selectedInvoice: this.state.invoices[index],
      });
      this.props.history.replace({
        pathname: this.props.location.pathname,
        search: `?invoice=${this.state.invoices[index]._id}`,
      });
    }, 350);
  };

  downloadExcel = (event) => {
    event.preventDefault();
    const downloadExcelInvoice = async () => {
      try {
        this.setState({
          viewState: "invoice_selection_download",
          loadingExcel: true,
        });
        const response = await reportsAPI.ticketsBillCommerce({
          commerce_id: this.state.selectedInvoice.commerce._id,
          bill_code: this.state.selectedInvoice.code,
        });
        const error = !response.ok && !response.created;
        const res = await response.json();
        if (res.error) throw res.error;
        if (error) throw res.message;
        this.setState({
          loadingExcel: false,
        });
        this.throwPopupMessage("success", res.message);
      } catch (error) {
        this.setState({
          loadingExcel: false,
        });
        this.throwPopupMessage(
          "error",
          "Ha ocurrido un error, verifique su conexión a internet"
        );
      }
    };
    downloadExcelInvoice();
  };

  // 10)
  removeSelectedInvoice = () => {
    this.setState({ viewState: "invoices_search_done" });

    setTimeout(() => {
      this.setState({
        showInvoicesSearch: true,
        selectedInvioce: null,
      });
      this.props.history.replace({
        pathname: this.props.location.pathname,
        search: "",
      });
    }, 350);
  };

  // 11)
  printPage = () => {
    window.print();
  };

  // 12)
  showPaymentMethods = () => {
    this.throwPopupMessage("", "");
  };

  // 13)
  setViewType = () => {
    let documentElement = document.documentElement,
      width = window.innerWidth || documentElement.clientWidth;
    if (width < 768) {
      this.setState({ viewType: "phone" });
    } else {
      this.setState({ viewType: "desktop" });
    }
  };

  //14)
  generateBill = (event) => {
    event.preventDefault();
    const bill = async () => {
      try {
        this.setState({
          viewState: "invoice_selection_download",
          loadingBill: true,
        });
        const res = await commerceBill({
          bill_id: this.state.selectedInvoice._id,
        });

        if (res.error) throw res.error;
        this.setState({
          loadingBill: false,
        });
        this.throwPopupMessage("success", "Factura generada con éxito");
      } catch (error) {
        this.setState({
          loadingBill: false,
        });
        this.throwPopupMessage(
          "error",
          "Ha ocurrido un error, verifique su conexión a internet"
        );
      }
    };
    bill();
  };
  render() {
    if (this.state.viewType === "phone") {
      return (
        <MposInvoicesHistoryPhoneView
          // properties
          viewState={this.state.viewState}
          haveParking={this.state.haveParking}
          showInvoicesSearch={this.state.showInvoicesSearch}
          searchType={this.state.searchType}
          fromDate={this.state.fromDate}
          endDate={this.state.endDate}
          invoices={this.state.invoices}
          nextPageExist={this.state.nextPageExist}
          currentPage={this.state.currentPage}
          selectedInvoice={this.state.selectedInvoice}
          permissions={this.props.permissions}
          category={this.props.category}
          popupMessages={this.state.popupMessages}
          popupMessagesType={this.state.popupMessagesType}
          showPopup={this.state.showPopup}
          location={this.props.location}
          downloadExcel={this.downloadExcel}
          generateBill={this.generateBill}
          // methods
          handleSearchTypeButtons={this.handleSearchTypeButtons}
          handleFromDate={this.handleFromDate}
          handleEndDate={this.handleEndDate}
          handleSubmit={this.handleSubmit}
          handlePageDown={this.handlePageDown}
          handlePageUp={this.handlePageUp}
          handleInvoiceSelection={this.handleInvoiceSelection}
          removeSelectedInvoice={this.removeSelectedInvoice}
          showPaymentMethods={this.showPaymentMethods}
          loadingExcel={this.state.loadingExcel}
          loadingBill={this.state.loadingBill}
          closePopup={this.closePopup}
        />
      );
    }

    return (
      <MposInvoicesHistoryView
        // properties
        viewState={this.state.viewState}
        haveParking={this.state.haveParking}
        showInvoicesSearch={this.state.showInvoicesSearch}
        searchType={this.state.searchType}
        fromDate={this.state.fromDate}
        endDate={this.state.endDate}
        invoices={this.state.invoices}
        nextPageExist={this.state.nextPageExist}
        currentPage={this.state.currentPage}
        selectedInvoice={this.state.selectedInvoice}
        permissions={this.props.permissions}
        category={this.props.category}
        downloadExcel={this.downloadExcel}
        popupMessages={this.state.popupMessages}
        popupMessagesType={this.state.popupMessagesType}
        showPopup={this.state.showPopup}
        location={this.props.location}
        generateBill={this.generateBill}
        // methods
        handleSearchTypeButtons={this.handleSearchTypeButtons}
        handleFromDate={this.handleFromDate}
        handleEndDate={this.handleEndDate}
        handleSubmit={this.handleSubmit}
        handlePageDown={this.handlePageDown}
        handlePageUp={this.handlePageUp}
        handleInvoiceSelection={this.handleInvoiceSelection}
        removeSelectedInvoice={this.removeSelectedInvoice}
        printPage={this.printPage}
        showPaymentMethods={this.showPaymentMethods}
        closePopup={this.closePopup}
        loadingBill={this.state.loadingBill}
        loadingExcel={this.state.loadingExcel}
      />
    );
  }
}

const mapStateToProps = (state) => {
  let selectedCommerce = getSelectedCommerce(state);
  return { selectedCommerce };
};

const mapDispatchToProps = {
  clearReduxStore,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MposInvoicesHistory);
