import React, { useContext } from "react";
import { Grid } from "semantic-ui-react";
import { Loader } from "components/loader";
import { ResponsiveColumn } from "components/responsive-column";
import { IconBack } from "components/icon-back";
import { DiscountCodesContext } from "../context";
import History from "./history/History";
import { CodeDetail } from "./detail";
import { NewCode } from "./new";

export const DiscountCodesView = () => {
  const { state, viewType, changeState } = useContext(DiscountCodesContext);
  const display = (viewState) =>
    (viewType === "phone" && state.viewState === viewState) ||
    viewType === "desktop";

  const handleBack = (event) => {
    event.preventDefault();
    changeState({ viewState: "default" });
  };

  if (state.loading) return <Loader />;
  return (
    <Grid padded centered>
      <ResponsiveColumn
        computer={8}
        mobile={16}
        show={display("default")}
        withBorder
      >
        <History />
      </ResponsiveColumn>
      <ResponsiveColumn
        computer={8}
        mobile={16}
        show={display("detail") || display("new")}
      >
        <div style={{ padding: "1rem", width: "100%" }}>
          <IconBack handleBack={handleBack} show={viewType === "phone"} />
        </div>
        <CodeDetail />
        <NewCode />
      </ResponsiveColumn>
    </Grid>
  );
};
