import React from "react";

const SearchInputModel = ({
  handleChange,
  searchParam,
  placeholder,
  name,
  className,
  maxLength,
  minLength,
}) => {
  return (
    <div className={"ui transparent labeled input " + className}>
      <i aria-hidden="true" className="search icon label"></i>
      {!maxLength ? (
        <input
          type="text"
          placeholder={placeholder}
          className="input-tag"
          value={searchParam}
          onChange={handleChange}
          name={name}
        />
      ) : (
        <input
          type="text"
          placeholder={placeholder}
          className="input-tag"
          value={searchParam}
          onChange={handleChange}
          maxLength={maxLength}
          minLength={minLength}
          name={name}
        />
      )}
    </div>
  );
};

export default SearchInputModel;
