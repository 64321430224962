import React from "react";
import { EntitiesList, Pagination } from "components/entities-list";
import { TransactionListItem } from "./index";
import { formatPrice } from "services/utils";

const TransactionListItemModel = ({
  username,
  amount,
  currency,
  index,
  handleTransactionSelection,
  className,
}) => {
  return (
    <dd id={index} onClick={handleTransactionSelection} className={className}>
      <div className="data">
        <p className="username">@{username}</p>
        <p className="amount">{`${formatPrice(amount)} ${currency}`}</p>
      </div>
    </dd>
  );
};

const formattingTransactionList = (
  transactions,
  loading,
  handleTransactionSelection
) => {
  let transactionList = [];
  if (loading) {
    return <div className="ui active loader"></div>;
  } else if (!transactions || !transactions.length) {
    return <div className="not-found">No se ha encontrado transacciones</div>;
  } else {
    transactions.map((transaction, index) => {
      transactionList.push(
        <TransactionListItem
          username={
            transaction &&
            transaction.ticket_ref &&
            transaction.ticket_ref.username
              ? transaction.ticket_ref.username
              : ""
          }
          amount={
            transaction && transaction.pd_amount ? transaction.pd_amount : ""
          }
          currency={
            transaction && transaction.currency ? transaction.currency : ""
          }
          index={index}
          handleTransactionSelection={handleTransactionSelection}
          key={index}
        />
      );
      return null;
    });
    return transactionList;
  }
};

const TransacionsListModel = ({
  transactionList,
  handleTransactionSelection,
  loading,
  nextPageExists,
  page,
  handlePageUp,
  handlePageDown,
  className,
}) => {
  return (
    <div className={className}>
      <Pagination
        padding="0.65rem 0 0.65rem 0"
        nextPageExist={nextPageExists}
        currentPage={page}
        handlePageUp={handlePageUp}
        handlePageDown={handlePageDown}
        height="6vh"
      />
      <EntitiesList
        height={`${nextPageExists ? "calc(79vh - 4rem)" : "calc(85vh - 4rem)"}`}
        viewState={loading}
        entities={transactionList}
        formattingFunction={formattingTransactionList}
        selectionHandler={handleTransactionSelection}
      />
    </div>
  );
};

export { TransacionsListModel, TransactionListItemModel };
