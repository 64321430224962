import React from 'react'
import PropTypes from 'prop-types'
import { formatPrice } from 'services/utils'

const BillItem = ({ description, price, currency, className }) => {
  price = formatPrice(price)
  return(
    <div className={className}>
      <span>{description}</span>
      <span>{price} {currency}</span>
    </div>
  )
}

BillItem.propTypes = {
  description: PropTypes.string,
  currency: PropTypes.string,
  price:  PropTypes.number,
  className: PropTypes.string
}

export default BillItem