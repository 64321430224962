import React, { useEffect } from "react";
import { SwitchSlider } from "components/checkboxes";
import { Dropdown } from "components/dropdown";
import { useStateManager } from "hooks/use-state-manager";
import { search } from "./services";

const CounterPartSelectModel = ({
  onChangeCounterpart,
  hideSwitch,
  commerce,
  allOptionAvailable = false,
  className,
}) => {
  const initialOptions = allOptionAvailable
    ? [
        {
          key: "all",
          text: "Todos",
          value: "",
        },
      ]
    : [];
  const initialState = {
    counterpartSelected: commerce
      ? "commerce_counterpart_id"
      : "counterpart_id",
    counterpartOptions: [...initialOptions],
    initialOptions,
    counterpartValue: "",
    searchQuery: "",
    loading: false,
    skip: 0,
    limit: 100,
  };
  const [state, changeState] = useStateManager(initialState);
  const handleCounterpart = (event) => {
    const counterpartSelected = event.currentTarget.id;
    changeState({ ...initialState, counterpartSelected });
  };

  const onChange = (event, data) => {
    changeState({ counterpartValue: data.value });
    onChangeCounterpart({ [state.counterpartSelected]: data.value });
  };

  const onSearchChange = (event, data) => {
    changeState({ searchQuery: data.searchQuery });
  };

  useEffect(() => {
    const searchByUser =
      state.counterpartSelected === "counterpart_id" &&
      state.searchQuery.length > 3;
    const searchByCommerce =
      state.counterpartSelected === "commerce_counterpart_id" &&
      state.searchQuery.length > 1;

    const debounceTimer = setTimeout(() => {
      if (searchByUser || searchByCommerce) {
        search(state, changeState);
      }
    }, 500);

    return () => {
      clearTimeout(debounceTimer); // Limpia el temporizador en cada renderizado para evitar ejecuciones no deseadas
    };
  }, [state.searchQuery]);

  return (
    <div className={className}>
      {!hideSwitch && (
        <SwitchSlider
          margin="0.5rem 0"
          selected={state.counterpartSelected}
          options={[
            { data: "counterpart_id", displayData: "Usuario" },
            { data: "commerce_counterpart_id", displayData: "Comercio" },
          ]}
          handleSelection={handleCounterpart}
          zIndex={0}
        />
      )}

      <Dropdown
        placeholder={`Buscar ${
          state.counterpartSelected === "counterpart_id"
            ? "usuario"
            : "comercio"
        }`}
        search={(options) => (options ? options : [])}
        selection
        searchQuery={state.searchQuery}
        loading={state.loading}
        id="counterpart"
        options={state.counterpartOptions}
        onSearchChange={onSearchChange}
        onChange={onChange}
        noResultsMessage={
          state.loading ? "Consultando..." : "No se encontraron resultados"
        }
        style={{ width: "100%" }}
        searchInput={{ autoComplete: "off" }}
      />
    </div>
  );
};

export default CounterPartSelectModel;
