import React from "react";
import { Table } from "semantic-ui-react";
import { formatPrice, formatInt } from "services/utils";
import moment from "moment-timezone";

export const PDAmountModel = ({
  parking,
  services,
  monthlyPremium,
  dateFrom,
  dateTo,
  className,
}) => {
  return (
    <div className={className}>
      <div className="header">
        <p className="title">PagoDirecto</p>
        <p className="date">
          {` ${moment(dateFrom).tz("America/Caracas").format("ll")}`}
        </p>
      </div>
      <div className="table">
        <Table celled color={"blue"}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Tickets</Table.HeaderCell>
              <Table.HeaderCell>Cantidad</Table.HeaderCell>
              <Table.HeaderCell>Monto acumulado</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Especiales</Table.Cell>
              <Table.Cell>
                {parking && parking.total_promotional_qty
                  ? formatInt(parking.total_promotional_qty)
                  : 0}
              </Table.Cell>
              <Table.Cell>
                {parking && parking.total_promotional
                  ? `${formatPrice(parking.total_promotional)} Bs.`
                  : 0}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Todos</Table.Cell>
              <Table.Cell>
                {parking && parking.total_qty
                  ? formatInt(parking.total_qty)
                  : 0}
              </Table.Cell>
              <Table.Cell>
                {parking && parking.total_income
                  ? `${formatPrice(parking.total_income)} Bs.`
                  : 0}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
          <Table.Row>
            <Table.Cell />
            <Table.Cell />
            <Table.Cell positive>{`Ganancia: ${
              parking && parking.total_remaining
                ? `${formatPrice(parking.total_remaining)} Bs.`
                : "0 Bs."
            }`}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Puesto Fijo</Table.Cell>
            <Table.Cell>
              {monthlyPremium && monthlyPremium.total_qty
                ? formatInt(monthlyPremium.total_qty)
                : 0}
            </Table.Cell>
            <Table.Cell positive>
              {monthlyPremium && monthlyPremium.total_income
                ? `Ganancia: ${formatPrice(monthlyPremium.total_income)} Bs.`
                : 0}
            </Table.Cell>
          </Table.Row>
        </Table>
      </div>
      <div className="table space">
        <Table celled color={"blue"}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Servicios</Table.HeaderCell>
              <Table.HeaderCell>Transacciones</Table.HeaderCell>
              <Table.HeaderCell>Monto recaudado</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Digitel</Table.Cell>
              <Table.Cell>
                {services &&
                services.data &&
                services.data.digitel &&
                services.data.digitel.qty
                  ? formatInt(services.data.digitel.qty)
                  : 0}
              </Table.Cell>
              <Table.Cell>
                {services &&
                services.data &&
                services.data.digitel &&
                services.data.digitel.funds
                  ? `${formatPrice(services.data.digitel.funds)} Bs. - ${
                      services.data.digitel.profit_percentage
                    }% de ingreso`
                  : 0}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Movistar</Table.Cell>
              <Table.Cell>
                {services &&
                services.data &&
                services.data.movistar &&
                services.data.movistar.qty
                  ? formatInt(services.data.movistar.qty)
                  : 0}
              </Table.Cell>
              <Table.Cell>
                {services &&
                services.data &&
                services.data.movistar &&
                services.data.movistar.funds
                  ? `${formatPrice(services.data.movistar.funds)} Bs. - ${
                      services.data.movistar.profit_percentage
                    }% de ingreso`
                  : 0}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>SimpleTV</Table.Cell>
              <Table.Cell>
                {services &&
                services.data &&
                services.data.simpletv &&
                services.data.simpletv.qty
                  ? formatInt(services.data.simpletv.qty)
                  : 0}
              </Table.Cell>
              <Table.Cell>
                {services &&
                services.data &&
                services.data.simpletv &&
                services.data.simpletv.funds
                  ? `${formatPrice(services.data.simpletv.funds)} Bs. - ${
                      services.data.simpletv.profit_percentage
                    }% de ingreso`
                  : 0}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>CANTV</Table.Cell>
              <Table.Cell>
                {services &&
                services.data &&
                services.data.cantv &&
                services.data.cantv.qty
                  ? formatInt(services.data.cantv.qty)
                  : 0}
              </Table.Cell>
              <Table.Cell>
                {services &&
                services.data &&
                services.data.cantv &&
                services.data.cantv.funds
                  ? `${formatPrice(services.data.cantv.funds)} Bs. - ${
                      services.data.cantv.profit_percentage
                    }% de ingreso`
                  : 0}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>INTER</Table.Cell>
              <Table.Cell>
                {services &&
                services.data &&
                services.data.inter &&
                services.data.inter.qty
                  ? formatInt(services.data.inter.qty)
                  : 0}
              </Table.Cell>
              <Table.Cell>
                {services &&
                services.data &&
                services.data.inter &&
                services.data.inter.funds
                  ? `${formatPrice(services.data.inter.funds)} Bs. - ${
                      services.data.inter.profit_percentage
                    }% de ingreso`
                  : 0}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Todos</Table.Cell>
              <Table.Cell>
                {services && services.total_qty
                  ? formatInt(services.total_qty)
                  : 0}
              </Table.Cell>
              <Table.Cell>
                {services && services.total_amount
                  ? `${formatPrice(services.total_amount)} Bs.`
                  : 0}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell positive />
              <Table.Cell positive />
              <Table.Cell positive>{`Ganancia: ${
                services && services.total_profit
                  ? `${formatPrice(services.total_profit)} Bs.`
                  : "0 Bs."
              }`}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell negative />
              <Table.Cell negative />
              <Table.Cell negative>{`A pagar: ${
                services && services.amount_to_pay
                  ? `${formatPrice(services.amount_to_pay)} Bs.`
                  : "0 Bs."
              }`}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};
