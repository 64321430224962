import React from "react";
import { Button } from "components/buttons";
import { general as texts } from "assets/strings/texts";
import { recharge_validate as viewTexts } from "assets/strings/texts";
import { SwitchSlider } from "components/checkboxes";
import SearchInput from "components/search-input";

const RechargesFormModel = ({
  category,
  username,
  rechargesType,
  pending,
  approved,
  rejected,
  processing,
  className,
  handleUsernameChange,
  handleRechargesType,
  handleSearchStatus,
  handleSubmit,
}) => {
  return (
    <div className={className}>
      {rechargesType !== "TDC" && (
        <div className="username-input">
          <SearchInput
            handleChange={handleUsernameChange}
            searchParam={username}
            placeholder="@user"
          />
        </div>
      )}
      <SwitchSlider
        margin="0 0 .5rem 0"
        selected={rechargesType}
        options={[
          { data: "mobile_payment", displayData: viewTexts.pagoMovil },
          { data: "bank_transfer", displayData: viewTexts.wireTransfer },
          { data: "superadmin", displayData: viewTexts.pagodirecto },
          { data: "credicard", displayData: viewTexts.credicard },
          { data: "TDC", displayData: viewTexts.tdc },
        ]}
        handleSelection={handleRechargesType}
      />
      {category === "pagodirecto" &&
        rechargesType !== "TDC" && [
          <p className="label" key="status-selector-label">
            {viewTexts.rechargeStatus}
          </p>,
          <div className="status container" key="status-selector-buttons">
            <Button
              color={approved ? "blue" : "grey_1"}
              pressed={approved}
              id="approved"
              onClick={handleSearchStatus}
            >
              {texts.approved}
            </Button>
            <Button
              color={pending ? "blue" : "grey_1"}
              pressed={pending}
              id="pending"
              onClick={handleSearchStatus}
            >
              {texts.pending}
            </Button>

            <Button
              color={processing ? "blue" : "grey_1"}
              pressed={processing}
              id="processing"
              onClick={handleSearchStatus}
            >
              {texts.processing}
            </Button>

            <Button
              color={rejected ? "blue" : "grey_1"}
              pressed={rejected}
              id="rejected"
              onClick={handleSearchStatus}
            >
              {texts.rejected}
            </Button>
          </div>,
        ]}
      <Button color="grey" onClick={handleSubmit}>
        Buscar
      </Button>
    </div>
  );
};

export { RechargesFormModel };
