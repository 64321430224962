import React from "react";
import { GreenButton } from "./green-button";

const OptionsPhoneView = ({
  optionSelected,
  handleNewUser,
  handleRecharge,
  handleNewTicket,
  handleValidateEvent,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexFlow: "row wrap",
        justifyContent: "space-around",
      }}
    >
      <GreenButton
        handle={handleNewUser}
        isSelected={optionSelected === "new-user"}
        text="Registrar"
      />
      <GreenButton
        handle={handleNewTicket}
        isSelected={optionSelected === "new-ticket"}
        text="Digitalizar"
      />
      {/*      <GreenButton
        handle={handleRecharge}
        isSelected={optionSelected === "new-recharge"}
        text="Recargar"
      /> */}
      <GreenButton
        handle={handleValidateEvent}
        isSelected={optionSelected === "validate-event"}
        text="Eventos"
      />
    </div>
  );
};

export { OptionsPhoneView };
