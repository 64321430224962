import MobilePaidsListItemModel, {
  MobilePaidsListItemPhoneModel,
  RefAndDateModel,
} from "./model";
import styled from "styled-components";
import { grey_4, grey_2, blue, textualBlue } from "assets/strings/colors";

const MobilePaidsListItem = styled(MobilePaidsListItemModel)`
  #root & {
    cursor: pointer;
    padding: 1.1vh 1rem 1.1vh 1rem;
    border-bottom: 1px solid ${grey_4};
    margin: 0;
    list-style-type: none;
    color: ${textualBlue} >.time {
      display: inline-block;
      margin: 0.5rem 1rem 0.5rem auto;
      float: right;
    }
    > .data {
      max-width: 60%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      display: inline-block;
      > p {
        margin: 0;
        padding-left: 0.7rem;
        font-size: 0.9rem;
      }
      > .ref {
        padding: 0;
        white-space: pre;
        > .ref-number {
          display: inline-block;
          padding-left: 0.3rem;
          font-weight: bold;
          font-size: 1.2rem;
          color: ${blue};
        }
        > .status {
          font-weight: bold;
        }
      }
    }
  }
  :hover {
    background: ${grey_2};
  }
`;
export const MobilePaidsListItemPhone = styled(MobilePaidsListItemPhoneModel)`
  #root & {
    margin: 0.5rem 0;
    padding: 1rem 0;
    width: 100%;
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    align-items: center;
    border-top: 1px ${grey_4} solid;
    border-bottom: 1px ${grey_4} solid;
    border-radius: 1rem;

    > .title {
      margin: 0;
      text-align: left;
      font-weight: bold;
      width: calc(50% - 1rem);
    }
    > .content {
      margin: 0;
      text-align: right;
      width: calc(50% - 1rem);
    }
    > .cap {
      text-transform: capitalize;
    }
  }
`;

export const RefAndDate = styled(RefAndDateModel)`
  #root & {
    margin: 0.5rem 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: calc(25% - 1rem);
    > .reference {
      width: 100%;
      margin: 0;
      padding: 0;
      font-weight: bold;
      text-align: start;
      > span {
        color: ${blue};
      }
    }
    > .date {
      width: 100%;
      margin: 0;
      padding: 0;
      font-weight: bold;
      text-align: start;
      > span {
        font-weight: normal;
        font-size: 0.7rem;
      }
    }
  }
`;

export default MobilePaidsListItem;
