import { MovementsModel, MovementDetailModel } from "./model";
import styled from "styled-components";

export const Movements = styled(MovementsModel)`
  #root && {
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    ${(props) => (props.phoneView ? "margin-top: 20rem;" : "")};
    > .movements-form {
      width: 100%;
      margin: 0;
      padding: 1rem;
      padding-top: 0;
      border-bottom: 1px solid #e0e0e0;
    }
    @media (min-width: 991px) {
      display: flex;
    }
  }
`;
export const MovementDetail = styled(MovementDetailModel)`
  #root && {
    display: flex;
    @media (min-width: 991px) {
      display: flex;
    }
  }
`;
