import React from "react";
import moment from "moment-timezone";
import { RechargeListItem } from "./index";
import { formatPrice } from "services/utils";
import { general as texts } from "assets/strings/texts";
import { recharge_validate as viewTexts } from "assets/strings/texts";

const RechargeListItemModel = ({
  index,
  username,
  phone,
  paymentRef,
  rechargeType,
  time,
  api,
  status,
  amount,
  currency,
  handleRechargeSelection,
  className,
}) => {
  if (!paymentRef) paymentRef = "--";
  return (
    <dd id={index} className={className} onClick={handleRechargeSelection}>
      <div className="data">
        <p className="ref">
          {viewTexts.ref}
          <span className="ref-number">{paymentRef + "  "}</span>-
          <span className="status">{"  " + texts[status]}</span>
        </p>
        <p>{formatPrice(amount) + " " + currency}</p>
        <p>
          {status === "pending" && rechargeType === "mobile_payment"
            ? phone
            : username
            ? "@" + username
            : ""}
        </p>
      </div>
      <div className="time">{moment(time).format(texts.timeFormat)}</div>
    </dd>
  );
};

function formatRechargesList(recharges, viewState, handleRechargeSelection) {
  // Declaramos el array que devolverá la funcións
  let rechargesList = [];
  if (viewState === "recharge_search_loading") {
    return <div className="ui active loader"></div>;
  }
  if (viewState === "recharge_search") {
    return <div className="not-found">{viewTexts.rechargeSearch}</div>;
  }
  if (
    viewState === "recharge_search_done" ||
    viewState === "recharge_selection_done" ||
    viewState === "recharge_validation_approved" ||
    viewState === "recharge_validation_reported" ||
    viewState === "recharge_validation_rejected" ||
    viewState === "recharge_validation_loading" ||
    viewState === "recharge_validation_done"
  ) {
    if (recharges.length === 0) {
      return <div className="not-found">{viewTexts.rechargesNotFound}</div>;
    } else {
      recharges.forEach((recharge, index) => {
        let rechargeDate = moment(recharge.updated_at)
          .tz("America/Caracas")
          .format("DD / MM / YYYY");
        let nextRechargeDate;
        if (index !== 0)
          nextRechargeDate = moment(recharges[index - 1].updated_at)
            .tz("America/Caracas")
            .format("DD / MM / YYYY");
        if (index === 0 || rechargeDate !== nextRechargeDate) {
          let separatorDate = moment(recharge.updated_at).tz("America/Caracas"); //Test this format
          rechargesList.push(
            <dt key={recharge.updated_at} className="date-separator">
              {viewTexts.rechargesOf}
              {separatorDate.format(texts.longDateFormat)}
            </dt>
          );
        }
        rechargesList.push(
          <RechargeListItem
            index={index}
            amount={recharge.amount}
            currency={recharge.currency}
            status={recharge.status}
            time={recharge.updated_at}
            phone={recharge.payment_issuing_phone}
            username={
              recharge.status === "pending" &&
              recharge.type === "mobile_payment"
                ? ""
                : (recharge &&
                    recharge.customer &&
                    recharge.customer.username) ||
                  ""
            }
            rechargeType={recharge.type}
            api={
              recharge.api_mobile_payment ? recharge.api_mobile_payment : "--"
            }
            paymentRef={recharge.payment_ref}
            handleRechargeSelection={handleRechargeSelection}
            key={recharge._id}
          />
        );
      });
      return rechargesList;
    }
  }
}

export { RechargeListItemModel, formatRechargesList };
