import React, { useContext, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import { general as texts } from "assets/strings/texts";
import moment from "moment/moment";
import { UsersReportContext } from "../../context";
import { services } from "../../services";
import { Dropdown } from "components/dropdown";
import { SchoolOptions } from "../school-options";
import { BuildingOptions } from "../building-options";
import { CommerceAutocomplete } from "components/commerce-autocomplete";
import { TollsSelection } from "components/tolls-selection";

const transactionsTagsOptions = [
  {
    value: "recharge",
    key: "recharge",
    text: "Recarga",
  },
  {
    value: "fee",
    key: "fee",
    text: "Fee",
  },
  {
    value: "parking",
    key: "parking",
    text: "Estacionamiento",
  },
  {
    value: "reverse",
    key: "reverse",
    text: "Reverso",
  },
  {
    value: "parking_premium_bundle",
    key: "parking_premium_bundle",
    text: "Paquete premium",
  },
  {
    value: "transfer",
    key: "transfer",
    text: "Transferencia",
  },
  {
    value: "parking_overtime",
    key: "parking_overtime",
    text: "Tickets con sobretiempo",
  },
  {
    value: "fuel",
    key: "fuel",
    text: "Combustible",
  },
  {
    value: "parking_premium",
    key: "parking_premium",
    text: "Tickets premium",
  },
  {
    value: "return",
    key: "return",
    text: "Retornos",
  },
  {
    value: "service",
    key: "service",
    text: "Pago de servicios",
  },
  {
    value: "withdraw",
    key: "withdraw",
    text: "Retiros de fondos",
  },
];

export const ReportFormModel = ({ className, phoneView }) => {
  const {
    state,
    changeState,
    fromDate,
    endDate,
    handleFromDate,
    handleEndDate,
    file,
    setFile,
    loading,
    throwPopupMessage,
  } = useContext(UsersReportContext);
  const showGetFile = state.reportValue === "userActivities";
  const showTransactionsTags = state.reportValue === "transactionCustomer";
  const showSchools = state.reportValue === "transactionsSchools";
  const showBuildings = state.reportValue === "parkingLogs";
  const showTolls = state.reportValue === "tollTickets";
  const showCommercesAutocomplete =
    state.reportValue === "transactionsCommercesTransfers";
  const showParkingMetersAutocomplete =
    state.reportValue === "parkingMeterTickets";
  const fileInputRef = useRef(null);
  const onFileChange = (event) => {
    event.preventDefault();
    setFile(event.target.files[0]);
    changeState({ _file: event.target.value });
  };
  const onFileSelectorClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };
  const onSubmit = (event) => {
    event.preventDefault();
    changeState({ submit: true });
  };
  const onChangeTransactionTag = (event, data) => {
    const transactionTag = data.value;
    changeState({ transactionTag });
  };
  useEffect(() => {
    const apiRequest = async () => {
      changeState({ loading: true });
      const { response, result } = await services.submitForm({
        reportValue: state.reportValue,
        file,
        fromDate,
        endDate,
        transaction_tag: state.transactionTag,
        schoolId: state.schoolSelected,
        buildingCode: state.buildingSelected,
        commerces: state.commerces,
        tolls: state.tolls,
        selectedCommerce: state.selectedCommerce,
      });
      changeState({ loading: false, _file: "" });
      throwPopupMessage(
        result,
        response && response.message ? response.message : result
      );
    };
    if (state.submit) {
      changeState({ submit: false });
      apiRequest();

      //throwPopupMessage(result, response.message);
    }
  }, [state.submit]);

  const onChangeCommerces = (commerces) => {
    changeState({ commerces });
  };

  const onChangeCommerce = (selectedCommerce) => {
    changeState({ selectedCommerce });
  };

  const onChangeTolls = (tolls) => {
    changeState({ tolls });
  };
  return (
    <form className={className} onSubmit={onSubmit}>
      {showCommercesAutocomplete && (
        <CommerceAutocomplete
          onChangeCommerces={onChangeCommerces}
          margin="1rem 0 0 0"
        />
      )}
      {showParkingMetersAutocomplete && (
        <CommerceAutocomplete
          onChangeCommerces={onChangeCommerce}
          margin="1rem 0 0 0"
          category="parquimetro"
          isMulti={false}
        />
      )}
      {showTolls && <TollsSelection onChangeTolls={onChangeTolls} />}
      <p className="date-range">Seleccione rango de fechas:</p>
      <div className="date-pickers">
        <DatePicker
          className="date-picker"
          placeholderText={texts.from}
          dateFormat={texts.shortDateFormat}
          showMonthDropdown
          showYearDropdown
          todayButton={texts.today}
          isClearable
          withPortal={phoneView}
          selected={fromDate}
          maxDate={endDate ? moment(endDate).subtract(23, "hours") : moment()}
          minDate={state.isSuperadmin ? null : moment().subtract(3, "month")}
          onChange={handleFromDate}
        />
        <p>/</p>
        <DatePicker
          className="date-picker"
          placeholderText={texts.to}
          dateFormat={texts.shortDateFormat}
          popperPlacement="bottom-end"
          showMonthDropdown
          showYearDropdown
          todayButton={texts.today}
          isClearable
          withPortal={phoneView}
          selected={endDate ? moment(endDate).endOf("day") : null}
          minDate={fromDate ? moment(fromDate).startOf("day") : null}
          maxDate={moment().endOf("day")}
          onChange={handleEndDate}
        />
      </div>
      {showGetFile && (
        <div className="file-form">
          <span>Archivo de usuarios:</span>
          <label htmlFor="file" key="file">
            <div className="file-style">
              <button onClick={onFileSelectorClick} disabled={loading}>
                Subir archivo
              </button>
              <p className={!state._file ? "no-file" : ""}>
                {state._file ? state._file : "Suba un archivo del directorio"}
              </p>
              <input
                type="file"
                id="file"
                accept=".txt"
                value={state._file}
                onChange={onFileChange}
                hidden
                disabled={loading}
                required
                ref={fileInputRef}
              />
            </div>
          </label>
        </div>
      )}
      {showTransactionsTags && (
        <div className="transactions">
          <p>Seleccione una etiqueta de transacción:</p>
          <Dropdown
            fluid
            selection
            onChange={onChangeTransactionTag}
            options={transactionsTagsOptions}
            value={state.transactionTag}
            className={"selection"}
          />
        </div>
      )}
      {showSchools && <SchoolOptions className="transactions" />}
      {showBuildings && <BuildingOptions className="transactions" />}

      <input type="submit" className="submit" value={"Enviar"} />
    </form>
  );
};
