import { useEffect, useState } from "react";
import { getWithdrawPreview, getWithdraw } from "../services";

const useOnMountWithdraw = (state, changeState, throwPopupMessage) => {
  const [preview, setPreview] = useState(false);
  const [withdraw, setWithdraw] = useState(false);
  useEffect(() => {
    if (withdraw) {
      getWithdraw(state, changeState, throwPopupMessage);
      setWithdraw(false);
    }
    if (preview) {
      getWithdrawPreview(state, changeState, throwPopupMessage);
      setPreview(false);
    }
  }, [preview, withdraw]);
  return [setWithdraw, setPreview];
};

export default useOnMountWithdraw;
