import React from "react";
import { Button } from "components/buttons";
import { Link } from "react-router-dom";
import moment from "moment";
import { Icon } from "semantic-ui-react";
import "moment/locale/es-us";
import { general as texts } from "assets/strings/texts";
import { users_admin as viewTexts } from "assets/strings/texts";
import { formatPrice, arrayToObject } from "services/utils";
import imageCrown from "assets/img/king.png";
import { Menu } from "semantic-ui-react";
import { DropdownSimple } from "components/dropdown";
import { grey_4, grey_6 } from "assets/strings/colors";
import {
  PanelTitle,
  PanelSectionHeader,
  PanelSectionContent,
} from "components/panel";

moment.locale("es-us");
export const UserInfo = ({
  viewType,
  user,
  permissions,
  removeSelecteUser,
  handleSearchMovements,
  handleOptionsSelection,
  remoteAccess,
  remoteStateToBack,
  contactPhone,
  handleContactPhone,
  handleUpdateUser,
}) => {
  let amounts = null;
  if (user && user.amounts && user.amounts.length) {
    amounts = arrayToObject(user.amounts, "currency");
  }
  const status = user.status
    ? user.status === "active"
      ? "Activo"
      : "Inactivo"
    : "no disponible";
  const appVersion = user.app_version;
  const hasRecommendationTickets =
    user.parking_configuration &&
    user.parking_configuration.hasOwnProperty("recommendation_tickets_number");

  const hasPromotionTickets =
    user.parking_configuration &&
    user.parking_configuration.hasOwnProperty("promotion_tickets_number");

  let userOptions = [{ key: "return", value: "return", text: "Debitar" }];

  /**
     * @brief deacttivated for a while {}
     let premiumOption =
     !user.parking_configuration ||
     moment().isAfter(user.parking_configuration.expire_date) ||
     !user.parking_configuration.is_active
     ? "Activar premium"
     : "Desactivar premium";
     userOptions.push({
    key: premiumOption,
    value: premiumOption,
    text: premiumOption,
  });*/

  userOptions.push({
    key: "ref",
    value: "ref",
    text: "Mostrar Referidos",
  });
  if (permissions && permissions.permission2) {
    userOptions.push(
      {
        key: "logs",
        value: "logs",
        text: "Informes de Conexión",
      },
      {
        key: "ticketsReport",
        value: "ticketsReport",
        text: "Reporte de tickets",
      }
    );
  }
  if (permissions && permissions.permission3) {
    userOptions.push(
      {
        key: "ticketAllocator",
        value: "ticketAllocator",
        text: "Asignar Tickets",
      },
      {
        key: "ticketDelete",
        value: "ticketDelete",
        text: "Eliminar Tickets",
      },
      {
        key: "resetPassword",
        value: "resetPassword",
        text: "Restablecer contraseña",
      },
      { key: "modifyData", value: "modifyData", text: "Modificar Datos" },
      { key: "modifyVehicle", value: "modifyVehicle", text: "Gestión de vehículos" },
    );
  }

  if (permissions && permissions.permission3 && user.status) {
    userOptions.push({
      key: "block",
      value: "block",
      text: `${user.status === "inactive" ? "Desbloquear" : "Bloquear"}`,
    });
  }

  return (
    <div>
      <Menu
        style={{
          border: "none",
          paddingLeft: "85%",
          marginBottom: "-5rem",
          background: "none",
          boxShadow: "none",
          position: "absolute",
          zIndex: 1,
          width: "40%",
        }}
      >
        <Menu.Menu position="right" style={{ opacity: 1, zIndex: 1 }}>
          <DropdownSimple
            icon="ellipsis vertical"
            text=" "
            placeholder=" "
            selectOnBlur={false}
            options={userOptions}
            onChange={handleOptionsSelection}
            wrapSelection={false}
            value={""}
          />
        </Menu.Menu>
      </Menu>
      {remoteAccess && (
        <Link
          to={{
            pathname: remoteStateToBack.path
              ? remoteStateToBack.path
              : "/mpos/tickets/history",
            state: {
              ...remoteStateToBack,
            },
          }}
          style={{
            position: "absolute",
            zIndex: 1,
          }}
        >
          <Icon
            style={{
              paddingLeft: "2rem",
              display: "flex",
              alignItem: "left",
              float: "left",
              color: grey_4,
              cursor: "pointer",
              zIndex: 1,
            }}
            name="arrow left"
          />
        </Link>
      )}
      {user.parking_configuration &&
        user.parking_configuration.is_active &&
        user.parking_configuration.expire_date &&
        moment().isBefore(user.parking_configuration.expire_date) && (
          <img
            src={imageCrown}
            style={
              viewType === "phone"
                ? {
                    height: "8.3vw",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "4vh",
                    width: "10%",
                  }
                : {
                    height: "4.3vw",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "4vh",
                    width: "10%",
                  }
            }
            alt="icon premium"
          />
        )}
      <PanelTitle style={{ textTransform: "lowercase", fontSize: "2rem" }}>
        @{user.username}
      </PanelTitle>
      {user.created_at && (
        <div>
          <PanelSectionHeader>{texts.registerDate}:</PanelSectionHeader>
          <PanelSectionContent>
            {moment(user.created_at)
              .tz("America/Caracas")
              .format("D MMMM YYYY, h:mm:ss a")}
          </PanelSectionContent>{" "}
        </div>
      )}
      {user.email && (
        <div>
          <PanelSectionHeader>{texts.email}:</PanelSectionHeader>

          <PanelSectionContent>{user.email}</PanelSectionContent>
          {!user.is_verified && (
            <PanelSectionContent>
              <i className="ban icon"></i>
              {texts.not}
              {texts.verifyed}
            </PanelSectionContent>
          )}

          {user.is_verified && (
            <PanelSectionContent>
              <i className="check icon"></i>
              {texts.verifyed}
            </PanelSectionContent>
          )}
        </div>
      )}

      {user.profile && (
        <div>
          <PanelSectionHeader>{texts.phone}:</PanelSectionHeader>

          <PanelSectionContent>
            {user.profile.mobile_phone ? user.profile.mobile_phone : "--"}
          </PanelSectionContent>
        </div>
      )}
      {user.profile && (
        <div>
          <PanelSectionHeader>{texts.contactPhone}:</PanelSectionHeader>

          <PanelSectionContent>
            <input
              type="text"
              placeholder={user.profile.phone ? user.profile.phone : "--"}
              required={true}
              value={contactPhone}
              minLength={11}
              onChange={handleContactPhone}
              style={{
                border: "none",
                borderRadius: "1rem",
                color: grey_6,
                fontSize: "1.1rem",
                textAlign: "center",
                width: "100%",
                outline: "none",
                boxShadow: "none",
                WebkitTransition: "box-shadow 250ms linear",
                transition: "box-shadow 250ms linear",
              }}
            />
          </PanelSectionContent>
        </div>
      )}

      {user.profile &&
        user.profile.identification &&
        user.profile.identification.doc_type &&
        user.profile.identification.doc_id && (
          <div>
            <PanelSectionHeader>{texts.id}:</PanelSectionHeader>
            <PanelSectionContent>
              {user.profile.identification.doc_type}-
              {user.profile.identification.doc_id}
            </PanelSectionContent>
          </div>
        )}

      {user.role && (
        <div>
          <PanelSectionHeader>{texts.role}:</PanelSectionHeader>

          <PanelSectionContent>{user.role}</PanelSectionContent>
        </div>
      )}

      {user.status && (
        <div>
          <PanelSectionHeader>{texts.status}:</PanelSectionHeader>

          <PanelSectionContent>{status}</PanelSectionContent>
        </div>
      )}

      {amounts ? (
        <div>
          <PanelSectionHeader>{viewTexts.BSfunds}:</PanelSectionHeader>

          {amounts && amounts.BS && amounts.BS.hasOwnProperty("funds") ? (
            <PanelSectionContent>
              {formatPrice(amounts.BS.funds)}
            </PanelSectionContent>
          ) : (
            <PanelSectionContent>No disponible</PanelSectionContent>
          )}

          <PanelSectionHeader>{viewTexts.BSblockedFunds}:</PanelSectionHeader>

          {amounts &&
          amounts.BS &&
          amounts.BS.hasOwnProperty("blocked_funds") ? (
            <PanelSectionContent>
              {formatPrice(amounts.BS.blocked_funds)}
            </PanelSectionContent>
          ) : (
            <PanelSectionContent>No disponible</PanelSectionContent>
          )}
        </div>
      ) : (
        <div>
          <PanelSectionHeader>{viewTexts.funds}:</PanelSectionHeader>

          {/* {user.funds != null ? formatPrice(user.funds) : 0} */}
          <p
            style={{
              fontSize: "1rem",
              margin: "0.5rem 2rem 0.5rem 2rem",
              justifyContent: "center",
              textAlign: "center",
              borderRadius: "1rem",
              border: "3px solid #ffe5d0",
              backgroundColor: "#ffe5d4",
            }}
          >
            Este usuario no ha hecho su primera transacción o no ha iniciado
            sesión en mucho tiempo.
          </p>
        </div>
      )}
      {appVersion && (
        <div>
          <PanelSectionHeader>{viewTexts.appVersion}:</PanelSectionHeader>
          <PanelSectionContent>{appVersion}</PanelSectionContent>
        </div>
      )}

      {/* {user.amounts[1] != null ? (
        <div>
          <PanelSectionHeader>{viewTexts.USDfunds}:</PanelSectionHeader>

          <PanelSectionContent>
            {formatPrice(user.amounts[1].funds)}
          </PanelSectionContent>

          <PanelSectionHeader>{viewTexts.USDblockedFunds}:</PanelSectionHeader>

          <PanelSectionContent>
            {formatPrice(user.amounts[1].blocked_funds)}
          </PanelSectionContent>
        </div>
      ) : null}
    */}
      {user.recovery_code !== null ? (
        <div>
          <PanelSectionHeader>{"Codigo de verificación"}:</PanelSectionHeader>
          <PanelSectionContent>{user.recovery_code.value}</PanelSectionContent>
          {!user.recovery_code.is_used && (
            <div>
              {Math.floor(
                (new Date().getTime() -
                  new Date(user.recovery_code.timestamp).getTime()) /
                  1000 /
                  60
              ) > 3 ? (
                <PanelSectionContent>
                  <i className="ban icon"></i>
                  No disponible
                </PanelSectionContent>
              ) : (
                <PanelSectionContent>
                  <i className="check icon"></i>
                  Disponible
                </PanelSectionContent>
              )}
            </div>
          )}
          {user.recovery_code.is_used && (
            <PanelSectionContent>
              <i className="ban icon"></i>
              No disponible
            </PanelSectionContent>
          )}
        </div>
      ) : null}
      {hasRecommendationTickets && (
        <div>
          <PanelSectionHeader>
            {"Tickets por recomendación"}:
          </PanelSectionHeader>
          <PanelSectionContent>
            {user.parking_configuration.recommendation_tickets_number}
          </PanelSectionContent>
        </div>
      )}
      {hasPromotionTickets && (
        <div>
          <PanelSectionHeader>{"Tickets de promoción"}:</PanelSectionHeader>
          <PanelSectionContent>
            {user.parking_configuration.promotion_tickets_number}
          </PanelSectionContent>
        </div>
      )}
      {user.parking_configuration &&
        user.parking_configuration.is_active &&
        user.parking_configuration.expire_date &&
        moment().isBefore(user.parking_configuration.expire_date) && (
          <div
            style={{
              marginTop: "0.5rem",
              borderRadius: "1rem",
              border: "3px solid #e0f5ff",
              backgroundColor: "#e4f5ff",
            }}
          >
            <h4
              style={{
                color: "#00a5ff",
                textAlign: "center",
                marginTop: "0.4rem",
              }}
            >
              Usuario Premium-Estacionamientos
            </h4>
            <PanelSectionHeader>{"Tickets por Día"}:</PanelSectionHeader>
            <PanelSectionContent>
              <b>{user.parking_configuration.per_day_amount}</b>
            </PanelSectionContent>
            <PanelSectionHeader>
              {"Tickets disponibles de hoy"}:
            </PanelSectionHeader>
            <PanelSectionContent>
              <b>
                {user.remaining_tickets_per_day >= 0
                  ? user.remaining_tickets_per_day
                  : "No disponible"}
              </b>
            </PanelSectionContent>
            <PanelSectionHeader>
              {"Días restantes con el servicio"}:
            </PanelSectionHeader>
            <PanelSectionContent>
              <b>
                {user.remaining_premium_days
                  ? user.remaining_premium_days
                  : "No disponible"}
              </b>
            </PanelSectionContent>

            <PanelSectionHeader>{"Fecha de vencimiento"}:</PanelSectionHeader>
            <PanelSectionContent>
              <b>
                {moment(user.parking_configuration.expire_date).format(
                  "DD/MM/YYYY"
                )}
              </b>
            </PanelSectionContent>
          </div>
        )}
      {user.parking_configuration &&
        user.parking_configuration.expire_date &&
        moment().isAfter(user.parking_configuration.expire_date) && (
          <div
            style={{
              marginTop: "0.5rem",
              borderRadius: "1rem",
              border: "3px solid #e0f5ff",
              backgroundColor: "#e4f5ff",
            }}
          >
            <h4
              style={{
                color: "#00a5ff",
                textAlign: "center",
                marginTop: "0.4rem",
              }}
            >
              Usuario Premium-Estacionamientos
            </h4>
            <h4
              style={{
                color: "#ff0000",
                textAlign: "center",
                marginTop: "0.2rem",
                marginBottom: "0.4rem",
                fontWeight: "bold",
              }}
            >
              Servicio Vencido
            </h4>
          </div>
        )}
      <Button
        color="green"
        onClick={handleUpdateUser}
        disabled={!contactPhone || contactPhone.length < 11}
      >
        {"Guardar Cambios"}
      </Button>

      <Button color="blue" onClick={handleSearchMovements}>
        {"Mostrar Movimientos"}
      </Button>

      {viewType === "phone" && (
        <Button color="grey" onClick={removeSelecteUser}>
          {texts.back}
        </Button>
      )}
    </div>
  );
};
