import React from "react";
import { Table } from "semantic-ui-react";
import { formatPrice, formatInt, amountsToObject } from "services/utils";
import moment from "moment-timezone";

export const WalletAmountModel = ({ users, commerces, className }) => {
  const usersIsArray = users && users.length > 0;
  const walletUsers = usersIsArray ? amountsToObject(users) : null;

  const commerceIsArray = commerces && commerces.length > 0;
  const walletCommerces = commerceIsArray ? amountsToObject(commerces) : null;

  return (
    <div className={className}>
      <div className="header">
        <p className="title">En circulación</p>
        <p className="date">{moment().tz("America/Caracas").format("lll")}</p>
      </div>
      <div className="table">
        <Table celled color={"blue"}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Wallet</Table.HeaderCell>
              <Table.HeaderCell>Cantidad</Table.HeaderCell>
              <Table.HeaderCell>Monto acumulado</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Usuarios</Table.Cell>
              <Table.Cell>
                {walletUsers && walletUsers.BS && walletUsers.BS.qty
                  ? formatInt(walletUsers.BS.qty)
                  : 0}
              </Table.Cell>
              <Table.Cell>
                {walletUsers && walletUsers.BS && walletUsers.BS.funds
                  ? `${formatPrice(walletUsers.BS.funds)} Bs.`
                  : 0}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Comercios</Table.Cell>
              <Table.Cell>
                {walletCommerces && walletCommerces.BS && walletCommerces.BS.qty
                  ? formatInt(walletCommerces.BS.qty)
                  : 0}
              </Table.Cell>
              <Table.Cell>
                {walletCommerces &&
                walletCommerces.BS &&
                walletCommerces.BS.funds
                  ? `${formatPrice(walletCommerces.BS.funds)} Bs.`
                  : 0}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};
