import React from "react";
import { Button } from "components/buttons";
import { general as texts } from "assets/strings/texts";

const FlatFormModel = ({
  billingObj,
  flatAmount,
  overnightAmount,
  handleInputFlatAmount,
  handleInputOvernightAmount,
  handleFlatSubmit,
  currency,
  className,
}) => {
  return (
    <div className={className}>
      <p>Tarifa de Estacionamiento</p>
      <div className="input-section">
        <input
          required
          id="flatAmount"
          min="0.01"
          max="any"
          type="text"
          name="amount"
          autoComplete="off"
          placeholder="Nueva Tarifa"
          value={flatAmount}
          onChange={handleInputFlatAmount}
        />
      </div>
      {billingObj.overnight && <p>Pernocta</p>}
      {billingObj.overnight && (
        <div className="input-section">
          <input
            required
            id="overnightAmount"
            min="0.01"
            max="any"
            type="text"
            name="amount"
            autoComplete="off"
            placeholder="Nueva Tarifa"
            value={overnightAmount}
            onChange={handleInputOvernightAmount}
          />
        </div>
      )}
      <Button color="blue" onClick={handleFlatSubmit}>
        {texts.accept}
      </Button>
    </div>
  );
};

export { FlatFormModel };
