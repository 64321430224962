import styled from "styled-components";
import SuccessDigitizeModel from "./model";

const SuccessDigitize = styled(SuccessDigitizeModel)`
  #root & {
    > .ok-circle-icon {
      text-align: center;
      width: 100%;
      margin: 0 auto 0 auto;
      max-width: 120px;
      > img {
        width: 100%;
      }
    }
    > .sub-title {
      font-size: 0.85rem;
      display: inline-block;
      border-bottom: 1px solid #5b5b5b;
      text-transform: uppercase;
      font-weight: bold;
      color: #5b5b5b;
      margin-bottom: 0.5rem 0 0.5rem 0;
    }
    > .text {
      font-weight: bold;
      color: #5b5b5b;
      font-size: 1.5rem;
      margin: 0.5rem auto 0.5rem auto;
      text-align: center;
    }
  }
`;
export default SuccessDigitize;
