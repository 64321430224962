import React from "react";
import PropTypes from "prop-types";
import UserListItem from "../users-list";
import { EntitiesList } from "components/entities-list";
import defaultUserGrey from "assets/img/default-user-grey.png";

const UserListItemModel = ({
  username,
  email,
  idNumber,
  profileImg,
  index,
  handleUserSelection,
  className,
}) => {
  profileImg = profileImg ? profileImg : defaultUserGrey;
  return (
    <dd id={index} onClick={handleUserSelection} className={className}>
      <img className="img" src={profileImg} alt="user avatar" />
      <div className="data">
        <p className="username">@{username}</p>
        <p className="email">{email}</p>
        <p className="idNumber">{idNumber}</p>
      </div>
    </dd>
  );
};

function formatUserList(users, viewState, handleUserSelection) {
  let formatUserList = [];
  if (users.length > 0) {
    users.forEach((user, index) => {
      let idNumber = "";
      if (
        user.profile &&
        user.profile.identification &&
        user.profile.identification.doc_type &&
        user.profile.identification.doc_id
      ) {
        idNumber =
          user.profile.identification.doc_type +
          "-" +
          user.profile.identification.doc_id;
      }
      formatUserList.push(
        <UserListItem
          index={index}
          username={user.username}
          email={user.email}
          idNumber={idNumber}
          handleUserSelection={handleUserSelection}
          key={user._id}
        />
      );
    });
    return formatUserList;
  } else {
    return (
      <div style={{ marginTop: "3rem" }}>No se encontraron resultados</div>
    );
  }
}

const UsersListModel = ({
  toShow,
  users,
  formatFunctionUserList,
  handleUserSelection,
  className,
}) => {
  return (
    <div className={className}>
      <EntitiesList
        height="calc(60vh - 4rem)"
        width="40%"
        viewState={toShow}
        entities={users}
        formattingFunction={formatFunctionUserList}
        selectionHandler={handleUserSelection}
      />
    </div>
  );
};

UserListItemModel.propTypes = {
  username: PropTypes.string,
  email: PropTypes.string,
  idNumber: PropTypes.string,
  profileImg: PropTypes.string,
  index: PropTypes.number,
};

export default UserListItemModel;
export { formatUserList, UsersListModel };
