import BarGraphModel from "./model";
import styled from "styled-components";

const BarGraph = styled(BarGraphModel)`
  #root & {
    margin: 0;
    width: 100%;
    height: ${(props) => {
      return props.custom ? "51.5vh" : "90%";
    }};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title {
      margin-bottom: 1.2rem;
      font-size: 1.1rem;
      color: #5b5b5b;
      text-transform: uppercase;
      font-weight: bold;
    }
    .no-data {
      text-align: center;
      height: 290px;
      font-size: 1.1rem;
      color: #5b5b5b;
      padding-top: 25%;
    }
  }

  @media (max-width: 768px) {
    #root & .title {
      text-align: center;
    }
  }
`;
export default BarGraph;
