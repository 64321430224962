import {
  UsersListModel,
  UsersNoFoundModel,
  UserListHeaderModel,
  UserListItemModel,
} from "./model";
import {
  blue_3,
  blue,
  bluePD,
  grey_4,
  grey_3,
  grey_1,
  grey_2,
  textualBlue,
} from "assets/strings/colors";
import styled from "styled-components";

const UsersList = styled(UsersListModel)`
  #root & {
    margin-top: 2rem;
    > .header {
      display: flex;
      text-align: center;
      position: relative;
      > p {
        text-transform: uppercase;
        margin: auto;
        padding: auto;
        margin-bottom: 1rem;
        display: flex;
        font-size: 1.5rem;
        align-item: center;
        justify-content: center;
        font-weight: bold;
        text-align: center;
        color: ${textualBlue};
      }
    }
    > button {
      margin-top: 2rem;
      position: absolute;
      left: 25%;
      border: none;
      cursor: pointer;
      width: 50%;
      height: 50px;
      border-radius: 0.28rem;
      font-weight: bold;
      min-height: 1.5rem;
    }
    > .register {
      border-bottom: 1px solid ${blue_3};
      background: ${bluePD};
      color: white;
      outline: none;
      :hover {
        background: ${blue};
      }
    }
    @media (max-width: 576px) {
      > button {
        left: 23%;
      }
      > .register {
        margin-top: 0.5rem;
      }
    }
  }
`;

const UserListItem = styled(UserListItemModel)`
  #root & {
    cursor: pointer;
    display: flex;
    text-align: center;
    align-item: center;
    justify-content: center;
    padding: 1.1vh 0 1.1vh 0;
    border-bottom: 1px solid ${grey_4};
    margin: 0;
    margin-top: 0.5rem;
    background: ${(props) => (props.status === "inactive" ? grey_2 : "white")};
    :hover {
      background: ${(props) => (props.status === "inactive" ? grey_3 : grey_1)};
    }
    > .username {
      color: ${(props) => (props.status === "inactive" ? grey_4 : bluePD)};
      width: 33.3%;
    }

    > .role {
      color: ${(props) => props.status === "inactive" && grey_4};
      width: 33.3%;
    }

    > .status {
      color: ${(props) => props.status === "inactive" && grey_4};
      width: 33.3%;
    }
  }
`;
const UserListHeader = styled(UserListHeaderModel)`
  #root & {
    margin: 0;
    border-bottom: 2px solid ${blue_3};
    > .col {
      color: ${grey_4};
      margin: 1.2rem 0.5rem 0 0.5rem;
      padding-bottom: 0.3rem;
      display: inline-block;
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
      font-size: 1.3rem;
      width: calc(33.3% - 1rem);
    }
  }
`;
const UsersNoFound = styled(UsersNoFoundModel)`
  #root & {
    margin: auto;
    padding: auto;
    display: flex;
    font-size: 1rem;
    align-item: center;
    justify-content: center;
    font-weight: bold;
    text-align: center;
    color: dimgrey;
  }
`;
export { UsersList, UserListHeader, UserListItem, UsersNoFound };
