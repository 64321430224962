import { useEffect, useState, useContext } from "react";
import { customerExtensionsAPI } from "api";
import { UsersExtensionsContext } from "../context";
import { errors } from "assets/strings/texts";

export const useAddNewExtension = () => {
  const [loading, setLoading] = useState(false);
  const [activate, setActivate] = useState(false);
  const { state, changeState, throwPopupMessage, initialState } = useContext(
    UsersExtensionsContext
  );

  useEffect(() => {
    const newExtension = async () => {
      setLoading(true);
      try {
        const user_id = state.selectedUser._id;
        const first_name = state.inputs.firstName;
        const second_name = state.inputs.secondName;
        const first_last_name = state.inputs.firstLastname;
        const second_last_name = state.inputs.secondLastname;
        const status = state.dropdownValues.status;
        const design = state.dropdownValues.carnet;
        const commerce = state.dropdownValues.school;
        const response = await customerExtensionsAPI.addExtension({
          user_id,
          children: {
            first_name,
            second_name,
            first_last_name,
            second_last_name,
            design,
            commerce,
            status,
          },
        });
        const res = await response.json();
        if (res.error) throw res.error;
        throwPopupMessage("success", "Representado añadido correctamente");
        changeState({
          viewState: "user_selected",
          users: state.users,
          selectedUser: state.selectedUser,
          searchExtensions: true,
        });
      } catch (error) {
        changeState({ ...initialState, viewType: state.viewType });
        if (
          error.id === "NO_TOKEN_PROVIDED" ||
          error.id === "AUTHENTICATE_FAILED" ||
          error.id === "DUPLICATE_SESSION"
        ) {
          throwPopupMessage("alert", errors.AUTHENTICATION);
        } else {
          throwPopupMessage("error", error.description);
        }
      }
      setLoading(false);
    };
    if (activate) {
      setActivate(false);
      newExtension();
    }
  }, [activate]);

  return { loading, setActivate };
};
