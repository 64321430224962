import React, { useState } from "react";
import { connect } from "react-redux";
import { clearReduxStore } from "redux/actions/general";
import { mpos as mposTexts } from "assets/strings/texts";

const CriticalNotificationsModel = ({ notifications, className }) => {
  let _notifications = [];

  for (const notification in notifications) {
    _notifications.push(notifications[notification]);
  }

  return <div className={className}>{_notifications}</div>;
};

const BlockedCommerce = () => {
  return (
    <div className={"notification error"}>
      <p>{mposTexts.blockedNotification}</p>
    </div>
  );
};

const PushSentSuccessfully = ({ message }) => {
  const [clear, setClear] = useState(false);
  const updateCode = (event) => {
    event.preventDefault();
    setClear(true);
  };
  if (clear) {
    return null;
  }
  return (
    <div className={"notification success"}>
      <p>
        {message}
        <span className="clickable" onClick={updateCode}>
          {` ${mposTexts.closeButton}`}
        </span>
      </p>
    </div>
  );
};

const PushSentError = ({ message }) => {
  const [clear, setClear] = useState(false);
  const updateCode = (event) => {
    event.preventDefault();
    setClear(true);
  };
  if (clear) {
    return null;
  }
  return (
    <div className={"notification error"}>
      <p>
        {message}
        <span className="clickable" onClick={updateCode}>
          {` ${mposTexts.closeButton}`}
        </span>
      </p>
    </div>
  );
};

let UpdateReady = ({ history, clearReduxStore }) => {
  // needs to be connected to redux
  const updateCode = () => {
    clearReduxStore();
    history.push("/");
    window.location.reload(true);
  };

  return (
    <div className={"notification alert"}>
      <p>
        {mposTexts.updateReady}
        <span className="clickable" onClick={updateCode}>
          {mposTexts.updateButton}
        </span>
      </p>
    </div>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = {
  clearReduxStore,
};

UpdateReady = connect(mapStateToProps, mapDispatchToProps)(UpdateReady);

export {
  CriticalNotificationsModel,
  BlockedCommerce,
  UpdateReady,
  PushSentSuccessfully,
  PushSentError,
};
