import React, { useContext } from "react";
import { MonthlyPremiumInvoicesContext } from "../../context";

export const MarkAsPaidFormModel = ({ className }) => {
  const {
    handleMarkAsPaidConfirmation,
    physical_bill_ref,
    onChangePhyBill,
    pay_ref,
    onChangePayRef,
  } = useContext(MonthlyPremiumInvoicesContext);
  return (
    <form onSubmit={handleMarkAsPaidConfirmation} className={className}>
      <label htmlFor="phy">
        <span>#Factura física</span>
        <input
          type="text"
          id="phy"
          placeholder="Factura física"
          onChange={onChangePhyBill}
          value={physical_bill_ref}
          required
        />
      </label>
      <label htmlFor="pay-ref">
        <span>#Refetencia</span>
        <input
          type="text"
          id="pay-ref"
          placeholder="Referencia de pago"
          onChange={onChangePayRef}
          value={pay_ref}
          required
        />
      </label>
      <button>Aceptar</button>
    </form>
  );
};
