import React, { useContext } from "react";
import { UsersExtensionsContext } from "../../context";
import { NewExtension } from "../new-extension";
import { EditExtension } from "../edit-extension";

const ExtensionFormModel = ({ className }) => {
  const { state } = useContext(UsersExtensionsContext);

  const defaultPanel = <></>;
  const panelReturned = {
    new_extension: <NewExtension />,
    extension_selected: <EditExtension />,
  };

  if (state.viewState === "movement_selected") return null;

  return (
    <div className={className}>
      {panelReturned[state.viewState] || defaultPanel}
    </div>
  );
};

export { ExtensionFormModel };
