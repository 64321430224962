import React from "react";
import { EntitiesList, Pagination } from "components/entities-list";
import { Loader } from "components/loader";
import { TicketsListItem } from "./index";
import storeIcon from "assets/img/store_photo_default.jpg";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import { general as texts } from "assets/strings/texts";
import iconoTicket from "assets/img/icono-ticket.png";
import imageCrown from "assets/img/king.png";
import { Button } from "components/buttons";
const CommerceTicketsModel = ({
  loading,
  isSelected,
  selectedCommerce,
  tickets,
  handleSelection,
  handlePageUp,
  handlePageDown,
  currentPage,
  nextPageExist,
  viewType,
  handleBack,
  className,
}) => {
  let imgSrc =
    isSelected &&
    selectedCommerce.photo_url &&
    selectedCommerce.photo_url !== "http://www.default.com/default.jpg"
      ? selectedCommerce.photo_url
      : storeIcon;
  return (
    <div className={className}>
      {isSelected && (
        <div className="commerce-info">
          <div className="header">
            <img src={imgSrc} alt="" />
            {selectedCommerce &&
              selectedCommerce.short_name !== "pagodirecto" && (
                <p className="name">{selectedCommerce.short_name}</p>
              )}
            {selectedCommerce &&
              selectedCommerce.short_name === "pagodirecto" && (
                <p className="name">Taquilla</p>
              )}
          </div>
          <div className="info">
            <p>{`TLF: ${selectedCommerce.phone}`}</p>
            <p className="doc">
              {`${selectedCommerce.identification.doc_type}-${selectedCommerce.identification.doc_id}`}
            </p>
          </div>
        </div>
      )}
      {isSelected && (
        <div className="list">
          <EntitiesList
            height="calc(70vh - 4rem)"
            viewState={""}
            entities={tickets}
            formattingFunction={formatTicketsList}
            selectionHandler={handleSelection}
            additionalArgs={[loading]}
          >
            <Pagination
              //padding="0.65rem 0 0.65rem 0"
              nextPageExist={nextPageExist}
              currentPage={currentPage}
              handlePageUp={handlePageUp}
              handlePageDown={handlePageDown}
            ></Pagination>
          </EntitiesList>
        </div>
      )}
      {isSelected && viewType === "phone" && (
        <Button color="grey" width="50%" onClick={handleBack}>
          Volver
        </Button>
      )}
    </div>
  );
};

const TicketsListItemModel = ({
  ticketNumber,
  ticketRef,
  username,
  premium,
  time,
  index,
  handleTicketSelection,
  className,
}) => {
  return (
    <dd id={index} className={className} onClick={handleTicketSelection}>
      <img
        src={premium ? imageCrown : iconoTicket}
        className="ticket-icon"
        alt=""
      />
      <div className="data">
        <p className={"ticket-number"}>{ticketNumber}</p>
        <p className="ticket-username">@{username}</p>
      </div>
      <p className="ref">{`REF: ${ticketRef}`}</p>
      <p className="time">{moment(time).tz("America/Caracas").format("LTS")}</p>
    </dd>
  );
};

const TicketsListHeaderModel = ({ className }) => {
  return (
    <dd className={className}>
      <p className="col twenty-five">#Ticket</p>
      <p className="col twenty-five">Estado</p>
      <p className="col twenty-five">Edificio</p>
      <p className="col twenty-five">Hora</p>
    </dd>
  );
};

function formatTicketsList(tickets, viewState, handleTicketSelection, loading) {
  // Declaramos el array que devolverá la función

  let ticketsList = [];
  if (loading) {
    return <Loader />;
  }
  if (tickets.length <= 0) {
    return <div className="not-found">No se han encontrado tickets</div>;
  }

  tickets.forEach((ticket, index) => {
    let premium = false;
    if (ticket.premium_type) {
      premium = true;
    }
    let ticketDate = moment(ticket.enter_at)
      .tz("America/Caracas")
      .format("DD / MM / YYYY");
    let nextTicketDate;
    if (index !== 0)
      nextTicketDate = moment(tickets[index - 1].enter_at)
        .tz("America/Caracas")
        .format("DD / MM / YYYY");
    if (index === 0 || ticketDate !== nextTicketDate) {
      let separatorDate = moment(ticket.enter_at).tz("America/Caracas"); //Test this format
      ticketsList.push(
        <dt key={ticket.enter_at} className="date-separator">
          {texts.entriesOf}
          {formatDate(separatorDate)}
        </dt>
      );
    }
    ticketsList.push(
      <TicketsListItem
        ticketNumber={ticket.ticket_number}
        username={ticket.username}
        ticketRef={ticket.ticket_reference}
        time={ticket.enter_at}
        index={index}
        premium={premium}
        handleTicketSelection={handleTicketSelection}
        key={ticket._id}
      />
    );
  });
  return ticketsList;
}

function formatDate(momentObject) {
  let dateString = momentObject.format("dddd, D [de] MMMM YYYY");
  dateString = dateString.charAt(0).toUpperCase() + dateString.slice(1);
  return dateString;
}

TicketsListItemModel.propTypes = {
  ticketNumber: PropTypes.string,
  username: PropTypes.string,
  time: PropTypes.string,
  index: PropTypes.number,
};
export { CommerceTicketsModel, TicketsListItemModel, TicketsListHeaderModel };
