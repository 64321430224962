import React from "react";
import Navbar from "components/navbar";
import { Link } from "react-router-dom";
import { Grid, Segment } from "semantic-ui-react";
import { NewTerms } from "./new-terms";
import PopupMessage from "components/popup-message";
import GreatingsText from "components/greatings-text";
import {
  NewBlueRoundButton /* GradientRoundButton */,
} from "components/round-button";
/* import CountrySelector from "components/country-selector"; */
import NewCountrySelector from "components/new-country-selector";
import { SigninFormInputs } from "components/register-user-form-inputs";
import { posDirectoBlue } from "assets/strings/colors";

const SigninView = ({
  formData,
  loading,
  redirect,
  agreeWithTerms,
  popupMessages,
  popupMessagesType,
  showPopup,
  location,
  handleLoginChange,
  handlePasswordChange,
  handleSubmit,
  handleTerms,
  refuseTerms,
  closePopup,
}) => {
  return (
    <div>
      {!agreeWithTerms && (
        <PopupMessage
          messageType={popupMessagesType}
          messages={popupMessages}
          showPopup={showPopup}
          closePopup={refuseTerms}
        >
          <NewTerms
            accept={handleTerms}
            cancel={refuseTerms}
            loading={loading}
          />
        </PopupMessage>
      )}

      {agreeWithTerms && (
        <PopupMessage
          messageType={popupMessagesType}
          messages={popupMessages}
          showPopup={showPopup}
          closePopup={closePopup}
        />
      )}

      <Navbar
        bgColors={[posDirectoBlue, posDirectoBlue]}
        title={
          <span>
            Inicie sesión con Pago<b>Directo</b>
          </span>
        }
      />

      <Grid centered padded>
        <Grid.Column mobile={15} tablet={8} computer={6}>
          <GreatingsText>
            <h4>Bienvenido</h4>
            <p>Es un placer tenerlo de vuelta</p>
          </GreatingsText>

          <form
            onSubmit={handleSubmit}
            className={loading ? "ui loading form" : ""}
          >
            <SigninFormInputs
              handleLoginChange={handleLoginChange}
              handlePasswordChange={handlePasswordChange}
              values={formData}
            />
            {/* <CountrySelector margins="0 auto 2.87vh auto" /> */}
            <NewCountrySelector />

            <NewBlueRoundButton fluid> Iniciar Sesión </NewBlueRoundButton>
          </form>

          <Segment basic textAlign="center">
            <p>
              ¿Aún no posee una cuenta comercio de Pago Directo?{" "}
              <Link to="/signup">¡Créela Ya!</Link>
            </p>
            <p>
              ¿Ha olvidado su contraseña?{" "}
              <Link to="/reset-password">Haga click aquí</Link>
            </p>
          </Segment>
        </Grid.Column>
      </Grid>
      {location && redirect}
    </div>
  );
};
export default SigninView;
