import { UserBundleDetailModel } from "./model";
import styled from "styled-components";
import { bluePD, grey_6 } from "assets/strings/colors";
export const UserBundleDetail = styled(UserBundleDetailModel)`
  #root & {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > .username {
      margin: 0;
      margin-bottom: 1rem;
      font-size: 1.5rem;
      color: ${bluePD};
      width: 100%;
      text-align: center;
    }
    > .building {
      margin: 0;
      font-size: 2rem;
      width: 100%;
      text-align: center;
    }
    > .info {
      margin: 0;
      padding: 0;
      display: inline-block;
      width: 95%;
      > .line {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: space-between;
        > .title {
          text-align: left;
          font-weight: bold;
        }
        > .content {
          text-align: right;
          color: ${grey_6};
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    > .stats {
      margin: 0;
      margin-top: 1rem;
      padding: 0;
      width: 95%;
      > .title {
        margin: 0;
        font-size: 1.5rem;
        font-weight: bold;
      }
      > .table {
        margin: 0;
      }
    }
    @media (max-width: 800px) {
      > .building {
        font-size: 1.2rem;
      }
      > .username {
        font-size: 1rem;
      }
      > .info {
        font-size: 0.8rem;
      }
      > .stats {
        > .title {
          font-size: 1rem;
        }
        > .table {
          font-size: 0.8rem;
        }
      }
    }
  }
`;
