import { useState } from "react";

const useViewType = (initialView, trigger = 800) => {
  const [view, setView] = useState(initialView);
  return [
    view,
    () => {
      let documentElement = document.documentElement,
        width = window.innerWidth || documentElement.clientWidth;
      if (width < trigger) {
        setView("phone");
      } else {
        setView("desktop");
      }
    },
  ];
};

export { useViewType };
