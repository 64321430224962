import styled from "styled-components";
import { ConfirmationModel } from "./model";

const Confirmation = styled(ConfirmationModel)`
  #root & {
    margin-top: 1rem;
    text-align: center;
    font-size: 1rem;
    justify-content: center;
    > .info {
      > .ticket-number {
        > span {
          color: #63c0e8;
          font-size: 1rem;
          text-align: center;
        }
      }
      > .username {
        > span {
          font-size: 1rem;
          text-align: center;
        }
      }
    }
    > .dropdown {
      floating: left;
      margin-top: 2rem;
      z-index: 400;
    }
  }
`;

export { Confirmation };
