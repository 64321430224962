import HistoryFormModel from "./model";
import styled from "styled-components";
import { grey_4 } from "assets/strings/colors";

const HistoryForm = styled(HistoryFormModel)`
  #root & {
    padding: 0 1rem 1rem 1rem;
    border-bottom: 1px solid ${grey_4};
    > .options {
      padding: 0 1rem;
      padding-top: 1rem;
      display: flex;
      alifgn-items: center;
      justify-content: flex-end;
      width: 100%;
    }
    > .dateform {
      padding: 0 1rem;
      display: flex;
      alifgn-items: center;
      justify-content: flex-start;
      > .date-label {
        display: flex;
        flex-direction: column;
        justify-content: center;
        aling-items: center;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 0.8rem;
        width: 20%;
        margin: 0;
      }
    }
  }
`;

export default HistoryForm;
