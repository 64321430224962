import { useEffect, useContext } from "react";
import { DiscountCodesContext } from "../context";

const useCreateNewCode = () => {
  const { asyncNewCode, state, changeState } = useContext(DiscountCodesContext);
  useEffect(() => {
    if (state.newCode.submit) {
      changeState({ newCode: { ...state.newCode, submit: false } });
      asyncNewCode();
    }
  }, [state.newCode.submit]);
};

export default useCreateNewCode;
