import React, { useContext } from "react";
import { Table } from "semantic-ui-react";
import { formatPrice } from "services/utils";
import { AdminTransfersContext } from "../../context";
import { Loader } from "components/loader";
import moment from "moment-timezone";

export const AccountsInfoModel = ({ dateFrom, className }) => {
  const {
    state: { bankList: banks, loadingAmounts },
  } = useContext(AdminTransfersContext);
  const data = {
    title: "Bancamiga",
    date: `${moment(dateFrom).tz("America/Caracas").format("ll")}`,
    nameHeader: "Nombre",
    accountNumberHeader: "Número de cuenta",
    fundsHeader: "Fondos",
    bankList: banks && banks.length ? [...banks] : [],
  };

  if (loadingAmounts)
    return (
      <div className={className}>
        <Loader margin="-20rem 0 0 0" />
      </div>
    );
  const bankComponentsList = data.bankList.map((bank) => {
    const bankData = {
      name: bank && bank.name ? bank.name : "--",
      accountNumber: bank && bank.account_number ? bank.account_number : 0,
      funds: `${formatPrice(bank && bank.funds ? bank.funds : 0)} Bs.`,
    };
    return (
      <Table.Row key={bank.name}>
        <Table.Cell>{bankData.name}</Table.Cell>
        <Table.Cell>{bankData.accountNumber}</Table.Cell>
        <Table.Cell>{bankData.funds}</Table.Cell>
      </Table.Row>
    );
  });

  return (
    <div className={className}>
      <div className="header">
        <p className="title">{data.title}</p>
        <p className="date">{data.date}</p>
      </div>
      <div className="table">
        <Table celled color={"green"}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{data.nameHeader}</Table.HeaderCell>
              <Table.HeaderCell>{data.accountNumberHeader}</Table.HeaderCell>
              <Table.HeaderCell>{data.fundsHeader}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{bankComponentsList}</Table.Body>
        </Table>
      </div>
    </div>
  );
};
