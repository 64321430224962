import React from "react";
import moment from "moment";
import "moment/locale/es-us";
import { CSSTransition } from "react-transition-group";
import okCircleIcon from "assets/img/ok-circle-icon-64px.png";
import isotipoPd from "assets/img/logotipo-pd-app.png";
import defaultUser from "assets/img/default-user-grey.png";
import excamCircleIcon from "assets/img/exclam-circle-icon-64px.png";
import closeCircleIcon from "assets/img/close-circle-icon-64px.png";
import bellCircleIcon from "assets/img/bell-circle-icon-64px.png";
import { formatPrice } from "services/utils";
import {
  MovementListItem,
  MovementDetail,
  MovementDetailPhone,
  MovementPhoneListItem,
  MovementListHeader,
  NoMovementFound,
} from "../movements-list-item";

moment.locale("es-us");

const MovementListItemModel = ({
  handleMovementSelection,
  movementDate,
  counterpart,
  commerceName,
  totalAmount,
  currency,
  type,
  transactionTag,
  status,
  index,
  className,
  style,
}) => {
  if (!currency) currency = "BS";
  return (
    <dd
      id={index}
      className={className}
      onClick={handleMovementSelection}
      style={style}
    >
      <div className="period-section">
        <p className="date">{movementDate}</p>
      </div>

      {type === "debit" && (
        <p className="amount-debit">
          {"-" + formatPrice(totalAmount) + " " + currency}
        </p>
      )}
      {type === "credit" && (
        <p className="amount-credit">
          {formatPrice(totalAmount) + " " + currency}
        </p>
      )}

      <div className="status">
        {status === "approved" && type === "credit" && (
          <img className="image" src={okCircleIcon} alt="approved" />
        )}
        {status === "reversed" && type === "credit" && (
          <img className="image" src={okCircleIcon} alt="approved" />
        )}
        {status === "approved" &&
          type === "debit" &&
          transactionTag !== "withdraw" && (
            <img src={excamCircleIcon} alt="approved" className="image" />
          )}
        {status === "approved" &&
          type === "debit" &&
          transactionTag === "withdraw" && (
            <img src={okCircleIcon} alt="approved" className="image red" />
          )}
        {status === "pending" && (
          <img className="image" src={bellCircleIcon} alt="pending" />
        )}
        {status === "rejected" && (
          <img className="image" src={closeCircleIcon} alt="rejected" />
        )}
        {type === "credit" && status === "approved" && (
          <p className="approved">Crédito</p>
        )}
        {type === "credit" && status === "reversed" && (
          <p className="approved">Reverso</p>
        )}
        {type === "credit" && status === "pending" && (
          <p className="pending">Crédito-pendiente</p>
        )}
        {type === "debit" &&
          (transactionTag === "withdraw" ? (
            <p className={status === "pending" ? "pending" : "debit"}>
              {" "}
              {status === "pending" ? "Retiro-Pendiente" : "Retiro"}
            </p>
          ) : (
            <p className="debit">Débito</p>
          ))}
      </div>
      <div className="user">
        {counterpart === "pagodirecto" && (
          <img src={isotipoPd} alt="paid" className="user" />
        )}
        {counterpart === "pagodirecto" && (
          <p className="user">{"pagoDirecto"}</p>
        )}

        {counterpart !== "pagodirecto" && (
          <img src={defaultUser} alt="paid" className="user" />
        )}
        {counterpart !== "pagodirecto" && counterpart !== null && (
          <p className="user">{"@" + counterpart}</p>
        )}
        {!counterpart && <p className="user">{commerceName} </p>}
      </div>
    </dd>
  );
};

const MovementPhoneListItemModel = ({
  handleMovementSelection,
  movementDate,
  counterpart,
  totalAmount,
  transactionTag,
  currency,
  type,
  status,
  index,
  style,
  className,
}) => {
  return (
    <dd
      id={index}
      className={className}
      onClick={handleMovementSelection}
      style={style}
    >
      {status === "approved" && type === "credit" && (
        <img className="status image" src={okCircleIcon} alt="approved" />
      )}
      {status === "reversed" && type === "credit" && (
        <img className="status image" src={okCircleIcon} alt="approved" />
      )}
      {status === "approved" &&
        type === "debit" &&
        !transactionTag === "withdraw" && (
          <img src={excamCircleIcon} alt="approved" className="image" />
        )}
      {status === "approved" &&
        type === "debit" &&
        transactionTag === "withdraw" && (
          <img src={okCircleIcon} alt="approved" className="image red" />
        )}
      {status === "pending" && (
        <img className="status image" src={bellCircleIcon} alt="pending" />
      )}
      {status === "rejected" && (
        <img className="status image" src={closeCircleIcon} alt="rejected" />
      )}

      <p className="title">Fecha</p>
      <p>{movementDate}</p>
      <p className="title">Monto</p>

      {type === "debit" && (
        <p>{"-" + formatPrice(totalAmount) + " " + currency}</p>
      )}
      {type === "credit" && (
        <p className="amount-credit">
          {formatPrice(totalAmount) + " " + currency}
        </p>
      )}
      {counterpart === "pagodirecto" && (
        <img src={isotipoPd} alt="paid" className="status" />
      )}
      {counterpart !== "pagodirecto" && (
        <img src={defaultUser} alt="paid" className="status" />
      )}
      <p className="title"> Usuario</p>
      {counterpart === "pagodirecto" && <p>{"pagoDirecto"}</p>}
      {counterpart !== "pagodirecto" && <p>{"@" + counterpart}</p>}
    </dd>
  );
};

const MovementDetailModel = ({
  handleMovementSelection,
  movement,
  movementHour,
  children,
  index,
  className,
}) => {
  return (
    <div id={index} onClick={handleMovementSelection} className={className}>
      {children}
      <div className="header">
        <p className="col hundred">Detalles</p>
      </div>
      <div className="header">
        <p className="col fifty">hora:</p>

        {movement.type === "credit" && (
          <p className="col twenty-five">Estado:</p>
        )}
        {movement.type === "credit" ? (
          <p className="col twenty-five">Referencia:</p>
        ) : (
          <p className="col fifty">Referencia:</p>
        )}
      </div>
      <div className="item">
        <p className="col fifty">{movementHour}</p>
        {movement.status === "approved" && movement.type === "credit" && (
          <p className="col twenty-five"> Disponible</p>
        )}
        {movement.status === "reversed" && movement.type === "credit" && (
          <p className="col twenty-five"> Reverso</p>
        )}
        {movement.status === "pending" && movement.type === "credit" && (
          <p className="col twenty-five"> Pendiente</p>
        )}
        {movement.status === "rejected" && movement.type === "credit" && (
          <p className="col twenty-five"> Sin efecto</p>
        )}
        {movement.type === "credit" ? (
          <p className="col twenty-five">{movement.reference}</p>
        ) : (
          <p className="col fifty">{movement.reference}</p>
        )}
      </div>
      {movement.transaction_tag === "fuel" && (
        <div className="header">
          <p className="col fifty">Tipo:</p>
          <p className="col twenty-five">Litros:</p>
          <p className="col twenty-five">Surtidor:</p>
        </div>
      )}
      {movement.transaction_tag === "fuel" && (
        <div className="item">
          <p className="col fifty">{movement.fuel_details.fuel_type}</p>
          <p className="col twenty-five">{movement.fuel_details.liters}</p>
          <p className="col twenty-five">{movement.fuel_details.pump}</p>
        </div>
      )}
      <div className="header">
        <p className="col hundred">Descripción:</p>
      </div>
      <div className="item">
        <p className="col hundred">{movement.description}</p>
      </div>
    </div>
  );
};
const MovementDetailPhoneModel = ({
  handleMovementSelection,
  movement,
  movementHour,
  children,
  index,
  className,
}) => {
  return (
    <div id={index} onClick={handleMovementSelection} className={className}>
      {children}
      <div className="header">
        {movement.type === "credit" ? (
          <p className="col thirty">hora:</p>
        ) : (
          <p className="col fifty">hora:</p>
        )}
        {movement.type === "credit" ? (
          <p className="col twenty">tipo</p>
        ) : (
          <p className="col twenty-five">tipo</p>
        )}

        {movement.type === "credit" && (
          <p className="col twenty-five">Estado:</p>
        )}
        <p className="col twenty-five">Referencia:</p>
      </div>
      <div className="item">
        {movement.type === "credit" ? (
          <p className="col thirty">{movementHour}</p>
        ) : (
          <p className="col fifty">{movementHour}</p>
        )}
        {movement.type === "credit" ? (
          <p className="col twenty">Crédito</p>
        ) : movement.transaction_tag === "withdraw" ? (
          <p className="col twenty-five">Retiro</p>
        ) : (
          <p className="col twenty-five">Débito</p>
        )}
        {movement.status === "approved" && movement.type === "credit" && (
          <p className="col twenty-five"> Disponible</p>
        )}
        {movement.status === "reversed" && movement.type === "credit" && (
          <p className="col twenty-five"> Reverso</p>
        )}
        {movement.status === "pending" && (
          <p className="col twenty-five"> Pendiente</p>
        )}
        {movement.status === "rejected" && (
          <p className="col twenty-five"> Sin efecto</p>
        )}
        <p className="col twenty-five">{movement.reference}</p>
      </div>
      {movement.transaction_tag === "fuel" && (
        <div className="header">
          <p className="col fifty">Tipo:</p>
          <p className="col twenty-five">Litros:</p>
          <p className="col twenty-five">Surtidor:</p>
        </div>
      )}
      {movement.transaction_tag === "fuel" && (
        <div className="item">
          <p className="col fifty">{movement.fuel_details.fuel_type}</p>
          <p className="col twenty-five">{movement.fuel_details.liters}</p>
          <p className="col twenty-five">{movement.fuel_details.pump}</p>
        </div>
      )}
      <div className="header">
        <p className="col hundred">Descripción:</p>
      </div>
      <div className="item">
        <p className="col hundred">{movement.description}</p>
      </div>
    </div>
  );
};

const MovementListHeaderModel = ({ className }) => {
  return (
    <dd className={className}>
      <p className="col twenty-five">Fecha</p>
      <p className="col twenty-five">Monto</p>
      <p className="col twenty-five">Tipo</p>
      <p className="col twenty-five">Usuario</p>
    </dd>
  );
};

const NoMovementFoundModel = ({ className }) => {
  return <p className={className}>No se han encontrado transacciones</p>;
};

function formatMovementsList(
  movements,
  viewState,
  handleMovementSelection,
  commerceName
) {
  // Declaramos el array que devolverá la función
  let movementsList = [];
  let formatString = "DD [de] MMMM YYYY";
  let formatHourString = "hh:mm:ss a";
  if (
    viewState === "movements_pagination_loading" ||
    viewState === "movements_search_loading"
  ) {
    return (
      <div
        className="ui active loader"
        style={{
          marginTop: "3rem",
          position: "relative",
        }}
      ></div>
    );
  }
  if (viewState === "movements_search_done") {
    if (!movements[0].length) {
      return <NoMovementFound />;
    } else {
      movementsList.push(<MovementListHeader key="header" />);
      movements[0].forEach((movement, index) => {
        let movementDate = moment(movement.created_at).format(formatString);
        movementsList.push(
          <MovementListItem
            handleMovementSelection={handleMovementSelection}
            viewState={viewState}
            movementDate={movementDate}
            counterpart={
              movement.counterpart ? movement.counterpart.username : null
            }
            commerceName={commerceName}
            totalAmount={movement.pd_amount}
            currency={movement.currency}
            type={movement.type}
            transactionTag={movement.transaction_tag}
            status={movement.status}
            index={index}
            key={movement._id}
          />
        );
      });

      return movementsList;
    }
  }
  if (viewState === "movement_selection_done") {
    if (!movements[0].length && !movements[1].length) {
      return <NoMovementFound />;
    } else {
      movementsList.push(<MovementListHeader key="header" />);

      movements[0].forEach((movement, index) => {
        let movementDate = moment(movement.created_at).format(formatString);
        let movementHour = moment(movement.created_at).format(formatHourString);
        if (movement !== movements[1]) {
          movementsList.push(
            <MovementListItem
              handleMovementSelection={handleMovementSelection}
              movementDate={movementDate}
              counterpart={
                movement.counterpart ? movement.counterpart.username : null
              }
              commerceName={commerceName}
              totalAmount={movement.pd_amount}
              currency={movement.currency}
              type={movement.type}
              status={movement.status}
              transactionTag={movement.transaction_tag}
              index={index}
              key={movement._id}
            />
          );
        } else {
          movementsList.push(
            <CSSTransition
              in={viewState === "movement_selection_done"}
              mountOnEnter
              unmountOnExit
              timeout={350}
              className="Panel"
              key={movement._id}
            >
              <MovementDetail
                handleMovementSelection={handleMovementSelection}
                movement={movement}
                movementHour={movementHour}
                index={index}
                /* key={movement._id} */
              >
                <MovementListItem
                  viewState={viewState}
                  movementDate={movementDate}
                  counterpart={
                    movement.counterpart ? movement.counterpart.username : null
                  }
                  commerceName={commerceName}
                  totalAmount={movement.pd_amount}
                  currency={movement.currency}
                  type={movement.type}
                  transactionTag={movement.transaction_tag}
                  status={movement.status}
                  index={index}
                  style={{ borderRadius: "0", borderBottom: "0" }}
                />
              </MovementDetail>
            </CSSTransition>
          );
        }
      });

      return movementsList;
    }
  }
}

function formatMovementsPhoneList(
  movements,
  viewState,
  handleMovementSelection,
  commerceName
) {
  // Declaramos el array que devolverá la función
  let movementsList = [];
  let formatString = "DD / MM / YYYY";
  let formatHourString = "hh:mm:ss a";
  if (viewState === "movements_pagination_loading") {
    return (
      <div
        className="ui active loader"
        style={{
          marginTop: "3rem",
          position: "relative",
        }}
      ></div>
    );
  }

  if (viewState === "movements_search_done") {
    if (!movements[0].length) {
      return <NoMovementFound />;
    } else {
      movements[0].forEach((movement, index) => {
        let movementDate = moment(movement.created_at).format(formatString);

        movementsList.push(
          <MovementPhoneListItem
            handleMovementSelection={handleMovementSelection}
            movementDate={movementDate}
            counterpart={
              movement.counterpart ? movement.counterpart.username : null
            }
            commerceName={commerceName}
            totalAmount={movement.pd_amount}
            currency={movement.currency}
            type={movement.type}
            status={movement.status}
            index={index}
            key={movement._id}
          />
        );
      });
      return movementsList;
    }
  }
  if (viewState === "movement_selection_done") {
    if (!movements[0].length && !movements[1].length) {
      return <NoMovementFound />;
    } else {
      movements[0].forEach((movement, index) => {
        let movementDate = moment(movement.created_at).format(formatString);
        let movementHour = moment(movement.created_at).format(formatHourString);
        if (movement !== movements[1]) {
          movementsList.push(
            <MovementPhoneListItem
              handleMovementSelection={handleMovementSelection}
              movementDate={movementDate}
              counterpart={
                movement.counterpart ? movement.counterpart.username : null
              }
              commerceName={commerceName}
              totalAmount={movement.pd_amount}
              currency={movement.currency}
              transactionTag={movement.transaction_tag}
              type={movement.type}
              status={movement.status}
              index={index}
              key={movement._id}
            />
          );
        } else {
          movementsList.push(
            <CSSTransition
              in={viewState === "movement_selection_done"}
              mountOnEnter
              unmountOnExit
              timeout={350}
              className="Panel"
              key={movement._id}
            >
              <MovementDetailPhone
                handleMovementSelection={handleMovementSelection}
                movement={movement}
                movementHour={movementHour}
                index={index}
                phone={true}
              >
                <MovementPhoneListItem
                  movementDate={movementDate}
                  counterpart={
                    movement.counterpart ? movement.counterpart.username : null
                  }
                  commerceName={commerceName}
                  transactionTag={movement.transaction_tag}
                  totalAmount={movement.pd_amount}
                  currency={movement.currency}
                  type={movement.type}
                  status={movement.status}
                  index={index}
                  style={{ borderRadius: "0", borderBottom: "0" }}
                />
              </MovementDetailPhone>
            </CSSTransition>
          );
        }
      });

      return movementsList;
    }
  }
}

export {
  MovementListItemModel,
  MovementDetailModel,
  MovementDetailPhoneModel,
  MovementPhoneListItemModel,
  MovementListHeaderModel,
  NoMovementFoundModel,
  formatMovementsList,
  formatMovementsPhoneList,
};
