import { TDCDetailsModel } from "./model";
import styled from "styled-components";
import { grey_2, grey_6 } from "assets/strings/colors";

const TDCDetails = styled(TDCDetailsModel)`
  #root && {
    margin: 0;
    width: 100%;
    max-height: 16vh;
    > .header {
      margin: 0;
      margin-top: 1rem;
      margin-bottom: 0.5rem;
      width: 30%;
      text-align: left;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.85rem;
      color: ${grey_6};
      border-bottom: 1px ${grey_6} solid;
    }
    > .content {
      margin: 0;
      width: 100%;
      max-height: 13vh;
      overflow-y: scroll;
      display: flex;
      flex-flow: wrap;
      justify-content: center;
      align-items: center;
      background: ${grey_2};
      border-radius: 0.2rem;

      > .title {
        text-align: left;
        font-weight: bold;
        width: calc(50% - 1rem);
      }
      > .item {
        text-align: right;
        width: calc(50% - 1rem);
      }
    }
  }
`;

export { TDCDetails };
