import React from "react";
import { Dropdown } from "semantic-ui-react";
import { CSSTransition } from "react-transition-group";

const BoxofficeFormModel = ({
  buildingOptions,
  building,
  username,
  genPromoTicket,
  handleBuilding,
  handleUsername,
  handleSubmit,
  handleGenPromoTicket,
  className,
}) => {
  return (
    <div className={className}>
      <CSSTransition
        in={true}
        appear={true}
        timeout={350}
        classNames="register"
      >
        <form className="register" onSubmit={handleSubmit}>
          <p className="label">Nombre de usuario sin prefijo</p>
          <input
            required
            maxLength={25}
            type="text"
            placeholder="Usuario"
            value={username}
            onChange={handleUsername}
          />
          <p className="label">Edificio</p>
          <Dropdown
            className="label-drop"
            name="building"
            options={buildingOptions}
            value={building}
            onChange={handleBuilding}
            selection
          />
          <div className="check">
            <input
              type="checkbox"
              id="agree"
              checked={genPromoTicket}
              onChange={handleGenPromoTicket} //To fix warning
            />
            <label htmlFor="agree"> Generar ticket de promoción</label>
          </div>
          <button>Registrar</button>
        </form>
      </CSSTransition>
    </div>
  );
};

export { BoxofficeFormModel };
