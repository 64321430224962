import React from "react";
import { Grid } from "semantic-ui-react";
import { Panel } from "components/panel";
import { InvalidUser } from "./invalid-user";
import SuccessDigitize from "./success-digitize";
import SearchInputNew from "components/search-input-new";
import { PanelPlaceholder } from "components/panel";
import PopupMessage from "components/popup-message";
import { CSSTransition } from "react-transition-group";
import { EntitiesList } from "components/entities-list";
import { DigitizeTicketForm } from "./digitize-ticket-form";
import { formatUserList } from "../components/user-list-item";
import { DigitizeConfirmation } from "./digitize-confirmation";
import { RightBorderGreyColumn } from "components/styled-grid-column";

const MposTicketsDigitizeView = ({
  viewState,
  role,
  category,
  searchParam,
  users,
  showResponsePanel,
  commerce,
  selectedUser,
  ticketPaid,
  premiumObj,
  newTicketReference,
  newTicketDate,
  newTicketHour,
  newTicketMinute,
  generatedTicket,
  buildingName,
  showGeneratedTicket,
  digitizedBy,
  popupMessages,
  popupMessagesType,
  showPopup,
  location,
  handleSearchInputChange,
  handleUserSelection,
  handleTicketReference,
  handleTicketDate,
  handleTicketHour,
  handleTicketMinute,
  handleSearchUser,
  toggleDigitizeConfirmation,
  digitizeTicket,
  closePopup,
}) => {
  return (
    <Grid padded>
      {!(
        viewState === "ticket_digitize_confirmation" ||
        viewState === "ticket_digitize_loading"
      ) && (
        // If not on confirmation process, show popup
        <PopupMessage
          messageType={popupMessagesType}
          showPopup={showPopup}
          messages={popupMessages}
          closePopup={closePopup}
        />
      )}

      {(viewState === "ticket_digitize_confirmation" ||
        viewState === "ticket_digitize_loading") && (
        // If view IS on confirmation process, show confirmation popup
        <PopupMessage
          messageType={popupMessagesType}
          showPopup={showPopup}
          messages={popupMessages}
          closePopup={toggleDigitizeConfirmation}
        >
          <DigitizeConfirmation
            viewState={viewState}
            selectedUser={selectedUser}
            newTicketReference={newTicketReference}
            newTicketDate={newTicketDate}
            newTicketHour={newTicketHour}
            newTicketMinute={newTicketMinute}
            digitizeTicket={digitizeTicket}
            cancel={toggleDigitizeConfirmation}
          />
        </PopupMessage>
      )}

      <RightBorderGreyColumn tablet={4} computer={6}>
        <SearchInputNew
          placeholder="Buscar Usuario"
          searchParam={searchParam}
          handleChange={handleSearchInputChange}
          handleSearch={handleSearchUser}
        />
        <EntitiesList
          height="calc(100vh - 9rem)"
          viewState={viewState}
          entities={users}
          formattingFunction={formatUserList}
          selectionHandler={handleUserSelection}
        />
      </RightBorderGreyColumn>

      <Grid.Column tablet={6} computer={5}>
        {(viewState === "user_search" ||
          viewState === "user_search_loading" ||
          viewState === "user_search_done") && (
          <PanelPlaceholder>
            Seleccione un usuario para digitalizar su ticket
          </PanelPlaceholder>
        )}

        <CSSTransition
          in={showResponsePanel}
          mountOnEnter
          unmountOnExit
          timeout={350}
          appear={true}
          classNames="panel"
        >
          <Panel
            className={
              (viewState === "user_selection_loading" ||
                viewState === "ticket_digitize_confirmation" ||
                viewState === "ticket_digitize_loading" ||
                viewState === "ticket_digitize_done") &&
              "inactive"
            }
          >
            {viewState === "user_selection_loading" && (
              <div className="ui loader active"></div>
            )}

            {viewState === "user_selection" && (
              <InvalidUser
                viewState={viewState}
                username={selectedUser.username}
              />
            )}

            {(viewState === "user_selection_done" ||
              viewState === "ticket_digitize_confirmation" ||
              viewState === "ticket_digitize_loading" ||
              viewState === "ticket_digitize_done") && (
              <DigitizeTicketForm
                viewState={viewState}
                selectedUser={selectedUser}
                newTicketReference={newTicketReference}
                newTicketDate={newTicketDate}
                newTicketHour={newTicketHour}
                newTicketMinute={newTicketMinute}
                buildingName={buildingName}
                // handlers
                handleTicketReference={handleTicketReference}
                handleTicketDate={handleTicketDate}
                handleTicketHour={handleTicketHour}
                handleTicketMinute={handleTicketMinute}
                toggleDigitizeConfirmation={toggleDigitizeConfirmation}
              />
            )}
          </Panel>
        </CSSTransition>
      </Grid.Column>

      <Grid.Column tablet={6} computer={5}>
        {(viewState === "user_selection_done" ||
          viewState === "ticket_digitize_confirmation" ||
          viewState === "ticket_digitize_loading") && (
          <PanelPlaceholder>
            Ingrese la información del ticket físico y presione "Digitalizar
            ticket"
          </PanelPlaceholder>
        )}

        <CSSTransition
          in={showGeneratedTicket || viewState === "user_selection"}
          mountOnEnter
          unmountOnExit
          timeout={350}
          appear={true}
          classNames="panel"
        >
          <Panel>
            {(viewState === "ticket_digitize_done" ||
              viewState === "user_selection") && (
              <SuccessDigitize
                viewState={viewState}
                username={selectedUser.username}
                ticketPaid={ticketPaid}
                premiumObj={premiumObj}
                commerce={commerce}
                role={role}
                category={category}
                generatedTicket={generatedTicket}
                digitizedBy={digitizedBy}
              />
            )}
          </Panel>
        </CSSTransition>
      </Grid.Column>
    </Grid>
  );
};

export default MposTicketsDigitizeView;
