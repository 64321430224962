import React, { useState } from "react";
import { Button } from "components/buttons";
import { Dropdown } from "components/dropdown";

const ConfirmationModel = ({
  handleSubmit,
  handleClose,
  text,
  withdraw = false,
  bankAccounts,
  withdrawData,
  className,
}) => {
  let bankAccountsToSelect = [];
  if (withdraw) {
    bankAccounts.forEach((account) => {
      bankAccountsToSelect.push({
        key: account._id,
        text: account.account_name,
        value: account.account_name,
      });
    });
  }
  const [accountName, setAccountName] = useState("");

  const handleAccountName = (event, data) => {
    event.preventDefault();
    setAccountName(data.value);
    data.options.forEach((option) => {
      if (option.value === data.value) {
        withdrawData(option.key);
      }
    });
  };

  return (
    <div className={className}>
      <div>{text ? text : "¿Desea continuar?"}</div>
      {withdraw && (
        <Dropdown
          selection
          selectOnBlur={false}
          placeholder="Cuentas Bancarias"
          name="Banco"
          value={accountName}
          options={bankAccountsToSelect}
          onChange={handleAccountName}
        />
      )}
      <Button color="blue" onClick={handleSubmit}>
        Sí
      </Button>
      <Button color="grey_1" onClick={handleClose}>
        No
      </Button>
    </div>
  );
};

export { ConfirmationModel };
