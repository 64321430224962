import React from "react";
// import { Items } from "./items";
import styled from "styled-components";
import { grey_bg } from "assets/strings/colors";
import UserStats from "../users";
import ParkingStats from "../parking";

const DashboardViewModel = ({
  item,
  handleItemChange,
  viewType,
  className,
}) => {
  return (
    <div className={className}>
      {/* <Items handleChange={handleItemChange} item={item} /> */}
      {item === "users" && <UserStats viewType={viewType} />}
      {item === "parking" && <ParkingStats viewType={viewType} />}
    </div>
  );
};

const DashboardView = styled(DashboardViewModel)`
  #root && {
    width: 100%;
    height: calc(100vh - 4rem);
    min-height: 700px;
    background: ${grey_bg};
  }
`;

export { DashboardView };
