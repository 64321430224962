import BuildingListItemModel from "./model";
import styled from "styled-components";
import { grey_4 } from "assets/strings/colors";

const BuildingListItem = styled(BuildingListItemModel)`
    border-bottom: solid 1px ${grey_4};
    cursor: pointer;
    padding: .5rem 1rem;
    margin: 0;
    position: relative;
    >.data{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      >.name{
        color: #63c0e8;
        font-size: 1.2rem;
        font-weight: bold;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      >.fee{
        color: #454545;
        font-size: .9rem;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
`;
export default BuildingListItem;
