import MobilePaidsListModel from "./model";
import styled from "styled-components";

const MobilePaidsList = styled(MobilePaidsListModel)`
  #root & {
    margin: 0;
  }
`;

export default MobilePaidsList;
