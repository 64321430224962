import React from "react";
import { Panel } from "components/panel";
import { Loader } from "components/loader";
import { Grid } from "semantic-ui-react";
import PopupMessage from "components/popup-message";
import { FlatForm } from "./flat-form";
import { PerhourForm } from "./perhour-form";
import { CustomForm } from "./custom-form";
import { CurrencyViewer } from "./currency-viewer";

const MposParkingSettingsView = ({
  activeMode,
  billingObj,
  loading,
  //
  currency,
  flatAmount,
  hourAmount,
  secondHourAmount,
  thirdHourAmount,
  extraAmount,
  overnightAmount,
  //
  handleInputFlatAmount,
  handleInputHourAmount,
  handleInputSecondHourAmount,
  handleInputThirdHourAmount,
  handleInputExtraAmount,
  handleInputOvernightAmount,
  //
  handleFlatSubmit,
  handlePerHourSubmit,
  handleCustomSubmit,
  //
  popupMessages,
  popupMessagesType,
  showPopup,
  closePopup,
}) => {
  return (
    <Grid centered padded>
      <PopupMessage
        messageType={popupMessagesType}
        showPopup={showPopup}
        messages={popupMessages}
        closePopup={closePopup}
      ></PopupMessage>

      <Grid.Column mobile={16} tablet={14} computer={10}>
        <Panel>
          {loading && <Loader />}
          {!loading && <CurrencyViewer currency={currency} />}
          {!loading && activeMode === "FLAT" && (
            <FlatForm
              billingObj={billingObj}
              flatAmount={flatAmount}
              overnightAmount={overnightAmount}
              handleInputFlatAmount={handleInputFlatAmount}
              handleInputOvernightAmount={handleInputOvernightAmount}
              handleFlatSubmit={handleFlatSubmit}
            />
          )}
          {!loading && activeMode === "PER_HOUR_CUSTOM" && (
            <CustomForm
              overnightActive={billingObj.overnight}
              billingObj={billingObj}
              flatAmount={flatAmount}
              hourAmount={hourAmount}
              secondHourAmount={secondHourAmount}
              thirdHourAmount={thirdHourAmount}
              extraAmount={extraAmount}
              overnightAmount={overnightAmount}
              handleInputFlatAmount={handleInputFlatAmount}
              handleInputHourAmount={handleInputHourAmount}
              handleInputSecondHourAmount={handleInputSecondHourAmount}
              handleInputThirdHourAmount={handleInputThirdHourAmount}
              handleInputExtraAmount={handleInputExtraAmount}
              handleInputOvernightAmount={handleInputOvernightAmount}
              handleCustomSubmit={handleCustomSubmit}
            />
          )}
          {!loading && activeMode === "PER_HOUR" && (
            <PerhourForm
              billingObj={billingObj}
              hourAmount={hourAmount}
              overnightAmount={overnightAmount}
              handleInputHourAmount={handleInputHourAmount}
              handlePerHourSubmit={handlePerHourSubmit}
              handleInputOvernightAmount={handleInputOvernightAmount}
            />
          )}
        </Panel>
      </Grid.Column>
    </Grid>
  );
};

export { MposParkingSettingsView };
