import styled from "styled-components";
import { grey_4, grey_6 } from "assets/strings/colors";
import { WithdrawPaymentFormModel } from "./model";

const WithdrawPaymentForm = styled(WithdrawPaymentFormModel)`
  #root & {
    width: 100%;
    > .title {
      text-transform: uppercase;
      font-weight: bold;
      color: ${grey_6};
      margin: 0.2rem 0.5rem 1rem 0.5rem;
    }

    > .invoice-payment-form {
      > .date-pickers {
        margin 0; 
        margin-top: 0.7rem;
        display: none;
        align-items: center;
        justify-content: space-between;
        > p {
          margin: 0;
          font-weight: bold;
        }
        > div {
          width: 100%;
          > .react-datepicker__input-container {
            width: 100%;
            > input {
              width: calc(100% - 16px);
              border: none;
              border-radius: 1rem;
              color: ${grey_6};
              font-size: 1.2rem;
              margin: 0.5rem 5px 0.5rem 5px;
              text-align: center;
              outline: none;
              box-shadow: none;
              transition: box-shadow 250ms linear;
              :focus {
                box-shadow: 0 1px darkgrey;
              }
            }
            > .react-datepicker__close-icon::after {
              right: 0;
            }
          }
          > .react-datepicker-wrapper {
            width: 100%;
            > .react-datepicker__input-container {
              width: 100%;
              > input {
                width: calc(100% - 16px);
                border: none;
                border-radius: 1rem;
                color: ${grey_6};
                font-size: 1.2rem;
                margin: 0.5rem 5px 0.5rem 5px;
                text-align: center;
                outline: none;
                box-shadow: none;
                transition: box-shadow 250ms linear;
                :focus {
                  box-shadow: 0 1px darkgrey;
                }
              }
              > .react-datepicker__close-icon::after {
                right: 15px;
              }
            }
          }
        }
        > div:last-child {
          > .react-datepicker-popper {
            > .react-datepicker {
              > .react-datepicker__triangle {
                right: 50px;
                left: auto;
              }
            }
          }
        }
      }
      > .input-section {
        text-align: left;
        margin: 0.5rem 0 0.5rem 0;
        > .ui.selection.dropdown {
          display: block;
        }

        > input {
          border: none;
          width: 98%;
          min-width: 0;
          border-radius: 1rem;
          color: ${grey_6};
          font-size: 1.5rem;
          margin: 0.5rem 5px 0.5rem 5px;
          text-align: center;
          outline: none;
          box-shadow: none;
          transition: box-shadow 250ms linear;
          :focus {
            box-shadow: 0 1px ${grey_4};
          }
          ::-webkit-input-placeholder {
            color: lightgray;
          }
          :-ms-input-placeholder {
            color: lightgray;
          }
          ::-ms-input-placeholder {
            color: lightgray;
          }
          ::placeholder {
            color: lightgray;
          }
        }
        > label {
          font-size: 0.85rem;
          border-bottom: 1px solid ${grey_6};
          display: inline-block;
          text-transform: uppercase;
          font-weight: bold;
          color: ${grey_6};
          margin-bottom: 0.5rem;
        }
      }
    }
  }
`;
export { WithdrawPaymentForm };
