import React from "react";
import { Button } from "components/buttons";
const EditNumberFormModel = ({
  value,
  handleChange,
  toggleValidate,
  className,
}) => {
  return (
    <div className={className}>
      <p>Edite el número de contacto</p>
      <input
        type="text"
        value={value}
        maxLength={11}
        minLength={11}
        onChange={handleChange}
      />
      <Button
        color="green"
        width={"70%"}
        onClick={toggleValidate}
        disabled={value.length !== 11}
      >
        Aceptar
      </Button>
    </div>
  );
};

export { EditNumberFormModel };
