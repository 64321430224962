import { BillPreviewModel } from "./model";
import styled from "styled-components";

export const BillPreview = styled(BillPreviewModel)`
  #root & {
    display: flex;
    flex-direction: column;
    font-size: 0.8rem;
    > .container {
      margin-top: 4rem;
      width: 90%;
      display: flex;
      min-height: 70vh;
      flex-direction: column;
      justify-content: space-between;
      align-self: center;
      > .header {
        display: flex;
        flex-direction: column;
        > .content {
          display: flex;
          justify-content: space-between;
          padding: 0.5rem 0;
          > .data {
            padding: 0;
            margin: 0;
            font-weight: bold;
            span {
              font-weight: normal;
            }
          }
          > .table {
            margin: 0;
            padding: 0;
            text-transform: uppercase;
            width: 20%;
          }
          > .header {
            font-weight: bold;
          }
          > .left {
            text-align: left;
          }
          > .right {
            text-align: right;
          }
        }
      }
      > .footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        > .titles {
          width: calc(70% - 1rem);
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          p {
            text-transform: uppercase;
            text-align: right;
          }
        }
        > .usd {
          width: calc(30% - 1rem);
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          p {
            text-transform: uppercase;
            text-align: left;
          }
        }
        > .values {
          width: calc(30% - 1rem);
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          p {
            text-align: right;
          }
        }
      }
      > .middle {
        width: 50%;
      }
    }
  }
`;
