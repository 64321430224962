import { reports_server } from "services/config";
import { post } from "services/http-methods";

const endpoints = {
  userParkingTickets: `${reports_server}/user-parking-tickets`,
  ticketsBillCommerce: `${reports_server}/tickets-bill-commerce`,
};

export function userParkingTickets(body) {
  /**
   * @brief
   * @attributes
   *  body = {
   *
   */

  return post(endpoints.userParkingTickets, body);
}

export function ticketsBillCommerce(body) {
  /**
   * @brief
   * @attributes
   *  body = {
   *
   */

  return post(endpoints.ticketsBillCommerce, body);
}
