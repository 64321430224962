export const noTokenRoutes = [
  "/",
  "/signup",
  "/signin",
  "/reset-password",
  "/policies",
];
export const generalRoutes = [
  "/email-verification",
  "/owner-info",
  "/commerce-info",
  "/not-verified-user",
  "/change-password",
];

export const commercePermissions = {
  permission1: [
    "/mpos/dashboard",
    "/mpos/commerce/users",
    "/mpos/commerce/wallet",
    "/mpos/commerce/movements",
  ],
  permission2: ["/mpos/commerce/movements"],
  permission3: ["/mpos/commerce/movements", "/change-password"],
  defaultRoute: "/mpos/commerce/movements",
};

export const monthlyPremiumRoutes = [
  "/mpos/monthly-premium/activate",
  "/mpos/monthly-premium/invoices",
  "/mpos/monthly-premium/history",
];
export const commercePostpaidParkingPermissions = {
  permission1: [
    "/mpos/dashboard",
    "/mpos/commerce/users",
    "/mpos/commerce/wallet",
  ],
  permission2: ["/mpos/invoices/history", "/mpos/tickets/validate"],
  permission3: [
    "/mpos/tickets/history",
    "/change-password",
    "/mpos/commerce/movements",
    "/mpos/commerce/digitize",
  ],
  //can_digitize: ["/mpos/commerce/digitize"],
  defaultRoute: "/mpos/commerce/movements",
};
export const commercePrepaidParkingPermissions = {
  permission1: [
    "/mpos/dashboard",
    "/mpos/commerce/users",
    "/mpos/commerce/wallet",
  ],
  permission2: ["/mpos/tickets/validate"],
  permission3: [
    "/mpos/tickets/history",
    "/change-password",
    "/mpos/commerce/movements",
    "/mpos/commerce/digitize",
  ],
  //can_digitize: ["/mpos/commerce/digitize"],
  defaultRoute: "/mpos/commerce/movements",
};
export const parkingPermissions = {
  permission1: [
    "/mpos/dashboard",
    "/mpos/commerce/users",
    "/mpos/commerce/wallet",
  ],
  permission2: ["/mpos/parking/settings", "/mpos/users/logs", "/mpos/reports"],
  permission3: [
    "/mpos/building/commerces",
    "/mpos/tickets/history",
    "/change-password",
    "/mpos/commerce/movements",
  ],
  can_digitize: ["/mpos/tickets/digitize"],
  defaultRoute: "/mpos/commerce/movements",
};
export const superadminPermissions = {
  admin: [
    "/mpos/commerce/users",
    "/mpos/amount-history",
    "/mpos/wallet/transfer",
    "/mpos/wallet/transfer-history",
    "/mpos/wallet/today-movements",
    "/mpos/wallet/accounts",
  ],
  permission1: [
    "/mpos/dashboard",
    "/mpos/parking/pdfee",
    "/mpos/users/extensions",
    "/mpos/resgister/boxoffice",
    "/mpos/buildings/settings",
    "/mpos/commerces",
    "/mpos/withdraw/history",
    "/mpos/superadmin/bills",
    "/mpos/superadmin/bills/history",
  ],
  permission2: [
    "/mpos/recharges/recharge",
    "/mpos/users/logs",
    "/mpos/reports",
    "/mpos/users/notifications",
    "/mpos/users/notifications/massive",
    "/mpos/users/notifications/discount-codes",
    "/mpos/recharges/validate",
    "/mpos/users/movements/mobile-paid",
    "/mpos/users/movements/transfers",
    "/mpos/tickets/overtime-transactions",
    "/mpos/support",
    "/mpos/invoices/history",
  ],
  permission3: [
    "/change-password",
    "/mpos/users",
    "/mpos/users/register",
    "/mpos/tickets/history",
    "/mpos/tickets/validate",
    "/mpos/tickets/digitize",
  ],
  taquilla: ["/mpos/boxoffice"],
  defaultRoute: "/mpos/tickets/validate",
  //can_digitize: ["/mpos/tickets/digitize"],
};
