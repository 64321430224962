import React from "react";
import { Grid } from "semantic-ui-react";
import { Button } from "components/buttons";
import { Dropdown } from "components/dropdown";
import { RechargesForm } from "./recharges-form";
import PopupMessage from "components/popup-message";
import { CSSTransition } from "react-transition-group";
import { general as texts } from "assets/strings/texts";
import okCircleIcon from "assets/img/ok-circle-icon.png";
import { PanelPlaceholder, Panel } from "components/panel";
import { formatRechargesList } from "./recharge-list-item";
import { RechargeValidation } from "./recharge-validation";
import { ResultScreen } from "components/result-screen/styles";
import { EntitiesList, Pagination } from "components/entities-list";
import { RightBorderGreyColumn } from "components/styled-grid-column";

const RechargesValidateView = ({
  category, // props
  rechargeReasonsOptions,
  viewState,
  username,
  rechargesType,
  pending,
  approved,
  processing,
  rejected,
  recharges,
  currentPage,
  nextPageExist,
  showRecharge,
  selectedRecharge,
  rechargeAmount,
  rechargeStatus,
  rechargeReason,
  rechargeResponseText,
  popupMessages,
  popupMessagesType,
  showPopup,
  location,
  handleUsernameChange, // methods
  handleUsernameChangeRecharge,
  handleRechargesType,
  handleSearchStatus,
  handleRechargesFormSubmit,
  handlePageDown,
  handlePageUp,
  handleRechargeSelection,
  handleRechargeAmount,
  handleDropdown,
  toggleConfirmation,
  handleRechargePatch,
  resetView,
  closePopup,
}) => {
  return (
    <Grid padded>
      {(viewState === "recharge_validation_approved" ||
        viewState === "recharge_validation_reported" ||
        viewState === "recharge_validation_rejected" ||
        viewState === "recharge_validation_loading") && (
        <PopupMessage
          messageType={popupMessagesType}
          showPopup={showPopup}
          messages={popupMessages}
          closePopup={toggleConfirmation}
        >
          {(rechargeStatus === "reported" || rechargeStatus === "rejected") && (
            <Dropdown
              fluid
              selection
              id="rechargeReason"
              disabled={viewState === "recharge_validation_loading"}
              value={rechargeReason}
              onChange={handleDropdown}
              options={rechargeReasonsOptions}
            />
          )}

          <Button
            disabled={viewState === "recharge_validation_loading"}
            color="blue"
            onClick={handleRechargePatch}
          >
            {(viewState === "recharge_validation_approved" ||
              viewState === "recharge_validation_reported" ||
              viewState === "recharge_validation_rejected") &&
              texts.accept}

            {viewState === "recharge_validation_loading" &&
              texts.loading + "..."}
          </Button>
          <Button color="grey" onClick={toggleConfirmation}>
            {texts.back}
          </Button>
        </PopupMessage>
      )}

      {!(
        viewState === "recharge_validation_approved" ||
        viewState === "recharge_validation_reported" ||
        viewState === "recharge_validation_rejected" ||
        viewState === "recharge_validation_loading"
      ) && (
        <PopupMessage
          messageType={popupMessagesType}
          showPopup={showPopup}
          messages={popupMessages}
          closePopup={closePopup}
        />
      )}

      {viewState !== "recharge_validation_done" && (
        <RightBorderGreyColumn width={8}>
          <RechargesForm
            category={category}
            username={username}
            rechargesType={rechargesType}
            pending={pending}
            approved={approved}
            processing={processing}
            rejected={rejected}
            handleUsernameChange={handleUsernameChange}
            handleRechargesType={handleRechargesType}
            handleSearchStatus={handleSearchStatus}
            handleSubmit={handleRechargesFormSubmit}
          />
          <EntitiesList
            height={
              category === "pagodirecto" && rechargesType !== "TDC"
                ? "calc(100vh - 23rem)"
                : rechargesType === "TDC"
                ? "calc(100vh - 14rem)"
                : "calc(100vh - 17.5rem)"
            }
            viewState={viewState}
            entities={recharges}
            formattingFunction={formatRechargesList}
            selectionHandler={handleRechargeSelection}
          >
            <Pagination
              padding="0.65rem 0 0.65rem 0"
              nextPageExist={nextPageExist}
              currentPage={currentPage}
              handlePageUp={handlePageUp}
              handlePageDown={handlePageDown}
            />
          </EntitiesList>
        </RightBorderGreyColumn>
      )}

      {viewState !== "recharge_validation_done" && (
        <Grid.Column width={8}>
          <Grid padded centered>
            <Grid.Column mobile={14} tablet={14} computer={12}>
              {(viewState === "recharge_search" ||
                viewState === "recharge_search_loading" ||
                viewState === "recharge_search_done") && (
                <PanelPlaceholder>
                  Seleccióne una transacción para habilitar la recarga
                </PanelPlaceholder>
              )}

              <CSSTransition
                in={showRecharge}
                mountOnEnter
                unmountOnExit
                timeout={350}
                appear={true}
                classNames="panel"
              >
                <Panel>
                  {(viewState === "recharge_search_done" ||
                    viewState === "recharge_selection_done" ||
                    viewState === "recharge_validation_approved" ||
                    viewState === "recharge_validation_reported" ||
                    viewState === "recharge_validation_rejected" ||
                    viewState === "recharge_validation_loading" ||
                    viewState === "recharge_validation_done") && (
                    <RechargeValidation
                      category={category}
                      recharge={selectedRecharge}
                      rechargeAmount={rechargeAmount}
                      rechargesType={rechargesType}
                      rechargeReasonsOptions={rechargeReasonsOptions}
                      handleRechargeAmount={handleRechargeAmount}
                      handleUsernameChangeRecharge={
                        handleUsernameChangeRecharge
                      }
                      toggleConfirmation={toggleConfirmation}
                    />
                  )}
                </Panel>
              </CSSTransition>
            </Grid.Column>
          </Grid>
        </Grid.Column>
      )}

      {viewState === "recharge_validation_done" && (
        <Grid.Column width={16}>
          <ResultScreen
            imagePath={okCircleIcon}
            text={rechargeResponseText}
            buttonText={texts.continue}
            handleFinishButton={resetView}
          />
        </Grid.Column>
      )}
    </Grid>
  );
};

export { RechargesValidateView };
