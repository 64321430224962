import React from "react";
import { EntitiesList } from "components/entities-list";
import { UserListHeader, UserListItem, UsersNoFound } from "../users-list";

const UsersListModel = ({
                          loadingUsers,
                          commerceUsers,
                          view,
                          handleUserSelection,
                          handleShowForm,
                          className
                        }) => {
  return (
    <div className={className}>
      <div className="header">
        <p>Lista de Usuarios</p>
      </div>

      {loadingUsers && (
        <div
          className="ui active loader"
          style={{
            marginTop: "7rem",
            position: "absolute"
          }}
        ></div>
      )}
      {!loadingUsers && [
        <UserListHeader key="header" />,
        <EntitiesList
          height="calc(65vh - 4rem)"
          entities={commerceUsers}
          formattingFunction={formatUsersList}
          selectionHandler={handleUserSelection}
          key="list"
        />
      ]}
      {!loadingUsers && (
        <button
          className="register"
          onClick={handleShowForm}
          style={{ marginBottom: view === "phone" ? "4rem" : "0" }}
        >
          Nuevo Usuario
        </button>
      )}
    </div>
  );
};

const UserListHeaderModel = ({ className }) => {
  return (
    <div className={className}>
      <p className="col">Usuario</p>
      <p className="col">Rol</p>
      <p className="col">Estado</p>
    </div>
  );
};

const UsersNoFoundModel = ({ className }) => {
  return <p className={className}>Aún no has agregado usuarios</p>;
};

const UserListItemModel = ({
                             username,
                             role,
                             status,
                             handleUserSelection,
                             index,
                             className
                           }) => {
  const defaultRole = "No definido";
  const roleOptions = {
    Administrador: "Administrador",
    Master: "Principal",
    Supervisor: "Supervisor",
    Operador: "Operador"
  };
  return (
    <div
      className={className}
      status={status}
      id={index}
      onClick={handleUserSelection}
    >
      <p className="username">@{username}</p>
      <p className="role">{roleOptions[role] || defaultRole}</p>
      <p className="status">{status === "active" ? "Activo" : "Inactivo"}</p>
    </div>
  );
};

const formatUsersList = (users, viewState, handleUserSelection) => {
  let usersList = [];
  if (users && users.length) {
    users.forEach((user, index) => {
      usersList.push(
        <UserListItem
          viewState={viewState}
          username={user.username}
          role={user.commerce_role.name}
          status={user.status}
          handleUserSelection={handleUserSelection}
          key={index}
          index={index}
        />
      );
    });
  } else {
    usersList.push(<UsersNoFound key={"no found"} />);
  }
  return usersList;
};

export {
  UsersListModel,
  UsersNoFoundModel,
  UserListHeaderModel,
  UserListItemModel,
  formatUsersList
};
