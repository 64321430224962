import { micropd_02 } from "services/config";
import { post, get } from "services/http-methods";

const endpoints = {
  getDate: `${micropd_02}/superadmin/constants/weekend-date-transfer`,
  setDate: `${micropd_02}/superadmin/constants/weekend-date-transfer`,
};

export const getDate = () => {
  return get(endpoints.getDate, {}, true);
};

export const setDate = (body) => {
  return post(endpoints.setDate, body);
};
