import { UserInfoModel } from "./model";
import styled from "styled-components";

export const UserInfo = styled(UserInfoModel)`
  #root & {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 22vh;
    > .container {
      display: flex;
      margin: 0;
      padding: 0;
      padding-left: 2rem;
      justify-content: space-around;
      align-items: center;
      width: 100%;

      > .img {
        display: inline-block;
        vertical-align: middle;
        margin-right: 1rem;
        height: 40px;
        border-radius: 200px;
      }
      > .data {
        margin: 0;
        display: inline-block;
        vertical-align: middle;
        width: calc(85% - 30px - 1rem);
        > .username {
          color: #63c0e8;
          font-size: 1.2rem;
          font-weight: bold;
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        > .name,
        > .phone,
        > .idNumber {
          color: #454545;
          font-size: 0.9rem;
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .container.container-appear {
      opacity: 0;
      transform: translateX(-30px);
    }
    .container.container-appear-active {
      opacity: 0.8;
      transform: translateX(2px);
      transition: all 350ms ease-out;
    }
    .container.container-enter {
      opacity: 0;
      transform: translateX(-30px);
    }
    .container.container-enter-active {
      opacity: 0.8;
      transform: translateX(2px);
      transition: all 300ms ease-out;
    }
    .container.container-enter-done {
      opacity: 1;
      transform: translateX(0);
      transition: all 50ms ease-out;
    }
    .container.container-exit {
      opacity: 0.8;
      transform: translateX(-2px);
    }
    .container.container-exit-active {
      opacity: 0;
      transform: translateX(30px);
      transition: all 350ms ease-out;
    }
  }
`;
