import DateFormModel from "./model";
import styled from "styled-components";
import { grey_6 } from "assets/strings/colors";

export const DateForm = styled(DateFormModel)`
  #root & {
    margin: 0;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: ${(props) => (props.width ? props.width : "100%")};
    > .date-pickers {
      margin: 0;
      padding: 0;
      margin-top: 0.7rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      > p {
        margin: 0;
        font-weight: bold;
      }
      > div {
        width: 46%;
        > .react-datepicker__input-container {
          width: 100%;
          > input {
            width: calc(100% - 16px);
            border: none;
            border-radius: 1rem;
            color: ${grey_6};
            font-size: 2.2vh;
            margin: 0.5rem 5px 0.5rem 5px;
            text-align: center;
            outline: none;
            box-shadow: none;
            transition: box-shadow 250ms linear;
            :focus {
              box-shadow: 0 1px darkgrey;
            }
          }
          > .react-datepicker__close-icon::after {
            right: 0;
          }
        }
        > .react-datepicker-wrapper {
          width: 100%;
          > .react-datepicker__input-container {
            width: 100%;
            > input {
              width: calc(100% - 16px);
              border: none;
              border-radius: 1rem;
              color: ${grey_6};
              font-size: 2.2vh;
              margin: 0.5rem 5px 0.5rem 5px;
              text-align: center;
              outline: none;
              box-shadow: none;
              transition: box-shadow 250ms linear;
              :focus {
                box-shadow: 0 1px darkgrey;
              }
            }
            > .react-datepicker__close-icon::after {
              right: 15px;
            }
          }
        }
      }
      > div:last-child {
        > .react-datepicker-popper {
          > .react-datepicker {
            > .react-datepicker__triangle {
              right: 50px;
              left: auto;
            }
          }
        }
      }
    }
  }
`;
