import styled from "styled-components";
import { OfficeNumberListItemModel } from "./model";
import { grey_4, bluePD } from "assets/strings/colors";

const OfficeNumberListItem = styled(OfficeNumberListItemModel)`
  #root && {
    height: 3rem;
    width: calc(100% - 1rem);
    margin: 0.5rem;
    border: 1px solid ${grey_4};
    border-radius: 2rem;
    text-align: center;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    :hover {
      border: 1px solid ${bluePD};
    }
  }
`;

export { OfficeNumberListItem };
