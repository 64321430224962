export function handleChange(event) {
  this.setState({
    formData: {
      ...this.state.formData,
      [event.target.name]: event.target.value,
    },
  });
}

export function firstFormComplete() {
  let isValid = true;
  let errorMessage = "";
  let formData = this.state.formData;

  if (
    !formData.email &&
    !formData.username &&
    !formData.short_name &&
    !formData.pre_username &&
    !formData.phone &&
    !formData.password
  ) {
    isValid = false;
    errorMessage = "Por favor llene el formulario";
  } else if (!formData.email) {
    isValid = false;
    errorMessage = "Por favor introduzca su dirección de correo electrónico";
  } else if (!formData.username) {
    isValid = false;
    errorMessage = "Por favor introduza su nombre de usuario";
  } else if (!formData.short_name) {
    isValid = false;
    errorMessage = "Por favor introduzca un Pseudónimo";
  } else if (!formData.pre_username) {
    isValid = false;
    errorMessage = "Por favor introduzca el prefijo del comercio";
  } else if (formData.pre_username.length < 2) {
    isValid = false;
    errorMessage = "El prefijo del comercio debe ser de al menos 2 caracteres";
  } else if (!formData.phone) {
    isValid = false;
    errorMessage = "Por favor introduzca su telefono de contacto";
  } else if (!formData.password) {
    isValid = false;
    errorMessage = "Por favor introduzca una contraseña";
  } else if (!formData.confirm_password) {
    isValid = false;
    errorMessage = "Debe confirmar su contraseña";
  } else if (formData.password !== formData.confirm_password) {
    isValid = false;
    errorMessage = "las contraseñas no coinciden";
  }

  return [isValid, errorMessage];
}

export function handlePhoneChange(event) {
  let value = event.target.value.replace(/[^0-9\s]/g, "");
  value = value.replace(" ", "");
  this.setState({
    formData: {
      ...this.state.formData,
      [event.target.name]: value,
    },
  });
}
export function handleLoginChange(event) {
  let value = event.target.value;
  value = value.toLowerCase();
  this.setState({
    formData: {
      ...this.state.formData,
      [event.target.name]: value,
    },
  });
}

export function handleNewPasswordChange(event) {
  let value = event.target.value;
  // Will NOT accept white spaces or agrupations like <>{}
  if (value.match(/^[^\s<>{}]*$/)) {
    let passwordStrength = 0;
    if (value.match(/\d/g)) {
      passwordStrength++;
    }
    if (value.match(/[A-Za-z]/g)) {
      passwordStrength++;
    }
    if (value.length >= 8) {
      passwordStrength++;
    }
    switch (passwordStrength) {
      case 0:
        passwordStrength = "weak";
        break;
      case 3:
        passwordStrength = "secure";
        break;
      default:
        passwordStrength = "medium";
        break;
    }
    this.setState({
      formData: {
        ...this.state.formData,
        [event.target.name]: value,
      },
      passwordStrength,
    });
  }
}

export function handlePasswordChange(event) {
  let value = event.target.value;
  // Will NOT accept white spaces or agrupations like <>{}
  if (value.match(/^[^\s<>{}]*$/)) {
    this.setState({
      formData: {
        ...this.state.formData,
        [event.target.name]: value,
      },
    });
  }
}

export function handleNumberChange(event) {
  let value = event.target.value;
  if (value.match(/^\d*$/)) {
    this.setState({
      formData: {
        ...this.state.formData,
        [event.target.name]: value,
      },
    });
  }
}

export function handleUsernameChange(event) {
  let value = event.target.value;
  // The field is restrcted to accept only letters, numbers and _-.
  if (value.match(/^[\w.-]*$/)) {
    value = value.toLowerCase();
    this.setState({
      formData: {
        ...this.state.formData,
        [event.target.name]: value,
      },
    });
  }
}
export function handleShortNameChange(event) {
  let value = event.target.value;
  // The field is restrcted to accept only letters, numbers and _-.
  if (value.match(/^[\w.-]*$/)) {
    value = value.toLowerCase();
    this.setState({
      formData: {
        ...this.state.formData,
        [event.target.name]: value,
      },
    });
  }
}

export function handleCheckBox(form_item) {
  this.setState((prevState) => {
    return {
      formData: {
        ...this.state.formData,
        [form_item]: !prevState.formData[form_item],
      },
    };
  });
}

export function handleDropdown(event, data) {
  this.setState({
    formData: {
      ...this.state.formData,
      [data.name]: data.value,
    },
  });
}

export function handleDateChange(event) {
  let value = event.target.value;
  let name = event.target.name;
  if (value.match(/^\d*$/)) {
    if (value === "") {
      this.setState({
        formData: {
          ...this.state.formData,
          [name]: value,
        },
      });
    } else {
      value = parseInt(value, 10);
      // Format year
      if (name === "yyyy") {
        if (value <= 2099) {
          this.setState({
            formData: {
              ...this.state.formData,
              [name]: value,
            },
          });
        }
      }
      // Format month
      if (name === "mm") {
        if (value <= 12) {
          this.setState({
            formData: {
              ...this.state.formData,
              [name]: value,
            },
          });
        }
      }
      // Format day
      if (name === "dd") {
        if (value <= 31) {
          this.setState({
            formData: {
              ...this.state.formData,
              [name]: value,
            },
          });
        }
      }
    }
  }
}

export function handlePrimaryColorChange(color) {
  this.setState((prevState) => {
    let [...colors] = prevState.formData.colors;
    colors[0] = color.hex;
    return {
      formData: {
        ...prevState.formData,
        colors,
      },
    };
  });
}

export function handleSecondaryColorChange(color) {
  this.setState((prevState) => {
    let [...colors] = prevState.formData.colors;
    colors[1] = color.hex;
    return {
      formData: {
        ...prevState.formData,
        colors,
      },
    };
  });
}
