import React from "react";
import { Icon } from "semantic-ui-react";

const SearchInputNewModel = ({
  handleSearch,
  handleChange,
  searchParam,
  placeholder,
  className,
}) => {
  return (
    <div className={"ui transparent labeled input " + className}>
      <input
        type="text"
        placeholder={placeholder}
        className="input-tag"
        //value={searchParam}
        onChange={handleChange}
      />
      <div className="search" onClick={handleSearch}>
        <Icon name="search" />
      </div>
    </div>
  );
};

export default SearchInputNewModel;
