import { getTolls } from "services/api";

export const search = async (changeState) => {
  try {
    changeState({ loading: true });
    const response = await getTolls();
    const res = await response.json();
    if (res.error) throw res.error;
    if (res && res.data && res.data.items) {
      const options = [
        ...res.data.items.map((toll) => ({
          key: toll._id,
          value: toll.code,
          text: toll.name,
        })),
      ];
      changeState({
        options,
      });
    }
  } catch (error) {
    console.log({ error });
  } finally {
    changeState({ loading: false });
  }
};
