import React, { useEffect } from "react";
import { getAmountHistory } from "services/api";
import { useStateManager } from "hooks/use-state-manager";
import { usePopupMessages } from "hooks/use-popup-messages";
import { useViewType } from "hooks/use-view-type";
import { connect } from "react-redux";
import { clearReduxStore } from "redux/actions/general";
import { AmountHistoryView } from "./components/amount-history-view";
import { bankAmounts } from "./services/bank-amounts";
const MposAmountHistory = ({ props }) => {
  const initialState = {
    info: "",
    loading: false,
    bankAmounts: null,
    loadingAmounts: false,
  };
  const [state, changeState] = useStateManager(initialState);
  const [viewType, setViewType] = useViewType("desktop");

  const {
    popupMessages,
    popupMessagesType,
    showPopup,
    throwPopupMessage,
    closePopup,
  } = usePopupMessages(null, "", false);

  useEffect(() => {
    const fetchAmountHistory = async () => {
      try {
        changeState({ loading: true });
        const response = await getAmountHistory();
        const res = await response.json();
        if (res.error) throw res.error;
        changeState({ info: res, loading: false });
      } catch (error) {
        throwPopupMessage("error", "Error al buscar los datos");
      }
    };
    fetchAmountHistory();
    bankAmounts(changeState, throwPopupMessage);
    setViewType();
    window.addEventListener("resize", setViewType);
    return () => {
      window.removeEventListener("resize", setViewType);
    };
  }, []);

  const defComponent = (
    <AmountHistoryView
      loading={state.loading}
      info={state.info}
      popupMessages={popupMessages}
      popupMessagesType={popupMessagesType}
      showPopup={showPopup}
      closePopup={closePopup}
    />
  );
  const view = {
    phone: (
      <AmountHistoryView
        loading={state.loading}
        info={state.info}
        loadingAmounts={state.loadingAmounts}
        bankAmounts={state.bankAmounts}
        popupMessages={popupMessages}
        popupMessagesType={popupMessagesType}
        showPopup={showPopup}
        closePopup={closePopup}
      />
    ),
    desktop: (
      <AmountHistoryView
        loading={state.loading}
        loadingAmounts={state.loadingAmounts}
        bankAmounts={state.bankAmounts}
        info={state.info}
        popupMessages={popupMessages}
        popupMessagesType={popupMessagesType}
        showPopup={showPopup}
        closePopup={closePopup}
      />
    ),
  };
  return view[viewType] || defComponent;
};

const mapStateToProps = null;
const mapDispatchToProps = {
  clearReduxStore,
};
export default connect(mapStateToProps, mapDispatchToProps)(MposAmountHistory);
