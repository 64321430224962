export const getPopupMessages = state => {
  return state.popupMessage.messages
}

export const getMessagesType = state => {
  return state.popupMessage.type
}

export const isPopupVisible = state => {
  return state.popupMessage.showPopup
}