import { useEffect, useContext } from "react";
import { MobilePaidsContext } from "../context";
import { getConstants } from "./api";

export const useGetConstants = () => {
  const { state, changeState, throwPopupMessage } =
    useContext(MobilePaidsContext);

  const constanst = async () => {
    changeState({ loading: true });
    try {
      const response = await getConstants();
      const res = await response.json();
      if (res.error) throw res.error;
      changeState({
        bankList: res.BANK_LIST,
        submitForm: true,
      });
    } catch (error) {
      throwPopupMessage("error", error.description);
    }
    changeState({ getConstants: false, loading: true });
  };

  useEffect(() => {
    if (state.getConstants) {
      constanst();
    }
  }, [state.getConstants]);

  return null;
};
