import React from "react";
import { CSSTransition } from "react-transition-group";
import { Dropdown } from "semantic-ui-react";
import { Loader } from "components/loader";
import { AlertMessage } from "../alert-message";

const RegisterFormModel = ({
  user,
  email,
  docType,
  docId,
  password,
  confirmPassword,
  mobilePaidNumber,
  handleUser,
  handleEmail,
  handleDocType,
  handleDocId,
  handlePassword,
  handleConfirmPassword,
  handleMobilePaidNumber,
  handleSubmit,

  toShow,
  message,
  errorType,
  handleContinue,
  handleBack,
  className,
}) => {
  const options = [
    { key: 0, text: "V.", value: "V" },
    { key: 1, text: "E.", value: "E" },
    { key: 2, text: "J.", value: "J" },
    { key: 3, text: "P.", value: "P" },
  ];
  return (
    <div className={className}>
      {toShow === "register" && (
        <CSSTransition
          in={true}
          appear={true}
          timeout={350}
          classNames="register"
        >
          <div className="register">
            <p>Registro</p>
            <input
              maxLength={30}
              type="text"
              placeholder="Usuario"
              value={user}
              onChange={handleUser}
            />
            <input
              type="email"
              maxLength={60}
              placeholder="Correo Electrónico"
              value={email}
              onChange={handleEmail}
            />
            <div className="doc-class">
              <Dropdown
                className="label"
                name="doc_type"
                options={options}
                value={docType}
                onChange={handleDocType}
              />
              <input
                type="text"
                placeholder="Nro. Documento"
                minLength={8}
                maxLength={10}
                value={docId}
                onChange={handleDocId}
              />
            </div>

            <input
              minLength={6}
              type="password"
              placeholder="Contraseña"
              value={password}
              pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d$@!%*#?&.=*]{8,}$"
              onChange={handlePassword}
            />
            <input
              minLength={6}
              type="password"
              placeholder="Confirmar contraseña"
              value={confirmPassword}
              pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d$@!%*#?&.=*]{8,}$"
              onChange={handleConfirmPassword}
            />
            {password !== confirmPassword ? (
              <p className="wrong-password">las contraseñas no coinciden</p>
            ) : (
              ""
            )}
            <input
              minLength={11}
              maxLength={11}
              type="text"
              placeholder="Teléfono Pago Móvil"
              value={mobilePaidNumber}
              onChange={handleMobilePaidNumber}
            />
            <button
              onClick={handleSubmit}
              disabled={
                !mobilePaidNumber.length ||
                !user.length ||
                !email.length ||
                !docType.length ||
                !docId.length ||
                !password.length ||
                !confirmPassword.length ||
                confirmPassword !== password
              }
            >
              Registrar
            </button>
          </div>
        </CSSTransition>
      )}
      {toShow === "loader" && (
        <div className="loader">
          <Loader margin="0 17rem" />
        </div>
      )}
      {toShow === "error" && (
        <AlertMessage
          message={message}
          type={errorType}
          handleContinue={handleContinue}
          handleBack={handleBack}
        />
      )}
      <p className="info">
        Para atención al cliente llamar a: <br />
        <span className="contact">0412.366.72.46</span>
      </p>
    </div>
  );
};

export { RegisterFormModel };
