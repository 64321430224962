import React, { useContext } from "react";
import { CSSTransition } from "react-transition-group";
import { element_transition_time } from "assets/strings/constants";
import { Button } from "components/buttons";
import { Icon } from "semantic-ui-react";
import { AdminTransfersContext } from "../../context";
import { useAmountInput, useTextInput } from "hooks/use-inputs";

export const SimpleTransferModel = ({ phoneView, className }) => {
  const {
    state: { typeofTransferSelected },
    changeState,
    onGetCode,
    handleBack,
    withdraw,
  } = useContext(AdminTransfersContext);

  const transferType = {
    wallet: "Transferencia de cuenta Wallet a cuenta Paytech",
    service: "Transferencia de cuenta Wallet a cuenta Servicios ",
    default: "Tipo de transferencia desconocido",
  };

  const data = {
    customTitle: `${
      transferType[typeofTransferSelected] || transferType.default
    }`,
    conceptLabel: "Concepto:",
    amountLabel: "Monto a transferir:",
    buttonLabel: "Transferir",
  };

  const { amount, handle: onChangeAmount } = useAmountInput(
    withdraw &&
      withdraw.intern &&
      withdraw.bill_ref &&
      withdraw.bill_ref.total_amount
      ? `${withdraw.bill_ref.total_amount}`
      : ""
  );

  const {
    bind: { value: concept, onChange: onChangeConcept },
  } = useTextInput(
    withdraw && withdraw.transaction_owner
      ? `@${withdraw.transaction_owner}`
      : ""
  );
  console.log({ withdraw });
  const onSubmit = (event) => {
    changeState({
      amount,
      concept,
    });
    onGetCode(event);
  };

  return (
    <CSSTransition
      in={true}
      mountOnEnter
      timeout={element_transition_time}
      appear={true}
      classNames="form"
    >
      <form className={className} onSubmit={onSubmit}>
        <div className="custom-title">
          {phoneView && (
            <Icon
              name="arrow left"
              onClick={handleBack}
              styles={{
                zIndex: 1,
                paddingTop: "4rem",
              }}
            />
          )}
          <p>{data.customTitle}</p>
        </div>
        <div className="input-section">
          <label htmlFor="concept-id">{data.conceptLabel}</label>
          <input
            id="concept"
            type="text"
            name="concept"
            autoComplete="off"
            placeholder="Concepto"
            value={concept}
            onChange={onChangeConcept}
            required
          />
          <label htmlFor="amount-id">{data.amountLabel}</label>
          <input
            id="concept"
            type="text"
            min="0"
            name="amount"
            autoComplete="off"
            placeholder="Monto"
            value={amount}
            onChange={onChangeAmount}
            required
          />
        </div>
        <Button color="blue" type="submit" width="40%">
          {data.buttonLabel}
        </Button>
      </form>
    </CSSTransition>
  );
};
