import React from "react";
import { SuperadminBillsContext } from "./context";
import { useResponsive } from "hooks/use-responsive";
import { connect } from "react-redux";
import { usePopupMessages } from "hooks/use-popup-messages";
import { useStateManager } from "hooks/use-state-manager";

const SuperadminBillsState = ({ children }) => {
  const viewport = useResponsive();
  const initialState = {
    fiscalForm: {
      billing_fiscal_id: "",
      alias: "",
      name: "",
      address: "",
      email: "",
      phone: "",
      currency: "VES",
      doc_id: "",
      doc_type: "",
      save_billing_fiscal_info: false,
    },
    lines: [
      {
        name: "operation",
        description: "",
        qty: 0,
        amount_bs: 0,
        amount_usd: 0,
        is_exempt: false,
        code: "",
        isActive: true,
      },
      {
        name: "pd-fee",
        description: "",
        qty: 0,
        amount_bs: 0,
        amount_usd: 0,
        is_exempt: false,
        code: "",
        isActive: true,
      },
    ],
    showNextPage: false,
    nextPageExist: false,
    currentPage: 1,
    directory: [],
    selectedFiscal: null,
    exchange_usd_to_bs: "",
    currentExchange: 0,
    cleanUp: true,
    showFormOnMobile: false,
    showPreview: false,
    billToPreview: null,
    resetDirectory: true,
  };
  const [state, changeState] = useStateManager(initialState);

  const {
    popupMessages,
    popupMessagesType,
    showPopup,
    throwPopupMessage,
    closePopup,
  } = usePopupMessages(
    "Unidigital tarda de 3 a 5 min en generar un número de control después de generar la factura, por favor sé paciente",
    "alert",
    true
  );

  return (
    <SuperadminBillsContext.Provider
      value={{
        viewport,
        state,
        changeState,
        popupMessages,
        popupMessagesType,
        showPopup,
        throwPopupMessage,
        closePopup,
      }}
    >
      {children}
    </SuperadminBillsContext.Provider>
  );
};

const mapStateToProps = null;
const mapDispatchToProps = null;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuperadminBillsState);
