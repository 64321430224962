import { STORE_CLEAR } from "redux/actions/general";
import { combineReducers } from "redux";
import session from "./session";
import buildings from "./buildings";
import constants from "./constants";
import commerces from "./commerces";
import popupMessage from "./popup-message";
import viewMposCommerces from "./view-mpos-commerces";
import responsiveData from "./responsive-data";
import socketService from "./socket-service";
const appReducer = combineReducers({
  session,
  buildings,
  commerces,
  popupMessage,
  viewMposCommerces,
  responsiveData,
  socketService,
  constants,
});

export default (state, action) => {
  // Manage reset sotre case on logout
  if (action.type === STORE_CLEAR) {
    state = undefined;
  }

  return appReducer(state, action);
};
