import { PDAmountModel } from "./model";
import styled from "styled-components";
import { grey_5 } from "assets/strings/colors";

export const PDAmount = styled(PDAmountModel)`
  #root && {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > .header {
      margin: 0;
      margin-top: 0.5rem;
      padding: 0;
      display: flex;
      justify-content: inline-block;
      width: 95%;
      margin-bottom: 0.2rem;
      font-size: 1.5rem;
      > .title {
        margin: 0;
        width: 50%;
        font-weight: bold;
      }
      > .date {
        margin: 0;
        align-self: flex-end;
        width: 50%;
        color: ${grey_5};
        font-size: 1rem;
        text-align: right;
      }
    }
    > .table {
      width: 95%;
    }
    > .space {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
`;
