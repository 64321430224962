import React from "react";

const DigitizeFooterModel = ({ commerceIsPrepaid, className }) => {
  return (
    <div className={className}>
      {commerceIsPrepaid && (
        <p className="info">
          Para solicitar más tickets o atención al cliente llamar a: <br />
          <span className="contact">0412.366.72.46</span>
        </p>
      )}
      {!commerceIsPrepaid && (
        <p className="info">
          Para atención al cliente llamar a: <br />
          <span className="contact">0412.366.72.46</span>
        </p>
      )}
    </div>
  );
};

export { DigitizeFooterModel };
