import React from "react";
import styled from "styled-components";
import { RegisterForm } from "./register-form";
const RegisterUserViewModel = ({
  user,
  email,
  docType,
  docId,
  password,
  confirmPassword,
  mobilePaidNumber,
  handleUser,
  handleEmail,
  handleDocType,
  handleDocId,
  handlePassword,
  handleConfirmPassword,
  handleMobilePaidNumber,
  handleSubmit,
  toShow,
  message,
  errorType,
  handleContinue,
  handleBack,
  className,
}) => {
  return (
    <div className={className}>
      <RegisterForm
        user={user}
        email={email}
        docType={docType}
        docId={docId}
        password={password}
        confirmPassword={confirmPassword}
        mobilePaidNumber={mobilePaidNumber}
        handleUser={handleUser}
        handleEmail={handleEmail}
        handleDocType={handleDocType}
        handleDocId={handleDocId}
        handlePassword={handlePassword}
        handleConfirmPassword={handleConfirmPassword}
        handleMobilePaidNumber={handleMobilePaidNumber}
        handleSubmit={handleSubmit}
        toShow={toShow}
        message={message}
        errorType={errorType}
        handleContinue={handleContinue}
        handleBack={handleBack}
      />
    </div>
  );
};

const RegisterUserView = styled(RegisterUserViewModel)`
  #root && {
    height: 100%;
    width: 100%;
  }
`;

export { RegisterUserView };
