import React, { useEffect } from "react";
import moment from "moment-timezone";
import { useStateManager } from "hooks/use-state-manager";
import { usePopupMessages } from "hooks/use-popup-messages";
import { useDates } from "hooks/use-inputs";
import { connect } from "react-redux";
import { getBuildings } from "redux/selectors";
import { ParkingView } from "./components/parking-view";
import { ParkingPhoneView } from "./components/parking-phone-view";
import { getPagoDirectoParkingDashboard } from "services/api";
//import { addSocketListener, removeSocketListener } from "services/websockets";
import { buildingsToOptions } from "services/utils";
import {
  errors,
  time_assets,
  days_of_weeks_assets,
} from "assets/strings/texts";

const ParkingStats = ({ buildings }) => {
  const buildingsOptions = buildingsToOptions(buildings);

  let itemsPerDay = [];
  let itemsPerWeek = [];
  let itemsPerLastWeek = [];
  let itemsPerMonth = [];
  let itemsPerLastMonth = [];

  let startOfDay = moment().tz("America/Caracas").startOf("day");
  let startOfDayBuffer = moment().tz("America/Caracas").startOf("day");
  let startOfLastDay = moment()
    .tz("America/Caracas")
    .startOf("day")
    .subtract(1, "days");
  let startOfWeek = moment().tz("America/Caracas").startOf("isoWeek");
  let startOfWeekBuffer = moment().tz("America/Caracas").startOf("isoWeek");
  let startOfLastWeek = moment()
    .tz("America/Caracas")
    .startOf("isoWeek")
    .subtract(1, "weeks");
  let startOfLastWeekBuffer = moment()
    .tz("America/Caracas")
    .startOf("isoWeek")
    .subtract(1, "weeks");
  let startOfMonth = moment().tz("America/Caracas").startOf("month");
  let startOfMonthBuffer = moment().tz("America/Caracas").startOf("month");
  let startOfLastMonth = moment()
    .tz("America/Caracas")
    .startOf("month")
    .subtract(1, "months");
  let startOfLastMonthBuffer = moment()
    .tz("America/Caracas")
    .startOf("month")
    .subtract(1, "months");

  let weekLabel = [...days_of_weeks_assets];

  let hourLabel = [...time_assets];

  for (let i = 0; i < 24; i++) {
    itemsPerDay.push({
      hour: startOfDayBuffer.format("H"),
      qty: 0,
      label: hourLabel[i],
    });
    startOfDayBuffer.add(1, "hours");
  }

  for (let i = 0; i < 7; i++) {
    itemsPerWeek.push({
      day: startOfWeekBuffer.format("YYYY-MM-DD"),
      qty: 0,
      label: weekLabel[i],
    });
    itemsPerLastWeek.push({
      day: startOfLastWeekBuffer.format("YYYY-MM-DD"),
      qty: 0,
      label: weekLabel[i],
    });
    startOfWeekBuffer.add(1, "days");
    startOfLastWeekBuffer.add(1, "days");
  }

  for (let i = 0; i < 31; i++) {
    itemsPerMonth.push({
      day: startOfMonthBuffer.format("YYYY-MM-DD"),
      qty: 0,
      label: startOfMonthBuffer.format("YYYY-MM-DD"),
    });
    itemsPerLastMonth.push({
      day: startOfLastMonthBuffer.format("YYYY-MM-DD"),
      qty: 0,
      label: startOfMonthBuffer.format("YYYY-MM-DD"),
    });
    startOfMonthBuffer.add(1, "days");
    startOfLastMonthBuffer.add(1, "days");
  }
  const optionsPeriods = [
    { key: 1, text: "Diarias", value: "daily" },
    { key: 2, text: "Semanales", value: "weekly" },
    { key: 3, text: "Mensuales", value: "monthly" },
    { key: 4, text: "Rango de fechas", value: "range" },
  ];

  const initialState = {
    viewType: "desktop",
    itemSelected: "parking",
    showTarget: true,
    render: false,
    transition: false,
    building: null,
    buildingsOptions,
    buildingName: "Todos",
    updateBySocket: false,
    parkingData: {
      iva: 0,
      pd_fee: 0,
      tickets: [],
    },
    itemsPerDay,
    itemsPerWeek,
    itemsPerLastWeek,
    itemsPerMonth,
    itemsPerLastMonth,
    startOfDay,
    startOfLastDay,
    startOfWeek,
    startOfLastWeek,
    startOfMonth,
    startOfLastMonth,
    period: "daily",
    filterTime: "Diarias",
    barStats: {
      periodSelected: "current",
      current: {
        title: "",
        data: [],
        keyY: "tickets",
        lineName: "Tickets",
      },
      last: {
        title: "",
        data: [],
        keyY: "tickets",
        lineName: "Tickets",
      },
    },
    ticketsDistribution: {
      title: "",
      data: [],
      keyY: "tickets",
      lineName: "Tickets",
    },
    totalAmounts: {
      periodSelected: "current",
      current: {
        firstTitle: "",
        firstAmount: 0,
        firstUnit: "",
        secondTitle: "",
        secondAmount: 0,
        secondUnit: "",
      },
      last: {
        firstTitle: "",
        firstAmount: 0,
        firstUnit: "",
        secondTitle: "",
        secondAmount: 0,
        secondUnit: "",
      },
    },
    typeStatusList: {
      periodSelected: "current",
      dataSelected: "status",
      current: {
        status: [],
        types: [],
      },
      last: {
        status: [],
        types: [],
      },
    },
    customStats: {
      title: "",
      data: [],
      lines: 2,
    },
    tableStats: {
      entry_relevance: [],
      exit_relevance: [],
    },
    loading: false,
    submit: false,
    relevance: {
      today: {
        qty: 0,
        amount: 0,
      },
      yesterday: {
        qty: 0,
        amount: 0,
        at: "--|--",
      },
      variation: 0,
    },
  };

  const [state, changeState] = useStateManager(initialState);
  const { fromDate, endDate, handleFromDate, handleEndDate } = useDates(
    null,
    null
  );

  const {
    popupMessages,
    popupMessagesType,
    showPopup,
    throwPopupMessage,
    closePopup,
  } = usePopupMessages(null, "", false);

  useEffect(() => {
    if (!state.transition) {
      setTimeout(changeState({ transition: true }), 350);
    }
    return () => {};
  }, [!state.transition]);

  useEffect(() => {
    if (state.render) {
      changeState({ render: false });
    }
    return () => {};
  }, [state.render]);

  useEffect(() => {
    setViewType();
    window.addEventListener("resize", setViewType);
    //addSocketListener("new ticket", setSocketRequest);
    changeState({ submit: true });
    return () => {
      window.removeEventListener("resize", setViewType);
      //removeSocketListener();
    };
  }, []);

  useEffect(() => {
    const getDashboard = async () => {
      let { building, period } = state;
      let start_date = period === "range" ? fromDate : null;
      let end_date = period === "range" ? endDate : null;
      try {
        changeState({ loading: !state.updateBySocket });
        const response = await getPagoDirectoParkingDashboard({
          building,
          period,
          start_date,
          end_date,
        });
        const res = await response.json();
        if (res.error) throw res.error;
        let {
          tableStats,
          totalAmounts,
          barStats,
          ticketsDistribution,
          customStats,
          typeStatusList,
          relevance,
          itemsPerDay,
          itemsPerWeek,
          itemsPerLastWeek,
          startOfDay,
          startOfWeek,
          startOfLastWeek,
          itemsPerMonth,
          itemsPerLastMonth,
          startOfMonth,
          startOfLastMonth,
        } = initialState;
        const period_tickets = res.period_tickets ? res.period_tickets : [];
        const hasPeriodTickets = period_tickets.length > 0;
        if (state.period === "daily" && hasPeriodTickets) {
          let res_last_day = [];
          let res_current_day = [];

          let res_current_day_types = [];
          let res_current_day_status = [];
          let res_last_day_types = [];
          let res_last_day_status = [];

          let res_current_day_amounts = { qty: 0, amount: 0 };
          let res_last_day_amounts = { qty: 0, amount: 0 };
          let res_current_day_buildings = [];
          let res_last_day_buildings = [];

          period_tickets.map((period) => {
            if (period.date === startOfDay.format("YYYY-MM-DD")) {
              res_current_day = period.hours;
              res_current_day_buildings = period.buildings;
              res_current_day_types = period.types;
              res_current_day_status = period.status;
              res_current_day_amounts = {
                qty: period.qty,
                amount: period.amount,
              };
            }
            if (period.date === startOfLastDay.format("YYYY-MM-DD")) {
              res_last_day = period.hours;
              res_last_day_buildings = period.buildings;
              res_last_day_types = period.types;
              res_last_day_status = period.status;
              res_last_day_amounts = {
                qty: period.qty,
                amount: period.amount,
              };
            }
            return null;
          });
          typeStatusList.current = {
            types: res_current_day_types,
            status: res_current_day_status,
          };

          typeStatusList.last = {
            types: res_last_day_types,
            status: res_last_day_status,
          };
          totalAmounts.current.firstTitle = "total de tickets";
          totalAmounts.current.firstAmount = res_current_day_amounts.qty;
          totalAmounts.current.firstUnit = "";
          totalAmounts.current.secondTitle = "monto acumulado";
          totalAmounts.current.secondAmount = res_current_day_amounts.amount;
          totalAmounts.current.secondUnit = "Bs.S";
          totalAmounts.last.firstTitle = "total de tickets";
          totalAmounts.last.firstAmount = res_last_day_amounts.qty;
          totalAmounts.last.firstUnit = "";
          totalAmounts.last.secondTitle = "monto acumulado";
          totalAmounts.last.secondAmount = res_last_day_amounts.amount;
          totalAmounts.last.secondUnit = "Bs.S";

          let last_day = [...itemsPerDay];
          if (res_last_day.length > 0) {
            itemsPerDay.forEach((sample_hour, index) => {
              res_last_day.forEach((hour) => {
                if (sample_hour.hour === hour.hourFormatted) {
                  last_day[index] = {
                    ...sample_hour,
                    qty: hour.qty,
                  };
                }
              });
            });
          }

          let current_day = [...itemsPerDay];
          if (res_current_day.length > 0) {
            itemsPerDay.forEach((sample_hour, index) => {
              res_current_day.forEach((hour) => {
                if (sample_hour.hour === hour.hourFormatted) {
                  current_day[index] = {
                    ...sample_hour,
                    qty: hour.qty,
                  };
                }
              });
            });
          }

          let data = [];
          for (let i = 0; i < 24; i++) {
            data.push({
              name: current_day[i].label,
              "Periodo Anterior": last_day[i].qty,
              "Periodo Actual": current_day[i].qty,
            });
          }
          customStats.title = "Distribución de Hoy";
          customStats.data = data;
          customStats.lines = 2;
          ticketsDistribution.title = "Distribución Diaria";

          const current_tickets_per_building = res_current_day_buildings;
          const hasCurrentTicketsPerBuilding =
            current_tickets_per_building.length > 0;

          if (hasCurrentTicketsPerBuilding) {
            const current_data = current_tickets_per_building.map((item) => {
              return {
                name: item.building ? item.building : "",
                tickets: item.qty ? item.qty : 0,
              };
            });
            barStats.current.title = "Distribución por edificio";
            barStats.current.data = current_data;
            barStats.current.keyY = "tickets";
            barStats.current.lineName = "Tickets";
          }

          const last_tickets_per_building = res_last_day_buildings;
          const hasLastTicketsPerBuilding =
            last_tickets_per_building.length > 0;

          if (hasLastTicketsPerBuilding) {
            const last_data = last_tickets_per_building.map((item) => {
              return {
                name: item.building ? item.building : "",
                tickets: item.qty ? item.qty : 0,
              };
            });
            barStats.last.title = "Distribución por edificio";
            barStats.last.data = last_data;
            barStats.last.keyY = "tickets";
            barStats.last.lineName = "Tickets";
          }
        } else if (state.period === "weekly" && hasPeriodTickets) {
          let res_last_week = [];
          let res_current_week = [];
          let res_current_week_buildings = [];
          let res_last_week_buildings = [];

          let res_current_week_types = [];
          let res_current_week_status = [];
          let res_last_week_types = [];
          let res_last_week_status = [];

          let res_current_week_amounts = { qty: 0, amount: 0 };
          let res_last_week_amounts = { qty: 0, amount: 0 };

          period_tickets.map((period) => {
            if (period.date === startOfWeek.format("YYYY-MM-DD")) {
              res_current_week = period.days;
              res_current_week_buildings = period.buildings;
              res_current_week_types = period.types;
              res_current_week_status = period.status;
              res_current_week_amounts = {
                qty: period.qty,
                amount: period.amount,
              };
            }
            if (period.date === startOfLastWeek.format("YYYY-MM-DD")) {
              res_last_week = period.days;
              res_last_week_buildings = period.buildings;
              res_last_week_types = period.types;
              res_last_week_status = period.status;
              res_last_week_amounts = {
                qty: period.qty,
                amount: period.amount,
              };
            }
            return null;
          });

          typeStatusList.current = {
            types: res_current_week_types,
            status: res_current_week_status,
          };

          typeStatusList.last = {
            types: res_last_week_types,
            status: res_last_week_status,
          };

          totalAmounts.current.firstTitle = "total de tickets";
          totalAmounts.current.firstAmount = res_current_week_amounts.qty;
          totalAmounts.current.firstUnit = "";
          totalAmounts.current.secondTitle = "monto acumulado";
          totalAmounts.current.secondAmount = res_current_week_amounts.amount;
          totalAmounts.current.secondUnit = "Bs.S";
          totalAmounts.last.firstTitle = "total de tickets";
          totalAmounts.last.firstAmount = res_last_week_amounts.qty;
          totalAmounts.last.firstUnit = "";
          totalAmounts.last.secondTitle = "monto acumulado";
          totalAmounts.last.secondAmount = res_last_week_amounts.amount;
          totalAmounts.last.secondUnit = "Bs.S";

          let last_week = [...itemsPerLastWeek];
          if (res_last_week.length > 0) {
            itemsPerLastWeek.forEach((sample_days, index) => {
              res_last_week.forEach((day) => {
                if (sample_days.day === day.dateFormatted) {
                  last_week[index] = {
                    ...sample_days,
                    qty: day.qty,
                  };
                }
              });
            });
          }

          let current_week = [...itemsPerWeek];
          if (res_current_week.length > 0) {
            itemsPerWeek.forEach((sample_days, index) => {
              res_current_week.forEach((day) => {
                if (sample_days.day === day.dateFormatted) {
                  current_week[index] = {
                    ...sample_days,
                    qty: day.qty,
                  };
                }
              });
            });
          }

          let data = [];
          for (let i = 0; i < 7; i++) {
            data.push({
              name: current_week[i].label,
              "Periodo Anterior": last_week[i].qty,
              "Periodo Actual": current_week[i].qty,
            });
          }
          customStats.title = "Distribución de esta semana";
          customStats.data = data;
          customStats.lines = 2;

          ticketsDistribution.title = "Distribución Semanal";

          const current_tickets_per_building = res_current_week_buildings;
          const hasCurrentTicketsPerBuilding =
            current_tickets_per_building.length > 0;

          if (hasCurrentTicketsPerBuilding) {
            const current_data = current_tickets_per_building.map((item) => {
              return {
                name: item.building ? item.building : "",
                tickets: item.qty ? item.qty : 0,
              };
            });
            barStats.current.title = "Distribución por edificio";
            barStats.current.data = current_data;
            barStats.current.keyY = "tickets";
            barStats.current.lineName = "Tickets";
          }

          const last_tickets_per_building = res_last_week_buildings;
          const hasLastTicketsPerBuilding =
            last_tickets_per_building.length > 0;

          if (hasLastTicketsPerBuilding) {
            const last_data = last_tickets_per_building.map((item) => {
              return {
                name: item.building ? item.building : "",
                tickets: item.qty ? item.qty : 0,
              };
            });
            barStats.last.title = "Distribución por edificio";
            barStats.last.data = last_data;
            barStats.last.keyY = "tickets";
            barStats.last.lineName = "Tickets";
          }
        } else if (state.period === "monthly" && hasPeriodTickets) {
          let res_last_month = [];
          let res_current_month = [];
          let res_current_month_buildings = [];
          let res_last_month_buildings = [];

          let res_current_month_types = [];
          let res_current_month_status = [];
          let res_last_month_types = [];
          let res_last_month_status = [];

          let res_current_month_amounts = { qty: 0, amount: 0 };
          let res_last_month_amounts = { qty: 0, amount: 0 };

          period_tickets.map((period) => {
            if (period.date === startOfMonth.format("YYYY-MM")) {
              res_current_month = period.days;
              res_current_month_buildings = period.buildings;
              res_current_month_types = period.types;
              res_current_month_status = period.status;
              res_current_month_amounts = {
                qty: period.qty,
                amount: period.amount,
              };
            }
            if (period.date === startOfLastMonth.format("YYYY-MM")) {
              res_last_month = period.days;
              res_last_month_buildings = period.buildings;
              res_last_month_types = period.types;
              res_last_month_status = period.status;
              res_last_month_amounts = {
                qty: period.qty,
                amount: period.amount,
              };
            }
            return null;
          });

          typeStatusList.current = {
            types: res_current_month_types,
            status: res_current_month_status,
          };

          typeStatusList.last = {
            types: res_last_month_types,
            status: res_last_month_status,
          };

          totalAmounts.current.firstTitle = "total de tickets";
          totalAmounts.current.firstAmount = res_current_month_amounts.qty;
          totalAmounts.current.firstUnit = "";
          totalAmounts.current.secondTitle = "monto acumulado";
          totalAmounts.current.secondAmount = res_current_month_amounts.amount;
          totalAmounts.current.secondUnit = "Bs.S";
          totalAmounts.last.firstTitle = "total de tickets";
          totalAmounts.last.firstAmount = res_last_month_amounts.qty;
          totalAmounts.last.firstUnit = "";
          totalAmounts.last.secondTitle = "monto acumulado";
          totalAmounts.last.secondAmount = res_last_month_amounts.amount;
          totalAmounts.last.secondUnit = "Bs.S";

          let last_month = [...itemsPerLastMonth];
          if (res_last_month.length > 0) {
            itemsPerLastMonth.forEach((sample_days, index) => {
              res_last_month.forEach((day) => {
                if (sample_days.day === day.dateFormatted) {
                  last_month[index] = {
                    ...sample_days,
                    qty: day.qty,
                  };
                }
              });
            });
          }

          let current_month = [...itemsPerMonth];
          if (res_current_month.length > 0) {
            itemsPerMonth.forEach((sample_days, index) => {
              res_current_month.forEach((day) => {
                if (sample_days.day === day.dateFormatted) {
                  current_month[index] = {
                    ...sample_days,
                    qty: day.qty,
                  };
                }
              });
            });
          }
          let data = [];
          for (let i = 0; i < 31; i++) {
            data.push({
              name: current_month[i].day,
              "Periodo Anterior": last_month[i].qty,
              "Periodo Actual": current_month[i].qty,
            });
          }
          customStats.title = "Distribución de este mes";
          customStats.data = data;
          customStats.lines = 2;

          ticketsDistribution.title = "Distribución mensual";

          const current_tickets_per_building = res_current_month_buildings;
          const hasCurrentTicketsPerBuilding =
            current_tickets_per_building.length > 0;

          if (hasCurrentTicketsPerBuilding) {
            const current_data = current_tickets_per_building.map((item) => {
              return {
                name: item.building ? item.building : "",
                tickets: item.qty ? item.qty : 0,
              };
            });
            barStats.current.title = "Distribución por edificio";
            barStats.current.data = current_data;
            barStats.current.keyY = "tickets";
            barStats.current.lineName = "Tickets";
          }

          const last_tickets_per_building = res_last_month_buildings;
          const hasLastTicketsPerBuilding =
            last_tickets_per_building.length > 0;

          if (hasLastTicketsPerBuilding) {
            const last_data = last_tickets_per_building.map((item) => {
              return {
                name: item.building ? item.building : "",
                tickets: item.qty ? item.qty : 0,
              };
            });
            barStats.last.title = "Distribución por edificio";
            barStats.last.data = last_data;
            barStats.last.keyY = "tickets";
            barStats.last.lineName = "Tickets";
          }
        } else if (state.period === "range" && hasPeriodTickets) {
          let data = period_tickets.map((item) => {
            return {
              name: item.dateFormatted,
              "Periodo Actual": item.qty,
            };
          });

          customStats.title = "Distribución por rango de fechas";
          customStats.data = data;
          customStats.lines = 1;

          ticketsDistribution.title = "Distribución Temporal";

          let res_current_range_amounts = {
            qty:
              res.period_range_qtys &&
              res.period_range_qtys.hasOwnProperty("qty")
                ? res.period_range_qtys.qty
                : 0,
            amount:
              res.period_range_qtys &&
              res.period_range_qtys.hasOwnProperty("amount")
                ? res.period_range_qtys.amount
                : 0,
          };

          totalAmounts.current.firstTitle = "total de tickets";
          totalAmounts.current.firstAmount = res_current_range_amounts.qty;
          totalAmounts.current.firstUnit = "";
          totalAmounts.current.secondTitle = "monto acumulado";
          totalAmounts.current.secondAmount = res_current_range_amounts.amount;
          totalAmounts.current.secondUnit = "Bs.S";
          totalAmounts.last.firstTitle = "total de tickets";
          totalAmounts.last.firstAmount = res_current_range_amounts.qty;
          totalAmounts.last.firstUnit = "";
          totalAmounts.last.secondTitle = "monto acumulado";
          totalAmounts.last.secondAmount = res_current_range_amounts.amount;
          totalAmounts.last.secondUnit = "Bs.S";

          let res_current_range_types_status = {
            types:
              res.period_range_qtys &&
              res.period_range_qtys.hasOwnProperty("types")
                ? res.period_range_qtys.types
                : [],
            status:
              res.period_range_qtys &&
              res.period_range_qtys.hasOwnProperty("status")
                ? res.period_range_qtys.status
                : [],
          };

          typeStatusList.current = res_current_range_types_status;
          typeStatusList.last = res_current_range_types_status;

          if (res.period_range_qtys && res.period_range_qtys.buildings) {
            const hasTicketsPerBuilding =
              res.period_range_qtys.buildings.length > 0;

            if (hasTicketsPerBuilding) {
              const data = res.period_range_qtys.buildings.map((item) => {
                return {
                  name: item.building ? item.building : "",
                  tickets: item.qty ? item.qty : 0,
                };
              });
              barStats.last.title = "Distribución por edificio";
              barStats.last.data = data;
              barStats.last.keyY = "tickets";
              barStats.last.lineName = "Tickets";
              barStats.current.title = "Distribución por edificio";
              barStats.current.data = data;
              barStats.current.keyY = "tickets";
              barStats.current.lineName = "Tickets";
            }
          }
        } else {
          let data = [
            {
              name: moment().tz("America/Caracas").startOf("day"),
              "Periodo Actual": 0,
            },
          ];

          customStats.title = "Sin datos para mostrar";
          customStats.data = data;
          customStats.lines = 1;
        }

        let parkingData = initialState.parkingData;

        const history_tickets = res.history_tickets;
        if (history_tickets) {
          parkingData.iva =
            history_tickets && history_tickets.iva && history_tickets.iva.amount
              ? history_tickets.iva.amount / 100 + 1
              : 0;
          parkingData.pd_fee =
            history_tickets &&
            history_tickets.pd_fee &&
            history_tickets.pd_fee.amount
              ? history_tickets.pd_fee.amount
              : 0;

          if (history_tickets.tickets && history_tickets.tickets.length) {
            parkingData.tickets = history_tickets.tickets;
          }
        }
        const today_tickets_relevance = res.today_tickets_relevance
          ? res.today_tickets_relevance
          : null;
        if (today_tickets_relevance) {
          relevance = {
            today: today_tickets_relevance.today_qty
              ? today_tickets_relevance.today_qty
              : { qty: 0, amount: 0 },
            yesterday: today_tickets_relevance.yesterday_qty
              ? today_tickets_relevance.yesterday_qty
              : { qty: 0, amount: 0 },
            variation: today_tickets_relevance.variation
              ? today_tickets_relevance.variation
              : 0,
          };
        }
        const tables_data = res.relevance_tables ? res.relevance_tables : null;
        let entry_data = tables_data.entry_access_relevance
          ? tables_data.entry_access_relevance
          : [];
        const isValidEntryData = entry_data && entry_data.length > 0;

        let exit_data = tables_data.exit_access_relevance
          ? tables_data.exit_access_relevance
          : [];
        const isValidExitData = exit_data && exit_data.length > 0;

        if (isValidEntryData) {
          entry_data.map((item) => {
            if (item.name && item.building && item.relevance > 0) {
              tableStats.entry_relevance.push([
                { value: item.name },
                { value: item.building },
                { value: `${item.relevance.toFixed(2)}%` },
              ]);
            }
            return null;
          });

          tableStats.entry_relevance.sort((a, b) => {
            if (parseFloat(a[2].value) > parseFloat(b[2].value)) {
              return -1;
            } else return 1;
          });
        }
        if (isValidExitData) {
          exit_data.map((item) => {
            if (item.name && item.building && item.relevance > 0) {
              tableStats.exit_relevance.push([
                { value: item.name },
                { value: item.building },
                { value: `${item.relevance.toFixed(2)}%` },
              ]);
            }
            return null;
          });
          tableStats.exit_relevance.sort((a, b) => {
            if (parseFloat(a[2].value) > parseFloat(b[2].value)) {
              return -1;
            } else return 1;
          });
        }
        if (res.amount_recauded) {
          totalAmounts.title_qty = "Tickets acumulados";
          totalAmounts.total_qty = res.total_tickets ? res.total_tickets : 0;
          totalAmounts.total_amount = res.amount_recauded;
        }

        if (res.tickets_distribution) {
          let dist = res.tickets_distribution.map((item) => {
            return {
              name: moment(item.dateFormatted).format("ll"),
              tickets: item.qty,
            };
          });
          ticketsDistribution.data = dist;
          ticketsDistribution.keyY = "tickets";
          ticketsDistribution.lineName = "Tickets";
        }
        changeState({
          typeStatusList,
          totalAmounts,
          tableStats,
          barStats,
          ticketsDistribution,
          customStats,
          parkingData,
          relevance,
          loading: false,
          updateBySocket: false,
          render: true,
        });
      } catch (err) {
        console.log({ err });
        changeState({
          ...initialState,
        });
        if (
          err.id === "NO_TOKEN_PROVIDED" ||
          err.id === "AUTHENTICATE_FAILED" ||
          err.id === "DUPLICATE_SESSION"
        ) {
          throwPopupMessage("alert", errors.AUTHENTICATION);
        } else {
          throwPopupMessage("error", err.description);
        }
      }
    };
    if (state.submit) {
      getDashboard();
      changeState({ submit: false });
    }
    return () => {};
  }, [state.submit]);

  //handlers

  const setViewType = () => {
    let documentElement = document.documentElement,
      width = window.innerWidth || documentElement.clientWidth;
    if (width < 768) {
      changeState({ viewType: "phone", render: true });
    } else {
      changeState({ viewType: "desktop", render: true });
    }
  };

  const handleBuildingChange = (event, data) => {
    event.preventDefault();
    let buildingName = "";
    buildingsOptions.map((building) => {
      if (data.value === building.value) buildingName = building.text;
      return null;
    });
    changeState({ building: data.value, buildingName });
  };

  const handlePeriodChange = (event, data) => {
    event.preventDefault();
    let filterTime = "";
    optionsPeriods.map((period) => {
      if (data.value === period.value) filterTime = period.text;
      return null;
    });
    changeState({ period: data.value, filterTime });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    changeState({ submit: true });
  };

  const barHandlePeriod = (event) => {
    event.preventDefault();
    let periodSelected = event.currentTarget.id;
    let barStats = state.barStats;
    changeState({ barStats: { ...barStats, periodSelected } });
  };

  const amountsHandlePeriod = (event) => {
    event.preventDefault();
    let periodSelected = event.currentTarget.id;
    let totalAmounts = state.totalAmounts;
    changeState({ totalAmounts: { ...totalAmounts, periodSelected } });
  };

  const listTypesStatusHandlePeriod = (event) => {
    event.preventDefault();
    let periodSelected = event.currentTarget.id;
    let typeStatusList = state.typeStatusList;
    changeState({ typeStatusList: { ...typeStatusList, periodSelected } });
  };

  const listDataHandle = (event) => {
    event.preventDefault();
    let dataSelected = event.currentTarget.id;
    let typeStatusList = state.typeStatusList;
    changeState({ typeStatusList: { ...typeStatusList, dataSelected } });
  };

  /*  const setSocketRequest = (socket_res) => {
    if (!socket_res.error) {
      console.log("new ticket");
      let optionsItems = {
        parking: { searchByParking: true, updateBySocket: true },
      };
      changeState(optionsItems[state.itemSelected]);
    }
  };*/

  const view = {
    phone: (
      <ParkingPhoneView
        transition={state.transition}
        item={state.itemSelected}
        fromDate={fromDate}
        endDate={endDate}
        ticketsDistribution={state.ticketsDistribution}
        barStats={state.barStats}
        customStats={state.customStats}
        tableStats={state.tableStats}
        typeStatusList={state.typeStatusList}
        totalAmounts={state.totalAmounts}
        parkingData={state.parkingData}
        relevance={state.relevance}
        buildingName={state.buildingName}
        filterTime={state.filterTime}
        buildingsOptions={state.buildingsOptions}
        loading={state.loading}
        listTypesStatusHandlePeriod={listTypesStatusHandlePeriod}
        listDataHandle={listDataHandle}
        barHandlePeriod={barHandlePeriod}
        amountsHandlePeriod={amountsHandlePeriod}
        handleBuildingChange={handleBuildingChange}
        handlePeriodChange={handlePeriodChange}
        handleFromDate={handleFromDate}
        handleEndDate={handleEndDate}
        handleSubmit={handleSubmit}
        popupMessagesType={popupMessagesType}
        showPopup={showPopup}
        popupMessages={popupMessages}
        closePopup={closePopup}
      />
    ),
    desktop: (
      <ParkingView
        transition={state.transition}
        item={state.itemSelected}
        fromDate={fromDate}
        endDate={endDate}
        ticketsDistribution={state.ticketsDistribution}
        barStats={state.barStats}
        customStats={state.customStats}
        tableStats={state.tableStats}
        typeStatusList={state.typeStatusList}
        totalAmounts={state.totalAmounts}
        parkingData={state.parkingData}
        relevance={state.relevance}
        buildingName={state.buildingName}
        filterTime={state.filterTime}
        buildingsOptions={state.buildingsOptions}
        loading={state.loading}
        listTypesStatusHandlePeriod={listTypesStatusHandlePeriod}
        listDataHandle={listDataHandle}
        barHandlePeriod={barHandlePeriod}
        amountsHandlePeriod={amountsHandlePeriod}
        handleBuildingChange={handleBuildingChange}
        handlePeriodChange={handlePeriodChange}
        handleFromDate={handleFromDate}
        handleEndDate={handleEndDate}
        handleSubmit={handleSubmit}
        popupMessagesType={popupMessagesType}
        showPopup={showPopup}
        popupMessages={popupMessages}
        closePopup={closePopup}
      />
    ),
  };
  return view[state.viewType] || <></>;
};

const mapStateToProps = (state) => {
  let buildings = getBuildings(state);
  return { buildings };
};

const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(ParkingStats);
