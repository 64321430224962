import { InputFeeModel } from "./model";
import styled from "styled-components";
import { grey_4, grey_6 } from "assets/strings/colors";

const InputFee = styled(InputFeeModel)`
  #root && {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 2px solid ${grey_4};
    > .title {
      font-size: 1.7rem;
      color: ${grey_6};
    }
    > input {
      display: "block";
      border: none;
      border-radius: 1rem;
      font-size: 1.5rem;
      margin: 0 auto;
      text-align: center;
      outline: none;
      box-shadow: none;
    }
  }
`;
export { InputFee };
