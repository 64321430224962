import { useEffect, useContext } from "react";
import { MobilePaidsContext } from "../context";
import { getMobilePaidDetail } from "./api";

export const useFindDetails = () => {
  const { state, changeState, throwPopupMessage } =
    useContext(MobilePaidsContext);

  const mobilePaidDetails = async () => {
    changeState({ selectionLoading: true });
    try {
      const response = await getMobilePaidDetail(state.selectedMobilePaid._id);
      const res = await response.json();
      if (res.error) throw res.error;
      if (res.recharge) {
        changeState({
          selectedMobilePaid: {
            ...state.selectedMobilePaid,
            rechargeData: { ...res.recharge },
          },
        });
      }
    } catch (error) {
      throwPopupMessage("error", error.description);
    }
    changeState({ selectionLoading: false });
  };

  useEffect(() => {
    if (state.getMobilePaidDetail) {
      mobilePaidDetails();
      changeState({ getMobilePaidDetail: false });
    }
  }, [state.getMobilePaidDetail]);

  return null;
};
