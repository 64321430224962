import React, { PureComponent } from "react";
import { Icon } from "semantic-ui-react";

class EntitiesListModel extends PureComponent {
  render() {
    let {
      viewState,
      entities,
      formattingFunction,
      selectionHandler,
      additionalArgs,
      children,
      className,
    } = this.props;

    if (!additionalArgs) {
      additionalArgs = [];
    }

    let formattedEntitiesList = formattingFunction(
      entities,
      viewState,
      selectionHandler,
      ...additionalArgs
    );
    return (
      <dl className={className}>
        {children}
        {formattedEntitiesList}
      </dl>
    );
  }
}

const PaginationModel = ({
  nextPageExist,
  currentPage,
  children,
  handlePageDown,
  handlePageUp,
  className,
  includeReload,
  handleReload,
}) => {
  return (
    <div>
      {children}
      {(nextPageExist || currentPage > 1) && (
        <div className={className}>
          <div
            className={currentPage > 1 ? "button" : "button hidden"}
            onClick={handlePageDown}
          >
            <i className="angle left icon"></i>
          </div>
          <p className="page">{currentPage}</p>
          <div
            className={nextPageExist ? "button" : "button hidden"}
            onClick={handlePageUp}
          >
            <i className="angle right icon"></i>
          </div>
          {includeReload && (
            <div className="reload reload-button" onClick={handleReload}>
              <Icon name="redo" size="tiny" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export { EntitiesListModel, PaginationModel };
