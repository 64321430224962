import TransfersListHeaderModel from "./model";
import styled from "styled-components";
import { grey_4, bluePD } from "assets/strings/colors";

const TransfersListHeader = styled(TransfersListHeaderModel)`
  #root & {
    margin: 0;
    padding: 0 1rem;
    border-bottom: 2px solid ${bluePD};
    display: flex;
    align-items: flex-end;
    justify-content: space-evenly;
    height: 4vh;
    text-transform: uppercase;
    font-weight: bold;
    color: ${grey_4};
    > p {
      margin: 0;
      padding: 0;
      width: calc(20% - 2rem);
      text-align: center;
    }
  }
`;

export default TransfersListHeader;
