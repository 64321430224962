import { COMMERCES_ADD_MANY, COMMERCES_SELECT } from "redux/actions/commerces";

const initialState = {
  all: [],
  selected: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case COMMERCES_ADD_MANY:
      let { commerces } = action.payload;
      return {
        ...state,
        all: [...commerces],
      };

    case COMMERCES_SELECT:
      let { commerceId } = action.payload;
      return {
        ...state,
        selected: commerceId,
      };

    default:
      return state;
  }
}
