import styled from "styled-components";
import {
  MovementListItemModel,
  MovementPhoneListItemModel,
  MovementListHeaderModel,
  NoMovementFoundModel,
} from "./model";
import { grey_2, grey_4, red, green_6 } from "assets/strings/colors";

const MovementListItem = styled(MovementListItemModel)`
  #root & {
    margin: 0;
    padding: 0.7rem 0;
    cursor: pointer;
    border-bottom: 1px solid ${grey_4};
    font-size: 0.9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    :hover {
      background: ${grey_2};
    }
    > .period-section {
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: calc(25% - 0.25rem);
      > p {
        font-size: 0.8rem;
        margin: 0;
        > span {
          text-transform: uppercase;
          font-weight: bold;
        }
      }
    }
    > .amount-debit {
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: calc(25% - 0.25rem);
      color: ${red};
      font-size: 0.9rem;
    }
    > .amount-credit {
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: calc(25% - 0.25rem);
      font-size: 0.9rem;
      color: ${green_6};
    }
    > .user {
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 0.8rem;
      width: calc(25% - 0.25rem);
      > img {
        height: 1.5rem;
      }
    }
    > .funds {
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 0.8rem;
      width: calc(25% - 0.25rem);
      > p {
        margin: 0;
        text-align: center;
        > span {
          font-weight: bold;
        }
      }
    }
  }
`;

const MovementPhoneListItem = styled(MovementPhoneListItemModel)`
  #root & {
    margin: 0;
    padding: 0.7rem;
    font-size: 0.85rem;
    cursor: pointer;
    border-radius: 1rem;
    border-bottom: 1px solid ${grey_4};
    :hover {
      background: ${grey_2};
    }
    > .title {
      display: inline-block;
      margin-bottom: 0.3rem;
      text-transform: uppercase;
      font-size: 1.1rem;
      border-bottom: 1px solid ${grey_4};
    }
    .status {
      float: right;
      height: 2rem;
    }
    > p {
      margin: 0 0 0.6rem 0;
    }
  }
`;

const MovementListHeader = styled(MovementListHeaderModel)`
  #root & {
    margin: 0;
    > .col {
      margin: 1.2rem 0.5rem 0 0.5rem;
      padding-bottom: 0.3rem;
      display: inline-block;
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
      border-bottom: 1px solid ${grey_4};
    }
    > .forty {
      width: calc(40% - 1rem);
    }
    > .twenty {
      width: calc(20% - 1rem);
    }
    > .twenty-five {
      width: calc(25% - 1rem);
    }
  }
`;

const NoMovementFound = styled(NoMovementFoundModel)`
  #root & {
    text-align: center;
    font-size: 1.2rem;
    line-height: 62px;
  }
`;

export {
  MovementListItem,
  MovementListHeader,
  NoMovementFound,
  MovementPhoneListItem,
};
