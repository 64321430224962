import styled from "styled-components";
import { Button } from "semantic-ui-react";
import {
  grey_5,
  grey_6,
  bluePD,
  magenta,
  posDirectoBlue,
} from "assets/strings/colors";

const RoundButton = styled(Button)`
  #root & {
    margin: ${(props) => props.margins};
    border-radius: 150px;
    height: 8.15vh;
    min-height: 48px;
  }
`;

const BlueRoundButton = styled(RoundButton)`
  #root & {
    background: #6586cf;
    color: white;
    :hover {
      background: #546ea9;
    }
  }
`;
const NewBlueRoundButton = styled(RoundButton)`
  #root & {
    background: ${posDirectoBlue};
    color: white;
    :hover {
      opacity: 0.8;
      //background: #546ea9;
    }
  }
`;
const GreyRoundButton = styled(RoundButton)`
  #root & {
    margin-top: 1rem;
    background: ${grey_5};
    color: white;
    :hover {
      background: ${grey_6};
    }
  }
`;

const WhiteRoundButton = styled(RoundButton)`
  #root & {
    background: white;
    color: black;
    border: 1px solid ${grey_5};
    :hover {
      background: #f1f1f1;
    }
  }
`;
const MagentaRoundButton = styled(RoundButton)`
  #root & {
    background: ${magenta};
    color: white;
    border: 1px solid ${magenta};
    :hover {
      opacity: 0.8;
    }
  }
`;

const GradientRoundButton = styled(RoundButton)`
  #root & {
    background: linear-gradient(to right, ${magenta}, ${bluePD});
    border: linear-gradient(to right, ${magenta}, ${bluePD});
    color: white;
    :focus {
      outline: none;
      opacity: 0.7;
    }
  }
`;

export {
  RoundButton,
  BlueRoundButton,
  GreyRoundButton,
  WhiteRoundButton,
  GradientRoundButton,
  MagentaRoundButton,
  NewBlueRoundButton,
};
