import styled from 'styled-components'
import { PaymentMethodsModel } from './model'
import { grey_3 } from 'assets/strings/colors'

const PaymentMethods = styled(PaymentMethodsModel)`
  #root &{
    >.payment-method{
      border-bottom: 1px solid ${grey_3};
      padding-bottom: .5rem;
      margin-bottom: .5rem;
      >.title{
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: .2rem;
      }
      >.method{
        margin-bottom: .2rem;
        >p{
          margin: 0;
        }
      }
    }
  }
`
export { PaymentMethods }
