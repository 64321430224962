import React from "react";
import { Button } from "components/buttons";
import { EntitiesList } from "components/entities-list";
import { formatOfficeNumbersList } from "../office-numbers-list";

const SupportFormModel = ({
  whatsappNumber,
  officeNumbers,
  email,
  handleChangeWhatsappNumber,
  handleOfficeNumberSelection,
  handleChangeEmail,
  handleSubmit,
  className,
}) => {
  return (
    <form className={className} onSubmit={handleSubmit}>
      <p className="header">Números de Oficina</p>
      <EntitiesList
        height="calc(30vh - 3rem)"
        viewState={""}
        entities={officeNumbers}
        formattingFunction={formatOfficeNumbersList}
        selectionHandler={handleOfficeNumberSelection}
      />
      <p className="header">Número de Whatsapp</p>
      <input
        type="text"
        value={whatsappNumber}
        minLength={11}
        maxLength={11}
        onChange={handleChangeWhatsappNumber}
        className="support-input"
        required
      />
      <p className="header">Correo Electrónico</p>
      <input
        type="text"
        value={email}
        onChange={handleChangeEmail}
        className="support-input"
        required
      />
      <Button color="green" width="30%" disabled={whatsappNumber.length !== 11}>
        Guardar Cambios
      </Button>
    </form>
  );
};

export { SupportFormModel };
