import React from "react";
import MobilePaidsContext from "./context";
import { connect } from "react-redux";
import { clearReduxStore } from "redux/actions/general";
import { getSelectedCommerce as getSelectedCommerceFromRedux } from "redux/selectors";
import { useStateManager } from "hooks/use-state-manager";
import {
  throwPopupMessage as tPopupMessage,
  closePopup as tClosePopup,
} from "redux/actions/popup-messages";

const MobilePaidsState = ({
  selectedCommerce,
  viewType,
  children,
  throwPopupMessage,
  closePopup,
}) => {
  const initialState = {
    selectedCommerce,
    formData: {
      reference: "",
      amount: "",
      phone: "",
      status: [],
      page: 1,
      show: 25,
    },
    submitForm: false,
    loading: false,
    mobilePaids: [],
    selectedMobilePaid: null,
    showDetail: false,
    phoneView: viewType === "phone",
    username: "",
    changeStatus: false,
    sendUsername: false,
    nextPageExists: false,
    getConstants: true,
    bankList: [],
    selectionLoading: false,
    getMobilePaidDetail: false,
  };
  const [state, changeState] = useStateManager(initialState);

  return (
    <MobilePaidsContext.Provider
      value={{
        state,
        changeState,
        throwPopupMessage,
        closePopup,
        viewType,
      }}
    >
      {children}
    </MobilePaidsContext.Provider>
  );
};

const mapStateToProps = (state) => {
  let selectedCommerce = getSelectedCommerceFromRedux(state);
  return { selectedCommerce };
};

const mapDispatchToProps = {
  clearReduxStore,
  throwPopupMessage: tPopupMessage,
  closePopup: tClosePopup,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobilePaidsState);
