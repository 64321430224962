import React, { useContext } from "react";
import { UsersExtensionsContext } from "../context";
import { Grid } from "semantic-ui-react";
import { RightBorderGreyColumn } from "components/styled-grid-column";
import PopupMessage from "components/popup-message";
import { UserInfo } from "./user-info";
import { ExtensionForm } from "./extension-form";
import { MovementDetails } from "./movement-details";
import { OptionConfirmation } from "./option-confirmation";
import { UsersSearch } from "./user-search";
import MPosUsersExtensionsPhoneView from "./mpos-users-extensions-phone-view";

const MPosUsersExtensionsView = () => {
  const { popupMessages, popupMessagesType, showPopup, closePopup, viewport } =
    useContext(UsersExtensionsContext);

  if (viewport === "phone") return <MPosUsersExtensionsPhoneView />;
  return (
    <Grid padded>
      <PopupMessage
        messageType={popupMessagesType}
        showPopup={showPopup}
        messages={popupMessages}
        closePopup={closePopup}
      >
        <OptionConfirmation />
      </PopupMessage>

      <UsersSearch phone={8} tablet={8} computer={5} />

      <RightBorderGreyColumn phone={8} tablet={8} computer={6}>
        <UserInfo />
      </RightBorderGreyColumn>

      <Grid.Column phone={8} tablet={8} computer={5}>
        <ExtensionForm />
        <MovementDetails />
      </Grid.Column>
    </Grid>
  );
};

export default MPosUsersExtensionsView;
