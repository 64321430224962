import React from 'react'
import PropTypes from 'prop-types'
import BillItem from '../bill-items'
import { Button } from 'components/buttons'
import { general as texts } from 'assets/strings/texts'
import okCircleIcon from 'assets/img/ok-circle-icon.png'
import { tickets_validate as viewTexts } from 'assets/strings/texts'

const Bill = ({ header, billContent, currency, phoneView, resetView, className}) => {
  if (!billContent){
    return (
      <div className={className}>
        <h4>{header}</h4>
        <p>No se encontró comprobante</p>
        {phoneView &&
        <Button 
          color='blue'
          onClick={resetView}>
          {texts.accept}
        </Button>
      }
      </div>
    )
  }
  return(
    <div className={className}>
      {phoneView &&
        <h4>{viewTexts.successValidate}</h4>
      }
      {phoneView &&
        <img src={okCircleIcon} alt='' />
      }

      <h4>{header}</h4>

      <BillItem 
        description={viewTexts.ticket}
        price={billContent.amount}
        currency={currency}/>

      {billContent.pdFee !== 0 &&
        <BillItem 
          description={viewTexts.pdFee}
          price={billContent.pdFee}
          currency={currency}/>
      }

      {billContent.commerceFee !== 0 &&
        <BillItem 
          description={viewTexts.commerceFee}
          price={billContent.commerceFee}
          currency={currency}/>
      }

      <hr/>

      <BillItem 
        description={viewTexts.subTotal}
        currency={currency}
        price={billContent.subTotal} />
      <BillItem 
        description={viewTexts.taxName +' ('+billContent.taxPercent+'%)'}
        currency={currency}
        price={billContent.tax} />
      <BillItem 
        description={viewTexts.total}
        currency={currency}
        price={billContent.totalAmount} />

      {phoneView &&
        <Button 
          color='blue'
          onClick={resetView}>
          {texts.accept}
        </Button>
      }
    </div>
  )
}

Bill.propTypes = {
  header: PropTypes.string,
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string,
    price: PropTypes.number
  }))
}

export default Bill