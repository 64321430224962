import { DigitizeBodyModel } from "./model";
import styled from "styled-components";

const DigitizeBody = styled(DigitizeBodyModel)`
  #root && {
    height: calc(70vh - 4rem);
    min-height: 490px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export { DigitizeBody };
