import { GeneralDataModel, LastDetailModel } from "./model";
import styled from "styled-components";
import { grey_4, grey_1, bluePD } from "assets/strings/colors";

const GeneralData = styled(GeneralDataModel)`
  #root && {
    > .panels {
      width: 100%;
      > .panel {
        display: flex;
        flex-direction: column;
        ${
          "" /* height: ${(props) => {
          return props.item === "parking" ? "645px" : "450px";
        }}; */
        }
        height: clamp(645px, 60vh,1200px);
        background: white;
        margin: ${(props) => {
          return props.phoneView ? "1rem 0.4rem" : "1.3rem";
        }};
        border-radius: 0.4rem;
        box-shadow: 1px 1px 4px 0 rgba(34, 36, 38, 0.4);
        > .today-stats {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          height: calc(32.9vh - 4rem);
          min-height: 150px;
          > .title {
            margin: 0;
            padding-top: 1rem;
            text-align: center;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 1.5rem;
            color: ${grey_4};
          }
          > .qty {
            margin: 0;
            width: 100%;
            text-align: center;
            padding-left: 4rem;
            font-size: 4rem;
            > span {
              padding-left: 2rem;
              font-size: 2rem;
              color: rgba(100, 255, 100, 0.8);
            }
            > .negative {
              color: rgba(255, 0, 0, 0.9);
            }
          }
          > .qty-h {
            margin: 0;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size: 1.5rem;
            color: ${grey_4};
            > span {
              font-size: 2rem;
              padding-left: 2rem;
            }
          }
        }
        > .list-title {
          width: 100%;
          padding-top: 1rem;
          text-align: center;
          text-transform: uppercase;
          font-weight: bold;
          border-bottom: 1px solid ${grey_4};
        }
      }
    }
    .panels.panels-appear {
      opacity: 0;
      transform: translateX(30px);
    }
    .panels.panels-appear-active {
      opacity: 0.8;
      transform: translateX(-2px);
      transition: all 350ms ease-out;
    }
    .panels.panels-enter {
      opacity: 0;
      transform: translateX(30px);
    }
    .panels.panels-enter-active {
      opacity: 0.8;
      transform: translateX(-2px);
      transition: all 300ms ease-out;
    }
    .panels.panels-enter-done {
      opacity: 1;
      transform: translateX(0);
      transition: all 50ms ease-out;
    }
    .panels.panels-exit {
      opacity: 0.8;
      transform: translateX(2px);
    }
    .panels.panels-exit-active {
      opacity: 0;
      transform: translateX(-30px);
      transition: all 350ms ease-out;
    }
  }
`;

const LastDetail = styled(LastDetailModel)`
  #root && {
    width: 100%;
    margin-top: 1rem;
    > .title {
      text-align: center;
      text-transform: uppercase;
      padding: 1rem;
      font-size: 1.2rem;
      font-weight: bold;
      color: ${grey_4};
    }
    > .item {
      :hover {
        background: ${grey_1};
      }
      margin-top: -1.5rem;
      border-top: 1px solid ${bluePD}
      border-bottom: 1px solid ${bluePD}
      > .header {
        padding-top: 0.4rem;
        display: flex;
        font-size: 1rem;
        text-transform: uppercase;
        > p {
          font-size: clamp(0.5rem, 1vw, 1rem);
          font-weight: bold;
          color: ${grey_4};
          width: 25%;
          text-align: center;
        }
      }
      > .data {
        display: flex;
        padding-bottom: 0.4rem;
        font-size: clamp(0.5rem, 1vw, 0.8rem);
        > p {
          width: 25%;
          text-align: center;
        }
        > .user {
          color: blue;
        }
      }
    }
  }
`;

export { GeneralData, LastDetail };
