import React from "react";
import { Grid, GridColumn } from "semantic-ui-react";
import { Button } from "components/buttons";
import { RightBorderGreyColumn } from "components/styled-grid-column";
import { BankAccountList } from "./bank-account-list";
import { BankAccountDetail } from "./bank-account-detail";
import { BankAccountForm } from "./bank-account-form";
import { Loader } from "components/loader";
import { connect } from "react-redux";
import { clearReduxStore } from "redux/actions/general";
import { getSelectedCommerce } from "redux/selectors";

const MposWalletView = ({
  viewType,
  isSelected,
  showForm,
  edit,
  genLoading,
  bankList,
  indexSelected,
  accountSelected,
  bankAccounts,
  commerce,
  handleAccountSelection,
  handleShowForm,
  handleOptions,
  handleBack,
  update,
}) => {
  return (
    <Grid padded>
      <RightBorderGreyColumn
        width={8}
        style={{
          height: "calc(100vh - 4rem)",
        }}
      >
        {genLoading && <Loader margin="4rem 0 0 0" />}
        {!genLoading && [
          <BankAccountList
            viewType={viewType}
            bankAccounts={bankAccounts}
            handleAccountSelection={handleAccountSelection}
            handleOptions={handleOptions}
            indexSelected={indexSelected}
            key={"list"}
          />,
          <div
            key="newAccount"
            style={{
              justifyContent: "center",
              alignItems: "center",
              paddingLeft: "15%",
              marginTop: "15%",
            }}
          >
            <Button color="blue" width={"80%"} onClick={handleShowForm}>
              Nueva Cuenta
            </Button>
          </div>,
        ]}
      </RightBorderGreyColumn>
      <GridColumn
        width={8}
        style={{
          height: "calc(100vh - 4rem)",
        }}
      >
        {!genLoading && isSelected && !showForm && (
          <BankAccountDetail
            viewType={viewType}
            accountSelected={accountSelected}
            commerce={commerce}
            handleBack={handleBack}
            update={update}
            edit={edit}
            bankList={bankList}
            indexSelected={indexSelected}
          />
        )}
        {!genLoading && !isSelected && showForm && (
          <BankAccountForm
            viewType={viewType}
            handleBack={handleBack}
            update={update}
            bankList={bankList}
          />
        )}
      </GridColumn>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  let selectedCommerce = getSelectedCommerce(state);
  return { selectedCommerce };
};

const mapDispatchToProps = {
  clearReduxStore,
};

export default connect(mapStateToProps, mapDispatchToProps)(MposWalletView);
