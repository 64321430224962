import { useEffect, useState, useContext } from "react";
import { customerExtensionsAPI } from "api";
import { UsersExtensionsContext } from "../context";

export const useExtensionInfoService = () => {
  const [loading, setLoading] = useState(true);
  const [activate, setActivate] = useState(false);
  const { state, changeState, throwPopupMessage } = useContext(
    UsersExtensionsContext
  );

  useEffect(() => {
    const extensionInfo = async () => {
      setLoading(true);
      try {
        const user_id = state.selectedUser._id;
        const response = await customerExtensionsAPI.extensionInfo({ user_id });
        const res = await response.json();
        if (res.error) throw res.error;
        if (res.commerces) {
          const commerces = res.commerces;
          const schoolsOptions = commerces.map((commerce) => {
            return {
              value: commerce._id,
              text: commerce.school_name,
              key: commerce._id,
            };
          });
          changeState({ schoolsOptions });
        }
        if (res.design) {
          const design = res.design;
          const carnetOptions = design.map((des) => {
            return {
              value: des.value,
              text: des.name,
              key: des.value,
            };
          });
          changeState({ carnetOptions });
        }
      } catch (error) {
        throwPopupMessage("error", error.description || "");
      }
      setLoading(false);
    };
    if (activate || state.getInfo) {
      extensionInfo();
      setActivate(false);
      changeState({ getInfo: false });
    }
  }, [activate, state.getInfo]);

  return { loading, setActivate };
};
