import React from "react";
import UserListItem from "../user-list-item";
import defaultUserGrey from "assets/img/default-user-grey.png";
import { users_admin as viewTexts } from "assets/strings/texts";

const UserListItemModel = ({
  username,
  email,
  mobilePhone,
  idNumber,
  profileImg,
  index,
  handleUserSelection,
  className,
}) => {
  profileImg = profileImg ? profileImg : defaultUserGrey;
  return (
    <dd id={index} onClick={handleUserSelection} className={className}>
      <img className="img" src={profileImg} alt="user avatar" />
      <div className="data">
        <p className="username">@{username}</p>
        {/* <p className="email">{email}</p> */}
        <p className="phone">{mobilePhone}</p>
        <p className="idNumber">{idNumber}</p>
      </div>
    </dd>
  );
};

function formatUserList(users, viewState, handleUserSelection) {
  let formatUserList = [];
  if (viewState === "user_search_loading") {
    return (
      <div
        className="ui active loader"
        style={{
          marginTop: "-20rem",
          position: "relative",
        }}
      ></div>
    );
  }
  if (viewState === "user_search") {
    return <div className="not-found">{viewTexts.notFound}</div>;
  }
  if (
    viewState === "user_search_done" ||
    viewState === "user_selection" ||
    viewState === "user_selection_loading" ||
    viewState === "user_selection_done" ||
    viewState === "ticket_digitize_confirmation" ||
    viewState === "ticket_digitize_loading" ||
    viewState === "ticket_digitize_done" ||
    viewState === "movements_search_loading" ||
    viewState === "movements_pagination_loading" ||
    viewState === "movements_search_done" ||
    viewState === "movement_selection_done"
  ) {
    users.forEach((user, index) => {
      let idNumber = "";
      if (
        user.profile &&
        user.profile.identification &&
        user.profile.identification.doc_type &&
        user.profile.identification.doc_id
      ) {
        idNumber =
          user.profile.identification.doc_type +
          "-" +
          user.profile.identification.doc_id;
      }
      formatUserList.push(
        <UserListItem
          index={index}
          username={user.username}
          email={user.email}
          mobilePhone={
            user && user.profile && user.profile.mobile_phone
              ? user.profile.mobile_phone
              : "--"
          }
          idNumber={idNumber}
          handleUserSelection={handleUserSelection}
          key={user._id}
        />
      );
    });

    return formatUserList;
  }
}

export default UserListItemModel;
export { formatUserList };
