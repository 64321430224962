import { view_type_phone, view_type_desktop } from'assets/strings/constants'

export const RESPONSIVE_DATA_UPDATE = 'RESPONSIVE_DATA_UPDATE'

export const updateResponsiveData = () => {
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  let viewType = view_type_desktop
  if (windowWidth < 768) {
    viewType = view_type_phone
  }

  return {
    type: RESPONSIVE_DATA_UPDATE,
    payload: {
      responsiveData: {
        windowWidth,
        windowHeight,
        viewType
      },
    },
  }
}
