import React from "react";
import { RateForm } from "./rate-form";
import { Grid } from "semantic-ui-react";
import { SettingsHeader } from "./settings-header";
import PopupMessage from "components/popup-message";
import { Panel, PanelTitle } from "components/panel";
import { AccessesSettings } from "./accesses-settings";
import { building_settings as viewTexts } from "assets/strings/texts";

const MposBuildingSettingsView = ({
  viewState,
  building,
  popupMessagesType,
  showPopup,
  popupMessages,
  location,
  closePopup,
}) => {
  return (
    <Grid padded centered>
      <PopupMessage
        messageType={popupMessagesType}
        showPopup={showPopup}
        messages={popupMessages}
        closePopup={closePopup}
      />

      <Grid.Column mobile={16} tablet={15} computer={13}>
        {(viewState === "workspace_selection" ||
          viewState === "workspace_selection_loading") && (
          <Panel
            className={
              viewState === "workspace_selection_loading" && "inactive"
            }
          >
            {viewState === "workspace_selection_loading" && (
              <div className="ui active loader"></div>
            )}
            {viewState === "workspace_selection" && (
              <PanelTitle>{viewTexts.noWorkspaceSelected}</PanelTitle>
            )}
          </Panel>
        )}

        {viewState === "workspace_selection_done" && (
          <Panel>
            <SettingsHeader buildingName={building.commercial_name} />
          </Panel>
        )}

        {viewState === "workspace_selection_done" && (
          <RateForm
            building={building}
            rate={building.rate.amount}
            currency={building.rate.currency}
          />
        )}

        {viewState === "workspace_selection_done" && (
          <AccessesSettings
            building={building}
            accesses={building.accesses}
            buildingCode={building.code}
          />
        )}
      </Grid.Column>
    </Grid>
  );
};

export { MposBuildingSettingsView };
