import React from "react";
import DiscountCodesContext from "./context";
import { useStateManager } from "hooks/use-state-manager";
import { history as historyApi, create as createApi } from "../api";

const DiscountCodesState = ({
  throwPopupMessage,
  closePopup,
  viewType,
  children,
}) => {
  const initialState = {
    viewState: "default",
    selectedCode: null,
    history: {
      codes: [],
      pagination: {
        nextPageExist: false,
        currentPage: 1,
        limit: 25,
      },
      fromDate: null,
      endDate: null,
      type: "all",
      loading: false,
      submit: true,
    },
    newCode: {
      name: "",
      expiration_date: null,
      status: "active",
      vertica_type: ["pariking"],
      discount_type: "fixed",
      coupon_type: "public",
      coupon_owner: null,
      assign_massively: false,
      value: 0,
      file: null,
      submit: false,
      loading: false,
    },
  };
  const [state, changeState] = useStateManager(initialState);
  const typeMap = {
    public: "Público",
    private: "Privado",
  };
  const discountTypeMap = {
    fixed: "Por fracción",
  };
  const statusMap = {
    active: "Activo",
    inactive: "Inactivo",
  };

  const changeHistoryState = (value) => {
    changeState({ ...state, history: { ...state.history, ...value } });
  };
  const changeNewCodeState = (value) => {
    changeState({ ...state, newCode: { ...value } });
  };
  const handleCodeSelection = (selectedCode) => {
    changeState({ selectedCode, viewState: "detail" });
  };
  const handlePageUp = () => {
    changeHistoryState({
      pagination: {
        ...state.history.pagination,
        currentPage: state.history.pagination.currentPage + 1,
      },
      submit: true,
    });
  };
  const handlePageDown = () => {
    changeHistoryState({
      pagination: {
        ...state.history.pagination,
        currentPage: state.history.pagination.currentPage - 1,
      },
      submit: true,
    });
  };

  const asyncHistory = async () => {
    try {
      changeState({ history: { ...state.history, loading: true } });
      const response = await historyApi({
        date_range: {
          fromDate: state.history.fromDate,
          untilDate: state.history.endDate,
        },
        filter_type: state.history.type === "all" ? "" : state.history.type,
        pagination: {
          page: state.history.pagination.currentPage,
          limit: state.history.pagination.limit,
        },
      });
      if (!response.ok) throw new Error(response.message);
      const res = await response.json();
      changeState({
        history: {
          codes: [...res.coupons.items],
          pagination: {
            ...state.history.pagination,
            nextPageExist: res.coupons.nextPageExists,
          },
          loading: false,
        },
      });
    } catch (error) {
      changeState({
        history: {
          ...state.history,
          loading: false,
        },
      });
      throwPopupMessage(
        "error",
        error.message || "No se pudo obtener el historial"
      );
    }
  };

  const asyncNewCode = async () => {
    try {
      changeNewCodeState({ loading: true });
      const formData = new FormData();
      const {
        name,
        expiration_date,
        status,
        vertical_type,
        discount_type,
        coupon_type,
        coupon_owner,
        privateType,
        value,
        assign_massively,
        file,
      } = state.newCode;

      formData.append("name", name);
      formData.append("expiration_date", expiration_date);
      formData.append("status", status);
      formData.append("vertical_type", vertical_type);
      formData.append("discount_type", discount_type);
      formData.append("coupon_type", coupon_type);
      formData.append("value", value);
      if (coupon_type === "private")
        formData.append("assign_massively", assign_massively);
      if (coupon_type === "private" && privateType === "single")
        formData.append("coupon_owner", coupon_owner);
      if (coupon_type === "private" && privateType === "massive")
        formData.append("excelFile", file);

      const response = await createApi(formData);
      if (!response.ok) throw new Error(response.message);
      await response.json();
      throwPopupMessage("success", "Código creado con éxito");
      changeState({
        newCode: {
          ...initialState.newCode,
        },
        history: {
          ...state.history,
          submit: true,
        },
        viewState: "default",
      });
    } catch (error) {
      changeState({
        newCode: {
          ...state.newCode,
          submit: false,
          loading: false,
        },
      });
      throwPopupMessage(
        "error",
        error.message || "No se pudo crear el código de descuento"
      );
    }
  };
  return (
    <DiscountCodesContext.Provider
      value={{
        state,
        changeState,
        historyState: state.history,
        changeHistoryState,
        changeNewCodeState,
        throwPopupMessage,
        closePopup,
        viewType,
        asyncHistory,
        asyncNewCode,
        handleCodeSelection,
        handlePageUp,
        handlePageDown,
        statusMap,
        discountTypeMap,
        typeMap,
      }}
    >
      {children}
    </DiscountCodesContext.Provider>
  );
};

export default DiscountCodesState;
