import styled from 'styled-components'
import { grey_4, grey_6 } from 'assets/strings/colors'
import { InvoicePaymentFormModel } from './model'

const InvoicePaymentForm = styled(InvoicePaymentFormModel)`
  #root &{
    width: 100%;
    >.title{
      text-transform: uppercase;
      font-weight: bold;
      color: ${grey_6};
      margin: .2rem .5rem 1rem .5rem;
    }
    >.invoice-payment-form{
      >.input-section{
        text-align: left;
        margin: .5rem 0 .5rem 0;
        >.ui.selection.dropdown{
          display: block;
        }
        >input{
          border: none;
          width: 98%;
          min-width: 0;
          border-radius: 1rem;
          color: ${grey_6};
          font-size: 1.5rem;
          margin: .5rem 5px .5rem 5px;
          text-align: center;
          outline: none;
          box-shadow: none;
          transition: box-shadow 250ms linear;
          :focus{
            box-shadow: 0 1px ${grey_4};
          }
          ::-webkit-input-placeholder{
            color: lightgray;
          }
          :-ms-input-placeholder{
            color: lightgray;
          }
          ::-ms-input-placeholder{
            color: lightgray;
          }
          ::placeholder{
            color: lightgray;
          }
        }
        >label{
          font-size: .85rem;
          border-bottom: 1px solid ${grey_6};
          display: inline-block;
          text-transform: uppercase;
          font-weight: bold;
          color: ${grey_6};
          margin-bottom: .5rem;
        }
      }
    }
  }
`
export { InvoicePaymentForm }