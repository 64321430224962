import { PremiumBundleItemModel, BundlesListHeaderModel } from "./model";
import styled from "styled-components";
import {
  grey_4,
  grey_5,
  green_4,
  green_5,
  red,
  red_2,
} from "assets/strings/colors";

export const PremiumBundleItem = styled(PremiumBundleItemModel)`
  #root & {
    > .bundle {
      margin: 0;
      padding: 0;
      width: 100%;
      height: 6rem;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid ${grey_4};
      border-radius: 1rem;
      > .col {
        margin: 0.5rem 0.5rem 0 0.5rem;
        padding-bottom: 0.3rem;
        display: inline-block;
        > .status {
          width: 100%;
          padding: 0;
          margin: 0;
          text-align: center;
          align-self: flex-start;
          > img {
            height: 2rem;
          }
          > .active {
            text-transform: uppercase;
            font-weight: bold;
            color: #a2c400;
          }
          > .inactive {
            text-transform: uppercase;
            font-weight: bold;
            color: #ffc107;
          }
        }
      }
      > .fifty {
        width: calc(50% - 1rem);
      }
      > .thirty {
        width: calc(30% - 1rem);
      }
      > .buttons {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: calc(40% - 2rem);
        > button {
          margin: 0;
          padding: 0;
          font-size: 0.9rem;
          color: white;
          margin-bottom: 0.2rem;
          height: 2.5rem;
          width: calc(48% - 0.5rem);
          font-weight: bold;
          border: none;
          border-radius: 0.5rem;
          text-align: center;
          :hover {
            cursor: pointer;
          }
        }
        .grey-b {
          background: ${grey_4};
          :hover {
            background: ${grey_5};
          }
        }
        .red-b {
          background: ${red};
          :hover {
            background: ${red_2};
          }
        }
        .green-b {
          background: ${green_4};
          :hover {
            background: ${green_5};
          }
        }
      }
      :hover {
        cursor: default;
      }
    }
    .bundle.bundle-appear {
      opacity: 0;
      transform: translateX(-30px);
    }
    .bundle.bundle-appear-active {
      opacity: 0.8;
      transform: translateX(2px);
      transition: all 350ms ease-out;
    }
    .bundle.bundle-enter {
      opacity: 0;
      transform: translateX(-30px);
    }
    .bundle.bundle-enter-active {
      opacity: 0.8;
      transform: translateX(2px);
      transition: all 300ms ease-out;
    }
    .bundle.bundle-enter-done {
      opacity: 1;
      transform: translateX(0);
      transition: all 50ms ease-out;
    }
    .bundle.bundle-exit {
      opacity: 0.8;
      transform: translateX(-2px);
    }
    .bundle.bundle-exit-active {
      opacity: 0;
      transform: translateX(30px);
      transition: all 350ms ease-out;
    }
    @media (max-width: 800px) {
      > .bundle {
        > .buttons {
          flex-direction: column;
          > button {
            height: 1.7rem;
            font-size: 0.7rem;
            width: calc(100% - 1rem);
          }
        }
      }
    }
  }
`;

export const BundlesListHeader = styled(BundlesListHeaderModel)`
  #root & {
    margin: 0;
    margin-left: 1rem;

    > .col {
      margin: 0.5rem 0.5rem 0 0.5rem;
      padding-bottom: 0.3rem;
      display: inline-block;
      text-transform: uppercase;
      font-weight: bold;
      border-bottom: 1px solid ${grey_4};
    }
    > .forty {
      width: calc(40% - 2.5rem);
    }
    > .thirty {
      width: calc(30% - 0.7rem);
    }
  }
`;
