import React, { useContext } from "react";
import { WithdrawHistoryContext } from "../context";
import { WithdrawHistoryForm } from "components/withdraw-history-form";
import { EntitiesList, Pagination } from "components/entities-list";
import {
  formatWithdrawList,
  formatWithdrawPhoneList,
} from "../components/withdraw-list-item";
import { CounterPartSelect } from "components/counterpart-select";
import useOnMountHistory from "../hooks/useOnMountHistory";
import HorizontalLine from "components/horizontal-line";
import moment from "moment";

const HistoryFormAndList = () => {
  const {
    state,
    changeState,
    viewType,
    throwPopupMessage,
    setPagination,
    setApproveForm,
  } = useContext(WithdrawHistoryContext);

  useOnMountHistory();
  const phoneView = viewType === "phone";

  let formDataCache = {};
  const handleValidateWithdrawal = (event) => {
    event.preventDefault();
    changeState({
      validateWithdrawal: event.target.value,
    });
  };
  const handleSearchTypeButtons = (event) => {
    event.preventDefault();
    changeState({ searchType: event.target.value });
  };

  const handleStatusButtons = (event) => {
    event.preventDefault();
    const status = event.target.value;
    changeState({
      [status]: !state[status],
    });
  };
  const handleEndDate = (date) => {
    changeState({
      endDate: date ? moment(date).endOf("day") : date,
    });
  };
  const handleFromDate = (date) => {
    changeState({ fromDate: date ? moment(date).startOf("day") : date });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    formDataCache = {
      searchType: state.searchType,
      fromDate: state.fromDate,
      endDate: state.endDate,
    };
    if (
      (!formDataCache.fromDate || !formDataCache.endDate) &&
      formDataCache.searchType === "dateRange"
    ) {
      throwPopupMessage("error", "Faltan Campos que son obligatorios");
    } else {
      setPagination({ currentPage: 1, skip: 0, nextPageExist: false });
      changeState({ ...formDataCache, getWithdrawals: true });
    }
  };

  const handlePageDown = (event) => {
    event.preventDefault();
    // format request data
    const skip =
      (state.pagination.currentPage - 2) * state.pagination.withdrawPerPage;
    // Set loading state
    formDataCache = {
      searchType: state.searchType,
      fromDate: state.fromDate,
      endDate: state.endDate,
    };
    setPagination({ currentPage: state.pagination.currentPage - 1, skip });
    changeState({ ...formDataCache, getWithdrawals: true });
  };

  const handlePageUp = (event) => {
    event.preventDefault();

    const skip =
      state.pagination.currentPage * state.pagination.withdrawPerPage;
    // Set loading state
    formDataCache = {
      searchType: state.searchType,
      fromDate: state.fromDate,
      endDate: state.endDate,
    };
    setPagination({ currentPage: state.pagination.currentPage + 1, skip });
    changeState({ ...formDataCache, getWithdrawals: true });
  };
  const handleSelection = (event) => {
    event.preventDefault();
    const id = event.currentTarget.id;
    const withdrawSelected = state.withdrawals.find(
      (withdraw) => withdraw._id === id
    );

    const billId = withdrawSelected.bill_ref;
    const withdrawId = withdrawSelected._id;
    const status = withdrawSelected.status;

    changeState({
      withdrawSelected: null,
    });
    const cssTransitionTime = 1;
    setTimeout(
      () =>
        changeState({
          withdrawSelected,
        }),
      cssTransitionTime
    );
    const show = status === "pending";
    setApproveForm({ ...state.approveForm, billId, withdrawId, show });
    //if (show) throwPopupMessage("", "");
  };

  const onChangeCommerce = (data) => {
    changeState({ ...data });
  };

  const listHeight =
    state.searchType === "latests"
      ? "calc(100vh - 229px - 6.5rem)"
      : "calc(95vh - 230px - 6.5rem)";

  return (
    <>
      <WithdrawHistoryForm
        padding="1rem"
        loading={state.loading}
        phoneView={phoneView}
        searchType={state.searchType}
        withdrawSelected={state.withdrawSelected}
        validateWithdrawal={state.validateWithdrawal}
        fromDate={state.fromDate}
        endDate={state.endDate}
        pending={state.pending}
        approved={state.approved}
        handleValidateWithdrawal={handleValidateWithdrawal}
        handleSearchTypeButtons={handleSearchTypeButtons}
        handleStatusButtons={handleStatusButtons}
        handleFromDate={handleFromDate}
        handleEndDate={handleEndDate}
        handleSubmit={handleSubmit}
        disOverflow
      >
        <CounterPartSelect
          hideSwitch
          commerce
          allOptionAvailable
          onChangeCounterpart={onChangeCommerce}
          margin="1rem 0 0 0"
        />
      </WithdrawHistoryForm>
      <HorizontalLine />
      <EntitiesList
        margin="0.5rem 0 0 0"
        height={listHeight}
        viewState={state.loading}
        entities={[
          state.withdrawals,
          { approved: state.approved, pending: state.pending },
        ]}
        formattingFunction={
          phoneView ? formatWithdrawPhoneList : formatWithdrawList
        }
        selectionHandler={handleSelection}
      >
        <Pagination
          padding="0 0 0.8rem 0"
          nextPageExist={state.pagination.nextPageExist}
          currentPage={state.pagination.currentPage}
          handlePageUp={handlePageUp}
          handlePageDown={handlePageDown}
        />
      </EntitiesList>
    </>
  );
};

export default HistoryFormAndList;
