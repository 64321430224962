import React from "react";
import { Dropdown } from "semantic-ui-react";
import styled from "styled-components";

const OptionsModel = ({
  options,
  onChange,
  disabled,
  vertical = false,
  className,
}) => {
  const onClick = (event, data) => {
    const value = data.value;
    onChange(event, value);
  };
  return (
    <div className={className}>
      <Dropdown
        text=""
        icon={`ellipsis ${vertical ? "vertical" : "horizontal"}`}
        floating
        labeled
        disabled={disabled}
        className="icon"
      >
        <Dropdown.Menu direction="left">
          {options.map((option) => (
            <Dropdown.Item
              text={option.text}
              value={option.value}
              key={option.key}
              onClick={onClick}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

const Options = styled(OptionsModel)`
  #root & {
    display: flex;
    padding: 0;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin: 0;
    color: #808080;
  }
`;

export default Options;
