import React, { useEffect } from "react";
import { RegisterUserView } from "./components/register-user-view";
import {
  useTextInput,
  usePhoneInput,
  useNumberInput,
  useLowercaseInput,
} from "hooks/use-inputs";
import { useDropdown } from "hooks/use-dropdown";
import { useStateManager } from "hooks/use-state-manager";
import { usePopupMessages } from "hooks/use-popup-messages";
import { createCustomer } from "services/api";

const MPosUserRegister = ({ props }) => {
  const initialState = {
    loading: false,
    submit: false,
    resetInputs: false,
  };
  const [state, changeState] = useStateManager(initialState);
  const {
    popupMessages,
    popupMessagesType,
    showPopup,
    throwPopupMessage,
    closePopup,
  } = usePopupMessages(null, "", false);
  const {
    reset: resetUser,
    bind: { value: user, onChange: handleUser },
  } = useLowercaseInput("");
  const {
    reset: resetEmail,
    bind: { value: email, onChange: handleEmail },
  } = useTextInput("");
  const {
    bind: { value: docType, onChange: handleDocType },
  } = useDropdown("V");
  const {
    reset: resetDocId,
    bind: { value: docId, onChange: handleDocId },
  } = useNumberInput("");
  const {
    reset: resetPassword,
    bind: { value: password, onChange: handlePassword },
  } = useTextInput("");
  const {
    reset: resetConfirmPassword,
    bind: { value: confirmPassword, onChange: handleConfirmPassword },
  } = useTextInput("");
  const {
    reset: resetMobilPaidNumber,
    bind: { value: mobilePaidNumber, onChange: handleMobilePaidNumber },
  } = usePhoneInput("");

  useEffect(() => {
    const resetAllInputs = () => {
      resetUser();
      resetEmail();
      resetDocId();
      resetPassword();
      resetConfirmPassword();
      resetMobilPaidNumber();
    };
    const registerUser = async () => {
      try {
        changeState({ loading: true });
        const form = {
          username: user,
          email,
          doc_type: docType,
          doc_id: docId,
          password,
          mobile_phone: mobilePaidNumber,
        };
        const response = await createCustomer(form);
        const res = await response.json();
        if (res.error) throw res.error;
        changeState({
          ...initialState,
          resetInputs: true,
        });
        throwPopupMessage("success", res.message);
      } catch (error) {
        changeState({
          ...initialState,
        });
        throwPopupMessage("error", error.description);
      }
    };
    if (state.submit) {
      registerUser();
      changeState({ submit: false });
    }
    if (state.resetInputs) {
      resetAllInputs();
      changeState({ resetInputs: false });
    }
  }, [state.submit || state.resetInputs]);

  const handleSubmit = (event) => {
    event.preventDefault();
    changeState({ submit: true });
  };

  const handleBack = (event) => {
    event.preventDefault();
    changeState({ ...initialState, resetInputs: true });
  };

  return (
    <RegisterUserView
      loading={state.loading}
      popupMessages={popupMessages}
      popupMessagesType={popupMessagesType}
      showPopup={showPopup}
      closePopup={closePopup}
      handleContinue={handleBack}
      handleBack={handleBack}
      user={user}
      email={email}
      docType={docType}
      docId={docId}
      password={password}
      confirmPassword={confirmPassword}
      mobilePaidNumber={mobilePaidNumber}
      handleUser={handleUser}
      handleEmail={handleEmail}
      handleDocType={handleDocType}
      handleDocId={handleDocId}
      handlePassword={handlePassword}
      handleConfirmPassword={handleConfirmPassword}
      handleMobilePaidNumber={handleMobilePaidNumber}
      handleSubmit={handleSubmit}
    />
  );
};

export default MPosUserRegister;
