import { ReportFormModel } from "./model";
import styled from "styled-components";
import {
  grey_6,
  grey_4,
  bluePD,
  blue_2,
  green_5,
  green_6,
} from "assets/strings/colors";

export const ReportForm = styled(ReportFormModel)`
  #root & {
    width: 100%;
    display: flex;
    flex-direction: column;
    > .date-range {
      font-size: 0.85rem;
      border-bottom: 1px solid ${grey_6};
      text-transform: uppercase;
      font-weight: bold;
      color: ${grey_6};
      margin: 1rem 0 0 0;
      align-self: flex-start;
    }
    > .date-pickers {
      margin: 0;
      margin-top: 0.7rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      > p {
        margin: 0;
        font-weight: bold;
      }

      > div {
        width: 46%;

        > .react-datepicker__input-container {
          width: 100%;

          > input {
            width: calc(100% - 16px);
            border: none;
            border-radius: 1rem;
            color: ${grey_6};
            font-size: 1.2rem;
            margin: 0.5rem 5px 0.5rem 5px;
            text-align: center;
            outline: none;
            box-shadow: none;
            transition: box-shadow 250ms linear;

            :focus {
              box-shadow: 0 1px darkgrey;
            }
          }

          > .react-datepicker__close-icon::after {
            right: 0;
          }
        }

        > .react-datepicker-wrapper {
          width: 100%;

          > .react-datepicker__input-container {
            width: 100%;

            > input {
              width: calc(100% - 16px);
              border: none;
              border-radius: 1rem;
              color: ${grey_6};
              font-size: 1.2rem;
              margin: 0.5rem 5px 0.5rem 5px;
              text-align: center;
              outline: none;
              box-shadow: none;
              transition: box-shadow 250ms linear;

              :focus {
                box-shadow: 0 1px darkgrey;
              }
            }

            > .react-datepicker__close-icon::after {
              right: 15px;
            }
          }
        }
      }

      > div:last-child {
        > .react-datepicker-popper {
          > .react-datepicker {
            > .react-datepicker__triangle {
              right: 50px;
              left: auto;
            }
          }
        }
      }
    }
    > .file-form {
      margin: 0.5rem 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      > span {
        font-size: 0.85rem;
        border-bottom: 1px solid ${grey_6};
        text-transform: uppercase;
        font-weight: bold;
        color: ${grey_6};
        margin: 1rem 0 0 0;
        align-self: flex-start;
      }
      > label {
        width: 60%;
        .file-style {
          margin: 1rem 0 0 0;
          display: flex;
          align-items: center;
          justify-content: space-around;
          width: 100%;
          > p {
            margin: 0;
            padding: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          > .no-file {
            color: ${grey_4};
          }
          > button {
            margin: 0;
            width: 30%;
            height: 2.5rem;
            color: white;
            font-weight: bold;
            border: none;
            border-radius: 0.4rem;
            background: ${bluePD};
            :hover {
              cursor: pointer;
              background: ${blue_2};
            }
          }
        }
      }
    }
    > .transactions {
      > p {
        font-size: 0.85rem;
        border-bottom: 1px solid ${grey_6};
        display: inline-block;
        text-transform: uppercase;
        font-weight: bold;
        color: ${grey_6};
        margin: 1rem 0 0 0;
      }
    }
    > .submit {
      margin: 1rem 0 0 0;
      width: 30%;
      align-self: center;
      border-radius: 0.4rem;
      border: none;
      outline: none;
      height: 2.5rem;
      color: white;
      font-weight: bold;
      background: ${green_5};
      :hover {
        cursor: pointer;
        background: ${green_6};
      }
    }
    @media (max-width: 726px) {
      > .file-form {
        > label {
          width: 100%;
          font-size: 0.5rem;
        }
      }
    }
  }
`;
