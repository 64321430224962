import React, { useContext } from "react";
import { DiscountCodesContext } from "../../context";
import {
  Panel,
  PanelSectionHeader,
  PanelSectionContent,
} from "components/panel";
import { CSSTransition } from "react-transition-group";

import moment from "moment";
moment.locale();
const CodeDetailModel = ({ className }) => {
  const { state, typeMap, discountTypeMap, statusMap } =
    useContext(DiscountCodesContext);
  if (state.viewState !== "detail") return null;
  const code = state.selectedCode;
  return (
    <CSSTransition
      in={code && true}
      timeout={350}
      appear={code && true}
      classNames="panel"
    >
      <Panel>
        <PanelSectionHeader>Nombre:</PanelSectionHeader>

        <PanelSectionContent>{code.name}</PanelSectionContent>
        <PanelSectionHeader>Tipo:</PanelSectionHeader>
        <PanelSectionContent>
          {typeMap[code.coupon_type] || "N/A"}
        </PanelSectionContent>
        <PanelSectionHeader>Tipo de descuento:</PanelSectionHeader>
        <PanelSectionContent>
          {discountTypeMap[code.discount_type] || "N/A"}
        </PanelSectionContent>
        <PanelSectionHeader>Evita fees:</PanelSectionHeader>
        <PanelSectionContent>
          {code.avoid_fee ? "Sí" : "No"}
        </PanelSectionContent>
        <PanelSectionHeader>Fecha de creación:</PanelSectionHeader>
        <PanelSectionContent>
          {moment(code.created_at).format("LLL")}
        </PanelSectionContent>
        <PanelSectionHeader>Fecha de vencimiento:</PanelSectionHeader>
        <PanelSectionContent>
          {moment(code.expiration_date).format("LLL")}
        </PanelSectionContent>
        <PanelSectionHeader>Porcentaje:</PanelSectionHeader>
        <PanelSectionContent>{code.pd_percentage}</PanelSectionContent>
        <PanelSectionHeader>Valor:</PanelSectionHeader>
        <PanelSectionContent>{code.value}</PanelSectionContent>
        <PanelSectionHeader>Monto máximo de descuento:</PanelSectionHeader>
        <PanelSectionContent>
          {code.maximum_discount_amount}
        </PanelSectionContent>
        <PanelSectionHeader>Monto mínimo a aplicar:</PanelSectionHeader>
        <PanelSectionContent>
          {code.minimum_amount_to_apply}
        </PanelSectionContent>
        <PanelSectionHeader>Monto mínimo a pagar:</PanelSectionHeader>
        <PanelSectionContent>{code.minimum_amount_to_pay}</PanelSectionContent>
        <PanelSectionHeader>Estado:</PanelSectionHeader>
        <PanelSectionContent>
          {statusMap[code.status] || "N/A"}
        </PanelSectionContent>
      </Panel>
    </CSSTransition>
  );
};

export default CodeDetailModel;
