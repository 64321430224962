import { BundleDetailsModel } from "./model";
import styled from "styled-components";
import { grey_4 } from "assets/strings/colors";

export const BundleDetails = styled(BundleDetailsModel)`
  #root & {
    margin: 0;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    > .sub-title {
      width: 100%;
      font-weight: bold;
      text-decoration: underline;
      text-transform: uppercase;
      color: ${grey_4};
    }
    > .content {
      font-size: 1rem;
      text-align: center;
      width: 100%;
    }
  }
`;
