import BankAccountListModel, {
  NoAccountsModel,
  AccountListItemModel,
  AccountHeaderModel,
} from "./model";
import { grey_4, textualBlue, grey_1 } from "assets/strings/colors";
import styled from "styled-components";

const BankAccountList = styled(BankAccountListModel)`
  #root & {
    margin: 0;
    > .title {
      color: ${textualBlue};
      margin: 0;
      margin-top: 2rem;
      margin-bottom: 3rem;
      font-size: 1.5rem;
      text-align: center;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
`;

const AccountHeader = styled(AccountHeaderModel)`
  #root && {
    margin: 0rem;
    border-bottom: 2px solid ${textualBlue};
    > .col {
      color: ${grey_4};
      margin: 0 0.5rem 0 0.5rem;
      padding-bottom: 0.3rem;
      display: inline-block;
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
      font-size: 1.2rem;
    }
    > .fifty {
      width: calc(50% - 1rem);
    }
    > .forty {
      width: calc(45% - 1rem);
    }
    > .ten {
      width: calc(10% - 1rem);
    }
  }
`;

const NoAccounts = styled(NoAccountsModel)`
  #root & {
    margin-top: 2rem;
    text-align: center;
  }
`;
const AccountListItem = styled(AccountListItemModel)`
  #root & {
    cursor: pointer;
    padding-top: 0.2rem;
    display: flex;
    text-align: center;
    border-bottom: 1px solid ${grey_4};
    height: 4rem;
    background: ${(props) => {
      return props.selected ? grey_1 : "none";
    }};
    > .number {
      padding-top: 1rem;
      margin: 0 0.5rem 0 0.5rem;
      width: calc(45% - 1rem);
    }
    > .bank {
      padding-top: 1rem;
      margin: 0 0.5rem 0 0.5rem;
      width: calc(45% - 1rem);
    }
    > .options {
      position: relative;
      width: calc(10% - 1rem);
      border: none;
      background: none;
      box-shadow: none;
    }
  }
`;
export { BankAccountList, NoAccounts, AccountListItem, AccountHeader };
