import React, { PureComponent } from "react";
import moment from "moment";
import "moment/locale/es-us";
//import { Link } from "react-router-dom";
import { formatPrice } from "services/utils";
import { general as texts } from "assets/strings/texts";
import okCircleIcon from "assets/img/ok-circle-icon-64px.png";
import bellCircleIcon from "assets/img/bell-circle-icon-64px.png";
import excamCircleIcon from "assets/img/exclam-circle-icon-64px.png";
import { commerces_admin as viewTexts } from "assets/strings/texts";
import { WithdrawPhoneListItem, NoWithdrawFound } from "./index";
moment.locale("es-us");

class WithdrawPaymentListItemModel extends PureComponent {
  render() {
    let {
      withdrawId,
      handleWithdrawSelection,
      date,
      totalAmount,
      currency,
      status,
      checked,
      className,
    } = this.props;
    let checkedClass = "";
    if (checked === true) {
      checkedClass = "checked";
    }
    if (status === "approved") {
      handleWithdrawSelection = null;
      checkedClass = "inactive";
    }

    return (
      <dd className={className}>
        <div
          id={withdrawId}
          className="checkbox-container"
          onClick={handleWithdrawSelection}
        >
          <div className={"checkbox " + checkedClass}></div>
        </div>
        <div className="box">
          <div className="content">
            {status === "approved" && (
              <img src={okCircleIcon} alt="paid" className="status" />
            )}
            {status === "pending" && (
              <img src={bellCircleIcon} alt="pending" className="status" />
            )}
            {status === "reject" && (
              <img src={excamCircleIcon} alt="reject" className="status" />
            )}

            <p className="title">{viewTexts.withdrawTotalHeader}</p>
            <p>{formatPrice(totalAmount) + " " + currency}</p>
            <p className="title">{viewTexts.date}</p>
            <p>{date}</p>
          </div>
        </div>
        {/* </Link> */}
      </dd>
    );
  }
}

const NoWithdrawFoundModel = ({ className }) => {
  return <p className={className}>No se han encontrado retiros</p>;
};

function formatWithdrawPhoneList(
  withdraw,
  viewState,
  handleWithdrawSelection,
  selectedWithdraw,
  approved,
  pending
) {
  // Declaramos el array que devolverá la función
  let withdrawList = [];
  if (viewState === "commerce_withdraw_loading") {
    return (
      <div
        className="ui active loader"
        style={{
          position: "relative",
        }}
      ></div>
    );
  } else if (!withdraw.length) {
    return <NoWithdrawFound />;
  } else {
    if (pending && approved) {
      withdraw.forEach((withdrawU, index) => {
        let date = moment(withdrawU.created_at).format(texts.shortDateFormat);
        withdrawList.push(
          <WithdrawPhoneListItem
            handleWithdrawSelection={pending ? handleWithdrawSelection : null}
            date={date}
            totalAmount={withdrawU.pd_amount}
            currency={withdrawU.currency}
            status={withdrawU.status}
            checked={selectedWithdraw === withdrawU._id}
            withdrawId={withdrawU._id}
            key={withdrawU._id}
          />
        );
      });
    } else if (pending && !approved) {
      withdraw.forEach((withdrawU, index) => {
        let date = moment(withdrawU.created_at).format(texts.shortDateFormat);
        withdrawU.status === "pending" &&
          withdrawList.push(
            <WithdrawPhoneListItem
              handleWithdrawSelection={handleWithdrawSelection}
              date={date}
              totalAmount={withdrawU.pd_amount}
              currency={withdrawU.currency}
              status={withdrawU.status}
              checked={selectedWithdraw === withdrawU._id}
              withdrawId={withdrawU._id}
              key={withdrawU._id}
            />
          );
      });
    } else if (!pending && approved) {
      withdraw.forEach((withdrawU, index) => {
        let date = moment(withdrawU.created_at).format(texts.shortDateFormat);
        withdrawU.status === "approved" &&
          withdrawList.push(
            <WithdrawPhoneListItem
              handleWithdrawSelection={null}
              date={date}
              totalAmount={withdrawU.pd_amount}
              currency={withdrawU.currency}
              status={withdrawU.status}
              checked={selectedWithdraw === withdrawU._id}
              withdrawId={withdrawU._id}
              key={withdrawU._id}
            />
          );
      });
    }
    if (!withdrawList.length) return <NoWithdrawFound />;
    else return withdrawList;
  }
}

export {
  WithdrawPaymentListItemModel,
  formatWithdrawPhoneList,
  NoWithdrawFoundModel,
};
