import React from "react";
import BuildingListItem from "../building-list-item";
import { formatPrice } from "services/utils";
import { building_admin as viewTexts } from "assets/strings/texts";

const BuildingListItemModel = ({
  name,
  index,
  fee,
  handleBuildingSelection,
  className,
}) => {
  return (
    <dd id={index} onClick={handleBuildingSelection} className={className}>
      <div className="data">
        <p className="name">{name}</p>
        <p className="fee">{formatPrice(fee)} Bs</p>
      </div>
    </dd>
  );
};

function formatBuildingList(
  buildings,
  viewState,
  handleBuildingSelection,
  loading
) {
  let formatBuildingList = [];
  if (loading) {
    return <div className="ui active loader"></div>;
  }
  if (!buildings.length) {
    return <div className="not-found">{viewTexts.notFound}</div>;
  }
  buildings.forEach((building, index) => {
    formatBuildingList.push(
      <BuildingListItem
        index={index}
        name={building.name}
        fee={building.billing.parking_pd_fee}
        handleBuildingSelection={handleBuildingSelection}
        key={building._id}
      />
    );
  });

  return formatBuildingList;
}

export default BuildingListItemModel;
export { formatBuildingList };
