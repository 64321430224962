import React from "react";
import fullTicketsIcon from "assets/img/fullTickets.png";
import { Icon } from "semantic-ui-react";

const AlertMessageModel = ({
  type,
  errorType,
  message,
  handleContinue,
  handleBack,
  className,
}) => {
  return (
    <div className={className}>
      {type === "error" && errorType !== "no-tickets" && (
        <Icon
          name="exclamation triangle"
          size="massive"
          className="pd-colors"
        />
      )}
      {type === "error" && errorType === "no-tickets" && (
        <Icon name="remove circle" size="huge" color="red" />
      )}
      {type === "error" && errorType === "no-tickets" && (
        <img src={fullTicketsIcon} alt="alert" className="upside" />
      )}
      {type === "success" && (
        <Icon name="thumbs up outline" size="massive" className="pd-colors" />
      )}
      <p>{message}</p>
      <button
        onClick={
          type === "error" && errorType === "bad-input"
            ? handleBack
            : handleContinue
        }
      >
        {type === "error" && errorType === "bad-input" ? "Volver" : "Continuar"}
      </button>
    </div>
  );
};

export { AlertMessageModel };
