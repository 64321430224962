import React from "react";
import moment from "moment-timezone";
import { CSSTransition } from "react-transition-group";
import { EntitiesList } from "components/entities-list";
import { formatListDetail } from "../latest";

import { formatPrice } from "services/utils";

const GeneralDataModel = ({
  item = "wallet",
  transition,
  parkingData,
  usersData,
  relevance,
  negativeVariation,
  phoneView,
  className,
}) => {
  let title = "";
  let tickets = parkingData && parkingData.tickets ? parkingData.tickets : null;
  let users = usersData && usersData.users ? usersData.users : null;
  let lastEventTitle = "";
  switch (item) {
    case "parking":
      title = "Tickets de Hoy";
      lastEventTitle = "Últimos tickets";
      break;
    case "fuel":
      title = "Combustible";
      lastEventTitle = "Última transacción";
      break;
    case "services":
      title = "Servicios";
      lastEventTitle = "Último servicio utilizado";
      break;
    case "pay":
      title = "Pagos";
      lastEventTitle = "Último pago";
      break;
    case "wallet":
      title = "Transacciones";
      lastEventTitle = "Última Recarga";
      break;
    case "users":
      title = "Usuarios Registrados hoy";
      lastEventTitle = "Últimos Registros";
      break;
    default:
      break;
  }

  const defaultItemList = [];
  let itemsList = {
    parking: tickets,
    users: users,
  };

  return (
    <div className={className}>
      <CSSTransition
        in={transition}
        timeout={350}
        appear={true}
        classNames="panels"
      >
        <div className="panels">
          <div className="panel">
            <div className="today-stats">
              <p className="title">{title}</p>
              <p className="qty">
                {relevance.today.qty}
                <span className={negativeVariation ? "negative" : ""}>
                  {`${relevance.variation}`}%
                </span>
              </p>
              <p className="qty-h">
                Ayer{" "}
                {moment(relevance.yesterday.at, "hh:mm a").format("hh:mm a")}
                <span>{relevance.yesterday.qty}</span>
              </p>
            </div>
            <p className="list-title">{lastEventTitle}</p>
            <EntitiesList
              height={"450px"}
              viewState={item}
              entities={itemsList[item] || defaultItemList}
              formattingFunction={formatListDetail}
              selectionHandler={() => {}}
            />
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

const LastDetailModel = ({ title, lastEvent, className }) => {
  let { username, amount, ref, date } = lastEvent;
  return (
    <div className={className}>
      <p className="title">{title}</p>
      <div className="item">
        <div className="header">
          <p>Usuario:</p>
          <p>Monto:</p>
          <p>Referencia:</p>
          <p>Fecha:</p>
        </div>
        <div className="data">
          <p className="user">@{username}</p>
          <p>{formatPrice(amount)} Bs.S</p>
          <p>{formatPrice(ref)}</p>
          <p>
            {moment(date).tz("America/Caracas").format("DD/MM/YYYY hh:mm:ss")}
          </p>
        </div>
      </div>
    </div>
  );
};

export { GeneralDataModel, LastDetailModel };
