export const getBuildings = state => {
  return state.buildings.all
}

export const getBuildingById = (state, id) => {
  let building = null
  getBuildings(state).forEach(currentBuilding => {
    if(currentBuilding._id === id){
      building = currentBuilding
    }
  });
  return building
}

export const getSelectedBuilding = state => {
  return getBuildingById(state, state.buildings.selected)
}