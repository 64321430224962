import React, { PureComponent } from "react";
import moment from "moment";
import "moment/locale/es-us";
import { Button } from "components/buttons";
import { UsersPhoneListItem, NoUsersFound } from "../users-list-item";
moment.locale("es-us");

class UserListItemModel extends PureComponent {
  render() {
    let { user, username, handleResetPassword, className } = this.props;
    return (
      <dd className={className}>
        <div className="item">
          <p className="username">{username}</p>
        </div>
        <div className="item">
          <Button
            onClick={handleResetPassword}
            value={user ? JSON.stringify(user) : user}
            width="60%"
            color="blue"
            margin="0"
          >
            Restablecer contraseña
          </Button>
        </div>
      </dd>
    );
  }
}

const NoUsersFoundModel = ({ className }) => {
  return (
    <p className={className}>No se han encontrado usuarios operacionales</p>
  );
};

function formatUsersPhoneList(users, viewState, handleResetPassword) {
  let usersList = [];
  if (viewState === "commerce_getusers_loading") {
    return (
      <div
        className="ui active loader"
        style={{
          margin: "4rem 0 0 0",
          position: "relative",
        }}
      ></div>
    );
  } else if (!users.length) {
    return <NoUsersFound />;
  } else {
    users.map((user) => {
      usersList.push(
        <UsersPhoneListItem
          handleResetPassword={handleResetPassword}
          key={user._id}
          username={user.username}
          user={user}
        />
      );
      return null;
    });

    if (!usersList.length) return <NoUsersFound />;
    else return usersList;
  }
}

export { UserListItemModel, formatUsersPhoneList, NoUsersFoundModel };
