import React, { useContext, useState } from "react";
import { DateForm } from "components/date-form";
import { DiscountCodesContext } from "../../../context";
import { useDates } from "hooks/use-inputs";
import { Dropdown } from "components/dropdown";
import { Button } from "components/buttons";
import useHistoryList from "../../../hooks/useHistoryList";

import moment from "moment";

const options = [
  {
    key: "all",
    text: "Todos",
    value: "all",
  },
  {
    key: "reffered",
    text: "Referidos",
    value: "reffered",
  },
  {
    key: "private",
    text: "Privados",
    value: "private",
  },
  {
    key: "admin",
    text: "Admin",
    value: "admin",
  },
];

const HistoryFormModel = ({ className }) => {
  const {
    historyState: state,
    changeHistoryState,
    changeState,
    viewType,
  } = useContext(DiscountCodesContext);
  const { fromDate, endDate, handleFromDate, handleEndDate } = useDates(
    null,
    null
  );
  useHistoryList();
  const [type, setType] = useState("all");
  const onSubmit = (event) => {
    event.preventDefault();
    changeHistoryState({
      fromDate: fromDate ? moment(fromDate).format("YYYY-MM-DD") : null,
      endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
      type,
      submit: true,
    });
  };
  const onChangeOption = (event, { value }) => {
    event.preventDefault();
    changeHistoryState({ type: value });
    setType(value);
  };
  const onClickGlobalOption = (event, data) => {
    event.preventDefault();
    setTimeout(() => {
      changeState({
        viewState: "new",
      });
    }, 300);
  };
  return (
    <form className={className} onSubmit={onSubmit}>
      <div className="options">
        <Button
          color="blue"
          width="20%"
          type="button"
          disabled={state.loading}
          onClick={onClickGlobalOption}
          margin="0"
        >
          + Nuevo
        </Button>
      </div>
      <div className="dateform">
        <p className="date-label">Rango de fechas:</p>
        <DateForm
          fromDate={fromDate}
          endDate={endDate}
          loading={state.loading}
          handleFromDate={handleFromDate}
          handleEndDate={handleEndDate}
          phoneView={viewType === "phone"}
        />
      </div>
      <Dropdown
        value={type}
        placeholder="Tipo"
        options={options}
        onChange={onChangeOption}
        selection
        fluid
      />
      <Button
        color="grey"
        width="100%"
        disabled={
          !type ||
          (fromDate && !endDate) ||
          (!fromDate && endDate) ||
          state.loading
        }
      >
        Buscar
      </Button>
    </form>
  );
};

export default HistoryFormModel;
