import React from "react";
import moment from "moment-timezone";

moment.locale();

const LogDetailModel = ({ log, category, className }) => {
    const isParking = category === "estacionamiento";
    const typesOfMTD = {
        entry: "Entrada",
        exit: "Salida",
        default: "N/A",
    };
    let username = log && log.username ? log.username : "";
    let date =
        log && log.created_at
            ? moment(log.created_at)
                  .tz("America/Caracas")
                  .format("MMMM D YYYY, h:mm:ss a")
            : "N/A";
    let device = log && log.device_name ? log.device_name : "N/A";
    let adress = log && log.module_name ? log.module_name : "N/A";
    let rssiEvent = log && log.rssi_event ? log.rssi_event : "Ninguno";
    let localID = log && log.local_id ? log.local_id : "N/A";
    let type = typesOfMTD[log && log.type ? log.type : "default"];
    let step = log && log.step_event ? log.step_event : "N/A";
    let buildingName =
        log.building_access && log.building_access.name
            ? log.building_access.name
            : "N/A";
    let accessName = log.building_name ? log.building_name : "N/A";

    let message_type = "";
    let message = "";

    if (log.status) {
        let messageArray = log.status.split("-");
        if (messageArray.length > 1) {
            message_type = messageArray[0].includes("success")
                ? "success"
                : "error";
            message = messageArray[1];
        } else {
            message = log.status;
        }
    }
    if (message_type === "error" && isParking) {
        message = "No se pudo establecer comunicación entre dispositivos";
    }

    let connectionType =
        log &&
        log.customer &&
        log.customer.parking_configuration &&
        log.customer.parking_configuration.enable_new_connection_method
            ? " Método actual"
            : " Método antiguo";
    let appVersion =
        log && log.app_version ? log.app_version : "Versión no disponible";

    return (
        <div className={className}>
            <div className="box">
                <p className="title">Información General</p>
                <div>
                    <p>
                        <b>Usuario:</b> @{username}
                    </p>
                    <p>
                        <b>Fecha:</b> {date}
                    </p>
                    {!isParking && (
                        <p>
                            <b>APP:</b> {appVersion}
                        </p>
                    )}
                </div>
            </div>
            <div className="box">
                <p className="title">Información del MTD</p>
                <div>
                    {!isParking && (
                        <p>
                            <b>Módulo:</b> {adress}
                        </p>
                    )}
                    <p>
                        <b>Nombre:</b> {accessName}
                    </p>
                    {!isParking && (
                        <p>
                            <b>Edificio</b> {buildingName}
                        </p>
                    )}
                    <p>
                        <b>Tipo:</b> {type}
                    </p>
                    {!isParking && (
                        <p>
                            <b>Eventos de RSSI:</b> {rssiEvent}
                        </p>
                    )}
                </div>
            </div>
            <div className="box">
                <p className="title">Información del Dispositivo</p>
                <div>
                    <p>
                        <b>Nombre:</b> {device}
                    </p>
                    {!isParking && (
                        <p>
                            <b>ID local del MTD:</b> {localID}
                        </p>
                    )}
                    {!isParking && (
                        <p>
                            <b>Tipo de conexión:</b> {connectionType}
                        </p>
                    )}
                </div>
            </div>
            <div className="box">
                <p className="title">Mensajes</p>
                <div>
                    {!isParking && (
                        <p>
                            <b>Progreso:</b> {step}
                        </p>
                    )}
                    <p>
                        <b>Estado:</b>{" "}
                        {message_type === "success" ? "Exitoso" : "Error"}
                    </p>
                    <p>
                        <b>Mensaje:</b> {message}
                    </p>
                </div>
            </div>
        </div>
    );
};
export { LogDetailModel };
