import React from "react";
import {
  currencyAmount,
  mapStatus,
  checkField,
  checkDate,
} from "services/utils";
import moment from "moment";

const TransfersListItemModel = ({
  transfer,
  handleSelection,
  id,
  className,
}) => {
  const hasHour = checkField(transfer, "movement_hour") !== "N/A";
  return (
    <li className={className} onClick={handleSelection} id={id}>
      <p>{checkField(transfer, "reference")}</p>
      <p className="small">
        {checkDate(checkField(transfer, "movement_date"))}{" "}
        <span>
          {hasHour
            ? moment(transfer.movement_hour, "h:mm:ss").format("h:mm a")
            : ""}
        </span>
      </p>
      <p className="cap">{checkField(transfer, "bank")}</p>
      <p>{mapStatus(checkField(transfer, "status"))}</p>
      <p>{currencyAmount(checkField(transfer, "amount"), "Bs.")}</p>
    </li>
  );
};

export const TransfersListItemPhoneModel = ({
  transfer,
  handleSelection,
  id,
  className,
}) => {
  return (
    <li className={className} onClick={handleSelection} id={id}>
      <h5 className="title">Referencia:</h5>
      <p className="content">{checkField(transfer, "reference")}</p>
      <h5 className="title">Fecha:</h5>
      <p className="content">
        {checkDate(checkField(transfer, "movement_date"))}{" "}
        {checkDate(checkField(transfer, "movement_hour"))}
      </p>
      <h5 className="title">Banco:</h5>
      <p className="content cap">{checkField(transfer, "bank")}</p>
      <h5 className="title">Estado:</h5>
      <p className="content">{mapStatus(checkField(transfer, "status"))}</p>
      <h5 className="title">Monto:</h5>
      <p className="content">
        {currencyAmount(checkField(transfer, "amount"), "Bs.")}
      </p>
    </li>
  );
};

export default TransfersListItemModel;
