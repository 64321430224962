import React from "react";
import CountUp from "react-countup";

const SummaryTabsModel = ({ value, decimals = null, text, className }) => {
  return (
    <div className={className}>
      <p className="value">
         <CountUp
          start={0}
          end={value}
          duration={1.0}
          useEasing={true}
          decimals={decimals}
          useGrouping={true}
          separator="."
          decimal=","
        />
      </p>
      <p className="text">{text}</p>
    </div>
  );
};

export { SummaryTabsModel };
