import { micropd_02 } from "services/config";
import { post } from "services/http-methods";

const endpoints = {
  bankAmounts: `${micropd_02}/superadmin/bank-transaction/account-status`,
};

export const getBankAmounts = (body) => {
  return post(endpoints.bankAmounts, body);
};
