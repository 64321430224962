import React from "react";
import { EntitiesList } from "components/entities-list";
import { NoAccounts, AccountListItem, AccountHeader } from "./index";
import { Menu } from "semantic-ui-react";
import { DropdownSimple } from "components/dropdown";

const AccountHeaderModel = ({ className }) => {
  return (
    <div className={className}>
      <p className="col forty">Banco</p>
      <p className="col forty">Número</p>
    </div>
  );
};
const AccountListItemModel = ({
  className,
  accountNumber,
  bankName,
  index,
  handleAccountSelection,
  handleOptions,
  selected,
}) => {
  return (
    <div
      id={index}
      className={className}
      onClick={handleAccountSelection}
      selected={selected}
    >
      <p className="bank">{bankName}</p>
      <p className="number">{`${accountNumber.slice(
        0,
        4
      )}*******${accountNumber.slice(16, 20)}`}</p>
      <Menu className="options">
        <Menu.Menu position="right">
          <DropdownSimple
            icon="ellipsis vertical"
            text=" "
            placeholder=" "
            selectOnBlur={false}
            options={[
              {
                key: `{"edit": ${index}}`, // to parse in handler
                text: "Edición",
                value: "edit",
              },
              {
                key: `{"delete": ${index}}`,
                text: "Eliminar",
                value: "delete",
              },
            ]}
            onChange={handleOptions}
            wrapSelection={false}
            value={""}
          />
        </Menu.Menu>
      </Menu>
    </div>
  );
};
const NoAccountsModel = ({ className }) => {
  return (
    <div className={className}>
      <p>No ha registrado cuentas bancarias</p>
    </div>
  );
};

function formattingAccount(
  accounts,
  viewState,
  handleAccountSelection,
  handleOptions,
  indexSelected
) {
  let listAmounts = [];
  if (!accounts.length) {
    return <NoAccounts />;
  } else {
    accounts.forEach((account, index) => {
      listAmounts.push(
        <AccountListItem
          accountNumber={account.account_number}
          bankName={account.bank_name}
          handleAccountSelection={handleAccountSelection}
          handleOptions={handleOptions}
          key={account._id}
          index={index}
          selected={indexSelected === index}
        />
      );
    });
  }
  return listAmounts;
}

const BankAccountListModel = ({
  className,
  viewType,
  indexSelected,
  bankAccounts,
  handleAccountSelection,
  handleOptions,
}) => {
  return (
    <div className={className}>
      <p className="title">Cuentas Registradas</p>
      <AccountHeader />
      <EntitiesList
        height="calc(60vh - 4rem)"
        entities={bankAccounts}
        formattingFunction={formattingAccount}
        selectionHandler={handleAccountSelection}
        additionalArgs={[handleOptions, indexSelected]}
      />
    </div>
  );
};

export default BankAccountListModel;

export { NoAccountsModel, AccountListItemModel, AccountHeaderModel };
