import VerticalDividerModel from "./model";
import styled from "styled-components";
import { grey_4 } from "assets/strings/colors";

export const VerticalDivider = styled(VerticalDividerModel)`
  #root & {
    position: relative;
    > .grey-line {
      position: absolute;
      height: calc(100vh - 4rem);
      right: 0px;
      top: 0px;
      width: 1px;
      background-color: ${grey_4};
    }
  }
`;
