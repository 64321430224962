import styled from "styled-components";
import { SearchByTicketsModel } from "./model";
import { grey_4 } from "assets/strings/colors";

const SearchByTickets = styled(SearchByTicketsModel)`
  height: 100vh;
  > .selector {
    display: flex;
    justify-content: center;
    margin-bottom: 0.5rem;
  }
  > .list {
    border-top: solid 1px ${grey_4};
  }
  > .buttons {
    border-top: solid 1px ${grey_4};
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
`;
export { SearchByTickets };
