import React, { useState } from "react";
import { UsersReportContext } from "./context";
import { useStateManager } from "hooks/use-state-manager";
import { usePopupMessages } from "hooks/use-popup-messages";
import { useDates } from "hooks/use-inputs";

export const UsersReportState = ({ buildings, selectedCommerce, children }) => {
  const isSuperadmin = selectedCommerce.category === "pagodirecto";

  const initialState = {
    loading: false,
    reportValue: "parkingLogs",
    transactionTag: "recharge",
    _file: "",
    submit: false,
    commerces: [],
    tolls: [],
    selectedCommerce: null,
    schoolOptions: [],
    schoolSelected: null,
    buildingOptions: isSuperadmin
      ? buildings.map((building) => ({
          value: building.code,
          key: building._id,
          text: building.name,
        }))
      : [],
    buildingSelected: isSuperadmin
      ? null
      : selectedCommerce.parking.building.code,
    isSuperadmin,
  };
  const [file, setFile] = useState(null);
  const {
    popupMessages,
    popupMessagesType,
    showPopup,
    throwPopupMessage,
    closePopup,
  } = usePopupMessages(null, "", false);
  const { fromDate, endDate, handleFromDate, handleEndDate } = useDates(
    null,
    null
  );

  const [state, changeState] = useStateManager(initialState);
  return (
    <UsersReportContext.Provider
      value={{
        state,
        changeState,
        fromDate,
        endDate,
        file,
        setFile,
        handleFromDate,
        handleEndDate,
        popupMessages,
        popupMessagesType,
        showPopup,
        throwPopupMessage,
        closePopup,
      }}
    >
      {children}
    </UsersReportContext.Provider>
  );
};
