import React from "react";
import { SuperadminBillsState } from "./context";
import SuperadminBillsView from "./components/superadmin-bills-view";

const MPosSuperadminBills = () => {
  return (
    <SuperadminBillsState>
      <SuperadminBillsView />
    </SuperadminBillsState>
  );
};

export default MPosSuperadminBills;
