import React from "react";
import { CSSTransition } from "react-transition-group";
import BarGraph from "components/bar-graph";
import { bluePD } from "assets/strings/colors";

const bar = {
  //lineName: "Tickets",
  height: 180,
  fill: bluePD,
  keyX: "name",
  keyY: "tickets",
  margin: {
    bottom: 0,
    left: -28,
    top: 0,
    right: 0,
  },
};

const RowModel = ({ transition, barStats, barHandlePeriod, className }) => {
  const period =
    barStats && barStats.periodSelected ? barStats.periodSelected : null;
  const users = !period;
  return (
    <div className={className}>
      <CSSTransition
        in={transition}
        timeout={350}
        appear={true}
        classNames="row"
      >
        <div className="row-parking">
          <div className="center">
            <div className="selectors">
              <p className="title">
                {" "}
                {period ? barStats[period].title : barStats.title}
              </p>
              {period && (
                <div className="periods">
                  <p
                    className={period === "last" ? "box-selected" : "box"}
                    onClick={barHandlePeriod}
                    id="last"
                  >
                    Periodo Anterior
                  </p>
                  <p
                    id="current"
                    className={period === "current" ? "box-selected" : "box"}
                    style={{ height: "20px" }}
                    onClick={barHandlePeriod}
                  >
                    Periodo Actual
                  </p>
                </div>
              )}
            </div>
            <BarGraph
              data={period ? barStats[period].data : barStats.data}
              lineName={period ? barStats[period].lineName : barStats.lineName}
              keyX={bar.keyX}
              keyY={
                period && barStats[period].keyY
                  ? barStats[period].keyY
                  : users
                  ? "users"
                  : bar.keyY
              }
              height={bar.height}
              margin={bar.margin}
              fill={bar.fill}
            />
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export { RowModel };
