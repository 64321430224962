import { getSuperAdminTransactions } from "services/api";
import { errors } from "assets/strings/texts";

const formatRequestData = (state) => {
  let type = "debit",
    currency = "BS",
    transaction_tag = "withdraw",
    skip = state.pagination.skip ? state.pagination.skip : 0,
    limit,
    fromDate,
    endDate,
    status = null,
    user_id = state.commerce_counterpart_id;
  if (state.searchType === "dateRange" && state.fromDate)
    fromDate = state.fromDate.toISOString();
  else fromDate = null;
  if (state.searchType === "dateRange" && state.endDate)
    endDate = state.endDate.toISOString();
  else endDate = null;
  limit = state.pagination.withdrawPerPage + 1;

  let { pending, approved } = state;

  if (pending && !approved) {
    status = "pending";
  } else if (!pending && approved) {
    status = "approved";
  } else {
    status = null;
  }
  return {
    fromDate,
    endDate,
    skip,
    limit,
    type,
    currency,
    transaction_tag,
    status,
    user_id,
  };
};

const getWithdrawals = async (context) => {
  const { state, changeState, setPagination, throwPopupMessage } = context;
  try {
    changeState({
      loading: true,
    });
    const reqData = formatRequestData(state);
    const response = await getSuperAdminTransactions({
      ...reqData,
      commerce_id: state.commerce._id,
    });

    const res = await response.json();
    if (res.error) throw res.error;
    const withdrawals = [...res.transactions];
    const nextPageExist = withdrawals.length > state.pagination.withdrawPerPage;
    changeState({
      withdrawals,
      loading: false,
    });
    setPagination({ nextPageExist });
  } catch (error) {
    if (
      error.id === "NO_TOKEN_PROVIDED" ||
      error.id === "AUTHENTICATE_FAILED" ||
      error.id === "DUPLICATE_SESSION"
    ) {
      throwPopupMessage("alert", errors.AUTHENTICATION);
    } else {
      changeState({ loading: false });
      throwPopupMessage("error", errors.GENERAL_ERR);
    }
  }
};

export default getWithdrawals;
