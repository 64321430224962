import React from "react";
import moment from "moment";
import "moment/locale/es-us";
//import okCircleIcon from "assets/img/ok-circle-icon-64px.png";
//import excamCircleIcon from "assets/img/exclam-circle-icon-64px.png";
import { formatPrice } from "services/utils";
import {
  TransferListItem,
  TransferPhoneListItem,
  TransferListHeader,
  NoTransferFound,
} from "../transfers-list-item";

moment.locale("es-us");

export const TransferListItemModel = ({
  handleSelection,
  date,
  type,
  amount,
  concept,
  reference,
  index,
  className,
}) => {
  //const typeMapped = type === "Crédito" ? "credit" : "debit";
  /*const typeComponents = {
    credit: {
      icon: <img src={okCircleIcon} alt="credit" />,
      label: <p className="credit">Crédito</p>,
    },
    debit: {
      icon: <img src={excamCircleIcon} alt="debit" />,
      label: <p className="debit">Débito</p>,
    },
  };*/
  //const typeComponentToShow =
  //typeComponents[typeMapped] || typeComponents.credit;
  const stringAmount = `${formatPrice(amount)} Bs.S`;
  return (
    <dd id={index} className={className} onClick={handleSelection}>
      <div className="period-section">
        <p className="date">{date}</p>
      </div>
      <p className="amount">{stringAmount}</p>
      {/*<div className="type">
        {typeComponentToShow.icon}
        {typeComponentToShow.label}
      </div>*/}
      <div className="ref">
        <p>{reference}</p>
      </div>
      <div className="ref">
        <p>{concept}</p>
      </div>
    </dd>
  );
};

export const TransferPhoneListItemModel = ({
  handleSelection,
  date,
  type,
  amount,
  concept,
  reference,
  index,
  className,
}) => {
  /*const typeMapped = type === "Crédito" ? "credit" : "debit";
  const typeComponents = {
    credit: <img src={okCircleIcon} alt="approved" className="type" />,
    debit: <img src={excamCircleIcon} alt="rejected" className="type" />,
  };*/
  //const typeComponentToShow =
  //typeComponents[typeMapped] || typeComponents.credit;
  const stringAmount = `${formatPrice(amount)} Bs.S`;

  return (
    <dd id={index} className={className} onClick={handleSelection}>
      {/*typeComponentToShow*/}
      <p className="title">Monto</p>
      <p>{stringAmount}</p>
      <p className="title">Fecha</p>
      <p>{date}</p>
      <p className="title">Referencia</p>
      <p>{reference}</p>
      <p className="title">Concepto</p>
      <p>{concept}</p>
    </dd>
  );
};

export const TransferListHeaderModel = ({ className }) => {
  return (
    <dd className={className}>
      <p className="col twenty-five">Fecha</p>
      <p className="col twenty-five">Monto</p>
      {/*<p className="col twenty">Tipo</p>*/}
      <p className="col twenty-five">Referencia</p>
      <p className="col twenty-five">Concepto</p>
    </dd>
  );
};

export const NoTransferFoundModel = ({ className }) => {
  return <p className={className}>No se ha encontrado transacciones</p>;
};

export function formatTransferList(
  transfers,
  loading,
  handleTransferSelection
) {
  // Declaramos el array que devolverá la función
  let allTransfers = transfers;
  //let approved = withdraw[1].approved;
  //let pending = withdraw[1].pending;

  let transferList = [];
  let formatString = "DD [de] MMMM YYYY";
  if (loading) {
    return (
      <div
        className="ui active loader"
        style={{
          marginTop: "3rem",
          position: "relative",
        }}
      ></div>
    );
  }
  if (!(allTransfers instanceof Array) || !allTransfers.length) {
    return <NoTransferFound />;
  } else {
    transferList.push(<TransferListHeader key="header" />);
    allTransfers.forEach((transfer, index) => {
      let date = moment(
        transfer.created_at ? transfer.created_at : transfer.updated_at
      ).format(formatString);
      transferList.push(
        <TransferListItem
          handleSelection={handleTransferSelection}
          date={date}
          amount={transfer.amount}
          reference={transfer.reference}
          type={transfer.type}
          concept={transfer.concept}
          index={index}
          key={index}
        />
      );
    });
    return transferList;
  }
}

export function formatTransferPhoneList(
  transfers,
  loading,
  handleTransferSelection
) {
  // Declaramos el array que devolverá la función
  let allTransfers = transfers;
  //let approved = withdraw[1].approved;
  //let pending = withdraw[1].pending;

  let transferList = [];
  let formatString = "DD [de] MMMM YYYY";
  if (loading) {
    return (
      <div
        className="ui active loader"
        style={{
          marginTop: "3rem",
          position: "relative",
        }}
      ></div>
    );
  }
  if (!(allTransfers instanceof Array) || !allTransfers.length) {
    return <NoTransferFound />;
  } else {
    allTransfers.forEach((transfer, index) => {
      let date = moment(transfer.date).format(formatString);
      transferList.push(
        <TransferPhoneListItem
          handleSelection={handleTransferSelection}
          date={date}
          amount={transfer.amount}
          reference={transfer.reference}
          type={transfer.type}
          index={index}
          key={index}
        />
      );
    });
    return transferList;
  }
}
