import React from "react";
import { EntitiesList } from "components/entities-list";
import { UserRefItem } from "../users-ref";

const UserRefItemModel = ({ username, className }) => {
  return (
    <div className={className}>
      <p>@{username}</p>
    </div>
  );
};

function formatUsersRef(usersList) {
  let usersRef = [];
  const hasUsers = usersList.length;
  if (!hasUsers)
    return (
      <p style={{ paddingTop: "1rem", fontSize: "1.4rem" }}>
        Este Usuario no ha recomendado la aplicación
      </p>
    );
  usersList.forEach((user) => {
    usersRef.push(<UserRefItem username={user.username} key={user._id} />);
  });
  return usersRef;
}

const UserRefListModel = ({ users, className }) => {
  return (
    <div className={className}>
      <EntitiesList entities={users} formattingFunction={formatUsersRef} />
    </div>
  );
};
export { UserRefItemModel, UserRefListModel };
