import React from "react";
import { Button } from "components/buttons";
import { Link } from "react-router-dom";

export const TransferResultModel = ({
  transferResult,
  message,
  withdraw,
  className,
}) => {
  const {
    reference,
    receiver_document,
    receiver_account,
    receiver_name,
    concept,
    amount,
  } = transferResult;
  const withdrawExist = withdraw._id;
  return (
    <div className={className}>
      <p className={message}>{message}</p>
      <div className="field">
        <p className="title">Receptor:</p>
        <p>{receiver_name}</p>
      </div>

      <div className="field">
        <p className="title">Cuenta receptora:</p>
        <p className="description">{receiver_account}</p>
      </div>

      <div className="field">
        <p className="title">Documento de receptor:</p>
        <p className="description">{receiver_document}</p>
      </div>

      <div className="field">
        <p className="title">Monto:</p>
        <p className="description">{amount}</p>
      </div>

      <div className="field">
        <p className="title">Referencia:</p>
        <p className="description">{reference}</p>
      </div>

      <div className="field">
        <p className="title">Concepto:</p>
        <p className="description">{concept}</p>
      </div>
      {withdrawExist && (
        <Link
          to={{
            pathname: "/mpos/withdraw/history",
            state: {
              withdraw: { ...withdraw, transferReference: reference },
            },
          }}
          className="link"
        >
          <Button color="blue" width="100%">
            Aprobar retiro
          </Button>
        </Link>
      )}
    </div>
  );
};
