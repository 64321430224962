import React from "react";
export const Loader = ({ margin }) => {
  return (
    <div
      className="ui active loader"
      style={{
        margin: margin ? margin : 0,
      }}
    ></div>
  );
};
