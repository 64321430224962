import { WithdrawValidatorFormModel } from "./model";
import styled from "styled-components";
import { grey_4, grey_6 } from "assets/strings/colors";

const WithdrawValidatorForm = styled(WithdrawValidatorFormModel)`
  #root & {
    margin: 0;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > .header {
      width: 100%;
      padding-top: 2rem;
      text-transform: uppercase;
      font-weight: bold;
      color: ${grey_6};
      margin: 0.2rem 0.5rem 1rem 0.5rem;
    }
    > .input-section {
      text-align: left;
      margin: 0.5rem 0 0.5rem 0;
      > .ui.selection.dropdown {
        display: block;
      }

      > input {
        border: none;
        width: 98%;
        min-width: 0;
        border-radius: 1rem;
        color: ${grey_6};
        font-size: 1.5rem;
        margin: 0.5rem 5px 0.5rem 5px;
        text-align: center;
        outline: none;
        box-shadow: none;
        transition: box-shadow 250ms linear;
        :focus {
          box-shadow: 0 1px ${grey_4};
        }
        ::-webkit-input-placeholder {
          color: lightgray;
        }
        :-ms-input-placeholder {
          color: lightgray;
        }
        ::-ms-input-placeholder {
          color: lightgray;
        }
        ::placeholder {
          color: lightgray;
        }
      }
      > label {
        font-size: 0.85rem;
        border-bottom: 1px solid ${grey_6};
        display: inline-block;
        text-transform: uppercase;
        font-weight: bold;
        color: ${grey_6};
        margin-bottom: 0.5rem;
      }
    }
  }
`;
export { WithdrawValidatorForm };
