import React, { useContext } from "react";
import { DiscountCodesContext } from "../../../context";
import { Loader } from "components/loader";
import { EntitiesList, Pagination } from "components/entities-list";
import { NoItemsFound, Item, ItemsHeader } from "./styles";

import moment from "moment";

export const NoItemsFoundModel = ({ className }) => {
  return <div className={className}>No se encontraron resultados</div>;
};

export const ItemModel = ({ code, handleSelection, className }) => {
  const onClick = () => {
    handleSelection(code);
  };

  const typeMap = {
    public: "Público",
    private: "Privado",
  };

  return (
    <div className={className} onClick={onClick}>
      <p className="col twenty name">{code.name}</p>
      <p className="col twenty type">{typeMap[code.coupon_type] || "N/A"}</p>
      <p className="col twenty value">{code.value || "N/A"}</p>
      <p className="col twenty percentage">{code.pd_percentage}</p>
      <p className="col twenty expiration">
        {moment(code.created_at).format("ll")}-
        {moment(code.expiration_date).format("ll")}
      </p>
    </div>
  );
};

export const ItemsHeaderModel = ({ className }) => {
  return (
    <dd className={className}>
      <p className="col twenty">Nombre</p>
      <p className="col twenty">Tipo</p>
      <p className="col twenty">Valor</p>
      <p className="col twenty">Porcentaje</p>
      <p className="col twenty">Vigencia</p>
    </dd>
  );
};

function formatItem(codes = [], loading, handleSelection) {
  if (loading) return <Loader />;
  if (codes.length < 1) return <NoItemsFound />;
  const visualCodes = codes.map((code) => (
    <Item code={code} handleSelection={handleSelection} key={code._id} />
  ));

  return [<ItemsHeader key="header" />, ...visualCodes];
}

export const DiscountCodesListModel = ({ className }) => {
  const {
    historyState: state,
    handlePageDown,
    handlePageUp,
    handleCodeSelection,
  } = useContext(DiscountCodesContext);
  const { loading, codes, pagination } = state;
  const { nextPageExist, currentPage } = pagination;
  return (
    <div className={className}>
      <EntitiesList
        height={"calc(66vh - 2.1rem)"}
        viewState={loading}
        entities={codes}
        formattingFunction={formatItem}
        selectionHandler={handleCodeSelection}
      >
        <Pagination
          padding="0.65rem 0 0.65rem 0"
          nextPageExist={nextPageExist}
          currentPage={currentPage}
          handlePageUp={handlePageUp}
          handlePageDown={handlePageDown}
        ></Pagination>
      </EntitiesList>
    </div>
  );
};
