import React from "react";
import { Home } from "../home-view";
import { Validation } from "../validation";
import { AlertMessage } from "../alert-message";
import { Loader } from "components/loader";

const TicketModel = ({
  ticket,
  message,
  toShow,
  handleContinue,
  handleSubmit,
  errorType,
  handleBack,
  viewType,
  className,
}) => {
  const component = {
    home: <Home viewType={viewType} />,
    loader: <Loader margin={viewType === "desktop" ? "0 17rem" : "0"} />,
    ticket: (
      <Validation
        ticket={ticket}
        handleSubmit={handleSubmit}
        viewType={viewType}
      />
    ),
    success: (
      <AlertMessage
        type="success"
        message={message}
        handleContinue={handleContinue}
        viewType={viewType}
      />
    ),
    error: (
      <AlertMessage
        type="error"
        errorType={errorType}
        message={message}
        handleContinue={handleContinue}
        viewType={viewType}
        handleBack={handleBack}
      />
    ),
  };
  return (
    <div className={className}>
      {component[toShow] || <Home viewType={viewType} />}
    </div>
  );
};

export { TicketModel };
