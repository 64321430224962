import React from "react";

const GeneralInputModel = ({ handleChange, txt, title, className }) => {
  return (
    <div className={className}>
      <div>
        <p>{title}</p>
        <input
          type="text"
          className="input-tag"
          value={txt}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};

export default GeneralInputModel;
