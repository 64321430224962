import React, { PureComponent } from "react";
import PopupMessage from "components/popup-message";
import { throwPopupMessage, closePopup } from "components/popup-message";
import { connect } from "react-redux";
import moment from "moment";
import { errors } from "assets/strings/texts";
import { clearReduxStore } from "redux/actions/general";
import { CommentsListItem, CommentsHeader, CommentForm } from "../comments-box";
import {
  getUserComments,
  postUserComment,
  //patchUserComment,
  deleteUserComment,
} from "services/api";
import { getSession as getSessionFromRedux } from "redux/selectors";
import { Panel } from "components/panel";
import { EntitiesList } from "components/entities-list";
import { Button } from "components/buttons";
import { popup_transition_time } from "assets/strings/constants";
import { Icon } from "semantic-ui-react";
import { grey_4 } from "assets/strings/colors";

const CommentsListItemModel = ({
  viewState,
  comment,
  id,
  handleDeleteComment,
  className,
}) => {
  return (
    <div className={className}>
      <p className="overflow-visible">
        <span>
          <b>Operador:</b> {comment.operator}
        </span>
        {viewState === "show_all_comments" && (
          <Icon
            id={id}
            style={{
              display: "flex",
              alignItem: "right",
              float: "right",
              color: grey_4,
              cursor: "pointer",
            }}
            name="delete"
            onClick={handleDeleteComment}
          />
        )}
      </p>
      <p className="overflow-visible">
        {comment.comment}
        <br />
        <b className="date">
          {moment(comment.updated_at).format("DD/MM/YYYY")}
        </b>
      </p>
    </div>
  );
};
const CommentFormModel = ({
  viewState,
  submitComment,
  handleChangeComment,
  className,
}) => {
  if (viewState === "wait_post_comment") {
    return (
      <div
        className="ui active loader"
        style={{
          marginTop: "3rem",
          position: "relative",
        }}
      ></div>
    );
  } else {
    return (
      <form className={className} onSubmit={submitComment}>
        <p className="title">Nueva Observación</p>
        <textarea
          type="text"
          id="Observacion"
          rows={"3"}
          cols={"38"}
          placeholder="Observación"
          onChange={handleChangeComment}
          autoComplete="off"
        />
        <Button color="blue">Listo</Button>
      </form>
    );
  }
};
const CommentsHeaderModel = ({
  showCommentsButton,
  handleShowAllComments,
  handleAddComment,
  className,
}) => {
  return (
    <div className={className}>
      <div>
        <p>Observaciones</p>
        <Button color="blue" onClick={handleAddComment}>
          Añadir
        </Button>
        {showCommentsButton && (
          <Button color="green" onClick={handleShowAllComments}>
            Ver todos
          </Button>
        )}
      </div>
    </div>
  );
};

function formatPopupCommentsList(comments, viewState, handleCommentSelection) {
  let formatCommentsList = [];
  if (!comments.length) {
    formatCommentsList.push(
      <p style={{ textAlign: "center" }} key={"no comments"}>
        No hay observaciones para mostrar
      </p>
    );
  } else if (viewState === "wait_delete_comment") {
    return (
      <div
        className="ui active loader"
        style={{
          marginTop: "3rem",
          position: "relative",
        }}
      ></div>
    );
  } else {
    comments.forEach((comment, index) => {
      formatCommentsList.push(
        <CommentsListItem
          key={comment._id}
          id={comment._id}
          viewState={viewState}
          comment={comment}
          handleDeleteComment={handleCommentSelection}
          index={index}
        />
      );
    });
  }
  return formatCommentsList;
}
function formatCommentsList(
  comments,
  viewState,
  handleAddComment,
  handleShowAllComments
) {
  let formatCommentsList = [];
  if (viewState === "waiting_comments") {
    return (
      <div
        className="ui active loader"
        style={{
          marginTop: "3rem",
          position: "relative",
        }}
      ></div>
    );
  } else {
    if (!comments || !comments.length) {
      formatCommentsList.push(
        <CommentsHeader
          showCommentsButton={false}
          handleShowAllComments={handleShowAllComments}
          handleAddComment={handleAddComment}
          key={"header"}
        />
      );
      formatCommentsList.push(
        <p
          style={{
            textAlign: "center",
            paddingBottom: "1rem",
            paddingTop: "1rem",
            fontSize: "1.2rem",
          }}
          key={"no comments"}
        >
          No hay observaciones para mostrar
        </p>
      );
    } else {
      formatCommentsList.push(
        <CommentsHeader
          showCommentsButton={true}
          handleShowAllComments={handleShowAllComments}
          handleAddComment={handleAddComment}
          key={"header"}
        />
      );
      formatCommentsList.push(
        <CommentsListItem
          key={comments[0]._id}
          viewState={viewState}
          comment={comments[0]}
        />
      );
    }
  }

  return formatCommentsList;
}

class CommentsBoxModel extends PureComponent {
  constructor(props) {
    super(props);
    let operator = props.getSession.username;
    this.state = {
      user: props.selectedUser,
      operator,
      viewState: "show_last_comment",
      loading: false,
      comments: [],
      comment: null,
      popupMessages: null,
      popupMessagesType: "list",
      showPopup: false,
    };
    this.initialState = { ...this.state };
    // Bind PopupMessage functions
    this.formDataCahe = {};
    this.throwPopupMessage = throwPopupMessage.bind(this);
    this.closePopup = closePopup.bind(this);
  }

  componentDidMount() {
    this.userComments(this.state.user._id);
  }

  //1) request to list and save all comments
  userComments = async (_id) => {
    this.setState({
      viewState: "waiting_comments",
    });
    const response = await getUserComments(_id);
    try {
      const res = await response.json();
      if (res.error) {
        throw res.error;
      }
      let viewState = "show_last_comment";
      if (this.state.viewState === "show_all_comments")
        viewState = "show_all_comments";
      this.setState({
        viewState,
        comments: res.comments,
      });
    } catch (error) {
      if (
        error.id === "NO_TOKEN_PROVIDED" ||
        error.id === "AUTHENTICATE_FAILED" ||
        error.id === "DUPLICATE_SESSION"
      ) {
        //this.props.clearReduxStore();
        this.throwPopupMessage("alert", errors.AUTHENTICATION);
        return;
      }
      this.setState({
        ...this.initialState,
      });
      this.throwPopupMessage("error", error.description);
    }
  };

  //2)TODO: press to add comment
  handleAddComment = (e) => {
    e.preventDefault();
    this.setState({
      viewState: "add_comment",
    });
    this.throwPopupMessage("", "");
  };

  //2.2 onChange comment textbox
  handleChangeComment = (e) => {
    e.preventDefault();
    this.setState({ comment: e.target.value });
  };

  //2.3 submit comment
  submitComment = async (e) => {
    let { user, comment, operator } = this.state;
    this.setState({ viewState: "wait_post_comment" });
    const response = await postUserComment(user._id, comment, operator);
    try {
      const res = await response.json();
      if (res.error) {
        throw res.error;
      }
      this.userComments(user._id);
      this.closePopup();
    } catch (error) {
      if (
        error.id === "NO_TOKEN_PROVIDED" ||
        error.id === "AUTHENTICATE_FAILED" ||
        error.id === "DUPLICATE_SESSION"
      ) {
        this.throwPopupMessage("alert", errors.AUTHENTICATION);
        //this.props.clearReduxStore();
        return;
      }
      this.setState({ ...this.initialState });
      this.throwPopupMessage(
        "error",
        "Hubo un error subiendo la observación, intente más tarde"
      );
    }
  };

  //3) TODO: press to show all comments
  handleShowAllComments = (e) => {
    e.preventDefault();
    this.setState({
      viewState: "show_all_comments",
    });
    this.throwPopupMessage("", "");
  };
  closeFormPopup = () => {
    this.closePopup();
    setTimeout(() => {
      this.setState((prevState) => ({
        ...prevState,
      }));
    }, popup_transition_time);
  };

  //3.1 TODO: press to delete a comment
  handleDeleteComment = async (e) => {
    e.preventDefault();
    let _id = e.currentTarget.id;
    let customer_associated = this.state.user._id;
    this.setState({ viewState: "wait_delete_comment" });
    const response = await deleteUserComment(_id, customer_associated);
    try {
      const res = await response.json();
      if (res.error) {
        throw res.error;
      }
      this.setState({ viewState: "show_all_comments", comments: res.comments });
    } catch (error) {
      if (
        error.id === "NO_TOKEN_PROVIDED" ||
        error.id === "AUTHENTICATE_FAILED" ||
        error.id === "DUPLICATE_SESSION"
      ) {
        this.throwPopupMessage("alert", errors.AUTHENTICATION);
        //this.props.clearReduxStore();
        return;
      }
      this.setState({ ...this.initialState });
      this.throwPopupMessage(
        "error",
        "No se pudo eliminar el comentario, intente más tarde"
      );
    }
  };

  render() {
    return (
      <Panel className={this.props.className}>
        <PopupMessage
          messageType={this.state.popupMessagesType}
          showPopup={this.state.showPopup}
          messages={this.state.popupMessages}
          closePopup={this.closeFormPopup}
          style={{
            width: "cal(40% -1rem)",
          }}
        >
          {(this.state.viewState === "add_comment" ||
            this.state.viewState === "wait_post_comment") && (
            <CommentForm
              viewState={this.state.viewState}
              submitComment={this.submitComment}
              handleChangeComment={this.handleChangeComment}
            />
          )}
          {(this.state.viewState === "show_all_comments" ||
            this.state.viewState === "wait_delete_comment") && [
            <h3 key="history" style={{ width: "cal(40% -1rem)" }}>
              Historial de Observaciones
            </h3>,
            <EntitiesList
              viewState={this.state.viewState}
              entities={this.state.comments}
              formattingFunction={formatPopupCommentsList}
              selectionHandler={this.handleDeleteComment}
              overflow={"hidden"}
              key={"list"}
            />,
          ]}
        </PopupMessage>

        <EntitiesList
          viewState={this.state.viewState}
          entities={this.state.comments}
          formattingFunction={formatCommentsList}
          additionalArgs={[this.handleShowAllComments]}
          selectionHandler={this.handleAddComment}
        />
      </Panel>
    );
  }
}

const mapStateToProps = (state) => {
  let getSession = getSessionFromRedux(state);
  return { getSession };
};
const mapDispatchToProps = {
  clearReduxStore,
};

CommentsBoxModel = connect(
  mapStateToProps,
  mapDispatchToProps
)(CommentsBoxModel);

export { CommentsListItemModel };
export { CommentsHeaderModel };
export { CommentsBoxModel };
export { CommentFormModel };
export { formatCommentsList };
