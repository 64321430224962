import React, { useContext } from "react";
import { TransfersContext } from "../../context";
import { EntitiesList, Pagination } from "components/entities-list";
import TransfersListItem, {
  TransfersListItemPhone,
} from "../transfers-list-item";
import TransfersListHeader from "../transfers-list-header";
import { Loader } from "components/loader";

function formatTransfersList(transfers, viewState, handleTransferSelection) {
  if (transfers.length < 1) {
    return (
      <div
        style={{
          marginTop: "0.5rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        No se encontraron transferencias
      </div>
    );
  }
  const componentToRender = {
    phone: TransfersListItemPhone,
    desktop: TransfersListItem,
  };
  const Items = componentToRender[viewState] || TransfersListItem;
  return transfers.map((transfer) => {
    return (
      <Items
        transfer={transfer}
        key={`${transfer._id}`}
        handleSelection={handleTransferSelection}
        id={`${transfer._id}`}
      />
    );
  });
}

const TransfersListModel = () => {
  const { state, changeState, viewType } = useContext(TransfersContext);

  const onTransferSelection = (event) => {
    const transferID = event.currentTarget.id;
    const selectedTransfer = state.transfers.find((t) => t._id === transferID);
    changeState({
      selectedTransfer,
      showDetail: false,
    });
    setTimeout(() => {
      //to manage detail transition
      changeState({ showDetail: true });
    }, 150);
  };

  const handlePageUp = (event) => {
    event.preventDefault();
    changeState({
      formData: { ...state.formData, page: state.formData.page + 1 },
      submitForm: true,
    });
  };
  const handlePageDown = (event) => {
    event.preventDefault();
    changeState({
      formData: { ...state.formData, page: state.formData.page - 1 },
      submitForm: true,
    });
  };

  if (state.loading) return <Loader />;
  return (
    <>
      <Pagination
        padding="0.65rem 0 0.65rem 0"
        nextPageExist={state.nextPageExists}
        currentPage={state.formData.page}
        handlePageUp={handlePageUp}
        handlePageDown={handlePageDown}
      />
      <TransfersListHeader transfers={state.transfers} viewType={viewType} />
      <EntitiesList
        height={"calc(67vh - 10rem)"}
        viewState={viewType}
        entities={state.transfers}
        formattingFunction={formatTransfersList}
        selectionHandler={onTransferSelection}
      ></EntitiesList>
    </>
  );
};

export default TransfersListModel;
