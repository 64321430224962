import { NewExtensionModel } from "./model";
import styled from "styled-components";
import { bluePD, grey_6 } from "assets/strings/colors";

export const NewExtension = styled(NewExtensionModel)`
#root && {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  > .extension {
    width: calc(100% - 1rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > .drop {
      margin: 1rem;
      width: 90%;
    }
    > .header {
      margin: 0;
      padding: 1.5rem;
      width: 100%;
      text-align: center;
      font-size: 1.3rem;
      text-transform: uppercase;
      color: ${grey_6};
      font-weight: bold;
      > .edit {
        width: 100%;
      }
    }
    > .title {
      align-self: flex-start;
      border-bottom: 1px solid ${grey_6};
      color: ${grey_6};
      font-weight: bold;
    }
    > input {
      margin: 1rem;
      border: 1px solid ${bluePD};
      border-radius: 2rem;
      text-align: center;
      font-size: 1rem;
      width: 90%;
      height: 30px;
      :focus {
        outline: none;
        border: 1px solid ${bluePD};
      }
    }
    > .selectors {
      margin: 0;
      width: calc(100% - 1rem);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    > .idinfo {
      padding: 1rem 0 2rem 0;
      display: flex;
      align-items: center;
      justify-content: center
      cursor: pointer;
      width: calc(100% - 1rem);
      > .idtext {
        background: red;
        font-size: 1rem;
        color: ${grey_6};
      }
    }
  }
}
`;
