import React from "react";
import moment from "moment";
import "moment/locale/es-us";
import okCircleIcon from "assets/img/ok-circle-icon-64px.png";
import excamCircleIcon from "assets/img/exclam-circle-icon-64px.png";
import defaultUser from "assets/img/default-user-grey.png";
import bellCircleIcon from "assets/img/bell-circle-icon-64px.png";
import { formatPrice } from "services/utils";
import {
    WithdrawListItem,
    WithdrawPhoneListItem,
    WithdrawListHeader,
    NoWithdrawFound,
} from "../withdraw-list-item";

moment.locale("es-us");

const WithdrawListItemModel = ({
    handleWithdrawSelection,
    withdrawDate,
    customer,
    totalAmount,
    currency,
    reference,
    status,
    id,
    className,
}) => {
    return (
        <dd id={id} className={className} onClick={handleWithdrawSelection}>
            <div className="col reference-section">
                <p className="reference">{reference || "N/A"}</p>
            </div>
            <div className="col period-section">
                <p className="date">{withdrawDate}</p>
            </div>
            <p className="col amount">
                {formatPrice(totalAmount) + " " + currency}
            </p>
            <div className="col status">
                {status === "approved" && <img src={okCircleIcon} alt="paid" />}
                {status === "approved" && <p className="paid">aprobado</p>}

                {status === "pending" && (
                    <img src={bellCircleIcon} alt="pending" />
                )}
                {status === "pending" && <p className="pending">pendiente</p>}

                {status === "rejected" && (
                    <img src={excamCircleIcon} alt="reject" />
                )}
                {status === "rejected" && <p className="reject">rechazado</p>}
            </div>
            <div className="col commerce">
                <img src={defaultUser} alt="paid" />
                {<p>{`${customer ? `@${customer}` : "N/A"}`}</p>}
            </div>
        </dd>
    );
};

const WithdrawPhoneListItemModel = ({
    handleWithdrawSelection,
    withdrawDate,
    totalAmount,
    customer,
    currency,
    status,
    reference,
    index,
    id,
    className,
}) => {
    return (
        <dd id={id} className={className} onClick={handleWithdrawSelection}>
            {status === "approved" && (
                <img src={okCircleIcon} alt="approved" className="status" />
            )}
            {status === "pending" && (
                <img src={bellCircleIcon} alt="pending" className="status" />
            )}
            {status === "rejected" && (
                <img src={excamCircleIcon} alt="rejected" className="status" />
            )}

            <p className="title">total</p>
            <p>{formatPrice(totalAmount) + " " + currency}</p>
            <p className="title">Fecha</p>
            <p>{withdrawDate}</p>
            <p className="title">Comercio</p>
            <p>{`${customer ? `@${customer}` : "N/A"}`}</p>
            <p className="title">Referencia</p>
            <p>{reference || "N/A"}</p>
        </dd>
    );
};

const WithdrawListHeaderModel = ({ className }) => {
    return (
        <dd className={className}>
            <p className="col twenty">Referencia</p>
            <p className="col twenty">Fecha</p>
            <p className="col twenty">Monto</p>
            <p className="col twenty">Estado</p>
            <p className="col twenty">Comercio</p>
        </dd>
    );
};

const NoWithdrawFoundModel = ({ className }) => {
    return <p className={className}>No se han encontrado retiros</p>;
};

function formatWithdrawList(withdraw, loading, handleWithdrawSelection) {
    // Declaramos el array que devolverá la función
    let withdrawals = withdraw[0];
    //let approved = withdraw[1].approved;
    //let pending = withdraw[1].pending;

    let withdrawList = [];
    let formatString = "DD [de] MMMM YYYY";
    if (loading) {
        return (
            <div
                className="ui active loader"
                style={{
                    marginTop: "3rem",
                    position: "relative",
                }}
            ></div>
        );
    }
    if (!withdrawals.length) {
        return <NoWithdrawFound />;
    } else {
        withdrawList.push(<WithdrawListHeader key="header" />);

        withdrawals.forEach((withdraw, index) => {
            let withdrawDate = moment(withdraw.created_at).format(formatString);
            const customer =
                withdraw &&
                withdraw.bill_ref &&
                withdraw.bill_ref.commerce &&
                withdraw.bill_ref.commerce.short_name
                    ? withdraw.bill_ref.commerce.short_name
                    : null;
            withdrawList.push(
                <WithdrawListItem
                    handleWithdrawSelection={handleWithdrawSelection}
                    withdrawDate={withdrawDate}
                    totalAmount={withdraw.pd_amount}
                    currency={"Bs.S"}
                    reference={withdraw.reference}
                    customer={customer}
                    status={withdraw.status}
                    index={index}
                    key={withdraw._id}
                    id={withdraw._id}
                />
            );
        });
        return withdrawList;
    }
}

function formatWithdrawPhoneList(withdraw, loading, handleWithdrawSelection) {
    let withdrawals = withdraw[0];
    // Declaramos el array que devolverá la función
    let withdrawList = [];
    let formatString = "DD / MM / YYYY";
    if (loading) {
        return (
            <div
                className="ui active loader"
                style={{
                    marginTop: "3rem",
                    position: "relative",
                }}
            ></div>
        );
    }
    if (!withdrawals.length) {
        return <NoWithdrawFound />;
    } else {
        withdrawals.forEach((withdraw, index) => {
            let withdrawDate = moment(withdraw.created_at).format(formatString);
            withdrawList.push(
                <WithdrawPhoneListItem
                    handleWithdrawSelection={handleWithdrawSelection}
                    withdrawDate={withdrawDate}
                    totalAmount={withdraw.pd_amount}
                    customer={withdraw.transaction_owner}
                    reference={withdraw.reference}
                    currency={"Bs.S"}
                    status={withdraw.status}
                    id={withdraw._id}
                    key={withdraw._id}
                />
            );
        });
        return withdrawList;
    }
}

export {
    WithdrawListItemModel,
    WithdrawPhoneListItemModel,
    WithdrawListHeaderModel,
    NoWithdrawFoundModel,
    formatWithdrawList,
    formatWithdrawPhoneList,
};
