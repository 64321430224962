import styled from "styled-components";
import {
  NotificationsPanelModel,
  UserListHeaderModel,
  UserListItemModel,
} from "./model";
import {
  grey_1,
  grey_4,
  grey_6,
  bluePD,
  green,
  green_2,
} from "assets/strings/colors";

const NotificationsPanel = styled(NotificationsPanelModel)`
  #root & {
    padding 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh-4em);
    width: 100%;
    > .panel-form {
      width: 80%;
      > .form-segment {
        display: flex;
        flex-direction: column;
        align-items: center;
        > .header {
          text-align: center;
          font-size: clamp(0.7rem, 1vw, 1.5rem);
        }
        > .types {
          display: flex;
          justify-content: center;
          padding: 1rem 0;
          > div {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0.5rem;
            font-size: clamp(0.5rem, 1vw, 1.5rem);
            > img {
              width: 20%;
            }

            :hover {
              cursor: pointer;
              background: #f0f0ff;
            }
          }
          > .selected {
            background: #f0f0ff;
            border-bottom: 1px solid ${bluePD};
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 1rem;
            > img {
              width: 20%;
            }
          }
        }
        > .screens {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          font-size: clamp(0.7rem, 1vw, 1.5rem);
          > p {
            padding-top: 1rem;
            padding-left: 1rem;
            width: 50%;
          }
        }
        > .title-inputs {
          padding-top: 1rem;
          display: flex;
          justify-content: center;
          font-size: clamp(0.7rem, 1vw, 1.5rem);
          > p {
            text-align: center;
            width: 50%;
          }
        }
        > .inputs {
          width: 100%;
          display: flex-column;
          justify-content: center;
          margin-bottom: 1rem;
        }
        > .button-style {
          width:80%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 1rem;
          > .date-pickers {
            margin: 0;
            width: 37%;
            display: flex;
            align-items: center;
            justify-content: center;
            > p {
              margin: 0;
              font-weight: bold;
            }
            > div {
              width: 100%;
              display: flex;
              justify-content: center;
              padding-left: 0.7rem;
              > .react-datepicker__input-container {
                width: 100%;
                > input {
                  width: 100%;
                  height: 2.5rem;
                  background: #f0f0ff;
                  border: 1px solid #d0d0dd;
                  color: ${grey_6};
                  font-size: clamp(0.7rem, 1vw, 1.5rem);
                  text-align: center;
                  outline: none;
                  box-shadow: none;
                  transition: box-shadow 250ms linear;
                  :focus {
                    outline: none !important;
                    border: 1px solid #a0a0ff;
                  }
                }
              }
              > .react-datepicker-wrapper {
                width: 100%;
                > .react-datepicker__input-container {
                  width: 100%;
                  > input {
                    width: calc(100% - 16px);
                    border: none;
                    border-radius: 1rem;
                    color: ${grey_6};
                    font-size: 1.2rem;
                    text-align: center;
                    outline: none;
                    box-shadow: none;
                    transition: box-shadow 250ms linear;
                    :focus {
                      box-shadow: 0 1px darkgrey;
                    }
                  }
                  > .react-datepicker__close-icon::after {
                    right: 15px;
                  }
                }
              }
            }
          }
          > button {
            color: white;
            font-weight: bold;
            width: 36%;
            background: ${green};
            outline: none !important;
            height: 2.5rem;
            box-shadow: 0;
            border-style: solid;
            border-color: transparent;
            border-radius: 0.5rem;
            :hover {
              cursor: pointer;
              background: ${green_2};
            }
          }
        }
        > .titles {
          margin: 0;
          margin-top: 0.7rem;
          display: flex;
          > p {
            margin: 0.5rem 3px 0.5rem 3px;
            color: ${grey_6};
            display: block;
            width: 50%;
            font-size: 1.2rem;
            border-bottom: solid 1px ${grey_4};
          }
        }
        > .dropdown-items {
          margin: 0;
          margin-top: 0.7rem;
          align-items: center;
        }
        > .date-pickers {
          margin: 0;
          margin-top: 0.7rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          > p {
            margin: 0;
            font-weight: bold;
          }
          > div {
            width: 46%;
            border-bottom: solid 1px ${grey_4};
            > .react-datepicker__input-container {
              width: 100%;
              > input {
                width: calc(100% - 16px);
                border: none;
                border-radius: 1rem;
                color: ${grey_6};
                font-size: 1rem;
                margin: 0.5rem 5px 0.5rem 5px;
                text-align: center;
                outline: none;
                box-shadow: none;
                transition: box-shadow 250ms linear;
                :focus {
                  box-shadow: 0 1px darkgrey;
                }
              }
              > .react-datepicker__close-icon::after {
                right: 0;
              }
            }
            > .react-datepicker-wrapper {
              width: 100%;
              > .react-datepicker__input-container {
                width: 100%;
                > input {
                  width: calc(100% - 16px);
                  border: none;
                  border-radius: 1rem;
                  color: ${grey_6};
                  font-size: 1rem;
                  margin: 0.5rem 5px 0.5rem 5px;
                  text-align: center;
                  outline: none;
                  box-shadow: none;
                  transition: box-shadow 250ms linear;
                  :focus {
                    box-shadow: 0 1px darkgrey;
                  }
                }
                > .react-datepicker__close-icon::after {
                  right: 15px;
                }
              }
            }
          }
          > div:last-child {
            > .react-datepicker-popper {
              > .react-datepicker {
                > .react-datepicker__triangle {
                  right: 50px;
                  left: auto;
                }
              }
            }
          }
        }
      }
    }
    > .panel-list {
      width: 80%;
    }
    @media (max-width: 610px) {
      width: 100%;
      > .panel-form{
        >.form-segment{
          >.screens{
            display: flex;
            flex-direction: column;
            >p{
              padding: 0;
              text-align: center;
            }
          }
        }

      }
    }
  }
`;

const UserListHeader = styled(UserListHeaderModel)`
  margin: 0;
  border-bottom: 2px solid ${bluePD};
  > .col {
    color: ${grey_4};
    margin: 1.2rem 0.5rem 0 0.5rem;
    padding-bottom: 0.3rem;
    display: inline-block;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
  }
  > .forty {
    width: calc(40% - 1rem);
  }
  > .twenty {
    width: calc(25% - 1rem);
  }
  > .thirty {
    width: calc(33% - 1rem);
  }
  > .all {
    width: 100%;
  }
`;

const UserListItem = styled(UserListItemModel)`
  margin: 0;
  border-bottom: 1px solid ${grey_4};
  cursor: pointer;
  padding-bottom: 0.3rem;
  padding-top: 1rem;
  display: flex;
  text-align: center;
  :hover{
    background: ${grey_1};
  }
  > .info {
    text-align: center;
    color: ${bluePD};
    width: 100%;
    font-size: 1rem;
  }
  > .user {
    color: ${bluePD}
    width: 25% ;
    text-align: center;
  }
  > .access {
    width: 25%;
    font-size: 0.9rem;
    text-align: center;
  }
  > .building {
    width: 25%;
    font-size: 0.9rem;
    text-align: center;
    
  }
  > .time {
    width: 25%;
    padding: 0 .5rem 0 .5rem;
    text-align: center;
    font-size: 0.8rem;
  }
  > .column {
    width: 100%;
    font-size: 0.8rem;
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
    > .info {
      text-align: center;
      color: ${bluePD};
    }
    > .user {
      > b{
        color: black;
      }
      color: ${bluePD};
    }
    > .access {
      > b{
        color: black;
      }
    }
    > .building {
      > b{
        color: black;
      }
    }
    > .date {
      > b{
        color: black;
      }
    }
    > .hour {
      > b{
        color: black;
      }
    }
  }
`;
export { NotificationsPanel, UserListHeader, UserListItem };
