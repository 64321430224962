import React from "react";
import styled from "styled-components";
import { Grid } from "semantic-ui-react";
import PopupMessage from "components/popup-message";
import { Loader } from "components/loader";

import { Search } from "./search";
import { Ticket } from "./ticket";
import { DigitizeFooter } from "./digitize-footer";

const MposEventReferenceViewModel = ({
  loading,
  handleSearchInputChange,
  searchParam,
  toShow,
  handleContinue,
  handleSearch,
  errorType,
  handleBack,
  handleSubmit,
  ticket,
  message,
  popupMessages,
  popupMessagesType,
  showPopup,
  closePopup,
  viewType,
  className,
}) => {
  return (
    <Grid padded centered className={className}>
      <PopupMessage
        messageType={popupMessagesType}
        showPopup={showPopup}
        messages={popupMessages}
        closePopup={closePopup}
      />
      <Search
        handleSearchInputChange={handleSearchInputChange}
        searchParam={searchParam}
        handleSearch={handleSearch}
        viewType={viewType}
      />
      {loading && <Loader />}
      <Ticket
        toShow={toShow}
        ticket={ticket}
        handleSubmit={handleSubmit}
        handleContinue={handleContinue}
        handleBack={handleBack}
        message={message}
        viewType={viewType}
        errorType={errorType}
      />
      <DigitizeFooter viewType={viewType} />
    </Grid>
  );
};

const MposEventReferenceView = styled(MposEventReferenceViewModel)`
  #root && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export { MposEventReferenceView };
