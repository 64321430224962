import React from "react";
import { formatPrice } from "services/utils";

const TotalAmountsModel = ({
  firstTitle,
  secondTitle,
  firstAmount,
  secondAmount,
  firstUnit,
  secondUnit,
  className,
}) => {
  return (
    <div className={className}>
      <div className="item">
        <p className="amount">
          {firstAmount} <span>{firstUnit}</span>
        </p>
        <p className="title">{firstTitle}</p>
      </div>
      <div className="item">
        <p className="amount">
          {secondUnit !== "" ? formatPrice(secondAmount) : secondAmount}{" "}
          <span>{secondUnit}</span>
        </p>
        <p className="title">{secondTitle}</p>
      </div>
    </div>
  );
};

export { TotalAmountsModel };
