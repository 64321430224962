import React from "react";
import { recharge_validate as viewTexts } from "assets/strings/texts";

const BankDetailsModel = ({ bankDetails, causeRejection, className }) => {
  const hasCause = causeRejection.hasOwnProperty("cause");
  let message = "";
  if (hasCause) {
    causeRejection.cause.map((msg) => {
      message = `${message}${msg} `;
      return null;
    });
  }
  return (
    <div className={className}>
      <p className="header">{viewTexts.bankDetails}</p>
      <div className="content">
        {bankDetails.bank_name && <p className="title">{viewTexts.bankName}</p>}
        {bankDetails.bank_name && (
          <p className="item">{bankDetails.bank_name}</p>
        )}
        {bankDetails.account_name && (
          <p className="title">{viewTexts.accountName}</p>
        )}
        {bankDetails.account_name && (
          <p className="item">{bankDetails.account_name}</p>
        )}
        {bankDetails.card_number && (
          <p className="title">{viewTexts.cardNumber}</p>
        )}
        {bankDetails.card_number && (
          <p className="item">{bankDetails.card_number}</p>
        )}
        {bankDetails.card_type && (
          <p className="title">
            {viewTexts.cardType === "CREDIT" ? "Crédito" : "Débito"}
          </p>
        )}
        {bankDetails.card_type && (
          <p className="item">{bankDetails.card_type}</p>
        )}
        {bankDetails.currency && <p className="title">{viewTexts.currency}</p>}
        {bankDetails.currency && <p className="item">{bankDetails.currency}</p>}
        {bankDetails.doc_id && <p className="title">{viewTexts.docId}</p>}
        {bankDetails.doc_id && <p className="item">{bankDetails.doc_id}</p>}
        {hasCause && <p className="title">{viewTexts.causeRejection}</p>}
        {hasCause && <p className="item">{message}</p>}
      </div>
    </div>
  );
};

export { BankDetailsModel };
