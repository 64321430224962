import React, { useEffect } from "react";
import "./assets/css/main.css";
import MPos from "./views/mpos";
import Splash from "./views/splash";
import Signin from "./views/signin";
import Signup from "./views/signup";
import Policies from "./views/policies";
import "semantic-ui-css/semantic.min.css";
//import OwnerInfo from "./views/owner-info";
import NotFound from "./views/404-not-found";
import ResetPassword from "views/reset-password";
import UserChangePassword from "views/change-password";
//import CommerceInfo from "./views/commerce-info";
import { PopupMessageRedux } from "./components/popup-message";
import EmailVerification from "./views/email-verification";
import UnvalidatedOwnerScreen from "views/unvalidated-owner-screen";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { updateResponsiveData } from "redux/actions/responsive-data";
import { PermissionsValidator } from "./components/permissions-validator";

const AppWithoutRedux = (props) => {
  useEffect(() => {
    window.addEventListener("resize", props.updateResponsiveData);
    return () =>
      window.removeEventListener("resize", props.updateResponsiveData);
  }, []);

  return (
    <Router>
      <React.Fragment>
        <PermissionsValidator />
        <Switch>
          <Route exact path="/" component={Splash} />
          <Route path="/signin" component={Signin} />
          <Route path="/signup" component={Signup} />
          {/* <Route path="/owner-info" component={OwnerInfo} /> */}
          {/* <Route path="/commerce-info" component={CommerceInfo} /> */}
          <Route path="/email-verification" component={EmailVerification} />
          <Route path="/not-verified-user" component={UnvalidatedOwnerScreen} />
          <Route path="/policies" component={Policies} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/change-password" component={UserChangePassword} />
          <Route path="/mpos" component={MPos} />
          <Route component={NotFound} />
        </Switch>
        <PopupMessageRedux />
      </React.Fragment>
    </Router>
  );
};

const mapStateToProps = null;
const mapDispatchToProps = {
  updateResponsiveData,
};

const App = connect(mapStateToProps, mapDispatchToProps)(AppWithoutRedux);

export default App;
