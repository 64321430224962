import React from "react";
import { Grid } from "semantic-ui-react";
import { PermissionsValidator } from "components/permissions-validator";
import { Panel } from "components/panel";
import { RightBorderGreyColumn } from "components/styled-grid-column";
import PopupMessage from "components/popup-message";
import { EntitiesList } from "components/entities-list";
import { formatBuildingList } from "../components/building-list-item";
import { InputFee } from "../components/input-fee";
import { grey_4 } from "assets/strings/colors";
import { Button } from "components/buttons";

const MPosBuilidingPdfeeView = ({
  loading,
  location,
  fee,
  buildingList,
  buildingsSelected,
  handleFee,
  handleBuildingSelection,
  handleSelectAll,
  handleBuildingUnselection,
  handleSubmit,
  popupMessages,
  popupMessagesType,
  showPopup,
  closePopup,
}) => {
  return (
    <Grid padded>
      <PermissionsValidator route={location.pathname} />
      <PopupMessage
        messageType={popupMessagesType}
        showPopup={showPopup}
        messages={popupMessages}
        closePopup={closePopup}
      />
      <RightBorderGreyColumn phone={8} tablet={8} computer={6}>
        <p
          style={{
            fontSize: "1.5rem",
            textTransform: "uppercase",
            fontWeight: "bold",
            textAlign: "center",
            borderBottom: `2px solid ${grey_4}`,
            margin: "0",
            height: "2em",
            paddingTop: "0.5rem",
          }}
        >
          Seleccione Edificios
        </p>
        <EntitiesList
          height="calc(100vh - 13em)"
          width="100%"
          viewState={null}
          entities={buildingList}
          formattingFunction={formatBuildingList}
          selectionHandler={handleBuildingSelection}
          additionalArgs={[loading]}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Button color="blue" onClick={handleSelectAll} width="50%">
            Seleccionar Todos
          </Button>
        </div>
      </RightBorderGreyColumn>
      <Grid.Column phone={8} tablet={8} computer={10}>
        <Panel>
          <InputFee
            fee={fee}
            handleFee={handleFee}
            handleSubmit={handleSubmit}
            buildingsSelected={buildingsSelected}
          />
          <EntitiesList
            height="55vh"
            viewState={null}
            entities={buildingsSelected}
            formattingFunction={formatBuildingList}
            selectionHandler={handleBuildingUnselection}
            additionalArgs={[loading]}
          />
        </Panel>
      </Grid.Column>
    </Grid>
  );
};

export default MPosBuilidingPdfeeView;
