import React from "react";
import moment from "moment";
import "moment/locale/es-us";
import { general as texts } from "assets/strings/texts";
import { Panel } from "components/panel";
import { Button } from "components/buttons";
import isologoColor from "assets/img/logo-color-2.png";
import { formatPrice } from "services/utils";
import { Loader } from "components/loader";

moment.locale("es-us");

const getInvoiceData = (data) => {
  let code, periodStart, periodEnd, name, idNumber, address, phone;
  code = periodStart = periodEnd = name = idNumber = address = phone = "--";

  if (data.code) code = data.code;

  if (
    data.billing_period &&
    data.billing_period.start &&
    data.billing_period.end
  ) {
    periodStart = moment(data.billing_period.start).format(
      texts.shortDateFormat
    );
    periodEnd = moment(data.billing_period.end).format(texts.shortDateFormat);
  }

  if (data.commerce) {
    if (data.commerce.name) name = data.commerce.name;

    if (data.commerce.address) address = data.commerce.address;

    if (data.commerce.phone) phone = data.commerce.phone;

    if (
      data.commerce.identification &&
      data.commerce.identification.doc_type &&
      data.commerce.identification.doc_id
    ) {
      idNumber = `${data.commerce.identification.doc_type} - ${data.commerce.identification.doc_id}`;
    }
  }

  return { code, periodStart, periodEnd, name, idNumber, address, phone };
};

const InvoiceModel = ({
  data,
  permissions,
  haveParking,
  category,
  removeSelectedInvoice,
  downloadExcel,
  printPage,
  showPaymentMethods,
  loading,
  generateBill,
  className,
}) => {
  let { code, periodStart, periodEnd, name, idNumber, address, phone } =
    getInvoiceData(data);

  let items = [];
  data.items.forEach((item, index) => {
    items.push(
      <tr key={"item" + index}>
        <td>{item.name}</td>
        <td>{item.qty}</td>
        <td>
          {formatPrice(item.total_amount) + " " + data.building.rate.currency}
        </td>
      </tr>
    );
  });

  if (loading) {
    return <Loader margin="50vh 0 0 0" />;
  }
  return (
    <div className={className}>
      <div className="buttons no-print">
        {category !== "pagodirecto" && (
          <Button color="grey_1" onClick={removeSelectedInvoice}>
            Volver
          </Button>
        )}

        {haveParking && (
          <Button color="blue" onClick={showPaymentMethods}>
            Métodos de pago
          </Button>
        )}

        <Button color="green" onClick={downloadExcel}>
          Descargar informe
        </Button>
        {category === "pagodirecto" && (
          <Button color="blue" onClick={generateBill}>
            Generar Factura
          </Button>
        )}
        <Button color="grey_1" onClick={printPage}>
          Imprimir
        </Button>
      </div>

      <Panel className="header">
        <img src={isologoColor} alt="isologo-pd" className="isologo-pd" />

        <table className="header-data">
          <tbody>
            <tr>
              <th>Factura proforma #</th>
              <th>Período de facturación</th>
            </tr>
            <tr>
              <td>{code}</td>
              <td>
                del {periodStart}
                <br />
                al {periodEnd}
              </td>
            </tr>
            <tr>
              <th>RIF / CI</th>
              <th>télefono</th>
            </tr>
            <tr>
              <td>{idNumber}</td>
              <td>{phone}</td>
            </tr>
            <tr>
              <th colSpan={2}>Razón Social</th>
            </tr>
            <tr>
              <td colSpan={2}>{name}</td>
            </tr>
            <tr>
              <th colSpan={2}>Dirección</th>
            </tr>
            <tr>
              <td colSpan={2}>{address}</td>
            </tr>
          </tbody>
        </table>
      </Panel>

      <Panel className="invoice">
        <table>
          <thead>
            <tr>
              <td>Descripción</td>
              <td>Cantidad</td>
              <td>Precio</td>
            </tr>
          </thead>
          <tbody>
            {items}
            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td></td>
              <td className="title">SubTotal</td>
              <td>
                {formatPrice(data.sub_total) +
                  " " +
                  data.building.rate.currency}
              </td>
            </tr>
            <tr>
              <td></td>
              <td className="title">IVA</td>
              <td>
                {formatPrice(data.tax) + " " + data.building.rate.currency}
              </td>
            </tr>
            <tr>
              <td></td>
              <td className="title">Total Proforma</td>
              <td>
                {formatPrice(data.total_amount) +
                  " " +
                  data.building.rate.currency}
              </td>
            </tr>
          </tfoot>
        </table>
      </Panel>

      {haveParking && [
        <hr key="ruler" />,
        <Panel className="commerceFee" key="commerceFee">
          <h4>Ganancias</h4>
          <p>
            {formatPrice(data.commerce_fee) + " " + data.building.rate.currency}
          </p>
        </Panel>,
      ]}
    </div>
  );
};

const InvoicePhoneModel = ({
  data,
  haveParking,
  category,
  removeSelectedInvoice,
  downloadExcel,
  showPaymentMethods,
  loading,
  generateBill,
  className,
}) => {
  let { code, periodStart, periodEnd, name, idNumber, address, phone } =
    getInvoiceData(data);

  let items = [];
  data.items.forEach((item, index) => {
    items.push(
      <div className="invoice-item" key={"item" + index}>
        <p className="title">{item.name}</p>
        <p>
          <b>x</b>
          {item.qty}
          <span className="amount">
            {formatPrice(item.total_amount) + " " + data.building.rate.currency}
          </span>
        </p>
      </div>
    );
  });

  if (loading) {
    return <Loader margin="50vh 0 0 0" />;
  }
  return (
    <div className={className}>
      <div className="buttons">
        {category !== "pagodirecto" && (
          <Button color="grey_1" onClick={removeSelectedInvoice}>
            Volver
          </Button>
        )}
        {haveParking && (
          <Button color="blue" onClick={showPaymentMethods}>
            Métodos de pago
          </Button>
        )}
        <Button color="green" onClick={downloadExcel}>
          Descargar informe
        </Button>
        {category === "pagodirecto" && (
          <Button color="green" onClick={generateBill}>
            Generar Factura
          </Button>
        )}
      </div>

      <Panel className="header">
        <img src={isologoColor} alt="isologo-pd" className="isologo-pd" />
        <table className="header-data">
          <tbody>
            <tr>
              <th>Factura proforma #</th>
              <th>Período de facturación</th>
            </tr>
            <tr>
              <td>{code}</td>
              <td>
                del {periodStart}
                <br />
                al {periodEnd}
              </td>
            </tr>
            <tr>
              <th>RIF / CI</th>
              <th>télefono</th>
            </tr>
            <tr>
              <td>{idNumber}</td>
              <td>{phone}</td>
            </tr>
            <tr>
              <th colSpan={2}>Razón Social</th>
            </tr>
            <tr>
              <td colSpan={2}>{name}</td>
            </tr>
            <tr>
              <th colSpan={2}>Dirección</th>
            </tr>
            <tr>
              <td colSpan={2}>{address}</td>
            </tr>
          </tbody>
        </table>
      </Panel>

      <Panel>{items}</Panel>

      <Panel>
        <div className="invoice-item">
          <p className="title">SubTotal</p>
          <p>
            {" "}
            <span className="amount">
              {formatPrice(data.sub_total) + " " + data.building.rate.currency}
            </span>
          </p>
        </div>
        <div className="invoice-item">
          <p className="title">IVA</p>
          <p>
            {" "}
            <span className="amount">
              {formatPrice(data.tax) + " " + data.building.rate.currency}
            </span>
          </p>
        </div>
        <div className="invoice-item">
          <p className="title">Total</p>
          <p>
            {" "}
            <span className="amount">
              {formatPrice(data.total_amount) +
                " " +
                data.building.rate.currency}
            </span>
          </p>
        </div>
      </Panel>

      {haveParking && [
        <hr key="ruler" />,
        <Panel className="commerceFee" key="commerceFee">
          <h4>Ganancias</h4>
          <p>
            {formatPrice(data.commerce_fee) + " " + data.building.rate.currency}
          </p>
        </Panel>,
      ]}
    </div>
  );
};

export { InvoiceModel, InvoicePhoneModel };
