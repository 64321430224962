import styled from "styled-components";
import { CustomFormModel } from "./model";
import { grey_6 } from "assets/strings/colors";

const CustomForm = styled(CustomFormModel)`
  #root & {
    .content {
      > p {
        margin: 0;
        text-align: center;
        font-size: 1.5rem;
        width: 100%;
      }
      margin: 1rem 0 0.5rem 0;
      margin-bottom: 4rem;
      .input-section {
        margin: 1.5rem 0 0.5rem 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        > input {
          border: none;
          width: 50%;
          border-radius: 1rem;
          color: ${grey_6};
          font-size: 1.5rem;
          margin: 0 0 0 0.25rem;
          text-align: center;
          outline: none;
          box-shadow: none;
          :focus {
            box-shadow: 0 1px darkgrey;
          }
          ::-webkit-input-placeholder {
            color: lightgray;
          }
          :-ms-input-placeholder {
            color: lightgray;
          }
          ::-ms-input-placeholder {
            color: lightgray;
          }
          ::placeholder {
            color: lightgray;
          }
        }
        > .title {
          color: #63c0e8;
          font-size: 1.5rem;
          text-align: center;
        }
        > .username {
          color: #63c0e8;
          font-size: 1.5rem;
          text-align: center;
        }
        > .link {
          font-size: 0.85rem;
          margin: 0.1rem;
          font-weight: bold;
          text-transform: uppercase;
          text-align: center;
        }
      }
    }
  }
`;

export { CustomForm };
