import React from "react";
import { UserInfo } from "./user-info";
import { Grid } from "semantic-ui-react";
import { Panel } from "components/panel";
import SearchInputNew from "components/search-input-new";
import { formatUserList } from "./user-list-item";
import { CommentsBox } from "./comments-box";
import { PanelPlaceholder } from "components/panel";
import PopupMessage from "components/popup-message";
import { CSSTransition } from "react-transition-group";
import { EntitiesList, Pagination } from "components/entities-list";
import { general as texts } from "assets/strings/texts";
import { users_admin as viewTexts } from "assets/strings/texts";
import { RightBorderGreyColumn } from "components/styled-grid-column";
import { PermissionsValidator } from "components/permissions-validator";
import { MovementsForm } from "components/movements-form";
import { formatMovementsList } from "./movements-list-item";
import { Button } from "components/buttons";
import { Dropdown } from "semantic-ui-react";
import { DateRange } from "./date-range";

const MposUsersAdminView = ({
    viewState,
    permissions,
    buildings,
    commentState,
    comments,
    promotionTickets,
    selectionUserOptions,
    searchParam,
    users,
    remoteAccess,
    remoteStateToBack,
    showResponsePanel,
    selectedUser,
    popupMessages,
    popupMessagesType,
    showPopup,
    location,
    redirect,
    searchType,
    searchFilter,
    searchCurrency,
    fromDate,
    endDate,
    movements,
    selectedMovement,
    nextPageExist,
    nextUsersPageExists,
    currentUsersPage,
    handleNextUsersPage,
    handlePrevUsersPage,
    currentPage,
    showMovementsSearch,
    handlePromotionTickets,
    handleSearchTypeButtons,
    handleSearchFilterButtons,
    handleSearchCurrencyButtons,
    handleFromDate,
    handleEndDate,
    handleSubmit,
    handlePageDown,
    handlePageUp,
    handleOptionsSelection,
    handleMovementSelection,
    handleSearchMovements,
    handleConfirmation,
    handleTicketTypeChange,
    loadingPremium,
    handleDeletePromotionTickets,
    removeSelectedMovement,
    removeMovementsView,
    removeSelectedUser,
    contactPhone,
    handleContactPhone,
    handleUpdateUser,
    optionSelected,
    amountInput,
    newName,
    newLastName,
    newEmail,
    newId,
    newIdType,
    newPhone,
    newMobilePhone,
    newTimeout,

    ticketType,
    handleSearchUser,
    handleAmount,
    handleNewName,
    handleNewEmail,
    handleNewLastName,
    handleNewPhone,
    handleNewMobilePhone,
    handleNewId,
    handleNewIdType,
    handleNewTimeout,
    handleNewConnectionTimeout,
    newConnectionTimeout,
    handleServiceFilterChange,
    serviceFilter,
    handleSearchInputChange,
    handleUserSelection,
    closePopup,
    fromDateReport,
    endDateReport,
    handleFromDateReport,
    handleEndDateReport,

    handleUsersVehicles,
    usersVehicles,
    onDetailOptionsChange,
    newLicensePlate,
    newBrand,
    newModel,
    newColor,
    newType,
    newYear,
    newVerified,
    newIsActive,
    newAlias,
    isVehicleSelected,
    handleDeleteVehicle,
    handleLicensePlate,
    handleBrand,
    handleModel,
    handleColor,
    handleType,
    handleYear,
    handleVerified,
    handleAlias,
    handleIsActive,
    onStatusOrder,
    transactionTags,
}) => {
    return (
        <Grid padded>
            <PermissionsValidator route={location.pathname} />

            <PopupMessage
                messageType={popupMessagesType}
                showPopup={showPopup}
                messages={popupMessages}
                closePopup={closePopup}
            >
                {selectionUserOptions && (
                    <div>
                        {loadingPremium ? (
                            <div
                                className="ui active loader"
                                style={{
                                    marginTop: "3rem",
                                    position: "relative",
                                }}
                            ></div>
                        ) : (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                }}
                            >
                                {optionSelected === "return" &&
                                    !loadingPremium && (
                                        <input
                                            type="text"
                                            placeholder="Monto"
                                            disabled={loadingPremium}
                                            onChange={handleAmount}
                                            value={amountInput}
                                            style={{
                                                marginTop: "1rem",
                                                border: "none",
                                                borderRadius: "1rem",
                                                height: "3rem",
                                                background: "none",
                                                textAlign: "center",
                                                fontSize: "1.2rem",
                                            }}
                                        />
                                    )}
                                {optionSelected === "ticketsReport" &&
                                    !loadingPremium && (
                                        <DateRange
                                            phoneView
                                            fromDateReport={fromDateReport}
                                            endDateReport={endDateReport}
                                            handleFromDateReport={
                                                handleFromDateReport
                                            }
                                            handleEndDateReport={
                                                handleEndDateReport
                                            }
                                        />
                                    )}
                                {optionSelected === "modifyData" &&
                                    !loadingPremium && (
                                        <>
                                            <div
                                                style={{
                                                    marginTop: "1rem",
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                    alignContent: "center",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    Nombre
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Nombre"
                                                    disabled={loadingPremium}
                                                    onChange={handleNewName}
                                                    value={newName}
                                                    style={{
                                                        marginTop: "3px",
                                                        border: "",
                                                        borderRadius: "1rem",
                                                        height: "3rem",
                                                        background: "none",
                                                        textAlign: "center",
                                                        fontSize: "1.2rem",
                                                    }}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    marginTop: "1rem",
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                    alignContent: "center",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    Apellido
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Apellido"
                                                    disabled={loadingPremium}
                                                    onChange={handleNewLastName}
                                                    value={newLastName}
                                                    style={{
                                                        marginTop: "3px",
                                                        border: "",
                                                        borderRadius: "1rem",
                                                        height: "3rem",
                                                        background: "none",
                                                        textAlign: "center",
                                                        fontSize: "1.2rem",
                                                    }}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    marginTop: "1rem",
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                    alignContent: "center",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    Correo Electrónico
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Email"
                                                    disabled={loadingPremium}
                                                    onChange={handleNewEmail}
                                                    value={newEmail}
                                                    style={{
                                                        marginTop: "3px",
                                                        border: "",
                                                        borderRadius: "1rem",
                                                        height: "3rem",
                                                        background: "none",
                                                        textAlign: "center",
                                                        fontSize: "1.2rem",
                                                    }}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    marginTop: "1rem",
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                    alignContent: "center",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    Tipo de Documento
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="V/E/J"
                                                    disabled={loadingPremium}
                                                    onChange={handleNewIdType}
                                                    value={newIdType}
                                                    maxLength="1"
                                                    style={{
                                                        marginTop: "3px",
                                                        border: "",
                                                        borderRadius: "1rem",
                                                        height: "3rem",
                                                        background: "none",
                                                        textAlign: "center",
                                                        fontSize: "1.2rem",
                                                    }}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    marginTop: "1rem",
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                    alignContent: "center",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    N° de Documento
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder={newId}
                                                    disabled={loadingPremium}
                                                    onChange={handleNewId}
                                                    value={newId}
                                                    style={{
                                                        marginTop: "3px",
                                                        border: "",
                                                        borderRadius: "1rem",
                                                        height: "3rem",
                                                        background: "none",
                                                        textAlign: "center",
                                                        fontSize: "1.2rem",
                                                    }}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    marginTop: "1rem",
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                    alignContent: "center",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    Teléfono de Contacto
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Teléfono de Contacto"
                                                    disabled={loadingPremium}
                                                    onChange={handleNewPhone}
                                                    value={newPhone}
                                                    maxLength="11"
                                                    style={{
                                                        marginTop: "3px",
                                                        border: "",
                                                        borderRadius: "1rem",
                                                        height: "3rem",
                                                        background: "none",
                                                        textAlign: "center",
                                                        fontSize: "1.2rem",
                                                    }}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    marginTop: "1rem",
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                    alignContent: "center",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    Teléfono asociado a Pago
                                                    Móvil
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Teléfono (pago móvil)"
                                                    disabled={loadingPremium}
                                                    onChange={
                                                        handleNewMobilePhone
                                                    }
                                                    value={newMobilePhone}
                                                    maxLength="11"
                                                    style={{
                                                        marginTop: "3px",
                                                        border: "",
                                                        borderRadius: "1rem",
                                                        height: "3rem",
                                                        background: "none",
                                                        textAlign: "center",
                                                        fontSize: "1.2rem",
                                                    }}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    marginTop: "1rem",
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                    alignContent: "center",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    Tiempo de Búsqueda (ms)
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Tiempo de Búsqueda"
                                                    disabled={loadingPremium}
                                                    onChange={handleNewTimeout}
                                                    value={newTimeout}
                                                    maxLength="11"
                                                    style={{
                                                        marginTop: "3px",
                                                        border: "",
                                                        borderRadius: "1rem",
                                                        height: "3rem",
                                                        background: "none",
                                                        textAlign: "center",
                                                        fontSize: "1.2rem",
                                                    }}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    marginTop: "1rem",
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                    alignContent: "center",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    Timeout de Conexión
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Timeout de conexión"
                                                    disabled={loadingPremium}
                                                    onChange={
                                                        handleNewConnectionTimeout
                                                    }
                                                    value={newConnectionTimeout}
                                                    maxLength="11"
                                                    style={{
                                                        marginTop: "3px",
                                                        border: "",
                                                        borderRadius: "1rem",
                                                        height: "3rem",
                                                        background: "none",
                                                        textAlign: "center",
                                                        fontSize: "1.2rem",
                                                    }}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    marginTop: "1rem",
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                    alignContent: "center",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    Filtro de Servicio
                                                </label>
                                                <Dropdown
                                                    placeholder="Filtro de Servicio"
                                                    className="select"
                                                    onChange={
                                                        handleServiceFilterChange
                                                    }
                                                    //searchInput={{ autoComplete: "off" }}
                                                    options={[
                                                        {
                                                            key: "on",
                                                            text: "Activado",
                                                            value: true,
                                                        },
                                                        {
                                                            key: "off",
                                                            text: "Desactivado",
                                                            value: false,
                                                        },
                                                    ]}
                                                    value={serviceFilter}
                                                />
                                            </div>
                                        </>
                                    )}

                                {optionSelected === "modifyVehicle" &&
                                    !loadingPremium && (
                                        <>
                                            <div
                                                style={{
                                                    marginTop: "1rem",
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                    alignContent: "center",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    Seleccione el vehículo
                                                </label>
                                                <Dropdown
                                                    placeholder={
                                                        usersVehicles.length <=
                                                            0 && "Sin vehículos"
                                                    }
                                                    disabled={
                                                        usersVehicles.length <=
                                                        0
                                                    }
                                                    fluid
                                                    selection
                                                    wrapSelection={true}
                                                    onChange={
                                                        handleUsersVehicles
                                                    }
                                                    options={usersVehicles}
                                                    style={{
                                                        marginTop: "3px",
                                                        // marginLeft: "6px",
                                                        border: "1px solid black",
                                                        borderRadius: "1rem",
                                                        height: "3rem",
                                                        background: "none",
                                                        textAlign: "center",
                                                        fontSize: "1.2rem",
                                                        width: "213px",
                                                    }}
                                                />
                                            </div>
                                            <div
                                                style={{
                                                    marginTop: "1rem",
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                    alignContent: "center",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    Placa
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Placa"
                                                    disabled={
                                                        !isVehicleSelected
                                                    }
                                                    onChange={
                                                        handleLicensePlate
                                                    }
                                                    value={newLicensePlate}
                                                    style={{
                                                        marginTop: "3px",
                                                        border: "",
                                                        borderRadius: "1rem",
                                                        height: "3rem",
                                                        background: "none",
                                                        textAlign: "center",
                                                        fontSize: "1.2rem",
                                                    }}
                                                />
                                            </div>

                                            <div
                                                style={{
                                                    marginTop: "1rem",
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                    alignContent: "center",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    Marca
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="marca"
                                                    disabled={
                                                        !isVehicleSelected
                                                    }
                                                    // change
                                                    onChange={handleBrand}
                                                    value={newBrand}
                                                    style={{
                                                        marginTop: "3px",
                                                        border: "",
                                                        borderRadius: "1rem",
                                                        height: "3rem",
                                                        background: "none",
                                                        textAlign: "center",
                                                        fontSize: "1.2rem",
                                                    }}
                                                />
                                            </div>

                                            <div
                                                style={{
                                                    marginTop: "1rem",
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                    alignContent: "center",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    Modelo
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Modelo"
                                                    disabled={
                                                        !isVehicleSelected
                                                    }
                                                    // change
                                                    onChange={handleModel}
                                                    value={newModel}
                                                    style={{
                                                        marginTop: "3px",
                                                        border: "",
                                                        borderRadius: "1rem",
                                                        height: "3rem",
                                                        background: "none",
                                                        textAlign: "center",
                                                        fontSize: "1.2rem",
                                                    }}
                                                />
                                            </div>

                                            <div
                                                style={{
                                                    marginTop: "1rem",
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                    alignContent: "center",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    Color
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Color"
                                                    disabled={
                                                        !isVehicleSelected
                                                    }
                                                    // change
                                                    onChange={handleColor}
                                                    value={newColor}
                                                    style={{
                                                        marginTop: "3px",
                                                        border: "",
                                                        borderRadius: "1rem",
                                                        height: "3rem",
                                                        background: "none",
                                                        textAlign: "center",
                                                        fontSize: "1.2rem",
                                                    }}
                                                />
                                            </div>

                                            <div
                                                style={{
                                                    marginTop: "1rem",
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                    alignContent: "center",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    Año
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="2022"
                                                    disabled={
                                                        !isVehicleSelected
                                                    }
                                                    // change
                                                    onChange={handleYear}
                                                    value={newYear}
                                                    style={{
                                                        marginTop: "3px",
                                                        border: "",
                                                        borderRadius: "1rem",
                                                        height: "3rem",
                                                        background: "none",
                                                        textAlign: "center",
                                                        fontSize: "1.2rem",
                                                    }}
                                                />
                                            </div>

                                            <div
                                                style={{
                                                    marginTop: "1rem",
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                    alignContent: "center",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    Alias
                                                </label>
                                                <input
                                                    type="text"
                                                    placeholder="Alias"
                                                    disabled={
                                                        !isVehicleSelected
                                                    }
                                                    // change
                                                    onChange={handleAlias}
                                                    value={newAlias}
                                                    style={{
                                                        marginTop: "3px",
                                                        border: "",
                                                        borderRadius: "1rem",
                                                        height: "3rem",
                                                        background: "none",
                                                        textAlign: "center",
                                                        fontSize: "1.2rem",
                                                    }}
                                                />
                                            </div>

                                            <div
                                                style={{
                                                    marginTop: "1rem",
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                    alignContent: "center",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    Verificado
                                                </label>
                                                <Dropdown
                                                    fluid
                                                    selection
                                                    wrapSelection={true}
                                                    value={newVerified}
                                                    onChange={handleVerified}
                                                    options={[
                                                        {
                                                            value: true,
                                                            text: "Si",
                                                        },
                                                        {
                                                            value: false,
                                                            text: "No",
                                                        },
                                                    ]}
                                                    disabled={
                                                        !isVehicleSelected
                                                    }
                                                    style={{
                                                        marginTop: "3px",
                                                        // marginLeft: "6px",
                                                        border: "1px solid black",
                                                        borderRadius: "1rem",
                                                        height: "3rem",
                                                        background: "none",
                                                        textAlign: "center",
                                                        fontSize: "1.2rem",
                                                        width: "213px",
                                                    }}
                                                />
                                            </div>

                                            <div
                                                style={{
                                                    marginTop: "1rem",
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                    alignContent: "center",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    Activo
                                                </label>
                                                <Dropdown
                                                    placeholder=""
                                                    fluid
                                                    selection
                                                    wrapSelection={true}
                                                    value={newIsActive}
                                                    onChange={handleIsActive}
                                                    disabled={
                                                        !isVehicleSelected
                                                    }
                                                    options={[
                                                        {
                                                            value: true,
                                                            text: "Si",
                                                        },
                                                        {
                                                            value: false,
                                                            text: "No",
                                                        },
                                                    ]}
                                                    style={{
                                                        marginTop: "3px",
                                                        // marginLeft: "6px",
                                                        border: "1px solid black",
                                                        borderRadius: "1rem",
                                                        height: "3rem",
                                                        background: "none",
                                                        textAlign: "center",
                                                        fontSize: "1.2rem",
                                                        width: "213px",
                                                    }}
                                                />
                                            </div>

                                            <div
                                                style={{
                                                    marginTop: "1rem",
                                                    display: "flex",
                                                    flexWrap: "wrap",
                                                    alignContent: "center",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <label
                                                    style={{
                                                        textAlign: "left",
                                                    }}
                                                >
                                                    Tipo de vehiculo
                                                </label>
                                                <Dropdown
                                                    fluid
                                                    selection
                                                    wrapSelection={true}
                                                    value={newType}
                                                    onChange={handleType}
                                                    disabled={
                                                        !isVehicleSelected
                                                    }
                                                    style={{
                                                        marginTop: "3px",
                                                        // marginLeft: "6px",
                                                        border: "1px solid black",
                                                        borderRadius: "1rem",
                                                        height: "3rem",
                                                        background: "none",
                                                        textAlign: "center",
                                                        fontSize: "1.2rem",
                                                        width: "213px",
                                                    }}
                                                    options={[
                                                        {
                                                            value: "light-car",
                                                            text: "Vehiculo liviano",
                                                        },
                                                        {
                                                            value: "light-duty",
                                                            text: "Vehiculo de carga liviana",
                                                        },
                                                        {
                                                            value: "microbus",
                                                            text: "Microbus",
                                                        },
                                                        {
                                                            value: "bus",
                                                            text: "Autobus",
                                                        },
                                                        {
                                                            value: "heavy-duty-2-axles",
                                                            text: "Vehiculo de carga pesada (2 ejes)",
                                                        },
                                                        {
                                                            value: "heavy-duty-3-axles",
                                                            text: "Vehiculo de carga pesada (3 ejes)",
                                                        },
                                                        {
                                                            value: "heavy-duty-4-axles",
                                                            text: "Vehiculo de carga pesada (4 ejes)",
                                                        },
                                                        {
                                                            value: "heavy-duty-5-axles",
                                                            text: "Vehiculo de carga pesada (5 ejes)",
                                                        },
                                                        {
                                                            value: "heavy-duty-6-axles",
                                                            text: "Vehiculo de carga pesada (6 ejes)",
                                                        },
                                                    ]}
                                                />
                                            </div>
                                        </>
                                    )}

                                {optionSelected === "ticketAllocator" &&
                                    !loadingPremium && (
                                        <>
                                            <Dropdown
                                                fluid
                                                placeholder="Tipo de Ticket"
                                                className="single-dropdown"
                                                onChange={
                                                    handleTicketTypeChange
                                                }
                                                //searchInput={{ autoComplete: "off" }}
                                                options={[
                                                    {
                                                        key: "Tickets de Promoción",
                                                        text: "Tickets de Promoción",
                                                        value: "promotion_tickets",
                                                    },
                                                    {
                                                        key: "Tickets de Recomendación",
                                                        text: "Tickets de Recomendación",
                                                        value: "recommendation_tickets",
                                                    },
                                                ]}
                                                value={ticketType}
                                            />
                                            <input
                                                type="text"
                                                placeholder="Cantidad de tickets"
                                                disabled={loadingPremium}
                                                onChange={
                                                    handlePromotionTickets
                                                }
                                                value={promotionTickets}
                                                style={{
                                                    marginTop: "1rem",
                                                    border: "none",
                                                    borderRadius: "1rem",
                                                    height: "3rem",
                                                    background: "none",
                                                    textAlign: "center",
                                                    fontSize: "1.2rem",
                                                }}
                                            />
                                        </>
                                    )}
                                {optionSelected === "ticketDelete" &&
                                    !loadingPremium && (
                                        <>
                                            <Dropdown
                                                fluid
                                                placeholder="Tipo de Ticket"
                                                className="single-dropdown"
                                                onChange={
                                                    handleTicketTypeChange
                                                }
                                                //searchInput={{ autoComplete: "off" }}
                                                options={[
                                                    {
                                                        key: "Tickets de Promoción",
                                                        text: "Tickets de Promoción",
                                                        value: "promotion_tickets",
                                                    },
                                                    {
                                                        key: "Tickets de Recomendación",
                                                        text: "Tickets de Recomendación",
                                                        value: "recommendation_tickets",
                                                    },
                                                ]}
                                                value={ticketType}
                                            />
                                            <input
                                                type="text"
                                                placeholder="Cantidad de tickets"
                                                disabled={loadingPremium}
                                                onChange={
                                                    handleDeletePromotionTickets
                                                }
                                                value={promotionTickets}
                                                style={{
                                                    marginTop: "1rem",
                                                    border: "none",
                                                    borderRadius: "1rem",
                                                    height: "3rem",
                                                    background: "none",
                                                    textAlign: "center",
                                                    fontSize: "1.2rem",
                                                }}
                                            />
                                        </>
                                    )}
                                {optionSelected !== "ref" && !loadingPremium && (
                                    <Button
                                        onClick={handleConfirmation}
                                        color="blue"
                                        disabled={loadingPremium}
                                    >
                                        {optionSelected === "modifyData" ||
                                        optionSelected === "modifyVehicle"
                                            ? "Modificar"
                                            : optionSelected === "return" ||
                                              optionSelected ===
                                                  "ticketAllocator" ||
                                              optionSelected ===
                                                  "ticketDelete" ||
                                              optionSelected === "ticketsReport"
                                            ? "Continuar"
                                            : "Sí"}
                                    </Button>
                                )}
                                {/*   {optionSelected !== "reverseTransaction" &&
                                    optionSelected !==
                                        "reverseOvertimeTransaction" &&
                                    optionSelected !==
                                        "reversePremiumTransaction" &&
                                    optionSelected !==
                                        "reversePremiumBundleTransaction" &&
                                    optionSelected !==
                                        "reverseParkingmeterTransaction" &&
                                    optionSelected !==
                                        "reverseTollTransaction" &&
                                    !loadingPremium && (
                                        <Button
                                            onClick={handleConfirmation}
                                            color="blue"
                                            disabled={loadingPremium}
                                        >
                                            Sí
                                        </Button>
                                    )} */}
                                {optionSelected !== "ref" &&
                                    optionSelected === "modifyVehicle" &&
                                    !loadingPremium && (
                                        <Button
                                            onClick={handleDeleteVehicle}
                                            color="red"
                                            disabled={!isVehicleSelected}
                                        >
                                            {optionSelected === "modifyVehicle"
                                                ? "Eliminar"
                                                : "Sí"}
                                        </Button>
                                    )}
                                {optionSelected !== "ref" && (
                                    <Button
                                        color="grey_1"
                                        onClick={closePopup}
                                        disabled={loadingPremium}
                                    >
                                        {optionSelected === "return" ||
                                        optionSelected === "ticketAllocator" ||
                                        optionSelected === "ticketDelete" ||
                                        optionSelected === "modifyData" ||
                                        optionSelected === "modifyVehicle" ||
                                        optionSelected === "ticketsReport"
                                            ? "Cancelar"
                                            : "No"}
                                    </Button>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </PopupMessage>
            <RightBorderGreyColumn phone={8} tablet={8} computer={6}>
                <SearchInputNew
                    placeholder={viewTexts.userSearchPlaceholder}
                    searchParam={searchParam}
                    handleChange={handleSearchInputChange}
                    handleSearch={handleSearchUser}
                />
                <EntitiesList
                    height="calc(100vh - 7rem)"
                    viewState={viewState}
                    entities={users}
                    formattingFunction={formatUserList}
                    selectionHandler={handleUserSelection}
                >
                    <Pagination
                        padding="1rem"
                        nextPageExist={nextUsersPageExists}
                        currentPage={currentUsersPage}
                        handlePageUp={handleNextUsersPage}
                        handlePageDown={handlePrevUsersPage}
                    />
                </EntitiesList>
            </RightBorderGreyColumn>

            <Grid.Column phone={8} tablet={8} computer={10}>
                <Grid centered padded>
                    <Grid.Column phone={14} tablet={14} computer={12}>
                        {viewState === "user_search" && (
                            <PanelPlaceholder>
                                {texts.userSearchPlaceholder}
                            </PanelPlaceholder>
                        )}
                        {viewState === "user_selection_loading" && (
                            <div
                                className="ui active loader"
                                style={{
                                    marginTop: "3rem",
                                    position: "relative",
                                }}
                            ></div>
                        )}
                        <CSSTransition
                            in={showResponsePanel}
                            mountOnEnter
                            unmountOnExit
                            timeout={350}
                            appear={true}
                            classNames="panel"
                        >
                            <Panel>
                                {viewState === "user_selection_done" && (
                                    <UserInfo
                                        permissions={permissions}
                                        user={selectedUser}
                                        handleSearchMovements={
                                            handleSearchMovements
                                        }
                                        handleOptionsSelection={
                                            handleOptionsSelection
                                        }
                                        remoteAccess={remoteAccess}
                                        remoteStateToBack={remoteStateToBack}
                                        contactPhone={contactPhone}
                                        handleContactPhone={handleContactPhone}
                                        handleUpdateUser={handleUpdateUser}
                                    />
                                )}

                                {(viewState === "movements_search_loading" ||
                                    viewState === "movements_search_done" ||
                                    viewState ===
                                        "movements_pagination_loading" ||
                                    viewState ===
                                        "movement_selection_done") && [
                                    <Button
                                        color="grey"
                                        onClick={removeMovementsView}
                                        key="button"
                                    >
                                        Información del Usuario
                                    </Button>,
                                    <MovementsForm
                                        viewState={viewState}
                                        searchType={searchType}
                                        searchFilter={searchFilter}
                                        searchCurrency={searchCurrency}
                                        fromDate={fromDate}
                                        endDate={endDate}
                                        handleSearchTypeButtons={
                                            handleSearchTypeButtons
                                        }
                                        handleSearchFilterButtons={
                                            handleSearchFilterButtons
                                        }
                                        handleSearchCurrencyButtons={
                                            handleSearchCurrencyButtons
                                        }
                                        handleFromDate={handleFromDate}
                                        handleEndDate={handleEndDate}
                                        handleSubmit={handleSubmit}
                                        key="item"
                                    />,
                                ]}
                            </Panel>
                        </CSSTransition>
                        {viewState === "user_selection_done" && (
                            <CommentsBox
                                selectedUser={selectedUser}
                            ></CommentsBox>
                        )}
                        {(viewState === "movements_search_loading" ||
                            viewState === "movements_search_done" ||
                            viewState === "movements_pagination_loading" ||
                            viewState === "movement_selection_done") && (
                            <Panel>
                                <EntitiesList
                                    height="calc(55vh - 7rem)"
                                    viewState={viewState}
                                    entities={movements}
                                    formattingFunction={formatMovementsList}
                                    selectionHandler={handleMovementSelection}
                                    additionalArgs={[
                                        selectedMovement,
                                        buildings,
                                        onDetailOptionsChange,
                                        onStatusOrder,
                                        transactionTags,
                                    ]}
                                >
                                    <Pagination
                                        padding="0 0 0.8rem 0"
                                        nextPageExist={nextPageExist}
                                        currentPage={currentPage}
                                        handlePageUp={handlePageUp}
                                        handlePageDown={handlePageDown}
                                    />
                                </EntitiesList>
                            </Panel>
                        )}
                    </Grid.Column>
                </Grid>
            </Grid.Column>
            {location && redirect}
        </Grid>
    );
};

export default MposUsersAdminView;
