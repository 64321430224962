import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { clearReduxStore } from "redux/actions/general";
import { debounce } from "services/utils";
import { errors } from "assets/strings/texts";
import SearchInput from "components/search-input";
import {
  commercesSuperAutocomplete /* downloadInvoiceExcel */,
} from "services/api";
import { debouncer_timeout } from "assets/strings/constants";
import { formatCommercesList } from "../commerces-list-item";
import { throwPopupMessage } from "redux/actions/popup-messages";
import { EntitiesList, Pagination } from "components/entities-list";
import { commerces_admin as viewTexts } from "assets/strings/texts";
//import { GeneralReport } from "./components/general-report";

import {
  setViewState,
  selectCommerce,
  updateCommercesList,
  resetState,
} from "redux/actions/view-mpos-commerces";

class CommercesSearchPanelModel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchParam: "",
      currentPage_Commerces: 1,
      nextPageExist_Commerces: false,
      authError: false,
    };

    // ========== INSTANCE PROPERTIES ==========
    this.initialState = { ...this.state };
    this.commercesPerPage = 10;
  }

  // 1)
  handleSearchInputChange = (event) => {
    let value = event.target.value;
    this.setState({
      ...this.initialState,
      searchParam: value,
    });
    this.props.resetState();
    this.props.setViewState("commerce_search_loading");
    let skip = 0;
    let limit = this.commercesPerPage + 1;
    // call the API in a debounced way
    this.debouncedApiCall(value, skip, limit);
  };

  // 2)
  debouncedApiCall = debounce((value, skip, limit) => {
    // call the API
    let commercesPromise = commercesSuperAutocomplete(value, skip, limit);
    // Manage the response
    this.handleCommercesSearchPromise(commercesPromise);
    // Timer
  }, debouncer_timeout);

  // 3)
  handlePageDown_Commerces = () => {
    // Get data required for the api call
    let skip = (this.state.currentPage_Commerces - 2) * this.commercesPerPage;
    let limit = this.commercesPerPage + 1;
    let value = this.state.searchParam;
    //  set state and make api call
    this.setState((prevState) => ({
      ...this.initialState,
      searchParam: prevState.searchParam,
      currentPage_Commerces: prevState.currentPage_Commerces - 1,
      nextPageExist_Commerces: true,
    }));
    this.props.resetState();
    this.props.setViewState("commerce_search_loading");

    let promise = commercesSuperAutocomplete(value, skip, limit);
    this.handleCommercesSearchPromise(promise);
  };

  // 4)
  handlePageUp_Commerces = () => {
    // Get data required for the api call
    let skip = this.state.currentPage_Commerces * this.commercesPerPage;
    let limit = this.commercesPerPage + 1;
    let value = this.state.searchParam;
    // set state and make api call
    this.setState((prevState) => ({
      ...this.initialState,
      searchParam: prevState.searchParam,
      currentPage_Commerces: prevState.currentPage_Commerces + 1,
      nextPageExist_Commerces: true,
    }));
    this.props.resetState();
    this.props.setViewState("commerce_search_loading");

    let promise = commercesSuperAutocomplete(value, skip, limit);
    this.handleCommercesSearchPromise(promise);
  };

  // 5)
  handleCommercesSearchPromise = (promise) => {
    promise
      .then((res) => {
        if (!res.ok) {
          throw res;
        } // If not OK throw error
        return res.json(); // If OK return body
      })
      .then((res) => {
        // Manage success response
        if (res.commerces) {
          let nextPageExist_Commerces;
          if (res.commerces.length > this.commercesPerPage) {
            nextPageExist_Commerces = true;
            res.commerces.pop();
          } else {
            nextPageExist_Commerces = false;
          }
          // Print the commerces
          this.setState({
            nextPageExist_Commerces,
          });
          this.props.updateCommercesList(res.commerces);
          this.props.setViewState("commerce_search_done");
        }
      })
      .catch((err) => {
        // Manage error response
        // Set apropiate states
        this.setState({ ...this.initialState });
        this.props.resetState();
        // If error has a body, check the response
        if (typeof err.json === "function") {
          err.json().then((err_body) => {
            // If it is an authentication error, clear the redux-store to close the session
            if (
              err_body.error.id === "NO_TOKEN_PROVIDED" ||
              err_body.error.id === "AUTHENTICATE_FAILED" ||
              err_body.error.id === "DUPLICATE_SESSION"
            ) {
              this.props.throwPopupMessage("alert", errors.AUTHENTICATION);
              //this.props.clearReduxStore();
              return; // prevent setState exeution on unmounted component
            }
            this.props.throwPopupMessage("error", err_body.error.description);
          });
        } else {
          this.props.throwPopupMessage("error", errors.GENERAL_ERR);
        }
      });
  };

  handleCommerceSelection = (event) => {
    let index = event.currentTarget.id;
    this.props.setViewState("commerce_info");

    this.props.selectCommerce({ ...this.props.commerces[index] });
  };

  /*   downloadExcel = (event) => {
    event.preventDefault();
    const downloadExcelInvoice = async () => {
      try {
        const response = await downloadInvoiceExcel();
        //const res = await response.json();
        //if (res.error) throw res.error;
        if (response.error) throw response.error;
        this.props.throwPopupMessage(
          "alert",
          "Por ahora esta funcion no esta disponible"
        );
      } catch (error) {
        this.props.throwPopupMessage(
          "error",
          "Ha ocurrido un error, verifique su conexión a internet"
        );
      }
    };
    downloadExcelInvoice();
  }; */

  render() {
    const { searchParam, currentPage_Commerces, nextPageExist_Commerces } =
      this.state;
    const { viewState, commerces } = this.props;
    return (
      <React.Fragment>
        {/* <GeneralReport downloadExcel={this.downloadExcel} /> */}
        <SearchInput
          searchParam={searchParam}
          placeholder={viewTexts.searchPlaceholder}
          handleChange={this.handleSearchInputChange}
        />
        <EntitiesList
          margin="0"
          height="calc(90vh - 9rem)"
          viewState={viewState}
          entities={commerces}
          formattingFunction={formatCommercesList}
          selectionHandler={this.handleCommerceSelection}
        >
          <Pagination
            padding="0.65rem 0 0.65rem 0"
            nextPageExist={nextPageExist_Commerces}
            currentPage={currentPage_Commerces}
            handlePageUp={this.handlePageUp_Commerces}
            handlePageDown={this.handlePageDown_Commerces}
          />
        </EntitiesList>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  viewState: state.viewMposCommerces.viewState,
  commerces: state.viewMposCommerces.commerces,
});

const mapDispatchToProps = {
  resetState,
  setViewState,
  selectCommerce,
  throwPopupMessage,
  updateCommercesList,
  clearReduxStore,
};

const CommercesSearchPanel = connect(
  mapStateToProps,
  mapDispatchToProps
)(CommercesSearchPanelModel);

export { CommercesSearchPanel };
