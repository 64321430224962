import React from "react";
import { CSSTransition } from "react-transition-group";
import { Dropdown } from "semantic-ui-react";

const RegisterFormModel = ({
  user,
  email,
  docType,
  docId,
  password,
  confirmPassword,
  mobilePaidNumber,
  handleUser,
  handleEmail,
  handleDocType,
  handleDocId,
  handlePassword,
  handleConfirmPassword,
  handleMobilePaidNumber,
  handleSubmit,
  className,
}) => {
  const options = [
    { key: 0, text: "V.", value: "V" },
    { key: 1, text: "E.", value: "E" },
    { key: 2, text: "J.", value: "J" },
    { key: 3, text: "P.", value: "P" },
  ];
  return (
    <div className={className}>
      <CSSTransition
        in={true}
        appear={true}
        timeout={350}
        classNames="register"
      >
        <div className="register">
          <p>Registro</p>
          <input
            maxLength={30}
            type="text"
            pattern="^[a-z0-9][a-z0-9\._-]{2,14}$"
            placeholder="Usuario"
            value={user}
            onChange={handleUser}
          />
          <input
            type="email"
            maxLength={60}
            placeholder="Correo Electrónico"
            value={email}
            onChange={handleEmail}
          />
          <div className="doc-class">
            <Dropdown
              className="label"
              name="doc_type"
              options={options}
              value={docType}
              onChange={handleDocType}
            />
            <input
              type="text"
              placeholder="Nro. Documento"
              minLength={8}
              maxLength={10}
              value={docId}
              onChange={handleDocId}
            />
          </div>

          <input
            minLength={6}
            type="password"
            placeholder="Contraseña"
            pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d$@!%*#?&.=*]{8,}$"
            value={password}
            onChange={handlePassword}
          />
          <input
            minLength={6}
            type="password"
            placeholder="Confirmar contraseña"
            pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d$@!%*#?&.=*]{8,}$"
            value={confirmPassword}
            onChange={handleConfirmPassword}
          />
          {password !== confirmPassword ? (
            <p className="wrong-password">las contraseñas no coinciden</p>
          ) : (
            ""
          )}
          <input
            minLength={11}
            maxLength={11}
            type="text"
            placeholder="Teléfono Pago Móvil"
            value={mobilePaidNumber}
            onChange={handleMobilePaidNumber}
          />
          <button
            onClick={handleSubmit}
            disabled={
              !mobilePaidNumber.length ||
              !user.length ||
              !email.length ||
              !docType.length ||
              !docId.length ||
              !password.length ||
              !confirmPassword.length ||
              confirmPassword !== password
            }
          >
            Registrar
          </button>
        </div>
      </CSSTransition>
    </div>
  );
};

export { RegisterFormModel };
