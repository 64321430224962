import React from "react";
import { Grid } from "semantic-ui-react";
import { GeneralData } from "../../components/general-data";
import { Filters } from "../../components/filters";
import { FirstRow } from "../../components/first-row";
import { SecondRow } from "../../components/second-row";
import { Row } from "../../components/row";
import PopupMessage from "components/popup-message";
import { Loader } from "components/loader";

const ParkingView = ({
                       transition,
                       item,
                       fromDate,
                       endDate,
                       barStats,
                       customStats,
                       ticketsDistribution,
                       totalAmounts,
                       parkingData,
                       buildingName,
                       relevance,
                       filterTime,
                       typeStatusList,
                       buildingsOptions,
                       barHandlePeriod,
                       listTypesStatusHandlePeriod,
                       listDataHandle,
                       amountsHandlePeriod,
                       handleBuildingChange,
                       handlePeriodChange,
                       handleFromDate,
                       handleEndDate,
                       handleSubmit,
                       popupMessagesType,
                       showPopup,
                       popupMessages,
                       closePopup,
                       loading,
                     }) => {

  return (
    <Grid padded>
      <PopupMessage
        messageType={popupMessagesType}
        showPopup={showPopup}
        messages={popupMessages}
        closePopup={closePopup}
      />
      {loading && <Loader />}
      {!loading && (
        <Grid.Column width={11}>
          <Filters
            item={item}
            transition={transition}
            fromDate={fromDate}
            endDate={endDate}
            buildingName={buildingName}
            filterTime={filterTime}
            buildingsOptions={buildingsOptions}
            handleBuildingChange={handleBuildingChange}
            handlePeriodChange={handlePeriodChange}
            handleFromDate={handleFromDate}
            handleEndDate={handleEndDate}
            handleSubmit={handleSubmit}
          />
          <FirstRow
            transition={transition}
            item={item}
            customStats={customStats}
            listTypesStatusHandlePeriod={listTypesStatusHandlePeriod}
            listDataHandle={listDataHandle}
            typeStatusList={typeStatusList}
          />

          <SecondRow
            transition={transition}
            item={item}
            barStats={barStats}
            ticketsDistribution={ticketsDistribution}
            barHandlePeriod={barHandlePeriod}
            totalAmounts={totalAmounts}
            amountsHandlePeriod={amountsHandlePeriod}
          />

          <Row
            transition={transition}
            item={item}
            barStats={barStats}
            barHandlePeriod={barHandlePeriod}
          />
        </Grid.Column>
      )}
      {!loading && (
        <Grid.Column width={5}>
          <GeneralData
            item={item}
            transition={transition}
            parkingData={parkingData}
            negativeVariation={relevance.variation < 0}
            relevance={relevance}
          />
        </Grid.Column>
      )}
    </Grid>
  );
};

export { ParkingView };
