import { CardModel } from "./model";
import styled from "styled-components";
import { green_6, grey_3, magenta } from "assets/strings/colors";

const Card = styled(CardModel)`
  #root && {
    margin: 0;
    padding: 2rem 2rem;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    > .identifier {
      width: 20%;
      padding-top: 0.4rem;
      display: flex;
      justify-content: center;
      img {
        width: 50%;
      }
      > i {
        color: ${magenta};
      }
    }
    > .content {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 80%;
      > p {
        text-align: left;
      }
      > .option {
        margin: 0;
        align-self: left;
        width: calc(80% - 1rem);
        height: 30px;
        background: ${(props) => (props.isSelected ? green_6 : grey_3)};
        color: white;
        font-size: 0.9rem;
        border: none;
        border-radius: 2rem;
        :hover {
          cursor: pointer;
          ${(props) => (props.isSelected ? "" : "opacity: 0.7")}
        }
      }
    }
  }
`;

export { Card };
