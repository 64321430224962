import React from "react";
import { Dropdown } from "components/dropdown";
import { general as texts } from "assets/strings/texts";
import { building_settings as viewTexts } from "assets/strings/texts";
import { Button } from "components/buttons";

const AccessesFormModel = ({
  viewState,
  loading,
  name,
  status,
  type,
  macType,
  mac,
  lat,
  long,
  rssi_android,
  rssi_ios,
  track_index,
  handleTextInput,
  handleDropown,
  handleSubmit,
  className,
}) => {
  return (
    <form className={className} onSubmit={handleSubmit}>
      {loading && <div className="ui active loader"></div>}
      <div className="input-section">
        <label htmlFor="name">{texts.name}</label>
        <input
          id="name"
          type="text"
          disabled={viewState === "accesses_show_one" || loading}
          required={true}
          autoComplete="off"
          placeholder={viewTexts.accessNamePlaceholder}
          value={name}
          onChange={handleTextInput}
        />
      </div>
      {viewState !== "accesses_create" && (
        <div className="input-section">
          <label htmlFor="status">{texts.status}</label>
          <Dropdown
            fluid
            selection
            id="status"
            disabled={viewState === "accesses_show_one" || loading}
            placeholder={viewTexts.accessStatusPlaceholder}
            value={status}
            onChange={handleDropown}
            options={[
              { text: "active", value: "active" },
              { text: "inactive", value: "inactive" },
            ]}
          />
        </div>
      )}
      {viewState !== "accesses_update" && (
        <div className="input-section">
          <label htmlFor="mac_type">{viewTexts.macType}</label>
          <Dropdown
            fluid
            selection
            id="mac_type"
            disabled={viewState === "accesses_show_one" || loading}
            placeholder={viewTexts.accessMacTypePlaceholder}
            value={macType}
            onChange={handleDropown}
            options={[
              { text: "arduino", value: "arduin" },
              { text: "beacon", value: "beacon" },
            ]}
          />
        </div>
      )}
      <div className="input-section">
        <label htmlFor="mac">{viewTexts.mac}</label>
        <input
          id="mac"
          type="text"
          disabled={viewState === "accesses_show_one" || loading}
          required={true}
          autoComplete="off"
          placeholder={viewTexts.accessMacPlaceholder}
          value={mac}
          onChange={handleTextInput}
        />
      </div>
      <div className="input-section">
        <label htmlFor="track-index">{viewTexts.track_index}</label>
        <input
          id="track_index"
          type="text"
          disabled={viewState === "accesses_show_one" || loading}
          required={true}
          autoComplete="off"
          placeholder={viewTexts.trackIndexPlaceholder}
          value={track_index}
          maxLength={3}
          onChange={handleTextInput}
        />
      </div>
      <div className="input-section">
        <label htmlFor="type">{texts.type}</label>
        <Dropdown
          fluid
          selection
          disabled={viewState === "accesses_show_one" || loading}
          id="type"
          placeholder={viewTexts.accessTypePlaceholder}
          value={type}
          onChange={handleDropown}
          options={[
            { text: "entry", value: "entry" },
            { text: "exit", value: "exit" },
          ]}
        />
      </div>
      <div className="input-section">
        <span className="label">{viewTexts.coordinates}</span>
        <div className="longlat">
          <label htmlFor="long" className="label">
            {viewTexts.long}
          </label>
          <input
            id="long"
            type="number"
            disabled={viewState === "accesses_show_one" || loading}
            required={true}
            autoComplete="off"
            placeholder={viewTexts.accessLongPlaceholder}
            value={long}
            onChange={handleTextInput}
          />
        </div>
        <div className="longlat">
          <label htmlFor="lat" className="label">
            {viewTexts.lat}
          </label>
          <input
            id="lat"
            type="number"
            disabled={viewState === "accesses_show_one" || loading}
            required={true}
            autoComplete="off"
            placeholder={viewTexts.accessLatPlaceholder}
            value={lat}
            onChange={handleTextInput}
          />
        </div>
      </div>

      <div className="input-section">
        <span className="label">{viewTexts.rssi}</span>
        <div className="longlat">
          <label htmlFor="rssi_android" className="label">
            {viewTexts.rssi_android}
          </label>
          <input
            id="rssi_android"
            type="number"
            disabled={viewState === "accesses_show_one" || loading}
            required={true}
            autoComplete="off"
            placeholder={viewTexts.accessAndroidRssiPlaceholder}
            value={rssi_android}
            onChange={handleTextInput}
          />
        </div>
        <div className="longlat">
          <label htmlFor="rssi_ios" className="label">
            {viewTexts.rssi_ios}
          </label>
          <input
            id="rssi_ios"
            type="number"
            disabled={viewState === "accesses_show_one" || loading}
            required={true}
            autoComplete="off"
            placeholder={viewTexts.accessIosRssiPlaceholder}
            value={rssi_ios}
            onChange={handleTextInput}
          />
        </div>
      </div>

      {viewState !== "accesses_show_one" && (
        <Button type="submit" disabled={loading} color="blue">
          {loading ? texts.loading : texts.accept}
        </Button>
      )}
    </form>
  );
};

export { AccessesFormModel };
