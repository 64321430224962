import React, { useEffect } from "react";
import { useStateManager } from "hooks/use-state-manager";
import { useAmountInput } from "hooks/use-inputs";
import { usePopupMessages } from "hooks/use-popup-messages";
import { connect } from "react-redux";
import { clearReduxStore } from "redux/actions/general";
import { getSelectedCommerce } from "redux/selectors";
import MPosBuildingPdfeeView from "./components/mpos-building-pdfee-view";
import MPosBuildingPdfeePhoneView from "./components/mpos-building-pdfee-phone-view";
import { errors } from "assets/strings/texts";
import { updateBuildingsPdFee, getBuildings } from "services/api";

const MPosBuildingPdfee = ({ location }) => {
  const {
    popupMessages,
    popupMessagesType,
    showPopup,
    throwPopupMessage,
    closePopup,
  } = usePopupMessages(null, "", false);
  const initialState = {
    viewState: "selection",
    loading: false,
    viewType: "desktop",
    buildingList: [],
    buildingsSelected: [],
    submitChanges: false,
    getBuildings: false,
    render: false,
  };
  const [state, changeState] = useStateManager(initialState);
  const {
    amount: fee,
    handle: handleFee,
    amountNumber: feeNumber,
  } = useAmountInput("");

  useEffect(() => {
    setViewType();
    changeState({ getBuildingsList: true });
    window.addEventListener("resize", setViewType);
    return () => {
      window.removeEventListener("resize", setViewType);
    };
  }, []);

  useEffect(() => {
    const apiGetBuildingsList = async () => {
      try {
        changeState({ loading: true });
        const response = await getBuildings();
        const res = await response.json();
        if (res.error) throw res.error;
        changeState({ buildingList: res.buildings, loading: false });
      } catch (err) {
        console.log({ err });
        changeState({
          ...initialState,
        });
        if (
          err.id === "NO_TOKEN_PROVIDED" ||
          err.id === "AUTHENTICATE_FAILED" ||
          err.id === "DUPLICATE_SESSION"
        ) {
          throwPopupMessage("alert", errors.AUTHENTICATION);
        } else {
          throwPopupMessage("error", err.description);
        }
      }
    };
    if (state.getBuildingsList) {
      changeState({ getBuildingsList: false });
      apiGetBuildingsList();
    }
    return () => {};
  }, [state.getBuildingsList]);

  useEffect(() => {
    const submitFeeChanges = async () => {
      try {
        const buildingsID = state.buildingsSelected.map((building) => {
          return building._id;
        });
        const fee = feeNumber;
        const req = {
          buildings_id: buildingsID,
          pd_fee: fee,
        };
        changeState({ loading: true });
        const response = await updateBuildingsPdFee(req);
        const res = await response.json();
        if (res.error) throw res.error;
        changeState({
          loading: false,
          getBuildingsList: true,
          buildingsSelected: [],
        });
        throwPopupMessage("success", "Fees actualizados correctamente");
      } catch (err) {
        console.log({ err });
        changeState({
          ...initialState,
        });
        if (
          err.id === "NO_TOKEN_PROVIDED" ||
          err.id === "AUTHENTICATE_FAILED" ||
          err.id === "DUPLICATE_SESSION"
        ) {
          throwPopupMessage("alert", errors.AUTHENTICATION);
        } else {
          throwPopupMessage("error", err.description);
        }
      }
    };
    if (state.submitChanges) {
      changeState({ submitChanges: false });
      submitFeeChanges();
    }
    return () => {};
  }, [state.submitChanges]);

  const setViewType = () => {
    let documentElement = document.documentElement,
      width = window.innerWidth || documentElement.clientWidth;
    if (width < 768) {
      changeState({ viewType: "phone", render: true });
    } else {
      changeState({ viewType: "desktop", render: true });
    }
  };

  const handleBuildingSelection = (event) => {
    let index = event.currentTarget.id;
    let selectBuilding = state.buildingList[index];
    let selection = state.buildingsSelected;
    const existList = selection.find((elem) => {
      return elem._id === selectBuilding._id;
    });
    if (!existList) {
      selection.push(selectBuilding);
      changeState({ buildingsSelected: selection, viewState: "selected" });
    }
  };

  const handleBuildingUnselection = (event) => {
    let index = event.currentTarget.id;
    let selection = state.buildingsSelected;
    selection.splice(index, 1);
    changeState({ buildingsSelected: selection });
  };

  const handleSelectAll = (event) => {
    event.preventDefault();
    let selection = [...state.buildingList];
    changeState({ buildingsSelected: selection, viewState: "selected" });
  };

  const handleBack = (event) => {
    event.preventDefault();
    changeState({ viewState: "selection" });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    changeState({ submitChanges: true });
  };

  if (state.viewType === "desktop")
    return (
      <MPosBuildingPdfeeView
        viewType={state.viewType}
        location={location}
        loading={state.loading}
        fee={fee}
        buildingList={state.buildingList}
        buildingsSelected={state.buildingsSelected}
        handleFee={handleFee}
        handleBuildingSelection={handleBuildingSelection}
        handleBuildingUnselection={handleBuildingUnselection}
        handleSubmit={handleSubmit}
        handleSelectAll={handleSelectAll}
        throwPopupMessage={throwPopupMessage}
        popupMessages={popupMessages}
        popupMessagesType={popupMessagesType}
        showPopup={showPopup}
        closePopup={closePopup}
      />
    );
  if (state.viewType === "phone")
    return (
      <MPosBuildingPdfeePhoneView
        viewState={state.viewState}
        viewType={state.viewType}
        location={location}
        loading={state.loading}
        fee={fee}
        buildingList={state.buildingList}
        buildingsSelected={state.buildingsSelected}
        handleFee={handleFee}
        handleBuildingSelection={handleBuildingSelection}
        handleBuildingUnselection={handleBuildingUnselection}
        handleBack={handleBack}
        handleSubmit={handleSubmit}
        handleSelectAll={handleSelectAll}
        throwPopupMessage={throwPopupMessage}
        popupMessages={popupMessages}
        popupMessagesType={popupMessagesType}
        showPopup={showPopup}
        closePopup={closePopup}
      />
    );
  if (state.viewType === null) return null;
};

const mapStateToProps = (state) => {
  let selectedCommerce = getSelectedCommerce(state);
  return { selectedCommerce };
};

const mapDispatchToProps = {
  clearReduxStore,
};

export default connect(mapStateToProps, mapDispatchToProps)(MPosBuildingPdfee);
