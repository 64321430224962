import React, { useContext } from "react";
import { Grid } from "semantic-ui-react";
import { Dropdown } from "components/dropdown";
import { PermissionsValidator } from "components/permissions-validator";
import { RightBorderGreyColumn } from "components/styled-grid-column";
import PopupMessage from "components/popup-message";
import SearchInputNew from "components/search-input-new";
import { EntitiesList } from "components/entities-list";
import { users_admin as viewTexts } from "assets/strings/texts";
import { formatUserList } from "../components/user-list-item";
import { formatMtdList } from "../components/mtd-list-item";
import { LogsPanel } from "../components/logs-form";
import { SwitchSlider } from "components/checkboxes";
import { UsersLogsContext } from "../context";

const MPosUsersLogsView = () => {
  const {
    location,
    loading,
    state,
    handleSearchType,
    handleSearchInputChange,
    handleSearchUser,
    handleBuildingChange,
    handleUserSelection,
    handleMtdSelection,
    popupMessages,
    popupMessagesType,
    showPopup,
    closePopup,
  } = useContext(UsersLogsContext);

  return (
    <Grid padded>
      <PermissionsValidator route={location.pathname} />
      <PopupMessage
        messageType={popupMessagesType}
        showPopup={showPopup}
        messages={popupMessages}
        closePopup={closePopup}
      />
      <RightBorderGreyColumn phone={8} tablet={8} computer={6}>
        {state.category === "pagodirecto" && (
          <SwitchSlider
            margin={state.searchType === "by_user" ? "0" : "0 0 0.5rem 0"}
            selected={state.searchType}
            options={[
              { data: "by_user", displayData: "Usuario" },
              { data: "by_building", displayData: "Edificio" },
            ]}
            handleSelection={handleSearchType}
          />
        )}
        {state.category === "pagodirecto" && state.searchType === "by_user" && (
          <SearchInputNew
            placeholder={viewTexts.userSearchPlaceholder}
            searchParam={state.searchParam}
            handleChange={handleSearchInputChange}
            handleSearch={handleSearchUser}
          />
        )}
        {state.searchType === "by_building" && !loading && (
          <Dropdown
            fluid
            selection
            placeholder={"Edificio"}
            wrapSelection={true}
            options={state.buildingsOptions}
            value={state.selectedBuilding}
            disabled={state.loading}
            onChange={handleBuildingChange}
            textalign="center"
          />
        )}
        <EntitiesList
          height={
            state.loading
              ? "calc(100vh - 4rem)"
              : state.category === "pagodirecto"
              ? "calc(100vh - 9rem)"
              : "calc(100vh - 7rem)"
          }
          viewState={state.viewState}
          entities={state.searchType === "by_user" ? state.users : state.mtds}
          formattingFunction={state.searchType === "by_user" ? formatUserList : formatMtdList}
          selectionHandler={
            state.searchType === "by_user" ? handleUserSelection : handleMtdSelection
          }
          additionalArgs={[state.loading]}
        />
      </RightBorderGreyColumn>
      <Grid.Column phone={8} tablet={8} computer={10}>
        <LogsPanel />
      </Grid.Column>
    </Grid>
  );
};

export default MPosUsersLogsView;
