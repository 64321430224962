import React, { Component } from "react";
import MPosUsersAdmin from "views/mpos-users-admin";
import MposUserRegister from "views/mpos-user-register";
import MPosUsersLogs from "views/mpos-users-logs";
import MPosUsersNotifications from "views/mpos-users-notifications";
import MPosUsersNotificationsDiscountCodes from "views/mpos-user-notifications-discount-codes";
import MPosUsersExtensions from "views/mpos-users-extensions";
import MPosBuildingPdfee from "views/mpos-building-pdfee";
import MPosDashboard from "views/mpos-dashboard";
import MPosCommerceUsers from "views/mpos-commerce-users";
import MPosCommerceDigitize from "views/mpos-commerce-digitize";
import MPosCommerceWallet from "views/mpos-commerce-wallet";
import MPosCommerceMovements from "views/mpos-commerce-movements";
import MPosInvoicesHistory from "views/mpos-invoices-history";
import MPosSignupBoxoffice from "views/mpos-signup-boxoffice";
import MPosSupport from "views/mpos-support";
import MPosReports from "views/mpos-reports";
import MPosSuperadminBills from "views/mpos-superadmin-bills";
import MPosSuperadminBillHistory from "views/mpos-superadmin-bills-history";
import MPosUsersNotificationsMassive from "views/mpos-users-notifications-massive";
import MPosTicketsHistory from "views/mpos-tickets-history";
import MPosBuildingSettings from "views/mpos-building-settings";
import MposBuildingCommerces from "views/mpos-building-commerces";
import MposCommercesAdmin from "views/mpos-commerces-admin";
import MPosTicketsValidate from "views/mpos-tickets-validate";
import MPosTicketsDigitize from "views/mpos-tickets-digitize";
import MposOvertimeTicketsTransactions from "views/mpos-overtime-tickets-transactions";
import MposRechargesValidate from "views/mpos-recharges-validate";
import MposRechargesRecharge from "views/mpos-recharges-recharge";
import MposWithdrawHistory from "views/mpos-withdraw-history";
import MposParkingSettings from "views/mpos-parking-settings";
import MPosBoxOffice from "views/mpos-box-office";
import MposAmountHistory from "views/mpos-amount-history";
import MposAdminTransfer from "views/mpos-admin-transfer";
import MposMonthlyPremiumActivate from "views/mpos-monthly-premium-activate";
import MposMonthlyPremiumInvoices from "views/mpos-monthly-premium-invoices";
import MposMonthlyPremiumHistory from "views/mpos-monthly-premium-history";
import MPosUsersMovementsMobilePaid from "views/mpos-users-movements-mobile-paid";
import MPosUsersMovementsTransfers from "views/mpos-users-movements-transfers";
import MposWalletTransferHistory from "views/mpos-wallet-transfer-history";
import MPosWalletTodayMovements from "views/mpos-wallet-today-movements";
import MposSuperadminAccounts from "views/mpos-superadmin-accounts";

import Sidebar from "react-sidebar";
import { connect } from "react-redux";
import styled from "styled-components";
import Navbar from "components/navbar";
import { Route } from "react-router-dom";
import { errors } from "assets/strings/texts";
import PopupMessage from "components/popup-message";
import { mpos as mposTexts } from "assets/strings/texts";
import SidebarContent from "./components/sidebar-content";
import { patchWorkspace, getBuildings } from "services/api";
import { debouncer_timeout } from "assets/strings/constants";
import { textualBlue, posDirectoBlue } from "assets/strings/colors";
import { throwPopupMessage, closePopup } from "components/popup-message";
import { addBuildings, selectBuilding } from "redux/actions/buildings";
import { clearReduxStore } from "redux/actions/general";
import {
  addColors,
  addWorkspace,
  updateSession,
  addForeignId,
  addOfficeNumbers,
} from "redux/actions/session";
import { addTransactionsTags } from "redux/actions/constants";
import {
  getSession,
  getSelectedBuilding,
  getBuildings as getBuildingsFromRedux,
  getBuildingById as getBuildingByIdFromRedux,
  getSelectedCommerce,
  getCommerces as getCommercesFromRedux,
  getPermissions,
  getOfficeNumbers,
} from "redux/selectors";
import {
  CriticalNotifications,
  BlockedCommerce,
  UpdateReady,
  // PushSentSuccessfully,
} from "./components/critical-notifications";
import {
  getRgbFromHex,
  getAverageColor,
  calculateLuma,
  debounce,
} from "services/utils";
import { joinCommerceSocket } from "services/websockets";
import { getConstants } from "services/api";
// Aplicar tamaño minimo y color de fondo para todas las pantallas dentro del MPos
const MPosContent = styled.div`
  #root & {
    .column {
      padding: 0;
    }
  }
`;

class MPos extends Component {
  constructor(props) {
    super(props);
    this._isMounted = false;
    let officeNumbers = props.selectedOfficeNumbers;
    let companyName =
      props.selectedCommerce && props.selectedCommerce.name
        ? props.selectedCommerce.name
        : "";
    let navbarBgColors =
      props.selectedCommerce && props.selectedCommerce.colors
        ? props.selectedCommerce.colors
        : [];
    let navbarTextColor;
    let notifications = {};
    let hasParking;
    if (
      props.selectedCommerce &&
      props.selectedCommerce.status &&
      props.selectedCommerce.status === "blocked"
    ) {
      notifications.commerceBlocked = <BlockedCommerce key="commerceBlocked" />;
    }
    const permissions = props.permissions;
    // Set navbar text color
    if (navbarBgColors) {
      try {
        let bgColors = navbarBgColors.map((colorString) =>
          getRgbFromHex(colorString)
        );
        let averageColor = getAverageColor(bgColors);
        let luma = calculateLuma(averageColor);
        if (luma > 179) navbarTextColor = textualBlue;
      } catch (error) {
        console.error(error);
      }
    }
    this.state = {
      isSidebarOpen: false,
      workspaceStatus: "none",
      workspaceOptions: null,
      notificationStatus: "active",
      notificationTitle: "pagomovil",
      notificationMessage: "activo",
      adminId: null,
      officeNumbers,
      companyName,
      navbarBgColors,
      navbarTextColor,
      notifications,
      hasParking,
      permissions,
      popupMessages: null,
      popupMessagesType: "",
      showPopup: false,
    };
    this.previousHtml = null;
    // bind popup message functions
    this.throwPopupMessage = throwPopupMessage.bind(this);
    this.closePopup = closePopup.bind(this);
  }

  /*
   ** mpos on Mount:
   ** --> Get workspaces for superadmin
   ** --> Check for updates every 10mins
   */
  componentDidMount() {
    this._isMounted = true;
    const updateOfficeNumbers = async () => {
      try {
        const response = await getConstants();
        const res = await response.json();
        if (res.error) throw res.error;
        if (res.TRANSACTION_TAGS) {
          const transactionTags = res.TRANSACTION_TAGS.map((tag) => ({
            text: tag.name,
            value: tag.value,
            key: tag.value,
          }));
          this.props.addTransactionsTags(transactionTags);
        }
        if (res.support_info && this._isMounted) {
          let { office_numbers: officeNumbersRes } = res.support_info;
          this.props.addOfficeNumbers(officeNumbersRes);
        }
      } catch (error) {
        this.throwPopupMessage("error", error.description);
      }
    };
    updateOfficeNumbers();
    joinCommerceSocket(this.props.session.token, (socket_res) => {
      console.log(socket_res.message);
    });

    // --> Get workspaces for superadmin
    const { workspace, category } = this.props.session;
    if (
      category === "pagodirecto" ||
      !(this.state.permissions && this.state.permissions.taquilla)
    ) {
      this.setState({ workspaceStatus: "loading" });
      getBuildings()
        .then((res) => {
          if (!res.ok) {
            throw res;
          }
          return res.json();
        })
        .then((res) => {
          if (res.buildings && this._isMounted) {
            this.props.addBuildings(res.buildings);
            let buildings = res.buildings.map((building) => {
              return {
                key: building._id,
                text: building.commercial_name,
                value: building._id,
              };
            });
            // If a workspace has already been selected
            if (workspace) {
              let selectedBuilding = res.buildings.find(
                (elem) => elem._id === workspace
              );
              buildings.forEach((option) => {
                if (option.value === workspace) {
                  this.props.selectBuilding(selectedBuilding._id);
                  this.props.updateSession({ workspace: option.value });
                  this.props.addWorkspace(option.value);
                }
              });
              this.setState({
                workspaceOptions: buildings,
                workspaceStatus: "selected",
              });
            } else {
              this.props.selectBuilding(res.buildings[0]._id);
              this.props.addWorkspace(buildings[0].value);
              this.setState({
                workspaceOptions: buildings,
                workspaceStatus: "selected",
              });
            }
          }
        })
        // if an error occurs, reset the view and print the error
        .catch((err) => {
          // If error has a body, check the response
          if (typeof err.json === "function") {
            err.json().then((err_body) => {
              this.setState({ workspaceStatus: "none" });
              this.throwPopupMessage("error", err_body.error.description);
            });
          } else {
            this.setState({ workspaceStatus: "none" });
            this.throwPopupMessage("error", errors.GENERAL_ERR);
          }
        });
    }

    // --> Check for updates every 10mins
    this.checkForUpdates();
    setInterval(this.checkForUpdates, 1000 * 60 * 10);
  }

  componentWillUnmount() {
    //removeSocketListener();
    this._isMounted = false;
  }

  checkForUpdates = () => {
    fetch(window.location.origin, {
      method: "GET",
      headers: {
        "cache-control": "no-cache",
      },
    })
      .then((res) => {
        if (!res.ok) {
          throw res;
        } // If not OK throw error
        return res.text(); // If OK return html response
      })
      .then((html) => {
        if (!this.previousHtml) {
          this.previousHtml = html;
          return;
        }
        if (this.previousHtml !== html) {
          this.previousHtml = html;
          let notifications = {
            ...this.state.notifications,
            updateReady: (
              <UpdateReady key="updateReady" history={this.props.history} />
            ),
          };
          this.setState({ notifications });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleWorkspaceChange = (event, data) => {
    let buildingData = { building: data.value };
    data.options.forEach((option) => {
      if (option.value === data.value) {
        this.setState({ adminId: option.key }, () =>
          this.props.addForeignId(option.key)
        );
      }
    });
    this.setState(() => {
      this.props.selectBuilding(buildingData.building);
      this.props.addWorkspace(buildingData.building);
    });
  };

  //No se esta usando
  debouncedWorkspacePatch = debounce((workspaceData) => {
    let promise = patchWorkspace(workspaceData);
    this.handleWorkspacePromise(promise, workspaceData);
  }, debouncer_timeout);

  //No se esta usando
  handleWorkspacePromise = (promise, workspaceData) => {
    promise
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((res) => {
        if (res.token) {
          this.setState({ workspaceStatus: "selected" }, () => {
            // callback for updating redux-store after updating local state
            this.props.updateSession({
              workspace: workspaceData.building,
              token: res.token,
            });
          });
          // reload /mpos to join sockets
          this.props.history.push("/mpos");
        }
      })
      // if an error occurs, set status 'none' for workspace
      .catch((err) => {
        // If error has a body, check the response
        if (typeof err.json === "function") {
          err.json().then((err_body) => {
            this.setState({ workspaceStatus: "none" });
            this.throwPopupMessage("error", err_body.error.description);
          });
        } else {
          this.setState({ workspaceStatus: "none" });
          this.throwPopupMessage("error", errors.GENERAL_ERR);
        }
      });
  };

  setSidebarOpen = (open) => {
    this.setState({ isSidebarOpen: open });
  };

  getNavbarTextAndColors = (route) => {
    /*const isParking =
      this.props.selectedCommerce &&
      this.props.selectedCommerce.category &&
      this.props.selectedCommerce.category === "estacionamiento";
    const withParking =
      this.props.selectedCommerce &&
      this.props.selectedCommerce.hasOwnProperty("parking");
    let bgColors =
      isParking ||
      withParking ||
      (this.state.permissions && this.state.permissions.taquilla)
        ? [posDirectoBlue, posDirectoBlue]
        : [bluePD, bluePD];*/

    let bgColors = [posDirectoBlue, posDirectoBlue];
    switch (route) {
      case "/mpos/dashboard":
        return {
          title: mposTexts.dashboardTitle,
          bgColors,
          textColor: this.state.navbarTextColor,
        };
      case "/mpos/tickets/validate":
        return {
          title: mposTexts.ticketsValidateTitle,
          bgColors,
          textColor: "white",
        };

      case "/mpos/tickets/history":
        return {
          title: mposTexts.ticketsHistoryTitle,
          bgColors,
          textColor: this.state.navbarTextColor,
        };

      case "/mpos/tickets/digitize":
        return {
          title: mposTexts.ticketsDigitizeTitle,
          bgColors,
          textColor: "white",
        };
      case "/mpos/tickets/overtime-transactions":
        return {
          title: mposTexts.ticketsOvertimedTitle,
          bgColors,
          textColor: "white",
        };
      case "/mpos/commerce/movements":
        let title = mposTexts.commerceMovements;
        return {
          title,
          bgColors,
          textColor: "white",
        };
      case "/mpos/commerce/wallet":
        return {
          title: mposTexts.wallet,
          bgColors,
          textColor: "white",
        };
      case "/mpos/commerce/users":
        return {
          title: mposTexts.commerceUsers,
          bgColors,
          textColor: "white",
        };
      case "/mpos/withdraw/history":
        return {
          title: mposTexts.withdrawHistoryTitle,
          bgColors,
          textColor: "white",
        };
      case "/mpos/parking/settings":
        return {
          title: mposTexts.buildingSettingsTitle,
          bgColors,
          textColor: "white",
        };
      case "/mpos/parking/pdfee":
        return {
          title: mposTexts.buildingPdfeeTitle,
          bgColors,
          textColor: "white",
        };
      case "/mpos/commerces":
        return {
          title: mposTexts.commercesInvoicesTitle,
          bgColors,
          //bgColors: this.state.navbarBgColors,
          textColor: this.state.navbarTextColor,
        };
      case "/mpos/commerce/digitize":
        return {
          title: "PagoDirecto",
          bgColors: [posDirectoBlue, posDirectoBlue],
          hasExtra: true,
          extraText: "para Comercios",
          //bgColors: this.state.navbarBgColors,
          textColor: "white",
        };
      case "/mpos/invoices/history":
        return {
          title: mposTexts.invoicesHistoryTitle,
          bgColors: [posDirectoBlue, posDirectoBlue],
          //bgColors: this.state.navbarBgColors,
          textColor: this.state.navbarTextColor,
        };
      case "/mpos/recharges/validate":
        return {
          title: mposTexts.rechargesValidateTitle,
          bgColors,
          //bgColors: [green, green],
          textColor: "white",
        };
      case "/mpos/recharges/recharge":
        return {
          title: mposTexts.rechargesRechargeTitle,
          bgColors,
          //bgColors: [blue, blue],
          textColor: "white",
        };
      case "/mpos/buildings/settings":
        return {
          title: mposTexts.buildingSettingsTitle,
          bgColors,
          //bgColors: this.state.navbarBgColors,
          textColor: this.state.navbarTextColor,
        };
      case "/mpos/building/commerces":
        return {
          title: mposTexts.buildingCommercesTitle,
          bgColors,
          //bgColors: this.state.navbarBgColors,
          textColor: this.state.navbarTextColor,
        };
      case "/mpos/users":
        return {
          title: mposTexts.usersAdminTitle,
          bgColors,
          //bgColors: this.state.navbarBgColors,
          textColor: this.state.navbarTextColor,
        };
      case "/mpos/users/extensions":
        return {
          title: mposTexts.usersExtensionsTitle,
          bgColors,
          //bgColors: this.state.navbarBgColors,
          textColor: this.state.navbarTextColor,
        };
      case "/mpos/users/movements/mobile-paid":
        return {
          title: mposTexts.usersMovementsMobilePaidTitle,
          bgColors,
          //bgColors: this.state.navbarBgColors,
          textColor: this.state.navbarTextColor,
        };
      case "/mpos/users/movements/transfers":
        return {
          title: mposTexts.usersMovementsTransfersTitle,
          bgColors,
          //bgColors: this.state.navbarBgColors,
          textColor: this.state.navbarTextColor,
        };
      case "/mpos/users/register":
        return {
          title: mposTexts.usersAdminTitle,
          bgColors,
          //bgColors: this.state.navbarBgColors,
          textColor: this.state.navbarTextColor,
        };
      case "/mpos/reports":
        return {
          title: mposTexts.usersReportsTitle,
          bgColors,
          //bgColors: this.state.navbarBgColors,
          textColor: this.state.navbarTextColor,
        };
      case "/mpos/users/logs":
        return {
          title: mposTexts.usersLogsTitle,
          bgColors,
          //bgColors: this.state.navbarBgColors,
          textColor: this.state.navbarTextColor,
        };
      case "/mpos/users/notifications":
        return {
          title: mposTexts.usersNotificationsTitle,
          bgColors,
          //bgColors: this.state.navbarBgColors,
          textColor: this.state.navbarTextColor,
        };
      case "/mpos/users/notifications/discount-codes":
        return {
          title: mposTexts.usersNotificationsDiscountCodesTitle,
          bgColors,
          //bgColors: this.state.navbarBgColors,
          textColor: this.state.navbarTextColor,
        };
      case "/mpos/users/notifications/massive":
        return {
          title: mposTexts.usersNotificationsTitle,
          bgColors,
          //bgColors: this.state.navbarBgColors,
          textColor: this.state.navbarTextColor,
        };
      case "/mpos/boxoffice":
        return {
          title: mposTexts.boxOffice,
          bgColors,
          //bgColors: this.state.navbarBgColors,
          textColor: this.state.navbarTextColor,
        };
      case "/mpos/resgister/boxoffice":
        return {
          title: mposTexts.registerBoxoffice,
          bgColors,
          //bgColors: this.state.navbarBgColors,
          textColor: this.state.navbarTextColor,
        };
      case "/mpos/support":
        return {
          title: mposTexts.support,
          bgColors,
          //bgColors: this.state.navbarBgColors,
          textColor: this.state.navbarTextColor,
        };
      case "/mpos/amount-history":
        return {
          title: mposTexts.amountHistory,
          bgColors,
          textColor: this.state.navbarTextColor,
        };
      case "/mpos/monthly-premium/activate":
        return {
          title: mposTexts.monthlyPremiumActivate,
          bgColors,
          //bgColors: this.state.navbarBgColors,
          textColor: this.state.navbarTextColor,
        };
      case "/mpos/wallet/transfer":
        return {
          title: mposTexts.transfer,
          bgColors,
          textColor: this.state.navbarTextColor,
        };
      case "/mpos/wallet/transfer-history":
        return {
          title: mposTexts.transferHistory,
          bgColors,
          textColor: this.state.navbarTextColor,
        };
      case "/mpos/wallet/today-movements":
        return {
          title: mposTexts.todayMovements,
          bgColors,
          textColor: this.state.navbarTextColor,
        };
      case "/mpos/monthly-premium/invoices":
        return {
          title: mposTexts.monthlyPremiumInvoices,
          bgColors,
          //bgColors: this.state.navbarBgColors,
          textColor: this.state.navbarTextColor,
        };
      case "/mpos/monthly-premium/history":
        return {
          title: mposTexts.monthlyPremiumHistory,
          bgColors,
          //bgColors: this.state.navbarBgColors,
          textColor: this.state.navbarTextColor,
        };
      case "/mpos/superadmin/bills":
        return {
          title: mposTexts.superadminBills,
          bgColors,
          //bgColors: this.state.navbarBgColors,
          textColor: this.state.navbarTextColor,
        };
      case "/mpos/superadmin/bills/history":
        return {
          title: mposTexts.superadminBillsHistory,
          bgColors,
          //bgColors: this.state.navbarBgColors,
          textColor: this.state.navbarTextColor,
        };
      case "/mpos/wallet/accounts":
        return {
          title: mposTexts.superadminAccounts,
          bgColors,
          //bgColors: this.state.navbarBgColors,
          textColor: this.state.navbarTextColor,
        };
      default:
        return "";
    }
  };

  render() {
    const route = this.props.location.pathname;
    const { title, bgColors, textColor, hasExtra, extraText } =
      this.getNavbarTextAndColors(route);
    const sidebarContent = (
      <SidebarContent
        role={this.props.session.role}
        category={this.props.session.category}
        setSidebarOpen={this.setSidebarOpen}
        username={this.props.session.username}
        companyName={this.state.companyName}
        selectedCommerce={this.props.selectedCommerce}
        workspaceStatus={this.state.workspaceStatus}
        workspaceOptions={this.state.workspaceOptions}
        workspace={this.props.session.workspace}
        handleWorkspaceChange={this.handleWorkspaceChange}
        throwPopupMessage={this.throwPopupMessage}
        hasParking={this.state.hasParking}
        permissions={this.props.permissions}
        officeNumbers={this.props.selectedOfficeNumbers}
        routes={this.props.session.routes}
      />
    );

    return (
      <Sidebar
        sidebar={sidebarContent}
        sidebarClassName="no-print"
        open={this.state.isSidebarOpen}
        onSetOpen={this.setSidebarOpen}
        styles={{
          // make sidebar the top-most element and remove scroll bar
          sidebar: { zIndex: 2000, overflowY: "hidden" },
          //Cover all childs with a gray layer
          overlay: { zIndex: 4 },
          // remove scroll bar when is not needed for mpos views
          content: { overflowY: "auto" },
        }}
      >
        <PopupMessage
          messageType={this.state.popupMessagesType}
          showPopup={this.state.showPopup}
          messages={this.state.popupMessages}
          closePopup={this.closePopup}
        />

        <Navbar
          title={title}
          setSidebarOpen={this.setSidebarOpen}
          category={this.props.session.category}
          status={this.state.notificationStatus}
          notificationTitle={this.state.notificationTitle}
          notificationMessage={this.state.notificationMessage}
          permissions={this.state.permissions}
          hasExtra={hasExtra}
          extraText={extraText}
          bgColors={bgColors}
          textColor={textColor}
        />

        <CriticalNotifications notifications={this.state.notifications} />

        <MPosContent>
          <Route
            exact
            path="/mpos/dashboard"
            render={(props) => (
              <MPosDashboard
                {...props}
                token={this.props.session.token}
                category={this.props.session.category}
              />
            )}
          />
          <Route
            exact
            path="/mpos/tickets/validate"
            render={(props) => (
              <MPosTicketsValidate
                {...props}
                workspace={this.props.session.workspace}
                token={this.props.session.token}
              />
            )}
          />
          <Route
            exact
            path="/mpos/tickets/history"
            render={(props) => (
              <MPosTicketsHistory
                {...props}
                token={this.props.session.token}
                permissions={this.props.session.permissions}
                workspace={this.props.session.workspace}
                category={this.props.session.category}
              />
            )}
          />
          <Route
            exact
            path="/mpos/tickets/digitize"
            render={(props) => (
              <MPosTicketsDigitize
                {...props}
                companyName={this.state.companyName}
                sessionOf={this.props.session.username}
                token={this.props.session.token}
                workspace={this.props.session.workspace}
              />
            )}
          />
          <Route
            exact
            path="/mpos/tickets/overtime-transactions"
            render={(props) => <MposOvertimeTicketsTransactions {...props} />}
          />
          <Route
            exact
            path="/mpos/commerce/movements"
            render={(props) => (
              <MPosCommerceMovements
                {...props}
                permissions={this.props.session.permissions}
                category={this.props.session.category}
                token={this.props.session.token}
              />
            )}
          />
          <Route
            exact
            path="/mpos/commerce/users"
            render={(props) => (
              <MPosCommerceUsers
                {...props}
                category={this.props.session.category}
                token={this.props.session.token}
              />
            )}
          />
          <Route
            exact
            path="/mpos/commerce/wallet"
            render={(props) => (
              <MPosCommerceWallet
                {...props}
                role={this.props.session.role}
                permissions={this.props.session.permissions}
              />
            )}
          />
          <Route
            exact
            path="/mpos/commerce/digitize"
            render={(props) => (
              <MPosCommerceDigitize
                {...props}
                token={this.props.session.token}
                permissions={this.props.session.permissions}
              />
            )}
          />
          <Route
            exact
            path="/mpos/withdraw/history"
            render={(props) => (
              <MposWithdrawHistory
                {...props}
                userId={
                  this.props.session.category === "pagodirecto"
                    ? this.state.adminId
                    : null
                }
                commerce={this.props.selectedCommerce}
              />
            )}
          />
          <Route
            exact
            path="/mpos/parking/settings"
            render={(props) => <MposParkingSettings {...props} />}
          />

          <Route
            exact
            path="/mpos/commerces"
            render={(props) => (
              <MposCommercesAdmin
                {...props}
                category={this.props.session.category}
                commerce={this.props.selectedCommerce}
              />
            )}
          />
          <Route
            exact
            path="/mpos/invoices/history"
            render={(props) => (
              <MPosInvoicesHistory
                {...props}
                permissions={this.props.session.permissions}
                role={this.props.session.role}
                category={this.props.session.category}
              />
            )}
          />
          <Route
            exact
            path="/mpos/recharges/validate"
            render={(props) => (
              <MposRechargesValidate
                {...props}
                permissions={this.props.session.permissions}
                role={this.props.session.role}
                category={this.props.session.category}
              />
            )}
          />
          <Route
            exact
            path="/mpos/recharges/recharge"
            render={(props) => (
              <MposRechargesRecharge
                {...props}
                companyName={this.state.companyName}
              />
            )}
          />
          <Route
            exact
            path="/mpos/buildings/settings"
            render={(props) => (
              <MPosBuildingSettings
                {...props}
                workspace={this.props.session.workspace}
                building={this.props.selectedBuilding}
              />
            )}
          />
          <Route
            exact
            path="/mpos/building/commerces"
            render={(props) => <MposBuildingCommerces {...props} />}
          />
          <Route
            exact
            path="/mpos/parking/pdfee"
            render={(props) => <MPosBuildingPdfee {...props} />}
          />
          <Route
            exact
            path="/mpos/users"
            render={(props) => <MPosUsersAdmin {...props} />}
          />
          <Route
            exact
            path="/mpos/users/register"
            render={(props) => <MposUserRegister {...props} />}
          />
          <Route
            exact
            path="/mpos/users/logs"
            render={(props) => <MPosUsersLogs {...props} />}
          />
          <Route
            exact
            path="/mpos/users/notifications"
            render={(props) => <MPosUsersNotifications {...props} />}
          />
          <Route
            exact
            path="/mpos/users/notifications/discount-codes"
            render={(props) => (
              <MPosUsersNotificationsDiscountCodes {...props} />
            )}
          />
          <Route
            exact
            path="/mpos/users/notifications/massive"
            render={(props) => <MPosUsersNotificationsMassive {...props} />}
          />
          <Route
            exact
            path="/mpos/reports"
            render={(props) => <MPosReports {...props} />}
          />
          <Route
            exact
            path="/mpos/boxoffice"
            render={(props) => (
              <MPosBoxOffice {...props} token={this.props.session.token} />
            )}
          />
          <Route
            exact
            path="/mpos/resgister/boxoffice"
            render={(props) => (
              <MPosSignupBoxoffice
                {...props}
                token={this.props.session.token}
              />
            )}
          />
          <Route
            exact
            path="/mpos/support"
            render={(props) => (
              <MPosSupport {...props} token={this.props.session.token} />
            )}
          />
          <Route
            exact
            path="/mpos/amount-history"
            render={(props) => (
              <MposAmountHistory {...props} token={this.props.session.token} />
            )}
          />
          <Route
            exact
            path="/mpos/wallet/transfer"
            render={(props) => (
              <MposAdminTransfer {...props} token={this.props.session.token} />
            )}
          />
          <Route
            exact
            path="/mpos/wallet/transfer-history"
            render={(props) => (
              <MposWalletTransferHistory
                {...props}
                token={this.props.session.token}
              />
            )}
          />
          <Route
            exact
            path="/mpos/wallet/today-movements"
            render={(props) => (
              <MPosWalletTodayMovements
                {...props}
                token={this.props.session.token}
              />
            )}
          />
          <Route
            exact
            path="/mpos/users/extensions"
            render={(props) => (
              <MPosUsersExtensions
                {...props}
                token={this.props.session.token}
              />
            )}
          />
          <Route
            exact
            path="/mpos/users/movements/mobile-paid"
            render={(props) => (
              <MPosUsersMovementsMobilePaid
                {...props}
                permissions={this.props.session.permissions}
                role={this.props.session.role}
                category={this.props.session.category}
              />
            )}
          />
          <Route
            exact
            path="/mpos/users/movements/transfers"
            render={(props) => (
              <MPosUsersMovementsTransfers
                {...props}
                permissions={this.props.session.permissions}
                role={this.props.session.role}
                category={this.props.session.category}
              />
            )}
          />
          <Route
            exact
            path="/mpos/monthly-premium/activate"
            render={(props) => (
              <MposMonthlyPremiumActivate
                {...props}
                token={this.props.session.token}
              />
            )}
          />
          <Route
            exact
            path="/mpos/monthly-premium/invoices"
            render={(props) => (
              <MposMonthlyPremiumInvoices
                {...props}
                token={this.props.session.token}
              />
            )}
          />
          <Route
            exact
            path="/mpos/monthly-premium/history"
            render={(props) => (
              <MposMonthlyPremiumHistory
                {...props}
                token={this.props.session.token}
              />
            )}
          />
          <Route
            exact
            path="/mpos/superadmin/bills"
            render={(props) => (
              <MPosSuperadminBills
                {...props}
                token={this.props.session.token}
              />
            )}
          />
          <Route
            exact
            path="/mpos/superadmin/bills/history"
            render={(props) => (
              <MPosSuperadminBillHistory
                {...props}
                token={this.props.session.token}
              />
            )}
          />
          <Route
            exact
            path="/mpos/wallet/accounts"
            render={(props) => (
              <MposSuperadminAccounts
                {...props}
                token={this.props.session.token}
              />
            )}
          />
        </MPosContent>
      </Sidebar>
    );
  }
}

const mapStateToProps = (state) => {
  let commerces = getCommercesFromRedux(state);
  let selectedCommerce = getSelectedCommerce(state);
  let buildings = getBuildingsFromRedux(state);
  let selectedBuilding = getSelectedBuilding(state);
  let session = getSession(state);
  let getBuildingById = getBuildingByIdFromRedux(state);
  let permissions = getPermissions(state);
  let selectedOfficeNumbers = getOfficeNumbers(state);
  return {
    commerces,
    selectedCommerce,
    buildings,
    getBuildingById,
    selectedBuilding,
    selectedOfficeNumbers,
    session,
    permissions,
  };
};

const mapDispatchToProps = {
  addColors,
  addBuildings,
  addOfficeNumbers,
  selectBuilding,
  addWorkspace,
  addForeignId,
  updateSession,
  clearReduxStore,
  addTransactionsTags,
};

export default connect(mapStateToProps, mapDispatchToProps)(MPos);
