import React, { useContext } from "react";
import { Button } from "components/buttons";
import { Checkbox } from "semantic-ui-react";
import { general as texts } from "assets/strings/texts";
import { ActivateMPContext } from "../../context";
import { Dropdown } from "components/dropdown";

const options = [
  { key: "marketing", text: "Mercadeo", value: "mercadeo" },
  { key: "office", text: "Oficina", value: "oficina" },
  { key: "other", text: "Otro", value: "otro" },
];

const ActivateConfirmationModel = ({ className }) => {
  const {
    closePopup: cancel,
    handleActivateFromSuperadmin: confirm,
    changeState,
    state,
  } = useContext(ActivateMPContext);

  const onChange = (e, data) => {
    changeState({ generate_transaction: data.checked });
  };
  const onOptionsChange = (e, data) => {
    changeState({ why_no_transaction: data.value });
  };
  return (
    <div className={className}>
      <Checkbox
        label="Generar transacción"
        onChange={onChange}
        checked={state.generate_transaction}
      />
      {!state.generate_transaction && (
        <Dropdown
          onChange={onOptionsChange}
          options={options}
          placeholder="¿Por qué no generar transacción?"
          selection
        />
      )}
      <Button
        color="blue"
        onClick={confirm}
        disabled={!state.generate_transaction && !state.why_no_transaction}
      >
        {texts.accept}
      </Button>
      <Button color="grey" onClick={cancel}>
        Cancelar
      </Button>
    </div>
  );
};

export { ActivateConfirmationModel };
