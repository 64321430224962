import { ReportsGeneratorModel } from "./model";
import styled from "styled-components";

export const ReportsGenerator = styled(ReportsGeneratorModel)`
  #root & {
    > .title {
      margin: 1rem 0;
    }

    > .report-selector {
      width: 100%;
      > .selection {
        margin: 1rem 0;
        width: 100%;
      }
    }
  }
`;
