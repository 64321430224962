import React, { useEffect, useState, useContext } from "react";
import moment from "moment-timezone";
import { Panel } from "components/panel";
import { Button } from "components/buttons";
import { Loader } from "components/loader";
import { Dropdown } from "components/dropdown";
import { EntitiesList, Pagination } from "components/entities-list";
import DatePicker from "react-datepicker";
import { useDates } from "hooks/use-inputs";
import { useDropdown } from "hooks/use-dropdown";
import { getBuildings, getUserLogs } from "services/api";
import { LogHeader, LogListItem } from "./index";
import { LogDetail } from "../log-detail";
import { UsersLogsContext } from "../../context";

const LogsPanelModel = ({ className }) => {
    const { state, viewType, handleBack, throwPopupMessage } =
        useContext(UsersLogsContext);

    let { selectedUser: user, selectedMtd: mtd } = state;
    const phoneView = viewType === "phone";
    const [loading, setLoading] = useState(false);
    const {
        fromDate: dateFrom,
        endDate: dateTo,
        handleFromDate: handleDateFrom,
        handleEndDate: handleDateTo,
    } = useDates(null, null);
    const {
        bind: { value: building, onChange: handleBuildingChange },
    } = useDropdown("");
    const {
        setValue: setAccessName,
        bind: { value: accessName, onChange: handleAccessNameChange },
    } = useDropdown("");
    const [buildingOptions, setBuildingOptions] = useState([]);
    const [buildingList, setBuildingList] = useState([]);
    const [accessNameOptions, setAccessOptions] = useState([]);
    const [logs, setLogs] = useState([]);
    const [submit, setSubmit] = useState(false);
    const [pagination, setPagination] = useState({
        currentPage: 1,
        skip: 0,
        limit: 11,
        nextPageExist: false,
    });

    const logsPerPage = 10;

    useEffect(() => {
        //rerender when user is selected only in desktop view
        if (user && viewType !== "phone") setSubmit(true);
    }, [user]);
    useEffect(() => {
        //rerender when user is selected only in desktop view
        if (mtd && viewType !== "phone") setSubmit(true);
    }, [mtd]);

    useEffect(() => {
        let isCancelled = false;
        const confBuildingOptions = async () => {
            if (!isCancelled) {
                try {
                    setLoading(true);
                    const response = await getBuildings();
                    const res = await response.json();
                    if (res.error) throw res.error;
                    if (res.buildings) {
                        const buildings = res.buildings;
                        setBuildingList(buildings);
                        let options = [
                            {
                                key: "all",
                                text: "Todos",
                                value: "",
                            },
                        ];
                        buildings.map((building, index) => {
                            options.push({
                                key: index,
                                text: building.name,
                                value: building._id,
                            });
                            return null;
                        });
                        setLoading(false);
                        setBuildingOptions(options);
                        if (viewType === "phone") setSubmit(true);
                    }
                } catch (error) {
                    throwPopupMessage("error", error.description);
                    setLoading(false);
                }
            }
        };
        confBuildingOptions();
        return () => {
            isCancelled = true;
        };
    }, []);

    useEffect(() => {
        let options = [
            {
                key: "all",
                text: "Todos",
                value: "",
            },
        ];
        if (building !== "") {
            const buildingSelected = buildingList.find(
                (elem) => elem._id === building
            );
            const accessList = buildingSelected.accesses;
            accessList.map((access) => {
                options.push({
                    key: access.name,
                    text: access.name,
                    value: access.name,
                });
                return null;
            });
        }
        setAccessOptions(options);
        setAccessName("");
    }, [building]);

    useEffect(() => {
        const searchLogs = async () => {
            try {
                setLoading(true);
                let body = {
                    username: user,
                    moduleName:
                        state.searchType === "by_user" ? accessName : mtd,
                    dateFrom,
                    dateTo,
                    skip: pagination.skip,
                    limit: pagination.limit,
                };
                const response = await getUserLogs(body);
                const res = await response.json();
                if (res.error) throw res.error;
                if (res.logs) {
                    let logs_res = res.logs;
                    let nextPageExist = false;
                    if (logs_res.length > logsPerPage) {
                        nextPageExist = true;
                        logs_res.pop();
                    }
                    setPagination({ ...pagination, nextPageExist });
                    setLogs(logs_res);
                }
                setLoading(false);
            } catch (error) {
                throwPopupMessage("error", error.description);
                setLoading(false);
            }
        };
        if (submit) {
            searchLogs();
            setSubmit(false);
        }
    }, [submit]);

    const handleLogSelection = (event) => {
        event.preventDefault();
        let index = event.currentTarget.id;
        let log = logs[index];
        throwPopupMessage(
            "",
            <LogDetail
                log={log}
                buildings={buildingList}
                category={state.category}
            />
        );
    };

    const handlePageUp = (event) => {
        event.preventDefault();
        setPagination({
            currentPage: pagination.currentPage + 1,
            skip: pagination.currentPage * logsPerPage,
            limit: logsPerPage + 1,
            nextPageExist: true,
        });
        setSubmit(true);
    };
    const handlePageDown = (event) => {
        event.preventDefault();
        setPagination({
            currentPage: pagination.currentPage - 1,
            skip: (pagination.currentPage - 2) * logsPerPage,
            limit: logsPerPage + 1,
            nextPageExist: true,
        });
        setSubmit(true);
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        setPagination({
            currentPage: 1,
            skip: 0,
            limit: 11,
            nextPageExist: false,
        });
        setSubmit(true);
    };
    const handleReload = (event) => {
        event.preventDefault();
        setPagination({
            currentPage: 1,
            skip: 0,
            limit: logsPerPage + 1,
            nextPageExist: true,
        });
        setSubmit(true);
    };

    if ((!user && !mtd) || state.viewState === "search_user") {
        return null;
    } else {
        return (
            <div className={className}>
                <Panel className="panel-form">
                    {loading && <Loader />}
                    {!loading && (
                        <form onSubmit={handleSubmit} className="form-segment">
                            <div className="date-pickers">
                                <DatePicker
                                    className="date-picker"
                                    placeholderText="Desde"
                                    dateFormat="DD / MM / YYYY"
                                    showMonthDropdown
                                    showYearDropdown
                                    todayButton={"Hoy"}
                                    isClearable
                                    withPortal={phoneView}
                                    selected={dateFrom}
                                    maxDate={
                                        dateTo
                                            ? moment(dateTo).subtract(
                                                  23,
                                                  "hours"
                                              )
                                            : moment()
                                    }
                                    onChange={handleDateFrom}
                                />
                                /
                                <DatePicker
                                    className="date-picker"
                                    placeholderText="Hasta"
                                    dateFormat="DD / MM / YYYY"
                                    showMonthDropdown
                                    showYearDropdown
                                    todayButton={"Hoy"}
                                    isClearable
                                    withPortal={phoneView}
                                    selected={
                                        dateTo
                                            ? moment(dateTo).subtract(
                                                  23,
                                                  "hours"
                                              )
                                            : null
                                    }
                                    minDate={
                                        dateFrom
                                            ? moment(dateFrom).add(23, "hours")
                                            : null
                                    }
                                    maxDate={moment()}
                                    onChange={handleDateTo}
                                />
                            </div>
                            {state.searchType === "by_user" && (
                                <div className="dropdown-items">
                                    <Dropdown
                                        fluid
                                        selection
                                        placeholder={"Edificio"}
                                        wrapSelection={true}
                                        options={buildingOptions}
                                        value={building}
                                        disabled={loading}
                                        onChange={handleBuildingChange}
                                        textalign="center"
                                        className={
                                            phoneView
                                                ? "drop-height"
                                                : "drop-width"
                                        }
                                    />
                                    <Dropdown
                                        fluid
                                        selection
                                        placeholder={"Acceso"}
                                        wrapSelection={true}
                                        options={accessNameOptions}
                                        value={accessName}
                                        disabled={loading}
                                        onChange={handleAccessNameChange}
                                        textalign="center"
                                        className={
                                            phoneView
                                                ? "drop-height"
                                                : "drop-width"
                                        }
                                    />
                                </div>
                            )}
                            <Button
                                color="grey"
                                margin="1rem 0 0 0"
                                disabled={loading}
                            >
                                Buscar
                            </Button>
                        </form>
                    )}
                </Panel>
                <Panel className="panel-list">
                    {loading && <Loader />}
                    {!loading && (
                        <div>
                            <Pagination
                                padding="0.65rem 0 0.65rem 0"
                                nextPageExist={pagination.nextPageExist}
                                currentPage={pagination.currentPage}
                                handlePageUp={handlePageUp}
                                handlePageDown={handlePageDown}
                                includeReload={true}
                                handleReload={handleReload}
                            />
                            {!phoneView && (
                                <LogHeader category={state.category} />
                            )}
                            <EntitiesList
                                height="calc(60vh - 11rem)"
                                entities={logs}
                                formattingFunction={formatLogsList}
                                selectionHandler={handleLogSelection}
                                additionalArgs={[
                                    phoneView,
                                    loading,
                                    state.category,
                                ]}
                            />
                        </div>
                    )}
                </Panel>
                {phoneView && !loading && (
                    <Button
                        width="40%"
                        color="grey"
                        onClick={handleBack}
                        style={{ marginLeft: "30%" }}
                    >
                        Volver
                    </Button>
                )}
            </div>
        );
    }
};

const LogListItemModel = ({
    log,
    phoneView,
    handleSelection,
    index,
    status,
    category,
    className,
}) => {
    const isParking = category === "estacionamiento";
    let user = log && log.username ? log.username : "N/A";
    const hasBuilding = log && log.building_name;
    const hasModule = log && log.building_access;
    let accessName = hasModule ? log.building_access.name : "N/A";
    let buildingName = hasBuilding ? log.building_name : "N/A";

    let trackIndex =
        hasModule && log.building_access.track_index
            ? log.building_access.track_index
            : "N/A";

    let date = log && log.date ? moment(log.date).format("D MMMM YYYY") : "N/A";
    let hour =
        log && log.date
            ? moment(log.date).add(4, "hour").format("h:mm:ss a")
            : "N/A";

    let connectionType =
        log &&
        log.customer &&
        log.customer.parking_configuration &&
        log.customer.parking_configuration.enable_new_connection_method
            ? " Método actual"
            : " Método antiguo";
    let appVersion = log && log.app_version ? log.app_version : "No disponible";

    return phoneView ? (
        <dd
            className={className}
            onClick={handleSelection}
            id={index}
            status={status}
        >
            <div className="column">
                <p className="user">
                    {" "}
                    <b>Usuario:</b> @{user}
                </p>
                <p className="access">
                    <b>Accesso:</b> {accessName}{" "}
                    {trackIndex && !isParking && (
                        <span>{` -- TRACK: ${trackIndex}`}</span>
                    )}
                </p>
                {!isParking && (
                    <p className="building">
                        {" "}
                        <b>Edificio:</b>
                        {buildingName}
                    </p>
                )}
                <div className="date">
                    <b>Fecha:</b> {date}
                </div>
                <div className="hour">
                    <b>Hora:</b> {hour}
                </div>
                {!isParking && (
                    <div className="conn-type">
                        <p>
                            {" "}
                            <b>Tipo de conexión:</b>
                            {connectionType}
                        </p>
                    </div>
                )}
                {!isParking && (
                    <div className="app-version">
                        <p>
                            <b>APP:</b> {appVersion}
                        </p>
                    </div>
                )}
            </div>
        </dd>
    ) : (
        <dd
            className={className}
            onClick={handleSelection}
            id={index}
            status={status}
        >
            <div className={`user${isParking ? " thirty" : ""}`}>
                <p className="username">@{user}</p>
                {!isParking && <p className="status">{log.status}</p>}
            </div>
            <p className={`access${isParking ? " thirty" : ""}`}>
                {accessName} <br />
                {trackIndex && !isParking && (
                    <span>{`TRACK: ${trackIndex}`}</span>
                )}
            </p>
            {!isParking && (
                <div className="building">
                    <p className="building">{buildingName}</p>
                </div>
            )}
            {!isParking && (
                <div className="connection">
                    <p className="version">{`APP:${appVersion}`}</p>
                    <p className="type">{`Tipo de conexión:${connectionType}`}</p>
                </div>
            )}

            <div className={`time${isParking ? " thirty" : ""}`}>
                <p className="date">{date}</p>
                <p className="hour">{hour}</p>
            </div>
        </dd>
    );
};
const LogHeaderModel = ({ category, className }) => {
    const isParking = category === "estacionamiento";
    return (
        <dd className={className}>
            <p className={`${isParking ? "col-parking thirty" : "col twenty"}`}>
                usuario
            </p>
            <p className={`${isParking ? "col-parking thirty" : "col twenty"}`}>
                acceso
            </p>
            {!isParking && <p className={`col twenty`}>Edificio</p>}
            {!isParking && <p className={`col twenty`}>Conexión</p>}
            <p className={`${isParking ? "col-parking thirty" : "col twenty"}`}>
                Fecha
            </p>
        </dd>
    );
};

function formatLogsList(
    logs,
    viewState,
    handleLogSelection,
    phoneView,
    loading,
    category
) {
    // Declaramos el array que devolverá la función
    let logList = [];
    if (loading) {
        return <div className="ui active loader"></div>;
    }
    if (!logs.length) {
        return (
            <div
                className="not-found"
                style={{ textAlign: "center", marginTop: "3rem" }}
            >
                No se han encontrado logs
            </div>
        );
    }
    if (logs.length) {
        logs.forEach((log, index) => {
            let message_type = "error";
            if (log.status) {
                let messageArray = log.status.split("-");
                if (messageArray.length > 1) {
                    message_type = messageArray[0].includes("success")
                        ? "success"
                        : "error";
                }
            }
            logList.push(
                <LogListItem
                    log={log}
                    phoneView={phoneView}
                    handleSelection={handleLogSelection}
                    index={index}
                    key={log._id}
                    status={message_type}
                    category={category}
                />
            );
        });

        return logList;
    }
}

export { LogsPanelModel, LogHeaderModel, LogListItemModel };
