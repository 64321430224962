import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { errors } from "assets/strings/texts";
import SigninView from "./components/signin-view";
import { commercesSignin, acceptTerms } from "services/api";
import { throwPopupMessage, closePopup } from "components/popup-message";
import {
  handleLoginChange,
  handlePasswordChange,
} from "components/register-user-form-inputs";
import { addBuildings, selectBuilding } from "redux/actions/buildings";
import { addCommerces, selectCommerce } from "redux/actions/commerces";
import {
  addToken,
  addUser,
  addWorkspace,
  addForeignId,
} from "redux/actions/session";

class Signin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      agreeWithTerms: true,
      formData: {
        login: "",
        password: "",
        session: `${Math.floor(Math.random() * 10000)}`,
        fcm_token: "",
      },
      popupMessages: null,
      popupMessagesType: "",
      showPopup: false,
      redirect: null,
    };
    this.initialState = { ...this.state };
    // Bind handler functions
    this.handleLoginChange = handleLoginChange.bind(this);
    this.handlePasswordChange = handlePasswordChange.bind(this);
    // Bind PopupMessage functions
    this.throwPopupMessage = throwPopupMessage.bind(this);
    this.closePopup = closePopup.bind(this);
  }

  componentDidMount() {
    if (
      this.props.location.state &&
      this.props.location.state.formAlertMessage
    ) {
      this.throwPopupMessage(
        this.props.location.state.formAlertMessageType,
        this.props.location.state.formAlertMessage
      );
      // then remove states from the path
      this.props.history.replace({
        pathname: this.props.location.pathname,
        state: {},
      });
    }
  }

  //response is the apiCall promise
  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    let promise = commercesSignin(this.state.formData);
    this.handleSigninPromise(promise);
  };

  handleTerms = () => {
    this.setState({ loading: true });
    acceptTerms()
      .then((res) => {
        if (!res.ok) {
          throw res;
        } else {
          let promise = commercesSignin(this.state.formData);
          this.handleSigninPromise(promise);
        }
      })
      // if an error occurs, reset the view and print the error
      .catch((err) => {
        // If error has a body, check the response
        if (typeof err.json === "function") {
          err.json().then((err_body) => {
            this.setState({ ...this.initialState });
            this.throwPopupMessage("error", err_body.error.description);
          });
        } else {
          this.setState({ ...this.initialState });
          this.throwPopupMessage("error", errors.GENERAL_ERR);
        }
      });
  };

  handleSigninPromise = (promise) => {
    promise
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((res) => {
        if (res.token) this.props.addToken(res.token);
        if (res.user) {
          // Add user data to redux store execpt user's role
          // to prevent PermisionsValidator to trigger
          // a redirect
          this.props.addUser({
            username: res.user.username,
            email: res.user.email,
            role: "",
            permissions: null,
            category: "",
            has_pin: false,
          });

          // here should be "agree with terms" process

          if (
            res.user.commerce_associated &&
            res.user.commerce_associated.category
          ) {
            let commerce = res.user.commerce_associated;
            const hasBlocked = commerce.status && commerce.status === "blocked";
            if (hasBlocked) {
              this.throwPopupMessage(
                "alert",
                "Este comercio se encuentra bloqueado. Contáctenos para más información"
              );
              this.setState({
                ...this.initialState,
                popupMessages: this.state.popupMessages,
                popupMessagesType: this.state.popupMessagesType,
                showPopup: this.state.showPopup,
              });
              return;
            }
            switch (commerce.category) {
              case "pagodirecto":
                //no llega res.buildings
                /*     if (
                  res.user.building_associated &&
                  res.user.building_associated._id
                ) {
                  // add buildings to redux-store
                  this.props.addBuildings([res.user.building_associated]);
                  this.props.selectBuilding(res.user.building_associated._id);
                  this.props.addWorkspace(res.user.building_associated._id);
                  //this.props.addForeignId(commerce.owner);
                } */

                break;

              case "estacionamiento":
                if (commerce.parking && commerce.parking.building) {
                  // add buildings to redux-store
                  this.props.addBuildings([commerce.parking.building]);
                  this.props.selectBuilding(commerce.parking.building._id);
                  this.props.addWorkspace(commerce.parking.building);
                  //this.props.addForeignId(commerce.owner);
                }
                break;

              case "gasolina":
                // no se sabe
                break;

              default:
                // no llega res.buildings
                break;
            }
            this.props.addCommerces([commerce]);
            this.props.selectCommerce(commerce._id);
            if (commerce.parking) {
              this.props.addWorkspace(commerce.parking.building);
              this.props.addBuildings([commerce.parking.building]);
              this.props.selectBuilding(commerce.parking.building._id);
            }
            // redirect to /mpos
            this.setState(
              {
                loading: false,
                redirect: res.user.change_password ? (
                  <Redirect to="/change-password" />
                ) : (
                  <Redirect to="/mpos" />
                ),
              },
              // add user data to redux-store after the redirect
              () =>
                this.props.addUser({
                  ...res.user,
                  role:
                    res.user.commerce_role && res.user.commerce_role.name
                      ? res.user.commerce_role.name
                      : "",
                  permissions:
                    res.user.commerce_role && res.user.commerce_role.permissions
                      ? {
                          ...res.user.commerce_role.permissions,
                          can_digitize: commerce.can_digitize,
                        }
                      : {
                          permission1: false,
                          permission2: false,
                          permission3: false,
                          admin: false,
                          can_digitize: false,
                        },
                  category: commerce.category,
                  has_pin: res.user.security_pin ? true : false,
                })
            );
            return;
          } else {
            this.setState({ ...this.initialState });
            this.throwPopupMessage("error", errors.GENERAL_ERR);
          }
        }
      })
      // if an error occurs, reset the view and print the error
      .catch((err) => {
        // If error has a body, check the response
        console.log({ err });
        if (typeof err.json === "function") {
          err.json().then((err_body) => {
            this.setState({ ...this.initialState });
            this.throwPopupMessage("error", err_body.error.description);
          });
        } else {
          this.setState({ ...this.initialState });
          this.throwPopupMessage("error", errors.GENERAL_ERR);
        }
      });
  };

  refuseTerms = () => {
    this.closePopup();
    setTimeout(() => {
      this.setState({ ...this.initialState });
    }, 250);
  };

  render() {
    return (
      <SigninView
        // props
        formData={this.state.formData}
        loading={this.state.loading}
        redirect={this.state.redirect}
        agreeWithTerms={this.state.agreeWithTerms}
        popupMessages={this.state.popupMessages}
        popupMessagesType={this.state.popupMessagesType}
        showPopup={this.state.showPopup}
        location={this.props.location}
        // methods
        handleLoginChange={this.handleLoginChange}
        handlePasswordChange={this.handlePasswordChange}
        handleSubmit={this.handleSubmit}
        handleTerms={this.handleTerms}
        refuseTerms={this.refuseTerms}
        closePopup={this.closePopup}
      />
    );
  }
}

const mapStateToProps = null;
const mapDispatchToProps = {
  addToken,
  addUser,
  addBuildings,
  selectBuilding,
  addCommerces,
  selectCommerce,
  addWorkspace,
  addForeignId,
};
export default connect(mapStateToProps, mapDispatchToProps)(Signin);
