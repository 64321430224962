import React, { useContext } from "react";
import moment from "moment";
import { Icon } from "semantic-ui-react";
import { dateFormats } from "services/constants";
import { ActivateForm } from "../activate-form";
import { ActivateMPContext } from "../../context";
import { Panel } from "components/panel";
import { Loader } from "components/loader";
import { CSSTransition } from "react-transition-group";
import { grey_4 } from "assets/strings/colors";

export const BundleDetailsModel = ({ className }) => {
  const {
    state: { selectedBundle, activateLoading },
    handleActivate,
    handleBack,
    onChangeDaysQty,
    daysQty,
    viewType,
  } = useContext(ActivateMPContext);

  if (!selectedBundle) return <></>;
  if (activateLoading) return <Loader />;
  const {
    conditions,
    premium_type,
    price,
    price_per_day,
    price_usd,
    price_usd_per_day,
    title,
    user_info,
  } = selectedBundle;

  const { is_active, expire_date } = user_info;
  const type = {
    monthly_premium: "Puesto Fijo",
    regular: "Regular",
    default: "--",
  };
  return (
    <CSSTransition
      in={selectedBundle ? true : false}
      mountOnEnter
      unmountOnExit
      timeout={350}
      appear={true}
      classNames="panel"
    >
      <Panel>
        <div className={className}>
          {viewType === "phone" && (
            <Icon
              style={{
                paddingBottom: "2rem",
                display: "flex",
                alignItem: "left",
                float: "left",
                color: grey_4,
                cursor: "pointer",
                zIndex: 1,
              }}
              onClick={handleBack}
              name="arrow left"
            />
          )}
          {title && <p className="sub-title">Nombre del paquete:</p>}
          {title && <p className="content">{title}</p>}
          <p className="sub-title">Estado:</p>
          <p className="content">{is_active ? "Activo" : "Inactivo"}</p>
          {conditions && <p className="sub-title">Condiciones:</p>}
          {conditions && <p className="content">{conditions}</p>}
          {premium_type && <p className="sub-title">Tipo:</p>}
          {premium_type && <p className="content">{type[premium_type]}</p>}
          {price && (
            <p className="sub-title">
              {`${price_per_day ? "Precio por día" : "Precio"}`}:
            </p>
          )}
          {price && (
            <p className="content">
              {`${price_per_day ? price_per_day : price} Bs.`}{" "}
              <span>
                <b>ref:</b>
                {`${price_usd_per_day ? price_usd_per_day : price_usd}`}
              </span>
            </p>
          )}
          {expire_date && <p className="sub-title">Fecha de vencimiento:</p>}
          {expire_date && (
            <p className="content">
              {moment(expire_date).format(dateFormats.standard)}
            </p>
          )}
          <ActivateForm
            type={type[premium_type]}
            handleActivate={handleActivate}
            onChangeDaysQty={onChangeDaysQty}
            daysQty={daysQty}
          />
        </div>
      </Panel>
    </CSSTransition>
  );
};
