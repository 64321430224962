import { baseURL_V2, baseURL } from "services/config";
import { post, get } from "services/http-methods";

const endpoints = {
  usersTransactions: `${baseURL_V2}/superadmin/transactions`,
  addExtension: `${baseURL}/superadmin/users/child/add`,
  updateExtension: `${baseURL}/superadmin/users/child/update`,
  deleteExtension: `${baseURL}/superadmin/users/child/delete`,
  listExtensions: `${baseURL}/superadmin/users/child`,
  extensionInfo: `${baseURL}/child/info`,
  generateExcel: `${baseURL_V2}/superadmin/users/child/generateExcel`,
};

export function usersTransactions(body) {
  /**
   * @brief
   * @attributes
   *  body = {
   *
   */

  return post(endpoints.usersTransactions, body);
}

export function addExtension(body) {
  /**
   * @brief
   * @attributes
   *  body = {
   *
   */

  return post(endpoints.addExtension, body);
}

export function updateExtension(body) {
  /**
   * @brief
   * @attributes
   *  body = {
   *
   */

  return post(endpoints.updateExtension, body);
}

export function deleteExtension(body) {
  /**
   * @brief
   * @attributes
   *  body = {
   *
   */

  return post(endpoints.deleteExtension, body);
}
export function listExtensions(body) {
  /**
   * @brief
   * @attributes
   *  body = {
   *
   */

  return post(endpoints.listExtensions, body);
}
export function extensionInfo(body) {
  /**
   * @brief
   * @attributes
   *  body = {
   *
   */

  return post(endpoints.extensionInfo, body);
}
export function generateExcel() {
  /**
   * @brief
   * @attributes
   *  body = {
   *
   */

  return get(endpoints.generateExcel);
}
