import React from "react";
import { Grid } from "semantic-ui-react";
import { Panel } from "components/panel";
import { RechargeForm } from "./recharge-form";
import SuccessRecharge from "./success-recharge";
import SearchInputNew from "components/search-input-new";
import PopupMessage from "components/popup-message";
import { formatUserList } from "./user-list-item";
import { PanelPlaceholder } from "components/panel";
import { CSSTransition } from "react-transition-group";
import { EntitiesList } from "components/entities-list";
import { RechargeConfirmation } from "./recharge-confirmation";
import { element_transition_time } from "assets/strings/constants";
import { RightBorderGreyColumn } from "components/styled-grid-column";
import {
  general as texts,
  recharges_recharge as viewTexts,
} from "assets/strings/texts";

const MposRechargesRechargeView = ({
  // Props
  viewState,
  searchParam,
  users,
  bankList,
  showRechargeForm,
  rechargeRef,

  showOtherAmountField,
  dropdownValueToShow,
  bankNameSelected,
  selectedUser,
  rechargeAmounts,
  desiredRechargeAmount,
  showGeneratedRecharge,
  popupMessages,
  popupMessagesType,
  showPopup,
  // Methods
  handleSearchInputChange,
  handleUserSelection,
  handleAmountSelect,
  handleBankSelect,
  handleReference,

  handleOtherAmount,

  toggleRechargeConfirmation,
  recharge,
  handleSearchUser,
  resetView,
  closePopup,
}) => {
  return (
    <Grid padded>
      {!(
        viewState === "recharge_confirmation" ||
        viewState === "recharge_loading"
      ) && (
        // If not on confirmation process, show popup
        <PopupMessage
          messageType={popupMessagesType}
          showPopup={showPopup}
          messages={popupMessages}
          closePopup={closePopup}
        />
      )}

      {(viewState === "recharge_confirmation" ||
        viewState === "recharge_loading") && (
        // If view IS on confirmation process, show confirmation popup
        <PopupMessage
          messageType={popupMessagesType}
          showPopup={showPopup}
          messages={popupMessages}
          closePopup={toggleRechargeConfirmation}
        >
          <RechargeConfirmation
            viewState={viewState}
            selectedUser={selectedUser}
            amount={desiredRechargeAmount}
            recharge={recharge}
            cancel={toggleRechargeConfirmation}
          />
        </PopupMessage>
      )}

      <RightBorderGreyColumn tablet={4} computer={6}>
        <SearchInputNew
          placeholder={texts.userSearchPlaceholder}
          searchParam={searchParam}
          handleChange={handleSearchInputChange}
          handleSearch={handleSearchUser}
        />
        <EntitiesList
          height="calc(100vh - 9rem)"
          viewState={viewState}
          entities={users}
          formattingFunction={formatUserList}
          selectionHandler={handleUserSelection}
        />
      </RightBorderGreyColumn>

      <Grid.Column tablet={6} computer={5}>
        {(viewState === "user_search" ||
          viewState === "user_search_loading" ||
          viewState === "user_search_done") && (
          <PanelPlaceholder>
            {viewTexts.rechargeFormPlaceholder}
          </PanelPlaceholder>
        )}

        <CSSTransition
          in={showRechargeForm}
          mountOnEnter
          unmountOnExit
          timeout={element_transition_time}
          appear={true}
          classNames="panel"
        >
          <Panel
            className={
              (viewState === "recharge_confirmation" ||
                viewState === "recharge_loading" ||
                viewState === "recharge_done") &&
              "inactive"
            }
          >
            {viewState === "recharge_loading" && (
              <div className="ui loader active"></div>
            )}

            {(viewState === "user_selection_done" ||
              viewState === "recharge_confirmation" ||
              viewState === "recharge_loading" ||
              viewState === "recharge_done") && (
              <RechargeForm
                viewState={viewState}
                selectedUser={selectedUser}
                rechargeRef={rechargeRef}
                rechargeAmounts={rechargeAmounts}
                showOtherAmountField={showOtherAmountField}
                dropdownValueToShow={dropdownValueToShow}
                desiredRechargeAmount={desiredRechargeAmount}
                bankList={bankList}
                bankNameSelected={bankNameSelected}
                // handlers
                handleReference={handleReference}
                handleBankSelect={handleBankSelect}
                handleAmountSelect={handleAmountSelect}
                handleOtherAmount={handleOtherAmount}
                toggleRechargeConfirmation={toggleRechargeConfirmation}
              />
            )}
          </Panel>
        </CSSTransition>
      </Grid.Column>

      <Grid.Column tablet={6} computer={5}>
        {(viewState === "user_selection_done" ||
          viewState === "recharge_confirmation" ||
          viewState === "recharge_loading") && (
          <PanelPlaceholder>
            Porfavor, seleccione el monto a recargar
          </PanelPlaceholder>
        )}

        <CSSTransition
          in={showGeneratedRecharge}
          mountOnEnter
          unmountOnExit
          timeout={element_transition_time}
          appear={true}
          classNames="panel"
        >
          <Panel>
            {viewState === "recharge_done" && (
              <SuccessRecharge
                desiredRechargeAmount={desiredRechargeAmount}
                selectedUser={selectedUser}
                resetView={resetView}
              />
            )}
          </Panel>
        </CSSTransition>
      </Grid.Column>
    </Grid>
  );
};

export default MposRechargesRechargeView;
