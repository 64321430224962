import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "components/buttons";
/* import { Dropdown } from "semantic-ui-react"; */
import moment from "moment";
//import DatePicker from "react-datepicker";
import { errors } from "assets/strings/texts";
import { markWithdrawAsPaid } from "services/api";
import { clearReduxStore } from "redux/actions/general";
import { general as texts } from "assets/strings/texts";
import { getViewMposCommerces } from "redux/selectors/view-mpos-commerces";
import { throwPopupMessage, closePopup } from "redux/actions/popup-messages";
import { popup_transition_time } from "assets/strings/constants";
import { commerces_admin as viewTexts } from "assets/strings/texts";

import {
  setViewState,
  updateWithdrawList,
} from "redux/actions/view-mpos-commerces";

class WithdrawPaymentFormModel_unconnected extends Component {
  constructor(props) {
    super(props);
    let billId = "";
    props.withdrawals.forEach((withdraw) => {
      if (withdraw._id === props.selectedWithdraw) billId = withdraw.bill_ref;
    });
    this.state = {
      billId,
      withdrawId: props.selectedWithdraw,
      payRef: "",
      payDate: moment().format("YYYY-MM-DDTHH:mm:ss.sssZ"),
      authError: false,
    };
    // ========== INSTANCE PROPERTIES ==========
    this.initialState = { ...this.state };
  }
  // 20)
  handleTextInput = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value });
  };

  // 21)
  handlePayType = (event, data) => {
    this.setState({ payType: data.value });
  };

  handleDate = (date) => {
    if (date) {
      // If the date exists add 23 hours
      this.setState({ payDate: moment(date).add(23, "hours") });
    } else {
      // If the date is null, save it like that
      this.setState({ payDate: date });
    }
  };
  // 22)
  handleWithdrawPayment = (event) => {
    event.preventDefault();
    this.props.setViewState("commerce_withdraw_payment_loading");
    let paymentData = {
      bill_id: this.state.billId,
      withdraw_id: this.state.withdrawId,
      pay_ref: this.state.payRef,
      pay_date: this.state.payDate,
    };
    markWithdrawAsPaid(paymentData)
      .then((res) => {
        if (!res.ok) {
          throw res;
        } // If not OK throw error
        return res.json(); // If OK return body
      })
      .then((res) => {
        // Manage success response
        if (res.message) {
          // Clone actual invoices
          let updatedWithdraw = [...this.props.withdrawals];
          // add the paid bill to the cloned array
          this.props.withdrawals.forEach((withdraw_u, index) => {
            if (withdraw_u._id === this.state.withdraw_id) {
              updatedWithdraw[index].status = "paid";
            }
          });
          this.props.setViewState("commerce_info");
          this.props.updateWithdrawList(updatedWithdraw);
          this.props.closePopup();

          setTimeout(() => {
            this.props.setViewState("commerce_withdraw_done");
            this.props.throwPopupMessage("success", res.message);
          }, popup_transition_time);
        }
      })
      .catch((err) => {
        // Manage error response
        this.props.closePopup();
        // If error has a body, check the response
        if (typeof err.json === "function") {
          err.json().then((err_body) => {
            // If it is an authentication error, clear the redux-store to close the session
            if (
              err_body.error.id === "NO_TOKEN_PROVIDED" ||
              err_body.error.id === "AUTHENTICATE_FAILED" ||
              err_body.error.id === "DUPLICATE_SESSION"
            ) {
              this.props.throwPopupMessage("alert", errors.AUTHENTICATION);
              //this.props.clearReduxStore();
              return; // prevent setState exeution on unmounted component
            }
            setTimeout(() => {
              this.props.setViewState("commerce_withdraw_done");
              this.props.throwPopupMessage("error", err_body.error.description);
            }, popup_transition_time);
          });
        } else {
          setTimeout(() => {
            this.props.setViewState("commerce_withdraw_done");
            this.props.throwPopupMessage("error", errors.GENERAL_ERR);
          }, popup_transition_time);
        }
      });
  };

  render() {
    const { viewState, className } = this.props;

    const { payRef } = this.state;

    return (
      <div className={className}>
        <h3 className="title">{viewTexts.paymentFormHeader}</h3>
        <form
          className="invoice-payment-form"
          onSubmit={this.handleWithdrawPayment}
        >
          {/* <div className="input-section">
            <label htmlFor="bill-ref">{viewTexts.paymentFormBillRef}</label>
            <input
              id="bill-ref"
              type="text"
              required={true}
              autoComplete="off"
              name="physicalBillRef"
              placeholder="170734"
              value={physicalBillRef}
              onChange={this.handleTextInput}
            />
          </div>
          <div className="input-section">
            <label htmlFor="pay-type">{viewTexts.paymentFormPayType}</label>
            <Dropdown
              selection
              onChange={this.handlePayType}
              value={payType}
              placeholder={viewTexts.paymentFormPayTypePlaceholder}
              options={[
                {
                  key: 1,
                  text: viewTexts.paymentFormPayTypePaycheck,
                  value: "paycheck",
                },
                {
                  key: 2,
                  text: viewTexts.paymentFormPayTypeWireTransfer,
                  value: "wire_transfer",
                },
              ]}
            />
          </div> */}
          <div className="input-section">
            <label htmlFor="pay-ref">{viewTexts.paymentFormPayRef}</label>
            <input
              id="pay-ref"
              type="text"
              name="payRef"
              required={true}
              autoComplete="off"
              placeholder="1200345123"
              value={payRef}
              onChange={this.handleTextInput}
            />
          </div>
          {/* 
          <div className="date-pickers">
            <DatePicker
              className="date-picker"
              placeholderText={viewTexts.date}
              dateFormat={texts.shortDateFormat}
              showMonthDropdown
              showYearDropdown
              todayButton={texts.today}
              isClearable
              maxDate={
                this.state.payDate
                  ? moment(this.state.payDate).subtract(23, "hours")
                  : moment()
              }
              onChange={this.handleDate}
            />
          </div> */}

          <Button
            color="blue"
            type="submit"
            disabled={viewState === "commerce_withdraw_payment_loading"}
          >
            {viewTexts.markAsPaid}
          </Button>
        </form>
        <Button
          color="grey"
          margin="1rem 0 0 0 "
          onClick={this.props.closePopup}
        >
          {texts.back}
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  viewState: getViewMposCommerces(state).viewState,
  selectedWithdraw: getViewMposCommerces(state).selectedWithdraw,
  withdraw: getViewMposCommerces(state).invoices,
});

const mapDispatchToProps = {
  closePopup,
  setViewState,
  clearReduxStore,
  throwPopupMessage,
  updateWithdrawList,
};

const WithdrawPaymentFormModel = connect(
  mapStateToProps,
  mapDispatchToProps
)(WithdrawPaymentFormModel_unconnected);

export { WithdrawPaymentFormModel };
