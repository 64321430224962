import React from "react";
import imagenLogo from "assets/img/PosDirecto-vertical.png";

const HomeModel = ({ className }) => {
  return (
    <div className={className}>
      <img src={imagenLogo} alt="logo" label="PagoDirecto" />
      <p className="info">
        Sistema de digitalización y habilitación de tickets para taquillas
      </p>
    </div>
  );
};

export { HomeModel };
