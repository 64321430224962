import Ticket from "./model";
import styled from "styled-components";
import giftImg from "assets/img/gift-ticket-icon.png";
import {
  grey_4,
  grey_5,
  grey_6,
  orange,
  purplePD,
  magenta,
} from "assets/strings/colors";
//import imagenTicket from "assets/img/ticket_asset_new.png";

const StyledTicket = styled(Ticket)`
  #root & {
    background: ${(props) => {
      return props.ticketData ? "#f0f0f0" : "white";
    }};
    height: 100%;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > .ticket::before {
      height: 13px;
      content: "";
      width: 100%;
      border-radius: 0.3rem 0.3rem 0 0;
      position: absolute;
      top: -13px;
    }
    > .ticket.ready::before {
      //background: #1ba2dd;
    }
    > .ticket.paid::before {
      //background: #a2c400;
    }
    > .ticket.closed::before {
      //background: #ff0000;
    }
    > .ticket.closed_superadmin::before {
      //background: #ff0000;
    }
    > .ticket.closed_premium_no_transactions::before {
      //background: #ff0000;
    }
    > .ticket.discarded::before {
      //background: ${orange};
    }
    > .ticket.gift::before {
      background: #caa338; /* Old browsers */
      background: -moz-linear-gradient(
        left,
        #caa338 0%,
        #eee68d 50%,
        #caa338 100%
      ); /* FF3.6-15 */
      background: -webkit-linear-gradient(
        left,
        #caa338 0%,
        #eee68d 50%,
        #caa338 100%
      ); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        to right,
        #caa338 0%,
        #eee68d 50%,
        #caa338 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#caa338', endColorstr='#caa338',GradientType=1 ); /* IE6-9 */
    }
    > .ticket.gift {
      background-image: url(${giftImg});
      background-size: 8vw;
      background-position: 105% -5%;
      background-origin: content-box;
      background-repeat: no-repeat;
    }
    > .ticket {
      margin: 0;
      display: flex;
      align-self: center;
      flex-direction: column;
      align-items: center;
      height: 75%;
      min-height: 700px;
      padding-inline: calc(9vw - 10rem);
      width: 32rem;

      > .test {
        font-size: 1rem;
        font-weight: bold;
        color: red;
        text-transform: uppercase;
      }
      > .options {
        margin-top: -4rem;
        margin-left: 20rem;
        border: none;
        background: none;
        box-shadow: none;
        display: absolute;
        height: 30px;
        padding-left: 100px;
      }

      > .circle {
        margin-top: 1rem;
        margin-back: 5rem;
        min-height: 70px;
        min-width: 70px;
        height: 6rem;
        width: 6rem;
        background: #fcfcfc;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        > .icon-incomplete {
          color: #2596be;
        }
        > .icon-ready {
          color: #ebd81b;
        }
        > .icon-paid {
          color: #95c33a;
        }
        > .icon-gift {
          color: ${purplePD};
        }
        > .icon-win {
          color: #ebd81b;
        }
        > .icon-cert {
        }
        > .icon-discarded {
          color: #ff0000;
        }
        > .icon-image {
          width: 60%;
        }
      }
      > .status {
        margin: 0;
        margin-top: -6rem;
        font-weight: bold;
        font-size: 1.3rem;
      }
      > .flow-payment {
        margin: 0;
        font-size: 0.7rem;
      }
      > .circle-ok {
        margin: 0 0 5rem 0;
        margin-top: 5vh;
      }
      > .discard {
        margin: 0 0 0 90%;
      }
      > .title {
        font-size: 2.6rem;
        margin-bottom: 1vh;
      }

      > .building {
        font-size: 1.1rem;
        margin-bottom: 1vh;
        margin: 0;
      }
      > .address {
        margin: 0;
        margin-bottom: 1vh;
        font-size: 0.7rem;
        width: 60%;
        text-align: center;
      }

      > .headers {
        margin-top: 1.5rem;
        width: 75%;
        height: 1rem;
        font-size: 1rem;
        font-weight: bold;
        color: #808080;
        text-transform: uppercase;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        > p {
          > .new-user {
            margin: 0.3rem;
            padding: 0.1rem 0.2rem;
            text-transform: lowercase;
            font-size: 0.8rem;
            font-weight: bold;
            color: white;
            background: ${magenta};
            border-radius: 0.3rem;
          }
        }
      }
      > .info {
        width: 75%;
        margin-top: 0.5rem;
        font-size: 1rem;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }
      > .type-footer {
        margin-top: 0.5rem;
        margin-bottom: 0;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 1.5rem;
        color: #9d8ba8;
      }
      > .status-footer {
        margin: 0;
        font-size: 1rem;
        text-align: center;
      }
      > .status-footer-time {
        margin: 0;
        font-size: 1rem;
        text-align: center;
      }
      > .id-data {
        margin: 0;
        margin-top: 0.5rem;
        height: 6rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 60%;
        background: #f0f0f0;
        border-radius: 0.5rem;
        font-weight: bold;
        > .title {
          padding-top: 1rem;
          color: #808080;
          font-size: rem;
        }
        > .id {
          margin-top: -0.5rem;
          font-size: 2rem;
          text-transform: uppercase;
        }
      }
    }

    .ticket.ticket-appear {
      opacity: 0;
      transform: translateX(-30px);
    }
    .ticket.ticket-appear-active {
      opacity: 0.8;
      transform: translateX(2px);
      transition: all 350ms ease-out;
    }
    .ticket.ticket-enter {
      opacity: 0;
      transform: translateX(-30px);
    }
    .ticket.ticket-enter-active {
      opacity: 0.8;
      transform: translateX(2px);
      transition: all 300ms ease-out;
    }
    .ticket.ticket-enter-done {
      opacity: 1;
      transform: translateX(0);
      transition: all 50ms ease-out;
    }
    .ticket.ticket-exit {
      opacity: 0.8;
      transform: translateX(-2px);
    }
    .ticket.ticket-exit-active {
      opacity: 0;
      transform: translateX(30px);
      transition: all 350ms ease-out;
    }

    @media (max-width: 1600px) {
      > .ticket {
        > .options {
          margin-right: 8rem;
        }
        > .headers {
          width: 65%;
        }
        > .info {
          width: 65%;
        }
      }
    }
    @media (max-width: 1500px) {
      > .ticket {
        > .options {
          margin-right: 5rem;
        }
        > .headers {
          width: 70%;
        }
        > .info {
          width: 70%;
        }
      }
    }
    @media (max-width: 1200px) {
      > .ticket {
        > .options {
          margin-right: 3rem;
        }
        > .headers {
          width: 80%;
        }
        > .info {
          width: 80%;
        }
      }
    }
    @media (max-width: 878px) {
      > .ticket {
        > .options {
          margin-right: 8rem;
        }
        > .headers {
          width: 60%;
        }
        > .info {
          width: 60%;
        }
      }
    }

    @media (max-width: 650px) {
      > .ticket {
        > .options {
          margin-right: 3rem;
        }
        > .headers {
          width: 80%;
        }
        > .info {
          width: 80%;
        }
      }
    }
    @media (max-width: 500px) {
      > .ticket {
        > .options {
          margin-right: 3rem;
        }
        > .headers {
          width: 80%;
          font-size: 0.8rem;
        }
        > .info {
          width: 80%;
          font-size: 0.8rem;
        }
        > .type-footer {
          font-size: 1rem;
        }
        > .status-footer {
          font-size: 0.8rem;
        }
        > .status-footer-time {
          font-size: 0.8rem;
        }
        > .id-data {
          > .title {
            font-size: 0.9rem;
          }
          > .id {
            font-size: 1.5rem;
          }
        }
      }
    }

    > button {
      margin-top: 1rem;
      border: none;
      cursor: pointer;
      width: 160px;
      border-radius: 0.28rem;
      font-weight: bold;
      min-height: 1.5rem;
      padding: 0.8rem 1.5rem;
    }
    > .back {
      border-bottom: 1px solid ${grey_4};
      background: ${grey_5};
      color: white;
      :hover {
        background: ${grey_6};
      }
    }
  }
`;

export default StyledTicket;
