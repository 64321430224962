import { TypeofTransferModel } from "./model";
import styled from "styled-components";
import { bluePD, grey_6 } from "assets/strings/colors";

export const TypeofTransfer = styled(TypeofTransferModel)`
  #root && {
    height: calc(60vh - 2rem);
    padding: 4rem 0 0 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    ${(props) => (props.phoneView ? "margin-top: 20rem;" : "")};
    align-items: flex-start;

    > .input-selection {
      margin-bottom: 3rem;
      width: 80%;
    }

    > .space {
      margin-top: 20rem;
    }
    > .withdraw {
      margin-top: 2rem;
      font-size: 1.2rem;
      font-weight: bold;
      color: ${grey_6};
      > span {
        color: ${bluePD};
      }
    }
  }
`;
