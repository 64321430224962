import React, { useContext } from "react";
import { Grid } from "semantic-ui-react";
import { Loader } from "components/loader";
import { PopupMessageContext } from "components/popup-message";
import { SimpleTransfer } from "./simple-transfer";
import { CompleteTransfer } from "./complete-transfer";
import { AccountsInfo } from "./accounts-info";
import { TypeofTransfer } from "./typeof-transfer";
import { RightBorderGreyColumn } from "components/styled-grid-column";
import { AdminTransfersContext } from "../context";

export const AdminTransferView = () => {
  const { state, remoteLoading } = useContext(AdminTransfersContext);

  if (state.loading || remoteLoading) return <Loader />;

  const transferComponents = {
    show_simple_transfer: <SimpleTransfer />,
    show_complete_transfer: <CompleteTransfer />,
  };

  const transferForm = transferComponents[state.viewState];
  const rightView = state.transferLoading ? <Loader /> : transferForm;

  return (
    <Grid padded centered>
      <PopupMessageContext context={AdminTransfersContext} />
      <RightBorderGreyColumn width={8}>
        <AccountsInfo />
        <TypeofTransfer />
      </RightBorderGreyColumn>
      <Grid.Column width={8}>{rightView}</Grid.Column>
    </Grid>
  );
};
