import React from "react";
import { ListStatusTypeDetail } from "./index";
import moment from "moment-timezone";
moment.locale();

const validStrings = {
  ready: "Por pagar",
  paid: "Pagados",
  incomplete: "Incompletos",
  closed: "Cerrados",
  discarded: "Descartados",
  deleted: "Eliminados",
  closed_superadmin: "Cerrados (PagoDirecto)",
  closed_premium_no_transaction: "Cerrados (Premium)",
  closed_promotional: "Cerrados (Promocional)",
  regular: "Regular",
  prized: "Premiados",
  recommended: "Recomendación",
  promotioned: "Promoción",
  premium: "Premium",
};

const ListStatusTypeDetailModel = ({ name, qty, className }) => {
  return (
    <div className={className}>
      <p className="name">{name}</p>
      <p className="qty">{qty}</p>
    </div>
  );
};

const formatStatusTypeListDetail = (list, viewState, handleItemSelection) => {
  if (list.length <= 0)
    return (
      <div style={{ textAlign: "center" }}>No hay resultado para mostrar</div>
    );
  if (viewState === "types") viewState = "type";
  let statusTypeListDetail = [];
  list.forEach((item, index) => {
    statusTypeListDetail.push(
      <ListStatusTypeDetail
        name={validStrings[item[viewState]]}
        qty={item.qty}
        key={index}
      />
    );
  });
  return statusTypeListDetail;
};

export { formatStatusTypeListDetail, ListStatusTypeDetailModel };
