import styled from 'styled-components'
import { ResultScreenModel } from './model'

const ResultScreen = styled(ResultScreenModel)`
  #root &{
    height: 90.75vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    >img{
      height: 18.5vh;
      margin-bottom: 5.9vh;
    }
    >p{
      font-size: 1.5rem;
    }
    >button{
      position: absolute;
      bottom: 6vh;
      width: 30%;
    }
  }
`;

export { ResultScreen }