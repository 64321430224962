import styled from 'styled-components'
import { InvalidUserModel } from './model'

const InvalidUser = styled(InvalidUserModel)`
  #root &.invalid-appear {
    max-height: 0;
  }
  #root &.invalid-appear-active {
    max-height: 1000px;
    transition: max-height 700ms linear;
  }
  #root &.invalid-enter {
    max-height: 0;
  }
  #root &.invalid-enter-done {
    max-height: 1000px;
    transition: max-height 700ms linear;
  }
  
  #root &{
    overflow: hidden;
    text-align: center;
    >.username{
      font-size: 1.6rem;
      font-weight: bold;
      color: red;
      margin: .5rem 0 .5rem 0;
    }
    >.text{
      font-weight: bold;
      color: #5B5B5B;
      font-size: 1.5rem;
      margin: .5rem auto .5rem auto;
      text-align: center;
    }
    >button{
      margin: 1rem 0 0 0;
    }
  }
`
export { InvalidUser }