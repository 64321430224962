import { getAccounts } from "./api";

export const getWithdrawAccounts = async (
  withdraw,
  setWithdraw,
  setLoading
) => {
  try {
    setLoading(true);
    const response = await getAccounts(withdraw.customer);
    const res = await response.json();
    if (res.error) throw res.error;
    setWithdraw((prevState) => ({
      ...prevState,
      accounts: [...res.bank_accounts],
    }));
  } catch (error) {
    console.error({ error });
  }
  setLoading(false);
};
