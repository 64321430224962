import * as api from "./api";
import moment from "moment";

const commonTimeout = 5000;
export const submitForm = async ({
  reportValue,
  file,
  fromDate,
  endDate,
  transaction_tag,
  schoolId,
  buildingCode,
  commerces,
  tolls,
  selectedCommerce,
}) => {
  let datesArgs = {
    fecha_inicio: moment(fromDate).format("DD-MM-YYYY"),
    fecha_fin: moment(endDate).format("DD-MM-YYYY"),
  };
  const schoolArgs = {
    escuela_id: schoolId,
  };
  const logsArgs = {
    building_code: buildingCode,
  };
  const commercesArgs = {
    comercios: [...commerces],
  };
  const commerceArgs = {
    comercio_id: selectedCommerce,
  };
  const tollsArgs = {
    peajes: [...tolls],
    metodo: "",
  };

  let formData = new FormData();
  Object.entries(datesArgs).forEach(([key, value]) => {
    formData.append(key, value);
  });
  formData.append("archivotxt", file);
  const reports = {
    verifiedRegisteredUsers: {
      service: api.verifiedRegisteredUsers,
      args: {
        ...datesArgs,
      },
      timeout: commonTimeout,
    },
    unverifiedRegisteredUsers: {
      service: api.unverifiedRegisteredUsers,
      args: {
        ...datesArgs,
      },
      timeout: commonTimeout,
    },
    unverifiedRegisteredUsersByOffice: {
      service: api.unverifiedRegisteredUsersByOffice,
      args: {
        ...datesArgs,
      },
      timeout: commonTimeout,
    },
    userActivities: {
      service: api.userActivities,
      args: formData,
      timeout: 20000,
    },

    transactionCustomer: {
      service: api.transactionsCustomer,
      args: { ...datesArgs, transaction_tag },
      timeout: commonTimeout,
    },
    servicePayment: {
      service: api.servicePayment,
      args: {
        ...datesArgs,
      },
      timeout: commonTimeout,
    },
    walletRecharges: {
      service: api.walletRecharges,
      args: {
        ...datesArgs,
      },
      timeout: commonTimeout,
    },
    transactionsCreditPremium: {
      service: api.transactionsCreditPremium,
      args: {
        ...datesArgs,
      },
      timeout: commonTimeout,
    },
    transactionsSchools: {
      service: api.transactionsSchools,
      args: {
        ...datesArgs,
        ...schoolArgs,
      },
      timeout: commonTimeout,
    },

    premiumTicketsQtyByUser: {
      service: api.premiumTicketsQtyByUser,
      args: {
        ...datesArgs,
      },
      timeout: commonTimeout,
    },
    approvedRechargesSuperadmin: {
      service: api.approvedRechargesSuperadmin,
      args: {
        ...datesArgs,
      },
      timeout: commonTimeout,
    },
    tickets: {
      service: api.tickets,
      args: {
        ...datesArgs,
      },
      timeout: commonTimeout,
    },
    parkingLogs: {
      service: api.parkingLogs,
      args: {
        ...datesArgs,
        ...logsArgs,
      },
      timeout: commonTimeout,
    },
    ticketsBuilding: {
      service: api.ticketsBuilding,
      args: {
        ...datesArgs,
        ...logsArgs,
      },
      timeout: commonTimeout,
    },
    transactionsCommercesTransfers: {
      service: api.transactionsCommercesTransfers,
      args: {
        ...datesArgs,
        ...commercesArgs,
      },
      timeout: commonTimeout,
    },
    parkingMeterTickets: {
      service: api.parkingMetersTickets,
      args: {
        ...datesArgs,
        ...commerceArgs,
      },
      timeout: commonTimeout,
    },
    tollTickets: {
      service: api.tollTickets,
      args: {
        ...datesArgs,
        ...tollsArgs,
      },
      timeout: commonTimeout,
    },
  };

  try {
    const request = reports[reportValue].service(reports[reportValue].args);
    const timeoutRequest = new Promise((resolve, reject) => {
      setTimeout(() => {
        reject({
          ok: false,
          timeout: true,
          message:
            "Agotado el tiempo de espera del sistema, espere el e-mail en 5 minutos",
        });
      }, reports[reportValue].timeout);
    });
    const response = await Promise.race([timeoutRequest, request]);

    const error = !response.ok && !response.created;
    const res = await response.json();

    if (error) throw res;
    if (res.error) throw res.error;
    return {
      response: res,
      result: "success",
    };
  } catch (error) {
    return {
      response: error,
      result: error.timeout ? "alert" : "error",
    };
  }
};

export const commerces = async () => {
  try {
    const response = await api.getCommerces({});
    const res = await response.json();
    if (res.error) throw res.error;
    return {
      ok: true,
      res,
    };
  } catch (error) {
    return {
      ok: false,
      response: error,
      result: error.timeout ? "alert" : "error",
    };
  }
};
