import React from "react";
import { Grid } from "semantic-ui-react";
import PopupMessage from "components/popup-message";
import { CommerceTickets } from "../components/commerce-tickets";
import { SearchCommerces } from "../components/search-commerces";
import { Ticket } from "../components/ticket";

const BuildingCommercesPhoneView = ({
  searchBy,
  handleSelectors,
  handleSearch,
  fromDate,
  endDate,
  loading,
  loadingTickets,
  selectedCommerce,
  selectedTicket,
  tickets,
  handleTicketSelection,
  searchParam,
  handleEndDate,
  handleFromDate,
  handleSearchInputChange,
  viewType,
  commerceIsSelected,
  ticketIsSelected,
  viewState,
  commerces,
  handleCommerceSelection,
  //pagination
  handleCommercePageUp,
  handleCommercePageDown,
  currentCommercePage,
  nextCommercePageExist,

  handleTicketsPageUp,
  handleTicketsPageDown,
  currentTicketsPage,
  nextTicketsPageExist,
  handleBackToSearch,
  handleBackToCommerce,
  //popupMessage
  popupMessages,
  popupMessagesType,
  showPopup,
  closePopup,
}) => {
  return (
    <Grid padded>
      <PopupMessage
        messageType={popupMessagesType}
        showPopup={showPopup}
        messages={popupMessages}
        closePopup={closePopup}
      />
      <Grid.Column width={16}>
        {!commerceIsSelected && !ticketIsSelected && (
          <SearchCommerces
            viewType={viewType}
            loading={loading}
            searchBy={searchBy}
            fromDate={fromDate}
            endDate={endDate}
            searchParam={searchParam}
            commerces={commerces}
            handleSelectors={handleSelectors}
            handleFromDate={handleFromDate}
            handleEndDate={handleEndDate}
            handleSearchInputChange={handleSearchInputChange}
            handleSearch={handleSearch}
            handlePageUp={handleCommercePageUp}
            handlePageDown={handleCommercePageDown}
            currentPage={currentCommercePage}
            nextPageExist={nextCommercePageExist}
            handleSelection={handleCommerceSelection}
          />
        )}

        {commerceIsSelected && !ticketIsSelected && (
          <CommerceTickets
            loading={loadingTickets}
            isSelected={commerceIsSelected}
            selectedCommerce={selectedCommerce}
            tickets={tickets}
            handleSelection={handleTicketSelection}
            handlePageUp={handleTicketsPageUp}
            handlePageDown={handleTicketsPageDown}
            currentPage={currentTicketsPage}
            nextPageExist={nextTicketsPageExist}
            viewType={viewType}
            handleBack={handleBackToSearch}
          />
        )}
        {commerceIsSelected && ticketIsSelected && (
          <Ticket
            isSelected={ticketIsSelected}
            ticket={selectedTicket}
            viewType={viewType}
            handleBack={handleBackToCommerce}
          />
        )}
      </Grid.Column>
    </Grid>
  );
};

export { BuildingCommercesPhoneView };
