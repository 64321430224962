import React from 'react' 
import { Grid, Segment } from 'semantic-ui-react'
import Navbar from 'components/navbar'
import { AnchorTagButton } from 'components/buttons'
import PopupMessage from 'components/popup-message'
import { Link } from 'react-router-dom'
import { EmailInput, CodeInput, ResetPasswordInputs } from 'components/register-user-form-inputs'
import { BlueRoundButton } from 'components/round-button'
import GreatingsText from 'components/greatings-text'


const ResetPasswordView = ({ 
  viewState, 
  loading, 
  redirect, 
  match,
  formData, 
  passwordStrength, 
  confirmationMatchClassName, 
  popupMessages, 
  popupMessagesType, 
  showPopup, 
  closePopup,
  handleFormChange, 
  handleEmailSubmit, 
  handleCodeSubmit, 
  handlePasswordChange, 
  handleResetPasswordSubmit,
  resetView 
}) =>{

  return (
    <div>

      <PopupMessage 
        messageType={popupMessagesType}
        messages={popupMessages}
        showPopup={showPopup}
        closePopup={closePopup} />

      <Navbar
        title={'Reestablecer contraseña'}/>

      <Grid centered padded>

        { viewState === 'initial' && 
          <Grid.Column mobile={15} tablet={8} computer={6}>

            <GreatingsText>
              <h4>Para comenzar,</h4>
              <p>Ingrese su correo electrónico</p>
            </GreatingsText>

            <form
              key='reset-pass-email-form'
              onSubmit={handleEmailSubmit} 
              className={loading ? 'ui loading form' : ''}>
                <EmailInput
                  onChange={handleFormChange}
                  values={formData} />
                <BlueRoundButton fluid> Enviar código de verificación </BlueRoundButton>
            </form>
            <Segment basic textAlign='center'>
            <p>¿Desea <Link to='/signin'>Iniciar Sesión</Link> con otro usuario?</p>
            </Segment>

          </Grid.Column>
        }

        {viewState === 'code-verification' &&
          <Grid.Column mobile={15} tablet={8} computer={6}>

            <GreatingsText>
              <h4>Valide su código de verificación</h4>
              <p>Hemos envido el código de verificación a su correo electrónico.</p>
            </GreatingsText>
 
            <form 
              onSubmit={handleCodeSubmit} 
              className={loading ? 'ui loading form' : ''}>
                <CodeInput
                  onChange={handleFormChange}
                  values={formData} />
                <BlueRoundButton fluid> Validar código </BlueRoundButton>
            </form>
            <Segment basic textAlign='center'>
            <p>¿Necesita solicitar un <AnchorTagButton onClick={resetView}>Nuevo código de verificación</AnchorTagButton>? o, ¿prefiere <Link to='/signin'>Iniciar Sesión</Link>?</p>
            </Segment>
          </Grid.Column>
        }

        {viewState === 'password-reset' &&
          <Grid.Column mobile={15} tablet={8} computer={6}>

            <GreatingsText key='reset-pass-email-text'>
              <h4>Ingrese su nueva contraseña</h4>
            </GreatingsText>

            <p style={{ color: '#6586cf', textAlign: 'center' }}>
              Recuerde que su contraseña debe tener una longitud de al menos 8 caracteres y poseer al menos un número.
            </p>

            <form
              onSubmit={handleResetPasswordSubmit}
              className={loading ? 'ui loading form' : ''}>
              <ResetPasswordInputs
                onChange={handleFormChange}
                onPasswordChange={handlePasswordChange}
                values={formData}
                passwordStrength={passwordStrength}
                confirmationMatchClassName={confirmationMatchClassName} />
              <BlueRoundButton fluid> Guardar Contraseña </BlueRoundButton>
            </form>
            <Segment basic textAlign='center'>
            <p>¿Necesita solicitar un <AnchorTagButton onClick={resetView}>Nuevo código de verificación</AnchorTagButton>? o, ¿prefiere <Link to='/signin'>Iniciar Sesión</Link>?</p>
            </Segment>
          </Grid.Column>
        }

        
      </Grid>
      {redirect}
    </div>
  )
}
export default ResetPasswordView