import React, { useContext } from "react";
import { SuperadminBillsHistoryContext } from "../../context";
import { BillsListEmpty, BillsListItem, BillsListHeader } from "./styles";
import { Loader } from "components/loader";
import { Pagination, EntitiesList } from "components/entities-list";
import moment from "moment";

export const BillsListItemModel = ({
  item,
  handleSelection,
  onDataDelete,
  className,
}) => {
  return (
    <ul className={className} onClick={handleSelection} id={item._id}>
      <li className="number">{item.bill_number || ""}</li>
      <li>{item.bill_name || ""}</li>
      <li>{item.bill_doc_id}</li>
      <li>{item.bill_type}</li>
      <li>{moment(item.emission_date).format("DD-MM-YYYY")}</li>
      <li
        className="number link"
        onClick={(event) => {
          event.stopPropagation();
          window.open(item.bill_url);
        }}
      >
        {item.bill_url}
      </li>
    </ul>
  );
};

export const BillsListItemPhoneModel = () => {
  return <div></div>;
};

export const BillsListHeaderModel = ({ className }) => {
  return (
    <ul className={className}>
      <li>Número</li>
      <li>Nombre</li>
      <li>Documento</li>
      <li>Tipo</li>
      <li>fecha</li>
      <li>link</li>
    </ul>
  );
};

export const BillsListHeaderPhoneModel = () => {
  return <div></div>;
};

export const BillsListEmptyModel = ({ className }) => {
  return <div className={className}>No se ha encontrado resultados</div>;
};

export const formattingBillsList = (
  billsList,
  viewState,
  handleBillSelection
) => {
  if (!billsList.length) return <BillsListEmpty />;
  return billsList.map((item) => {
    return (
      <BillsListItem
        item={item}
        key={item.bill_id}
        handleSelection={handleBillSelection}
      />
    );
  });
};

export const BillsList = ({ setArgs, setActivateFetch, loading }) => {
  const { state, changeState } = useContext(SuperadminBillsHistoryContext);

  const handlePageUp = (event) => {
    event.preventDefault();
    setArgs({
      ...state.historyForm,
      skip: state.currentPage + 1,
      limit: 10,
    });
    changeState({ currentPage: state.currentPage + 1 });
    setActivateFetch(true);
  };

  const handlePageDown = (event) => {
    event.preventDefault();
    setArgs({
      ...state.historyForm,
      skip: state.currentPage - 1,
      limit: 10,
    });
    changeState({ currentPage: state.currentPage - 1 });
    setActivateFetch(true);
  };

  const onBillSelection = (event) => {
    console.log("bill selected");
  };

  if (loading) return <Loader />;

  const ShowHeader = () => {
    const show = state.bills.length > 0 && !loading;
    if (!show) return null;
    return <BillsListHeader />;
  };
  return (
    <EntitiesList
      entities={state.bills}
      formattingFunction={formattingBillsList}
      selectionHandler={onBillSelection}
    >
      <Pagination
        padding="0 0 0.8rem 0"
        nextPageExist={state.nextPageExist}
        currentPage={state.currentPage}
        handlePageUp={handlePageUp}
        handlePageDown={handlePageDown}
      />
      <ShowHeader />
    </EntitiesList>
  );
};
