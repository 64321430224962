import React, { useEffect } from "react";
import { WalletTransferHistoryContext } from "../context";
import { useStateManager } from "hooks/use-state-manager";
import { usePopupMessages } from "hooks/use-popup-messages";
import { walletAPI } from "api";

export const WalletTransferHistoryState = ({ children }) => {
  const initialState = {
    transfers: [],
    loading: false,
    date_from: null,
    date_to: null,
    transaction_type: "",
    currentPage: 1,
    nextPageExist: false,
    skip: 0,
    limit: 11,
    submit: false,
    reference: "",
    selectedTransfer: null,
    showDetail: false,
    weekendDate: null,
    waitWeekendDate: true,
    setWeekendDate: false,
  };
  const [state, changeState] = useStateManager(initialState);
  const {
    popupMessages,
    popupMessagesType,
    showPopup,
    throwPopupMessage,
    closePopup,
  } = usePopupMessages(null, "", false);

  useEffect(() => {
    const checkHistory = async () => {
      changeState({ loading: true });
      const { date_from, date_to, transaction_type, reference } = state;
      try {
        const response = await walletAPI.bankTransferPdHistory({
          date_from,
          date_to,
          transaction_type,
          reference,
        });
        const res = await response.json();
        if (res.error) throw res.error;
        let transfers;
        if (res.bank_transactions) {
          transfers = [...res.bank_transactions];
        }
        changeState({ loading: false, transfers });
      } catch (error) {
        changeState({ loading: false });
        const errorMessage = error.message
          ? error.message
          : error.description
          ? error.description
          : "Ha ocurrido un error inesperado";
        throwPopupMessage("error", errorMessage);
      }
    };
    if (state.submit) {
      changeState({ submit: false });
      checkHistory();
    }
  }, [state.submit]);

  return (
    <WalletTransferHistoryContext.Provider
      value={{
        state,
        changeState,
        popupMessages,
        popupMessagesType,
        showPopup,
        throwPopupMessage,
        closePopup,
      }}
    >
      {children}
    </WalletTransferHistoryContext.Provider>
  );
};
