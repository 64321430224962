import React, { useContext } from "react";
import { Grid } from "semantic-ui-react";
import { PermissionsValidator } from "components/permissions-validator";
import PopupMessage from "components/popup-message";
import SearchInputNew from "components/search-input-new";
import { EntitiesList } from "components/entities-list";
import { users_admin as viewTexts } from "assets/strings/texts";
import { formatUserList } from "../components/user-list-item";
import { LogsPanel } from "./logs-form";
import { SwitchSlider } from "components/checkboxes";
import { Dropdown } from "components/dropdown";
import { formatMtdList } from "../components/mtd-list-item";
import { UsersLogsContext } from "../context";

const MPosUsersLogsPhoneView = () => {
  const {
    location,
    state,
    handleSearchType,
    handleSearchInputChange,
    handleSearchUser,
    handleBuildingChange,
    handleUserSelection,
    handleMtdSelection,
    popupMessages,
    popupMessagesType,
    showPopup,
    closePopup,
  } = useContext(UsersLogsContext);
  return (
    <Grid padded>
      {" "}
      <PermissionsValidator route={location.pathname} />
      <PopupMessage
        messageType={popupMessagesType}
        showPopup={showPopup}
        messages={popupMessages}
        closePopup={closePopup}
      />
      <Grid.Column width={16}>
        {state.viewState === "user_search" && [
          ...[
            state.category === "pagodirecto" && (
              <SwitchSlider
                margin={state.searchType === "by_user" ? "0" : "0 0 0.5rem 0"}
                selected={state.searchType}
                options={[
                  { data: "by_user", displayData: "Usuario" },
                  { data: "by_building", displayData: "Edificio" },
                ]}
                handleSelection={handleSearchType}
                key="switch"
              />
            ),
          ],
          ...[
            state.category === "pagodirecto" &&
              state.searchType === "by_user" && (
                <SearchInputNew
                  key="search-input"
                  placeholder={viewTexts.userSearchPlaceholder}
                  searchParam={state.searchParam}
                  handleChange={handleSearchInputChange}
                  handleSearch={handleSearchUser}
                />
              ),
          ],
          state.searchType === "by_building" && !state.loading && (
            <Dropdown
              fluid
              selection
              placeholder={"Edificio"}
              wrapSelection={true}
              options={state.buildingsOptions}
              value={state.selectedBuilding}
              disabled={state.loading}
              onChange={handleBuildingChange}
              textalign="center"
              key="drop"
            />
          ),
          <EntitiesList
            key="users-list"
            height="calc(100vh - 9rem)"
            entities={state.searchType === "by_user" ? state.users : state.mtds}
            formattingFunction={
              state.searchType === "by_user" ? formatUserList : formatMtdList
            }
            selectionHandler={
              state.searchType === "by_user"
                ? handleUserSelection
                : handleMtdSelection
            }
            additionalArgs={[state.loading]}
          />,
        ]}
        {state.viewState === "user_selection_done" && (
          <Grid padded>
            <LogsPanel phoneView />
          </Grid>
        )}
      </Grid.Column>
    </Grid>
  );
};

export default MPosUsersLogsPhoneView;
