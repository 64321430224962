import React from "react";
import { CSSTransition } from "react-transition-group";
import {
  PanelTitle,
  PanelSectionHeader,
  PanelSectionContent,
} from "components/panel";
import { Dropdown } from "components/dropdown";

const UserSelectedModel = ({
  user,
  view,
  loading,
  showUser,
  isSelected,
  handleEditRole,
  handleEditStatus,
  handleDeleteUser,
  handleRemove,
  roles,
  className,
}) => {
  const status = [
    {
      text: "Activo",
      value: "active",
      key: "active",
    },
    {
      text: "Inactivo",
      value: "inactive",
      key: "inactive",
    },
  ];
  if (loading) {
    return (
      <div
        className="ui active loader"
        style={{
          position: "absolute",
        }}
      ></div>
    );
  } else {
    return (
      <div className={className}>
        {!isSelected ? (
          <p> No has seleccionado ningún usuario</p>
        ) : (
          <CSSTransition
            in={showUser}
            timeout={350}
            appear={true}
            classNames="user"
          >
            <div className="user">
              <PanelTitle
                style={{
                  textTransform: "lowercase",
                  fontSize: "2rem",
                  marginTop: "1rem",
                }}
              >
                @{user && user.username ? user.username : ""}
              </PanelTitle>
              <PanelSectionHeader>{"Email"}:</PanelSectionHeader>
              <PanelSectionContent>
                {user && user.email ? user.email : ""}
              </PanelSectionContent>
              <PanelSectionHeader>{"Nombre"}:</PanelSectionHeader>
              <PanelSectionContent>
                {" "}
                {user && user.profile && user.profile.name
                  ? user.profile.name
                  : ""}
              </PanelSectionContent>
              <PanelSectionHeader>{"Apellido"}:</PanelSectionHeader>
              <PanelSectionContent>
                {" "}
                {user && user.profile && user.profile.last_name
                  ? user.profile.last_name
                  : "Sin registrar"}
              </PanelSectionContent>
              <PanelSectionHeader>{"Teléfono"}:</PanelSectionHeader>
              <PanelSectionContent>
                {" "}
                {user && user.profile && user.profile.phone
                  ? user.profile.phone
                  : ""}
              </PanelSectionContent>
              <PanelSectionHeader>{"Rol"}:</PanelSectionHeader>{" "}
              {user && user.commerce_role && user.commerce_role._id ? (
                <Dropdown
                  className="roles"
                  fluid
                  selection
                  name="role_id"
                  value={user.commerce_role._id}
                  options={roles}
                  onChange={handleEditRole}
                  //disabled={addUserLoading}
                />
              ) : (
                ""
              )}{" "}
              <PanelSectionHeader>{"Estado"}:</PanelSectionHeader>{" "}
              {user && user.status ? (
                <Dropdown
                  className="status"
                  fluid
                  selection
                  name="status"
                  value={user.status}
                  options={status}
                  onChange={handleEditStatus}
                  //disabled={addUserLoading}
                />
              ) : (
                ""
              )}{" "}
            </div>
          </CSSTransition>
        )}
        <button className="delete" onClick={handleDeleteUser}>
          Eliminar
        </button>
        {view === "phone" && (
          <button className="back" onClick={handleRemove}>
            Volver
          </button>
        )}
      </div>
    );
  }
};

export { UserSelectedModel };
