import ExtensionsListItemModel from "./model";
import styled from "styled-components";
import { grey_6, grey_4, grey_2, green_6 } from "assets/strings/colors";

const ExtensionsListItem = styled(ExtensionsListItemModel)`
  #root && {
    margin: 0;
    border-bottom: solid 1px ${grey_4};
    height: 8vh;
    cursor: pointer;
    > .data {
      margin: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;
      > .status {
        margin: 0;
        height: calc(100% - 4px);
        width: 0.5rem;
        background: ${(props) => (props.status === "active" ? green_6 : "red")};
      }
      > .name {
        color: #63c0e8;
        padding-left: 1rem;
        width: calc(50% - 0.5rem);
        font-size: 1.2rem;
        font-weight: bold;
        margin: 0;
        overflow: hidden;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      > .carnet {
        color: ${grey_6};
        width: calc(50% - 0.5rem);
        padding-right: 1rem;
        font-size: 1.2rem;
        font-weight: bold;
        margin: 0;
        overflow: hidden;
        text-align: right;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    :hover {
      background: ${grey_2};
    }
  }
`;
export default ExtensionsListItem;
