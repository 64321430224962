//React and hooks
import React, { useEffect } from "react";
import { errors } from "assets/strings/texts";
import { useViewType } from "hooks/use-view-type";
import { useAmountInput } from "hooks/use-inputs";
import { useStateManager } from "hooks/use-state-manager";
import { usePopupMessages } from "hooks/use-popup-messages";
//Redux
import { connect } from "react-redux";
import { clearReduxStore } from "redux/actions/general";
import { addBuildings, selectBuilding } from "redux/actions/buildings";
import { addWorkspace } from "redux/actions/session";
import {
  getSelectedBuilding as getSelectedBuildingFromRedux,
  getSelectedCommerce as getSelectedCommerceFromRedux,
} from "redux/selectors";
//Services
import { updateBuildingRate } from "services/api";
import { formatPrice } from "services/utils";
//Components
import { MposParkingSettingsView } from "./components/mpos-parking-settings-view";
import { MposParkingSettingsPhoneView } from "./components/mpos-parking-settings-phone-view";

const MposParkingSettings = (props) => {
  let building = props.selectedBuilding;
  let activeMode =
    building && building.billing && building.billing.active_mode
      ? building.billing.active_mode
      : "FLAT";
  let currency = building.billing.billing_currency || "BS";
  let billingArray =
    building && building.billing && building.billing.billing_mode
      ? building.billing.billing_mode
      : [];
  let billingObj = {};

  if (billingArray.length >= 0) {
    billingArray.map((billing_type) => {
      if (billing_type.tag === activeMode) {
        billingObj = billing_type;
      }
      return null;
    });
  }

  const initialState = {
    activeMode,
    loading: false,
    billingObj,
    currency,
    flatSubmit: false,
    customSubmit: false,
    perHourSubmit: false,
    building,
    //
  };

  const [state, changeState] = useStateManager(initialState);

  const {
    amount: flatAmount,
    handle: handleInputFlatAmount,
    amountNumber: flatAmountNumber,
  } = useAmountInput(
    formatPrice(billingObj.flat_amount ? billingObj.flat_amount : 0)
  );

  const {
    amount: hourAmount,
    handle: handleInputHourAmount,
    amountNumber: hourAmountNumber,
  } = useAmountInput(
    formatPrice(billingObj.hour_amount ? billingObj.hour_amount : 0)
  );

  const {
    amount: secondHourAmount,
    handle: handleInputSecondHourAmount,
    amountNumber: secondHourAmountNumber,
  } = useAmountInput(
    formatPrice(
      billingObj.second_hour_amount ? billingObj.second_hour_amount : 0
    )
  );

  const {
    amount: thirdHourAmount,
    handle: handleInputThirdHourAmount,
    amountNumber: thirdHourAmountNumber,
  } = useAmountInput(
    formatPrice(billingObj.third_hour_amount ? billingObj.third_hour_amount : 0)
  );

  const {
    amount: extraAmount,
    handle: handleInputExtraAmount,
    amountNumber: extraAmountNumber,
  } = useAmountInput(
    formatPrice(billingObj.extra_amount ? billingObj.extra_amount : 0)
  );

  const {
    amount: overnightAmount,
    handle: handleInputOvernightAmount,
    amountNumber: overnightAmountNumber,
  } = useAmountInput(
    formatPrice(billingObj.overnight_amount ? billingObj.overnight_amount : 0)
  );

  const [viewType, setViewType] = useViewType("desktop");
  const {
    popupMessages,
    popupMessagesType,
    showPopup,
    throwPopupMessage,
    closePopup,
  } = usePopupMessages(null, "", false);

  //component did mount
  useEffect(() => {
    setViewType();
    window.addEventListener("resize", setViewType);
    return () => {
      window.removeEventListener("resize", setViewType);
    };
  }, []);

  useEffect(() => {
    const updateRate = async (reqObj) => {
      try {
        changeState({ loading: true });
        const response = await updateBuildingRate(reqObj);
        const res = await response.json();

        if (res.error) throw res.error;
        props.addBuildings([res.building]);
        props.selectBuilding(res.building._id);
        props.addWorkspace(res.building._id);
        throwPopupMessage("success", "¡Tarifa actualizada exitosamente!");
        changeState({ loading: false });
      } catch (error) {
        if (
          error.id === "NO_TOKEN_PROVIDED" ||
          error.id === "AUTHENTICATE_FAILED" ||
          error.id === "DUPLICATE_SESSION"
        ) {
          throwPopupMessage("alert", errors.AUTHENTICATION);
          //this.props.clearReduxStore();
          //return;
        } else {
          throwPopupMessage("error", error.description);
          changeState({ loading: false });
        }
      }
    };
    let formattedData = {};
    formattedData.building_id = state.building._id;
    if (state.flatSubmit) {
      formattedData.flat_amount = flatAmountNumber;
      if (billingObj.overnight) {
        formattedData.overnight_amount = overnightAmountNumber;
      }
      updateRate(formattedData);
      changeState({ flatSubmit: false });
    }
    if (state.customSubmit) {
      formattedData.hour_amount = hourAmountNumber;
      formattedData.second_hour_amount = secondHourAmountNumber;
      formattedData.third_hour_amount = thirdHourAmountNumber;
      formattedData.extra_amount = extraAmountNumber;
      formattedData.overnight_amount = overnightAmountNumber;
      formattedData.flat_amount = flatAmountNumber;
      updateRate(formattedData);
      changeState({ customSubmit: false });
    }
    if (state.perHourSubmit) {
      formattedData.hour_amount = hourAmountNumber;
      overnightAmountNumber !== 0
        ? (formattedData.overnight_amount = overnightAmountNumber)
        : (formattedData.overnight_amount = null);
      updateRate(formattedData);
      changeState({ perHourSubmit: false });
    }

    return () => {};
  }, [state.flatSubmit || state.customSubmit || state.perHourSubmit]);

  //flat handler to submit
  const handleFlatSubmit = () => {
    changeState({ loading: true, flatSubmit: true });
  };

  //custom handler to submit
  const handleCustomSubmit = () => {
    changeState({ loading: true, customSubmit: true });
  };

  //custom handler to submit
  const handlePerHourSubmit = () => {
    changeState({ loading: true, perHourSubmit: true });
  };

  return viewType === "phone" ? (
    <MposParkingSettingsPhoneView
      currency={state.currency}
      buildingName={building.commercial_name}
      loading={state.loading}
      activeMode={state.activeMode}
      billingObj={state.billingObj}
      // extra amount
      flatAmount={flatAmount}
      hourAmount={hourAmount}
      secondHourAmount={secondHourAmount}
      thirdHourAmount={thirdHourAmount}
      extraAmount={extraAmount}
      overnightAmount={overnightAmount}
      //flat form
      //perHour form
      handleInputFlatAmount={handleInputFlatAmount}
      handleInputHourAmount={handleInputHourAmount}
      handleInputSecondHourAmount={handleInputSecondHourAmount}
      handleInputThirdHourAmount={handleInputThirdHourAmount}
      handleInputExtraAmount={handleInputExtraAmount}
      handleInputOvernightAmount={handleInputOvernightAmount}
      //
      handleFlatSubmit={handleFlatSubmit}
      handlePerHourSubmit={handlePerHourSubmit}
      handleCustomSubmit={handleCustomSubmit}
      //popupMessages
      popupMessagesType={popupMessagesType}
      showPopup={showPopup}
      popupMessages={popupMessages}
      closePopup={closePopup}
    />
  ) : (
    <MposParkingSettingsView
      currency={state.currency}
      buildingName={building.commercial_name}
      loading={state.loading}
      activeMode={state.activeMode}
      billingObj={state.billingObj}
      // extra amount
      flatAmount={flatAmount}
      hourAmount={hourAmount}
      secondHourAmount={secondHourAmount}
      thirdHourAmount={thirdHourAmount}
      extraAmount={extraAmount}
      overnightAmount={overnightAmount}
      //flat form
      handleInputFlatAmount={handleInputFlatAmount}
      handleInputHourAmount={handleInputHourAmount}
      handleInputSecondHourAmount={handleInputSecondHourAmount}
      handleInputThirdHourAmount={handleInputThirdHourAmount}
      handleInputExtraAmount={handleInputExtraAmount}
      handleInputOvernightAmount={handleInputOvernightAmount}
      //
      handleFlatSubmit={handleFlatSubmit}
      handlePerHourSubmit={handlePerHourSubmit}
      handleCustomSubmit={handleCustomSubmit}
      //popupMessages
      popupMessagesType={popupMessagesType}
      showPopup={showPopup}
      popupMessages={popupMessages}
      closePopup={closePopup}
    />
  );
};

const mapStateToProps = (state) => {
  let selectedBuilding = getSelectedBuildingFromRedux(state);
  let selectedCommerce = getSelectedCommerceFromRedux(state);
  return { selectedBuilding, selectedCommerce };
};

const mapDispatchToProps = {
  clearReduxStore,
  addBuildings,
  selectBuilding,
  addWorkspace,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MposParkingSettings);
