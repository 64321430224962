import React, { useContext } from "react";
import { UsersReportContext } from "../../context";
import { Dropdown } from "components/dropdown";
import { PanelSectionHeader } from "components/panel";

const BuildingOptions = ({ className }) => {
  const { state, changeState } = useContext(UsersReportContext);

  const onChangeBuilding = (event, data) => {
    const buildingSelected = data.value;
    changeState({ buildingSelected });
  };

  if (!state.isSuperadmin) return null;
  return (
    <div className={className}>
      <PanelSectionHeader className={"title"}>
        Seleccione el edificio:
      </PanelSectionHeader>
      <Dropdown
        fluid
        selection
        search
        onChange={onChangeBuilding}
        options={state.buildingOptions}
        value={state.buildingSelected}
        className={"selection"}
      />
    </div>
  );
};

export default BuildingOptions;
