import UserListItemModel, { UsersListModel } from "./model";
import styled from "styled-components";
import { grey_4, bluePD } from "assets/strings/colors";

const UserListItem = styled(UserListItemModel)`
    height: 9vh;
    min-height: 63px;
    border: solid 1px ${grey_4};
    border-radius: 5rem;
    cursor: pointer;
    padding: 1rem 0 ;
    margin: 0.5rem 0;
    position: relative;
    background: white;
    display:flex;
    justify-content:  space-between;
    align-items: center;
    >.img{
      vertical-align: middle;
      margin-left: clamp(1rem, 1.2rem, 2rem);
      height: 25px;
      border-radius: 200px;
    }
    >.data{
      display: inline-block;
      vertical-align: middle;
      margin-right: clamp(1rem, 1.2rem, 2rem);
      align-self: right;
      text-align: right;
      width: calc(85% - 30px - 1rem);
      >.username{
        color: ${bluePD};
        font-size: 1rem;
        font-weight: bold;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      >.email,
      >.idNumber{
        color: #454545;
        font-size: .8rem;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
`;

const UsersList = styled(UsersListModel)`
  #root && {
    width: ${(props) => {
      return props.viewType === "phone" ? "80%" : "20%";
    }};
    display: flex;
    flex-direction: column;
    border-top: 1px solid ${bluePD};
  }
`;
export default UserListItem;
export { UsersList };
