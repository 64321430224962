import { getToken } from "./config";
import { jsonToGetQuery } from "./utils";

export function post(endpoint, body) {
  const token = getToken();
  return fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
    body: JSON.stringify(body),
  });
}

export function patch(endpoint, body) {
  const token = getToken();
  return fetch(endpoint, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      "x-access-token": token,
    },
    body: JSON.stringify(body),
  });
}

export function postFormData(endpoint, formData) {
  const token = getToken();

  return fetch(endpoint, {
    method: "POST",
    headers: {
      "x-access-token": token,
    },
    body: formData,
  });
}

export function get(endpoint, queryParams = {}, auth = true) {
  const query = jsonToGetQuery(queryParams);
  let objectParams = {
    method: "GET",
  };

  if (auth) {
    const token = getToken();
    objectParams.headers = { "x-access-token": token };
  }

  return fetch(`${endpoint}${query}`, {
    ...objectParams,
  });
}
