import React, { Component } from "react";
import { connect } from "react-redux";
import { getSelectedCommerce } from "redux/selectors";
import { Panel } from "components/panel";
import { Button } from "components/buttons";
import { general as texts } from "assets/strings/texts";
import { clearReduxStore } from "redux/actions/general";
import { throwPopupMessage, closePopup } from "redux/actions/popup-messages";
import { EntitiesList /* Pagination */ } from "components/entities-list";
//import { commerces_admin as viewTexts } from "assets/strings/texts";
import { formatUsersPhoneList } from "../users-list-item";
import { getViewMposCommerces } from "redux/selectors/view-mpos-commerces";
import { listOperationalUsers, resetOperationalPassword } from "services/api";
import { errors } from "assets/strings/texts";
import { UserResetConfirm } from "../user-reset-confirm";
import {
  setViewState,
  selectUser,
  updateUsersList,
} from "redux/actions/view-mpos-commerces";

class UsersPanel_unconnected extends Component {
  constructor(props) {
    super(props);
    let commerceSessionId = this.props.session._id;
    this.state = {
      commerceSessionId,
      operationalUsers: [],
      selectedUser: null,
      authError: false,
    };
  }
  componentDidMount() {
    this.props.setViewState("commerce_getusers_loading");
    listOperationalUsers(this.props.selectedCommerce._id)
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((res) => {
        this.props.updateUsersList(res.users);
        this.props.setViewState("commerce_getusers_done");
        this.props.selectUser(null);
      })
      .catch((err) => {
        this.props.setViewState("commerce_info");
        if (typeof err.json === "function") {
          err.json().then((err_body) => {
            // If it is an authentication error, clear the redux-store to close the session
            this.props.updateUsersList([]); // empty list
            this.props.setViewState("commerce_users");
            this.props.selectUser(null);
            if (
              err_body.error.id === "NO_TOKEN_PROVIDED" ||
              err_body.error.id === "AUTHENTICATE_FAILED" ||
              err_body.error.id === "DUPLICATE_SESSION"
            ) {
              this.props.throwPopupMessage("alert", errors.AUTHENTICATION);
              return;
              //this.props.clearReduxStore();
              //return; // prevent setState exeution on unmounted component
            }
            this.props.throwPopupMessage("error", err_body.error.description);
          });
        } else {
          this.props.throwPopupMessage("error", errors.GENERAL_ERR);
        }
      });
  }
  resetPasswordRequest = (event) => {
    event.preventDefault();
    const reset = async () => {
      const userId = this.props.selectedUser._id;
      try {
        this.props.closePopup();
        this.props.setViewState("commerce_getusers_loading");
        const response = await resetOperationalPassword(userId);
        const res = response.json();
        this.props.setViewState("commerce_getusers_done");
        if (res.error) throw res.error;
        this.props.throwPopupMessage("success", "contraseña reestablecida");
      } catch (error) {
        // If it is an authentication error, clear the redux-store to close the session
        this.props.updateUsersList([]); // empty list
        this.props.setViewState("commerce_info");
        this.props.selectUser(null);
        this.props.throwPopupMessage("error", errors.GENERAL_ERR);
      }
    };

    reset();
  };

  handleResetPassword = (event) => {
    event.preventDefault();
    const user = JSON.parse(event.target.value);
    this.props.selectUser(user);
    this.props.throwPopupMessage(
      "",
      <UserResetConfirm
        username={user.username}
        handleApprove={this.resetPasswordRequest}
        handleCancel={this.props.closePopup}
      />
    );
  };
  removeUsersPanel = () => {
    this.props.setViewState("commerce_info");
  };
  render() {
    const {
      //viewType,
      viewState,
      selectedUser,
      users,
    } = this.props;

    return (
      <Panel>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            marginBottom: "0",
            borderBottom: "1px solid #abacad",
          }}
        >
          <p
            style={{
              textAlign: "center",
              fontSize: "1.3rem",
              marginBottom: "0",
            }}
          >
            Usuarios operacionales
          </p>
          <Button
            color="grey"
            margin="0 0 1rem 0"
            width="20%"
            onClick={this.removeUsersPanel}
          >
            {texts.back}
          </Button>
        </div>
        <EntitiesList
          margin="0"
          height="calc(80vh - 4rem)"
          viewState={viewState}
          entities={users}
          formattingFunction={formatUsersPhoneList}
          selectionHandler={this.handleResetPassword}
          additionalArgs={[selectedUser]}
        />
      </Panel>
    );
  }
}

const mapStateToProps = (state) => ({
  session: getSelectedCommerce(state),
  viewType: state.responsiveData.viewType,
  users: getViewMposCommerces(state).users,
  viewState: getViewMposCommerces(state).viewState,
  selectedUser: getViewMposCommerces(state).selectedUser,
  selectedCommerce: getViewMposCommerces(state).selectedCommerce,
});

const mapDispatchToProps = {
  setViewState,
  selectUser,
  updateUsersList,
  clearReduxStore,
  throwPopupMessage,
  closePopup,
};

const UsersPanel = connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersPanel_unconnected);
export { UsersPanel };
