import React from "react";
import { Button } from "components/buttons";
import { PanelTitle } from "components/panel";
import { Dropdown } from "components/dropdown";
import { CSSTransition } from "react-transition-group";
import { element_transition_time } from "assets/strings/constants";
import {
  general as texts,
  recharges_recharge as viewTexts,
} from "assets/strings/texts";

const RechargeFormModel = ({
  viewType,
  viewState,
  selectedUser,
  bankNameSelected,
  rechargeAmounts,
  bankList,
  rechargeRef,

  showOtherAmountField,
  dropdownValueToShow,

  desiredRechargeAmount,
  handleAmountSelect,
  handleBankSelect,
  handleOtherAmount,
  handleReference,
  removeSelectedUser,
  toggleRechargeConfirmation,
  className,
}) => {
  let idNumber = "";
  if (selectedUser) {
    if (
      selectedUser.profile &&
      selectedUser.profile.identification &&
      selectedUser.profile.identification.doc_id &&
      selectedUser.profile.identification.doc_type
    ) {
      idNumber =
        selectedUser.profile.identification.doc_type +
        "-" +
        selectedUser.profile.identification.doc_id;
    }
  }
  return (
    <CSSTransition
      in={
        viewState === "user_selection_done" ||
        viewState === "recharge_confirmation" ||
        viewState === "recharge_loading" ||
        viewState === "recharge_done"
      }
      mountOnEnter
      timeout={element_transition_time}
      appear={true}
      classNames="form"
    >
      <form className={className} onSubmit={toggleRechargeConfirmation}>
        <PanelTitle>{viewTexts.rechargeFormTitle}</PanelTitle>
        <p className="title">usuario</p>
        <p className="username">@{selectedUser.username}</p>
        <p className="text">{selectedUser.email}</p>
        <p className="text">{idNumber}</p>
        <div className="input-section">
          <label htmlFor="ticket-id">{viewTexts.desiredAmount}</label>
          <Dropdown
            fluid
            floating
            selection
            id="desiredRechargeAmount"
            disabled={viewState === "recharge_validation_loading"}
            value={dropdownValueToShow}
            onChange={handleAmountSelect}
            options={rechargeAmounts}
          />

          {/* This will be shown if 'Otro monto' is selected in dropdown options */}
          {showOtherAmountField && (
            <input
              id="amount"
              type="text"
              min="0"
              name="amount"
              autoComplete="off"
              placeholder="Monto"
              value={desiredRechargeAmount}
              onChange={handleOtherAmount}
            />
          )}
          <label htmlFor="bank-id">{viewTexts.bankList}</label>
          <Dropdown
            fluid
            floating
            selection
            id="desiredRechargeAmount"
            disabled={viewState === "recharge_validation_loading"}
            value={bankNameSelected}
            onChange={handleBankSelect}
            options={bankList}
          />

          <input
            id="ref"
            type="text"
            min="0"
            name="ref"
            autoComplete="off"
            placeholder="Referencia"
            value={rechargeRef}
            onChange={handleReference}
          />
        </div>
        <Button
          color="blue"
          type="submit"
          disabled={
            viewState === "recharge_confirmation" ||
            viewState === "recharge_loading" ||
            viewState === "recharge_done"
          }
        >
          {viewTexts.recharge}
        </Button>
        {viewType === "phone" && (
          <Button
            color="grey"
            disabled={
              viewState === "recharge_confirmation" ||
              viewState === "recharge_loading" ||
              viewState === "recharge_done"
            }
            margin="1rem 0 0 0 "
            onClick={removeSelectedUser}
          >
            {texts.back}
          </Button>
        )}
      </form>
    </CSSTransition>
  );
};

export { RechargeFormModel };
