import React, { useContext } from "react";
import { UsersExtensionsContext } from "../../context";
import { Button } from "components/buttons";
import { Loader } from "components/loader";
import { useEditExtension } from "../../services/edit-extension";
import { useAddNewExtension } from "../../services/new-extension";

const OptionConfirmationModel = ({ className }) => {
  const { state, changeState, closePopup } = useContext(UsersExtensionsContext);
  const { loading: editLoading, setActivate } = useEditExtension();
  const { loading: addLoading, setActivate: addActivate } =
    useAddNewExtension();

  const loading = editLoading || addLoading;

  const handleConfirmation = (event) => {
    event.preventDefault();
    if (state.optionName === "updateExtension") {
      setActivate(true);
    }
    if (state.optionName === "newExtension") addActivate(true);
    //changeState({ waitExtensions: true });
    //closePopup();
  };

  const closePopupOption = (event) => {
    event.preventDefault();
    changeState({
      viewState: "user_selected",
    });
    closePopup();
  };
  if (state.viewState !== "submit") return null;
  if (loading) return <Loader />;
  return (
    <div className={className}>
      <p>{state.message}</p>
      <Button color="green_6" width="80%" onClick={handleConfirmation}>
        Aceptar
      </Button>
      <Button color="grey_6" width="80%" onClick={closePopupOption}>
        Cancelar
      </Button>
    </div>
  );
};

export { OptionConfirmationModel };
