import React, { useEffect } from "react";
import { Dropdown } from "components/dropdown";
import { useStateManager } from "hooks/use-state-manager";
import { search } from "./services";

const TollsSelectionModel = ({ onChangeTolls, className }) => {
  const initialState = {
    options: [],
    loading: false,
  };
  const [state, changeState] = useStateManager(initialState);

  const onChange = (event, data) => {
    changeState({ tolls: [...data.value] });
    onChangeTolls([...data.value]);
  };
  useEffect(() => {
    search(changeState);
  }, []);

  return (
    <div className={className}>
      <Dropdown
        placeholder="Peajes"
        search={(options) => (options ? options : [])}
        selection
        loading={state.loading}
        id="tolls"
        multiple
        options={state.options}
        onChange={onChange}
        noResultsMessage={
          state.loading ? "Consultando..." : "No se encontraron resultados"
        }
        style={{ width: "100%" }}
        searchInput={{ autoComplete: "off" }}
      />
    </div>
  );
};

export default TollsSelectionModel;
