import React from "react";
import WalletAccountsContext from "./context";
import { useStateManager } from "hooks/use-state-manager";
import { usePopupMessages } from "hooks/use-popup-messages";
import usePropsMonitor from "../hooks/usePropsMonitor";

const WalletAccountsState = ({
  walletAccounts,
  viewType,
  loadingAccounts,
  setFetchAccounts,
  transactionTags,
  children,
}) => {
  const initialState = {
    onMount: true,
    viewState: "default",
    detailToShow: "movement",
    loading: false,
    accountSelected:
      walletAccounts && walletAccounts.length ? walletAccounts[0] : null,
    accountOptions: [],
    accountOptionSelected: null,
    accountsMappedInOptions: [],
    getMovements: false,
    loadingMovements: false,
    movements: [],
    movementsPerPage: 10,
    pagination: {
      limit: 10,
      skip: 1,
      currentPage: 1,
      nextPageExist: false,
    },
    searchParams: {
      searchType: "latests",
      type: "",
      from_date: null,
      end_date: null,
      currency: "BS",
      transaction_tag: [],
      counterpart_id: "",
      commerce_counterpart_id: "",
    },
  };

  const [state, changeState] = useStateManager(initialState);

  const {
    popupMessages,
    popupMessagesType,
    showPopup,
    throwPopupMessage,
    closePopup,
  } = usePopupMessages(null, "", false);

  const handleBack = (event) => {
    event.preventDefault();
    changeState({ showRightSide: false });
  };

  const handleAhead = (event) => {
    event.preventDefault();
    changeState({ showRightSide: true });
  };

  usePropsMonitor({ walletAccounts, state, throwPopupMessage, changeState });
  return (
    <WalletAccountsContext.Provider
      value={{
        state,
        walletAccounts,
        viewType,
        changeState,
        setFetchAccounts,
        popupMessages,
        popupMessagesType,
        showPopup,
        throwPopupMessage,
        closePopup,
        handleBack,
        handleAhead,
        loadingAccounts,
        transactionTags,
      }}
    >
      {children}
    </WalletAccountsContext.Provider>
  );
};

export default WalletAccountsState;
