import styled from "styled-components";
import { UserListItemModel, NoUsersFoundModel } from "./model";
import { grey_4, bluePD } from "assets/strings/colors";

const UsersPhoneListItem = styled(UserListItemModel)`
  #root & {
    display: flex;
    margin: 0;
    font-size: 0.85rem;
    height: 5rem;
    border-radius: 0.2rem;
    border-bottom: 1px solid ${grey_4};
    width: 100%;
    > .item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      > .username {
        color: ${bluePD};
        font-size: 1.1rem;
        width: 50%;
      }
    }
  }
`;

const NoUsersFound = styled(NoUsersFoundModel)`
  #root & {
    text-align: center;
    font-size: 1.2rem;
    line-height: 62px;
  }
`;

export { NoUsersFound, UsersPhoneListItem };
