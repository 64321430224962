import React from "react";
import { Link } from "react-router-dom";
import { Grid, Segment } from "semantic-ui-react";
import Navbar from "components/navbar";
import {
  CommercesSignupFirstFormInputs,
  CommercesSignupSecondFormInputs,
  CommerceInfoFormInputsPart3,
} from "components/register-user-form-inputs";
import CountrySelector from "components/country-selector";
import { NewBlueRoundButton, GreyRoundButton } from "components/round-button";
import GreatingsText from "components/greatings-text";
import { posDirectoBlue } from "assets/strings/colors";

const SignupView = ({
  viewState,
  loading,
  passwordStrength,
  formData,
  parkingDesired,
  buildings,
  redirect,
  handleBack,
  handleChange,
  handleLoginChange,
  handleBuildingChange,
  handleUsernameChange,
  handleShortNameChange,
  handleDropdown,
  handlePhoneChange,
  handleNumberChange,
  handleNewPasswordChange,
  handleCheckBox,
  handlePrimaryColorChange,
  handleSecondaryColorChange,
  handleEnableParking,
  handleNextView,
  handleSubmit,
  customName,
  localNumber,
}) => {
  return (
    <div>
      <Navbar
        title={
          <span>
            Crea gratis una cuenta de <b>Pago</b>Directo
          </span>
        }
        bgColors={[posDirectoBlue, posDirectoBlue]}
      />

      <Grid centered padded>
        <Grid.Column mobile={15} tablet={8} computer={6}>
          {viewState === "first_form" && (
            <GreatingsText>
              <h4>Empecemos</h4>
              <p>La manera más inteligente de llevar tu negocio</p>
            </GreatingsText>
          )}

          {viewState === "first_form" && (
            <form
              onSubmit={handleNextView}
              className={loading ? "ui loading form" : ""}
            >
              <p style={{ textAlign: "center" }}>
                <b>NOTA:</b> El <b>Prefijo del Comercio</b> debe contener al
                menos <b>2</b> caracteres. Funciona para identificar a los
                usuarios asociados a tu comercio tales como operadores,
                supervisores y gerentes.
              </p>
              <CommercesSignupFirstFormInputs
                handleChange={handleChange}
                handleLoginChange={handleLoginChange}
                handleUsernameChange={handleUsernameChange}
                handleShortNameChange={handleShortNameChange}
                handlePhoneChange={handlePhoneChange}
                handleNewPasswordChange={handleNewPasswordChange}
                passwordStrength={passwordStrength}
                values={formData}
              />
              <p style={{ textAlign: "center" }}>
                <b>
                  Su contraseña debe tener una longitud de al menos 8 caracteres
                  y al menos un número.
                </b>
              </p>
              <p style={{ textAlign: "center" }}>
                El <b>Pseudónimo</b> es lo que verán los usuarios cuando te
                busquen en <b>Pago</b>Directo
              </p>

              <CountrySelector margins="0 auto 2.87vh auto" />
              <p
                style={{ textAlign: "center", fontSize: "1.1rem" }}
                onClick={() => handleCheckBox("agree_with_terms")}
              >
                <input
                  type="checkbox"
                  id="agree"
                  checked={formData.agree_with_terms}
                  onChange={() => {}} //To fix warning
                />
                <label style={{ marginLeft: "1rem" }} htmlFor="agree">
                  Marcando está casilla usted afirma haber leído y aceptado los{" "}
                  <Link to="/policies?type=general" target="_blank">
                    términos y condiciones
                  </Link>{" "}
                  y las{" "}
                  <Link to="/policies?type=privacy-policies" target="_blank">
                    políticas de privacidad
                  </Link>{" "}
                  de nuestro servicio.
                </label>
              </p>
              <NewBlueRoundButton
                fluid
                disabled={formData.agree_with_terms ? false : true}
              >
                Siguiente
              </NewBlueRoundButton>
            </form>
          )}
          {viewState === "second_form" && (
            <GreatingsText>
              <h4>Continúa</h4>
            </GreatingsText>
          )}
          {viewState === "second_form" && (
            <form
              onSubmit={handleNextView}
              className={loading ? "ui loading form" : ""}
            >
              <CommercesSignupSecondFormInputs
                handleChange={handleChange}
                handleDropdown={handleDropdown}
                handleNumberChange={handleNumberChange}
                values={formData}
              />
              <p
                style={{ textAlign: "center", fontSize: "1.1rem" }}
                onClick={() => handleEnableParking()}
              >
                <input
                  type="checkbox"
                  id="agree"
                  checked={parkingDesired}
                  onChange={handleEnableParking}
                />
                <label
                  style={{ marginLeft: "1rem", fontSize: "1rem" }}
                  htmlFor="agree"
                >
                  Marque esta casilla para habilitar el servicio de
                  estacionamientos
                </label>
              </p>
              <NewBlueRoundButton fluid>Siguiente</NewBlueRoundButton>
            </form>
          )}
          {viewState === "third_form" && (
            <GreatingsText>
              <h4>Ya casi Terminas</h4>
            </GreatingsText>
          )}

          {viewState === "third_form" && (
            <form
              onSubmit={handleSubmit}
              className={loading ? "ui loading form" : ""}
            >
              <CommerceInfoFormInputsPart3
                handleChange={handleChange}
                handleDropdown={handleDropdown}
                handleNumberChange={handleNumberChange}
                handleBuildingChange={handleBuildingChange}
                handlePrimaryColorChange={handlePrimaryColorChange}
                handleSecondaryColorChange={handleSecondaryColorChange}
                buildings={buildings}
                parkingDesired={parkingDesired}
                colors={formData.colors}
                values={formData}
                customName={customName}
                localNumber={localNumber}
              />
              <NewBlueRoundButton fluid>Crear Cuenta</NewBlueRoundButton>
            </form>
          )}
          {(viewState === "second_form" || viewState === "third_form") && (
            <GreyRoundButton onClick={handleBack} fluid>
              Atrás
            </GreyRoundButton>
          )}
          <Segment basic textAlign="center">
            <p>
              ¿Ya posee una cuenta? <Link to="/signin">Inicie Sesión</Link>
            </p>
          </Segment>
        </Grid.Column>
      </Grid>
      {redirect}
    </div>
  );
};

export default SignupView;
