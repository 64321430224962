import React from "react";
import TransfersForm from "../components/transfers-form";
import TransfersList from "../components/transfers-list";

const TransferFormAndListContainer = () => {
  return (
    <>
      <TransfersForm />
      <TransfersList />
    </>
  );
};

export default TransferFormAndListContainer;
