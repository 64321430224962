import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import "moment/locale/es-us";
import { formatPrice, getAccessData } from "services/utils";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import imageCrown from "assets/img/king.png";
import successHand from "assets/img/success_hand_ticket.png";
import { Menu } from "semantic-ui-react";
import { DropdownSimple } from "components/dropdown";
import { Card, Icon, /* Image, */ Grid } from "semantic-ui-react";

moment.locale("es-us");

const Ticket = ({
  state,
  category,
  ticketData,
  showTicket,
  ticketOptions,
  removeTicket,
  phoneView,
  handleOptionsSelection,
  className,
}) => {
  if (!ticketData) {
    return (
      <div className={className + " noTicket"}>
        <p> No ha seleccionado ningún ticket </p>
      </div>
    );
  }
  const currentState = state ? { ...state } : null;
  const activateUserUrl =
    category === "pagodirecto" &&
    ((state.fromDate && state.endDate && state.searchType === "dateRange") ||
      state.searchType === "ticket#");
  if (activateUserUrl && state.searchType === "dateRange") {
    currentState.fromDate = moment(state.fromDate).format("DD/MM/YYYY");
    currentState.endDate = moment(state.endDate).format("DD/MM/YYYY");
  }

  const {
    status: incommingStatus,
    type: defaultType,
    ticket_type: ticketType,
    premium_type: premiumType,
    building: buildingData,
    ticket_number: ticketNumber,
    reversed: reversedObj,
    total_amount: totalAmount,
    enter_at: enterAt,
    created_at: createdAt,
    exit_at: exitAt,
    paid_at: paidAt,
    paid_by: paidBy,
    building_entry: entryCode,
    building_exit: exitCode,
    digitize_by: digitizeBy,
    flow_payment: incommingFlowPayment,
    new_user: newUser,
    username,
  } = ticketData;
  const {
    parking_name: buildingName,
    accesses,
    address: buildingAddress,
  } = buildingData;

  const ticketStatus = {
    ready: "Pago Pendiente",
    paid: "Pagado",
    incomplete: "Incompleto",
    closed: "Cerrado",
    closed_superadmin: "Cerrado (Habilitado por ATC)",
    closed_premium_no_transaction: "Cerrado (puestos fijos)",
  };

  const ticketTypes = {
    regular: "regular",
    premium: "premium",
    prized: "premiado",
    recommended: "recomendación",
    promotioned: "promoción",
    monthly_premium: "puesto fijo",
  };

  const status = ticketStatus[incommingStatus] || ticketStatus.closed;
  const typeToAnalize = ticketType
    ? ticketType
    : premiumType
    ? "premium"
    : defaultType;
  const type = ticketTypes[typeToAnalize] || ticketTypes.regular;

  const wasReversed = reversedObj && reversedObj.was_reversed;
  const rate = `Bs.S ${
    Number(totalAmount) ? formatPrice(totalAmount) : "0,00"
  }`;

  let entry = {
    date: enterAt ? moment(enterAt).format("DD / MM / YYYY") : "--",
    time: enterAt ? moment(enterAt).format("LT") : "--",
    create_date: createdAt ? moment(createdAt).format("DD / MM / YYYY") : "--",
    create_time: createdAt ? moment(createdAt).format("LT") : "--",
    name:
      digitizeBy && digitizeBy.user_commerce
        ? `Digitalizado por ${digitizeBy.user_commerce}`
        : "Digitalizado",
    track: "--",
  };
  let exit = {
    date: exitAt ? moment(exitAt).format("DD / MM / YYYY") : "--",
    time: exitAt ? moment(exitAt).format("LT") : "--",
    name: "--",
    track: "--",
  };
  let paid = {
    date: paidAt ? moment(paidAt).format("DD / MM / YYYY") : "--",
    time: paidAt ? moment(paidAt).format("LT") : "--",
  };

  if (accesses) {
    if (entryCode) {
      const { name, track } = getAccessData(accesses, entryCode);
      entry.name = name;
      entry.track = track;
    }
    if (exitCode) {
      const { name, track } = getAccessData(accesses, exitCode);
      exit.name = name;
      exit.track = track;
    }
  }

  const flowPaymentObj = {
    none: "",
    automatic_recharge_payment: "Pago automático al recargar",
    automatic_entry_payment: "Pago automático al entrar",
  };
  const flowPayment =
    flowPaymentObj[incommingFlowPayment] || flowPaymentObj.none;

  return (
    <div className={className}>
      <CSSTransition
        in={showTicket}
        timeout={350}
        appear={true}
        classNames="ticket"
      >
        <Card className={"ticket"} width={4}>
          <div className="circle">
            {incommingStatus === "incomplete" && (
              <Icon
                name="question circle"
                size="huge"
                className={"icon-incomplete"}
              />
            )}
            {incommingStatus === "discarded" && (
              <Icon
                name="remove circle"
                size="huge"
                className={"icon-discarded"}
              />
            )}
            {incommingStatus === "ready" &&
              (typeToAnalize === "regular" ||
                typeToAnalize === "monthly_premium") && (
                <Icon
                  name="warning circle"
                  size="huge"
                  className={"icon-ready"}
                />
              )}
            {incommingStatus === "ready" && typeToAnalize === "prized" && (
              <Icon name="gift" size="huge" className={"icon-gift"} />
            )}
            {incommingStatus === "ready" && typeToAnalize === "recommended" && (
              <Icon
                circular
                inverted
                color="purple"
                name="users"
                size="big"
                className={"icon-cert"}
              />
            )}
            {incommingStatus === "ready" && typeToAnalize === "promotioned" && (
              <Icon name="winner" size="huge" className={"icon-win"} />
            )}
            {incommingStatus === "paid" &&
              (typeToAnalize === "regular" ||
                typeToAnalize === "monthly_premium") && (
                <Icon name="check circle" size="huge" className={"icon-paid"} />
              )}
            {incommingStatus === "paid" && typeToAnalize === "prized" && (
              <Icon name="gift" size="huge" className={"icon-gift"} />
            )}
            {incommingStatus === "paid" && typeToAnalize === "premium" && (
              <img
                className={"icon-image"}
                src={imageCrown}
                alt="premium icon"
              />
            )}
            {incommingStatus === "paid" && typeToAnalize === "recommended" && (
              <Icon name="certificate" size="huge" className={"icon-cert"} />
            )}
            {incommingStatus === "paid" && typeToAnalize === "promotioned" && (
              <Icon name="winner" size="huge" className={"icon-win"} />
            )}
            {(incommingStatus === "closed" ||
              incommingStatus === "closed_superadmin" ||
              incommingStatus === "closed_premium_no_transaction" ||
              incommingStatus === "closed_promotional_no_transaction") && (
              <img
                src={successHand}
                alt="succes hand"
                className={"icon-image"}
              />
            )}
          </div>

          <Menu className="options">
            <Menu.Menu position="right" style={{ opacity: 1, zIndex: 1 }}>
              <DropdownSimple
                icon="ellipsis vertical"
                text=" "
                placeholder=" "
                selectOnBlur={false}
                options={ticketOptions}
                onChange={handleOptionsSelection}
                wrapSelection={false}
                value={""}
                style={{ color: "black" }}
                disabled={
                  category !== "pagodirecto" && category !== "estacionamiento"
                }
              />
            </Menu.Menu>
          </Menu>

          <Card.Content>
            <Card.Header textAlign="center">
              <p className={"status " + status}>{status}</p>
            </Card.Header>
            <Card.Meta textAlign="center">
              <p className={"flow-payment"}>{flowPayment}</p>
              <p className="building">{buildingName}</p>
              <p className="address">
                {buildingAddress ? buildingAddress : ""}
              </p>
            </Card.Meta>
            <Card.Description>
              <div className="ui divider"></div>
              <Grid columns={2} style={{ margin: "5px" }}>
                <Grid.Row style={{ padding: "7px" }}>
                  <Grid.Column textAlign="left">
                    <p
                      style={{
                        fontSize: "1rem",
                        color: "#808080",
                        fontWeight: "bold",
                      }}
                    >
                      USUARIO{" "}
                      {newUser && <span className="new-user">nuevo</span>}
                    </p>

                    <p>
                      {activateUserUrl ? (
                        <Link
                          to={{
                            pathname: "/mpos/users",
                            state: {
                              user: username,
                              remoteAccess: true,
                              remoteStateToBack: currentState,
                            },
                          }}
                        >
                          @{username}
                        </Link>
                      ) : (
                        <p>@{username}</p>
                      )}
                    </p>
                  </Grid.Column>
                  <Grid.Column textAlign="right" floated="right">
                    <p
                      style={{
                        fontSize: "1rem",
                        color: "#808080",
                        fontWeight: "bold",
                      }}
                    >
                      MONTO
                    </p>
                    <p>{rate}</p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ padding: "7px" }}>
                  <Grid.Column textAlign="left">
                    <p
                      style={{
                        fontSize: "1rem",
                        color: "#808080",
                        fontWeight: "bold",
                      }}
                    >
                      HORA DE ENTRADA
                    </p>

                    <p>
                      {entry.date}
                      {" | "}
                      {entry.time}
                    </p>
                  </Grid.Column>
                  <Grid.Column textAlign="right" floated="right">
                    <p
                      style={{
                        fontSize: "1rem",
                        color: "#808080",
                        fontWeight: "bold",
                      }}
                    >
                      HORA DE SALIDA
                    </p>
                    <p>
                      {" "}
                      {exit.date}
                      {" | "}
                      {exit.time}
                    </p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ padding: "7px" }}>
                  <Grid.Column textAlign="left">
                    <p
                      style={{
                        fontSize: "1rem",
                        color: "#808080",
                        fontWeight: "bold",
                      }}
                    >
                      CREACIÓN DEL TICKET:
                    </p>

                    <p>
                      {" "}
                      {entry.create_date}
                      {" | "}
                      {entry.create_time}
                    </p>
                  </Grid.Column>
                  <Grid.Column textAlign="right" floated="right"></Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ padding: "7px" }}>
                  {(category === "pagodirecto" ||
                    category === "estacionamiento") && (
                    <>
                      <Grid.Column textAlign="left">
                        <p
                          style={{
                            fontSize: "1rem",
                            color: "#808080",
                            fontWeight: "bold",
                          }}
                        >
                          MTD ENTRADA:
                        </p>

                        <p>
                          {`${entry.name} ${
                            entry.track && entry.track !== "--"
                              ? " - track:"
                              : ""
                          } ${entry.track}`}
                        </p>
                      </Grid.Column>
                      <Grid.Column textAlign="right" floated="right">
                        <p
                          style={{
                            fontSize: "1rem",
                            color: "#808080",
                            fontWeight: "bold",
                          }}
                        >
                          MTD SALIDA:
                        </p>

                        <p>
                          {`${exit.name} ${
                            exit.track && exit.track !== "--" ? " - track:" : ""
                          } ${exit.track}`}
                        </p>
                      </Grid.Column>
                    </>
                  )}
                </Grid.Row>
              </Grid>
            </Card.Description>
          </Card.Content>
          <Card.Content extra textAlign="center">
            {category === "pagodirecto" && (
              <p
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                  color: "#9B909E",
                  textTransform: "uppercase",
                }}
              >
                {type} {wasReversed && <span>(Reversado)</span>}
              </p>
            )}
            {category === "pagodirecto" && (
              <p className={"status-footer"}>
                {paidBy &&
                paidBy.user_commerce &&
                (status === "Pagado" || status.includes("Cerrado")) &&
                category === "pagodirecto"
                  ? `Habilitado por ${ticketData.paid_by.user_commerce}`
                  : ""}
              </p>
            )}
            {category === "pagodirecto" &&
              (status === "Pagado" ||
                status === "Cerrado" ||
                status === "Cerrado (puestos fijos)" ||
                status === "Cerrado (Habilitado por ATC)") && (
                <p className={"status-footer-time"}>
                  <b>Fecha de pago:</b> {paid.date} <b>Hora:</b> {paid.time}
                </p>
              )}
            {category === "estacionamiento" &&
              (status === "Pagado" ||
                status === "Cerrado" ||
                status === "Cerrado (puestos fijos)") && (
                <p className={"status-footer-time"}>
                  <b>Fecha de pago:</b> {paid.date} <b>Hora:</b> {paid.time}
                </p>
              )}
            <div
              style={{
                backgroundColor: "#f0f0f0",
                width: "200px",
                borderRadius: "10px",
                margin: "auto",
              }}
            >
              <p
                style={{
                  fontSize: "1rem",
                  color: "#808080",
                  fontWeight: "bold",
                }}
                className="title"
              >
                Ticket ID:
              </p>
              <p
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  color: "#515151",
                }}
              >
                {ticketNumber}
              </p>
            </div>
          </Card.Content>
        </Card>
      </CSSTransition>

      {phoneView && (
        <button className="back" onClick={removeTicket}>
          Volver
        </button>
      )}
    </div>
  );
};

Ticket.propTypes = {
  ticketData: PropTypes.shape({
    ticket_number: PropTypes.string,
    enter_at: PropTypes.string,
    building: PropTypes.shape({
      name: PropTypes.string,
      address: PropTypes.string,
    }),
  }),
};

export default Ticket;
