import {
  SESSION_TOKEN_ADD,
  SESSION_TOKEN_REMOVE,
  SESSION_WORKSPACE_ADD,
  SESSION_ADD_FOREIGN_ID,
  SESSION_UPDATE,
  SESSION_USER_ADD,
  SESSION_USER_EMAIL_SET,
  SESSION_COLORS_ADD,
  SESSION_ADD_OFFICE_NUMBERS,
  SESSION_ROUTES_ADD,
} from "redux/actions/session";

const initialState = {
  token: "",
  workspace: "",
  username: "",
  email: "",
  role: "",
  category: "",
  foreign_id: "",
  has_pin: false,
  colors: [],
  officeNumbers: [],
  permissions: null,
  routes: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SESSION_TOKEN_ADD:
      let { token } = action.payload;
      return {
        ...state,
        token,
      };

    case SESSION_WORKSPACE_ADD:
      let { workspace } = action.payload;
      return {
        ...state,
        workspace,
      };

    case SESSION_ADD_FOREIGN_ID:
      let { foreign_id } = action.payload;
      return {
        ...state,
        foreign_id,
      };

    case SESSION_UPDATE:
      let { sessionObject } = action.payload;
      return {
        ...state,
        ...sessionObject,
      };

    case SESSION_TOKEN_REMOVE:
      return {
        ...state,
        token: "",
      };

    case SESSION_USER_ADD:
      let { username, email, role, category, permissions, has_pin } =
        action.payload;
      return {
        ...state,
        username,
        email,
        role,
        has_pin,
        category,
        permissions,
      };

    case SESSION_USER_EMAIL_SET:
      return {
        ...state,
        email: action.payload.email,
      };

    case SESSION_COLORS_ADD:
      let { colors } = action.payload;
      return {
        ...state,
        colors,
      };

    case SESSION_ADD_OFFICE_NUMBERS:
      let { officeNumbers } = action.payload;
      return {
        ...state,
        officeNumbers,
      };
    case SESSION_ROUTES_ADD:
      let { routes } = action.payload;
      return {
        ...state,
        routes,
      };

    default:
      return state;
  }
}
