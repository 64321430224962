import React from "react";
import { UserInfo } from "./user-info";
import { Grid } from "semantic-ui-react";
import { Panel } from "components/panel";
import SearchInputNew from "components/search-input-new";
import { CSSTransition } from "react-transition-group";
import PopupMessage from "components/popup-message";
import { EntitiesList, Pagination } from "components/entities-list";
import { MovementsForm } from "components/movements-form";
import { formatMovementsPhoneList } from "./movements-list-item";
import { formatUserList } from "../components/user-list-item";
import { users_admin as viewTexts } from "assets/strings/texts";
import { PermissionsValidator } from "components/permissions-validator";
import { Button } from "components/buttons";
import { CommentsBox } from "./comments-box";
import { Dropdown } from "semantic-ui-react";
import { DateRange } from "./date-range";
const MposUsersAdminPhoneView = ({
  viewState,
  permissions,
  buildings,
  selectionUserOptions,
  viewType,
  searchParam,
  users,
  remoteAccess,
  remoteStateToBack,
  showResponsePanel,
  selectedUser,
  promotionTickets,
  popupMessages,
  popupMessagesType,
  showPopup,
  location,
  redirect,
  searchType,
  searchFilter,
  searchCurrency,
  fromDate,
  endDate,
  movements,
  selectedMovement,
  nextPageExist,
  currentPage,
  showMovementsSearch,
  handleSearchTypeButtons,
  handleSearchFilterButtons,
  handleSearchCurrencyButtons,
  handleFromDate,
  handleEndDate,
  handleSubmit,
  handlePageDown,
  handlePageUp,
  handleMovementSelection,
  handleSearchMovements,
  removeSelectedMovement,
  handleOptionsSelection,
  handlePromotionTickets,
  handleConfirmation,
  loadingPremium,
  removeMovementsView,
  handleSearchInputChange,
  handleDeletePromotionTickets,
  handleSearchUser,
  handleUserSelection,
  optionSelected,
  amountInput,
  handleAmount,
  closePopup,
  newName,
  newLastName,
  newEmail,
  newId,
  newIdType,
  newPhone,
  newMobilePhone,
  newTimeout,
  newConnectionTimeout,
  serviceFilter,
  handleNewName,
  handleNewEmail,
  handleNewLastName,
  handleNewPhone,
  handleNewMobilePhone,
  handleNewId,
  handleNewIdType,
  handleNewTimeout,
  handleNewConnectionTimeout,
  handleServiceFilterChange,
  contactPhone,
  handleContactPhone,
  handleUpdateUser,
  handleTicketTypeChange,
  ticketType,
  removeSelectedUser,

  nextUsersPageExists,
  currentUsersPage,
  handleNextUsersPage,
  handlePrevUsersPage,
  fromDateReport,
  endDateReport,
  handleFromDateReport,
  handleEndDateReport,
  onDetailOptionsChange,
  onStatusOrder,
  transactionTags,
}) => {
  return (
    <Grid padded>
      <PermissionsValidator route={location.pathname} />
      <PopupMessage
        messageType={popupMessagesType}
        showPopup={showPopup}
        messages={popupMessages}
        closePopup={closePopup}
      >
        {selectionUserOptions && (
          <div>
            {loadingPremium && (
              <div
                className="ui active loader"
                style={{
                  marginTop: "3rem",
                  position: "relative",
                }}
              ></div>
            )}
            {optionSelected === "return" && !loadingPremium && (
              <input
                type="text"
                disabled={loadingPremium}
                placeholder="Monto"
                onChange={handleAmount}
                value={amountInput}
                style={{
                  marginTop: "1rem",
                  border: "none",
                  borderRadius: "1rem",
                  height: "3rem",
                  background: "none",
                  textAlign: "center",
                  fontSize: "1.2rem",
                }}
              />
            )}
            {optionSelected === "ticketsReport" && !loadingPremium && (
              <DateRange
                phoneView
                fromDateReport={fromDateReport}
                endDateReport={endDateReport}
                handleFromDateReport={handleFromDateReport}
                handleEndDateReport={handleEndDateReport}
              />
            )}
            {optionSelected === "modifyData" && !loadingPremium && (
              <>
                <div
                  style={{
                    marginTop: "1rem",
                    display: "flex",
                    flexWrap: "wrap",
                    alignContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <label
                    style={{
                      textAlign: "left",
                    }}
                  >
                    Nombre
                  </label>
                  <input
                    type="text"
                    placeholder="Nombre"
                    disabled={loadingPremium}
                    onChange={handleNewName}
                    value={newName}
                    style={{
                      marginTop: "3px",
                      border: "",
                      borderRadius: "1rem",
                      height: "3rem",
                      background: "none",
                      textAlign: "center",
                      fontSize: "1.2rem",
                    }}
                  />
                </div>
                <div
                  style={{
                    marginTop: "1rem",
                    display: "flex",
                    flexWrap: "wrap",
                    alignContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <label
                    style={{
                      textAlign: "left",
                    }}
                  >
                    Apellido
                  </label>
                  <input
                    type="text"
                    placeholder="Apellido"
                    disabled={loadingPremium}
                    onChange={handleNewLastName}
                    value={newLastName}
                    style={{
                      marginTop: "3px",
                      border: "",
                      borderRadius: "1rem",
                      height: "3rem",
                      background: "none",
                      textAlign: "center",
                      fontSize: "1.2rem",
                    }}
                  />
                </div>

                <div
                  style={{
                    marginTop: "1rem",
                    display: "flex",
                    flexWrap: "wrap",
                    alignContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <label
                    style={{
                      textAlign: "left",
                    }}
                  >
                    Correo Electrónico
                  </label>
                  <input
                    type="text"
                    placeholder="Email"
                    disabled={loadingPremium}
                    onChange={handleNewEmail}
                    value={newEmail}
                    style={{
                      marginTop: "3px",
                      border: "",
                      borderRadius: "1rem",
                      height: "3rem",
                      background: "none",
                      textAlign: "center",
                      fontSize: "1.2rem",
                    }}
                  />
                </div>

                <div
                  style={{
                    marginTop: "1rem",
                    display: "flex",
                    flexWrap: "wrap",
                    alignContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <label
                    style={{
                      textAlign: "left",
                    }}
                  >
                    Tipo de Documento
                  </label>
                  <input
                    type="text"
                    placeholder="V/E/J"
                    disabled={loadingPremium}
                    onChange={handleNewIdType}
                    value={newIdType}
                    maxLength="1"
                    style={{
                      marginTop: "3px",
                      border: "",
                      borderRadius: "1rem",
                      height: "3rem",
                      background: "none",
                      textAlign: "center",
                      fontSize: "1.2rem",
                    }}
                  />
                </div>
                <div
                  style={{
                    marginTop: "1rem",
                    display: "flex",
                    flexWrap: "wrap",
                    alignContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <label
                    style={{
                      textAlign: "left",
                    }}
                  >
                    N° de Documento
                  </label>
                  <input
                    type="text"
                    placeholder={newId}
                    disabled={loadingPremium}
                    onChange={handleNewId}
                    value={newId}
                    style={{
                      marginTop: "3px",
                      border: "",
                      borderRadius: "1rem",
                      height: "3rem",
                      background: "none",
                      textAlign: "center",
                      fontSize: "1.2rem",
                    }}
                  />
                </div>

                <div
                  style={{
                    marginTop: "1rem",
                    display: "flex",
                    flexWrap: "wrap",
                    alignContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <label
                    style={{
                      textAlign: "left",
                    }}
                  >
                    Teléfono de Contacto
                  </label>
                  <input
                    type="text"
                    placeholder="Teléfono de Contacto"
                    disabled={loadingPremium}
                    onChange={handleNewPhone}
                    value={newPhone}
                    maxLength="11"
                    style={{
                      marginTop: "3px",
                      border: "",
                      borderRadius: "1rem",
                      height: "3rem",
                      background: "none",
                      textAlign: "center",
                      fontSize: "1.2rem",
                    }}
                  />
                </div>
                <div
                  style={{
                    marginTop: "1rem",
                    display: "flex",
                    flexWrap: "wrap",
                    alignContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <label
                    style={{
                      textAlign: "left",
                    }}
                  >
                    Teléfono asociado a Pago Móvil
                  </label>
                  <input
                    type="text"
                    placeholder="Teléfono (pago móvil)"
                    disabled={loadingPremium}
                    onChange={handleNewMobilePhone}
                    value={newMobilePhone}
                    maxLength="11"
                    style={{
                      marginTop: "3px",
                      border: "",
                      borderRadius: "1rem",
                      height: "3rem",
                      background: "none",
                      textAlign: "center",
                      fontSize: "1.2rem",
                    }}
                  />
                </div>
                <div
                  style={{
                    marginTop: "1rem",
                    display: "flex",
                    flexWrap: "wrap",
                    alignContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <label
                    style={{
                      textAlign: "left",
                    }}
                  >
                    Tiempo de Búsqueda (ms)
                  </label>
                  <input
                    type="text"
                    placeholder="Tiempo de Búsqueda"
                    disabled={loadingPremium}
                    onChange={handleNewTimeout}
                    value={newTimeout}
                    maxLength="11"
                    style={{
                      marginTop: "3px",
                      border: "",
                      borderRadius: "1rem",
                      height: "3rem",
                      background: "none",
                      textAlign: "center",
                      fontSize: "1.2rem",
                    }}
                  />
                </div>
                <div
                  style={{
                    marginTop: "1rem",
                    display: "flex",
                    flexWrap: "wrap",
                    alignContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <label
                    style={{
                      textAlign: "left",
                    }}
                  >
                    Timeout de Conexión
                  </label>
                  <input
                    type="text"
                    placeholder="Timeout de conexión"
                    disabled={loadingPremium}
                    onChange={handleNewConnectionTimeout}
                    value={newConnectionTimeout}
                    maxLength="11"
                    style={{
                      marginTop: "3px",
                      border: "",
                      borderRadius: "1rem",
                      height: "3rem",
                      background: "none",
                      textAlign: "center",
                      fontSize: "1.2rem",
                    }}
                  />
                </div>
                <div
                  style={{
                    marginTop: "1rem",
                    display: "flex",
                    flexWrap: "wrap",
                    alignContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <label
                    style={{
                      textAlign: "left",
                    }}
                  >
                    Filtro de Servicio
                  </label>
                  <Dropdown
                    placeholder="Filtro de Servicio"
                    className="select"
                    onChange={handleServiceFilterChange}
                    //searchInput={{ autoComplete: "off" }}
                    options={[
                      {
                        key: "on",
                        text: "Activado",
                        value: true,
                      },
                      {
                        key: "off",
                        text: "Desactivado",
                        value: false,
                      },
                    ]}
                    value={serviceFilter}
                  />
                </div>
              </>
            )}
            {optionSelected === "ticketAllocator" && !loadingPremium && (
              <>
                <Dropdown
                  fluid
                  placeholder="Tipo de Ticket"
                  className="single-dropdown"
                  onChange={handleTicketTypeChange}
                  //searchInput={{ autoComplete: "off" }}
                  options={[
                    {
                      key: "Tickets de Promoción",
                      text: "Tickets de Promoción",
                      value: "promotion_tickets",
                    },
                    {
                      key: "Tickets de Recomendación",
                      text: "Tickets de Recomendación",
                      value: "recommendation_tickets",
                    },
                  ]}
                  value={ticketType}
                />
                <input
                  type="text"
                  placeholder="Cantidad de tickets"
                  disabled={loadingPremium}
                  onChange={handlePromotionTickets}
                  value={promotionTickets}
                  style={{
                    marginTop: "1rem",
                    border: "none",
                    borderRadius: "1rem",
                    height: "3rem",
                    background: "none",
                    textAlign: "center",
                    fontSize: "1.2rem",
                  }}
                />
              </>
            )}
            {optionSelected === "ticketDelete" && !loadingPremium && (
              <>
                {" "}
                <Dropdown
                  fluid
                  placeholder="Tipo de Ticket"
                  className="single-dropdown"
                  onChange={handleTicketTypeChange}
                  //searchInput={{ autoComplete: "off" }}
                  options={[
                    {
                      key: "Tickets de Promoción",
                      text: "Tickets de Promoción",
                      value: "promotion_tickets",
                    },
                    {
                      key: "Tickets de Recomendación",
                      text: "Tickets de Recomendación",
                      value: "recommendation_tickets",
                    },
                  ]}
                  value={ticketType}
                />
                <input
                  type="text"
                  placeholder="Cantidad de tickets"
                  disabled={loadingPremium}
                  onChange={handleDeletePromotionTickets}
                  value={promotionTickets}
                  style={{
                    marginTop: "1rem",
                    border: "none",
                    borderRadius: "1rem",
                    height: "3rem",
                    background: "none",
                    textAlign: "center",
                    fontSize: "1.2rem",
                  }}
                />
              </>
            )}
            <Button
              onClick={handleConfirmation}
              color="blue"
              disabled={loadingPremium}
            >
              {optionSelected === "return" ||
              optionSelected === "ticketAllocator" ||
              optionSelected === "ticketDelete"
                ? "Continuar"
                : "Sí"}
            </Button>
            <Button
              color="grey_1"
              onClick={closePopup}
              disable={loadingPremium}
            >
              {optionSelected === "return" ||
              optionSelected === "ticketAllocator" ||
              optionSelected === "ticketDelete" ||
              optionSelected === "modifyData"
                ? "Cancelar"
                : "No"}
            </Button>
          </div>
        )}
      </PopupMessage>

      <Grid.Column width={16}>
        {(viewState === "user_search" ||
          viewState === "user_search_loading" ||
          viewState === "user_search_done") && [
          <SearchInputNew
            key="search-input"
            placeholder={viewTexts.userSearchPlaceholder}
            searchParam={searchParam}
            handleChange={handleSearchInputChange}
            handleSearch={handleSearchUser}
          />,
          <EntitiesList
            key="users-list"
            height="calc(100vh - 9rem)"
            viewState={viewState}
            entities={users}
            formattingFunction={formatUserList}
            selectionHandler={handleUserSelection}
          >
            <Pagination
              padding="1rem"
              nextPageExist={nextUsersPageExists}
              currentPage={currentUsersPage}
              handlePageUp={handleNextUsersPage}
              handlePageDown={handlePrevUsersPage}
            />
          </EntitiesList>,
        ]}

        <CSSTransition
          in={showResponsePanel}
          mountOnEnter
          unmountOnExit
          timeout={350}
          appear={true}
          classNames="panel"
        >
          <Panel>
            {viewState === "user_selection_done" && (
              <UserInfo
                viewType={viewType}
                user={selectedUser}
                permissions={permissions}
                remoteAccess={remoteAccess}
                remoteStateToBack={remoteStateToBack}
                handleSearchMovements={handleSearchMovements}
                handleOptionsSelection={handleOptionsSelection}
                removeSelecteUser={removeSelectedUser}
                contactPhone={contactPhone}
                handleContactPhone={handleContactPhone}
                handleUpdateUser={handleUpdateUser}
              />
            )}
            {(viewState === "movements_search_loading" ||
              viewState === "movements_search_done" ||
              viewState === "movements_pagination_loading" ||
              viewState === "movement_selection_done") && (
              <Button color="grey" onClick={removeMovementsView}>
                Información del Usuario
              </Button>
            )}
            {(viewState === "movements_search_loading" ||
              viewState === "movements_search_done" ||
              viewState === "movements_pagination_loading" ||
              viewState === "movement_selection_done") && (
              <MovementsForm
                viewState={viewState}
                searchType={searchType}
                searchFilter={searchFilter}
                searchCurrency={searchCurrency}
                fromDate={fromDate}
                endDate={endDate}
                handleSearchTypeButtons={handleSearchTypeButtons}
                handleSearchFilterButtons={handleSearchFilterButtons}
                handleSearchCurrencyButtons={handleSearchCurrencyButtons}
                handleFromDate={handleFromDate}
                handleEndDate={handleEndDate}
                handleSubmit={handleSubmit}
              />
            )}
          </Panel>
        </CSSTransition>
        {viewState === "user_selection_done" && (
          <CommentsBox selectedUser={selectedUser}></CommentsBox>
        )}
        {(viewState === "movements_search_loading" ||
          viewState === "movements_search_done" ||
          viewState === "movements_pagination_loading" ||
          viewState === "movement_selection_done") && (
          <Panel>
            <EntitiesList
              viewState={viewState}
              entities={movements}
              formattingFunction={formatMovementsPhoneList}
              selectionHandler={handleMovementSelection}
              additionalArgs={[
                selectedMovement,
                buildings,
                onDetailOptionsChange,
                onStatusOrder,
                transactionTags,
              ]}
            >
              <Pagination
                padding="0 0 0.8rem 0"
                nextPageExist={nextPageExist}
                currentPage={currentPage}
                handlePageUp={handlePageUp}
                handlePageDown={handlePageDown}
              />
            </EntitiesList>
          </Panel>
        )}
      </Grid.Column>
      {location && redirect}
    </Grid>
  );
};

export default MposUsersAdminPhoneView;
