import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useViewType } from "hooks/use-view-type";
import { useStateManager } from "hooks/use-state-manager";
import { eventsAPI } from "api";
import { MposEventReferenceView } from "./components/event-reference-view";

const ValidateEvent = (props) => {
  const initialState = {
    token: props.token,
    searchParam: "",
    message: "",
    ticket: null,
    toShow: "home",
    errorType: "current",
    searchTicket: false,
    validateTicket: false,
  };

  const [state, changeState] = useStateManager(initialState);

  const [viewType, setViewType] = useViewType("desktop");

  //0) listener for all sockets
  useEffect(() => {
    setViewType();
    window.addEventListener("resize", setViewType);
    document.addEventListener("keydown", onEnter);
    return () => {
      window.removeEventListener("resize", setViewType);
      document.removeEventListener("keydown", onEnter);
    };
  }, []);

  // 1) Search inpurt
  useEffect(() => {
    const validateTicketFetch = async () => {
      try {
        changeState({ toShow: "loader" });
        const response = await eventsAPI.unvalidateTicket(
          state.ticket.event_ticket_ref
        );
        const res = await response.json();
        if (res.error) throw res.error;
        changeState({
          toShow: "success",
          message: "Ticket validado exitosamente",
        });
      } catch (error) {
        changeState({
          toShow: "error",
          message: "Ha ocurrido un error, intente nuevamente",
        });
        if (
          error.id === "NO_TOKEN_PROVIDED" ||
          error.id === "AUTHENTICATE_FAILED" ||
          error.id === "DUPLICATE_SESSION"
        ) {
          changeState({
            ...initialState,
            toShow: "error",
            errorType: "current",
            message: "Error de autenticación, inicie sesión nuevamente",
          });
        } else {
          changeState({
            ...initialState,
            toShow: "error",
            errorType: "current",
            message: error.description,
          });
        }
      }
    };
    const searchTicketToValidate = async () => {
      try {
        console.log({
          searchParam: state.searchParam,
          len: state.searchParam.length,
          bol: state.searchParam.length < 7,
        });

        if (state.searchParam.length < 7) {
          const error = {
            id: "TICKET_NOT_FOUND",
            description: "La longitud de la referencia debe ser 7",
          };
          throw error;
        }
        changeState({ toShow: "loader" });
        const response = await eventsAPI.searchTicket(state.searchParam);
        const res = await response.json();
        if (res.error) throw res.error;
        if (!res.event_ticket) {
          const error = {
            id: "TICKET_NOT_FOUND",
            description: "Ticket no encontrado",
          };
          throw error;
        }
        changeState({
          toShow: "ticket",
          ticket: res.event_ticket,
        });
      } catch (error) {
        changeState({
          toShow: "error",
          message: "Ha ocurrido un error, intente nuevamente",
        });
        if (
          error.id === "NO_TOKEN_PROVIDED" ||
          error.id === "AUTHENTICATE_FAILED" ||
          error.id === "DUPLICATE_SESSION"
        ) {
          changeState({
            ...initialState,
            toShow: "error",
            errorType: "current",
            message: "Error de autenticación, inicie sesión nuevamente",
          });
        } else {
          changeState({
            toShow: "error",
            errorType: "current",
            message: error.description,
          });
        }
      }
    };
    if (state.searchTicket) {
      searchTicketToValidate();
      changeState({ searchTicket: false });
    }
    if (state.validateTicket) {
      validateTicketFetch();
      changeState({ validateTicket: false });
    }
  }, [state.searchTicket, state.validateTicket]);

  const handleSearchInputChange = (event) => {
    let value = event.target.value;
    // The field is restrcted to accept only letters, numbers, @(first position) and _-.
    if (value.match(/^[0-9a-fA-F]*$/)) {
      value = value.toUpperCase();
      // call the API in a debounced way
      changeState({
        ...initialState,
        searchParam: value,
      });
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    changeState({ searchTicket: true });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    changeState({ validateTicket: true });
  };

  const handleContinue = (event) => {
    event.preventDefault();
    changeState(initialState);
  };

  const handleBack = (event) => {
    event.preventDefault();
    changeState({ toShow: "home" });
  };

  const onEnter = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      changeState({ searchTicket: true });
    }
  };
  return (
    <MposEventReferenceView
      viewType={viewType}
      toShow={state.toShow}
      ticket={state.ticket}
      searchParam={state.searchParam}
      handleContinue={handleContinue}
      handleBack={handleBack}
      handleSearchInputChange={handleSearchInputChange}
      handleSearch={handleSearch}
      handleSubmit={handleSubmit}
      message={state.message}
      errorType={state.errorType}
    />
  );
};

const mapStateToProps = null;

const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(ValidateEvent);
