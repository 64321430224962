import React from "react";
import { Grid } from "semantic-ui-react";
import { UsersList } from "./users-list";
import { NewUserForm } from "./new-user-form";
import { UserSelected } from "./user-selection";
import { Confirmation } from "./confirmation";
import { GridColumn } from "semantic-ui-react";
import { RightBorderGreyColumn } from "components/styled-grid-column";
import PopupMessage from "components/popup-message";

const CommerceUserView = ({
  //loaders
  addUserLoading,
  loadingUsers,
  loadingRoles,
  loadingConfirm,
  showSelectedUser,
  isSelected,
  showForm,

  //data
  commerceUsers,
  confirm,
  confirmText,
  roles,
  form,
  selectedUser,
  //method

  handleChange,
  handlePhoneChange,
  handleRoleChange,
  handleEditStatus,
  handleEditRole,
  handleDeleteUser,
  handleSubmitUser,
  handleUserSelection,
  handleConfirm,
  handleShowForm,

  popupMessagesType,
  showPopup,
  popupMessages,
  closePopup,
}) => {
  return (
    <Grid padded>
      <PopupMessage
        messageType={popupMessagesType}
        showPopup={showPopup}
        messages={popupMessages}
        closePopup={closePopup}
      >
        {confirm && (
          <Confirmation
            loading={loadingConfirm}
            handleConfirm={handleDeleteUser}
            handleCancel={closePopup}
            textInfo={confirmText}
          />
        )}
      </PopupMessage>
      <RightBorderGreyColumn
        mobile={8}
        tablet={8}
        computer={8}
        style={{
          height: "calc(100vh - 4rem)",
        }}
      >
        <UsersList
          //loaders
          addUserLoading={addUserLoading}
          loadingUsers={loadingUsers}
          //data
          commerceUsers={commerceUsers}
          //methods
          handleUserSelection={handleUserSelection}
          handleShowForm={handleShowForm}
        />
      </RightBorderGreyColumn>
      <GridColumn width={8}>
        {!isSelected && showForm && (
          <NewUserForm
            //loaders
            addUserLoading={addUserLoading}
            loadingRoles={loadingRoles}
            //data
            roles={roles}
            form={form}
            showForm={showForm}
            //methods
            handleChange={handleChange}
            handlePhoneChange={handlePhoneChange}
            handleRoleChange={handleRoleChange}
            handleSubmitUser={handleSubmitUser}
          />
        )}
        {isSelected && (
          <UserSelected
            user={selectedUser}
            roles={roles}
            loading={loadingRoles}
            showUser={showSelectedUser}
            isSelected={isSelected}
            handleEditStatus={handleEditStatus}
            handleDeleteUser={handleConfirm}
            handleEditRole={handleEditRole}
          />
        )}
      </GridColumn>
    </Grid>
  );
};

export { CommerceUserView };
