import React from "react";
import { Grid } from "semantic-ui-react";
import { GeneralData } from "../../components/general-data";
import { Filters } from "../../components/filters";
import { FirstRow } from "../../components/first-row";
import { SecondRow } from "../../components/second-row";
import PopupMessage from "components/popup-message";
import { Loader } from "components/loader";
import { Row } from "../../components/row";

const UsersPhoneView = ({
  activeUsers,
  transition,
  usersTypes,
  totalUsers,
  newUsers,
  item,
  fromDate,
  endDate,
  barStats,
  usersData,
  customStats,
  totalAmounts,
  relevance,
  filterTime,
  typeStatusList,
  barHandlePeriod,
  usersDistribution,
  newUsersDistribution,
  listTypesStatusHandlePeriod,
  listDataHandle,
  amountsHandlePeriod,
  handlePeriodChange,
  handleFromDate,
  handleEndDate,
  handleSubmit,
  popupMessagesType,
  showPopup,
  popupMessages,
  closePopup,
  loading,
}) => {
  return (
    <Grid padded>
      <PopupMessage
        messageType={popupMessagesType}
        showPopup={showPopup}
        messages={popupMessages}
        closePopup={closePopup}
      />
      {loading && <Loader />}
      {!loading && (
        <Grid.Column width={16}>
          <Filters
            item={item}
            transition={transition}
            fromDate={fromDate}
            endDate={endDate}
            filterTime={filterTime}
            handlePeriodChange={handlePeriodChange}
            handleFromDate={handleFromDate}
            handleEndDate={handleEndDate}
            handleSubmit={handleSubmit}
            phoneView
          />
          <FirstRow
            phoneView
            transition={transition}
            item={item}
            customStats={customStats}
            listTypesStatusHandlePeriod={listTypesStatusHandlePeriod}
            listDataHandle={listDataHandle}
            typeStatusList={typeStatusList}
            activeUsers={activeUsers}
            usersTypes={usersTypes}
            totalUsers={totalUsers}
          />
          <SecondRow
            phoneView
            transition={transition}
            item={item}
            barStats={barStats}
            ticketsDistribution={usersDistribution}
            barHandlePeriod={barHandlePeriod}
            totalAmounts={totalAmounts}
            amountsHandlePeriod={amountsHandlePeriod}
          />
          <Row
            transition={transition}
            item={item}
            barStats={newUsersDistribution}
            barHandlePeriod={barHandlePeriod}
          />
          <GeneralData
            phoneView={true}
            item={item}
            transition={transition}
            usersData={usersData}
            negativeVariation={relevance.variation < 0}
            relevance={relevance}
          />
        </Grid.Column>
      )}
    </Grid>
  );
};

export { UsersPhoneView };
