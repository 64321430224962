export const dropdownData = {
  typeofTransferOptions: [
    { key: "wallet", text: "De Wallet a Paytech", value: "wallet" },
    { key: "service", text: "De Wallet a Servicios", value: "service" },
    {
      key: "third-bancamiga",
      text: "De Wallet a terceros Bancamiga",
      value: "third-bancamiga",
    },
    {
      key: "third-other",
      text: "De Wallet a terceros de otros bancos",
      value: "third-other",
    },
  ],
  typeofDocOptions: [
    { key: "V", text: "V.", value: "V" },
    { key: "E", text: "E.", value: "E" },
    { key: "J", text: "J.", value: "J" },
    { key: "G", text: "G.", value: "G" },
  ],
};
