import styled from "styled-components";
import { InvoiceFormModel } from "./model";
import { grey_6 } from "assets/strings/colors";

export const InvoiceForm = styled(InvoiceFormModel)`
  #root && {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    > .date-range {
      width: 100%;
      > .date-pickers {
        margin: 0;
        padding: 0;
        margin-top: 0.7rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100;
        > p {
          margin: 0;
          font-weight: bold;
        }
        > div {
          width: 46%;
          > .react-datepicker__input-container {
            width: 100%;
            > input {
              width: calc(100% - 16px);
              border: none;
              border-radius: 1rem;
              color: ${grey_6};
              font-size: 1.2rem;
              margin: 0.5rem 5px 0.5rem 5px;
              text-align: center;
              outline: none;
              box-shadow: none;
              transition: box-shadow 250ms linear;
              :focus {
                box-shadow: 0 1px darkgrey;
              }
            }
            > .react-datepicker__close-icon::after {
              right: 0;
            }
          }
          > .react-datepicker-wrapper {
            width: 100%;
            > .react-datepicker__input-container {
              width: 100%;
              > input {
                width: calc(100% - 16px);
                border: none;
                border-radius: 1rem;
                color: ${grey_6};
                font-size: 1.2rem;
                margin: 0.5rem 5px 0.5rem 5px;
                text-align: center;
                outline: none;
                box-shadow: none;
                transition: box-shadow 250ms linear;
                :focus {
                  box-shadow: 0 1px darkgrey;
                }
              }
              > .react-datepicker__close-icon::after {
                right: 15px;
              }
            }
          }
        }
        > div:last-child {
          > .react-datepicker-popper {
            > .react-datepicker {
              > .react-datepicker__triangle {
                right: 50px;
                left: auto;
              }
            }
          }
        }
      }
    }

    > .selectors {
      margin-bottom: 0.5rem;
      padding: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > button {
        width: calc(50% - 0.2rem);
      }
    }
    > .filter-by {
      margin: 0;
      padding-bottom: 0.5rem;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      > .title {
        margin: 0;
        padding: 0;
        text-transform: uppercase;
        font-weight: bold;
      }
      > .building-selector {
        margin-top: 0.5rem;
        width: 100%;
      }
    }
    > .submit {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      > .debtor {
        margin: 0;
        padding: 0 1rem;
        height: 3.4rem;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-end;
        width: 50%;
        > input {
          border: none;
          height: 3rem;
          width: 100%;
          font-size: 1.2rem;
          :focus {
            outline: none;
            border-bottom: 1px solid ${grey_6};
          }
          :disabled {
            border: none;
            background: transparent;
            color: ${grey_6};
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    > .filter-by {
      > label {
        position: relative;
        text-transform: none;
        font-weight: normal;
        font-size: 0.8rem;
        width: 100%;
      }
    }
  }
`;
