import React from "react";
import moment from "moment";
import "moment/locale/es-us";
import { Grid } from "semantic-ui-react";
import PopupMessage from "components/popup-message";
import { Loader } from "components/loader";
import { DashboardSummary } from "components/summary-tabs";
import DashboardSection from "components/dashboard-section";
import HistoryTicketsGraph from "components/history-tickets-graph";
moment.locale("es-us");

const MposParkingAssociatedView = ({
  loading,
  summary,
  ticketsPerDay,
  popupMessagesType,
  showPopup,
  popupMessages,
  closePopup,
}) => {
  return loading ? (
    <Loader />
  ) : (
    <Grid padded>
      <PopupMessage
        messageType={popupMessagesType}
        showPopup={showPopup}
        messages={popupMessages}
        closePopup={closePopup}
      />

      <Grid.Column width={16}>
        <DashboardSection elemPerRow={4} elemPerPhoneRow={2}>
          {summary.map((item) => (
            <DashboardSummary key={item.text} {...item} />
          ))}
        </DashboardSection>

        <DashboardSection>
          <HistoryTicketsGraph
            title="Tickets habilitados en el último mes"
            ticketsPerDay={ticketsPerDay}
          />
        </DashboardSection>
      </Grid.Column>
    </Grid>
  );
};

export default MposParkingAssociatedView;
