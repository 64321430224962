import React from "react";
import { Grid } from "semantic-ui-react";

const VerticalDividerModel = (props) => {
  return (
    <Grid.Column {...props}>
      <div className="grey-line" />
      {props.children}
    </Grid.Column>
  );
};

export default VerticalDividerModel;
