import React from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Button } from "components/buttons";
import { general as texts } from "assets/strings/texts";

export function WithdrawHistoryFormModel(props) {
  let {
    loading,
    phoneView,
    searchType,
    pending,
    approved,
    fromDate,
    endDate,
    toggleCollapse,
    collapsed,
    handleSearchTypeButtons,
    handleStatusButtons,
    handleFromDate,
    handleEndDate,
    handleSubmit,
    children,
    className,
  } = props;

  return (
    <form className={className}>
      <div className={`collapsed ${collapsed ? "true" : "false"}`}>
        <div className="selectors">
          <Button
            value="latests"
            pressed={searchType === "latests"}
            color={searchType === "latests" ? "blue" : "grey_1"}
            disabled={loading}
            onClick={handleSearchTypeButtons}
          >
            {texts.latests}
          </Button>
          <Button
            value="dateRange"
            pressed={searchType === "dateRange"}
            color={searchType === "dateRange" ? "blue" : "grey_1"}
            disabled={loading}
            onClick={handleSearchTypeButtons}
          >
            {texts.dateRange}
          </Button>
        </div>
        {searchType === "dateRange" && (
          <div className="date-pickers">
            <DatePicker
              className="date-picker"
              placeholderText={texts.from}
              dateFormat={texts.shortDateFormat}
              disabled={loading}
              showMonthDropdown
              showYearDropdown
              todayButton={texts.today}
              isClearable
              withPortal={phoneView}
              selected={fromDate}
              maxDate={
                endDate ? moment(endDate).subtract(23, "hours") : moment()
              }
              onChange={handleFromDate}
            />
            <p>/</p>
            <DatePicker
              className="date-picker"
              placeholderText={texts.to}
              dateFormat={texts.shortDateFormat}
              disabled={loading === true}
              popperPlacement="bottom-end"
              showMonthDropdown
              showYearDropdown
              todayButton={texts.today}
              isClearable
              withPortal={phoneView}
              selected={endDate ? moment(endDate).subtract(23, "hours") : null}
              minDate={fromDate ? moment(fromDate).add(23, "hours") : null}
              maxDate={moment()}
              onChange={handleEndDate}
            />
          </div>
        )}
        <div className="selectors">
          <Button
            value="pending"
            pressed={pending}
            color={pending ? "blue" : "grey_1"}
            disabled={loading === true}
            onClick={handleStatusButtons}
          >
            {texts.pending}
          </Button>
          <Button
            value="approved"
            pressed={approved}
            color={approved ? "blue" : "grey_1"}
            disabled={loading}
            onClick={handleStatusButtons}
          >
            {texts.approved}
          </Button>
        </div>
        {children}
        <Button color="grey" disabled={loading} onClick={handleSubmit}>
          Buscar
        </Button>
      </div>
      {typeof toggleCollapse === "function" && (
        <div className="collapse-button" onClick={toggleCollapse}>
          {collapsed ? (
            <i className="chevron down icon"></i>
          ) : (
            <i className="chevron up icon"></i>
          )}
        </div>
      )}
    </form>
  );
}

// for class components
export function handleSearchTypeButtons(event) {
  event.preventDefault();
  this.setState({ searchType: event.target.value });
}

export function handleFromDate(date) {
  this.setState({ fromDate: date });
}

export function handleEndDate(date) {
  if (date) {
    // If the date exists add 23 hours
    this.setState({ endDate: moment(date).add(23, "hours") });
  } else {
    // If the date is null, save it like that
    this.setState({ endDate: date });
  }
}

export function handleStatusButtons(event) {
  event.preventDefault();
  let status = event.target.value;
  this.setState((prevState) => ({
    [status]: !prevState[status],
  }));
}
