import React from "react";
import { Panel } from "components/panel";
import { Grid } from "semantic-ui-react";
import { InvoicePhone } from "./invoice";
import { InvoicesForm } from "components/invoices-form";
import { PaymentMethods } from "./payment-methods";
import PopupMessage from "components/popup-message";
import { CSSTransition } from "react-transition-group";
import { formatInvoicesPhoneList } from "./invoice-list-item";
import { EntitiesList, Pagination } from "components/entities-list";

const MposInvoicesHistoryPhoneView = ({
  viewState,
  showInvoicesSearch,
  searchType,
  fromDate,
  endDate,
  invoices,
  nextPageExist,
  currentPage,
  selectedInvoice,
  permissions,
  category,
  downloadExcel,
  generateBill,
  loadingBill,
  location,
  handleSearchTypeButtons,
  handleFromDate,
  handleEndDate,
  handleSubmit,
  handlePageDown,
  handlePageUp,
  handleInvoiceSelection,
  removeSelectedInvoice,
  showPaymentMethods,
  popupMessages,
  popupMessagesType,
  showPopup,
  closePopup,
  loadingExcel,
}) => {
  return (
    <Grid centered padded>
      <Grid.Column mobile={16} tablet={14} computer={10}>
        <PopupMessage
          messageType={popupMessagesType}
          showPopup={showPopup}
          messages={popupMessages}
          closePopup={closePopup}
        >
          {viewState === "invoice_selection_done" && <PaymentMethods />}
        </PopupMessage>

        <CSSTransition
          in={showInvoicesSearch}
          mountOnEnter
          unmountOnExit
          timeout={350}
          classNames="panel"
        >
          <Panel
            className={viewState === "invoices_search_loading" && "inactive"}
          >
            <InvoicesForm
              viewState={viewState}
              searchType={searchType}
              fromDate={fromDate}
              endDate={endDate}
              phoneView
              handleSearchTypeButtons={handleSearchTypeButtons}
              handleFromDate={handleFromDate}
              handleEndDate={handleEndDate}
              handleSubmit={handleSubmit}
            />
          </Panel>
        </CSSTransition>

        <CSSTransition
          in={
            (viewState === "invoices_search_done" ||
              viewState === "invoices_pagination_loading") &&
            showInvoicesSearch
          }
          mountOnEnter
          unmountOnExit
          timeout={350}
          appear={true}
          classNames="panel"
        >
          <Panel>
            <EntitiesList
              viewState={viewState}
              entities={invoices}
              formattingFunction={formatInvoicesPhoneList}
              selectionHandler={handleInvoiceSelection}
            >
              <Pagination
                padding="0 0 0.8rem 0"
                nextPageExist={nextPageExist}
                currentPage={currentPage}
                handlePageUp={handlePageUp}
                handlePageDown={handlePageDown}
              />
            </EntitiesList>
          </Panel>
        </CSSTransition>

        <CSSTransition
          in={viewState === "invoice_selection_done"}
          mountOnEnter
          unmountOnExit
          timeout={350}
          appear={true}
          classNames="invoice"
        >
          <InvoicePhone
            data={selectedInvoice}
            category={category}
            permisions={permissions}
            downloadExcel={downloadExcel}
            generateBill={generateBill}
            removeSelectedInvoice={removeSelectedInvoice}
            showPaymentMethods={showPaymentMethods}
            lloading={loadingExcel || loadingBill}
          />
        </CSSTransition>
      </Grid.Column>
    </Grid>
  );
};

export { MposInvoicesHistoryPhoneView };
