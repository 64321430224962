import StyledGridColumnModel from "./model";
import styled from "styled-components";
import { grey_4 } from "assets/strings/colors";

const RightBorderGreyColumn = styled(StyledGridColumnModel)`
  #root & {
    border-right: 1px solid ${grey_4};
    height: calc(100vh - 4rem);
  }
`;
export { RightBorderGreyColumn };
