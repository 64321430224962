import React, { useEffect } from "react";
import { MPosUsersReportsView } from "./components/mpos-users-reports-view";
import { useViewType } from "hooks/use-view-type";
import { connect } from "react-redux";
import { UsersReportState } from "./context";
import {
  getBuildings as getBuildingsFromRedux,
  getSelectedCommerce as getSelectedCommerceFromRedux,
} from "redux/selectors";

const MPosReports = ({ buildings, selectedCommerce }) => {
  const [viewType, setViewType] = useViewType("desktop");
  useEffect(() => {
    setViewType();
    window.addEventListener("resize", setViewType);
    return () => {
      window.removeEventListener("resize", setViewType);
    };
  }, []);

  const phoneView = viewType === "phone";

  return (
    <UsersReportState buildings={buildings} selectedCommerce={selectedCommerce}>
      <MPosUsersReportsView phoneView={phoneView} />
    </UsersReportState>
  );
};

const mapStateToProps = (state) => {
  const buildings = getBuildingsFromRedux(state);
  const selectedCommerce = getSelectedCommerceFromRedux(state);
  return { buildings, selectedCommerce };
};
const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(MPosReports);
