import { ValidateTicketModel } from "./model";
import styled from "styled-components";
import imagenTicket from "assets/img/ticket_asset_new.png";
import {
  yellow_3,
  grey_5,
  grey_bg,
  magenta,
  bluePD,
} from "assets/strings/colors";
const ValidateTicket = styled(ValidateTicketModel)`
  #root && {
    margin: 0;
    width: ${(props) => {
      return props.viewType === "phone" ? "90%" : "25%";
    }};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    > .ticket {
      margin: 0;
      display: flex;
      align-self: center;
      flex-direction: column;
      align-items: center;
      height: 80%;
      width: 100%;
      background-image: url(${imagenTicket});
      background-size: auto 100%;
      background-repeat: no-repeat;
      background-position: center;
      -webkit-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.1));
      filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.1));
      > .icon {
        margin-top: clamp(0, 0, 1rem);
        color: ${yellow_3};
        background: white;
        width: 60px;
        height: 60px;
        border: 10px solid white;
        border-radius: 50%;
        background-size: auto 50%;
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      > .titles {
        font-size: 0.7rem;
        text-align: center;
        height: 15%;
        width: clamp(20rem, 60%, 40rem);
        > .title {
          margin: 0;
          font-weight: bold;
        }
        > .sub-title {
          margin: 0;
        }
      }
      > .current-info {
        height: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 80%;
        > .row {
          width: 100%;
          font-size: 0.8rem;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          height: 15vh;
          > .data-left {
            margin: 0;
            text-align: left;
            > .icon {
              margin: 0;
              color: ${grey_5};
            }
            > .header {
              font-size: 0.7rem;
              margin: 0;
              text-transform: uppercase;
              color: ${grey_5};
              font-weight: bold;
            }
            > .content {
              font-size: 0.7rem;
            }
            > .amount {
              font-size: 0.8rem;
            }
          }
          > .data-right {
            margin: 0;
            text-align: right;
            > .icon {
              margin: 0;
            }
            > .header {
              margin: 0;
              font-size: 0.7rem;
              text-transform: uppercase;
              color: ${grey_5};
              font-weight: bold;
            }
            > .content {
              font-size: 0.7rem;
            }
            > .amount {
              font-size: 0.8rem;
            }
          }
        }
      }
      > .footer {
        margin: 0;
        margin-top: 4rem;
        width: 80%;
        background: ${grey_bg};
        border-radius: 1rem;
        height: 15%;
        > .title {
          margin: 0;
          font-size: 0.7rem;
          font-weight: bold;
          color: ${grey_5};
        }
        > .ticket-id {
          color: black;
          font-size: 1.2rem;
          font-weight: bold;
        }
      }
    }
    > button {
      margin-top: 2rem;
      cursor: pointer;
      font-weight: bold;
      border: none;
      color: white;
      border-radius: 3rem;
      //background: linear-gradient(to right, ${magenta}, ${bluePD});
      background: ${bluePD};
      height: 10%;
      width: 100%;
      :focus {
        outline: none;
        opacity: 0.7;
      }
    }
    @media (max-width: 1200px) {
      > .ticket {
        > .current-info {
          width: 70%;
        }
      }
    }
    @media (max-width: 770px) {
      > .ticket {
        > .current-info {
          width: 30%;
        }
        > .footer {
          width: 30%;
        }
      }
    }
    @media (max-width: 650px) {
      > .ticket {
        > .current-info {
          width: 40%;
        }
        > .footer {
          width: 40%;
        }
      }
    }
    @media (max-width: 500px) {
      > .ticket {
        > .current-info {
          width: 60%;
        }
        > .footer {
          width: 50%;
        }
      }
    }
  }
`;

export { ValidateTicket };
