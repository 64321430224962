import { commercesSuperAutocomplete } from "services/api";

export const search = async (state, changeState) => {
  try {
    changeState({ loading: true });
    const response = await commercesSuperAutocomplete(
      state.searchQuery,
      state.skip,
      state.limit
    );
    const res = await response.json();
    if (res.error) throw res.error;
    if (res.commerces) {
      const options = [
        ...res.commerces.map((commerce) => ({
          key: commerce._id,
          value: commerce._id,
          text: commerce.name,
          category: commerce.category,
        })),
        ...state.options,
        ...state.initialOptions,
      ];
      changeState({
        searchQuery: "",
        options: state.category
          ? [...options.filter((option) => option.category === state.category)]
          : [...options],
      });
    }
  } catch (error) {
    console.log({ error });
  } finally {
    changeState({ loading: false });
  }
};
