import React, { useContext } from "react";
import { Grid } from "semantic-ui-react";
import SearchInputNew from "components/search-input-new";
import { formatUserList } from "components/users-list-item";
import { Loader } from "components/loader";
import { NoUser } from "./no-user";
import PopupMessage from "components/popup-message";
//import { CSSTransition } from "react-transition-group";
import { EntitiesList } from "components/entities-list";
import { users_admin as viewTexts } from "assets/strings/texts";
import { RightBorderGreyColumn } from "components/styled-grid-column";
import { UserInfo } from "./user-info";
import { BundleDetails } from "./bundle-details";
import { formatPremiumBundleList } from "./premium-bundle-list";
import { ActivateMPContext } from "../context";

const MposMonthlyPremiumActivateView = () => {
  const {
    state: {
      premiumBundles,
      viewState,
      loadingUsers,
      searchParam,
      loading,
      users,
      selectedUser,
    },
    handleSearchUser,
    handleSearchInputChange,
    handleUserSelection,
    handleBundleSelection,
    handleDisableBundle,
    popupMessages,
    popupMessagesType,
    showPopup,
    closePopup,
  } = useContext(ActivateMPContext);
  return (
    <Grid padded>
      <PopupMessage
        messageType={popupMessagesType}
        showPopup={showPopup}
        messages={popupMessages}
        closePopup={closePopup}
      />
      <RightBorderGreyColumn phone={8} tablet={8} computer={5}>
        <SearchInputNew
          placeholder={viewTexts.userSearchPlaceholder}
          searchParam={searchParam}
          handleChange={handleSearchInputChange}
          handleSearch={handleSearchUser}
        />
        <EntitiesList
          height="calc(100vh - 7rem)"
          viewState={viewState}
          entities={users}
          formattingFunction={formatUserList}
          selectionHandler={handleUserSelection}
          additionalArgs={[loadingUsers]}
        />
      </RightBorderGreyColumn>
      <RightBorderGreyColumn phone={8} tablet={8} computer={6}>
        {loading && <Loader />}
        {!loading && !selectedUser && <NoUser />}
        {!loading &&
          selectedUser && [
            <UserInfo key="userInfo" />,
            <EntitiesList
              height="calc(80vh - 7rem)"
              viewState={viewState}
              entities={premiumBundles}
              formattingFunction={formatPremiumBundleList}
              selectionHandler={handleBundleSelection}
              additionalArgs={[loading, handleDisableBundle]}
              key="bundles"
            />,
          ]}
      </RightBorderGreyColumn>
      <Grid.Column phone={8} tablet={8} computer={5}>
        <BundleDetails />
      </Grid.Column>
    </Grid>
  );
};

export default MposMonthlyPremiumActivateView;
