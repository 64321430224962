import styled from "styled-components";
import { EditNumberFormModel } from "./model";
import { bluePD, grey_4 } from "assets/strings/colors";

const EditNumberForm = styled(EditNumberFormModel)`
  #root && {
    > input {
      margin: 0.5rem;
      border: 1px solid ${grey_4};
      border-radius: 2rem;
      text-align: center;
      font-size: 1rem;
      :focus {
        outline: none;
        border: 1px solid ${bluePD};
      }
    }
  }
`;

export { EditNumberForm };
