import React, { useContext, useState } from "react";
import { DiscountCodesContext } from "../../context";
import { useAmountInput, useDates } from "hooks/use-inputs";
import { general as texts } from "assets/strings/texts";
import DatePicker from "react-datepicker";
import { Panel, PanelTitle, PanelSectionHeader } from "components/panel";
import { Loader } from "components/loader";
import { Dropdown } from "components/dropdown";
import { Button } from "components/buttons";
import { FileInput } from "components/file-input";
import { SwitchSlider } from "components/checkboxes";
import { CounterPartSelect } from "components/counterpart-select";
import { CSSTransition } from "react-transition-group";
import useCreateNewCode from "../../hooks/useCreateNewCode";
import moment from "moment";

moment.locale();

const types = [
  {
    key: "private",
    text: "Privado",
    value: "private",
  },
  {
    key: "public",
    text: "Público",
    value: "public",
  },
];
const discoutTypes = [
  {
    key: "fixed",
    text: "Fijo",
    value: "fixed",
  },
];

const verticals = [
  {
    key: "parking",
    text: "Estacionamientos",
    value: "parking",
  },
];

const privateOptions = [
  { data: "single", displayData: "Usuario" },
  { data: "massive", displayData: "Lista de usuarios" },
];
const NewCodeModel = ({ className }) => {
  const {
    amount,
    amountNumber: valueNumber,
    handle: onChangeAmount,
  } = useAmountInput("");
  const { fromDate: expirationDate, handleFromDate: handleExpirationDate } =
    useDates(null, null);
  const [name, setName] = useState("");
  const [type, setType] = useState("public");
  const [user, setUser] = useState("");
  const [privateType, setPrivateType] = useState("single");
  const [discountType, setDiscountType] = useState("fixed");
  const [vertical, setVertical] = useState("parking");
  const [file, setFile] = useState(null);
  const { state, viewType, changeNewCodeState, throwPopupMessage } =
    useContext(DiscountCodesContext);
  useCreateNewCode();
  const onChangeType = (event, { value }) => {
    event.preventDefault();
    setType(value);
  };

  const onChangeDiscountType = (event, { value }) => {
    event.preventDefault();
    setDiscountType(value);
  };
  const onChangeVertical = (event, { value }) => {
    event.preventDefault();
    setVertical(value);
  };

  const onChangeFile = (data) => {
    setFile(data);
  };
  const onChangeName = (e) => {
    const inputValue = e.target.value.toUpperCase();
    setName(inputValue.replace(/[^A-Z0-9]/g, ""));
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const form = {
      name,
      expiration_date: expirationDate
        ? moment(expirationDate).format("YYYY-MM-DD")
        : "",
      status: "active",
      vertical_type: [vertical],
      discount_type: discountType,
      coupon_type: type,
      value: valueNumber,
      file,
      coupon_owner: user,
      assign_massively:
        type === "private" && privateType === "massive" ? true : false,
      submit: true,
      privateType: type === "private" ? privateType : null,
    };
    const fields = [
      "name",
      "expiration_date",
      "vertical_type",
      "discount_type",
      "coupon_type",
      "value",
    ];
    const error = fields.filter((field) => !form[field]);
    if (error.length > 0) {
      throwPopupMessage("error", "Todos los campos son obligatorios");
      return;
    }
    changeNewCodeState(form);
  };

  const handlePrivateOptions = (event) => {
    event.preventDefault();
    const option = event.currentTarget.id;
    setUser("");
    setPrivateType(option);
  };

  const onChangeUser = (data) => {
    const { counterpart_id: userID } = data;
    setUser(userID);
    setFile(null);
  };
  const viewState = state.viewState;
  const isNew = viewState === "new";

  const blockFrom =
    !vertical ||
    !discountType ||
    !type ||
    !name ||
    !amount ||
    !expirationDate ||
    (type === "private" && !privateType) ||
    (type === "private" && privateType === "single" && !user) ||
    (type === "private" && privateType === "massive" && !file);
  if (!isNew) return null;
  if (state.newCode.loading) return <Loader margin="45vh 0 0 0" />;
  return (
    <CSSTransition in={isNew} timeout={350} appear={isNew} classNames="panel">
      <Panel>
        <form className={className} onSubmit={onSubmit}>
          <PanelTitle>Nuevo código de descuento</PanelTitle>
          <PanelSectionHeader>Nombre:</PanelSectionHeader>
          <div className="rounded-div">
            <input
              type="text"
              value={name}
              onChange={onChangeName}
              placeholder="Ejemplo: DESCUENTO1"
              maxLength={15}
            />
          </div>

          <PanelSectionHeader>Fecha de vencimiento:</PanelSectionHeader>
          <div className="rounded-div">
            <div className="date-pickers">
              <DatePicker
                className="date-picker"
                placeholderText={"Fecha"}
                dateFormat={texts.shortDateFormat}
                disabled={false} //pendiente
                showMonthDropdown
                showYearDropdown
                todayButton={texts.today}
                isClearable
                withPortal={viewType === "phone"}
                selected={expirationDate}
                minDate={moment()}
                onChange={handleExpirationDate}
              />
            </div>
          </div>
          <PanelSectionHeader>Vertical:</PanelSectionHeader>
          <div className="rounded-div">
            <div className="selection">
              <Dropdown
                value={vertical}
                placeholder="Vertical"
                options={verticals}
                onChange={onChangeVertical}
                selection
                fluid
              />
            </div>
          </div>
          <PanelSectionHeader>Tipo de descuento:</PanelSectionHeader>
          <div className="rounded-div">
            <div className="selection">
              <Dropdown
                value={discountType}
                placeholder="Tipo de descuento"
                options={discoutTypes}
                onChange={onChangeDiscountType}
                selection
                fluid
              />
            </div>
          </div>
          <PanelSectionHeader>Valor:</PanelSectionHeader>
          <div className="rounded-div">
            <input
              type="text"
              value={amount}
              onChange={onChangeAmount}
              placeholder="Ejemplo: 10,000"
            />
          </div>
          <PanelSectionHeader>Tipo de código:</PanelSectionHeader>
          <div className="rounded-div">
            <div className="selection">
              <Dropdown
                value={type}
                placeholder="Tipo"
                options={types}
                onChange={onChangeType}
                selection
                fluid
              />
            </div>
          </div>
          {type === "private" && (
            <SwitchSlider
              margin="1rem 0 .5rem 0"
              selected={privateType}
              options={privateOptions}
              handleSelection={handlePrivateOptions}
            />
          )}
          {type === "private" && privateType === "massive" && (
            <div className="users">
              <FileInput onChange={onChangeFile} />
            </div>
          )}
          {type === "private" && privateType === "single" && (
            <div className="users">
              <CounterPartSelect
                hideSwitch
                onChangeCounterpart={onChangeUser}
              />
            </div>
          )}
          <Button color="blue" type="submit" disabled={blockFrom}>
            Guardar
          </Button>
        </form>
      </Panel>
    </CSSTransition>
  );
};

export default NewCodeModel;
