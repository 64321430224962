import { useEffect } from "react";
import { useViewType } from "hooks/use-view-type";

const useOnmountView = () => {
  const [viewType, setViewType] = useViewType("desktop", 992);

  useEffect(() => {
    setViewType();
    window.addEventListener("resize", setViewType);
    return () => {
      window.removeEventListener("resize", setViewType);
    };
  }, []);

  return [viewType];
};

export default useOnmountView;
