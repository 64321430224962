import styled from "styled-components";
import { grey_4, grey_2, green_4, green_5 } from "assets/strings/colors";
import { ActivateFormModel } from "./model";

export const ActivateForm = styled(ActivateFormModel)`
  #root & {
    width: 100%;
    margin: 0;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 0.4rem;
    background-color: ${grey_2};
    > .form-label {
      width: 100%;
      margin: 0;
      padding-left: 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
      > .form-label__text {
        width: 100%;
        color: ${grey_4};
        text-transform: uppercase;
        font-weight: bold;
        text-align: center;
        text-decoration: underline;
      }
      > .form-label__input {
        width: 70%;
        height: 2rem;
        margin-top: 1rem;
        text-align: center;
        outline: none;
        box-shadow: none;
        border-radius: 0.4rem;
        border: 1px solid transparent;
        background-color: transparent;
        -moz-appearance: textfield;
        ::-webkit-outer-spin-button,
        ::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        :focus {
          border-bottom: 1px solid ${grey_4};
        }
        ::-webkit-input-placeholder {
          /* Chrome/Opera/Safari/Edge */
          color: ${grey_4};
        }

        ::-ms-input-placeholder {
          /* Microsoft Edge */
          color: ${grey_4};
        }

        :-ms-input-placeholder {
          /* IE 10+ */
          color: ${grey_4};
        }
      }
    }
    > .form-submit {
      margin-top: 1rem;
      width: 50%;
      height: 2.5rem;
      color: white;
      font-weight: bold;
      border: none;
      border-radius: 0.5rem;
      background-color: ${green_4};
      :hover {
        cursor: pointer;
        background-color: ${green_5};
      }
    }
  }
`;
