import { useEffect, useState, useContext } from "react";
import { usersAutocomplete } from "api";
import { UsersExtensionsContext } from "../context";

export const useSearchUsersService = () => {
  const [loading, setLoading] = useState(false);
  const [userQuery, setUserQuery] = useState({});
  const [activate, setActivate] = useState(false);
  const { changeState, throwPopupMessage } = useContext(UsersExtensionsContext);

  useEffect(() => {
    const autocomplete = async () => {
      setLoading(true);
      try {
        const response = await usersAutocomplete(userQuery);
        const res = await response.json();
        if (res.error) throw res.error;
        if (res.users) {
          const users = res.users;
          changeState({ users });
        }
      } catch (error) {
        throwPopupMessage("error", error.description || "");
      }
      setLoading(false);
    };
    if (activate) {
      setActivate(false);
      autocomplete();
    }
  }, [activate]);

  return { loading, setUserQuery, setActivate };
};
