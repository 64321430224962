import React from "react";
import { Grid } from "semantic-ui-react";
import { SuperadminBillsHistoryContext } from "../context";
import { PopupMessageContext } from "components/popup-message";
import { Panel } from "components/panel";
import { BillsHistoryForm } from "./history-form";
import { BillsList } from "./bills-list";
import { billsHistoryService } from "../services";

const SuperadminBillsDesktopView = () => {
  const { setArgs, setActivateFetch, loading } =
    billsHistoryService.useFetchBills();

  return (
    <Grid centered padded>
      <PopupMessageContext context={SuperadminBillsHistoryContext} />
      <Grid.Column width={16}>
        <Panel>
          <BillsHistoryForm
            setArgs={setArgs}
            setActivateFetch={setActivateFetch}
          />
        </Panel>
        <Panel>
          <BillsList
            setArgs={setArgs}
            setActivateFetch={setActivateFetch}
            loading={loading}
          />
        </Panel>
      </Grid.Column>
    </Grid>
  );
};

export default SuperadminBillsDesktopView;
