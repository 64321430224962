import React, { useEffect } from "react";
import { Button, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { clearReduxStore } from "redux/actions/general";
import ticketIcon from "assets/img/fullTicketWhite.png";
import { Link } from "react-router-dom";
import { getSelectedCommerce as getSelectedCommerceFromRedux } from "redux/selectors";

const Navbar = ({
  setSidebarOpen,
  title,
  status,
  category,
  permissions,
  notificationTitle,
  hasExtra,
  extraText,
  notificationMessage,
  selectedCommerce,
  clearReduxStore,
  className,
}) => {
  let currentParkingCommerce =
    category !== "pagodirecto" &&
    category !== "estacionamiento" &&
    category &&
    category !== "gasolina";
  let parking =
    selectedCommerce && selectedCommerce.hasOwnProperty("parking")
      ? selectedCommerce.parking
      : null;
  let billing_type =
    parking && parking.billing_type ? parking.billing_type : "";
  let prepaidTickets =
    billing_type === "prepaid" && parking.hasOwnProperty("prepaid_tickets")
      ? parking.prepaid_tickets
      : 0;

  useEffect(() => {}, [selectedCommerce]);
  return (
    <nav className={className + " no-print"}>
      {(!permissions ||
        !permissions.hasOwnProperty("taquilla") ||
        !permissions.taquilla) && (
        <div className="side">
          {setSidebarOpen && (
            <Button inverted onClick={() => setSidebarOpen(true)}>
              <Icon name="sidebar" />
            </Button>
          )}
        </div>
      )}
      <p className="title">
        {title} {hasExtra && <span>{extraText}</span>}
      </p>

      {(!permissions ||
        !permissions.hasOwnProperty("taquilla") ||
        !permissions.taquilla) && (
        <div
          className={
            category === "pagodirecto" ||
            (currentParkingCommerce && billing_type === "prepaid")
              ? "notify"
              : "blanc"
          }
        >
          {category === "pagodirecto" && (
            <p className="mobile-paid" status={status}>
              {notificationTitle}
              <br />
              <span>{notificationMessage}</span>
            </p>
          )}
          {currentParkingCommerce && billing_type === "prepaid" && (
            <div className="tickets">
              <div className="qty">
                <p>{prepaidTickets}</p>
                <img src={ticketIcon} alt="ticket" />
              </div>
              <div className="content">
                <p>tickets</p>
                <p>disponibles</p>
              </div>
            </div>
          )}
        </div>
      )}
      {permissions && permissions.taquilla && (
        <div className="side">
          {setSidebarOpen && (
            <Button inverted>
              <Link to={"/"} style={{ color: "white" }}>
                <Icon name="log out" />
              </Link>
            </Button>
          )}
        </div>
      )}
    </nav>
  );
};

const mapStateToProps = (state) => {
  let selectedCommerce = getSelectedCommerceFromRedux(state);
  return { selectedCommerce };
};

const mapDispatchToProps = {
  clearReduxStore,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
