import React from "react";
import { Icon } from "semantic-ui-react";
import moment from "moment-timezone";
//import { formatPrice } from "services/utils";
import { Button } from "components/buttons";

const TicketModel = ({
  isSelected,
  ticket,
  viewType,
  handleBack,
  className,
}) => {
  return isSelected ? (
    <div className={className}>
      <div className="ticket">
        {ticket.status === "ready" && (
          <Icon name="warning circle" size="huge" className="icon" />
        )}
        {ticket.status === "paid" && (
          <Icon name="check circle" size="huge" className="paid" />
        )}
        {ticket.status === "closed" && (
          <Icon name="sign out" size="large" className="closed" />
        )}

        {ticket.status !== "closed" &&
          ticket.status !== "paid" &&
          ticket.status !== "ready" && (
            <Icon name="question circle" size="huge" className="unknow" />
          )}
        {ticket.status === "ready" && (
          <div className="titles">
            <p className="title">Ticket Digital Pago Pendiente</p>
            <p className="sub-title">Paga tu ticket digital para poder salir</p>
          </div>
        )}
        {ticket.status === "paid" && (
          <div className="titles">
            <p className="title">Ticket Digital Pagado</p>
            <p className="sub-title"> Ticket digital listo para salir</p>
          </div>
        )}
        {ticket.status === "closed" && (
          <div className="titles">
            <p className="title">Ticket Digital Cerrado</p>
          </div>
        )}
        {ticket.status !== "closed" &&
          ticket.status !== "paid" &&
          ticket.status !== "ready" && (
            <div className="titles">
              <p className="title">Ticket con estado no disponible</p>
            </div>
          )}
        <div className="current-info">
          <div className="row">
            <div className="data-left">
              <p className="header">referencia</p>
              <p className="content">
                {ticket && ticket.ticket_reference
                  ? ticket.ticket_reference
                  : "No disponible"}
              </p>
            </div>
            <div className="data-right">
              <p className="header">estacionamiento</p>
              <p className="content">
                {ticket && ticket.building && ticket.building.name
                  ? ticket.building.name
                  : "No disponible"}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="data-left">
              <p className="header">usuario</p>
              <p className="content">
                @{ticket && ticket.username ? ticket.username : "No disponible"}
              </p>
            </div>
            <div className="data-right">
              <p className="header">fecha</p>
              <p className="content">
                {ticket && ticket.enter_at
                  ? moment(ticket.enter_at).tz("America/Caracas").format("ll")
                  : "--:--"}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="data-left">
              <p className="header">hora de entrada</p>
              <p className="content">
                {ticket && ticket.enter_at
                  ? moment(ticket.enter_at)
                      .tz("America/Caracas")
                      .format("HH:mm")
                  : "--:--"}
              </p>
            </div>
            <div className="data-right">
              <p className="header">hora de salida</p>
              <p className="content">
                {ticket && ticket.exit_at
                  ? moment(ticket.exit_at).tz("America/Caracas").format("HH:mm")
                  : "--:--"}
              </p>
            </div>
          </div>
        </div>
        <div className="footer">
          <p className="title">Ticket ID:</p>
          <p className="ticket-id">
            {" "}
            {ticket && ticket.ticket_number
              ? ticket.ticket_number
              : "No disponible"}
          </p>
        </div>
      </div>
      {isSelected && viewType === "phone" && (
        <Button color="grey" width="50%" onClick={handleBack}>
          Volver
        </Button>
      )}
    </div>
  ) : (
    <div className={className}>Seleccione un ticket</div>
  );
};

export { TicketModel };
