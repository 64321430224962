import React from "react";
import { Grid } from "semantic-ui-react";
import { InvoicesPanel } from "./invoices-panel";
import { WithdrawPanel } from "./withdraw-panel";
import { CommercePanel } from "./commerce-panel";
import { PrepaidTicketsPanel } from "./prepaid-tickets-panel";
import { CommercesSearchPanel } from "./commerces-search-panel";
import { MovementsPanel } from "./movements-panel";
import { UsersPanel } from "./users-panel";

const MposCommercesPhoneView = ({ viewState, transactionTags }) => {
  return (
    <Grid padded>
      {(viewState === "commerce_search" ||
        viewState === "commerce_search_loading" ||
        viewState === "commerce_search_done") && (
        <Grid.Column width={16}>
          <CommercesSearchPanel />
        </Grid.Column>
      )}

      {(viewState === "commerce_info" ||
        viewState === "commerce_info_loading") && (
        <Grid.Column width={16}>
          <CommercePanel />
        </Grid.Column>
      )}

      {(viewState === "commerce_invoice" ||
        viewState === "commerce_invoice_loading" ||
        viewState === "commerce_invoice_done" ||
        viewState === "commerce_invoice_payment" ||
        viewState === "commerce_invoice_payment_loading") && (
        <Grid.Column mobile={16} tablet={6} computer={6}>
          <InvoicesPanel />
        </Grid.Column>
      )}
      {(viewState === "commerce_withdraw" ||
        viewState === "commerce_withdraw_loading" ||
        viewState === "commerce_withdraw_done" ||
        viewState === "commerce_withdraw_payment" ||
        viewState === "commerce_withdraw_payment_loading") && (
        <Grid.Column mobile={16} tablet={6} computer={6}>
          <WithdrawPanel />
        </Grid.Column>
      )}
      {(viewState === "commerce_users" ||
        viewState === "commerce_getusers_loading" ||
        viewState === "commerce_getusers_done" ||
        viewState === "commerce_user_reset" ||
        viewState === "commerce_user_reset_loading") && (
        <Grid.Column mobile={16} tablet={6} computer={6}>
          <UsersPanel />
        </Grid.Column>
      )}
      {viewState === "commerce_prepaid_tickets" && (
        <Grid.Column mobile={16} tablet={6} computer={6}>
          <PrepaidTicketsPanel />
        </Grid.Column>
      )}
      {viewState === "commerce_show_movements" && (
        <Grid.Column mobile={16} tablet={6} computer={6}>
          <MovementsPanel phoneView transactionTags={transactionTags} />
        </Grid.Column>
      )}
    </Grid>
  );
};
export { MposCommercesPhoneView };
