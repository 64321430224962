import { Segment } from 'semantic-ui-react'
import styled from 'styled-components'

const GreetingsText = styled(Segment).attrs({
  basic: true,
  textAlign: 'center'
})`
  #root &{
    font-size: 1.375rem;
	  padding: 0rem;
	  max-width: 312px;
    margin: 2.8vh auto 3vh auto;
    >h4{
      font-size: 1.875rem;
      margin-bottom: 3vh;
    }
  }
  
`;

export default GreetingsText;