import React, { useContext } from "react";
import { SuperadminBillsContext } from "../context";

import SuperadminBillsDesktopView from "../components/desktop-view";
import SuperadminBillsPhoneView from "../components/phone-view";

const SuperadminBillsView = () => {
  const { viewport } = useContext(SuperadminBillsContext);

  const view = {
    phone: <SuperadminBillsPhoneView />,
    desktop: <SuperadminBillsDesktopView />,
  };
  return view[viewport];
};

export default SuperadminBillsView;
