import React from "react";
import { Link } from "react-router-dom";
import SidebarItem from "../sidebar-items";
import { Dropdown } from "semantic-ui-react";
import logotipoPD from "assets/img/logo-isotipo.png";
import logotipoPDApp from "assets/img/logotipo-azul.png";
import { formatPhoneNumber } from "services/utils";
import { software_version } from "assets/strings/constants";

const SidebarContentModel = ({
  category,
  setSidebarOpen,
  username,
  companyName,
  selectedCommerce,
  className,
  workspaceStatus,
  workspaceOptions,
  workspace,
  permissions,
  officeNumbers,
  handleWorkspaceChange,
  throwPopupMessage,
  routes,
}) => {
  const routesExists = Array.isArray(routes);
  const canDigitize = permissions && permissions.can_digitize;
  const sidebarOptions = [
    ...[
      routesExists && routes.find((route) => route === "/mpos/dashboard") && (
        <SidebarItem
          key="dashboard"
          clickable
          linkTo="/mpos/dashboard"
          text="Resumen"
          sidebarHandler={setSidebarOpen}
        />
      ),
    ],
    ...[
      routesExists && routes.find((route) => route === "/mpos/amount-history") && (
        <SidebarItem key="wallet" collapsible text="Wallet">
          <SidebarItem
            key="amount-history"
            clickable
            linkTo="/mpos/amount-history"
            text="Posición consolidada"
            sidebarHandler={setSidebarOpen}
          />
          <SidebarItem
            clickable
            linkTo="/mpos/wallet/transfer"
            text="Transferencias"
            sidebarHandler={setSidebarOpen}
          />
          <SidebarItem
            clickable
            linkTo="/mpos/wallet/transfer-history"
            text="Historial de Transferencias"
            sidebarHandler={setSidebarOpen}
          />
          <SidebarItem
            clickable
            linkTo="/mpos/wallet/today-movements"
            text="Movimientos de hoy"
            sidebarHandler={setSidebarOpen}
          />
          <SidebarItem
            clickable
            linkTo="/mpos/wallet/accounts"
            text="Visualizar cuentas"
            sidebarHandler={setSidebarOpen}
          />
        </SidebarItem>
      ),
    ],
    ...[
      category === "pagodirecto" && !permissions.taquilla && (
        <SidebarItem key="workspace" text="Espacio de trabajo">
          <div className="selector">
            <div className="status">
              {workspaceStatus === "none" && null}
              {workspaceStatus === "loading" && (
                <div className="ui tiny inline active loader" />
              )}
              {workspaceStatus === "selected" && <i className="check icon"></i>}
            </div>
            <div className="dropdown">
              <Dropdown
                search
                fluid
                className="single-dropdown"
                placeholder="Edificio"
                onChange={handleWorkspaceChange}
                searchInput={{ autoComplete: "off" }}
                options={workspaceOptions}
                value={workspace}
              />
            </div>
          </div>
        </SidebarItem>
      ),
    ],
    ...[
      routesExists &&
        routes.find((route) => route === "/mpos/commerce/movements") && (
          <SidebarItem
            key="Historial de movimientos"
            clickable
            linkTo="/mpos/commerce/movements"
            text="Historial de movimientos"
            sidebarHandler={setSidebarOpen}
          />
        ),
    ],
    ...[
      routesExists &&
        routes.find((route) => route === "/mpos/tickets/digitize") && (
          <SidebarItem key="estacionamiento" collapsible text="Estacionamiento">
            {routesExists &&
              routes.find((route) => route === "/mpos/tickets/validate") && (
                <SidebarItem
                  clickable
                  linkTo="/mpos/tickets/validate"
                  text="Habilitar Ticket"
                  sidebarHandler={setSidebarOpen}
                />
              )}
            {routesExists &&
              routes.find((route) => route === "/mpos/tickets/history") && (
                <SidebarItem
                  clickable
                  linkTo="/mpos/tickets/history"
                  text="Historial de tickets"
                  sidebarHandler={setSidebarOpen}
                />
              )}
            {routesExists &&
              routes.find((route) => route === "/mpos/tickets/digitize") && (
                <SidebarItem
                  clickable
                  linkTo="/mpos/tickets/digitize"
                  text="Digitalizar Ticket"
                  sidebarHandler={setSidebarOpen}
                />
              )}
            {routesExists &&
              routes.find((route) => route === "/mpos/users/logs") && (
                <SidebarItem
                  clickable
                  linkTo="/mpos/users/logs"
                  text="Informes de Conexión"
                  sidebarHandler={setSidebarOpen}
                />
              )}
            {routesExists &&
              routes.find((route) => route === "/mpos/buildings/settings") && (
                <SidebarItem
                  key="ajustes-building"
                  clickable
                  linkTo="/mpos/buildings/settings"
                  text="Ajustes"
                  sidebarHandler={setSidebarOpen}
                />
              )}
            {routesExists &&
              routes.find((route) => route === "/mpos/resgister/boxoffice") && (
                <SidebarItem
                  key="registrar-taquilla"
                  clickable
                  linkTo="/mpos/resgister/boxoffice"
                  text="Registrar Taquilla"
                  sidebarHandler={setSidebarOpen}
                />
              )}
            {routesExists &&
              routes.find((route) => route === "/mpos/parking/settings") && (
                <SidebarItem
                  key="ajustes"
                  clickable
                  linkTo="/mpos/parking/settings"
                  text="Ajustes"
                  sidebarHandler={setSidebarOpen}
                />
              )}
            {routesExists &&
              routes.find((route) => route === "/mpos/parking/pdfee") && (
                <SidebarItem
                  key="ajustes-fees"
                  clickable
                  linkTo="/mpos/parking/pdfee"
                  text="Fees"
                  sidebarHandler={setSidebarOpen}
                />
              )}
          </SidebarItem>
        ),
    ],
    ...[
      routesExists &&
        routes.find((route) => route === "/mpos/monthly-premium/activate") && (
          <SidebarItem key="monthly_premium" collapsible text="Premiums">
            <SidebarItem
              key="mp_activate"
              clickable
              linkTo="/mpos/monthly-premium/activate"
              text="Activar"
              sidebarHandler={setSidebarOpen}
            />
            <SidebarItem
              key="mp_invoices"
              clickable
              linkTo="/mpos/monthly-premium/invoices"
              text="Recibos"
              sidebarHandler={setSidebarOpen}
            />
            <SidebarItem
              key="mp_history"
              clickable
              linkTo="/mpos/monthly-premium/history"
              text="Historial"
              sidebarHandler={setSidebarOpen}
            />
          </SidebarItem>
        ),
    ],
    ...[
      routesExists &&
        routes.find((route) => route === "/mpos/building/commerces") && (
          <SidebarItem
            key="Building Commerces"
            clickable
            linkTo="/mpos/building/commerces"
            text="Comercios"
            sidebarHandler={setSidebarOpen}
          />
        ),
    ],
    ...[
      routesExists && routes.find((route) => route === "/mpos/users") && (
        <SidebarItem
          key="administrador-usuarios"
          collapsible
          text="Administrador de Usuarios"
        >
          {routesExists && routes.find((route) => route === "/mpos/users") && (
            <SidebarItem
              key="Usuarios"
              clickable
              linkTo="/mpos/users"
              text="Buscar Usuario"
              sidebarHandler={setSidebarOpen}
            />
          )}
          {routesExists &&
            routes.find((route) => route === "/mpos/users/register") && (
              <SidebarItem
                key="Registar Usuarios"
                clickable
                linkTo="/mpos/users/register"
                text="Registrar Usuario"
                sidebarHandler={setSidebarOpen}
              />
            )}
          {routesExists &&
            routes.find((route) => route === "/mpos/recharges/validate") && (
              <SidebarItem
                clickable
                linkTo="/mpos/recharges/validate"
                text="Habilitar Recarga"
                sidebarHandler={setSidebarOpen}
              />
            )}
          {routesExists &&
            routes.find((route) => route === "/mpos/recharges/recharge") && (
              <SidebarItem
                clickable
                linkTo="/mpos/recharges/recharge"
                text="Recargar al Wallet"
                sidebarHandler={setSidebarOpen}
              />
            )}
          {routesExists &&
            routes.find(
              (route) => route === "/mpos/tickets/overtime-transactions"
            ) && (
              <SidebarItem
                key="overtime-transactions"
                clickable
                linkTo="/mpos/tickets/overtime-transactions"
                text="Aprobar Transacciones"
                sidebarHandler={setSidebarOpen}
              />
            )}
          {routesExists &&
            routes.find((route) => route === "/mpos/users/extensions") && (
              <SidebarItem
                key="users-extensions"
                clickable
                linkTo="/mpos/users/extensions"
                text="Extensiones"
                sidebarHandler={setSidebarOpen}
              />
            )}
          {routesExists &&
            routes.find(
              (route) => route === "/mpos/users/movements/mobile-paid"
            ) && (
              <SidebarItem
                key="users-mobile-paid"
                clickable
                linkTo="/mpos/users/movements/mobile-paid"
                text="Movimientos de Pago Móvil"
                sidebarHandler={setSidebarOpen}
              />
            )}
          {routesExists &&
            routes.find(
              (route) => route === "/mpos/users/movements/transfers"
            ) && (
              <SidebarItem
                key="users-transfers"
                clickable
                linkTo="/mpos/users/movements/transfers"
                text="Movimientos de Transferencias"
                sidebarHandler={setSidebarOpen}
              />
            )}
        </SidebarItem>
      ),
    ],
    ...[
      routesExists && routes.find((route) => route === "/mpos/reports") && (
        <SidebarItem
          key="Reports"
          clickable
          linkTo="/mpos/reports"
          text="Reportes"
          sidebarHandler={setSidebarOpen}
        />
      ),
    ],
    ...[
      routesExists &&
        routes.find((route) => route === "/mpos/users/notifications") && (
          <SidebarItem
            key="notifications-center"
            collapsible
            text="Centro notificaciones"
          >
            <SidebarItem
              key="standard-notifications"
              clickable
              linkTo="/mpos/users/notifications"
              text="Notificación estándar"
              sidebarHandler={setSidebarOpen}
            />
            <SidebarItem
              key="massive-notifications"
              clickable
              linkTo="/mpos/users/notifications/massive"
              text="Notificación masiva"
              sidebarHandler={setSidebarOpen}
            />
            <SidebarItem
              key="discount-codes"
              clickable
              linkTo="/mpos/users/notifications/discount-codes"
              text="Códigos de descuento"
              sidebarHandler={setSidebarOpen}
            />
          </SidebarItem>
        ),
    ],
    ...[
      routesExists && routes.find((route) => route === "/mpos/commerces") && (
        <SidebarItem
          key="administrador-comercios"
          collapsible
          text="Administrador de Comercios"
        >
          <SidebarItem
            key="comercios"
            clickable
            linkTo="/mpos/commerces"
            text="Comercios"
            sidebarHandler={setSidebarOpen}
          />
          {routesExists &&
            routes.find((route) => route === "/mpos/withdraw/history") && (
              <SidebarItem
                key="Historial de Retiros"
                clickable
                linkTo="/mpos/withdraw/history"
                text="Historial de Retiros"
                sidebarHandler={setSidebarOpen}
              />
            )}
        </SidebarItem>
      ),
    ],
    ...[
      routesExists &&
        routes.find((route) => route === "/mpos/superadmin/bills") && (
          <SidebarItem
            key="facturas-father"
            collapsible
            newFeature
            text="Facturación"
          >
            <SidebarItem
              key="facturas"
              clickable
              newFeature
              linkTo="/mpos/superadmin/bills"
              text="Facturas"
              sidebarHandler={setSidebarOpen}
            />
            <SidebarItem
              key="hisstorial-facturas"
              clickable
              newFeature
              linkTo="/mpos/superadmin/bills/history"
              text="Historial de Facturas"
              sidebarHandler={setSidebarOpen}
            />
          </SidebarItem>
        ),
    ],

    ...[
      routesExists &&
        routes.find((route) => route === "/mpos/commerce/users") && (
          <SidebarItem
            key="workteam"
            clickable
            linkTo="/mpos/commerce/users"
            text="Equipo de trabajo"
            sidebarHandler={setSidebarOpen}
          />
        ),
    ],
    ...[
      routesExists && routes.find((route) => route === "/mpos/support") && (
        <SidebarItem
          key="support"
          clickable
          linkTo="/mpos/support"
          text="Información de Soporte"
          sidebarHandler={setSidebarOpen}
        />
      ),
    ],
    ...[
      routesExists &&
        routes.find((route) => route === "/mpos/commerce/wallet") && (
          <SidebarItem
            key="wallet-commerce"
            clickable
            linkTo="/mpos/commerce/wallet"
            text="Bancamiga Wallet"
            sidebarHandler={setSidebarOpen}
            selectedCommerce={selectedCommerce}
            throwPopupMessage={throwPopupMessage}
          />
        ),
    ],
    ...[
      routesExists &&
        routes.find((route) => route === "/mpos/commerce/digitize") && (
          <SidebarItem
            key="digitize"
            clickable
            linkTo="/mpos/commerce/digitize"
            text={canDigitize ? "Digitalizar" : "Habilitar"}
            sidebarHandler={setSidebarOpen}
            selectedCommerce={selectedCommerce}
            throwPopupMessage={throwPopupMessage}
          />
        ),
    ],
    /*   ...[
              routesExists &&
                routes.find((route) => route === "/mpos/tickets/history") && (
                  <SidebarItem
                    key="tickets-history"
                    clickable
                    linkTo="/mpos/tickets/history"
                    text="Historial de tickets"
                    sidebarHandler={setSidebarOpen}
                  />
                ),
            ],*/
    ...[
      routesExists &&
        category !== "pagodirecto" &&
        routes.find((route) => route === "/mpos/invoices/history") && (
          <SidebarItem
            key="invoices"
            clickable
            linkTo="/mpos/invoices/history"
            text="Recibos"
            sidebarHandler={setSidebarOpen}
          />
        ),
    ],

    <SidebarItem
      key="logout"
      logout
      clickable
      linkTo="/"
      text="Cerrar Sesión"
      sidebarHandler={setSidebarOpen}
    />,
  ];

  return (
    <div className={className}>
      <div className="pago-directo">
        <img className="logo" src={logotipoPD} alt="Isotipo PagoDirecto" />
        {/*<img className="iso" src={logotipoPD} alt="Logotipo PagoDirecto" />*/}
      </div>

      <div className="options-container">
        {sidebarOptions}

        <div className="footer">
          <div className="username-info">
            <p className="commerce">{companyName}</p>
            <p className="username">@{username}</p>
            <p className="version">{software_version}</p>
          </div>
          <div className="pd-info">
            <img
              className="logoApp"
              src={logotipoPDApp}
              alt="PagoDirecto App Logo"
            />
            {officeNumbers && officeNumbers.length && (
              <div className="support">
                <p>Soporte Técnico</p>
                {officeNumbers.map((number, index) => {
                  return (
                    <p key={`${number}.${index}`}>
                      {formatPhoneNumber(number)}
                    </p>
                  );
                })}
              </div>
            )}
            <div className="policies">
              <p>
                <Link to="/policies?type=general" target="_blank">
                  Términos y condiciones
                </Link>{" "}
                /{" "}
                <Link to="/policies?type=privacy-policies" target="_blank">
                  Políticas de privacidad
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarContentModel;
