import React, { Component } from "react";
import { Dropdown, Image } from "semantic-ui-react";
import styled from "styled-components";
import vzlaAvatar from "assets/img/vzla-avatar.png";

class FormDropdown extends Component {
  render() {
    return (
      <div className={this.props.className}>
        <Image src={vzlaAvatar} avatar className="im" />
        <Dropdown
          disabled
          inline
          upward
          icon={null}
          options={[]}
          placeholder="Selecione su país"
          text={"Venezuela"}
          className="select"
        />
      </div>
    );
  }
}

FormDropdown = styled(FormDropdown)`
  #root & {
    height: 8.15vh;
    min-height: 48px;
    border: 1px solid #e1e1e1;
    border-radius: 1rem !important;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0;
    padding-left: 1rem;
    margin: 1rem 0;
    > .select {
      font-size: 1rem;
      opacity: 0.75;
      > .default {
        color: black;
      }
    }
  }
`;

export default FormDropdown;
