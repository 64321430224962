import { 
  POPUP_MESSAGE_THROW,
  POPUP_MESSAGE_CLOSE,
  POPUP_MESSAGE_CLEAR,
  POPUP_MESSAGE_SET_BEFORE_CLOSE,
} from 'redux/actions/popup-messages'

const initialState = {
  messages: null,
  type: '',
  showPopup: false,
  onBeforeClose: null,
}

export default function(state = initialState, action){
  switch (action.type) {

    case POPUP_MESSAGE_THROW: {
      const { messages, type } = action.payload
      return {
        ...state,
        messages,
        type,
        showPopup: true
      }
    }

    case POPUP_MESSAGE_CLOSE:
      return {
        ...state,
        showPopup: false
      }

    case POPUP_MESSAGE_CLEAR:
      return {
        ...initialState
      }

    case POPUP_MESSAGE_SET_BEFORE_CLOSE: {
      const { onBeforeClose } = action.payload
      return {
        ...state,
        onBeforeClose,
      }
    }

    default:
      return state
  }
}