import React from "react";
import { WithdrawHistoryState } from "./context";
import WithdrawHistory from "./templates/WithdrawHistory";
const MPosWithdrawHistory = (props) => {
  return (
    <WithdrawHistoryState commerce={props.commerce} {...props}>
      <WithdrawHistory />
    </WithdrawHistoryState>
  );
};

export default MPosWithdrawHistory;
