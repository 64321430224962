import React from "react";
import { connect } from "react-redux";
import { clearReduxStore } from "redux/actions/general";
import { SuperadminAccountsView } from "./components/superadmin-accounts-view";
import {
  getSelectedCommerce as getSelectedCommerceFromRedux,
  getBuildings as getBuildingsFromRedux,
} from "redux/selectors";
import { getTransactionsTags } from "redux/selectors/constants";
import { WalletAccountsState } from "./context";
import useOnmountView from "./hooks/useOnmountView";

const MposSuperadminAccounts = (props) => {
  const [accounts, viewType, loadingAccounts, setFetchAccounts, error] =
    useOnmountView();
  return (
    <WalletAccountsState
      selectedCommerce={props.selectedCommerce}
      buildings={props.buildings}
      transactionTags={props.transactionTags}
      walletAccounts={accounts}
      viewType={viewType}
      loadingAccounts={loadingAccounts}
      setFetchAccounts={setFetchAccounts}
      accountsError={error}
    >
      <SuperadminAccountsView />
    </WalletAccountsState>
  );
};

const mapStateToProps = (state) => {
  const selectedCommerce = getSelectedCommerceFromRedux(state);
  const buildings = getBuildingsFromRedux(state);
  const transactionTags = getTransactionsTags(state);
  return { selectedCommerce, buildings, transactionTags };
};
const mapDispatchToProps = {
  clearReduxStore,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MposSuperadminAccounts);
