import { useEffect, useContext } from "react";
import { TransfersContext } from "../context";
import { changeStatus } from "./api";

export const useChangeStatus = () => {
  const { state, changeState, throwPopupMessage } =
    useContext(TransfersContext);

  const changeTransferStatus = async () => {
    changeState({ changeStatus: false, changeStatusLoading: true });
    try {
      const response = await changeStatus({
        movement_id: state.selectedTransfer._id,
        status: state.newStatus,
        username: state.sendUsername ? state.username : null,
      });
      const res = await response.json();

      if (res.error) throw res.error;
      throwPopupMessage("success", "Se cambió el estado con éxito");
      changeState({ transfers: [], selectedTransfer: null, submitForm: true });
    } catch (error) {
      throwPopupMessage("error", error.description);
    }
    changeState({ changeStatusLoading: false });
  };

  useEffect(() => {
    if (state.changeStatus) {
      changeState({ changeStatusLoading: true }); //eliminar despues de quitar el mock
      setTimeout(() => {
        //eliminar despues de quitar el mock
        changeTransferStatus();
      }, 500);
    }
  }, [state.changeStatus]);

  return null;
};
