import React from "react";
import { Loader } from "components/loader";
import { Grid } from "semantic-ui-react";
import PopupMessage from "components/popup-message";
import { SupportForm } from "./support-form";
import { EditNumberForm } from "./edit-number-form";

const SupportView = ({
  loading,
  editNumber,
  whatsappNumber,
  officeNumbers,
  officeNumberSelected,
  handleChangeOfficeNumberInput,
  toggleEditNumber,
  email,
  handleChangeWhatsappNumber,
  handleChangeEmail,
  handleOfficeNumberSelection,
  handleSubmit,
  popupMessages,
  popupMessagesType,
  showPopup,
  closePopup,
}) => {
  return (
    <Grid padded centered>
      <PopupMessage
        messageType={popupMessagesType}
        showPopup={showPopup}
        messages={popupMessages}
        closePopup={closePopup}
      >
        {editNumber && (
          <EditNumberForm
            value={officeNumberSelected}
            handleChange={handleChangeOfficeNumberInput}
            toggleValidate={toggleEditNumber}
          />
        )}
      </PopupMessage>
      {loading && <Loader />}
      {!loading && (
        <SupportForm
          whatsappNumber={whatsappNumber}
          officeNumbers={officeNumbers}
          email={email}
          handleChangeWhatsappNumber={handleChangeWhatsappNumber}
          handleChangeEmail={handleChangeEmail}
          handleOfficeNumberSelection={handleOfficeNumberSelection}
          handleSubmit={handleSubmit}
        />
      )}
    </Grid>
  );
};

export { SupportView };
