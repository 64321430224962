import {
  CommentsListItemModel,
  CommentsBoxModel,
  CommentsHeaderModel,
  CommentFormModel,
} from "./model";
import styled from "styled-components";
import { textualBlue /* grey_4, grey_6 */ } from "assets/strings/colors";

const CommentsListItem = styled(CommentsListItemModel)`
  #root & {
    margin-top: 0.2rem;
    border-radius: 1rem;
    border: 1.5px solid #e6f5ff;
    background-color: #e4f5ff;
    > p {
      color: ${textualBlue};
      font-size: 0.9rem;
      text-align: left;
      margin: 0.5rem;
      overflow: hidden;
      white-space: nowrap;
      > span {
        float: left;
        color: #000000;
      }
      > b {
        float: right;
        color: #999999;
        font-size: 0.8rem;
      }
    }
    .overflow-visible {
      white-space: initial;
    }
  }
`;
const CommentForm = styled(CommentFormModel)`
  #root & {
    > .title {
      font-size: 1.5rem;
      font-weight: bold;
      margin: 1rem 0 1rem 0;
    }
    > input {
      border-radius: 0.3rem;
      border: 1.5px solid #00a5ff;
      margin-top: 0.5rem;
      width: 100%;
      box-sizing: border-box;
    }
    > textarea {
      border-radius: 0.3rem;
      border: 1.5px solid #00a5ff;
      margin-top: 0.5rem;
      width: 100%;
      box-sizing: border-box;
    }
  }
`;
const CommentsHeader = styled(CommentsHeaderModel)`
  #root & {
    padding-bottom: 1rem;
    > div {
      display: flex;
      > p {
        text-align: left;
        margin: 1rem 2rem 0 0;
        font-size: 2rem;
        color: 0x0000ff;
      }
      > button {
        float: right;
        margin: 0 0 0 1rem;
        height
        width: 100%;
        display: inline-block;
      }
    }
    @media (max-width: 576px) {
      > div {
        > label {
          font-size: 0.9rem;
        }
        > button {
          height: 2.6rem;
        }
      }
      > input {
        width: 100%;
        font-size: 1.4rem;
      }
    }
  }
`;
const CommentsBox = styled(CommentsBoxModel)`
  #root & {
    text-align: left;
    > div {
      display: flex;
      float: right;
      > button {
        margin: 0 0 0 0.5rem;
        width: 50%;
        display: inline-block;
      }
    }
    @media (max-width: 576px) {
      > div {
        > label {
          font-size: 0.9rem;
        }
        > button {
          height: 2.6rem;
        }
      }
      > input {
        width: 100%;
        font-size: 1.4rem;
      }
    }
  }
`;
export { CommentsBox, CommentsListItem, CommentsHeader, CommentForm };
