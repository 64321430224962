import React from "react";
import styled from "styled-components";

const NoUserModel = ({ className }) => {
  return <div className={className}>No se ha seleccionado algun usuario</div>;
};

const NoUser = styled(NoUserModel)`
  #root & {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100vh - 5rem);
  }
`;

export { NoUser };
