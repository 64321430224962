import { EntitiesListModel, PaginationModel } from "./model";
import styled from "styled-components";
import {
  grey_2,
  grey_3,
  grey_4,
  grey_6,
  textualBlue,
} from "assets/strings/colors";

const EntitiesList = styled(EntitiesListModel)`
  #root & {
    margin: ${(props) => (props.margin ? props.margin : "0")};
    padding: 0;
    overflow-y: ${(props) => (props.overflow ? props.overflow : "auto")};
    position: relative;
    max-height: ${(props) => (props.maxHeight ? props.maxHeight : "inherit")};
    height: ${(props) => (props.height ? props.height : "auto")};
    > .not-found {
      padding: 2.5vh 1rem 2.5vh 1rem;
      color: #636363;
    }
    > .date-separator {
      border-bottom: 1px solid ${grey_4};
      padding: 1rem 1rem 1rem 1.5rem;
      color: ${textualBlue};
      text-align: right;
      background: ${grey_3};
    }
  }
`;

const Pagination = styled(PaginationModel)`
  #root & {
    font-size: 1.5rem;
    text-align: center;
    height: ${(props) => (props.height ? props.height : "auto")};
    padding: ${(props) => (props.padding ? props.padding : "0")};
    border-bottom: 1px solid ${grey_4};
    > .button {
      height: 2.5rem;
      color: ${grey_6};
      width: 2.5rem;
      background: white;
      display: inline-block;
      line-height: 2.5rem;
      border-radius: 100%;
      border: 1px solid lightgray;
      border-bottom: 2px solid #bcbdbe;
      > i {
        width: 100%;
      }
    }
    > .reload-button {
      margin: 0;
      padding: 0;
      height: 2.5rem;
      color: ${grey_6};
      width: 2.5rem;
      line-height: 2.5rem;
      border-radius: 100%;
      border: 1px solid lightgray;
      border-bottom: 2px solid #bcbdbe;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      > i {
        margin: 0;
        padding: 0;
      }
      :hover {
        background: ${grey_2};
      }
    }

    > .button.hidden {
      visibility: hidden;
    }
    > .button:hover {
      background: ${grey_2};
    }
    > .page {
      margin: 0 2.5rem 0 2.5rem;
      display: inline-block;
    }
    > .reload {
      position: relative;
      float: right;
    }

    @media (max-width: 768px) {
      > .pagination {
        > .button:hover {
          background: white;
        }
      }
    }
  }
`;

export { EntitiesList, Pagination };
