import React from "react";
import moment from "moment";
const ValidationModel = ({ ticket, handleSubmit, viewType, className }) => {
  if (!ticket) return null;
  const { event_ref, event_ticket_ref, event_details, is_valid } = ticket;

  const { title, expire_date, flyer_url, is_active } = event_ref;
  const { name, phone, doc_id, doc_type } = event_details;
  return (
    <div className={className}>
      <div className="panel">
        <div className="figure">
          <img src={flyer_url} alt="flyer" className="compress" />
        </div>
        <div className="title">
          <p className="description">{title}</p>
        </div>
        <div className="content">
          <p className="header">Fecha:</p>
          <p className="description">{moment(expire_date).format("ll")}</p>
        </div>
        <div className="content">
          <p className="header">Nombre:</p>
          <p className="description">{name}</p>
        </div>
        <div className="content">
          <p className="header">Número de teléfono:</p>
          <p className="description">{phone}</p>
        </div>
        <div className="content">
          <p className="header">Cédula:</p>
          <p className="description">
            {doc_type}-{doc_id}
          </p>
        </div>
        <div className="content">
          <p className="header">Referencia:</p>
          <p className="description">{event_ticket_ref}</p>
        </div>
        {!is_valid && (
          <div className="verified">
            <p className="description">{"Verificado"}</p>
          </div>
        )}
      </div>
      {is_valid && is_active && (
        <button onClick={handleSubmit} disabled={!is_active || !is_valid}>
          Validar
        </button>
      )}
    </div>
  );
};

export { ValidationModel };
