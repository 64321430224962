import styled from "styled-components";
import { grey_6 } from "assets/strings/colors";
import { CodeFormModel } from "./model";

export const CodeForm = styled(CodeFormModel)`
  #root &.form-appear {
    opacity: 0;
    transform: translateX(-30px);
  }

  #root &.form-appear-active {
    opacity: 0.8;
    transform: translateX(2px);
    transition: all 350ms ease-out;
  }

  #root &.form-enter {
    opacity: 0;
    transform: translateX(-30px);
  }

  #root &.form-enter-active {
    opacity: 0.8;
    transform: translateX(2px);
    transition: all 300ms ease-out;
  }

  #root &.form-enter-done {
    opacity: 1;
    transform: translateX(0);
    transition: all 50ms ease-out;
  }

  #root & {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 400px;
    .custom-title {
      marign: 0;
      margin-top: 1rem;
      display: flex;
      justify-content: space-around;

      > p {
        text-transform: uppercase;
        color: ${grey_6};
        text-align: center;
        font-weight: bold;
      }

      > i {
        padding: 0 1rem;
      }
    }

    .input-section {
      width: 80%;
      margin: 1.5rem 0 0.5rem 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      > .document {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        > .doc-type {
          width: 20%;
        }

        > .doc-in {
          border: none;
          width: 75%;
          height: 1.9rem;
          border-radius: 1rem;
          color: ${grey_6};
          font-size: 1.1rem;
          margin: 1rem 0 0.2rem 0;
          text-align: center;
          outline: none;
          box-shadow: none;
          -webkit-transition: box-shadow 250ms linear;
          transition: box-shadow 250ms linear;

          :focus {
            box-shadow: 0 1px darkgrey;
          }

          ::-webkit-input-placeholder {
            color: lightgray;
          }

          :-ms-input-placeholder {
            color: lightgray;
          }

          ::-ms-input-placeholder {
            color: lightgray;
          }

          ::placeholder {
            color: lightgray;
          }
        }
      }

      > label {
        margin-top: 1rem;
        font-size: 0.85rem;
        border-bottom: 1px solid #5b5b5b;
        display: inline-block;
        text-transform: uppercase;
        font-weight: bold;
        color: #5b5b5b;
        margin-bottom: 0.5rem;
        align-self: flex-start;
      }

      > input {
        border: none;
        width: 100%;
        border-radius: 1rem;
        color: ${grey_6};
        font-size: 1.1rem;
        margin: 1rem 0 0.2rem 0;
        text-align: center;
        outline: none;
        box-shadow: none;
        -webkit-transition: box-shadow 250ms linear;
        transition: box-shadow 250ms linear;

        :focus {
          box-shadow: 0 1px darkgrey;
        }

        ::-webkit-input-placeholder {
          color: lightgray;
        }

        :-ms-input-placeholder {
          color: lightgray;
        }

        ::-ms-input-placeholder {
          color: lightgray;
        }

        ::placeholder {
          color: lightgray;
        }
      }
    }
  }
`;
