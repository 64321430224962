import React, { useContext } from "react";
import { WithdrawHistoryContext } from "../context";
import { Grid } from "semantic-ui-react";
import HistoryFormAndList from "../containers/HistoryFormAndList";
import { ResponsiveColumn } from "components/responsive-column/styles";
import { IconBackContent } from "components/icon-back";
import TransitionPanel from "../containers/TransitionPanel";
import WithdrawDetail from "../components/withdraw-detail.js";

const WithdrawHistory = () => {
  const { state, changeState, viewType } = useContext(WithdrawHistoryContext);
  const handleBack = (event) => {
    event.preventDefault();
    changeState({ withdrawSelected: null });
  };
  const isPhone = viewType === "phone";
  return (
    <Grid padded centered>
      <ResponsiveColumn
        computer={8}
        mobile={16}
        show={!isPhone || (isPhone && !state.withdrawSelected)}
        withBorder
      >
        <HistoryFormAndList />
      </ResponsiveColumn>
      <ResponsiveColumn computer={8} mobile={16} show={state.withdrawSelected}>
        <IconBackContent
          handleBack={handleBack}
          show={state.withdrawSelected && isPhone}
        />
        <TransitionPanel show={state.withdrawSelected}>
          <WithdrawDetail />
        </TransitionPanel>
      </ResponsiveColumn>
    </Grid>
  );
};

export default WithdrawHistory;
