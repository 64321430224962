import React from 'react'
import { PanelTitle } from 'components/panel'
import { CSSTransition } from 'react-transition-group'
import { Button } from 'components/buttons'

const InvalidUserModel = ({
  viewType,
  viewState,
  username,
  removeSelectedUser,
  className
}) => {
  return (
    <CSSTransition
      in={(viewState === 'user_selection')}
      mountOnEnter
      timeout={300}
      appear={true}
      classNames='invalid'>
      <div className={className}>
        <PanelTitle key='title'>
          Lo sentimos,
        </PanelTitle>
        <p className='text'>el usuario</p>
        <p className='username'>@{username}</p>
        <p className='text'>parece tener un ticket asignado</p>
        {viewType === 'phone' &&
          <Button 
          color='grey'
          disabled={
            (viewState === 'user_selection_loading'
            || viewState === 'ticket_digitize_loading'
            || viewState === 'ticket_digitize_done') ? true : false}
          onClick={removeSelectedUser}>
            Volver
          </Button>
        }
      </div>
    </CSSTransition>
  )
}

export { InvalidUserModel }