import React from "react";
import moment from "moment";
import "moment/locale/es-us";
import okCircleIcon from "assets/img/ok-circle-icon-64px.png";
import excamCircleIcon from "assets/img/exclam-circle-icon-64px.png";
import bellCircleIcon from "assets/img/bell-circle-icon-64px.png";
import { formatPrice } from "services/utils";
import {
  InvoiceListItem,
  InvoicePhoneListItem,
  InvoiceListHeader,
  NoInvoiceFound,
} from "../invoice-list-item";

moment.locale("es-us");

const InvoiceListItemModel = ({
  handleInvoiceSelection,
  billingPeriodStart,
  billingPeriodEnd,
  totalAmount,
  currency,
  status,
  index,
  className,
}) => {
  return (
    <dd id={index} className={className} onClick={handleInvoiceSelection}>
      <div className="period-section">
        <p className="date">
          <span>desde: </span>
          {billingPeriodStart}
        </p>
        <p className="date">
          <span>hasta: </span>
          {billingPeriodEnd}
        </p>
      </div>
      <p className="amount">{formatPrice(totalAmount) + " " + currency}</p>
      <div className="status">
        {status === "paid" && <img src={okCircleIcon} alt="paid" />}
        {status === "paid" && <p className="paid">pagado</p>}

        {status === "pending" && <img src={bellCircleIcon} alt="pending" />}
        {status === "pending" && <p className="pending">pendiente</p>}

        {status === "overdue" && <img src={excamCircleIcon} alt="overdue" />}
        {status === "overdue" && <p className="overdue">vencido</p>}
      </div>
    </dd>
  );
};

const InvoicePhoneListItemModel = ({
  handleInvoiceSelection,
  billingPeriodStart,
  billingPeriodEnd,
  totalAmount,
  currency,
  status,
  index,
  className,
}) => {
  return (
    <dd id={index} className={className} onClick={handleInvoiceSelection}>
      {status === "paid" && (
        <img src={okCircleIcon} alt="paid" className="status" />
      )}
      {status === "pending" && (
        <img src={bellCircleIcon} alt="pending" className="status" />
      )}
      {status === "overdue" && (
        <img src={excamCircleIcon} alt="overdue" className="status" />
      )}

      <p className="title">total</p>
      <p>{formatPrice(totalAmount) + " " + currency}</p>
      <p className="title">período</p>
      <p>
        {billingPeriodStart} - {billingPeriodEnd}
      </p>
    </dd>
  );
};

const InvoiceListHeaderModel = ({ className }) => {
  return (
    <dd className={className}>
      <p className="col forty">período de facturación</p>
      <p className="col forty">Total</p>
      <p className="col twenty">Estado</p>
    </dd>
  );
};

const NoInvoiceFoundModel = ({ className }) => {
  return <p className={className}>No se han encontrado recibos</p>;
};

function formatInvoicesList(invoices, viewState, handleInvoiceSelection) {
  // Declaramos el array que devolverá la función
  let invoicesList = [];
  let formatString = "DD [de] MMMM YYYY";
  if (viewState === "invoices_pagination_loading") {
    return (
      <div
        className="ui active loader"
        style={{
          marginTop: "3rem",
          position: "relative",
        }}
      ></div>
    );
  }

  if (viewState === "invoices_search_done") {
    if (!invoices.length) {
      return <NoInvoiceFound />;
    } else {
      invoicesList.push(<InvoiceListHeader key="header" />);

      invoices.forEach((invoice, index) => {
        let billingPeriodStart = moment(invoice.billing_period.start).format(
          formatString
        );

        let billingPeriodEnd = moment(invoice.billing_period.end).format(
          formatString
        );
        invoicesList.push(
          <InvoiceListItem
            handleInvoiceSelection={handleInvoiceSelection}
            billingPeriodStart={billingPeriodStart}
            billingPeriodEnd={billingPeriodEnd}
            totalAmount={invoice.total_amount}
            currency={invoice.building.rate.currency}
            status={invoice.status}
            index={index}
            key={invoice._id}
          />
        );
      });
      return invoicesList;
    }
  }
}

function formatInvoicesPhoneList(invoices, viewState, handleInvoiceSelection) {
  // Declaramos el array que devolverá la función
  let invoicesList = [];
  let formatString = "DD / MM / YYYY";
  if (viewState === "invoices_pagination_loading") {
    return (
      <div
        className="ui active loader"
        style={{
          marginTop: "3rem",
          position: "relative",
        }}
      ></div>
    );
  }

  if (viewState === "invoices_search_done") {
    if (!invoices.length) {
      return <NoInvoiceFound />;
    } else {
      invoices.forEach((invoice, index) => {
        let billingPeriodStart = moment(invoice.billing_period.start).format(
          formatString
        );
        let billingPeriodEnd = moment(invoice.billing_period.end).format(
          formatString
        );
        invoicesList.push(
          <InvoicePhoneListItem
            handleInvoiceSelection={handleInvoiceSelection}
            billingPeriodStart={billingPeriodStart}
            billingPeriodEnd={billingPeriodEnd}
            totalAmount={invoice.total_amount}
            currency={invoice.building.rate.currency}
            status={invoice.status}
            index={index}
            key={invoice._id}
          />
        );
      });
      return invoicesList;
    }
  }
}

export {
  InvoiceListItemModel,
  InvoicePhoneListItemModel,
  InvoiceListHeaderModel,
  NoInvoiceFoundModel,
  formatInvoicesList,
  formatInvoicesPhoneList,
};
