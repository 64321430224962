import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'components/buttons'

const NewTermsModel = ({
  accept,
  cancel,
  loading,
  className
}) => {
  return(
    <div className={className}>
      <h3>Nuevos Terminos y Condiciones</h3>
      <p>Para continuar haciendo uso de Pago<b>Directo</b>, porfavor acepte nuestros nuevos <Link target='_blank' to='/policies?type=general'>Terminos y Condiciones</Link></p>
      <Button
        color='blue'
        disabled={loading}
        onClick={accept}>
        {loading ? 'Cargando...' : 'Aceptar'}
      </Button>
      <Button
        color='red'
        onClick={cancel}>
        Cancelar
      </Button>
    </div>
  )
}

export { NewTermsModel }