import React from "react";
import { Grid } from "semantic-ui-react";
import { PermissionsValidator } from "components/permissions-validator";
import PopupMessage from "components/popup-message";
import { formatUserList } from "../components/user-list-item";
import { NotificationsPanel } from "../components/notification-panel";
import { SearchByUser } from "../components/search-by-user";
import { SearchByTickets } from "../components/search-by-tickets";
import { SwitchSlider } from "components/checkboxes";

const MPosUsersNotificationsPhoneView = ({
  viewState,
  viewType,
  users,
  selectedUsers,
  searchParam,
  searchType,
  loading,
  tickets,
  pd_fee,
  iva,
  screenOptions,
  logosOptions,
  handleSearchTickets,
  handleTicketSelection,
  handleSearchType,
  handleSearchUser,
  handleSearchInputChange,
  handleUserSelection,
  handleUserUnselection,
  handleAllTicketsSelection,
  handleAllUsersSelection,
  allSelection,
  handleBack,
  location,
  throwPopupMessage,
  popupMessages,
  popupMessagesType,
  showPopup,
  closePopup,
}) => {
  return (
    <Grid padded>
      {" "}
      <PermissionsValidator route={location.pathname} />
      <PopupMessage
        messageType={popupMessagesType}
        showPopup={showPopup}
        messages={popupMessages}
        closePopup={closePopup}
      />
      <Grid.Column width={16}>
        {viewState === "user_search" && (
          <SwitchSlider
            margin="0 0 .5rem 0"
            selected={searchType}
            options={[
              { data: "by_user", displayData: "Buscar por usuario" },
              { data: "by_tickets", displayData: "Tickets de hoy" },
            ]}
            handleSelection={handleSearchType}
          />
        )}
        {viewState === "user_search" && searchType === "by_user" && (
          <SearchByUser
            searchParam={searchParam}
            handleSearchInputChange={handleSearchInputChange}
            viewState={viewState}
            users={users}
            formatUserList={formatUserList}
            handleUserSelection={handleUserSelection}
            handleSearchUser={handleSearchUser}
            handleAllUsersSelection={handleAllUsersSelection}
            loading={loading}
            allSelection={allSelection}
          />
        )}
        {viewState === "user_search" && searchType === "by_tickets" && (
          <SearchByTickets
            tickets={tickets}
            pd_fee={pd_fee}
            iva={iva}
            handleSearchTickets={handleSearchTickets}
            handleTicketSelection={handleTicketSelection}
            handleAllTicketsSelection={handleAllTicketsSelection}
            loading={loading}
            allSelection={allSelection}
          />
        )}
        {viewState === "user_selection_done" && (
          <Grid padded>
            <NotificationsPanel
              users={selectedUsers}
              handleUserUnselection={handleUserUnselection}
              throwPopupMessage={throwPopupMessage}
              viewType={viewType}
              handleBack={handleBack}
              screenOptions={screenOptions}
              logosOptions={logosOptions}
              generalLoading={loading}
            />
          </Grid>
        )}
      </Grid.Column>
    </Grid>
  );
};

export default MPosUsersNotificationsPhoneView;
