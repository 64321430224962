import React from "react";
import Ticket from "components/ticket";
import { Grid } from "semantic-ui-react";
import PopupMessage from "components/popup-message";
import SearchTicketsForm from "./search-tickets-form";
import {
  formatTicketsList,
  formatTicketsListLessInfo,
} from "components/ticket-list-item";
import { EntitiesList, Pagination } from "components/entities-list";
import { OptionConfirmation } from "./option-confirmation";

const MposTicketsSearchPhoneView = ({
  viewState,
  state,
  searchType,
  searchParam,
  countTickets,
  fromDate,
  endDate,
  pd_fee,
  gift,
  regular,
  premium,
  monthlyPremium,
  iva,
  ready,
  paid,
  closed,
  closedPremiumNoTransaction,
  closedSuperadmin,
  discarded,
  tickets,
  nextPageExist,
  currentPage,
  selectedTicket,
  showTicket,
  popupMessages,
  popupMessagesType,
  showPopup,
  role,
  location,
  creationRule,
  handleTicketCreation,

  exitTicketDate,
  handleTicketExitDate,
  exitTicketHour,
  handleTicketExitHour,
  exitTicketMinute,
  handleTicketExitMinute,

  paidTicketDate,
  handlePaidTicketDate,
  paidTicketHour,
  handlePaidTicketHour,
  paidTicketMinute,
  handlePaidTicketMinute,


  handleBuildingSearch,
  buildingName,
  buildingSearch,
  category,

  handleAccessOptions,
  accessOptions,
  showConfirmButton,
  ticketOptionSelected,
  ticketOptions,
  toggleOptionConfirmation,
  handleOptionsSelection,
  handleSearchTypeButtons,
  handleSearchInputChange,
  handleFromDate,
  handleEndDate,
  handleTypesButton,
  handleStatusButton,
  handleSubmit,
  handlePageUp,
  handlePageDown,
  handleTicketSelection,
  removeSelectedTicket,
  closePopup,
  confirm,
  newUsersRule,
  handleNewUsers,
}) => {
  return (
    <Grid padded>
      <PopupMessage
        messageType={popupMessagesType}
        showPopup={showPopup}
        messages={popupMessages}
        closePopup={closePopup}
      />
      {(viewState === "option_confirmation" ||
        viewState === "option_done" ||
        viewState === "option_loading") && (
        // If view IS on confirmation process, show confirmation popup
        <PopupMessage
          messageType={popupMessagesType}
          showPopup={showPopup}
          messages={popupMessages}
          closePopup={toggleOptionConfirmation}
        >
          {(viewState === "option_loading" ||
            viewState === "option_confirmation") && (
            <OptionConfirmation
              viewState={viewState}
              ticket={selectedTicket}
              confirm={confirm}
              handleAccessOptions={handleAccessOptions}
              accessOptions={accessOptions}
              showConfirmButton={showConfirmButton}
              ticketOptionSelected={ticketOptionSelected}
              cancel={toggleOptionConfirmation}

              ticketData={selectedTicket}

              exitTicketDate={exitTicketDate}
              handleTicketExitDate={handleTicketExitDate}
              exitTicketHour={exitTicketHour}
              handleTicketExitHour={handleTicketExitHour}
              exitTicketMinute={exitTicketMinute}
              handleTicketExitMinute={handleTicketExitMinute}

              paidTicketDate={paidTicketDate}
              handlePaidTicketDate={handlePaidTicketDate}
              paidTicketHour={paidTicketHour}
              handlePaidTicketHour={handlePaidTicketHour}
              paidTicketMinute={paidTicketMinute}
              handlePaidTicketMinute={handlePaidTicketMinute}
            />
          )}
        </PopupMessage>
      )}

      <Grid.Column width={16}>
        {(viewState === "ticket_search" ||
          viewState === "ticket_search_loading" ||
          viewState === "ticket_search_done") && [
          <SearchTicketsForm
            // props
            key="search-tickets-form"
            searchType={searchType}
            searchParam={searchParam}
            fromDate={fromDate}
            endDate={endDate}
            gift={gift}
            regular={regular}
            premium={premium}
            monthlyPremium={monthlyPremium}
            ready={ready}
            paid={paid}
            closed={closed}
            closedSuperadmin={closedSuperadmin}
            closedPremiumNoTransaction={closedPremiumNoTransaction}
            discarded={discarded}
            role={role}
            phoneView={true}
            handleBuildingSearch={handleBuildingSearch}
            buildingName={buildingName}
            buildingSearch={buildingSearch}
            category={category}
            creationRule={creationRule}
            handleTicketCreation={handleTicketCreation}
            // methods
            handleSearchTypeButtons={handleSearchTypeButtons}
            handleSearchInputChange={handleSearchInputChange}
            handleFromDate={handleFromDate}
            handleEndDate={handleEndDate}
            handleTypesButton={handleTypesButton}
            handleStatusButton={handleStatusButton}
            handleSubmit={handleSubmit}
            newUsersRule={newUsersRule}
            handleNewUsers={handleNewUsers}
          />,
          <EntitiesList
            key="tickets-list"
            height="calc(100vh - 160px - 4rem)"
            viewState={viewState}
            entities={tickets}
            additionalArgs={!pd_fee && !iva ? [] : [{ pd_fee, iva, category }]}
            formattingFunction={
              category === "pagodirecto"
                ? formatTicketsList
                : formatTicketsListLessInfo
            }
            selectionHandler={handleTicketSelection}
          >
            <Pagination
              padding="0.65rem 0 0.65rem 0"
              nextPageExist={nextPageExist}
              currentPage={currentPage}
              handlePageUp={handlePageUp}
              handlePageDown={handlePageDown}
            >
              <div
                style={{
                  background: "#0899CB",
                  color: "#FFFFFF",
                  padding: "0.5rem 0rem 0.5rem 0rem",
                  textAlign: "center",
                  fontSize: "1rem",
                  fontWeight: "bold",
                }}
              >
                Cantidad de Tickets:
                {" " + countTickets}
              </div>
            </Pagination>
          </EntitiesList>,
        ]}

        {(viewState === "ticket_selection_done" ||
          viewState === "option_confirmation" ||
          viewState === "option_done" ||
          viewState === "option_loading") && (
          <Ticket
            viewState={viewState}
            state={state}
            category={category}
            ticketData={selectedTicket}
            ticketOptions={ticketOptions}
            toggleOptionConfirmation={toggleOptionConfirmation}
            handleOptionsSelection={handleOptionsSelection}
            showTicket={showTicket}
            removeTicket={removeSelectedTicket}
            phoneView
            popupMessages={popupMessages}
            popupMessagesType={popupMessagesType}
            closePopup={closePopup}
          />
        )}
      </Grid.Column>
    </Grid>
  );
};

export default MposTicketsSearchPhoneView;
