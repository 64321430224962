import React from "react";
import styled from "styled-components";
import {
  Panel,
  PanelTitle,
  PanelSectionHeader,
  PanelSectionContent,
} from "components/panel";
import { CSSTransition } from "react-transition-group";
import { formatPrice } from "services/utils";

const AccountDetailsModel = ({ data, show, className }) => {
  if (!data || !show) return null;

  const statusMapped = {
    active: "Activo",
    inactive: "Inactivo",
  };
  const currencyMapped = {
    BS: "VES",
    USD: "USD",
    KLD: "KLD",
  };
  const { name, email, status, amounts, withdraw } = data;
  return (
    <CSSTransition
      in={data && true}
      timeout={350}
      appear={data && true}
      classNames="panel"
    >
      <Panel>
        <PanelTitle>Detalles de la cuenta</PanelTitle>

        <PanelSectionHeader>Nombre:</PanelSectionHeader>
        <PanelSectionContent>{name}</PanelSectionContent>

        <PanelSectionHeader>Email:</PanelSectionHeader>
        <PanelSectionContent>{email}</PanelSectionContent>

        <PanelSectionHeader>Estado:</PanelSectionHeader>
        <PanelSectionContent>{statusMapped[status]}</PanelSectionContent>

        <PanelSectionHeader>Porcentaje de retención ISLR:</PanelSectionHeader>
        <PanelSectionContent>
          {withdraw.islr_withholding_percentage}%
        </PanelSectionContent>

        <PanelSectionHeader>Porcentaje de retención IVA:</PanelSectionHeader>
        <PanelSectionContent>
          {withdraw.iva_withholding_percentage}%
        </PanelSectionContent>

        <PanelSectionHeader>Porcentaje IVA:</PanelSectionHeader>
        <PanelSectionContent>{withdraw.iva_percentage}%</PanelSectionContent>

        <PanelSectionHeader>Fondos bloqueados:</PanelSectionHeader>
        {amounts.map((amount, index) => (
          <PanelSectionContent
            key={`${index}-${amount.currency}`}
          >{`${formatPrice(amount.blocked_funds)} ${
            currencyMapped[amount.currency]
          }`}</PanelSectionContent>
        ))}
        <PanelSectionHeader>Fondos:</PanelSectionHeader>
        {amounts.map((amount, index) => (
          <PanelSectionContent
            key={`${index}-${amount.currency}`}
          >{`${formatPrice(amount.funds)} ${currencyMapped[amount.currency]}
          `}</PanelSectionContent>
        ))}
      </Panel>
    </CSSTransition>
  );
};

const AccountDetails = styled(AccountDetailsModel)`
  #root & {
  }
`;

export default AccountDetails;
