import React from "react";

export const CurrencyViewerModel = ({ currency, className }) => {
  return (
    <div className={className}>
      <p className="header">Moneda:</p>
      <p className="content">{currency}</p>
    </div>
  );
};
