import React, { memo } from "react";
import { connect } from "react-redux";
import { clearReduxStore } from "redux/actions/general";
import { addRoutes } from "redux/actions/session";
import { errors } from "assets/strings/texts";
import {
  getRole,
  getToken,
  getCategory,
  getPermissions,
  getSession,
} from "redux/selectors";
import { getSelectedCommerce } from "redux/selectors/commerces";
import { Redirect, withRouter } from "react-router-dom";
import { throwPopupMessage } from "redux/actions/popup-messages";
import {
  noTokenRoutes,
  generalRoutes,
  monthlyPremiumRoutes,
  commercePostpaidParkingPermissions,
  commercePrepaidParkingPermissions,
  commercePermissions,
  parkingPermissions,
  superadminPermissions,
} from "assets/strings/routes";

const PermissionsValidatorModel = memo(
  ({
    location,
    category,
    token,
    selectedCommerce,
    permissions,
    throwPopupMessage,
    session,
    clearReduxStore,
    addRoutes,
  }) => {
    const route = location.pathname;
    const hasMonthlyPremium =
      selectedCommerce &&
      selectedCommerce.parking &&
      selectedCommerce.parking.building &&
      selectedCommerce.parking.building.monthly_premium &&
      selectedCommerce.parking.building.monthly_premium.is_active;

    const verifyAuthPermissions = ({ routes, clearLocalStorage = false }) => {
      if (clearLocalStorage) localStorage.clear();
      const routeExist = routes.find((key) => key === route);
      if (!routeExist) {
        if (process.env.NODE_ENV === "development") {
          console.log("<PermissionsValidator />: Access Denied");
        }
        throwPopupMessage("alert", errors.AUTHENTICATION);
        return <Redirect to="/" />;
      }
      return null;
    };

    const getRoutes = (objRoutes) => {
      let routesList = [...noTokenRoutes];
      Object.keys(permissions).forEach((permission) => {
        if (permissions[permission])
          routesList = routesList.concat(objRoutes[permission]);
      });
      const superadmin = category === "pagodirecto" && !permissions.taquilla;
      const parkingMonthlyPremium =
        category === "estacionamiento" && hasMonthlyPremium;
      if (parkingMonthlyPremium || superadmin) {
        routesList = routesList.concat(monthlyPremiumRoutes);
      }
      return routesList;
    };

    if (!token) {
      if (noTokenRoutes.find((key) => key === route)) return null;
      return <Redirect to="/" />;
    } else if (permissions && category) {
      if (route === "/") {
        localStorage.clear();
        clearReduxStore();
        return null;
      }
      const categories = {
        pagodirecto: {
          routes: getRoutes(superadminPermissions),
          defaultRoute: permissions.taquilla
            ? "/mpos/boxoffice"
            : superadminPermissions.defaultRoute,
        },
        estacionamiento: {
          routes: getRoutes(parkingPermissions),
          defaultRoute: parkingPermissions.defaultRoute,
        },
      };
      const billingType =
        selectedCommerce &&
        selectedCommerce.parking &&
        selectedCommerce.parking.billing_type;

      const parkingCommercePermissions = {
        prepaid: commercePrepaidParkingPermissions,
        postpaid: commercePostpaidParkingPermissions,
      };
      const defaultCategory = {
        routes: getRoutes(
          parkingCommercePermissions[billingType] || commercePermissions
        ),
        defaultRoute: commercePermissions.defaultRoute,
      };
      const routes =
        (categories[category] && categories[category].routes) ||
        defaultCategory.routes;
      if (!Array.isArray(session.routes) || !session.routes.length) {
        addRoutes(routes);
      }

      const hasPermission = routes.includes(route);
      if (hasPermission) return null;
      else {
        return (
          <Redirect
            to={
              (categories[category] && categories[category].defaultRoute) ||
              defaultCategory.defaultRoute
            }
          />
        );
      }
    } else {
      return verifyAuthPermissions({
        routes: [...noTokenRoutes, ...generalRoutes],
        clearLocalStorage: true,
      });
    }

    // If role is not yet set in redux store
    // let available the register routes only
  }
);

const mapStateToProps = (state) => {
  return {
    role: getRole(state),
    token: getToken(state),
    category: getCategory(state),
    selectedCommerce: getSelectedCommerce(state),
    permissions: getPermissions(state),
    session: getSession(state),
  };
};

const mapDispatchToProps = {
  throwPopupMessage,
  clearReduxStore,
  addRoutes,
};

const PermissionsValidator = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PermissionsValidatorModel)
);

export { PermissionsValidator };
