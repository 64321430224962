import React from "react";
import { Button } from "components/buttons";

const ConfirmChangesModel = ({ onAccept, onCancel, className }) => {
  return (
    <div className={className}>
      <p>Por favor, confirme los cambios</p>
      <Button color="blue" onClick={onAccept}>
        Confirmar
      </Button>
      <Button color="grey" onClick={onCancel}>
        Cancelar
      </Button>
    </div>
  );
};

export default ConfirmChangesModel;
