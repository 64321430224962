import React from "react";
import { Button } from "components/buttons";
import { general as texts } from "assets/strings/texts";

const RechargeConfirmationModel = ({
  viewState,
  selectedUser,
  amount,
  recharge,
  cancel,
  className,
}) => {
  return (
    <div className={className}>
      <p className="username">@{selectedUser.username}</p>
      <p>
        <b>Monto:</b> {amount} Bs.S.
      </p>
      <Button
        color="blue"
        disabled={viewState === "recharge_loading"}
        onClick={recharge}
      >
        {viewState === "recharge_confirmation" && texts.accept}
        {viewState === "recharge_loading" && `${texts.wait}...`}
      </Button>
      <Button color="grey" onClick={cancel}>
        Cancelar
      </Button>
    </div>
  );
};

export { RechargeConfirmationModel };
