import SidebarContentModel from "./model";
import styled from "styled-components";
import { grey_5 } from "assets/strings/colors";

const SidebarContent = styled(SidebarContentModel)`
  #root & {
    background: #1b1c1d;
    width: 460px;
    height: 100vh;
    > .options-container {
      height: 90.75vh;
      max-height: 90.75vh;
      overflow-x: hidden;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      div.item {
        div.selector {
          padding: 1rem 1.15rem;

          > .status {
            display: inline-block;
            width: 30px;
          }

          > .dropdown {
            display: inline-block;
            width: calc(100% - 30px);

            .visible.menu.transition {
              width: 100%;
              background: #9e9e9e;
              margin-top: 5px;
            }
          }
        }
      }

      > .footer {
        color: lightgray;
        width: 100%;
        z-index: 4;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        > .username-info {
          margin: 0.2rem 0 0 0;
          border-top: 2px groove #353535;
          padding: 0.5rem 1rem 0 1rem;
          color: lightgray;
          height: 56px;
          > .version {
            margin: 0;
            font-size: 0.7rem;
          }

          > .commerce {
            margin: 0;
          }

          > .username {
            margin: 0;
            font-weight: bold;
          }
        }
        > .pd-info {
          text-align: center;
          margin: 1rem 1rem 0 1rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;

          > .logoApp {
            display: inline-block;
            width: 10vh;
            vertical-align: middle;
          }

          > .support {
            display: inline-block;
            vertical-align: middle;
            margin: 0;

            p:first-child {
              margin-bottom: 0.5rem;
              font-weight: bold;
            }

            > p {
              margin: 0;
            }
          }
          > .policies {
            text-align: center;
            margin: 0 1rem 0 1rem;
            padding: 0.5px;

            > p {
              margin: 0.2rem 0.2rem 0.2rem 0.2rem;

              > a {
                color: ${grey_5};
                text-decoration: underline;
              }
            }
          }
        }
      }
    }

    .pago-directo {
      text-align: center;
      width: 100%;
      height: 9.25vh;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      > .logo {
        height: 40vh;
        margin-right: 1rem;
        //margin-bottom: -1.2rem;
      }

      > .iso {
        height: 2.2vh;
        margin-right: 1rem;
      }
    }

    @media (max-width: 576px) {
      width: 230px;
      div.item {
        font-size: 0.9rem;
      }

      .pago-directo {
        > .iso {
          height: 1vh;
          margin-right: 1rem;
        }
      }
    }
  }
`;

export default SidebarContent;
