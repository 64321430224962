import React, { useContext } from "react";
import MPosUsersLogsView from "./components/mpos-users-logs-view";
import MPosUsersLogsPhoneView from "./components/mpos-users-logs-phone-view";
import { UsersLogsContext, LogsState } from "./context";
import { DefaultRender } from "components/default-render";

const LogsResponsive = () => {
  const { viewType } = useContext(UsersLogsContext);
  const views = {
    desktop: <MPosUsersLogsView />,
    phone: <MPosUsersLogsPhoneView />,
  };
  return views[viewType] || <DefaultRender />;
};

const MPosUsersLogs = ({ location }) => {
  return (
    <LogsState location={location}>
      <LogsResponsive />
    </LogsState>
  );
};

export default MPosUsersLogs;
