import React from "react";
import moment from "moment";
import "moment/locale/es-us";
import okCircleIcon from "assets/img/ok-circle-icon-64px.png";
import isotipoPd from "assets/img/logotipo-pd-app.png";
import defaultUser from "assets/img/default-user-grey.png";
import excamCircleIcon from "assets/img/exclam-circle-icon-64px.png";
import closeCircleIcon from "assets/img/close-circle-icon-64px.png";
import bellCircleIcon from "assets/img/bell-circle-icon-64px.png";
import { formatPrice } from "services/utils";
import {
  MovementListItem,
  MovementPhoneListItem,
  MovementListHeader,
  NoMovementFound,
} from "../movements-list-item";

moment.locale("es-us");

const MovementListItemModel = ({
  handleMovementSelection,
  movementDate,
  counterpart,
  totalAmount,
  currency,
  type,
  transactionTag,
  status,
  prevFunds,
  funds,
  index,
  extension,
  className,
  style,
}) => {
  if (!currency) currency = "Bs";
  return (
    <dd
      id={index}
      className={className}
      onClick={handleMovementSelection}
      style={style}
    >
      <div className="period-section">
        <p className="date">{movementDate}</p>
      </div>

      {type === "debit" && (
        <p className="amount-debit">
          {`-${formatPrice(totalAmount)} ${currency}`}
        </p>
      )}
      {type === "credit" && (
        <p className="amount-credit">
          {`+${formatPrice(totalAmount)} ${currency}`}
        </p>
      )}

      <div className="funds">
        {prevFunds && (
          <p>
            <span>antes:</span>
            {` ${formatPrice(prevFunds)} ${currency}`}
          </p>
        )}
        {funds && (
          <p>
            <span>después:</span>
            {` ${formatPrice(funds)} ${currency}`}
          </p>
        )}
      </div>
      <div className="user">
        <img src={defaultUser} alt="paid" className="user" />
        <p className="user">{extension}</p>
      </div>
    </dd>
  );
};

const MovementPhoneListItemModel = ({
  handleMovementSelection,
  viewState,
  movementDate,
  counterpart,
  totalAmount,
  currency,
  type,
  transactionTag,
  status,
  index,
  style,
  className,
}) => {
  if (!currency) currency = "BS";
  return (
    <dd
      id={index}
      className={className}
      onClick={handleMovementSelection}
      style={style}
    >
      {status === "approved" && type === "credit" && (
        <img src={okCircleIcon} alt="approved" className="status" />
      )}
      {status === "reversed" && type === "credit" && (
        <img src={okCircleIcon} alt="approved" className="status" />
      )}
      {status === "approved" && type === "debit" && (
        <img src={excamCircleIcon} alt="approved" className="status" />
      )}
      {status === "pending" && (
        <img src={bellCircleIcon} alt="pending" className="status" />
      )}
      {status === "rejected" && (
        <img src={closeCircleIcon} alt="rejected" className="status" />
      )}

      <p className="title">Fecha</p>
      <p>{movementDate}</p>
      <p className="title">Monto</p>

      {type === "debit" && (
        <p>{"-" + formatPrice(totalAmount) + " " + currency}</p>
      )}
      {type === "credit" && (
        <p className="amount-credit">
          {formatPrice(totalAmount) + " " + currency}
        </p>
      )}
      {counterpart === "pagodirecto" && (
        <img src={isotipoPd} alt="paid" className="status" />
      )}
      {counterpart !== "pagodirecto" && (
        <img src={defaultUser} alt="paid" className="status" />
      )}
      <p className="title"> Usuario</p>
      {counterpart === "pagodirecto" && <p>{"pagoDirecto"}</p>}
      {counterpart !== "pagodirecto" && <p>{"@" + counterpart}</p>}
    </dd>
  );
};

const MovementListHeaderModel = ({ className }) => {
  return (
    <dd className={className}>
      <p className="col twenty-five">Fecha</p>
      <p className="col twenty-five">Monto</p>
      <p className="col twenty-five">Fondos</p>
      <p className="col twenty-five">Responsable</p>
    </dd>
  );
};

const NoMovementFoundModel = ({ className }) => {
  return <p className={className}>No se han encontrado transacciones</p>;
};

function formatMovementsList(
  movements,
  viewState,
  handleMovementSelection,
  loading,
  extensions
) {
  // Declaramos el array que devolverá la función
  let movementsList = [];
  let formatString = "DD [de] MMMM YYYY";
  if (loading) {
    return (
      <div
        className="ui active loader"
        style={{
          marginTop: "3rem",
          position: "relative",
        }}
      ></div>
    );
  }
  if (!Array.isArray(movements) || !movements.length)
    return <NoMovementFound />;

  movementsList.push(<MovementListHeader key="header" />);
  movements.forEach((movement, index) => {
    let movementDate = moment(movement.created_at).format(formatString);
    let extension = "Usuario";
    if (movement.child && extensions.length > 0) {
      extensions.forEach((kid) => {
        if (kid._id === movement.child) extension = kid.name;
      });
    }
    movementsList.push(
      <MovementListItem
        handleMovementSelection={handleMovementSelection}
        viewState={viewState}
        movementDate={movementDate}
        counterpart={
          movement.counterpart ? movement.counterpart.username : "Yo"
        }
        totalAmount={movement.pd_amount}
        funds={movement.funds}
        prevFunds={movement.previous_funds}
        currency={movement.currency}
        type={movement.type}
        transactionTag={movement.transaction_tag}
        status={movement.status}
        index={index}
        extension={extension}
        key={movement._id}
      />
    );
  });

  return movementsList;
}

function formatMovementsPhoneList(
  movements,
  viewState,
  handleMovementSelection,
  loading
) {
  let movementsList = [];
  let formatString = "DD / MM / YYYY";
  if (loading) {
    return (
      <div
        className="ui active loader"
        style={{
          marginTop: "3rem",
          position: "relative",
        }}
      ></div>
    );
  }

  if (!Array.isArray(movements) || !movements.length)
    return <NoMovementFound />;

  movements.forEach((movement, index) => {
    let movementDate = moment(movement.created_at).format(formatString);

    movementsList.push(
      <MovementPhoneListItem
        handleMovementSelection={handleMovementSelection}
        movementDate={movementDate}
        counterpart={
          movement.counterpart ? movement.counterpart.username : "Yo"
        }
        totalAmount={movement.pd_amount}
        currency={movement.currency}
        type={movement.type}
        status={movement.status}
        index={index}
        key={movement._id}
      />
    );
  });
  return movementsList;
}

export {
  MovementListItemModel,
  MovementPhoneListItemModel,
  MovementListHeaderModel,
  NoMovementFoundModel,
  formatMovementsList,
  formatMovementsPhoneList,
};
