import React from "react";
import styled from "styled-components";
import PopupMessage from "components/popup-message";
import { Loader } from "components/loader";
import { BoxofficeForm } from "../components/boxoffice-form";
import { grey_bg } from "assets/strings/colors";

const SignupBoxofficeViewModel = ({
  loading,
  buildingOptions,
  building,
  username,
  genPromoTicket,
  handleBuilding,
  handleUsername,
  handleGenPromoTicket,
  handleSubmit,
  popupMessages,
  popupMessagesType,
  showPopup,
  closePopup,
  className,
}) => {
  return (
    <div className={className}>
      <PopupMessage
        messageType={popupMessagesType}
        showPopup={showPopup}
        messages={popupMessages}
        closePopup={closePopup}
      />
      {!loading && (
        <BoxofficeForm
          buildingOptions={buildingOptions}
          building={building}
          username={username}
          genPromoTicket={genPromoTicket}
          handleGenPromoTicket={handleGenPromoTicket}
          handleBuilding={handleBuilding}
          handleUsername={handleUsername}
          handleSubmit={handleSubmit}
        />
      )}
      {loading && <Loader />}
    </div>
  );
};

const SignupBoxofficeView = styled(SignupBoxofficeViewModel)`
  #root && {
    height: calc(100vh - 4rem);
    min-height: 500px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${grey_bg};
  }
`;

export default SignupBoxofficeView;
