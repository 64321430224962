import React, { useContext, useEffect } from "react";
import {
  MonthlyPremiumInvoicesState,
  MonthlyPremiumInvoicesContext,
} from "./context";
import { MposMonthlyPremiumInvoicesView } from "./components/mpos-monthly-premium-invoices-view";

const MposMonthlyPremiumInvoicesLogic = ({ props }) => {
  const {
    getInvoices,
    markInvoiceAsPaid,
    state,
    changeState,
    setViewType,
    onEnter,
  } = useContext(MonthlyPremiumInvoicesContext);

  useEffect(() => {
    setViewType();
    window.addEventListener("resize", setViewType);
    document.addEventListener("keydown", onEnter);
    return () => {
      window.removeEventListener("resize", setViewType);
      document.removeEventListener("keydown", onEnter);
    };
  }, []);
  useEffect(() => {
    if (state.get_invoices) {
      getInvoices();
      changeState({ get_invoices: false });
    }
    if (state.isMarkAsPaid) {
      markInvoiceAsPaid();
      changeState({ isMarkAsPaid: false });
    }
  }, [state.get_invoices || state.isMarkAsPaid]);

  return <MposMonthlyPremiumInvoicesView />;
};

const MposMonthlyPremiumInvoices = ({
  selectedCommerce,
  history,
  location,
}) => {
  return (
    <MonthlyPremiumInvoicesState
      selectedCommerce={selectedCommerce}
      history={history}
      location={location}
    >
      <MposMonthlyPremiumInvoicesLogic />
    </MonthlyPremiumInvoicesState>
  );
};

export default MposMonthlyPremiumInvoices;
