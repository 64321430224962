import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { clearReduxStore } from "redux/actions/general";
import { useViewType } from "hooks/use-view-type";
import { AdminTransferView } from "./components/admin-transfer-view";
import { AdminTransferPhoneView } from "./components/admin-transfer-phone-view";
import { getWithdrawAccounts } from "./services/getWithdrawAccounts";
import {
  getSelectedCommerce as getSelectedCommerceFromRedux,
  getBuildings as getBuildingsFromRedux,
} from "redux/selectors";
import { AdminTransfersState } from "./context";

const MposAdminTransfer = (props) => {
  const [viewType, setViewType] = useViewType("desktop");
  const [withdraw, setWithdraw] = useState({});
  const [remoteLoading, setRemoteLoading] = useState(false);
  const { location, history, buildings, selectedCommerce } = props;
  const { state, pathname } = location;
  useEffect(() => {
    const withdrawExists = state && state.withdraw;
    if (withdrawExists) {
      const internWithdraw = state.withdraw.intern;
      setWithdraw({ ...state.withdraw });
      if (!internWithdraw) {
        getWithdrawAccounts(
          { ...state.withdraw },
          setWithdraw,
          setRemoteLoading
        );
      }
      history.replace({
        pathname,
        state: {},
      });
    }

    setViewType();
    window.addEventListener("resize", setViewType);
    return () => {
      window.removeEventListener("resize", setViewType);
    };
  }, []);

  const view = {
    phone: <AdminTransferPhoneView />,
    desktop: <AdminTransferView />,
  };
  return (
    <AdminTransfersState
      selectedCommerce={selectedCommerce}
      buildings={buildings}
      withdraw={withdraw}
      remoteLoading={remoteLoading}
    >
      {view[viewType] || <AdminTransferView />}
    </AdminTransfersState>
  );
};

const mapStateToProps = (state) => {
  let selectedCommerce = getSelectedCommerceFromRedux(state);
  let buildings = getBuildingsFromRedux(state);
  return { selectedCommerce, buildings };
};
const mapDispatchToProps = {
  clearReduxStore,
};
export default connect(mapStateToProps, mapDispatchToProps)(MposAdminTransfer);
