import React from "react";
import { Grid } from "semantic-ui-react";
import { PermissionsValidator } from "components/permissions-validator";
import { Panel } from "components/panel";
import { Button } from "components/buttons";
import PopupMessage from "components/popup-message";
import { EntitiesList } from "components/entities-list";
import { formatBuildingList } from "../components/building-list-item";
import { InputFee } from "../components/input-fee";
import { grey_4 } from "assets/strings/colors";

const MPosBuilidingPdfeePhoneView = ({
  viewState,
  loading,
  location,
  fee,
  buildingList,
  buildingsSelected,
  handleFee,
  handleBuildingSelection,
  handleBuildingUnselection,
  handleBack,
  handleSubmit,
  handleSelectAll,
  popupMessages,
  popupMessagesType,
  showPopup,
  closePopup,
}) => {
  return (
    <Grid padded>
      <PermissionsValidator route={location.pathname} />
      <PopupMessage
        messageType={popupMessagesType}
        showPopup={showPopup}
        messages={popupMessages}
        closePopup={closePopup}
      />
      <Grid.Column>
        {viewState === "selection" && (
          <p
            style={{
              fontSize: "1.5rem",
              textTransform: "uppercase",
              fontWeight: "bold",
              textAlign: "center",
              borderBottom: `2px solid ${grey_4}`,
              margin: "0",
              height: "2em",
              paddingTop: "0.5rem",
            }}
          >
            Seleccione Edificios
          </p>
        )}
        {viewState === "selection" && (
          <EntitiesList
            height="calc(100vh - 4em)"
            viewState={null}
            entities={buildingList}
            formattingFunction={formatBuildingList}
            selectionHandler={handleBuildingSelection}
            additionalArgs={[loading]}
          />
        )}
        {viewState === "selection" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Button color="blue" onClick={handleSelectAll} width="50%">
              Seleccionar Todos
            </Button>
          </div>
        )}
        {viewState === "selected" && (
          <Panel>
            <InputFee
              fee={fee}
              handleFee={handleFee}
              handleSubmit={handleSubmit}
              buildingsSelected={buildingsSelected}
            />
            <EntitiesList
              height="60vh"
              viewState={null}
              entities={buildingsSelected}
              formattingFunction={formatBuildingList}
              selectionHandler={handleBuildingUnselection}
              additionalArgs={[loading]}
            />
            <Button color="grey" onClick={handleBack}>
              Volver
            </Button>
          </Panel>
        )}
      </Grid.Column>
    </Grid>
  );
};

export default MPosBuilidingPdfeePhoneView;
