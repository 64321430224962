import React, { useContext } from "react";
import { Grid } from "semantic-ui-react";
import { SuperadminBillsContext } from "../context";
import { PopupMessageContext } from "components/popup-message";
import { FiscalForm } from "./fiscal-form";
import { OperationsForm } from "./operations-form";
import { VerticalDivider } from "components/vertical-divider";
import { Directory } from "../components/directory";
import { BillPreview } from "../components/bill-preview";
const SuperadminBillsDesktopView = () => {
  const { state } = useContext(SuperadminBillsContext);

  const currentComponent = (state) => {
    if (state.showPreview) return <BillPreview />;
    if (state.showNextPage) return <OperationsForm />;
    return <FiscalForm />;
  };

  return (
    <Grid padded>
      <PopupMessageContext context={SuperadminBillsContext} />
      <VerticalDivider width={8}>
        <Directory />
      </VerticalDivider>
      <Grid.Column width={8}>{currentComponent(state)}</Grid.Column>
    </Grid>
  );
};

export default SuperadminBillsDesktopView;
