import PopupMessageModel from "./model";
import styled, { css } from "styled-components";
import { bluePD } from "assets/strings/colors";
import { PopupMessageReduxModel, PopupMessageWithContextModel } from "./model";

const styles = css`
  #root &-appear {
    opacity: 0;
    > .popup {
      transform: translateY(-3rem);
    }
  }
  #root &-appear-active {
    opacity: 1;
    > .popup {
      transform: translateY(0);
    }
  }
  #root &-exit {
    opacity: 1;
    > .popup {
      transform: translateY(0);
    }
  }
  #root &-exit-active {
    opacity: 0;
    > .popup {
      transform: translateY(-3rem);
    }
  }

  #root & {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 200;
    transition: all 250ms ease;

    > .popup.success {
      border-bottom: 5px solid #a2c400;
      > i {
        color: #a2c400;
      }
    }
    > .popup.alert {
      border-bottom: 5px solid #ffc107;
      > i {
        color: #ffc107;
      }
    }
    > .popup.error {
      border-bottom: 5px solid #ee2b2b;
      > i {
        color: #ee2b2b;
      }
    }
    > .popup.info {
      border-bottom: 5px solid ${bluePD}};
      > i {
        color: #1ba2dd;
      }
    }
    > .popup {
      text-align: center;
      transition: all 250ms ease;
      position: relative;
      z-index: 300;
      background: white;
      padding: 1rem;
      padding-bottom: 1.9rem;
      border-radius: 0.5rem;
      max-height: 85vh;
      overflow: hidden auto;
      width: 50%;
      max-width: 320px;
      min-width: 230px;
      > i {
        float: right;
        height: 1.4rem;
        width: 1.4rem;
        cursor: pointer;
        :hover {
          transform: scale(1.3);
        }
      }
      > .header {
        font-size: 2.8rem;
        margin-bottom: 0.5rem;
      }
      > .message {
        /*Empty*/
      }
      button {
        width: 100%;
        max-width: 160px;
        margin: 1.5rem auto 0 auto;
      }
    }
    > .popup.list {
      text-align: center;
      transition: all 250ms ease;
      position: relative;
      z-index: 300;
      background: white;
      padding: 1rem;
      padding-bottom: 1.9rem;
      border-radius: 0.5rem;
      height: 100%;
      max-height: 85vh;
      overflow: hidden;
      width: 50%;
      max-width: 320px;
      min-width: 230px;
      > i {
        float: right;
        height: 1.4rem;
        width: 1.4rem;
        cursor: pointer;
        :hover {
          transform: scale(1.3);
        }
      }
      > .header {
        font-size: 2.8rem;
        margin-bottom: 0.5rem;
      }
      > .message {
        /*Empty*/
      }
      button {
        width: 100%;
        max-width: 160px;
        margin: 1.5rem auto 0 auto;
      }
    }

    @media (max-width: 768px) {
      > .popup {
        width: 60%;
      }
    }

    @media (max-width: 576px) {
      > .popup {
        width: 70%;
      }
    }
  }
`;

const PopupMessage = styled(PopupMessageModel)`
  ${styles}
`;

const PopupMessageRedux = styled(PopupMessageReduxModel)`
  ${styles}
`;
const PopupMessageContext = styled(PopupMessageWithContextModel)`
  ${styles}
`;

export default PopupMessage;
export { PopupMessageRedux, PopupMessageContext };
