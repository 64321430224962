import React, { useContext, useEffect, useRef } from "react";
import { UsersNotificationsContext } from "../../context";
import { Dropdown } from "components/dropdown";
import { Loader } from "components/loader";

export const NotificationFormModel = ({ className }) => {
  const { state, getConstants, setFile, sendPushNotification, changeState } =
    useContext(UsersNotificationsContext);

  const fileInputRef = useRef(null);

  useEffect(() => {
    getConstants();
  }, []);

  useEffect(() => {
    if (state.sendPush) {
      sendPushNotification();
      changeState({ sendPush: false });
    }
  }, [state.sendPush]);

  const onSubmit = (event) => {
    event.preventDefault();
    changeState({ sendPush: true });
  };

  const onTitleChange = (event) => {
    event.preventDefault();
    changeState({ _title: event.target.value });
  };
  const onFileChange = (event) => {
    event.preventDefault();
    setFile(event.target.files[0]);
    changeState({ _file: event.target.value });
  };
  const onFileSelectorClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };
  const onContentChange = (event) => {
    event.preventDefault();
    changeState({ _content: event.target.value });
  };
  const onDaysChange = (event) => {
    event.preventDefault();
    let value = event.target.value.replace(/[^0-9\s]/g, "");
    changeState({ _days_to_expire: value });
  };
  const onLinkChange = (event) => {
    event.preventDefault();
    changeState({ _link: event.target.value });
  };
  const onScreenChange = (event, data) => {
    event.preventDefault();
    changeState({ _screen: data.value });
  };
  const onFileFormatChange = (event, data) => {
    event.preventDefault();
    changeState({ _file_format: data.value });
  };
  const validForm =
    state._file &&
    state._file_format &&
    state._title &&
    state._content &&
    state._screen &&
    state._days_to_expire &&
    (state._screen !== "link:" || (state._screen === "link:" && state._link));

  const loading = state.loading;
  return (
    <form onSubmit={onSubmit} className={className}>
      <label htmlFor="file_format" key="file_format">
        <span>Formato de archivo:</span>
        <Dropdown
          fluid
          selection
          wrapSelection={true}
          options={state.fileFormatOptions}
          value={state._file_format}
          onChange={onFileFormatChange}
          id="file_format"
          placeholder="Formato"
          disabled={loading}
        />
      </label>
      <label
        htmlFor="title"
        key="title"
        className="ui transparent labeled input"
      >
        <span>Título:</span>
        <input
          type="text"
          id="title"
          placeholder="Ejemplo: Hola"
          value={state._title}
          onChange={onTitleChange}
          className="text-input"
          disabled={loading}
        />
      </label>
      <label
        htmlFor="content"
        key="content"
        className="ui transparent labeled input"
      >
        <span>Contenido:</span>
        <input
          type="text"
          id="content"
          placeholder="Ejemplo: ¿Irás al concierto de Adele?"
          value={state._content}
          onChange={onContentChange}
          className="text-input"
          disabled={loading}
        />
      </label>
      <label htmlFor="file" key="file" style={{ position: "relative" }}>
        <span>Archivo de usuarios:</span>
        <div className="file-style">
          <input
            type="file"
            id="file"
            accept=".xlsx"
            value={state._file}
            onChange={onFileChange}
            hidden
            disabled={loading}
            ref={fileInputRef}
          />
          <p className={!state._file ? "no-file" : ""}>
            {state._file ? state._file : "Suba un archivo del directorio"}
          </p>
          <button onClick={onFileSelectorClick} disabled={loading}>
            Subir archivo
          </button>
        </div>
      </label>
      <label
        htmlFor="screen"
        key="screen"
        className="ui transparent labeled input"
      >
        <span>Pantalla:</span>
        <Dropdown
          fluid
          selection
          required
          wrapSelection={true}
          options={state.screenOptions}
          value={state._screen}
          onChange={onScreenChange}
          id="screen"
          placeholder="Ejemplo: Home"
          disabled={loading}
        />
      </label>
      {state._screen === "link:" && (
        <label
          htmlFor="link"
          key="link"
          className="ui transparent labeled input"
        >
          <span>Link a:</span>
          <input
            type="text"
            id="days"
            required
            placeholder="Ejemplo: www.pagodirecto.com"
            value={state._link}
            onChange={onLinkChange}
            className="text-input"
            disabled={loading}
          />
        </label>
      )}
      <label htmlFor="days" key="days" className="ui transparent labeled input">
        <span>Días de vigencia:</span>
        <input
          type="text"
          id="days"
          placeholder="Ejemplo: 4"
          value={state._days_to_expire}
          onChange={onDaysChange}
          className="text-input"
          disabled={loading}
        />
      </label>

      {!loading && (
        <button key="button" disabled={!validForm}>
          Enviar
        </button>
      )}
      {loading && <Loader />}
    </form>
  );
};
