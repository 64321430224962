import { 
  BUILDINGS_ADD_MANY,
  BUILDINGS_SELECT
} from 'redux/actions/buildings'

const initialState = {
  all: [],
  selected: ''
}

export default function(state = initialState, action){
  switch (action.type) {

    case BUILDINGS_ADD_MANY:
      let { buildings } = action.payload
      return {
        ...state,
        all: [ ...buildings ]
      }

    case BUILDINGS_SELECT:
      let { buildingId } = action.payload
      return {
        ...state,
        selected: buildingId
      }

    default:
      return state
  }
}