import React, { useEffect } from "react";
import { connect } from "react-redux";
import { WalletTodayMovementsState } from "./context/state";
import WalletTodayMovementsView from "./components/wallet-today-movements-view";
import { useViewType } from "hooks/use-view-type";

const MPosWalletTodayMovements = () => {
  const [viewType, setViewType] = useViewType("desktop");
  useEffect(() => {
    setViewType();
    window.addEventListener("resize", setViewType);
    return () => {
      window.removeEventListener("resize", setViewType);
    };
  }, []);
  const phoneView = viewType === "phone";
  return (
    <WalletTodayMovementsState>
      <WalletTodayMovementsView phoneView={phoneView} />
    </WalletTodayMovementsState>
  );
};

const mapStateToProps = null;
const mapDispatchToProps = null;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MPosWalletTodayMovements);
//
