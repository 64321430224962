import React from "react";
import styled from "styled-components";
import PopupMessage from "components/popup-message";
import { Loader } from "components/loader";

import { RegisterForm } from "./register-form";
const RegisterUserViewModel = ({
  loading,
  user,
  email,
  docType,
  docId,
  password,
  confirmPassword,
  mobilePaidNumber,
  handleUser,
  handleEmail,
  handleDocType,
  handleDocId,
  handlePassword,
  handleConfirmPassword,
  handleMobilePaidNumber,
  handleSubmit,
  popupMessages,
  popupMessagesType,
  showPopup,
  closePopup,
  className,
}) => {
  return (
    <div className={className}>
      <PopupMessage
        messageType={popupMessagesType}
        showPopup={showPopup}
        messages={popupMessages}
        closePopup={closePopup}
      />
      {loading && <Loader />}
      {!loading && (
        <RegisterForm
          user={user}
          email={email}
          docType={docType}
          docId={docId}
          password={password}
          confirmPassword={confirmPassword}
          mobilePaidNumber={mobilePaidNumber}
          handleUser={handleUser}
          handleEmail={handleEmail}
          handleDocType={handleDocType}
          handleDocId={handleDocId}
          handlePassword={handlePassword}
          handleConfirmPassword={handleConfirmPassword}
          handleMobilePaidNumber={handleMobilePaidNumber}
          handleSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

const RegisterUserView = styled(RegisterUserViewModel)`
  #root && {
    height: 100%;
    min-height: 500px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export { RegisterUserView };
