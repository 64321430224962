import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { grey_4 } from 'assets/strings/colors'
import { formatPrice } from 'services/utils'
import { tickets_validate as viewTexts } from 'assets/strings/texts'

const SumaryBar = ({ currency, billContent, className}) => {
  let total = null
  if (billContent && currency){
    total = currency + ' ' + formatPrice(billContent.totalAmount)
  }
  return(
    <div className={className}>
      <p>{total ? viewTexts.sumaryBar + total : ''}</p>
    </div>
 )
}

const StyledSumaryBar = styled(SumaryBar)`
  #root &{
  display: flex;
  align-items: center;
  height: 5rem;
  border-bottom: solid 1px ${grey_4};
    p{
      font-size: 1.5rem;
      text-align: center;
      margin: 0 auto 0 auto;
    }
  }
}
`;

SumaryBar.porpTypes = {
  item: PropTypes.string, 
  currency: PropTypes.string, 
  subTotal: PropTypes.number
}

export default StyledSumaryBar;