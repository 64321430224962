import { useContext, useEffect } from "react";
import { SuperadminBillsContext } from "../context";
import {
  newBill,
  billPreview,
  listBillingFiscalDirectory,
  deleteBillingFiscalDirectory,
} from "api/superadmin-bills";
import { useFetch } from "hooks/use-fetch";

export const useFetchDirectory = (initialFetch) => {
  const { changeState, state, throwPopupMessage } = useContext(
    SuperadminBillsContext
  );
  const { loading, error, data, setActivateFetch, setArgs } = useFetch(
    listBillingFiscalDirectory
  );

  useEffect(() => {
    setArgs({
      skip: 1,
      limit: 10,
      input: "",
    });
    setActivateFetch(initialFetch);
  }, []);

  useEffect(() => {
    if (state.resetDirectory) {
      changeState({ resetDirectory: false });
      setArgs({
        skip: 1,
        limit: 10,
        input: "",
      });
      setActivateFetch(initialFetch);
    }
  }, [state.resetDirectory]);

  useEffect(() => {
    if (data) {
      const nextPageExist =
        data.billing_fiscal_directory &&
        data.billing_fiscal_directory.length >= 10;
      changeState({
        directory: data.billing_fiscal_directory,
        nextPageExist,
        exchange_usd_to_bs:
          data.exchange_usd_to_bs && data.exchange_usd_to_bs.toString(),
        currentExchange: data.exchange_usd_to_bs,
      });
    }
    if (error) {
      throwPopupMessage("error", error.description);
    }
  }, [data, error]);

  return { loading, setArgs, setActivateFetch };
};
export const usePostBill = () => {
  const { loading, error, data, setActivateFetch, setArgs } = useFetch(newBill);
  const { throwPopupMessage, changeState } = useContext(SuperadminBillsContext);
  useEffect(() => {
    if (error) {
      throwPopupMessage("error", error.description);
    }
    if (data) {
      if (!data.error) {
        throwPopupMessage(
          "success",
          `${data.message} - Link de factura: ${data.bill.bill_url}`
        );
        changeState({
          cleanUp: true,
          showNextPage: false,
          showPreview: false,
          billToPreview: null,
        });
      } else {
        throwPopupMessage(
          "error",
          `No es posible realizar la factura o existe algún error al general`
        );
        changeState({
          cleanUp: true,
          showNextPage: false,
          showPreview: false,
          billToPreview: null,
        });
      }
    }
  }, [error, data]);

  return { loading, setArgs, error, data, setActivateFetch };
};

export const usePreviewBill = () => {
  const { loading, error, data, setActivateFetch, setArgs } =
    useFetch(billPreview);
  const { throwPopupMessage, changeState } = useContext(SuperadminBillsContext);
  useEffect(() => {
    if (error) {
      throwPopupMessage("error", error.description);
    }
    if (data) {
      changeState({ showPreview: true, billToPreview: data.bill });
    }
  }, [error, data]);

  return { loading, setArgs, error, data, setActivateFetch };
};

export const useDeleteDataDirectory = () => {
  const { loading, error, data, setActivateFetch, setArgs } = useFetch(
    deleteBillingFiscalDirectory
  );
  const { throwPopupMessage, changeState } = useContext(SuperadminBillsContext);
  useEffect(() => {
    if (error) {
      throwPopupMessage("error", error.description);
    }
    if (data) {
      console.log({ data });
      changeState({
        showNextPage: false,
        showPreview: false,
        cleanup: true,
        resetDirectory: true,
      });
    }
  }, [error, data]);

  return { loading, setArgs, error, data, setActivateFetch };
};
