import React, { useEffect } from "react";
import moment from "moment-timezone";
import { useStateManager } from "hooks/use-state-manager";
import { useDates } from "hooks/use-inputs";
import { connect } from "react-redux";
import { UsersView } from "./components/users-view";
import { UsersPhoneView } from "./components/users-phone-view";
import { getPagoDirectoUsersDashboard } from "services/api";

import { usePopupMessages } from "hooks/use-popup-messages";
import { errors } from "assets/strings/texts";

const UsersStats = () => {
  let itemsPerDay = [];
  let itemsPerWeek = [];
  let itemsPerLastWeek = [];
  let itemsPerMonth = [];
  let itemsPerLastMonth = [];

  let startOfDay = moment().tz("America/Caracas").startOf("day");
  let startOfDayBuffer = moment().tz("America/Caracas").startOf("day");
  let startOfLastDay = moment()
    .tz("America/Caracas")
    .startOf("day")
    .subtract(1, "days");
  let startOfWeek = moment().tz("America/Caracas").startOf("isoWeek");
  let startOfWeekBuffer = moment().tz("America/Caracas").startOf("isoWeek");
  let startOfLastWeek = moment()
    .tz("America/Caracas")
    .startOf("isoWeek")
    .subtract(1, "weeks");
  let startOfLastWeekBuffer = moment()
    .tz("America/Caracas")
    .startOf("isoWeek")
    .subtract(1, "weeks");
  let startOfMonth = moment().tz("America/Caracas").startOf("month");
  let startOfMonthBuffer = moment().tz("America/Caracas").startOf("month");
  let startOfLastMonth = moment()
    .tz("America/Caracas")
    .startOf("month")
    .subtract(1, "months");
  let startOfLastMonthBuffer = moment()
    .tz("America/Caracas")
    .startOf("month")
    .subtract(1, "months");

  let weekLabel = ["Lun", "Mar", "Mie", "Jue", "Vie", "Sáb", "Dom"];

  let hourLabel = [
    "00:00 hrs",
    "01:00 hrs",
    "02:00 hrs",
    "03:00 hrs",
    "04:00 hrs",
    "05:00 hrs",
    "06:00 hrs",
    "07:00 hrs",
    "08:00 hrs",
    "09:00 hrs",
    "10:00 hrs",
    "11:00 hrs",
    "12:00 hrs",
    "13:00 hrs",
    "14:00 hrs",
    "15:00 hrs",
    "16:00 hrs",
    "17:00 hrs",
    "18:00 hrs",
    "19:00 hrs",
    "20:00 hrs",
    "21:00 hrs",
    "22:00 hrs",
    "23:00 hrs",
  ];

  for (let i = 0; i < 24; i++) {
    itemsPerDay.push({
      hour: startOfDayBuffer.format("H"),
      qty: 0,
      label: hourLabel[i],
    });
    startOfDayBuffer.add(1, "hours");
  }

  for (let i = 0; i < 7; i++) {
    itemsPerWeek.push({
      day: startOfWeekBuffer.format("YYYY-MM-DD"),
      qty: 0,
      label: weekLabel[i],
    });
    itemsPerLastWeek.push({
      day: startOfLastWeekBuffer.format("YYYY-MM-DD"),
      qty: 0,
      label: weekLabel[i],
    });
    startOfWeekBuffer.add(1, "days");
    startOfLastWeekBuffer.add(1, "days");
  }

  for (let i = 0; i < 31; i++) {
    itemsPerMonth.push({
      day: startOfMonthBuffer.format("YYYY-MM-DD"),
      qty: 0,
      label: startOfMonthBuffer.format("YYYY-MM-DD"),
    });
    itemsPerLastMonth.push({
      day: startOfLastMonthBuffer.format("YYYY-MM-DD"),
      qty: 0,
      label: startOfMonthBuffer.format("YYYY-MM-DD"),
    });
    startOfMonthBuffer.add(1, "days");
    startOfLastMonthBuffer.add(1, "days");
  }
  const optionsPeriods = [
    { key: 1, text: "Diarias", value: "daily" },
    { key: 2, text: "Semanales", value: "weekly" },
    { key: 3, text: "Mensuales", value: "monthly" },
    { key: 4, text: "Rango de fechas", value: "range" },
  ];
  const initialState = {
    viewType: "desktop",
    itemSelected: "users",
    showTarget: true,
    render: false,
    transition: false,
    usersData: {
      users: [],
    },
    itemsPerDay,
    itemsPerWeek,
    itemsPerLastWeek,
    itemsPerMonth,
    itemsPerLastMonth,
    startOfDay,
    startOfLastDay,
    startOfWeek,
    startOfLastWeek,
    startOfMonth,
    startOfLastMonth,
    period: "daily",
    filterTime: "Diarias",
    barStats: {
      periodSelected: "current",
      current: {
        title: "",
        data: [],
        keyY: "tickets",
        lineName: "Tickets",
      },
      last: {
        title: "",
        data: [],
        keyY: "tickets",
        lineName: "Tickets",
      },
    },
    newUsersDistribution: {
      title: "",
      data: [],
      keyY: "users",
      lineName: "Usuarios",
    },
    usersDistribution: {
      title: "",
      data: [],
      keyY: "users",
      lineName: "Usuarios",
    },
    newUsers: {
      title: "",
      data: [],
      keyY: "users",
      lineName: "Usuarios",
    },
    totalAmounts: {
      periodSelected: "current",
      current: {
        firstTitle: "",
        firstAmount: 0,
        firstUnit: "",
        secondTitle: "",
        secondAmount: 0,
        secondUnit: "",
      },
      last: {
        firstTitle: "",
        firstAmount: 0,
        firstUnit: "",
        secondTitle: "",
        secondAmount: 0,
        secondUnit: "",
      },
    },
    typeStatusList: {
      periodSelected: "current",
      dataSelected: "status",
      current: {
        status: [],
        types: [],
      },
      last: {
        status: [],
        types: [],
      },
    },
    customStats: {
      title: "",
      data: [],
      lines: 2,
    },
    loading: false,
    relevance: {
      today: {
        qty: 0,
        amount: 0,
      },
      yesterday: {
        qty: 0,
        amount: 0,
        at: "--|--",
      },
      variation: 0,
    },
    activeUsers: 0,
    submit: false,
  };

  const [state, changeState] = useStateManager(initialState);
  const { fromDate, endDate, handleFromDate, handleEndDate } = useDates(
    null,
    null
  );

  const {
    popupMessages,
    popupMessagesType,
    showPopup,
    throwPopupMessage,
    closePopup,
  } = usePopupMessages(null, "", false);

  useEffect(() => {
    if (!state.transition) {
      setTimeout(changeState({ transition: true }), 350);
    }
    return () => {};
  }, [!state.transition]);

  useEffect(() => {
    if (state.render) {
      changeState({ render: false });
    }
    return () => {};
  }, [state.render]);

  useEffect(() => {
    setViewType();
    window.addEventListener("resize", setViewType);
    changeState({ submit: true });
    return () => {
      window.removeEventListener("resize", setViewType);
    };
  }, []);

  useEffect(() => {
    const getDashboard = async () => {
      let { period } = state;
      let start_date = fromDate;
      let end_date = endDate;
      try {
        changeState({ loading: true });
        const response = await getPagoDirectoUsersDashboard({
          period,
          start_date,
          end_date,
        });
        const res = await response.json();
        if (res.error) throw res.error;
        let {
          totalAmounts,
          usersDistribution,
          newUsersDistribution,
          customStats,
          barStats,
          relevance,
          itemsPerDay,
          itemsPerWeek,
          itemsPerLastWeek,
          startOfDay,
          startOfWeek,
          startOfLastWeek,
          usersData,
          newUsers,
          activeUsers,
        } = initialState;

        const resActiveUsers = res.active_users ? res.active_users : null;
        const newUsersDistributionRes = res.newUsersDistribution
          ? res.newUsersDistribution
          : [];
        const activeUsersPeriod =
          resActiveUsers && resActiveUsers.activeUsersHourlyOrDaily
            ? resActiveUsers.activeUsersHourlyOrDaily
            : [];
        const activeUsersHistory =
          resActiveUsers && resActiveUsers.activeUsersPeriodically
            ? resActiveUsers.activeUsersPeriodically
            : [];

        const periodNewUsers = res.period_new_users ? res.period_new_users : [];

        if (state.period === "daily") {
          usersDistribution.title = "Distribución diaria de usuarios activos";
          newUsersDistribution.title = "Distribución diaria de nuevos usuarios";
          if (activeUsersPeriod.length > 0) {
            let res_last_day = [];
            let res_current_day = [];
            activeUsersPeriod.map((period) => {
              if (
                period.dateCurrentAndLast === startOfDay.format("YYYY-MM-DD")
              ) {
                res_current_day = period.data;
              }
              if (
                period.dateCurrentAndLast ===
                startOfLastDay.format("YYYY-MM-DD")
              ) {
                res_last_day = period.data;
              }
              return null;
            });

            let last_day = [...itemsPerDay];
            if (res_last_day.length > 0) {
              itemsPerDay.forEach((sample_hour, index) => {
                res_last_day.forEach((hour) => {
                  if (
                    sample_hour.hour === moment(hour.dateFormatted).format("H")
                  ) {
                    last_day[index] = {
                      ...sample_hour,
                      qty: hour.qty,
                    };
                  }
                });
              });
            }

            let current_day = [...itemsPerDay];
            if (res_current_day.length > 0) {
              itemsPerDay.forEach((sample_hour, index) => {
                res_current_day.forEach((hour) => {
                  if (
                    sample_hour.hour === moment(hour.dateFormatted).format("H")
                  ) {
                    current_day[index] = {
                      ...sample_hour,
                      qty: hour.qty,
                    };
                  }
                });
              });
            }

            let data = [];
            for (let i = 0; i < 24; i++) {
              data.push({
                name: current_day[i].label,
                "Periodo Anterior": last_day[i].qty,
                "Periodo Actual": current_day[i].qty,
              });
            }
            customStats.title = "Usuarios activos por día";
            customStats.data = data;
            customStats.lines = 2;
          }
          if (periodNewUsers.length > 0) {
            let resNewUsersLastDay = [];
            let resNewUsersCurrentDay = [];
            periodNewUsers.map((period) => {
              if (period.date === startOfDay.format("YYYY-MM-DD")) {
                resNewUsersCurrentDay = period.hours;
              }
              if (period.date === startOfLastDay.format("YYYY-MM-DD")) {
                resNewUsersLastDay = period.hours;
              }
              return null;
            });
            let newUsersLastDay = [...itemsPerDay];
            if (resNewUsersLastDay.length > 0) {
              itemsPerDay.forEach((sample_hour, index) => {
                resNewUsersLastDay.forEach((hour) => {
                  if (sample_hour.hour === hour.hourFormatted) {
                    newUsersLastDay[index] = {
                      ...sample_hour,
                      qty: hour.qty,
                    };
                  }
                });
              });
            }

            let newUsersCurrentDay = [...itemsPerDay];
            if (resNewUsersCurrentDay.length > 0) {
              itemsPerDay.forEach((sample_hour, index) => {
                resNewUsersCurrentDay.forEach((hour) => {
                  if (sample_hour.hour === hour.hourFormatted) {
                    newUsersCurrentDay[index] = {
                      ...sample_hour,
                      qty: hour.qty,
                    };
                  }
                });
              });
            }

            let usersDailyCurrentData = [];
            for (let i = 0; i < 24; i++) {
              usersDailyCurrentData.push({
                name: newUsersCurrentDay[i].label,
                users: newUsersCurrentDay[i].qty,
              });
            }
            let usersDailyLastData = [];
            for (let i = 0; i < 24; i++) {
              usersDailyLastData.push({
                name: newUsersCurrentDay[i].label,
                users: newUsersLastDay[i].qty,
              });
            }
            barStats.current.title = "Nuevos usuarios";
            barStats.current.data = usersDailyCurrentData;
            barStats.current.keyY = "users";
            barStats.current.lineName = "Users";
            barStats.last.title = "Nuevos usuarios";
            barStats.last.data = usersDailyLastData;
            barStats.last.keyY = "users";
            barStats.last.lineName = "Users";
          }
        } else if (state.period === "weekly") {
          usersDistribution.title = "Distribución Semanal de usuarios activos";
          newUsersDistribution.title =
            "Distribución Semanal de nuevos usuarios";
          if (activeUsersPeriod.length > 0) {
            let res_last_week = [];
            let res_current_week = [];
            activeUsersPeriod.map((period) => {
              if (
                period.dateCurrentAndLast === startOfWeek.format("YYYY-MM-DD")
              ) {
                res_current_week = period.data;
              }
              if (
                period.dateCurrentAndLast ===
                startOfLastWeek.format("YYYY-MM-DD")
              ) {
                res_last_week = period.data;
              }
              return null;
            });

            let last_week = [...itemsPerLastWeek];
            if (res_last_week.length > 0) {
              itemsPerLastWeek.forEach((sample_days, index) => {
                res_last_week.forEach((day) => {
                  if (
                    sample_days.day ===
                    moment(day.dateFormatted).format("YYYY-MM-DD")
                  ) {
                    last_week[index] = {
                      ...sample_days,
                      qty: day.qty,
                    };
                  }
                });
              });
            }

            let current_week = [...itemsPerWeek];
            if (res_current_week.length > 0) {
              itemsPerWeek.forEach((sample_days, index) => {
                res_current_week.forEach((day) => {
                  if (
                    sample_days.hour ===
                    moment(day.dateFormatted).format("YYYY-MM-DD")
                  ) {
                    current_week[index] = {
                      ...sample_days,
                      qty: day.qty,
                    };
                  }
                });
              });
            }

            let data = [];
            for (let i = 0; i < 7; i++) {
              data.push({
                name: current_week[i].label,
                "Periodo Anterior": last_week[i].qty,
                "Periodo Actual": current_week[i].qty,
              });
            }
            customStats.title = "Usuarios activos por semana";
            customStats.data = data;
            customStats.lines = 2;
          }
          if (periodNewUsers.length > 0) {
            let resNewUsersLastWeek = [];
            let resNewUsersCurrentWeek = [];
            periodNewUsers.map((period) => {
              if (period.date === startOfWeek.format("YYYY-MM-DD")) {
                resNewUsersCurrentWeek = period.days;
              }
              if (period.date === startOfLastWeek.format("YYYY-MM-DD")) {
                resNewUsersLastWeek = period.days;
              }
              return null;
            });
            let newUsersLastWeek = [...itemsPerLastWeek];
            if (resNewUsersLastWeek.length > 0) {
              itemsPerLastWeek.forEach((sample_days, index) => {
                resNewUsersLastWeek.forEach((day) => {
                  if (sample_days.day === day.dateFormatted) {
                    newUsersLastWeek[index] = {
                      ...sample_days,
                      qty: day.qty,
                    };
                  }
                });
              });
            }

            let newUsersCurrentWeek = [...itemsPerWeek];
            if (resNewUsersCurrentWeek.length > 0) {
              itemsPerWeek.forEach((sample_days, index) => {
                resNewUsersCurrentWeek.forEach((day) => {
                  if (sample_days.day === day.dateFormatted) {
                    newUsersCurrentWeek[index] = {
                      ...sample_days,
                      qty: day.qty,
                    };
                  }
                });
              });
            }

            let usersWeeklyCurrentData = [];
            for (let i = 0; i < 7; i++) {
              usersWeeklyCurrentData.push({
                name: newUsersCurrentWeek[i].label,
                users: newUsersCurrentWeek[i].qty,
              });
            }
            let usersWeeklyLastData = [];
            for (let i = 0; i < 7; i++) {
              usersWeeklyLastData.push({
                name: newUsersLastWeek[i].label,
                users: newUsersLastWeek[i].qty,
              });
            }
            barStats.current.title = "Nuevos usuarios";
            barStats.current.data = usersWeeklyCurrentData;
            barStats.current.keyY = "users";
            barStats.current.lineName = "Users";
            barStats.last.title = "Nuevos usuarios";
            barStats.last.data = usersWeeklyLastData;
            barStats.last.keyY = "users";
            barStats.last.lineName = "Users";
          }
        } else if (state.period === "range") {
          usersDistribution.title =
            "Distribución por fechas de usuarios activos";
          newUsersDistribution.title =
            "Distribución por fechas de nuevos usuarios";
          if (activeUsersPeriod.length > 0) {
            let data = activeUsersPeriod.map((item) => {
              return {
                name: moment(item.dateFormatted).format("YYYY-MM-DD"),
                "Periodo Actual": item.qty,
              };
            });

            customStats.title =
              "Distribución de usuarios activos por rango de fechas ";
            customStats.data = data;
            customStats.lines = 1;
          }
          if (periodNewUsers.length > 0) {
            const newUsersData = periodNewUsers.map((item) => {
              return {
                name: moment(item.dateFormatted).format("YYYY-MM-DD"),
                users: item.qty ? item.qty : 0,
              };
            });
            barStats.current.title = "Nuevos usuarios";
            barStats.current.data = newUsersData;
            barStats.current.keyY = "users";
            barStats.current.lineName = "Users";
            barStats.last.title = "Nuevos usuarios";
            barStats.last.data = newUsersData;
            barStats.last.keyY = "users";
            barStats.last.lineName = "Users";
          }
        }

        if (activeUsersHistory.length > 0) {
          let dist = activeUsersHistory.map((item) => {
            return {
              name: moment(item.dateFormatted).format("ll"),
              users: item.qty,
            };
          });

          usersDistribution.data = dist;
          usersDistribution.keyY = "users";
          usersDistribution.lineName = "Usuarios";
        }

        if (newUsersDistributionRes.length > 0) {
          let newDist = newUsersDistributionRes.map((item) => {
            return {
              name: moment(item.dateFormatted).format("ll"),
              users: item.qty,
            };
          });

          newUsersDistribution.data = newDist;
          newUsersDistribution.keyY = "users";
          newUsersDistribution.lineName = "Usuarios";
        }

        if (periodNewUsers.length > 0) {
          /*   let distNewUsers = usersPerDate.map((item) => {
            return {
              name: moment(item.dateFormatted).format("ll"),
              users: item.qty,
            };
          });
          newUsers.data = distNewUsers;
          newUsers.keyY = "users";
          newUsers.lineName = "Usuarios"; */
        }

        const today_users_data = res.today_users_data
          ? res.today_users_data
          : null;
        if (today_users_data) {
          const hasUsersHistory =
            today_users_data.hasOwnProperty("users_history");
          if (hasUsersHistory) {
            usersData.users = today_users_data.users_history;
          }
          relevance = {
            today: today_users_data.today_qty
              ? today_users_data.today_qty
              : { qty: 0 },
            yesterday: today_users_data.yesterday_qty
              ? today_users_data.yesterday_qty
              : { qty: 0 },
            variation: today_users_data.variation
              ? today_users_data.variation
              : 0,
          };
        }

        const totalUsers = res.users_type ? res.users_type : 0;
        totalAmounts.current.firstTitle = "usuarios regulares";
        totalAmounts.current.firstAmount = totalUsers
          ? totalUsers.regular_users
          : 0;
        totalAmounts.current.firstUnit = "";
        totalAmounts.current.secondTitle = "usuarios premium";
        totalAmounts.current.secondAmount = totalUsers
          ? totalUsers.premium_users
          : 0;
        totalAmounts.current.secondUnit = "";
        totalAmounts.last.firstTitle = "usuarios regulares";
        totalAmounts.last.firstAmount = totalUsers
          ? totalUsers.regular_users
          : 0;
        totalAmounts.last.firstUnit = "";
        totalAmounts.last.secondTitle = "usuarios premium";
        totalAmounts.last.secondAmount = totalUsers
          ? totalUsers.premium_users
          : 0;
        totalAmounts.last.secondUnit = "";
        changeState({
          customStats,
          usersDistribution,
          newUsersDistribution,
          totalUsers,
          activeUsers,
          usersData,
          barStats,
          newUsers,
          relevance,
          totalAmounts,
          loading: false,
          render: true,
        });
        if (res.error) throw res.error;
      } catch (err) {
        if (
          err.id === "NO_TOKEN_PROVIDED" ||
          err.id === "AUTHENTICATE_FAILED" ||
          err.id === "DUPLICATE_SESSION"
        ) {
          throwPopupMessage("alert", errors.AUTHENTICATION);
        } else {
          throwPopupMessage("error", err.description);
        }
        changeState({
          ...initialState,
        });
      }
    };
    if (state.submit) {
      getDashboard();
      changeState({ submit: false });
    }
    return () => {};
  }, [state.submit]);

  //handlers

  const handlePeriodChange = (event, data) => {
    event.preventDefault();
    let filterTime = "";
    optionsPeriods.map((period) => {
      if (data.value === period.value) filterTime = period.text;
      return null;
    });
    changeState({ period: data.value, filterTime });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    changeState({ submit: true });
  };

  const listTypesStatusHandlePeriod = (event) => {
    event.preventDefault();
    let periodSelected = event.currentTarget.id;
    let typeStatusList = state.typeStatusList;
    changeState({ typeStatusList: { ...typeStatusList, periodSelected } });
  };

  const barHandlePeriod = (event) => {
    event.preventDefault();
    let periodSelected = event.currentTarget.id;
    let barStats = state.barStats;
    changeState({ barStats: { ...barStats, periodSelected } });
  };

  const amountsHandlePeriod = (event) => {
    event.preventDefault();
    let periodSelected = event.currentTarget.id;
    let totalAmounts = state.totalAmounts;
    changeState({ totalAmounts: { ...totalAmounts, periodSelected } });
  };

  const listDataHandle = (event) => {
    event.preventDefault();
    let dataSelected = event.currentTarget.id;
    let typeStatusList = state.typeStatusList;
    changeState({ typeStatusList: { ...typeStatusList, dataSelected } });
  };

  const setViewType = () => {
    let documentElement = document.documentElement,
      width = window.innerWidth || documentElement.clientWidth;
    if (width < 768) {
      changeState({ viewType: "phone", render: true });
    } else {
      changeState({ viewType: "desktop", render: true });
    }
  };

  const view = {
    phone: (
      <UsersPhoneView
        transition={state.transition}
        item={state.itemSelected}
        fromDate={fromDate}
        endDate={endDate}
        ticketsDistribution={state.ticketsDistribution}
        usersDistribution={state.usersDistribution}
        newUsersDistribution={state.newUsersDistribution}
        barStats={state.barStats}
        customStats={state.customStats}
        tableStats={state.tableStats}
        typeStatusList={state.typeStatusList}
        totalAmounts={state.totalAmounts}
        parkingData={state.parkingData}
        newUsers={state.newUsers}
        usersData={state.usersData}
        relevance={state.relevance}
        buildingName={state.buildingName}
        filterTime={state.filterTime}
        buildingsOptions={state.buildingsOptions}
        loading={state.loading}
        activeUsers={state.activeUsers}
        newUser={state.newUsers}
        usersTypes={state.usersTypes}
        totalUsers={state.totalUsers}
        listDataHandle={listDataHandle}
        listTypesStatusHandlePeriod={listTypesStatusHandlePeriod}
        barHandlePeriod={barHandlePeriod}
        amountsHandlePeriod={amountsHandlePeriod}
        handlePeriodChange={handlePeriodChange}
        handleFromDate={handleFromDate}
        handleEndDate={handleEndDate}
        handleSubmit={handleSubmit}
        popupMessagesType={popupMessagesType}
        showPopup={showPopup}
        popupMessages={popupMessages}
        closePopup={closePopup}
      />
    ),
    desktop: (
      <UsersView
        transition={state.transition}
        item={state.itemSelected}
        fromDate={fromDate}
        endDate={endDate}
        ticketsDistribution={state.ticketsDistribution}
        usersDistribution={state.usersDistribution}
        newUsersDistribution={state.newUsersDistribution}
        barStats={state.barStats}
        customStats={state.customStats}
        tableStats={state.tableStats}
        typeStatusList={state.typeStatusList}
        totalAmounts={state.totalAmounts}
        parkingData={state.parkingData}
        newUsers={state.newUsers}
        usersData={state.usersData}
        relevance={state.relevance}
        buildingName={state.buildingName}
        filterTime={state.filterTime}
        buildingsOptions={state.buildingsOptions}
        loading={state.loading}
        activeUsers={state.activeUsers}
        usersTypes={state.usersTypes}
        totalUsers={state.totalUsers}
        listDataHandle={listDataHandle}
        listTypesStatusHandlePeriod={listTypesStatusHandlePeriod}
        barHandlePeriod={barHandlePeriod}
        amountsHandlePeriod={amountsHandlePeriod}
        handlePeriodChange={handlePeriodChange}
        handleFromDate={handleFromDate}
        handleEndDate={handleEndDate}
        handleSubmit={handleSubmit}
        popupMessagesType={popupMessagesType}
        showPopup={showPopup}
        popupMessages={popupMessages}
        closePopup={closePopup}
      />
    ),
  };
  return view[state.viewType] || <></>;
};

const mapStateToProps = null;

const mapDispatchToProps = null;

export default connect(mapStateToProps, mapDispatchToProps)(UsersStats);
