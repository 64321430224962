import React from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import { PanelTitle } from "components/panel";
import { CSSTransition } from "react-transition-group";
import { Button } from "components/buttons";
import { bluePD } from "assets/strings/colors";

const DigitizeTicketFormModel = ({
  viewType,
  viewState,
  selectedUser,
  newTicketReference,
  newTicketDate,
  newTicketHour,
  newTicketMinute,
  buildingName,
  handleTicketReference,
  handleTicketDate,
  handleTicketHour,
  handleTicketMinute,
  toggleDigitizeConfirmation,
  removeSelectedUser,
  className,
}) => {
  let idNumber = "";
  if (selectedUser) {
    if (
      selectedUser.profile &&
      selectedUser.profile.identification &&
      selectedUser.profile.identification.doc_id &&
      selectedUser.profile.identification.doc_type
    ) {
      idNumber =
        selectedUser.profile.identification.doc_type +
        "-" +
        selectedUser.profile.identification.doc_id;
    }
  }
  return (
    <CSSTransition
      in={
        viewState === "user_selection_done" ||
        viewState === "ticket_digitize_confirmation" ||
        viewState === "ticket_digitize_loading" ||
        viewState === "ticket_digitize_done"
      }
      mountOnEnter
      timeout={300}
      appear={true}
      classNames="form"
    >
      <form className={className} onSubmit={toggleDigitizeConfirmation}>
        <PanelTitle style={{ fontSize: "2rem", color: bluePD }}>
          {buildingName}
        </PanelTitle>
        <PanelTitle>
          Ingrese los datos del ticket físico a continuación
        </PanelTitle>
        <p className="title">usuario</p>
        <p className="username">@{selectedUser.username}</p>
        <p className="text">{selectedUser.email}</p>
        <p className="text">{idNumber}</p>
        <div className="input-section">
          <label htmlFor="ticket-id">Número de ticket</label>
          <input
            id="ticket-id"
            type="text"
            required={true}
            autoComplete="off"
            placeholder="170734-79"
            value={newTicketReference}
            onChange={handleTicketReference}
            disabled={
              viewState === "user_selection_loading" ||
              viewState === "ticket_digitize_confirmation" ||
              viewState === "ticket_digitize_loading" ||
              viewState === "ticket_digitize_done"
            }
          />
        </div>
        <div className="input-section">
          <label htmlFor="ticket-date-picker">Fecha</label>
          <DatePicker
            id="ticket-date-picker"
            className="date-picker"
            autoComplete="off"
            placeholderText="2018-12-31"
            dateFormat="DD / MM / YYYY"
            todayButton={"Hoy"}
            isClearable
            withPortal={viewType === "phone" ? true : false}
            minDate={moment().subtract(30, "day")}
            maxDate={moment()}
            selected={newTicketDate}
            onChange={handleTicketDate}
            disabled={
              viewState === "user_selection_loading" ||
              viewState === "ticket_digitize_confirmation" ||
              viewState === "ticket_digitize_loading" ||
              viewState === "ticket_digitize_done"
            }
          />
        </div>
        <div className="input-section">
          <label htmlFor="ticket-hour">Hora de entrada</label>
          <div className="time-picker">
            <input
              id="ticket-hour"
              type="tel"
              required={true}
              maxLength="3"
              pattern="^(0?[0-9]|1[0-9]|2[0-3])$"
              autoComplete="off"
              placeholder="14"
              value={newTicketHour}
              onChange={handleTicketHour}
              disabled={
                viewState === "user_selection_loading" ||
                viewState === "ticket_digitize_confirmation" ||
                viewState === "ticket_digitize_loading" ||
                viewState === "ticket_digitize_done"
              }
            />
            :
            <input
              id="ticket-minutes"
              type="tel"
              required={true}
              maxLength="3"
              pattern="^(0?[0-9]|[1-5][0-9])$"
              autoComplete="off"
              placeholder="40"
              value={newTicketMinute}
              onChange={handleTicketMinute}
              disabled={
                viewState === "user_selection_loading" ||
                viewState === "ticket_digitize_confirmation" ||
                viewState === "ticket_digitize_loading" ||
                viewState === "ticket_digitize_done"
              }
            />
          </div>
        </div>
        <Button
          color="blue"
          type="submit"
          disabled={
            viewState === "user_selection_loading" ||
            viewState === "ticket_digitize_confirmation" ||
            viewState === "ticket_digitize_loading" ||
            viewState === "ticket_digitize_done"
          }
        >
          Digitalizar Ticket
        </Button>
        {viewType === "phone" && (
          <Button
            color="grey"
            disabled={
              viewState === "user_selection_loading" ||
              viewState === "ticket_digitize_confirmation" ||
              viewState === "ticket_digitize_loading" ||
              viewState === "ticket_digitize_done"
            }
            margin="1rem 0 0 0 "
            onClick={removeSelectedUser}
          >
            Volver
          </Button>
        )}
      </form>
    </CSSTransition>
  );
};

export { DigitizeTicketFormModel };
