import { AccountsInfoModel } from "./model";
import styled from "styled-components";
import { grey_5 } from "assets/strings/colors";

export const AccountsInfo = styled(AccountsInfoModel)`
  #root && {
    width: 100%;
    height: calc(40vh - 2.5rem);
    display: block;
    padding-left: 2.5rem;
    > .header {
      margin: 0;
      margin-top: 0.5rem;
      padding: 0;
      display: flex;
      justify-content: inline-block;
      width: calc(100% - 2.5rem);
      margin-bottom: 0.2rem;
      font-size: 1.5rem;
      > .title {
        margin: 0;
        width: calc(100% - 2.5rem);
        font-weight: bold;
        text-align: left;
      }
      > .date {
        margin: 0;
        align-self: flex-end;
        width: calc(100% - 2.5rem);
        color: ${grey_5};
        font-size: 1rem;
        text-align: right;
      }
    }
    > .table {
      width: calc(100% - 2.5rem);
    }
  }
`;
