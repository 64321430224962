import React, { useReducer } from "react";
import { Icon, Menu, Table as SemanticTable } from "semantic-ui-react";
import { listInParts } from "services/utils";

function reducer(state, action) {
  let maxPage = null,
    minPage = null;
  switch (action.type) {
    case "increment":
      maxPage =
        state.maxPage < state.pageSelected + 1
          ? state.maxPage + 1
          : state.maxPage;
      minPage = maxPage > state.maxPage ? state.minPage + 1 : state.minPage;
      return { pageSelected: state.pageSelected + 1, maxPage, minPage };
    case "decrement":
      minPage =
        state.minPage > state.pageSelected - 1
          ? state.minPage - 1
          : state.minPage;
      maxPage = minPage < state.minPage ? state.maxPage - 1 : state.maxPage;
      return { pageSelected: state.pageSelected - 1, maxPage, minPage };
    case "selected":
      return { ...state, pageSelected: action.payload };
    default:
      throw new Error();
  }
}

const TableModel = ({ headers, content, height, radius, className }) => {
  const initialState = { pageSelected: 1, minPage: 1, maxPage: 3 };
  const [state, dispatch] = useReducer(reducer, initialState);

  let realContent = listInParts(content, 4);

  // header is a string array like ["one","two", "three", ...];
  //Content is a 3D matrix... content = [row = [[cell = {value: "value"}], [cell = {value: "value"}], [cell = {value: "value"}]...], ...row, ...row], contain a json into last child
  return (
    <div className={className}>
      {content.length > 0 && realContent.length > 0 && (
        <SemanticTable
          compact
          verticalAlign="middle"
          style={{
            height: height ? height : "34.5vh",
            borderRadius: radius ? radius : "0.4rem",
          }}
          fixed
        >
          <SemanticTable.Header>
            <SemanticTable.Row>
              {headers.map((header) => {
                return (
                  <SemanticTable.HeaderCell key={header}>
                    {header}
                  </SemanticTable.HeaderCell>
                );
              })}
            </SemanticTable.Row>
          </SemanticTable.Header>
          <SemanticTable.Body>
            {realContent[state.pageSelected - 1].map((row, index) => {
              return (
                <SemanticTable.Row key={index}>
                  {row.map((cell) => {
                    return (
                      <SemanticTable.Cell key={cell.value} collapsing>
                        {cell.value}
                      </SemanticTable.Cell>
                    );
                  })}
                </SemanticTable.Row>
              );
            })}
          </SemanticTable.Body>
          <SemanticTable.Footer>
            <SemanticTable.Row>
              <SemanticTable.HeaderCell colSpan="3">
                <Menu floated="right" pagination>
                  <Menu.Item
                    as="a"
                    icon
                    onClick={() => dispatch({ type: "decrement" })}
                    disabled={state.pageSelected === 1}
                  >
                    <Icon name="chevron left" />
                  </Menu.Item>
                  {realContent.map((part, index) => {
                    if (
                      state.minPage <= index + 1 &&
                      index + 1 <= state.maxPage
                    ) {
                      return (
                        <Menu.Item
                          as="a"
                          key={index}
                          onClick={() =>
                            dispatch({ type: "selected", payload: index + 1 })
                          }
                          disabled={state.pageSelected === index + 1}
                        >
                          {index + 1}
                        </Menu.Item>
                      );
                    } else {
                      return null;
                    }
                  })}
                  <Menu.Item
                    as="a"
                    icon
                    onClick={() => dispatch({ type: "increment" })}
                    disabled={state.pageSelected === realContent.length}
                  >
                    <Icon name="chevron right" />
                  </Menu.Item>
                </Menu>
              </SemanticTable.HeaderCell>
            </SemanticTable.Row>
          </SemanticTable.Footer>
        </SemanticTable>
      )}
    </div>
  );
};

export { TableModel };
