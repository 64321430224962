import React, { useEffect } from "react";
import { connect } from "react-redux";
import { clearReduxStore } from "redux/actions/general";
import { useViewType } from "hooks/use-view-type";
import { useDates } from "hooks/use-inputs";
import { useStateManager } from "hooks/use-state-manager";
import { usePopupMessages } from "hooks/use-popup-messages";
import { OvertimeTicketsTransactionsView } from "./components/overtime-tickets-transactions-view";
import { OvertimeTicketsTransactionsPhoneView } from "./components/overtime-tickets-transactions-phone-view";
import {
  getPendingOvertimeTransactions,
  changePendingOvertimeTransactions,
} from "services/api";
import { errors } from "assets/strings/texts";
import { Button } from "components/buttons";

const MposOvertimeTicketsTransactions = (props) => {
  const initialState = {
    viewType: "",
    transactionSelected: null,
    transactionList: [],
    refresh: false,
    approve: false,
    loading: false,
    showDetail: false,
    showList: true,
    skip: 1,
    limit: 19,
    nextPageExists: false,
  };
  const [viewType, setViewType] = useViewType(null);
  const [state, changeState] = useStateManager(initialState);
  const {
    popupMessages,
    popupMessagesType,
    showPopup,
    throwPopupMessage,
    closePopup,
  } = usePopupMessages(null, "", false);

  const { fromDate, endDate, handleFromDate, handleEndDate } = useDates(
    null,
    null
  );
  useEffect(() => {
    setViewType();
    window.addEventListener("resize", setViewType);
    return () => {
      window.removeEventListener("resize", setViewType);
    };
  }, []);

  useEffect(() => {
    const searchTransactions = async () => {
      try {
        changeState({ loading: true });
        const response = await getPendingOvertimeTransactions({
          fromDate,
          endDate,
          skip: state.skip,
          limit: state.limit,
        });
        const res = await response.json();
        console.log({ res, length: res.transactions.length });
        if (res.error) throw res.error;
        changeState({
          transactionList: res.transactions,
          loading: false,
          nextPageExists: res.transactions.length > state.limit,
        });
      } catch (error) {
        console.log({ error });
        changeState({ ...initialState });
        if (
          error.id === "NO_TOKEN_PROVIDED" ||
          error.id === "AUTHENTICATE_FAILED" ||
          error.id === "DUPLICATE_SESSION"
        ) {
          throwPopupMessage("alert", errors.AUTHENTICATION);
        } else {
          throwPopupMessage("error", error.description);
        }
      }
    };
    if (state.refresh) {
      searchTransactions();
      changeState({ refresh: false });
    }
  }, [state.refresh]);

  useEffect(() => {
    const changeTransactions = async () => {
      try {
        changeState({ loading: true });
        throwPopupMessage("alert", <div className="ui active loader"></div>);
        const response = await changePendingOvertimeTransactions(
          state.transactionSelected._id
        );
        const res = await response.json();
        if (res.error) throw res.error;
        throwPopupMessage("success", "¡Transacción aprobada con éxito!");
        changeState({ ...initialState, refresh: true });
      } catch (error) {
        changeState({ ...initialState });
        if (
          error.id === "NO_TOKEN_PROVIDED" ||
          error.id === "AUTHENTICATE_FAILED" ||
          error.id === "DUPLICATE_SESSION"
        ) {
          throwPopupMessage("alert", errors.AUTHENTICATION);
        } else {
          throwPopupMessage("error", error.description);
        }
      }
    };
    if (state.approve) {
      changeTransactions();
      changeState({ approve: false });
    }
  }, [state.approve]);

  const handleTransactionSelection = (event) => {
    changeState({ showList: false });
    const index = event.currentTarget.id;
    let transactionSelected = state.transactionList[index];
    changeState({ showDetail: false });
    setTimeout(() => {
      changeState({ transactionSelected });
    }, 350);
    changeState({ showDetail: true });
  };

  const handleRefresh = (event) => {
    event.preventDefault();
    changeState({ refresh: true });
  };

  const handleApprove = (event) => {
    event.preventDefault();
    const approve = (
      <div>
        <p>¿Deseas aprobar esta transacción?</p>
        <Button
          color="green"
          onClick={(event) => {
            changeState({ approve: true });
          }}
          disabled={state.loading}
        >
          Si
        </Button>
        <Button
          color="grey"
          onClick={(event) => {
            closePopup();
          }}
          disabled={state.loading}
        >
          no
        </Button>
      </div>
    );
    throwPopupMessage("alert", approve);
  };

  const handleBack = (event) => {
    event.preventDefault();
    changeState({ showList: true });
  };

  const handlePageUp = (event) => {
    event.preventDefault();
    changeState({
      skip: state.skip + 1,
      refresh: true,
    });
  };
  const handlePageDown = (event) => {
    event.preventDefault();
    changeState({
      skip: state.skip - 1,
      refresh: true,
    });
  };
  const views = {
    desktop: (
      <OvertimeTicketsTransactionsView
        viewType={viewType}
        fromDate={fromDate}
        endDate={endDate}
        handleFromDate={handleFromDate}
        handleEndDate={handleEndDate}
        transactionList={state.transactionList}
        transactionSelected={state.transactionSelected}
        handleTransactionSelection={handleTransactionSelection}
        handleRefresh={handleRefresh}
        handleApprove={handleApprove}
        loading={state.loading}
        showDetail={state.showDetail}
        throwPopupMessage={throwPopupMessage}
        popupMessages={popupMessages}
        popupMessagesType={popupMessagesType}
        showPopup={showPopup}
        closePopup={closePopup}
        nextPageExists={state.nextPageExists}
        page={state.skip}
        handlePageUp={handlePageUp}
        handlePageDown={handlePageDown}
      />
    ),
    phone: (
      <OvertimeTicketsTransactionsPhoneView
        viewType={viewType}
        fromDate={fromDate}
        endDate={endDate}
        handleFromDate={handleFromDate}
        handleEndDate={handleEndDate}
        transactionList={state.transactionList}
        transactionSelected={state.transactionSelected}
        handleTransactionSelection={handleTransactionSelection}
        handleRefresh={handleRefresh}
        loading={state.loading}
        showDetail={state.showDetail}
        handleApprove={handleApprove}
        handleBack={handleBack}
        throwPopupMessage={throwPopupMessage}
        popupMessages={popupMessages}
        popupMessagesType={popupMessagesType}
        showPopup={showPopup}
        closePopup={closePopup}
        showList={state.showList}
        nextPageExists={state.nextPageExists}
        page={state.skip}
        handlePageUp={handlePageUp}
        handlePageDown={handlePageDown}
      />
    ),
  };
  return views[viewType] || <></>;
};

const mapStateToProps = null;

const mapDispatchToProps = {
  clearReduxStore,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MposOvertimeTicketsTransactions);
