import { DigitizeBodyModel } from "./model";
import styled from "styled-components";

const DigitizeBody = styled(DigitizeBodyModel)`
  #root && {
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export { DigitizeBody };
