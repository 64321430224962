import React from "react";
import { Dropdown, Icon } from "semantic-ui-react";
import { Button } from "components/buttons";
import styled from "styled-components";

const OptionsModel = ({
  options,
  onChange,
  disabled,
  phone,
  back,
  className,
}) => {
  const onClick = (event, data) => {
    const value = data.value;
    onChange(event, value);
  };
  return (
    <div className={className}>
      {phone && (
        <Button
          margin="0"
          padding="0"
          width="5%"
          disabled={disabled}
          color="transparent"
          icon="true"
          onClick={back}
        >
          <Icon name="left arrow" />
        </Button>
      )}
      <Dropdown
        text=""
        icon="ellipsis horizontal"
        floating
        labeled
        disabled={disabled}
        className="icon"
      >
        <Dropdown.Menu direction="left">
          {options.map((option) => (
            <Dropdown.Item
              text={option.text}
              value={option.value}
              key={option.key}
              onClick={onClick}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

const Options = styled(OptionsModel)`
  #root & {
    display: flex;
    padding: ${(props) => (props.phone ? "0" : "1rem 0 0 0")};
    justify-content: ${(props) => (props.phone ? "space-between" : "flex-end")};
    align-items: center;
    width: 100%;
    margin: 0;
    color: #808080;
  }
`;

export default Options;
