import styled, { keyframes } from "styled-components";
import { posDirectoBlue } from "assets/strings/colors";

const backgroundAnimation = keyframes`
  0% {
    background-position: 0% 0%;
  }
  25% {
      background-position: 50% 50%;
  }
  50% {
      background-position: 100% 100%;
  }
  50.01%{
    background-position: 0% 0%;
  }
  75% {
      background-position: 50% 50%;
  }
  100% {
      background-position: 100% 100%;
  }
`;

const bannerShrinkComputer = keyframes`
  from{
    height: 100vh
  }
  to{
    height: 64vh
  }
`;

const bannerShrinkTablet = keyframes`
  from{
    height: 100vh
  }
  to{
    height: 50vh
  }
`;

const LogoBanner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 100vh;
  width: 100%;
  font-size: 3rem;

  animation: ${bannerShrinkComputer} 600ms ease-in-out 4000ms forwards,
    ${backgroundAnimation} 35s linear infinite;

  @media (max-width: 768px) {
    animation: ${bannerShrinkTablet} 600ms ease-in-out 4000ms forwards,
      ${backgroundAnimation} 35s linear infinite;
  }

  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ee7752+0,e73c7d+19,23a6d5+38,23d5ab+53,23a6d5+69,e73c7d+85,ee7752+100 */
  //background: #ee7752; /* Old browsers */
  background: ${posDirectoBlue};
  /*background: -moz-linear-gradient(
    -45deg,
    #e73c7d 0%,
    #ee7752 14.28%,
    #e73c7d 28.56%,
    #23a6d5 42.84%,
    #23d5ab 57.12%,
    #23a6d5 71.4%,
    #e73c7d 87%,
    #ee7752 100%
  );*/ /* FF3.6-15 */
  /*background: -webkit-linear-gradient(
    -45deg,
    #e73c7d 0%,
    #ee7752 14.28%,
    #e73c7d 28.56%,
    #23a6d5 42.84%,
    #23d5ab 57.12%,
    #23a6d5 71.4%,
    #e73c7d 87%,
    #ee7752 100%
  ); */ /* Chrome10-25,Safari5.1-6 */
  /*background: linear-gradient(
    135deg,
    #e73c7d 0%,
    #ee7752 14.28%,
    #e73c7d 28.56%,
    #23a6d5 42.84%,
    #23d5ab 57.12%,
    #23a6d5 71.4%,
    #e73c7d 87%,
    #ee7752 100%
  ); */ /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e73c7d', endColorstr='#23a6d5',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
  background-size: 800% 800%;
`;

export default LogoBanner;
