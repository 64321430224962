import React, { useContext } from "react";
import { SuperadminBillsContext } from "../../context";
import { Button } from "components/buttons";
import { Panel } from "components/panel";
import { LinesForm } from "../lines-form";
import { Icon } from "semantic-ui-react";
import { grey_4 } from "assets/strings/colors";
import { billingComponentServices } from "../../services";
import { Loader } from "components/loader";

const iconStyle = {
  paddingLeft: "2rem",
  paddingTop: "1rem",
  display: "flex",
  alignItems: "left",
  float: "left",
  color: grey_4,
  cursor: "pointer",
  zIndex: 1,
};
const OperationsFormModel = ({ className }) => {
  const { state, changeState } = useContext(SuperadminBillsContext);
  const { loading, setArgs, setActivateFetch } =
    billingComponentServices.usePreviewBill();

  const onSubmit = (event) => {
    event.preventDefault();
    const validLines = state.lines.filter((line) => line.isActive);
    const lines = validLines.map((line) => {
      const { description, qty, amount_bs, amount_usd, is_exempt, code } = line;
      return {
        description,
        qty: parseInt(qty),
        amount_bs: parseFloat(amount_bs.toString().replace(",", ".")),
        amount_usd: parseFloat(amount_usd.toString().replace(",", ".")),
        is_exempt,
        code,
      };
    });

    const fiscal = {
      ...state.fiscalForm,
      lines,
      exchange_usd: parseFloat(state.exchange_usd_to_bs),
    };
    setArgs(fiscal);
    setActivateFetch(true);
  };

  if (loading) return <Loader />;

  return (
    <Panel>
      <Icon
        style={iconStyle}
        name="arrow left"
        onClick={(event) => {
          event.preventDefault();
          changeState({ showNextPage: false });
        }}
      />
      <LinesForm name="operation" />
      <LinesForm name="pd-fee" />
      <Button onClick={onSubmit} color="blue">
        Previsualización
      </Button>
    </Panel>
  );
};

export default OperationsFormModel;
