import { useEffect, useContext } from "react";
import { TransfersContext } from "../context";
import { list } from "./api";
import moment from "moment";

export const useFetchTransfers = () => {
  const { state, changeState, throwPopupMessage } =
    useContext(TransfersContext);

  const listTransfers = async () => {
    changeState({ loading: true });
    try {
      const status =
        state.formData.status.length < 1
          ? ["pending", "approved", "rejected"]
          : [...state.formData.status];

      const date_from = state.formData.date_from
        ? moment(state.formData.date_from).format()
        : null;
      const date_to = state.formData.date_to
        ? moment(state.formData.date_to).format()
        : null;
      const params = {
        ...state.formData,
        status,
        date_from,
        date_to,
      };

      const response = await list(params);
      const res = await response.json();
      if (res.error) throw res.error;
      changeState({
        transfers: res.data.movements,
        nextPageExists: res.data.nextPageExists,
      });
    } catch (error) {
      throwPopupMessage("error", error.description);
    }
    changeState({ loading: false, submitForm: false });
  };

  useEffect(() => {
    if (state.submitForm) {
      listTransfers();
    }
  }, [state.submitForm]);

  return null;
};
