import React from 'react'
import { building_settings as viewTexts } from 'assets/strings/texts'

const SettingsHeaderModel = ({
  buildingName,
  className
}) => {
  return(
    <div className={className}>
      <p className='title'>
        {viewTexts.header}
      </p>
      <p className='building-name'>
        {buildingName}
      </p>
    </div>
  )
}

export { SettingsHeaderModel }