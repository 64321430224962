import { MarkAsPaidFormModel } from "./model";
import styled from "styled-components";
import { grey_4, bluePD, blue_6 } from "assets/strings/colors";

export const MarkAsPaidForm = styled(MarkAsPaidFormModel)`
  #root && {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    > label {
      display: flex;
      flex-direction: column;
      justify-content: center;
      > span {
        margin: 0;
        margin-top: 1rem;
        width: 100%;
        text-align: left;
      }
      > input {
        width: 100%;
        height: 2rem;
        margin-top: 1rem;
        text-align: center;
        outline: none;
        box-shadow: none;
        border-radius: 0.4rem;
        border: 1px solid transparent;
        background-color: transparent;
        -moz-appearance: textfield;
        ::-webkit-outer-spin-button,
        ::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        :focus {
          border-bottom: 1px solid ${grey_4};
        }
        ::-webkit-input-placeholder {
          /* Chrome/Opera/Safari/Edge */
          color: ${grey_4};
        }

        ::-ms-input-placeholder {
          /* Microsoft Edge */
          color: ${grey_4};
        }

        :-ms-input-placeholder {
          /* IE 10+ */
          color: ${grey_4};
        }
      }
    }

    > button {
      width: 50%;
      background: ${bluePD};
      color: white;
      font-weight: bold;
      border: none;
      border-radius: 0.5rem;
      height: 3rem;
      :hover {
        cursor: pointer;
        background: ${blue_6};
      }
    }
  }
`;
