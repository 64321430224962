import styled from "styled-components";
import { TransferResultModel } from "./model";

export const TransferResult = styled(TransferResultModel)`
  #root & {
    width: 100%;
    display: flex;
    flex-direction: column;
    > .field {
      display: flex;
      justify-content: space-between;
      width: 100%;

      > .title {
        text-decoration: underline;
        font-weight: bold;
      }
    }
    > .link {
      display: flex;
      justify-content: center;

      align-self: center;
      width: 100%;
    }
  }
`;
