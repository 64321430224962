import WithdrawDetailModel, { BillRefContentModel } from "./model";
import styled from "styled-components";
import { bluePD } from "assets/strings/colors";

const WithdrawDetail = styled(WithdrawDetailModel)`
  #root & {
    > .show-more {
      color: ${bluePD};
      cursor: pointer;
      margin-top: 1rem;
      width: 100%;
      text-align: center;
    }

    > .approve-buttons {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      > .link {
        width: 40%;
      }
    }
  }
`;

export const BillRefContent = styled(BillRefContentModel)`
  #root & {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    > p {
      font-weight: bold;
      font-size: 1.2rem;
      color: ${bluePD};
    }
    > dt {
      font-weight: bold;
      margin-top: 1rem;
    }
    > dd {
      margin-left: 1rem;
      text-align: left;
    }
  }
`;

export default WithdrawDetail;
