export const TRANSACTIONS_TAGS_ADD_MANY = "TRANSACTIONS_TAGS_ADD_MANY";

export const addTransactionsTags = (transactionsTags) => {
  return {
    type: TRANSACTIONS_TAGS_ADD_MANY,
    payload: {
      transactionsTags,
    },
  };
};
