import { TransacionsListModel, TransactionListItemModel } from "./model";
import styled from "styled-components";
import { grey_4, bluePD } from "assets/strings/colors";

const TransactionsList = styled(TransacionsListModel)`
  #root & {
    background: #f0f0ff;
    /*viewport have 100vh, navbar have 4rem and form 10vh*/
  }
`;
const TransactionListItem = styled(TransactionListItemModel)`
  #root & {
    margin: 0;
    height: 8vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid ${grey_4};
    :hover {
      cursor: pointer;
      opacity: 0.7;
    }

    > .data {
      padding: 0 1rem;
      > .username {
        color: ${bluePD};
      }
      > .amount {
        margin-top: -1rem;
      }
    }
  }
`;
export { TransactionsList, TransactionListItem };
