import {
  SearchCommercesModel,
  CommerceListItemModel,
  CommerceListHeaderModel,
} from "./model";
import styled from "styled-components";
import { grey_4, grey_6, bluePD } from "assets/strings/colors";

const SearchCommerces = styled(SearchCommercesModel)`
  #root && {
    margin: 0;
    height: calc(100vh - 4rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    > .form {
      margin: 0;
      height: 30vh;
      width: 100%;
      display: flex;
      flex-direction: column;
      > .selectors {
        margin-top: 1rem;
        width: 100%;
        height: calc(9vh - 1rem);
        display: flex;
        align-items: center;
        justify-content: space-between;
        > p {
          width: 20%;
          text-transform: uppercase;
          font-weight: bold;
          font-size: clamp(0.5vw, 0.8rem, 1vw);
          text-align: center;
        }
        > button {
          margin-top: -1rem;
          margin-right: 0.5rem;
          width: 38%;
        }
      }
      > .searchType {
        margin: 0;
        height: 12vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        > .date {
          margin: 0;
          margin-top: 0.7rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          > p {
            margin: 0;
            font-weight: bold;
          }
          > div {
            width: 46%;
            > .react-datepicker__input-container {
              width: 100%;
              > input {
                width: calc(100% - 16px);
                border: none;
                border-radius: 1rem;
                color: ${grey_6};
                font-size: 1.2rem;
                margin: 0.5rem 5px 0.5rem 5px;
                text-align: center;
                outline: none;
                box-shadow: none;
                transition: box-shadow 250ms linear;
                :focus {
                  box-shadow: 0 1px darkgrey;
                }
              }
              > .react-datepicker__close-icon::after {
                right: 0;
              }
            }
            > .react-datepicker-wrapper {
              width: 100%;
              > .react-datepicker__input-container {
                width: 100%;
                > input {
                  width: calc(100% - 16px);
                  border: none;
                  border-radius: 1rem;
                  color: ${grey_6};
                  font-size: 1.2rem;
                  margin: 0.5rem 5px 0.5rem 5px;
                  text-align: center;
                  outline: none;
                  box-shadow: none;
                  transition: box-shadow 250ms linear;
                  :focus {
                    box-shadow: 0 1px darkgrey;
                  }
                }
                > .react-datepicker__close-icon::after {
                  right: 15px;
                }
              }
            }
          }
          > div:last-child {
            > .react-datepicker-popper {
              > .react-datepicker {
                > .react-datepicker__triangle {
                  right: 50px;
                  left: auto;
                }
              }
            }
          }
        }

        > .ui.transparent.labeled.input {
          width: 100%;
          margin-top: 1rem;
          height: 2.5rem;
          font-size: 1.5rem;
          overflow: hidden;
          transition: 200ms;
          > .search.icon.label {
            height: 2rem;
            padding-top: 0.2rem;
            padding-bottom: 0;
            color: ${grey_4};
          }
          > input {
            margin-left: 0.5rem;
            height: 2rem;
            width: 65%;
          }
          > input::placeholder {
            overflow-wrap: normal;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        > .ui.transparent.labeled.input.hidden {
          display: none;
        }
      }
      > .search {
        margin: 0;
        height: 9vh;
        display: flex;
        justify-content: center;
        align-items: center;
        > button {
          margin-top: 0;
        }
      }
    }
    > .list {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    @media (max-width: 724px) {
      > .form {
        > .selectors {
          > p {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
`;

const CommerceListHeader = styled(CommerceListHeaderModel)`
  #root && {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4vh;
    width: 100%;
    background: ${bluePD};
    > .back {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 95%;
      > p {
        margin: 0;
        color: white;
        font-weight: bold;
        text-align: center;
      }
      > .thirty {
        width: calc(30% - 1rem);
      }
      > .twenty {
        width: calc(20% - 1rem);
      }
      > .fifteen {
        width: calc(15% - 1rem);
      }
    }
  }
`;
const CommerceListItem = styled(CommerceListItemModel)`
  #root && {
    margin: 0;
    height: 10vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid ${grey_4};
    cursor: pointer;
    > .back {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 95%;
      > p {
        margin: 0;
        text-align: center;
      }
      > .thirty {
        width: calc(30% - 1rem);
      }
      > .twenty {
        width: calc(20% - 1rem);
      }
      > .fifteen {
        width: calc(15% - 1rem);
      }
    }
  }
`;

export { SearchCommerces, CommerceListItem, CommerceListHeader };
