export const BUILDINGS_ADD_MANY = "BUILDINGS_ADD_MANY";
export const BUILDINGS_SELECT = "BUILDINGS_SELECT";

export const addBuildings = (buildings) => ({
  type: BUILDINGS_ADD_MANY,
  payload: {
    buildings,
  },
});

export const selectBuilding = (buildingId) => ({
  type: BUILDINGS_SELECT,
  payload: {
    buildingId,
  },
});
