import SearchTicketsFormModel from "./model";
import styled from "styled-components";
import { grey_4, grey_6 } from "assets/strings/colors";

const SearchTicketsForm = styled(SearchTicketsFormModel)`
  #root & {
    border-bottom: solid 1px ${grey_4};
    padding: 1rem;
    .selectors {
      min-height: 4rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      > p {
        font-weight: bold;
        text-align: center;
        font-size: 0.8rem;
        margin: 0;
        text-transform: uppercase;
        width: 30%;
      }
      > button {
        margin: 0 0.5rem;
        height: 3.2rem;
      }
    }
    > .cuantity {
      margin: 0.5rem 8rem 0 8rem;
      border-radius: 0.25rem;
      text-align: center;
      font-size 1rem;
    }
    > form {
      > .ui.transparent.labeled.input {
        width: 100%;
        margin-top: 1rem;
        height: 2.5rem;
        font-size: 1.5rem;
        overflow: hidden;
        transition: 200ms;
        > .search.icon.label {
          height: 2rem;
          padding-top: 0.2rem;
          padding-bottom: 0;
          color: ${grey_4};
        }
        > input {
          margin-left: 0.5rem;
          height: 2rem;
          width: 65%;
        }
        > input::placeholder {
          overflow-wrap: normal;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      > .ui.transparent.labeled.input.hidden {
        display: none;
      }
      > .date-pickers {
        margin: 1rem 0 0 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        > p {
          margin: 0;
          font-weight: bold;
        }
        > div {
          width: 46%;
          > .react-datepicker__input-container {
            width: 100%;
            > input {
              width: calc(100% - 16px);
              border: none;
              border-radius: 1rem;
              color: ${grey_6};
              font-size: 1.2rem;
              margin: 0.5rem 5px 0.5rem 5px;
              text-align: center;
              outline: none;
              box-shadow: none;
              transition: box-shadow 250ms linear;
              :focus {
                box-shadow: 0 1px darkgrey;
              }
            }
            > .react-datepicker__close-icon::after {
              right: 0;
            }
          }
          > .react-datepicker-wrapper {
            width: 100%;
            > .react-datepicker__input-container {
              width: 100%;
              > input {
                width: calc(100% - 16px);
                border: none;
                border-radius: 1rem;
                color: ${grey_6};
                font-size: 1.2rem;
                margin: 0.5rem 5px 0.5rem 5px;
                text-align: center;
                outline: none;
                box-shadow: none;
                transition: box-shadow 250ms linear;
                :focus {
                  box-shadow: 0 1px darkgrey;
                }
              }
              > .react-datepicker__close-icon::after {
                right: 15px;
              }
            }
          }
        }
      }
      > .date-pickers.hidden {
        display: none;
      }
      > .submit-button {
        margin: 0;
        height: 3rem;
      }
    }
    @media (max-width: 768px) {
      > form > .selectors {
        flex-wrap: wrap;
        > button {
          margin: 0 0.5rem 0.5rem 0.5rem;
          max-width: calc(50% - 1rem);
        }
      }
    }
  }
`;

export default SearchTicketsForm;
