import React from "react";
//import moment from "moment";
import moment from "moment-timezone";
import PropTypes from "prop-types";
import { general as texts } from "assets/strings/texts";
import {
  TicketListItem,
  TicketListLessInfoItem,
  TicketListHeader,
  TicketListLessInfoHeader,
} from "../ticket-list-item";
import { arrayToObject } from "services/utils";
import iconoTicket from "assets/img/icono-ticket.png";
import imageCrown from "assets/img/king.png";
import monthlyPremiumImg from "assets/img/monthly_premium.png";

const TicketListItemModel = ({
  ticketNumber,
  username,
  premium,
  building,
  allInfo,
  status,
  suffFunds,
  monthlyPremium,
  newUser,
  type,
  time,
  index,
  handleTicketSelection,
  className,
}) => {
  let statusToShow = "";

  switch (status) {
    case "ready":
      statusToShow = " Por Pagar";
      break;
    case "paid":
      statusToShow = " Pagado";
      break;
    case "closed":
      statusToShow = " Cerrado";
      break;
    case "discarded":
      statusToShow = " Descartado";
      break;
    case "incomplete":
      statusToShow = " Incompleto";
      break;
    case "closed_superadmin":
      statusToShow = " Cerrado (Habilitado por ATC)";
      break;
    case "closed_premium_no_transaction":
      statusToShow = " Cerrado (Puestos Fijos)";
      break;
    case "closed_promotional_no_transaction":
      statusToShow = " Cerrado (Promoción)";
      break;
    default:
      break;
  }
  if (type === "reopened") statusToShow = " Reopened";
  const isMonthlyPremium = monthlyPremium === "monthly_premium";
  return (
    <dd id={index} className={className} onClick={handleTicketSelection}>
      <img
        src={
          premium
            ? imageCrown
            : isMonthlyPremium
            ? monthlyPremiumImg
            : iconoTicket
        }
        className={isMonthlyPremium ? "location-icon" : "ticket-icon"}
        alt=""
      />
      <div className="data">
        <p className={"ticket-number"}>{ticketNumber}</p>
        <p className="ticket-username">
          {" "}
          {newUser && <span className="new-user" />} @{username}
        </p>
      </div>
      {allInfo && (
        <div className="status">
          <p>{statusToShow}</p>
          {/* {!suffFunds && <p className="funds">Sin Fondos</p>} */}
        </div>
      )}
      {allInfo && <p className="building">{building}</p>}
      <p className={allInfo ? "time" : "time-less-info"}>
        {moment(time).tz("America/Caracas").format("LTS")}
      </p>
    </dd>
  );
};
const TicketListLessInfoItemModel = ({
  ticketNumber,
  username,
  premium,
  category,
  status,
  time,
  index,
  handleTicketSelection,
  className,
}) => {
  const statusDefault = " Utilizado";
  const validStatus = {
    ready: " Por Pagar",
    paid: " Pagado",
    closed: " Cerrado",
    closed_premium_no_transaction: " Cerrado (Puestos Fijos)",
    closed_superadmin: " Cerrado (Habilitado por ATC)",
    discarded: " Descartado",
  };
  return (
    <dd id={index} className={className} onClick={handleTicketSelection}>
      <img
        src={premium ? imageCrown : iconoTicket}
        className="ticket-icon"
        alt=""
      />
      <div className="data">
        <p className={"ticket-number"}>{ticketNumber}</p>
        <p className="ticket-username">@{username}</p>
      </div>
      {category === "estacionamiento" && (
        <div className="status">
          <p>{validStatus[status] || statusDefault}</p>
          {/* {!suffFunds && <p className="funds">Sin Fondos</p>} */}
        </div>
      )}
      <p className="time">{moment(time).format("LTS")}</p>
    </dd>
  );
};

const TicketListHeaderModel = ({ className }) => {
  return (
    <dd className={className}>
      <p className="col twenty-five">#Ticket</p>
      <p className="col twenty-five">Estado</p>
      <p className="col twenty-five">Edificio</p>
      <p className="col twenty-five">Hora</p>
    </dd>
  );
};
const TicketListLessInfoHeaderModel = ({ className }) => {
  return (
    <dd className={className}>
      <p className="col twenty-five">#Ticket</p>
      <p className="col twenty-five">Estado</p>
      <p className="col fifty">Hora</p>
    </dd>
  );
};
function formatTicketsList(tickets, viewState, handleTicketSelection, tax) {
  // Declaramos el array que devolverá la función
  let ticketsList = [];
  if (viewState === "ticket_search_loading") {
    return <div className="ui active loader"></div>;
  }
  if (viewState === "ticket_search") {
    return <div className="not-found">No se han encontrado tickets</div>;
  }
  if (
    viewState === "ticket_search_done" ||
    viewState === "ticket_selection" ||
    viewState === "ticket_selection_loading" ||
    viewState === "ticket_selection_done" ||
    viewState === "ticket_validation" ||
    viewState === "ticket_validation_loading" ||
    viewState === "option_confirmation" ||
    viewState === "option_loading" ||
    viewState === "option_done"
  ) {
    tickets.forEach((ticket, index) => {
      const isPremium = ticket && ticket.premium_type;
      const userHasAmountsArray = ticket.customer && ticket.customer.amounts;
      const userAmountsObject = userHasAmountsArray
        ? arrayToObject(ticket.customer.amounts, "currency")
        : {};
      const userFundsBS =
        userAmountsObject &&
        userAmountsObject.hasOwnProperty("BS") &&
        userAmountsObject.BS.hasOwnProperty("funds")
          ? userAmountsObject.BS.funds
          : 0;
      const hasTax = tax && tax.hasOwnProperty("pd_fee");
      const isReady = ticket.status === "ready";
      let ticketValue;
      let ticketToUser;
      let suffFunds = true;
      if (hasTax) {
        const hasRate =
          ticket &&
          ticket.building &&
          ticket.building.rate &&
          ticket.building.rate.amount;
        const rate = hasRate ? ticket.building.rate.amount : 0;
        ticketValue = tax.pd_fee + rate;
        ticketToUser = ticketValue * tax.iva;
      }

      if (ticket.customer && userHasAmountsArray && isReady) {
        suffFunds = ticketToUser < userFundsBS;
      }

      let ticketDate = moment(ticket.enter_at)
        .tz("America/Caracas")
        .format("DD / MM / YYYY");
      let nextTicketDate;
      if (index !== 0)
        nextTicketDate = moment(tickets[index - 1].enter_at)
          .tz("America/Caracas")
          .format("DD / MM / YYYY");
      if (index === 0 || ticketDate !== nextTicketDate) {
        let separatorDate = moment(ticket.enter_at).tz("America/Caracas"); //Test this format
        ticketsList.push(
          <dt key={ticket.enter_at} className="date-separator">
            {texts.entriesOf}
            {formatDate(separatorDate)}
          </dt>
        );
        ticketsList.push(<TicketListHeader key={index} />);
      }
      const hasBuilding = ticket && ticket.building;
      const buildingName =
        hasBuilding && ticket.building.name
          ? ticket.building.name
          : hasBuilding && ticket.building.commercial_name
          ? ticket.building.commercial_name
          : hasBuilding && ticket.building.parking_name
          ? ticket.building.parking_name
          : hasBuilding &&
            ticket.building.code &&
            ticket.building.code === "0004"
          ? "C.C. San Ignacio"
          : "";

      ticketsList.push(
        <TicketListItem
          ticketNumber={ticket.ticket_number}
          username={ticket.username}
          suffFunds={suffFunds}
          time={ticket.enter_at}
          index={index}
          status={ticket.status}
          type={ticket.type}
          monthlyPremium={ticket.ticket_type}
          building={buildingName}
          allInfo={true}
          newUser={ticket.new_user}
          premium={isPremium}
          handleTicketSelection={handleTicketSelection}
          key={ticket._id}
        />
      );
    });

    return ticketsList;
  }
}
function formatTicketsListLessInfo(
  tickets,
  viewState,
  handleTicketSelection,
  args
) {
  // Declaramos el array que devolverá la función
  let ticketsList = [];
  if (viewState === "ticket_search_loading") {
    return <div className="ui active loader"></div>;
  }
  if (viewState === "ticket_search") {
    return <div className="not-found">No se han encontrado tickets</div>;
  }
  if (
    viewState === "ticket_search_done" ||
    viewState === "ticket_selection" ||
    viewState === "ticket_selection_loading" ||
    viewState === "ticket_selection_done" ||
    viewState === "ticket_validation" ||
    viewState === "ticket_validation_loading" ||
    viewState === "option_confirmation" ||
    viewState === "option_loading" ||
    viewState === "option_done"
  ) {
    tickets.forEach((ticket, index) => {
      let premium = false;
      if (ticket.premium_type) {
        premium = true;
      }
      let ticketDate = moment(ticket.enter_at)
        .tz("America/Caracas")
        .format("DD / MM / YYYY");
      let nextTicketDate;
      if (index !== 0)
        nextTicketDate = moment(tickets[index - 1].enter_at)
          .tz("America/Caracas")
          .format("DD / MM / YYYY");
      if (index === 0 || ticketDate !== nextTicketDate) {
        let separatorDate = moment(ticket.enter_at).tz("America/Caracas"); //Test this format
        ticketsList.push(
          <dt key={ticket.enter_at} className="date-separator">
            {texts.entriesOf}
            {formatDate(separatorDate)}
          </dt>
        );
        if (args && args.category && args.category === "estacionamiento")
          ticketsList.push(<TicketListLessInfoHeader key={index} />);
      }
      ticketsList.push(
        <TicketListLessInfoItem
          ticketNumber={ticket.ticket_number}
          username={ticket.username}
          time={ticket.enter_at}
          status={ticket.status}
          index={index}
          category={args && args.category ? args.category : ""}
          premium={premium}
          handleTicketSelection={handleTicketSelection}
          key={ticket._id}
        />
      );
    });

    return ticketsList;
  }
}

function formatDate(momentObject) {
  let dateString = momentObject.format("dddd, D [de] MMMM YYYY");
  dateString = dateString.charAt(0).toUpperCase() + dateString.slice(1);
  return dateString;
}

TicketListItemModel.propTypes = {
  ticketNumber: PropTypes.string,
  username: PropTypes.string,
  time: PropTypes.string,
  index: PropTypes.number,
};

export {
  TicketListItemModel,
  TicketListLessInfoItemModel,
  TicketListHeaderModel,
  TicketListLessInfoHeaderModel,
  formatTicketsList,
  formatTicketsListLessInfo,
};
