import React from "react";
import { OptionsView } from "./components/options-view";
import { OptionsPhoneView } from "./components/options-phone-view";

const Options = ({
  viewType,
  optionSelected,
  handleNewUser,
  handleRecharge,
  handleNewTicket,
  handleValidateEvent,
}) => {
  if (viewType !== "phone")
    return (
      <OptionsView
        optionSelected={optionSelected}
        handleNewUser={handleNewUser}
        handleRecharge={handleRecharge}
        handleNewTicket={handleNewTicket}
        handleValidateEvent={handleValidateEvent}
      />
    );
  else
    return (
      <OptionsPhoneView
        optionSelected={optionSelected}
        handleNewUser={handleNewUser}
        handleRecharge={handleRecharge}
        handleNewTicket={handleNewTicket}
        handleValidateEvent={handleValidateEvent}
      />
    );
};

export default Options;
