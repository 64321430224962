import { AlertMessageModel } from "./models";
import styled from "styled-components";
import { bluePD, magenta } from "assets/strings/colors";

const AlertMessage = styled(AlertMessageModel)`
  #root && {
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > .upside {
      width: clamp(20%, 200px, 40%);
    }
    > .upside-down {
      width: clamp(20%, 200px, 40%);
      transform: rotateX(180deg);
    }
  }
  > button {
    cursor: pointer;
    font-weight: bold;
    border: none;
    color: white;
    border-radius: 3rem;
    //background: linear-gradient(to right, ${magenta}, ${bluePD});
    background: ${bluePD};
    height: 5vh;
    width: 40%;
    :focus {
      outline: none;
      opacity: 0.7;
    }
  }
`;

export { AlertMessage };
