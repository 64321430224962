import React from "react";
import { useResponsive } from "hooks/use-responsive";
import { connect } from "react-redux";
import { usePopupMessages } from "hooks/use-popup-messages";
import { SuperadminBillsHistoryContext } from "./context";
import { useStateManager } from "hooks/use-state-manager";

const SuperadminBillsHistoryState = ({ children }) => {
  const viewport = useResponsive();
  const initialState = {
    viewState: "search_bills",
    historyForm: {
      from_date: null,
      end_date: null,
      limit: 10,
      skip: 1,
      bill_type: "superadmin",
    },
    bills: [],
    loading: false,
    currentPage: 1,
    nextPageExist: false,
  };

  const [state, changeState] = useStateManager(initialState);

  const {
    popupMessages,
    popupMessagesType,
    showPopup,
    throwPopupMessage,
    closePopup,
  } = usePopupMessages(null, "", false);

  return (
    <SuperadminBillsHistoryContext.Provider
      value={{
        state,
        changeState,
        popupMessages,
        popupMessagesType,
        showPopup,
        throwPopupMessage,
        closePopup,
        viewport,
      }}
    >
      {children}
    </SuperadminBillsHistoryContext.Provider>
  );
};

const mapStateToProps = null;
const mapDispatchToProps = null;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuperadminBillsHistoryState);
