export const SESSION_TOKEN_ADD = "SESSION_TOKEN_ADD";
export const SESSION_TOKEN_REMOVE = "SESSION_TOKEN_REMOVE";
export const SESSION_WORKSPACE_ADD = "SESSION_WORKSPACE_ADD";
export const SESSION_UPDATE = "SESSION_UPDATE";
export const SESSION_USER_ADD = "SESSION_USER_ADD";
export const SESSION_USER_EMAIL_SET = "SESSION_USER_EMAIL_SET";
export const SESSION_COLORS_ADD = "SESSION_COLORS_ADD";
export const SESSION_ADD_FOREIGN_ID = "SESSION_ADD_FOREIGN_ID";
export const SESSION_ADD_OFFICE_NUMBERS = "SESSION_ADD_OFFICE_NUMBERS";
export const SESSION_ROUTES_ADD = "SESSION_ROUTES_ADD";
export const addToken = (token) => ({
  type: SESSION_TOKEN_ADD,
  payload: {
    token,
  },
});

export const removeToken = () => ({
  type: SESSION_TOKEN_REMOVE,
});

export const addWorkspace = (workspace) => ({
  type: SESSION_WORKSPACE_ADD,
  payload: {
    workspace,
  },
});
export const addForeignId = (foreign_id) => ({
  type: SESSION_ADD_FOREIGN_ID,
  payload: {
    foreign_id,
  },
});

export const updateSession = (sessionObject) => ({
  type: SESSION_UPDATE,
  payload: {
    sessionObject,
  },
});

export const addUser = ({
  username,
  email,
  role,
  has_pin,
  category,
  permissions,
}) => ({
  type: SESSION_USER_ADD,
  payload: {
    username,
    email,
    role,
    has_pin,
    category,
    permissions,
  },
});

export const setUserEmail = (email) => ({
  type: SESSION_USER_EMAIL_SET,
  payload: { email },
});

export const addColors = (colors) => ({
  type: SESSION_COLORS_ADD,
  payload: {
    colors,
  },
});

export const addOfficeNumbers = (officeNumbers) => ({
  type: SESSION_ADD_OFFICE_NUMBERS,
  payload: {
    officeNumbers,
  },
});

export const addRoutes = (routes) => ({
  type: SESSION_ROUTES_ADD,
  payload: {
    routes: [...routes],
  },
});
