import SearchInputModel from "./model";
import styled from "styled-components";
import { grey_4 } from "assets/strings/colors";

const SearchInput = styled(SearchInputModel)`
  #root & {
    display: flex;
    align-items: center;
    width: ${(props) =>
      props.width ? `calc({${props.width} - 1rem)}` : "100%"};
    height: 5rem;
    border-bottom: solid 1px ${grey_4};
    font-size: 1.2rem;

    .input-tag {
      margin-left: 0.5rem;
      height: 2rem;
      max-width: calc(100% - 60px);

      ::placeholder {
        overflow-wrap: normal;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .search.icon.label {
      color: ${grey_4};
      margin-left: 1rem;
    }
  }

  *,
  :before {
    box-sizing: initial;
  }
`;

export const SearchInputRounded = styled(SearchInputModel)`
  #root & {
    display: flex;
    align-items: center;
    width: ${(props) => (props.width ? props.width : "100%")};
    border-bottom: solid 1px ${grey_4};
    font-size: 1.2rem;
    height: 3rem;
    margin-bottom: 1rem;
    border-radius: 1rem;

    .input-tag {
      margin-left: 0.5rem;
      height: 2rem;
      max-width: calc(100% - 60px);

      ::placeholder {
        overflow-wrap: normal;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .search.icon.label {
      color: ${grey_4};
      margin-left: 1rem;
    }
  }

  *,
  :before {
    box-sizing: initial;
  }
`;

export default SearchInput;
