import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { clearReduxStore } from "redux/actions/general";
import { errors } from "assets/strings/texts";
import excamCircleIcon from "assets/img/exclam-circle-grey-icon.png";
import closeCircleIcon from "assets/img/close-circle-grey-icon.png";
import { recharge_validate as viewTexts } from "assets/strings/texts";
import {
  getRecharges,
  getTDCRecharges,
  patchRecharge,
  getConstants,
  patchRechargePending,
} from "services/api";
import { getSelectedCommerce as getSelectedCommerceFromRedux } from "redux/selectors";
import { throwPopupMessage, closePopup } from "components/popup-message";
import { RechargesValidateView } from "./components/recharges-validate-view";
import { RechargesValidatePhoneView } from "./components/recharges-validate-phone-view";

import {
  popup_transition_time,
  element_transition_time,
} from "assets/strings/constants";

class MPosRechargesValidate extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      viewState: "recharge_search",
      username: "",
      usernameRecharge: "",
      rechargesType: "mobile_payment", //enum ['movil_payment', 'bank_transfer'],
      pending: true,
      approved: false,
      processing: false,
      rejected: false,
      nextPageExist: false,
      currentPage: 1,
      recharges: [],
      selectedRecharge: null,
      rechargeAmount: 0,
      rechargeStatus: "",
      rechargeReason: "",
      rechargeResponseText: "",
      showRecharge: false,
      // viewType: 'phone' | is set when component mounts
      popupMessages: null,
      popupMessagesType: "",
      showPopup: false,
    };
    // ========== INSTANCE PROPERTIES ==========
    this.initialState = { ...this.state };
    this.rechargesPerPage = 5;
    this.formDataCache = {};
    this.rechargeReasonsOptions = [];
    // Bind popup message functions
    this.throwPopupMessage = throwPopupMessage.bind(this);
    this.closePopup = closePopup.bind(this);
  }

  // =============== STATE DOCS ===============
  /*
--> recharge_search
  { ...this.initialState }

--> recharge_search_loading
  {
    ...this.initialState,
      username: String,
      rechargesType: String,
      pending: boolean,
      approved: boolean,
      reported: boolean,
      rejected: boolean,
  }

--> recharge_search_done
  {
    username: String,
    rechargesType: String,
    pending: boolean,
    approved: boolean,
    reported: boolean,
    rejected: boolean,
    currentPage: Number,
    recharges: [{zero or more}]
  }

--> recharge_selection_done
  {
    username: String,
    rechargesType: String,
    pending: boolean,
    approved: boolean,
    reported: boolean,
    rejected: boolean,
    currentPage: Number,
    recharges: [{zero or more}],
    selectedRecharge: {},
    rechargeAmount: Number,
    showRecharge: true
  }

--> recharge_validation_approved
--> recharge_validation_rejected
{
    username: String,
    rechargesType: String,
    pending: boolean,
    approved: boolean,
    reported: boolean,
    rejected: boolean,
    currentPage: Number,
    recharges: [{zero or more}]
    selectedRecharge: {},
    rechargeAmount: Number,
    showRecharge: true,
    rechargeStatus: String,
    showPopup: true,
    popupMessages: ['text'],
    popupMessageType: 'alert
  }

--> recharge_validation_reported
{
    username: String,
    rechargesType: String,
    pending: boolean,
    approved: boolean,
    reported: boolean,
    rejected: boolean,
    currentPage: Number,
    recharges: [{zero or more}]
    selectedRecharge: {},
    rechargeAmount: Number,
    showRecharge: true,
    rechargeStatus: String,
    rechargeReason: String,
    showPopup: true,
    popupMessages: ['text'],
    popupMessageType: 'alert
  }

--> recharge_validation_loading
  {
    username: String,
    rechargesType: String,
    pending: boolean,
    approved: boolean,
    reported: boolean,
    rejected: boolean,
    currentPage: Number,
    recharges: [{zero or more}]
    selectedRecharge: {},
    rechargeAmount: Number,
    showRecharge: true,
    rechargeStatus: String,
    rechargeReason: String,
    showPopup: true,
    popupMessages: ['text'],
    popupMessageType: 'alert
  }

--> recharge_validation_done
  {
    username: String,
    rechargesType: String,
    pending: boolean,
    approved: boolean,
    reported: boolean,
    rejected: boolean,
    currentPage: Number,
    recharges: [{zero or more}]
    selectedRecharge: {},
    rechargeAmount: Number,
    showRecharge: true,
    rechargeStatus: String,
    rechargeReason: String,
    showPopup: true,
    popupMessages: ['text'],
    popupMessageType: 'alert,
    rechargeResponseText: String
  }

*/
  // =============== END STATE DOCS ===============

  /*
   ** mpos-recharge-validate on Mount:
   ** --> set viewType
   ** --> set the 'resize' event listener
   ** --> get constants from server
   ** then
   ** --> call handleRechargesFormSubmit() to search for
   **     new recharges on load
   */
  componentDidMount() {
    // --> Set viewType
    this.setViewType();
    // --> Set 'resize' event listener
    window.addEventListener("resize", this.setViewType);
    document.addEventListener("keydown", this.onEnter);
    // --> get constants from server
    // handleRechargesFormSubmit is called on ok response
    getConstants()
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((res) => {
        if (res.RECHARGE_FAIL_REASONS) {
          let rechargeReasonsOptions = [];

          for (let reason in res.RECHARGE_FAIL_REASONS) {
            rechargeReasonsOptions.push({
              text: res.RECHARGE_FAIL_REASONS[reason].description,
              value: reason,
            });
          }

          this.rechargeReasonsOptions = rechargeReasonsOptions;
          // --> call handleRechargesFormSubmit() to search for new recharges on load
          //this.handleRechargesFormSubmit();
        }
      })
      // if an error occurs, reset the view and print the error
      .catch((err) => {
        // If error has a body, check the response
        if (typeof err.json === "function") {
          err.json().then((err_body) => {
            // If it is an authentication error, clear the redux-store to close the session
            if (
              err_body.error.id === "NO_TOKEN_PROVIDED" ||
              err_body.error.id === "AUTHENTICATE_FAILED" ||
              err_body.error.id === "DUPLICATE_SESSION"
            ) {
              //this.props.clearReduxStore();
              this.throwPopupMessage("alert", errors.AUTHENTICATION);
              return; // prevent setState exeution on unmounted component
            }
            this.setState({ ...this.initialState });
            this.throwPopupMessage("error", err_body.error.description);
          });
        } else {
          this.setState({ ...this.initialState });
          this.throwPopupMessage("error", errors.GENERAL_ERR);
        }
      });
  }

  // Remove event listeners
  componentWillUnmount() {
    window.removeEventListener("resize", this.setViewType);
    document.removeEventListener("keydown", this.onEnter);
  }

  // ========== METHODS ==========
  // 0)
  handleUsernameChange = (event) => {
    let username = event.currentTarget.value;
    this.setState({ username });
  };

  //0.1)
  handleUsernameChangeRecharge = (event) => {
    let usernameRecharge = event.currentTarget.value;
    this.setState({ usernameRecharge });
  };

  // 1)
  handleRechargesType = (event) => {
    let rechargesType = event.currentTarget.id;
    this.setState({ rechargesType });
  };

  // 2)
  handleSearchStatus = (event) => {
    event.preventDefault();
    let status = event.currentTarget.id;
    this.setState((prevState) => ({
      [status]: !prevState[status],
    }));
  };

  // 3)
  formatRequestData = (dataObject) => {
    let username,
      rechargesType,
      status = [],
      limit;
    if (dataObject.username) username = dataObject.username;
    if (dataObject.pending) status.push("pending");
    if (dataObject.approved) status.push("approved");
    if (dataObject.processing) status.push("processing");
    if (dataObject.rejected) status.push("rejected");
    rechargesType = dataObject.rechargesType;
    limit = this.rechargesPerPage + 1;
    return { username, rechargesType, status, limit };
  };

  // 4)
  handleRechargesFormSubmit = () => {
    // format request data
    let { username, rechargesType, status, limit } = this.formatRequestData(
      this.state
    );
    let skip = 0;
    // save a copy of the form state
    this.formDataCache = {
      username: this.state.username,
      rechargesType: this.state.rechargesType,
      pending: this.state.pending,
      approved: this.state.approved,
      processing: this.state.processing,
      rejected: this.state.rejected,
    };
    // Remove on screen recharge
    this.setState({ showRecharge: false });
    // timeout next instructions so the recharge is removed from view
    setTimeout(() => {
      // Set loading state
      this.setState({
        ...this.initialState,
        ...this.formDataCache,
        viewState: "recharge_search_loading",
      });
      let promise = getRecharges(username, rechargesType, status, skip, limit);
      if (this.state.rechargesType === "TDC")
        promise = getTDCRecharges(1, this.rechargesPerPage);
      this.handleRechargesSearchPromise(promise);
    }, element_transition_time);
  };

  // 5)
  handlePageDown = (event) => {
    event.preventDefault();
    // format request data
    let { username, rechargesType, status, limit } = this.formatRequestData(
      this.formDataCache
    );
    let skip = (this.state.currentPage - 2) * this.rechargesPerPage;
    // Remove on screen recharge
    this.setState({ showRecharge: false });
    // timeout next instructions so the recharge is removed from view
    setTimeout(() => {
      // Set loading state
      this.setState((prevState) => ({
        ...this.initialState,
        ...this.formDataCache,
        viewState: "recharge_search_loading",
        currentPage: prevState.currentPage - 1,
        nextPageExist: true,
      }));
      // make the api call
      let promise = getRecharges(username, rechargesType, status, skip, limit);
      if (this.state.rechargesType === "TDC")
        promise = getTDCRecharges(
          this.state.currentPage,
          this.rechargesPerPage
        );
      this.handleRechargesSearchPromise(promise);
    }, element_transition_time);
  };

  // 6)
  handlePageUp = (event) => {
    event.preventDefault();
    // Get data required for the api call
    let { username, rechargesType, status, limit } = this.formatRequestData(
      this.formDataCache
    );
    let skip = this.state.currentPage * this.rechargesPerPage;
    // Remove on screen recharge
    this.setState({ showRecharge: false });
    // timeout next instructions so the recharge is removed from view
    setTimeout(() => {
      // set state and make api call
      this.setState((prevState) => ({
        ...this.initialState,
        ...this.formDataCache,
        viewState: "recharge_search_loading",
        currentPage: prevState.currentPage + 1,
        nextPageExist: true,
      }));
      // make the api call
      let promise = getRecharges(username, rechargesType, status, skip, limit);
      if (this.state.rechargesType === "TDC")
        promise = getTDCRecharges(
          this.state.currentPage,
          this.rechargesPerPage
        );
      this.handleRechargesSearchPromise(promise);
    }, element_transition_time);
  };

  // 7)
  handleRechargesSearchPromise = (promise) => {
    promise
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((res) => {
        if (res.recharges) {
          let nextPageExist;
          if (res.recharges.length > this.rechargesPerPage) {
            nextPageExist = true;
            res.recharges.pop();
          } else {
            nextPageExist = false;
          }
          // else, print the recharges
          this.setState({
            viewState: "recharge_search_done",
            recharges: res.recharges,
            nextPageExist,
          });
        }
        if (this.state.rechargesType === "TDC" && res.data) {
          this.setState({
            viewState: "recharge_search_done",
            recharges: res.data.dollar_payments.map((item) => ({
              ...item,
              phone: "--",
              type: "TDC",
              payment_ref: item.reference,
              currency: "VES",
            })),
            nextPageExist: res.data.nextPageExists,
          });
        }
      })
      // if an error occurs, reset the view and print the error
      .catch((err) => {
        // If error has a body, check the response
        if (typeof err.json === "function") {
          err.json().then((err_body) => {
            // If it is an authentication error, clear the redux-store to close the session
            if (
              err_body.error.id === "NO_TOKEN_PROVIDED" ||
              err_body.error.id === "AUTHENTICATE_FAILED" ||
              err_body.error.id === "DUPLICATE_SESSION"
            ) {
              //this.props.clearReduxStore();
              this.throwPopupMessage("alert", errors.AUTHENTICATION);
              return; // prevent setState exeution on unmounted component
            }
            this.setState({ ...this.initialState });
            this.throwPopupMessage("error", err_body.error.description);
          });
        } else {
          this.setState({ ...this.initialState });
          this.throwPopupMessage("error", errors.GENERAL_ERR);
        }
      });
  };

  // 8)
  handleRechargeSelection = (event) => {
    let index = event.currentTarget.id;
    // remove current recharge and wait for the transition
    this.setState({ showRecharge: false });

    setTimeout(() => {
      this.setState((prevState) => ({
        viewState: "recharge_selection_done",
        selectedRecharge: prevState.recharges[index],
        showRecharge: true,
        rechargeAmount: prevState.recharges[index].amount,
      }));
    }, element_transition_time);
  };

  // 9)
  removeSelectedRecharge = () => {
    // Remove recharge and set transition states
    this.setState({ showRecharge: false });
    setTimeout(() => {
      this.setState({
        viewState: "recharge_search_done",
        selectedRecharge: null,
        rechargeAmount: 0,
      });
    }, element_transition_time);
  };

  // 10)
  handleRechargeAmount = (event) => {
    let value = event.target.value;
    if (value.match(/^\d+\.?\d*$/)) {
      this.setState({ rechargeAmount: value });
    }
  };

  // 9)
  toggleConfirmation = (action) => {
    if (
      this.state.viewState === "recharge_validation_approved" ||
      this.state.viewState === "recharge_validation_reported" ||
      this.state.viewState === "recharge_validation_rejected" ||
      this.state.viewState === "recharge_validation_loading"
    ) {
      this.closePopup();
      // timeout the update for the animation
      setTimeout(() => {
        this.setState({
          viewState: "recharge_selection_done",
          rechargeStatus: "",
          rechargeReason: "",
        });
      }, popup_transition_time);
    }
    if (this.state.viewState === "recharge_selection_done") {
      this.setState({
        viewState: "recharge_validation_" + action,
        rechargeStatus: action,
      });

      if (action === "approved")
        this.throwPopupMessage("alert", viewTexts.rechargeValidateConfirmation);

      if (action === "processing") {
        this.setState({ rechargeReason: this.rechargeReasonsOptions[3].value });
        this.throwPopupMessage(
          "alert",
          viewTexts.rechargeReportConfirmation,
          <img
            src={excamCircleIcon}
            alt=""
            style={{ height: "48px", marginTop: "1rem" }}
          />
        );
      }

      if (action === "rejected") {
        this.setState({ rechargeReason: this.rechargeReasonsOptions[3].value });
        this.throwPopupMessage(
          "alert",
          viewTexts.rechargeRejectConfirmation,
          <img
            src={closeCircleIcon}
            alt=""
            style={{ height: "48px", marginTop: "1rem" }}
          />
        );
      }
    }
  };

  handleDropdown = (e, data) => {
    let propertie = data.id;
    let value = data.value;
    this.setState({ [propertie]: value });
  };

  handleRechargePatch = () => {
    this.setState({ viewState: "recharge_validation_loading" });

    if (this.state.rechargesType === "mobile_payment") {
      patchRechargePending(
        this.state.selectedRecharge._id,
        this.state.usernameRecharge,
        this.state.selectedRecharge.payment_ref
      )
        .then((res) => {
          if (!res.ok) {
            throw res;
          }
          return res.json();
        })
        .then((res) => {
          if (res.message) {
            this.closePopup();
            setTimeout(() => {
              this.setState({
                viewState: "recharge_validation_done",
                rechargeResponseText: res.message,
              });
            }, popup_transition_time);
          }
        })
        // if an error occurs, reset the view and print the error
        .catch((err) => {
          // If error has a body, check the response
          if (typeof err.json === "function") {
            err.json().then((err_body) => {
              // If it is an authentication error, clear the redux-store to close the session
              if (
                err_body.error.id === "NO_TOKEN_PROVIDED" ||
                err_body.error.id === "AUTHENTICATE_FAILED" ||
                err_body.error.id === "DUPLICATE_SESSION"
              ) {
                //this.props.clearReduxStore();
                this.throwPopupMessage("alert", errors.AUTHENTICATION);
                return; // prevent setState exeution on unmounted component
              }
              this.closePopup();
              // timeout the update for the animation
              setTimeout(() => {
                this.setState({
                  viewState: "recharge_selection_done",
                  rechargeStatus: "",
                });
                this.throwPopupMessage("error", err_body.error.description);
              }, popup_transition_time);
            });
          } else {
            this.closePopup();
            // timeout the update for the animation
            setTimeout(() => {
              this.setState({
                viewState: "recharge_selection_done",
                rechargeStatus: "",
              });
              this.throwPopupMessage("error", errors.GENERAL_ERR);
            }, popup_transition_time);
          }
        });
    } else {
      patchRecharge(
        this.state.selectedRecharge._id,
        this.state.rechargeAmount,
        this.state.rechargeStatus,
        this.state.rechargeReason
      )
        .then((res) => {
          if (!res.ok) {
            throw res;
          }
          return res.json();
        })
        .then((res) => {
          if (res.message) {
            this.closePopup();
            setTimeout(() => {
              this.setState({
                viewState: "recharge_validation_done",
                rechargeResponseText: res.message,
              });
            }, popup_transition_time);
          }
        })
        // if an error occurs, reset the view and print the error
        .catch((err) => {
          // If error has a body, check the response
          if (typeof err.json === "function") {
            err.json().then((err_body) => {
              // If it is an authentication error, clear the redux-store to close the session
              if (
                err_body.error.id === "NO_TOKEN_PROVIDED" ||
                err_body.error.id === "AUTHENTICATE_FAILED" ||
                err_body.error.id === "DUPLICATE_SESSION"
              ) {
                //this.props.clearReduxStore();
                this.throwPopupMessage("alert", errors.AUTHENTICATION);
                return; // prevent setState exeution on unmounted component
              }
              this.closePopup();
              // timeout the update for the animation
              setTimeout(() => {
                this.setState({
                  viewState: "recharge_selection_done",
                  rechargeStatus: "",
                });
                this.throwPopupMessage("error", err_body.error.description);
              }, popup_transition_time);
            });
          } else {
            this.closePopup();
            // timeout the update for the animation
            setTimeout(() => {
              this.setState({
                viewState: "recharge_selection_done",
                rechargeStatus: "",
              });
              this.throwPopupMessage("error", errors.GENERAL_ERR);
            }, popup_transition_time);
          }
        });
    }
  };

  // 11)
  resetView = () => {
    let recharges = this.state.recharges.filter((recharge) => {
      return recharge._id !== this.state.selectedRecharge._id;
    });

    this.setState({
      ...this.initialState,
      ...this.formDataCache,
      nextPageExist: this.state.nextPageExist,
      currentPage: this.state.currentPage,
      viewState: "recharge_search_done",
      recharges,
    });
  };

  // 12)
  setViewType = () => {
    let documentElement = document.documentElement,
      width = window.innerWidth || documentElement.clientWidth;
    if (width < 768) {
      this.setState({ viewType: "phone" });
    } else {
      this.setState({ viewType: "desktop" });
    }
  };

  onEnter = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      this.handleRechargesFormSubmit(event);
    }
  };

  render() {
    if (this.state.viewType === "phone") {
      return (
        <RechargesValidatePhoneView
          // properties
          viewType={this.state.viewType}
          category={this.props.getSelectedCommerce.category}
          role={this.props.role}
          rechargeReasonsOptions={this.rechargeReasonsOptions}
          viewState={this.state.viewState}
          username={this.state.username}
          rechargesType={this.state.rechargesType}
          pending={this.state.pending}
          approved={this.state.approved}
          processing={this.state.processing}
          rejected={this.state.rejected}
          recharges={this.state.recharges}
          currentPage={this.state.currentPage}
          nextPageExist={this.state.nextPageExist}
          showRecharge={this.state.showRecharge}
          selectedRecharge={this.state.selectedRecharge}
          rechargeAmount={this.state.rechargeAmount}
          rechargeStatus={this.state.rechargeStatus}
          rechargeReason={this.state.rechargeReason}
          rechargeResponseText={this.state.rechargeResponseText}
          popupMessages={this.state.popupMessages}
          popupMessagesType={this.state.popupMessagesType}
          showPopup={this.state.showPopup}
          location={this.props.location}
          // methods
          handleUsernameChange={this.handleUsernameChange}
          handleUsernameChangeRecharge={this.handleUsernameChangeRecharge}
          handleRechargesType={this.handleRechargesType}
          handleSearchStatus={this.handleSearchStatus}
          handleRechargesFormSubmit={this.handleRechargesFormSubmit}
          handlePageDown={this.handlePageDown}
          handlePageUp={this.handlePageUp}
          handleRechargeSelection={this.handleRechargeSelection}
          removeSelectedRecharge={this.removeSelectedRecharge}
          handleRechargeAmount={this.handleRechargeAmount}
          toggleConfirmation={this.toggleConfirmation}
          handleDropdown={this.handleDropdown}
          handleRechargePatch={this.handleRechargePatch}
          resetView={this.resetView}
          closePopup={this.closePopup}
        />
      );
    }

    return (
      <RechargesValidateView
        // properties
        category={this.props.getSelectedCommerce.category}
        rechargeReasonsOptions={this.rechargeReasonsOptions}
        viewState={this.state.viewState}
        username={this.state.username}
        rechargesType={this.state.rechargesType}
        pending={this.state.pending}
        approved={this.state.approved}
        processing={this.state.processing}
        rejected={this.state.rejected}
        recharges={this.state.recharges}
        currentPage={this.state.currentPage}
        nextPageExist={this.state.nextPageExist}
        showRecharge={this.state.showRecharge}
        selectedRecharge={this.state.selectedRecharge}
        rechargeAmount={this.state.rechargeAmount}
        rechargeStatus={this.state.rechargeStatus}
        rechargeReason={this.state.rechargeReason}
        rechargeResponseText={this.state.rechargeResponseText}
        popupMessages={this.state.popupMessages}
        popupMessagesType={this.state.popupMessagesType}
        showPopup={this.state.showPopup}
        location={this.props.location}
        // methods
        handleUsernameChange={this.handleUsernameChange}
        handleUsernameChangeRecharge={this.handleUsernameChangeRecharge}
        handleRechargesType={this.handleRechargesType}
        handleSearchStatus={this.handleSearchStatus}
        handleRechargesFormSubmit={this.handleRechargesFormSubmit}
        handlePageDown={this.handlePageDown}
        handlePageUp={this.handlePageUp}
        handleRechargeSelection={this.handleRechargeSelection}
        handleRechargeAmount={this.handleRechargeAmount}
        toggleConfirmation={this.toggleConfirmation}
        handleDropdown={this.handleDropdown}
        handleRechargePatch={this.handleRechargePatch}
        resetView={this.resetView}
        closePopup={this.closePopup}
      />
    );
  }
}

const mapStateToProps = (state) => {
  let getSelectedCommerce = getSelectedCommerceFromRedux(state);
  return { getSelectedCommerce };
};

const mapDispatchToProps = {
  clearReduxStore,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MPosRechargesValidate);
