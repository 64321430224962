import { UserSelectedModel } from "./model";
import styled from "styled-components";
import {
  grey_4,
  bluePD,
  grey_5,
  grey_6,
  red_2,
  red,
} from "assets/strings/colors";

const UserSelected = styled(UserSelectedModel)`
  #root & {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 4rem);
    > .user {
      position: relative;
      padding: 2rem;
      margin-bottom: 2rem;
      flex-direction: column;
      width: 75%;
      background-color: white;
      border-radius: 0 0 0.3rem 0.3rem;
      box-shadow: 0 2px 7px 1px ${grey_4};
      > .delete-button {
        width: 50%;
        margin-left: 50%;
        margin-bottom: 2rem;
        > button {
          display: block;
          border: none;
          cursor: pointer;
          width: 100%;
          border-radius: 0.28rem;
          font-weight: bold;
          min-height: 1.5rem;
          padding: 0.8rem 1.5rem;
        }
      }
      > .roles {
        flex-direction: column;
        margin-top: 1rem;
        text-align: center;
        color: ${bluePD};
        width: 100%;
      }
      > .status {
        flex-direction: column;
        margin-top: 1rem;
        text-align: center;
        color: ${bluePD};
        width: 100%;
      }
    }
    .user.user-appear {
      opacity: 0;
      transform: translateX(-30px);
    }
    .user.user-appear-active {
      opacity: 0.8;
      transform: translateX(2px);
      transition: all 350ms ease-out;
    }
    .user.user-enter {
      opacity: 0;
      transform: translateX(-30px);
    }
    .user.user-enter-active {
      opacity: 0.8;
      transform: translateX(2px);
      transition: all 300ms ease-out;
    }
    .user.user-enter-done {
      opacity: 1;
      transform: translateX(0);
      transition: all 50ms ease-out;
    }
    .user.user-exit {
      opacity: 0.8;
      transform: translateX(-2px);
    }
    .user.user-exit-active {
      opacity: 0;
      transform: translateX(30px);
      transition: all 350ms ease-out;
    }
    > button {
      border: none;
      cursor: pointer;
      width: 160px;
      border-radius: 0.28rem;
      font-weight: bold;
      min-height: 1.5rem;
      padding: 0.8rem 1.5rem;
    }
    > .back {
      margin-top: 1rem;
      margin-bottom: 2rem;
      border-bottom: 1px solid ${grey_4};
      background: ${grey_5};
      color: white;
      :hover {
        background: ${grey_6};
      }
    }
    > .delete {
      float: right;
      border-bottom: 1px solid ${red};
      background: ${red};
      color: white;
      :hover {
        background: ${red_2};
      }
    }
  }
`;

export { UserSelected };
