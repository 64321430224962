import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "components/buttons";
import { Dropdown } from "semantic-ui-react";
import { errors } from "assets/strings/texts";
import { markInvoiceAsPaid } from "services/api";
import { clearReduxStore } from "redux/actions/general";
import { general as texts } from "assets/strings/texts";
import { getViewMposCommerces } from "redux/selectors/view-mpos-commerces";
import { throwPopupMessage, closePopup } from "redux/actions/popup-messages";
import { popup_transition_time } from "assets/strings/constants";
import { commerces_admin as viewTexts } from "assets/strings/texts";
import {
  setViewState,
  updateInvoicesList,
} from "redux/actions/view-mpos-commerces";

class InvoicePaymentFormModel_unconnected extends Component {
  constructor(props) {
    super(props);
    this.state = {
      physicalBillRef: "",
      payType: null,
      payRef: "",
      authError: false,
    };

    // ========== INSTANCE PROPERTIES ==========
    this.initialState = { ...this.state };
  }

  // 20)
  handleTextInput = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value });
  };

  // 21)
  handlePayType = (event, data) => {
    this.setState({ payType: data.value });
  };

  // 22)
  handleInvoicePayment = (event) => {
    event.preventDefault();
    this.props.setViewState("commerce_invoice_payment_loading");
    let paymentData = {
      bill_id: this.props.selectedInvoice,
      physical_bill_ref: this.state.physicalBillRef,
      pay_ref: this.state.payRef,
      pay_type: this.state.payType,
    };
    markInvoiceAsPaid(paymentData)
      .then((res) => {
        if (!res.ok) {
          throw res;
        } // If not OK throw error
        return res.json(); // If OK return body
      })
      .then((res) => {
        // Manage success response
        if (res.message) {
          // Clone actual invoices
          let updatedInvoices = [...this.props.invoices];
          // add the paid bill to the cloned array
          this.props.invoices.forEach((invoice, index) => {
            if (invoice._id === res.bill._id) {
              updatedInvoices[index] = res.bill;
            }
          });

          this.props.updateInvoicesList(updatedInvoices);
          this.props.closePopup();

          setTimeout(() => {
            this.props.setViewState("commerce_invoice_done");
            this.props.throwPopupMessage("success", res.message);
          }, popup_transition_time);
        }
      })
      .catch((err) => {
        // Manage error response
        this.props.closePopup();
        // If error has a body, check the response
        if (typeof err.json === "function") {
          err.json().then((err_body) => {
            // If it is an authentication error, clear the redux-store to close the session
            if (
              err_body.error.id === "NO_TOKEN_PROVIDED" ||
              err_body.error.id === "AUTHENTICATE_FAILED" ||
              err_body.error.id === "DUPLICATE_SESSION"
            ) {
              this.props.throwPopupMessage("alert", errors.AUTHENTICATION);
              //this.props.clearReduxStore()
              return; // prevent setState exeution on unmounted component
            }
            setTimeout(() => {
              this.props.setViewState("commerce_invoice_done");
              this.props.throwPopupMessage("error", err_body.error.description);
            }, popup_transition_time);
          });
        } else {
          setTimeout(() => {
            this.props.setViewState("commerce_invoice_done");
            this.props.throwPopupMessage("error", errors.GENERAL_ERR);
          }, popup_transition_time);
        }
      });
  };

  render() {
    const { viewState, className } = this.props;

    const { physicalBillRef, payRef, payType } = this.state;

    return (
      <div className={className}>
        <h3 className="title">{viewTexts.paymentFormHeader}</h3>
        <form
          className="invoice-payment-form"
          onSubmit={this.handleInvoicePayment}
        >
          <div className="input-section">
            <label htmlFor="bill-ref">{viewTexts.paymentFormBillRef}</label>
            <input
              id="bill-ref"
              type="text"
              required={true}
              autoComplete="off"
              name="physicalBillRef"
              placeholder="170734"
              value={physicalBillRef}
              onChange={this.handleTextInput}
            />
          </div>
          <div className="input-section">
            <label htmlFor="pay-type">{viewTexts.paymentFormPayType}</label>
            <Dropdown
              selection
              onChange={this.handlePayType}
              value={payType}
              placeholder={viewTexts.paymentFormPayTypePlaceholder}
              options={[
                {
                  key: 1,
                  text: viewTexts.paymentFormPayTypePaycheck,
                  value: "paycheck",
                },
                {
                  key: 2,
                  text: viewTexts.paymentFormPayTypeWireTransfer,
                  value: "wire_transfer",
                },
              ]}
            />
          </div>
          <div className="input-section">
            <label htmlFor="pay-ref">{viewTexts.paymentFormPayRef}</label>
            <input
              id="pay-ref"
              type="text"
              name="payRef"
              required={true}
              autoComplete="off"
              placeholder="1200345123"
              value={payRef}
              onChange={this.handleTextInput}
            />
          </div>
          <Button
            color="blue"
            type="submit"
            disabled={viewState === "commerce_invoice_payment_loading"}
          >
            {viewTexts.markAsPaid}
          </Button>
        </form>
        <Button
          color="grey"
          margin="1rem 0 0 0 "
          onClick={this.props.closePopup}
        >
          {texts.back}
        </Button>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  viewState: getViewMposCommerces(state).viewState,
  selectedInvoice: getViewMposCommerces(state).selectedInvoice,
  invoices: getViewMposCommerces(state).invoices,
});

const mapDispatchToProps = {
  closePopup,
  setViewState,
  clearReduxStore,
  throwPopupMessage,
  updateInvoicesList,
};

const InvoicePaymentFormModel = connect(
  mapStateToProps,
  mapDispatchToProps
)(InvoicePaymentFormModel_unconnected);

export { InvoicePaymentFormModel };
