import React from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Loader } from "components/loader";
import { Button } from "components/buttons";
import { Dropdown } from "components/dropdown";
import { general as texts } from "assets/strings/texts";

const CommerceMovementsFormModel = ({
  loading,
  viewType,
  operatorPermissions,

  searchType,
  searchFilter,
  fromDate,
  endDate,
  user,
  status,
  statusOptions,

  handleSearchType,
  handleSearchFilter,
  handleChangeUser,
  handleChangeStatus,
  handleFromDate,
  handleEndDate,
  handleSubmit,

  className,
}) => {
  let phoneView = viewType === "phone" ? true : false;
  return (
    <form className={className}>
      {loading && <Loader />}
      <div className={`collapsed false`}>
        <div className="selectors">
          <Button
            value="latests"
            pressed={searchType === "latests"}
            color={searchType === "latests" ? "blue" : "grey_1"}
            disabled={loading}
            onClick={handleSearchType}
          >
            {texts.latests}
          </Button>
          <Button
            value="dateRange"
            pressed={searchType === "dateRange"}
            color={searchType === "dateRange" ? "blue" : "grey_1"}
            disabled={loading}
            onClick={handleSearchType}
          >
            {texts.dateRange}
          </Button>
        </div>
        {searchType === "dateRange" && (
          <div className="date-pickers">
            <DatePicker
              className="date-picker"
              placeholderText={texts.from}
              dateFormat={texts.shortDateFormat}
              disabled={loading}
              showMonthDropdown
              showYearDropdown
              todayButton={texts.today}
              isClearable
              withPortal={phoneView}
              selected={fromDate}
              maxDate={
                endDate ? moment(endDate).subtract(23, "hours") : moment()
              }
              onChange={handleFromDate}
            />
            <p>/</p>
            <DatePicker
              className="date-picker"
              placeholderText={texts.to}
              dateFormat={texts.shortDateFormat}
              disabled={loading}
              popperPlacement="bottom-end"
              showMonthDropdown
              showYearDropdown
              todayButton={texts.today}
              isClearable
              withPortal={phoneView}
              selected={endDate ? moment(endDate).subtract(23, "hours") : null}
              minDate={fromDate ? moment(fromDate).add(23, "hours") : null}
              maxDate={moment()}
              onChange={handleEndDate}
            />
          </div>
        )}
        <div className="filters">
          <Dropdown
            fluid
            selection
            wrapSelection={true}
            placeholder="Estado"
            options={statusOptions}
            value={status}
            disabled={loading}
            onChange={handleChangeStatus}
            fontSize={"1.2rem"}
            style={{ width: "50%", height: "2rem", textAlign: "center" }}
          />
          <div className="ui transparent labeled input user">
            <input
              type="text"
              value={user}
              onChange={handleChangeUser}
              placeholder={phoneView ? "Usuario" : "Filtrar por usuario"}
              className="input-tag"
            />
          </div>
        </div>
        {operatorPermissions && operatorPermissions.permission2 && (
          <div className="selectors">
            <Button
              value={searchFilter === "credit" ? "" : "credit"}
              pressed={searchFilter === "credit"}
              color={searchFilter === "credit" ? "blue" : "grey_1"}
              disabled={loading}
              onClick={handleSearchFilter}
            >
              {texts.income}
            </Button>
            <Button
              value={searchFilter === "debit" ? "" : "debit"}
              pressed={searchFilter === "debit"}
              color={searchFilter === "debit" ? "blue" : "grey_1"}
              disabled={loading}
              onClick={handleSearchFilter}
            >
              {texts.expenditures}
            </Button>
          </div>
        )}
        <Button color="grey" disabled={loading} onClick={handleSubmit}>
          Buscar
        </Button>
      </div>
    </form>
  );
};

export { CommerceMovementsFormModel };
