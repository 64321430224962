import React, { useEffect } from "react";
import { RegisterUserView } from "./components/register-user-view";
import {
  useTextInput,
  usePhoneInput,
  useNumberInput,
  useLowercaseInput,
} from "hooks/use-inputs";
import { useDropdown } from "hooks/use-dropdown";
import { useStateManager } from "hooks/use-state-manager";
import { createCustomer } from "services/api";

const Register = ({ props }) => {
  const initialState = {
    toShow: "register",
    submit: false,
    errorType: "error",
    message: "",
    resetInputs: false,
  };
  const [state, changeState] = useStateManager(initialState);
  const {
    reset: resetUser,
    bind: { value: user, onChange: handleUser },
  } = useLowercaseInput("");
  const {
    reset: resetEmail,
    bind: { value: email, onChange: handleEmail },
  } = useTextInput("");
  const {
    bind: { value: docType, onChange: handleDocType },
  } = useDropdown("V");
  const {
    reset: resetDocId,
    bind: { value: docId, onChange: handleDocId },
  } = useNumberInput("");
  const {
    reset: resetPassword,
    bind: { value: password, onChange: handlePassword },
  } = useTextInput("");
  const {
    reset: resetConfirmPassword,
    bind: { value: confirmPassword, onChange: handleConfirmPassword },
  } = useTextInput("");
  const {
    reset: resetMobilPaidNumber,
    bind: { value: mobilePaidNumber, onChange: handleMobilePaidNumber },
  } = usePhoneInput("");

  useEffect(() => {
    const resetAllInputs = () => {
      resetUser();
      resetEmail();
      resetDocId();
      resetPassword();
      resetConfirmPassword();
      resetMobilPaidNumber();
    };
    const registerUser = async () => {
      try {
        changeState({ toShow: "loader" });
        const form = {
          username: user,
          email,
          doc_type: docType,
          doc_id: docId,
          password,
          mobile_phone: mobilePaidNumber,
        };
        const response = await createCustomer(form);
        const res = await response.json();
        if (res.error) throw res.error;
        changeState({
          toShow: "error",
          errorType: "success",
          message: res.message,
        });
      } catch (error) {
        changeState({
          ...initialState,
          toShow: "error",
          message:
            error.description ||
            "Error al conectar con el servidor, intente más tarde",
        });
      }
    };
    if (state.submit) {
      registerUser();
      changeState({ submit: false });
    }
    if (state.resetInputs) {
      resetAllInputs();
      changeState({ resetInputs: false });
    }
  }, [state.submit || state.resetInputs]);

  const handleSubmit = (event) => {
    event.preventDefault();
    changeState({ submit: true });
  };

  const handleBack = (event) => {
    event.preventDefault();
    changeState({ ...initialState, resetInputs: true });
  };

  return (
    <RegisterUserView
      toShow={state.toShow}
      message={state.message}
      errorType={state.errorType}
      handleContinue={handleBack}
      handleBack={handleBack}
      user={user}
      email={email}
      docType={docType}
      docId={docId}
      password={password}
      confirmPassword={confirmPassword}
      mobilePaidNumber={mobilePaidNumber}
      handleUser={handleUser}
      handleEmail={handleEmail}
      handleDocType={handleDocType}
      handleDocId={handleDocId}
      handlePassword={handlePassword}
      handleConfirmPassword={handleConfirmPassword}
      handleMobilePaidNumber={handleMobilePaidNumber}
      handleSubmit={handleSubmit}
    />
  );
};

export default Register;
