import React, { useContext, useEffect } from "react";
import { Dropdown } from "components/dropdown";
import { CSSTransition } from "react-transition-group";
import { element_transition_time } from "assets/strings/constants";
import { Button } from "components/buttons";
import { Icon } from "semantic-ui-react";
import { AdminTransfersContext } from "../../context";
import { useAmountInput, useNumberInput, useTextInput } from "hooks/use-inputs";
import { useDropdown } from "hooks/use-dropdown";
import { dropdownData } from "../../constants/constants";
import { formatPrice } from "services/utils";

export const CompleteTransferModel = ({ phoneView, className }) => {
  const {
    state: { typeofTransferSelected: typeofTransfer },
    state,
    onGetCode,
    handleBack,
    changeState,
    withdraw,
  } = useContext(AdminTransfersContext);
  const bankDetailsExist = withdraw && withdraw.bank_details;
  const billRefExist = withdraw && withdraw.bill_ref;
  const typeofDocOptions = [...dropdownData.typeofDocOptions];

  const transferType = {
    "third-bancamiga": "Transferencia de cuenta Wallet a terceros Bancamiga",
    "third-other": "Transferencia de cuenta Wallet a terceros de otros bancos ",
    paytech: "Transferencia de cuenta Wallet a Paytech ",
    default: "Tipo de transferencia desconocido",
  };

  const data = {
    customTitle: `${transferType[typeofTransfer] || transferType.default}`,
    nameLabel: "A nombre de:",
    docLabel: "Número de documento:",
    accountNumberLabel: "Número de cuenta:",
    conceptLabel: "Concepto:",
    amountLabel: "Monto a transferir:",
    buttonLabel: "Transferencia",
  };

  const { amount, handle: onChangeAmount } = useAmountInput(
    formatPrice(
      bankDetailsExist && billRefExist ? withdraw.bill_ref.total_amount : ""
    )
  );
  const {
    bind: { value: concept, onChange: onChangeConcept },
  } = useTextInput(
    withdraw && withdraw.transaction_owner
      ? `@${withdraw.transaction_owner}`
      : ""
  );
  const {
    setValue: setTypeofDoc,
    bind: { onChange: onChangeTypeofDoc, value: typeofDoc },
  } = useDropdown(
    bankDetailsExist ? withdraw.bank_details.doc_type : state.typeofDoc
  );
  const {
    setValue: setReceiverName,
    bind: { value: receiver_name, onChange: onChangeReceiverName },
  } = useTextInput(
    bankDetailsExist ? withdraw.bank_details.account_name : state.receiver_name
  );
  const {
    setValue: setReceiverAccount,
    bind: { value: receiver_account, onChange: onChangeReceiverAccount },
  } = useNumberInput(
    bankDetailsExist
      ? withdraw.bank_details.account_number
      : state.receiver_account
  );
  const {
    setValue: setDocummentNumber,
    bind: { value: receiver_document_number, onChange: onChangeReceiverDoc },
  } = useNumberInput(
    bankDetailsExist
      ? withdraw.bank_details.doc_id
      : state.receiver_document_number
  );

  useEffect(() => {
    if (state.renderInputs) {
      setTypeofDoc(state.typeofDoc);
      setDocummentNumber(state.receiver_document_number);
      setReceiverAccount(state.receiver_account);
      setReceiverName(state.receiver_name);
      changeState({
        renderInputs: false,
      });
    }
    if (state.cleanup) {
      if (!state.withdrawFilled) {
        changeState({
          receiver_name: withdraw.bank_details.account_name || "",
          receiver_account: withdraw.bank_details.account_number || "",
          typeofDoc: withdraw.bank_details.doc_type || "",
          receiver_document_number: withdraw.bank_details.doc_id || "",
          renderInputs: true,
          withdrawFilled: true,
        });
      }
      setTypeofDoc("");
      setDocummentNumber("");
      setReceiverAccount("");
      setReceiverName("");
      changeState({ cleanup: false });
    }
    return () => {};
  }, [state.renderInputs || state.cleanup, state.withdrawFilled]);

  const onSubmit = (event) => {
    changeState({
      amount,
      concept,
      receiver_name,
      typeofDoc,
      receiver_document_number,
      receiver_account,
    });
    onGetCode(event);
  };

  return (
    <CSSTransition
      in={true}
      mountOnEnter
      timeout={element_transition_time}
      appear={true}
      classNames="form"
    >
      <form className={className} onSubmit={onSubmit}>
        <div className="custom-title">
          {phoneView && (
            <Icon
              name="arrow left"
              onClick={handleBack}
              styles={{
                zIndex: 1,
                paddingTop: "4rem",
              }}
            />
          )}
          <p>{data.customTitle}</p>
        </div>
        <div className="input-section">
          <label htmlFor="name-id">{data.nameLabel}</label>
          <input
            id="account"
            type="text"
            name="account"
            minLength={1}
            autoComplete="off"
            placeholder="A nombre de"
            value={receiver_name}
            onChange={onChangeReceiverName}
            required
          />
          <label htmlFor="doc-id">{data.docLabel}</label>
          <div className="document">
            <Dropdown
              fluid
              floating
              selection
              id="docType"
              value={typeofDoc}
              onChange={onChangeTypeofDoc}
              options={typeofDocOptions}
              className="doc-type"
              required
            />
            <input
              id="doc-id"
              type="text"
              name="document"
              maxLength={16}
              autoComplete="off"
              placeholder="Número de documento"
              value={receiver_document_number}
              onChange={onChangeReceiverDoc}
              className="doc-in"
              required
            />
          </div>
          <label htmlFor="number-id">{data.accountNumberLabel}</label>
          <input
            id="account"
            type="text"
            name="account"
            maxLength={20}
            minLength={20}
            autoComplete="off"
            placeholder="Número de cuenta"
            value={receiver_account}
            onChange={onChangeReceiverAccount}
            required
          />
          <label htmlFor="concept-id">{data.conceptLabel}</label>
          <input
            id="amount"
            type="text"
            name="concept"
            autoComplete="off"
            placeholder="Concepto"
            value={concept}
            onChange={onChangeConcept}
            required
          />
          <label htmlFor="amount-id">{data.amountLabel}</label>
          <input
            id="concept"
            type="text"
            min="0"
            name="amount"
            autoComplete="off"
            placeholder="Monto"
            value={amount}
            onChange={onChangeAmount}
            required
          />
        </div>
        <Button color="blue" type="submit" width="40%">
          {data.buttonLabel}
        </Button>
      </form>
    </CSSTransition>
  );
};
