import { baseURL_V2, baseURL, withdrawURL } from "services/config";
import { post } from "services/http-methods";
import { superadminCommerceTransactions } from "services/api";
import { errors } from "assets/strings/texts";
//import { amountsToObject } from "services/utils";

const enpoints = {
  getAccounts: `${baseURL_V2}/superadmin/commerces/list/category`,
  transfer: `${baseURL}/commerces/payments/transfer`,
  withdrawPreview: `${withdrawURL}`,
  withdraw: `${withdrawURL}`,
  //withdrawPreview: `${baseURL_V2}/superadmin/withdraw/preview`,
  //withdraw: `${baseURL_V2}/superadmin/withdraw`,
};

export const getAccounts = async (setAccounts) => {
  try {
    const response = await post(enpoints.getAccounts, {
      category: "pagodirecto",
    });
    if (!response.ok) throw response;
    const res = await response.json();
    if (res.error) throw res.error;
    if (res.commerces) setAccounts(res.commerces);
  } catch (e) {
    if (e.json) {
      const error = await e.json();
      console.log(error);
    }
  }
};

export const getOptions = (account) => {
  const options = [
    {
      key: "withdraw",
      text: "Retirar fondos",
      value: "withdraw",
    },
    {
      key: "transfer",
      text: "Transferir fondos",
      value: "transfer",
    },
    {
      key: "details",
      text: "Detalles de la cuenta",
      value: "details",
    },
  ];

  if (account && account.short_name === "pagodirectoganancias") {
    return options.filter((option) => option.key !== "transfer");
  }

  return options;
};

const formatRequestData = (state, customer) => {
  const {
    from_date,
    end_date,
    type,
    currency,
    transaction_tag,
    counterpart_id,
    commerce_counterpart_id,
  } = state.searchParams;
  const { limit, skip } = state.pagination;
  return {
    from_date,
    end_date,
    limit,
    skip,
    type,
    currency,
    transaction_tag,
    customer_id: customer,
    counterpart_id,
    commerce_counterpart_id,
  };
};

export const getMovementsRequest = async (
  customer,
  state,
  changeState,
  throwPopupMessage
) => {
  try {
    changeState({ loadingMovements: true });
    const payload = formatRequestData(state, customer);
    const response = await superadminCommerceTransactions(payload);
    const res = await response.json();
    if (res.error) throw res.error;
    const nextPageExist = res.transactions.length > state.movementsPerPage;

    changeState({
      pagination: {
        ...state.pagination,
        nextPageExist,
      },
      movements: nextPageExist
        ? [...res.transactions.slice(0, state.movementsPerPage)]
        : [...res.transactions],
      loadingMovements: false,
      getMovements: false,
    });
  } catch (error) {
    changeState({
      loadingMovements: false,
      getMovements: false,
    });
    if (
      error.id === "NO_TOKEN_PROVIDED" ||
      error.id === "NO_TOKEN_PROVIDED" ||
      error.id === "DUPLICATE_SESSION"
    ) {
      throwPopupMessage("error", errors.AUTHENTICATION);
    } else {
      throwPopupMessage(
        "error",
        error.message ||
          error.description ||
          "Ha ocurrido un error intente más tarde"
      );
    }
  }
};

export const getWithdrawPreview = async (
  state,
  changeState,
  throwPopupMessage
) => {
  try {
    changeState({ loadingResult: true });
    const response = await post(enpoints.withdrawPreview, {
      commerce_id: state.commerce_id,
      currency: "BS",
      is_query: true,
    });

    const res = await response.json();
    if (res.error) throw res.error;
    if (!response.ok) {
      throw (
        res.message ||
        res.description ||
        "Ha ocurrido un error retirando los fondos"
      );
    }
    changeState({ result: res, loadingResult: false });
  } catch (error) {
    changeState({ loadingResult: false });
    console.log({ error });
    throwPopupMessage(
      "error",
      error.message ||
        error.description ||
        "Ha ocurrido un error intente más tarde"
    );
  }
};

export const getWithdraw = async (state, changeState, throwPopupMessage) => {
  try {
    changeState({ loadingResult: true });
    const response = await post(enpoints.withdraw, {
      commerce_id: state.commerce_id,
      currency: "BS",
      is_query: false,
    });
    const res = await response.json();
    if (res.error) throw res.error;
    if (!response.ok) {
      throw (
        res.message ||
        res.description ||
        "Ha ocurrido un error retirando los fondos"
      );
    }
    changeState({ result: res, loadingResult: false });
  } catch (error) {
    changeState({ result: null, loadingResult: false });
    throwPopupMessage(
      "error",
      error.message ||
        error.description ||
        error ||
        "Ha ocurrido un error intente más tarde"
    );
  }
};

export const postTransfer = async (
  body,
  initialState,
  changeState,
  throwPopupMessage,
  setFetchAccounts,
  changeGlobalState
) => {
  try {
    changeState({ loading: true });
    const response = await post(enpoints.transfer, body);
    const res = await response.json();
    changeState({ ...initialState });
    throwPopupMessage("success", "Transferencia realizada con éxito");
    setFetchAccounts(true);
    changeGlobalState({ getMovements: true });
    if (res.error) throw res.error;
  } catch (error) {
    changeState({ loading: false });
    throwPopupMessage(
      "error",
      error.message ||
        error.description ||
        "Ha ocurrido un error intente más tarde"
    );
  }
};
