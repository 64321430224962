import HistoryTicketsGraphModel from "./model";
import styled from "styled-components";

const HistoryTicketsGraph = styled(HistoryTicketsGraphModel)`
  #root & {
    z-index: -1;
    margin: 1.3rem;
    .title {
      margin-bottom: 1.2rem;
      font-size: 1.1rem;
      color: #5b5b5b;
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  @media (max-width: 768px) {
    #root & .title {
      text-align: center;
    }
  }
`;
export default HistoryTicketsGraph;
