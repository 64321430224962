export const blue = "#0899CB";

export const blue_2 = "#08789E";

export const blue_3 = "#00B1FF";

export const blue_4 = "#00FFBB";

export const blue_5 = "#0AC1FF";

export const blue_6 = "#2596BE";

//export const bluePD = "#06a3e5";
//export const bluePD = "#06a3e5";
//export const bluePD = "#06a3e5";
//export const bluePD = "#06a3e5";
export const bluePD = "#365AF6";

export const green = "#ABC324";

export const green_2 = "#95B000";

export const green_3 = "#ABD05A";

export const green_4 = "#64FF64";

export const green_5 = "#58EC7C";

export const green_6 = "#84c43a";

export const grey_bg = "#f8f4fc";

export const grey_1 = "whitesmoke";

export const grey_2 = "#ededed";

export const grey_3 = "lightgrey";

export const grey_4 = "#abacad";

export const grey_5 = "#979797";

export const grey_6 = "dimgrey";

export const gold_1 = "#EEE68D";

export const gold_2 = "#CAA338";

export const purplePD = "#D4249C";

export const purplePD_2 = "#E434AC";
//export const purplePD = "#BB0099";
export const magenta = "#CE4C7E";

export const red = "#EE2B2B";

export const red_2 = "#D12929";

export const textualBlue = "#2c304d";

export const whitesmoke = "whitesmoke";

export const yellow = "#FFAF00";

export const yellow_2 = "#E89F00";

export const yellow_3 = "#ECD414";

export const orange = "#FF8D0C";

export const magenta_2 = "#CE4C7E";

export const posDirectoBlue = "#365AF6";

export const posDirectoBlueHover = "#2141E1";
