import ResponsiveColumnModel from "./model";
import styled from "styled-components";
import { grey_4 } from "assets/strings/colors";

const ResponsiveColumn = styled(ResponsiveColumnModel)`
  #root & {
    margin: 0;
    padding: 0;
    border-right: ${(props) =>
      props.withBorder ? `1px solid ${grey_4}` : "none"};
    display: ${(props) => (props.show ? "block" : "none")};
    width: calc(100% - 1px);
    flex-direction: column;
    height: 100%;
    @media (min-width: 991px) {
      display: flex;
    }
  }
`;
export { ResponsiveColumn };
