import React from "react";
import { Grid } from "semantic-ui-react";
import { PermissionsValidator } from "components/permissions-validator";
import { RightBorderGreyColumn } from "components/styled-grid-column";
import PopupMessage from "components/popup-message";
import { formatUserList } from "../components/user-list-item";
import { NotificationsPanel } from "../components/notification-panel";
import { SearchByUser } from "../components/search-by-user";
import { SearchByTickets } from "../components/search-by-tickets";
import { SwitchSlider } from "components/checkboxes";

const MPosUsersNotificationsView = ({
  viewState,
  viewType,
  users,
  selectedUsers,
  loading,
  searchType,
  searchParam,
  tickets,
  pd_fee,
  screenOptions,
  logosOptions,
  iva,
  allSelection,
  handleSearchTickets,
  handleTicketSelection,
  handleSearchType,
  handleSearchUser,
  handleSearchInputChange,
  handleUserSelection,
  handleUserUnselection,
  handleAllTicketsSelection,
  handleAllUsersSelection,
  location,
  throwPopupMessage,
  popupMessages,
  popupMessagesType,
  showPopup,
  closePopup,
}) => {
  return (
    <Grid padded>
      <PermissionsValidator route={location.pathname} />
      <PopupMessage
        messageType={popupMessagesType}
        showPopup={showPopup}
        messages={popupMessages}
        closePopup={closePopup}
      />
      <RightBorderGreyColumn phone={8} tablet={8} computer={6}>
        <SwitchSlider
          margin="0 0 .5rem 0"
          selected={searchType}
          options={[
            { data: "by_user", displayData: "Buscar por usuario" },
            { data: "by_tickets", displayData: "Tickets de hoy" },
          ]}
          handleSelection={handleSearchType}
        />
        {searchType === "by_user" && (
          <SearchByUser
            searchParam={searchParam}
            handleSearchInputChange={handleSearchInputChange}
            viewState={viewState}
            users={users}
            formatUserList={formatUserList}
            handleUserSelection={handleUserSelection}
            handleSearchUser={handleSearchUser}
            handleAllUsersSelection={handleAllUsersSelection}
            loading={loading}
            allSelection={allSelection}
          />
        )}
        {searchType === "by_tickets" && (
          <SearchByTickets
            tickets={tickets}
            pd_fee={pd_fee}
            iva={iva}
            loading={loading}
            handleSearchTickets={handleSearchTickets}
            handleTicketSelection={handleTicketSelection}
            handleAllTicketsSelection={handleAllTicketsSelection}
            allSelection={allSelection}
          />
        )}
      </RightBorderGreyColumn>
      <Grid.Column phone={8} tablet={8} computer={10}>
        <NotificationsPanel
          users={selectedUsers}
          handleUserUnselection={handleUserUnselection}
          throwPopupMessage={throwPopupMessage}
          viewType={viewType}
          screenOptions={screenOptions}
          logosOptions={logosOptions}
          generalLoading={loading}
        />
      </Grid.Column>
    </Grid>
  );
};

export default MPosUsersNotificationsView;
