import React from "react";
import { Button } from "components/buttons";
import { formatPrice } from "services/utils";
import { commerces_admin as viewTexts } from "assets/strings/texts";
import { useStateManager } from "hooks/use-state-manager";

import { Loader } from "components/loader";
import moment from "moment";
import "moment/locale/es-us";
moment.locale("es-us");

const WithdrawValidatorFormModel = ({
  withdrawSelected,
  onSubmit,
  loading,
  className,
}) => {
  const initialState = {
    reference: withdrawSelected.transferReference || "",
    payDate: moment().format("YYYY-MM-DDTHH:mm:ss.sssZ"),
    billId: withdrawSelected.bill_ref && withdrawSelected.bill_ref._id,
    withdrawId: withdrawSelected._id,
  };
  const [state, changeState] = useStateManager(initialState);

  const handleChangeRef = (e) => {
    changeState({ reference: e.target.value });
  };
  const handleSubmitForm = (e) => {
    e.preventDefault();
    onSubmit(state);
  };
  if (loading) return <Loader />;
  return (
    <form className={className} onSubmit={handleSubmitForm}>
      <p className="header">
        Retiro por {formatPrice(withdrawSelected.pd_amount)} Bs.S de @
        {withdrawSelected.transaction_owner}{" "}
      </p>
      <div className="input-section">
        <label htmlFor="pay-ref">{viewTexts.paymentFormPayRef}</label>
        <input
          id="pay-ref"
          type="text"
          name="payRef"
          required={true}
          autoComplete="off"
          placeholder="1200345123"
          value={state.reference}
          disabled={withdrawSelected.transferReference}
          onChange={handleChangeRef}
        />
      </div>
      <Button color="blue">Aprobar</Button>
    </form>
  );
};

export { WithdrawValidatorFormModel };
