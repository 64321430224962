import { ItemContentModel } from "./model";
import styled from "styled-components";

const ItemContent = styled(ItemContentModel)`
  #root & {
    > .title {
      font-size: clamp(0.9rem, 1vw, 1.3rem);
      text-transform: uppercase;
      font-weight: bold;
      color: #808080;
      text-align: ${(props) => {
        let align = props.align ? props.align : "center";
        return align;
      }};
    }
    > .content {
      margin-top: -1rem;
      font-size: clamp(1rem, 1vw, 1.5rem);
      text-align: ${(props) => {
        let align = props.align ? props.align : "center";
        return align;
      }};
    }
  }
`;

export { ItemContent };
