import styled from "styled-components";
import { AccessListItemModel } from "./model";
import { grey_4, textualBlue, grey_1, green } from "assets/strings/colors";

const AccessListItem = styled(AccessListItemModel)`
  #root & {
    cursor: pointer;
    display: flex;
    border-radius: 1rem;
    align-content: center;
    justify-content: space-between;
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-bottom: 1px solid ${grey_4};
    margin: 0;
    &::before {
      content: "";
      width: 5px;
      background: ${(props) => (props.status === "inactive" ? grey_4 : green)};
      border-radius: 1rem;
    }
    > .data {
      margin-left: 1rem;
      width: calc(100% - 65px - 2rem);
      > p {
        margin: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        > .label {
          display: inline-block;
          margin-right: 0.5rem;
          font-size: 0.9rem;
          font-weight: bold;
        }
        > .mac-address {
          font-size: 1.2rem;
          color: ${textualBlue};
        }
      }
    }
    > .buttons {
      display: flex;
      justify-content: space-between;
      > button {
        margin: 0;
        width: 60px;
        font-size: 0.8rem;
      }
      :hover {
        background: ${grey_1};
      }
    }
  }
`;
export { AccessListItem };
