import io from "socket.io-client";
import runtimeEnv from "@mars/heroku-js-runtime-env";
const env = runtimeEnv();

const socket = io(env.REACT_APP_SOCKET_URL, {
  transports: ["websocket"],
});

socket.on("connect", () => {
  socket.sendBuffer = [];
});

export const initiateSocket = () => {
  //socket = io(env.REACT_APP_SOCKET_URL);

  console.log("new socket connection");
  // Remove buffered messages
};

export const joinSocket = (token, cb) => {
  socket.on("join", (msg) => cb(msg));
  socket.emit("join", { token: token });
};

export const joinCommerceSocket = (token, cb) => {
  /*   if (!socket) {
    socket = io(env.REACT_APP_SOCKET_URL);
    console.log(`Connecting socket...`);
  } */

  socket.on("join commerce", (msg) => cb(msg));
  socket.emit("join commerce", { token: token });
};

export const addSocketListener = (event, cb) => {
  socket.on(event, (msg) => {
    return cb(msg);
  });
};

export const removeSocketListener = (event = false, cb = false) => {
  if (!event && !cb) {
    socket.off();
  } else {
    if (typeof event === "string" && typeof cb === "function") {
      socket.off(event, (msg) => {
        return cb(msg);
      });
    } else if (typeof event === "string") {
      socket.off(event);
    }
  }
};

export const markTicketAsPaid = (
  token,
  { ticket_number, username, building }
) => {
  /*   if (!socket) {
    socket = io(env.REACT_APP_SOCKET_URL);
    console.log(`Connecting socket...`);
  } */

  socket.emit("mark as paid", {
    username: username,
    ticket: ticket_number,
    token: token,
    building: building._id,
  });
};

export const historyMarkTicketAsPaid = (
  token,
  { ticket_number, username, building, paid_at, enable_ticket_type }
) => {
  /*   if (!socket) {
    socket = io(env.REACT_APP_SOCKET_URL);
    console.log(`Connecting socket...`);
  }
 */
  socket.emit("mark as paid", {
    username: username,
    ticket: ticket_number,
    token: token,
    building: building._id,
    paid_at,
    enable_ticket_type: enable_ticket_type,
  });
};

export const calculateTicketAmount = (building, { _id }, token) => {
  /*   if (!socket) {
    socket = io(env.REACT_APP_SOCKET_URL);
    console.log(`Connecting socket...`);
  } */

  socket.emit("calculate amount", {
    building,
    ticket: _id,
    token,
  });
};

export const digitizeTicket = (token, ticketData) => {
  /*   if (!socket) {
    socket = io(env.REACT_APP_SOCKET_URL);
    console.log(`Connecting socket...`);
  } */

  socket.emit("digitized ticket", {
    ...ticketData,
    token: token,
  });
};

export const commerceDigitizeTicket = (token, ticketData, autoPaid) => {
  /*   if (!socket) {
    socket = io(env.REACT_APP_SOCKET_URL);
    console.log(`Connecting socket...`);
  } */

  socket.emit("digitized ticket", {
    ...ticketData,
    token: token,
    enable_ticket: autoPaid,
  });
};

export const disconnectSocket = () => {
  //socket.disconnect();
};
