import { useState } from "react";

const useDropdown = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const [key, setKey] = useState("");
  return {
    value,
    key,
    setValue,
    reset: () => setValue(""),
    bind: {
      value,
      onChange: (event, data) => {
        event.preventDefault();
        setValue(data.value);
        data.options.forEach((option) => {
          if (option.value === data.value) {
            setKey(option.key);
          }
        });
      },
    },
  };
};

export { useDropdown };
