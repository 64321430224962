import styled from "styled-components";
import MobilePaidsFormModel from "./model";
import { grey_4 } from "assets/strings/colors";

const MobilePaidsForm = styled(MobilePaidsFormModel)`
  #root & {
    padding: 1rem;
    border-bottom: 1px solid ${grey_4};
    ${(props) => (props.phone ? null : "height: 27vh;")}
    ${(props) => (props.phone ? null : "min-height: 240px;")}
    > .values-inputs {
      > label {
        font-size: 0.9rem;
        margin: 0 0 0.6rem 0;
        font-weight: bold;
        text-transform: uppercase;
        width: 100%;
      }
      padding: 0.5px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    > button {
      margin: 0;
    }

    > .label {
      font-size: 0.9rem;
      margin: 0 0 0.6rem 0;
      font-weight: bold;
      text-transform: uppercase;
    }

    > .container {
      min-height: 4rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0;

      > button {
        margin: 0 0.5rem 0.6rem 0.5rem;
      }
    }

    @media (max-width: 768px) {
      > .container {
        flex-wrap: wrap;

        > button {
          width: calc(50% - 1rem);
        }
      }
    }
  }
`;
export default MobilePaidsForm;
