import styled from 'styled-components'
import { InvoiceModel, InvoicePhoneModel } from './model'
import { grey_1, grey_3 } from 'assets/strings/colors'

const Invoice = styled(InvoiceModel)`
  #root &{
    >.buttons{
      display: flex;
      margin: 1.3rem;
      >button{
        margin: .5rem;
      }
    }
    >hr{
      border: none;
      border-top: 1px solid ${grey_3};
      margin: .1px;
    }
    >.commerceFee{
      background: ${grey_1};
      >h4{
        border-bottom: 1px solid ${grey_3};
        padding-bottom: .3rem;
        margin-bottom: .2rem;
        text-transform: uppercase;
        font-size: 1.1rem;
        font-weight: bold;
      }
      >p{
        text-align: right;
      }
    }
    >.header{
      >.isologo-pd{
        vertical-align: top;
        width: 50%;
        padding-right: 6rem;
      }
      >.header-data{
        display: inline-table;
        font-size: .9rem;
        border-spacing: .5rem 0;
        width: 50%;
        >tbody>tr>th{
          border-bottom: 1px solid ${grey_3};
          text-transform: uppercase;
          font-size: .85rem;
          font-weight: bold;
          vertical-align: bottom;
        }
        >tbody>tr>td{
          text-align: right;
          width: 50%;
        }
      }
    }

    >.invoice{
      table{
        width: 100%;
        border-spacing: 0;
        line-height: 2rem;
        >thead>tr>td{
          border-bottom: 1px solid ${grey_3};
          text-transform: uppercase;
          font-size: 1.1rem;
          font-weight: bold;
        }
        >tbody>tr:last-child>td{
          border-bottom: 1px solid ${grey_3};
          height: 2rem;
        }
        >tfoot>tr>.title{
          text-transform: uppercase;
          font-size: 1.1rem;
          font-weight: bold;
        }
      }
    }
  }

  #root &.invoice-appear {
    opacity: 0;
    transform: translateX(30px);
  }
  #root &.invoice-appear-active {
    opacity: .8;
    transform: translateX(0px);
    transition: all 350ms ease-out;
  }
  #root &.invoice-enter {
    opacity: 0;
    transform: translateX(30px);
  }
  #root &.invoice-enter-active {
    opacity: 1;
    transform: translateX(0px);
    transition: all 300ms ease-out;
  }
  #root &.invoice-enter-done {
    transform: none;
    transition: all 50ms ease-out;
  }
  #root &.invoice-exit {
    opacity: .8;
    transform: translateX(-2px);
  }
  #root &.invoice-exit-active {
    opacity: 0;
    transform: translateX(30px);
    transition: all 350ms ease-out;
  }

`
const InvoicePhone = styled(InvoicePhoneModel)`
  #root &{
    >.buttons{
      display: flex;
      margin: 1.3rem;
      >button{
        margin: .5rem;
      }
    }
    >hr{
      border: none;
      border-top: 1px solid ${grey_3};
      margin: .1px;
    }
    >.commerceFee{
      background: ${grey_1};
      >h4{
        border-bottom: 1px solid ${grey_3};
        padding-bottom: .3rem;
        margin-bottom: .2rem;
        text-transform: uppercase;
        font-size: 1.1rem;
        font-weight: bold;
      }
      >p{
        text-align: right;
      }
    }
    >.header{
      >.isologo-pd{
        vertical-align: top;
        width: 50%;
        min-width: 170px;
        padding-right: 4rem;
        margin-bottom: 1rem;
      }
      >.header-data{
        display: inline-table;
        font-size: .9rem;
        border-spacing: .5rem 0;
        width: 100%;
        >tbody>tr>th{
          border-bottom: 1px solid ${grey_3};
          text-transform: uppercase;
          font-size: .85rem;
          font-weight: bold;
          vertical-align: bottom;
        }
        >tbody>tr>td{
          text-align: right;
          width: 50%;
        }
      }
    }

    div>.invoice-item{
      border-bottom: 1px solid ${grey_3};
      margin-bottom: 0.5rem;
      padding-bottom: 0.5rem;
      >.title{
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: bold;
        margin: 0;
      }
      >p{
        min-height: 1rem;
        >b{
          display: inline-block;
          margin-right: .2rem;
        }
        >.amount{
          float: right;
        }
      }
    }
  }

  #root &.invoice-appear {
  opacity: 0;
  transform: translateX(30px);
  }
  #root &.invoice-appear-active {
    opacity: .8;
    transform: translateX(0px);
    transition: all 350ms ease-out;
  }
  #root &.invoice-enter {
    opacity: 0;
    transform: translateX(30px);
  }
  #root &.invoice-enter-active {
    opacity: 1;
    transform: translateX(0px);
    transition: all 300ms ease-out;
  }
  #root &.invoice-enter-done {
    transform: none;
    transition: all 50ms ease-out;
  }
  #root &.invoice-exit {
    opacity: .8;
    transform: translateX(-2px);
  }
  #root &.invoice-exit-active {
    opacity: 0;
    transform: translateX(30px);
    transition: all 350ms ease-out;
  }
`

export { Invoice, InvoicePhone }