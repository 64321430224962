import React, { useContext, useEffect } from "react";
import { Dropdown } from "components/dropdown";
import { AdminTransfersContext } from "../../context";
import { useDropdown } from "hooks/use-dropdown";
import { dropdownData } from "../../constants/constants";
import { useStateManager } from "hooks/use-state-manager";

const getTypeOfTransfer = (bankDetails) => {
  const bankCode =
    bankDetails &&
    bankDetails.account_number &&
    bankDetails.account_number.substring(0, 4);
  if (bankCode === "0172") return "third-bancamiga";
  return "third-other";
};

export const TypeofTransferModel = ({ className }) => {
  const {
    state: { commerces },
    changeState,
    withdraw,
  } = useContext(AdminTransfersContext);

  const initialData = {
    loading: false,
    selectionState: "type_of_transfer",
    commercesOptions: [{ value: "", key: "", text: "" }],
    selectedCommerce: null,
    accountsOptions: [{ value: "", key: "", text: "" }],
    bankAccounts: [],
    selectedAccount: null,
    showAccountsOptions: false,
    thisBankCode: "0172",
  };
  const typeofTransferOptions = [...dropdownData.typeofTransferOptions];

  const transferTitle =
    "Seleccione el tipo de transferencia que desea realizar:";
  const commercesTitle = "Seleccione comercio a transferir:";
  const accountsTitle = "Seleccione la cuenta a transferir:";
  const viewStates = {
    "third-bancamiga": "show_complete_transfer",
    "third-other": "show_complete_transfer",
    wallet: "show_simple_transfer",
    service: "show_simple_transfer",
    default: "show_simple_transfer",
  };
  const [dataState, changeDataState] = useStateManager(initialData);
  const {
    bind: { onChange: onChangeTransfer, value: typeofTransferSelected },
  } = useDropdown(
    withdraw.bank_details ? getTypeOfTransfer(withdraw.bank_details) : "wallet"
  );

  const onChangeCommerce = (event, data) => {
    event.preventDefault();
    changeDataState({ selectedCommerce: data.value });
  };
  const onChangeAccount = (event, data) => {
    event.preventDefault();
    changeDataState({ selectedAccount: data.value });
  };

  useEffect(() => {
    changeState({
      viewState: viewStates[typeofTransferSelected],
      typeofTransferSelected,
    });
    const thisBankTransfer = typeofTransferSelected === "third-bancamiga";
    const otherBankTransfer = typeofTransferSelected === "third-other";
    const validRender = thisBankTransfer || otherBankTransfer;
    const getOptions = (thisBank = true) => {
      let commercesOptions = [];
      commerces.forEach((commerce, index) => {
        commerce.bank_accounts.forEach((account) => {
          const sameBank =
            account.bank_code === dataState.thisBankCode && thisBank;
          const diffBank =
            account.bank_code !== dataState.thisBankCode && !thisBank;
          if (sameBank || diffBank) {
            let commerceExist = false;
            commercesOptions.forEach((commerceOption) => {
              if (commerceOption.key === commerce._id) commerceExist = true;
            });
            if (!commerceExist) {
              const isParking =
                commerce && commerce.parking && commerce.parking.building;

              const commerceName = commerce.custom_name;

              commercesOptions.push({
                value: commerce._id ? commerce._id : index,
                key: commerce._id ? commerce._id : index,
                text: isParking ? isParking.name : commerceName,
              });
            }
          }
        });
      });
      return commercesOptions;
    };
    if (validRender) {
      const commercesOptions = getOptions(thisBankTransfer);

      changeDataState({
        commercesOptions,
        selectionState: "commerce_selection",
      });
    } else {
      changeDataState(initialData);
      changeState({ cleanup: true });
    }

    return () => {};
  }, [typeofTransferSelected]);

  useEffect(() => {
    if (dataState.selectedCommerce) {
      const thisBankTransfer = typeofTransferSelected === "third-bancamiga";
      const otherBankTransfer = typeofTransferSelected === "third-other";
      const validRender = thisBankTransfer || otherBankTransfer;
      const getOptions = (thisBank = true) => {
        let accountsOptions = [];
        commerces.forEach((commerce) => {
          if (commerce._id === dataState.selectedCommerce) {
            commerce.bank_accounts.forEach((account, index) => {
              const sameBank =
                account.bank_code === dataState.thisBankCode && thisBank;
              const diffBank =
                account.bank_code !== dataState.thisBankCode && !thisBank;
              if (sameBank || diffBank) {
                let accountExist = false;
                accountsOptions.forEach((accountOption) => {
                  if (accountOption.key === account.account_number)
                    accountExist = true;
                });
                if (!accountExist) {
                  accountsOptions.push({
                    value: account.account_number
                      ? account.account_number
                      : index,
                    key: account.account_number
                      ? account.account_number
                      : index,
                    text: account.bank_name,
                  });
                }
              }
            });
          }
        });
        return accountsOptions;
      };
      if (validRender) {
        const accountsOptions = getOptions(thisBankTransfer);
        changeDataState({
          accountsOptions,
          selectionState: "account_selection",
        });
        changeState({ cleanup: true });
      }
    }
  }, [dataState.selectedCommerce]);

  useEffect(() => {
    if (dataState.selectedAccount) {
      const thisBankTransfer = typeofTransferSelected === "third-bancamiga";
      const otherBankTransfer = typeofTransferSelected === "third-other";
      const validRender = thisBankTransfer || otherBankTransfer;
      if (validRender) {
        let commerceToShow = commerces.find(
          (commerce) => commerce._id === dataState.selectedCommerce
        );
        let account = commerceToShow.bank_accounts.find(
          (account) => account.account_number === dataState.selectedAccount
        );
        changeState({
          receiver_name: (account && account.account_name) || "",
          receiver_account: (account && account.account_number) || "",
          typeofDoc: (account && account.doc_type) || "",
          receiver_document_number: (account && account.doc_id) || "",
          renderInputs: true,
          cleanup: false,
        });
      }
    }
  }, [dataState.selectedAccount, dataState.selectedCommerce]);

  const componentArray = [
    <div className="input-selection" key="typeOfTransfer">
      <p className="title">{transferTitle}</p>
      <Dropdown
        fluid
        floating
        selection
        id="typeofTransfer"
        value={typeofTransferSelected}
        onChange={onChangeTransfer}
        options={typeofTransferOptions}
      />
    </div>,
    <div className="input-selection" key={"commerces"}>
      <p className="title">{commercesTitle}</p>
      <Dropdown
        fluid
        floating
        search
        selection
        id="commerces"
        value={dataState.selectedCommerce}
        onChange={onChangeCommerce}
        options={dataState.commercesOptions}
      />
    </div>,
    <div className="input-selection" key={"accounts"}>
      <p className="title">{accountsTitle}</p>
      <Dropdown
        fluid
        floating
        selection
        id="accounts"
        value={dataState.selectedAccount}
        onChange={onChangeAccount}
        options={dataState.accountsOptions}
      />
    </div>,
  ];
  const componentsToShow = {
    type_of_transfer: componentArray[0],
    commerce_selection: [componentArray[0], componentArray[1]],
    account_selection: [...componentArray],
  };

  const drop = componentsToShow[dataState.selectionState];
  if (withdraw && withdraw.transaction_owner)
    return (
      <div className={className}>
        <p className="withdraw">
          Retiro de fondos solicitado por:
          <span className="withdraw-owner">{` @${withdraw.transaction_owner}`}</span>
        </p>
      </div>
    );
  return <div className={className}>{drop}</div>;
};
