import { GreenButtonModel } from "./model";
import styled from "styled-components";
import { green_6, grey_4 } from "assets/strings/colors";

const GreenButton = styled(GreenButtonModel)`
  #root && {
    margin: 0;
    margin-bottom: 0.5rem;
    align-self: left;
    width: calc(25% - 1rem);
    height: 25px;
    background: ${(props) => (props.isSelected ? grey_4 : green_6)};
    color: white;
    font-size: 0.9rem;
    border: none;
    border-radius: 2rem;
    :hover {
      cursor: pointer;
      ${(props) => (props.isSelected ? "" : "opacity: 0.7")}
    }
  }
`;

export { GreenButton };
