import React, { Component } from "react";
import {
  commercesSignup,
  chekExistencesSignup,
  getBuildings,
} from "services/api";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { errors } from "assets/strings/texts";
import SignupView from "./components/signup-view";
/* import { sendEmailVerification } from "services/api"; */
import { addToken, addUser } from "redux/actions/session";
import { throwPopupMessage } from "redux/actions/popup-messages";
import { addCommerces, selectCommerce } from "redux/actions/commerces";
import {
  handleLoginChange,
  handlePhoneChange,
  handleNewPasswordChange,
  handleNumberChange,
  handleUsernameChange,
  handleShortNameChange,
  handleCheckBox,
  handleDropdown,
  handleChange,
  handlePrimaryColorChange,
  handleSecondaryColorChange,
  firstFormComplete,
} from "components/register-user-form-inputs";

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewState: "first_form",
      loading: false,
      formData: {
        email: "",
        password: "",
        confirm_password: "",
        username: "",
        name: "",
        short_name: "",
        doc_type: "J",
        doc_id: "",
        phone: "",
        address: "",
        category: "none",
        pre_username: "",
        building: "",
        agree_with_terms: false,
        colors: ["#000000", "#000000"],
        custom_name: null,
        local_number: null,
      },
      signin: {
        login: "",
        password: "",
      },
      coordinate: [],
      buildings: null,
      passwordStrength: "",
      redirect: null,
      parkingDesired: false,
    };
    // Bind handler functions
    this.handleLoginChange = handleLoginChange.bind(this);
    this.handleNewPasswordChange = handleNewPasswordChange.bind(this);
    this.handleNumberChange = handleNumberChange.bind(this);
    this.handleUsernameChange = handleUsernameChange.bind(this);
    this.handleShortNameChange = handleShortNameChange.bind(this);
    this.handlePhoneChange = handlePhoneChange.bind(this);
    this.handleCheckBox = handleCheckBox.bind(this);
    this.handleDropdown = handleDropdown.bind(this);
    this.handleChange = handleChange.bind(this);
    this.handlePrimaryColorChange = handlePrimaryColorChange.bind(this);
    this.handleSecondaryColorChange = handleSecondaryColorChange.bind(this);
    this.firstFormComplete = firstFormComplete.bind(this);
  }
  handleEnableParking = (event) => {
    this.setState({ parkingDesired: !this.state.parkingDesired });
  };
  handleBuildingChange = (event, data) => {
    let coordinates;
    this.state.buildings.forEach((building) => {
      if (building._id === data.value) {
        coordinates = building.loc.coordinates;
      }
    });
    this.setState((prevState) => {
      return {
        formData: {
          ...prevState.formData,
          building: data.value,
          coordinates,
        },
      };
    });
  };

  validate = async () => {
    let { email, username, short_name, pre_username } = this.state.formData;
    let isValid = false;
    this.setState({ loading: true });
    const response = await chekExistencesSignup({
      email,
      username,
      short_name,
      pre_username,
    });
    try {
      if (!response.ok) {
        throw response;
      } else {
        this.setState({ loading: false });
        isValid = true;
      }
    } catch (err) {
      isValid = false;
      if (typeof err.json === "function") {
        const err_body = await err.json();
        if (err_body.error.id === "USER_ALREADY_EXIST") {
          let usedVar = err_body.error.extra;
          let message = "El";
          let count = 0;
          if (usedVar.email) {
            message += " correo electrónico";
            count += 1;
          }
          if (usedVar.username) {
            if (count > 0) message += ",";
            message += " nombre de usuario";
            count += 1;
          }
          if (usedVar.pre_username) {
            if (count > 0) message += ",";
            message += " prefijo del comercio";
            count += 1;
          }
          if (usedVar.short_name) {
            if (count > 0) message += ",";
            message += " pseudónimo";
            count += 1;
          }
          message += " ya está siendo utilizado";
          this.setState({ loading: false });
          this.props.throwPopupMessage("error", message);
        }
      } else {
        this.setState({ loading: false });
        this.props.throwPopupMessage("error", errors.GENERAL_ERR);
      }
    }
    return isValid;
  };

  handleBack = (event) => {
    event.preventDefault();
    switch (this.state.viewState) {
      case "second_form":
        this.setState({ viewState: "first_form" });
        break;
      case "third_form":
        this.setState({ viewState: "second_form" });
        break;
      default:
        break;
    }
  };
  // response is the apiCall promise
  handleNextView = async (event) => {
    event.preventDefault();
    /* let { ...formData } = this.state.formData; */
    switch (this.state.viewState) {
      case "first_form":
        let [isComplete, errorMessage] = this.firstFormComplete();
        try {
          let isValid = await this.validate();
          if (isComplete && isValid) {
            this.setState({ viewState: "second_form" });
          } else {
            if (errorMessage) {
              this.setState({ loading: false });
              this.props.throwPopupMessage("error", errorMessage);
            }
            throw errorMessage;
          }
        } catch (err) {
          this.setState({ viewState: "first_form" });
        }
        break;
      case "second_form":
        this.setState({ viewState: "third_form" });
        break;
      default:
        break;
    }
  };

  componentDidMount() {
    this.setState({ loading: true });
    getBuildings()
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((res) => {
        // if token save it in redux store
        if (res.buildings) {
          this.setState({
            loading: false,
            buildings: res.buildings,
          });
        }
      })
      .catch((err) => {
        // If error has a body, check the response
        if (typeof err.json === "function") {
          err.json().then((err_body) => {
            this.setState({ loading: false });
            this.props.throwPopupMessage("error", err_body.error.description);
          });
        } else {
          this.setState({ loading: false });
          this.props.throwPopupMessage("error", errors.GENERAL_ERR);
        }
      });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let { ...formData } = this.state.formData;
    // Assign the loading state to the form
    this.setState({ loading: true });
    commercesSignup(formData)
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((res) => {
        // if token save it in redux store
        if (res.token) this.props.addToken(res.token);
        // if user
        if (res.user) {
          // save user to redux store
          let redirect = null;
          let commerces = [res.user.commerce_associated];
          this.props.addCommerces(commerces);
          this.props.selectCommerce(res.user.commerce_associated._id);
          this.props.addUser({
            username: res.user.username,
            email: res.user.email,
            role: "",
          });
          redirect = <Redirect to="/signin" />;
          this.setState(
            {
              loading: false,
              redirect,
            },
            () =>
              this.props.addUser({
                ...res.user,
                role: res.user.commerce_role.name,
              })
          );
          this.props.throwPopupMessage(
            "success",
            "Ha sido registrado satisfactoriamente."
          );
        }
      })
      .catch((err) => {
        // If error has a body, check the response
        if (typeof err.json === "function") {
          err.json().then((err_body) => {
            this.setState({ loading: false });
            this.props.throwPopupMessage("error", err_body.error.description);
          });
        } else {
          this.setState({ loading: false });
          this.props.throwPopupMessage("error", errors.GENERAL_ERR);
        }
      });
  };
  render() {
    return (
      <SignupView
        // props
        viewState={this.state.viewState}
        loading={this.state.loading}
        passwordStrength={this.state.passwordStrength}
        buildings={this.state.buildings}
        formData={this.state.formData}
        redirect={this.state.redirect}
        location={this.props.location}
        parkingDesired={this.state.parkingDesired}
        customName={this.state.custom_name}
        localNumber={this.state.local_number}
        // methods
        handlePrimaryColorChange={this.handlePrimaryColorChange}
        handleSecondaryColorChange={this.handleSecondaryColorChange}
        handleBack={this.handleBack}
        handleBuildingChange={this.handleBuildingChange}
        handleChange={this.handleChange}
        handleEnableParking={this.handleEnableParking}
        handleNextView={this.handleNextView}
        handleLoginChange={this.handleLoginChange}
        handleUsernameChange={this.handleUsernameChange}
        handleShortNameChange={this.handleShortNameChange}
        handleDropdown={this.handleDropdown}
        handleNumberChange={this.handleNumberChange}
        handlePhoneChange={this.handlePhoneChange}
        handleNewPasswordChange={this.handleNewPasswordChange}
        handleCheckBox={this.handleCheckBox}
        handleSubmit={this.handleSubmit}
      />
    );
  }
}

const mapStateToProps = null;
const mapDispatchToProps = {
  addToken,
  addUser,
  addCommerces,
  selectCommerce,
  throwPopupMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
