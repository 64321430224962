import React from "react";
import { Loader } from "components/loader";
import { randomListKey } from "services/utils";
import { PremiumBundleItem, BundlesListHeader } from ".";
import okCircleIcon from "assets/img/ok-circle-icon-64px.png";
import bellCircleIcon from "assets/img/bell-circle-icon-64px.png";

export const PremiumBundleItemModel = ({
  index,
  bundle,
  handleBundleSelection,
  handleDisableBundle,
  className,
}) => {
  const bundleTitle = bundle && bundle.title ? bundle.title : "--";
  const status =
    bundle && bundle.user_info && bundle.user_info.is_active
      ? "activo"
      : "inactivo";
  return (
    <div className={className}>
      <ul id={index} className="bundle">
        <p className="col thirty" key="title">
          {bundleTitle}
        </p>

        <section className="col thirty">
          <figure className="status">
            <img
              src={status === "activo" ? okCircleIcon : bellCircleIcon}
              alt="status-img"
            />
            <figcaption className={status === "activo" ? "active" : "inactive"}>
              {status}
            </figcaption>
          </figure>
        </section>

        <div className="buttons">
          <button
            id={index}
            onClick={handleBundleSelection}
            className={status === "activo" ? "grey-b" : "green-b"}
          >
            {status === "activo" ? "Editar" : "Activar"}
          </button>
          {/*          {status === "activo" && (
            <button id={index} onClick={handleDisableBundle} className="red-b">
              Desactivar
            </button>
          )}*/}
        </div>
      </ul>
    </div>
  );
};

export const BundlesListHeaderModel = ({ className }) => {
  return (
    <dd className={className}>
      <p className="col thirty">Paquete</p>
      <p className="col thirty">Estado</p>
      <p className="col forty">Opciones</p>
    </dd>
  );
};

export function formatPremiumBundleList(
  premiumBundles,
  viewState,
  handleBundleSelection,
  loading,
  handleDisableBundle
) {
  const thereIsSomeBundle = premiumBundles.length > 0;
  let formatBundlesList = [];
  if (loading) {
    return <Loader />;
  }

  if (!loading && !thereIsSomeBundle) {
    return (
      <div className="not-found">
        No hay paquetes disponibles para este usuario
      </div>
    );
  }

  formatBundlesList.push(<BundlesListHeader key={randomListKey()} />);

  premiumBundles.forEach((bundle, index) => {
    formatBundlesList.push(
      <PremiumBundleItem
        bundle={bundle}
        handleBundleSelection={handleBundleSelection}
        handleDisableBundle={handleDisableBundle}
        key={randomListKey()}
        index={index}
      />
    );
  });

  return formatBundlesList;
}
