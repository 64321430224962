import React from "react";
import {
  currencyAmount,
  mapStatus,
  checkField,
  checkDate,
} from "services/utils";
import { recharge_validate as viewTexts } from "assets/strings/texts";

export const RefAndDateModel = ({ reference, date, className }) => {
  return (
    <div className={className}>
      <p className="reference">
        ref: <span>{reference}</span>
      </p>
      <p className="date">
        fecha: <span>{date}</span>
      </p>
    </div>
  );
};

const MobilePaidsListItemModel = ({
  mobilePaid,
  handleSelection,
  id,
  className,
}) => {
  return (
    <li className={className} onClick={handleSelection} id={id}>
      <div className="data">
        <p className="ref">
          {viewTexts.ref}
          <span className="ref-number">
            {`${checkField(mobilePaid, "reference_id")} `}
          </span>

          {" - "}
          <span className="status">
            {`${mapStatus(checkField(mobilePaid, "status"))}`}
          </span>
        </p>
        <p>
          {checkField(mobilePaid, "bank_phone")} -{" "}
          {currencyAmount(checkField(mobilePaid, "amount"), "Bs.")}
        </p>
      </div>
      <div className="time">
        {checkDate(checkField(mobilePaid, "movement_date"))} -{" "}
        {checkField(mobilePaid, "bank_name")}
      </div>
    </li>
  );
};

export const MobilePaidsListItemPhoneModel = ({
  mobilePaid,
  handleSelection,
  id,
  className,
}) => {
  return (
    <li className={className} onClick={handleSelection} id={id}>
      <h5 className="title">Referencia:</h5>
      <p className="content">{checkField(mobilePaid, "reference_id")}</p>
      <h5 className="title">Fecha:</h5>
      <p className="content">
        {checkDate(checkField(mobilePaid, "movement_date"))}
      </p>
      <h5 className="title">Banco:</h5>
      <p className="content cap">{checkField(mobilePaid, "bank_name")}</p>
      <h5 className="title">Estado:</h5>
      <p className="content">{mapStatus(checkField(mobilePaid, "status"))}</p>
      <h5 className="title">Monto:</h5>
      <p className="content">
        {currencyAmount(checkField(mobilePaid, "amount"), "Bs.")}
      </p>
    </li>
  );
};

export default MobilePaidsListItemModel;
