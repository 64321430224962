import styled from "styled-components";
import { HistoryFormModel } from "./model";
import { grey_3, grey_6 } from "assets/strings/colors";

const HistoryForm = styled(HistoryFormModel)`
  #root & {
    > .collapsed.false {
      max-height: 600px;
    }

    > .title {
      margin: 0;
      padding: 0 1rem;
      text-decoration: underline;
    }
    
    > .collapsed {
      max-height: 0;
      overflow: hidden;
      transition: max-height 450ms linear;

      > .selectors {
        display: flex;
        margin: 0.7rem 0 0 0;
        align-items: center;
        justify-content: space-between;

        > button {
          font-size: 0.85rem;
          margin: 0 0.2rem 0 0.2rem;
        }

        > button:first-child {
          margin-left: 0;
        }

        > button:last-child {
          margin-right: 0;
        }
      }

      > .date-pickers {
        margin: 0;
        margin-top: 0.7rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        > p {
          margin: 0;
          font-weight: bold;
        }

        > div {
          width: 46%;

          > .react-datepicker__input-container {
            width: 100%;

            > input {
              width: calc(100% - 16px);
              border: none;
              border-radius: 1rem;
              color: ${grey_6};
              font-size: 1.2rem;
              margin: 0.5rem 5px 0.5rem 5px;
              text-align: center;
              outline: none;
              box-shadow: none;
              transition: box-shadow 250ms linear;

              :focus {
                box-shadow: 0 1px darkgrey;
              }
            }

            > .react-datepicker__close-icon::after {
              right: 0;
            }
          }

          > .react-datepicker-wrapper {
            width: 100%;

            > .react-datepicker__input-container {
              width: 100%;

              > input {
                width: calc(100% - 16px);
                border: none;
                border-radius: 1rem;
                color: ${grey_6};
                font-size: 1.2rem;
                margin: 0.5rem 5px 0.5rem 5px;
                text-align: center;
                outline: none;
                box-shadow: none;
                transition: box-shadow 250ms linear;

                :focus {
                  box-shadow: 0 1px darkgrey;
                }
              }

              > .react-datepicker__close-icon::after {
                right: 15px;
              }
            }
          }
        }

        > div:last-child {
          > .react-datepicker-popper {
            > .react-datepicker {
              > .react-datepicker__triangle {
                right: 50px;
                left: auto;
              }
            }
          }
        }
      }

      > button {
        margin: 0.7rem 0 0 0;
      }
    }

    > .collapse-button {
      text-align: center;
      margin-top: 0.5rem;
      height: 2rem;
      line-height: 2rem;
      border-bottom: 1px solid ${grey_3};
    }
  }
`;

export { HistoryForm };
