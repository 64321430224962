import React, { useContext } from "react";
import { Grid } from "semantic-ui-react";
import { SuperadminBillsContext } from "../context";
import { PopupMessageContext } from "components/popup-message";
import { FiscalForm } from "./fiscal-form";
import { OperationsForm } from "./operations-form";
import { Directory } from "../components/directory";
import { BillPreview } from "../components/bill-preview";

const SuperadminBillsPhoneView = () => {
  const { state } = useContext(SuperadminBillsContext);

  const currentComponent = (state) => {
    if (state.showPreview) return <BillPreview />;
    if (state.showFormOnMobile & state.showNextPage) return <OperationsForm />;
    if (state.showFormOnMobile && !state.showNextPage) return <FiscalForm />;
    return <Directory />;
  };

  return (
    <Grid padded>
      <PopupMessageContext context={SuperadminBillsContext} />
      <Grid.Column width={16}>{currentComponent(state)}</Grid.Column>
    </Grid>
  );
};

export default SuperadminBillsPhoneView;
