import React, { useContext } from "react";
import { UsersExtensionsContext } from "../../context";
import { Button } from "components/buttons";
import { IconBack } from "components/icon-back";
import { EntitiesList } from "components/entities-list";
import { formatExtensionsList } from "../extensions-list-item";
import { formatMovementsList } from "../movements-list-item";
import { Loader } from "components/loader";
import { useListExtensionService } from "../../services/list-extensions-and-movements";
import { useExtensionInfoService } from "../../services/extension-info";

const UserInfoModel = ({ className }) => {
  useExtensionInfoService();
  const { loading } = useListExtensionService();

  const { state, changeState, handleBack, viewport } = useContext(
    UsersExtensionsContext
  );
  const phoneView = viewport === "phone";
  const user = state.selectedUser;

  const handleNewExtension = (event) => {
    event.preventDefault();
    let restartedInputs = {};
    Object.keys(state.inputs).forEach((key) => {
      restartedInputs = { ...restartedInputs, [key]: "" };
    });
    changeState({ inputs: { ...restartedInputs }, viewState: "new_extension" });
  };

  const handleExtensionEdit = (event) => {
    event.preventDefault();
    const index = event.currentTarget.id;
    const selectedExtension = state.extensions[index];
    changeState({
      selectedExtension,
      inputs: {
        firstName: selectedExtension.first_name || "",
        secondName: selectedExtension.second_name || "",
        firstLastname: selectedExtension.first_last_name || "",
        secondLastname: selectedExtension.second_last_name || "",
      },
      dropdownValues: {
        status: selectedExtension.status || "",
        carnet: selectedExtension.design || "",
        school: selectedExtension.commerce || "",
        is_printed: selectedExtension.is_printed || "",
      },
      viewState: "extension_selected",
    });
  };

  const handleSelectionMovement = (event) => {
    event.preventDefault();
    const index = event.currentTarget.id;
    const selectedMovement = state.movements[index];
    changeState({
      selectedMovement,
      viewState: "movement_selected",
    });
  };

  if (loading || state.getInfo) return <Loader />;
  if (!user)
    return (
      <div className={className}>
        <p className="not-found">Busque y seleccione un usuario</p>
      </div>
    );

  return (
    <div className={className}>
      <div className="user">
        <div className="left">
          {phoneView && <IconBack handleBack={handleBack} />}
        </div>
        <div className="info">
          {user.username && <p className="header">@{user.username}</p>}
          {user.profile && user.profile.mobile_phone && (
            <p className="phone"> TLF: {user.profile.mobile_phone}</p>
          )}
        </div>
        <Button
          color="green_6"
          width="20%"
          margin="0 1rem 0 -1rem"
          onClick={handleNewExtension}
        >
          Agregar Hijo
        </Button>
      </div>
      <p className="div-header"> Extensiones</p>

      <EntitiesList
        height="calc(50vh - 6rem)"
        viewState={null}
        entities={state.extensions}
        formattingFunction={formatExtensionsList}
        selectionHandler={handleExtensionEdit}
        additionalArgs={[state.loading]}
      />

      <p className="div-header"> Últimos movimientos</p>

      <EntitiesList
        height={phoneView ? "calc(50vh + 6rem)" : "calc(43vh - 6rem)"}
        viewState={null}
        entities={state.movements}
        formattingFunction={formatMovementsList}
        selectionHandler={handleSelectionMovement}
        additionalArgs={[state.loading, state.extensions]}
      />
    </div>
  );
};

export { UserInfoModel };
