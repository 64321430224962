import React from "react";
import { Panel, PanelTitle } from "components/panel";
import { Button } from "components/buttons";
import { CSSTransition } from "react-transition-group";
import { Loader } from "components/loader";
import { IconBackContent } from "components/icon-back";
import moment from "moment";
import "moment/locale/es";
moment.locale("es");

const WithdrawPreviewNew = ({
  show,
  loading,
  onWithdraw,
  onPreview,
  back,
  onBack,
}) => {
  if (loading) return <Loader margin="40px 0 0 0" />;
  if (!show) return null;
  return (
    <CSSTransition in={true} timeout={350} appear={true} classNames="panel">
      <Panel>
        <IconBackContent show={back} handleBack={onBack} />
        <PanelTitle>Opciones de retiro</PanelTitle>
        <Button color="blue" onClick={onPreview}>
          Enviar previsualización
        </Button>
        <Button color="green" onClick={onWithdraw}>
          Retirar
        </Button>
      </Panel>
    </CSSTransition>
  );
};

export default WithdrawPreviewNew;
