import { commercesSuperAutocomplete, usersAutocomplete } from "services/api";

export const search = async (state, changeState) => {
  const searchPromises = {
    commerce_counterpart_id: commercesSuperAutocomplete,
    counterpart_id: usersAutocomplete,
  };
  try {
    changeState({ loading: true });
    const response = await searchPromises[state.counterpartSelected](
      state.searchQuery,
      state.skip,
      state.limit
    );
    const res = await response.json();
    if (res.error) throw res.error;
    if (res.users)
      changeState({
        searchQuery: "",
        counterpartOptions: [
          ...res.users.map((user) => ({
            key: user._id,
            value: user._id,
            text: user.username,
          })),
          ...state.initialOptions,
        ],
      });
    if (res.commerces)
      changeState({
        searchQuery: "",
        counterpartOptions: [
          ...res.commerces.map((commerce) => ({
            key: commerce._id,
            value: commerce._id,
            text: commerce.name,
          })),
          ...state.initialOptions,
        ],
      });
    changeState({ loading: false });
  } catch (error) {
    changeState({ loading: false });
  }
};
