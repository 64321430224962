import React from 'react'
import Navbar from 'components/navbar'
import { Link } from 'react-router-dom'
import { Button } from 'components/buttons'
import { Grid, Segment } from 'semantic-ui-react'
import PopupMessage from 'components/popup-message'
import { AnchorTagButton } from 'components/buttons'
import GreatingsText from 'components/greatings-text'
import { BlueRoundButton } from 'components/round-button'
import { CodeInput, EmailInput } from 'components/register-user-form-inputs'


const EmailValidationView = ({
  loading,
  formData,
  email,
  redirect,
  popupMessages,
  showPopup,
  handleFormChange,
  handleEmailReSend,
  handleCodeSubmit,
  openChangeEmailForm,
  handleEmailUpdate,
  closePopup
}) => {

  return (
    <div>
      <Navbar
        title={'Validación de correo electrónico'}/>

      <PopupMessage
        messages={popupMessages}
        showPopup={showPopup}
        closePopup={closePopup}>
          <GreatingsText>
            <h4>Ingrese su correo electrónico</h4>
            <p style={{ fontSize: '1rem' }}>De esta forma se modificará el correo electrónico asociado a su cuenta</p>
          </GreatingsText>
          <form
          onSubmit={handleEmailUpdate}
            className={loading ? 'ui loading form' : ''}>
            <EmailInput
              onChange={handleFormChange}
              values={formData} />
            <Button
              color='blue'
              disabled={loading}>
              Cambiar Email
            </Button>
          </form>
      </PopupMessage>

      <Grid centered padded>
        <Grid.Column mobile={15} tablet={8} computer={6}>

          <GreatingsText>
            <h4>Ingrese su código de verificación</h4>
            <p style={{ fontSize: '1rem' }}>Hemos enviado un correo de verificación a <b>{email}</b>. si este no es su correo electrónico por favor haga <AnchorTagButton onClick={openChangeEmailForm}>Click aquí</AnchorTagButton></p>
          </GreatingsText>

          <form
            onSubmit={handleCodeSubmit}
            className={loading ? 'ui loading form' : ''}>
            <CodeInput
              onChange={handleFormChange}
              values={formData} />
            <BlueRoundButton fluid>Verificar Email</BlueRoundButton>
          </form>
          <Segment basic textAlign='center'>
            <p>¿Desea <Link to='/signin'>Iniciar Sesión</Link> con otro usuario?</p>
            <p>¿No ha recibido el código de verificación? <AnchorTagButton onClick={handleEmailReSend}>Reenviar código</AnchorTagButton></p>
          </Segment>
          {redirect}
        </Grid.Column>
      </Grid>
    </div>
  )
}
export default EmailValidationView