import { FirstRowModel } from "./model";
import styled from "styled-components";
import { grey_6, bluePD } from "assets/strings/colors";

const FirstRow = styled(FirstRowModel)`
  #root && {
    margin: 0;
    > .first-row-parking {
      display: flex;
      ${(props) => {
        return props.phoneView ? "flex-direction: column;" : "";
      }};
      ${(props) => {
        return props.phoneView ? "width: 100%; align-items: center;" : "";
      }};
      position: relative;
      z-index: 1;
      > .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: ${(props) => {
          return props.phoneView ? "0" : "2rem";
        }};
        background: white;
        height: ${(props) => {
          return props.phoneView ? "200px" : "175px";
        }};
        border-radius: 0.4rem;
        box-shadow: 1px 1px 4px 0 rgba(34, 36, 38, 0.4);
        width: ${(props) => {
          return props.phoneView ? "95%" : "30%";
        }};
        > .selectors {
          margin: 0;
          margin-top: 0.5rem;
          margin-bottom: -1rem;
          width: 95%;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          > .periods {
            width: 100%;
            display: flex;
            > .box {
              display: flex;
              justify-content: center;
              width: 50%;
              :hover {
                cursor: pointer;
              }
            }
            > .box-selected {
              width: 50%;
              display: flex;
              justify-content: center;
              border-radius: 0.3rem;
              background: ${grey_6};
              color: white;
              font-weight: bold;
            }
            > .box-selected-color {
              width: 50%;
              display: flex;
              justify-content: center;
              border-radius: 0.3rem;
              background: ${bluePD};
              color: white;
              font-weight: bold;
            }
          }
        }
        > .list {
          margin: 0;
          margin-top: 1rem;
          width: 100%;
        }
      }
      > .right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: ${(props) => {
          return props.phoneView ? "200px" : "175px";
        }};
        margin-left: ${(props) => {
          return props.phoneView
            ? "0"
            : props.item === "users"
            ? "2rem"
            : "1rem";
        }};
        ${(props) => {
          return props.phoneView ? "margin-top: 1rem;" : "";
        }};
        background: white;
        border-radius: 0.4rem;
        box-shadow: 1px 1px 4px 0 rgba(34, 36, 38, 0.4);
        width: ${(props) => {
          return props.phoneView
            ? "95%"
            : props.item === "users"
            ? "100%"
            : "70%";
        }};
        > .title {
          padding-top: 0.2rem;
          font-size: 1.1rem;
          color: #5b5b5b;
          text-transform: uppercase;
          font-weight: bold;
        }
      }
    }
    > .first-row-users {
      display: flex;

      ${(props) => {
        return props.phoneView ? "flex-direction: column;" : "";
      }};
      ${(props) => {
        return props.phoneView ? "width: 100%; align-items: center;" : "";
      }};
      position: relative;
      z-index: 1;
      > .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: ${(props) => {
          return props.phoneView ? "0" : "2rem";
        }};
        background: white;
        height: ${(props) => {
          return props.phoneView ? "40vh" : "30vh";
        }};
        border-radius: 0.4rem;
        box-shadow: 1px 1px 4px 0 rgba(34, 36, 38, 0.4);
        width: ${(props) => {
          return props.phoneView ? "95%" : "49%";
        }};
        > .active {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          > .title {
            width: 100%;
            border-top: 2px solid ${bluePD};
            text-align: center;
            text-transform: uppercase;
          }
          > .qty {
            margin: 0;
            text-align: center;
            text-transform: uppercase;
            font-size: 2rem;
            font-weight: bold;
          }
        }
      }
      > .right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: ${(props) => {
          return props.phoneView ? "40vh" : "30vh";
        }};
        margin-left: ${(props) => {
          return props.phoneView ? "0" : "1rem";
        }};
        ${(props) => {
          return props.phoneView ? "margin-top: 1rem;" : "";
        }};
        background: white;
        border-radius: 0.4rem;
        box-shadow: 1px 1px 4px 0 rgba(34, 36, 38, 0.4);
        width: ${(props) => {
          return props.phoneView ? "95%" : "49%";
        }};
        > .active {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;

          > .title {
            width: 100%;
            border-top: 2px solid ${bluePD};
            text-align: center;
            text-transform: uppercase;
          }
          > .qty {
            margin: 0;
            text-align: center;
            text-transform: uppercase;
            font-size: 2rem;
            font-weight: bold;
          }
        }
      }
    }
    .first-row.first-row-appear {
      opacity: 0;
      transform: translateY(30px);
    }
    .first-row.first-row-appear-active {
      opacity: 0.8;
      transform: translateY(-2px);
      transition: all 350ms ease-out;
    }
    .first-row.first-row-enter {
      opacity: 0;
      transform: translateY(30px);
    }
    .first-row.first-row-enter-active {
      opacity: 0.8;
      transform: translateY(-2px);
      transition: all 300ms ease-out;
    }
    .first-row.first-row-enter-done {
      opacity: 1;
      transform: translateY(0);
      transition: all 50ms ease-out;
    }
    .first-row.first-row-exit {
      opacity: 0.8;
      transform: translateY(2px);
    }
    .first-row.first-row-exit-active {
      opacity: 0;
      transform: translateY(-30px);
      transition: all 350ms ease-out;
    }
  }
`;

export { FirstRow };
