import React, { useContext } from "react";
import { WalletTransferHistoryContext } from "../../context";
import { Loader } from "components/loader";
import { EntitiesList, Pagination } from "components/entities-list";
import {
  formatTransferList,
  formatTransferPhoneList,
} from "../transfers-list-item";

export const HistoryModel = ({ phoneView, className }) => {
  const {
    state: { loading, transfers, nextPageExist, currentPage },
    changeState,
  } = useContext(WalletTransferHistoryContext);
  if (loading) return <Loader />;

  const handleSelection = (event) => {
    event.preventDefault();
    changeState({
      selectedTransfer: transfers[event.currentTarget.id],
      showDetail: true,
    });
  };
  const handlePageUp = (event) => {
    event.preventDefault();
  };
  const handlePageDown = (event) => {
    event.preventDefault();
  };
  return (
    <div className={className}>
      <EntitiesList
        viewState={null}
        entities={transfers}
        formattingFunction={
          phoneView ? formatTransferPhoneList : formatTransferList
        }
        selectionHandler={handleSelection}
      >
        <Pagination
          padding="0 0 0.8rem 0"
          nextPageExist={nextPageExist}
          currentPage={currentPage}
          handlePageUp={handlePageUp}
          handlePageDown={handlePageDown}
        />
      </EntitiesList>
    </div>
  );
};
