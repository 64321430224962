import React, { useEffect } from "react";
import { useStateManager } from "hooks/use-state-manager/hook";
import { usePopupMessages } from "hooks/use-popup-messages/hook";
import { useDates, useTextInput } from "hooks/use-inputs";
import moment from "moment";
import { connect } from "react-redux";
import { clearReduxStore } from "redux/actions/general";
import { getSelectedCommerce } from "redux/selectors";
import {
  getBuildingCommerces,
  getBuildingCommerceTickets,
  commerceAutocompleteByBuilding,
} from "services/api";

import { BuildingCommercesView } from "./components/mpos-building-commerces-view";
import { BuildingCommercesPhoneView } from "./components/mpos-building-commerces-phone-view";
import { errors } from "assets/strings/texts";

const MposBuildingCommerces = ({ selectedBuildingCommerce }) => {
  const initialState = {
    buildingId: selectedBuildingCommerce.parking.building._id,
    selectedCommerce: null,
    selectedCommerceDate: null,
    commerceIsSelected: false,
    ticketIsSelected: false,
    commerces: [],
    selectedTicket: null,
    tickets: [],
    searchBy: "date",
    getCommerces: false,
    getCommerceTickets: false,
    viewType: "desktop",
    loading: false,
    loadingTickets: false,
    commercesPerPage: 20,
    limitCommerces: null,
    skipCommerces: null,
    currentCommercesPage: 1,
    nextCommercesPageExist: false,
  };
  const [state, changeState] = useStateManager(initialState);
  const {
    popupMessages,
    popupMessagesType,
    showPopup,
    throwPopupMessage,
    closePopup,
  } = usePopupMessages(null, "", false);

  useEffect(() => {
    setViewType();
    window.addEventListener("resize", setViewType);
    document.addEventListener("keydown", onEnter);
    return () => {
      window.removeEventListener("resize", setViewType);
      document.removeEventListener("keydown", onEnter);
    };
  }, []);

  useEffect(() => {
    const commerceInput = async () => {
      try {
        changeState({ loading: true });
        const response = await commerceAutocompleteByBuilding(
          searchParam,
          state.buildingId
        );
        const res = await response.json();
        if (res.error) throw res.error;
        changeState({
          commerces: res.tickets_digitized_by_commerce,
          loading: false,
        });
      } catch (err) {
        console.log({ err });
        changeState({
          ...initialState,
        });
        if (
          err.id === "NO_TOKEN_PROVIDED" ||
          err.id === "AUTHENTICATE_FAILED" ||
          err.id === "DUPLICATE_SESSION"
        ) {
          throwPopupMessage("alert", errors.AUTHENTICATION);
        } else {
          throwPopupMessage("error", err.description);
        }
      }
    };
    const commerces = async () => {
      try {
        changeState({ loading: true });
        const response = await getBuildingCommerces({
          building_id: state.buildingId,
          start_date: moment(fromDate).format(),
          end_date: moment(endDate).format(),
          limit: state.limitCommerces,
          skip: state.skipCommerces,
        });
        const res = await response.json();
        if (res.error) throw res.error;
        let commerces = [...res.tickets_digitized_by_commerce];

        changeState({
          commerces,
          loading: false,
        });
      } catch (err) {
        console.log({ err });
        changeState({
          ...initialState,
        });
        if (
          err.id === "NO_TOKEN_PROVIDED" ||
          err.id === "AUTHENTICATE_FAILED" ||
          err.id === "DUPLICATE_SESSION"
        ) {
          throwPopupMessage("alert", errors.AUTHENTICATION);
        } else {
          throwPopupMessage(
            "error",
            err && err.description
              ? err.description
              : "Ha ocuirrido un error interno"
          );
        }
      }
    };
    if (state.getCommerces) {
      state.searchBy === "date" ? commerces() : commerceInput();
      changeState({ getCommerces: false });
    }
    return () => {};
  }, [state.getCommerces]);

  useEffect(() => {
    const tickets = async () => {
      try {
        changeState({ loadingTickets: true });

        const response = await getBuildingCommerceTickets({
          commerce_id: state.selectedCommerce._id,
          date: state.selectedCommerceDate,
        });
        const res = await response.json();
        if (res.error) throw res.error;
        changeState({ tickets: res.tickets, loadingTickets: false });
      } catch (err) {
        console.log({ err });
        changeState({
          ...initialState,
        });
        if (
          err.id === "NO_TOKEN_PROVIDED" ||
          err.id === "AUTHENTICATE_FAILED" ||
          err.id === "DUPLICATE_SESSION"
        ) {
          throwPopupMessage("alert", errors.AUTHENTICATION);
        } else {
          throwPopupMessage(
            "error",
            err && err.description
              ? err.description
              : "Ha ocuirrido un error interno"
          );
        }
      }
    };
    if (state.getCommerceTickets) {
      changeState({ getCommerceTickets: false });
      tickets();
    }
    return () => {};
  }, [state.getCommerceTickets]);

  //Responsive function
  const setViewType = () => {
    let documentElement = document.documentElement,
      width = window.innerWidth || documentElement.clientWidth;
    if (width < 768) {
      changeState({ viewType: "phone", render: true });
    } else {
      changeState({ viewType: "desktop", render: true });
    }
  };

  //Listener function
  const onEnter = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      event.preventDefault();
      changeState({ getCommerces: true });
    }
  };

  //HANDLERS

  //1)
  const handleSelectors = (event) => {
    event.preventDefault();
    changeState({ searchBy: event.target.value });
  };

  //2.1)
  const { fromDate, endDate, handleFromDate, handleEndDate } = useDates(
    null,
    null
  );

  //2.2)
  const {
    bind: { value: searchParam, onChange: handleSearchInputChange },
  } = useTextInput("");
  //3)
  const handleSearch = (event) => {
    event.preventDefault();
    changeState({
      viewType: state.viewType,
      getCommerces: true,
    });
  };

  //4)
  const handleCommerceSelection = (event) => {
    let index = event.currentTarget.id;
    let selectedCommerce = state.commerces[index].commerce;
    let selectedCommerceDate = state.commerces[index].date;
    changeState({
      selectedCommerce,
      selectedCommerceDate,
      getCommerceTickets: true,
      commerceIsSelected: true,
      selectedTicket: null,
      ticketIsSelected: false,
    });
  };

  //5)
  const handleTicketSelection = (event) => {
    let index = event.currentTarget.id;
    let selectedTicket = state.tickets[index];
    changeState({ selectedTicket, ticketIsSelected: true });
  };
  const handleBackToCommerce = (event) => {
    event.preventDefault();
    changeState({ ticketIsSelected: false });
  };
  const handleBackToSearch = (event) => {
    event.preventDefault();
    changeState({ commerceIsSelected: false });
  };
  const handleCommercePageUp = (event) => {
    event.preventDefault();
    changeState({
      currentCommercesPage: state.currentCommercesPage + 1,
      skipCommerces: state.currentCommercesPage * state.commercesPerPage,
      getCommerces: true,
    });
  };
  const handleCommercePageDown = (event) => {
    event.preventDefault();
    changeState({
      currentCommercesPage: state.currentCommercesPage - 1,
      skipCommerces: (state.currentCommercesPage - 2) * state.commercesPerPage,
      getCommerces: true,
    });
  };
  return state.viewType === "desktop" ? (
    <BuildingCommercesView
      loading={state.loading}
      loadingTickets={state.loadingTickets}
      nextCommercesPageExist={state.nextCommercesPageExist}
      commerces={state.commerces}
      commerceIsSelected={state.commerceIsSelected}
      currentCommercesPage={state.currentCommercesPage}
      selectedCommerce={state.selectedCommerce}
      selectedTicket={state.selectedTicket}
      tickets={state.tickets}
      ticketIsSelected={state.ticketIsSelected}
      handleTicketSelection={handleTicketSelection}
      handleCommerceSelection={handleCommerceSelection}
      handleSearchInputChange={handleSearchInputChange}
      searchParam={searchParam}
      fromDate={fromDate}
      endDate={endDate}
      handleFromDate={handleFromDate}
      handleEndDate={handleEndDate}
      searchBy={state.searchBy}
      handleCommercePageUp={handleCommercePageUp}
      handleCommercePageDown={handleCommercePageDown}
      handleSelectors={handleSelectors}
      handleSearch={handleSearch}
      handleBackToSearch={handleBackToSearch}
      handleBackToCommerce={handleBackToCommerce}
      popupMessages={popupMessages}
      popupMessagesType={popupMessagesType}
      showPopup={showPopup}
      closePopup={closePopup}
    />
  ) : (
    <BuildingCommercesPhoneView
      loading={state.loading}
      loadingTickets={state.loadingTickets}
      nextCommercesPageExist={state.nextCommercesPageExist}
      commerces={state.commerces}
      commerceIsSelected={state.commerceIsSelected}
      selectedCommerce={state.selectedCommerce}
      selectedTicket={state.selectedTicket}
      currentCommercesPage={state.currentCommercesPage}
      tickets={state.tickets}
      ticketIsSelected={state.ticketIsSelected}
      handleTicketSelection={handleTicketSelection}
      handleCommerceSelection={handleCommerceSelection}
      handleSearchInputChange={handleSearchInputChange}
      searchParam={searchParam}
      fromDate={fromDate}
      endDate={endDate}
      handleFromDate={handleFromDate}
      handleEndDate={handleEndDate}
      searchBy={state.searchBy}
      handleCommercePageUp={handleCommercePageUp}
      handleCommercePageDown={handleCommercePageDown}
      handleSelectors={handleSelectors}
      handleSearch={handleSearch}
      handleBackToSearch={handleBackToSearch}
      handleBackToCommerce={handleBackToCommerce}
      popupMessages={popupMessages}
      popupMessagesType={popupMessagesType}
      showPopup={showPopup}
      closePopup={closePopup}
      viewType={state.viewType}
    />
  );
};

const mapStateToProps = (state) => {
  let selectedBuildingCommerce = getSelectedCommerce(state);
  return { selectedBuildingCommerce };
};

const mapDispatchToProps = {
  clearReduxStore,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MposBuildingCommerces);
