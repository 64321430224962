import TransfersListItemModel, { TransfersListItemPhoneModel } from "./model";
import styled from "styled-components";
import { grey_4 } from "assets/strings/colors";

const TransfersListItem = styled(TransfersListItemModel)`
  #root & {
    margin: 0;
    padding: 1.1vh 1rem 1.1vh 1rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-bottom: 1px solid ${grey_4};
    cursor: pointer;
    height: 6vh;
    p {
      margin: 0;
      padding: 0;
      width: calc(20% - 1rem);
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    > .small {
      font-size: 0.8rem;
    }
    > .cap {
      text-transform: capitalize;
    }
  }
  :hover {
    background: ${grey_4};
  }
`;
export const TransfersListItemPhone = styled(TransfersListItemPhoneModel)`
  #root & {
    margin: 0.5rem 0;
    padding: 1rem 0;
    width: 100%;
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    align-items: center;
    border-top: 1px ${grey_4} solid;
    border-bottom: 1px ${grey_4} solid;
    border-radius: 1rem;

    > .title {
      margin: 0;
      text-align: left;
      font-weight: bold;
      width: calc(50% - 1rem);
    }
    > .content {
      margin: 0;
      text-align: right;
      width: calc(50% - 1rem);
    }
    > .cap {
      text-transform: capitalize;
    }
  }
`;

export default TransfersListItem;
