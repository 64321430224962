import React from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Icon } from "semantic-ui-react";

const DigitizeFormModel = ({
  ticketHour,
  ticketMinute,
  handleTicketHour,
  handleTicketMinute,
  ticketDate,
  autoPaid,
  handleAutoPaid,
  handleTicketDate,
  ticketNumber,
  handleTicketNumber,
  selectedUser,
  handleSubmit,
  viewType,
  className,
}) => {
  return (
    <div className={className}>
      <div className="panel">
        <div className={"form"}>
          <p className="title">Digitalización de ticket</p>
          <div className="user-info">
            <p className="username">@{selectedUser.username}</p>
            <p>{selectedUser.email}</p>
            <p>C.I. {selectedUser.profile.identification.doc_id}</p>
          </div>
          <div className={"data"}>
            <p className="header">Número de ticket</p>
            <input
              className="normal"
              placeholder="001052210"
              value={ticketNumber}
              onChange={handleTicketNumber}
            />
          </div>
          <div className={"data"}>
            <p className="header">Fecha</p>
            <DatePicker
              //id="ticket-date-picker"
              className="date-picker"
              autoComplete="off"
              placeholderText="Día / Mes / Año"
              dateFormat="DD / MM / YYYY"
              todayButton={"Hoy"}
              isClearable
              withPortal={viewType === "phone" ? true : false}
              minDate={moment().subtract(30, "days")}
              maxDate={moment()}
              selected={ticketDate}
              onChange={handleTicketDate}
              //disabled
            />
          </div>
          <div className={"data-hour"}>
            <p className="header">Hora</p>
            <div className="hour">
              <input
                type="text"
                placeholder="14"
                value={ticketHour}
                onChange={handleTicketHour}
              />
              <p>:</p>
              <input
                type="text"
                placeholder="00"
                value={ticketMinute}
                onChange={handleTicketMinute}
              />
            </div>
          </div>
          <div className="auto-validate">
            <p>Habilitación automática</p>
            {!autoPaid && (
              <Icon
                name="toggle off"
                size="large"
                className="icon"
                onClick={handleAutoPaid}
              />
            )}
            {autoPaid && (
              <Icon
                name="toggle on"
                size="large"
                className="icon-on"
                onClick={handleAutoPaid}
              />
            )}
          </div>
        </div>
      </div>
      <button
        onClick={handleSubmit}
        disabled={!ticketHour || !ticketMinute || !ticketDate || !ticketNumber}
      >
        Digitalizar
      </button>
    </div>
  );
};

export { DigitizeFormModel };
