import React, { useContext, useEffect } from "react";
import { MPremiumHistoryState, MPremiumHistoryContext } from "./context";
import { MPremiumHistoryView } from "./components/monthly-premium-history-view";
import { MPremiumHistoryPhoneView } from "./components/monthly-premium-history-phone-view";
import { DefaultRender } from "components/default-render";
import moment from "moment";

const MposMPremiumHistoryLogic = ({ location, history, props }) => {
  const {
    state,
    changeState,
    viewType,
    setViewType,
    onEnter,
    getPremiumHistory,
    getUserStatistics,
    getAvailableBundles
  } = useContext(MPremiumHistoryContext);

  useEffect(() => {
    setViewType();
    window.addEventListener("resize", setViewType);
    document.addEventListener("keydown", onEnter);
    getAvailableBundles();
    if (location.state) {
      let remoteState = location.state;
      changeState({
        ...remoteState,
        from_date: remoteState.from_date ? moment(remoteState.from_date) : null,
        end_date: remoteState.end_date ? moment(remoteState.end_date) : null,
        viewType
      });
      history.replace({
        pathname: location.pathname,
        state: {}
      });
    }


    return () => {
      window.removeEventListener("resize", setViewType);
      document.removeEventListener("keydown", onEnter);
    };
  }, []);

  useEffect(() => {
    if (state.getActiveBundles) {
      getPremiumHistory();
      changeState({ getActiveBundles: false });
    }
    if (state.getStatistics) {
      getUserStatistics();
      changeState({ getStatistics: false });
    }
  }, [state.getActiveBundles || state.getStatistics]);

  const toRender = {
    phone: <MPremiumHistoryPhoneView />,
    desktop: <MPremiumHistoryView />
  };
  return toRender[viewType] || <DefaultRender />;
};

const MposMonthlyPremiumHistory = ({ location, history }) => {
  return (
    <MPremiumHistoryState>
      <MposMPremiumHistoryLogic location={location} history={history} />
    </MPremiumHistoryState>
  );
};

export default MposMonthlyPremiumHistory;
