import { HomeModel } from "./model";
import styled from "styled-components";

const Home = styled(HomeModel)`
  #root && {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 10rem;
    > img {
      width: 20%;
    }
    > .label {
      margin-top: ${(props) => {
        return props.viewType === "phone" ? "-3rem" : "-6rem";
      }};
      font-size: 1.5rem;
      > span {
        font-weight: bold;
      }
    }
    > .info {
      font-size: 1rem;
      width: ${(props) => {
        return props.viewType === "phone" ? "80%" : "50%";
      }};
    }
  }
`;

export { Home };
