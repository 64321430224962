import React from "react";
import { MPremiumHistoryContext } from ".";
import { connect } from "react-redux";
import { clearReduxStore } from "redux/actions/general";
import { getSelectedCommerce as getSelectedCommerceFromRedux } from "redux/selectors";
import { useStateManager } from "hooks/use-state-manager";
import { useViewType } from "hooks/use-view-type";
import { usePopupMessages } from "hooks/use-popup-messages";
import { premiumAvailableBundles, premiumHistory, premiumStatisticUser } from "api";
import { useTextInput } from "hooks/use-inputs";

const MPremiumHistoryState = ({ children, selectedCommerce }) => {
  const initialState = {
    from_date: null,
    end_date: null,
    limit: 21,
    skip: 0,
    selectedBundle: null,
    commerce_id: selectedCommerce._id,
    nextPageExist: false,
    currentPage: 1,
    statisticsLoading: false,
    bundles: [],
    bundle_title: [],
    bundlesLoading: false,
    selectionDone: false,
    formLoading: false,
    selectedCommerce,
    getActiveBundles: true,
    getStatistics: false,
    searchType: "dateRange"
  };

  const [viewType, setViewType] = useViewType(null);
  const [state, changeState] = useStateManager(initialState);
  const {
    popupMessages,
    popupMessagesType,
    showPopup,
    throwPopupMessage,
    closePopup
  } = usePopupMessages(null, "", false);
  const {
    bind: { value: searchParam, onChange: handleSearchInputChange }
  } = useTextInput("");

  const bundlesPerPage = 20;

  const handlePageDown = (event) => {
    event.preventDefault();
    // format request data
    let skip = (state.currentPage - 2) * bundlesPerPage;
    let limit = bundlesPerPage + 1;
    // Set loading state
    changeState({
      currentPage: state.currentPage - 1,
      nextPageExist: true,
      skip,
      limit,
      getActiveBundles: true
    });
  };

  // 7)
  const handlePageUp = (event) => {
    event.preventDefault();
    // format request data
    let limit = bundlesPerPage + 1;
    let skip = state.currentPage * bundlesPerPage;
    // Set loading state
    changeState({
      currentPage: state.currentPage + 1,
      nextPageExist: true,
      skip,
      limit,
      getActiveBundles: true
    });
  };

  const onEnter = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      event.preventDefault();
      changeState({ getActiveBundles: true });
    }
  };

  const getPremiumHistory = async () => {
    try {
      changeState({ bundlesLoading: true });
      const from_date = state.from_date
        ? state.from_date.toISOString()
        : state.from_date;
      const end_date = state.end_date
        ? state.end_date.toISOString()
        : state.end_date;

      const dateRange = state.searchType === "dateRange";
      const response = await premiumHistory({
        from_date: dateRange ? from_date : null,
        end_date: dateRange ? end_date : null,
        username: dateRange ? null : searchParam,
        limit: state.limit,
        skip: state.skip,
        status: ["approved"],
        building_id: selectedCommerce.building
          ? selectedCommerce.building
          : undefined,
        bundle_title: state.bundle_title
      });
      const res = await response.json();
      if (res.error) throw res.error;
      if (Array.isArray(res.premiums) && res.premiums.length) {
        const nextPageExist = res.premiums.length > bundlesPerPage;
        changeState({ bundlesLoading: false, bundles: [...res.premiums], nextPageExist });
      } else {
        changeState({ bundlesLoading: false, bundles: [] });
      }

    } catch (error) {
      changeState({ ...initialState, getActiveBundles: false });
      throwPopupMessage(
        "error",
        error.description
          ? error.description
          : "Ha ocurrido un error, intente más tarde."
      );
    }
  };

  const getUserStatistics = async () => {
    try {
      changeState({ statisticsLoading: true });
      const date_from =
        state.selectedBundle && state.selectedBundle.start_date
          ? state.selectedBundle.start_date
          : state.from_date;
      const date_to =
        state.selectedBundle && state.selectedBundle.expire_date
          ? state.selectedBundle.expire_date
          : state.end_date;
      const response = await premiumStatisticUser({
        date_from,
        date_to,
        premium_type: state.selectedBundle.type,
        user_id: state.selectedBundle.customer._id,
        building_code: state.selectedBundle.building_code
      });
      const res = await response.json();
      if (res.error) throw res.error;
      setTimeout(() => {
        changeState({
          selectedBundle: {
            ...state.selectedBundle,
            ...res
          },
          selectionDone: true,
          statisticsLoading: false
        });
      }, 350);
    } catch (error) {
      console.log({ error });
      changeState({
        selectedBundle: {
          ...state.selectedBundle
        },
        selectionDone: true,
        statisticsLoading: false
      });
      throwPopupMessage(
        "error",
        error.description
          ? error.description
          : "No se ha podido obtener las estadísticas del usuario"
      );
    }
  };

  const handleBundleSelection = (event) => {
    event.preventDefault();
    let index = event.currentTarget.id;
    changeState({
      selectionDone: false,
      selectedBundle: state.bundles[index],
      getStatistics: true
    });
  };

  const getAvailableBundles = async () => {
    try {
      changeState({ formLoading: true });
      const response = await premiumAvailableBundles(null);
      const res = await response.json();
      if (res.error) throw res.error;
      const premiumBundlesOptions = res.premium_bundles.map((bundle) => {
          return { value: bundle, text: bundle, key: bundle };
        }
      );
      changeState({ formLoading: false, premiumBundlesOptions });
    } catch (e) {
      console.log({ e });
      changeState({
        ...initialState
      });
      throwPopupMessage(
        "error",
        e.description
          ? e.description
          : "No se ha podido obtener la lista de packets disponibles."
      );
    }
  };

  return (
    <MPremiumHistoryContext.Provider
      value={{
        viewType,
        setViewType,
        state,
        changeState,
        popupMessages,
        popupMessagesType,
        showPopup,
        throwPopupMessage,
        closePopup,
        handlePageDown,
        handlePageUp,
        handleBundleSelection,
        onEnter,
        getPremiumHistory,
        getUserStatistics,
        getAvailableBundles,
        searchParam,
        handleSearchInputChange
      }}
    >
      {children}
    </MPremiumHistoryContext.Provider>
  );
};

const mapStateToProps = (state) => {
  let selectedCommerce = getSelectedCommerceFromRedux(state);
  return { selectedCommerce };
};

const mapDispatchToProps = {
  clearReduxStore
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MPremiumHistoryState);
