import React, { useState, useEffect } from "react";
import moment from "moment";
import "moment/locale/es-us";
import { connect } from "react-redux";
import { throwPopupMessage, closePopup } from "redux/actions/popup-messages";
import { getCommercesDashboard, getCommerceAmounts } from "services/api";
import { getSelectedCommerce } from "redux/selectors/commerces";
import MposGeneralDashboardView from "./components/mpos-general-dashboard-view";
import MposParkingAssociated from "../parking-associated";
import { arrayToObject, pushOption } from "services/utils";
import { errors } from "assets/strings/texts";

moment.locale("es-us");

const formatterTransactions = (transactions) => {
  let amount = 0;
  let qty = 0;
  let amountByQty = 0;

  const arrayReducer = (a, b) => {
    return a + b;
  };
  if (transactions.length) {
    let amountArray = transactions.map((item) => {
      return item.amount;
    });
    let totalAmounts = amountArray.reduce(arrayReducer, 0);
    amount = totalAmounts / amountArray.length;

    let qtyArray = transactions.map((item) => {
      return item.qty;
    });
    let totalQty = qtyArray.reduce(arrayReducer, 0);
    qty = totalQty / qtyArray.length;

    amountByQty = amount / qty;
  }

  return { amount, qty, amountByQty };
};
const pushDataGraph = (transactions, period) => {
  let data = [];
  let titleGraph;
  let keyX = "";
  let keyY = "transactions";
  let lineName = "Transacciones Realizadas";
  if (transactions.length) {
    if (period === "daily") {
      titleGraph = "Estadísticas por Día";
      keyX = "day";
      let dayOfPastMonth = moment({ hour: "0" }).subtract(31, "days");
      for (let i = 0; i < 31; i++) {
        data.push({
          day: dayOfPastMonth.format("MMM D"),
          transactions: 0,
        });
        dayOfPastMonth.add(1, "days");
      }
      data.forEach((coord, index) => {
        transactions.forEach((item) => {
          let day = moment(item.dateFormatted).format("MMM D");
          if (day === coord.day) {
            data[index] = {
              day,
              transactions: item.qty,
            };
          }
        });
      });
    } else if (period === "weekly") {
      keyX = "week";
      titleGraph = "Estadisticas por Semana";
      let weeksOfPastMonth = moment().startOf("week").subtract(5, "weeks");
      for (let i = 0; i < 5; i++) {
        data.push({
          week: weeksOfPastMonth.format("MMM D"),
          transactions: 0,
        });
        weeksOfPastMonth.add(1, "week");
      }
      data.forEach((coord, index) => {
        transactions.forEach((item) => {
          let week = moment(item.dateFormatted).format("MMM D");
          if (week === coord.week) {
            data[index] = {
              week,
              transactions: item.qty,
            };
          }
        });
      });
    } else if (period === "monthly") {
      titleGraph = "Estadísticas por Mes";
      keyX = "month";
      let pastMonth = moment().startOf("month").subtract(1, "months");
      for (let i = 0; i < 2; i++) {
        data.push({
          month: pastMonth.format("MMM D"),
          transactions: 0,
        });
        pastMonth.add(1, "month");
      }
      data.forEach((coord, index) => {
        transactions.forEach((item) => {
          let month = moment(item.dateFormatted).format("MMM D");
          if (month === coord.month) {
            data[index] = {
              month,
              transactions: item.qty,
            };
          }
        });
      });
    }
  }
  return { data, titleGraph, keyX, keyY, lineName };
};
const MposGeneralDashboard = (props) => {
  let periodOptions = ["Diarias", "Semanales", "Mensuales"];
  let timePeriods = [];
  periodOptions.forEach((option) => {
    pushOption(option, timePeriods);
  });

  const [viewState, setViewState] = useState("wait_statistics");
  const [phoneView, setPhoneView] = useState("false");
  const [stats, setStats] = useState({ qty: 0, amount: 0, amountByQty: 0 });
  const [period, setPeriod] = useState({
    periodType: "daily",
    periodToShow: "Diarias",
  });
  const [wallet, setWallet] = useState(0);
  const [dataGraph, setDataGraph] = useState({
    data: [],
    titleGraph: "",
    keyX: "",
    keyY: "",
    lineName: "",
  });
  const [loading, setLoading] = useState(false);
  const [getDashboard, setGetDashboard] = useState(false);
  const [getAmounts, setGetAmounts] = useState(false);

  const initialState = () => {
    setViewState("");
    setStats({ qty: 0, amount: 0, amountByQty: 0 });
    setPeriod({ periodType: "daily", periodToShow: "Diarias" });
    setWallet(0);
  };
  useEffect(() => {
    setGetDashboard(true);
    setGetAmounts(true);
    window.addEventListener("resize", setViewType);
    return () => {
      window.removeEventListener("resize", setViewType);
    };
  }, []);

  useEffect(() => {
    const obtainDashboard = async (date, periodToReq) => {
      if (!date) date = moment().format("Y-MM-DD");
      if (!periodToReq) periodToReq = period.periodType;
      try {
        const response = await getCommercesDashboard(date, periodToReq);
        if (!response.ok) {
          throw response;
        } else {
          const res = await response.json();
          setLoading(false);
          const transactions = res.transactions_bs;
          const newStats = formatterTransactions(transactions);
          const newDataGraph = pushDataGraph(transactions, periodToReq);
          setViewState("show_statistics");
          setStats({ ...newStats });
          setDataGraph({ ...newDataGraph });
        }
      } catch (err) {
        setLoading(false);
        if (typeof err.json === "function") {
          const err_body = await err.json();
          if (err_body.error.id === "DUPLICATE_SESSION") {
            initialState();
            props.throwPopupMessage("alert", errors.AUTHENTICATION);
            //props.clearReduxStore(); // prevent the setState process on unMounted component
          }
        } else {
          props.throwPopupMessage(
            "alert",
            "Ha ocurrido un error. Verifique su conexión a internet"
          );
        }
      }
    };
    if (getDashboard) {
      setLoading(true);
      setGetDashboard(false);
      obtainDashboard();
    }
  }, [getDashboard]);

  useEffect(() => {
    const obtainAmounts = async () => {
      try {
        const response = await getCommerceAmounts();
        const res = await response.json();
        setLoading(false);
        if (!res.user_amounts && !res.user_amounts.length) {
          const error = { description: "No hay montos disponibles" };
          throw error;
        }
        const wallets = arrayToObject(res.user_amounts, "currency");
        let bs =
          wallets && wallets.BS && wallets.BS.funds ? wallets.BS.funds : 0;
        if (bs < 0) bs = 0;
        setWallet(bs);
      } catch (error) {
        setLoading(false);
        initialState();
        props.throwPopupMessage("error", error.description);
      }
    };
    if (getAmounts) {
      setLoading(true);
      setGetAmounts(false);
      obtainAmounts();
    }
  }, [getAmounts]);

  const handleTimePeriodSelect = (event, data) => {
    event.preventDefault();
    setViewState("wait_statistics");
    if (data.value === "Diarias") {
      setPeriod({ periodType: "daily", periodToShow: "Diarias" });
      setGetDashboard(true);
    } else if (data.value === "Semanales") {
      setPeriod({ periodType: "weekly", periodToShow: "Semanales" });
      setGetDashboard(true);
    } else if (data.value === "Mensuales") {
      setPeriod({ periodType: "monthly", periodToShow: "Mensuales" });
      setGetDashboard(true);
    }
  };

  const setViewType = () => {
    let documentElement = document.documentElement,
      width = window.innerWidth || documentElement.clientWidth;
    if (width < 768) {
      setPhoneView(true);
    } else {
      setPhoneView(false);
    }
  };

  return (
    <div>
      <MposGeneralDashboardView
        viewState={viewState}
        phoneView={phoneView}
        wallet={wallet}
        loading={loading}
        stats={stats}
        dataGraph={dataGraph}
        periodToShow={period.periodToShow}
        timePeriods={timePeriods}
        handleTimePeriodSelect={handleTimePeriodSelect}
        category={props.category}
      />
      {props.category !== "estacionamiento" &&
      props.category !== "gasolina" &&
      props.category !== "pagodirecto" &&
      props.selectedCommerce.parking ? (
        <MposParkingAssociated />
      ) : (
        <></>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedCommerce: getSelectedCommerce(state),
});
const mapDispatchToProps = {
  throwPopupMessage,
  closePopup,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MposGeneralDashboard);
