import React from "react";
import warningIcon from "assets/img/warningIcon.gif";
import fullTicketsIcon from "assets/img/fullTickets.png";
import handIcon from "assets/img/handgift.gif";
import { Icon } from "semantic-ui-react";

const AlertMessageModel = ({
  type,
  errorType,
  message,
  handleContinue,
  handleBack,
  className,
}) => {
  return (
    <div className={className}>
      {type === "error" && errorType !== "no-tickets" && (
        <img src={warningIcon} alt="alert" className="upside" />
      )}
      {type === "error" && errorType === "no-tickets" && (
        <Icon name="remove circle" size="huge" color="red" />
      )}
      {type === "error" && errorType === "no-tickets" && (
        <img src={fullTicketsIcon} alt="alert" className="upside" />
      )}
      {type === "success" && (
        <img src={handIcon} alt="alert" className="upside-down" />
      )}
      <p>{message}</p>
      <button
        onClick={
          type === "error" && errorType === "bad-input"
            ? handleBack
            : handleContinue
        }
      >
        {type === "error" && errorType === "bad-input" ? "Volver" : "Continuar"}
      </button>
    </div>
  );
};

export { AlertMessageModel };
