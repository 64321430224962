import React, { useEffect, useContext } from "react";
import { WithdrawHistoryContext } from "../context";
import { WithdrawValidatorForm } from "../components/withdraw-validation-form";
import getWithdrawals from "../services/history";
import approveWithdraw from "../services/approve";

const useOnMountHistory = () => {
  const context = useContext(WithdrawHistoryContext);

  useEffect(() => {
    if (context.location.state && context.location.state.withdraw) {
      context.changeState({
        withdrawSelected: context.location.state.withdraw,
      });
      context.throwPopupMessage(
        "",
        <WithdrawValidatorForm
          withdrawSelected={context.location.state.withdraw}
          onSubmit={context.submitApprove}
        />
      );
      context.history.replace({
        pathname: context.location.pathname,
        state: null,
      });
    }
    if (context.state.getWithdrawals) {
      getWithdrawals(context);
      context.changeState({ getWithdrawals: false });
    }
    if (context.state.approveWithdraw) {
      approveWithdraw(context);
      context.changeState({ approveWithdraw: false });
    }
    context.setViewType();
    window.addEventListener("resize", context.setViewType);
    return () => {
      window.removeEventListener("resize", context.setViewType);
    };
  }, [context.state.getWithdrawals, context.state.approveWithdraw]);
};

export default useOnMountHistory;
