import { TicketModel } from "./model";
import styled from "styled-components";

const Ticket = styled(TicketModel)`
  #root && {
    height: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export { Ticket };
