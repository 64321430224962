import React from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Button } from "components/buttons";
import { general as texts } from "assets/strings/texts";
import { useStateManager } from "hooks/use-state-manager";
import { connect } from "react-redux";
import { getTransactionsTags } from "redux/selectors/constants";
import { Dropdown } from "components/dropdown";
import { CounterPartSelect } from "components/counterpart-select";

const initialState = {
  searchType: "latests",
  type: "",
  from_date: null,
  end_date: null,
  currency: "BS",
  transaction_tag: [],
  counterpart_id: "",
  commerce_counterpart_id: "",
};

const MovementsFormNewModel = ({
  transactionTags,
  loading,
  onSubmit,
  phoneView,
  counterpartFilter,
  className,
}) => {
  const [state, changeState] = useStateManager(initialState);
  const handleChangeButton = (event) => {
    event.preventDefault();
    changeState({ [event.target.name]: event.target.value });
  };

  const handleDates = (name, date) => {
    const dateRange = {
      from_date: date,
      end_date: date ? moment(date).endOf("day") : date,
    };
    changeState({ [name]: dateRange[name] });
  };

  const handleTransactionTag = (event, data) => {
    event.preventDefault();
    changeState({ transaction_tag: data.value });
  };

  const onChangeCounterpart = (data) => {
    changeState({ counterpart_id: "", commerce_counterpart_id: "", ...data });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit({
      ...state,
      from_date: state.from_date ? state.from_date.toISOString() : null,
      end_date: state.end_date ? state.end_date.toISOString() : null,
    });
  };
  return (
    <form className={className} onSubmit={handleSubmit}>
      <div className={`collapsed false`}>
        <div className="selectors">
          <Button
            name="searchType"
            value="latests"
            pressed={state.searchType === "latests"}
            color={state.searchType === "latests" ? "blue" : "grey_1"}
            disabled={loading}
            onClick={handleChangeButton}
          >
            {texts.latests}
          </Button>
          <Button
            name="searchType"
            value="dateRange"
            pressed={state.searchType === "dateRange"}
            color={state.searchType === "dateRange" ? "blue" : "grey_1"}
            disabled={loading}
            onClick={handleChangeButton}
          >
            {texts.dateRange}
          </Button>
        </div>
        {state.searchType === "dateRange" && (
          <div className="date-pickers">
            <DatePicker
              className="date-picker"
              placeholderText={texts.from}
              dateFormat={texts.shortDateFormat}
              disabled={loading}
              showMonthDropdown
              showYearDropdown
              todayButton={texts.today}
              isClearable
              withPortal={phoneView}
              selected={state.from_date}
              maxDate={
                state.end_date
                  ? moment(state.end_date).startOf("day")
                  : moment()
              }
              onChange={(date) => handleDates("from_date", date)}
            />
            <p>/</p>
            <DatePicker
              className="date-picker"
              placeholderText={texts.to}
              dateFormat={texts.shortDateFormat}
              disabled={loading}
              popperPlacement="bottom-end"
              showMonthDropdown
              showYearDropdown
              todayButton={texts.today}
              isClearable
              withPortal={phoneView}
              selected={
                state.end_date
                  ? moment(state.end_date).subtract(23, "hours")
                  : null
              }
              minDate={
                state.from_date ? moment(state.from_date).endOf("day") : null
              }
              maxDate={moment().endOf("day")}
              onChange={(date) => handleDates("end_date", date)}
            />
          </div>
        )}

        <div className="selectors">
          <Button
            name="type"
            value={state.type === "credit" ? "" : "credit"}
            pressed={state.type === "credit"}
            color={state.type === "credit" ? "blue" : "grey_1"}
            disabled={loading}
            onClick={handleChangeButton}
          >
            {texts.income}
          </Button>
          <Button
            name="type"
            value={state.type === "debit" ? "" : "debit"}
            pressed={state.type === "debit"}
            color={state.type === "debit" ? "blue" : "grey_1"}
            disabled={loading}
            onClick={handleChangeButton}
          >
            {texts.expenditures}
          </Button>
        </div>

        <Dropdown
          placeholder="Etiquetas de transacción"
          multiple
          search
          selection
          id="bundlePacket"
          onChange={handleTransactionTag}
          options={transactionTags}
          style={{ width: "100%", marginTop: "0.5rem" }}
        />
        {counterpartFilter && (
          <CounterPartSelect onChangeCounterpart={onChangeCounterpart} />
        )}
        <Button color="grey" disabled={loading}>
          Buscar
        </Button>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => {
  let transactionTags = getTransactionsTags(state);
  return {
    transactionTags,
  };
};

const mapDispatchToProps = null;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MovementsFormNewModel);
