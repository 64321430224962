import styled from 'styled-components'
import SuccessRechargeModel from './model'

const SuccessRecharge = styled(SuccessRechargeModel)`
  #root &{
    >.ok-circle-icon{
      text-align: center;
      width: 100%;
      margin: 0 auto 0 auto;
      max-width: 160px;
      >img{
        width: 100%;
      }
    }
    >.sub-title{
      font-size: .85rem;
      display: inline-block;
      border-bottom: 1px solid #5B5B5B;
      text-transform: uppercase;
      font-weight: bold;
      color: #5B5B5B;
      margin-bottom: .5rem 0 .5rem 0;
    }
    >.text{
      font-weight: bold;
      color: #5B5B5B;
      font-size: 1.5rem;
      margin: .5rem auto .5rem auto;
      text-align: center;
    }
  }
`
export default SuccessRecharge