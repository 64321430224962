import { UserInfoModel } from "./model";
import styled from "styled-components";
import { grey_4, grey_6, bluePD } from "assets/strings/colors";

const UserInfo = styled(UserInfoModel)`
  #root && {
    display: flex;
    flex-direction: column;
    ${(props) => {
      return props.phoneView ? "min-height: 700px" : "";
    }}
    > .not-found {
      margin: 1rem 1rem;
      color: ${grey_6};
    }
    > .user {
      display: flex;
      justify-content: inline-block;
      align-items: center;
      border-bottom: 1px solid ${grey_4};
      > .left {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20%;
        display: flex;
        height: 100%;
      }
      > .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 60%;
        padding: 2rem 0;
        > .header {
          margin: 0;
          color: #63c0e8;
          font-size: 2rem;
          font-weight: bold;
          text-align: center;
        }
        > .phone {
          margin: 0;
          color: ${grey_6};
          width: 100%;
          font-weight: bold;
          text-align: center;
        }
      }
    }
    > .div-header {
      margin: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${bluePD};
      color: white;
      font-weight: bold;
    }
  }
`;

export { UserInfo };
