import React from "react";
import TransfersContext from "./context";
import { connect } from "react-redux";
import { clearReduxStore } from "redux/actions/general";
import { getSelectedCommerce as getSelectedCommerceFromRedux } from "redux/selectors";
import { useStateManager } from "hooks/use-state-manager";
import { throwPopupMessage as tPopupMessage } from "redux/actions/popup-messages";

const TransfersState = ({
  selectedCommerce,
  viewType,
  children,
  throwPopupMessage,
}) => {
  const initialState = {
    selectedCommerce,
    formData: {
      reference: "",
      date_from: null,
      date_to: null,
      status: [],
      page: 1,
      limit: 20,
    },
    submitForm: true,
    loading: false,
    transfers: [],
    selectedTransfer: null,
    showDetail: false,
    phoneView: viewType === "phone",
    username: "",
    changeStatus: false,
    sendUsername: false,
    nextPageExists: false,
  };
  const [state, changeState] = useStateManager(initialState);

  return (
    <TransfersContext.Provider
      value={{
        state,
        changeState,
        throwPopupMessage,
        viewType,
      }}
    >
      {children}
    </TransfersContext.Provider>
  );
};

const mapStateToProps = (state) => {
  let selectedCommerce = getSelectedCommerceFromRedux(state);
  return { selectedCommerce };
};

const mapDispatchToProps = {
  clearReduxStore,
  throwPopupMessage: tPopupMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(TransfersState);
