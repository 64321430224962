import React from 'react'
 
const PaymentMethodsModel = ({className}) => {
  return(
    <div className={className}>
      <div className='payment-method'>
        <h4 className='title'>Transferencía o Cheque</h4>
        <div className='method'>
          <p>
            <b>Banplus</b> - Cta. Corriente Nº:<br/>
            0174-0149-11-1494191032
          </p>
          <p>
            Corporación Paytech, C.A.<br/>
            RIF: J-410970715
          </p>
        </div>
        <div className='extra'>
          <p><b>Enviar el comprobante de transferencía a <a href='mailto:admin@pagodirecto.com'>admin@pagodirecto.com</a></b></p>
        </div>
      </div>
    </div>
  )
}

export { PaymentMethodsModel }