import styled from 'styled-components'
import { 
  InvoiceListItemModel,
  InvoicePhoneListItemModel,
  InvoiceListHeaderModel,
  NoInvoiceFoundModel
} from './model'
import { grey_1, grey_4 } from 'assets/strings/colors'

const InvoiceListItem = styled(InvoiceListItemModel)`
  #root &{
    margin: 0;
    padding: .7rem;
    cursor: pointer;
    border-radius: 1rem;
    border-bottom: 1px solid ${grey_4};
    font-size: .9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    :hover{
      background: ${grey_1};
    }
    >.period-section{
      padding: 0 .5rem 0 .5rem;
      width: 40%;
      >p{
        >span{
          text-transform: uppercase;
        font-weight: bold;
        }
        line-height: 2rem;
        margin: .1rem 0 .1rem 0;
      }
    }
    >.amount{
      padding: 0 .5rem 0 .5rem;
      width: 40%;
      margin: 0;
      font-size: 1.2rem;
    }
    >.status{
      padding: 0 .5rem 0 .5rem;
      width: 20%;
      margin: 0;
      text-align: center;
      >img{
        height: 2.5rem;
      }
      >.paid{
        text-transform: uppercase;
        font-weight: bold;
        color: #a2c400;
      }
      >.pending{
        text-transform: uppercase;
        font-weight: bold;
        color: #ffc107;
      }
      >.overdue{
        text-transform: uppercase;
        font-weight: bold;
        color: #ff0000;
      }
    }
  }
`

const InvoicePhoneListItem = styled(InvoicePhoneListItemModel)`
  #root &{
    margin: 0;
    padding: .7rem;
    font-size: .85rem;
    cursor: pointer;
    border-radius: 1rem;
    border-bottom: 1px solid ${grey_4};
    :hover{
      background: ${grey_1};
    }
    >.title{
      display: inline-block;
      margin-bottom: .3rem;
      text-transform: uppercase;
      font-size: 1.1rem;
      border-bottom: 1px solid ${grey_4};
    }
    .status{
      float: right; 
      height: 2rem;
    }
    >p{
      margin: 0 0 .6rem 0;
    }
  }
`

const InvoiceListHeader = styled(InvoiceListHeaderModel)`
  #root &{
    margin: 0;
    >.col{
      margin: 1.2rem .5rem 0 .5rem;
      padding-bottom: .3rem;
      display: inline-block;
      text-transform: uppercase;
      font-weight: bold;
      border-bottom: 1px solid ${grey_4};
    }
    >.forty{
      width: calc(40% - 1rem);
    }
    >.twenty{
      width: calc(20% - 1rem);
    }
  }
`

const NoInvoiceFound = styled(NoInvoiceFoundModel)`
  #root &{
    text-align: center;
    font-size: 1.2rem;
    line-height: 62px;
  }
`

export { 
  InvoiceListItem, 
  InvoiceListHeader,
  NoInvoiceFound,
  InvoicePhoneListItem
}