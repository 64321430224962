import React, { useContext } from "react";
import moment from "moment";
import { Button } from "components/buttons";
import { WalletTodayMovementsContext } from "../../context";
import { Dropdown } from "components/dropdown";
import { useDropdown } from "hooks/use-dropdown";

export function HistoryFormModel({ phoneView, className }) {
  const accountsOptions = [
    {
      value: "01720110791105768301",
      text: "Wallet",
      key: "wallet",
    },
    {
      value: "01720110711105768319",
      text: "Servicios",
      key: "services",
    },
  ];
  const {
    bind: { onChange: onChangeAccount, value: accountSelected },
  } = useDropdown("");

  const {
    state: { loading },
    changeState,
  } = useContext(WalletTodayMovementsContext);

  const onSubmit = (event) => {
    event.preventDefault();
    changeState({
      date_from: moment().startOf("day").toISOString(),
      date_to: moment().add(1, "days").startOf("day").toISOString(),
      account: accountSelected,
      submit: true,
    });
  };

  const submitDisabled = !accountSelected || loading;
  return (
    <form className={className} onSubmit={onSubmit}>
      {/*    <div className={`collapsed false`}>
        <div className="date-pickers">
          <DatePicker
            className="date-picker"
            placeholderText={texts.from}
            dateFormat={texts.shortDateFormat}
            showMonthDropdown
            showYearDropdown
            todayButton={texts.today}
            isClearable
            withPortal={phoneView}
            selected={fromDate}
            maxDate={endDate ? moment(endDate).subtract(23, "hours") : moment()}
            onChange={handleFromDate}
          />
          <p>/</p>
          <DatePicker
            className="date-picker"
            placeholderText={texts.to}
            dateFormat={texts.shortDateFormat}
            popperPlacement="bottom-end"
            showMonthDropdown
            showYearDropdown
            todayButton={texts.today}
            isClearable
            withPortal={phoneView}
            selected={endDate ? moment(endDate).subtract(23, "hours") : null}
            minDate={fromDate ? moment(fromDate).add(23, "hours") : null}
            maxDate={moment()}
            onChange={handleEndDate}
          />
        </div>
      </div>*/}
      <p className="title"> Seleccione una cuenta:</p>
      <Dropdown
        fluid
        floating
        selection
        id="account"
        value={accountSelected}
        onChange={onChangeAccount}
        options={accountsOptions}
      />

      <Button color="grey" disabled={submitDisabled}>
        Buscar
      </Button>
    </form>
  );
}
