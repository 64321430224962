import React from "react";
import { CSSTransition } from "react-transition-group";
import { Button } from "components/buttons";
import { Segment, Dropdown } from "semantic-ui-react";

const NewUserFormModel = ({
  //loaders
  addUserLoading,
  loadingRoles,
  //data
  view,
  roles,
  form,
  showForm,
  //methods
  handleChange,
  handlePhoneChange,
  handleRoleChange,
  handleSubmitUser,
  handleRemove,
  className,
}) => {
  return (
    <div className={className}>
      <CSSTransition
        in={showForm}
        timeout={350}
        appear={true}
        classNames="register"
      >
        <div className="register">
          <p className="title"> Registro</p>
          {(loadingRoles || addUserLoading) && (
            <div
              className="ui active loader"
              style={{
                marginTop: "21rem",
                position: "absolute",
              }}
            ></div>
          )}
          {!loadingRoles && !addUserLoading && (
            <Segment.Group className="register-form" autoComplete="off">
              <div className="ui transparent input segment">
                <input
                  name="email"
                  type="email"
                  autoComplete="new-password"
                  placeholder="Dirección de correo electrónico"
                  onChange={handleChange}
                  value={form && form.email ? form.email : ""}
                  disabled={addUserLoading}
                />
              </div>
              <div className="ui transparent input segment">
                <input
                  name="username"
                  type="text"
                  autoComplete="new-username"
                  maxLength={14}
                  pattern="^[a-z0-9][a-z0-9\._-]{2,14}$"
                  title="Su nombre de usuario solo puede contener letras minúsculas, numeros, puntos, guiones y debe comenzar con un valor alfanumérico"
                  placeholder="Nombre de usuario"
                  onChange={handleChange}
                  value={form && form.username ? form.username : ""}
                  disabled={addUserLoading}
                />
              </div>
              <div className="ui transparent input segment">
                <input
                  name="name"
                  type="text"
                  autoComplete="new-name"
                  maxLength={14}
                  pattern="^[a-z0-9][a-z0-9\._-]{2,14}$"
                  title="Debe ingresar el nombre"
                  placeholder="Nombre"
                  onChange={handleChange}
                  value={form && form.name ? form.name : ""}
                  disabled={addUserLoading}
                />
              </div>
              <div className="ui transparent input segment">
                <input
                  name="last_name"
                  type="text"
                  autoComplete="new-lastname"
                  maxLength={14}
                  pattern="^[a-z0-9][a-z0-9\._-]{2,14}$"
                  title="Debe ingresar el apellido"
                  placeholder="Apellido"
                  onChange={handleChange}
                  value={form && form.last_name ? form.last_name : ""}
                  disabled={addUserLoading}
                />
              </div>
              <div className="ui transparent input segment">
                <input
                  name="phone"
                  type="text"
                  autoComplete="new-phone"
                  maxLength={11}
                  pattern="^[0-9][0-9\._-]{2,14}$"
                  title="Debe colocar su número de telefono"
                  placeholder="Telefono"
                  onChange={handlePhoneChange}
                  value={form && form.phone ? form.phone : ""}
                  disabled={addUserLoading}
                />
              </div>
              <div className="ui transparent input segment">
                <Dropdown
                  className="label"
                  fluid
                  selectOnBlur={false}
                  placeholder="Selecciona un Rol"
                  name="role_id"
                  value={form && form.role_id ? form.role_id : ""}
                  options={roles}
                  onChange={handleRoleChange}
                  disabled={addUserLoading}
                />
              </div>
            </Segment.Group>
          )}

          {!loadingRoles && !addUserLoading && (
            <div className="selectors" key="selectors">
              <Button
                color="blue"
                disabled={
                  form.email === "" ||
                  form.username === "" ||
                  form.name === "" ||
                  form.last_name === "" ||
                  form.phone === "" ||
                  form.phone.length < 11 ||
                  form.role_id === "" ||
                  addUserLoading
                }
                key="buttom-submit"
                onClick={handleSubmitUser}
              >
                {" "}
                Registrar
              </Button>
              {view === "phone" && (
                <Button
                  color="grey"
                  style={{ marginBottom: "1.5rem" }}
                  onClick={handleRemove}
                >
                  Volver
                </Button>
              )}
            </div>
          )}
        </div>
      </CSSTransition>
    </div>
  );
};
export { NewUserFormModel };
