import React, { useEffect } from "react";
import AdminTransfersContext from "./context";
import { getAmountHistory } from "services/api";
import { useStateManager } from "hooks/use-state-manager";
import { usePopupMessages } from "hooks/use-popup-messages";
import { walletAPI } from "api";
import { priceToRequest } from "services/utils";
import { CodeForm } from "../components/code-form";
import { parkingCommercesList } from "../../../api/pd-wallet";
import { TransferResult } from "../components/transfer-result";
import { bankAmounts } from "../services/bank-amounts";

const AdminTransferState = ({
  children,
  withdraw,
  remoteLoading,
  selectedCommerce,
}) => {
  const initialState = {
    bankList: [],
    loading: false,
    loadingAmounts: false,
    transferLoading: false,
    submit: false,
    fetchNewData: true,
    cleanup: false,
    viewState: "show_simple_transfer",
    showRightSide: false,
    //form values
    typeOfTransferSelected: "",
    typeOfDoc: "",
    amount: "",
    concept: "",
    receiver_name: "",
    receiver_account: "",
    receiver_document_number: "",
    code: "",
    user: "",
    commerces: null,
    renderInputs: false,
    withdrawFilled: true,
  };

  const [state, changeState] = useStateManager(initialState);

  const {
    popupMessages,
    popupMessagesType,
    showPopup,
    throwPopupMessage,
    closePopup,
  } = usePopupMessages(null, "", false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        changeState({ loading: true });
        const amountsResponse = await getAmountHistory();
        const amountsRes = await amountsResponse.json();
        const commercesResponse = await parkingCommercesList();
        const commercesRes = await commercesResponse.json();
        if (amountsRes.error) throw amountsRes.error;
        if (commercesRes.error) throw commercesRes.error;

        if (amountsRes.bank_amounts) {
          let paytechAccountNumber = "";
          amountsRes.bank_amounts.map((bankData) => {
            if (bankData.name === "Paytech") {
              paytechAccountNumber = bankData.account_number;
            }
            return null;
          });
          changeState({
            bankList: amountsRes.bank_amounts,
            paytechData: {
              ...state.paytechData,
              accountNumber: paytechAccountNumber,
            },
          });
        }
        if (commercesRes.commerces)
          changeState({ commerces: [...commercesRes.commerces] });
        changeState({ loading: false });
      } catch (error) {
        console.log(error);
        throwPopupMessage("error", "Error al buscar los datos");
        changeState({ loading: false });
      }
    };
    if (state.fetchNewData) {
      changeState({ fetchNewData: false });
      bankAmounts(changeState, throwPopupMessage);
      fetchData();
    }
    return () => {};
  }, [state.fetchNewData]);

  useEffect(() => {
    const transfer = async () => {
      try {
        changeState({ transferLoading: true });
        const form = {
          wallet: {
            concept: state.concept,
            amount: priceToRequest(state.amount),
            bank: "wallet",
            code: state.code,
            user: state.user,
          },
          service: {
            concept: state.concept,
            amount: priceToRequest(state.amount),
            bank: "service",
            code: state.code,
            user: state.user,
          },
          "third-bancamiga": {
            receiver_account: state.receiver_account,
            receiver_name: state.receiver_name,
            receiver_document: `${state.typeofDoc}${state.receiver_document_number}`,
            concept: state.concept,
            amount: priceToRequest(state.amount),
            bank: "third-bancamiga",
            code: state.code,
            user: state.user,
          },
          "third-other": {
            receiver_account: state.receiver_account,
            receiver_name: state.receiver_name,
            receiver_document: `${state.typeofDoc}${state.receiver_document_number}`,
            concept: state.concept,
            amount: priceToRequest(state.amount),
            bank: "third-other",
            code: state.code,
            user: state.user,
          },
          default: {},
        };

        const response = await walletAPI.bankTransfer(
          form[state.typeofTransferSelected] || form.default
        );
        const res = await response.json();
        if (res.error) throw res.error;
        changeState({ transferLoading: false });
        if (res.data && res.data.bankTransaction) {
          throwPopupMessage(
            "success",
            <TransferResult
              transferResult={res.data.bankTransaction}
              message={"Transacción realizada con éxito"}
              withdraw={withdraw}
              key={"message"}
            />
          );

          if (!withdraw) {
            changeState({
              fetchNewData: true,
              cleanup: true,
            });
          }
        } else {
          throwPopupMessage("success", "Transacción realizada con éxito");
          if (!withdraw) {
            changeState({
              fetchNewData: true,
              cleanup: true,
            });
          }
        }
      } catch (error) {
        throwPopupMessage(
          "error",
          error.description || "No se pudo ejecutar la orden"
        );
        changeState({ transferLoading: false });
        console.log(error);
      }
    };
    if (state.submit) {
      changeState({ submit: false });
      //closePopup();
      transfer();
    }
    return () => {};
  }, [state.submit]);

  useEffect(() => {
    if (withdraw.bank_details) {
      changeState({
        withdrawFilled: false,
      });
    }
  }, [withdraw]);
  const onGetCode = (event) => {
    event.preventDefault();
    throwPopupMessage("", <CodeForm key="code" />);
  };

  const handleBack = (event) => {
    event.preventDefault();
    changeState({ showRightSide: false });
  };

  const handleAhead = (event) => {
    event.preventDefault();
    changeState({ showRightSide: true });
  };
  return (
    <AdminTransfersContext.Provider
      value={{
        state,
        changeState,
        popupMessages,
        popupMessagesType,
        showPopup,
        throwPopupMessage,
        closePopup,
        onGetCode,
        handleBack,
        handleAhead,
        remoteLoading,
        withdraw,
      }}
    >
      {children}
    </AdminTransfersContext.Provider>
  );
};

export default AdminTransferState;
