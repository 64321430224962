import { AccountsInfoModel } from "./model";
import styled from "styled-components";

export const AccountsInfo = styled(AccountsInfoModel)`
  #root && {
    margin: 0;
    width: 100%;
    height: calc(20vh - 4rem);
    display: flex;
    flex-direction: column;
    padding: 1rem;
    justify-content: center;
    > .header {
      margin: 0;
      padding: 0;
      font-size: 1.5rem;
      > p {
        margin-left: 0.5rem;
        font-weight: bold;
        text-align: left;
      }
    }
    > .selection-options {
      display: flex;
      align-items: center;
      > .w-20 {
        margin-top: 0.5rem;
        width: 10%;
      }
    }
  }
`;
