import React, { useContext } from "react";
import { MobilePaidsContext } from "../../context";
import { EntitiesList, Pagination } from "components/entities-list";
import MobilePaidsListItem, {
  MobilePaidsListItemPhone,
} from "../mobile-paids-list-item";
import { Loader } from "components/loader";
import {
  recharge_validate as viewTexts,
  general as texts,
} from "assets/strings/texts";
import moment from "moment-timezone";

function formatMobilePaidsList(
  mobilePaids,
  viewState,
  handleMobilePaidSelection
) {
  if (mobilePaids.length < 1) {
    return (
      <div
        style={{
          marginTop: "0.5rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        No se encontraron transferencias
      </div>
    );
  }
  const componentToRender = {
    phone: MobilePaidsListItemPhone,
    desktop: MobilePaidsListItem,
  };
  const Items = componentToRender[viewState] || MobilePaidsListItem;

  const DateSeparatorComponent = ({ dateSeparator }) => {
    return (
      <dt className="date-separator">
        {viewTexts.rechargesOf}
        {dateSeparator.format(texts.longDateFormat)}
      </dt>
    );
  };
  return mobilePaids.reduce((result, mobilePaid, index) => {
    const date = moment(mobilePaid.movement_date).format("DD / MM / YYYY");
    const prevDate =
      index &&
      mobilePaids[index - 1] &&
      moment(mobilePaids[index - 1].movement_date).format("DD / MM / YYYY");

    if ((prevDate && date !== prevDate) || index === 0) {
      const dateSeparator = moment(mobilePaid.movement_date);
      return [
        ...result,
        <DateSeparatorComponent
          key={mobilePaid.movement_date}
          dateSeparator={dateSeparator}
        />,
        <Items
          mobilePaid={mobilePaid}
          key={`${mobilePaid._id}`}
          handleSelection={handleMobilePaidSelection}
          id={`${mobilePaid._id}`}
        />,
      ];
    }
    return [
      ...result,
      <Items
        mobilePaid={mobilePaid}
        key={`${mobilePaid._id}`}
        handleSelection={handleMobilePaidSelection}
        id={`${mobilePaid._id}`}
      />,
    ];
  }, []);

  /*   return mobilePaids.map((mobilePaid) => {
    return (
      <Items
        mobilePaid={mobilePaid}
        key={`${mobilePaid._id}`}
        handleSelection={handleMobilePaidSelection}
        id={`${mobilePaid._id}`}
      />
    );
  }); */
}

const MobilePaidsListModel = ({ className }) => {
  const { state, changeState, viewType } = useContext(MobilePaidsContext);

  const onMobilePaidSelection = (event) => {
    const mobilePaidID = event.currentTarget.id;
    const selectedMobilePaid = state.mobilePaids.find(
      (mp) => mp._id === mobilePaidID
    );
    changeState({
      selectedMobilePaid,
      showDetail: false,
    });
    if (selectedMobilePaid.status === "approved")
      changeState({ getMobilePaidDetail: true });
    setTimeout(() => {
      //to manage detail transition
      changeState({ showDetail: true });
    }, 150);
  };

  const handlePageUp = (event) => {
    event.preventDefault();
    changeState({
      formData: { ...state.formData, page: state.formData.page + 1 },
      submitForm: true,
    });
  };
  const handlePageDown = (event) => {
    event.preventDefault();
    changeState({
      formData: { ...state.formData, page: state.formData.page - 1 },
      submitForm: true,
    });
  };

  if (state.loading) return <Loader />;
  return (
    <div className={className}>
      <Pagination
        padding="0.65rem 0 0.65rem 0"
        nextPageExist={state.nextPageExists}
        currentPage={state.formData.page}
        handlePageUp={handlePageUp}
        handlePageDown={handlePageDown}
        height="52px"
      />
      <EntitiesList
        height="calc(67vh - 4rem)"
        viewState={viewType}
        entities={state.mobilePaids}
        formattingFunction={formatMobilePaidsList}
        selectionHandler={onMobilePaidSelection}
      ></EntitiesList>
    </div>
  );
};

export default MobilePaidsListModel;
