import React, { useEffect } from "react";
import { Dropdown } from "components/dropdown";
import { useStateManager } from "hooks/use-state-manager";
import { search } from "./services";

const CommerceAutocompleteModel = ({
  onChangeCommerces,
  allOptionAvailable = false,
  isMulti = true,
  category,
  className,
}) => {
  const initialOptions = allOptionAvailable
    ? [
        {
          key: "all",
          text: "Todos",
          value: "",
        },
      ]
    : [];
  const initialState = {
    options: [...initialOptions],
    initialOptions,
    commerces: [],
    selectedCommerce: null,
    searchQuery: "",
    loading: false,
    skip: 0,
    limit: 100,
    category,
  };
  const [state, changeState] = useStateManager(initialState);

  const onChange = (event, data) => {
    if (isMulti) {
      changeState({ commerces: [...data.value] });
      onChangeCommerces([...data.value]);
    } else {
      changeState({ commerce: data.value });
      onChangeCommerces(data.value);
    }
  };

  const onSearchChange = (event, data) => {
    changeState({ searchQuery: data.searchQuery });
  };

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (state.searchQuery.length > 1) {
        search(state, changeState);
      }
    }, 500);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [state.searchQuery]);

  return (
    <div className={className}>
      <Dropdown
        placeholder="Buscar comercio"
        search={(options) => (options ? options : [])}
        selection
        searchQuery={state.searchQuery}
        loading={state.loading}
        id="commerces"
        options={state.options}
        multiple={isMulti}
        onSearchChange={onSearchChange}
        onChange={onChange}
        noResultsMessage={
          state.loading ? "Consultando..." : "No se encontraron resultados"
        }
        style={{ width: "100%" }}
        searchInput={{ autoComplete: "off" }}
      />
    </div>
  );
};

export default CommerceAutocompleteModel;
