import React, { useEffect } from "react";
import { useDates, useTextInput } from "hooks/use-inputs";
import { useFilterButtons } from "hooks/use-status-buttons";
import { useViewType } from "hooks/use-view-type";
import { useDropdown } from "hooks/use-dropdown";
import { usePopupMessages } from "hooks/use-popup-messages";
import {useStateManager} from "hooks/use-state-manager";

import { connect } from "react-redux";
import { clearReduxStore } from "redux/actions/general";
import { getSelectedCommerce } from "redux/selectors";

import { commerceTransactions } from "services/api";
import { MposMovementsHistoryView } from "./components/mpos-movements-history-view";
import { errors } from "assets/strings/texts";

const MPosCommerceMovements = (props) => {
  const operatorPermissions = props.permissions;
  const commerceName = props.selectedCommerce.name;
  const movementsPerPage = 10;
  const limit = movementsPerPage + 1;

  const initialState = {
    loading: false,
    currentPage: 1,
    nextPageExist: false,
    movements: [],
    selectedMovement: null,
    viewState: "search_movements",
    getMovements: false,
    skip: 1,
  }
  const [state, changeState] = useStateManager(initialState);


  const [viewType, setViewType] = useViewType("desktop");
  const {
    popupMessages,
    popupMessagesType,
    showPopup,
    throwPopupMessage,
    closePopup,
  } = usePopupMessages(null, "", false); // (messages, messageType, showPopup)
  const { fromDate, endDate, handleFromDate, handleEndDate } = useDates(
    null,
    null
  );
  const {
    searchType,
    searchFilter,
    handleSearchType,
    handleSearchFilter,
  } = useFilterButtons([{ searchType: "latests" }, { searchFilter: "" }]);
  const { value: user, onChange: handleChangeUser } = useTextInput("").bind;
  const { value: status, onChange: handleChangeStatus } = useDropdown(
    "all"
  ).bind;
  const statusOptions = [
    {
      key: "approved",
      text: "Aprobados",
      value: "approved",
    },
    {
      key: "pending",
      text: "Pendientes",
      value: "pending",
    },
    {
      key: "rejected",
      text: "Rechazados",
      value: "rejected",
    },
    {
      key: "reversed",
      text: "Devueltos",
      value: "reversed",
    },
    {
      key: "all",
      text: "Todos",
      value: "all",
    },
  ];


  useEffect(() => {
    setViewType();
    changeState({getMovements: true});
    window.addEventListener("resize", setViewType);
    return () => {
      window.removeEventListener("resize", setViewType);
    };
  }, []);

  useEffect(() => {
    const getMovementsRequest = async() =>{
      try{
        changeState({loading: true, viewState:"movements_search_loading"});
        const response = await commerceTransactions({
          fromDate: searchType === "latests" ? null : fromDate.toISOString(),
          endDate: searchType === "latests" ? null : endDate.toISOString(),
          status:status !== "all" ? (status !== "reversed" ? status : null) : null,
          type: searchFilter,
          transaction_tag: status === "reversed" ? "reverse" : "",
          query: user,
          limit: limit,
          skip: state.skip,
        });

        const res = await response.json();
        if (res.error) throw res.error;
        const nextPageExist = res.transactions.length > movementsPerPage;
        changeState({
          nextPageExist,
          movements: res.transactions,
          viewState: "movements_search_done",
          loading: false,
        })
      }catch(error){
        console.log({error});
        changeState({loading: false});
        if (
            error.id === "NO_TOKEN_PROVIDED" ||
            error.id === "NO_TOKEN_PROVIDED" ||
            error.id === "DUPLICATE_SESSION"
        ) {
          throwPopupMessage("error", errors.AUTHENTICATION);
        }else{
          throwPopupMessage("error", error.message || "Ha ocurrido un error intente más tarde");
        }
      }
    }
    if(state.getMovements){
      getMovementsRequest();
      changeState({getMovements: false});

    }
  }, [state.getMovements])


  const handleSubmit = (event) => {
    event.preventDefault();
    changeState({getMovements: true});
  };

  const handleMovementSelection = (event) => {
    let index = event.currentTarget.id;
    if (
      state.selectedMovement !==
      state.movements[index]
    ) {
      setTimeout(() => {
        changeState({
          viewState: "movement_selection_done",
          selectedMovement: state.movements[index],
        });
      });
    } else {
      setTimeout(() => {
        changeState({
          viewState: "movements_search_done",
          selectedMovement: null,
        });
      });
    }
  };

  const handlePageDown = (event) => {
    event.preventDefault();
    let skip = (state.skip - 1);
    changeState({skip, currentPage: state.currentPage - 1, nextPageExist: true, getMovements:true});
  };
  const handlePageUp = (event) => {
    event.preventDefault();
    let skip = state.skip + 1;
    changeState({skip, currentPage: state.currentPage + 1, nextPageExist: true, getMovements: true})
  };
  return (
    <MposMovementsHistoryView
      //component data
      operatorPermissions={operatorPermissions}
      commerceName={commerceName}
      viewType={viewType}
      loading={state.loading}
      currentPage={state.currentPage}
      nextPageExist={state.nextPageExist}
      viewState={state.viewState}
      movements={state.movements}
      selectedMovement={state.selectedMovement}
      //form data
      searchType={searchType}
      searchFilter={searchFilter}
      fromDate={fromDate}
      endDate={endDate}
      user={user}
      status={status}
      statusOptions={statusOptions}
      //methods
      handleSearchType={handleSearchType}
      handleSearchFilter={handleSearchFilter}
      handleFromDate={handleFromDate}
      handleEndDate={handleEndDate}
      handleChangeUser={handleChangeUser}
      handleChangeStatus={handleChangeStatus}
      handleSubmit={handleSubmit}
      handlePageDown={handlePageDown}
      handlePageUp={handlePageUp}
      handleMovementSelection={handleMovementSelection}
      //popup
      popupMessages={popupMessages}
      popupMessagesType={popupMessagesType}
      showPopup={showPopup}
      closePopup={closePopup}
    />
  );
};

const mapStateToProps = (state) => {
  let selectedCommerce = getSelectedCommerce(state);
  return { selectedCommerce };
};

const mapDispatchToProps = {
  clearReduxStore,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MPosCommerceMovements);
