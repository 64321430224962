import styled from "styled-components";
import { AccessesSettingsModel } from "./model";
import { textualBlue } from "assets/strings/colors";

const AccessesSettings = styled(AccessesSettingsModel)`
  #root & {
    > .title {
      margin: 0;
      text-align: center;
      font-size: 1.5rem;
      color: ${textualBlue};
    }
    > .buttons {
      padding-left: 20%;
      > button {
        width: 35%;
        display: inline-block;
        margin: 1rem 0.5rem 0 0;
      }
    }

    @media (max-width: 576px) {
      > .title {
        font-size: 1.5rem;
      }
      > button {
        height: 2.6rem;
      }
      > input {
        width: 100%;
        font-size: 1.4rem;
      }
    }
  }
`;
export { AccessesSettings };
