import { ValidationModel } from "./model";
import styled from "styled-components";
import { grey_2, grey_4, bluePD, magenta } from "assets/strings/colors";

const Validation = styled(ValidationModel)`
  #root && {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: ${(props) => {
      return props.viewType === "phone" ? "90%" : "50%";
    }};
    margin: -2rem 0;
    > .panel {
      margin: 0;
      height: 80%;
      border: 1px solid ${grey_2};
      border-radius: 0.2rem;
      background: white;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 1rem;
      width: 80%;

      > .figure {
        width: 100%;
        text-align: center;
        padding: 1rem 0;
        > .compress {
          background: red;
          width: 70px;
          height: 70px;
          border-radius: 1rem;
        }
      }
      > .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        > .header {
          margin: 0;
          font-size: 1rem;
          color: ${grey_4};
        }
        > .description {
          margin: 0;
          margin-bottom: 0.5rem;
          font-size: 0.9rem;
          text-align: center;
        }
      }
      > .verified {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        > .description {
          margin: 0;
          margin-bottom: 0.5rem;
          padding: 0.5rem 1rem;
          font-size: 1.1rem;
          text-align: center;
          text-transform: uppercase;
          font-weight: bold;
          border-radius: 0.5rem;
          background: ${grey_4};
          color: white;
        }
      }
      > .title {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        > .header {
          margin: 0;
          font-size: 1rem;
          color: ${grey_4};
        }
        > .description {
          margin: 0;
          margin-bottom: 0.5rem;
          font-size: 1.1rem;
          text-align: center;
        }
      }
    }
    > button {
      cursor: pointer;
      font-weight: bold;
      border: none;
      color: white;
      border-radius: 3rem;
      //background: linear-gradient(to right, ${magenta}, ${bluePD});
      background: ${bluePD};
      height: 10%;
      width: 70%;
      :focus {
        outline: none;
        opacity: 0.7;
      }
    }
  }
`;

export { Validation };
