import React, { useContext } from "react";
import { SuperadminBillsContext } from "../../context";
import { useStateManager } from "hooks/use-state-manager";
import { Checkbox } from "semantic-ui-react";
import CurrencyInput from "react-currency-input-field";

const LinesFormModel = ({ className, name }) => {
  const { state, changeState } = useContext(SuperadminBillsContext);
  const names = {
    operation: {
      text: "Operación",
      code: "000001",
      description: "Recaudación en cuenta de terceros: Estacionamientos",
    },
    "pd-fee": {
      text: "Servicio PagoDirecto",
      code: "000002",
      description: "Servicio PagoDirecto",
    },
  };
  const initialState = {
    name,
    description: names[name].description,
    qty: "",
    amount_bs: 0,
    amount_usd: 0,
    is_exempt: names[name].code === "000001",
    code: names[name].code,
    isActive: true,
  };
  const [formState, changeFormState] = useStateManager(initialState);

  const updateParentState = (obj) => {
    changeState({
      lines: state.lines.map((line) => {
        return line.name !== name ? line : obj;
      }),
    });
  };
  const onChangeAmountUSD = (value) => {
    const numberValue = parseFloat(value ? value.replace(",", ".") : "0");
    const stateRecovery = {
      ...formState,
      amount_usd: value,
      amount_bs: (numberValue * state.exchange_usd_to_bs).toFixed(5).toString(),
    };
    changeFormState(stateRecovery);
    updateParentState(stateRecovery);
  };
  const onChangeAmountVES = (value) => {
    const stateRecovery = {
      ...formState,
      amount_bs: value,
    };
    changeFormState(stateRecovery);
    updateParentState(stateRecovery);
  };

  const onChangeQty = (event) => {
    const value = event.target.value.replace(/[^0-9\s]/g, "");

    const stateRecovery = {
      ...formState,
      qty: value,
    };
    changeFormState(stateRecovery);
    updateParentState(stateRecovery);
  };
  const onChangeDescription = (event) => {
    const key = event.target.name;
    const value = event.target.value;

    if (key === "code") {
      const stateRecovery = {
        ...formState,
        code: value.replace(/[^0-9\s]/g, ""),
      };
      changeFormState(stateRecovery);
      updateParentState(stateRecovery);
    } else {
      const stateRecovery = {
        ...formState,
        [key]: value,
      };
      changeFormState(stateRecovery);
      updateParentState(stateRecovery);
    }
  };

  const onExemptChange = (event, data) => {
    const stateRecovery = {
      ...formState,
      is_exempt: data.checked,
    };
    changeFormState(stateRecovery);
    updateParentState(stateRecovery);
  };
  return (
    <div className={className}>
      <p className={"title"}>{names[name].text || "Operación"}</p>
      <form>
        <section>
          <Checkbox
            toggle
            checked={formState.isActive}
            label="Editar"
            onChange={(event, data) => {
              const stateRecovery = {
                ...formState,
                isActive: data.checked,
              };
              changeFormState(stateRecovery);
              updateParentState(stateRecovery);
            }}
          />
        </section>
        <div className="columns-inputs">
          <div className="column">
            <section className="content">
              <label htmlFor="qty">Cantidad:</label>
              <input
                type="text"
                value={formState.qty}
                onChange={onChangeQty}
                name="qty"
                required
                disabled={!formState.isActive}
              />
            </section>
            <section className="content">
              <label htmlFor="code">Código:</label>
              <input
                type="text"
                value={formState.code}
                //value={names[name].code}
                onChange={onChangeDescription}
                name="code"
                maxLength={6}
                required
                disabled={!formState.isActive}
                //disabled
              />
            </section>
          </div>
          <div className="column">
            <section className="content">
              <label htmlFor="amount_bs">Monto en VES por unidad:</label>
              <CurrencyInput
                prefix={"Bs. "}
                value={formState.amount_bs}
                defaultValue={0}
                decimalsLimit={5}
                decimalSeparator=","
                groupSeparator="."
                onValueChange={onChangeAmountVES}
                name="amount_bs"
                required
                disabled={
                  !formState.isActive || state.fiscalForm.currency === "USD"
                }
              />
            </section>
            <section className="content">
              <label htmlFor="amount_usd">Monto en USD:</label>
              <CurrencyInput
                prefix={"$ "}
                value={formState.amount_usd}
                defaultValue={0}
                decimalsLimit={5}
                decimalSeparator=","
                groupSeparator="."
                onValueChange={onChangeAmountUSD}
                name="amount_usd"
                required
                disabled={!formState.isActive}
              />
            </section>
          </div>
        </div>
        <section className="description">
          <label htmlFor="description">Descripción:</label>
          <input
            type="text"
            value={formState.description}
            onChange={onChangeDescription}
            name="description"
            required
          />
        </section>
        <section className="exempt">
          <Checkbox
            checked={formState.is_exempt}
            label="Exento"
            onChange={onExemptChange}
          />
        </section>
      </form>
    </div>
  );
};

export default LinesFormModel;
