import React from "react";
import { Grid } from "semantic-ui-react";
import { grey_3 } from "assets/strings/colors";
import { InvoicesPanel } from "./invoices-panel";
import { WithdrawPanel } from "./withdraw-panel";
import { MovementsPanel } from "./movements-panel";
import { UsersPanel } from "./users-panel";
import { CommercePanel } from "./commerce-panel";
import { CommercesSearchPanel } from "./commerces-search-panel";
import { PrepaidTicketsPanel } from "./prepaid-tickets-panel";

const searchColumStyle = {
  borderRight: `1px solid ${grey_3}`,
  height: "calc(100vh - 4rem)",
};

const MposCommercesView = ({
  viewState,
  selectedCommerce,
  transactionTags,
}) => {
  return (
    <Grid padded>
      <Grid.Column mobile={12} tablet={4} computer={4} style={searchColumStyle}>
        <CommercesSearchPanel />
      </Grid.Column>

      <Grid.Column mobile={16} tablet={6} computer={6}>
        {(viewState === "commerce_info" ||
          viewState === "commerce_info_loading" ||
          viewState === "commerce_invoice" ||
          viewState === "commerce_invoice_loading" ||
          viewState === "commerce_invoice_done" ||
          viewState === "commerce_invoice_payment" ||
          viewState === "commerce_invoice_payment_loading" ||
          viewState === "commerce_withdraw" ||
          viewState === "commerce_withdraw_loading" ||
          viewState === "commerce_withdraw_done" ||
          viewState === "commerce_withdraw_payment" ||
          viewState === "commerce_withdraw_payment_loading" ||
          viewState === "commerce_users" ||
          viewState === "commerce_getusers_loading" ||
          viewState === "commerce_getusers_done" ||
          viewState === "commerce_show_movements" ||
          viewState === "commerce_user_reset" ||
          viewState === "commerce_user_reset_loading") && <CommercePanel />}
      </Grid.Column>

      <Grid.Column mobile={16} tablet={6} computer={6}>
        {(viewState === "commerce_info" ||
          viewState === "commerce_info_loading" ||
          viewState === "commerce_invoice" ||
          viewState === "commerce_invoice_loading" ||
          viewState === "commerce_invoice_done" ||
          viewState === "commerce_invoice_payment" ||
          viewState === "commerce_invoice_payment_loading") &&
          selectedCommerce.parking &&
          selectedCommerce.category !== "estacionamiento" &&
          selectedCommerce.parking.billing_type === "postpaid" && (
            <InvoicesPanel />
          )}

        {(viewState === "commerce_info" ||
          viewState === "commerce_info_loading") &&
          selectedCommerce.category !== "estacionamiento" &&
          selectedCommerce.parking &&
          selectedCommerce.parking.billing_type === "prepaid" && (
            <PrepaidTicketsPanel />
          )}
        {(viewState === "commerce_withdraw" ||
          viewState === "commerce_withdraw_loading" ||
          viewState === "commerce_withdraw_done" ||
          viewState === "commerce_withdraw_payment" ||
          viewState === "commerce_withdraw_payment_loading") && (
          <WithdrawPanel />
        )}
        {viewState === "commerce_show_movements" && (
          <MovementsPanel transactionTags={transactionTags} />
        )}
        {(viewState === "commerce_users" ||
          viewState === "commerce_getusers_loading" ||
          viewState === "commerce_getusers_done" ||
          viewState === "commerce_user_reset" ||
          viewState === "commerce_user_reset_loading") && <UsersPanel />}

        {(viewState === "commerce_info" ||
          viewState === "commerce_info_loading") &&
          (!selectedCommerce.parking ||
            selectedCommerce.category === "estacionamiento") && (
            <p
              style={{
                textAlign: "center",
                fontSize: "1.2rem",
                margin: "1.5rem 3rem 0 3rem",
              }}
            >
              Este comercio, no tiene habilitadas las funciones de facturación
            </p>
          )}
      </Grid.Column>
    </Grid>
  );
};
export { MposCommercesView };
