import { useReducer } from "react";

function reducer(state, action) {
  switch (action.type) {
    case "change":
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
}

const useStateManager = (initialState) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const changeState = (obj) => {
    dispatch({ type: "change", payload: { ...obj } });
  };
  return [state, changeState];
};

export { useStateManager };
