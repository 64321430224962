import React from "react";
import DatePicker from "react-datepicker";
import { general as texts } from "assets/strings/texts";
import moment from "moment";

export const DateRangeModel = ({
  fromDateReport,
  endDateReport,
  handleFromDateReport,
  handleEndDateReport,
  phoneView,
  className,
}) => {
  return (
    <div className={className}>
      <DatePicker
        className="date-picker"
        placeholderText={texts.from}
        dateFormat={texts.shortDateFormat}
        showMonthDropdown
        showYearDropdown
        todayButton={texts.today}
        isClearable
        withPortal={phoneView}
        selected={fromDateReport}
        maxDate={
          endDateReport ? moment(endDateReport).subtract(23, "hours") : moment()
        }
        onChange={handleFromDateReport}
      />
      <p>/</p>
      <DatePicker
        className="date-picker"
        placeholderText={texts.to}
        dateFormat={texts.shortDateFormat}
        popperPlacement="bottom-end"
        showMonthDropdown
        showYearDropdown
        todayButton={texts.today}
        isClearable
        withPortal={phoneView}
        selected={
          endDateReport ? moment(endDateReport).subtract(23, "hours") : null
        }
        minDate={
          fromDateReport ? moment(fromDateReport).add(23, "hours") : null
        }
        maxDate={moment()}
        onChange={handleEndDateReport}
      />
    </div>
  );
};
