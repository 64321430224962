import { useEffect } from "react";
import { formatPrice, amountsToObject } from "services/utils";
import { getOptions } from "../services";
import { getMovementsRequest } from "../services";

const verifyAmount = (amounts) => amountsToObject(amounts).BS.funds;
const usePropsMonitor = ({
  walletAccounts,
  state,
  changeState,
  throwPopupMessage,
}) => {
  const getAccountsOptions = () =>
    walletAccounts.map((account) => ({
      key: account._id,
      text: `${account.name}: ${formatPrice(
        verifyAmount(account.amounts)
      )} VES`,
      value: account._id,
    }));

  useEffect(() => {
    const accountsMappedInOptions = getAccountsOptions();
    if (walletAccounts && walletAccounts.length && state.onMount) {
      getMovementsRequest(
        accountsMappedInOptions[0].value,
        state,
        changeState,
        throwPopupMessage
      );
      changeState({
        onMount: false,
      });
    }
    if (state.getMovements) {
      getMovementsRequest(
        state.accountSelected,
        state,
        changeState,
        throwPopupMessage
      );
      changeState({
        getMovements: false,
      });
    }
    if (accountsMappedInOptions.length) {
      changeState({
        accountsMappedInOptions,
        accountSelected: !state.accountSelected
          ? accountsMappedInOptions[0].value
          : state.accountSelected,
        accountOptions: getOptions(
          walletAccounts.find(
            (account) => account._id === state.accountSelected
          )
        ),
      });
    }
  }, [walletAccounts, state.getMovements]);
};

export default usePropsMonitor;
