import styled from 'styled-components'
import { SettingsHeaderModel } from './model'
import { textualBlue } from 'assets/strings/colors'

const SettingsHeader = styled(SettingsHeaderModel)`
  #root &{
    text-align: center;
    color: ${textualBlue};
    >.title{
      font-size: 1.5rem;
    }
    >.building-name{
      font-size: 2rem;
    }
  }
`
export { SettingsHeader }