import styled from "styled-components";
import { LogsPanelModel, LogHeaderModel, LogListItemModel } from "./model";
import { grey_1, grey_4, grey_6, bluePD, green, red } from "assets/strings/colors";

const LogsPanel = styled(LogsPanelModel)`
  #root & {
    display: table;
    position: absolute;
    top: 0;
    left: 10%;
    height: 100%;
    width: 80%;
    > .panel-form {
      > .form-segment {
        > .titles {
          margin: 0;
          margin-top: 0.7rem;
          display: flex;
          > p {
            margin: 0.5rem 3px 0.5rem 3px;
            color: ${grey_6};
            display: block;
            width: 50%;
            font-size: 1.2rem;
            border-bottom: solid 1px ${grey_4};
          }
        }
        > .dropdown-items {
          margin: 0;
          margin-top: 0.7rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          > .drop-width {
            width: calc(50% - 1rem);
          }
        }

        > .date-pickers {
          margin: 0;
          margin-top: 0.7rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          > p {
            margin: 0;
            font-weight: bold;
          }
          > div {
            width: 46%;
            border-bottom: solid 1px ${grey_4};
            > .react-datepicker__input-container {
              width: 100%;
              > input {
                width: calc(100% - 16px);
                border: none;
                border-radius: 1rem;
                color: ${grey_6};
                font-size: 1rem;
                margin: 0.5rem 5px 0.5rem 5px;
                text-align: center;
                outline: none;
                box-shadow: none;
                transition: box-shadow 250ms linear;
                :focus {
                  box-shadow: 0 1px darkgrey;
                }
              }
              > .react-datepicker__close-icon::after {
                right: 0;
              }
            }
            > .react-datepicker-wrapper {
              width: 100%;
              > .react-datepicker__input-container {
                width: 100%;
                > input {
                  width: calc(100% - 16px);
                  border: none;
                  border-radius: 1rem;
                  color: ${grey_6};
                  font-size: 1rem;
                  margin: 0.5rem 5px 0.5rem 5px;
                  text-align: center;
                  outline: none;
                  box-shadow: none;
                  transition: box-shadow 250ms linear;
                  :focus {
                    box-shadow: 0 1px darkgrey;
                  }
                }
                > .react-datepicker__close-icon::after {
                  right: 15px;
                }
              }
            }
          }
          > div:last-child {
            > .react-datepicker-popper {
              > .react-datepicker {
                > .react-datepicker__triangle {
                  right: 50px;
                  left: auto;
                }
              }
            }
          }
        }
      }
    }
    @media (max-width: 750px) {
      > .panel-form {
        > .form-segment {
          > .dropdown-items {
            margin: 0;
            margin-top: 0.7rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            > .drop-width {
              width: calc(100% - 1rem);
            }
          }
        }
      }
    }
  }
`;

const LogHeader = styled(LogHeaderModel)`
  margin: 0;
  border-bottom: 2px solid ${bluePD};
  > .col {
    color: ${grey_4};
    margin: 1.2rem 0.5rem 0 0.5rem;
    padding-bottom: 0.3rem;
    display: inline-block;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
  }
  > .col-parking {
    color: ${grey_4};
    margin: 1.2rem 0 0 0;
    padding-bottom: 0.3rem;
    display: inline-block;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold;
  }
  > .forty {
    width: calc(40% - 1rem);
  }
  > .twenty {
    width: calc(20% - 1rem);
  }
  > .thirty {
    width: calc(33% - 1rem);
  }
`;

const LogListItem = styled(LogListItemModel)`
  margin: 0;
  border-bottom: 1px solid ${grey_4};
  cursor: pointer;
  padding-bottom: 0.3rem;
  padding-top: 1rem;
  display: flex;
  text-align: center;
  :hover {
    background: ${grey_1};
  }
  &::before {
    content: "";
    width: 5px;
    background: ${(props) => {
      return props.status === "success" ? green : red;
    }};
  }
  > .user {
    margin: 0;
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > .username {
      margin: 0;
      color: ${bluePD};
      text-align: center;
    }
    > .status {
      margin: 0;
      font-size: 0.6em;
      width: 70%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  > .access {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20%;
    font-size: 0.8em;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  > .building {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20%;
    font-size: 0.8em;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  > .time {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20%;
    padding: 0 0.5rem 0 0.5rem;
    text-align: center;
    font-size: 0.8rem;
  }
  > .connection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20%;
    padding: 0 0.5rem 0 0.5rem;
    text-align: center;
    font-size: 0.8rem;
  }
  > .thirty {
    width: calc(33% - 1rem);
  }
  > .column {
    width: 100%;
    font-size: 0.8rem;
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
    > .user {
      > b {
        color: black;
      }
      color: ${bluePD};
    }
    > .access {
      > b {
        color: black;
      }
    }
    > .building {
      > b {
        color: black;
      }
    }
    > .date {
      > b {
        color: black;
      }
    }
    > .hour {
      > b {
        color: black;
      }
    }
  }
`;
export { LogsPanel, LogHeader, LogListItem };
