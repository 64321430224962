import React from "react";
import DashboardSectionModel from "./model";
import styled, { keyframes } from "styled-components";
import { grey_5 } from "assets/strings/colors";

const loadTitles = keyframes`
  0%{
    margin-left: 200%; 
    margin-right: 200%; 
  }
  99%{
    margin-left: 0;
    margin-right: 0;
    white-space: nowrap;
  }
  100%{
    margin-left: 0;
    margin-right: 0;
    white-space: normal;
  }
`;

const DashboardSection = styled(DashboardSectionModel)`
  color: #2c304d;
  .container{
    width:${(props) => {
      let width = props.width ? props.width : "80%";
      return `${width}`;
    }};
    margin: 0 auto 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    margin-top: 1rem;
    margin-bottom: 1rem;
    .section-title{
      overflow: hidden;
      white-space: nowrap;
      font-size: 1.8rem;
      border-bottom: 1px solid ${grey_5};
      margin: 0 0 .6rem 0;
      flex-basis: 100%;
      animation: ${loadTitles} 1.5s forwards;
    }
    .section-item{
      transform: scale(1,1);
      transition: all .07s linear;
      width: ${(props) => {
        let elemPerRow = props.elemPerRow
          ? props.elemPerRow
          : React.Children.count(props.children);
        let ratio = React.Children.count(props.children) / elemPerRow;
        return `calc(${
          (ratio * 100) / React.Children.count(props.children)
        }% - 1rem)`;
      }};
      min-width: 110px;
      border-radius: .2rem;
      margin: 0.5rem;
      background: white;
      box-shadow: 1px 2px 8px 0 rgba(34,36,38,.4);
    }
    .section-item:hover{
        transform: scale(1.03,1.03)
      }
  @media (max-width: 768px){
    #root &{
      .container{
        width: 95%
      }
      .section-item{
      width: ${(props) => {
        let elemPerRow = props.elemPerPhoneRow
          ? props.elemPerPhoneRow
          : React.Children.count(props.children);
        let ratio = React.Children.count(props.children) / elemPerRow;
        return `calc(${
          (ratio * 100) / React.Children.count(props.children)
        }% - 1rem)`;
      }};
      }
    }
  }
`;
export default DashboardSection;
