import { MovementDetailsModel } from "./model";
import styled from "styled-components";
import { grey_4, grey_6 } from "assets/strings/colors";

const MovementDetails = styled(MovementDetailsModel)`
  #root && {
    display: flex;
    flex-direction: column;
    align-items: center;
    > .info {
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      > .header {
        margin: 0;
        padding: 1.5rem;
        display: flex;
        width: 100%;
        text-align: center;
        font-size: 1.2rem;
        text-transform: uppercase;
        color: ${grey_6};
        font-weight: bold;
        > p {
          width: 100%;
        }
      }
      > .title {
        margin: 0;
        margin-top: 0.5rem;
        align-self: flex-start;
        border-bottom: 1px solid ${grey_4};
        font-size: 1rem;
        color: ${grey_6};
        font-weight: bold;
      }
      > .content {
        margin: 0;
        margin-top: 0.3rem;
        width: 100%;
        text-align: center;
        font-size: 1rem;
        color: ${grey_6};
      }
    }
  }
`;

export { MovementDetails };
