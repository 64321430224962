import React from "react";
import { useResponsive } from "hooks/use-responsive/hook";
import { TransfersState } from "./context";
import TransfersPhoneView from "./containers/transfers-phone-view";
import TransfersView from "./containers/transfers-view";

const MPosUsersMovementsTransfers = () => {
  const viewType = useResponsive();
  const view = {
    phone: <TransfersPhoneView />,
    desktop: <TransfersView />,
  };
  return <TransfersState viewType={viewType}>{view[viewType]}</TransfersState>;
};

export default MPosUsersMovementsTransfers;
