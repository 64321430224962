import React, { useContext } from "react";
import { Grid } from "semantic-ui-react";
import { PopupMessageContext } from "components/popup-message";
import { WalletTransferHistoryContext } from "../context";
import { HistoryForm } from "./history-form";
import { History } from "./history";
import { RightBorderGreyColumn } from "components/styled-grid-column";
import { TransferDetail } from "./transfer-detail";
import WeekendDate from "./weekend-date";

const WalletTransferHistoryView = ({ phoneView }) => {
  const {
    state: { showDetail },
  } = useContext(WalletTransferHistoryContext);

  if (phoneView && !showDetail) {
    return (
      <Grid centered padded>
        <PopupMessageContext context={WalletTransferHistoryContext} />
        <Grid.Column width={16}>
          <WeekendDate phoneView={phoneView} />
          <HistoryForm phoneView={phoneView} />
          <History phoneView={phoneView} />
        </Grid.Column>
      </Grid>
    );
  }

  if (phoneView && showDetail) {
    return (
      <Grid centered padded>
        <PopupMessageContext context={WalletTransferHistoryContext} />
        <Grid.Column width={16}>
          <TransferDetail phoneView={phoneView} />
        </Grid.Column>
      </Grid>
    );
  }
  return (
    <Grid centered padded>
      <PopupMessageContext context={WalletTransferHistoryContext} />
      <RightBorderGreyColumn width={8} style={{ height: "calc(100vh - 4rem)" }}>
        <WeekendDate />
        <HistoryForm />
        <History />
      </RightBorderGreyColumn>
      <Grid.Column width={8}>
        <TransferDetail />
      </Grid.Column>
    </Grid>
  );
};

export default WalletTransferHistoryView;
