import React from "react";
import { bluePD, purplePD_2  } from "assets/strings/colors";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const CustomGraphModel = ({ data, lines }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        width={730}
        height={350}
        data={data}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={bluePD} stopOpacity={0.4} />
            <stop offset="95%" stopColor={bluePD} stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={purplePD_2} stopOpacity={0.4} />
            <stop offset="95%" stopColor={purplePD_2} stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="Periodo Actual"
          stroke={bluePD}
          fillOpacity={1}
          fill="url(#colorUv)"
        />
        {lines > 1 && (
          <Area
            type="monotone"
            dataKey="Periodo Anterior"
            stroke={purplePD_2}
            fillOpacity={1}
            fill="url(#colorPv)"
          />
        )}
        {lines > 1 && <Legend verticalAlign="top" align="right" height={36} />}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default CustomGraphModel;
