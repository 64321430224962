import { micropd_01 } from "services/config";
import { post, postFormData } from "services/http-methods";

const endpoints = {
  history: `${micropd_01}/superadmin/coupon/list`,
  create: `${micropd_01}/superadmin/coupon/create`,
};

export const history = (body) => {
  return post(endpoints.history, body);
};

export const create = (formData) => {
  return postFormData(endpoints.create, formData);
};
