import { micropd_02 } from "services/config";
import { post } from "services/http-methods";

const endpoints = {
  list: `${micropd_02}/superadmin/bank-movement/list`,
};

export function list(body) {
  /**
   * @brief
   * @attributes
   *  body = {
   *
   */
  return post(endpoints.list, body);
}
