import { useEffect, useState } from "react";

export const useFetch = (service) => {
  const [loading, setLoading] = useState(false);
  const [activateFetch, setActivateFetch] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [args, setArgs] = useState(null);

  useEffect(() => {
    if (activateFetch) {
      setLoading(true);
      setActivateFetch(false);
      service(args).then(
        (data) => {
          setData(data);
          setLoading(false);
        },
        (err) => {
          setError(err);
          setLoading(false);
        }
      );
    }
  }, [activateFetch]);

  return { loading, error, data, setActivateFetch, setArgs };
};
