import { CurrencyViewerModel } from "./model";
import styled from "styled-components";
import { grey_6 } from "assets/strings/colors";

export const CurrencyViewer = styled(CurrencyViewerModel)`
  #root & {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-self: center;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 1rem;
    > .header {
      margin: 0;
      text-align: center;
      font-size: 1.5rem;
      padding: 1rem 0;
    }
    > .content {
      margin: 0;
      text-align: center;
      font-size: 1.2rem;
      color: ${grey_6};
    }
  }
`;
