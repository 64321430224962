import { popup_transition_time } from 'assets/strings/constants'
import { store } from 'redux/store'

export const POPUP_MESSAGE_THROW = 'POPUP_MESSAGE_THROW'
export const POPUP_MESSAGE_CLOSE = 'POPUP_MESSAGE_CLOSE'
export const POPUP_MESSAGE_CLEAR = 'POPUP_MESSAGE_CLEAR'
export const POPUP_MESSAGE_SET_BEFORE_CLOSE = 'POPUP_MESSAGE_SET_BEFORE_CLOSE'

export const throwPopupMessage = (type, messages) => ({
  type: POPUP_MESSAGE_THROW,
  payload: {
    messages,
    type
  } 
})

export const closePopup = () => dispatch => {
  if (store && typeof store.getState === 'function') {
    const state = store.getState()
    if (
      state
      && state.popupMessage
      && state.popupMessage.onBeforeClose
      && typeof state.popupMessage.onBeforeClose === 'function'
    ) {
      state.popupMessage.onBeforeClose();
    }
  }

  dispatch({ type: POPUP_MESSAGE_CLOSE })

  setTimeout(() => {
    dispatch({ type: POPUP_MESSAGE_CLEAR })
  }, popup_transition_time);
}

export const setOnBeforeClosePopup = onBeforeClose => ({
  type: POPUP_MESSAGE_SET_BEFORE_CLOSE,
  payload: {
    onBeforeClose,
  } 
})