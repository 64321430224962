import React from 'react'
import { Grid } from 'semantic-ui-react'

const StyledGridColumnModel = (props) => {
  return(
    <Grid.Column {...props} >
    </Grid.Column>
  )
}

export default StyledGridColumnModel