//import { bancamigaTransfer, banescoTransfer } from "./mock";
import { baseURL_V2, baseURL } from "services/config";
import { patch, get, post } from "services/http-methods";

const endpoints = {
  constants: `${baseURL}/constants`, //get
  patchRechargePending: `${baseURL_V2}/superadmin/recharges/pending`,
  listMobilePaids: `${baseURL_V2}/superadmin/recharges/mobilepaid`, //get
  mobilePaidDetail: `${baseURL_V2}/superadmin/recharges/mobilepaid/details`,
};

/* function mockResponse() {
  const transfers = new Array(10).fill(bancamigaTransfer);
  transfers.fill(banescoTransfer, 6, 10);

  const data = {
    ok: true,
    transfers,
  };
  const blob = new Blob([JSON.stringify(data)]);
  return new Response(blob);
}

function mockStatusResponse() {
  const data = {
    ok: true,
  };
  const blob = new Blob([JSON.stringify(data)]);
  return new Response(blob);
}
 */

export function listMobilePaids(queryParams) {
  /**
   * @brief
   * @attributes
   *  queryParams = {
   *    phone,
   *    amount,
   *    reference,
   *    page: skip,
   *    show: limit,
   *    type,
   *    status: [],
   */

  return get(endpoints.listMobilePaids, queryParams, true);
}

export function getConstants() {
  return get(endpoints.constants, {}, true);
}

export function changeStatus(body) {
  /**
   * @payload
   * @body {
    id: rechargeId,
    recharge_id: rechargeId,
    username: user,
    payment_ref,
  };
   *
   * 
   */
  return patch(endpoints.patchRechargePending, body);
}

export function getMobilePaidDetail(id) {
  return post(`${endpoints.mobilePaidDetail}`, { mobile_payment_id: id });
}
