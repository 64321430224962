import React from "react";
import { Button } from "components/buttons";

const ConfirmActionModel = ({
  onConfirm,
  onCancel,
  message,
  loading,
  className,
}) => {
  const confirmText = loading ? "Cargando..." : "Sí";
  return (
    <div className={className}>
      <p>{message}</p>
      <Button color="blue" onClick={onConfirm} disabled={loading}>
        {confirmText}
      </Button>
      <Button color="grey_1" onClick={onCancel}>
        No
      </Button>
    </div>
  );
};

export default ConfirmActionModel;
