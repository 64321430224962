import React from "react";
import { Icon } from "semantic-ui-react";

const DigitizeHeaderModel = ({
  handleSearchInputChange,
  searchParam,
  handleSearch,
  canDigitize,
  className,
}) => {
  return (
    <div className={className}>
      {canDigitize && (
        <p className="title">¿A quién desea asignarle un ticket digital?</p>
      )}
      {!canDigitize && (
        <p className="title">¿A quién desea habilitarle un ticket digital?</p>
      )}
      <div className="search">
        <input
          type="text"
          placeholder="Ingrese usuario, teléfono o cédula"
          value={searchParam}
          onChange={handleSearchInputChange}
        />
        <Icon name="search" onClick={handleSearch} />
      </div>
    </div>
  );
};

export { DigitizeHeaderModel };
