import { RateFormModel } from "./model";
import styled from "styled-components";
import { textualBlue, grey_4, grey_6 } from "assets/strings/colors";

const RateForm = styled(RateFormModel)`
  #root & {
    text-align: right;
    > .header {
      display: flex;
      margin-bottom: 1rem;
      > label {
        width: 100%;
        text-align: center;
        font-size: 1.5rem;
        display: inline-block;
        color: ${textualBlue};
      }
    }
    > .buttons {
      align-items: center;
      display: block;
      position: relative;
      left: 32%;
      > button {
        margin: 0;
        width: 35%;
      }
    }

    > p {
      display: block;
      border: none;
      border-radius: 1rem;
      color: ${grey_6};
      font-size: 1.8rem;
      margin: 1rem auto;
      text-align: center;
      outline: none;
      box-shadow: none;
      transition: box-shadow 250ms linear;
      :focus {
        box-shadow: 0 1px ${grey_4};
      }
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none !important;
      margin: 0 !important;
      -moz-appearance: textfield !important;
    }
    @media (max-width: 576px) {
      > div {
        > label {
          font-size: 0.9rem;
        }
        > button {
          height: 2.6rem;
        }
      }
      > input {
        width: 100%;
        font-size: 1.4rem;
      }
    }
  }
`;
export { RateForm };
