import { useEffect, useContext, useState } from "react";
import { WalletTransferHistoryContext } from "../context";
import { setWeekendDate } from "../services";

export const useSetWeekendDate = () => {
  const { state, changeState, throwPopupMessage } = useContext(
    WalletTransferHistoryContext
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (state.setWeekendDate) {
      setWeekendDate(state, changeState, setLoading, throwPopupMessage);
    }
  }, [state.setWeekendDate]);

  return { loading };
};
