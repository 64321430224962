import React, { useContext } from "react";
import { DateForm } from "components/date-form";
import { Button } from "components/buttons";
import { SuperadminBillsHistoryContext } from "../../context";

export const BillsHistoryFormModel = ({
  setArgs,
  setActivateFetch,
  className,
}) => {
  const { state, changeState, viewport } = useContext(
    SuperadminBillsHistoryContext
  );

  const handleFromDate = (date) => {
    changeState({ historyForm: { ...state.historyForm, from_date: date } });
  };
  const handleEndDate = (date) => {
    changeState({ historyForm: { ...state.historyForm, end_date: date } });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setArgs({
      ...state.historyForm,
    });
    setActivateFetch(true);
  };
  return (
    <form className={className} onSubmit={handleSubmit}>
      <DateForm
        fromDate={state.historyForm.from_date}
        endDate={state.historyForm.end_date}
        loading={false}
        handleFromDate={handleFromDate}
        handleEndDate={handleEndDate}
        phoneView={viewport === "phone"}
        width="80%"
      />

      <Button color="blue" margin="0.2rem 0">
        Buscar
      </Button>
    </form>
  );
};
