import React, { useContext } from "react";
import { TransfersContext } from "../context";
import { Grid } from "semantic-ui-react";
import TransferDetailContainer from "./transfer-detail";
import TransferFormAndListContainer from "./transfers-form-and-list";

const toRender = (selectedTransfer) => {
  if (!selectedTransfer) {
    return <TransferFormAndListContainer />;
  }
  return <TransferDetailContainer />;
};

const TransfersPhoneView = () => {
  const { state } = useContext(TransfersContext);
  return (
    <Grid padded>
      <Grid.Column width={16}>{toRender(state.selectedTransfer)}</Grid.Column>
    </Grid>
  );
};

export default TransfersPhoneView;
