import React, { PureComponent } from "react";
import { connect } from "react-redux";
import {
  getSelectedCommerce as getSelectedCommerceFromRedux,
  getSelectedBuilding as getSelectedBuildingFromRedux,
} from "redux/selectors";
import { addBuildings, selectBuilding } from "redux/actions/buildings";
import { getBuildings } from "services/api";
import { errors } from "assets/strings/texts";
import { clearReduxStore } from "redux/actions/general";
import { MposBuildingSettingsView } from "./components/mpos-building-settings-view";
import { throwPopupMessage, closePopup } from "components/popup-message";
class MPosBuildingSettings extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      viewState: "workspace_selection",
      building: null,
      popupMessages: null,
      popupMessagesType: "",
      showPopup: false,
    };
    this.initialState = { ...this.state };
    // Bind PopupMessage functions
    this.throwPopupMessage = throwPopupMessage.bind(this);
    this.closePopup = closePopup.bind(this);
  }

  // =============== STATE DOCS ===============
  /*
  ** In this view the subcomponents have their own state management,
  because every one of them do different things.

  --> workspace_selection
  { ...this.initialState }

  --> workspace_selection_loading
  { ...this.initialState }

  --> workspace_selection_done
  {
    building: { }
  }
*/
  // =============== END STATE DOCS ===============

  /*
   ** mpos-building-settings on Mount:
   ** --> get building initial data if there is a workspace selected
   */
  componentDidMount() {
    if (this.props.workspace) {
      this.setState({ viewState: "workspace_selection_loading" });
      getBuildings()
        .then((res) => {
          if (!res.ok) {
            throw res;
          }
          return res.json();
        })
        .then((res) => {
          if (res.buildings) {
            let buildings = res.buildings;
            let selected;
            buildings.map((building) => {
              if (building._id === this.props.workspace) {
                selected = building;
              }
              return null;
            });
            this.props.addBuildings(buildings);
            this.props.selectBuilding(selected._id);
            this.setState({
              viewState: "workspace_selection_done",
              building: selected,
            });
          }
        })
        // if an error occurs, reset the view and print the error
        .catch((err) => {
          // If error has a body, check the response
          if (typeof err.json === "function") {
            err.json().then((err_body) => {
              // If it is an authentication error, clear the redux-store to close the session
              if (
                err_body.error.id === "NO_TOKEN_PROVIDED" ||
                err_body.error.id === "AUTHENTICATE_FAILED" ||
                err_body.error.id === "DUPLICATE_SESSION"
              ) {
                this.throwPopupMessage("alert", errors.AUTHENTICATION);
                //this.props.clearReduxStore();
                return; // prevent setState exeution on unmounted component
              }
              this.setState({ ...this.initialState });
              this.throwPopupMessage("error", err_body.error.description);
            });
          } else {
            this.setState({ ...this.initialState });
            this.throwPopupMessage("error", errors.GENERAL_ERR);
          }
        });
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState({ viewState: "workspace_selection_done" });
    }
  }

  render() {
    return (
      <MposBuildingSettingsView
        viewState={this.state.viewState}
        building={this.props.building}
        popupMessages={this.state.popupMessages}
        popupMessagesType={this.state.popupMessagesType}
        showPopup={this.state.showPopup}
        location={this.props.location}
        closePopup={this.closePopup}
      />
    );
  }
}

const mapStateToProps = (state) => {
  let getSelectedBuilding = getSelectedBuildingFromRedux(state);
  let getSelectedCommerce = getSelectedCommerceFromRedux(state);
  return { getSelectedBuilding, getSelectedCommerce };
};
const mapDispatchToProps = {
  clearReduxStore,
  addBuildings,
  selectBuilding,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MPosBuildingSettings);
