import React from "react";
import UsersExtensionsContext from "./context";
import { useStateManager } from "hooks/use-state-manager";
import { usePopupMessages } from "hooks/use-popup-messages";
import { connect } from "react-redux";
import { clearReduxStore } from "redux/actions/general";
import { getSelectedCommerce } from "redux/selectors";
import { useResponsive } from "hooks/use-responsive/hook";

export const UsersExtensionsState = ({ selectedCommerce, children }) => {
  const {
    popupMessages,
    popupMessagesType,
    showPopup,
    throwPopupMessage,
    closePopup,
  } = usePopupMessages(null, "", false);

  const viewport = useResponsive();
  const initialState = {
    commerce_id: selectedCommerce._id,
    loading: false,
    viewState: "user_search",
    searchParam: "",
    searchUser: false,
    searchExtensions: false,
    selectedUser: null,
    selectedChild: null,
    selectedMovement: null,
    users: [],
    extensions: [],
    movements: [],
    waitExtensions: false,
    message: "",
    optionName: "",
    inputs: {
      firstName: "",
      secondName: "",
      firstLastname: "",
      secondLastname: "",
    },
    dropdownValues: {
      status: "active",
      carnet: "",
      is_printed: "in-progress",
      school: "",
    },
    statusOptions: [
      {
        value: "active",
        key: "active",
        text: "Activo",
      },
      {
        value: "inactive",
        key: "inactive",
        text: "Inactivo",
      },
    ],
    schoolsOptions: [],
    carnetOptions: [],
    carnetStatusOptions: [
      {
        value: "in-progress",
        key: "in-progress",
        text: "En proceso",
      },
      {
        value: "printed",
        key: "printed",
        text: "Impreso",
      },
      {
        value: "delivered",
        key: "delivered",
        text: "Entregado",
      },
    ],
    newExtension: false,
    deleteExtension: false,
    updateExtension: false,
    excelUrl: "",
  };

  const [state, changeState] = useStateManager(initialState);

  const onChangeInput = (event) => {
    const inputName = event.target.name;
    changeState({
      inputs: { ...state.inputs, [inputName]: event.target.value },
    });
  };

  const onChangeDropdown = (event, data) => {
    const dropdownName = data.name;
    changeState({
      dropdownValues: { ...state.dropdownValues, [dropdownName]: data.value },
    });
  };

  const handleBack = (event) => {
    event.preventDefault();
    if (state.viewState === "user_selected") {
      changeState({ viewState: "user_search" });
    } else {
      changeState({ viewState: "user_selected" });
    }
  };

  return (
    <UsersExtensionsContext.Provider
      value={{
        initialState,
        state,
        changeState,
        popupMessages,
        popupMessagesType,
        showPopup,
        throwPopupMessage,
        closePopup,
        selectedCommerce,
        viewport,
        handleBack,
        onChangeInput,
        onChangeDropdown,
      }}
    >
      {children}
    </UsersExtensionsContext.Provider>
  );
};

const mapStateToProps = (state) => {
  let selectedCommerce = getSelectedCommerce(state);
  return { selectedCommerce };
};

const mapDispatchToProps = {
  clearReduxStore,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersExtensionsState);
