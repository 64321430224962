import { useEffect, useContext } from "react";
import { MobilePaidsContext } from "../context";
import { listMobilePaids } from "./api";

export const useFetchMobilePaids = () => {
  const { state, changeState, throwPopupMessage } =
    useContext(MobilePaidsContext);

  const mapBankCode = (mobilePayments) => {
    return mobilePayments.map((mobilePaid) => {
      const prefix = mobilePaid.bank_code || mobilePaid.bank_id || null;
      const bank = state.bankList.find((bank) => bank.prefix === prefix);
      const bank_name = bank ? bank.short_name : prefix;
      return { ...mobilePaid, bank_name };
    });
  };

  const getMobilePaids = async () => {
    changeState({ loading: true });
    try {
      const status =
        state.formData.status.length < 1
          ? ["pending", "approved", "deferred"]
          : [...state.formData.status];
      const queryParams = {
        ...state.formData,
        status,
      };

      const response = await listMobilePaids(queryParams);
      const res = await response.json();
      if (res.error) throw res.error;
      changeState({
        mobilePaids: mapBankCode(res.mobilePayments),
        nextPageExists: res.nextPageExists,
      });
      mapBankCode();
    } catch (error) {
      throwPopupMessage("error", error.description);
    }
    changeState({ loading: false, submitForm: false });
  };

  useEffect(() => {
    if (state.submitForm) {
      getMobilePaids();
    }
  }, [state.submitForm]);

  return null;
};
