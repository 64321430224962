import React from "react";
import { CSSTransition } from "react-transition-group";
import { Table } from "components/tables";

const PanelsBoxModel = ({
  transition,
  tableStats,
  className,
}) => {
  let entry_content =
    tableStats &&
    tableStats.entry_relevance &&
    tableStats.entry_relevance.length > 0
      ? tableStats.entry_relevance
      : [];
  let exit_content =
    tableStats &&
    tableStats.exit_relevance &&
    tableStats.exit_relevance.length > 0
      ? tableStats.exit_relevance
      : [];
  return (
    <div className={className}>
      <CSSTransition
        in={transition}
        timeout={350}
        appear={true}
        classNames="tables"
      >
        <div className="tables">
          <div className="simple-first">
            {" "}
            {entry_content && entry_content.length > 0 ? (
              <Table
                height="calc(45vh - 7rem)"
                radius="0.4rem"
                headers={["Entrada", "Edificio", "Relevancia"]}
                content={entry_content}
              />
            ) : (
              "No hay datos para mostrar"
            )}
          </div>
          <div className="simple">
            {" "}
            {exit_content && exit_content.length > 0 ? (
              <Table
                height="calc(45vh - 7rem)"
                radius="0.4rem"
                headers={["Salida", "Edificio", "Relevancia"]}
                content={exit_content}
              />
            ) : (
              "No hay datos para mostrar"
            )}
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export { PanelsBoxModel };
