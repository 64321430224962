import React from "react";
import { connect } from "react-redux";
import WithdrawHistoryContext from "./context";
import { useStateManager } from "hooks/use-state-manager";
import {
  throwPopupMessage as throwPopupMessageFromRedux,
  closePopup as closePopupFromRedux,
} from "redux/actions/popup-messages";

import moment from "moment";
import { useViewType } from "hooks/use-view-type";

const WithdrawHistoryState = ({
  commerce,
  throwPopupMessage,
  closePopup,
  children,
  ...props
}) => {
  const initialState = {
    commerce,
    customer_id: commerce._id,
    viewState: "withdraw_loading",
    validateWithdrawal: null,
    searchType: "latests",
    approved: false,
    pending: false,
    fromDate: null,
    endDate: null,
    withdrawals: [],
    withdrawSelected: null,
    showWithdrawSearch: true,
    getWithdrawals: true,
    loading: false,
    approveWithdraw: false,
    commerce_counterpart_id: "",
    approveForm: {
      show: false,
      reference: "",
      payDate: moment().format("YYYY-MM-DDTHH:mm:ss.sssZ"),
      billId: "",
      withdrawId: "",
      loading: false,
    },
    pagination: {
      currentPage: 1,
      nextPageExists: false,
      withdrawPerPage: 10,
    },
  };
  const [state, changeState] = useStateManager(initialState);

  const setCustomState = (field, payload) => {
    changeState({
      [field]: {
        ...state[field],
        ...payload,
      },
    });
  };
  const setPagination = (payload) => setCustomState("pagination", payload);
  const setApproveForm = (payload) => setCustomState("approveForm", payload);
  const [viewType, setViewType] = useViewType("desktop", 992);

  const submitApprove = (data) => {
    setApproveForm(data);
    changeState({ approveWithdraw: true });
    closePopup();
  };
  return (
    <WithdrawHistoryContext.Provider
      value={{
        state,
        changeState,
        setApproveForm,
        setPagination,
        submitApprove,
        initialState,
        viewType,
        setViewType,
        throwPopupMessage,
        closePopup,
        ...props,
      }}
    >
      {children}
    </WithdrawHistoryContext.Provider>
  );
};

const mapStateToProps = null;
const mapDispatchToProps = {
  throwPopupMessage: throwPopupMessageFromRedux,
  closePopup: closePopupFromRedux,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithdrawHistoryState);
