import React from "react";
import { Button } from "components/buttons";
import { EntitiesList } from "components/entities-list";
import { formatTicketsList } from "components/ticket-list-item";

const SearchByTicketsModel = ({
  tickets = [],
  pd_fee,
  iva,
  handleSearchTickets,
  handleTicketSelection,
  handleAllTicketsSelection,
  allSelection,
  loading,
  className,
}) => {
  let state = loading ? "ticket_search_loading" : "ticket_search_done";
  const hasTicketsList = tickets && tickets.length && tickets.length > 0;
  return (
    <div className={className}>
      <div className="selector">
        <Button
          width="80%"
          color="blue"
          onClick={handleSearchTickets}
          margin="0.5rem 0 0 0"
        >
          Buscar
        </Button>
      </div>
      <div className="list">
        <EntitiesList
          height="calc(100vh - 20em)"
          viewState={state}
          entities={tickets}
          additionalArgs={!pd_fee && !iva ? [] : [{ pd_fee, iva }]}
          formattingFunction={formatTicketsList}
          selectionHandler={handleTicketSelection}
        />
      </div>
      {!allSelection && hasTicketsList && <div className="buttons">
        <Button  color="blue" width="80%" onClick={handleAllTicketsSelection}>
          Seleccionar Todo
        </Button>
      </div>}
    </div>
  );
};

export { SearchByTicketsModel };
