import React from "react";
import { Button } from "components/buttons";
import DatePicker from "react-datepicker";
import moment from "moment";

const FiltersModel = ({
  fromDate,
  endDate,
  handleFromDate,
  handleEndDate,
  phoneView,
  handleSubmit,
  className,
}) => {
  return (
    <div className={className}>
      <div className={`date-pickers`}>
        <p>fecha:</p>
        <DatePicker
          className="date-picker"
          placeholderText="Desde"
          dateFormat="DD / MM / YYYY"
          showMonthDropdown
          showYearDropdown
          todayButton={"Hoy"}
          isClearable
          withPortal={phoneView}
          selected={fromDate}
          maxDate={endDate ? moment(endDate).subtract(23, "hours") : moment()}
          onChange={handleFromDate}
        />
        /
        <DatePicker
          className="date-picker"
          placeholderText="Hasta"
          dateFormat="DD / MM / YYYY"
          showMonthDropdown
          showYearDropdown
          todayButton={"Hoy"}
          isClearable
          withPortal={phoneView}
          selected={endDate}
          minDate={fromDate ? moment(fromDate).add(23, "hours") : null}
          maxDate={moment()}
          onChange={handleEndDate}
        />
      </div>
      <Button
        color="grey"
        onClick={handleSubmit}
        margin={phoneView ? "0.5rem 0 0 0" : "1rem 0 0 0"}
        width="98%"
      >
        Buscar
      </Button>
    </div>
  );
};

export { FiltersModel };
