import React, { PureComponent } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Button } from "components/buttons";
import { general as texts } from "assets/strings/texts";

export class FilterFormModel extends PureComponent {
  render() {
    let {
      viewState,
      phoneView,
      searchType,
      searchFilter,
      fromDate,
      endDate,
      handleSearchTypeButtons,
      handleSearchFilterButtons,
      handleFromDate,
      handleEndDate,
      className,
    } = this.props;
    return (
      <div className={className}>
        <div className="collapsed">
          <div className="selectors">
            <Button
              value="last_month"
              pressed={searchType === "last_month"}
              color={searchType === "last_month" ? "purple" : "grey_1"}
              disabled={viewState === "waiting_data"}
              onClick={handleSearchTypeButtons}
            >
              Último mes
            </Button>
            <Button
              value="dateRange"
              pressed={searchType === "dateRange"}
              color={searchType === "dateRange" ? "purple" : "grey_1"}
              disabled={viewState === "waiting_data"}
              onClick={handleSearchTypeButtons}
            >
              {texts.dateRange}
            </Button>
          </div>
          <div className="date-pickers">
            <DatePicker
              className="date-picker"
              placeholderText={texts.from}
              dateFormat={texts.shortDateFormat}
              disabled={searchType !== "dateRange"}
              showMonthDropdown
              showYearDropdown
              todayButton={texts.today}
              isClearable
              withPortal={phoneView}
              selected={fromDate}
              maxDate={
                endDate ? moment(endDate).subtract(23, "hours") : moment()
              }
              onChange={handleFromDate}
            />
            <p>/</p>
            <DatePicker
              className="date-picker"
              placeholderText={texts.to}
              dateFormat={texts.shortDateFormat}
              disabled={searchType !== "dateRange"}
              popperPlacement="bottom-end"
              showMonthDropdown
              showYearDropdown
              todayButton={texts.today}
              isClearable
              withPortal={phoneView}
              selected={endDate ? moment(endDate).subtract(23, "hours") : null}
              minDate={fromDate ? moment(fromDate).add(23, "hours") : null}
              maxDate={moment()}
              onChange={handleEndDate}
            />
          </div>
          <div className="search">
            <Button
              value={searchFilter === "active" ? "inactive" : "active"}
              pressed={searchFilter === "active"}
              disabled={searchType !== "dateRange"}
              color={searchType !== "dateRange" ? "grey_1" : "green"}
              onClick={handleSearchFilterButtons}
            >
              Buscar
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export function handleFromDate(date) {
  this.setState({ fromDate: date });
}

export function handleEndDate(date) {
  if (date) {
    // If the date exists add 23 hours
    this.setState({ endDate: moment(date).add(23, "hours") });
  } else {
    // If the date is null, save it like that
    this.setState({ endDate: date });
  }
}
export function handleStatusButtons(event) {
  event.preventDefault();
  let status = event.target.value;
  this.setState((prevState) => ({
    [status]: !prevState[status],
  }));
}
