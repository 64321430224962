import React from "react";
import { Dropdown } from "components/dropdown";
const DashboardSectionModel = ({
  children,
  sectionTitle,
  className,
  timePeriods,
  periodToShow,
  handleTimePeriodsSelect,
}) => {
  return (
    <div className={className}>
      <div className="container">
        {sectionTitle && <p className="section-title">{sectionTitle}</p>}

        {timePeriods && (
          <Dropdown
            fluid
            floating
            selection
            id="timePeriod"
            fontSize="19.8px"
            /* disabled={viewState === 'recharge_validation_loading'} */
            value={periodToShow}
            onChange={handleTimePeriodsSelect}
            options={timePeriods}
          />
        )}

        {React.Children.map(children, (child) => {
          return <div className="section-item">{child}</div>;
        })}
      </div>
    </div>
  );
};

export default DashboardSectionModel;
