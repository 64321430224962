import React from "react";
import { Grid } from "semantic-ui-react";
import { RightBorderGreyColumn } from "components/styled-grid-column";
import MobilePaidDetailContainer from "./mobile-paid-detail";
import MobilePaidFormAndListContainer from "./mobile-paids-form-and-list";

const MobilePaidsView = () => {
  return (
    <Grid padded>
      <RightBorderGreyColumn width={8}>
        <MobilePaidFormAndListContainer />
      </RightBorderGreyColumn>
      <Grid.Column width={8}>
        <MobilePaidDetailContainer />
      </Grid.Column>
    </Grid>
  );
};

export default MobilePaidsView;
