import React, { useContext } from "react";
import { MobilePaidsContext } from "../../context";
import {
  currencyAmount,
  mapStatus,
  checkField,
  checkDate,
} from "services/utils";
import { Button } from "components/buttons";
import { PanelTitle } from "components/panel";
import { IconBack } from "components/icon-back";
import AssignTo from "../assign-to";
import ConfirmAction from "../confirm-action";
import { Loader } from "components/loader";
import { useChangeStatus } from "../../services/change-status";
import { useFindDetails } from "../../services/mobile-paid-detail";
import { recharge_validate as viewTexts } from "assets/strings/texts";

const RechargeData = ({ rechargeData }) => {
  if (!rechargeData) return null;
  return (
    <>
      <p className="title">Usuario</p>
      <p className="content username">@{rechargeData.username}</p>
    </>
  );
};

const StatusButtons = ({
  status,
  onApprove,
  onAssign,
  onReject,
  className,
}) => {
  if (status === "approved") return null;
  return (
    <div className={className}>
      <Button color="blue" onClick={onApprove} width="100%">
        Aprobar
      </Button>
      {/*       <Button color="green" onClick={onAssign} width="30%">
        Asignar
      </Button>
      <Button color="red" onClick={onReject} width="30%">
        Rechazar
      </Button> */}
    </div>
  );
};

const MobilePaidDetailModel = ({ className }) => {
  useChangeStatus();
  useFindDetails();
  const { state, changeState, throwPopupMessage, closePopup, viewType } =
    useContext(MobilePaidsContext);

  const mobilePaid = state.selectedMobilePaid;
  const status = mobilePaid && mobilePaid.status ? mobilePaid.status : "";

  const onApprove = (event, sendUsername = false) => {
    event.preventDefault();
    changeState({ changeStatus: true, sendUsername, newStatus: "approved" });
    closePopup();
  };
  const onReject = (event) => {
    event.preventDefault();
    changeState({
      changeStatus: true,
      newStatus: "rejected",
      sendUsername: false,
    });
  };

  const onUsernameChange = (event) => {
    const username = event.target.value;
    changeState({ username });
  };

  const onAssign = (event) => {
    event.preventDefault();
    throwPopupMessage(
      "",
      <AssignTo
        selectedMobilePaid={state.selectedMobilePaid}
        onApprove={(event) => {
          onApprove(event, true);
        }}
        onUsernameChange={onUsernameChange}
      />
    );
  };

  const onApproveModal = (event) => {
    event.preventDefault();
    throwPopupMessage(
      "alert",
      <ConfirmAction
        message={viewTexts.rechargeValidateConfirmation}
        onConfirm={(event) => {
          onApprove(event, true);
        }}
        onCancel={(event) => {
          closePopup();
        }}
        loading={state.changeStatusLoading}
      />
    );
  };

  const handleBack = (event) => {
    event.preventDefault();
    changeState({ selectedMobilePaid: null, showDetail: false });
  };

  const phone = viewType === "phone";
  if (state.selectionLoading) return <Loader />;
  return (
    <div className={className}>
      {phone && <IconBack handleBack={handleBack} />}
      <PanelTitle>Pago Móvil</PanelTitle>
      <h5 className="title">Descripción:</h5>
      <p className="content">{checkField(mobilePaid, "description")}</p>
      <h5 className="title">Estado:</h5>
      <p className="content">{mapStatus(checkField(mobilePaid, "status"))}</p>
      <h5 className="title">Monto:</h5>
      <p className="content">
        {currencyAmount(checkField(mobilePaid, "amount"), "Bs.")}
      </p>
      <h5 className="title">N° de Referencia:</h5>
      <p className="content">{checkField(mobilePaid, "reference_id")}</p>
      <h5 className="title">Fecha:</h5>
      <p className="content">
        {checkDate(checkField(mobilePaid, "movement_date"))}
      </p>
      <RechargeData rechargeData={mobilePaid.rechargeData} />
      <StatusButtons
        status={status}
        onAssign={onAssign}
        onApprove={onApproveModal}
        onReject={onReject}
        className="buttons"
      />
    </div>
  );
};

export default MobilePaidDetailModel;
