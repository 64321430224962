import React, { useEffect, useState } from "react";
import { Panel } from "components/panel";
import { Button } from "components/buttons";
import { Loader } from "components/loader";
import GeneralInput from "components/general-input";
import { EntitiesList } from "components/entities-list";
import { UserListHeader, UserListItem } from "./index";
import { useTextInput } from "hooks/use-inputs";
import { sendPushNotification } from "services/api";
import { errors } from "assets/strings/texts";
import notiCupon from "assets/img/notif_msg_cupon.png";
import notiGeneral from "assets/img/notif_msg_general.png";
import notiPremio from "assets/img/notif_msg_premio.png";
import { Dropdown } from "components/dropdown";
import { useDropdown } from "hooks/use-dropdown";
import { useDates } from "hooks/use-inputs";
import DatePicker from "react-datepicker";
import moment from "moment";

const NotificationsPanelModel = ({
  users,
  throwPopupMessage,
  viewType,
  screenOptions,
  logosOptions,
  handleUserUnselection,
  handleBack,
  generalLoading,
  className,
}) => {
  const phoneView = viewType === "phone";
  const [loading, setLoading] = useState(false);
  const {
    bind: { value: title, onChange: handleTitleChange },
  } = useTextInput(null);
  const {
    bind: { value: message, onChange: handleMessageChange },
  } = useTextInput(null);
  const [sendNotification, setSendNotification] = useState(false);
  const {
    bind: { value: screen, onChange: handleScreenOption },
  } = useDropdown("home");

  const [typeSelected, setTypeSelected] = useState("general");
  const { fromDate: date, handleFromDate: handleDate } = useDates(null, null);
  
  useEffect(() => {
    const notification = async () => {
      try {
        let error = {};
        if (!users || !users.length) {
          error.description = "Debe seleccionar al menos un usuario";
          throw error;
        }
        let userList = users.map((user) => {
          return user.username;
        });
        const response = await sendPushNotification(
          userList,
          title,
          message,
          date,
          screen,
          typeSelected
        );
        const res = await response.json();
        if (res.error) throw res.error;
        setLoading(false);
        if (res.message) {
          throwPopupMessage("success", res.message);
        }
      } catch (error) {
        setLoading(false);
        if (
          error.id === "NO_TOKEN_PROVIDED" ||
          error.id === "AUTHENTICATE_FAILED" ||
          error.id === "DUPLICATE_SESSION"
        ) {
          throwPopupMessage("alert", errors.AUTHENTICATION);
        } else {
          throwPopupMessage("error", error.description);
        }
      }
    };
    if (sendNotification) {
      setLoading(true);
      notification();
      setSendNotification(false);
    }
    return () => {};
  }, [sendNotification]);

  useEffect(() => {
    return () => {};
  }, [typeSelected]);
  const handleSend = (event) => {
    event.preventDefault();
    setSendNotification(true);
  };
  const handleType = (event) => {
    setTypeSelected(event.currentTarget.id);
  };
  return loading || generalLoading ? (
    <Loader margin="0" />
  ) : (
    <div className={className}>
      <Panel className="panel-form">
        <form onSubmit={handleSend} className="form-segment">
          <p className="header">Seleccione un Tipo de Notificación</p>
          <div className="types">
            <div
              className={typeSelected === "cupon" ? "selected" : ""}
              onClick={handleType}
              id="cupon"
            >
              <img src={notiCupon} alt="cupon" />
              <p>Cupon</p>
            </div>
            <div
              onClick={handleType}
              className={typeSelected === "general" ? "selected" : ""}
              id="general"
            >
              <img src={notiGeneral} alt="general" />
              <p>General</p>
            </div>
            <div
              onClick={handleType}
              className={typeSelected === "premio" ? "selected" : ""}
              id="premio"
            >
              <img src={notiPremio} alt="premio" />
              <p>Premio</p>
            </div>
          </div>
          <div className="screens">
            <p>Direccionar a:</p>
            <Dropdown
              fluid
              floating
              selection
              value={screen}
              onChange={handleScreenOption}
              options={screenOptions}
              disabled={generalLoading}
            />
          </div>
          <div className="inputs">
            <GeneralInput
              title={"Título"}
              param={title}
              handleChange={handleTitleChange}
            />
            <GeneralInput
              title={"Mensaje"}
              param={message}
              handleChange={handleMessageChange}
            />
          </div>
          <div className="button-style">
            <div className={`date-pickers`}>
              <DatePicker
                className="date-picker"
                placeholderText="Expira en"
                dateFormat="DD / MM / YYYY"
                showMonthDropdown
                showYearDropdown
                isClearable
                withPortal={phoneView}
                selected={date}
                minDate={moment()}
                onChange={handleDate}
              />
            </div>
            <button
              disabled={
                loading ||
                !message ||
                !title ||
                !typeSelected ||
                !screen ||
                !date
              }
            >
              Enviar
            </button>
          </div>
        </form>
      </Panel>
      <Panel className="panel-list">
        {phoneView && (
          <Button
            width="100%"
            color="blue"
            onClick={handleBack}
            style={{ margin: "0 0 0 0" }}
          >
            Agregar
          </Button>
        )}
        <div>
          <UserListHeader />
          <EntitiesList
            height="calc(20vh - 4em)"
            entities={users}
            formattingFunction={formatUsersList}
            selectionHandler={handleUserUnselection}
            additionalArgs={[phoneView]}
          />
        </div>
      </Panel>
    </div>
  );
};

const UserListItemModel = ({
  user,
  phoneView,
  handleUnselection,
  index,
  className,
}) => {
  let userName = user && user.username ? user.username : "";
  return phoneView ? (
    <dd className={className} onClick={handleUnselection} id={index}>
      <div className={"column"}>
        <p className="info"> @{userName}</p>
      </div>
    </dd>
  ) : (
    <dd className={className} onClick={handleUnselection} id={index}>
      <p className="info">@{userName}</p>
    </dd>
  );
};
const UserListHeaderModel = ({ className }) => {
  return (
    <dd className={className}>
      <p className="col all">usuarios</p>
    </dd>
  );
};

function formatUsersList(
  users,
  viewState,
  handleUserUnselection,
  phoneView,
  loading
) {
  // Declaramos el array que devolverá la función

  let usersList = [];
  if (loading) {
    return <div className="ui active loader"></div>;
  }
  if (users && users.length) {
    users.forEach((user, index) => {
      usersList.push(
        <UserListItem
          user={user}
          phoneView={phoneView}
          handleUnselection={handleUserUnselection}
          index={index}
          key={user._id}
        />
      );
    });

    return usersList;
  } else {
    return (
      <div
        className="not-found"
        style={{ textAlign: "center", marginTop: "3rem" }}
      >
        Seleccione usuarios para enviar notificación
      </div>
    );
  }
}

export { NotificationsPanelModel, UserListHeaderModel, UserListItemModel };
