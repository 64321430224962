import React from "react";
import { Grid } from "semantic-ui-react";
import { RightBorderGreyColumn } from "components/styled-grid-column";
import TransferDetailContainer from "./transfer-detail";
import TransferFormAndListContainer from "./transfers-form-and-list";

const TransferView = () => {
  return (
    <Grid padded>
      <RightBorderGreyColumn width={8}>
        <TransferFormAndListContainer />
      </RightBorderGreyColumn>
      <Grid.Column width={8}>
        <TransferDetailContainer />
      </Grid.Column>
    </Grid>
  );
};

export default TransferView;
