import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Panel } from "components/panel";
import { Button } from "components/buttons";
import { Loader } from "components/loader";
import { errors } from "assets/strings/texts";
import { AccessesForm } from "../accesses-form";
import { clearReduxStore } from "redux/actions/general";
import PopupMessage from "components/popup-message";
import { EntitiesList } from "components/entities-list";
import { general as texts } from "assets/strings/texts";
import { formatAccessesList } from "../accesses-list-item";
import { popup_transition_time } from "assets/strings/constants";
import { building_settings as viewTexts } from "assets/strings/texts";
import {
  patchBuildingAccess,
  postBuildingAccess,
  postDeleteBuildingAccess,
  getBuildings,
} from "services/api";
import { throwPopupMessage, closePopup } from "components/popup-message";

import { addBuildings, selectBuilding } from "redux/actions/buildings";
import { getSelectedBuilding } from "redux/selectors";
class AccessesSettingsModel extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      prevProps: props,
      building_code: props.buildingCode,
      accesses: props.accesses,
      viewState: "accesses_list",
      loading: false,
      hideAccesses: true,
      id: "",
      name: "",
      status: "",
      type: "",
      mac_type: "",
      mac: "",
      long: "",
      lat: "",
      rssi_android: "",
      rssi_ios: "",
      track_index: "",
      popupMessages: null,
      popupMessagesType: "",
      showPopup: false,
    };
    this.initialState = { ...this.state };
    // Bind PopupMessage functions
    this.throwPopupMessage = throwPopupMessage.bind(this);
    this.closePopup = closePopup.bind(this);
  }

  // =============== STATE DOCS ===============
  /*
  --> accesses_list
  ** Errors will be shown here

  { ...this.initialState }

  --> accesses_show_one
  {
    accessName: String,
    accessType: String,
    accessMacType: String,
    accessMac: String,
    accessLat: String,
    accessLong: String,
    popupMessages: null,
    popupMessagesType: '',
    showPopup: true
  }

  --> accesses_update
  {
    accessName: String,
    accessType: String,
    accessMacType: String,
    accessMac: String,
    accessLong: String,
    accessLat: String,
    popupMessages: null,
    popupMessagesType: '',
    showPopup: true
  }

  --> accesses_create
  {
    accessName: String,
    accessType: String,
    accessMacType: String,
    accessMac: String,
    accessLong: String,
    accessLat: String,
    popupMessages: null,
    popupMessagesType: '',
    showPopup: true
  }

  --> accesses_update_done
  ** this.initialState = { ...this.initialState, accesses }
  ** after this.closePopup() component will end on 
  ** the new initialState

  {
    ...this.initialState
    popupMessages: [res.message],
    popupMessagesType: 'success',
    showPopup: true
  }
*/
  // =============== END STATE DOCS ===============
  static getDerivedStateFromProps(props, state) {
    if (
      props.buildingCode !== state.prevProps.buildingCode ||
      props.accesses !== state.prevProps.accesses
    ) {
      return {
        building_code: props.buildingCode,
        accesses: props.accesses,
      };
    }
    return null;
  }

  updateBuildings = async () => {
    try {
      const response = await getBuildings();
      if (!response.ok) throw response;

      const res = await response.json();
      if (res.buildings) {
        this.props.addBuildings(res.buildings);
        this.props.selectBuilding(this.props.building._id);
      }
    } catch (err) {
      this.setState({ ...this.initialState });
      this.throwPopupMessage("error", errors.GENERAL_ERR);
    }
  };

  toggleAccessesVisibility = () => {
    this.setState({ hideAccesses: !this.state.hideAccesses });
  };

  handleAccessSelection = (event) => {
    let index = event.currentTarget.getAttribute("index");
    this.setState((prevState) => ({
      viewState: "accesses_show_one",
      name: prevState.accesses[index].name,
      status: prevState.accesses[index].status,
      type: prevState.accesses[index].type,
      mac_type: prevState.accesses[index].mac_type,
      mac: prevState.accesses[index].mac,
      long: prevState.accesses[index].loc.coordinates[1],
      lat: prevState.accesses[index].loc.coordinates[0],
      rssi_android: prevState.accesses[index].rssi_android,
      rssi_ios: prevState.accesses[index].rssi_ios,
      track_index: prevState.accesses[index].track_index
        ? prevState.accesses[index].track_index
        : "",
    }));
    this.throwPopupMessage("", "");
  };

  handleAccessEdit = (event, index) => {
    event.stopPropagation();
    this.setState((prevState) => ({
      viewState: "accesses_update",
      id: prevState.accesses[index]._id,
      name: prevState.accesses[index].name,
      status: prevState.accesses[index].status,
      type: prevState.accesses[index].type,
      mac: prevState.accesses[index].mac,
      long: prevState.accesses[index].loc.coordinates[1],
      lat: prevState.accesses[index].loc.coordinates[0],
      rssi_android: prevState.accesses[index].rssi_android,
      rssi_ios: prevState.accesses[index].rssi_ios,
      track_index: prevState.accesses[index].track_index
        ? prevState.accesses[index].track_index
        : "",
    }));
    this.throwPopupMessage("", "");
  };

  handleAccessDelete = (event, index) => {
    event.stopPropagation();
    this.setState((prevState) => ({
      viewState: "accesses_delete",
      id: prevState.accesses[index]._id,
      name: prevState.accesses[index].name,
      status: prevState.accesses[index].status,
      type: prevState.accesses[index].type,
      mac: prevState.accesses[index].mac,
      long: prevState.accesses[index].loc.coordinates[1],
      lat: prevState.accesses[index].loc.coordinates[0],
      rssi_android: prevState.accesses[index].rssi_android,
      rssi_ios: prevState.accesses[index].rssi_ios,
      track_index: prevState.accesses[index].track_index
        ? prevState.accesses[index].track_index
        : "",
    }));
    this.throwPopupMessage(
      "alert",
      <div>
        <p>¿Deseas eliminar el este accesso?</p>
        <p>{this.state.accesses[index].name}</p>
        <Button onClick={this.handleSubmit} color="green">
          Sí
        </Button>
        <Button onClick={this.closeFormPopup} color="grey">
          No
        </Button>
      </div>
    );
  };
  confirmationDelete = (event) => {
    event.preventDefault();
    this.setState({ viewState: "accesses_delete_loading" });

    this.throwPopupMessage("success", "Acceso eliminado con éxito");
  };

  handleAccessNew = () => {
    this.setState({ viewState: "accesses_create" });
    this.throwPopupMessage("", "");
  };

  handleTextInput = (event) => {
    let value = event.target.value;
    let propertie = event.target.id;
    this.setState({ [propertie]: value });
  };

  handleDropown = (event, data) => {
    let value = data.value;
    let propertie = data.id;
    this.setState({ [propertie]: value });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ loading: true });
    // Get endpoints data from state
    let {
      building_code,
      id,
      name,
      status,
      type,
      mac_type,
      mac,
      long,
      lat,
      rssi_android,
      rssi_ios,
      track_index,
    } = this.state;
    let promise;
    // Set specific date and make the appropiate call to the API
    if (this.state.viewState === "accesses_update") {
      let requestData = {
        id,
        name,
        status,
        type,
        mac,
        coordinates: [long, lat],
        rssi_android,
        rssi_ios,
        track_index,
      };
      promise = patchBuildingAccess(requestData);
    }
    if (this.state.viewState === "accesses_create") {
      let requestData = {
        building_code,
        access: {
          name,
          type,
          mac_type,
          mac,
          coordinates: [long, lat],
          rssi_android,
          rssi_ios,
          track_index,
        },
      };
      promise = postBuildingAccess(requestData);
    }
    if (this.state.viewState === "accesses_delete") {
      let requestData = {
        building_code,
        _id: id,
      };
      this.setState({ popupMessages: <Loader /> });
      promise = postDeleteBuildingAccess(requestData);
    }

    promise
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((res) => {
        if (res.accesses && res.message) {
          // save the new accesses list to the initialState
          this.initialState = { ...this.initialState, accesses: res.accesses };
          // close popup and clear access form
          this.closeFormPopup();
          setTimeout(() => {
            // after the transition, reset the view (with the new data)
            // set viewState to 'accesses_update_done' is unnecessary
            this.setState((prevState) => ({
              ...this.initialState,
              hideAccesses: prevState.hideAccesses,
            }));
            this.throwPopupMessage("success", res.message);
          }, popup_transition_time);
          this.updateBuildings();
        }
      })
      // if an error occurs, reset the view and print the error
      .catch((err) => {
        // If error has a body, check the response
        if (typeof err.json === "function") {
          err.json().then((err_body) => {
            // If it is an authentication error, clear the redux-store to close the session
            if (
              err_body.error.id === "NO_TOKEN_PROVIDED" ||
              err_body.error.id === "AUTHENTICATE_FAILED" ||
              err_body.error.id === "DUPLICATE_SESSION"
            ) {
              this.throwPopupMessage("alert", errors.AUTHENTICATION);
              return; // prevent setState exeution on unmounted component
            }
            this.setState((prevState) => ({
              ...this.initialState,
              hideAccesses: prevState.hideAccesses,
            }));
            this.throwPopupMessage("error", err_body.error.description);
          });
        } else {
          this.setState((prevState) => ({
            ...this.initialState,
            hideAccesses: prevState.hideAccesses,
          }));
          this.throwPopupMessage("error", errors.GENERAL_ERR);
        }
      });
  };

  closeFormPopup = () => {
    this.closePopup();
    setTimeout(() => {
      this.setState((prevState) => ({
        ...this.initialState,
        hideAccesses: prevState.hideAccesses,
      }));
    }, popup_transition_time);
  };

  render() {
    return (
      <Panel>
        {(this.state.viewState === "accesses_show_one" ||
          this.state.viewState === "accesses_update" ||
          this.state.viewState === "accesses_create" ||
          this.state.viewState === "accesses_loading") && (
          <PopupMessage
            messageType={this.state.popupMessagesType}
            showPopup={this.state.showPopup}
            messages={this.state.popupMessages}
            closePopup={this.closeFormPopup}
          >
            <AccessesForm
              viewState={this.state.viewState}
              loading={this.state.loading}
              name={this.state.name}
              status={this.state.status}
              type={this.state.type}
              macType={this.state.mac_type}
              mac={this.state.mac}
              lat={this.state.lat}
              long={this.state.long}
              rssi_android={this.state.rssi_android}
              rssi_ios={this.state.rssi_ios}
              track_index={this.state.track_index}
              handleTextInput={this.handleTextInput}
              handleDropown={this.handleDropown}
              handleSubmit={this.handleSubmit}
            />
          </PopupMessage>
        )}

        {(this.state.viewState === "accesses_list" ||
          this.state.viewState === "accesses_delete" ||
          this.state.viewState === "accesses_update_done") && (
          <PopupMessage
            messageType={this.state.popupMessagesType}
            showPopup={this.state.showPopup}
            messages={this.state.popupMessages}
            closePopup={this.closePopup}
          />
        )}

        <div className={this.props.className}>
          <p className="title">{viewTexts.mtdSettingsTitle}</p>
          <div className="buttons">
            <Button color="green" onClick={this.handleAccessNew}>
              {texts.add}
            </Button>
            <Button color="blue" onClick={this.toggleAccessesVisibility}>
              {this.state.hideAccesses ? texts.show : texts.hide}
            </Button>
          </div>
          <EntitiesList
            maxHeight={this.state.hideAccesses ? "0" : "350px"}
            margin="1rem 0 0 0"
            entities={this.state.accesses}
            formattingFunction={formatAccessesList}
            selectionHandler={this.handleAccessSelection}
            additionalArgs={[this.handleAccessEdit, this.handleAccessDelete]}
          />
        </div>
      </Panel>
    );
  }
}

const mapStateToProps = (state) => {
  let selectedBuilding = getSelectedBuilding(state);
  return { selectedBuilding };
};

const mapDispatchToProps = {
  clearReduxStore,
  addBuildings,
  selectBuilding,
};

AccessesSettingsModel = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccessesSettingsModel);

export { AccessesSettingsModel };
