import React, { Component } from "react";
import { connect } from "react-redux";
import { Panel } from "components/panel";
import { errors } from "assets/strings/texts";
import { patchCommerceBlock, changeBilling } from "services/api";
import { general as texts } from "assets/strings/texts";
import { throwPopupMessage } from "redux/actions/popup-messages";
import defaultUserWhite from "assets/img/default-user-white.png";
import { commerces_admin as viewTexts } from "assets/strings/texts";
import { formatPrice, arrayToObject } from "services/utils";
import {
  setViewState,
  updateCommercesList,
  selectCommerce,
  removeCommerce,
} from "redux/actions/view-mpos-commerces";
import Options from "./Options";
class CommercePanelModel_unconected extends Component {
  constructor(props) {
    super(props);
    this.initialState = {
      ...this.state,
      commercesOptions: [
        {
          text: "Mostrar usuarios",
          value: "showUsers",
          key: "showUsers",
          active: true,
        },
        {
          text: "Historial de retiros",
          value: "showWithdrawals",
          key: "showWithdrawals",
          active: true,
        },
        {
          text: "Historial de movimientos",
          value: "showMovements",
          key: "showMovements",
          active: true,
        },
      ],
    };
  }
  changeBillingType = () => {
    //this.props.setViewState('commerce_info_loading');
    const selectedCommerceId = this.props.selectedCommerce._id;
    const billingType =
      this.props.selectedCommerce.parking.billing_type === "prepaid"
        ? "postpaid"
        : "prepaid";
    changeBilling(selectedCommerceId, billingType) //
      .then((res) => {
        if (!res.ok) {
          throw res;
        } // If not OK throw error
        return res.json(); // If OK return body
      })
      .then((res) => {
        // Manage success response
        if (res.commerce) {
          // Clone actual commerces
          let updatedCommerces = [...this.props.commerces];
          // add the updated commerce to the cloned array
          this.props.commerces.forEach((commerce, index) => {
            if (commerce._id === res.commerce._id) {
              updatedCommerces[index] = res.commerce;
            }
          });
          this.props.updateCommercesList(updatedCommerces);
          this.props.selectCommerce(res.commerce);
          this.props.setViewState("commerce_info");
        }
      })
      .catch((err) => {
        // Manage error response
        // Set apropiate states
        this.props.setViewState("commerce_info");
        // If error has a body, check the response
        if (typeof err.json === "function") {
          err.json().then((err_body) => {
            // If it is an authentication error, clear the redux-store to close the session
            if (
              err_body.error.id === "NO_TOKEN_PROVIDED" ||
              err_body.error.id === "AUTHENTICATE_FAILED" ||
              err_body.error.id === "DUPLICATE_SESSION"
            ) {
              this.props.throwPopupMessage("alert", errors.AUTHENTICATION);
              return;
              //this.props.clearReduxStore();
              //return; // prevent setState exeution on unmounted component
            }
            this.props.throwPopupMessage("error", err_body.error.description);
          });
        } else {
          this.props.throwPopupMessage("error", errors.GENERAL_ERR);
        }
      });
  };

  toggleBlockedStatus = () => {
    this.props.setViewState("commerce_info_loading");
    const selectedCommerceId = this.props.selectedCommerce._id;
    const blocked = !(this.props.selectedCommerce.status === "blocked");

    patchCommerceBlock(selectedCommerceId, blocked)
      .then((res) => {
        if (!res.ok) {
          throw res;
        } // If not OK throw error
        return res.json(); // If OK return body
      })
      .then((res) => {
        // Manage success response
        if (res.commerce) {
          // Clone actual commerces
          let updatedCommerces = [...this.props.commerces];
          // add the updated commerce to the cloned array
          this.props.commerces.forEach((commerce, index) => {
            if (commerce._id === res.commerce._id) {
              updatedCommerces[index] = res.commerce;
            }
          });
          this.props.updateCommercesList(updatedCommerces);
          this.props.selectCommerce(res.commerce);
          this.props.setViewState("commerce_info");
        }
      })
      .catch((err) => {
        // Manage error response
        // Set apropiate states
        this.props.setViewState("commerce_info");
        // If error has a body, check the response
        if (typeof err.json === "function") {
          err.json().then((err_body) => {
            // If it is an authentication error, clear the redux-store to close the session
            if (
              err_body.error.id === "NO_TOKEN_PROVIDED" ||
              err_body.error.id === "AUTHENTICATE_FAILED" ||
              err_body.error.id === "DUPLICATE_SESSION"
            ) {
              this.props.throwPopupMessage("alert", errors.AUTHENTICATION);
              return;
              //this.props.clearReduxStore();
              //return; // prevent setState exeution on unmounted component
            }
            this.props.throwPopupMessage("error", err_body.error.description);
          });
        } else {
          this.props.throwPopupMessage("error", errors.GENERAL_ERR);
        }
      });
  };
  showWithdrawals = () => {
    this.props.setViewState("commerce_withdraw_loading");
  };
  removeSelectedCommerce = () => {
    this.props.setViewState("commerce_search_done");
    this.props.removeCommerce();
  };

  handleViewInvoices = () => {
    this.props.setViewState("commerce_invoice_loading");
  };

  displayPrepaidTickets = () => {
    this.props.setViewState("commerce_prepaid_tickets");
  };

  handleViewUsers = (event) => {
    event.preventDefault();
    this.props.setViewState("commerce_users");
  };
  formatCommerceData = (commerce) => {
    let name = "--",
      address = "--",
      commerceId = "",
      ownerName = "--",
      phone = "--",
      billingType = "--",
      funds = "--";
    const hasAmount = commerce.amounts && commerce.amounts.length;

    if (hasAmount) {
      let amounts = arrayToObject(commerce.amounts, "currency");
      let isPositive = amounts.BS && amounts.BS.funds && amounts.BS.funds >= 0;
      if (isPositive) funds = formatPrice(amounts.BS.funds) + " Bs.S";
      else funds = "0,00 Bs.S";
    } else {
      funds = "0,00 Bs.S";
    }
    if (commerce.name) name = commerce.name;

    if (commerce.address) address = commerce.address;

    if (commerce.owner && commerce.owner.profile) {
      if (commerce.owner.profile.name && commerce.owner.profile.last_name) {
        ownerName =
          commerce.owner.profile.name + " " + commerce.owner.profile.last_name;
      }
    }
    if (commerce.phone) {
      phone = commerce.phone;
    }

    if (commerce.identification && commerce.identification.doc_id) {
      commerceId =
        commerce.identification.doc_type +
        " - " +
        commerce.identification.doc_id;
    }
    if (commerce.parking) {
      commerce.parking.billing_type === "prepaid"
        ? (billingType = texts.prepaid)
        : (billingType = texts.postpaid);
    } else {
      billingType = null;
    }

    return {
      name,
      address,
      commerceId,
      ownerName,
      phone,
      billingType,
      funds,
    };
  };

  showMovements = () => {
    this.props.setViewState("commerce_show_movements");
  };
  getOptions = (selectedCommerce) => {
    const extraOptions = [
      {
        text: viewTexts.changeBillingType,
        value: "changeBillingType",
        key: "changeBillingType",
        active:
          selectedCommerce.category &&
          selectedCommerce.category !== "estacionamiento" &&
          selectedCommerce.parking,
      },
      {
        text: viewTexts.viewInvoices,
        value: "showInvoices",
        key: "showInvoices",
        active:
          selectedCommerce.category &&
          selectedCommerce.category !== "estacionamiento" &&
          selectedCommerce.parking &&
          selectedCommerce.parking.billing_type === "postpaid",
      },
      {
        text: viewTexts.prepaidTickets,
        value: "showPrepaidTickets",
        key: "showPrepaidTickets",
        active:
          selectedCommerce.category &&
          selectedCommerce.category !== "estacionamiento" &&
          selectedCommerce.parking &&
          selectedCommerce.parking.billing_type === "prepaid",
      },
      {
        text: "Bloquear | Desbloquear comercio",
        value: "block",
        key: "block",
        active: true,
      },
    ];

    const filteredOptions = extraOptions.filter((option) => option.active);
    return [...this.initialState.commercesOptions, ...filteredOptions];
  };
  onOptionSelection = (event, value) => {
    const options = {
      showUsers: this.handleViewUsers,
      showWithdrawals: this.showWithdrawals,
      showMovements: this.showMovements,
      showInvoices: this.handleViewInvoices,
      changeBillingType: this.changeBillingType,
      showPrepaidTickets: this.displayPrepaidTickets,
      block: this.toggleBlockedStatus,
    };
    const fn = options[value]
      ? options[value]
      : () => {
          console.log({ value });
        };
    fn(event);
  };
  render() {
    const { viewState, viewType, selectedCommerce, className } = this.props;

    const {
      name,
      address,
      commerceId,
      ownerName,
      phone,
      /* billingType */ funds,
    } = this.formatCommerceData(selectedCommerce);
    const options = this.getOptions(selectedCommerce);
    const optionsDisabled =
      viewState === "commerce_getusers_loading" ||
      viewState === "commerce_info_loading" ||
      viewState === "commerce_withdraw" ||
      viewState === "commerce_withdraw_loading" ||
      viewState === "commerce_withdraw_payment" ||
      viewState === "commerce_get_movements_loading" ||
      viewState === "commerce_withdraw_payment_loading";
    return (
      <Panel className={viewState === "commerce_info_loading" && "inactive"}>
        <div className={className}>
          <div className="commerce-img-container">
            <img
              className="commerce-img"
              src={defaultUserWhite}
              alt="default user img"
            />
          </div>
          <Options
            options={options}
            onChange={this.onOptionSelection}
            disabled={optionsDisabled}
            phone={viewType === "phone"}
            back={this.removeSelectedCommerce}
          />
          <h4 className="commerce-name">{name}</h4>
          <hr />
          <dl className="commerce-data">
            <div>
              <dt>{viewTexts.commerceAddressHeader}</dt>
              <dd>{address}</dd>
            </div>
            <div>
              <dt>{viewTexts.commerceIDHeader}</dt>
              <dd>{commerceId}</dd>
            </div>
            <div>
              <dt>{viewTexts.commerceContactHeader}</dt>
              <dd>{ownerName}</dd>
            </div>
            <div>
              <dt>{viewTexts.commercePhoneHeader}</dt>
              <dd>{phone}</dd>
            </div>

            <div>
              <dt>{viewTexts.commerceWallet}</dt>
              <dd>{funds}</dd>
            </div>
          </dl>
        </div>
      </Panel>
    );
  }
}

const mapStateToProps = (state) => ({
  viewState: state.viewMposCommerces.viewState,
  viewType: state.responsiveData.viewType,
  commerces: state.viewMposCommerces.commerces,
  selectedCommerce: state.viewMposCommerces.selectedCommerce,
});

const mapDispatchToProps = {
  setViewState,
  selectCommerce,
  removeCommerce,
  throwPopupMessage,
  updateCommercesList,
};

const CommercePanelModel = connect(
  mapStateToProps,
  mapDispatchToProps
)(CommercePanelModel_unconected);

export { CommercePanelModel };
