import React, { useContext } from "react";
import { Button } from "components/buttons";
import { general as texts } from "assets/strings/texts";
import { ActivateMPContext } from "../../context";

const ConfirmationModel = ({ children, className }) => {
  const {
    closePopup: cancel,
    handleDisable: confirm,
    disableLoading,
  } = useContext(ActivateMPContext);

  return (
    <div className={className}>
      {children}
      {disableLoading && (
        <div className="ui active loader" style={{ marginTop: "2rem" }}></div>
      )}
      <Button color="blue" disabled={disableLoading} onClick={confirm}>
        {!disableLoading && texts.accept}
        {disableLoading && `${texts.wait}...`}
      </Button>
      <Button color="grey" onClick={cancel} disabled={disableLoading}>
        Cancelar
      </Button>
    </div>
  );
};

export { ConfirmationModel };
