import React from "react";
import Bill from "./bill";
import Ticket from "./ticket";
import SummaryBar from "./summary-bar";
import { Grid } from "semantic-ui-react";
import { Button } from "components/buttons";
import SearchInputNew from "components/search-input-new";
import PopupMessage from "components/popup-message";
import { general as texts } from "assets/strings/texts";
import okCircleIcon from "assets/img/ok-circle-icon.png";
import { formatTicketsListLessInfo } from "components/ticket-list-item";
import { EntitiesList, Pagination } from "components/entities-list";
import { tickets_validate as viewTexts } from "assets/strings/texts";
import { RightBorderGreyColumn } from "components/styled-grid-column";
import { ResultScreen } from "components/result-screen/styles";

const MposTicketsValidateView = ({
  viewState,
  searchParam,
  tickets,
  currentPage,
  nextPageExist,
  showTicket,
  selectedTicket,
  billContent,
  currency,
  commerce,
  popupMessages,
  popupMessagesType,
  showPopup,
  location,
  totalAmount,
  closePopup,
  handleSearchInputChange,
  handlePageDown,
  handlePageUp,
  handleTicketSelection,
  getTicketAmount,
  toggleValidatedTicket,
  validateTicket,
  resetView,
  handleSearchTicket,
}) => {
  let isPrepaidCommerce =
    commerce &&
    commerce.parking &&
    commerce.parking.billing_type &&
    commerce.parking.billing_type === "prepaid";
  let canValidate = isPrepaidCommerce
    ? commerce.parking.prepaid_tickets > 0
      ? true
      : false
    : true;
  return (
    <Grid padded>
      {viewState !== "ticket_validation" &&
        viewState !== "ticket_validation_loading" && (
          <PopupMessage
            messageType={popupMessagesType}
            showPopup={showPopup}
            messages={popupMessages}
            closePopup={closePopup}
          />
        )}

      {(viewState === "ticket_validation" ||
        viewState === "ticket_validation_loading") && (
        <PopupMessage
          messageType={popupMessagesType}
          showPopup={showPopup}
          messages={popupMessages}
          closePopup={toggleValidatedTicket}
        >
          <Button
            color="blue"
            disabled={viewState === "ticket_validation_loading" || !canValidate}
            onClick={validateTicket}
          >
            {viewState === "ticket_validation" && "Aceptar"}
            {viewState === "ticket_validation_loading" &&
              viewTexts.validateLoading}
          </Button>
          <Button color="grey" onClick={toggleValidatedTicket}>
            {texts.back}
          </Button>
        </PopupMessage>
      )}

      {viewState === "ticket_validation_done" && (
        <Grid.Column width={16}>
          <ResultScreen
            imagePath={okCircleIcon}
            text={viewTexts.successValidate}
            buttonText={texts.continue}
            handleFinishButton={resetView}
          />
        </Grid.Column>
      )}

      {viewState !== "ticket_validation_done" && (
        <RightBorderGreyColumn mobile={8} tablet={5} computer={4}>
          <SearchInputNew
            placeholder={viewTexts.searchPlaceholder}
            searchParam={searchParam}
            handleChange={handleSearchInputChange}
            handleSearch={handleSearchTicket}
            width={"20%"}
          />
          <EntitiesList
            height="calc(100vh - 9rem)"
            viewState={viewState}
            entities={tickets}
            formattingFunction={formatTicketsListLessInfo}
            selectionHandler={handleTicketSelection}
          >
            <Pagination
              padding="0.65rem 0 0.65rem 0"
              nextPageExist={nextPageExist}
              currentPage={currentPage}
              handlePageUp={handlePageUp}
              handlePageDown={handlePageDown}
            />
          </EntitiesList>
        </RightBorderGreyColumn>
      )}

      {viewState !== "ticket_validation_done" && (
        <Grid.Column mobile={8} tablet={11} computer={12}>
          <SummaryBar currency={currency} billContent={billContent} />
          <Grid padded>
            <Grid.Column mobile={16} tablet={10} computer={10}>
              <Ticket
                viewState={viewState}
                showTicket={showTicket}
                ticketData={selectedTicket}
                getTicketAmount={getTicketAmount}
                canValidate={canValidate}
                totalAmount={totalAmount}
                validate={toggleValidatedTicket}
              />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={6} computer={6}>
              <Bill
                header={viewTexts.billHeader}
                billContent={billContent}
                currency={currency}
              />
            </Grid.Column>
          </Grid>
        </Grid.Column>
      )}
    </Grid>
  );
};

export default MposTicketsValidateView;
