import React, { useRef, useState } from "react";

const FileInputModel = ({ onChange, className }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      onChange(file);
    }
  };

  return (
    <div className={className}>
      <input
        type="file"
        accept=".xlsx, .xls, .csv, .txt"
        style={{ display: "none" }}
        onChange={handleFileChange}
        ref={fileInputRef}
      />
      <button
        type="button"
        onClick={() => {
          fileInputRef.current.click();
        }}
        className={`file-button${selectedFile ? " selected" : ""}`}
      >
        {selectedFile
          ? `Archivo seleccionado: ${selectedFile.name}`
          : "Seleccionar archivo"}
      </button>
    </div>
  );
};

export default FileInputModel;
