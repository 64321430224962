import thunk from 'redux-thunk';
import rootReducer from "./reducers";
import throttle from 'lodash/throttle';
import { createStore, applyMiddleware, compose } from "redux";
import { 
  loadStateFromLocalStorage,
  saveStateToLocalStorage
} from 'services/utils'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistedState = loadStateFromLocalStorage();

const store = createStore(
  rootReducer, 
  persistedState,
  composeEnhancers(
    applyMiddleware(thunk)
  )
);

store.subscribe(throttle( ()=>{
  const { buildings, commerces, session } = store.getState()
  saveStateToLocalStorage( { buildings, commerces, session } )
}), 1000)

export { store }