import { getBankAmounts } from "./api";

export const bankAmounts = async (changeState, throwPopupMessage) => {
  try {
    changeState({ loadingAmounts: true });
    const response = await getBankAmounts();
    const res = await response.json();
    changeState({
      bankAmounts: res.data.bank_amounts,
    });
    if (res.error) throw res.error;
  } catch (error) {
    throwPopupMessage(
      "error",
      "Error al buscar los datos de las cuentas bancarias"
    );
  }
  changeState({ loadingAmounts: false });
};
