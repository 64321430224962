import {
  //baseURL,
  micropd_01,
} from "services/config";

import { get, postFormData } from "services/http-methods";
//import { micropd_01 } from "../services/config";

const endpoints = {
  push: `${micropd_01}/superadmin/push`,
};

export function pushInfo() {
  return get(endpoints.push);
}

export function postPush(formData) {
  /**
   * @att
   *  title
   *  body
   *  screen
   *  file_format
   *  days_to_expire
   *  excel
   */
  return postFormData(endpoints.push, formData);
}
