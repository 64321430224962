import { TotalAmountsModel } from "./model";
import styled from "styled-components";
import { bluePD, grey_4 } from "assets/strings/colors";

const TotalAmounts = styled(TotalAmountsModel)`
  #root && {
    height: 120px;
    width: 100%;
    margin-top: 1rem;
    > .item {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 50px;
      > .amount {
        width: 100%;
        text-align: center;
        font-size: clamp(0.9rem, 1.2vw, 1.3rem);
        margin-bottom: -0.2rem;
        border-bottom: 2px solid ${bluePD};
      }
      > .title {
        width: 100%;
        padding-top: 0.4rem;
        font-size: clamp(0.8rem, 1vw, 1.1rem);
        color: ${grey_4};
        text-transform: uppercase;
        text-align: center;
      }
    }
  }
`;

export { TotalAmounts };
