import { HistoryFormModel } from "./model";
import styled from "styled-components";
import { grey_4, grey_6 } from "assets/strings/colors";

export const HistoryForm = styled(HistoryFormModel)`
  #root & {
    margin: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid ${grey_4};
    align-items: center;
    justify-content: space-between;
    padding: 1rem;

    > .ui.transparent.labeled.input {
      width: 100%;
      margin-top: 1rem;
      height: 4rem;
      font-size: 1.2rem;
      overflow: hidden;
      transition: 200ms;

      > .search.icon.label {
        height: 2rem;
        padding-top: 0.2rem;
        padding-bottom: 0;
        color: ${grey_4};
      }

      > input {
        margin-left: 0.5rem;
        height: 2rem;
        width: 60%;
      }

      > input::placeholder {
        overflow-wrap: normal;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    > .ui.transparent.labeled.input.hidden {
      display: none;
    }

    .selectors {
      min-height: 4rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      > p {
        font-weight: bold;
        text-align: center;
        font-size: 0.8rem;
        margin: 0;
        text-transform: uppercase;
        width: 30%;
      }

      > button {
        margin: 0 0.5rem;
        height: 3.2rem;
      }
    }

    > .search {
      margin: 0;
      height: 10vh;
      width: 100%;
      display: flex;
      align-items: center;
      align-self: flex-end;

      > button {
        height: 6vh;
        margin: 0;
        border: none;
        border-radius: 0.2rem;
        width: 100%;
        color: white;
        font-size: 2vh;
        font-weight: bold;
        background: ${grey_4};

        :hover {
          background: ${grey_6};
          cursor: pointer;
        }
      }
    }

    > .selection {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;

      > .label {
        font-weight: bold;
        text-align: center;
        font-size: 1rem;
        margin: 0;
        text-transform: uppercase;
        width: 20%;
      }
    }
  }
`;
