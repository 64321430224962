import React from "react";
import { Icon } from "semantic-ui-react";
import moment from "moment";
import { formatPrice } from "services/utils";

const ValidateTicketModel = ({
  handleValidate,
  generatedTicket,
  className,
}) => {
  return (
    <div className={className}>
      <div className="ticket">
        {generatedTicket.status === "ready" && (
          <Icon name="warning circle" size="huge" className="icon" />
        )}
        <div className="titles">
          <p className="title">Ticket Digital Pago Pendiente</p>
          <p className="sub-title">Paga tu ticket digital para poder salir</p>
        </div>
        <div className="current-info">
          <div className="row">
            <div className="data-left">
              <p className="header">vip off</p>
              <Icon name="toggle off" size="large" className="icon" />
            </div>
            <div className="data-right">
              <p className="header">estacionamiento</p>
              <p className="content">
                {generatedTicket &&
                generatedTicket.building &&
                generatedTicket.building.name
                  ? generatedTicket.building.name
                  : "No disponible"}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="data-left">
              <p className="header">usuario</p>
              <p className="content">
                @
                {generatedTicket && generatedTicket.username
                  ? generatedTicket.username
                  : "No disponible"}
              </p>
            </div>
            <div className="data-right">
              <p className="header">hora de entrada</p>
              <p className="content">
                {generatedTicket && generatedTicket.enter_at
                  ? moment(generatedTicket.enter_at).format("HH:mm")
                  : "--:--"}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="data-left">
              <p className="header">monto a pagar</p>
              <p className="amount">
                {generatedTicket && generatedTicket.hasOwnProperty("amount")
                  ? formatPrice(generatedTicket.amount)
                  : formatPrice(0)}{" "}
                Bs
              </p>
            </div>
            <div className="data-right">
              <p className="header">fecha</p>
              <p className="content">
                {generatedTicket && generatedTicket.enter_at
                  ? moment(generatedTicket.enter_at).format("ll")
                  : "--:--"}
              </p>
            </div>
          </div>
        </div>
        <div className="footer">
          <p className="title">Ticket ID:</p>
          <p className="ticket-id">
            {" "}
            {generatedTicket && generatedTicket.ticket_number
              ? generatedTicket.ticket_number
              : "No disponible"}
          </p>
        </div>
      </div>
      <button onClick={handleValidate}>Habilitar</button>
    </div>
  );
};

export { ValidateTicketModel };
