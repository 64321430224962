import React from "react";
import { Grid } from "semantic-ui-react";
import { UsersList } from "./users-list";
import { NewUserForm } from "./new-user-form";
import { UserSelected } from "./user-selection";
import { Confirmation } from "./confirmation";
import { GridColumn } from "semantic-ui-react";
import PopupMessage from "components/popup-message";

const CommerceUserPhoneView = ({
  //loaders
  addUserLoading,
  loadingUsers,
  loadingRoles,
  loadingConfirm,
  showSelectedUser,
  isSelected,
  //data
  view,
  showForm,
  confirm,
  confirmText,
  commerceUsers,
  roles,
  form,
  selectedUser,
  //method
  handleChange,
  handlePhoneChange,
  handleRoleChange,
  handleEditRole,
  handleDeleteUser,
  handleEditStatus,
  handleSubmitUser,
  handleUserSelection,
  handleRemove,
  handleConfirm,
  handleShowForm,

  popupMessagesType,
  showPopup,
  popupMessages,
  closePopup,
}) => {
  return (
    <Grid padded>
      <PopupMessage
        messageType={popupMessagesType}
        showPopup={showPopup}
        messages={popupMessages}
        closePopup={closePopup}
      >
        {confirm && (
          <Confirmation
            loading={loadingConfirm}
            handleConfirm={handleDeleteUser}
            handleCancel={closePopup}
            textInfo={confirmText}
          />
        )}
      </PopupMessage>
      <GridColumn width={16}>
        {!isSelected && !showForm && (
          <UsersList
            //loaders
            view={view}
            addUserLoading={addUserLoading}
            loadingUsers={loadingUsers}
            //data
            commerceUsers={commerceUsers}
            //methods
            handleUserSelection={handleUserSelection}
            handleShowForm={handleShowForm}
            key="list"
          />
        )}
        {!isSelected && showForm && (
          <NewUserForm
            //loaders
            addUserLoading={addUserLoading}
            loadingRoles={loadingRoles}
            showForm={showForm}
            //data
            view={view}
            roles={roles}
            form={form}
            //methods
            handleChange={handleChange}
            handlePhoneChange={handlePhoneChange}
            handleRoleChange={handleRoleChange}
            handleSubmitUser={handleSubmitUser}
            handleRemove={handleRemove}
            key={"form"}
          />
        )}

        {isSelected && (
          <UserSelected
            user={selectedUser}
            roles={roles}
            view={view}
            loading={loadingRoles}
            showUser={showSelectedUser}
            isSelected={isSelected}
            handleEditRole={handleEditRole}
            handleDeleteUser={handleConfirm}
            handleEditStatus={handleEditStatus}
            handleRemove={handleRemove}
            key="details"
          />
        )}
      </GridColumn>
    </Grid>
  );
};
export { CommerceUserPhoneView };
