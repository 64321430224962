import React from "react";
import { Button } from "components/buttons";

const InputFeeModel = ({
  fee,
  handleFee,
  handleSubmit,
  buildingsSelected,
  className,
}) => {
  return (
    <div className={className}>
      <p className="title">Ingrese el nuevo fee</p>
      <input
        type="text"
        value={fee}
        onChange={handleFee}
        placeholder="48.500,15"
      />
      <Button
        color="green"
        width="40%"
        margin="1rem 0"
        onClick={handleSubmit}
        disabled={!fee || buildingsSelected.length < 1}
      >
        Aceptar
      </Button>
    </div>
  );
};

export { InputFeeModel };
