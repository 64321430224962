import React, { useState } from "react";
import { connect } from "react-redux";
import { clearReduxStore } from "redux/actions/general";
import { throwPopupMessage, closePopup } from "redux/actions/popup-messages";
import { Redirect } from "react-router-dom";

import { useTextInput, useNumberInput } from "hooks/use-inputs";
import { useDropdown } from "hooks/use-dropdown";

import { CSSTransition } from "react-transition-group";
import { Button } from "components/buttons";
import { Segment, Dropdown } from "semantic-ui-react";
import { Confirmation } from "../confirmation";
import { Loader } from "components/loader";

import { addCommerceBankAccount } from "services/api";

const BankAccountFormModel = ({
  className,
  viewType,
  handleBack,
  bankList,
  update,
  throwPopupMessage,
  closePopup,
}) => {
  const {
    setValue: setBankName,
    key: bankCode,
    bind: { value: bankName, onChange: handleBankName },
  } = useDropdown("");
  const {
    setValue: setAccountNumber,
    bind: { value: accountNumber, onChange: handleAccountNumber },
  } = useNumberInput("");
  const {
    setValue: setAccountName,
    bind: { value: accountName, onChange: handleAccountName },
  } = useTextInput("");

  const {
    setValue: setAccountDescription,
    bind: { value: accountDescription, onChange: handleAccountDescription },
  } = useDropdown("");
  const {
    setValue: setDocId,
    bind: { value: docId, onChange: handleDocId },
  } = useNumberInput("");
  const {
    setValue: setDocType,
    bind: { value: docType, onChange: handleDocType },
  } = useDropdown("V");
  const [authError, setAuthError] = useState(false);
  const handleConfirmation = (event) => {
    event.preventDefault();
    throwPopupMessage(
      "alert",
      <Confirmation handleClose={closePopup} handleSubmit={handleSubmit} />
    );
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    submitFunction();
  };

  const submitFunction = async () => {
    try {
      throwPopupMessage("alert", <Loader margin={"2rem 0 0 0"} />);
      const response = await addCommerceBankAccount({
        bank_name: bankName,
        bank_code: bankCode,
        account_name: accountName, // A NOMBRE DE
        account_type: "Nacional", // Nacional o Internacional
        account_number: accountNumber,
        account_description: accountDescription, //Tipo de cuenta
        currency: "BS",
        doc_id: docId,
        doc_type: docType,
      });
      const res = await response.json();
      if (res.error) throw res.error;
      throwPopupMessage("success", "Cambios realizados con éxito");
      setBankName("");
      setAccountName("");
      setAccountNumber("");
      setAccountDescription("");
      setDocId("");
      setDocType("V");
      update();
    } catch (error) {
      if (
        error.id === "NO_TOKEN_PROVIDED" ||
        error.id === "AUTHENTICATE_FAILED" ||
        error.id === "DUPLICATE_SESSION"
      ) {
        //this.props.clearReduxStore();
        //return;
        setAuthError(true);
      } else {
        throwPopupMessage("error", error.description);
      }
    }
  };

  return authError ? (
    <Redirect to="/" />
  ) : (
    <div className={className}>
      <CSSTransition
        in={true}
        timeout={350}
        appear={true}
        classNames="register"
      >
        <div className="register">
          <p className="title">Registrar Nueva Cuenta</p>
          <Segment.Group className="register-form" autoComplete="off">
            <div className="ui transparent input segment">
              <Dropdown
                className="label"
                fluid
                scrolling
                selectOnBlur={false}
                placeholder="Banco"
                name="Banco"
                value={bankName}
                options={bankList}
                onChange={handleBankName}
              />
            </div>
            <div className="ui transparent input segment">
              <input
                name="account_number"
                type="text"
                minLength={20}
                maxLength={20}
                autoComplete="new_bank_number"
                placeholder="Número de cuenta"
                onChange={handleAccountNumber}
                value={accountNumber}
              />
            </div>
            <div className="ui transparent input segment">
              <input
                name="account_name"
                type="text"
                autoComplete="new-account_name"
                placeholder="A nombre de"
                onChange={handleAccountName}
                value={accountName}
              />
            </div>
            <div className="ui transparent input segment">
              <Dropdown
                className="label "
                fluid
                scrolling
                selectOnBlur={false}
                name="account_description"
                placeholder="Tipo de Cuenta"
                value={accountDescription}
                options={[
                  {
                    key: 0,
                    text: "Cuenta Corriente",
                    value: "Cuenta Corriente",
                  },
                  {
                    key: 1,
                    text: "Cuenta de Ahorros",
                    value: "Cuenta de Ahorros",
                  },
                ]}
                onChange={handleAccountDescription}
              />
            </div>
            <div className="ui transparent left labeled input segment">
              <Dropdown
                className="label"
                name="doc_type"
                value={docType}
                options={[
                  { key: 0, text: "V.", value: "V" },
                  { key: 1, text: "J.", value: "J" },
                  { key: 2, text: "E.", value: "E" },
                ]}
                onChange={handleDocType}
              />
              <input
                name="doc_id"
                type="text"
                maxLength={9}
                autoComplete="off"
                placeholder="Número"
                value={docId}
                onChange={handleDocId}
              />
            </div>
          </Segment.Group>
          <div className="selectors" key="selectors">
            <Button
              color="blue"
              disabled={
                bankName === "" ||
                accountNumber === "" ||
                accountName === "" ||
                accountNumber.length < 20 ||
                accountDescription === "" ||
                docId === "" ||
                docType === ""
              }
              key="buttom-submit"
              onClick={handleConfirmation}
            >
              {" "}
              Registrar
            </Button>
            {viewType === "phone" && (
              <Button
                color="grey"
                style={{ marginBottom: "1.5rem" }}
                onClick={handleBack}
              >
                Volver
              </Button>
            )}
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

const mapStateToProps = null;

const mapDispatchToProps = {
  clearReduxStore,
  throwPopupMessage,
  closePopup,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BankAccountFormModel);
