import React from "react";
import { Grid } from "semantic-ui-react";
import { PopupMessageContext } from "components/popup-message";
import { Panel } from "components/panel";
import { WalletTodayMovementsContext } from "../context";
import { HistoryForm } from "./history-form";
import { History } from "./history";

const WalletTodayMovementsView = ({ phoneView }) => {
  return (
    <Grid centered padded>
      <PopupMessageContext context={WalletTodayMovementsContext} />
      <Grid.Column mobile={16} tablet={14} computer={10}>
        <Panel>
          <HistoryForm phoneView={phoneView} />
        </Panel>
        <Panel>
          <History phoneView={phoneView} />
        </Panel>
      </Grid.Column>
    </Grid>
  );
};

export default WalletTodayMovementsView;
