import { view_type_desktop, view_type_phone } from 'assets/strings/constants'

import { 
  RESPONSIVE_DATA_UPDATE,
} from 'redux/actions/responsive-data'

const initialState = {
  windowWidth: window.innerWidth,
  windoweight: window.innerHeight,
  viewType: window.innerWidth < 768 ? view_type_phone : view_type_desktop,
}

export default function(state = initialState, action){
  switch (action.type) {
    
    case RESPONSIVE_DATA_UPDATE:
      let { responsiveData } = action.payload
      return {
        ...state,
        ...responsiveData,
      }
    
    default:
      return state
  }
}