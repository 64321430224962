import { baseURL_V2, micropd_02 } from "services/config";
import { post, get } from "services/http-methods";

const endpoints = {
  transfer: `${micropd_02}/superadmin/bank-transaction/transfer`,
  transactions: `${micropd_02}/superadmin/bank-transaction/list`,
  transactions_pd: `${baseURL_V2}/superadmin/bank/transfer/transactions-pd`,
  commerces: `${baseURL_V2}/superadmin/commerces/list`,
};
export function mockResponse(body) {
  const data = {
    ok: true,
    message: "Transferencia realizada con éxito",
    bankTransaction: {
      test: "test",
      reference: "ABC1234BV21",
    },
  };
  const blob = new Blob([JSON.stringify({ data })]);
  return new Promise((resolve) => {
    setTimeout(() => {
      const response = new Response(blob);
      resolve(response);
    }, 3000);
  });
}
export const bankTransfer = (body) => {
  /**
   * @brief
   * @param {json} receiver_account,receiver_name,receiver_document,concept,amount,bank, code
   */

  return post(endpoints.transfer, body);
  //return mockResponse(body);
};
export const bankTransferHistory = (body) => {
  /**
   * @brief
   * @param {json} date_from,date_to,transaction_type
   */

  return post(endpoints.transactions, body);
};
export const bankTransferPdHistory = (body) => {
  /**
   * @brief
   * @param {json} date_from,date_to,transaction_type
   */

  return post(endpoints.transactions_pd, body);
};
export const parkingCommercesList = () => {
  /**
   * @brief
   * @param {json} date_from,date_to,transaction_type,reference
   */

  return get(endpoints.commerces);
};
