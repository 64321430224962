import React, { useContext } from "react";
import { UsersExtensionsContext } from "../context";
import { RightBorderGreyColumn } from "components/styled-grid-column";
import SearchInputNew from "components/search-input-new";
import { EntitiesList } from "components/entities-list";
import { useEnter } from "hooks/use-enter";
import { formatUserList } from "./user-list-item";
import { useSearchUsersService } from "../services/users-search";
import { DownloadExcel } from "./download-excel";

export const UsersSearch = ({ phone, tablet, computer }) => {
  const { loading, setUserQuery, setActivate } = useSearchUsersService();
  const { state, changeState } = useContext(UsersExtensionsContext);

  const handleSearchUser = (event) => {
    event.preventDefault();
    setUserQuery(state.searchParam);
    setActivate(true);
    changeState({ searchUser: true });
  };

  const handleSearchInputChange = (event) => {
    let value = event.target.value;
    // The field is restrcted to accept only letters, numbers, @(first position) and _-.
    if (value.match(/^(?=@?.*)@?[\w.-]*$/)) {
      value = value.toLowerCase();
      // call the API in a debounced way
      changeState({ searchParam: value });
    }
  };

  const handleUserSelection = (event) => {
    event.preventDefault();
    let index = event.currentTarget.id;
    let selectedUser = state.users[index];
    changeState({
      viewState: "user_selected",
      selectedUser,
      searchExtensions: true,
      waitExtensions: true,
      getInfo: true,
    });
  };

  useEnter(handleSearchUser, state.searchParam);

  return (
    <RightBorderGreyColumn phone={phone} tablet={tablet} computer={computer}>
      <DownloadExcel />
      <SearchInputNew
        placeholder="Buscar Usuario"
        searchParam={state.searchParam}
        handleChange={handleSearchInputChange}
        handleSearch={handleSearchUser}
      />
      <EntitiesList
        height="calc(100vh - 7rem)"
        viewState={state.viewState}
        entities={state.users}
        formattingFunction={formatUserList}
        selectionHandler={handleUserSelection}
        additionalArgs={[loading]}
      />
    </RightBorderGreyColumn>
  );
};
