import { useEffect, useState } from "react";
import { customerExtensionsAPI } from "api";

export const useExcelGeneratorService = () => {
  const [loading, setLoading] = useState(false);
  const [activate, setActivate] = useState(false);
  const [url, setUrl] = useState("");
  useEffect(() => {
    const excel = async () => {
      setLoading(true);
      try {
        const response = await customerExtensionsAPI.generateExcel();
        const res = await response.json();
        if (res.error) throw res.error;
        setUrl(res.url);
      } catch (error) {
        console.log({ error });
      }
      setLoading(false);
    };
    if (activate) {
      excel();
      setActivate(false);
    }
  }, [activate]);

  return { loading, setActivate, url };
};
