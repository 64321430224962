import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { clearReduxStore } from "redux/actions/general";
import { CommerceUserView } from "./components/commerceUsersView";
import { CommerceUserPhoneView } from "./components/commerceUsersPhoneView";
import { errors } from "assets/strings/texts";

import {
  getCommercesRole,
  getCommerceUsers,
  addCommerceUser,
  editCommerceUserRole,
  editCommerceUserStatus
} from "services/api";
import {
  getSelectedCommerce as getSelectedCommerceFromRedux,
  getSelectedBuilding as getSelectedBuildingFromRedux
} from "redux/selectors";

const MPosCommerceUsers = () => {
  // For screen views
  const [view, setView] = useState("desktop");

  //For request
  const [roles, setRoles] = useState([]);
  const [loadingRoles, setLoadingRoles] = useState(true);
  const [commerceUsers, setCommerceUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(true);
  const [addUserLoading, setAddUserLoading] = useState(false);

  //For methods
  const [form, setForm] = useState({
    email: "",
    username: "",
    name: "",
    last_name: "",
    phone: "",
    role_id: "",
    password: ""
  });
  const [showForm, setShowForm] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showSelectedUser, setShowSelectedUser] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);

  const initialState = () => {
    setRoles([]);
    setLoadingRoles(true);
    setCommerceUsers([]);
    setLoadingUsers(true);
    setAddUserLoading(false);
    setForm({
      email: "",
      username: "",
      name: "",
      last_name: "",
      phone: "",
      role_id: "",
      password: ""
    });
    setShowForm(false);
    setSelectedUser(null);
    setShowSelectedUser(false);
    setIsSelected(false);
  };
  //popup Messages
  const [popup, setPopup] = useState({
    messages: null,
    messagesType: "",
    show: false
  });

  // 1)
  useEffect(() => {
    getRoles();
    getUsers();
    setIsSelected(false);
    setViewType();
    window.addEventListener("resize", setViewType);
    return () => {
      window.removeEventListener("resize", setViewType);
    };
  }, []);

  const setViewType = () => {
    let documentElement = document.documentElement,
      width = window.innerWidth || documentElement.clientWidth;
    if (width < 768) {
      setView("phone");
    } else {
      setView("desktop");
    }
  };

  const getRoles = async () => {
    try {
      setLoadingRoles(true);
      const response = await getCommercesRole();
      const res = await response.json();
      if (res.error) throw res.error;
      if (res.roles && res.roles.length) {
        let roles = [];
        res.roles.forEach((role, index) => {
          roles.push({
            text: role.name
              ? role.name === "Master"
                ? "Principal"
                : role.name
              : "",
            value: role._id ? role._id : "",
            key: index
          });
        });
        setRoles(roles);
        setLoadingRoles(false);
      } else {
        const error = {
          description: "No se pueden obtener los roles del comercio"
        };
        throw error;
      }
    } catch (error) {
      if (
        error.id === "NO_TOKEN_PROVIDED" ||
        error.id === "AUTHENTICATE_FAILED" ||
        error.id === "DUPLICATE_SESSION"
      ) {
        throwPopupMessage("alert", errors.AUTHENTICATION);
        //this.props.clearReduxStore();
        //return;
      }
      throwPopupMessage("error", error.description);
      initialState();
    }
  };

  const getUsers = async (user_id = null) => {
    try {
      setLoadingUsers(true);
      const response = await getCommerceUsers();
      const res = await response.json();
      if (res.error) throw res.error;
      if (!res.users || !res.users.length) {
        let error = {
          description: "No existen usuarios creados para este comercio"
        };
        throw error;
      }
      setCommerceUsers(res.users);
      setLoadingUsers(false);
      if (user_id) {
        const user = res.users.filter((user) => user._id === user_id);
        if (user && user.length > 0) {
          setSelectedUser(user[0]);
        } else {
          setIsSelected(false);
          setShowSelectedUser(false);
        }
      }
    } catch (error) {
      if (
        error.id === "NO_TOKEN_PROVIDED" ||
        error.id === "AUTHENTICATE_FAILED" ||
        error.id === "DUPLICATE_SESSION"
      ) {
        throwPopupMessage("alert", errors.AUTHENTICATION);
        //this.props.clearReduxStore();
        //return;
      } else {
        throwPopupMessage("error", error.description);
        initialState();
      }
    }
  };

  const addUserToCommerce = async (body) => {
    try {
      setAddUserLoading(true);
      const response = await addCommerceUser(body);
      const res = await response.json();
      if (res.error) throw res.error;
      setAddUserLoading(false);
      getUsers();
    } catch (error) {
      setAddUserLoading(false);
      const isInternal = error.id === "INTERNAL_ERROR" && error.extra;
      const isEmailError =
        isInternal && error.extra.errors && error.extra.errors.email;

      getUsers();
      throwPopupMessage(
        "error",
        isEmailError ? "Formato de Email incorrecto" : error.description
      );
    }
  };

  const changeUserStatus = async (status) => {
    try {
      let messages = "";
      switch (status) {
        case "deleted":
          messages = "Usuario eliminado con éxito";
          setLoadingConfirm(true);
          break;
        default:
          messages = "Estado cambiado con éxito";
          break;
      }
      let user_id = selectedUser._id;
      let body = { user_id, status };
      const response = await editCommerceUserStatus(body);
      const res = await response.json();
      if (res.error) throw res.error;
      getUsers(user_id);
      setLoadingConfirm(false);
      setConfirm(false);
      setPopup({
        show: true,
        messagesType: "success",
        messages: [messages]
      });
    } catch (error) {
      initialState();
      getUsers();
      getRoles();
      throwPopupMessage("error", error.description);
    }
  };

  // These handlers are request too
  const handleEditRole = async (event, data) => {
    event.preventDefault();
    try {
      let user_id = selectedUser._id;
      let body = { user_id, role_id: data.value };
      const response = await editCommerceUserRole(body);
      const res = await response.json();
      if (res.error) throw res.error;
      getUsers(user_id);
      throwPopupMessage("success", "Role cambiado con éxito");
    } catch (error) {
      initialState();
      throwPopupMessage("error", error.description);
      getUsers();
      getRoles();
    }
  };

  const handleDeleteUser = (event) => {
    event.preventDefault();
    changeUserStatus("deleted");
  };

  const handleEditStatus = (event, data) => {
    event.preventDefault();
    changeUserStatus(data.value);
  };

  // Methods
  const handleUserSelection = (event) => {
    let index = event.currentTarget.id;
    let user = commerceUsers[index];
    setShowSelectedUser(false);
    setTimeout(() => {
      setSelectedUser(user);
      setShowSelectedUser(true);
      setIsSelected(true);
    }, 350);
  };

  const handleConfirm = (event) => {
    event.preventDefault();
    let username =
      selectedUser && selectedUser.username ? selectedUser.username : "";
    setConfirm(true);
    throwPopupMessage("alert", `¿Deseas eliminar a @${username}?`);
  };
  const handleSubmitUser = (event) => {
    event.preventDefault();
    let body = form;
    addUserToCommerce(body);
    setForm({
      email: "",
      username: "",
      name: "",
      last_name: "",
      phone: "",
      role_id: ""
    });
  };

  const handleChange = (event) => {
    event.preventDefault();
    setForm({
      ...form,
      [event.target.name]: event.target.value
    });
  };
  const handlePhoneChange = (event) => {
    event.preventDefault();
    let phone = event.target.value.replace(/[^0-9\s]/g, "");
    phone = phone.replace(" ", "");
    setForm({
      ...form,
      phone
    });
  };
  const handleRoleChange = (event, data) => {
    event.preventDefault();
    let role_id = data.value;
    setForm({ ...form, role_id });
  };

  const handleRemove = (event) => {
    event.preventDefault();
    setTimeout(() => {
      setShowForm(false);
      setIsSelected(false);
    }, 350);
  };

  const handleShowForm = (event) => {
    event.preventDefault();
    setShowForm(false);
    setTimeout(() => {
      setShowForm(true);
      setIsSelected(false);
    }, 350);
  };

  //Popup messages

  const throwPopupMessage = (messageType, ...messages) => {
    setPopup({
      show: true,
      messagesType: messageType,
      messages: [...messages]
    });
  };
  const closePopup = () => {
    if (confirm) setConfirm(false);
    setPopup({
      show: false,
      messagesType: "",
      messages: null
    });
  };

  return view === "phone" ? (
    <CommerceUserPhoneView
      //loaders
      addUserLoading={addUserLoading}
      loadingRoles={loadingRoles}
      loadingUsers={loadingUsers}
      loadingConfirm={loadingConfirm}
      showSelectedUser={showSelectedUser}
      isSelected={isSelected}
      //datos
      view={view}
      showForm={showForm}
      commerceUsers={commerceUsers}
      form={form}
      confirm={confirm}
      roles={roles}
      selectedUser={selectedUser}
      //methods
      handleUserSelection={handleUserSelection}
      handleChange={handleChange}
      handlePhoneChange={handlePhoneChange}
      handleRoleChange={handleRoleChange}
      handleSubmitUser={handleSubmitUser}
      handleRemove={handleRemove}
      handleShowForm={handleShowForm}
      handleEditStatus={handleEditStatus}
      handleEditRole={handleEditRole}
      handleDeleteUser={handleDeleteUser}
      handleConfirm={handleConfirm}
      //popupMessages
      popupMessages={popup.messages}
      popupMessagesType={popup.messagesType}
      showPopup={popup.show}
      closePopup={closePopup}
    />
  ) : (
    <CommerceUserView
      //loaders
      addUserLoading={addUserLoading}
      loadingRoles={loadingRoles}
      loadingUsers={loadingUsers}
      loadingConfirm={loadingConfirm}
      isSelected={isSelected}
      showSelectedUser={showSelectedUser}
      showForm={showForm}
      //datos
      view={view}
      roles={roles}
      confirm={confirm}
      commerceUsers={commerceUsers}
      form={form}
      selectedUser={selectedUser}
      //methods
      handleUserSelection={handleUserSelection}
      handleChange={handleChange}
      handlePhoneChange={handlePhoneChange}
      handleRoleChange={handleRoleChange}
      handleSubmitUser={handleSubmitUser}
      handleEditStatus={handleEditStatus}
      handleEditRole={handleEditRole}
      handleDeleteUser={handleDeleteUser}
      handleConfirm={handleConfirm}
      handleShowForm={handleShowForm}
      //popupMessages
      popupMessages={popup.messages}
      popupMessagesType={popup.messagesType}
      showPopup={popup.show}
      closePopup={closePopup}
    />
  );
};

const mapStateToProps = (state) => {
  let getSelectedBuilding = getSelectedBuildingFromRedux(state);
  let getSelectedCommerce = getSelectedCommerceFromRedux(state);
  return { getSelectedBuilding, getSelectedCommerce };
};
const mapDispatchToProps = {
  clearReduxStore
};

export default connect(mapStateToProps, mapDispatchToProps)(MPosCommerceUsers);
