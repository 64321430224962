import React from "react";
import { Grid } from "semantic-ui-react";
import { RightBorderGreyColumn } from "components/styled-grid-column";
import PopupMessage from "components/popup-message";
import { Filters } from "./filters";
import { TransactionsList } from "./transactions-list";
import { TransactionDetail } from "./transaction-detail";

const OvertimeTicketsTransactionsView = ({
  viewType,
  transactionSelected,
  transactionList,
  handleTransactionSelection,
  loading,
  showDetail,
  handleRefresh,
  handleApprove,
  handleBack,
  fromDate,
  endDate,
  handleFromDate,
  handleEndDate,
  popupMessages,
  popupMessagesType,
  showPopup,
  closePopup,
  nextPageExists,
  page,
  handlePageUp,
  handlePageDown,
}) => {
  return (
    <Grid padded>
      <PopupMessage
        messageType={popupMessagesType}
        showPopup={showPopup}
        messages={popupMessages}
        closePopup={closePopup}
      />
      <RightBorderGreyColumn phone={8} tablet={8} computer={6}>
        <Filters
          fromDate={fromDate}
          endDate={endDate}
          handleFromDate={handleFromDate}
          handleEndDate={handleEndDate}
          handleSubmit={handleRefresh}
        />
        <TransactionsList
          transactionList={transactionList}
          handleTransactionSelection={handleTransactionSelection}
          nextPageExists={nextPageExists}
          page={page}
          handlePageUp={handlePageUp}
          handlePageDown={handlePageDown}
          loading={loading}
        />
      </RightBorderGreyColumn>
      <Grid.Column phone={8} tablet={8} computer={10}>
        <Grid centered padded style={{ background: "#f0f0ff" }}>
          <Grid.Column phone={14} tablet={14} computer={12}>
            <TransactionDetail
              viewType={viewType}
              transactionSelected={transactionSelected}
              showDetail={showDetail}
              handleApprove={handleApprove}
              handleBack={handleBack}
            />
          </Grid.Column>
        </Grid>
      </Grid.Column>
    </Grid>
  );
};

export { OvertimeTicketsTransactionsView };
