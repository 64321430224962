import React from "react";
import SearchInputNew from "components/search-input-new";
import { users_admin as viewTexts } from "assets/strings/texts";
import { EntitiesList } from "components/entities-list";
import { formatUserList } from "../user-list-item";
import { Button } from "components/buttons";

const SearchByUserModel = ({
  viewState,
  loading,
  searchParam,
  handleSearchInputChange,
  handleSearchUser,
  users,
  allSelection,
  handleUserSelection,
  handleAllUsersSelection,
  className,
}) => {
  const hasUsersList = users && users.length && users.length > 0;
  return (
    <div className={className}>
      <SearchInputNew
        placeholder={viewTexts.userSearchPlaceholder}
        searchParam={searchParam}
        handleSearch={handleSearchUser}
        handleChange={handleSearchInputChange}
      />
      <EntitiesList
        height="calc(100vh - 20em)"
        viewState={viewState}
        entities={users}
        formattingFunction={formatUserList}
        selectionHandler={handleUserSelection}
        additionalArgs={[loading]}
      />
      {!allSelection && hasUsersList && (
        <div className="buttons">
          <Button color="blue" width="80%" onClick={handleAllUsersSelection}>
            Seleccionar Todo
          </Button>
        </div>
      )}
    </div>
  );
};

export { SearchByUserModel };
