import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from 'components/buttons'
import { general as texts } from 'assets/strings/texts'
import { setViewState }from 'redux/actions/view-mpos-commerces'
import { throwPopupMessage }from 'redux/actions/popup-messages'
import { commerces_admin as viewTexts } from 'assets/strings/texts'
import { getViewMposCommerces } from 'redux/selectors/view-mpos-commerces'
import { PrepaidTicketsConfirmation } from '../prepaid-tickets-confirmation'
import { Panel, PanelTitle, PanelSectionContent, PanelSectionHeader } from 'components/panel'

class PrepaidTicketsPanelModel_unconnected extends Component {
  constructor(props){
    super(props)
    this.state = {
      numOfTickets: '',
    }
  }

  handleNumOfTickets = e => {
    const numOfTickets = e.target.value

    this.setState({ numOfTickets })
  }

  displayConfirmationPopup = e => {
    e.preventDefault()

    this.props.throwPopupMessage(
      'alert',
      <PrepaidTicketsConfirmation numOfTickets={this.state.numOfTickets} />
    )
  }

  backToCommerceInfo = () => {
    this.props.setViewState('commerce_info')
  }
  
  render() {
    const {
      viewType,
      className,
      selectedCommerce,
    } = this.props

    const {
      numOfTickets
    } = this.state;

    return(
      <Panel>
        <div className={className}>

          <div className='current-tickets'>
            {viewType === 'phone' &&
              <PanelTitle>{selectedCommerce.name}</PanelTitle>  
            }
            <PanelTitle>{viewTexts.prepaidTickets}</PanelTitle>

            <PanelSectionHeader>
              {texts.available}
            </PanelSectionHeader>
            <PanelSectionContent>
              {selectedCommerce.parking.prepaid_tickets}
            </PanelSectionContent>
          </div>

          <form className='prepaid-tickets-form' onSubmit={this.displayConfirmationPopup}>
            <PanelTitle>{viewTexts.addTickets}</PanelTitle>

            <label htmlFor='numOfTickets'>
              {viewTexts.prepaidTickets}
            </label>
            <input
              id='numOfTickets'
              type='number'
              min='0'
              name='numOfTickets'
              required={true}
              autoComplete='off'
              placeholder='Número de tickets'
              value={numOfTickets}
              onChange={this.handleNumOfTickets}
            />

            <Button
              color='blue'
              type='submit'
            >
              {texts.add}
            </Button>
          </form>

          {viewType === 'phone' &&
            <Button
              color='grey'
              margin='1rem 0 0 0'
              onClick={this.backToCommerceInfo}
            >
              {texts.back}
            </Button>
          }
        </div>
      </Panel>
    )
  }
}

const mapStateToProps = state =>({
  viewType: state.responsiveData.viewType,
  selectedCommerce: getViewMposCommerces(state).selectedCommerce,
})

const mapDispatchToProps = {
  setViewState,
  throwPopupMessage,
}

const PrepaidTicketsPanelModel =
  connect(mapStateToProps, mapDispatchToProps)(PrepaidTicketsPanelModel_unconnected)

export { PrepaidTicketsPanelModel }