export const MPOS_COMMERCES_SET_VIEW_STATE = "MPOS_COMMERCES_SET_VIEW_STATE";
export const MPOS_COMMERCES_UPDATE_COMMERCES =
  "MPOS_COMMERCES_UPDATE_COMMERCES";
export const MPOS_COMMERCES_SELECT_COMMERCE = "MPOS_COMMERCES_SELECT_COMMERCE";
export const MPOS_COMMERCES_REMOVE_COMMERCE = "MPOS_COMMERCES_REMOVE_COMMERCE";
export const MPOS_COMMERCES_RESET_STATE = "MPOS_COMMERCES_RESET_STATE";
export const MPOS_COMMERCES_UPDATE_INVOICES = "MPOS_COMMERCES_UPDATE_INVOICES";
export const MPOS_COMMERCES_SELECT_INVOICE = "MPOS_COMMERCES_SELECT_INVOICE";
export const MPOS_COMMERCES_SELECT_WITHDRAW = "MPOS_COMMERCES_SELECT_WITHDRAW";
export const MPOS_COMMERCES_UPDATE_WITHDRAWALS =
  "MPOS_COMMERCES_UPDATE_WITHDRAWALS";
export const MPOS_COMMERCES_SELECT_USER = "MPOS_COMMERCES_SELECT_USER";
export const MPOS_COMMERCES_UPDATE_USERS = "MPOS_COMMERCES_UPDATE_USERS";

export const setViewState = (viewState) => ({
  type: MPOS_COMMERCES_SET_VIEW_STATE,
  payload: {
    viewState,
  },
});

export const updateCommercesList = (commercesList) => ({
  type: MPOS_COMMERCES_UPDATE_COMMERCES,
  payload: {
    commerces: commercesList,
  },
});

export const selectCommerce = (commerce) => ({
  type: MPOS_COMMERCES_SELECT_COMMERCE,
  payload: {
    selectedCommerce: commerce,
  },
});

export const removeCommerce = () => ({
  type: MPOS_COMMERCES_REMOVE_COMMERCE,
});

export const resetState = () => ({
  type: MPOS_COMMERCES_RESET_STATE,
});

export const updateInvoicesList = (invoicesList) => ({
  type: MPOS_COMMERCES_UPDATE_INVOICES,
  payload: {
    invoices: invoicesList,
  },
});

export const selectInvoice = (invoice) => ({
  type: MPOS_COMMERCES_SELECT_INVOICE,
  payload: {
    selectedInvoice: invoice,
  },
});

export const updateWithdrawList = (withdrawList) => ({
  type: MPOS_COMMERCES_UPDATE_WITHDRAWALS,
  payload: {
    withdraw: withdrawList,
  },
});
export const selectWithdraw = (withdraw) => ({
  type: MPOS_COMMERCES_SELECT_WITHDRAW,
  payload: {
    selectedWithdraw: withdraw,
  },
});

export const updateUsersList = (usersList) => ({
  type: MPOS_COMMERCES_UPDATE_USERS,
  payload: {
    users: usersList,
  },
});
export const selectUser = (user) => ({
  type: MPOS_COMMERCES_SELECT_USER,
  payload: {
    selectedUser: user,
  },
});
