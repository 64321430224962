import styled from "styled-components";
import NavbarComponent from "./model";
import { bluePD, blue_6, purplePD } from "assets/strings/colors";

const Navbar = styled(NavbarComponent)`
  #root & {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 4rem;
    font-size: 1.375rem;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#c61999+0,06a3e5+100 */
    ${({ bgColors }) => {
      if (!bgColors) {
        bgColors = [bluePD, purplePD];
      }

      return `background: ${bgColors[0]};
				background: -moz-linear-gradient(350deg, ${bgColors[0]} 0%, ${bgColors[1]} 100%);
				background: -webkit-linear-gradient(350deg, ${bgColors[0]} 0%,${bgColors[1]} 100%);
				background: linear-gradient(350deg, ${bgColors[0]} 0%,${bgColors[1]} 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='${bgColors[0]}', endColorstr='${bgColors[1]}',GradientType=1 );`;
    }}
    > .title {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 4rem;
      padding-top: 1rem;
      ${(props) =>
        props && props.permissions && props.permissions.taquilla
          ? "padding-left: 10%"
          : ""};
      ${({ textColor }) =>
        textColor ? `color: ${textColor};` : `color: white;`}
      width: ${(props) =>
        props && props.permissions && props.permissions.taquilla
          ? "90%"
          : "60%"};
      text-align: center;
      > span {
        padding-left: 0.5rem;
        font-weight: bold;
      }
    }
    > .title-no-padding {
      ${({ textColor }) =>
        textColor ? `color: ${textColor};` : `color: white;`}
      ${({ setSidebarOpen }) =>
        setSidebarOpen ? "padding-right: calc(2.8vw + 60px + .25rem);" : ""}
      margin-left: auto;
      margin-right: auto;
      > span {
        font-weight: bold;
      }
    }
    > .blanc {
      width: 20%;
    }
    > .notify {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20%;
      height: 3.5rem;
      color: white;
      text-transform: uppercase;
      > .mobile-paid {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: clamp(0.4rem, 2vw, 0.9rem);
        width: 60%;
        height: 3rem;
        border: 1px solid white;
        border-radius: 0.5rem;
        background: ${({ status }) => {
          return status === "active"
            ? "rgba(100, 255, 100, 0.8)"
            : "rgba(255, 0, 0, 0.9)";
        }};
      }
      > .tickets {
        width: 60%;
        border: 2px solid white;
        border-radius: 0.5rem;
        background: ${blue_6};
        display: flex;
        align-items: center;
        font-size: clamp(0.4rem, 2vw, 0.9rem);
        width: 60%;
        height: 3.5rem;
        > .qty {
          width: 30%;
          height: 100%;
          margin: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          > p {
            margin: 0;
            margin-top: 0.5em;
            margin-bottom: -0.5em;
            font-weight: bold;
          }
          > img {
            margin: 0;
            width: 75%;
          }
        }
        > i {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 30%;
        }
        > .content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          font-size: 1em;
          height: 100%;
          > p {
            margin: 0;
          }
        }
      }
    }
    > .side {
      width: ${(props) =>
        props && props.permissions && props.permissions.taquilla
          ? "10%"
          : "20%"};
      display: flex;
      justify-content: ${(props) =>
        props && props.permissions && props.permissions.taquilla
          ? "flex-end"
          : "flex-start"};
      > button {
        width: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 2.8vw;
        ${({ textColor }) =>
          textColor ? `color: ${textColor};` : `color: white;`}
        box-shadow: none !important;
        > i {
          margin: 0;
        }
      }
      > button:hover {
        background: rgba(255, 255, 255, 0.5);
      }
    }
    @media (max-width: 740px) {
      > .title {
        font-size: 0.9rem;
      }
      > .notify {
        width: 30%;
        > .mobile-paid {
          font-size: 0.6rem;
        }
        > .tickets {
          font-size: 0.9rem;
          flex-direction: column;

          > .qty {
            > p {
              margin: 0;
              margin-top: 0.5em;
              margin-bottom: -0.5em;
              font-weight: bold;
            }
            > img {
              width: 0%;
            }
          }
          > .content {
            align-items: center;
            margin: 0;
            width: 100%;
            font-size: 0.5rem;
            > p {
              margin: 0;
              width: 100%;
            }
          }
        }
      }
    }
  }
`;

export default Navbar;
