import { CriticalNotificationsModel } from "./model";
import styled from "styled-components";
import { red, yellow, green } from "assets/strings/colors";

const CriticalNotifications = styled(CriticalNotificationsModel)`
  #root & {
    > .notification {
      color: white;
      font-size: 1.1rem;
      text-align: center;
      margin: 0;
      &.error {
        background: ${red};
      }
      &.alert {
        background: ${yellow};
      }
      &.success {
        background: ${green};
      }
      & .clickable {
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
`;
export { CriticalNotifications };
