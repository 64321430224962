import React, { useContext } from "react";
import { EntitiesList, Pagination } from "components/entities-list";
import { Grid } from "semantic-ui-react";
import PopupMessage from "components/popup-message";
import { Loader } from "components/loader";
import { MPremiumHistoryContext } from "../context";
import { HistoryForm } from "./history-form";
import { formatBundlesPhoneList } from "./bundle-list-item";
import { UserBundleDetail } from "./user-bundle-detail";
import { Panel } from "components/panel";
import { CSSTransition } from "react-transition-group";

export const MPremiumHistoryPhoneView = ({ props }) => {
  const {
    popupMessagesType,
    showPopup,
    popupMessages,
    closePopup,
    handlePageDown,
    handlePageUp,
    handleBundleSelection,
    state,
  } = useContext(MPremiumHistoryContext);

  return (
    <Grid padded>
      <PopupMessage
        messageType={popupMessagesType}
        showPopup={showPopup}
        messages={popupMessages}
        closePopup={closePopup}
      ></PopupMessage>
      {!state.selectionDone && (
        <Grid.Column phone={8} tablet={8} computer={16}>
          <HistoryForm />
          <EntitiesList
            height="calc(80vh - 7rem)"
            viewState={null}
            entities={state.bundles}
            formattingFunction={formatBundlesPhoneList}
            selectionHandler={handleBundleSelection}
            additionalArgs={[state.bundlesLoading]}
          >
            <Pagination
              padding="0 0 0.8rem 0"
              nextPageExist={state.nextPageExist}
              currentPage={state.currentPage}
              handlePageUp={handlePageUp}
              handlePageDown={handlePageDown}
            />
          </EntitiesList>
        </Grid.Column>
      )}
      {state.selectionDone && (
        <Grid.Column phone={8} tablet={8} computer={16}>
          {state.statisticsLoading && <Loader />}
          {!state.statisticsLoading && (
            <CSSTransition
              in={state.selectionDone}
              mountOnEnter
              unmountOnExit
              timeout={350}
              appear={true}
              classNames="panel"
            >
              <Panel>
                <UserBundleDetail />
              </Panel>
            </CSSTransition>
          )}
        </Grid.Column>
      )}
    </Grid>
  );
};
