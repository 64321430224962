import React, { useContext } from "react";
import { Panel, PanelTitle, PanelSectionHeader, PanelSectionContent } from "components/panel";
import { WalletTransferHistoryContext } from "../../context";
import moment from "moment";
import { formatString, formatPrice } from "services/utils";
import { Icon } from "semantic-ui-react";
import { grey_4 } from "assets/strings/colors";

export const TransferDetailModel = ({ phoneView, className }) => {
  const { state: { selectedTransfer }, changeState } = useContext(WalletTransferHistoryContext);
  if (!selectedTransfer) {
    return <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}>
      Seleccione una transacción
    </div>;
  }

  const goBack = (event) => {
    event.preventDefault();
    changeState({ showDetail: false });
  };
  const {
    created_at,
    emitter_account,
    emitter_document,
    receiver_account,
    receiver_name,
    receiver_document,
    concept,
    reference,
    amount
  } = selectedTransfer;
  const date = moment(created_at).format(formatString);
  return (
    <div className={className}>
      <Panel style={{ width: "80%" }}>
        {phoneView && <Icon
          style={{
            paddingLeft: "1rem",
            display: "flex",
            alignItem: "left",
            float: "left",
            color: grey_4,
            cursor: "pointer",
            zIndex: 1,
            marginBottom: "2rem"
          }}
          name="arrow left"
          onClick={goBack}
        />}
        <PanelTitle>Detalle de la transacción</PanelTitle>
        <PanelSectionHeader>Fecha:</PanelSectionHeader>
        <PanelSectionContent>{date}</PanelSectionContent>
        <PanelSectionHeader>Cuenta Emisora:</PanelSectionHeader>
        <PanelSectionContent>{emitter_account ? emitter_account : "N/A"}</PanelSectionContent>
        <PanelSectionHeader>Documento Emisor:</PanelSectionHeader>
        <PanelSectionContent>{emitter_document ? emitter_document : "N/A"}</PanelSectionContent>
        <PanelSectionHeader>Cuenta Receptora:</PanelSectionHeader>
        <PanelSectionContent>{receiver_account ? receiver_account : "N/A"}</PanelSectionContent>
        <PanelSectionHeader>Documento Receptor:</PanelSectionHeader>
        <PanelSectionContent>{receiver_document ? receiver_document : "N/A"}</PanelSectionContent>
        <PanelSectionHeader>Nombre del Receptor:</PanelSectionHeader>
        <PanelSectionContent>{receiver_name ? receiver_name : "N/A"}</PanelSectionContent>
        <PanelSectionHeader>Concepto:</PanelSectionHeader>
        <PanelSectionContent>{concept ? concept : "N/A"}</PanelSectionContent>
        <PanelSectionHeader>Referencia:</PanelSectionHeader>
        <PanelSectionContent>{reference ? reference : "N/A"}</PanelSectionContent>
        <PanelSectionHeader>Monto:</PanelSectionHeader>
        <PanelSectionContent>{formatPrice(amount)}</PanelSectionContent>
      </Panel>
    </div>);
};
