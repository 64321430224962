import React from "react";
import { Icon } from "semantic-ui-react";

const CardModel = ({
  iconName,
  withIcon,
  withImage,
  imgUrl,
  handle,
  description,
  isSelected,
  buttonText,
  className,
}) => {
  return (
    <div className={className}>
      <div className="identifier">
        {withIcon && <Icon name={iconName} size="large" />}
        {withImage && <img src={imgUrl} alt="ico here" />}
      </div>
      <div className="content">
        <p>{description}</p>
        <button className="option" onClick={handle} disabled={isSelected}>
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export { CardModel };
