import { UserResetConfirmModel } from "./model";
import styled from "styled-components";

export const UserResetConfirm = styled(UserResetConfirmModel)`
  #root && {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
