import React, { useContext } from "react";
import { Panel } from "components/panel";
import { Grid } from "semantic-ui-react";
import { InvoiceForm } from "./invoice-form";
import { EntitiesList, Pagination } from "components/entities-list";
import { CSSTransition } from "react-transition-group";
import PopupMessage from "components/popup-message";
import { MonthlyPremiumInvoicesContext } from "../context";
import {
  formatInvoicesList,
  formatInvoicesPhoneList,
} from "./invoice-list-item";
import { Invoice } from "./invoice";

export const MposMonthlyPremiumInvoicesView = () => {
  const {
    state,
    handleInvoiceSelection,
    removeSelectedInvoice,
    popupMessages,
    popupMessagesType,
    showPopup,
    closePopup,
    handleMarkAsPaid,
    handlePageDown,
    handlePageUp,
    viewType,
  } = useContext(MonthlyPremiumInvoicesContext);
  const phoneView = viewType === "phone";
  return (
    <Grid centered padded>
      <PopupMessage
        messageType={popupMessagesType}
        showPopup={showPopup}
        messages={popupMessages}
        closePopup={closePopup}
      />
      <Grid.Column mobile={16} tablet={14} computer={10}>
        <CSSTransition
          in={!state.selectionDone}
          mountOnEnter
          unmountOnExit
          timeout={350}
          classNames="panel"
        >
          <Panel>
            <InvoiceForm />
          </Panel>
        </CSSTransition>
        <CSSTransition
          in={!state.selectionDone}
          mountOnEnter
          unmountOnExit
          timeout={350}
          classNames="panel"
        >
          <Panel>
            {/*             <FilterInvoices /> */}
            <EntitiesList
              viewState={null}
              entities={state.invoicesToShow}
              formattingFunction={
                phoneView ? formatInvoicesPhoneList : formatInvoicesList
              }
              selectionHandler={handleInvoiceSelection}
              additionalArgs={[state.loading, state.selectedCommerce.category]}
            >
              <Pagination
                padding="0 0 0.8rem 0"
                nextPageExist={state.nextPageExist}
                currentPage={state.currentPage}
                handlePageUp={handlePageUp}
                handlePageDown={handlePageDown}
              />
            </EntitiesList>
          </Panel>
        </CSSTransition>
        <CSSTransition
          in={state.selectionDone}
          mountOnEnter
          unmountOnExit
          timeout={350}
          appear={true}
          classNames="invoice"
        >
          <Invoice
            data={state.selectedInvoice}
            category={state.selectedCommerce.category}
            removeSelectedInvoice={removeSelectedInvoice}
            markAsPaid={handleMarkAsPaid}
            printPage={false}
            showPaymentMethods={false}
          />
        </CSSTransition>
      </Grid.Column>
    </Grid>
  );
};
