import { useEffect } from "react";

export const useEnter = (functionSubmit, state) => {
  useEffect(() => {
    document.addEventListener("keydown", onEnter);
    return () => {
      document.removeEventListener("keydown", onEnter);
    };
  }, [state]);

  const onEnter = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      functionSubmit(event);
    }
  };
};
