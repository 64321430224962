import React from "react";
import {
  DirectoryEmpty,
  //DirectoryListHeader,
  //DirectoryListHeaderPhone,
  DirectoryListItem,
  //DirectoryListItemPhone,
} from "./styles";

import { Icon } from "semantic-ui-react";

export const DirectoryListItemModel = ({
  item,
  handleSelection,
  onDataDelete,
  className,
}) => {
  return (
    <ul className={className} onClick={handleSelection} id={item._id}>
      <li>{item.alias || item.name}</li>
      <li>{item.name}</li>
      <li>
        {item.doc_type}
        {item.doc_id}
      </li>
      <li>{item.phone}</li>
      <li onClick={onDataDelete} id={item._id}>
        <Icon name="trash" />
      </li>
    </ul>
  );
};

export const DirectoryListItemPhoneModel = () => {
  return <div></div>;
};

export const DirectoryListHeaderModel = ({ className }) => {
  return (
    <ul className={className}>
      <li>Alias</li>
      <li>Nombre</li>
      <li>Documento</li>
      <li>Teléfono</li>
      <li>Acciones</li>
    </ul>
  );
};

export const DirectoryListHeaderPhoneModel = () => {
  return <div></div>;
};

export const DirectoryEmptyModel = ({ className }) => {
  return <div className={className}>No se ha encontrado resultados</div>;
};

export const formattingDirectory = (
  usersDirectory,
  viewState,
  handleUserDirectorySelection,
  onDataDelete
) => {
  if (!(usersDirectory && usersDirectory.length)) return <DirectoryEmpty />;
  return usersDirectory.map((item) => {
    return (
      <DirectoryListItem
        item={item}
        key={item._id}
        handleSelection={handleUserDirectorySelection}
        onDataDelete={onDataDelete}
      />
    );
  });
};
