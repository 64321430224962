import { DigitizeFormModel } from "./model";
import styled from "styled-components";
import {
  grey_2,
  grey_4,
  bluePD,
  magenta,
  green_5,
} from "assets/strings/colors";

const DigitizeForm = styled(DigitizeFormModel)`
  #root && {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: ${(props) => {
      return props.viewType === "phone" ? "90%" : "50%";
    }};
    margin: -2rem 0;
    > .panel {
      margin: 0;
      height: 80%;
      border: 1px solid ${grey_2};
      border-radius: 0.2rem;
      background: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 80%;
      > .form {
        height: 45%;
        width: 85%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        > .title {
          margin: 0;
          width: 80%;
          height: calc(20vh-4rem);
          font-size: 1.2rem;
          text-align: left;
        }
        > .user-info {
          margin: 0;
          font-size: 1rem;
          text-align: left;
          > .username {
            margin: 0;
            font-size: 1.2rem;
            color: ${bluePD};
          }
          > p {
            margin: 0;
          }
        }
        > .data {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
          > .header {
            margin: 0;
            margin-bottom: 0.5rem;
            font-size: 0.9rem;
            color: ${grey_4};
          }
          > .normal {
            margin: 0;
            width: 100%;
            border: none;
            border-bottom: 1px solid ${grey_4};
            font-size: 1rem;
            :focus {
              outline: none;
            }
          }
          > div {
            width: 100%;
            text-align: left;
            > .react-datepicker__input-container {
              width: 100%;
              > input {
                margin: 0;
                width: 100%;
                border: none;
                border-bottom: 1px solid ${grey_4};
                font-size: 1rem;
                :focus {
                  outline: none;
                }
              }
            }
          }
        }
        > .data-hour {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
          > .header {
            margin: 0;
            margin-bottom: 0.5rem;
            font-size: 0.9rem;
            color: ${grey_4};
          }
          > .hour {
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            border-bottom: 1px solid ${grey_4};
            > input {
              margin: 0;
              width: 10%;
              border: none;
              font-size: 1rem;
              :focus {
                outline: none;
              }
            }
            > p {
              margin: 0;
              width: 5%;
              font-size: 1rem;
            }
          }
        }
        > .auto-validate {
          width: 90%;
          height: 5vh;
          min-height: 35px;
          align-self: center;
          display: flex;
          justify-content: space-between;
          padding-top: 1rem;
          > p {
            color: ${grey_4};
          }
          > i {
            color: ${grey_4};
            cursor: pointer;
          }
          > .icon-on {
            color: ${green_5};
            cursor: pointer;
          }
        }
      }
    }
    > button {
      cursor: pointer;
      font-weight: bold;
      border: none;
      color: white;
      border-radius: 3rem;
      //background: linear-gradient(to right, ${magenta}, ${bluePD});
      background: ${bluePD};
      height: 10%;
      width: 70%;
      :focus {
        outline: none;
        opacity: 0.7;
      }
    }
  }
`;

export { DigitizeForm };
