import {
  NoItemsFoundModel,
  ItemModel,
  DiscountCodesListModel,
  ItemsHeaderModel,
} from "./model";
import styled from "styled-components";

import { bluePD, grey_2, grey_4, grey_5 } from "assets/strings/colors";
export const NoItemsFound = styled(NoItemsFoundModel)`
  #root & {
    padding: 1rem;
    text-align: center;
  }
`;
export const Item = styled(ItemModel)`
  #root & {
    display: flex;
    flex-direction: column;
    padding: 1rem 0.5rem;
    border-bottom: 1px solid ${grey_5};
    border-radius: 0.5rem;
    cursor: pointer;
    :hover {
      background-color: ${grey_2};
    }
    > .name::before {
      content: "Nombre: ";
      color: ${bluePD};
      text-transform: capitalize;
      font-weight: bold;
    }
    > .name {
    }
    > .type::before {
      content: "Tipo: ";
      color: ${bluePD};
      text-transform: capitalize;9
      font-weight: bold;
    }
    > .type {
    }
    > .value::before {
      content: "Valor: ";
      color: ${bluePD};
      text-transform: capitalize;
      font-weight: bold;
    }
    > .value {
    }
    > .percentage::before {
      content: "Procentaje: ";
      color: ${bluePD};
      text-transform: capitalize;
      font-weight: bold;
    }
    > .percentage {
    }
    > .expiration::before {
      content: "Vigencia: ";
      color: ${bluePD};
      text-transform: capitalize;
      font-weight: bold;
    }
    > .expiration {
    }
    @media (min-width: 992px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      width: 100%;
      > .col {
        margin: 0;
        padding: 0;
        font-size: 0.8rem;
      }

      > .twenty {
        width: calc(20% - 1rem);
        overflow-wrap: break-word;
        text-align: center;
      }

      > .name::before {
        content: "";
      }

      > .type::before {
        content: "";
      }

      > .value::before {
        content: "";
      }
      > .percentage::before {
        content: "";
      }

      > .expiration::before {
        content: "";
      }
    }
  }
`;
export const ItemsHeader = styled(ItemsHeaderModel)`
  #root & {
    display: none;
    @media (min-width: 992px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: 0;
      padding: 1rem 0.5rem 0 0.5rem;
      border-bottom: 2px solid ${bluePD};
      > .col {
        margin: 0;
        padding: 0;
        padding-bottom: 0.3rem;
        text-transform: uppercase;
        font-weight: bold;
        color: ${grey_4};
        text-align: center;
      }

      > .twenty {
        width: calc(20% - 1rem);
      }
    }
  }
`;
export const DiscountCodesList = styled(DiscountCodesListModel)`
  #root & {
  }
`;
