import React from "react";
import { connect } from "react-redux";
import { clearReduxStore } from "redux/actions/general";
import {
  getSelectedCommerce as getSelectedCommerceFromRedux,
  getBuildings as getBuildingsFromRedux,
} from "redux/selectors";
import { useStateManager } from "hooks/use-state-manager";
import { usePopupMessages } from "hooks/use-popup-messages";
import { useNumberInput } from "hooks/use-inputs";
import { useViewType } from "hooks/use-view-type";
import { MonthlyPremiumInvoicesContext } from ".";
import { premiumBills, premiumMarkBillAsPaid } from "api";
import { MarkAsPaidForm } from "../components/mark-as-paid-form";
import { Loader } from "components/loader";

const MonthlyPremiumInvoicesState = ({
  selectedCommerce,
  buildingList,
  history,
  location,
  children,
}) => {
  const initialState = {
    searchType: "latests",
    from_date: null,
    end_date: null,
    limit: 25,
    skip: 0,
    pending: true,
    paid: true,
    invoices: [],
    invoicesToShow: [],
    status: [],
    selectedInvoice: null,
    commerce_id: selectedCommerce._id,
    nextPageExist: false,
    currentPage: 1,
    get_invoices: true,
    loading: false,
    selectionDone: false,
    isMarkAsPaid: false,
    selectedCommerce,
    textFilterValue: "",
    textFilterBy:
      selectedCommerce.category !== "pagodirecto" ? "" : "commerce_to_pay",
    textFilterByOptions: [
      { data: "commerce_to_pay", displayData: "Cobra" },
      { data: "commerce", displayData: "Debe" },
    ],
    buildingsOptions: buildingList.map((building) => {
      return {
        key: building._id,
        text: building.commercial_name,
        value: building._id,
      };
    }),
    selectedBuilding: null,
  };
  const {
    bind: { value: physical_bill_ref, onChange: onChangePhyBill },
  } = useNumberInput("");
  const {
    bind: { value: pay_ref, onChange: onChangePayRef },
  } = useNumberInput("");
  const [viewType, setViewType] = useViewType(null);
  const [state, changeState] = useStateManager(initialState);
  const {
    popupMessages,
    popupMessagesType,
    showPopup,
    throwPopupMessage,
    closePopup,
  } = usePopupMessages(null, "", false);

  const invoicesPerPage = 10;

  const localInvoicesFilter = (invoices, field, textFilter) =>
    invoices.filter((invoice) => {
      if (!field) return invoice;

      return invoice[field].name.toLowerCase().includes(textFilter);
    });
  const getInvoices = async () => {
    changeState({ loading: true });
    try {
      let {
        from_date: fromDate,
        end_date: endDate,
        limit,
        skip,
        pending,
        paid,
        commerce_id,
        selectedBuilding: building_id,
        textFilterBy: filter_by,
      } = state;
      const status = [];
      pending && status.push("pending");
      paid && status.push("paid");
      const from_date = fromDate ? fromDate.toISOString() : fromDate;
      const end_date = endDate ? endDate.toISOString() : endDate;

      const response = await premiumBills({
        from_date,
        end_date,
        limit,
        skip,
        status,
        commerce_id,
        building_id,
        filter_by,
      });
      const res = await response.json();
      if (res.error) throw res.error;
      if (res.bills) {
        let nextPageExist;
        if (res.bills.length > invoicesPerPage) {
          nextPageExist = true;
          res.bills.pop();
        } else {
          nextPageExist = false;
        }
        changeState({
          invoices: [...res.bills],
          invoicesToShow: localInvoicesFilter(
            [...res.bills],
            state.textFilterBy,
            state.textFilterValue
          ),
          nextPageExist,
          loading: false,
        });
      }
    } catch (error) {
      changeState({ loading: false });
      const errorMessage = error.description
        ? error.description
        : "Ha ocurrido un error, intente más tarde.";
      throwPopupMessage("error", errorMessage);
    }
  };

  const markInvoiceAsPaid = async () => {
    try {
      throwPopupMessage("", <Loader />);
      const response = await premiumMarkBillAsPaid({
        bill_id: state.selectedInvoice._id,
        physical_bill_ref,
        pay_ref,
        pay_type: "wire_transfer",
      });
      const res = await response.json();
      if (res.error) throw res.error;
      throwPopupMessage(
        "success",
        res.message ? res.message : "Recibo marcado como pagado exitosamente"
      );
    } catch (error) {
      throwPopupMessage(
        "error",
        error.description
          ? error.description
          : "Ha ocurrido un error, intente más tarde."
      );
    }
    changeState({
      selectedInvioce: null,
      selectionDone: false,
      get_invoices: true,
    });
    history.replace({
      pathname: location.pathname,
      search: "",
    });
  };

  const handleMarkAsPaid = (event) => {
    event.preventDefault();
    throwPopupMessage("", <MarkAsPaidForm />);
  };
  const handleMarkAsPaidConfirmation = (event) => {
    event.preventDefault();
    changeState({ isMarkAsPaid: true });
  };

  const handleInvoiceSelection = (event) => {
    event.preventDefault();
    let index = event.currentTarget.id;
    changeState({
      selectionDone: false,
    });
    setTimeout(() => {
      changeState({
        selectedInvoice: state.invoices[index],
        selectionDone: true,
      });
      history.replace({
        pathname: location.pathname,
        search: `?invoice=${state.invoices[index]._id}`,
      });
    }, 350);
  };

  // 6)
  const handlePageDown = (event) => {
    event.preventDefault();
    // format request data
    let skip = (state.currentPage - 2) * invoicesPerPage;
    let limit = invoicesPerPage + 1;
    // Set loading state
    changeState({
      currentPage: state.currentPage - 1,
      nextPageExist: true,
      skip,
      limit,
      get_invoices: true,
    });
  };

  // 7)
  const handlePageUp = (event) => {
    event.preventDefault();
    // format request data
    let limit = invoicesPerPage + 1;
    let skip = state.currentPage * invoicesPerPage;
    // Set loading state
    changeState({
      currentPage: state.currentPage + 1,
      nextPageExist: true,
      skip,
      limit,
      get_invoices: true,
    });
  };

  const onEnter = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      event.preventDefault();
      changeState({ get_invoices: true });
    }
  };

  const removeSelectedInvoice = (event) => {
    event.preventDefault();
    changeState({
      selectedInvioce: null,
      selectionDone: false,
    });
    history.replace({
      pathname: location.pathname,
      search: "",
    });
  };

  return (
    <MonthlyPremiumInvoicesContext.Provider
      value={{
        state,
        changeState,
        getInvoices,
        handleInvoiceSelection,
        markInvoiceAsPaid,
        handleMarkAsPaid,
        removeSelectedInvoice,
        popupMessages,
        popupMessagesType,
        showPopup,
        throwPopupMessage,
        closePopup,
        handlePageDown,
        handlePageUp,
        viewType,
        setViewType,
        onEnter,
        history,
        handleMarkAsPaidConfirmation,
        physical_bill_ref,
        onChangePhyBill,
        pay_ref,
        onChangePayRef,
        localInvoicesFilter,
      }}
    >
      {children}
    </MonthlyPremiumInvoicesContext.Provider>
  );
};

const mapStateToProps = (state) => {
  const selectedCommerce = getSelectedCommerceFromRedux(state);
  const buildingList = getBuildingsFromRedux(state);
  return { selectedCommerce, buildingList };
};

const mapDispatchToProps = {
  clearReduxStore,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MonthlyPremiumInvoicesState);
