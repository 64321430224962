import React, { PureComponent } from "react";
import { Segment, Dropdown } from "semantic-ui-react";
//import { ChromePicker } from "react-color";
import { commerceCategories /*, billingTypes*/ } from "assets/strings/texts";

export const SignupFormInputsModel = (props) => {
  const options = [
    { key: 0, text: "V.", value: "V" },
    { key: 1, text: "E.", value: "E" },
  ];
  return (
    <Segment.Group className={props.className}>
      <div className="ui transparent input segment">
        <input
          name="email"
          type="email"
          autoComplete="email"
          placeholder="Dirección de correo electrónico"
          value={props.values.email}
          onChange={props.handleLoginChange}
        />
      </div>
      <div className="ui transparent input segment">
        <input
          name="username"
          type="text"
          autoComplete="username"
          maxLength={14}
          pattern="^[a-z0-9][a-z0-9\._-]{2,14}$"
          title="Su nombre de usuario solo puede contener letras minúsculas, numeros, puntos, guiones y debe comenzar con un valor alfanumérico"
          placeholder="Nombre de usuario"
          value={props.values.username}
          onChange={props.handleUsernameChange}
        />
      </div>

      <div className="ui transparent left labeled input segment">
        <Dropdown
          className="label"
          name="doc_type"
          value={props.values.doc_type}
          options={options}
          onChange={props.handleDropdown}
        />
        <input
          name="doc_id"
          type="text"
          maxLength={8}
          autoComplete="off"
          placeholder="Ingrese su C.I."
          value={props.values.doc_id}
          onChange={props.handleNumberChange}
        />
      </div>

      <div className={"ui transparent input segment " + props.passwordStrength}>
        <input
          name="password"
          type="password"
          pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d$@!%*#?&.=*]{8,}$"
          autoComplete="new-password"
          placeholder="Contraseña"
          value={props.values.password}
          onChange={props.handleNewPasswordChange}
        />
      </div>
    </Segment.Group>
  );
};

export const CommercesSignupFirstFormInputsModel = (props) => {
  return (
    <Segment.Group className={props.className}>
      <div className="ui transparent labeled input segment">
        <input
          style={{ textAlign: "left", width: "40%" }}
          name="pre_username"
          type="text"
          maxLength={3}
          placeholder="Prefijo del Comercio"
          /* autoComplete="pre_username" */
          autoComplete="off"
          value={props.values.pre_username}
          onChange={props.handleChange}
        />
        <div className="separator">
          <p>-</p>
        </div>
        <input
          style={{ textAlign: "center", width: "40%" }}
          name="username"
          type="text"
          /* autoComplete="username" */
          autoComplete="off"
          maxLength={14}
          minLength={6}
          pattern="^[a-z0-9][a-z0-9\]{2,14}$"
          title="Su nombre de usuario solo puede contener letras minúsculas, numeros, puntos, guiones y debe comenzar con un valor alfanumérico"
          placeholder="Nombre de usuario"
          value={props.values.username}
          onChange={props.handleUsernameChange}
        />
      </div>
      <div className="ui transparent labeled input segment">
        <input
          name="short_name"
          type="text"
          /* autoComplete="short_name" */
          autoComplete="off"
          maxLength={20}
          pattern="^[a-z0-9][a-z0-9\._-]{2,14}$"
          required
          title="Su nombre de corto solo puede contener letras minúsculas, numeros, puntos, guiones y debe comenzar con un valor alfanumérico"
          placeholder="Pseudónimo"
          value={props.values.short_name}
          onChange={props.handleShortNameChange}
        />
      </div>
      <div className="ui transparent input segment">
        <input
          name="email"
          type="email"
          /* autoComplete="email" */
          required
          autoComplete="off"
          placeholder="Dirección de correo electrónico"
          value={props.values.email}
          title={"El correo electrónico debe contener @"}
          onChange={props.handleLoginChange}
        />
      </div>

      <div className="ui transparent input segment">
        <input
          name="phone"
          type="phone"
          /* autoComplete="phone" */
          autoComplete="off"
          required
          title="Debe cumplir con los parámetros del número telefónico"
          minLength="11"
          maxLength="11"
          placeholder="Teléfono de contacto"
          value={props.values.phone}
          onChange={props.handlePhoneChange}
        />
      </div>

      <div className={"ui transparent input segment " + props.passwordStrength}>
        <input
          name="password"
          type="password"
          pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d$@!%*#?&.=*]{8,}$"
          autoComplete="new-password"
          placeholder="Contraseña"
          required
          title={"Recuerda cumplir con los parametros de la contraseña"}
          value={props.values.password}
          onChange={props.handleNewPasswordChange}
        />
      </div>

      <div className={"ui transparent input segment "}>
        <input
          name="confirm_password"
          type="password"
          required
          pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d$@!%*#?&.=*]{8,}$"
          /* autoComplete="confirm-password" */
          title={"Recuerda cumplir con los parametros de la contraseña"}
          autoComplete="off"
          placeholder="Confirmar Contraseña"
          value={props.values.confirm_password}
          onChange={props.handleNewPasswordChange}
        />
      </div>
    </Segment.Group>
  );
};
export const CommercesSignupSecondFormInputsModel = (props) => {
  const categories = commerceCategories.map((category, index) => {
    let prettyCateg = category;
    if (category === "none") prettyCateg = "Seleccione una Categoría";
    return { key: "categ" + index, text: prettyCateg, value: category };
  });

  const options = [
    { key: 0, text: "V.", value: "V" },
    { key: 1, text: "E.", value: "E" },
    { key: 2, text: "J.", value: "J" },
    { key: 3, text: "P.", value: "P" },
  ];
  return (
    <Segment.Group className={props.className}>
      <div className="ui transparent input segment">
        <input
          name="name"
          type="text"
          required
          placeholder="Razón Social"
          /* autoComplete="name" */
          autoComplete="off"
          value={props.values.name}
          onChange={props.handleChange}
        />
      </div>
      <div className="ui transparent left labeled input segment">
        <Dropdown
          className="label"
          name="doc_type"
          value={props.values.doc_type}
          options={options}
          onChange={props.handleDropdown}
        />
        <input
          name="doc_id"
          type="text"
          maxLength={9}
          autoComplete="off"
          required
          placeholder="Ingrese su Número de identificación"
          value={props.values.doc_id}
          onChange={props.handleNumberChange}
        />
      </div>
      <div className="ui transparent input segment">
        <input
          name="address"
          type="address"
          /* autoComplete="street-address */
          autoComplete="off"
          required
          placeholder="Dirección fiscal, según RIF"
          value={props.values.address}
          onChange={props.handleChange}
        />
      </div>
      <div className="ui transparent input segment">
        <Dropdown
          search
          fluid
          className="new-label"
          name="category"
          value={props.values.category}
          options={categories}
          onChange={props.handleDropdown}
        />
      </div>
    </Segment.Group>
  );
};

export const SigninFormInputsModel = (props) => {
  return (
    <Segment.Group className={props.className}>
      <div className="ui transparent input segment">
        <input
          name="login"
          type="text"
          placeholder="Correo electronico o username"
          value={props.values.login}
          onChange={props.handleLoginChange}
        />
      </div>

      <div className="ui transparent input segment">
        <input
          name="password"
          type="password"
          placeholder="Contraseña"
          value={props.values.password}
          onChange={props.handlePasswordChange}
        />
      </div>
    </Segment.Group>
  );
};

export const OwnerInfoFormInputsModel = (props) => {
  return (
    <Segment.Group className={props.className}>
      <div className="ui transparent input segment">
        <input
          name="name"
          type="text"
          required
          placeholder="Primer Nombre"
          autoComplete="name"
          value={props.values.name}
          onChange={props.handleChange}
        />
      </div>

      <div className="ui transparent input segment">
        <input
          name="last_name"
          type="text"
          required
          placeholder="Primer Apellido"
          autoComplete="family-name"
          value={props.values.last_name}
          onChange={props.handleChange}
        />
      </div>
      <div className="ui transparent input segment">
        <input
          name="phone"
          type="tel"
          pattern="[0-9]{11}"
          title="ej: 04141234567"
          required
          maxLength={11}
          placeholder="Telefono de Contacto, ej: 04149871234"
          autoComplete="tel-national"
          value={props.values.phone}
          onChange={props.handleNumberChange}
        />
      </div>
    </Segment.Group>
  );
};

export class CommerceInfoFormInputsPart1Model extends PureComponent {
  render() {
    const rif = [{ key: 0, text: "J.", value: "J" }];

    return (
      <Segment.Group className={this.props.className}>
        <div className="ui transparent input segment">
          <input
            name="name"
            type="text"
            title="Nombre o Razón Social de su comercio"
            autoComplete="organization"
            placeholder="Razón Social"
            value={this.props.name}
            onChange={this.props.handleChange}
          />
        </div>

        <div className="ui transparent left labeled input segment">
          <Dropdown
            className="label"
            name="doc_type"
            value={this.props.doc_type}
            options={rif}
            onChange={this.props.handleDropdown}
          />
          <input
            name="doc_id"
            type="text"
            maxLength={9}
            placeholder="Ingrese su R.I.F."
            value={this.props.doc_id}
            onChange={this.props.handleNumberChange}
          />
        </div>

        <div className="ui transparent input segment">
          <input
            name="address"
            type="address"
            autoComplete="street-address"
            placeholder="Dirección fiscal, según RIF"
            value={this.props.address}
            onChange={this.props.handleChange}
          />
        </div>
      </Segment.Group>
    );
  }
}

export class CommerceInfoFormInputsPart2Model extends PureComponent {
  render() {
    return (
      <Segment.Group className={this.props.className}>
        <div className="ui transparent input segment">
          <input
            name="phone"
            type="tel"
            pattern="[0-9]{11}"
            title="ej: 04141234567"
            required
            maxLength={11}
            placeholder="Telefono, ej: 02127654321"
            autoComplete="tel-national"
            value={this.props.phone}
            onChange={this.props.handleNumberChange}
          />
        </div>

        <div className="ui transparent input segment">
          <input
            name="email"
            type="email"
            autoComplete="email"
            placeholder="Dirección de correo electrónico"
            value={this.props.email}
            onChange={this.props.handleChange}
          />
        </div>
      </Segment.Group>
    );
  }
}

export class CommerceInfoFormInputsPart3Model extends PureComponent {
  render() {
    let buildings = [];
    if (this.props.buildings) {
      buildings = this.props.buildings.map((building, index) => {
        return {
          key: "building" + index,
          text: building.commercial_name,
          value: building._id,
        };
      });
    }

    return (
      <Segment.Group className={this.props.className}>
        {this.props.parkingDesired && (
          <div className="ui transparent input segment">
            <Dropdown
              search
              fluid
              className="single-dropdown"
              placeholder="Edificio en el que se localiza"
              name="building"
              searchInput={{ autoComplete: "off" }}
              value={this.props.building}
              options={buildings}
              onChange={this.props.handleBuildingChange}
            />
          </div>
        )}
        {/*       <div className="ui transparent input segment">
          <Dropdown
            search
            fluid
            className="single-dropdown"
            placeholder="Rubro Comercial"
            name="category"
            searchInput={{ autoComplete: "off" }}
            value={this.props.category}
            options={categories}
            onChange={this.props.handleDropdown}
          />
        </div>
        */}
        {this.props.parkingDesired && (
          <div className="ui transparent input segment">
            <input
              name="local_number"
              type="text"
              title="ej: PB Local-024"
              required
              maxLength={30}
              placeholder="PB Local-024"
              autoComplete="dir-national"
              value={this.props.localNumber}
              onChange={this.props.handleChange}
            />
          </div>
        )}
        <div className="ui transparent input segment">
          <input
            name="custom_name"
            type="text"
            title="¿Con qué nombre te conocen?"
            required
            maxLength={50}
            placeholder="¿Con qué nombre te conocen?"
            autoComplete="dir-name"
            value={this.props.customName}
            onChange={this.props.handleChange}
          />
        </div>
        {/*    <div className="ui transparent colorpicker segment">
          <p className="label">Seleccione el color principal de su comercio</p>
          <ChromePicker
            disableAlpha
            className="primary color"
            onChange={this.props.handlePrimaryColorChange}
            color={this.props.colors[0]}
          />

          <p className="label">Seleccione el color secundario de su comercio</p>
          <ChromePicker
            disableAlpha
            className="secondary color"
            onChange={this.props.handleSecondaryColorChange}
            color={this.props.colors[1]}
          />
        </div> */}
      </Segment.Group>
    );
  }
}

export const EmailInputModel = (props) => {
  return (
    <Segment.Group className={props.className}>
      <div className="ui transparent input segment">
        <input
          name="email"
          type="text"
          autoComplete="email"
          placeholder="Correo electrónico"
          value={props.values.email}
          onChange={props.onChange}
        />
      </div>
    </Segment.Group>
  );
};

export const CodeInputModel = (props) => {
  return (
    <Segment.Group className={props.className}>
      <div className="ui transparent input segment">
        <input
          name="code"
          type="text"
          autoComplete="off"
          placeholder="Código de Verificación"
          value={props.values.code}
          onChange={props.onChange}
        />
      </div>
    </Segment.Group>
  );
};

export const ResetPasswordInputsModel = (props) => {
  return (
    <Segment.Group className={props.className}>
      <div className={"ui transparent input segment"}>
        <input
          name="password"
          type="password"
          pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d$@!%*#?&.=*]{8,}$"
          autoComplete="new-password"
          placeholder="Contraseña"
          value={props.values.password}
          onChange={props.onPasswordChange}
        />
      </div>
      <div
        className={
          "ui transparent input segment " +
          props.passwordStrength +
          " " +
          props.confirmationMatchClassName
        }
      >
        <input
          name="confirm"
          type="password"
          pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d$@!%*#?&.=*]{8,}$"
          autoComplete="new-password"
          placeholder="Confirme su contraseña"
          value={props.values.confirm}
          onChange={props.onChange}
        />
      </div>
    </Segment.Group>
  );
};
