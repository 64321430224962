import { useState } from "react";
import moment from "moment";
import { inputPrice, priceToRequest, inputHour } from "services/utils";

const useTextInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    setValue,
    reset: () => setValue(""),
    bind: {
      value,
      onChange: (event) => {
        event.preventDefault();
        setValue(event.target.value);
      },
    },
  };
};

const useLowercaseInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    setValue,
    reset: () => setValue(""),
    bind: {
      value,
      onChange: (event) => {
        let value = event.target.value;
        // The field is restrcted to accept only letters, numbers and _-.
        if (value.match(/^[\w.-]*$/)) {
          value = value.toLowerCase();
          setValue(value);
        }
      },
    },
  };
};
const useNumberInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    setValue,
    reset: () => setValue(""),
    bind: {
      value,
      onChange: (event) => {
        event.preventDefault();
        let value = event.target.value.replace(/[^0-9\s]/g, "");
        setValue(value);
      },
    },
  };
};
const usePhoneInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    setValue,
    reset: () => setValue(""),
    bind: {
      value,
      onChange: (event) => {
        event.preventDefault();
        let phone = event.target.value.replace(/[^0-9\s]/g, "");
        phone = phone.replace(" ", "");
        setValue(phone);
      },
    },
  };
};
const useHourInput = (initialValue) => {
  const [hour, setHour] = useState(initialValue);
  return {
    hour,
    handle: (event) => {
      let inputHourValue = event.target.value.replace(/[^0-9\s]/g, "");
      let formattingHour = inputHour(inputHourValue.replace(" ", ""));
      setHour(formattingHour);
    },
    handleFocus: (event) => {
      event.currentTarget.setSelectionRange(
        event.currentTarget.value.length,
        event.currentTarget.value.length
      );
    },
  };
};

const useTimeInput = ({ initialHour, initialMinute }) => {
  const [hour, setHour] = useState(initialHour);
  const [minute, setMinute] = useState(initialMinute);
  const [time, setTime] = useState("");
  return {
    hour,
    minute,
    time,
    handleHour: (event) => {
      let inputHour = event.target.value.replace(/[^0-9\s]/g, "");
      inputHour =
        parseInt(inputHour) > 23
          ? "23"
          : parseInt(inputHour) < 0
          ? "0"
          : inputHour;
      setHour(inputHour);
    },
    handleMinute: (event) => {
      let inputMin = event.target.value.replace(/[^0-9\s]/g, "");
      inputMin =
        parseInt(inputMin) > 59
          ? "59"
          : parseInt(inputMin) < 0
          ? "0"
          : inputMin;
      setMinute(inputMin);
    },
    getInputTime: () => {
      let newTime = `${hour}:${minute}`;
      setTime(newTime);
    },
    setMinute,
    setHour,
  };
};

const useAmountInput = (initialValue) => {
  const [amount, setAmount] = useState(initialValue);
  return {
    amount,
    handle: (event) => {
      let inputAmount = event.target.value.replace(/[^0-9\s]/g, "");
      let formattingAmount = inputPrice(inputAmount.replace(" ", ""));
      setAmount(formattingAmount);
    },
    amountNumber: priceToRequest(amount),
    reset: () => setAmount(""),
  };
};

const useDates = (initialFromDate, initialEndDate) => {
  const [fromDate, setFromDate] = useState(initialFromDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  return {
    fromDate,
    endDate,
    handleFromDate: (date) => {
      setFromDate(date);
    },
    handleEndDate: (date) => {
      if (date) {
        // If the date exists add 23 hours
        setEndDate(moment(date).endOf("day"));
      } else {
        // If the date is null, save it like that
        setEndDate(date);
      }
    },
  };
};

const useSingleDate = (initialDate) => {
  const [date, setDate] = useState(initialDate);
  return {
    date,
    setDate,
    handleDate: (date) => {
      setDate(date);
    },
  };
};

const useNoSpecialCharactersTextInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  return {
    value,
    setValue,
    reset: () => setValue(""),
    bind: {
      value,
      onChange: (event) => {
        let noSpecialCharacters = event.target.value.replace(
          /[^A-Za-z0-9\s]/g,
          ""
        );
        setValue(noSpecialCharacters);
      },
    },
  };
};

export {
  useTextInput,
  useNumberInput,
  usePhoneInput,
  useAmountInput,
  useHourInput,
  useTimeInput,
  useSingleDate,
  useDates,
  useLowercaseInput,
  useNoSpecialCharactersTextInput,
};
