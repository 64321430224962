export const getCommerces = state => {
  return state.commerces.all
}

export const getCommerceById = (state, id) => {
  let commerce = null
  getCommerces(state).forEach(currentCommerce => {
    if(currentCommerce._id === id){
      commerce = currentCommerce
    }
  });
  return commerce
}

export const getSelectedCommerce = state => {
  return getCommerceById(state, state.commerces.selected)
}