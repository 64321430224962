import React, { Component } from "react";
import { Dropdown, Image } from "semantic-ui-react";
import styled from "styled-components";
import vzlaAvatar from "assets/img/vzla-avatar.png";

// let countryOptions = [
//   { key: 'ar', value: 'AR', flag: 'ar', text: 'Argentina' },
//   { key: 'bo', value: 'BO', flag: 'bo', text: 'Bolivia' },
//   { key: 'br', value: 'BR', flag: 'br', text: 'Brasil' },
//   { key: 'co', value: 'CO', flag: 'co', text: 'Colombia' },
//   { key: 'cl', value: 'CL', flag: 'cl', text: 'Chile' },
//   { key: 'pe', value: 'PE', flag: 'pe', text: 'Peru' },
//   { key: 've', value: 'VE', text: <span><Image src='vzla-avatar.png' small avatar /> Venezuela </span> },
// ]

class FormDropdown extends Component {
  render() {
    return (
      <Dropdown
        fluid
        disabled
        floating
        selection
        upward
        className={this.props.className}
        icon={null}
        options={[]}
        placeholder="Selecione su país"
        text={
          <span>
            <Image src={vzlaAvatar} avatar />
            {"Venezuela"}
          </span>
        }
      />
    );
  }
}

FormDropdown = styled(FormDropdown)`
  #root & {
    display: flex;
    align-items: center;
    font-size: 1rem;
    height: 8.15vh;
    border-radius: 1rem !important;
    min-height: 48px;
    margin: ${(props) => props.margins};
    position: relative;
    opacity: 0.85;
    > .default {
      color: black;
    }
    & > .menu {
      border-radius: 1rem !important;
    }

    &.upward > .menu {
      bottom: 130%;
    }
  }
`;

export default FormDropdown;
