import React from "react";
import { Button } from "components/buttons";

const AssignToModel = ({
  selectedTransfer,
  onUsernameChange,
  onApprove,
  className,
}) => {
  const onInvalid = (event) => {
    event.target.setCustomValidity(
      "Por favor, escriba un nombre de usuario válido"
    );
  };
  const placeholder = selectedTransfer.possible_username
    ? `Usuario posible ${selectedTransfer.possible_username}`
    : "Escriba un nombre de Usuario";
  return (
    <form className={className} onSubmit={onApprove}>
      <label>Usuario:</label>
      <input
        placeholder={placeholder}
        onChange={onUsernameChange}
        onInvalid={onInvalid}
        required
      />
      <Button color="blue">Aprobar</Button>
    </form>
  );
};

export default AssignToModel;
