import React from 'react'
import { Link } from 'react-router-dom'
import { Grid, Icon } from 'semantic-ui-react'
import Navbar from 'components/navbar'
import GreatingsText from 'components/greatings-text'


const UnvalidateOwnerScreen = () => {
  return(
    <div>
      <Navbar 
        title={<span>Estamos Validando su cuenta de <b>Pago</b>Directo</span>}/>
      <Grid centered padded>
        <Grid.Column mobile={15} tablet={8} computer={6}>
          <GreatingsText>
            <h4>Su usuario ha sido registrado satisfactoriamente</h4>
            <p>Estamos validado su información, pronto podrá hacer uso de nuestro servicio</p>
          </GreatingsText>
          <Icon name= 'id card outline' size='massive' style={{
            display: 'block',
            margin: '0 auto 0 auto',
            color: '#625FBF'
          }}/>
          <GreatingsText>
            <p>¿Desea volver al <Link to='/'>Inicio</Link>?</p>
          </GreatingsText>
        </Grid.Column>
      </Grid>
  </div>
  )
}

export default UnvalidateOwnerScreen