import React from 'react'

/**
 * @param options: should be an array of objects with the 
 * following sructure: 
 *  {
 *    data: String, data to compare
 *    displayData: String or React component to display
 *  }
 */
const SwitchSliderModel = ({
  selected,
  options, 
  className,
  handleSelection
}) => {

  let optionElemnts = []
  let optionSelected = ''
  let selectedClass
  options.forEach((option, index) => {
    if(option.data === selected){
      optionSelected = 'o' + index
      selectedClass = 'selected'
      
    } else {
      selectedClass = ''
    }
    optionElemnts.push(
      <div 
        id={option.data}
        className={'option ' + selectedClass}
        key={'sliderOption-'+index}
        onClick={handleSelection}>
          {option.displayData}
      </div>
    )
  });
  
  return(
    <div className={className}>
      <div className={'selected-cover '+optionSelected}>___</div>
      {optionElemnts}
    </div>
  )
}
export { SwitchSliderModel }