import React, { useEffect } from "react";
import { useViewType } from "hooks/use-view-type";
import { usePopupMessages } from "hooks/use-popup-messages";
import { useStateManager } from "hooks/use-state-manager";
import { connect } from "react-redux";
import { clearReduxStore } from "redux/actions/general";
import {
  getSelectedCommerce,
  getBuildings as getBuildingsRedux,
  getSelectedBuilding,
} from "redux/selectors";
import { usersAutocomplete, getBuildings } from "services/api";
import { errors } from "assets/strings/texts";
import { UsersLogsContext } from ".";

const LogsState = ({
  location,
  buildingsSaved,
  selectedCommerce,
  selectedBuilding,
  children,
}) => {
  const categoryReceived =
    selectedCommerce && selectedCommerce.category
      ? selectedCommerce.category
      : null;
  const initialState = {
    searchParam: "",
    users: [],
    mtds: [],
    category: categoryReceived,
    selectedUser: null,
    loading: false,
    viewState: "user_search",
    isRemoteLocation: false,
    searchType: categoryReceived === "pagodirecto" ? "by_user" : "by_building",
    selectedBuilding: null,
    buildingsOptions: [
      ...[
        categoryReceived === "pagodirecto" && {
          key: "all",
          text: "Todos",
          value: "",
        },
      ],
    ],
    selectedMtd: null,
    submitUser: false,
  };

  const [state, changeState] = useStateManager(initialState);
  const [viewType, setViewType] = useViewType(null);

  const {
    popupMessages,
    popupMessagesType,
    showPopup,
    throwPopupMessage,
    closePopup,
  } = usePopupMessages(null, "", false);

  useEffect(() => {
    setViewType();
    let buildingsOptions;
    if (state.category === "pagodirecto") {
      buildingsOptions = buildingsSaved.map((building) => {
        return {
          key: building._id,
          text: building.commercial_name,
          value: building._id,
        };
      });
    } else {
      buildingsOptions = [
        {
          key: selectedBuilding._id,
          text: selectedBuilding.commercial_name,
          value: selectedBuilding._id,
        },
      ];
    }

    changeState({ buildingsOptions });
    if (location.state && location.state.username) {
      changeState({
        searchParam: location.state.username,
        isRemoteLocation: true,
      });
    }

    window.addEventListener("resize", setViewType);
    document.addEventListener("keydown", onEnter);
    return () => {
      window.removeEventListener("resize", setViewType);
      document.removeEventListener("keydown", onEnter);
    };
  }, []);

  useEffect(() => {
    const searchUsers = async () => {
      try {
        let { loading, users, isRemoteLocation, selectedUser } = state;
        changeState({ loading: true });
        const response = await usersAutocomplete(state.searchParam);
        const res = await response.json();
        if (res.error) throw res.error;
        if (res.users) users = res.users;
        if (isRemoteLocation) {
          let locationUser = res.users.filter(
            (user) => user.username === location.state.username
          );
          selectedUser = locationUser;
        }
        loading = false;
        changeState({
          loading,
          users,
          isRemoteLocation,
          selectedUser,
        });
      } catch (error) {
        changeState({
          loading: false,
        });
        if (
          error.id === "NO_TOKEN_PROVIDED" ||
          error.id === "AUTHENTICATE_FAILED" ||
          error.id === "DUPLICATE_SESSION"
        ) {
          throwPopupMessage("alert", errors.AUTHENTICATION);
        } else {
          throwPopupMessage("error", error.description);
        }
      }
    };
    if (state.submitUser) {
      searchUsers();
      changeState({ submitUser: false });
    }
    return () => {};
  }, [state.submitUser]);

  useEffect(() => {
    const buildingReq = async () => {
      try {
        changeState({ loading: true });
        const response = await getBuildings(state.selectedBuilding);
        const res = await response.json();
        let building = res.building;
        let mtds = building.accesses ? building.accesses : [];
        changeState({ mtds, loading: false });
      } catch (error) {
        throwPopupMessage("error", error.description);
      }
    };
    if (state.searchAccesses) {
      buildingReq();
      changeState({ searchAccesses: false });
    }
  }, [state.searchAccesses]);

  const handleSearchType = (event) => {
    event.preventDefault();
    let searchType = event.currentTarget.id;
    changeState({
      ...initialState,
      searchType,
      buildingsOptions: state.buildingsOptions,
    });
  };
  const handleSearchInputChange = (event) => {
    let value = event.target.value;
    // The field is restrcted to accept only letters, numbers, @(first position) and _-.
    if (value.match(/^(?=@?.*)@?[\w.-]*$/)) {
      value = value.toLowerCase();
      // call the API in a debounced way
      changeState({
        searchParam: value,
      });
    }
  };

  const handleSearchUser = (event) => {
    event.preventDefault();
    changeState({ submitUser: true });
  };

  const handleBuildingChange = (event, data) => {
    event.preventDefault();
    let selectedBuilding = data.value;
    changeState({ selectedBuilding, searchAccesses: true });
  };

  const handleUserSelection = (event) => {
    let index = event.currentTarget.id;
    let selectedUser = state.users[index].username;

    changeState({
      viewState: "user_selection_done",
      selectedUser,
      selectedMtd: null,
    });
  };

  const handleMtdSelection = (event) => {
    let index = event.currentTarget.id;
    let selectedMtd = state.mtds[index].mac;
    changeState({
      viewState: "user_selection_done",
      selectedMtd,
      selectedUser: null,
    });
  };

  //Listener function
  const onEnter = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      event.preventDefault();
      changeState({ submitUser: true });
    }
  };

  const handleBack = (event) => {
    event.preventDefault();
    changeState({
      viewState: "user_search",
    });
  };

  return (
    <UsersLogsContext.Provider
      value={{
        location,
        state,
        changeState,
        onEnter,
        handleBack,
        handleMtdSelection,
        handleBuildingChange,
        handleUserSelection,
        handleSearchInputChange,
        handleSearchType,
        handleSearchUser,
        viewType,
        popupMessages,
        popupMessagesType,
        showPopup,
        throwPopupMessage,
        closePopup,
      }}
    >
      {children}
    </UsersLogsContext.Provider>
  );
};

const mapStateToProps = (state) => {
  let selectedCommerce = getSelectedCommerce(state);
  let selectedBuilding = getSelectedBuilding(state);
  let buildingsSaved = getBuildingsRedux(state);
  return { selectedCommerce, buildingsSaved, selectedBuilding };
};

const mapDispatchToProps = {
  clearReduxStore,
};

export default connect(mapStateToProps, mapDispatchToProps)(LogsState);
