import {
  //baseURL,
  baseURL_V2,
} from "services/config";

import { post } from "services/http-methods";

const endpoints = {
  getOne: `${baseURL_V2}/superadmin/events/ticket`,
  unvalidate: `${baseURL_V2}/superadmin/events/tickets/unvalidate`,
};

export function searchTicket(searchParam) {
  return post(endpoints.getOne, { event_ticket_ref: searchParam });
}
export function unvalidateTicket(event_ticket_ref) {
  return post(endpoints.unvalidate, { event_ticket_ref });
}
