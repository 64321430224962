import React from "react";
import { CSSTransition } from "react-transition-group";
import { Dropdown } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";

const FiltersModel = ({
  endDate,
  fromDate,
  item,
  transition,
  buildingName,
  filterTime,
  phoneView,
  buildingsOptions,
  handleBuildingChange,
  handlePeriodChange,
  handleFromDate,
  handleEndDate,
  handleSubmit,
  className,
}) => {
  const options = [
    { key: 1, text: "Diarias", value: "daily" },
    { key: 2, text: "Semanales", value: "weekly" },
    { key: 3, text: "Mensuales", value: "monthly" },
    { key: 4, text: "Rango de fechas", value: "range" },
  ];

  const usersOptions = [
    { key: 1, text: "Diarias", value: "daily" },
    { key: 2, text: "Semanales", value: "weekly" },
    { key: 4, text: "Rango de fechas", value: "range" },
  ];
  return (
    <div className={className}>
      <CSSTransition
        in={transition}
        timeout={350}
        appear={true}
        classNames="filters"
      >
        <div className="filters">
          {item === "parking" && (
            <div className="single">
              <Dropdown
                text="Edificio"
                labeled
                floating
                scrolling
                options={buildingsOptions}
                onChange={handleBuildingChange}
              />
              <p>{buildingName}</p>
            </div>
          )}
          {item === "services" && (
            <div className="single">
              <Dropdown text="Servicios" labeled floating options={options} />
              <p>Servicio seleccionado</p>
            </div>
          )}
          <div className="single">
            <Dropdown
              text="Filtro Temporal"
              labeled
              floating
              options={item === "users" ? usersOptions : options}
              onChange={handlePeriodChange}
            />
            <p>{filterTime}</p>
          </div>

          {filterTime === "Rango de fechas" && (
            <div className="date-pickers">
              <DatePicker
                className="date-picker"
                placeholderText="Desde"
                dateFormat="DD / MM / YYYY"
                showMonthDropdown
                showYearDropdown
                todayButton={"Hoy"}
                isClearable
                withPortal={phoneView}
                selected={fromDate}
                maxDate={
                  endDate
                    ? moment(endDate)
                        .tz("America/Caracas")
                        .subtract(23, "hours")
                    : moment().tz("America/Caracas")
                }
                onChange={handleFromDate}
              />
              /
              <DatePicker
                className="date-picker"
                placeholderText="Hasta"
                dateFormat="DD / MM / YYYY"
                showMonthDropdown
                showYearDropdown
                todayButton={"Hoy"}
                isClearable
                withPortal={phoneView}
                selected={
                  endDate
                    ? moment(endDate)
                        .tz("America/Caracas")
                        .subtract(23, "hours")
                    : null
                }
                minDate={
                  fromDate
                    ? moment(fromDate).tz("America/Caracas").add(23, "hours")
                    : null
                }
                maxDate={moment().tz("America/Caracas")}
                onChange={handleEndDate}
              />
            </div>
          )}
          <button
            onClick={handleSubmit}
            disabled={
              filterTime === "Rango de fechas" && (!fromDate || !endDate)
            }
          >
            Buscar
          </button>
        </div>
      </CSSTransition>
    </div>
  );
};

export { FiltersModel };
