import React, { Fragment } from "react";
import styled from "styled-components";
import moment from "moment";
import "moment/locale/es-us";
import { formatPrice } from "services/utils";
import { general as text } from "assets/strings/texts";
import { bluePD } from "assets/strings/colors";
moment.locale("es-us");

const BillRefContent = ({ bill, currency }) => {
  return (
    <Fragment>
      <dt>Aprobado por:</dt>
      <dd>{bill.pay_by_username}</dd>
      <dt>Fecha de aprobación:</dt>
      <dd>{moment(bill.pay_date).format(text.shortDateHourFormat)}</dd>
      <dt>Referencia de pago:</dt>
      <dd>{bill.pay_ref}</dd>
      <dt>Código:</dt>
      <dd>{bill.code}</dd>
      <dt>Subtotal:</dt>
      <dd>
        {currency}
        {formatPrice(bill.sub_total_amount)}
      </dd>
      <dt>Porcentaje de retención ISLR:</dt>
      <dd>{formatPrice(bill.islr_withholding_percentage)}%</dd>
      <dt>Monto de retención ISLR:</dt>
      <dd>
        {currency}
        {formatPrice(bill.islr_withholding_amount)}
      </dd>
      <dt>Porcentaje de retención IVA:</dt>
      <dd>{formatPrice(bill.iva_withholding_percentage)}%</dd>
      <dt>Retención IVA:</dt>
      <dd>
        {currency}
        {formatPrice(bill.iva_withholding_amount)}
      </dd>

      <dt>Monto de comisión:</dt>
      <dd>
        {currency}
        {formatPrice(bill.pd_fee)}
      </dd>

      <dt>Total:</dt>
      <dd>
        {currency}
        {formatPrice(bill.total_amount)}
      </dd>
    </Fragment>
  );
};
const MovementDetailModel = ({ movement, className }) => {
  const currencyMapped = {
    USD: "$ ",
    BS: "VES ",
  };

  const showBillRef = movement.bill_ref && movement.status === "approved";
  return (
    <dl className={className}>
      <p>Detalles de la transacción</p>
      <dt>Descripción:</dt>
      <dd>{movement.description_tag}</dd>
      <dd>{movement.description}</dd>

      <dt>Referencia:</dt>
      <dd>{movement.reference}</dd>

      {/*     <dt>Fondos previos a la transacción:</dt>
      <dd>
        {currencyMapped[movement.currency] || ""}
        {formatPrice(movement.previous_funds)}
      </dd> */}

      <dt>Monto:</dt>
      <dd>
        {currencyMapped[movement.currency] || ""}
        {formatPrice(movement.pd_amount)}
      </dd>

      <dt>Fondos:</dt>
      <dd>
        {currencyMapped[movement.currency] || ""}
        {formatPrice(movement.funds)}
      </dd>

      <dt>Contraparte:</dt>
      <dd>
        {(movement && movement.counterpart && movement.counterpart.username) ||
          (movement &&
            movement.commerce_counterpart &&
            movement.commerce_counterpart.name) ||
          "N/A"}
      </dd>

      <dt>Fecha:</dt>
      <dd>{moment(movement.created_at).format(text.shortDateHourFormat)}</dd>
      {showBillRef && (
        <BillRefContent
          bill={movement.bill_ref}
          currency={currencyMapped[movement.currency] || ""}
        />
      )}
    </dl>
  );
};

const MovementDetail = styled(MovementDetailModel)`
  #root & {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    > p {
      font-weight: bold;
      font-size: 1.2rem;
      color: ${bluePD};
    }
    > dt {
      font-weight: bold;
      margin-top: 1rem;
    }
    > dd {
      margin-left: 1rem;
      text-align: left;
    }
  }
`;

export default MovementDetail;
