import React from "react";
import DatePicker from "react-datepicker";
import { Button } from "components/buttons";
import { general as texts } from "assets/strings/texts";
import moment from "moment-timezone";
import { EntitiesList, Pagination } from "components/entities-list";
import { Loader } from "components/loader";
import PropTypes from "prop-types";
import { CommerceListItem, CommerceListHeader } from "./index";
import { randomString } from "services/utils";
const SearchCommercesModel = ({
  viewType,
  loading,
  searchBy,
  fromDate,
  endDate,
  searchParam,
  commerces,
  handleSelectors,
  handleEndDate,
  handleFromDate,
  handleSearchInputChange,
  handleSearch,
  handlePageUp,
  handlePageDown,
  handleSelection,
  currentPage,
  nextPageExist,
  className,
}) => {
  return (
    <div className={className}>
      <div className={"form"}>
        <div className={"selectors"}>
          <p>filtrar búsqueda por:</p>
          <Button
            color={searchBy === "date" ? "blue" : "grey_1"}
            pressed={searchBy === "date" ? true : false}
            value="date"
            disabled={loading}
            onClick={handleSelectors}
          >
            Fecha
          </Button>
          <Button
            color={searchBy === "commerces" ? "blue" : "grey_1"}
            pressed={searchBy === "commerces" ? true : false}
            value="commerces"
            disabled={loading}
            onClick={handleSelectors}
          >
            Comercio
          </Button>
        </div>
        <div className={"searchType"}>
          {searchBy === "date" && (
            <div className={"date"}>
              <DatePicker
                className="date-picker"
                placeholderText={texts.from}
                dateFormat={texts.shortDateFormat}
                disabled={loading}
                showMonthDropdown
                showYearDropdown
                todayButton={texts.today}
                isClearable
                withPortal={viewType === "phone"}
                selected={fromDate}
                maxDate={
                  endDate ? moment(endDate).subtract(23, "hours") : moment()
                }
                onChange={handleFromDate}
              />
              <p>/</p>
              <DatePicker
                className="date-picker"
                placeholderText={texts.to}
                dateFormat={texts.shortDateFormat}
                disabled={loading}
                popperPlacement="bottom-end"
                showMonthDropdown
                showYearDropdown
                todayButton={texts.today}
                isClearable
                withPortal={viewType === "phone"}
                selected={
                  endDate ? moment(endDate).subtract(23, "hours") : null
                }
                minDate={fromDate ? moment(fromDate).add(23, "hours") : null}
                maxDate={moment()}
                onChange={handleEndDate}
              />
            </div>
          )}
          {searchBy === "commerces" && (
            <div
              className={`ui transparent labeled input ${
                searchBy === "date" ? "hidden" : ""
              }`}
            >
              <i aria-hidden="true" className="search icon label"></i>
              <input
                placeholder="Buscar Comercio"
                type="text"
                value={searchParam}
                disabled={loading}
                onChange={handleSearchInputChange}
              />
            </div>
          )}
        </div>
        <div className={"search"}>
          <Button
            disabled={loading}
            color="grey"
            width="95%"
            onClick={handleSearch}
          >
            {" "}
            Buscar
          </Button>
        </div>
      </div>
      <div className="list">
        <EntitiesList
          height="calc(70vh - 4rem)"
          viewState={""}
          entities={commerces}
          formattingFunction={formatCommercesList}
          selectionHandler={handleSelection}
          additionalArgs={[loading]}
        >
          <Pagination
            //padding="0.65rem 0 0.65rem 0"
            nextPageExist={nextPageExist}
            currentPage={currentPage}
            handlePageUp={handlePageUp}
            handlePageDown={handlePageDown}
          ></Pagination>
        </EntitiesList>
      </div>
    </div>
  );
};

const CommerceListItemModel = ({
  commerceName,
  ticketsNumber,
  index,
  handleCommerceSelection,
  className,
}) => {
  return (
    <div id={index} className={className} onClick={handleCommerceSelection}>
      <dd className="back">
        <p className="thirty">{commerceName}</p>
        <p className="twenty">{ticketsNumber}</p>
      </dd>
    </div>
  );
};

const CommerceListHeaderModel = ({ className }) => {
  return (
    <div className={className}>
      <dd className="back">
        <p className="thirty">Comercio</p>
        <p className="twenty">#Tickets</p>
      </dd>
    </div>
  );
};

function formatCommercesList(
  commerces,
  viewState,
  handleCommerceSelection,
  loading
) {
  let commercesList = [];
  if (loading) {
    return <Loader />;
  }
  if (commerces.length <= 0) {
    return <div className="not-found">No se han encontrado comercios</div>;
  }
  commercesList.push(<CommerceListHeader key={"header"} />);
  commerces.forEach((elem, index) => {
    let commerceDate = moment(elem.date)
      .utcOffset(0, false)
      .format("DD / MM / YYYY");
    let nextCommerceDate;
    if (index !== 0)
      nextCommerceDate = moment(commerces[index - 1].date)
        .utcOffset(0, false)
        .format("DD / MM / YYYY");
    if (index === 0 || commerceDate !== nextCommerceDate) {
      let separatorDate = moment(elem.date).utcOffset(0, false);
      commercesList.push(
        <dt key={`${randomString()}`} className="date-separator">
          {"Tickets digitalizados del "}
          {formatDate(separatorDate)}
        </dt>
      );
    }
    commercesList.push(
      <CommerceListItem
        commerceName={
          elem.commerce.short_name !== "pagodirecto"
            ? elem.commerce.short_name
            : "Taquilla"
        }
        ticketsNumber={elem.tickets}
        handleCommerceSelection={handleCommerceSelection}
        index={index}
        key={`${randomString()}`}
      />
    );
  });

  return commercesList;
}

function formatDate(momentObject) {
  let dateString = momentObject.format("dddd, D [de] MMMM YYYY");
  dateString = dateString.charAt(0).toUpperCase() + dateString.slice(1);
  return dateString;
}

CommerceListItemModel.propTypes = {
  commerceName: PropTypes.string,
  ticketsNumber: PropTypes.number,
};

export {
  SearchCommercesModel,
  CommerceListItemModel,
  CommerceListHeaderModel,
  formatCommercesList,
};
