import { BoxofficeFormModel } from "./model";
import styled from "styled-components";
import { magenta, bluePD, grey_3, grey_5 } from "assets/strings/colors";

const BoxofficeForm = styled(BoxofficeFormModel)`
  #root && {
    height: calc(100vh - 4rem);
    min-height: 500px;
    width: 60vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > .register {
      margin: 0;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      > input {
        margin: 0.5rem;
        height: 8vh;
        min-height: 56px;
        width: 35vw;
        border: 1px solid ${grey_3};
        border-radius: 2rem;
        text-align: center;
        font-size: 1rem;
        :focus {
          outline: none;
          border: 1px solid ${bluePD};
        }
      }
      > .label {
        margin: 0;
        margin-top: 2rem;
        width: 35vw;
        text-align: center;
        color: ${grey_5};
      }
      > .label-drop {
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 8vh;
        min-height: 56px;
        width: 35vw;
        color: #6586cf;
        padding: 0 5rem;
        border: 1px solid ${grey_3};
        border-radius: 2rem;
        text-align: center;
        font-size: 1rem;
        .menu {
          max-height: 200px;
          border: 1px solid ${bluePD};
          border-radius: 1rem;
        }
        :focus {
          outline: none;
          border: 1px solid ${bluePD};
        }
      }
      > .check {
        margin: 1rem 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        > input {
          cursor: pointer;
        }
        > label {
          padding: 0 0.5rem;
          color: ${grey_5};
        }
      }
      > button {
        margin-top: 4rem;
        width: 60%;
        height: 8vh;
        min-height: 56px;
        //background: linear-gradient(to right, ${magenta}, ${bluePD});
        background: ${bluePD};
        border: none;
        border-radius: 2rem;
        color: white;
        font-size: 1.2rem;
        font-weight: bold;
        :hover {
          outline: none;
          opacity: 0.7;
          cursor: pointer;
        }
      }
    }
    .register.register-appear {
      opacity: 0;
      transform: translateX(-30px);
    }
    .register.register-appear-active {
      opacity: 0.8;
      transform: translateX(2px);
      transition: all 350ms ease-out;
    }
    .register.register-enter {
      opacity: 0;
      transform: translateX(-30px);
    }
    .register.register-enter-active {
      opacity: 0.8;
      transform: translateX(2px);
      transition: all 300ms ease-out;
    }
    .register.register-enter-done {
      opacity: 1;
      transform: translateX(0);
      transition: all 50ms ease-out;
    }
    .register.register-exit {
      opacity: 0.8;
      transform: translateX(-2px);
    }
    .register.register-exit-active {
      opacity: 0;
      transform: translateX(30px);
      transition: all 350ms ease-out;
    }
    @media (max-width: 900px) {
      > .register {
        > input {
          width: 100%;
        }
        > .label-drop {
          width: 100%;
        }
        > .label {
          width: 100%;
        }
        > button {
          width: 100%;
        }
      }
    }
  }
`;

export { BoxofficeForm };
