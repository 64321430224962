import React, { useEffect } from "react";
import { connect } from "react-redux";
import { WalletTransferHistoryState } from "./context/state";
import WalletTransferHistoryView from "./components/wallet-transfer-history-view";
import { useViewType } from "hooks/use-view-type";

const MPosWalletTransferHistory = () => {
  const [viewType, setViewType] = useViewType("desktop");
  useEffect(() => {
    setViewType();
    window.addEventListener("resize", setViewType);
    return () => {
      window.removeEventListener("resize", setViewType);
    };
  }, []);
  const phoneView = viewType === "phone";
  return (
    <WalletTransferHistoryState>
      <WalletTransferHistoryView phoneView={phoneView} />
    </WalletTransferHistoryState>
  );
};

const mapStateToProps = null;
const mapDispatchToProps = null;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MPosWalletTransferHistory);
//
