import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { resetState } from "redux/actions/view-mpos-commerces";
import { MposCommercesView } from "./components/mpos-commerces-view";
import { getTransactionsTags } from "redux/selectors/constants";
import { getViewMposCommerces } from "redux/selectors/view-mpos-commerces";
import { MposCommercesPhoneView } from "./components/mpos-commerces-phone-view";
class MposCommercesAdmin_unconected extends PureComponent {
  /*
  =============== STATE DOCS ===============
  IMPORTANT: this Dcumentation needs to be updated due to the refactor made,
  the most important highlights are:
  -- state has been moved to redux state
  -- every panel (the componens that form this view) control their correspondign state
  -- viewState could and should be refactored to a more component-friendly alternative
  -- previous state docs is kept as an overview of the flow

--> commerce_search
  { ...this.initialState }

--> commerce_search_loading
  {
    searchParam: String
  }

--> commerce_search_done
  {
    searchParam: String,
    commerces: [zero or more],
    currentPage_Commerces: Number,
    nextPageExist_Commerces: Boolean
  }

--> commerce_info
  {
    searchParam: String,
    commerces: [zero or more],
    currentPage_Commerces: Number,
    nextPageExist_Commerces: Boolean,
    selectedCommerce: {}
  }

--> commerce_info_loading
  {
    searchParam: String,
    commerces: [zero or more],
    currentPage_Commerces: Number,
    nextPageExist_Commerces: Boolean,
    selectedCommerce: {}
  }
  === Prepaid Tickets Recharge ===
--> commerce_prepaid_tickets
  {
    searchParam: String,
    commerces: [zero or more],
    currentPage_Commerces: Number,
    nextPageExist_Commerces: Boolean,
    selectedCommerce: {}
  }

--> commerce_prepaid_tickets_loading
  {
    searchParam: String,
    commerces: [zero or more],
    currentPage_Commerces: Number,
    nextPageExist_Commerces: Boolean,
    selectedCommerce: {}
  }

--> commerce_prepaid_tickets_done
  {
    searchParam: String,
    commerces: [zero or more],
    currentPage_Commerces: Number,
    nextPageExist_Commerces: Boolean,
    selectedCommerce: {},
    availableTickets: Number
    ticketsToAdd: Number,
  }

--> commerce_prepaid_tickets_recharge
  {
    searchParam: String,
    commerces: [zero or more],
    currentPage_Commerces: Number,
    nextPageExist_Commerces: Boolean,
    selectedCommerce: {},
    availableTickets: Number
    ticketsToAdd: Number,
    ... Popup_recharge_alert_message,
  }

--> commerce_prepaid_tickets_recharge_loading
  {
    searchParam: String,
    commerces: [zero or more],
    currentPage_Commerces: Number,
    nextPageExist_Commerces: Boolean,
    selectedCommerce: {},
    availableTickets: Number
    ticketsToAdd: Number,
    ... Popup_recharge_alert_message,
  }

  === Invoice flow ===
--> commerce_invoice
  {
    searchParam: String,
    commerces: [zero or more],
    currentPage_Commerces: Number,
    nextPageExist_Commerces: Boolean,
    selectedCommerce: {}
  }

--> commerce_invoice_loading
  {
    searchParam: String,
    commerces: [zero or more],
    currentPage_Commerces: Number,
    nextPageExist_Commerces: Boolean,
    selectedCommerce: {},
    searchType: enum ['latests','dateRange'],
    fromDate: moment,
    endDate: moment,
    paid: boolean,
    pending: boolean,
    overdue: boolean
  }

--> commerce_invoice_done
  {
    searchParam: String,
    commerces: [zero or more],
    currentPage_Commerces: Number,
    nextPageExist_Commerces: Boolean,
    selectedCommerce: {},
    searchType: enum ['latests','dateRange'],
    fromDate: moment,
    endDate: moment,
    paid: boolean,
    pending: boolean,
    overdue: boolean,
    invoices: [zero or more],
    currentPage_Invoices: Number,
    nextPageExist_Invoices: boolean
  }

--> commerce_invoice_payment
  {
    searchParam: String,
    commerces: [zero or more],
    currentPage_Commerces: Number,
    nextPageExist_Commerces: Boolean,
    selectedCommerce: {},
    searchType: enum ['latests','dateRange'],
    fromDate: moment,
    endDate: moment,
    paid: boolean,
    pending: boolean,
    overdue: boolean,
    invoices: [zero or more],
    currentPage_Invoices: Number,
    nextPageExist_Invoices: boolean,
    selectedInvoice: String
  }

--> commerce_invoice_payment_loading
  {
    searchParam: String,
    commerces: [zero or more],
    currentPage_Commerces: Number,
    nextPageExist_Commerces: Boolean,
    selectedCommerce: {},
    searchType: enum ['latests','dateRange'],
    fromDate: moment,
    endDate: moment,
    paid: boolean,
    pending: boolean,
    overdue: boolean,
    invoices: [zero or more],
    currentPage_Invoices: Number,
    nextPageExist_Invoices: boolean,
    selectedInvoice: String
  }
  */

  componentWillUnmount() {
    this.props.resetState();
  }

  render() {
    if (this.props.viewType === "phone") {
      return (
        <MposCommercesPhoneView
          viewState={this.props.viewState}
          commerceSession={this.props.commerce._id}
          transactionTags={this.props.transactionTags}
        />
      );
    }

    return (
      <MposCommercesView
        viewState={this.props.viewState}
        selectedCommerce={this.props.selectedCommerce}
        commerceSession={this.props.commerce._id}
        transactionTags={this.props.transactionTags}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  viewState: getViewMposCommerces(state).viewState,
  selectedCommerce: getViewMposCommerces(state).selectedCommerce,
  viewType: state.responsiveData.viewType,
  transactionTags: getTransactionsTags(state),
});

const mapDispatchToProps = {
  resetState,
};

const MposCommercesAdmin = connect(
  mapStateToProps,
  mapDispatchToProps
)(MposCommercesAdmin_unconected);

export default MposCommercesAdmin;
