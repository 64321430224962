export const STORE_CLEAR = 'STORE_CLEAR'
export const SOCKET = "SOCKET";

export const clearReduxStore = () => ({ 
  type: STORE_CLEAR 
})

export const addSocket = (socket) => ({
  type: SOCKET,
  payload: {
    socket,
  },
});