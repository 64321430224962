import React from "react";
import { CSSTransition } from "react-transition-group";
import { CustomGraph } from "components/custom-graph";
import { EntitiesList } from "components/entities-list";
import { formatStatusTypeListDetail } from "../type-status-list";

const FirstRowModel = ({
  transition,
  customStats,
  item,
  listTypesStatusHandlePeriod,
  listDataHandle,
  typeStatusList,
  phoneView,
  activeUsers,
  usersTypes,
  totalUsers,
  className,
}) => {
  let period = typeStatusList.periodSelected;
  let dataSelected = typeStatusList.dataSelected;
  return (
    <div className={className}>
      <CSSTransition
        in={transition}
        timeout={350}
        appear={true}
        classNames="first-row"
      >
        <div
          className={
            //item === "parking" ? "first-row-parking" : "first-row-users"
            "first-row-parking"
          }
        >
          {item === "parking" && (
            <div className="left">
              {
                <div className="selectors">
                  <div className="periods">
                    <p
                      className={period === "last" ? "box-selected" : "box"}
                      onClick={listTypesStatusHandlePeriod}
                      id="last"
                    >
                      Periodo Anterior
                    </p>
                    <p
                      id="current"
                      className={period === "current" ? "box-selected" : "box"}
                      style={{ height: "20px" }}
                      onClick={listTypesStatusHandlePeriod}
                    >
                      Periodo Actual
                    </p>
                  </div>
                </div>
              }
              {item === "parking" && (
                <div className="selectors">
                  <div className="periods">
                    <p
                      className={
                        dataSelected === "status" ? "box-selected-color" : "box"
                      }
                      onClick={listDataHandle}
                      id="status"
                    >
                      Status
                    </p>
                    <p
                      id="types"
                      className={
                        dataSelected === "types" ? "box-selected-color" : "box"
                      }
                      style={{ height: "25px" }}
                      onClick={listDataHandle}
                    >
                      Tipos
                    </p>
                  </div>
                </div>
              )}
              {item === "parking" && (
                <div className="list">
                  <EntitiesList
                    height={"100px"}
                    viewState={dataSelected}
                    entities={typeStatusList[period][dataSelected]}
                    formattingFunction={formatStatusTypeListDetail}
                    selectionHandler={() => {}}
                  />
                </div>
              )}
              {/*     {item === "users" && (
              <div className="active">
                <p className="qty">{activeUsers}</p>
                <p className="title">Usuarios activos</p>
                <p className="qty">{totalUsers}</p>
                <p className="title">Total de nuevos usuarios</p>
              </div>
            )} */}
            </div>
          )}
          <div className="right">
            {<p className="title">{customStats.title}</p>}
            {<CustomGraph data={customStats.data} lines={customStats.lines} />}
            {/*          {item === "users" && (
              <div className="active">
                <p className="qty">{usersTypes.premium}</p>
                <p className="title">Usuarios premium</p>
                <p className="qty">{usersTypes.regular}</p>
                <p className="title">Usuarios regulares</p>
              </div>
            )} */}
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export { FirstRowModel };
