import React from "react";
import { Button } from "components/buttons";
import { general as texts } from "assets/strings/texts";

const CustomFormModel = ({
  overnightActive,
  billingObj,
  flatAmount,
  hourAmount,
  secondHourAmount,
  thirdHourAmount,
  extraAmount,
  overnightAmount,
  handleInputFlatAmount,
  handleInputHourAmount,
  handleInputSecondHourAmount,
  handleInputThirdHourAmount,
  handleInputExtraAmount,
  handleInputOvernightAmount,
  handleCustomSubmit,
  className,
}) => {
  let activeAmounts = billingObj.active_amounts
    ? billingObj.active_amounts
    : [];
  let hourIsActive = activeAmounts.find((amount) => amount === "hour_amount"),
    secondHourIsActive = activeAmounts.find(
      (amount) => amount === "second_hour_amount"
    ),
    thirdHourIsActive = activeAmounts.find(
      (amount) => amount === "third_hour_amount"
    ),
    flatIsActive = activeAmounts.find((amount) => amount === "flat_amount"),
    extraIsActive = activeAmounts.find((amount) => amount === "extra_amount");

  return (
    <div className={className}>
      {flatIsActive && (
        <div className="content">
          <p>Tarifa plana </p>
          <div className="input-section">
            <input
              required
              id="flat"
              min="0.01"
              max="any"
              type="text"
              name="amount"
              autoComplete="off"
              placeholder="Nueva Tarifa"
              value={flatAmount}
              onChange={handleInputFlatAmount}
            />
          </div>
        </div>
      )}
      {hourIsActive && (
        <div className="content">
          <p>Tarifa por primera hora </p>
          <div className="input-section">
            <input
              required
              id="perHour"
              min="0.01"
              max="any"
              type="text"
              name="amount"
              autoComplete="off"
              placeholder="Nueva Tarifa"
              value={hourAmount}
              onChange={handleInputHourAmount}
            />
          </div>
        </div>
      )}
      {secondHourIsActive && (
        <div className="content">
          <p>Tarifa por segunda hora </p>
          <div className="input-section">
            <input
              required
              id="secondHour"
              min="0.01"
              max="any"
              type="text"
              name="amount"
              autoComplete="off"
              placeholder="Nueva Tarifa"
              value={secondHourAmount}
              onChange={handleInputSecondHourAmount}
            />
          </div>
        </div>
      )}
      {thirdHourIsActive && (
        <div className="content">
          <p>Tarifa por tercera hora </p>
          <div className="input-section">
            <input
              required
              id="thirdHour"
              min="0.01"
              max="any"
              type="text"
              name="amount"
              autoComplete="off"
              placeholder="Nueva Tarifa"
              value={thirdHourAmount}
              onChange={handleInputThirdHourAmount}
            />
          </div>
        </div>
      )}
      {extraIsActive && (
        <div className="content">
          <p>Tarifa extra por más de un día </p>
          <div className="input-section">
            <input
              required
              id="extraHour"
              min="0.01"
              max="any"
              type="text"
              name="amount"
              autoComplete="off"
              placeholder="Nueva Tarifa"
              value={extraAmount}
              onChange={handleInputExtraAmount}
            />
          </div>
        </div>
      )}
      {overnightActive && (
        <div className="content">
          <p>Pernocta</p>
          <div className="input-section">
            <input
              required
              id="pernocta"
              min="0.01"
              max="any"
              type="text"
              name="amount"
              autoComplete="off"
              placeholder="Nueva Tarifa"
              value={overnightAmount}
              onChange={handleInputOvernightAmount}
            />
          </div>
        </div>
      )}
      <Button color="blue" onClick={handleCustomSubmit}>
        {texts.accept}
      </Button>
    </div>
  );
};

export { CustomFormModel };
