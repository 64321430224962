import React from "react";
import styled from "styled-components";
import { grey_bg } from "assets/strings/colors";
import { Grid } from "semantic-ui-react";
import PopupMessage from "components/popup-message";
import { Loader } from "components/loader";

import { DigitizeHeader } from "./digitize-header";
import { DigitizeBody } from "./digitize-body";
import { DigitizeFooter } from "./digitize-footer";

const MposCommerceDigitizeViewModel = ({
  loading,
  commerceIsPrepaid,
  selectedUser,
  handleSearchInputChange,
  searchParam,
  toShow,
  users,
  autoPaid,
  message,
  handleUserSelection,
  handleContinue,
  canDigitize,
  ticketHour,
  ticketMinute,
  handleTicketHour,
  handleTicketMinute,
  ticketDate,
  handleTicketDate,
  handleUserSearch,
  ticketNumber,
  handleTicketNumber,
  handleAutoPaid,
  errorType,
  handleBack,
  generatedTicket,
  handleValidate,
  handleSubmit,
  popupMessages,
  popupMessagesType,
  showPopup,
  closePopup,
  viewType,
  className,
}) => {
  return (
    <Grid padded centered className={className}>
      <PopupMessage
        messageType={popupMessagesType}
        showPopup={showPopup}
        messages={popupMessages}
        closePopup={closePopup}
      />
      {loading && <Loader />}
      <DigitizeHeader
        handleSearchInputChange={handleSearchInputChange}
        searchParam={searchParam}
        handleSearch={handleUserSearch}
        viewType={viewType}
        canDigitize={canDigitize}
      />
      <DigitizeBody
        toShow={toShow}
        users={users}
        autoPaid={autoPaid}
        generatedTicket={generatedTicket}
        ticketHour={ticketHour}
        ticketMinute={ticketMinute}
        handleTicketHour={handleTicketHour}
        handleTicketMinute={handleTicketMinute}
        handleAutoPaid={handleAutoPaid}
        ticketDate={ticketDate}
        handleTicketDate={handleTicketDate}
        ticketNumber={ticketNumber}
        handleTicketNumber={handleTicketNumber}
        selectedUser={selectedUser}
        handleUserSelection={handleUserSelection}
        handleSubmit={handleSubmit}
        handleValidate={handleValidate}
        handleContinue={handleContinue}
        handleBack={handleBack}
        message={message}
        viewType={viewType}
        errorType={errorType}
      />
      <DigitizeFooter
        commerceIsPrepaid={commerceIsPrepaid}
        viewType={viewType}
      />
    </Grid>
  );
};

const MposCommerceDigitizeView = styled(MposCommerceDigitizeViewModel)`
  #root && {
    background: ${grey_bg};
    min-height: calc(100vh - 4rem);
    overflow-y: scroll;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export { MposCommerceDigitizeView };
