import React, { useContext } from "react";
import { MonthlyPremiumInvoicesContext } from "../../context";
import DatePicker from "react-datepicker";
import moment from "moment";
import { general as texts } from "assets/strings/texts";
import { Button } from "components/buttons";
import { Dropdown } from "components/dropdown";
import { SwitchSlider } from "components/checkboxes";

export const InvoiceFormModel = ({ className }) => {
  const { state, changeState, viewType } = useContext(
    MonthlyPremiumInvoicesContext
  );

  const isSuperadmin = state.selectedCommerce.category === "pagodirecto";

  const phoneView = viewType === "phone";
  const handleSubmit = (event) => {
    event.preventDefault();
    changeState({ get_invoices: true });
  };

  const handleFromDate = (date) => {
    changeState({ from_date: date });
  };

  const handleEndDate = (date) => {
    changeState({
      end_date: date ? moment(date).add(23, "hours") : date,
    });
  };

  const handleSearchTypeButtons = (event) => {
    event.preventDefault();
    changeState({ searchType: event.target.value });
  };

  const handleStatusButtons = (event) => {
    event.preventDefault();
    let status = event.target.value;
    changeState({
      [status]: !state[status],
    });
  };
  const handleBuildingChange = (event, data) => {
    const selectedBuilding = data.value;
    changeState({ selectedBuilding });
  };
  const handleFilterSelection = (event) => {
    event.preventDefault();
    let textFilterBy = event.currentTarget.id;
    changeState({ textFilterBy });
  };

  return (
    <form onSubmit={handleSubmit} className={className}>
      <label className="selectors">
        <Button
          value="latests"
          pressed={state.searchType === "latests"}
          color={state.searchType === "latests" ? "blue" : "grey_1"}
          onClick={handleSearchTypeButtons}
          margin="0"
        >
          {texts.latests}
        </Button>
        <Button
          value="dateRange"
          pressed={state.searchType === "dateRange"}
          color={state.searchType === "dateRange" ? "blue" : "grey_1"}
          onClick={handleSearchTypeButtons}
          margin="0"
        >
          {texts.dateRange}
        </Button>
      </label>

      {state.searchType === "dateRange" && (
        <div className="date-range">
          <div className="date-pickers">
            <DatePicker
              className="date-picker"
              placeholderText={texts.from}
              dateFormat={texts.shortDateFormat}
              disabled={state.loading}
              showMonthDropdown
              showYearDropdown
              todayButton={texts.today}
              isClearable
              withPortal={phoneView}
              selected={state.from_date}
              maxDate={
                state.end_date
                  ? moment(state.end_date).subtract(23, "hours")
                  : moment()
              }
              onChange={handleFromDate}
            />
            <p>/</p>
            <DatePicker
              className="date-picker"
              placeholderText={texts.to}
              dateFormat={texts.shortDateFormat}
              disabled={state.loading}
              popperPlacement="bottom-end"
              showMonthDropdown
              showYearDropdown
              todayButton={texts.today}
              isClearable
              withPortal={phoneView}
              selected={
                state.end_date
                  ? moment(state.end_date).subtract(23, "hours")
                  : null
              }
              minDate={
                state.from_date
                  ? moment(state.from_date).add(23, "hours")
                  : null
              }
              maxDate={moment()}
              onChange={handleEndDate}
            />
          </div>
        </div>
      )}
      <label className="selectors">
        <Button
          color={state.paid ? "green" : "grey_1"}
          pressed={state.paid ? true : false}
          value="paid"
          onClick={handleStatusButtons}
          margin="0"
        >
          Pagados
        </Button>
        <Button
          color={state.pending ? "yellow" : "grey_1"}
          pressed={state.pending ? true : false}
          value="pending"
          onClick={handleStatusButtons}
          margin="0"
        >
          Pendientes
        </Button>
      </label>
      {isSuperadmin && (
        <div className="filter-by">
          <label className="title">Filtrar por quien:</label>
          <SwitchSlider
            margin="0"
            selected={state.textFilterBy}
            options={state.textFilterByOptions}
            width={phoneView ? "100%" : "80%"}
            handleSelection={handleFilterSelection}
          />

          <Dropdown
            fluid
            selection
            placeholder={"Edificio"}
            wrapSelection={true}
            search
            options={state.buildingsOptions}
            value={state.selectedBuilding}
            disabled={state.loading}
            onChange={handleBuildingChange}
            textalign="center"
            className={"building-selector"}
          />
        </div>
      )}
      <Button color="grey" onClick={handleSubmit} margin="0">
        Buscar
      </Button>
    </form>
  );
};
