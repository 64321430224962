import React from "react";
import { Panel } from "components/panel";
import { Grid } from "semantic-ui-react";
import { CommerceMovementsForm } from "components/commerce-movements-form";
import PopupMessage from "components/popup-message";
import { CSSTransition } from "react-transition-group";
import {
  formatMovementsList,
  formatMovementsPhoneList,
} from "./movements-list-item";
import { EntitiesList, Pagination } from "components/entities-list";

const MposMovementsHistoryView = ({
  //properties
  viewType,
  loading,
  operatorPermissions,
  commerceName,
  viewState,
  movements,
  selectedMovement,
  currentPage,
  nextPageExist,
  //formData
  searchType,
  searchFilter,
  fromDate,
  endDate,
  user,
  status,
  statusOptions,
  //methods
  handleSearchType,
  handleSearchFilter,
  handleFromDate,
  handleEndDate,
  handleChangeUser,
  handleChangeStatus,
  handleSubmit,
  handlePageDown,
  handlePageUp,
  handleMovementSelection,
  //popup
  popupMessages,
  popupMessagesType,
  showPopup,
  closePopup,
}) => {
  return (
    <Grid centered padded>
      <PopupMessage
        messageType={popupMessagesType}
        showPopup={showPopup}
        messages={popupMessages}
        closePopup={closePopup}
      ></PopupMessage>

      <Grid.Column mobile={16} tablet={14} computer={10}>
        <CSSTransition
          in={true}
          mountOnEnter
          unmountOnExit
          timeout={350}
          classNames="panel"
        >
          <Panel>
            <CommerceMovementsForm
              viewType={viewType}
              loading={loading}
              searchType={searchType}
              searchFilter={searchFilter}
              fromDate={fromDate}
              endDate={endDate}
              operatorPermissions={operatorPermissions}
              user={user}
              status={status}
              statusOptions={statusOptions}
              handleSearchType={handleSearchType}
              handleSearchFilter={handleSearchFilter}
              handleFromDate={handleFromDate}
              handleEndDate={handleEndDate}
              handleChangeStatus={handleChangeStatus}
              handleChangeUser={handleChangeUser}
              handleSubmit={handleSubmit}
            />
          </Panel>
        </CSSTransition>

        <CSSTransition
          in={!loading}
          mountOnEnter
          unmountOnExit
          timeout={350}
          appear={true}
          classNames="panel"
        >
          <Panel>
            <EntitiesList
              viewState={viewState}
              entities={[movements, selectedMovement]}
              formattingFunction={
                viewType === "desktop"
                  ? formatMovementsList
                  : formatMovementsPhoneList
              }
              selectionHandler={handleMovementSelection}
              additionalArgs={[commerceName]}
            >
              <Pagination
                padding="0 0 0.8rem 0"
                nextPageExist={nextPageExist}
                currentPage={currentPage}
                handlePageUp={handlePageUp}
                handlePageDown={handlePageDown}
              />
            </EntitiesList>
          </Panel>
        </CSSTransition>
      </Grid.Column>
    </Grid>
  );
};

export { MposMovementsHistoryView };
