import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { useViewType } from "hooks/use-view-type";
//import { useDebounce } from "hooks/use-debounce";
import { usePopupMessages } from "hooks/use-popup-messages";
import { connect } from "react-redux";
import { clearReduxStore } from "redux/actions/general";
import { getSelectedCommerce } from "redux/selectors";
import MPosUsersNotificationsView from "./components/mpos-users-notifications-view";
import MPosUsersNotificationsPhoneView from "./components/mpos-users-notifications-phone-view";
//import { debouncer_timeout } from "assets/strings/constants";
import {
  usersAutocomplete,
  ticketsHistory,
  notificationConstants,
} from "services/api";
import { capitalizeFirstLetter } from "services/utils";
import { errors } from "assets/strings/texts";

const MPosUsersNotifications = ({ location }) => {
  const [viewType, setViewType] = useViewType(null);
  const [searchParam, setSearchParam] = useState("");
  const [searchUser, setSearchUser] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewState, setViewState] = useState("user_search");
  /*   const debouncedSearchParam = useDebounce(
    searchParam,
    debouncer_timeout - 600
  ); */
  const {
    popupMessages,
    popupMessagesType,
    showPopup,
    throwPopupMessage,
    closePopup,
  } = usePopupMessages(null, "", false);

  const [screenOptions, setScreenOptions] = useState([]);
  const [logosOptions, setLogosOptions] = useState([]);
  const [allSelection, setAllselection] = useState(false);
  useEffect(() => {
    const getConstants = async () => {
      try {
        setLoading(true);
        const response = await notificationConstants();
        const res = await response.json();
        if (res.error) throw res.error;
        let scrOptions = [];
        res.events.map((event) => {
          scrOptions.push({
            key: event,
            value: event,
            text: capitalizeFirstLetter(event),
          });
          return null;
        });
        setScreenOptions(scrOptions);
        setLogosOptions(res.logos);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        throwPopupMessage("error", err.description);
      }
    };
    setViewType();
    getConstants();
    window.addEventListener("resize", setViewType);
    document.addEventListener("keydown", onEnter);
    return () => {
      window.removeEventListener("resize", setViewType);
      document.removeEventListener("keydown", onEnter);
    };
  }, []);

  useEffect(() => {
    const searchUsers = async () => {
      try {
        setLoading(true);
        const response = await usersAutocomplete(searchParam);
        const res = await response.json();
        if (res.error) throw res.error;
        if (res.users) setUsers(res.users);
        setLoading(false);
      } catch (error) {
        if (
          error.id === "NO_TOKEN_PROVIDED" ||
          error.id === "AUTHENTICATE_FAILED" ||
          error.id === "DUPLICATE_SESSION"
        ) {
          throwPopupMessage("alert", errors.AUTHENTICATION);
        } else {
          throwPopupMessage("error", error.description);
        }
      }
    };
    if (searchUser) {
      setSearchUser(false);
      searchUsers();
    }
  }, [searchUser]);

  const handleSearchInputChange = (event) => {
    let value = event.target.value;
    // The field is restrcted to accept only letters, numbers, @(first position) and _-.
    if (value.match(/^(?=@?.*)@?[\w.-]*$/)) {
      value = value.toLowerCase();
      // call the API in a debounced way
      setSearchParam(value);
    }
  };

  const [searchType, setSearchType] = useState("by_user");

  const handleSearchType = (event) => {
    event.preventDefault();
    let type = event.currentTarget.id;
    setSearchType(type);
  };

  const [handling, setHandling] = useState(false);

  useEffect(() => {
    if (handling) setHandling(false);
  }, [handling]);

  const handleUserSelection = (event) => {
    let index = event.currentTarget.id;
    let selectUser = users[index];
    let selection = selectedUsers;
    const existList = selection.find((elem) => {
      return elem._id === selectUser._id;
    });
    if (!existList) {
      selection.push(selectUser);
      setViewState("user_selection_done");
      setSelectedUsers(selection);
      setHandling(true);
    }
  };

  const handleAllUsersSelection = (event) => {
    event.preventDefault();
    setAllselection(true);
    let selection = selectedUsers;
    users.map((user) => {
      const existList = selection.find((elem) => {
        return elem._id === user._id;
      });
      if (!existList) {
        selection.push(user);
      }
      return null;
    });
    setViewState("user_selection_done");
    setSelectedUsers(selection);
    setHandling(true);
  };

  const handleUserUnselection = (event) => {
    let index = event.currentTarget.id;
    let selection = selectedUsers;
    selection.splice(index, 1);
    setViewState("user_selection_done");
    setSelectedUsers(selection);
    setHandling(true);
    setAllselection(false);
  };

  const [tickets, setTickets] = useState([]);
  const [searchTickets, setSearchTickets] = useState(false);

  useEffect(() => {
    const getHistory = async () => {
      try {
        const dateFrom = moment(moment().startOf("day"))
          .tz("America/Caracas")
          .format();
        const dateTo = moment(moment().startOf("day"))
          .tz("America/Caracas")
          .add(23, "hours")
          .format();
        setLoading(true);
        let fromDate = dateFrom;
        let endDate = dateTo;
        let status = ["ready", "paid"];
        let skip = 0;
        let limit = 1000;
        let building = null;
        let commerce = null;
        const response = await ticketsHistory(
          fromDate,
          endDate,
          status,
          skip,
          limit,
          commerce,
          building
        );
        const res = await response.json();
        if (res.error) throw res.error;
        if (res.tickets) setTickets(res.tickets);
        if (res.iva && res.iva.amount && res.pd_fee && res.pd_fee.amount)
          setTax({
            pd_fee: res.pd_fee.amount,
            iva: res.iva.amount / 100 + 1,
          });
        setLoading(false);
      } catch (error) {
        if (
          error.id === "NO_TOKEN_PROVIDED" ||
          error.id === "AUTHENTICATE_FAILED" ||
          error.id === "DUPLICATE_SESSION"
        ) {
          throwPopupMessage("alert", errors.AUTHENTICATION);
        } else {
          throwPopupMessage("error", error.description);
        }
      }
    };
    if (searchTickets) {
      getHistory();
      setSearchTickets(false);
    }
  }, [searchTickets]);

  const handleSearchTickets = (event) => {
    event.preventDefault();
    setSearchTickets(true);
  };

  const handleTicketSelection = (event) => {
    let index = event.currentTarget.id;
    let selectTicket = tickets[index];
    let selectUser = selectTicket.customer ? selectTicket.customer : null;
    let selection = selectedUsers;
    const existList = selection.find((elem) => {
      return elem._id === selectUser._id;
    });
    if (!existList) {
      selection.push(selectUser);
      setViewState("user_selection_done");
      setSelectedUsers(selection);
      setHandling(true);
    }
  };
  const [tax, setTax] = useState({
    pd_fee: 0,
    iva: 0,
  });
  const handleAllTicketsSelection = (event) => {
    event.preventDefault();
    setAllselection(true);
    let selection = selectedUsers;
    tickets.map((ticket) => {
      let selectUser = ticket.customer ? ticket.customer : null;
      const existList = selection.find((elem) => {
        return elem._id === selectUser._id;
      });
      if (!existList) {
        selection.push(selectUser);
      }
      return null;
    });
    setViewState("user_selection_done");
    setSelectedUsers(selection);
    setHandling(true);
  };
  const handleBack = (event) => {
    event.preventDefault();
    setViewState("user_search");
  };

  const handleSearchUser = (event) => {
    event.preventDefault();
    setSearchUser(true);
  };

  const onEnter = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      handleSearchUser(event);
    }
  };

  if (viewType === "desktop")
    return (
      <MPosUsersNotificationsView
        viewType={viewType}
        loading={loading}
        location={location}
        selectedUsers={selectedUsers}
        users={users}
        searchParam={searchParam}
        searchType={searchType}
        tickets={tickets}
        pd_fee={tax.pd_fee}
        allSelection={allSelection}
        screenOptions={screenOptions}
        logosOptions={logosOptions}
        iva={tax.iva}
        handleSearchTickets={handleSearchTickets}
        handleTicketSelection={handleTicketSelection}
        handleSearchType={handleSearchType}
        handleSearchUser={handleSearchUser}
        handleSearchInputChange={handleSearchInputChange}
        handleUserSelection={handleUserSelection}
        handleUserUnselection={handleUserUnselection}
        handleAllTicketsSelection={handleAllTicketsSelection}
        handleAllUsersSelection={handleAllUsersSelection}
        throwPopupMessage={throwPopupMessage}
        popupMessages={popupMessages}
        popupMessagesType={popupMessagesType}
        showPopup={showPopup}
        closePopup={closePopup}
      />
    );
  if (viewType === "phone")
    return (
      <MPosUsersNotificationsPhoneView
        viewType={viewType}
        viewState={viewState}
        loading={loading}
        location={location}
        selectedUsers={selectedUsers}
        users={users}
        allSelection={allSelection}
        searchParam={searchParam}
        searchType={searchType}
        tickets={tickets}
        pd_fee={tax.pd_fee}
        iva={tax.iva}
        screenOptions={screenOptions}
        logosOptions={logosOptions}
        handleSearchTickets={handleSearchTickets}
        handleTicketSelection={handleTicketSelection}
        handleSearchType={handleSearchType}
        handleBack={handleBack}
        handleSearchInputChange={handleSearchInputChange}
        handleUserSelection={handleUserSelection}
        handleSearchUser={handleSearchUser}
        handleUserUnselection={handleUserUnselection}
        handleAllTicketsSelection={handleAllTicketsSelection}
        handleAllUsersSelection={handleAllUsersSelection}
        throwPopupMessage={throwPopupMessage}
        popupMessages={popupMessages}
        popupMessagesType={popupMessagesType}
        showPopup={showPopup}
        closePopup={closePopup}
      />
    );
  if (viewType === null) return null;
};

const mapStateToProps = (state) => {
  let selectedCommerce = getSelectedCommerce(state);
  return { selectedCommerce };
};

const mapDispatchToProps = {
  clearReduxStore,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MPosUsersNotifications);
