import styled, { keyframes } from "styled-components";

const pagoDirectoAnimation = keyframes`
  0%{
    opacity: 0;
    transform: translateY(20px)
  }
  50%{
    transform: translateY(-3px)
  }
  100%{
    opacity: 1;
    transform: translateY(0)
  }
`;

const Logo = styled.img`
  opacity: 0;
  height: 20vh;
  margin-top: 10vh;
  color: white;
  animation-name: ${pagoDirectoAnimation};
  animation-fill-mode: forwards;
  animation-timing-function: ease;
  animation-duration: 1000ms;
  animation-delay: 2000ms;
`;

export default Logo;
