import React from "react";
import { useStateManager } from "hooks/use-state-manager";
import { DashboardView } from "./components/dashboard-view";

const PDDashboard = () => {
  const initialState = {
    itemSelected: "parking",
    transition: false,
  };

  const [state, changeState] = useStateManager(initialState);

  const handleItemChange = (event) => {
    event.preventDefault();
    let itemSelected = event.currentTarget.id;
    changeState({ itemSelected, transition: false });
  };

  return (
    <DashboardView
      item={state.itemSelected}
      handleItemChange={handleItemChange}
    />
  );
};

export default PDDashboard;
