import styled from "styled-components";
import { NotificationFormModel } from "./model";
import { green, green_2, bluePD, blue_2, grey_4 } from "assets/strings/colors";

export const NotificationForm = styled(NotificationFormModel)`
  #root && {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > label {
      margin: 0.5rem 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 90%;
      > span {
        margin: 0;
        margin-bottom: 0.3rem;
        padding: 0;
        font-size: 1rem;
        text-decoration: underline;
      }
      .file-style {
        margin: 0;
        margin-top: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        > p {
          margin: 0;
          padding: 0;
        }
        > .no-file {
          color: ${grey_4};
        }
        > button {
          margin: 0;
          width: 30%;
          height: 2.5rem;
          color: white;
          font-weight: bold;
          border: none;
          border-radius: 0.4rem;
          background: ${bluePD};
          :hover {
            cursor: pointer;
            background: ${blue_2};
          }
        }
      }
    }

    > button {
      margin: 0;
      margin-top: 1rem;
      width: 30%;
      height: 2.5rem;
      color: white;
      font-weight: bold;
      border: none;
      border-radius: 0.4rem;
      background: ${green};
      :hover {
        cursor: pointer;
        background: ${green_2};
      }
      :disabled {
        outline: none;
        opacity: 0.7;
        cursor: not-allowed;
      }
    }
  }
`;
