import React from "react";
import Bill from "./bill";
import Ticket from "./ticket";
import { Grid } from "semantic-ui-react";
import { Button } from "components/buttons";
import SearchInputNew from "components/search-input-new";
import PopupMessage from "components/popup-message";
import { general as texts } from "assets/strings/texts";
import { formatTicketsListLessInfo } from "components/ticket-list-item";
import { tickets_validate as viewTexts } from "assets/strings/texts";
import { EntitiesList, Pagination } from "components/entities-list";

const MposTicketsValidateView = ({
  viewState,
  searchParam,
  tickets,
  currentPage,
  nextPageExist,
  showTicket,
  selectedTicket,
  totalAmount,
  billContent,
  currency,
  commerce,
  popupMessages,
  popupMessagesType,
  showPopup,
  location,
  closePopup,
  handleSearchInputChange,
  handlePageDown,
  handlePageUp,
  handleTicketSelection,
  handleSearchTicket,
  getTicketAmount,
  removeSelectedTicket,
  toggleValidatedTicket,
  validateTicket,
  resetView,
}) => {
  let isPrepaidCommerce =
    commerce &&
    commerce.parking &&
    commerce.parking.billing_type &&
    commerce.parking.billing_type === "prepaid";
  let canValidate = isPrepaidCommerce
    ? commerce.parking.prepaid_tickets > 0
      ? true
      : false
    : true;
  return (
    <Grid padded>
      {viewState !== "ticket_validation" &&
        viewState !== "ticket_validation_loading" && (
          <PopupMessage
            messageType={popupMessagesType}
            showPopup={showPopup}
            messages={popupMessages}
            closePopup={closePopup}
          />
        )}

      {(viewState === "ticket_validation" ||
        viewState === "ticket_validation_loading") && (
        <PopupMessage
          messageType={popupMessagesType}
          showPopup={showPopup}
          messages={popupMessages}
          closePopup={toggleValidatedTicket}
        >
          <Button
            color="blue"
            disabled={viewState === "ticket_validation_loading" || !canValidate}
            onClick={validateTicket}
          >
            {viewState === "ticket_validation" && "Aceptar"}
            {viewState === "ticket_validation_loading" &&
              viewTexts.validateLoading}
          </Button>
          <Button color="grey" onClick={toggleValidatedTicket}>
            {texts.back}
          </Button>
        </PopupMessage>
      )}

      <Grid.Column width={16}>
        {(viewState === "ticket_search" ||
          viewState === "ticket_search_loading" ||
          viewState === "ticket_search_done") && [
          <SearchInputNew
            placeholder={viewTexts.searchPlaceholder}
            searchParam={searchParam}
            handleChange={handleSearchInputChange}
            handleSearch={handleSearchTicket}
            key="searchInput"
          />,
          <EntitiesList
            key="ticketList"
            height="calc(100vh - 9rem)"
            viewState={viewState}
            entities={tickets}
            formattingFunction={formatTicketsListLessInfo}
            selectionHandler={handleTicketSelection}
          >
            <Pagination
              padding="0.65rem 0 0.65rem 0"
              nextPageExist={nextPageExist}
              currentPage={currentPage}
              handlePageUp={handlePageUp}
              handlePageDown={handlePageDown}
            />
          </EntitiesList>,
        ]}

        {(viewState === "ticket_selection" ||
          viewState === "ticket_selection_loading" ||
          viewState === "ticket_selection_done" ||
          viewState === "ticket_validation" ||
          viewState === "ticket_validation_loading") && (
          <Ticket
            viewState={viewState}
            showTicket={showTicket}
            ticketData={selectedTicket}
            totalAmount={totalAmount}
            getTicketAmount={getTicketAmount}
            canValidate={canValidate}
            validate={toggleValidatedTicket}
            removeTicket={removeSelectedTicket}
            phoneView
          />
        )}

        {viewState === "ticket_validation_done" && (
          <Bill
            header={viewTexts.billHeader}
            billContent={billContent}
            currency={currency}
            resetView={resetView}
            phoneView
          />
        )}
      </Grid.Column>
    </Grid>
  );
};

export default MposTicketsValidateView;
