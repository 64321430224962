import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import ChangePasswordView from "./components/change-password-view";
import { connect } from "react-redux";
import { clearReduxStore } from "redux/actions/general";
import { throwPopupMessage } from "redux/actions/popup-messages";
import { changePassword } from "services/api";

const UserChangePassword = (props) => {
  const [password, setPassword] = useState("");
  const [phoneView, setPhoneView] = useState(false);
  const [redirect, setRedirect] = useState({ redirect: null });
  const [confirm, setConfirm] = useState("");
  const [viewState, setViewState] = useState("password_incomplete");

  const handlePasswordChange = (event) => {
    let value = event.target.value;
    setPassword(value);
    setConfirm("");
    if (value.length < 6) {
      setViewState("password_incomplete");
    } else {
      setViewState("password_success");
    }
  };
  const handleConfirmChange = (event) => {
    let value = event.target.value;
    setConfirm(value);
    if (value !== password) {
      setViewState("confirm_not_equal");
    } else {
      setViewState("confirm_equal");
    }
  };
  const changePasswordFetch = async () => {
    setViewState("wait_response");
    const response = await changePassword(password);
    try {
      if (!response.ok) {
        throw response;
      } else {
        setRedirect({ redirect: <Redirect to="/mpos" /> });
        const res = await response.json();
        props.throwPopupMessage("success", res.message);
      }
    } catch (err) {
      console.log(err);
      if (typeof err.json === "function") {
        const err_body = await err.json();
        props.throwPopupMessage("error", err_body.error.description);
        props.clearReduxStore();
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    changePasswordFetch();
  };
  useEffect(() => {
    window.addEventListener("resize", setViewType);
    return () => {
      window.removeEventListener("resize", setViewType);
    };
  }, []);

  const setViewType = () => {
    let documentElement = document.documentElement,
      width = window.innerWidth || documentElement.clientWidth;
    if (width < 768) {
      setPhoneView(true);
    } else {
      setPhoneView(false);
    }
  };

  return (
    <ChangePasswordView
      // props
      viewState={viewState}
      redirect={redirect.redirect}
      password={password}
      confirm={confirm}
      phoneView={phoneView}
      // methods
      handleConfirmChange={handleConfirmChange}
      handlePasswordChange={handlePasswordChange}
      handleSubmit={handleSubmit}
    />
  );
};

const mapStateToProps = null;

const mapDispatchToProps = {
  clearReduxStore,
  throwPopupMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserChangePassword);
