import { FiltersModel } from "./model";
import styled from "styled-components";
import { grey_4, grey_6 } from "assets/strings/colors";

const Filters = styled(FiltersModel)`
  #root && {
    height: 15vh;
    border: none;
    border-bottom: 1px solid ${grey_4};
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #f0f0ff;
    > .date-pickers {
      margin: 1rem 0 0 0;
      display: flex;
      align-items: center;
      justify-content: space-around;
      > p {
        margin: 0;
        padding-left: 1rem;
        font-weight: bold;
        text-transform: uppercase;
        color: ${grey_6};
        background: #f0f0ff;
      }
      > div {
        width: 46%;
        background: #f0f0ff;
        > .react-datepicker__input-container {
          width: 100%;
          background: #f0f0ff;
          > input {
            background: #f0f0ff;
            width: calc(100% - 16px);
            border: none;
            border-radius: 1rem;
            color: ${grey_6};
            font-size: 1.2rem;
            margin: 0.5rem 5px 0.5rem 5px;
            text-align: center;
            outline: none;
            box-shadow: none;
            transition: box-shadow 250ms linear;
            :focus {
              box-shadow: 0 1px darkgrey;
            }
          }
          > .react-datepicker__close-icon::after {
            right: 0;
            background: #f0f0ff;
          }
        }
        > .react-datepicker-wrapper {
          width: 100%;
          background: #f0f0ff;
          > .react-datepicker__input-container {
            width: 100%;
            background: #f0f0ff;
            > input {
              width: calc(100% - 16px);
              background: #f0f0ff;
              border: none;
              border-radius: 1rem;
              color: ${grey_6};
              font-size: 1.2rem;
              margin: 0.5rem 5px 0.5rem 5px;
              text-align: center;
              outline: none;
              box-shadow: none;
              transition: box-shadow 250ms linear;
              :focus {
                box-shadow: 0 1px darkgrey;
              }
            }
            > .react-datepicker__close-icon::after {
              right: 15px;
            }
          }
        }
      }
    }
  }
`;

export { Filters };
