import React from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import { general as texts } from "assets/strings/texts";

const DateFormModel = ({
  fromDate,
  endDate,
  loading,
  handleFromDate,
  handleEndDate,
  phoneView,
  className,
}) => {
  return (
    <div className={className}>
      <div className="date-pickers">
        <DatePicker
          className="date-picker"
          placeholderText={texts.from}
          dateFormat={texts.shortDateFormat}
          disabled={loading}
          showMonthDropdown
          showYearDropdown
          todayButton={texts.today}
          isClearable
          withPortal={phoneView}
          selected={fromDate}
          maxDate={endDate ? moment(endDate).subtract(23, "hours") : moment()}
          onChange={handleFromDate}
        />
        <p>/</p>
        <DatePicker
          className="date-picker"
          placeholderText={texts.to}
          dateFormat={texts.shortDateFormat}
          disabled={loading}
          popperPlacement="bottom-end"
          showMonthDropdown
          showYearDropdown
          todayButton={texts.today}
          isClearable
          withPortal={phoneView}
          selected={endDate}
          minDate={fromDate ? moment(fromDate).add(23, "hours") : null}
          maxDate={moment()}
          onChange={handleEndDate}
        />
      </div>
    </div>
  );
};

export default DateFormModel;
