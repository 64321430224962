import React from "react";
import { Button } from "components/buttons";

export const UserResetConfirmModel = ({
  username,
  handleCancel,
  handleApprove,
  className,
}) => {
  return (
    <div className={className}>
      <p> {`¿Desea reestablecer la contraseña de ${username}?`}</p>
      <Button color="blue" onClick={handleApprove}>
        Si
      </Button>
      <Button color="grey" onClick={handleCancel}>
        No
      </Button>
    </div>
  );
};
