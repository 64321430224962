import GeneralInputModel from "./model";
import styled from "styled-components";
import { grey_4, grey_6 } from "assets/strings/colors";

const GeneralInput = styled(GeneralInputModel)`
  #root & {
    margin-top: 1rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 5vh;
    font-size: 1rem;
    > div {
      width: 100%;
      > p {
        font-size: clamp(0.7rem, 1vw, 1.5rem);
        text-align: center;
        width: 100%;
        margin-bottom: 0;
      }
      > input {
        width: 100%;
        color: ${grey_6};
        background: #f0f0ff;
        border: 1px solid #d0d0dd;
        :focus {
          outline: none !important;
          border: 1px solid #a0a0ff;
        }
      }
      .input-tag {
        height: 2rem;
        text-align: center;
        max-width: 100%;
        ::placeholder {
          overflow-wrap: normal;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .search.icon.label {
      color: ${grey_4};
      margin-left: 1rem;
    }
  }
  *,
  :before {
    box-sizing: initial;
  }
`;

export default GeneralInput;
