import { IconBackModel, IconBackContentModel } from "./model";
import styled from "styled-components";
import { grey_4 } from "assets/strings/colors";

export const IconBack = styled(IconBackModel)`
  #root & {
    display: flex;
    align-item: left;
    float: left;
    color: ${grey_4};
    cursor: pointer;
    z-index: 1;
  }
`;
export const IconBackContent = styled(IconBackContentModel)`
  #root & {
    width: 100%;
    padding: 1rem;
  }
`;
