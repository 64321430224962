import styled from "styled-components";
import { SwitchSliderModel } from "./model";
import { posDirectoBlue, grey_1, grey_4 } from "assets/strings/colors";

const SwitchSlider = styled(SwitchSliderModel)`
  #root & {
    cursor: pointer;
    margin: ${(props) => (props.margin ? props.margin : 0)};
    background: ${grey_1};
    color: ${grey_4};
    width: ${(props) => (props.width ? props.width : "100%")};
    border-bottom: 2px solid ${grey_4};
    border-radius: 0.7rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3rem;
    position: relative;
    > .selected-cover {
      z-index: ${(props) =>
        props.hasOwnProperty("zIndex") ? props.zIndex : 1};
      color: white;
      position: absolute;
      background: ${posDirectoBlue};
      box-shadow: 2px 1px 10px rgba(0, 0, 0, 0.3);
      width: ${(props) => 100 / props.options.length}%;
      font-size: 1.5rem;
      line-height: 2.7rem;
      border-radius: 0.7rem;
      text-align: center;
      transition: left 250ms ease-out;
      &.o0 {
        left: calc(${(props) => (100 / props.options.length) * 0}% + 0.3rem);
      }
      &.o1 {
        left: calc(${(props) => (100 / props.options.length) * 1}% - 0.3rem);
      }
      &.o2 {
        left: calc(${(props) => (100 / props.options.length) * 2}% - 0.3rem);
      }
      &.o3 {
        left: calc(${(props) => (100 / props.options.length) * 3}% - 0.3rem);
      }
      &.o4 {
        left: calc(${(props) => (100 / props.options.length) * 4}% - 0.3rem);
      }
    }
    > .option {
      width: ${(props) => 100 / props.options.length}%;
      z-index: ${(props) =>
        props.hasOwnProperty("zIndex") ? props.zIndex : 3};
      text-align: center;
      font-size: 1.2rem;
      line-height: 3rem;
      &.selected {
        color: white;
        font-weight: bold;
      }
    }
  }
`;

export { SwitchSlider };
