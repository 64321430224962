import React from "react";
import { Table } from "semantic-ui-react";
import { formatPrice } from "services/utils";
import moment from "moment-timezone";
import { Loader } from "components/loader";

export const BancamigaAmountModel = ({
  loading,
  banks,
  dateFrom,
  dateTo,
  className,
}) => {
  if (loading)
    return (
      <div className={className}>
        <Loader />
      </div>
    );
  const bankList = banks && banks.length ? [...banks] : [];
  return (
    <div className={className}>
      <div className="header">
        <p className="title">Bancamiga</p>
        <p className="date">
          {`${moment(dateFrom).tz("America/Caracas").format("ll")}`}
        </p>
      </div>
      <div className="table">
        <Table celled color={"green"}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Nombre</Table.HeaderCell>
              <Table.HeaderCell>Número de cuenta</Table.HeaderCell>
              <Table.HeaderCell>Fondos</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {bankList.map((bank) => {
              return (
                <Table.Row key={bank.name}>
                  <Table.Cell>
                    {bank && bank.name ? bank.name : "--"}
                  </Table.Cell>
                  <Table.Cell>
                    {bank && bank.account_number ? bank.account_number : 0}
                  </Table.Cell>
                  <Table.Cell>{`${formatPrice(
                    bank && bank.funds ? bank.funds : 0
                  )} Bs.`}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    </div>
  );
};
