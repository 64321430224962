import React, { useContext } from "react";
import { Icon } from "semantic-ui-react";
import { MPremiumHistoryContext } from "../../context";
import { grey_4 } from "assets/strings/colors";
import { Table } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { formatPrice } from "services/utils";
import moment from "moment";

export const UserBundleDetailModel = ({ className }) => {
  const { viewType, state, changeState } = useContext(MPremiumHistoryContext);

  const formatString = "DD [de] MMMM YYYY";

  const handleBack = (event) => {
    event.preventDefault();
    changeState({
      selectionDone: false,
    });
  };
  if (!state.selectedBundle) return <></>;

  const category = state.selectedCommerce.category;

  const {
    activated_by: activatedBy,
    commerce_amount: amount,
    pd_amount: pdAmount,
    commerce_to_pay,
    username,
    exchange_day: exchangeDay,
    start_date: activatedDate,
    expire_date: expireDate,
    max_enter_hour: avgEnterHour,
    max_exit_hour: avgExitHour,
    max_entry_access: maxEntryAccess,
    max_exit_access: maxExitAccess,
    qty_tickets: qtyTickets,
    bundle_title: bundleTitle,
  } = state.selectedBundle;
  const payToCommerceName = (commerce_to_pay && commerce_to_pay.name) || "N/A";

  const activatedByCommerce = activatedBy && activatedBy.commerce;
  let activeByName = activatedByCommerce
    ? `${activatedBy.commerce.name}:${activatedBy.operator.username}`
    : `${username}`;

  const getHour = (strHr) => {
    return strHr ? `${strHr}:00` : "N/A";
  };

  return (
    <div className={className}>
      {viewType === "phone" && (
        <Icon
          style={{
            paddingBottom: "2rem",
            display: "flex",
            alignItem: "left",
            float: "left",
            color: grey_4,
            cursor: "pointer",
            zIndex: 1,
            width: "100%",
          }}
          onClick={handleBack}
          name="arrow left"
        />
      )}
      <p className="building">{bundleTitle}</p>
      <div className="username">
        {category === "pagodirecto" ? (
          <Link
            to={{
              pathname: "/mpos/users",
              state: {
                user: username,
                remoteAccess: true,
                remoteStateToBack: {
                  ...state,
                  from_date: state.from_date
                    ? state.from_date.toISOString()
                    : null,
                  end_date: state.end_date
                    ? state.end_date.toISOString()
                    : null,
                  viewType,
                  path: "/mpos/monthly-premium/history",
                },
              },
            }}
          >
            @{username}
          </Link>
        ) : (
          <p>@{username}</p>
        )}
      </div>
      <div className="info">
        <div className="line">
          <p className="title">Fecha de activación:</p>
          <p className="content">
            {moment(activatedDate).format(formatString)}
          </p>
        </div>
        <div className="line">
          <p className="title">Fecha de vencimiento:</p>
          <p className="content">{moment(expireDate).format(formatString)}</p>
        </div>
        <div className="line">
          <p className="title">Activado por:</p>
          <p className="content">{activeByName}</p>
        </div>
        <div className="line">
          <p className="title">Recaudado para:</p>
          <p className="content">{payToCommerceName}</p>
        </div>
        {category === "estacionamiento" && (
          <div className="line">
            <p className="title">Monto:</p>
            <p className="content">{`${formatPrice(amount)} Bs`}</p>
          </div>
        )}
        {category === "pagodirecto" && (
          <div className="line">
            <p className="title">Monto:</p>
            <p className="content">{`${formatPrice(pdAmount + amount)} Bs`}</p>
          </div>
        )}
        <div className="line">
          <p className="title">Tasa de cambio del día:</p>
          <p className="content">{`${formatPrice(exchangeDay)} Bs`}</p>
        </div>
      </div>
      <div className="stats">
        <p className="title">Estadísticas</p>
        <div className="table">
          <Table celled>
            <Table.Header>
              {viewType !== "phone" && (
                <Table.Row>
                  <Table.HeaderCell>Nombre</Table.HeaderCell>
                  <Table.HeaderCell>Valor</Table.HeaderCell>
                </Table.Row>
              )}
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>Tickets</Table.Cell>
                <Table.Cell>{qtyTickets}</Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Hora promedio de entrada</Table.Cell>
                <Table.Cell>{getHour(avgEnterHour)}</Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>Hora promedio de salida</Table.Cell>
                <Table.Cell>{getHour(avgExitHour)}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Acceso con más entradas</Table.Cell>
                <Table.Cell>{`${
                  maxEntryAccess && maxEntryAccess.name
                    ? maxEntryAccess.name
                    : "N/A"
                } - track: ${
                  maxEntryAccess && maxEntryAccess.track_index
                    ? maxEntryAccess.track_index
                    : "--"
                }`}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>Acceso con más salidas</Table.Cell>
                <Table.Cell>{`${
                  maxExitAccess && maxExitAccess.name
                    ? maxExitAccess.name
                    : "N/A"
                } - track: ${
                  maxExitAccess && maxExitAccess.track_index
                    ? maxExitAccess.track_index
                    : "--"
                }`}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </div>
      </div>
    </div>
  );
};
