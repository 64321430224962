import { PanelsBoxModel } from "./model";
import styled from "styled-components";

const PanelsBox = styled(PanelsBoxModel)`
  #root && {
    width: 100%;
    ${(props)=>{return props.phoneView ? "" :"height: calc(45vh - 7em);"}}
    display: flex;
    ${(props)=>{return props.phoneView ? "flex-direction:column" :""}};
    justify-content: center;
    align-items: center;
    > .tables {
      display: flex;
      justify-content: center;
      align-items:center;
      ${(props)=>{return props.phoneView ? "flex-direction:column" :""}};
      > .simple-first {
        ${(props)=>{return props.phoneView ? "" :"height: calc(45vh - 7em);"}}
        margin-left: ${(props)=>{return props.phoneView ? "0" :"1rem"}};
        width: ${(props)=>{return props.phoneView ? "95%" :"48%"}};
      }
      > .simple {
        ${(props)=>{return props.phoneView ? "margin-top: 1rem;" :"height: calc(45vh - 7em); "}}
        margin-left: ${(props)=>{return props.phoneView ? "0" :"1rem"}};
        width: ${(props)=>{return props.phoneView ? "95%" :"48%"}};
      }
      > .compose {
        display: flex-column;
        justify-content: space-between;
        ${(props)=>{return props.phoneView ? "margin-top: 1rem;" :"height: calc(45vh - 7em); "}}
        margin-left: ${(props)=>{return props.phoneView ? "0" :"1rem"}};
        width: ${(props)=>{return props.phoneView ? "95%" :"20%"}};
      }
    }
    .tables.tables-appear {
      opacity: 0;
      transform: translateY(30px);
    }
    .tables.tables-appear-active {
      opacity: 0.8;
      transform: translateY(-2px);
      transition: all 350ms ease-out;
    }
    .tables.tables-enter {
      opacity: 0;
      transform: translateY(30px);
    }
    .tables.tables-enter-active {
      opacity: 0.8;
      transform: translateY(-2px);
      transition: all 300ms ease-out;
    }
    .tables.tables-enter-done {
      opacity: 1;
      transform: translateY(0);
      transition: all 50ms ease-out;
    }
    .tables.tables-exit {
      opacity: 0.8;
      transform: translateY(2px);
    }
    .tables.tables-exit-active {
      opacity: 0;
      transform: translateY(-30px);
      transition: all 350ms ease-out;
    }
  }
`;

export { PanelsBox };
