import React, { useState, useContext } from "react";
import { SuperadminBillsContext } from "../../context";
import { Pagination, EntitiesList } from "components/entities-list";
import SearchInputNew from "components/search-input-new";
import {
  DirectoryListHeader,
  formattingDirectory,
} from "../directory-list-item";
import { Loader } from "components/loader";

import { billingComponentServices } from "../../services";
import { Button } from "components/buttons";

const DirectoryModel = ({ className }) => {
  const [input, setInput] = useState("");
  const { state, changeState, viewport } = useContext(SuperadminBillsContext);
  const { loading, setArgs, setActivateFetch } =
    billingComponentServices.useFetchDirectory(true);
  const {
    loading: loadingDelete,
    setArgs: setArgsDelete,
    setActivateFetch: setActiveDelete,
  } = billingComponentServices.useDeleteDataDirectory();

  const onChangeUser = (event) => {
    const value = event.target.value;
    setInput(value);
  };

  const onDirectorySubmit = (event) => {
    event.preventDefault();
    setArgs({
      skip: 1,
      limit: 10,
      input,
    });
    setActivateFetch(true);
  };

  const handlePageUp = (event) => {
    event.preventDefault();
    setArgs({
      skip: state.currentPage + 1,
      limit: 10,
      input,
    });
    changeState({ currentPage: state.currentPage + 1 });
    setActivateFetch(true);
  };

  const handlePageDown = (event) => {
    event.preventDefault();
    setArgs({
      skip: state.currentPage - 1,
      limit: 10,
      input,
    });
    changeState({ currentPage: state.currentPage - 1 });
    setActivateFetch(true);
  };

  const onDataSelection = (event) => {
    const selectedFiscalForm = state.directory.find(
      (item) => item._id === event.currentTarget.id
    );
    changeState({
      selectedFiscalForm,
      fiscalForm: {
        ...state.fiscalForm,
        ...selectedFiscalForm,
        billing_fiscal_id: selectedFiscalForm._id,
      },
      showFormOnMobile: true,
    });
  };

  const onDataDelete = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setArgsDelete({ _id: event.currentTarget.id });
    setActiveDelete(true);
  };

  const loadingResult = loading || loadingDelete;
  return (
    <div className={className}>
      {viewport === "phone" && (
        <Button
          color="blue"
          width="40%"
          style={{ alignSelf: "center" }}
          onClick={(event) => {
            event.preventDefault();
            changeState({ showFormOnMobile: true });
          }}
        >
          Nueva factura
        </Button>
      )}
      <p className={"title"}>Directorio</p>

      <SearchInputNew
        handleSearch={onDirectorySubmit}
        handleChange={onChangeUser}
        searchParam={input}
        placeholder={"Buscar Usuario"}
        className={"input-container"}
      />
      {loadingResult && <Loader />}
      {!loadingResult && (
        <EntitiesList
          entities={state.directory}
          formattingFunction={formattingDirectory}
          selectionHandler={onDataSelection}
          additionalArgs={[onDataDelete]}
          height={"82vh"}
        >
          <Pagination
            padding="0 0 0.8rem 0"
            nextPageExist={state.nextPageExist}
            currentPage={state.currentPage}
            handlePageUp={handlePageUp}
            handlePageDown={handlePageDown}
          />
          {state.directory && state.directory.length > 0 ? (
            <DirectoryListHeader />
          ) : (
            ""
          )}
        </EntitiesList>
      )}
    </div>
  );
};

export default DirectoryModel;
