import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getViewMposCommerces } from "redux/selectors/view-mpos-commerces";
import { setViewState } from "redux/actions/view-mpos-commerces";
import { MovementsFormNew } from "components/movements-form-new";
import { Icon } from "semantic-ui-react";
import { Button } from "components/buttons";
import {
  formatMovementsList,
  formatMovementsPhoneList,
} from "components/movements-list";
import { EntitiesList, Pagination } from "components/entities-list";
import { Panel } from "components/panel";
import { useStateManager } from "hooks/use-state-manager";
import { getMovementsRequest } from "./services";
import { throwPopupMessage } from "redux/actions/popup-messages";
import MovementDetail from "./MovementDetail";

const MovementsPanel_unconnected = ({
  throwPopupMessage,
  selectedCommerce,
  setViewState,
  transactionTags,
  phoneView,
}) => {
  const initialState = {
    loading: false,
    selectedCommerce,
    movements: [],
    searchParams: {
      searchType: "latests",
      type: "",
      from_date: null,
      end_date: null,
      currency: "BS",
      transaction_tag: [],
      counterpart_id: "",
      commerce_counterpart_id: "",
    },
    pagination: {
      limit: 10,
      skip: 1,
      currentPage: 1,
      nextPageExist: false,
    },
    getMovements: true,
    movementsPerPage: 10,
  };

  const [state, changeState] = useStateManager(initialState);

  const onSubmit = (data) => {
    changeState({
      searchParams: { ...data },
      pagination: { ...initialState.pagination },
      getMovements: true,
    });
  };

  const handlePageDown = (event) => {
    event.preventDefault();
    const { pagination } = state;
    const newPagination = {
      ...pagination,
      skip: pagination.skip - 1,
      currentPage: pagination.currentPage - 1,
    };
    changeState({ pagination: newPagination, getMovements: true });
  };
  const handlePageUp = (event) => {
    event.preventDefault();
    const { pagination } = state;
    const newPagination = {
      ...pagination,
      skip: pagination.skip + 1,
      currentPage: pagination.currentPage + 1,
    };
    changeState({ pagination: newPagination, getMovements: true });
  };

  const handleBack = (event) => {
    event.preventDefault();
    setViewState("commerce_info");
  };
  const onMovementSelection = (event) => {
    const movement = state.movements.find(
      (mov) => mov._id === event.currentTarget.id
    );
    throwPopupMessage("info", <MovementDetail movement={movement} />);
  };
  useEffect(() => {
    if (state.getMovements) {
      getMovementsRequest(state, changeState, throwPopupMessage);
    }
  }, [state.getMovements]);

  return (
    <>
      {phoneView && (
        <Button
          margin="0 0 0 1rem"
          padding="0"
          width="5%"
          color="transparent"
          icon="true"
          onClick={handleBack}
        >
          <Icon name="left arrow" />
        </Button>
      )}
      <Panel>
        <MovementsFormNew
          onSubmit={onSubmit}
          loading={state.loading}
          phoneView={phoneView}
          counterpartFilter
        />
      </Panel>
      <Panel>
        <EntitiesList
          height="calc(55vh - 11rem)"
          viewState={state.loading}
          entities={state.movements}
          formattingFunction={
            phoneView ? formatMovementsPhoneList : formatMovementsList
          }
          selectionHandler={onMovementSelection}
          additionalArgs={[transactionTags]}
        >
          <Pagination
            padding="0 0 0.8rem 0"
            nextPageExist={state.pagination.nextPageExist}
            currentPage={state.pagination.currentPage}
            handlePageUp={handlePageUp}
            handlePageDown={handlePageDown}
          />
        </EntitiesList>
      </Panel>
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedCommerce: getViewMposCommerces(state).selectedCommerce,
});

const mapDispatchToProps = {
  setViewState,
  throwPopupMessage,
};

const MovementsPanel = connect(
  mapStateToProps,
  mapDispatchToProps
)(MovementsPanel_unconnected);

export { MovementsPanel };
