//import { bancamigaTransfer, banescoTransfer } from "./mock";
import { micropd_02 } from "services/config";
import { post } from "services/http-methods";

const endpoints = {
  list: `${micropd_02}/superadmin/bank-movement/list`,
  changeStatus: `${micropd_02}/superadmin/bank-movement/change-status`,
};

/* function mockResponse() {
  const transfers = new Array(10).fill(bancamigaTransfer);
  transfers.fill(banescoTransfer, 6, 10);

  const data = {
    ok: true,
    transfers,
  };
  const blob = new Blob([JSON.stringify(data)]);
  return new Response(blob);
}

function mockStatusResponse() {
  const data = {
    ok: true,
  };
  const blob = new Blob([JSON.stringify(data)]);
  return new Response(blob);
}
 */
export function list(body) {
  /**
   * @brief
   * @attributes
   *  body = {
   *
   */

  return post(endpoints.list, body);
  //return mockResponse();
}

export function changeStatus(body) {
  /**
   * @brief
   * @attributes
   *  body = {
   *
   */

  return post(endpoints.changeStatus, body);
  //return mockStatusResponse();
}
