import { TicketModel } from "./model";
import styled from "styled-components";
import imagenTicket from "assets/img/ticket_asset_new.png";
import {
  yellow_3,
  grey_5,
  grey_bg,
  bluePD,
  green_3,
} from "assets/strings/colors";

const Ticket = styled(TicketModel)`
  #root && {
    margin: 0;
    background: ${grey_bg};
    height: calc(100vh - 4rem);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > .ticket {
      margin: 0;
      margin-top: 1rem;
      display: flex;
      align-self: center;
      flex-direction: column;
      align-items: center;
      height: calc(100vh - 4rem);
      width: 100%;
      background-image: url(${imagenTicket});
      background-size: auto 85%;
      background-repeat: no-repeat;
      background-position: center;
      -webkit-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.1));
      filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.1));
      > .icon {
        margin-top: 3rem;
        color: ${yellow_3};
        background: white;
        width: 60px;
        height: 60px;
        border: 10px solid white;
        border-radius: 50%;
        background-size: auto 50%;
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      > .paid {
        margin-top: 3rem;
        color: ${green_3};
        background: white;
        width: 60px;
        height: 60px;
        border: 10px solid white;
        border-radius: 50%;
        background-size: auto 50%;
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      > .closed {
        margin-top: 3rem;
        color: white;
        background: red;
        width: 60px;
        height: 60px;
        border: 10px solid white;
        border-radius: 50%;
        background-size: auto 50%;
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      > .unknow {
        margin-top: 3rem;
        color: ${bluePD};
        background: white;
        width: 60px;
        height: 60px;
        border: 10px solid white;
        border-radius: 50%;
        background-size: auto 50%;
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      > .titles {
        font-size: 1rem;
        text-align: center;
        height: calc(25vh - 4em);
        width: clamp(20rem, 80%, 40rem);
        > .title {
          margin: 0;
          font-weight: bold;
        }
        > .sub-title {
          margin: 0;
        }
      }
      > .current-info {
        margin-top: 2.5rem;
        height: calc(30vh - 4em);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 65%;
        > .row {
          width: 100%;
          font-size: 0.8rem;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          height: 15vh;
          > .data-left {
            margin: 0;
            text-align: left;
            > .icon {
              margin: 0;
              color: ${grey_5};
            }
            > .header {
              font-size: 1rem;
              margin: 0;
              text-transform: uppercase;
              color: ${grey_5};
              font-weight: bold;
            }
            > .content {
              font-size: 1rem;
            }
            > .amount {
              font-size: 1.1rem;
            }
          }
          > .data-right {
            margin: 0;
            text-align: right;
            > .icon {
              margin: 0;
            }
            > .header {
              margin: 0;
              font-size: 1rem;
              text-transform: uppercase;
              color: ${grey_5};
              font-weight: bold;
            }
            > .content {
              font-size: 1rem;
            }
            > .amount {
              font-size: 1.1rem;
            }
          }
        }
      }

      > .footer {
        margin: 4rem;
        width: 65%;
        background: ${grey_bg};
        border-radius: 1rem;
        height: 12vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        > .title {
          margin: 0;
          font-size: 1rem;
          font-weight: bold;
          color: ${grey_5};
        }
        > .ticket-id {
          color: black;
          font-size: 1.4rem;
          font-weight: bold;
        }
      }
    }
    @media (max-width: 740px) {
      > .ticket {
        margin-top: -1rem;
        background-size: auto 90%;
        > .icon {
          margin-top: 2rem;
        }
        > .paid {
          margin-top: 2rem;
        }
        > .closed {
          margin-top: 2rem;
        }
        > .unknow {
          margin-top: 2rem;
        }
        > .titles {
          width: 90%;
        }
        > .current-info {
          width: 80%;
        }
      }

      > button {
        margin: 0;
        margin-bottom: 1rem;
      }
    }
  }
`;

export { Ticket };
